<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
  
      <div class="container-fluid  bg-white">
        <div class="row">

          <div class="col-sm-12 p-0">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                  <li class="breadcrumb-item">
                      <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                  </li>
                  <li class="breadcrumb-item">
                      <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="">Marketing Master</a>
                  </li>
                <li class="breadcrumb-item newitm2  active" aria-current="page"> Gift Code</li>
              </ol>
            </nav>
          </div> 

            <div class="col-sm-12">
              <div class="row mx-0 my-3">
                <div class="col-sm-12 p-0">
                    <div class="bg-lyt ">
                        <div class="d-flex justify-content-between align-items-center py-3 px-2">
                            <h6 class="mb-0 font-weight-bold">
                              Gift Codes
                            </h6>
                            <button type="button" class="btn btn-sm px-3 py-1 rounded-pill btn-theme">
                                <i class="fa fa-plus-circle mr-1"></i> Add New Gift Code</button>
                        </div>
                    </div>
                </div>
              </div>
                <div class="col-sm-12 col-md-12 nopadding">
                    <ul class="nav nav-tabs newnav border-0" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Gift Codes <i class="fa fa-caret-right ml-2"></i></a>
                        </li>
                        <li class="nav-item ml-4">
                          <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Activity <i class="fa fa-caret-right ml-2"></i></a>
                        </li>
                      </ul>
                      <div class="tab-content tab-contents border-0 p-0 mt-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                          <div class="btn-block-gift-code">  
                            <strong class="mr-2">Gift Code</strong>
                            <button type="button" class="btn btn-sm btn-dark-new mr-2 px-3">Import</button>
                            <button type="button" class="btn btn-sm btn-dark-new px-3">Export</button>
                          </div>
                          <div class="pt-3">
                            <div class="row">
                              <div class="col-lg-9 col-12 mb-2">
                                <h6 class=""> <b> All (308) </b> | <b>Redeemed (99)</b></h6>
                              </div>
                              <div class="col-lg-3 col-12">
                                <input type="text" class="form-control w-100 mr-2" placeholder="Search ID, Name, E-Mail"> <i
                                  class="fa fa-search psr"></i>
                              </div>
                            </div>
                          </div>

                          <div class="pt-3">
                            <div class="row">
                             <div class="col-lg-4 col-12 mb-2">
                              <select name=""  id="" class="form-control bg-light w-auto d-inline-block pr-2">
                               <option value="Actions">Actions</option>
                               <option value="1">1</option>
                               <option value="2">2</option>
                              </select>  <label for="" class="pl-2 font-weight-bold">Action on 2 selected: </label>
                             
                              <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 ml-sm-2 mt-2 mt-sm-02">
                                <option value="">Redeemed By Customer</option>
                              
                               </select>
                            </div>
                             <div class="col-lg-8 col-12 d-flex flex-column flex-lg-row justify-content-end ">
                               <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
                                 <option value="">Approved</option>
                                 <option value="1">Reject</option>
                               
                                </select>
                                <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
                                 <option value="">Date Range: Last Day</option>
                                 <option value="1">Date Range: Today</option>
                               
                                </select>
                                <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
                                 <option value="">Result of per page 15</option>
                                 <option value="">Result of per page 10</option>
                               
                                </select>
                                <button class="form-control bg-light w-auto d-inline-block pr-2 mr-2 mb-2 mb-lg-0"> Refresh</button>
                             </div>
                 
                            </div>
                           </div>

                           <div id="grid-theme-wrapper">
                            <div class="border">
                              <div class="table-responsive">
                                <igx-grid #grid2 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                                  [filterMode]="'excelStyleFilter'"  >
                               
                                  <igx-column field="" header="" width="60">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <a href="javascript:void(0)">
                                          <span class="cell-vl " > 
                                          </span>
                                        </a>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="code" header="Code" width="250">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span class="cell-vl " ></span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="balance" header="Balance" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span class="cell-vl " ></span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="status" header="Status" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span ></span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="issued" header="Issued" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span ></span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="from" header="From" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span ></span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="to" header="To" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span > Yes</span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="redeemed" header="Redeemed" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span > Yes</span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
                                  <igx-column field="expires" header="Expires" width="">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                      <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                        <span ></span>
                                      </div>
                                    </ng-template>
                                  </igx-column>
              
                                </igx-grid>
                              </div>
                           
                            </div>
                          </div>


                        </div>
                        <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">

                            <div class="btn-block-gift-code">  
                                  <strong class="mr-2">Gift Code</strong>
                                  <button type="button" class="btn btn-sm btn-dark-new mr-2 px-3">Import</button>
                                  <button type="button" class="btn btn-sm btn-dark-new px-3">Export</button>
                                </div>
                                <div class="pt-3">
                                  <div class="row">
                                    <div class="col-lg-9 col-12 mb-2">
                                      <h6 class=""> <b> All (308) </b> | <b>Redeemed (99)</b></h6>
                                    </div>
                                    <div class="col-lg-3 col-12">
                                      <input type="text" class="form-control w-100 mr-2" placeholder="Search ID, Name, E-Mail"> <i
                                        class="fa fa-search psr"></i>
                                    </div>
                                  </div>
                                </div>
      
                                <div class="pt-3">
                                  <div class="row">
                                   <div class="col-lg-4 col-12 mb-2">
                                    <select name=""  id="" class="form-control bg-light w-auto d-inline-block pr-2">
                                     <option value="Actions">Actions</option>
                                     <option value="1">1</option>
                                     <option value="2">2</option>
                                    </select>  <label for="" class="pl-2 font-weight-bold">Action on 2 selected: </label>
                                   
                                    <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 ml-sm-2 mt-2 mt-sm-02">
                                      <option value="">Redeemed By Customer</option>
                                    
                                     </select>
                                  </div>
                                   <div class="col-lg-8 col-12 d-flex flex-column flex-lg-row justify-content-end ">
                                     <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
                                       <option value="">Approved</option>
                                       <option value="1">Reject</option>
                                     
                                      </select>
                                      <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
                                       <option value="">Date Range: Last Day</option>
                                       <option value="1">Date Range: Today</option>
                                     
                                      </select>
                                      <select name=""  id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
                                       <option value="">Result of per page 15</option>
                                       <option value="">Result of per page 10</option>
                                     
                                      </select>
                                      <button class="form-control bg-light w-auto d-inline-block pr-2 mr-2 mb-2 mb-lg-0"> Refresh</button>
                                   </div>
                       
                                  </div>
                                 </div>
      
                                 <div id="grid-theme-wrapper">
                                  <div class="border">
                                    <div class="table-responsive">
                                      <igx-grid #grid2 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                                        [filterMode]="'excelStyleFilter'"  >
                                     
                                        <igx-column field="" header="" width="60">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <a href="javascript:void(0)">
                                                <span class="cell-vl " > 
                                                </span>
                                              </a>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="code" header="Code" width="250">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span class="cell-vl " ></span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="balance" header="Balance" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span class="cell-vl " ></span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="status" header="Status" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span ></span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="issued" header="Issued" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span ></span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="from" header="From" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span ></span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="to" header="To" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span > Yes</span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="redeemed" header="Redeemed" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span > Yes</span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                                        <igx-column field="expires" header="Expires" width="">
                                          <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                              <span ></span>
                                            </div>
                                          </ng-template>
                                        </igx-column>
                    
                                      </igx-grid>
                                    </div>
                                 
                                  </div>
                                </div>

                        </div>
                      </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>




