import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AppSettings } from './AppSetting';

@Injectable({
  providedIn: 'root'
})
export class CustomerServiceService {
  url = AppSettings.API_ENDPOINT;

constructor(private http: HttpClient) { }

getCategory() {
  const headers = new HttpHeaders();
  return this.http.post(this.url + "/getCategory", { headers })
}

getCatalogueType(){
  // const data = { customer_id}
  const headers = new HttpHeaders()
  return this.http.post(this.url + "/getCatalogueTypeList",{ headers }).pipe(map(res => {
    return res as any;
  },error =>{
    return error as any;
  }))
}
saveCatalogue(cataloguelist, buyerid, userId, typename, typeid, price_as_per){
  const data = { cataloguelist, buyerid,userId, typename, typeid,price_as_per}
  //console.log("datatta", data)
  const headers = new HttpHeaders()
  return this.http.post(this.url + "/postCatalogueListAdmin",data, { headers }).pipe(map(res => {
    return res as any;
  },error =>{
    return error as any;
  }))
}
removeCatalogueProduct(id){
  const data = { id }
  const headers = new HttpHeaders()
  return this.http.post(this.url + "/deletecatalogueproducts",data,{ headers }).pipe(map(res => {
    return res as any;
  },error =>{
    return error as any;
  }))
}
getBuyerAddress(customerId) {
  const headers = new HttpHeaders()
  const data = { customerId }
  return this.http.post(this.url + "/getallbuyeraddress", data, { headers }).pipe(map(res => {
    return res as any;
  }, error => {
    return error as any;
  }));
}
getAllBuyerCatalogue(customerId,userId) {
  const headers = new HttpHeaders()
  const data = {customerId,userId }
  return this.http.post(this.url + "/getAllCatalogue", data, { headers }).pipe(map(res => {
   
    return res as any;
  }, error => {
    return error as any;
  }));
}
makeOrderPayment(amount, currency){
  const data = { amount,  currency}
  const headers = new HttpHeaders()
  return this.http.post(this.url + "/createOrderForAdmin", data,{ headers }).pipe(map(res => {
    return res as any;
  },error =>{
    return error as any;
  }))
}
checkPaymentstatus(order_id, razorpay_payment_id, razorpay_signature){
  const data = { order_id,  razorpay_payment_id, razorpay_signature}
  const headers = new HttpHeaders()
  return this.http.post(this.url + "/checkPaymentstatusAdmin", data,{ headers }).pipe(map(res => {
    return res as any;
  },error =>{
    return error as any;
  }))
}
getProductForCreateCatalogue(whereClouse,customerId){
  const headers = new HttpHeaders()
  const data = { whereClouse,customerId }
  console.log("catatttattattaa", data)
  return this.http.post(this.url + "/getProductForCreateCatalogueAdmin", data, { headers }).pipe(map(res => {
    return res as any;
  }, error => {
    return error as any;
  }));
}
saveForLater(customer_id,product_id,product_price,product_mrp,product_name,seller_id,seller_name,image_path){
  const headers = new HttpHeaders()
  const data = { customer_id,product_id,product_price,product_mrp,product_name,seller_id,seller_name,image_path}
  return this.http.post(this.url + "/saveForLater", data, { headers }).pipe(map(res => {
    return res as any;
  }, error => {
    return error as any;
  }));
}

}
