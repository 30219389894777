import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
//import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'gridform-button-renderer',
  template: `
    <button type="button" class={{labelclass}} (click)="onClick($event)"> <i class={{icon}}> </i> </button>
    `
})
export class GridFromButtonRendererComponent implements ICellRendererAngularComp {

    params;
    label: string;
    icon:string;
    labelclass:string;
  
    agInit(params): void {
      this.params = params;
      this.label = this.params.label || null;
      this.icon = this.params.icon || null;
      this.labelclass = this.params.labelclass || null;
    }
  
    refresh(params?: any): boolean {
      return true;
    }
  
    onClick($event) {
      if (this.params.onClick instanceof Function) {
        // put anything into params u want pass into parents component
        const params = {
          event: $event,
          rowData: this.params.node.data
          // ...something
        }
        this.params.onClick(params);
  
      }
    }
  }
