import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  templateUrl: './button-renderer.component.html',
  styleUrls: ['./button-renderer.component.css']
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  agInit(params): void {
    
    this.params = params;
    this.label = this.params.label || null;
    //console.log("ButtonRendererComponent params==>",this.params)
  }
  isPopup(params?: any): boolean{
    return true;
  }
  refresh(params?: any): boolean {
    return true;
  }

  onClick($event,str) {
    var selarr=this.params.actionList.filter(x=>x.str==str)
    if(selarr[0].onClick instanceof Function)
    {
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      selarr[0].onClick(params)
    }
  }

//   onClick($event,str) {
//     console.log("dhj")
//     if (this.params.onClick instanceof Function) {
//       // put anything into params u want pass into parents component
//       const params = {
//         event: $event,
//         rowData: this.params.node.data,
//         str:str
//         // ...something
//       }
//       this.params.onClick(params);
      
//     }
//   }
 }
// Author: T4professor


// import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid';

// @Component({
//   selector: 'app-button-renderer',
//   template: `
//     <button type="button" (click)="onClick($event)">{{label}}</button>
//     <div class="navbar navbar-expand-lg">
//     <ul class="navbar-nav mt-2 mt-lg-0" >
       
//         <li class="nav-item dropdown">
//           <a class="nav-link dropdown-toggle btn btn-info" href="javascript:void(0)" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//             Actions
//           </a>
//           <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
         
//             <a class="dropdown-item" href="javascript:void(0)" ><i class="fa fa-edit"></i> Edit</a>
//             <a class="dropdown-item" href="javascript:void(0)" ><i class="fa fa-trash-o"></i> Delete</a>
//             <a class="dropdown-item" href="javascript:void(0)" ><i class="fa fa-save"></i> Update</a>
//             <a class="dropdown-item" href="javascript:void(0)" ><i class="fa fa-file"></i> Conditional Rules</a>
//             <div class="dropdown-divider"></div>
        
//           </div>
//         </li>
//       </ul>
//     </div>
//     `
// })

// export class ButtonRendererComponent implements ICellRendererAngularComp {

//   params;
//   label: string;

//   agInit(params): void {
//     this.params = params;
//     this.label = this.params.label || null;
//   }

//   refresh(params?: any): boolean {
//     return true;
//   }

//   onClick($event) {
//     if (this.params.onClick instanceof Function) {
//       // put anything into params u want pass into parents component
//       const params = {
//         event: $event,
//         rowData: this.params.node.data
//         // ...something
//       }
//       this.params.onClick(params);

//     }
//   }
// }