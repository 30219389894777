import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'; 
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import {FormService} from '../../_services/form.service';
import { SectionConfig,SessionUrlPrams } from "../../field.interface";
import{LoginService} from 'src/app/_services/login.service';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";

declare var $: any;
@Component({
  selector: 'app-inprocess-completed',
  templateUrl: './inprocess-completed.component.html',
  styleUrls: ['./inprocess-completed.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InprocessCompletedComponent  implements OnInit {
  
  // loginType:any;
  columnActionStrip: any[] = [];
  columndraftActionStrip: any[] = [];

  pagesize="10";  
  frameworkComponents: any;
  // frameworkComponents1: any;
  sessionUrlPrams:SessionUrlPrams; //new added
  formId:any;
  InboxLoaded:Promise<boolean>;
  OutboxLoaded:Promise<boolean>;
  inboxRows:any[]=[];
  inboxColumndef:any[]=[];  
  currentRouter: any="";
  outboxColumndef: any[]=[];
  outboxRows: any[]=[];
  stageid: any;
  processinstid: any;
  worklistid: any;
  refKey: any;
  clickLink: any;
  OverlaySetting: any;

  constructor(private router:Router,
    private loginService:LoginService,
  private formService:FormService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,

    }
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.OverlaySetting = {
      positionStrategy: new ConnectedPositioningStrategy({
        horizontalDirection: HorizontalAlignment.Left,
        horizontalStartPoint: HorizontalAlignment.Right,
        verticalStartPoint: VerticalAlignment.Bottom,

      }),
      scrollStrategy: new NoOpScrollStrategy()
    };
    
   }

  ngOnInit(): void {
    this.clickLink= localStorage.getItem("linkname")?localStorage.getItem("linkname"):"";
    $("body").removeClass("greyback");   
    
      this.sessionUrlPrams={
        frmid:this.formId,
        vid:0,
        UserCode:"UserCode",
        UserName:localStorage.getItem("userFullName"),
        ProfileId:localStorage.getItem("profileId"),
        Location:localStorage.getItem("office"),
        department:localStorage.getItem("department"),
        processtype:"ext",
      };
      let Url=this.router.url.split("?")[0]
      
        if(Url == "/draft"){
          this.currentRouter="draft";
          this.getInboxList();
        }else if(Url == "/submitted"){
          this.currentRouter="submitted";
          this.getOutboxList();
        }else if(Url == "/drafts"){
          this.currentRouter="drafts";
          this.getDraftList();
        }else if(Url == "/approved"){
          this.currentRouter="approved";
          this.getOutboxList();
        }
     
     
      
  }

  getInboxList(){
    var strInboxQry=`select w.worklistid,frm.form_name,TO_CHAR(w.sendtime:: DATE, 'dd/mm/yyyy') sendtime,CONCAT(su.user_fname,' ',su.user_mname,' ',su.user_lname) sendername,
    CONCAT(ru.user_fname,' ',ru.user_mname,' ',ru.user_lname) as receivername,pr.process_name,pi.form_id,pi.itemid,CONCAT(wu.user_fname,' ',wu.user_mname,' ',wu.user_lname) as withwhom ,pi.processinstanceid,
    dr.draft_requestid,dr.department_id,w.is_open,TO_CHAR(w.parkedtill:: DATE, 'dd/mm/yyyy') as parkedtill ,w.parked_remarks,w.actiontaken,w.stageid,dr.scheme_title,d.department_name,dr.form_vid,ses.varibale_name as scheme_category,'[1,1]' as val
    from radx_worklist w
    left join app_tbluserprofile s on w.sourceid=s.userprofile_id
    left join app_tbluser su on s.user_id=su.user_id
    left join app_tbluserprofile rec on w.destinationid=rec.userprofile_id
    left join app_tbluser ru on rec.user_id=ru.user_id
    left join radx_processinstances pi on w.processinstanceid=pi.processinstanceid
    left join app_tbluserprofile whom on pi.with_whom_userid=whom.userprofile_id
    left join app_tbluser wu on whom.user_id=wu.user_id
    left join radx_process pr on pi.processid=pr.processid
    left join radx_draft_requests dr on w.processinstanceid=dr.processinstid
    left join app_forms frm on frm.form_id=dr.form_id
    left join z_entitymast_department_001 d on dr.department_id=d.department_id
    left join app_tblsystemenum ses on dr.scheme_category=ses.id
    where  w.parked_status=1 and w.destinationid=${this.sessionUrlPrams.ProfileId} order by w.sendtime desc`;
 this.formService.getFormData(strInboxQry).subscribe(res=>{
     this.inboxColumndef=[
      {"field": "draft_requestid","headerName": "Request ID","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
       {"field": "scheme_title","headerName": "Scheme Title","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
       {"field": "process_name","headerName": "Proposal Type","sortable": true,"filter": true,"resizable": true,"width": "250","wrapText": true,"autoHeight": true},
       {"field": "sendtime","headerName": "Send Date","sortable": true,"filter": true,"resizable": true,"width": "120","wrapText": true,"autoHeight": true},
       {"field": "sendername","headerName": "Recieved From","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
       {"field": "parkedtill","headerName": "Parked Till","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
       {"field": "parked_remarks","headerName": "Parked Remarks","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
      
     ]  
     this.columndraftActionStrip.push({ field: "View Process", iconName: "external-link-square" });

     this.inboxRows=res.rows;
     this.InboxLoaded = Promise.resolve(true);
    })
  }

  getOutboxList(){   
    var strQry="";
    if(this.currentRouter == "approved"){
      strQry=`select dr.draft_requestid,sys.varibale_name scheme_category,d.department_name department_id,
      d.department_id deptid,
		  dr.processinstid as processinstanceid,
      dr.item_id,dr.scheme_title,
      case when dr.proposal_type ='1' then 'New'
      when dr.proposal_type ='2' then 'Ongoing'
      when dr.proposal_type ='1' then 'Continuing' end proposal_type,TO_CHAR(dr.createddate:: DATE, 'dd/mm/yyyy') application_date,form_id,form_vid,process_id,'[1,1]' as val 
      from radx_draft_requests dr left join app_tblsystemenum sys on dr.scheme_category=sys.id 
      left join z_entitymast_department_001 d on d.department_id=dr.department_id
      where dr.status_id=105 and dr.createdby=${this.sessionUrlPrams.ProfileId}  order by dr.createddate desc;`
    } else{
      strQry=`select dr.draft_requestid,sys.varibale_name scheme_category,d.department_name department_id,dr.item_id,dr.scheme_title,
      case when dr.proposal_type ='1' then 'New'
      when dr.proposal_type ='2' then 'Ongoing'
      when dr.proposal_type ='1' then 'Continuing' end proposal_type,TO_CHAR(dr.createddate:: DATE, 'dd/mm/yyyy') application_date,form_id,form_vid,process_id,'[1,1]' as val 
      from radx_draft_requests dr left join app_tblsystemenum sys on dr.scheme_category=sys.id 
      left join z_entitymast_department_001 d on d.department_id=dr.department_id
      where dr.status_id=107 and dr.createdby=${this.sessionUrlPrams.ProfileId}  order by dr.createddate desc;`
    }  
     this.formService.getFormData(strQry).subscribe(res=>{ 
      this.outboxColumndef=[];
       if(this.currentRouter == "approved"){
        this.outboxColumndef=[
          {"field": "draft_requestid","headerName": "Request ID","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
          {"field": "scheme_title","headerName": "Title of the Scheme","sortable": true,"filter": true,"resizable": true,"width": "450","wrapText": true,"autoHeight": true},
          {"field": "department_id","headerName": "Department Name","sortable": true,"filter": true,"resizable": true,"width": "300","wrapText": true,"autoHeight": true},
          {"field": "application_date","headerName": "Application Date","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
          {"field": "application_date","headerName": "Action","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
        ]    
      }else{
        this.outboxColumndef=[
          {"field": "draft_requestid","headerName": "Request ID","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
          {"field": "scheme_title","headerName": "Title of the Scheme","sortable": true,"filter": true,"resizable": true,"width": "450","wrapText": true,"autoHeight": true},
          {"field": "department_id","headerName": "Department Name","sortable": true,"filter": true,"resizable": true,"width": "300","wrapText": true,"autoHeight": true},
          {"field": "application_date","headerName": "Application Date","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true}        
        ] 
      }
      this.outboxRows=res.rows;
      // console.log(JSON.stringify(this.inboxRows))
      this.OutboxLoaded = Promise.resolve(true);
     })
  }  

  
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  onInitialize(obj)
  {
    // this.router.navigateByUrl('/initiate-process?'+obj.rowData.new_scheme_proposal_id+'&64');
    let data  = JSON.stringify(`${obj.rowData.new_scheme_proposal_id}&64`)
    let encriptUrl = this.loginService.encriptDataUrl(data)
this.router.navigateByUrl('/initiate-process?'+encriptUrl);        
     }
  onEdit(obj)
  {
    // this.router.navigateByUrl('/form-view?64=');    
    let data  = JSON.stringify(`64=`)
    let encriptUrl = this.loginService.encriptDataUrl(data)
this.router.navigateByUrl('/form-view?'+encriptUrl);    
  }

  onProcess(obj){
    // console.log(obj)
    this.formId=obj.rowData["form_id"];
    let rowId=obj.rowData["itemid"];
    this.stageid=obj.rowData["stageid"];
    this.processinstid=obj.rowData["processinstanceid"];
    this.worklistid=obj.rowData["worklistid"];
    this.refKey=rowId;
    let vid=obj.rowData["form_vid"];
    let deptid=obj.rowData["department_id"];
    let is_open=obj.rowData["is_open"]?obj.rowData["is_open"]:false;
    if(this.formId==313){
      vid=286;
    }else if(this.formId==301){
      vid=273;
    }
    if(is_open == false){
      let updateqry="update radx_worklist set is_open=true where worklistid="+this.worklistid;
      this.formService.getFormData(updateqry).subscribe(res => {

      });
    } 
    let isParked=localStorage.getItem("linkname");
    let data;
    if(isParked == "parked"){
      data = JSON.stringify('frmid='+this.formId+'&deptid='+deptid+'&editrowid='+rowId+'&worklistid='+this.worklistid+'&processinstid='+this.processinstid+'&stageid='+this.stageid+'&isAddMode=false&vid='+vid+'&isView=false&isParked=true')
    }else{
      data = JSON.stringify('frmid='+this.formId+'&deptid='+deptid+'&editrowid='+rowId+'&worklistid='+this.worklistid+'&processinstid='+this.processinstid+'&stageid='+this.stageid+'&isAddMode=false&vid='+vid+'&isView=false')
    }    
    let encriptUrl = this.loginService.encriptDataUrl(data)
    this.router.navigateByUrl('/act-process-new?'+encriptUrl);
    // this.router.navigateByUrl('/act-process-new?frmid='+this.formId+'&deptid='+deptid+'&editrowid='+rowId+'&worklistid='+this.worklistid+'&processinstid='+this.processinstid+'&stageid='+this.stageid+'&isAddMode=false&vid='+vid+'&isView=false');
   
  }


  getDraftList(){
    var strQry=`select dr.draft_requestid,sys.varibale_name scheme_category,d.department_name department_id,dr.item_id,dr.scheme_title,
    case when dr.proposal_type ='1' then 'New'
    when dr.proposal_type ='2' then 'Ongoing'
    when dr.proposal_type ='1' then 'Continuing' end proposal_type,TO_CHAR(dr.createddate:: DATE, 'dd/mm/yyyy') application_date,form_id,form_vid,process_id,'[1,1]' as val 
    from radx_draft_requests dr left join app_tblsystemenum sys on dr.scheme_category=sys.id 
    left join z_entitymast_department_001 d on d.department_id=dr.department_id
    where dr.status_id=108 and dr.createdby=${this.sessionUrlPrams.ProfileId}  order by dr.createddate desc;`
     this.formService.getFormData(strQry).subscribe(res=>{      
      this.inboxColumndef=[
        {"field": "scheme_title","headerName": "Title of the Scheme","sortable": true,"filter": true,"resizable": true,"width": "450","wrapText": true,"autoHeight": true},
        {"field": "","headerName": "Request ID","sortable": true,"filter": true,"resizable": true,"width": "250","wrapText": true,"autoHeight": true},
        {"field": "department_id","headerName": "Department Name","sortable": true,"filter": true,"resizable": true,"width": "300","wrapText": true,"autoHeight": true},
        {"field": "application_date","headerName": "Application Date","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
      ]    
      this.columnActionStrip.push({field: "Edit", iconName: "edit"});
      this.inboxRows=res.rows;
      this.InboxLoaded = Promise.resolve(true);
     })
  }

  onProcesss(obj){    
    // console.log("obj==>",obj._rowData)
    let row=obj._rowData
    this.router.navigateByUrl('request-form?frmid='+row.form_id+'&editrowid='+row.item_id+'&vid='+row.form_vid+'&isAddMode=false');
  }

  viewproposal(obj){
    let rows=obj.rowData;
    let data  = JSON.stringify('frmid='+rows.form_id+'&deptid='+rows.deptid+'&editrowid='+rows.item_id+'&worklistid=5&processinstid='+rows.processinstanceid+'&stageid=5&isAddMode=false&vid='+rows.form_vid+'&isView=true')
    let encriptUrl = this.loginService.encriptDataUrl(data)
    this.router.navigateByUrl('/act-process-newx?'+encriptUrl);
  }

}
