import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-permission',
  templateUrl: './right-permission.component.html',
  styleUrls: ['./right-permission.component.css']
})
export class RightPermissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
