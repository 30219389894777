<div class="main">
    <aside>
      
        <div class="sidebar left fliph leftbg" id="leftmenu">
            <div class="user-panel btn-click leftbg" >
                <div class="image logoflx">
                      <!-- <a href="javascript:void(0)" id="showbr" class="button-left text-white" style="display: none;">	<i class="fa fa-bars fa-2x" ></i></a> -->
                      <a href="javascript:void(0)" id="showbr" class=" text-white webhide" [routerLink]="['/dashboardnew']"><img src="../../../assets/images/menulogo.svg" class="img-fluid"></a>

                    <img src="../../../assets/images/menu_btn.svg" class="fliphremove webhide" style="display: none;" id="menu-toggle"  *ngIf = "actorType=='1'" [routerLink]="['/dashboardnew']" alt="User Image">
                    <!-- <img src="../../../assets/images/landing/logo.svg" class="fliphremove webhide" style="display: none;" id="menu-toggle" *ngIf = "actorType=='2'" [routerLink]="['/company-dashboard']" alt="User Image">
                    <img src="../../../assets/images/landing/logo.svg" class="fliphremove webhide" style="display: none;" id="menu-toggle" *ngIf = "actorType=='4'" [routerLink]="['/nic-dashboard']" alt="User Image"> -->

					 <!-- <a href="javascript:void(0)" class="button-left webclose text-white"><i class="fa fa-times"></i></a> -->
                   
                     <a href="javascript:void(0)" id="closefliph" class="button-left btnnoflip text-white pull-right pr-2 fnt15"> <i class="fa fa-times "></i></a>
				
                    </div>
				
                
            </div>

            <div class="pull-left info logotxt mobshow1">
                <a  href="javascript:void(0)" class="button-left btnflip text-white px-3 pt-3 mt-1"><img  src="../../../assets/images/menu_btn.svg" class="widcstm"></a>
            </div>

            <ul class="list-sidebar bg-default pl-md-3">
                <li class="nodisplay"> <a title="Dashboard" (click)="gotoDashboard()" href="javascript:void(0)"> <span class="nav-label">My Task</span></a>
                </li>
                <!-- <li>
                    <a title="Automation" href="javascript:void(0)" class="collapsed" data-target="#mytask" data-toggle="collapse"> <i class="fa fa-circle-o"></i> <span class="nav-label">My Task</span> <span class="fa fa-chevron-left pull-right"></span>
                    </a>
                    <ul class="sub-menu collapse" id="mytask">
                        <h5 class="cstmmaster">My Task</h5>
                        <li class="" id="inbox"><a id="inbox" title="Inbox / Outbox" href="javascript:void(0)" (click)="Routing('inbox')" (click)="gotoDashboard()">Inbox</a></li>
                        <li class="" id="outbox"><a id="outbox" title="Inbox / Outbox" href="javascript:void(0)" (click)="Routing('outbox')">Outbox</a></li>
                        <li class="" id="sbmit" *ngIf="roleId==3"><a id="sbmit" title="Submitted Request" href="javascript:void(0)" (click)="Routing('drafts')">Draft</a></li>
                        <li class="" id="sbmitreq"><a id="sbmitreq" title="Submitted Request" href="javascript:void(0)" (click)="Routing('submitted')">Submitted Request</a>
                        <li class="" *ngIf="roleId == 14 || roleId == 33" id="commmit"><a id="commmit" title="Committee Meeting" href="javascript:void(0)" (click)="Routing('committee-meeting')">Committee Meeting</a>
                        <li class="" id="tracker"><a id="tracker" title="Status tracker " href="javascript:void(0)" (click)="Routing('status-tracker')">Status Tracker</a></li>
                        <li class="" *ngIf="department == 17 || department == 18" id="buffsheet" ><a id="buffsheet" title="Buff-sheet " href="javascript:void(0)" (click)="Routing('buff-sheet')">Buff-Sheet List</a></li>
                    </ul>
                    </li> -->

                    <!-- code start for dynamic menus-->
                    <li *ngFor="let lst of ModuleList; let i=index">
                        <a title="{{lst.menu_txt}}" href="javascript:void(0)" data-toggle="collapse" [attr.data-target]="'#' + removeSpace(lst.menu_txt)" class="collapsed">  <span class="nav-label"> {{lst.menu_txt}} </span> <span class="fa fa-chevron-left pull-right"></span>
                        </a>
                        <ul class="sub-menu collapse" id="{{removeSpace(lst.menu_txt)}}">
                            <!-- <h5 class="cstmmaster">{{lst.menu_txt}}</h5> -->
                            <ng-container *ngFor="let slist of lst.links">
                            <li class="dropdown-submenu drop_styl" >
                                <!-- <p>{{slist.submenu_txt}}{{lst.menu_id}}{{slist.submenu_id}}</p> -->
                                <ng-container *ngIf="lst.menu_id==slist.submenu_id">
                                    <ng-container *ngFor="let sublist of slist.slink">
                                        <a title="{{sublist.form_link_name}}" href="javascript:void(0)" (click)="gotoFormDesignPage(sublist.form_id,sublist.form_template,sublist.view_type,sublist.view_id,sublist.child_form_ids,sublist.is_attachment,sublist.form_link,sublist.is_custom_form,sublist.is_table_required,conversionEncryptOutput,sublist.form_view_type)">{{sublist.form_link_name}}</a>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="lst.menu_id != slist.submenu_id">
                                    <a class="before-sl">{{slist.submenu_txt}}</a>
                                    <ul class="dropdown-menu next-child-menu">
                                    <ng-container *ngFor="let sublist of slist.slink">
                                        
                                          
                                            <li>  <a class="dropdown-item"  href="javascript:void(0)" (click)="gotoFormDesignPage(sublist.form_id,sublist.form_template,sublist.view_type,sublist.view_id,sublist.child_form_ids,sublist.is_attachment,sublist.form_link,sublist.is_custom_form,sublist.is_table_required,conversionEncryptOutput,sublist.form_view_type)"> {{sublist.form_link_name}} </a></li>
                                       
                                    </ng-container>
                                </ul>
                                </ng-container>
                            </li>
                        </ng-container>
                        </ul>
                    </li>
                    
                    <li *ngIf="actorType == '2'">
                            <a title="Report" (click)="changePage1()">
                                <!-- <i class="fa fa-circle-o" style="color: aliceblue"></i> -->
                                <span class="nav-label" style="color: aliceblue">Verification</span>
                                <span class="fa fa-chevron-left pull-right"></span>
                            </a>
                    </li>
                     

                    <!-- code start for mobile menus for notification , messages and logout-->

                    <!-- code end for mobile menus for notification , messages and logout-->
            </ul>
        </div>
    </aside>
</div>