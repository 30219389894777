import {Component,EventEmitter,Input,OnChanges,OnInit,Output,ViewEncapsulation,SimpleChanges} from "@angular/core";
import { formatRange } from "@fullcalendar/common";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {FormService} from '../../_services/form.service';
import { SessionUrlPrams } from "../../field.interface";
@Component({
  selector: 'app-user-rule',
  templateUrl: './user-rule.component.html',
  styleUrls: ['./user-rule.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserRuleComponent implements OnInit {
  @Input() formId:any;
  @Input() resetFilter:any;
  @Output() applyEvent = new EventEmitter<any>();
  criteriaList:any[]=[{ "type":"object","operator":"and","list":[
    {"type":"row","id":1,"field_id":null,"conditionList":[],"condition_id":null,"control_type":1,"ddl":[],"value":null,"month":null,"days":null}
  ]
  }]
  allFormFields:any[]=[];
  conditionList:any[]=[];
  tmplst:any[]=[];
  datechk:any;
  dropdownSettings:any={};
  tmpFormId:any;

  dropdownQuery: string="";
  tempTableName: string;
  tempPrimaryKey: string;
  arrProperty: any[]=[];
  isFilterJson: boolean=false;
  @Input() SessionUrlPrams:SessionUrlPrams;
  constructor(private formService:FormService) { }
  ngOnInit(): void {
    
    this.getFieldsDetail();
    this.getConditionList();
    
    this.dropdownSettings={
      singleSelection: false,
      idField: 'id',
      textField: 'labl',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll:false
    }
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    
      if(changes.resetFilter?(changes.resetFilter.currentValue ==true):false)
      {
        this.resetCriterisFilter();
      }
      if(changes.formId?(this.tmpFormId!=changes.formId.currentValue):false)
      {
        this.tmpFormId=changes.formId.currentValue;
        this.resetCriterisFilter();
        this.getFieldsDetail();
      }
  }
  resetCriterisFilter()
  {
    this.criteriaList=[{ "type":"object","operator":"and","list":[
      {"type":"row","id":1,"field_id":null,"conditionList":[],"condition_id":null,"control_type":1,"ddl":[],"value":null,"month":null,"days":null}
    ]
    }]
  }
 


  getFieldsDetail()
  {
    var strQry=`select field_id,field_caption,field_name,ct.data_type_category,control_type,reference_form_id,display_field,value_field,dynamiclist from app_form_field_details fd
    left join app_control_type ct on ct.control_id=fd.control_type
    where form_id=${this.formId} and control_type not in(16,17); select af.form_display_json,afvd.field_json from app_forms af
    left join app_form_view_details afvd on af.form_id= afvd.form_id
    where afvd.view_id=${this.SessionUrlPrams.vid}`;
     this.formService.getFormData(strQry).subscribe(res => {
      let viewFieldJson=[];
      this.allFormFields=[]; 
      this.allFormFields=res[0].rows;      
      let result=res[1].rows;      
      if(result.length>0){      
        let formDisplayJson=JSON.parse(result[0]["form_display_json"]);
        viewFieldJson=result[0]["field_json"]?JSON.parse(result[0]["field_json"]):[];        
        this.allFormFields=this.allFormFields.filter(d=> (viewFieldJson.filter(x=>x.field_id == d.field_id).length>0));
        this.arrProperty=[];
        for(let c=0; c<formDisplayJson.length; c++){
          let fieldConfig=formDisplayJson[c]["FieldConfig"]?(formDisplayJson[c]["FieldConfig"]):[];       
          for(let d=0; d<fieldConfig.length; d++){           
              this.arrProperty.push({
                "id":fieldConfig[d]["name"],
                "filter_json":fieldConfig[d]["filter_json"]?fieldConfig[d]["filter_json"]:null
              })
          }
        }        
      }        
    })   
  }
  getConditionList()
  {
      var condQry=`select condition_id,cd.condition_text,cd.symbol,data_type_id,base_data_type from app_condition_data_type_mapping cdt
      left join app_condition cd on cd.id=cdt.condition_id
      left join app_base_data_type dt on dt.base_data_type_id=cdt.data_type_id`
      this.formService.getFormData(condQry).subscribe(res=>{
        // console.log("conditionList==>",res.rows)
        this.conditionList=res.rows;
      })
  }

  onAddCondition(list)
  {
     list.push({"type":"row","id":(list.length)+1,"field_id":null,"condition_id":null,"conditionList":[],"control_type":1,"ddl":[],"value":null,"month":null,"days":null});
  }

  onAddSubCondition(list)
  {
    list.push({"type":"object","id":list.length+1,"operator":"and","list":[{"type":"row","id":1,"field_id":null,"condition_id":null,"conditionList":[],"control_type":1,"ddl":[],"value":null,"month":null,"days":null}]});
  }
  onRemove(obj,id)
  {
    var tmplist=obj.list;
    obj.list=tmplist.filter(x=>x.id!=id);
  }
  
  oncriteriaFieldChange(obj){
    //this.spinnerService.show();
      var condList=[];
      var control_type=null;
      var table_name='';
      var primary_key='';
      var display_name='';
      var dynamicList=[];
      if(obj.field_id!=null && obj.field_id!=undefined && obj.field_id!=0 && obj.field_id!="null" && obj.field_id!="undefined")
      {
        var selField=this.allFormFields.filter(x=>x.field_name==obj.field_id);
        var data_typ_id=null;
        
        if(selField.length>0)
        {
           data_typ_id=selField[0].data_type_category;
           control_type=selField[0].control_type;
           if(selField[0].reference_form_id != null && selField[0].reference_form_id != undefined && selField[0].reference_form_id != ""){
            table_name=selField[0].reference_form_id.split(",")[0];
            }else{
              table_name="";
            }
           primary_key=selField[0].value_field;
           display_name=selField[0].display_field;
           dynamicList=selField[0].dynamiclist?(eval(selField[0].dynamiclist)):[];
        }
        obj.control_type=control_type?control_type:1;
        obj.ddl=[];
        obj.condition_id=null;
        obj.value=null;
        obj.month=null;
        obj.days=null;
        if(control_type==14 || control_type==15 || control_type==19 || control_type==22)
        {
          this.tmplst=[];
          var dname='';
          var arr=eval(display_name);
          if(arr.length>0)
          {
            for(let g=0;g<arr.length;g++)
            {
              dname=dname+arr[g].id+",' ',";
            }
            dname=dname.substring(0,dname.length-5);
          }

          let filter_json="",query="";
          let filterArray=this.arrProperty.filter(d=>d.id == obj.field_id)
          if(filterArray.length>0){
            let flterJson= filterArray[0]["filter_json"]?filterArray[0]["filter_json"]:null;
            if(flterJson != null){
               filter_json=this.formService.getConditionQueryByJson(flterJson,this.SessionUrlPrams)             
            }
          }          
          if(filter_json != "" && filter_json != undefined && filter_json != null){
            query=`select (${primary_key})id,concat(${dname})labl from ${table_name} a where ${filter_json} `
            this.isFilterJson=true;
          }else{
            query=`select (${primary_key})id,concat(${dname})labl from ${table_name} `
            this.isFilterJson=false
          }

          // this.dropdownQuery=`select (${primary_key})id,concat(${dname})labl from ${table_name}`;
          this.dropdownQuery=query;
          this.tempTableName=dname;
          this.tempPrimaryKey=primary_key;

          this.formService.getFormData(query+` order by ${primary_key} limit 100;`).subscribe(res=>{
            this.tmplst=[];
            this.tmplst=res.rows;
          })
        }
        else if(control_type==5 || control_type==10 || control_type==18 )
        {
          this.tmplst=[];
          if(dynamicList.length>0)
          {
            for(let ob of dynamicList)
            {
              this.tmplst.push({"id":ob.text,"labl":ob.binding});
            }
          }
        }
        else if(control_type==6)
        {
          obj.value=false;
        }
          condList=this.conditionList.filter(x=>x.data_type_id==data_typ_id);
      }

      obj.conditionList=condList;
  }

  onCriteriaConditionChange(obj)
  {
    obj.ddl=this.tmplst;
  }

  apply()
   {
     try{
       var resolveList='';
      if(this.criteriaList.length>0)
      {
           if(this.criteriaList[0].list.length>0)
           {
              for(let i=0;i< this.criteriaList[0].list.length;i++)
              {
                if(i==0)
                {
                  resolveList=resolveList+" ( ";
                }
                var obj=this.criteriaList[0].list[i];
                 if(obj.type=='row')
                 {
                  if(obj.condition_id==1)
                  resolveList=resolveList+" lower(a."+obj.field_id+") "+" "+"like"+" "+"lower('%"+obj.value+"%') "+this.criteriaList[0].operator
                  else if(obj.condition_id==2)
                    resolveList=resolveList+" lower(a."+obj.field_id+") "+" "+"not like"+" "+"lower('%"+obj.value+"%') "+this.criteriaList[0].operator
                  else if(obj.condition_id==12 || obj.condition_id==13)
                  resolveList=resolveList+" lower(a."+obj.field_id+") "+" "+"like"+" "+((obj.condition_id==12)?("lower('"+obj.value+"%') "):("lower('%"+obj.value+"') "))+this.criteriaList[0].operator
                    else if(obj.condition_id==3 || obj.condition_id==4)
                  {
                    if(obj.control_type==1 || obj.control_type==2 || obj.control_type==3 || obj.control_type==11 || obj.control_type==12)
                    resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" =":" !=")+" '"+obj.value+"' "+this.criteriaList[0].operator
                    else if(obj.control_type==10 ||  obj.control_type==14 || obj.control_type==15 ||  obj.control_type==19 || obj.control_type==22)
                    {
                      var ids='';
                      if(obj.value.length>0)
                      {
                        for(let ob of obj.value){ ids=ids+((obj.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                        ids=ids.substring(0,ids.length-1);
                      }
                      resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" in":" not in")+" ("+ids+") "+this.criteriaList[0].operator 
                    }
                    else if(obj.control_type==4 || obj.control_type==5 || obj.control_type==6 || obj.control_type==9 || obj.control_type==13)
                    {
                        resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" =":" !=")+" "+obj.value+" "+this.criteriaList[0].operator
                    }
                    
                  }
                  else if(obj.condition_id==5 || obj.condition_id==6)
                  {
                    if(obj.control_type==7 || obj.control_type==8)
                      resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==5)?" <":" >")+" '"+obj.value+"'::date "+this.criteriaList[0].operator
                    else
                      resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==5)?" < ":" > ")+obj.value+" "+this.criteriaList[0].operator
                  }
                  else if(obj.condition_id==7 || obj.condition_id==8 || obj.condition_id==9)
                  {
                    if(obj.control_type==7 || obj.control_type==8)
                    {
                      if(obj.condition_id==7)
                        resolveList=resolveList+" a."+obj.field_id+"::date = '"+obj.value+"'::date "+this.criteriaList[0].operator
                      else if(obj.condition_id==8 || obj.condition_id==9)
                        resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==8)?" > '":" < '")+obj.value+"'::date "+this.criteriaList[0].operator
                    } 
                  }
                 }
                 else
                 {
                   if(obj.list.length>0)
                   {
                    for(let j=0;j< obj.list.length;j++)
                    { 
                       if(j==0)
                       {
                         resolveList=resolveList+" ( "
                       }
                       var obj1=  obj.list[j];
                       if(obj1.type=='row')
                       {
                        if(obj1.condition_id==1)
                        resolveList=resolveList+" lower(a."+obj1.field_id+") "+" "+"like"+" "+"lower('%"+obj1.value+"%') "+obj.operator
                        else if(obj1.condition_id==2)
                          resolveList=resolveList+" lower(a."+obj1.field_id+") "+" "+"not like"+" "+"lower('%"+obj1.value+"%') "+obj.operator
                          else if(obj1.condition_id==12 || obj1.condition_id==13)
                          resolveList=resolveList+" lower(a."+obj1.field_id+") "+" "+"like"+" "+((obj1.condition_id==12)?("lower('"+obj1.value+"%') "):("lower('%"+obj1.value+"') "))+obj.operator
                          else if(obj1.condition_id==3 || obj1.condition_id==4)
                        {
                          if(obj1.control_type==1 || obj1.control_type==2 || obj1.control_type==3 || obj1.control_type==11 || obj1.control_type==12)
                          resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==3)?" =":" !=")+" '"+obj1.value+"' "+obj.operator
                          else if(obj1.control_type==10 ||  obj1.control_type==14 || obj1.control_type==15 ||  obj1.control_type==19 || obj1.control_type==22)
                          {
                            var ids='';
                            if(obj1.value.length>0)
                            {
                              for(let ob of obj1.value){ ids=ids+((obj1.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                              ids=ids.substring(0,ids.length-1);
                            }
                            resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==3)?" in":" not in")+" ("+ids+") "+obj.operator 
                          }
                          else if(obj1.control_type==4 || obj1.control_type==5 || obj1.control_type==6 || obj1.control_type==9 || obj1.control_type==13)
                          {
                              resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==3)?" =":" !=")+" "+obj1.value+" "+obj.operator
                          }
                        }
                        else if(obj1.condition_id==5 || obj1.condition_id==6)
                        {
                          if(obj1.control_type==7 || obj1.control_type==8)
                            resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==5)?" <":" >")+" '"+obj1.value+"'::date "+obj.operator
                          else
                            resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==5)?" < ":" > ")+obj1.value+" "+obj.operator
                        }
                        else if(obj1.condition_id==7 || obj1.condition_id==8 || obj1.condition_id==9)
                        {
                          if(obj1.control_type==7 || obj1.control_type==8)
                          {
                            if(obj1.condition_id==7)
                              resolveList=resolveList+" a."+obj1.field_id+"::date = '"+obj1.value+"'::date "+obj.operator
                            else if(obj1.condition_id==8 || obj1.condition_id==9)
                              resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==8)?" > '":" < '")+obj1.value+"'::date "+obj.operator
                          } 
                        }
                       }
                       else
                       {
                          if(obj1.list.length>0)
                          {
                            for(let k=0;k< obj1.list.length;k++)
                            {
                              if(k==0)
                              {
                                resolveList=resolveList+" ( "
                              }
                              var obj2=obj1.list[k];
                              if(obj2.type=='row')
                              {
                                if(obj2.condition_id==1)
                                resolveList=resolveList+" lower(a."+obj2.field_id+") "+" "+"like"+" "+"lower('%"+obj2.value+"%') "+obj1.operator
                                else if(obj2.condition_id==2)
                                  resolveList=resolveList+" lower(a."+obj2.field_id+") "+" "+"not like"+" "+"lower('%"+obj2.value+"%') "+obj1.operator
                                  else if(obj2.condition_id==12 || obj2.condition_id==13)
                                  resolveList=resolveList+" lower(a."+obj2.field_id+") "+" "+"like"+" "+((obj2.condition_id==12)?("lower('"+obj2.value+"%') "):("lower('%"+obj2.value+"') "))+obj1.operator
                                  else if(obj2.condition_id==3 || obj2.condition_id==4)
                                {
                                  if(obj2.control_type==1 || obj2.control_type==2 || obj2.control_type==3 || obj2.control_type==11 || obj2.control_type==12)
                                  resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==3)?" =":" !=")+" '"+obj2.value+"' "+obj1.operator
                                  else if(obj2.control_type==10 ||  obj2.control_type==14 || obj2.control_type==15 || obj2.control_type==19 || obj2.control_type==22)
                                  {
                                    var ids='';
                                    if(obj2.value.length>0)
                                    {
                                      for(let ob of obj2.value){ ids=ids+((obj2.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                      ids=ids.substring(0,ids.length-1);
                                    }
                                    resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==3)?" in":" not in")+" ("+ids+") "+obj1.operator 
                                  }
                                  else if(obj2.control_type==4 || obj2.control_type==5 || obj2.control_type==6 || obj2.control_type==9 || obj2.control_type==13)
                                  {
                                      resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==3)?" =":" !=")+" "+obj2.value+" "+obj1.operator
                                  }
                                }
                                else if(obj2.condition_id==5 || obj2.condition_id==6)
                                {
                                  if(obj2.control_type==7 || obj2.control_type==8)
                                    resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==5)?" <":" >")+" '"+obj2.value+"'::date "+obj1.operator
                                  else
                                    resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==5)?" < ":" > ")+obj2.value+" "+obj1.operator
                                }
                                else if(obj2.condition_id==7 || obj2.condition_id==8 || obj2.condition_id==9)
                                {
                                  if(obj2.control_type==7 || obj2.control_type==8)
                                  {
                                    if(obj2.condition_id==7)
                                      resolveList=resolveList+" a."+obj2.field_id+"::date = '"+obj2.value+"'::date "+obj1.operator
                                    else if(obj2.condition_id==8 || obj2.condition_id==9)
                                      resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==8)?" > '":" < '")+obj2.value+"'::date "+obj1.operator
                                  } 
                                }
                              }
                              else
                              {
                                if(obj2.list.length>0)
                                {
                                  for(let l=0;l< obj2.list.length;l++)
                                  {
                                    if(l==0)
                                    {
                                      resolveList=resolveList+" ( "
                                    }
                                    var obj3=obj2.list[l];
                                    if(obj3.type=='row')
                                    {
                                      if(obj3.condition_id==1)
                                      resolveList=resolveList+" lower(a."+obj3.field_id+") "+" "+"like"+" "+"lower('%"+obj3.value+"%') "+obj2.operator
                                      else if(obj3.condition_id==2)
                                        resolveList=resolveList+" lower(a."+obj3.field_id+") "+" "+"not like"+" "+"lower('%"+obj3.value+"%') "+obj2.operator
                                        else if(obj3.condition_id==12 || obj3.condition_id==13)
                                        resolveList=resolveList+" lower(a."+obj3.field_id+") "+" "+"like"+" "+((obj3.condition_id==12)?("lower('"+obj3.value+"%') "):("lower('%"+obj3.value+"') "))+obj2.operator
                                        else if(obj3.condition_id==3 || obj3.condition_id==4)
                                      {
                                        if(obj3.control_type==1 || obj3.control_type==2 || obj3.control_type==3 || obj3.control_type==11 || obj3.control_type==12)
                                        resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==3)?" =":" !=")+" '"+obj3.value+"' "+obj2.operator
                                        else if(obj3.control_type==10 ||  obj3.control_type==14 || obj3.control_type==15 ||  obj3.control_type==19 || obj3.control_type==22)
                                        {
                                          var ids='';
                                          if(obj3.value.length>0)
                                          {
                                            for(let ob of obj3.value){ ids=ids+((obj3.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                            ids=ids.substring(0,ids.length-1);
                                          }
                                          resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==3)?" in":" not in")+" ("+ids+") "+obj2.operator 
                                        }
                                        else if(obj3.control_type==4 || obj3.control_type==5 || obj3.control_type==6 || obj3.control_type==9 || obj3.control_type==13)
                                        {
                                            resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==3)?" =":" !=")+" "+obj3.value+" "+obj2.operator
                                        }
                                      }
                                      else if(obj3.condition_id==5 || obj3.condition_id==6)
                                      {
                                        if(obj3.control_type==7 || obj3.control_type==8)
                                          resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==5)?" <":" >")+" '"+obj3.value+"'::date "+obj2.operator
                                        else
                                          resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==5)?" < ":" > ")+obj3.value+" "+obj2.operator
                                      }
                                      else if(obj3.condition_id==7 || obj3.condition_id==8 || obj3.condition_id==9)
                                      {
                                        if(obj3.control_type==7 || obj3.control_type==8)
                                        {
                                          if(obj3.condition_id==7)
                                            resolveList=resolveList+" a."+obj3.field_id+"::date = '"+obj3.value+"'::date "+obj2.operator
                                          else if(obj3.condition_id==8 || obj3.condition_id==9)
                                            resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==8)?" > '":" < '")+obj3.value+"'::date "+obj2.operator
                                        } 
                                      }
                                    }
                                    else
                                    {
                                      if(obj3.list.length>0)
                                      {
                                        for(let m=0;m< obj3.list.length;m++)
                                        {
                                          if(m==0)
                                          {
                                            resolveList=resolveList+" ( "
                                          }
                                          var obj4=obj3.list[m];
                                          if(obj4.type=='row')
                                          {
                                            if(obj4.condition_id==1)
                                            resolveList=resolveList+" lower(a."+obj4.field_id+") "+" "+"like"+" "+"lower('%"+obj4.value+"%') "+obj3.operator
                                            else if(obj4.condition_id==2)
                                              resolveList=resolveList+" lower(a."+obj4.field_id+") "+" "+"not like"+" "+"lower('%"+obj4.value+"%') "+obj3.operator
                                              else if(obj4.condition_id==12 || obj4.condition_id==13)
                                              resolveList=resolveList+" lower(a."+obj4.field_id+") "+" "+"like"+" "+((obj4.condition_id==12)?("lower('"+obj4.value+"%') "):("lower('%"+obj4.value+"') "))+obj3.operator
                                              else if(obj4.condition_id==3 || obj4.condition_id==4)
                                            {
                                              if(obj4.control_type==1 || obj4.control_type==2 || obj4.control_type==3 || obj4.control_type==11 || obj4.control_type==12)
                                              resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==3)?" =":" !=")+" '"+obj4.value+"' "+obj3.operator
                                              else if(obj4.control_type==10 ||  obj4.control_type==14 || obj4.control_type==15 ||  obj4.control_type==19 || obj4.control_type==22)
                                              {
                                                var ids='';
                                                if(obj4.value.length>0)
                                                {
                                                  for(let ob of obj4.value){ ids=ids+((obj4.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                  ids=ids.substring(0,ids.length-1);
                                                }
                                                resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==3)?" in":" not in")+" ("+ids+") "+obj3.operator 
                                              }
                                              else if(obj4.control_type==4 || obj4.control_type==5 || obj4.control_type==6 || obj4.control_type==9 || obj4.control_type==13)
                                              {
                                                  resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==3)?" =":" !=")+" "+obj4.value+" "+obj3.operator
                                              }
                                            }
                                            else if(obj4.condition_id==5 || obj4.condition_id==6)
                                            {
                                              if(obj4.control_type==7 || obj4.control_type==8)
                                                resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==5)?" <":" >")+" '"+obj4.value+"'::date "+obj3.operator
                                              else
                                                resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==5)?" < ":" > ")+obj4.value+" "+obj3.operator
                                            }
                                            else if(obj4.condition_id==7 || obj4.condition_id==8 || obj4.condition_id==9)
                                            {
                                              if(obj4.control_type==7 || obj4.control_type==8)
                                              {
                                                if(obj4.condition_id==7)
                                                  resolveList=resolveList+" a."+obj4.field_id+"::date = '"+obj4.value+"'::date "+obj3.operator
                                                else if(obj4.condition_id==8 || obj4.condition_id==9)
                                                  resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==8)?" > '":" < '")+obj4.value+"'::date "+obj3.operator
                                              } 
                                            }
                                          }
                                          else
                                          {
                                            if(obj4.list.length>0)
                                            {
                                              for(let n=0;n< obj4.list.length;n++)
                                              {
                                                if(n==0)
                                                {
                                                  resolveList=resolveList+" ( "
                                                }
                                                var obj5=obj4.list[n];
                                                if(obj5.type=='row')
                                                {
                                                  if(obj5.condition_id==1)
                                                  resolveList=resolveList+" lower(a."+obj5.field_id+") "+" "+"like"+" "+"lower('%"+obj5.value+"%') "+obj4.operator
                                                  else if(obj5.condition_id==2)
                                                    resolveList=resolveList+" lower(a."+obj5.field_id+") "+" "+"not like"+" "+"lower('%"+obj5.value+"%') "+obj4.operator
                                                    else if(obj5.condition_id==12 || obj5.condition_id==13)
                                              resolveList=resolveList+" lower(a."+obj5.field_id+") "+" "+"like"+" "+((obj5.condition_id==12)?("lower('"+obj5.value+"%') "):("lower('%"+obj5.value+"') "))+obj4.operator
                                                  else if(obj5.condition_id==3 || obj5.condition_id==4)
                                                  {
                                                    if(obj5.control_type==1 || obj5.control_type==2 || obj5.control_type==3 || obj5.control_type==11 || obj5.control_type==12)
                                                    resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==3)?" =":" !=")+" '"+obj5.value+"' "+obj4.operator
                                                    else if(obj5.control_type==10 ||  obj5.control_type==14 || obj5.control_type==15 ||  obj5.control_type==19 || obj5.control_type==22)
                                                    {
                                                      var ids='';
                                                      if(obj5.value.length>0)
                                                      {
                                                        for(let ob of obj5.value){ ids=ids+((obj5.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                        ids=ids.substring(0,ids.length-1);
                                                      }
                                                      resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==3)?" in":" not in")+" ("+ids+") "+obj4.operator 
                                                    }
                                                    else if(obj5.control_type==4 || obj5.control_type==5 || obj5.control_type==6 || obj5.control_type==9 || obj5.control_type==13)
                                                    {
                                                        resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==3)?" =":" !=")+" "+obj5.value+" "+obj4.operator
                                                    }
                                                  }
                                                  else if(obj5.condition_id==5 || obj5.condition_id==6)
                                                  {
                                                    if(obj5.control_type==7 || obj5.control_type==8)
                                                      resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==5)?" <":" >")+" '"+obj5.value+"'::date "+obj4.operator
                                                    else
                                                      resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==5)?" < ":" > ")+obj5.value+" "+obj4.operator
                                                  }
                                                  else if(obj5.condition_id==7 || obj5.condition_id==8 || obj5.condition_id==9)
                                                  {
                                                    if(obj5.control_type==7 || obj5.control_type==8)
                                                    {
                                                      if(obj5.condition_id==7)
                                                        resolveList=resolveList+" a."+obj5.field_id+"::date = '"+obj5.value+"'::date "+obj4.operator
                                                      else if(obj5.condition_id==8 || obj5.condition_id==9)
                                                        resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==8)?" > '":" < '")+obj5.value+"'::date "+obj4.operator
                                                    } 
                                                  }
                                                }
                                                else
                                                {
                                                  if(obj5.list.length>0)
                                                  {
                                                    for(let o=0;o< obj5.list.length;o++)
                                                    {
                                                      if(o==0)
                                                      {
                                                        resolveList=resolveList+" ( "
                                                      }
                                                      var obj6=obj5.list[o];
                                                      if(obj6.type=='row')
                                                      {
                                                        if(obj6.condition_id==1)
                                                            resolveList=resolveList+" lower(a."+obj6.field_id+") "+" "+"like"+" "+"lower('%"+obj6.value+"%') "+obj5.operator
                                                          else if(obj6.condition_id==2)
                                                            resolveList=resolveList+" lower(a."+obj6.field_id+") "+" "+"not like"+" "+"lower('%"+obj6.value+"%') "+obj5.operator
                                                            else if(obj6.condition_id==12 || obj6.condition_id==13)
                                                            resolveList=resolveList+" lower(a."+obj6.field_id+") "+" "+"like"+" "+((obj6.condition_id==12)?("lower('"+obj6.value+"%') "):("lower('%"+obj6.value+"') "))+obj5.operator
                                                            else if(obj6.condition_id==3 || obj6.condition_id==4)
                                                          {
                                                            if(obj6.control_type==1 || obj6.control_type==2 || obj6.control_type==3 || obj6.control_type==11 || obj6.control_type==12)
                                                            resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==3)?" =":" !=")+" '"+obj6.value+"' "+obj5.operator
                                                            else if(obj6.control_type==10 ||  obj6.control_type==14 || obj6.control_type==15 ||  obj6.control_type==19 || obj6.control_type==22)
                                                            {
                                                              var ids='';
                                                              if(obj6.value.length>0)
                                                              {
                                                                for(let ob of obj6.value){ ids=ids+((obj6.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                                ids=ids.substring(0,ids.length-1);
                                                              }
                                                              resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==3)?" in":" not in")+" ("+ids+") "+obj5.operator 
                                                            }
                                                            else if(obj6.control_type==4 || obj6.control_type==5 || obj6.control_type==6 || obj6.control_type==9 || obj6.control_type==13)
                                                            {
                                                                resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==3)?" =":" !=")+" "+obj6.value+" "+obj5.operator
                                                            }
                                                          }
                                                          else if(obj6.condition_id==5 || obj6.condition_id==6)
                                                          {
                                                            if(obj6.control_type==7 || obj6.control_type==8)
                                                              resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==5)?" <":" >")+" '"+obj6.value+"'::date "+obj5.operator
                                                            else
                                                              resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==5)?" < ":" > ")+obj6.value+" "+obj5.operator
                                                          }
                                                          else if(obj6.condition_id==7 || obj6.condition_id==8 || obj6.condition_id==9)
                                                          {
                                                            if(obj6.control_type==7 || obj6.control_type==8)
                                                            {
                                                              if(obj6.condition_id==7)
                                                                resolveList=resolveList+" a."+obj6.field_id+"::date = '"+obj6.value+"'::date "+obj5.operator
                                                              else if(obj6.condition_id==8 || obj6.condition_id==9)
                                                                resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==8)?" > '":" < '")+obj6.value+"'::date "+obj5.operator
                                                            } 
                                                          }
                                                      }
                                                      else
                                                      {
                                                        if(obj6.list.length>0)
                                                        {
                                                          for(let p=0;p< obj6.list.length;p++)
                                                          {
                                                            if(p==0)
                                                            {
                                                              resolveList=resolveList+" ( "
                                                            }
                                                            var obj7=obj6.list[p];
                                                            if(obj7.type=='row')
                                                            {
                                                                if(obj7.condition_id==1)
                                                                resolveList=resolveList+" lower(a."+obj7.field_id+") "+" "+"like"+" "+"lower('%"+obj7.value+"%') "+obj6.operator
                                                              else if(obj7.condition_id==2)
                                                                resolveList=resolveList+" lower(a."+obj7.field_id+") "+" "+"not like"+" "+"lower('%"+obj7.value+"%') "+obj6.operator
                                                                else if(obj7.condition_id==12 || obj7.condition_id==13)
                                                                resolveList=resolveList+" lower(a."+obj7.field_id+") "+" "+"like"+" "+((obj7.condition_id==12)?("lower('"+obj7.value+"%') "):("lower('%"+obj7.value+"') "))+obj6.operator
                                                              else if(obj7.condition_id==3 || obj7.condition_id==4)
                                                              {
                                                                if(obj7.control_type==1 || obj7.control_type==2 || obj7.control_type==3 || obj7.control_type==11 || obj7.control_type==12)
                                                                resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==3)?" =":" !=")+" '"+obj7.value+"' "+obj6.operator
                                                                else if(obj7.control_type==10 ||  obj7.control_type==14 || obj7.control_type==15 ||  obj7.control_type==19 || obj7.control_type==22)
                                                                {
                                                                  var ids='';
                                                                  if(obj7.value.length>0)
                                                                  {
                                                                    for(let ob of obj7.value){ ids=ids+((obj7.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                                    ids=ids.substring(0,ids.length-1);
                                                                  }
                                                                  resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==3)?" in":" not in")+" ("+ids+") "+obj6.operator 
                                                                }
                                                                else if(obj7.control_type==4 || obj7.control_type==5 || obj7.control_type==6 || obj7.control_type==9 || obj7.control_type==13)
                                                                {
                                                                    resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==3)?" =":" !=")+" "+obj7.value+" "+obj6.operator
                                                                }
                                                              }
                                                              else if(obj7.condition_id==5 || obj7.condition_id==6)
                                                              {
                                                                if(obj7.control_type==7 || obj7.control_type==8)
                                                                  resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==5)?" <":" >")+" '"+obj7.value+"'::date "+obj6.operator
                                                                else
                                                                  resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==5)?" < ":" > ")+obj7.value+" "+obj6.operator
                                                              }
                                                              else if(obj7.condition_id==7 || obj7.condition_id==8 || obj7.condition_id==9)
                                                              {
                                                                if(obj7.control_type==7 || obj7.control_type==8)
                                                                {
                                                                  if(obj7.condition_id==7)
                                                                    resolveList=resolveList+" a."+obj7.field_id+"::date = '"+obj7.value+"'::date "+obj6.operator
                                                                  else if(obj7.condition_id==8 || obj7.condition_id==9)
                                                                    resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==8)?" > '":" < '")+obj7.value+"'::date "+obj6.operator
                                                                } 
                                                              }
                                                            }
                                                            else
                                                            {
                                                              if(obj7.list.length>0)
                                                              {
                                                                for(let q=0;q< obj7.list.length;q++)
                                                                {
                                                                  if(q==0)
                                                                  {
                                                                    resolveList=resolveList+" ( "
                                                                  }
                                                                  var obj8=obj7.list[q];
                                                                  if(obj8.type=='row')
                                                                  {
                                                                    if(obj8.condition_id==1)
                                                                      resolveList=resolveList+" lower(a."+obj8.field_id+") "+" "+"like"+" "+"lower('%"+obj8.value+"%') "+obj7.operator
                                                                    else if(obj8.condition_id==2)
                                                                      resolveList=resolveList+" lower(a."+obj8.field_id+") "+" "+"not like"+" "+"lower('%"+obj8.value+"%') "+obj7.operator
                                                                      else if(obj8.condition_id==12 || obj8.condition_id==13)
                                                                      resolveList=resolveList+" lower(a."+obj8.field_id+") "+" "+"like"+" "+((obj8.condition_id==12)?("lower('"+obj8.value+"%') "):("lower('%"+obj8.value+"') "))+obj7.operator
                                                                      else if(obj8.condition_id==3 || obj8.condition_id==4)
                                                                    {
                                                                      if(obj8.control_type==1 || obj8.control_type==2 || obj8.control_type==3 || obj8.control_type==11 || obj8.control_type==12)
                                                                       resolveList=resolveList+" lower(a."+obj8.field_id+") "+((obj8.condition_id==3)?" =":" !=")+" lower('%"+obj8.value+"%') "+obj7.operator
                                                                       else if(obj8.control_type==10 ||  obj8.control_type==14 || obj8.control_type==15 ||  obj8.control_type==19 || obj8.control_type==22)
                                                                       {
                                                                         var ids='';
                                                                         if(obj8.value.length>0)
                                                                         {
                                                                           for(let ob of obj8.value){ ids=ids+((obj8.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                                           ids=ids.substring(0,ids.length-1);
                                                                         }
                                                                         resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==3)?" in":" not in")+" ("+ids+") "+obj7.operator 
                                                                       }
                                                                       else if(obj8.control_type==4 || obj8.control_type==5 || obj8.control_type==6 || obj8.control_type==9 || obj8.control_type==13)
                                                                       {
                                                                           resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==3)?" =":" !=")+" "+obj8.value+" "+obj7.operator
                                                                       }
                                                                    }
                                                                    else if(obj8.condition_id==5 || obj8.condition_id==6)
                                                                    {
                                                                       if(obj8.control_type==7 || obj8.control_type==8)
                                                                        resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==5)?" <":" >")+" '"+obj8.value+"'::date "+obj7.operator
                                                                       else
                                                                        resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==5)?" < ":" > ")+obj8.value+" "+obj7.operator
                                                                    }
                                                                    else if(obj8.condition_id==7 || obj8.condition_id==8 || obj8.condition_id==9)
                                                                    {
                                                                      if(obj8.control_type==7 || obj8.control_type==8)
                                                                      {
                                                                        if(obj8.condition_id==7)
                                                                          resolveList=resolveList+" a."+obj8.field_id+"::date = '"+obj8.value+"'::date "+obj7.operator
                                                                        else if(obj8.condition_id==8 || obj8.condition_id==9)
                                                                          resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==8)?" > '":" < '")+obj8.value+"'::date "+obj7.operator
                                                                      } 
                                                                    }
                                                                  }     
                                                                }
                                                                resolveList=resolveList.substring(0,resolveList.length-((obj7.operator=='and')?3:2))+" ) "+obj6.operator      
                                                              }
                                                            }
                                                          }
                                                          resolveList=resolveList.substring(0,resolveList.length-((obj6.operator=='and')?3:2))+" ) "+obj5.operator      
                                                        }
                                                      }
                                                    }
                                                    resolveList=resolveList.substring(0,resolveList.length-((obj5.operator=='and')?3:2))+" ) "+obj4.operator      
                                                  }   
                                                }
                                              }
                                              resolveList=resolveList.substring(0,resolveList.length-((obj4.operator=='and')?3:2))+" ) "+obj3.operator      
                                            }     
                                          }
                                        }
                                        resolveList=resolveList.substring(0,resolveList.length-((obj3.operator=='and')?3:2))+" ) "+obj2.operator      
                                      }
                                    }     
                                  }
                                  resolveList=resolveList.substring(0,resolveList.length-((obj2.operator=='and')?3:2))+" ) "+obj1.operator           
                                }       
                              }             
                            }
                            resolveList=resolveList.substring(0,resolveList.length-((obj1.operator=='and')?3:2))+" ) "+obj.operator         
                          }    
                       }
                    }
                   resolveList=resolveList.substring(0,resolveList.length-((obj.operator=='and')?3:2))+" ) "+this.criteriaList[0].operator
                    }
                 }
              }
              resolveList=resolveList.substring(0,resolveList.length-((this.criteriaList[0].operator=='and')?3:2))+" ) "
             }
      }
      var viewStr='';
      if(this.criteriaList.length>0)
      {
           if(this.criteriaList[0].list.length>0)
           {
              for(let i=0;i< this.criteriaList[0].list.length;i++)
              {
                if(i==0)
                {
                  viewStr=viewStr+"(";
                }
                var obj=this.criteriaList[0].list[i];
                 if(obj.type=='row')
                 {
                   var fieldCap=this.allFormFields.filter(x=>x.field_name==obj.field_id)[0].field_caption;
                   var cond=obj.conditionList.filter(x=>x.condition_id==obj.condition_id)[0].symbol;
                   var valstr='';
                   if(obj.control_type==10 || obj.control_type==14 || obj.control_type==15 || obj.control_type==18 || obj.control_type==19 || obj.control_type==22)
                   {
                      if(obj.value.length>0)
                      {
                        for(let ob of obj.value){ valstr=valstr+ob.labl+","; }
                        valstr=valstr.substring(0,valstr.length-1);
                      }  
                   }
                   else if(obj.control_type==5){ valstr=obj.ddl.filter(x=>x.id==obj.value)[0].labl; }
                   else{valstr=obj.value}
                   viewStr=viewStr+fieldCap+" "+`<span class="text-danger">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((this.criteriaList[0].operator=='and')?' && ':' || ')
                 }
                 else
                 {
                   if(obj.list.length>0)
                   {
                    for(let j=0;j< obj.list.length;j++)
                    { 
                       if(j==0)
                       {
                        viewStr=viewStr+"("
                       }
                       var obj1=  obj.list[j];
                       if(obj1.type=='row')
                       {
                        var fieldCap=this.allFormFields.filter(x=>x.field_name==obj1.field_id)[0].field_caption;
                        var cond=obj1.conditionList.filter(x=>x.condition_id==obj1.condition_id)[0].symbol;
                        var valstr='';
                        if(obj1.control_type==10 || obj1.control_type==14 || obj1.control_type==15 || obj1.control_type==18 || obj1.control_type==19 || obj1.control_type==22)
                        {
                            if(obj1.value.length>0)
                            {
                              for(let ob of obj1.value){ valstr=valstr+ob.labl+","; }
                              valstr=valstr.substring(0,valstr.length-1);
                            }  
                        }
                        else if(obj1.control_type==5){ valstr=obj1.ddl.filter(x=>x.id==obj1.value)[0].labl; }
                        else{valstr=obj1.value}
                        viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj.operator=='and')?' && ':' || ')
                       }
                       else
                       {
                          if(obj1.list.length>0)
                          {
                            for(let k=0;k< obj1.list.length;k++)
                            {
                              if(k==0)
                              {
                                viewStr=viewStr+"("
                              }
                              var obj2=obj1.list[k];
                              if(obj2.type=='row')
                              {
                                var fieldCap=this.allFormFields.filter(x=>x.field_name==obj2.field_id)[0].field_caption;
                                var cond=obj2.conditionList.filter(x=>x.condition_id==obj2.condition_id)[0].symbol;
                                var valstr='';
                                if(obj2.control_type==10 || obj2.control_type==14 || obj2.control_type==15 || obj2.control_type==18 || obj2.control_type==19 || obj2.control_type==22)
                                {
                                    if(obj2.value.length>0)
                                    {
                                      for(let ob of obj2.value){ valstr=valstr+ob.labl+","; }
                                      valstr=valstr.substring(0,valstr.length-1);
                                    }  
                                }
                                else if(obj2.control_type==5){ valstr=obj2.ddl.filter(x=>x.id==obj2.value)[0].labl; }
                                 else{valstr=obj2.value}
                                 viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj1.operator=='and')?' && ':' || ')
                              }
                              else
                              {
                                if(obj2.list.length>0)
                                {
                                  for(let l=0;l< obj2.list.length;l++)
                                  {
                                    if(l==0)
                                    {
                                      viewStr=viewStr+"("
                                    }
                                    var obj3=obj2.list[l];
                                    if(obj3.type=='row')
                                    {
                                      var fieldCap=this.allFormFields.filter(x=>x.field_name==obj3.field_id)[0].field_caption;
                                      var cond=obj3.conditionList.filter(x=>x.condition_id==obj3.condition_id)[0].symbol;
                                      var valstr='';
                                      if(obj3.control_type==10 || obj3.control_type==14 || obj3.control_type==15 || obj3.control_type==18 || obj3.control_type==19 || obj3.control_type==22)
                                      {
                                          if(obj3.value.length>0)
                                          {
                                            for(let ob of obj3.value){ valstr=valstr+ob.labl+","; }
                                            valstr=valstr.substring(0,valstr.length-1);
                                          }  
                                      }
                                      else if(obj3.control_type==5){ valstr=obj3.ddl.filter(x=>x.id==obj3.value)[0].labl; }
                                      else{valstr=obj3.value}
                                      viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj2.operator=='and')?' && ':' || ')
                                    }
                                    else
                                    {
                                      if(obj3.list.length>0)
                                      {
                                        for(let m=0;m< obj3.list.length;m++)
                                        {
                                          if(m==0)
                                          {
                                            viewStr=viewStr+"("
                                          }
                                          var obj4=obj3.list[m];
                                          if(obj4.type=='row')
                                          {
                                            var fieldCap=this.allFormFields.filter(x=>x.field_name==obj4.field_id)[0].field_caption;
                                            var cond=obj4.conditionList.filter(x=>x.condition_id==obj4.condition_id)[0].symbol;
                                            var valstr='';
                                            if(obj4.control_type==10 || obj4.control_type==14 || obj4.control_type==15 || obj4.control_type==18 || obj4.control_type==19 || obj4.control_type==22)
                                            {
                                                if(obj4.value.length>0)
                                                {
                                                  for(let ob of obj4.value){ valstr=valstr+ob.labl+","; }
                                                  valstr=valstr.substring(0,valstr.length-1);
                                                }  
                                            }
                                            else if(obj4.control_type==5){ valstr=obj4.ddl.filter(x=>x.id==obj4.value)[0].labl; }
                                            else{valstr=obj4.value}
                                            viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj3.operator=='and')?' && ':' || ')
                                          }
                                          else
                                          {
                                            if(obj4.list.length>0)
                                            {
                                              for(let n=0;n< obj4.list.length;n++)
                                              {
                                                if(n==0)
                                                {
                                                  viewStr=viewStr+"("
                                                }
                                                var obj5=obj4.list[n];
                                                if(obj5.type=='row')
                                                {
                                                  var fieldCap=this.allFormFields.filter(x=>x.field_name==obj5.field_id)[0].field_caption;
                                                  var cond=obj5.conditionList.filter(x=>x.condition_id==obj5.condition_id)[0].symbol;
                                                  var valstr='';
                                                  if(obj5.control_type==10 || obj5.control_type==14 || obj5.control_type==15 || obj5.control_type==18 || obj5.control_type==19 || obj5.control_type==22)
                                                  {
                                                      if(obj5.value.length>0)
                                                      {
                                                        for(let ob of obj5.value){ valstr=valstr+ob.labl+","; }
                                                        valstr=valstr.substring(0,valstr.length-1);
                                                      }  
                                                  }
                                                  else if(obj5.control_type==5){ valstr=obj5.ddl.filter(x=>x.id==obj5.value)[0].labl; }
                                                  else{valstr=obj5.value}
                                                  viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj4.operator=='and')?' && ':' || ')
                                                }
                                                else
                                                {
                                                  if(obj5.list.length>0)
                                                  {
                                                    for(let o=0;o< obj5.list.length;o++)
                                                    {
                                                      if(o==0)
                                                      {
                                                        viewStr=viewStr+"("
                                                      }
                                                      var obj6=obj5.list[o];
                                                      if(obj6.type=='row')
                                                      {
                                                        var fieldCap=this.allFormFields.filter(x=>x.field_name==obj6.field_id)[0].field_caption;
                                                        var cond=obj6.conditionList.filter(x=>x.condition_id==obj6.condition_id)[0].symbol;
                                                        var valstr='';
                                                        if(obj6.control_type==10 || obj6.control_type==14 || obj6.control_type==15 || obj6.control_type==18 || obj6.control_type==19 || obj6.control_type==22)
                                                        {
                                                            if(obj6.value.length>0)
                                                            {
                                                              for(let ob of obj6.value){ valstr=valstr+ob.labl+","; }
                                                              valstr=valstr.substring(0,valstr.length-1);
                                                            }  
                                                        }
                                                        else if(obj6.control_type==5){ valstr=obj6.ddl.filter(x=>x.id==obj6.value)[0].labl; }
                                                        else{valstr=obj6.value}
                                                        viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj5.operator=='and')?' && ':' || ')
                                                      }
                                                      else
                                                      {
                                                        if(obj6.list.length>0)
                                                        {
                                                          for(let p=0;p< obj6.list.length;p++)
                                                          {
                                                            if(p==0)
                                                            {
                                                              viewStr=viewStr+"("
                                                            }
                                                            var obj7=obj6.list[p];
                                                            if(obj7.type=='row')
                                                            {
                                                              var fieldCap=this.allFormFields.filter(x=>x.field_name==obj7.field_id)[0].field_caption;
                                                              var cond=obj7.conditionList.filter(x=>x.condition_id==obj7.condition_id)[0].symbol;
                                                              var valstr='';
                                                              if(obj7.control_type==10 || obj7.control_type==14 || obj7.control_type==15 || obj7.control_type==18 || obj7.control_type==19 || obj7.control_type==22)
                                                              {
                                                                  if(obj7.value.length>0)
                                                                  {
                                                                    for(let ob of obj7.value){ valstr=valstr+ob.labl+","; }
                                                                    valstr=valstr.substring(0,valstr.length-1);
                                                                  }  
                                                              }
                                                              else if(obj7.control_type==5){ valstr=obj7.ddl.filter(x=>x.id==obj7.value)[0].labl; }
                                                              else{valstr=obj7.value}
                                                              viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj6.operator=='and')?' && ':' || ')
                                                            }
                                                            else
                                                            {
                                                              if(obj7.list.length>0)
                                                              {
                                                                for(let q=0;q< obj7.list.length;q++)
                                                                {
                                                                  if(q==0)
                                                                  {
                                                                    viewStr=viewStr+"("
                                                                  }
                                                                  var obj8=obj7.list[q];
                                                                  if(obj8.type=='row')
                                                                  {
                                                                    var fieldCap=this.allFormFields.filter(x=>x.field_name==obj8.field_id)[0].field_caption;
                                                                    var cond=obj8.conditionList.filter(x=>x.condition_id==obj8.condition_id)[0].symbol;
                                                                    var valstr='';
                                                                    if(obj8.control_type==10 || obj8.control_type==14 || obj8.control_type==15 || obj8.control_type==18 || obj8.control_type==19 || obj8.control_type==22)
                                                                    {
                                                                        if(obj8.value.length>0)
                                                                        {
                                                                          for(let ob of obj8.value){ valstr=valstr+ob.labl+","; }
                                                                          valstr=valstr.substring(0,valstr.length-1);
                                                                        }  
                                                                    }
                                                                    else if(obj8.control_type==5){ valstr=obj8.ddl.filter(x=>x.id==obj8.value)[0].labl; }
                                                                     else{valstr=obj8.value}
                                                                     viewStr=viewStr+fieldCap+" "+`<span class="text-success">${cond}</span>`+" "+`<span class="text-primary">${valstr}</span>`+((obj7.operator=='and')?' && ':' || ')
                                                                  }     
                                                                }
                                                                viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj6.operator=='and')?' && ':' || ')      
                                                              }
                                                            }
                                                          }
                                                          viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj5.operator=='and')?' && ':' || ')      
                                                        }
                                                      }
                                                    }
                                                    viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj4.operator=='and')?' && ':' || ')      
                                                  }   
                                                }
                                              }
                                              viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj3.operator=='and')?' && ':' || ')      
                                            }     
                                          }
                                        }
                                        viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj2.operator=='and')?' && ':' || ')      
                                      }
                                    }     
                                  }
                                  viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj1.operator=='and')?' && ':' || ')           
                                }       
                              }             
                            }
                            viewStr=viewStr.substring(0,viewStr.length-4)+")"+((obj.operator=='and')?' && ':' || ')         
                          }    
                       }
                    }
                    viewStr=viewStr.substring(0,viewStr.length-4)+")"+((this.criteriaList[0].operator=='and')?' && ':' || ')
                   }
                 }
              }
              viewStr=viewStr.substring(0,viewStr.length-4)+")"
           }
      }
      var outputObj={"queryStr":resolveList,"viewStr":viewStr}
      //console.log("outputObj==>",outputObj);
      this.applyEvent.emit(outputObj);
    }
      catch(err)
      {
         alert("Please Select/Enter Valid Text");
         console.log(err)
      }
   }
   
   
OnSearch(event,obj2) {      
      if (event.length >= 3) {        
        let querystr=this.dropdownQuery; 
        if(this.isFilterJson == true){
          querystr=querystr+"  and lower("+ this.tempTableName+ ") like '%"+ event.toLowerCase() +"%'";
        } else{
          querystr=querystr+"  where lower("+ this.tempTableName+ ") like '%"+ event.toLowerCase() +"%'";
        }   
        
        querystr=querystr+" order by "+this.tempPrimaryKey+ " asc limit 100"
        this.formService.getFormData(querystr).subscribe(res => {
         let result=res.rows;
         if(result.length>0){
           obj2.ddl=result;
         }else{
           obj2=[];
         }
        },error => {
                          
        });
  
      } else {
        //console.log("OnSearch<3");
      }
     
    }
  
   

}
