import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-customize-setting',
  templateUrl: './customize-setting.component.html',
  styleUrls: ['./customize-setting.component.css']
})
export class CustomizeSettingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.settingPanelToggle').click(function () {
        $('.settingSidebar').toggleClass('settingtoggleclassss');
      });
      $(".settingSidebar").addClass("settingtoggleclassss");
      $("#top-sidebar").hide();
    });
    $(document).ready(function () {
      $('ul.sidebar-color li').click(function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');

      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li').click(function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');

      });
    });
    $(document).ready(function () {
      $('ul.sidebar-color li .blue').click(function () {

        $('#sidebar-wrapper').addClass('blue-sidebar');
        $('#sidebar-wrapper').removeClass('coral-sidebar');
        $('#sidebar-wrapper').removeClass('purple-sidebar');
        $('#sidebar-wrapper').removeClass('allports-sidebar');
        $('#sidebar-wrapper').removeClass('barossa-sidebar');
        $('#sidebar-wrapper').removeClass('fancy-sidebar');
        // $(this).siblings().removeClass('active');
      });
    });
    $(document).ready(function () {
      $('ul.sidebar-color li .coral').click(function () {

        $('#sidebar-wrapper').addClass('coral-sidebar');
        $('#sidebar-wrapper').removeClass('blue-sidebar');
        $('#sidebar-wrapper').removeClass('purple-sidebar');
        $('#sidebar-wrapper').removeClass('allports-sidebar');
        $('#sidebar-wrapper').removeClass('barossa-sidebar');
        $('#sidebar-wrapper').removeClass('fancy-sidebar');
        // $(this).siblings().removeClass('active');
      });
    });
    $(document).ready(function () {
      $('ul.sidebar-color li .purple').click(function () {
        $('#sidebar-wrapper').removeClass('blue-sidebar');
        $('#sidebar-wrapper').removeClass('coral-sidebar');
        $('#sidebar-wrapper').addClass('purple-sidebar');
        $('#sidebar-wrapper').removeClass('allports-sidebar');
        $('#sidebar-wrapper').removeClass('barossa-sidebar');
        $('#sidebar-wrapper').removeClass('fancy-sidebar');
        // $(this).siblings().removeClass('active');
      });
    });
    $(document).ready(function () {
      $('ul.sidebar-color li .allports').click(function () {
        $('#sidebar-wrapper').removeClass('blue-sidebar');
        $('#sidebar-wrapper').removeClass('coral-sidebar');
        $('#sidebar-wrapper').removeClass('purple-sidebar');
        $('#sidebar-wrapper').addClass('allports-sidebar');
        $('#sidebar-wrapper').removeClass('barossa-sidebar');
        $('#sidebar-wrapper').removeClass('fancy-sidebar');
        // $(this).siblings().removeClass('active');
      });
    });
    $(document).ready(function () {
      $('ul.sidebar-color li .barossa').click(function () {
        $('#sidebar-wrapper').removeClass('blue-sidebar');
        $('#sidebar-wrapper').removeClass('coral-sidebar');
        $('#sidebar-wrapper').removeClass('purple-sidebar');
        $('#sidebar-wrapper').removeClass('allports-sidebar');
        $('#sidebar-wrapper').addClass('barossa-sidebar');
        $('#sidebar-wrapper').removeClass('fancy-sidebar');
        // $(this).siblings().removeClass('active');
      });
    });
    $(document).ready(function () {
      $('ul.sidebar-color li .fancy').click(function () {
        $('#sidebar-wrapper').removeClass('blue-sidebar');
        $('#sidebar-wrapper').removeClass('coral-sidebar');
        $('#sidebar-wrapper').removeClass('purple-sidebar');
        $('#sidebar-wrapper').removeClass('allports-sidebar');
        $('#sidebar-wrapper').removeClass('barossa-sidebar');
        $('#sidebar-wrapper').addClass('fancy-sidebar');
        // $(this).siblings().removeClass('active');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .white').click(function () {
        $('#page-content-wrapper').addClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .blue').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').addClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .coral').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').addClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .purple').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').addClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .allports').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').addClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .barossa').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').addClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .fancy').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').addClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .cyan').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').addClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .orange').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').addClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .green').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').addClass('theme-green');
        $('#page-content-wrapper').removeClass('theme-red');
      });
    });
    $(document).ready(function () {
      $('.settingSidebar ul.choose-theme li .red').click(function () {
        $('#page-content-wrapper').removeClass('theme-white');
        $('#page-content-wrapper').removeClass('theme-blue');
        $('#page-content-wrapper').removeClass('theme-coral');
        $('#page-content-wrapper').removeClass('theme-purple');
        $('#page-content-wrapper').removeClass('theme-allports');
        $('#page-content-wrapper').removeClass('theme-barossa');
        $('#page-content-wrapper').removeClass('theme-fancy');
        $('#page-content-wrapper').removeClass('theme-cyan');
        $('#page-content-wrapper').removeClass('theme-orange');
        $('#page-content-wrapper').removeClass('theme-green');
        $('#page-content-wrapper').addClass('theme-red');
      });
    });

    $('#mini_sidebar_setting').click(function () {
      $('.sidebar').toggleClass('fliph');
    
    });
    $('#topside').click(function () {
     if($("#topside").is(":checked") ) 
     {
      $('#sidebar-wrapper').hide();
      $("app-header").hide();
      $("#top-sidebar").show();
     
     } else {
      $('#sidebar-wrapper').show();
      $("app-header").show();
      $("#top-sidebar").hide();
    
     }
    });
    $('#leftside').click(function () {
      if($("#leftside").is(":checked") ) 
      {
       $('#sidebar-wrapper').show();
       $("app-header").show();
       $("#top-sidebar").hide();
      }
     });
    $('.darkmode .custom-switch-input').click(function () {
      $('body').addClass('alldark');
      $('#page-content-wrapper').removeClass('theme-white');
      $('#page-content-wrapper').removeClass('theme-blue');
      $('#page-content-wrapper').removeClass('theme-coral');
      $('#page-content-wrapper').removeClass('theme-purple');
      $('#page-content-wrapper').removeClass('theme-allports');
      $('#page-content-wrapper').removeClass('theme-barossa');
      $('#page-content-wrapper').removeClass('theme-fancy');
      $('#page-content-wrapper').removeClass('theme-cyan');
      $('#page-content-wrapper').removeClass('theme-orange');
      $('#page-content-wrapper').removeClass('theme-green');
      $('#page-content-wrapper').removeClass('theme-red');
      $('#sidebar-wrapper').removeClass('blue-sidebar');
      $('#sidebar-wrapper').removeClass('coral-sidebar');
      $('#sidebar-wrapper').removeClass('purple-sidebar');
      $('#sidebar-wrapper').removeClass('allports-sidebar');
      $('#sidebar-wrapper').removeClass('barossa-sidebar');
      $('#sidebar-wrapper').removeClass('fancy-sidebar');
    });
    $('.lightmode .custom-switch-input').click(function () {
      $('body').removeClass('alldark');
      $('#page-content-wrapper').removeClass('theme-white');
      $('#page-content-wrapper').removeClass('theme-blue');
      $('#page-content-wrapper').removeClass('theme-coral');
      $('#page-content-wrapper').removeClass('theme-purple');
      $('#page-content-wrapper').removeClass('theme-allports');
      $('#page-content-wrapper').removeClass('theme-barossa');
      $('#page-content-wrapper').removeClass('theme-fancy');
      $('#page-content-wrapper').removeClass('theme-cyan');
      $('#page-content-wrapper').removeClass('theme-orange');
      $('#page-content-wrapper').removeClass('theme-green');
      $('#page-content-wrapper').removeClass('theme-red');
      $('#sidebar-wrapper').removeClass('blue-sidebar');
      $('#sidebar-wrapper').removeClass('coral-sidebar');
      $('#sidebar-wrapper').removeClass('purple-sidebar');
      $('#sidebar-wrapper').removeClass('allports-sidebar');
      $('#sidebar-wrapper').removeClass('barossa-sidebar');
      $('#sidebar-wrapper').removeClass('fancy-sidebar');
    });

  }

  
}
