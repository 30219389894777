import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router'; 
import {FormService} from '../_services/form.service';
import { SectionConfig,SessionUrlPrams } from "../field.interface";
import { ButtonRendererComponent } from '../button-renderer/button-renderer.component';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import{LoginService} from 'src/app/_services/login.service'
declare var $: any; 
import * as CryptoJS from 'crypto-js';
import { AppSettings } from '../_services/AppSetting'

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css']
})
export class FormEditComponent implements OnInit,OnDestroy {
  subscription: Subscription;
  frameworkComponents: any;
  formId:any=0;
  public isLoading = true;
  form_viewid:any=0;
  formName:any;
  sessionUrlPrams:SessionUrlPrams; //new added
  tableName:any;
  tablePrimaryKey : any
  SectionConfig:SectionConfig[]=[];  
  formDisplayJSON:any;
  nodeJson:any;
  connJson:any;
  categoryname:any;
  processid:any;
  stageid:any;
  worklistid:any;
  processinstid:any;
  FormViewLoaded:Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  processing: Promise<boolean>;
  isAddMode:boolean=true;
  is_attachment: boolean=false;
  ErrorOccurred:String="Error occurred!!";
  SuccessMasg:any;
  editValueRow:any;
  editrowid:any;
  refKey:any=0;
  childFormList: any[]=[];
  childFormListPage: any[]=[]; //add new
  childFormListTab: any[]=[]; //add new
  fieldChildFormList: any[]=[]; //add new
  metaDataList: any[]=[];
  attachmentType: any[]=[];
  list: any;
  metadata: any;
  edit_option:number=1;
  add_edit: Promise<boolean>;
  urlData:any;
  templateUrl: string;
  DataForm:any;
  dataAddedValue:any;
  address_id: any;
  address_id1: any;
  customer_id: any;
  constructor(
    private router: Router,
    private loginService:LoginService,
    private formService : FormService,
    private toastrService: ToastrService,
    private route :ActivatedRoute,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
  }
 
  ngOnInit(): void {
  this.route.queryParams.subscribe((res)=>{
    let data=this.loginService.decreptedDataUrl(res)
    if(data.length>1){
      var str = data;
      console.log("gr", str);
      var value=str.split("&");
      //console.log("pks uri===>",uri,value)
      this.toastrService.toastrConfig.enableHtml=true;
      value.forEach(queryParm=>{
        if(queryParm.indexOf('frmid')>=0)
        {
          this.formId=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('editrowid')>=0)
        {
          this.editrowid=queryParm.split('=')[1];
          this.refKey=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('vid')>=0)
        {
          this.form_viewid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('worklistid')>=0)
        {
          this.worklistid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('processinstid')>=0)
        {
          this.processinstid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('stageid')>=0)
        {
          this.stageid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('isAddMode')>=0)
        {
          this.isAddMode=queryParm.split('=')[1]=='true'?true:false;
        }
      });
      this.sessionUrlPrams={
        frmid:this.formId,
        vid:this.form_viewid,
        UserCode:localStorage.getItem("userId"),//localStorage.getItem("UserCode")?localStorage.getItem("UserCode"):"UserCode",
        stageid:this.stageid,
        UserName:localStorage.getItem("userFullName"),
        ProfileId:localStorage.getItem("profileId"),
        office:localStorage.getItem("office"),
        Location:localStorage.getItem("office"),
        department:localStorage.getItem("department"),
        processtype:"ext",
        processCtrl:true,
        processinstid:0,
     };      
      this.getFormDetails(); 
      let is_sbi_approval= localStorage.getItem("is_sbi_approval");
      let actorid = localStorage.getItem("actorid")     
      if(actorid == "2" && is_sbi_approval != "1" && this.formId == "376"){
        setTimeout(() => {
          $("#leftmenu").hide();
        }, 10);     
      }else{
        $("#leftmenu").show();
      }
    }
  });
 
  
    this.isLoading=true;
    
    
  }

  getFormDetails(){
    this.isLoading=true;
    this.subscription=this.formService.getFormData("select form_id,form_name,table_name,primary_key,form_display_json,value_field,processid from app_forms where form_id="+this.formId).subscribe(res => {
    //this.subscription=this.formService.getFormDataById(this.formId).subscribe(res => {
      let formList=res.rows;
      let objChildFormIds="",objChildFormViewIds="";
      if(formList.length>0){
        this.formName=formList[0]["form_name"];
        this.formId = formList[0]["form_id"];
        this.processid=formList[0]["processid"];
        this.tableName= formList[0]["table_name"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase(); 
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
        if(this.formDisplayJSON.length>0){
          if(this.processid >0){
            this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
            this.connJson=JSON.parse(this.formDisplayJSON).connJson;
            //now getting initial stageid
            var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
            this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
            //console.log("this.SectionConfig==>",this.SectionConfig)
            // control field hide/show based on process stages...
            this.SectionConfig.forEach(arrField => {
              if(arrField.FieldConfig[0]?.visible_stage !=undefined){
                arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0 || field.visible_stage.toString() =="");
              }
            });
            this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0  || d.visible_stage.toString() =="")));
            this.stageid=intStageid;
            this.sessionUrlPrams.stageid=intStageid;
          }else{
            this.SectionConfig=JSON.parse(this.formDisplayJSON)
            this.SectionConfig.forEach(arrField => {
              if(arrField.FieldConfig[0]?.visible_stage !=undefined){
                //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
                arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0 || field.visible_stage.toString() =="");
              }
            });
            this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0  || d.visible_stage.toString() =="")));
          }
          this.SectionConfig.forEach(arrField => {
            arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible==null || field?.visible=="always" || field?.visible=="add" || field?.visible=="hidden");
          });

          this.SectionConfig=this.SectionConfig.filter(sec=>(sec.FieldConfig?.length >0 || sec.section_dependent==true) && (sec?.isCaptionVisiable =="always" || sec?.isprocess =="1" ) );
          //to get child form id
          this.fieldChildFormList=[];
          this.SectionConfig.forEach(objSec => {
            objSec.FieldConfig.forEach(objField=>{
              if(objField.type == "linkwithchild")
              {
                //console.log("field linkwithchild arrObjSect==>",objField,objField.child_form_id)
                objChildFormIds=objChildFormIds+objField.child_form_id +",";
                //objChildFormViewIds=objChildFormViewIds+objField.child_view_id +",";
                this.fieldChildFormList.push(objField);
              }
            });
          });
         //console.log("field linkwithchild arrObjSect==>",objChildFormIds,objChildFormViewIds)
        }
      }
      this.getFormViewDetails(objChildFormIds,objChildFormViewIds);
    });
    setTimeout(() => {this.isLoading=false},800);
  }
  
  getFormViewDetails(childformids,childformviewid){
    this.subscription=this.formService.getFormViewById(this.form_viewid).subscribe(res => {
      let formViewList=res.rows;  
      if(formViewList.length>0){
        //console.log("sadasd==>",formViewList);
        //let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        this.edit_option=formViewList[0]["edit_option"];
        this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        let validationList=formViewList[0]["validation"]?JSON.parse(formViewList[0]["validation"]):[];
        this.list={
          "attachmentList":this.attachmentType,
          "validationList" : validationList
        }
        //console.log("PKS===>",this.list)
        this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;                 
        let child_form_view_json=formViewList[0]["child_form_view_ids"]?JSON.parse(formViewList[0]["child_form_view_ids"]):[];
        if(child_form_view_json !=undefined && child_form_view_json.length>0){
          child_form_view_json=child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId="";
          for(let r=0;r<child_form_view_json.length; r++){
            childFormId=childFormId+child_form_view_json[r]["formid"]+",";
          }
          childFormId=childFormId.substring(0,childFormId.length-1)
        }
        console.log("childformids==>",childFormId,child_form_view_json,this.fieldChildFormList)
        if(childFormId ==""){
          childFormId=childformids.substring(0,childformids.length-1);
        }else{
          childFormId=childformids+childFormId;
        }
        

        if(childFormId != "" && childFormId != undefined && childFormId != null){
          this.subscription=this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
            this.childFormList=childRes.rows;
            console.log("childFormList==>",this.childFormList)
            //if(child_form_view_json.length>0){
              for(let a=0;a<this.childFormList.length;a++){
                let arrView=child_form_view_json.filter(d=>d.formid == this.childFormList[a]["form_id"])
                if(arrView.length>0){
                  this.childFormList[a]["viewid"]=arrView[0]["viewid"]
                  this.childFormList[a]["display_order"]=arrView[0]["orderno"]
                  this.childFormList[a]["filterjson"]=arrView[0]["filterjson"]
                  this.childFormList[a]["showon"]=arrView[0]["showon"]
                  this.childFormList[a]["layout"]=arrView[0]["layout"]
                  this.childFormList[a]["type"]="linkwithview"
                  this.childFormList[a]["visible_stage"]=arrView[0]["visible_stage"]==undefined?"":arrView[0]["visible_stage"];
                  this.childFormList[a]["editable_stage"]=arrView[0]["editable_stage"]==undefined?"":arrView[0]["editable_stage"];
                  }else{
                    let fieldArr=this.fieldChildFormList.filter(d=>d.child_form_id==this.childFormList[a]["form_id"].toString());
                    if(fieldArr.length>0){
                      this.childFormList[a]["viewid"]=fieldArr[0]["child_view_id"];
                      this.childFormList[a]["display_order"]=0;
                      this.childFormList[a]["filterjson"]="";
                      this.childFormList[a]["showon"]="page";
                      this.childFormList[a]["layout"]=fieldArr[0]["child_layout"]
                      this.childFormList[a]["type"]="linkwithform";
                      this.childFormList[a]["visible_stage"]=fieldArr[0]["visible_stage"];
                      this.childFormList[a]["editable_stage"]=fieldArr[0]["editable_stage"];
                    }
                  }
                // console.log(arrView)
              }
            //}
            this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
            //console.log("this.childFormList==>",this.childFormList,child_form_view_json) 
            this.childFormListPage=this.childFormList.filter(d=>d.showon==="page");
            this.childFormListTab=this.childFormList.filter(d=>d.showon==="tab");
            if(this.isAddMode ==false){
              this.getRowEditData()
            }else{
              //console.log("this.SectionConfig==>",this.SectionConfig)  
              this.SectionConfig.forEach(arrField => {
                arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="add" || field?.visible=="hidden");
              });
              this.FormViewLoaded=Promise.resolve(true);
              this.processing=Promise.resolve(true);
            }
          });
        }else{
          if(this.isAddMode ==false){
            this.getRowEditData()
          }else{
            //console.log("this.SectionConfig==>",this.SectionConfig)  
            this.SectionConfig.forEach(arrField => {
              arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="add" || field?.visible=="hidden");
            });
            this.FormViewLoaded=Promise.resolve(true);
            this.processing=Promise.resolve(true);
          }
        }

      }
     
    });
  }
  getRowEditData(){
    this.add_edit=Promise.resolve(false);
    this.SectionConfig=null;
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
        //now getting initial stageid
        var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control field hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0  || d.visible_stage.toString() =="")));
        this.stageid=intStageid;
        this.sessionUrlPrams.stageid=intStageid;
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0  || d.visible_stage.toString() =="")));
      }
       //console.log("this.SectionConfig==>",this.SectionConfig)  
       this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="edit" || field?.visible=="hidden");
      });
      //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.isAddMode=false;
      //this.editrowid=e.rowData[this.tablePrimaryKey];
      this.refKey=this.editrowid
      var strQry='select * from '+this.tableName+' where '+this.tablePrimaryKey+'= '+this.editrowid+';';
      // console.log(strQry,"in View");
      this.subscription=this.formService.getFormData(strQry).subscribe(res => {
        console.log("rowData==",res.rows[0]);
        this.editValueRow=JSON.parse(JSON.stringify(res.rows[0]));
        this.categoryname =  this.editValueRow.category_name
        // console.log("this.formAllField===>",this.editValueRow,);
        this.FormViewLoaded=Promise.resolve(true);
        this.processing=Promise.resolve(true);
        if(this.edit_option == 3){
            if(this.childFormList.length>0){        
              let formid=this.childFormList[0]["form_id"];
              let tabid="#tabchildedit"+formid;
              setTimeout(() => {$(tabid).click();}, 100);        
            }
        }
        setTimeout(() => {
        //  $('.col-sm-12 text-center mt-2 label').text('');
           $("label:contains('Note: Please make sure you save the changes before submitting.')").remove()
       //   $('label[value|="Note: Please make sure you save the changes before submitting."]').remove()
        }, 500);
      });
    }

  }
  gotoback(){
    //this.router.navigateByUrl('/display-form-detail?'+this.tempFormId);
  }
 
  processsubmit(value: any){    
    console.log("process value.isAddMode===>",JSON.stringify(value),value.SuccessMasgLoaded);
    if(this.DataForm== undefined || this.DataForm == null || this.DataForm==""){
      this.DataForm=value.value;
    }
    this.isLoading=true;
    if(value.SuccessMasgLoaded=="true"){
      if(value.actiontype=='initprocss'){      
      }     
      // this.SuccessMasg="Submitted Sucessfully !!"
    }else{
      this.toastrService.error(value.ErrorOccurred,'',{timeOut: 3000,positionClass : "toast-top-center"});
      
    }
    setTimeout(() => {this.isLoading=false},800);
  }
 
  submit(value:any) {
     console.log("pradeep value.isAddMode===>",JSON.stringify(value),value.isAddMode,value.SuccessMasgLoaded);
    let FormData=value.FormDate;
    this.DataForm=value.FormDate;
    this.dataAddedValue=value.isAddMode;
    this.isLoading=true;
    this.categoryname =  value.FormDate.category_name
    let mobile_no = value.FormDate.contact_number
    let firstname = value.FormDate.contact_person;
    let email =  value.FormDate.email_address;
    let customer_password =  value.FormDate.corp_password;
   // console.log(value.FormDate,value.FormDate.email_address,mobile_no,firstname,email,customer_password)
   this.refKey=value.refKey; 
console.log('this.refKey' , this.refKey)
    if(value.isAddMode==true){
      if(value.SuccessMasgLoaded=="true"){
        this.refKey=value.refKey; 
        if(this.formId == 404){ 
          let corp_name ;
          let corpcode;
          let enterprise_id
          if(value.FormDate.enterprise_name != null){
          corp_name = value.FormDate.enterprise_name.replace(/\s/g,'');
          corpcode = corp_name.slice(0, 3);
          enterprise_id = corpcode +  this.refKey
          }
          else{
             enterprise_id = 'R' + this.refKey
          }
          // console.log('enterprise_id hi i am here',enterprise_id,corpcode,corp_name)
          // return false
         let innsertqry = `insert into z_entitymast_customer_details_001(referance_id,first_name,mobile_no,email,is_corporate)values(${this.refKey},'${firstname}','${mobile_no}','${email}',true) returning customer_id;`
          let strSQLprofile =  `INSERT INTO app_tbluser(user_fname,user_mobile,user_emailid,user_password,referance_id,user_loginname,effective_from, createddate, isactive,user_type)
          VALUES ('${firstname}','${mobile_no}','${email}','${customer_password}',${this.refKey}, '${email}', current_timestamp, current_timestamp, true,'Corporate')returning*;`
          let strSQLDraftUpdate=`update ${this.tableName} set enterprise_id= '${enterprise_id}' where add_corporate_id =${this.refKey} ;`
           let finalqry =   innsertqry + strSQLprofile + strSQLDraftUpdate
          // console.log('finalqry===========',finalqry)
          this.subscription=this.formService.getFormData(finalqry).subscribe(result =>{
          //  console.log('Hi I am here')
          let data2 = result[0].rows;  
          let data1 = result[1].rows;
          if(data1.length > 0 && data2.length){
          let userId = data1[0]["user_id"]
          let cust_id = data2[0]["customer_id"]
          let query = `INSERT INTO public.app_tbluserprofile(
          user_id, role_id, office_id, default_role_id, default_office_id, effective_from, effective_to, createdby, createddate, updatedby, updateddate, isactive)
          VALUES (${userId}, 47, null, null,null, current_timestamp, null, ${localStorage.getItem("userId")}, current_timestamp, null, null, true);`
           let updateqey = `update app_tbluser set customer_id = ${cust_id} where user_id = ${userId};`
           let fqry = query + updateqey
           this.subscription=this.formService.getFormData(fqry).subscribe(result =>{ 
            let address = '';
            let getadd = `select * from z_entitydtl_corporate_shipping_details_001 where add_corporate_id=${this.refKey};
                          select * from z_entitydtl_corporate_billing_details_001 where add_corporate_id=${this.refKey}`
          this.formService.getFormData(getadd).subscribe(res =>{
            let alladdress =  [...res[1].rows,...res[0].rows];
            console.log('resss', res)
            for (const add of alladdress) {
                if(add.corporate_billing_details_id){
                  address = address + `INSERT INTO z_entitydtl_customer_addresses_001(isactive, customer_id, name,mobile,email_address, pin_code, locatity, area_street, city, state_id, country_id, company_name,gstin,is_billing_add, corporate_billing_details_id)
                  VALUES(${true}, ${cust_id},'${add.company_name}', '${add.phone_no}','${add.email}', '${add.zip_code}', '${add.address}', '${add.address}','${add.city}',  ${add.state}, 1, '${add.company_name}', '${add.gst}', ${true}, ${add.corporate_billing_details_id});`
                }else if(add.corporate_shipping_details_id){
                  address = address + `INSERT INTO z_entitydtl_customer_addresses_001(isactive, customer_id, name,mobile,email_address, pin_code, locatity, area_street, city, state_id, country_id, company_name,gstin,is_billing_add,contact_person,corporate_shipping_details_id)
                  VALUES(${true}, ${cust_id},'${add.contact_person}', '${add.phone_no}','${add.email}', '${add.zip_code}', '${add.address}', '${add.address}','${add.city}',  ${add.state}, 1, '${add.company_name ? add.company_name : null}', '${add.gst ? add.gst : null}', ${false}, '${add.contact_person}', ${add.corporate_shipping_details_id});`
                }
            }
           console.log('print queryyyy', address)
          if(address != null && address != undefined && address != '' && address != 'null'){
            this.loginService.getFormData(address).subscribe(res =>{
              
              console.log('sucesssaved')
            }, err =>{
              console.log('error')

            })
          }
       
    
          })
 

           let data = JSON.stringify('');
            let  encriptUrl = this.loginService.encriptDataUrl(data)
            this.router.navigateByUrl('/display-form-detail?frmid=404&vid=379');  
            
           })
          }
        })  
      }
      this.toastrService.success("Added Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"});    
        
      }else{
       this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")",'',{timeOut: 3000,positionClass : "toast-top-center"});        
      }
    }else{
      if(value.SuccessMasgLoaded =="true"){   

        let address = '';
        let getadd = `select * from z_entitydtl_corporate_shipping_details_001 where add_corporate_id=${this.refKey};
                      select * from z_entitydtl_corporate_billing_details_001 where add_corporate_id=${this.refKey};
                      `
      this.formService.getFormData(getadd).subscribe(res =>{
        let alladdress =  [...res[1].rows,...res[0].rows];
        console.log('resss', res)
        for (const add of alladdress) {
            if(add.corporate_billing_details_id){
              address = address + `update z_entitydtl_customer_addresses_001 set(isactive, name,mobile,email_address, pin_code, locatity, area_street, city, state_id, country_id, company_name,gstin,is_billing_add)
              =(${true}, '${add.company_name}', '${add.phone_no}','${add.email}', '${add.zip_code}', '${add.address}', '${add.address}','${add.city}',  ${add.state}, 1, '${add.company_name}', '${add.gst}', ${true}) where corporate_billing_details_id = ${add.corporate_billing_details_id};`
            }else if(add.corporate_shipping_details_id){
              address = address + `update z_entitydtl_customer_addresses_001(isactive, name,mobile,email_address, pin_code, locatity, area_street, city, state_id, country_id, company_name,gstin,is_billing_add,contact_person)
              =(${true},'${add.contact_person}', '${add.phone_no}','${add.email}', '${add.zip_code}', '${add.address}', '${add.address}','${add.city}',  ${add.state}, 1, '${add.company_name ? add.company_name : null}', '${add.gst ? add.gst : null}', ${false}, '${add.contact_person}');`
            }
        }
      console.log('print update queryyy', address)

      })



          this.toastrService.success("Updated Sucessfully !!",'',{timeOut: 3000,positionClass : "toast-top-center"});
          this.router.navigateByUrl('/display-form-detail?frmid=404&vid=379');
        
      }else{        
        this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")",'',{timeOut: 3000,positionClass : "toast-top-center"});
      }
    }
    setTimeout(() => {this.isLoading=false},800);
  }
  public fileName(scheme_type,process_type,refKey){
    const nowDate = new Date(); 
    const month = nowDate.toLocaleString('default', { month: 'short' })
    const date = nowDate.getDate()+month+nowDate.getFullYear(); 
    const url = this.router.url.replace(/\//g, ""); //department code/  
     
    const filename =localStorage.getItem("department_code_en")+"/"+(refKey?this.padLeft(refKey, '0', 4):"00").toString()+"/"+nowDate.getFullYear();
    return filename;
  }

  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
}
 
 
  
  ngOnDestroy() { 
    this.subscription.unsubscribe();
    this.formService=null;
    this.frameworkComponents=null
    this.processing=null;
    this.formId=null
    this.processid=null
    this.stageid=null
    this.form_viewid=null
    this.formName=null
    this.tableName=null
    this.tablePrimaryKey=null
    this.SectionConfig=null
    this.formDisplayJSON=null
    this.nodeJson=null
    this.connJson=null
    this.FormViewLoaded=null
    this.SuccessMasgLoaded=null
    this.ErrorMasgLoaded=null
    this.isAddMode=null
    this.is_attachment=null
    this.SuccessMasg=null
    this.editrowid=null
    this.refKey=null
    this.childFormList=null
    this.metaDataList=null
    this.attachmentType=null
    this.metadata=null
   this.edit_option=null
  }
    
}


