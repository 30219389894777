import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
declare var $: any;

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  sellerId: any;
  view_item_id: any;
  isView: boolean;
  allOrderPaymentList: any;
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  TempallOrderPaymentList: any;
  isLoading: boolean = false;
  userId: any;
  allOrderListData: any;
  sellerName:any;
  currentTooltip:any=""
  searchTerm: any;
  isLoadGrid: boolean = false;

  constructor(private productService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService,  private router: Router) { }

  ngOnInit(): void {
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.sellerName = localStorage.getItem('sellerName');
    this.isLoading = true;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.bindNgxDataGrid(this.page, 1)

    $(document).ready(function(){
      $('ul.tbsbtns li').click(function(){
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
    });
    });
  }
  setTitle(e){
    this.currentTooltip=e;
  }
  
  LoadGrid(event) {
    this.isLoadGrid = true
    this.configParms = event; 
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)

  }
  bindNgxDataGrid(pageInfo, status) {
    // console.log('check orderstatus', {first: this.currentOrderStatus, second: this.currentOrderStatus2})
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
  //  let orderStatus = 187;
  

if(!this.isLoadGrid)
offset = this.searchTerm ? 0 : offset
    this.productService.getAllOrderPaymentListsellerwisecount(this.searchTerm,this.sellerId).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      // console.log('countss', this.page.totalElements)
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllOrderPaymentListsellerwise(this.searchTerm, pagesize,offset,this.sellerId).subscribe(res => {
        // console.log('check response', res.rows)
        this.allOrderPaymentList = res.rows;
        this.TempallOrderPaymentList = this.allOrderPaymentList;
        this.isLoader = false;
      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;  
      this.isLoading = false;
    })

  }

  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;    
      this.bindNgxDataGrid(this.page, 1);
  } 
 
  closeModal() {
    this.isView = false;
    $("#myModal").modal('hide');
  }

  onProductdetailView(data) {
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    $('#scroll').animate({ scrollTop: 0 }, 'slow');
    this.isView = true;
    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal").modal('show');
      //this.toastrService.success("Product Approved", '', { timeOut: 3000, positionClass: "toast-top-center" 
    });
  }

  showSellerDetails(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem('approve_reject', 'false')
    this.router.navigateByUrl('/seller-profile'+ '?' + localStorage.getItem('tempToken'));

  }
  
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
