<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class=" page-wraper" id="page-content-wrapper" style="background: #f8f9fa">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 p-0">
                        <nav aria-label="breadcrumb p-0" *ngIf="!breadcrumb">
                            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                                <li class="breadcrumb-item">
                                    <a href="javascript:void(0)" class="text-primary"
                                        style="text-decoration: none;  cursor: text;"
                                        routerLink="/dashboardnew">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="javascript:void(0)" class="text-primary"
                                        style="text-decoration: none;  cursor: text;" routerLink="/total-seller">Total
                                        Seller
                                    </a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="javascript:void(0)" class="text-primary"
                                        style="text-decoration: none;  cursor: text;"
                                        routerLink="/seller-wise-product">Product Listing</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Add Configurable Product</li>
                            </ol>
                        </nav>
                        <nav aria-label="breadcrumb p-0" *ngIf="breadcrumb">
                            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                                <li class="breadcrumb-item">
                                    <a href="javascript:void(0)" routerLink="/dashboardnew">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="javascript:void(0)" routerLink="/product-listing">Pending Products</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Add Configurable Product</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-sm-12 p-0">
                        <div class="tabs  my-2">
                            <div class="tab-button-outer1">
                                <ul id="tab-button" class="tbsbtns">
                                    <li id="tabDraft" class="is-active">
                                        <a href="javascript:void(0)" class="font8">Add Configurable Product </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-body">
                                <form id="msform">
                                    <div class="row">
                                        <div class="col-md-8 mx-auto">
                                            <ul id="progressbar" class="p-0">
                                                <li class="active" id="step1">
                                                    <span class="fa icon_reg   fa-cog"></span>
                                                    <strong>Basic Configuration</strong>
                                                    <h6 class="text-center m-0"> Step 1 </h6>
                                                </li>
                                                <li class="" id="step2">
                                                    <span class="fa icon_reg  fa-credit-card"></span>
                                                    <strong>Set Product Attributes</strong>
                                                    <h6 class="text-center m-0"> Step 2 </h6>
                                                </li>
                                                <li class="" id="step3">
                                                    <span class="fa fa-th-list icon_reg"></span>
                                                    <strong>Product Configurations</strong>
                                                    <h6 class="text-center m-0"> Step 3 </h6>
                                                </li>
                                            </ul>
                                            <div class="progress mt-2">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <fieldset *ngIf="step1">
                                        <div class="form-card mt-3">
                                            <div class="row mb-2">
                                                <div class="col-12">
                                                    <h6 class="">Basic Configuration</h6>
                                                </div>
                                            </div>
                                            <div class="row align-items-center mb-3">
                                                <div class="col-lg-auto col-md-3 minwidth-responsive">
                                                    <label for="">Assign Product</label>
                                                </div>
                                                <div class="col-lg col-md-9">
                                                    <div class="form-check pl-0">
                                                        <input class="custom1-chkboxradio" type="checkbox" name="first"
                                                            id="market_price" value="market_price_value"
                                                            [(ngModel)]="assign_marketplace">
                                                        <label class="mx-2" for="market_price">Market Place</label>
                                                    </div>
                                                    <div class="form-check pl-0">
                                                        <input class="custom1-chkboxradio" type="checkbox" name="secomd"
                                                            id="corporate" value="corporate_value"
                                                            [(ngModel)]="assign_corp" (change)="CorporateChange()">
                                                        <label class="mx-2" for="corporate">Corporate</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" *ngIf="assign_corp">
                                                <div class="col-lg-auto col-md-3 minwidth-responsive">
                                                    <label for="">Select Corporate</label>
                                                </div>
                                                <div class="col-lg col-md-9">
                                                    <ng-select multiple="true" name="corpname" id="corpid" class=" mb-2"
                                                        [(ngModel)]="corp_id" (change)="getcorporate('0')">
                                                        <ng-option value="undefined" disabled>--Select Corporate--
                                                        </ng-option>
                                                        <ng-option *ngFor="let corp of corpList"
                                                            [value]="corp.add_corporate_id">
                                                            {{corp.enterprise_name}} ({{corp.corp_name}})
                                                        </ng-option>
                                                    </ng-select>

                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card shadow-none p-0 mt-2 mb-2" *ngIf="tempcoprlist.length > 0">
                                                        <div class="card-header px-2">
                                                            <h6 class="mb-0 font600">Corporate Prices</h6>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="row align-items-center my-1" *ngFor="let corp of tempcoprlist; let i=index">
                                                                <div class="col-md-2 pr-lg-0">
                                                                    <label for="">{{corp.enterprise_name}}</label>
                                                                </div>
                                                                <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                                    <input type="text" class="form-control" name="Corporate_price{{corp.enterprise_name}}" [(ngModel)]="corp.Corporate_price" onpaste="return false;" ondrop="return false;"
                                                                        autocomplete="off" min="0" maxlength="15" (keypress)="commonFunctionService.amountValidator($event)">
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                            <div class="col-lg-2 align-self-center mt-2">
                                                                <div class="form-check pl-0">
                                                                        <input id="showqtyPrice" class="custom1-chkboxradio ml-0 mr-2" type="checkbox" name= "showqtyPrice" [(ngModel)]="showqtyPrice">
                                                                        <label for="showqtyPrice" class=""> Add Quantity Wise Price</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10 mt-sm-0 mt-2" *ngIf="showqtyPrice">
                                                                    <div class="row mb-2" >
                                                                            <div class="col-lg-2 col-md pr-0 mb-sm-0 mb-2 align-self-end">
                                                                                
                                                                                <ng-select [(ngModel)]="selectedCorpid" name="corpName1">
                                                                                    <option value="" selected disabled>Select Corporate</option>
                                                                                    <ng-option *ngFor="let obj of tempcoprlist"
                                                                                        [value]="obj.add_corporate_id">{{obj.enterprise_name}}</ng-option>
                                                                                </ng-select>
                                                                            </div>
                                                                            <div class="col-lg-1 col-md-2 col-3 pr-0 mb-sm-0 mb-2">
                                                                                <label for="">Qty</label>
                                                                                <input type="text" class="form-control" name="minQty" [(ngModel)]="minQty" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                                                    min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                                                            </div>
                                                                            <div class="col-auto align-self-end mb-sm-2 mb-3 pr-0">
                                                                                <label>to</label>
                                                                            </div>
                                                                            <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                                                <label for="">Qty</label>
                                                                                <input type="text" class="form-control" name="maxQty" [(ngModel)]="maxQty" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                                                    min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                                                            </div>
                                                                            <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                                                <label for="">Price</label>
                                                                                <input type="text" class="form-control" name="qtyprice"  [(ngModel)]="qtyprice" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                                                    min="0" maxlength="" (keypress)="commonFunctionService.amountValidator($event)">
                                                                            </div>
                                                                            <div class="col-auto d-flex align-self-end">
                                                                                <button class="btn btn-theme btn-sm px-3 py-1" (click)="addCorpWisepeise()">
                                                                                    <i class="fa fa-plus"></i> Add
                                                                                </button>
                                                                            </div>
                                        
                                                                          
                                                                        </div>
                                                            </div>
                                                        </div>
                                                        <div class="row" *ngIf="CorpWisepeiseList.length>0">
                                                              <div class="col-lg-10 offset-lg-2" *ngIf="showqtyPrice == true">
                                                                                <div class="table-responsive white-space-pre mt-1">
                                                                                    <table class="table table-bordered vertically-middle thead mb-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col" class="text-center w10">Sr. No.</th>
                                                                                                <th scope="col">Corporate Name</th>
                                                                                                <th scope="col">Min Qty.</th>
                                                                                                <th scope="col">Max Qty.</th>
                                                                                                <th scope="col">Price</th>
                                                                                                <th scope="col" class="text-center w20">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr *ngFor="let obj of CorpWisepeiseList;let i=index">
                                                                                                <td class="text-center">{{i+1}}</td>
                                                                                                <td>{{obj.selectedCorpName}}</td>
                                                                                                <td>{{obj.minQty}}</td>
                                                                                                <td>{{obj.maxQty}}</td>
                                                                                                <td>{{obj.qtyprice}}</td>
                                                                                                <td class="text-center">
                                                                                                    <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editCorpWisepeise(i)">
                                                                                                        <i class="fa fa-edit mr-lg-1"></i>
                                                                                                        <span class="d-lg-inline d-none">Edit</span>
                                                                                                    </button>
                                                                                                    <button type="button" class="btn btn-sm btn-danger" (click)="removeCorpWisepeise(i)">
                                                                                                        <i class="fa fa-trash mr-lg-1"></i>
                                                                                                        <span class="d-lg-inline d-none">Delete</span>
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                        </div>
                                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="row mb-2">
                                                <div class="col-lg-4 col-md-6 brandnamebox">
                                                    <div class="form-group brandnameblock">
                                                        <label>Brand Name
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <a class="position-absolute addbrandnew text-decoration-none end-0"
                                                            href="javascript:void()" data-target="#addbrand"
                                                            data-toggle="modal">
                                                            <i class="fa fa-plus"></i> Add New Brand</a>
                                                        <!-- <ng-select [(ngModel)]="brand_id" name="brandName1">
                                                            <option value="" selected disabled>Select Brand</option>
                                                            <ng-option *ngFor="let obj of brandNameList"
                                                                [value]="obj.brand_name_id">{{obj.name}}</ng-option>
                                                        </ng-select> -->
                                                        <select class="form-control" [(ngModel)]="brand_id"
                                                            name="brandName1" placeholder="Enter Brand Name">
                                                            <option value="" selected disabled>--Select Brand--</option>
                                                            <option *ngFor="let obj of brandNameList"
                                                                [value]="obj.brand_name_id">
                                                                {{obj.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="form-group ">
                                                        <label for="productTitle">Product Name
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <input type="text" class="form-control mb-2" id="productTitle"
                                                            [(ngModel)]="productName" name="productName"
                                                            autocomplete="off" placeholder="Enter Product Name"
                                                            maxlength="500">
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="hsn">HSN No.
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <input type="text" class="form-control mb-2" id="hsn"
                                                            [(ngModel)]="hsn"
                                                            onkeypress="return /[0-9]/i.test(event.key)" name="hsn"
                                                            autocomplete="off" placeholder="Enter HSN No." maxlength="8"
                                                            minlength="4" >
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="gstrate">GST Rate(%)
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <!-- <input type="text" class="form-control mb-2" id="gstrate" [(ngModel)]="gstRate" onkeypress="return /[0-9]/i.test(event.key)"
                                        name="gstrate" autocomplete="off" placeholder="Enter GST Rate" maxlength="2"> -->
                                                        <select class="form-control" [(ngModel)]="gstRate"
                                                            name="gstRate" placeholder="Enter GST Rate">
                                                            <option value="undefined" selected disabled>Select GST Value
                                                            </option>
                                                            <option *ngFor="let obj of gstList" [value]="obj.value">
                                                                {{obj.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="min_quantity">Minimum Order Quantity
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <input type="text" name="minorderqu" autocomplete="off"
                                                            [(ngModel)]="minOrderQuantity" maxlength="5"
                                                            onkeypress="return /[0-9]/i.test(event.key)"
                                                            class="form-control mb-2" id="min_quantity"
                                                            placeholder="Enter Minimum Quantity">
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="quantity">Product Tags </label>
                                                        <input type="text" min="0" class="form-control mb-2"
                                                            [(ngModel)]="productTags" name="productTags"
                                                            placeholder="Enter Product Tags" autocomplete="off"
                                                            maxlength="50">
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="category">Category
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <select name="category" id="category" class="form-control mb-2"
                                                            [(ngModel)]="category" (change)="categoryChanged()" [disabled]='editable_item_id'>
                                                            <option value="undefined" disabled>Select Category</option>
                                                            <option *ngFor="let cat of categoryList"
                                                                [value]="cat.product_category_id">
                                                                {{cat.category_name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="category">Sub Category
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <select name="subCategory" id="sub-category"
                                                            [(ngModel)]="subCategory" (change)="getSpecificationLabel()"
                                                            class="form-control mb-2">
                                                            <option value="undefined" disabled>Select Sub Category</option>
                                                            <option *ngFor="let cat of subCategoryList"
                                                                [value]="cat.product_category_id">
                                                                {{cat.category_name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-12" *ngFor="let nexted of nextedsubcategory;let next=index;">
                                                    <div class="form-group ">
                                                        <label for="category">Nexted {{next+1}} Sub Category
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <select name="subCategory{{next}}" id="sub-category{{next}}" class="form-control mb-2" (change)="getnextedSubCategory(nexted.selected,next+1)" [(ngModel)]="nexted.selected"
                                                            [disabled]="isView">
                                                            <option value="undefined" disabled>Select Sub Category</option>
                                                            <option *ngFor="let cat1 of nexted.res" [value]="cat1.product_category_id">
                                                                {{cat1.category_name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="country_id">Country Of Manufacture
                                                            <span class="asterisk">*</span>

                                                        </label>
                                                        <select name="countryId" id="country_id" [(ngModel)]="countryId"
                                                            class="form-control mb-2">
                                                            <option value="undefined" disabled>-: Select Country :-</option>
                                                            <option *ngFor="let con of countryList"
                                                                value="{{con.country_id}}">
                                                                {{con.country_name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-12">
                                                    <div class="form-group ">
                                                        <label for="quantity">Manufacture Year </label>
                                                        <span class="asterisk">*</span>
                                                        <!-- <input type="text" min="0" class="form-control mb-2" [(ngModel)]="manufactureYear" name="manufactureYear" onkeypress="return /[0-9]/i.test(event.key)"
                                                            placeholder="Enter Manufacture Year" autocomplete="off" minlength="4"
                                                            maxlength="4"> -->
                                                        <select class="form-control" [(ngModel)]="manufactureYear"
                                                            name="manufactureYear" placeholder="Enter Year">
                                                            <option value="undefined" selected disabled>Select Year
                                                            </option>
                                                            <option *ngFor="let obj of yearList"
                                                                [value]="obj.year_value">
                                                                {{obj.year_value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-12">
                                                    <h6>Product Specifications
                                                        <span class="asterisk">*</span>
                                                    </h6>
                                                </div>
                                            </div>

                                            <!-- <div class="row mb-sm-0 mb-2">
                                                <div class=" col-md-5">
                                                    <label for="lable">Label </label>
                                                    <input type="text" min="0" class="form-control mb-2" maxlength="50"
                                                        id="lable" autocomplete="off" [(ngModel)]="lable" name="lable"
                                                        placeholder="Enter Label">
                                                </div>
                                                <div class="col-md-5">
                                                    <label for="value">Value </label>
                                                    <input type="text" min="0" class="form-control mb-2" maxlength="50"
                                                        id="value" autocomplete="off" [(ngModel)]="value" name="value"
                                                        placeholder="Enter Value">
                                                </div>
                                                <div class="col-md-2 pl-sm-0">
                                                    <button type="button" class="btn btn-sm btn-theme p-1 px-3  mt-sm-4"
                                                        (click)="addSpecifications()">
                                                        <i class="fa fa-plus"></i> Add </button>
                                                </div>
                                            </div> -->
                                            <div class="table-responsive white-space-pre mt-2">
                                                <table class="table table-bordered vertically-middle"
                                                    *ngIf="lableList.length > 0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class="w10 text-center">Sr. No.</th>
                                                            <th scope="col">Label</th>
                                                            <th scope="col" class="mw-118">Value</th>
                                                            <!-- <th scope="col" *ngIf="!isView" class="text-center w20">
                                                                Action</th> -->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let obj of lableList let i=index">
                                                            <td class="text-center">{{i+1}}</td>
                                                            <td>{{obj.label_name}}</td>
                                                            <td *ngIf="!obj.is_custom">
                                                                <select [(ngModel)]="obj.selectedValue"
                                                                    name="selectedValue_{{i+1}}" class="form-control">
                                                                    <option *ngFor="let values of obj.lableValues"
                                                                        value="{{values.product_specification_label_value_id}}">
                                                                        {{values.lebal_value}}</option>
                                                                </select>
                                                            </td>
                                                            <!-- <td *ngIf="obj.is_custom">{{obj.label_valuelist}}</td>
                                                            <td *ngIf="obj.is_custom" class="text-center">
                                                                <button type="button" class="btn btn-sm btn-theme "
                                                                    (click)="editSpecifications(i)">
                                                                    <i class="fa fa-edit mr-sm-1"></i>
                                                                    <span class="d-sm-inline d-none">Edit</span>
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn-danger m-1"
                                                                    (click)="removeSpecifications(i)">
                                                                    <i class="fa fa-trash mr-sm-1"></i>
                                                                    <span class="d-sm-inline d-none">Delete</span>
                                                                </button>
                                                            </td> -->
                                                            <!-- <td *ngIf="!obj.is_custom">

                                                            </td> -->
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="row mr-0">
                                                <div class="col-10">
                                                    <label for="keyFeature">Key Feature
                                                        <span class="asterisk">*</span>
                                                    </label>
                                                    <input type="text" min="0" class="form-control mb-3" id="keyFeature"
                                                        [(ngModel)]="key" name="key" *ngIf="!isView"
                                                        placeholder="Enter Key Feature" maxlength="500"
                                                        autocomplete="off">
                                                </div>
                                                <div class="col-2 pl-0">
                                                    <button type="button" class="btn btn-sm btn-theme px-3 mt-4 me-2"
                                                        (click)="addKeyFeatures()" *ngIf="!isView">
                                                        <i class="fa fa-plus mr-sm-1"></i>
                                                        <span class="d-sm-inline d-none">Add</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="table-responsive white-space-pre mt-2">
                                                <table class="table table-bordered vertically-middle"
                                                    *ngIf="keyFeatures.length > 0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class="w10 text-center">Sr. No.</th>
                                                            <th scope="col">Key Feature</th>
                                                            <th scope="col" *ngIf="!isView" class="text-center w20">
                                                                Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let key of keyFeatures let i=index">
                                                            <td class="text-center">{{i+1}}</td>
                                                            <td class="text-wrap">{{key.key}}</td>
                                                            <td *ngIf="!isView" class="text-center">
                                                                <button type="button" class="btn btn-sm btn-theme"
                                                                    (click)="editKeyFeatures(i)">
                                                                    <i class="fa fa-edit mr-sm-1"></i>
                                                                    <span class="d-sm-inline d-none">Edit</span>
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn-danger ms-1"
                                                                    (click)="removeKeyFeatures(i)">
                                                                    <i class="fa fa-trash mr-sm-1"></i>
                                                                    <span class="d-sm-inline d-none">Delete</span>
                                                                </button>


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="form-group mb-3">
                                                <label for="discription">Product Description
                                                    <span class="asterisk">*</span>
                                                </label>
                                                <!-- <div class="NgxEditor__Wrapper width100 ngxcstm min-hy-200">
                                                        <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="productDiscription" [config]="editorConfig"></angular-editor>
                                                    </div> -->
                                                <!-- <textarea class="form-control2 mb-2" id="discription" autocomplete="off"
                                                    name="productDiscription" [(ngModel)]="productDiscription"
                                                    placeholder="Product Description" style="height: 100px;"
                                                    maxlength="500"></textarea> -->
                                                <div class="NgxEditor__Wrapper width100 ngxcstm min-hy-200">
                                                    <angular-editor [placeholder]="'Enter text here...'"
                                                        [(ngModel)]="productDiscription" [config]="editorConfig"
                                                        [ngModelOptions]="{standalone: true}"></angular-editor>
                                                </div>
                                            </div>

                                            <!-- <div class="row">
                            <div class="col-8">
                                <input type="file" id="Photograph" class="form-control mb-2"
                                    (change)="onFileChnage($event)" [disabled]="isView" #inputFile>
                            </div>
                            <div class="col-3" *ngIf="!isView">
                                <button type="button" class="btn btn-sm btn-theme p-1 px-3  " (click)="onUpload()"><i
                                        class="fa fa-plus"></i> Add Image</button>
                            </div>
                            <div class="col-md-12">
                                <table class="table w-100 table-bordered vertically-middle cstmheadtable"
                                    *ngIf="uploadedGalaryFiles.length>0">
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>File Name</th>
                                        <th *ngIf="!isView">Action</th>
                                    </tr>
                                    <tbody>
                                        <tr *ngFor="let ob of uploadedGalaryFiles let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{ob.file_name}}</td>
                                            <td *ngIf="!isView"><a href="javascript:void(0)"
                                                    class="btn btn-sm btn-theme p-1 px-3 "
                                                    (click)="deleteFile(ob.id)"><i class="fa fa-trash"></i> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                                        </div>
                                        <input type="button" name="next" class="next action-button"
                                            (click)="saveBasicConfiguration()" value="Next" />
                                        <input type="button" name="previous" class="previous action-button-previous"
                                            (click)="backToListing()" value="Back" />

                                    </fieldset>

                                    <fieldset *ngIf="step2">

                                        <div class="form-card mt-3">

                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h6 class="mb-0">Select Attribute

                                                        <button type="button"
                                                            class="btn btn-sm btn-theme ms-2 float-right mb-1"
                                                            (click)="openAttributeModel()">
                                                            <i class="fa fa-plus"></i> Add Attribute Value</button>
                                                    </h6>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-sm-12 ">
                                                    <div class="row align-items-center mb-2">
                                                        <div class="col-sm-4 mb-sm-2 mb-3">    
                                                            <label>First Attribute </label>          
                                                         <!-- <ng-select name="productsList1"
                                                                placeholder="-- Select First Attribute --" [(ngModel)]="attribute1" (change)="selectAttribute(1,attribute1)" [disabled]="addAttributeCombination && addAttributeCombination.length>0">
                                                                <ng-option *ngFor="let attval of AttributeLableList"
                                                                    value="{{attval.attribute_id}}">{{attval.attribute_lable}}
                                                                </ng-option>
                                                            </ng-select> -->
                                                        </div>

                                                        <div class="col-sm-4 mb-sm-2 mb-3">
                                                            <label>Second Attribute </label>  
                                                            <!-- <ng-select name="productsList2"
                                                                placeholder="-- Select Second Attribute --" [(ngModel)]="attribute2" (change)="selectAttribute(2,attribute2)" [disabled]="addAttributeCombination && addAttributeCombination.length>0">
                                                                <ng-option *ngFor="let attval of AttributeLableList"
                                                                    value="{{attval.attribute_id}}">{{attval.attribute_lable}}
                                                                </ng-option>
                                                            </ng-select> -->
                                                        </div>
                                                   
                                                    </div>
                                                    <div class="row align-items-center mb-2">
                                                        <div class="col-sm-4 mb-sm-2 mb-3">
                                                            <ng-select name="productsList3"
                                                                placeholder="-- Select First attribute value --" [(ngModel)]="color">
                                                                <ng-option *ngFor="let attval of AttributeColor"
                                                                    value="{{attval.value_lable}}">{{attval.value_lable}}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>

                                                        <div class="col-sm-4 mb-sm-2 mb-3">
                                                        <ng-select name="productsList4"
                                                                placeholder="-- Select Second Attribute value --" [(ngModel)]="size">
                                                                <ng-option *ngFor="let attval of AttributeSize"
                                                                    value="{{attval.value_lable}}">{{attval.value_lable}}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>
                                                        <div
                                                            class="col-lg-2 col-md-4 mb-sm-2 mb-2 text-sm-left text-center align-self-end">
                                                            <button type="button"
                                                                class="btn btn-sm btn-theme px-3 me-2 mt-lg-3 mb-1"
                                                                (click)="add()">
                                                                <i class="fa fa-plus"></i> Add </button>

                                                            <button type="button"
                                                                class="btn btn-sm btn-theme px-3 mt-lg-3 ml-2 mb-1"
                                                                (click)="reset()">
                                                                <i class="fa fa-refresh"></i> Reset </button>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive white-space-pre">
                                                                <table class="table table-bordered vertically-middle"
                                                                    *ngIf="addAttributeCombination.length>0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="w10">S.No.</th>
                                                                            <th scope="col">First Attribute</th>
                                                                            <th scope="col">Second Attribute</th>
                                                                            <th scope="col" class="text-center w20">
                                                                                Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let atr of addAttributeCombination; let i = index">
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{atr.color}}</td>
                                                                            <td>{{atr.size}}</td>
                                                                            <td class="text-center">
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-danger"
                                                                                    (click)="removeConfig(i)">
                                                                                    <i class="fa fa-trash mr-sm-1"></i>
                                                                                    <span
                                                                                        class="d-sm-inline d-none">Delete</span>
                                                                                </button>

                                                                                <!-- <button type="button" class="btn btn-sm btn-warning m-1"><i class="fa fa-edit"></i>
                                                            </button> -->
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                            <!-- <h6>For Bundle Product</h6>
                                <br>
                                    <div class="form-group">
                                        <label for="productTitle">Min Quantity <span class="asterisk">*</span></label>
                                        <input type="text" [(ngModel)]="minQuantity" class="form-control mb-2" id="productTitle" name="minquant" autocomplete="off"
                                            placeholder="Enter Minimum Quantity" maxlength="50">
                                    </div>
                                    <div class="form-group">
                                        <label for="productTitle">Max Quantity <span class="asterisk">*</span></label>
                                        <input type="text" [(ngModel)]="maxQuantity" class="form-control mb-2" id="productTitle" name="maxquant" autocomplete="off"
                                            placeholder="Enter Maximum Quantity" maxlength="50">
                                    </div>
                                    <div class="form-group">
                                        <label for="productTitle">Bundle Price <span class="asterisk">*</span></label>
                                        <input type="text" [(ngModel)]="bundlePrice" class="form-control mb-2" id="productTitle" name="maxquant" autocomplete="off"
                                            placeholder="Enter Minimum Quantity" maxlength="50">
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-sm btn-success"  (click)="addBundleQuantity()"> Add
                                        </button>&nbsp;&nbsp;
                                    </div> -->
                                            <!-- <div class="row">
                                <table class="table " *ngIf="bundleAtribute.length > 0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr. No</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Bundle Price</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let atr of bundleAtribute; let i = index">
                                            <th>{{i+1}}</th>
                                            <td>{{atr.min}} - {{atr.max}}</td>
                                            <td>{{atr.bundlePrice}}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm btn-danger" (click)="removeBundleConfig(i)"><i class="fa fa-trash"></i>
                                                </button>
        
                                                <button type="button" class="btn btn-sm btn-warning m-1" (click)="editBundleConfig(i)"><i class="fa fa-edit"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->


                                        </div>
                                        <div>
                                            <input type="button" name="next" class="next action-button"
                                                (click)="saveAttributeDetail()" value="Next" />
                                            <input type="button" name="previous" class="previous action-button-previous"
                                                (click)="getBasicConfiguration()" value="Previous" />
                                        </div>
                                    </fieldset>

                                    <fieldset *ngIf="step3">
                                        <div class="form-card mt-3">

                                            <!-- New Style Upload File code Start  -->
                                            <div class="card shadow-none text-left d-none">
                                                <!-- <div class="card-header py-2">
                                                        <h6 class="mb-0 font-weight-bold">
                                                            Product Photos (0/5)
                                                        </h6>
                                                    </div> -->
                                                <!-- <div class="card-body">
                                                        <label class="mb-2">Upload your recommendations for product images.</label>
                                                        <h6 class="font-weight-bold">Hide image guidelines</h6>
                                                        <label>
                                                            <span class="font-weight-bold font9">Resolution</span>: Use color images with minimum resolution of (1500 x 1500 px) Guidelines:
                                                            Upload authentic product photos taken in bright lightning. JPEG is preferred in image format,
                                                            but you also may use JPG and PNG files.</label>
                                                        <label class="font-weight-bold my-2">
                                                            <button class="btn btn-sm px-4 rounded-pill rounded-pill-theme text-dark mr-1" data-toggle="modal" data-target="#branch_details">
                                                                <i class="fa fa-plus mr-1"></i> Upload multiple files</button>
                                                            <span> or drag and drop 1 or more files below.</span>
                                                        </label>
                                                        <div class="row my-2">
                                                            <div class="card custom-card shadow-none text-center bg-white mb-sm-0 mb-4">
                                                                <div class="card-body p-0 d-flex flex-column justify-content-end">
                                                                    <img class="mb-1 px-4 py-1 productimg img-fluid" src="../../../assets/images/product/black_tshirt.png" alt="">
                                                                    <div class="row m-0">
                                                                        <div class="bg-secondary text-white col-6 p-0">
                                                                            <a class="">
                                                                                <i class="fa fa-plus"></i>
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-6 p-0 bg-secondary text-white">
                                                                            <a class="">
                                                                                <i class="fa fa-trash"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card custom-card shadow-none text-center bg-white mb-sm-0 mb-4">
                                                                    <div class="card-body p-0 d-flex flex-column justify-content-end">
                                                                        <img class="mb-1 px-4 py-1 productimg img-fluid" src="../../../assets/images/product/black_tshirt.png" alt="">
                                                                        <div class="row m-0">
                                                                            <div class="bg-secondary text-white col-6 p-0">
                                                                                <a class="">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </a>
                                                                            </div>
                                                                            <div class="col-6 p-0 bg-secondary text-white">
                                                                                <a class="">
                                                                                    <i class="fa fa-trash"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card custom-card shadow-none text-center bg-white mb-sm-0 mb-4">
                                                                        <div class="card-body p-0 d-flex flex-column justify-content-end">
                                                                            <img class="mb-1 px-4 py-1 productimg img-fluid" src="../../../assets/images/product/black_tshirt.png" alt="">
                                                                            <div class="row m-0">
                                                                                <div class="bg-secondary text-white col-6 p-0">
                                                                                    <a class="">
                                                                                        <i class="fa fa-plus"></i>
                                                                                    </a>
                                                                                </div>
                                                                                <div class="col-6 p-0 bg-secondary text-white">
                                                                                    <a class="">
                                                                                        <i class="fa fa-trash"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                        </div> -->
                                                <!-- <div class="row">
                                                            <div class="col-sm-10">
                                                                <div class="row">
                                                                    <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                                                        <div class="card-body d-flex justify-content-center align-items-center">
                                                                            <label for="fileupld" class="cursor-pointer">
                                                                                <div class="drag-drop">
                                                                                    <input type="file" id="fileupld" class="d-none">
                                                                                    <figure class="figure text-center mb-0">
                                                                                        <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                                                    </figure>
                                                                                    <p class="mb-1">Upload</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                                                        <div class="card-body d-flex justify-content-center align-items-center">
                                                                            <label for="fileupld" class="cursor-pointer">
                                                                                <div class="drag-drop">
                                                                                    <input type="file" id="fileupld" class="d-none">
                                                                                    <figure class="figure text-center mb-0">
                                                                                        <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                                                    </figure>
                                                                                    <p class="mb-1">Upload</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                                                        <div class="card-body d-flex justify-content-center align-items-center">
                                                                            <label for="fileupld" class="cursor-pointer">
                                                                                <div class="drag-drop">
                                                                                    <input type="file" id="fileupld" class="d-none">
                                                                                    <figure class="figure text-center mb-0">
                                                                                        <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                                                    </figure>
                                                                                    <p class="mb-1">Upload</p>
                                                                                </div>
                                                                            </label>
    
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                                                        <div class="card-body d-flex justify-content-center align-items-center">
                                                                            <label for="fileupld" class="cursor-pointer">
                                                                                <div class="drag-drop">
                                                                                    <input type="file" id="fileupld" class="d-none">
                                                                                    <figure class="figure text-center mb-0">
                                                                                        <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                                                    </figure>
                                                                                    <p class="mb-1">Upload</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                                                        <div class="card-body d-flex justify-content-center align-items-center">
                                                                            <label for="fileupld" class="cursor-pointer">
                                                                                <div class="drag-drop">
                                                                                    <input type="file" id="fileupld" class="d-none">
                                                                                    <figure class="figure text-center mb-0">
                                                                                        <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                                                    </figure>
                                                                                    <p class="mb-1">Upload</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                <!-- </div> -->
                                            </div>
                                            <!-- New Style Upload File code End  -->


                                            <div class="row">
                                                <div class="col-12">
                                                    <h6 class="mt-2">Configurations</h6>

                                                    <!-- <table class="table">
                                    <thead>
                                      <tr >
                                        <th scope="col" *ngFor="let col of table_col">{{col}}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor=" let res of product_configuratios">
                                            <td *ngFor=" let key of table_col">
                                              {{res[key]}}
                                            </td>
                                          </tr> 
                                    </tbody>
                                  </table> -->
                                                    <div class="table-responsive min-wdthTd">
                                                        <table
                                                            class="table table-bordered vertically-middle min-width118">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="w10 text-center">Sr. No.</th>
                                                                    <th scope="col">Description </th>
                                                                    <th scope="col">SKU
                                                                        <span class="asterisk">*</span>
                                                                    </th>
                                                                    <th scope="col">Price (₹)
                                                                        <span class="asterisk">*</span>
                                                                    </th>
                                                                    <th scope="col">MRP (₹)
                                                                        <span class="asterisk">*</span>
                                                                    </th>
                                                                    <th scope="col">Available stock
                                                                        <span class="asterisk">*</span>
                                                                    </th>

                                                                    <!-- <th scope="col">Purchase Price (₹)
                                                                        <span class="asterisk">*</span>
                                                                    </th> -->
                                                                    <!-- <th scope="col">Add Image
                                                                        <span class="asterisk">*</span>
                                                                    </th> -->
                                                                    <!-- <th scope="col"
                                                                        class="text-center actionbtn width-action thumb">
                                                                        Preview</th> -->
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let pro of product_configuratios; let i = index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td>
                                                                        {{pro.product_description}}
                                                                    </td>

                                                                    <td>
                                                                        <input type="text" autocomplete="off" min="0"
                                                                            maxlength="15" class="form-control my-1"
                                                                            id="productTitle" placeholder="Enter SKU"
                                                                            (keypress)="commonFunctionService.alphaNumericValidatorwithDash($event)"
                                                                            [(ngModel)]="pro.sku"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" autocomplete="off" min="0"
                                                                            maxlength="15" class="form-control my-1"
                                                                            id="productTitle" placeholder="Enter Price"
                                                                            (keypress)="commonFunctionService.amountValidator($event)"
                                                                            [(ngModel)]="pro.price"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" autocomplete="off" min="0"
                                                                            maxlength="10" class="form-control my-1"
                                                                            id="productTitle" placeholder="Enter MRP"
                                                                            (keypress)="commonFunctionService.amountValidator($event)"
                                                                            [(ngModel)]="pro.mrp"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" autocomplete="off" min="0"
                                                                            class="form-control my-1" id="productTitle"
                                                                            placeholder="Enter Quantity"
                                                                            (keypress)="commonFunctionService.numericCheck($event)"
                                                                            [(ngModel)]="pro.quantity" maxlength="10"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                    </td>

                                                                    <!-- <td>
                                                                        <input type="text" autocomplete="off" min="0" maxlength="10" class="form-control mb-2"
                                                                            id="productTitle" placeholder="Enter Purchase Price"
                                                                            (keypress)="commonFunctionService.amountValidator($event)"
                                                                            [(ngModel)]="pro.purchase_price" [ngModelOptions]="{standalone: true}">
                                                                    </td> -->
                                                                    <!-- <td>
                                                                        <div class="form-group mb-2 border">
                                                                            <input type="file" 
                                                                                autocomplete="off" min="0"
                                                                                class="form-control-file"
                                                                                id="productTitle"
                                                                                placeholder="Enter Quantity"
                                                                                (change)="onFileChnage($event,pro.product_id,pro.product_configuration_id,i)"
                                                                                #inputFile>
                                                                        </div>
                                                                    </td> -->
                                                                    <!-- <td class="text-center actionbtn width-action">
                                                                        <div
                                                                            class="img-size-custom mx-auto d-flex align-items-center">
                                                                            <img [src]="pro.imgurl" width="60"
                                                                                height="60"
                                                                                class="img-fluid text-center mx-auto d-block" #input>
                                                                        </div>
                                                                    </td> -->
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card shadow-none p-0 mt-2 mb-2"
                                                        *ngIf="tempcoprlist.length > 0">
                                                        <div class="card-header px-2">
                                                            <h6 class="mb-0 font600">Corporate Prices</h6>
                                                        </div>
                                                        <div class="card-body py-2"
                                                            *ngFor="let corp of tempcoprlist; let i=index">
                                                            <div class="row">
                                                                <div class="col-md-2 pr-lg-0">
                                                                    <label for="">{{corp.enterprise_name}}</label>
                                                                    <!-- <label for="">{{pro.product_description}}</label> -->
                                                                </div>
                                                            </div>
                                                            <div class="row align-items-center mt-1 mb-2"
                                                                *ngFor="let pro of corp.productCnf; let i = index">
                                                                <div class="col-md-2 pr-lg-0">
                                                                    <!-- <label for="">{{corp.enterprise_name}}</label> -->
                                                                    <label
                                                                        for="">{{pro.product_description.substring(pro.product_description.lastIndexOf("-",
                                                                        pro.product_description.lastIndexOf("-") -
                                                                        1)+1,pro.product_description.length)}}</label>
                                                                </div>

                                                                <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                                    <label>Price (₹) </label>
                                                                    <input type="text" class="form-control"
                                                                        [name]="corp.enterprise_name +''+(corp.productCnf[i].id || pro.product_configuration_id)"
                                                                        [(ngModel)]="corp.productCnf[i].corpPrice"
                                                                        autocomplete="off" min="0" maxlength="6"
                                                                        (keypress)="commonFunctionService.amountValidator($event)">
                                                                </div>
                                                                <!-- <div class="col my-md-0 my-2">
                                                                    <div class="form-check pl-0">
                                                                        <input class="custom1-chkboxradio mx-0" name="checkbox_{{i}}" type="checkbox" id="checkbox_{{i}}" [(ngModel)]="corp.is_selected" (click)="getCorpId(corp)">
                                                                        <label class="ml-2" for="checkbox_{{i}}">Quantity Wise Price</label>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-2 align-self-center mt-2">
                                                                    <div class="form-check pl-0">
                                                                        <input id="{{corp.add_corporate_id}}"
                                                                            class="custom1-chkboxradio ml-0 mr-2"
                                                                            type="checkbox" name="showqtyPrice{{corp.add_corporate_id}}"
                                                                            [(ngModel)]="corp.showqtyPrice">
                                                                        <label for="showqtyPrice" class=""> Add Quantity
                                                                            Wise Price</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-10 mt-sm-0 mt-2"
                                                                    *ngIf="corp.showqtyPrice">
                                                                    <div class="row mb-2">
                                                                        <!-- <div class="col-lg-2 col-md pr-0 mb-sm-0 mb-2 align-self-end"> -->
                                                                        <!-- <ng-select [(ngModel)]="selectedCorpid">
                                                                                    <ng-option value="undefined" disabled selected>Select Corporate</ng-option>
                                                                                    <ng-option *ngFor="let obj of tempcoprlist" [value]="obj.add_corporate_id">{{obj.enterprise_name}}
                                                                                    </ng-option>
                                                                                </ng-select> -->

                                                                        <!-- <ng-select [(ngModel)]="selectedCorpid" name="corpName1">
                                                                                    <option value="" selected disabled>Select Corporate</option>
                                                                                    <ng-option *ngFor="let obj of tempcoprlist"
                                                                                        [value]="obj.add_corporate_id">{{obj.enterprise_name}}</ng-option>
                                                                                </ng-select> -->
                                                                        <!-- </div> -->
                                                                        <div
                                                                            class="col-lg-2 col-md-2 col-3 pr-0 mb-sm-0 mb-2">
                                                                            <label for="">Min Quantity</label>
                                                                            <input type="text" class="form-control"
                                                                                name="minQty" [(ngModel)]="corp.minQty"
                                                                                autocomplete="off" min="0" maxlength="5"
                                                                                onkeypress="return /[0-9]/i.test(event.key)">
                                                                        </div>
                                                                        <div
                                                                            class="col-auto align-self-end mb-sm-2 mb-3 pr-0">
                                                                            <label>to</label>
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2 col-3 pr-0">
                                                                            <label for="">Max Quantity</label>
                                                                            <input type="text" class="form-control"
                                                                                name="maxQty" [(ngModel)]="corp.maxQty"
                                                                                autocomplete="off" min="0" maxlength="5"
                                                                                onkeypress="return /[0-9]/i.test(event.key)">
                                                                        </div>
                                                                        <div class="col-lg-2 col-md-2 col-3 pr-0">
                                                                            <label for="">Bundle Price (₹) </label>
                                                                            <input type="text" class="form-control"
                                                                                name="qtyprice"
                                                                                [(ngModel)]="corp.qtyprice"
                                                                                autocomplete="off" min="0" maxlength=""
                                                                                (keypress)="commonFunctionService.amountValidator($event)">
                                                                        </div>
                                                                        <div class="col-auto d-flex align-self-end">
                                                                            <button class="btn btn-theme btn-sm px-3"
                                                                                (click)="addCorpWisepeise(corp)">
                                                                                <i class="fa fa-plus"></i> Add
                                                                            </button>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="corp.CorpWisepeiseList.length>0">
                                                                <div class="col-lg-10 offset-lg-2"
                                                                    *ngIf="corp.showqtyPrice == true">
                                                                    <div class="table-responsive white-space-pre mt-1">
                                                                        <table
                                                                            class="table table-bordered vertically-middle thead mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"
                                                                                        class="text-center w10">Sr. No.
                                                                                    </th>
                                                                                    <th scope="col">Corporate Name</th>
                                                                                    <th scope="col">Min Qty.</th>
                                                                                    <th scope="col">Max Qty.</th>
                                                                                    <th scope="col">Price</th>
                                                                                    <th scope="col"
                                                                                        class="text-center w20">Action
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let obj of corp.CorpWisepeiseList;let i=index">
                                                                                    <td class="text-center">{{i+1}}</td>
                                                                                    <td>{{obj.selectedCorpName}}</td>
                                                                                    <td>{{obj.minQty}}</td>
                                                                                    <td>{{obj.maxQty}}</td>
                                                                                    <td>{{obj.qtyprice}}</td>
                                                                                    <td class="text-center">
                                                                                        <button type="button"
                                                                                            class="btn btn-sm btn-theme text-white mr-2"
                                                                                            (click)="editCorpWisepeise(i, corp)">
                                                                                            <i
                                                                                                class="fa fa-edit mr-lg-1"></i>
                                                                                            <span
                                                                                                class="d-lg-inline d-none">Edit</span>
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            class="btn btn-sm btn-danger"
                                                                                            (click)="removeCorpWisepeise(i,corp)">
                                                                                            <i
                                                                                                class="fa fa-trash mr-lg-1"></i>
                                                                                            <span
                                                                                                class="d-lg-inline d-none">Delete</span>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <!-- <div class="row mb-2">
                                                <div class="col-sm-2 text-md-right">
                                                    <h6 class="mb-0"> Accept Orders As Per:
                                                        <span class="asterisk">*</span>
                                                    </h6>
                                                </div>

                                                <div *ngFor="let order of getOrderAcceptList; index as i" class="col-sm-2 col-4">
                                                    <div class="form-check pl-0" *ngIf="order.attr">
                                                        <input type="radio" class="mt-sm-1 mt-0 form-check-input" [value]="order.id" [(ngModel)]="asPerOrder" name="asperorder" id="asperprices_{{i+1}}">
                                                        <label class="form-check-label ml-sm-4" for="asperprices_{{i+1}}">{{order.varibale_name}}</label>
                                                    </div>
                                                </div>
                                                

                                            </div> -->

                                            <div class="row mb-2" *ngIf="assign_marketplace== true">
                                                <div class="col-sm-2">
                                                    <h6 class="mb-0">Quantity wise price
                                                        <span class="asterisk">*</span>
                                                    </h6>
                                                </div>

                                                <!-- <div *ngFor="let pricing of getPricingList; index as i" class="col-sm-2 col-6">
                                                    <div class="form-check pl-0">
                                                        <input type="radio" class="mt-sm-1 mt-0 form-check-input" [value]="pricing.id" [(ngModel)]="asPerPrice" [checked]="asPerPrice"
                                                            name="asperprice" id="asperpriceb_{{i+1}}">
                                                        <label for="asperpriceb_{{i+1}}" class="form-check-label ml-sm-4">{{pricing.varibale_name}}</label>
                                                    </div>
                                                </div> -->
                                                <div class="col-sm-6">
                                                    <div class="form-check pl-0">
                                                        <input type="checkbox" class="mt-sm-1 mt-0 custom1-chkboxradio"
                                                            [value]="pricingId" [(ngModel)]="asPerPrice"
                                                            (change)="onChange($event)" name="asperprice"
                                                            id="as_per_bundle">
                                                        <label class="form-check-label ml-sm-2 mt-1"
                                                            for="as_per_bundle">Quantity Wise Price<b> (Market
                                                                Place)</b></label>
                                                    </div>
                                                </div>

                                                <!-- Bundled:  <input type="radio" value="asperbund" [(ngModel)]="asPerPrice"  name="asperprice"  id="asperpriceb">
                          Per Item:  <input type="radio" value="asperitem" [(ngModel)]="asPerPrice" name="asperprice" id="asperpricep"> -->
                                            </div>
                                            <div class="row" *ngIf="asPerPrice == 211">
                                                <div class="col-12">
                                                    <h6>For Bundle Product</h6>
                                                    <div class="row">
                                                        <div class="col-lg-3 col-md-6">
                                                            <div class="form-group">
                                                                <label for="min">Min Quantity
                                                                    <span class="asterisk">*</span>
                                                                </label>
                                                                <input type="text" [(ngModel)]="minQuantity"
                                                                    onkeypress="return /[0-9]/i.test(event.key)"
                                                                    class="form-control mb-2" id="min" name="minquant"
                                                                    autocomplete="off" placeholder="" maxlength="4">
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-3 col-md-6" *ngIf="!noLimit">
                                                            <div class="form-group">
                                                                <label for="max">Max Quantity
                                                                    <span class="asterisk">*</span>
                                                                </label>
                                                                <input type="text" [(ngModel)]="maxQuantity"
                                                                    onkeypress="return /[0-9]/i.test(event.key)"
                                                                    class="form-control mb-2" id="max" name="maxquant"
                                                                    autocomplete="off" placeholder="" maxlength="4">
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-2 align-self-center mb-lg-0 my-2">
                                                            <div class="input-group">
                                                                <input type="checkbox" [(ngModel)]="noLimit"
                                                                    class="mt-sm-1 mt-0 custom1-chkboxradio" id="check"
                                                                    name="noLimit" autocomplete="off">
                                                                <label for="check" class="ml-2">Make Bundle price
                                                                    same</label>

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-6">
                                                            <div class="form-group">
                                                                <label for="pr">Bundle Price (₹)
                                                                    <span class="asterisk">*</span>
                                                                </label>
                                                                <input type="text" [(ngModel)]="bundlePrice"
                                                                    onkeypress="return /[0-9]/i.test(event.key)"
                                                                    class="form-control mb-2" id="pr" name="prquant"
                                                                    autocomplete="off" placeholder="" maxlength="15">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-1 col-md-2 align-self-center mt-sm-3">
                                                            <button type="button" class="btn btn-sm btn-theme px-3"
                                                                (click)="addBundleQuantity()">
                                                                <i class="fa fa-plus"></i> Add
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="row my-2" *ngIf="bundleAtribute.length > 0">
                                                        <div class="col-12">
                                                            <div class="table-responsive white-space-pre">
                                                                <table
                                                                    class="table table-bordered vertically-middle mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="w10 text-center">Sr.
                                                                                No.
                                                                            </th>
                                                                            <th scope="col">Quantity</th>
                                                                            <th scope="col">Bundle Price (₹)</th>
                                                                            <th scope="col" class="text-center w20">
                                                                                Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let atr of bundleAtribute; let i = index">
                                                                            <td class="text-center">{{i+1}}</td>
                                                                            <td>{{atr.min_qty}} - {{atr.max_qty ?
                                                                                atr.max_qty
                                                                                : ' Above'}}
                                                                            </td>
                                                                            <td>{{atr.price}}</td>
                                                                            <td class="text-center">
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-theme"
                                                                                    (click)="editBundleAttribute(i)">
                                                                                    <i class="fa fa-edit mr-sm-1"></i>
                                                                                    <span
                                                                                        class="d-sm-inline d-none">Edit</span>
                                                                                </button>
                                                                                <button type="button"
                                                                                    class="btn btn-sm btn-danger m-1"
                                                                                    (click)="removeBundleAttribute(i, atr)">
                                                                                    <i class="fa fa-trash mr-sm-1"></i>
                                                                                    <span
                                                                                        class="d-sm-inline d-none">Delete</span>
                                                                                </button>


                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="form-group mb-3">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <h6 class="fs-6">Images
                                                            <span class="asterisk">*</span>
                                                        </h6>
                                                    </div>
                                                    <div class="col-sm-7 col-10 pr-0">
                                                        <div class="form-group border">
                                                            <input type="file" id="Photograph"
                                                                class="form-control-file form-select pt-2 font8"
                                                                multiple (change)="onFileChnage($event)"
                                                                [disabled]="isView" #inputFile>
                                                        </div>
                                                        <label class="font75">
                                                            <b class="text-danger">Note :</b> Image should be 1500 px *
                                                            1500 px. You can upload
                                                            maximum 10 images.
                                                        </label>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="table-responsive white-space-pre mt-1">
                                                            <table
                                                                class="table w-100 thead table-bordered vertically-middle cstmheadtable"
                                                                *ngIf="uploadedGalaryFiles.length>0">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center w10">Sr. No.</th>
                                                                        <th>File Name</th>
                                                                        <th class="text-center">Preview</th>
                                                                        <!-- <th>Document Size(In KB)</th> -->
                                                                        <th *ngIf="!isView" class="text-center w20">
                                                                            Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <tr
                                                                        *ngFor="let ob of uploadedGalaryFiles;index as i">
                                                                        <td class="text-center">{{i+1}}</td>
                                                                        <td>{{ob.file_name}}
                                                                        </td>
                                                                        <td *ngIf="!ob.isSavedInDB" class="text-center">
                                                                            <img src={{ob.file_path}} width="40"
                                                                                height="40" alt="">
                                                                        </td>
                                                                        <td *ngIf="ob.isSavedInDB" class="text-center">
                                                                            <img src="../..{{ob.file_path}}" width="40"
                                                                                height="40" alt="">
                                                                        </td>
                                                                        <!-- <td>{{ob.file_size}}</td> -->
                                                                        <td *ngIf="!isView" class="text-center">
                                                                            <a href="javascript:void(0)"
                                                                                *ngIf="!ob.isSavedInDB"
                                                                                class="btn btn-sm btn-danger"
                                                                                (click)="deleteFile(ob.id)">
                                                                                <i class="fa fa-trash mr-sm-1"></i>
                                                                                <span
                                                                                    class="d-sm-inline d-none">Delete</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)"
                                                                                *ngIf="ob.isSavedInDB"
                                                                                class="btn btn-sm btn-danger"
                                                                                (click)="deleteFromDb(ob.id, ob.image_id)">
                                                                                <i class="fa fa-trash mr-sm-1"></i>
                                                                                <span
                                                                                    class="d-sm-inline d-none">Delete</span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <input type="button" name="next" class="next action-button" value="Submit"
                                                (click)="updateConfigurations()" />
                                            <input type="button" name="previous" class="previous action-button-previous"
                                                (click)="onPreviousButton()" value="Previous" />
                                            <!-- <input type="button" name="previous" class="previous action-button-previous" (click)="getAttributeDetail()" value="Previous" /> -->
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal fade" id="addbrand" tabindex="-1" aria-labelledby="addbrandLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md  modal-dialog-scrollable-new">
            <div class="modal-content">
                <div class="modal-header p-2">
                    <h6 class="modal-title" id="addtocartLabel">
                        <strong>Add New Brand</strong>
                    </h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetBrand()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-3">
                    <div class="p3">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="productTitle">Brand Name
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" [(ngModel)]="brand_name" class="form-control mb-2" id="productTitle"
                                    name="brand_name" autocomplete="off" placeholder="Enter Brand Name" maxlength="50">
                            </div>
                            <!-- <div class="form-group">
                                <label for="productTitle">Brand Code
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" [(ngModel)]="brandCode" class="form-control mb-2" name="brandCode" id="productTitle" autocomplete="off"
                                    placeholder="Enter Brand Name" maxlength="10">
                            </div> -->
                        </div>
                        <div class="col-sm-12 text-center pt-3">
                            <button type="button" class="btn btn-sm btn-theme" (click)="addBrandName()"> Submit</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  
        </div> -->
            </div>
        </div>
    </div>


    <!-- The Modal -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                <div class="modal-header p-2">
                    <h6 class="modal-title"> Add Attribute Value</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="resetAttributeValue()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- Modal body -->

                <div class="modal-body p-3">

                    <div class="form-group brandnamebox">
                        <label for="at-code">Select Attribute</label>
                        <ng-select name="attr_id" placeholder="--Select Base Product--" [(ngModel)]="selectedAttribute">
                            <ng-option *ngFor="let attr of allAttributeList" value="{{attr.attribute_id}}">
                                {{attr.attribute_lable}}</ng-option>
                        </ng-select>
                    </div>


                    <div class="form-group">
                        <label for="at-label">Attribute Value Code</label>
                        <input type="text" id="at-label" class="form-control" placeholder="Enter Attribute Value Code"
                            [(ngModel)]="valueCode" name="attributeLable" autocomplete="off" maxlength="50">
                    </div>
                    <div class="form-group">
                        <label for="at-label">Attribute Value Label</label>
                        <input type="text" id="at-label" class="form-control" placeholder="Enter Attribute Value Label"
                            [(ngModel)]="valueLabel" name="attributeLable" autocomplete="off" maxlength="50">
                    </div>
                    <div class="modalfooter mt-3">
                        <div class="text-center">
                            <button type="button" class="btn btn-sm btn-theme px-3 mr-2" (click)="addAttribute()"> Add
                            </button>

                            <button type="button" class="btn btn-sm btn-danger" (click)="resetAttributeValue()"> Reset
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Modal footer -->


            </div>
        </div>
    </div>


    <!-- <div class="modal" id="myModal2">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header">
                <h6 class="modal-title"> Add Attribute Value </h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div> -->

    <!-- Modal body -->

    <!-- <div class="modal-body p-3">

                <div class="form-group">
                    <label for="at-code">Attribute Value Code</label>
                    <input type="text" id="at-code" class="form-control" placeholder="Attribute Code"
                        [(ngModel)]="attributeValueCode" name="attributeValueCode">
                </div>


                <div class="form-group">
                    <label for="at-label">Attribute Value</label>
                    <input type="text" id="at-label" class="form-control" placeholder="Attribute Lable"
                        [(ngModel)]="attributeValue" name="attributeValue">
                </div>
            </div> -->

    <!-- Modal footer -->
    <!-- <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-theme" data-bs-dismiss="modal"
                    (click)="addAttributeValue()"> Add
                </button> -->
    <!-- <button type="button" class="btn btn-danger  btn-sm" data-bs-dismiss="modal">Cancel</button> -->
    <!-- </div>

        </div>
    </div>
</div> -->