<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid  bg-white">
            <div class="row">

                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="">Marketing Master</a>
                            </li>
                            <li class="breadcrumb-item newitm2  active" aria-current="page">View Gift Code</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-sm-12">

                    <div class="row mx-0 my-3">
                        <div class="col-sm-12 p-0">
                            <div class="bg-lyt ">
                                <div class="d-flex justify-content-between align-items-center py-3 px-2">
                                    <h6 class="mb-0 font-weight-bold">
                                    Gift Codes
                                    </h6>
                                    <button type="button" class="btn btn-sm px-3 py-1 rounded-pill btn-theme">
                                        <i class="fa fa-plus-circle mr-1"></i> Add New Gift Code</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 nopadding">
                        <ul class="nav nav-tabs newnav border-0" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Gift Codes
                                    <i class="fa fa-caret-right ml-3"></i>
                                </a>
                            </li>
                            <li class="nav-item ml-4">
                                <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Activity
                                    <i class="fa fa-caret-right ml-3"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content tab-contents border-0 p-0 mt-3" id="myTabContent">
                            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <h6 class="mb-0 font-weight-bold"> Edit Gift Codes</h6>
                                <div class="row my-3">
                                    <div class="col-sm-8 col-lg-9">
                                        <div class="card shadow-none bg-light box-min-height">
                                            <div class="card-body py-3">
                                                <h6 class="font-weight-bold"> BYS7-HGH84-HJGT-OI21</h6>
                                                <p class="mb-2">Available Balance</p>
                                                <h5 class="mb-0">₹ 1</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-lg-3">
                                        <div class="card shadow-none bg-light">
                                            <div class="card-body py-3">
                                                <label for="" class="font-weight-bold mb-2">Redeem For Customer</label>
                                                <select class="form-control">
                                                    <option>Search for a customer</option>
                                                </select>
                                                <div class="text-md-right">
                                                    <button type="button" class="btn btn-sm px-4 rounded-pill rounded-pill-theme text-dark rounded-theme mt-2">Redeem</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card shadow-none bg-light-theme mb-3">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm">
                                                <label for="" class="d-block text-uppercase">Issued</label>
                                                <strong>2022/09/03</strong>
                                            </div>
                                            <div class="col-sm">
                                                <label for="" class="d-block text-uppercase">Issued Value</label>
                                                <strong>₹2,000</strong>
                                            </div>
                                            <div class="col-sm">
                                                <label for="" class="d-block text-uppercase">Order</label>
                                                <strong>#01</strong>
                                            </div>
                                            <div class="col-sm">
                                                <label for="" class="d-block text-uppercase">Redeemed</label>
                                                <strong>2022/09/03</strong>
                                            </div>
                                            <div class="col-sm-4 px-lg-3 px-sm-0 px-3 col-lg-5">
                                                <label for="" class="d-block text-uppercase">Redeemed By</label>
                                                <strong>kiran.sarvabhotla@services.com</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-none mb-3">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-sm-6 mb-2">
                                                        <label>From</label>
                                                        <input type="text" class="form-control" value="Service India">
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <label>To</label>
                                                        <input type="text" class="form-control" value="servicesindi@gmail.com">
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label>Message</label>
                                                        <textarea class="form-control" style="height:100px !important;"> ATG Company 2022 Gift Card</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-sm-6 mr-auto">
                                                        <div class="row">
                                                            <div class="col-12 mb-2">
                                                                <label>Delivery Date</label>
                                                                <input type="date" class="form-control" value="29-12-2022">
                                                            </div>
                                                            <div class="col-12">
                                                                <label>Expiration Date</label>
                                                                <input type="date" class="form-control" value="29-12-2022">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <h6 class="font-weight-bold">Activity</h6>

                                <div id="grid-theme-wrapper">
                                    <div class="border">
                                        <div class="table-responsive">
                                            <igx-grid #grid2 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                                                [filterMode]="'excelStyleFilter'">

                                                <igx-column field="gidt_card" header="Gift Card" width="200">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span class="cell-vl "></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="customer" header="Customer" width="200">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span class="cell-vl "></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="event" header="Event" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="amount" header="Amount" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="date" header="Date" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="action" header="Action" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <button class="btn btn-sm px-3 py- 1 rounded-pill btn-theme2">View Order</button>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                            </igx-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">

                                <h6 class="mb-0 font-weight-bold"> Edit Gift Codes</h6>
                        
                                    <div class="row my-3">
                                        <div class="col-sm-8 col-lg-9">
                                            <div class="card shadow-none bg-light box-min-height">
                                                <div class="card-body py-3">
                                                    <h6 class="font-weight-bold mb-2"> BYS7-HGH84-HJGT-OI21</h6>
                                                    <p class="mb-2">Available Balance</p>
                                                    <h5 class="mb-0">₹ 1</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-lg-3">
                                            <div class="card shadow-none bg-light">
                                                <div class="card-body py-3">
                                                    <label for="" class="font-weight-bold mb-2">Redeem For Customer</label>
                                                    <select class="form-control">
                                                        <option>Search for a customer</option>
                                                    </select>
                                                    <div class="text-md-right">
                                                        <button type="button" class="btn btn-sm px-4 rounded-pill rounded-pill-theme text-dark rounded-theme mt-2">Redeem</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card shadow-none bg-light-theme mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm">
                                                    <label for="" class="d-block text-uppercase">Issued</label>
                                                    <strong>2022/09/03</strong>
                                                </div>
                                                <div class="col-sm">
                                                    <label for="" class="d-block text-uppercase">Issued Value</label>
                                                    <strong>₹2,000</strong>
                                                </div>
                                                <div class="col-sm">
                                                    <label for="" class="d-block text-uppercase">Order</label>
                                                    <strong>#01</strong>
                                                </div>
                                                <div class="col-sm">
                                                    <label for="" class="d-block text-uppercase">Redeemed</label>
                                                    <strong>2022/09/03</strong>
                                                </div>
                                                <div class="col-sm-4 px-lg-3 px-sm-0 px-3 col-lg-5">
                                                    <label for="" class="d-block text-uppercase">Redeemed By</label>
                                                    <strong>kiran.sarvabhotla@services.com</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card shadow-none mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-sm-6 mb-2">
                                                            <label>From</label>
                                                            <input type="text" class="form-control" value="Service India">
                                                        </div>
                                                        <div class="col-sm-6 mb-2">
                                                            <label>To</label>
                                                            <input type="text" class="form-control" value="servicesindi@gmail.com">
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <label>Message</label>
                                                            <textarea class="form-control" style="height:100px !important;"> ATG Company 2022 Gift Card</textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-sm-6 mr-auto">
                                                            <div class="row">
                                                                <div class="col-12 mb-2">
                                                                    <label>Delivery Date</label>
                                                                    <input type="date" class="form-control" value="29-12-2022">
                                                                </div>
                                                                <div class="col-12">
                                                                    <label>Expiration Date</label>
                                                                    <input type="date" class="form-control" value="29-12-2022">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                            

                                <h6 class="font-weight-bold">Activity</h6>

                                <div id="grid-theme-wrapper">
                                    <div class="border">
                                        <div class="table-responsive">
                                            <igx-grid #grid2 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                                                [filterMode]="'excelStyleFilter'">

                                                <igx-column field="gidt_card" header="Gift Card" width="200">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span class="cell-vl "></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="customer" header="Customer" width="200">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span class="cell-vl "></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="event" header="Event" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="amount" header="Amount" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="date" header="Date" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <span></span>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                                <igx-column field="action" header="Action" width="">
                                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                                                            <button class="btn btn-sm px-3 py- 1 rounded-pill btn-theme2">View Order</button>
                                                        </div>
                                                    </ng-template>
                                                </igx-column>
                                            </igx-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>