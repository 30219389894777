import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionService } from '../../_services/common-function.service';
import { LoginService } from '../../_services/login.service';
import { ProductService } from '../../_services/product.service';
declare var $: any;
@Component({
  selector: 'app-add-group-product',
  templateUrl: './add-group-product.component.html',
  styleUrls: ['./add-group-product.component.css']
})
export class AddGroupProductComponent implements OnInit {
  product_id: any;
  base_product_id: any;
  seller_id: any;
  simpleProductList: any = [];
  selectedProductList: any = [];
  group_name: any;
  editable_item_id: any;
  base_product_list: any = [];
  isEdit: boolean = false;
  breadcrumb: string;
  constructor(private router: Router, private productService: ProductService, private loginService: LoginService, private route: ActivatedRoute,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,) { }

  ngOnInit(): void {
    this.seller_id = localStorage.getItem('selected_seller_id');
    this.breadcrumb = localStorage.getItem('breadcrumb');
    // this.seller_id  = 155;
    this.getBaseProductList();
    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      // console.log('datagetbaseproductlist', data);
      if (data.length > 1) {
        data = data ? JSON.parse(data) : '';
        this.editable_item_id = data.editable_item;
        this.base_product_id = this.editable_item_id;
        this.getGroupedProductById();
      }
      if (this.base_product_id != null || this.base_product_id != undefined) {
        // this.getBaseProductList();
        this.OnBaseChange();
      }
    });

  }


  back() {
    this.router.navigateByUrl('/seller-wise-product' + '?' + localStorage.getItem('tempToken'));
  }
  OnBaseChange() {
    this.productService.getSimpleProductList(this.seller_id, this.base_product_id).subscribe((res:any) => {
      this.simpleProductList = res.rows;
    })
  }
  getBaseProductList() {
    this.productService.getBaseProductList(this.seller_id).subscribe((res:any) => {
      this.base_product_list = res.rows;
    })
  }
  addProductInList() {
    if(this.base_product_id==null || this.base_product_id==undefined || this.base_product_id==''){
      this.toastrService.warning("Please Select Base Product First", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    if(this.product_id==null || this.product_id==undefined || this.product_id==''){
      this.toastrService.warning("Please Select Product", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    let data = this.selectedProductList.filter(x => x.product_id == this.product_id);
    if (data.length > 0) {
      this.toastrService.warning("Already In List", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    } else {
      let temp = this.simpleProductList.filter(x => x.product_id == this.product_id);
      this.selectedProductList.push(temp[0]);
      this.product_id = null;
    }
  }
  removeProduct(index) {
    this.selectedProductList.splice(index, 1);
  }
  reset() {
    this.base_product_id = null
    this.product_id = null;
    this.selectedProductList = [];
    this.group_name = null;
  }
  saveProductGroup() {
    // console.log('checkkkkkkkkkkkkkkkkkkkkkkk')
    if (this.base_product_id == null || this.base_product_id == undefined || this.base_product_id == '' || this.base_product_id == 'undefined') {
      this.toastrService.warning("Please Select Base Product", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    if (this.selectedProductList < 1) {
      this.toastrService.warning("Please Select Atleast 1 Products For Group", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    for (let obj of this.selectedProductList) {
      if (obj.quantity == 0) {
        this.toastrService.warning("Please Add Atleast 1 Quanticy Of Each Product", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");
        return false;
      }
    }
    this.productService.saveProductGroup(localStorage.getItem('userId'), this.base_product_id, JSON.stringify(this.selectedProductList), this.editable_item_id).subscribe((res:any) => {
      if (res.status == 200) {
        this.toastrService.success("Product Group Successfully Saved.", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");
        this.reset();
        this.router.navigateByUrl('/seller-wise-product' + '?' + localStorage.getItem('tempToken'));
      }
      else {
        this.toastrService.warning(res.message, '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");
        return false;
      }
    })
  }
  getGroupedProductById() {
    this.productService.getGroupedProductById(this.editable_item_id).subscribe((res:any) => {
      console.log("ress----", res)
      if (res.rows.length > 0) {
        this.isEdit = true;
        let data = res.rows;
        this.group_name = data[0].group_name;
        this.selectedProductList = data;
      
      }

    });
  }
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
