import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import { FormService } from '../../_services/form.service';
import { SessionUrlPrams, ConfigPrams } from "../../field.interface";
//import { SectionConfig,SessionUrlPrams,ConfigPrams } from "../../field.interface";
import { IgxGridComponent,DisplayDensity,IgxCsvExporterService,IgxExcelExporterService, IgxGridRowComponent, Transaction , IgxStringFilteringOperand,ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy,GridPagingMode,  IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { Subscription } from 'rxjs';
import { Page } from '../../_models/page';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.css']
})
export class MisReportComponent implements OnInit {

  frameworkComponents: any;
  frameworkComponents1: any;
  tableName: any;
  tablePrimaryKey: any
  gridFilter: any = "";
  view_filter: any;
  currentSelectedRow: any;
  isResetFilter: Boolean = false;
  viewFilterString: any;
  misReportRows:any[]=[];
  misReportColumndef:any[]=[];
  MisReportLoaded : any
  configParms: ConfigPrams;
  page = new Page();
  public totalCount = 0;
  formId: any=335;

  sessionUrlPrams: SessionUrlPrams;

  constructor(private router: Router,
    private toastrService: ToastrService,
    private formService: FormService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
  }


  ngOnInit(): void {

    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.tableName = "vw_scheme_requests";
    this.tablePrimaryKey = "id";

    this.sessionUrlPrams = {
      frmid: this.formId,
      vid: 0,
      UserCode: "UserCode",
      UserName: localStorage.getItem("userFullName"),
      ProfileId: localStorage.getItem("profileId"),
      Location: localStorage.getItem("office"),
      department: localStorage.getItem("department"),
      processtype: "ext",
    };

    this.getMisReport()
  }

  getMisReport()
  {
    this.misReportRows=[];
    var strQry=`SELECT se.varibale_name as scheme_name,dr.scheme_category,
          coalesce(sum(case when  status_id = 105 then 1 end),0) as Approved,
          coalesce(sum(case when  status_id =106 then 1 end),0) as Rejected,
          coalesce(sum(case when  status_id = 107 then 1 end),0) as Inprocess       
          FROM radx_draft_requests dr
          INNER JOIN app_tblsystemenum se on se.id=dr.scheme_category where dr.department_id=${this.sessionUrlPrams.department} 
          GROUP BY se.varibale_name,dr.scheme_category 
          ORDER BY se.varibale_name ASC`
          console.log("hi-------",strQry)
     this.formService.getFormData(strQry).subscribe(res=>{  
      this.misReportColumndef.push({ field: 'scheme_name', headerName: 'Scheme Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.misReportColumndef.push({ field: 'approved', headerName: 'Approved Scheme', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.misReportColumndef.push({ field: 'rejected', headerName: 'Rejected Scheme', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.misReportColumndef.push({ field: 'inprocess', headerName: 'In-Process Scheme', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.misReportRows=res.rows;
      console.log("dataSummary===>",this.misReportRows)
     });
  }

  ShowViewData(event){
    console.log(event)
  }
}
