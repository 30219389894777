import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionService } from '../../_services/common-function.service';
import { FormService } from '../../_services/form.service';
import { ProductService } from '../../_services/product.service';
import { Router } from '@angular/router';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppSettings } from 'src/app/_services/AppSetting';
declare var $: any;
@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {
  linkTimer: any
  customerList: any=[];
  subTotal: any;
  bcomanyName: any;
  cust_id: any;
  referenceNo: any;
  customer_name: any;
  shippingDetails: any
  billingDetails: any;
  remarks:any = ''
  productList: any;
  product_id: any;
  productName: any;
  temproductName: any = [];
  isEditLoad: string = null;
  shippingCharge: any = 0;
  totalAmount: any;
  gstAmount: any;
  gst: any;
  rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  contactPerson: any;
  emailS: any;
  phoneS: any;
  shipAddress: any;
  countryS: any;
  stateS: any;
  cityS: any;
  zipCodeS: any;
  defaultAddressS: any;
  companyName: any;
  emailB: any;
  phoneB: any;
  billAddress: any;
  countryB: any;
  stateB: any;
  cityB: any;
  zipCodeB: any;
  defaultAddressB: any;
  countryList: any = [];
  stateList: any = [];
  corporate_billing_details_id: any;
  MassageOutTime = AppSettings.getMassageOutTime;
  corporate_shipping_details_id: any;
  shipType: any = 'single'
  corporate_billing_id: any;
  @ViewChild('mySelectElement') mySelectElement!: NgSelectComponent;
  corporate_shipping_id: any;
  price: number;
  quantity: number;
  bill_id: any;
  ship_id: any;
  payment_term: any;
  credit_limit: any;
  customer_id: any;
  sku: any;
  gstList: any[] = [];
  credit_remaining: any;
  constructor(private productService: ProductService,
    private formService: FormService, private sellerService: SellerRegistrationService,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,
    private router: Router) { }

  ngOnInit(): void {
    this.getcustomer('');
    this.getAllCountries();
    this.getStateList();
    //  this.getProduct();

  }
  getcustomer(searchTerm) {
    this.sellerService.getCop(searchTerm).subscribe(res => {
      this.customerList = res;
    })
  }
  getAllCountries() {
    this.productService.getCounty().subscribe((res: any) => {
      this.countryList = res.rows;
    })
  }

  getStateList() {
    this.sellerService.getState().subscribe(res => {
      this.stateList = res;
    })
  }
   onchangeproduct(event){
    if(event){
      this.product_id =''
      this.productList = [];
      //console.log("onchangeproduct",this.product_id)
    }    
   }
  getSelectCust() {
    this.productList = [];
    this.temproductName = [];
    this.totalAmount = 0;
    this.gstList  = [];
    this.subTotal = 0;
    this.gstAmount = 0;

    let customer = this.customerList.filter(x => x.add_corporate_id == this.cust_id)[0];
    this.customer_name = customer.enterprise_name;
    this.payment_term = customer.payment_term;
    this.credit_limit = customer.credit_limit;
    this.credit_remaining = customer.credit_remaining;
    this.customer_id = customer.customer_id;
    console.log("this.customer_name", this.payment_term, this.credit_limit, this.customer_id);
    this.sellerService.corpshippingBillingdetails(this.customer_id).subscribe((res:any) => {
      if(res.rows.length > 0){
        this.shippingDetails = res.rows.filter(x => !x.is_billing_add).length ? res.rows.filter(x => !x.is_billing_add) : res.rows.filter(x => x.is_billing_add);
          this.billingDetails = res.rows.filter(x => x.is_billing_add).length ? res.rows.filter(x => x.is_billing_add) : res.rows.filter(x => !x.is_billing_add)
      }
      this.corporate_billing_id = this.billingDetails[0]['address_id'] ? this.billingDetails[0]['address_id'] : ''
      this.corporate_shipping_id = this.shippingDetails[0]['address_id'] ? this.shippingDetails[0]['address_id'] : ''
      console.log("corpId", this.corporate_billing_id, this.corporate_shipping_id)
      this.bcomanyName = this.billingDetails[0].company_name
      for (let obb of this.billingDetails) {
        obb["full_address"] = obb.locatity + ', ' + obb.area_street + ', ' + obb.city + ', ' + obb.state_name + ', ' + obb.country_name + ', ' + obb.pin_code;
      }
      for (let obb of this.shippingDetails) {
        obb["full_address"] = obb.locatity + ',' + obb.city + ', ' + obb.state_name + ', ' + obb.country_name + ', ' + obb.pin_code;
      }
    })
  }
  // getProduct(){
  //   this.sellerService.getproduct().subscribe(res => {
  //     this.productList = res;
  //   })
  // }
  onSearch(term) {
    //console.log('term', term)
    let searchName = term.term;
    this.productList = [];
    if (searchName.length > 2) {
      this.productList = [];
      clearInterval(this.linkTimer);
      this.linkTimer = setInterval(() => { this.getProduct(searchName, this.customer_id), clearInterval(this.linkTimer) }, 1000);
    } else {
      this.productList = [];
    }

  }
  getProduct(searchName,customerId) {
    let searchteam = searchName
    //console.log('searchteam', searchteam)
    // myInputVariable
    if (searchName.length > 2) {
      this.productList = [];
      console.log('ssssssssssdf', customerId)
      this.sellerService.getproduct(searchteam,customerId).subscribe(res => {
        console.log(res, 'res================>');
        if (res) {
          this.productList = res;
        } else {
          clearInterval(this.linkTimer);
        }
      }, error => {
        clearInterval(this.linkTimer);
      }
      )
    } else {
      // this.productList = [];
    }

  }
  getProductDetail(event) {
    if (event) {
      let product = this.temproductName.filter((x) => (x.sku || x.suk) ==this.sku );
      if(product.length > 0){
        this.sku = '';
        this.productList = [];
       this.toastrService.warning("this product already exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
       $(".overlay-container").addClass("addcontainerdisable");
       this.removeClass();
      
    //  return false;
    // this.getProductDetail(undefined) 
      }else{
        let tempobj = this.productList.filter(x => (x.sku || x.suk) == this.sku)[0];
        tempobj.cus_quantity = 1;
        this.temproductName.push(tempobj);
        this.subTotal = 0;
        this.totalAmount = 0;
        this.gstAmount = 0;
        for (let ob of this.temproductName) {
          this.subTotal = this.subTotal + (Number(ob.price) * (Number(ob.cus_quantity)))
          this.price = Number(ob.price)
          this.quantity = Number(ob.cus_quantity)
          this.gstAmount = this.gstAmount + ((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)
         ob.cgst_rate = ob.gst_rate/2;
         ob.cgst_value =((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)/2;
         ob.sgst_value = ((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)/2;
          this.gst = ob.gst_rate
        }
        this.gstAmount = this.gstAmount ? parseFloat((this.gstAmount)).toFixed(2) : 0;
        this.totalAmount = Number(this.totalAmount) + Number(this.subTotal) + Number(this.gstAmount)  
        this.gstList = this.temproductName.reduce((acc, curr) => {
          const index = acc.findIndex(item => item.cgst_rate === curr.cgst_rate);
          if (index !== -1) {
            acc[index].cgst_value += curr.cgst_value;
            acc[index].sgst_value += curr.sgst_value; 
          } else {
            acc.push({cgst_rate: curr.cgst_rate, cgst_value: curr.cgst_value, sgst_value: curr.sgst_value});
          }
          return acc;
        }, []);
      }
    }
    this.sku = ''
    this.productList = [];
    this.mySelectElement.writeValue('');
  }

  deleteProduct(i) {
    this.temproductName.splice(i, 1);
    this.onChangePrice()
  }
  editprices(i) {
    this.isEditLoad = i;
  }
  onClickDeleteProduct() {
     this.subTotal = 0;
    this.totalAmount = 0;
    this.gstAmount = 0;
    for (let ob of this.temproductName) {
      this.subTotal = Number(this.subTotal) + (Number(ob.price) * (Number(ob.cus_quantity)))
      this.gstAmount = this.gstAmount + ((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)
      //console.log("gstamount", this.gstAmount)

    }
    this.subTotal = Number(this.subTotal)
    this.gstAmount = this.gstAmount ? parseFloat((this.gstAmount)).toFixed(2) : 0;
    this.totalAmount = Number(this.totalAmount) + Number(this.subTotal) + Number(this.gstAmount)
  }

  onChangePrice() {
      this.subTotal = 0;
      this.totalAmount = 0;
      this.gstAmount = 0;
      for (let ob of this.temproductName) {
        this.subTotal = this.subTotal + (Number(ob.price) * (Number(ob.cus_quantity)))
        this.price = Number(ob.price)
        this.quantity = Number(ob.cus_quantity)        
        if(Number(ob.cus_quantity) > Number(ob.quantity)){
          this.toastrService.warning(`Stock not available for ${ob.product_name} - ${ob.sku ? ob.sku : ob.suk}`, '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
     $(".overlay-container").addClass("addcontainerdisable");
     this.removeClass();
     return false;
        }
        this.gstAmount = this.gstAmount + ((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)
       ob.cgst_rate = ob.gst_rate/2;
       ob.cgst_value =((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)/2;
       ob.sgst_value = ((Number(ob.price) * Number(ob.cus_quantity) * Number(ob.gst_rate)) / 100)/2;
        this.gst = ob.gst_rate
      }
      this.gstAmount = this.gstAmount ? parseFloat((this.gstAmount)).toFixed(2) : 0;
      this.totalAmount = Number(this.totalAmount) + Number(this.subTotal) + Number(this.gstAmount)  
      this.gstList = this.temproductName.reduce((acc, curr) => {
        const index = acc.findIndex(item => item.cgst_rate === curr.cgst_rate);
        if (index !== -1) {
          acc[index].cgst_value += curr.cgst_value;
          acc[index].sgst_value += curr.sgst_value; 
        } else {
          acc.push({cgst_rate: curr.cgst_rate, cgst_value: curr.cgst_value, sgst_value: curr.sgst_value});
        }
        return acc;
      }, []);

    

  }

  onEditAddress(bill_id) {
    this.bill_id = bill_id.address_id
    //console.log("rowww", this.bill_id)    
    this.companyName = bill_id.company_name
    this.emailB = bill_id.email_address
    this.phoneB = bill_id.mobile
    this.billAddress = bill_id.area_street
    this.countryB = bill_id.country_id
    this.stateB = bill_id.state_id
    this.cityB = bill_id.city
    this.zipCodeB = bill_id.pin_code
    this.defaultAddressB = bill_id.is_billing_add
  }

  onEditShipAddress(ship_id) {
    this.ship_id = ship_id.address_id
    this.contactPerson = ship_id.contact_person
    this.emailS = ship_id.email_address
    this.phoneS = ship_id.mobile
    this.shipAddress = ship_id.area_street
    this.countryS = ship_id.country_id
    this.stateS = ship_id.state_id
    this.cityS = ship_id.city
    this.zipCodeS = ship_id.pin_code
    this.defaultAddressS = ship_id.is_billing_add
  }

  updateCorpShippingAdress() {
    this.productService.updateCorpShippingAdress(this.ship_id, this.contactPerson, this.emailS, this.phoneS, this.shipAddress, this.countryS, this.stateS, this.cityS, this.zipCodeS, this.defaultAddressS).subscribe(res => {
      this.toastrService.success('Shipping address updated successfully', '', {timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $('#editShipAd').modal('hide');
        this.getSelectCust()
      
      //this.reset();
    })
  }

  updateCorpBillingAddress() {
    this.productService.updateCorpBillingAddress(this.bill_id, this.companyName, this.emailB, this.phoneB, this.billAddress, this.countryB, this.stateB, this.cityB, this.zipCodeB, this.defaultAddressB).subscribe(res => {
      this.toastrService.success('Billing address updated successfully', '', {timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $('#editBilAd').modal('hide');
      this.getSelectCust()

    })
  }

  deleteCorpShippingAddress(ship_id) {
    this.ship_id = ship_id.address_id
    Swal.fire({
      title: `Are you sure you want to delete this shipping address?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteCorpShippingAddress(this.ship_id).subscribe((res) => {
          Swal.fire('Shipping address deleted successfully!', '', 'success');
        this.getSelectCust()

        }
        );
      }
    });
  }

  deleteCorpBillingAddress(bill_id) {
    this.bill_id = bill_id.corporate_billing_details_id
    Swal.fire({
      title: `Are you sure you want to delete this billing address?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteCorpBillingAddress(this.bill_id).subscribe((res) => {
          Swal.fire('Billing address deleted successfully!', '', 'success');
          this.getSelectCust()
        }
        );
      }
    });
  }

  reset() {
   this.customerList = []
   this.temproductName = []
   this.corporate_billing_details_id = ''
   this.corporate_shipping_details_id = ''
   this.customer_name = ''
   this.subTotal = ''
   this.totalAmount = ''
   this.corporate_billing_id = ''
   this.corporate_shipping_id = ''
   this.credit_limit =''
   this.credit_remaining = ''
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }

  createOrderForCorporate() {
   // debugger
    if(this.temproductName.length == 0){
      this.toastrService.warning('Please select atleast one product', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    for (let ob of this.temproductName) {
    if(Number(ob.cus_quantity) > Number(ob.quantity)){
    this.toastrService.warning(`Stock not available for ${ob.product_name}- ${ob.sku ? ob.sku : ob.suk}`, '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    return false;
    }}
    if (this.corporate_billing_id == null || this.corporate_billing_id == undefined || this.corporate_billing_id == '') {
      this.toastrService.warning('Please select billing address', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.shipType == 'single') {
      if (this.corporate_shipping_id == null || this.corporate_shipping_id == undefined || this.corporate_shipping_id == '') {
        this.toastrService.warning('Please select shipping address', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    if (this.payment_term == 'cash') {
      this.toastrService.warning('Cash payment not acceptable', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.payment_term == 'credit') {
      if (this.totalAmount >= this.credit_remaining) {
        this.toastrService.warning('You have no credit limit for create order', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }

    }

    this.productService.createOrderForCorporate(this.customer_id, JSON.stringify(this.temproductName), this.totalAmount, this.gstAmount, this.corporate_billing_id, this.corporate_shipping_id, this.remarks).subscribe(res => {

      this.toastrService.success('Order placed successfully', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      this.reset();
      setTimeout(() => {
        this.router.navigate(["/dashboardnew"]);
      }, 2000);
      
    })

  }

  addProduct() {

  }


}
