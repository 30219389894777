
<div class="paginationCare">
    <div class="row">
      <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
        <span class="itemsFound"> {{configParms.totalItems}} items found </span> <span class="itemsPerPage">Showing
         <select class="form-control" #mySelect (change)="itemsPerPageChanged(mySelect.value)">
        <option *ngFor="let opt of pageShowing" [value]="opt.labelValue" [disabled]="configParms.totalItems == 0">
          {{opt.labelDisplay}}</option>

      </select> items per page</span>
    </div>
      <div class="col-0 col-sm-1 col-md-1 col-lg-3 col-xl-3">
   
      </div>
      <div class="col-8  col-sm-6 col-md-6 col-lg-4 col-xl-4">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item">
              <a class="page-link" (click)="onFirstPage()" tabindex="-1" aria-disabled="true">
                <i class="fa fa-angle-double-left"></i>
   
              </a>
            </li>
            <li class="page-item">
              <a class="page-link"(click)="onPagePrevious()" tabindex="-1" aria-disabled="true">
                <i class="fa fa-angle-left"></i>
          
              </a>
            </li>
            <li class="page-item" *ngIf="ispagingdataloaded">
              <span>Page <input type="text" class="form-control" value={{configParms.currentPage}} disabled> of {{TotalPages}}</span>
            </li>
  
            <li class="page-item">
              <a class="page-link" (click)="onPageNext()"> 
                <i class="fa fa-angle-right"></i>

              </a>
            </li>
            <li class="page-item">
              <a class="page-link" (click)="onLastPage()"> 
                <i class="fa fa-angle-double-right"></i>
         
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>