<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
        <section class="bg-white"> 
        <div class="container-fluid ">
            <div class="tabs col-lg-12 nopadding py-2">
                <div class="tab-button-outer">
                    <ul id="tab-button">
                        <li *ngIf="currentRouter == 'inbox'"><a href="#tab03" class="font8">Inbox</a></li>
                        <li *ngIf="currentRouter == 'outbox'"><a href="#tab04" class="font8">Outbox</a></li>
                    </ul>

                </div>
                <div id="tab03" class="tab-contents mmt5 p-0" *ngIf="currentRouter == 'inbox'">
                    <div class="col-sm-12" *ngIf="(InboxLoaded | async)">
                        <div class="row">
                            <div class="col-sm-12 col-md-12   nopadding ">
                                <div class="col-sm-12 col-md-12 mt-2 mb-2 nopadding">
                                    <!-- <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" suppressCsvExport="true" [pagination]="true" [paginationPageSize]="pagesize" [rowData]="inboxRows" (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)" domLayout="autoHeight"
                                        (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [columnDefs]="inboxColumndef">
                                    </ag-grid-angular> -->
                                    <igx-grid #grid1 [data]="inboxRows" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="10" (onDataPreLoad)="actionstrip.hide()" (onRowSelectionChange)="handleRowSelection($event)">
                                        <ng-container *ngFor="let col of inboxColumndef let i=index">
                                            <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                                                <ng-template igxCell let-cell="cell">
                                                    {{cell.value}}
                                                    <!-- <a class="truncate" href="javascript:void(0)"
              (click)="ShowViewData(cell)">{{cell.value}}</a> -->
                                                </ng-template>
                                            </igx-column>
                                            <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable" [filterable]="col.filter"></igx-column>

                                        </ng-container>
                                        <igx-action-strip #actionstrip>
                                            <div class="drop-down-wrapper">
                                                <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting" [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu" igxButton="icon">
                  <igx-icon>more_vert</igx-icon>
                </button>
                                                <igx-drop-down #menu [allowItemsFocus]="true">
                                                    <ng-container *ngFor="let item of columnActionStrip">
                                                        <igx-drop-down-item (click)="onProcess(actionstrip.context,item.field)">
                                                            <div>
                                                                <span><i class="fa fa-{{ item.iconName }}"></i>
                          {{ item.field }}
                        </span>
                                                            </div>
                                                        </igx-drop-down-item>
                                                    </ng-container>
                                                </igx-drop-down>
                                                <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                                            </div>
                                        </igx-action-strip>
                                    </igx-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="tab04" class="tab-contents p-0 mmt5 " *ngIf="currentRouter == 'outbox'">
                    <div class="col-sm-12" *ngIf="(OutboxLoaded | async)">
                        <div class="row">
                            <div class="col-sm-12 col-md-12   nopadding ">
                                <div class="col-sm-12 col-md-12  mb-2 nopadding">
                                    <!-- <ag-grid-angular 
                                                style="width: 100%;" 
                                                    class="ag-theme-alpine"
                                                    suppressCsvExport="true"
                                                    [pagination]="true"
                                                    [paginationPageSize]="pagesize"
                                                    [rowData]="outboxRows" (columnResized)="onColumnResized($event)"
                                                    (columnVisible)="onColumnVisible($event)"
                                                    domLayout="autoHeight"
                                                    [frameworkComponents]="frameworkComponents"
                                                    [columnDefs]="outboxColumndef">
                                                </ag-grid-angular> -->
                                    <igx-grid #grid1 [data]="outboxRows" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="10" (onDataPreLoad)="actionstrip.hide()" (onRowSelectionChange)="handleRowSelection($event)">
                                        <ng-container *ngFor="let col of outboxColumndef let i=index">
                                            <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [Width]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                                                <!-- <ng-template igxCell let-cell="cell">
                                                </ng-template> -->
                                            </igx-column>
                                            <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable" [filterable]="col.filter"></igx-column>

                                            <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName == 'Action'" dataType="string">
                                                <ng-template igxCell let-cell="cell">
                                                    <ng-container >
                                                        <div class="gridCheckboxCenter row"><a href="javascript:void(0)" *ngIf="cell.rowData.is_open==false ||cell.rowData.is_open==null" (click)="onCallback(cell)" class=" btn btn-green btn-sm rounded-pill px-3 mr-2">Call Back </a>
                                                            <!-- </div> -->
                                                            <!-- <div class="gridCheckboxCenter"> -->
                                                            <a href="javascript:void(0)" class=" btn btn-warning btn-sm rounded-pill px-3 " (click)="OnTracker(cell)">Track </a></div>
                                                    </ng-container>
                                                </ng-template>
                                            </igx-column>
                                            <!-- <igx-column [field]="col.field" *ngIf="col.headerName == 'Action'" dataType="string">
                                                <ng-template igxCell let-cell="cell">
                                                    <ng-container>
                                                        <span class="gridCheckboxCenter">
                                                                            <a href="javascript:void(0)" style="color:white"(click)="onCallback(cell)" class="text-body btn-sm btn btn-info">Call  </a>
                                                                        </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </ng-container>
                                                </ng-template>
                                            </igx-column> -->
                                        </ng-container>
                                    </igx-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>

</section>

        <div class="modal" id="editModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div id="edit-content" class="modal-content">
                    <div class="modal-header p-2">
                        <h6 class="modal-title">Edit
                            <label style="color:green" class="font9 successedit" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
                            <label style="color:red" class="font9 erroredit" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
                        </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
                                </button>
                    </div>
                    <div class="modal-body minhyt40 pt-1" *ngIf="EditLoaded | async">
                        <ul class="nav nav-tabs">
                            <li class="active" *ngIf="edit_option == 1 || edit_option == 2"><a data-toggle="tab" class="active" href="#tabedit{{formId}}">{{formName}}</a>
                            </li>
                            <li *ngFor="let lst of childFormList"><a *ngIf="edit_option == 1 || edit_option == 3" [class]="isAddMode?'disabled':''" id="tabchildedit{{lst.form_id}}" data-toggle="tab" href="#tabedit{{lst.form_id}}">{{lst.form_name}}</a>
                            </li>
                            <li *ngIf="is_attachment == true"><a [class]="isAddMode?'disabled':''" data-toggle="tab" href="#tabeditAttachment">Attachments</a>
                            </li>
                        </ul>
                        <div class="tab-content tab-contents pl-0 pr-0  pt-0  ">
                            <div id="tabedit{{formId}}" class="tab-pane fade in active show" *ngIf="edit_option == 1 || edit_option == 2">
                                <hdsbpm-form-repeat [section]="SectionConfig" [isAddMode]="isAddMode" [processid]="processid" [stageid]="stageid" [connJson]="connJson" [nodeJson]="nodeJson" [editValueRow]="editValueRow" [processinstid]="processinstid" (submit)="submit($event)" (processsubmit)="processsubmit($event)"></hdsbpm-form-repeat>
                            </div>
                            <!-- <ng-container *ngIf="isAddMode == false">														
                                                <div *ngFor="let lst of childFormList; index as i" id="tabedit{{lst.form_id}}" class="tab-pane fade" >
                                                        <dynamic-grid-form [lstsection]="lst" *ngIf="edit_option == 1 || edit_option == 3" [rowid]="refKey" (submit)="submit($event)"></dynamic-grid-form>
                                                </div>														
                                                <div class="tab-pane fade" id="tabeditAttachment">
                                                        <app-file-attachment id="edit" [rowid]="refKey" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
                                                </div>
                                        </ng-container> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>