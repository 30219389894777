import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import{ LoginService} from '../../_services/login.service'
import {FormService} from '../../_services/form.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user_name: any;
  role_name: any;
  departmentName: any = "";
  officeName : any="";
  profileJson: any[] = [];
  accesToken:any;
  menuList: any[]=[];
  viewList: any[]=[];
  oldPassword:any;
  newPassword:any;
  confirmPassword:any;
  routerName: any;
  @Output() profileIdChange: EventEmitter<boolean> = new EventEmitter();
  show: boolean;
  err_msg: string;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    const userId = localStorage.getItem("userId");
    let uri = window.location.search;
   setTimeout(() => {
    let currenturl=this.router.url;
    if(currenturl != ''){
      currenturl=currenturl.substring(1,currenturl.length)
      this.routerName=currenturl;
      if(this.routerName.length>0){
        this.routerName=this.routerName.split("?")[0];
      }
      if(currenturl == 'inbox'){   
        $("#outbox").removeClass("active") 
        $("#sbmit").removeClass("active") 
        $("#sbmitreq").removeClass("active")   
        $("#commmit").removeClass("active") 
        $("#tracker").removeClass("active")   
        $("#inbox").addClass("active") 
        $("#mytask").addClass("show");
        $(".fliph #mytask").removeClass("show");
      }
      else if(currenturl == 'outbox')
      {
         $("#sbmit").removeClass("active") 
        $("#sbmitreq").removeClass("active")    
        $("#inbox").removeClass("active") 
        $("#commmit").removeClass("active") 
        $("#tracker").removeClass("active")  
        $("#outbox").addClass("active") 
        $("#mytask").addClass("show");
        $(".fliph #mytask").removeClass("show");
      }
      else if(currenturl == 'draft')
      {
         $("#sbmitreq").removeClass("active")    
        $("#inbox").removeClass("active") 
        $("#outbox").removeClass("active")
        $("#commmit").removeClass("active") 
        $("#tracker").removeClass("active")  
        $("#sbmit").addClass("active") 
        $("#mytask").addClass("show");
        $(".fliph #mytask").removeClass("show");
      }
      else if(currenturl == 'submitted')
      {
         $("#sbmit").removeClass("active") 
       $("#inbox").removeClass("active") 
        $("#outbox").removeClass("active")
        $("#commmit").removeClass("active") 
        $("#tracker").removeClass("active")  
        $("#sbmitreq").addClass("active") 
        $("#mytask").addClass("show");
        $(".fliph #mytask").removeClass("show");
      }
      else if(currenturl == 'committee-meeting')
      {
         $("#sbmit").removeClass("active") 
        $("#inbox").removeClass("active") 
        $("#outbox").removeClass("active")
        $("#tracker").removeClass("active")  
        $("#sbmitreq").removeClass("active")
        $("#commmit").addClass("active") 
        $("#mytask").addClass("show");
        $(".fliph #mytask").removeClass("show");
      }
      else if(currenturl == 'status-tracker')
      {
         $("#sbmit").removeClass("active") 
       $("#inbox").removeClass("active") 
        $("#outbox").removeClass("active")
        $("#commmit").removeClass("active") 
        $("#sbmitreq").removeClass("active") 
        $("#tracker").addClass("active")  
        $("#mytask").addClass("show");
        $(".fliph #mytask").removeClass("show");

      }
   
      else{
        $("#sbmit").removeClass("active") 
        $("#inbox").removeClass("active") 
        $("#commmit").removeClass("active") 
        $("#tracker").removeClass("active")  
         $("#outbox").removeClass("active") 
         $("#sbmitreq").removeClass("active")
         $("#mytask").removeClass("show");
         $(".fliph #mytask").removeClass("show");
      }
    }
   }, 300);
    
    // alert(this.router.url);
    if (uri.length > 1) {
      var str = uri
      var value = str.split("&");
      value.forEach(queryParm => {
        if (queryParm.indexOf('accesstoken') >= 0) {
          var token = queryParm.split('=')[1];
          this.accesToken=decodeURIComponent(token);        
          // console.log("Decode token",this.accesToken)
          let conversionDecryptOutput = this.loginService.getDecryptedData(this.accesToken)
          // console.log("Decrypted",conversionDecryptOutput);
          // console.log(userId)
          // if(userId != null){
          //   if(conversionDecryptOutput==userId.toString()){   
          //   }else{
          //     this.router.navigate(['/login']);
          //   }
          // }else{
          //   this.router.navigate(['/login']);
          // }          
        }
      });
     
    }


    this.user_name = localStorage.getItem("userFullName") ? (localStorage.getItem("userFullName").toUpperCase()) : "";
    this.role_name = localStorage.getItem("designation_name") ? (localStorage.getItem("designation_name").toUpperCase()) : "";
    let user_profile_list = localStorage.getItem("user_profile_list")
    this.departmentName = localStorage.getItem("department_name")
    this.officeName=localStorage.getItem("office_name")
    this.profileJson = [];
    if (user_profile_list != "" && user_profile_list != null && user_profile_list != undefined) {
      this.profileJson = user_profile_list ? JSON.parse(user_profile_list) : [];
      // console.log("this.profilejson", this.profileJson)
    }


    // if ($(window).width() < 992) {
    //   //  alert('Less than 960');
    //  $('.sidebar').addClass('fliph');
    // }
    //  $(window).resize(function() {
    if ($(window).width() < 960) {

      $('.sidebar').addClass('fliph');
    }

    //  });

    $(window).resize(function () {
      if ($(window).width() < 960) {

        $('.sidebar').addClass('fliph');
      }

    });

// remove all .active classes when clicked anywhere
// hide = true;
$('body').on("click", function () {
  $('.fliph .sub-menu').removeClass('show');
  
});
 
$('body').on('click', '.fliph .sub-menu', function () {

  var self = $(this);

  if (self.hasClass('show')) {
      $('.fliph .sub-menu').removeClass('show');
   
  }

  $('.fliph .sub-menu').removeClass('show');
  self.toggleClass('show');
  
});

  }
  btnnav() {
    $('.sidebar').toggleClass('fliph');
  }

  setNewRole(userprofile_id) {
    let profileJson = this.profileJson.filter(d => d.userprofile_id == userprofile_id);
    if (profileJson.length > 0) {
      this.role_name = profileJson[0]["designation_name"];
      this.officeName=profileJson[0]["office_name"];
      this.departmentName = profileJson[0]["department_name"];
      localStorage.setItem("role_id", profileJson[0]["role_id"])
      localStorage.setItem("role_name", profileJson[0]["role_name"])
      localStorage.setItem("designation_name",profileJson[0]["designation_name"])            
      localStorage.setItem("profileId", profileJson[0]["userprofile_id"])
      localStorage.setItem("office", profileJson[0]["office_id"])
      localStorage.setItem("office_name", profileJson[0]["office_name"])
      localStorage.setItem("department", profileJson[0]["department_id"])
      localStorage.setItem("department_name", profileJson[0]["department_name"] ? profileJson[0]["department_name"] : "")
      localStorage.setItem("sector", profileJson[0]["sector_id"])
      localStorage.setItem("reporting_office", profileJson[0]["reporting_office"])
      localStorage.setItem("department_code_en", profileJson[0]["department_code_en"])
      localStorage.setItem("designation_id",this.profileJson[0]["designation_id"]) 
      localStorage.setItem("hod_designation_id",this.profileJson[0]["hod_designation_id"]?this.profileJson[0]["hod_designation_id"]:"")      
      localStorage.setItem("office_designation_id",this.profileJson[0]["office_designation_id"]?this.profileJson[0]["office_designation_id"]:"")
      // this.profileIdChange.emit(true);
      // this.gotoDBoard();
      this.bindMenu(profileJson[0]["role_id"],profileJson[0]["department_id"])
    
    }
  }

  bindMenu(role_id,department_id){
    localStorage.setItem("menuList",undefined)
    let mennuQry=`select rdm.menu_id, am.menu_txt from z_entitymast_menu_role_department_mapping_001 rdm
    left join app_menu am on am.menu_id=rdm.menu_id where rdm.role_id=${role_id} and rdm.department_id=${department_id}`
    this.formService.getFormData(mennuQry).subscribe(roleResult => { 
      let result = roleResult.rows;
      this.menuList=roleResult.rows;;
      if(result.length>0){
        let menuids="";
        for(let a=0; a<result.length; a++){
          menuids=menuids+result[a]["menu_id"]+",";
        }
        if(menuids != ""){
          menuids=menuids.substring(0,menuids.length-1);          
          let viewQuery=`select string_agg(view_id::text, ',') as view_id from app_menu_link_mapping
          where menu_id in (${menuids});select menu_id,view_id from app_menu_link_mapping where menu_id in (${menuids})`
          this.formService.getFormData(viewQuery).subscribe(viewResult => { 
            let result1=viewResult[0].rows;
            let result2=viewResult[1].rows;
            if(result1.length>0){
              let viewIds=result1[0]["view_id"];
              if(viewIds != ""){
                let linkQuery=`select fd.order_id,fd.form_id,fd.form_template,fd.is_custom_form, fd.form_link_name form_link,v.link_name form_link_name ,v.view_type,v.view_id,v.child_form_ids,v.is_attachment,fd.module_id,m.modulename,fd.is_table_required from app_forms fd left join app_module m on fd.module_id=m.id left join app_form_view_details v on fd.form_id=v.form_id 
                where (fd.db_status=2 and fd.isactive=true and v.is_link_display=true and v.view_id in(${viewIds})) or fd.is_custom_form=true `
                this.formService.getFormData(linkQuery).subscribe(linkResult => { 
                  this.viewList=linkResult.rows;
                  if(this.viewList.length>0){
                    for(let c=0; c<result2.length; c++){
                      let vid=result2[c]["view_id"];
                      this.viewList.filter(d=>d.view_id == vid)[0]["menu_id"]=result2[c]["menu_id"];
                    } 
                    let arr=this.viewList.filter(d=>d.form_id == 339)
                    if(arr.length>0){
                      this.viewList.filter(d=>d.form_id == 339)[0]["view_id"]="311";
                    }                    
                  }
                  for(let x=0; x<this.menuList.length; x++){
                    let sublist=this.viewList.filter(d=>d.menu_id == this.menuList[x]["menu_id"]);
                    this.menuList[x]["links"]=sublist;
                    this.menuList[x]["strng"]=x;
                  }
                  this.menuList=this.menuList.filter(d =>d.links.length>0)
                  localStorage.setItem("menuList",JSON.stringify(this.menuList))                 
                  this.gotoDashBoard(role_id,department_id)                  
                });
              }
              // console.log(viewIds)
            }else{
              localStorage.setItem("menuList",undefined)
              this.gotoDashBoard(role_id,department_id)
            }
            // console.log(viewResult.rows[0]["view_id"])
          })
          
        }else{
          localStorage.setItem("menuList",undefined)
          this.gotoDashBoard(role_id,department_id)
        }
      }else{
        localStorage.setItem("menuList",undefined)
        this.gotoDashBoard(role_id,department_id)
      }
      // console.log(roleResult.rows)
    });
  }

  gotoDashBoard(role_id,department_id){
    // let role_id=localStorage.getItem("role_id")?localStorage.getItem("role_id"):0;
    // let department_id=localStorage.getItem("department")?localStorage.getItem("department"):0;
    if(role_id == 14 || role_id == 33){
      this.router.navigateByUrl('/committee-dashboard?'+localStorage.getItem("tempToken"));
      //this.profileIdChange.emit(true);
    }else if(role_id == 3 ){
      this.router.navigateByUrl('/dashboard?'+localStorage.getItem("tempToken"));
      //this.profileIdChange.emit(true);
    }else{
      if(this.routerName == "dashboardnew"){
        this.router.navigateByUrl('/newdashboard?'+localStorage.getItem("tempToken"));
      }else{
        this.router.navigateByUrl('/dashboardnew?'+localStorage.getItem("tempToken"));
      }
    }
  }
  gotoDBoard(){
      let role_id=localStorage.getItem("role_id")?localStorage.getItem("role_id"):0;
      let department_id=localStorage.getItem("department")?localStorage.getItem("department"):0;
      this.gotoDashBoard(role_id,department_id)
  }

  showChangePassword() {
   this.router.navigate(["/change-password"])
  }

  logout() {
    // var date = new Date();
    // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // let month=date.getMonth() + 1;
    // let m,d;  
    // if(month<10){ m="0"+month }else{m=month}
    // if(date.getDate()<10){d="0"+date.getDate()}else{d=date.getDate()}
    // let year=date.getFullYear();
    // let startDate= year+"-"+month+"-01";
    // let lastDate= year+"-"+month+"-"+lastDay.getDate();      
    // var hour = date.getHours();
    // var minute= date.getMinutes();
    // let todayDate=year+"-"+m+"-"+d+" "+hour+":"+minute;

    // let ipaddress=localStorage.getItem("ipaddress");    
    // let squery="select id from app_authuser_session where user_id="+localStorage.getItem("userId")+" order by id desc limit 1 "
    // this.formService.getFormData(squery).subscribe(res => {      
    //   if(res.rows.length>0){
    //     let authsessionId=res.rows[0]["id"];
    //     let updateQuery="update app_authuser_session set logout_ip_address='"+ipaddress+"', updated_date='"+todayDate+"' where id="+authsessionId+";"
    //     this.formService.getFormData(updateQuery).subscribe(res => {
          // console.log(authsessionId)
      //  });        
    //  } 
    //});
    //this.router.navigate(["/login-new-megh"])
 //   this.router.navigate(["/login"])
 localStorage.clear()
 this.router.navigate(["/"])
   }
 

}
