
<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
    <div class="h-100 page-wraper" id="page-content-wrapper" style="background: #f8f9fa">
        <div class="container-fluid py-3 bg-white">
            <div class="col-sm-12">
                <nav aria-label="breadcrumb p-0" *ngIf="!breadcrumb">
                    <ol class="breadcrumb p-0 bg-white">
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/dashboardnew">Dashboard</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/total-seller">Total Seller</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                    routerLink="/seller-wise-product">Product Listing</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Business Card</li>
                    </ol>
                </nav>
                <nav aria-label="breadcrumb p-0" *ngIf="breadcrumb">
                    <ol class="breadcrumb p-0 bg-white">
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/dashboardnew">Dashboard</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/product-listing">Pending Products</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Configurable Product</li>
                    </ol>
                </nav>
                <div class="col-sm-12 col-md-12 col-lg-12 py-4">
                    <div class="row">
                        <div class="col-lg-4 col-12 ">
        
                            <div class="w-100 ps-stiky">
                                <img class="xzoom w-100" id="xzoom-default" src="../../assets{{mainImg}}"
                                    alt="Image Not Found" />
                                <div class="xzoom-thumbs">
                                    <a href="../../assets/images/product/Cards/1020/img1.png">
                                        <img class="xzoom-gallery" width="80"
                                            src="../../assets/images/product/Cards/80/img1.png"
                                            alt="Image Not Found">
                                    </a>
                                    <a href="../../assets/images/product/Cards/1020/img2.png"><img class="xzoom-gallery"
                                            width="80" src="../../assets/images/product/Cards/400/img2.png"></a>
                                    <a href="../../assets/images/product/Cards/1020/img3.png"><img class="xzoom-gallery"
                                            width="80" src="../../assets/images/product/Cards/400/img3.png"></a>
                                    <a href="../../assets/images/product/Cards/1020/img4.png"><img class="xzoom-gallery"
                                            width="80" src="../../assets/images/product/Cards/400/img4.png"></a>
                                    <a href="../../assets/images/product/Cards/1020/img5.png"><img class="xzoom-gallery"
                                            width="80" src="../../assets/images/product/Cards/400/img5.png"></a>
        
                                </div>
                            </div>
        
        
        
                        </div>
        
                        <div class="col-lg-8 col-12">
                            <div class="product-content">
                                <div class="row">
                                    <div class="col-lg-11 col-12">
                                        <h4>{{card_title}}</h4>
                                        <h6 *ngIf="idc">IDC-{{idc}}</h6>
                                    </div>
                                    <div class="col-lg-1 col-12 ">
                                        <div class="border p-1 radius-5 text-center float-end float-lg-none float-md-none">
                                            <i class="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="rating-product mb-lg-1">
                                    <div class="row">
                                        <div class="col-lg-6 col-12 mb-2">
                                            <i class="	fa fa-star star-cst"></i> <i class="	fa fa-star star-cst"></i> <i
                                                class="	fa fa-star star-cst"></i> <i class="	fa fa-star star-cst"></i> <i
                                                class=" star-cst far fa-star me-1"></i>
        
                                            <i class="fa fa-angle-down me-2"></i>
                                            <span>580 ratings</span>
                                        </div>
                                        <div class="col-lg-6 col-12 mb-2">
                                            <span class="recently-bougth font8 p-1 ps-1 pe-3 small float-none float-lg-end"> <img
                                                    class="img-fluid trending me-1"
                                                    src="../../assets/images/icons/trending.svg " alt=""> 580 people bought this
                                                recently</span>
                                        </div>
                                    </div>
        
                                </div>
        
                                <div class="quantity-item mb-3">
                                    <div class="row">
                                        <div class="col-12 col-lg-12">
                                            <h2 class="d-inline-block fw-bold"> ₹{{price}} <small
                                                    class="text-secondary fw-normal fs-5"> <span><del>₹{{mrp}} </del></span>
                                                </small> <span class="text-secondary fw-normal fs-6">/ Set of {{set_quantity}}</span> <span
                                                    class="text-danger fw-bold ps-2 fs-5">{{discountPer}}% Off</span> </h2>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="quantity-item mb-3" *ngIf="slides.length>0">
                                    <div class="row">
                                        <div class="col-2 col-lg-1 text-center align-item-center">
                                            <h5 class="pt-lg-2 pt-1">Slides</h5>
                                        </div>
                                        <div class="col-10 col-lg-3">
                                            <select name="" id="" class="form-control3">
                                                <option *ngFor="let obj of slides" value="{{obj.text}}">{{obj.text}}</option>
                                                
                                            </select>
        
                                        </div>
                                    </div>
                                </div>
        
        
        
                                <div class="quantity-item mb-4">
                                    <div class="row">
                                        <div class="col-lg-6 col-12 col-md-12">
                                            <div class="extra-features">
                                                <h4 class="mb-3 text-uppercase fs-5">Extra Features</h4>
        
                                                <div class="row mb-2" *ngFor="let obj of extra_features;let i=index;">
                                                    <div class="col-1 col-lg-1 text-center">
                                                        <div>
                                                            <input class="styled-checkbox" id="styled-checkbox-4{{i}}"
                                                               [(ngModel)]="obj.isSelected" (change)="onAddExtraFeature()" type="checkbox" value="value1">
                                                            <label for="styled-checkbox-4{{i}}"></label>
                                                        </div>
                                                    </div>
                                                    <div class="col-11 col-lg-11 ">
                                                        <h5 class="ps-2 fw-normal pt-1 fs-6"><i> {{obj.feature}}:</i> ₹{{obj.price}} </h5>
                                                         </div>
        
                                                </div>
                                                <!-- <div class="row mb-2">
                                                    <div class="col-1 col-lg-1 text-center">
                                                        <div>
                                                            <input class="styled-checkbox" checked id="styled-checkbox-5"
                                                                type="checkbox" value="value1">
                                                            <label for="styled-checkbox-5"></label>
                                                        </div>
                                                    </div>
                                                    <div class="col-11 col-lg-11 ">
                                                        <h5 class="ps-2 fw-normal pt-1 fs-6"><i> Lamination:</i> ₹29 </h5>
        
                                                    </div>
        
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-1 col-lg-1 text-center">
                                                        <div>
                                                            <input class="styled-checkbox" id="styled-checkbox-6"
                                                                type="checkbox" value="value1">
                                                            <label for="styled-checkbox-6"></label>
                                                        </div>
                                                    </div>
                                                    <div class="col-11 col-lg-11 ">
                                                        <h5 class="ps-2 fw-normal pt-1 fs-6"><i> Option 3:</i> ₹49 </h5>
        
                                                    </div>
        
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-1 col-lg-1 text-center">
                                                        <div>
                                                            <input class="styled-checkbox" checked id="styled-checkbox-7"
                                                                type="checkbox" value="value1">
                                                            <label for="styled-checkbox-7"></label>
                                                        </div>
                                                    </div>
                                                    <div class="col-11 col-lg-11 ">
                                                        <h5 class="ps-2 fw-normal pt-1 fs-6"><i> Option 4:</i> ₹49 </h5>
        
                                                    </div>
        
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12 col-md-12" *ngIf="is_supported_logo_customization">
                                            <div class="art-work mb-2">
        
                                                <div class="row mb-2">
        
                                                    <div class="col-12 col-lg-12 ">
                                                        <h4 class="text-uppercase fs-5">Upload Artwork</h4>
                                                        <div class="drag-drop">
                                                            <h6> <img src="../../assets/images/icons/upload.png" class="me-3"
                                                                    alt="">
        
                                                                Drag &
                                                                Drop or Click to upload</h6>
                                                            <input type="file" name="myfile" />
                                                        </div>
                                                    </div>
        
                                                </div>
        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="quantity-item mb-4">
        
                                    <!-- <h4 class="fs-5 mb-1">Add Details</h4>
                                    <p class="fs-6">Need to be print on the card. or <a data-bs-toggle="modal" data-bs-target="#modalid" href="javascript:void(0)">Upload in
                                            Bulk</a></p>
                                    <div class="add-box-form border-bottom mb-3 py-2">
                                        <div class="row">
                                            <div class="col-lg-11 col-12">
                                                <div class="row">
        
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Name" *ngIf="is_supported_name">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Contact Number" *ngIf="is_supported_contact_no">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Email ID" *ngIf="is_supported_email">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Desination" *ngIf="is_supported_designation">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Quantity">
                                                    </div>
        
                                                </div>
        
                                            </div>
                                            <div class="col-lg-1 col-12">
                                                <div class="plus-circle float-end float-lg-none float-md-none">
                                                    <i class="fa fa-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="add-box-form border-bottom mb-3 py-2">
                                        <div class="row">
                                            <div class="col-lg-11 col-12">
                                                <div class="row">
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Name">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Contact Number">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Email ID">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Desination">
                                                    </div>
                                                    <div class="col-lg-4 col-6 col-md-4 mb-3">
                                                        <input type="text" class="form-control" placeholder="Quantity">
                                                    </div>
        
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-12">
                                                <div class="plus-circle float-end float-lg-none float-md-none">
                                                    <i class="fa fa-minus"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
        
                                <div class="total-amount p-2 px-3 rouned mb-3" style="pointer-events: none; opacity: 0.4;">
                                    <div class="row">
                                        <div class="col-lg-4 col- border-right">
                                            <h5>Total Amount</h5>
                                            <h2><b>₹{{grand_total_amount}}</b></h2>
                                            <h6><i>{{total_card_quantity}} Cards</i> </h6>
        
                                        </div>
                                        <div class="col-lg-8 col-12 align-item-center float-end text-lg-end text-center pt-4">
                                            <button class="save-later  btn-lg btn btn-secondary me-lg-3 me-1 rounded-pill">Save for
                                                later</button>
                                            <button class="btn btn-lg add-to-cart rounded-pill "> Add to cart </button>
                                        </div>
        
                                    </div>
                                </div>
        
                                <!-- <div class="quantity mb-3">
                                    <div class="row">
                                        <div class="col-md-6 col-lg-6 col-12">
        
                                            <div class="main-dl  p-2 py-3">
                                                <div class="deliver-to mb-1">
                                                    <h5><img class="img-fluid " src="../../assets/images/icons/deliver-icon.svg"
                                                            alt=""> <span class="ps-3"> Deliver
                                                            To </span></h5>
                                                </div>
                                                <div class="check-delivery mb-2">
                                                    <input type="text" class="delivery form-control ps-0" value="201301">
                                                    <a href="#" class="check-pin">Check</a>
                                                </div>
                                                <h5>Delivery by 21 July, Thursday</h5>
                                                <h5>Free <del class="col-theme"> ₹40 </del> <span class="ps-2"> <a
                                                            href="javascript:void(0)"> <i
                                                                class="fa  fa-question-circle col-gry"></i> </a> </span> </h5>
                                                <h6 class="font-weight-normal">if ordered before 03:55 PM</h6>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-6 col-12 ">
        
                                            <div class="main-dl height-cont  p-2 py-3">
                                                <div class="row mb-2">
                                                    <div class="col-2 text-center">
                                                        <img src="../../assets/images/icons/rupees.png" alt="">
                                                    </div>
                                                    <div class="col-10">
                                                        <h6 class="mb-1">No hidden fees.</h6>
                                                        <h6 class="font-weight-normal">What you see is what you get</h6>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-2 text-center">
                                                        <img src="../../assets/images/icons/eye.png" alt="">
                                                    </div>
                                                    <div class="col-10">
                                                        <h6 class="mb-1">You'll review proofs</h6>
                                                        <h6 class="font-weight-normal">before you merchandise goes to production
                                                        </h6>
                                                    </div>
                                                </div>
        
                                            </div>
                                        </div>
        
                                    </div>
        
                                </div> -->
        
        
        
        
        
        
        
        
        
                            </div>
        
                        </div>
                    </div>
                    <hr class="my-lg-5 my-2 border-lg border-0">
        
        
                    <div class="row">
                        <div class="col-md-5 col-12 col-lg-5">
                            <div class="art-text p-2 px-3 mb-3">
                                <h5 class="fs-6">Need a different artwork area or size? Product not quite what you're looking for? </h5>
                                <p class="mb-0">We can help. Just call 1-800-491-3003 or <a href="javascript:void(0)"
                                        class="text-danger">chat with us</a></p>
                            </div>
                            <div class="product-detl">
                                <h4 class="mb-3 fs-5">Product Details</h4>
                                <h5 class="mb-3" *ngFor="let obj of card_details"> <b> {{obj.lable}}: </b> {{obj.value}} </h5>
                                <!-- <h5 class="mb-3"><b> Material: </b> 100% Cotton </h5>
                                <h5 class="mb-3"><b>GSM:</b> 200 </h5>
                                <h5 class="mb-3"> <b> ICIN: </b> B073IJFJFF </h5>
                                <h5 class="mb-3"><b> Item model number: </b> AW17DSGKG </h5>
                                <h5 class="mb-3"><b>Country of Origin:</b> India </h5>
        
                                <h5 class="mb-3"> <b>Department: </b> Men </h5>
                                <h5 class="mb-3"><b> Item Weight: </b> 270g </h5>
                                <h5 class="mb-3"><b>Item Dementions L*W*H :</b> 38*30*2 Centimeters </h5>
                                <h5 class="mb-3"><b>Net Quantity :</b> 1 Count </h5>
                                <h5 class="mb-3"><b>Included Components :</b> 1 N Men's Polo T-shirt </h5>
                                <h5 class="mb-3"><b>Generic Name :</b> Polo T-Shirt </h5> -->
                            </div>
                        </div>
                        <div class="col-md-7 col-12 col-lg-7 border-left ">
                            <div class="pd-des ps-lg-4">
                                <h4 class="mb-3 fs-5">Product Description</h4>
                                <p> {{description}}
                                </p>
                                <!-- <p> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime animi, a iure blanditiis
                                    odio neque
                                    voluptate ducimus dolorem. Magnam neque veniam recusandae esse corrupti sunt cupiditate quis
                                    earum
                                    minima sed!
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime animi, a iure blanditiis
                                    odio neque
                                    voluptate ducimus dolorem. Magnam neque veniam recusandae esse corrupti sunt cupiditate quis
                                    earum
                                    minima sed!
                                </p> -->
                            </div>
                        </div>
        
                    </div>
        
        
                    <hr class="my-3 border">
        
        
                    <div class="review-area">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-12">
                                <div class="cst-review mb-5">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div id="maindiv" class="">
        
                                                <h4 class="fs-5">
                                                    Customer reviews
                                                </h4>
                                                <div class="submain">
        
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="far fa-star icon" aria-hidden="true"></i>
                                                    <span class="topspan">3.9 out of 5 (15,936 ratings)</span>
        
                                                    <div class="sub">
                                                        <div class="supersub mb-3">
                                                            <div class="row">
                                                                <div class="col-2 col-lg-1 pe-0">
                                                                    <div class=" fs-6"> 5 Star</div>
                                                                </div>
                                                                <div class="col-8 col-lg-8 ps-0">
                                                                 
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style="width: 62%" aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                                                      </div>
        
                                                                </div>
                                                                <div class="col-2 col-lg-2">
                                                                    <div class="fs-6">
                                                                        62%
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                        <div class="supersub mb-3">
                                                            <div class="row">
                                                                <div class="col-2 col-lg-1 pe-0">
                                                                    <div class=" fs-6"> 4 Star</div>
                                                                </div>
                                                                <div class="col-8 col-lg-8 ps-0">
                                                                 
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                      </div>
        
                                                                </div>
                                                                <div class="col-2 col-lg-2">
                                                                    <div class="fs-6">
                                                                        70%
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                        <div class="supersub mb-3">
                                                            <div class="row">
                                                                <div class="col-2 col-lg-1 pe-0">
                                                                    <div class=" fs-6"> 3 Star</div>
                                                                </div>
                                                                <div class="col-8 col-lg-8 ps-0">
                                                                 
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                      </div>
        
                                                                </div>
                                                                <div class="col-2 col-lg-2">
                                                                    <div class="fs-6">
                                                                        25%
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                        <div class="supersub mb-3">
                                                            <div class="row">
                                                                <div class="col-2 col-lg-1 pe-0">
                                                                    <div class=" fs-6"> 2 Star</div>
                                                                </div>
                                                                <div class="col-8 col-lg-8 ps-0">
                                                                 
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                                                      </div>
        
                                                                </div>
                                                                <div class="col-2 col-lg-2">
                                                                    <div class="fs-6">
                                                                        15%
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                        <div class="supersub mb-3">
                                                            <div class="row">
                                                                <div class="col-2 col-lg-1 pe-0">
                                                                    <div class=" fs-6"> 1 Star</div>
                                                                </div>
                                                                <div class="col-8 col-lg-8 ps-0">
                                                                 
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style="width: 5%" aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                                                                      </div>
        
                                                                </div>
                                                                <div class="col-2 col-lg-2">
                                                                    <div class="fs-6">
                                                                        5%
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                   
                                                    
        
        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="by-feature">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <h4 class="mb-3 fs-5">By feature</h4>
                                            <h5 class="fs-6">Value for money <span class="float-end"> <i class="fa fa-star icon "
                                                        aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="far fa-star icon" aria-hidden="true"></i>
                                                    <span class="topspan">3.9 out of 5 (15,936 ratings)</span> </span> </h5>
                                            <h5 class="mb-3 fs-6">Value for money <span class="float-end"> <i
                                                        class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="fa fa-star icon " aria-hidden="true"></i>
                                                    <i class="far fa-star icon" aria-hidden="true"></i>
                                                    <span class="topspan">3.9 out of 5 (15,936 ratings)</span> </span> </h5>
                                        </div>
                                    </div>
        
                                </div>
        
                                <div class="review-this mb-4">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <hr class="my-3 border">
                                            <h4 class="fs-5">Review this product</h4>
                                            <h6 class="mb-3">Share your thoughts with other Customers</h6>
                                            <button class="write-a-rivew">Write a Product Review</button>
                                        </div>
                                    </div>
        
        
                                </div>
                                <div class="eye-bolg mt-4 pt-4">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <div class="eyebloger text-white text-center p-5 rouned-eye">
                                                <h3>EYEBOGLER Regular Fit Men's Cotton T-Shirt</h3>
                                                <h2 class="display-1"> <b> 5968089 <small class="pcs">Pcs</small> </b></h2>
                                                <h2>Sold</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-12">
                                <div class="top-reviews">
                                    <div class="row mb-3">
                                        <div class="col-lg-3 col-6">
                                            <select name="" id="" class="form-control-select">
                                                <option value="">Top Reviews</option>
                                                <option value="">Quality</option>
                                                <option value="">Price</option>
                                            </select>
        
                                        </div>
                                        <div class="col-lg-3 col-6">
                                            <h5 class="pt-2">Top Reviews</h5>
                                        </div>
        
                                    </div>
                                    <div class="review-box-user mb-3 border-bottom pb-4">
                                        <h6> <i class="fa fa-user-circle user-icon"></i> Madhubala kambala | Purchased
                                            Quantity: 860 Pcs </h6>
                                        <h6> <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="far fa-star  fonti-12" aria-hidden="true"></i>
                                            <span class="ms-2">4.9 out of 5 </span> <span> <b class="ps-2"> Execellent
                                                </b></span>
                                        </h6>
                                        <h6>Reviewed on 28 February 2018</h6>
                                        <h6>Colour: Candle Pink <span class="px-3"> | </span> <span class="col-theme"> Verified
                                                Purchase</span></h6>
                                        <h6>Good Quality and fits perfectly. And its beautiful rose pink in color</h6>
                                        <div class="img-rev">
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-box-user mb-3 border-bottom pb-4">
                                        <h6> <i class="fa fa-user-circle user-icon"></i> Madhubala kambala | Purchased
                                            Quantity: 860 Pcs </h6>
                                        <h6> <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="far fa-star  fonti-12" aria-hidden="true"></i>
                                            <span class="ms-2">4.9 out of 5 </span> <span> <b class="ps-2"> Execellent
                                                </b></span>
                                        </h6>
                                        <h6>Reviewed on 28 February 2018</h6>
                                        <h6>Colour: Candle Pink <span class="px-3"> | </span> <span class="col-theme"> Verified
                                                Purchase</span></h6>
                                        <h6>Good Quality and fits perfectly. And its beautiful rose pink in color</h6>
                                        <div class="img-rev">
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review-box-user mb-3 pb-4">
                                        <h6> <i class="fa fa-user-circle user-icon"></i> Madhubala kambala | Purchased
                                            Quantity: 860 Pcs </h6>
                                        <h6> <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="fa fa-star  fonti-12" aria-hidden="true"></i>
                                            <i class="far fa-star  fonti-12" aria-hidden="true"></i>
                                            <span class="ms-2">4.9 out of 5 </span> <span> <b class="ps-2"> Execellent
                                                </b></span>
                                        </h6>
                                        <h6>Reviewed on 28 February 2018</h6>
                                        <h6>Colour: Candle Pink <span class="px-3"> | </span> <span class="col-theme"> Verified
                                                Purchase</span></h6>
                                        <h6>Good Quality and fits perfectly. And its beautiful rose pink in color</h6>
                                        <div class="img-rev">
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                            <div class="img-b">
                                                <img src="../../assets/images/rev-img.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                        </div>
        
                    </div>
                </div>
          
        </div>
    </div>
<!----Popup-->
<!--Modal Starts-->

<div class="modal fade" id="modalid">
    <div class="modal-dialog modal-dialog-centered modal-lg ">
        <div class="container-fluid">
            <div class="modal-content">
                <div class="modal-header border-bottom-0 d-inline">
                    <span class="float-end btn-close m-auto" data-bs-dismiss="modal"></span>
                    <h4 class="modal-title fs-5">Upload Details</h4>

                    <p class="p-0 mb-0 fs-6">Need to be print on the card</p>
                </div>
                <div class="modal-body p-5">
                    <input type="file" id="fileupld" style="display:none">
                    <label class="input-group-text justify-content-center" for="fileupld">
                        <div class="py-5">
                            <figure class="figure text-center">
                                <img class="figure-img" src="../../assets/images/icons/upload.png" alt="">
                            </figure>
                            <p class="small">Drag and drop or click to upload</p>
                        </div>
                    </label>
                    <a href="javascript:void(0)" class="pt-2 d-inline-block  text-decoration-none fs-6"> <i class="fa fa-download"></i>  Download Template</a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
