<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid bg-white">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item newitm1">
                                <a href="javascript:void(0)" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item newitm2  active" aria-current="page">Quick Order</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <!-- 
        <div class="col-sm-12 p-0">
          <div class="tabs col-lg-12 pl-0 pr-1 my-2 ">
            <div class="tab-button-outer1 col-lg-12 pl-0">
              <ul id="tab-button">
                <li class="is-active">
                  <a href="javascript:void(0)" class="font8">Add New Deal</a>
                </li>
              </ul>
            </div>
        </div>
        </div> -->

            <div class="col-sm-12 mb-2 p-0 mt-2">

                <div id="tab01" class=" mmt5 p-0">
                    <div class="col-sm-12 col-md-12  nopadding">
                        <div id="grid-theme-wrapper">
                            <div class="tabs d-flex mb-1">

                                <!-- <div class="tab-button-outer1  col-lg-6 pl-0">
                      <ul id="tab-button">
                        <li id="tabDraft" class="is-active">
                          <a href="javascript:void(0)" class="font8">Seller List
                          </a>
                        </li>
                      </ul>
                    </div> -->
                                <div class="tab-button-outer1  col-sm-5 pl-0">
                                    <ul id="tab-button" class="tbsbtns">
                                        <li id="tabDraft" class="is-active">
                                            <!-- SearchValue.value = '' -->
                                            <a href="javascript:void(0)" class="font8">First Tab
                                                <span></span>
                                            </a>
                                        </li>

                                        <!-- <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getSuspendSeller(); SearchValue.value = '' ">Suspended
                            <span >({{suspendedCount}})</span>
                          </a>
                        </li> -->
                                    </ul>
                                </div>
                                <div class="col-sm-7 cstmposition2 d-none d-sm-block pr-0" id="masterSearch">
                                    <div class="sort-wrapper pull-right width100">
                                        <div class="form-group col-lg-6">
                                            <div class=" input-group">
                                                <input type="text" class="searchbar form-control newform-control" onpaste="return false;" autocomplete="off" placeholder="Search in Page"
                                                    #SearchValue id="searchvaluemaster" />
                                                <a href="javascript:void(0)" class="btn btn-cstm px-2 mt-0">
                                                    <i class="fa fa-search"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <button class="btn btn-sm  btn-theme" data-toggle="modal" data-target="#quick-order">
                                            <i class="fa fa-plus mr-1"></i>Add Quick Order</button>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <igx-grid #grid1 width="100%" height="" displayDensity="cosy">
                                <!-- <ngx-loading [show]="isLoading"></ngx-loading>  -->

                                <igx-column header="Products Name" width="232">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                        <div class="address-container">
                                            <a href="javascript:void(0)">
                                                <span class="cell-vl ">Cell Value
                                                </span>
                                            </a>
                                        </div>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="category_name" header="Product Category" width="232">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                        <div class="address-container">
                                            <span class="cell-vl ">fsfsdfsdfsd</span>
                                        </div>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="product_deal_quantity" header="Quantity" width="232">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                        <div class="address-container">
                                            <span class="cell-vl ">ffsdfsdfsdfsd</span>
                                        </div>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="selling_price" header="Price" width="232">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                        <div class="address-container">
                                            <span>fdsfsdsfdfsdfsd</span>
                                        </div>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="discount_percent" header="Offer(%)" width="232">
                                    <ng-template #compositeTemp igxCell let-cell="cell">
                                        <div class="address-container">
                                            <span class="cell-vl ">fdsfsdfsdfsdfsd</span>
                                        </div>
                                    </ng-template>
                                </igx-column>
                                <!-- <igx-column field="start_date" header="startDate" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                                <!-- <igx-column field=" " header="Action" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->

                                <igx-column field="product_name" header="Action" width="100">
                                    <ng-template compositeTemp igxCell let-cell="cell">
                                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">


                                            <button id="btnGroupDrop5" type="button" class="py-2 px-3 btn btn-sm btn-light shadow-none dropdown-toggle" data-toggle="dropdown"
                                                aria-expanded="false">
                                                Action
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop5">

                                                <li class="border-bottom">
                                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#quick-order" class="text-decoration-none pl-0 font-weight-bold text-darkorange dropdown-item">
                                                        <i class="fa fa-pencil mr-1"></i> Edit </a>

                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                                        <i class="fa fa-trash mr-1"></i> Delete </a>
                                                </li>

                                            </ul>

                                        </div>

                                        <!-- <div class="address-container mr-2 text-center">
                        <button data-toggle="modal" class="btn btn-sm btn-dark" data-target="#add-new-deal">
                          <i class="fa fa-pencil mr-1" ></i> Edit</button>
                      </div>
                      <div class="address-container text-center">
                       
                          <button class="btn btn-sm btn-danger">
                          <i class="fa fa-trash mr-1"></i> Delete</button>
                      </div> -->
                                    </ng-template>
                                </igx-column>


                            </igx-grid>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="myModal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="ms-auto p-2">
                        <button type="button" class="close btn" data-dismiss="modal">
                            &times;
                        </button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body px-3 py-0">
                        <div class="col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label>Remarks</label>
                                </div>
                                <div class="col-sm-12 ">
                                    <textarea name="remarks" id="remarks" placeholder="Enter remarks here...." class="form-control2" maxlength="500"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class=" py-3 text-center ">

                            <div class="unBlocked">
                                <!-- <label for="unblocked " class="m-3">Unblock</label> -->
                                <!-- <input type="checkbox" #unb required name="unblocked" id="unblocked" ngModel> -->
                                <button class="btn btn-theme bg-primary border-primary mr-2 btn-sm ">Unblock</button>
                                <button class="btn btn-sm btn-theme border-danger bg-danger" data-dismiss="modal">Close</button>
                            </div>
                            <div class="unsuspend">
                                <button class="btn btn-theme bg-primary border-primary mr-2 btn-sm">Active</button>
                                <button class="btn btn-sm btn-danger border-danger bg-danger" data-dismiss="modal">Close</button>
                                <!-- <label for="unsuspend" class="m-3">Active</label>
                      <input type="checkbox" #sus required name="unsuspend" id="unsuspend" ngModel> -->
                            </div>

                        </div>

                        <!-- <div class="modal-footer p-2">
                  <button class="btn btn-sm btn-theme bg-primary border-primary mr-2" [disabled]="!myForm.valid" (click)="reAcitve(181)">Submit</button>
                  <button class="btn btn-sm btn-danger" data-dismiss="modal">Close</button>
                </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal" id="toBlock" >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{}}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <div class="col-sm-12 col-lg-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <label>Remarks</label>
                          </div>
                          <div class="col-sm-12">
                            <textarea name="remarks" id="remarks" [(ngModel)]="adminRemarks"  class="form-control2" maxlength="500"></textarea>
                        </div>
                        </div>
                      </div>
                      <div class="action">
                          <div class="block form-check d-flex">
                            <div>
                              <label for="block " class="mr-3">Block</label>
                              <input type="radio" class="  mr-2" value="183"  [(ngModel)]="blk_suspend" id="block" name="block">
                            </div>
                            <div>
                              <label for="susp" class="mr-3">Suspend</label>
                              <input type="radio" class="mr-2 " value="184" [(ngModel)]="blk_suspend"  id="susp" name="block">
                            </div>
                         
                          </div>
                         
                      </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" [disabled]="blk_suspend == ''" (click)="reAcitve(blk_suspend)">Submit</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div> -->
        <div class="modal" id="toBlock">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header p-2">
                        <h6 class="modal-title">Action</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-2">
                        <div class="col-sm-12 col-lg-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label>Remarks</label>
                                </div>
                                <div class="col-sm-12 mb-3">
                                    <textarea name="remarks" id="remarks" placeholder="Enter remarks here...." class="form-control2" maxlength="500"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="action">
                            <div class="block form-check d-flex">
                                <div class="row m-0 py-2">
                                    <input type="radio" class="ps-top" value="183" id="block" name="block">
                                    <label for="block " class="ml-2">Block</label>

                                    <input type="radio" class="ml-3 ps-top" value="184" name="block">
                                    <label for="susp" class="ml-2">Suspend</label>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="text-center  p-2">
                        <button type="button" class="btn btn-sm text-white bg-success border-success mr-2">Submit</button>
                        <button type="button" class="btn btn-sm text-white bg-danger border-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="quick-order" tabindex="-1" role="dialog" aria-labelledby="quick-orderLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header py-2">
                <h6 class="modal-title" id="quick-orderLabel">
                    <strong> Quick Order </strong>
                </h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3">
                <div class="row">
                    <div class="col-sm-12 mb-2">
                        <label for="">Select Category
                            <span class="asterisk">*</span>
                        </label>

                        <select name="category" id="category" class="form-control" [(ngModel)]="category" (change)="getSubCategory()">
                            <option value="undefined" disabled selected>Select Category</option>
                            <option [value]="cat.product_category_id" *ngFor="let cat of categoryList">
                                {{cat.category_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="">Select Sub Category
                            <span class="asterisk">*</span>
                        </label>

                        <select name="sub-category" id="sub-category" class="form-control" [(ngModel)]="sub_category" (change)="getProducts()">
                            <option value="undefined" disabled selected>Select Sub Category</option>
                            <option *ngFor="let sub of subCategoryList" [value]="sub.product_category_id">
                                {{sub.category_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="">Select Product
                            <span class="asterisk">*</span>
                        </label>
                        <select name="" id="" class="form-control" [(ngModel)]="selectedProdcut" (change)="getMRPPrice()">
                            <option value="undefined" disabled selected>Select Product</option>
                            <option [value]="pro.product_id" *ngFor="let pro of allProductList; index as i">
                                {{pro.product_name}}
                            </option>
                            <!-- <option value=""> Pen </option>
                              <option value=""> Cap </option> -->
                        </select>
                    </div>
                    <!--            
                  <div class="col-sm-3 mb-2">
                    <label for="">Start Date <span class="asterisk">*</span></label>
                    <input type="date" class="form-control" placeholder="Enter Date">
      
                    </div>
                    <div class="col-sm-3 mb-2">
                      <label for="">End Date <span class="asterisk">*</span></label>
                      <input type="date" class="form-control" placeholder="End Date">
        
                      </div> -->


                    <div class="col-sm-12 mb-2 mt-4 text-center">
                        <button class="btn btn-sm btn-theme px-3  mr-2"> Add</button>
                        <!-- <button class="btn btn-sm btn-theme mr-2">  Update</button> -->
                        <button class="btn btn-sm btn-danger"> Reset</button>
                    </div>


                </div>
            </div>
            <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
        </div>
    </div>
</div>

<!-- view model -->

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h5 class="modal-title" id="exampleModalLabel">Product Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"> &times; </span>
                </button>
            </div>
            <div class="modal-body minhyt70 p-0" data-spy="scroll" id="scroll">

            </div>
            <!-- <div class="modal-footer p-2">
        <button type="button" class="btn btn-gray btn-sm rounded-pill px-2" data-dismiss="modal">
          Close
        </button>
      </div> -->
        </div>
    </div>
</div>