<div class="d-flex minhyt80" id="wrapper" >	
  <div class="" id="sidebar-wrapper">
     <app-left-menu></app-left-menu>
 </div> 
 <div class="" id="page-content-wrapper">

<app-header (profileIdChange)="onProfileIdChange($event)"> </app-header>
<div class="container-fluid bckgry">
  <div class="col-sm-12 p-0">
  <div class="row d-flex ">
    <div class="col-sm-12 p-0">
      <section class="col-sm-12 mt-0 p-0">
        <div class="row m-0">

          <!--LEFT SECTION-->

          <div class="col-lg-9 prr-1-new">
            <div class="card mt-2 minhy30">
              <!-- <div class="card-header cstmheader">
                                                     <img src="../../assets/images/icon/tasklist.png" class="iconstyle" />
                                                            {{labelObj ? (isHindi ? labelObj.tasklist.labelHn : labelObj.tasklist.labelEn):"Task Name"}}
                                                 
                                            </div> -->
              <div class="card-body px-3 pt-1">
                <h5 class="linktitle mt-1 mb-1"><img src="../../assets/images/icon/task.svg" class="width30p">&nbsp;&nbsp;
                  {{labelObj ? (isHindi ? labelObj.tasklist.labelHn : labelObj.tasklist.labelEn):"Task Name"}}
                </h5>
                <div class="row pt-1">
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2" (click)="gotoNextPage('pending')">
                    <div class="card bg-success1  h-100">
                      <div class="card-body bg-success1">
                        <h6 class=" fw-600 ble fnt16n mb-0">Pending For Action</h6>
                        <p class="mb-3 ble">(Inbox)</p>
                        <div class="rotate mb-2">
                          <img src="../../assets/images/icon/action.svg" style="width: 60px;position: absolute;" >
                          <!-- <i class="fa fa-clock-o fa-3x first"></i> -->
                          <h1 class="display-4 mb-0 text-right">10</h1>

                          <p class="text-right  fw-600 ble  mb-0">Action</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2" (click)="gotoNextPage('outbox')">
                    <div class="card bg-success1  h-100">
                      <div class="card-body bg-success1">
                        <h6 class="fw-600 ble fnt16n mb-0">Outbox</h6>
                        <p class="mb-2">&nbsp;</p>
                        <div class="rotate mb-2">
                          <img src="../../assets/images/icon/outbox.svg" style="width: 60px;position: absolute;">
                          <!-- <i class="fa fa-clock-o fa-3x first"></i> -->
                          <h1 class="display-4 mb-0 text-right">18</h1>
                          <p class="text-right  fw-600 ble mb-0">Request</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2" (click)="gotoNextPage('inprocess')">
                    <div class="card bg-success1  h-100">
                      <div class="card-body bg-success1">
                        <h6 class="fw-600 ble fnt16n mb-0">Submitted</h6>
                        <p class="mb-3 ble">(InProcess)</p>
                        <div class="rotate mb-2">
                          <img src="../../assets/images/icon/pending_request.svg"
                            style="width: 60px;position: absolute;">
                          <!-- <i class="fa fa-clock-o fa-3x first"></i> -->
                          <h1 class="display-4 mb-0 text-right">12</h1>
                          <p class="text-right  fw-600 ble  mb-0">Request</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 col-lg-3 my-2" (click)="gotoNextPage('approved')">
                    <div class="card bg-success1  h-100">
                      <div class="card-body bg-success1">
                        <h6 class=" fw-600 ble mb-0 fnt16n">Approved Proposal</h6>
                        <p class="mb-3 ble">&nbsp;</p>
                        <div class="rotate mb-2">
                          <img src="../../assets/images/icon/self_request.svg" style="width: 60px;position: absolute;">
                          <!-- <i class="fa fa-clock-o fa-3x first"></i> -->
                          <h1 class="display-4 mb-0 text-right">15</h1>
                          <p class="text-right  fw-600 ble  mb-0">Request</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


              </div>

            </div>
          </div>
          <div class="col-lg-3 pll-0 pr-0 mt-2">
            <div class="col-sm-12 pll-0-n">
              <div class="card">
                <div class="card-body px-2 minhy30">
                  <h5 class="linktitle mt-1 mb-1">
                    <img src="../../assets/images/icon/bell.svg" class="width30p" />&nbsp;&nbsp;{{labelObj ? (isHindi ?
                    labelObj.notification.labelHn : labelObj.notification.labelEn):'Notification'}}
                  </h5>
                  <div class="">
                    <div class="col-sm-12 alert alert-orange">
                      <label>{{labelObj ? (isHindi ? labelObj.annualdecalaration.labelHn :
                        labelObj.annualdecalaration.labelEn):'Please Declare
                        Annual Asset Declaration for Next Financial Year before 15 April 2020.'}}</label>
                    </div>

                    <div class="col-sm-12 alert alert-orange">
                      <label>{{labelObj ? (isHindi ? labelObj.fillingPARs.labelHn :
                        labelObj.fillingPARs.labelEn):'Regarding Filling PARs
                        -Please fill PARs online through HRMS.'}}</label>
                    </div>

                    <div class="col-sm-12 alert alert-orange">
                      <label>{{labelObj ? (isHindi ? labelObj.updateeventnotify.labelHn :
                        labelObj.updateeventnotify.labelEn):'Please update
                        all event Service book entry.'}}
                      </label>
                    </div>


                    <!-- <div class="col-sm-12 alert alert-orange">
                                    <label> Next Hearing Date is 28/02/2020 Regarding Case No. is 569875 and Name of Court is Supreme Court </label>
                                </div> -->

                    <!-- <table class="tblpos">
                    <tr>
                    <td class="width60 text-right"> <span class="requesttitle"> {{labelObj ? (isHindi ? labelObj.leaveReq.labelHn : labelObj.leaveReq.labelEn):"Leave Request"}} </span> </td>
                    <td class="width10 text-center"> : </td>
                    <td class="width30"> <span class="counttitle bluebadge">1</span> </td>
                    </tr>
                    <tr>
                    <td class="width60 text-right"> <span class="requesttitle"> {{labelObj ? (isHindi ? labelObj.claimReq.labelHn : labelObj.claimReq.labelEn):"Claim Request"}} </span> </td>
                    <td  class="width10 text-center">: </td>
                    <td> <span class="counttitle greenbadge">3</span> </td>
                    </tr>
                    <tr>
                    <td  class="width60 text-right"> <span class="requesttitle"> {{labelObj ? (isHindi ? labelObj.trainingReq.labelHn : labelObj.trainingReq.labelEn):"Training Request"}} </span> </td>
                    <td  class="width10 text-center">: </td>
                    <td> <span class="counttitle bluebadge">2</span> </td>
                    </tr>
                       <tr>
                    <td  class="width60 text-right"> <span class="requesttitle"> {{labelObj ? (isHindi ? labelObj.rhf.labelHn : labelObj.rhf.labelEn):"RFH"}} </span> </td>
                    <td  class="width10 text-center">: </td>
                    <td> <span class="counttitle greenbadge">1</span> </td>
                    </tr>
                    </table> -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-lg-12">

            <div class="tab-content tab-contents pl-0 pr-0 pt-0 card pb-0">
              <ul class="nav nav-tabs newnav">
                <li class="active"><a data-toggle="tab" class="active" href="#tab1" id="tabs-1" role="tab"
                    aria-controls="tab1" aria-selected="true">Draft Data</a>
                </li>
                <!-- <li><a data-toggle="tab" href="javascript:void(0)" href="#tab2" id="tabs-2" role="tab"
                    aria-controls="tab2" aria-selected="true">Query Grid</a>
                </li>
                <li><a data-toggle="tab" href="javascript:void(0)" href="#tab3" id="tabs-3" role="tab"
                    aria-controls="tab3" aria-selected="true">Break-Out Charts</a>
                </li> -->
              </ul>
              <div class="tab-pane fade in active show pb-0 minhy60" id="tab1" role="tabpanel" aria-labelledby="tabs-1">
                <div class="row">
                  <div class="col-lg-12">

                    <!-- <div class="cstmmultiselect selectdata">
                      <div class="example-header cstmpage pt-0 cstminblock pull-left ml-2">
                        <a href="javascript:void(0)" class="btn btn-sm btn-dark filtericon pt-0 pb-0 hyt24 mr-1"
                          (click)="modalRoot.show(); isResetFilter=false;"> <i class="fa fa-filter"></i>
                        </a> <span *ngIf="viewFilterString"
                          class="badge badge-pill badge-success badge-outlined fnt100 pt-1 pb-1">
                          <div class="float-left" [innerHTML]="viewFilterString"></div>
                          <a href="javascript:void(0)" class="posclose" (click)="removeFilter()"><i
                              class="fa fa-times"></i> </a>
                        </span>
                      </div>                      
                      <span class="badge badge-pill badge-success badge-outlined font8 pt-1 pb-1 my-1 ml-2">
                      </span>
                    </div> -->
                    <app-modal #modalRoot class="modal-demo custommodal">
                      <ng-container class="app-modal-header p-2">Add Rule</ng-container>
                      <ng-container class="app-modal-body">
                        <app-user-rule [formId]="formId" [SessionUrlPrams]="sessionUrlPrams"
                          [resetFilter]="isResetFilter" (applyEvent)="apply($event)"></app-user-rule>
                      </ng-container>
                      <ng-container class="app-modal-footer p-2 text-right">
                        <button type="button" id="clos" (click)="modalRoot.hide()" style="display: none">Close</button>
                      </ng-container>
                    </app-modal>
                    <div class="grid__wrapper tableMain p-0">
                      <!-- <app-hds-main-tab-group [configParms]="configParms" *ngIf="LoadPagination == true" (valueChange)="LoadGrid($event)">
                      </app-hds-main-tab-group> -->
                      <igx-grid #grid1 
                        [data]="inboxRows" 
                        width="100%" height="" 
                        [autoGenerate]="false"
                        [allowFiltering]="true" 
                        displayDensity="cosy" 
                        [filterMode]="'excelStyleFilter'"
                        [paging]="true" [perPage]="10"
                        (onDataPreLoad)="actionstrip.hide()"
                        (onRowSelectionChange)="handleRowSelection($event)">
                        <ng-container *ngFor="let col of inboxColumndef let i=index">
                          <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                            [minWidth]='"200px"' [sortable]="col.sortable" [filterable]="col.filter">
                            <ng-template igxCell let-cell="cell">
                              {{cell.value}}
                              <!-- <a class="truncate" href="javascript:void(0)"
                                (click)="ShowViewData(cell)">{{cell.value}}</a> -->
                            </ng-template>
                          </igx-column>
                          <igx-column [field]="col.field" [header]="col.headerName"
                            *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                            [filterable]="col.filter"></igx-column>

                        </ng-container>
                        <igx-action-strip #actionstrip>
                          <div class="drop-down-wrapper">
                            <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting"
                              [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu" igxButton="icon">
                              <igx-icon>more_vert</igx-icon>
                            </button>
                            <igx-drop-down #menu [allowItemsFocus]="true">
                              <ng-container *ngFor="let item of columnActionStrip">
                                <igx-drop-down-item (click)="onProcess(actionstrip.context)">
                                  <div>
                                    <span><i class="fa fa-{{ item.iconName }}"></i>
                                      {{ item.field }}
                                    </span>
                                  </div>
                                </igx-drop-down-item>
                              </ng-container>
                            </igx-drop-down>
                            <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                          </div>
                        </igx-action-strip>
                      </igx-grid>
                    </div>
                  </div>
                </div>
                <!-- <div class="wid100">

                  <div class="card shadow-none">
                    <div class="card-body px-2">

                      <div style="display: block;">
                        <canvas baseChart width="500" height="380px" [datasets]="lineChartData"
                          [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
                          [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
                        </canvas>
                      </div>
                    

                    </div>
                  </div>

                </div> -->
              </div>
              <div class="tab-pane fade minhy60" id="tab2" role="tabpanel" aria-labelledby="tabs-2">
                <!-- <div class="row">
                  <div class="col-lg-12">

                    <div class="cstmmultiselect selectdata">
                      <div class="example-header cstmpage pt-0 cstminblock pull-left ml-2">
                        <a href="javascript:void(0)" class="btn btn-sm btn-dark filtericon pt-0 pb-0 hyt24 mr-1"
                          (click)="modalRoot.show(); isResetFilter=false;"> <i class="fa fa-filter"></i>
                        </a> <span *ngIf="viewFilterString"
                          class="badge badge-pill badge-success badge-outlined fnt100 pt-1 pb-1">
                          <div class="float-left" [innerHTML]="viewFilterString"></div>
                          <a href="javascript:void(0)" class="posclose" (click)="removeFilter()"><i
                              class="fa fa-times"></i> </a>
                        </span>
                      </div>                      
                      <span class="badge badge-pill badge-success badge-outlined font8 pt-1 pb-1 my-1 ml-2">
                      </span>
                    </div>
                    <app-modal #modalRoot class="modal-demo custommodal">
                      <ng-container class="app-modal-header p-2">Add Rule</ng-container>
                      <ng-container class="app-modal-body">
                        <app-user-rule [formId]="formId" [SessionUrlPrams]="sessionUrlPrams"
                          [resetFilter]="isResetFilter" (applyEvent)="apply($event)"></app-user-rule>
                      </ng-container>
                      <ng-container class="app-modal-footer p-2 text-right">
                        <button type="button" id="clos" (click)="modalRoot.hide()" style="display: none">Close</button>
                      </ng-container>
                    </app-modal>
                    <div class="grid__wrapper tableMain p-0">
                      <app-hds-main-tab-group [configParms]="configParms" *ngIf="LoadPagination == true" (valueChange)="LoadGrid($event)">
                      </app-hds-main-tab-group>
                      <igx-grid #grid1 [data]="data" width="100%" height="" [autoGenerate]="false"
                        [allowFiltering]="true" displayDensity="cosy" [filterMode]="'excelStyleFilter'"
                        [(perPage)]="page.size" (onDataPreLoad)="actionstrip.hide()"
                        (onRowSelectionChange)="handleRowSelection($event)">
                        <ng-container *ngFor="let col of columnDefs let i=index">

                          <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                            [minWidth]='"200px"' [sortable]="col.sortable" [filterable]="col.filter">
                            <ng-template igxCell let-cell="cell">
                              <a class="truncate" href="javascript:void(0)"
                                (click)="ShowViewData(cell)">{{cell.value}}</a>
                            </ng-template>
                          </igx-column>

                          <igx-column [field]="col.field" [header]="col.headerName"
                            *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                            [filterable]="col.filter"></igx-column>

                        </ng-container>
                        <igx-action-strip #actionstrip>
                          <div class="drop-down-wrapper">
                            <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting"
                              [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu" igxButton="icon">
                              <igx-icon>more_vert</igx-icon>
                            </button>
                            <igx-drop-down #menu [allowItemsFocus]="true">
                              <ng-container *ngFor="let item of columnActionStrip">
                                <igx-drop-down-item (click)="onClickActionStrip(actionstrip.context,item.field)">
                                  <div>
                                    <span><i class="fa fa-{{ item.iconName }}"></i>
                                      {{ item.field }}
                                    </span>
                                  </div>
                                </igx-drop-down-item>
                              </ng-container>
                            </igx-drop-down>
                            <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                          </div>
                        </igx-action-strip>
                      </igx-grid>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="tab-pane fade minhy60" id="tab3" role="tabpanel" aria-labelledby="tabs-3">
                <div class="row m-0 charttbl d-flex">
                  <div class="col-md-4 pr-0">
                    <div  style="display: block; width: 100%;  "  class="card noshdw pb-3">
                      <canvas baseChart  
                        [data]="pieChartData"
                        height="220"
                        [labels]="pieChartLabels"
                        [chartType]="pieChartType"
                        [options]="pieChartOptions"
                        [plugins]="pieChartPlugins"
                        [legend]="pieChartLegend"
                        [colors]="pieChartColorsOne">
                      </canvas>
                    </div>
                  </div>
                  <div class="col-md-4 pr-0"> 
                    <div  style="display: block; width: 100%;"  class="card noshdw pb-3">
                      <canvas baseChart  
                        [data]="pieChartDataThree"
                        height="220"
                        [labels]="pieChartLabelsThree"
                        [chartType]="pieChartType"
                        [options]="schemeCategoreyOptions"
                        [plugins]="pieChartPlugins"
                        [legend]="pieChartLegend"
                        [colors]="pieChartColorsTwo">
                      </canvas>
                    </div>
                  </div>
                  <div class="col-md-4 ">
                    <div  style="display: block; width: 100%;  "   class="card noshdw pb-3">
                      <canvas baseChart
                        [data]="pieChartDataTwo"
                        height="220"
                        [labels]="pieChartLabelsTwo"
                        [chartType]="pieChartType"
                        [options]="fundingCategoreyOptions"
                        [plugins]="pieChartPlugins"
                        [legend]="pieChartLegend"
                        [colors]="pieChartColorsThree">>
                      </canvas>
                    </div>
                  </div>
               
              </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
  </div>
</div>

</div></div>