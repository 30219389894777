<footer class="page-footer font-small"  id="footer">

       
      
        <!-- Copyright -->
        <div class="footer-copyright text-center py-2 font8 fntedit ">© Ideacraft 2022 <span  class="corp"></span> | All Rights Reserved. 
          <!-- <a href="javascript:void(0)" class="text-white">  </a>
          <a href="javascript:void(0)"> Humanitics Softwares Dimensions Pvt Ltd., 2020.</a> -->
        </div>
        <!-- Copyright -->
      
      </footer>
      <!-- <footer>
        <div id="footer" class="container p-4">
      <div class="row">
        <div class="col-sm-12 col-md-2 col-lg-2 py-3">
            <img src="../../../assets/images/logo_foot.png" class="img-fluid width150px">
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3 py-3">
            <h6 class="font-weight-bold">About</h6>
            <ul class="footerul">
                <li>
                    <a href="javascript:void(0)" class="">
                        Contact Print & Marketing Services
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                       Image Storage  Policy
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                     Privacy  Policy
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                     Terms and Conditions
                    </a>
                </li>
            </ul>
        </div>
    
        <div class="col-sm-12 col-md-2 col-lg-2 py-3">
            <h6 class="font-weight-bold">Quick Links</h6>
            <ul class="footerul">
                <li>
                    <a href="javascript:void(0)" class="">
                       My Projects
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                     My Orders
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
               Store Locator
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                     Terms and Conditions
                    </a>
                </li>
            </ul>
        </div>
    
        <div class="col-sm-12 col-md-3 col-lg-3 py-3">
            <h6 class="font-weight-bold">More Business Solutions</h6>
            <ul class="footerul">
                <li>
                    <a href="javascript:void(0)" class="">
                        Business Forms
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                     Checks
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
              Promotional Products
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="">
                    Online Marketing Services
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2 py-3">
            <h6 class="font-weight-bold">Join Us On</h6>
            <ul class="footerul">
                
                <li>
                    <a href="javascript:void(0)" class="social-media-twiter">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="javascript:void(0)" class="social-media-facebook">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="javascript:void(0)" class="social-media-youtube">
                        <i class="fab fa-youtube"></i>
    
                    </a>
                    <a href="javascript:void(0)" class="social-media-linkedin">
                        <i class="fab fa-linkedin-in"></i>
    
                    </a>
                </li>
            </ul>
        </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6">
              <p>See our delivery policy for full details © 2022</p>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 text-end">
            <p>Steples, Inc., All Rights Reserved</p>
        </div>
        </div>
        </div>
    </footer> -->