<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;">User Master</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Users</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="py-3">
        <div class="row">
          <div class="col-lg-6 col-sm-4">
            <h5 class="pl-2">
              <b> 650 </b> Users</h5>
          </div>
          <div class="col-lg-3 col-12 mb-lg-0 mb-2">
            <input type="text" class="form-control" placeholder="Search ID, Name, E-Mail">
            <i class="fa fa-search psr"></i>
          </div>
          <div class="col-lg-3 col-12 d-flex justify-content-end">
            <div class="input-group align-items-center">
              <label for="">Export to: </label>
              <select class="form-control mx-2">
                <option value=""> CSV </option>
                <option value=""> Excel</option>
                <option value=""> PDF </option>
              </select>
              <button class="btn btn-dark btn-sm export-btn"> Export</button>
            </div>
          </div>
        </div>
      </div>

      <div class="py-3">
        <div class="row">
          <div class="col-lg-4 col-12 mb-2">
            <select name="" id="" class="form-control bg-light w-auto d-inline-block pr-2">
              <option value="Actions">Actions</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
            <label for="" class="pl-2 font-weight-bold">Action on 2 selected: </label>
          </div>
          <div class="col-lg-8 col-12 d-flex flex-column flex-lg-row justify-content-end ">
            <select name="" id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
              <option value="">Approved</option>
              <option value="1">Reject</option>

            </select>
            <select name="" id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
              <option value="">Date Range: Last Day</option>
              <option value="1">Date Range: Today</option>

            </select>
            <select name="" id="" class="form-control mb-2 bg-light w-auto d-inline-block pr-2 mr-2">
              <option value="">Result of per page 15</option>
              <option value="">Result of per page 10</option>

            </select>
            <button class="form-control bg-light w-auto d-inline-block pr-2 mr-2"> Refresh</button>
          </div>

        </div>
      </div>
      <div class="py-2 bg-light px-2 rounded">
        <div class="row">
          <div class="col-lg-8 col-12 ">
            <div class="d-flex pt-1 d-flex flex-column flex-lg-row">
              <div>
                <label for="" class="pr-2 mr-3">Filters:</label>
              </div>
              <div>
                <label for="" class="mr-2">
                  <b> Listing Status: </b>
                </label>
              </div>
              <div class="form-check">
                <input name="list" type="radio" class="custom1-chkboxradio mx-1" id="all" checked>
                <label for="all" class="">
                  <b> All
                  </b>
                </label>
              </div>
              <div class="form-check">
                <input name="list" type="radio" class="custom1-chkboxradio mx-1" id="active">
                <label for="active" class="">
                  <b> Active </b>
                </label>
              </div>
              <div class="form-check">
                <input name="list" type="radio" class="custom1-chkboxradio mx-1" id="inactive">
                <label for="inactive" class="">
                  <b> Inactive </b>
                </label>
              </div>
              <div class="form-check">
                <input name="list" type="radio" class="custom1-chkboxradio mx-1" id="incomplete">
                <label for="incomplete" class="">
                  <b> Incomplete </b>
                </label>
              </div>

            </div>
          </div>
          <div class="col-lg-4 col-12 d-flex justify-content-end">
            <button class="form-control bg-light w-auto d-inline-block pr-2"> Save all</button>
          </div>

        </div>
      </div>


      <div class="col-sm-12  mb-2 my-2 p-0">

        <div id="tab01" class="tab-contents mmt5 p-0 border-0">
          <div class="col-sm-12 col-md-12  nopadding">
            <div id="grid-theme-wrapper">

              <div class="border">


                <div class="table-responsive">
                  <igx-grid #grid2 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'">

                    <igx-column field="status" header="Status" width="100">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <a href="javascript:void(0)">
                            <span class="cell-vl ">1</span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="user_details" header="User Details" width="200">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span class="cell-vl "></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="user_type" header="User Type" width="120">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span class="cell-vl "></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="subcription" header="Subcription" width="120">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="contact_person" header="Contact Person" width="200">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="child" header="Child" width="100">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span> Yes</span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="specific_url" header="Specific URL" width="150">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span> Yes</span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="payment_term" header="Payment Term*" width="150">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <span></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="action" header="Action" width="150">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-cst py-2 pt-3 ng-star-inserted">
                          <div class="btn-group py-2" role="group" aria-label="Button group with nested dropdown">

                            <div class="" role="group">
                              <button id="btnGroupDrop2" type="button" class="py-2 px-3 btn btn-sm btn-light shadow-none dropdown-toggle" data-toggle="dropdown"
                                aria-expanded="false">
                                Action
                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                              </button>

                              <ul class="dropdown-menu" aria-labelledby="btnGroupDrop2">
                                <li class="border-bottom">
                                  <a href="javascript:void(0)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                    <i class="fa fa-edit"></i> Edit </a>

                                </li>
                                <li class="border-bottom">
                                  <a href="javascript:void(0)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                    <i class="fa fa-trash"></i> Delete </a>

                                </li>






                              </ul>

                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </igx-column>

                  </igx-grid>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>