
<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>

     <app-hdsbpmCardLayout [cardviewlist]="finalCardViewArray" [cardheader]="card_header"> </app-hdsbpmCardLayout> 
     <!-- <div class="container-fluid">
        <section class="pt-3">
            <div class="col-sm-12">
                <div class="card shadow-none">
                    <div class="card-header btn-dark">
                        <h3 class="text-center text-light">
                            Limited Period Introductory Offer
                        </h3>
                    </div>
                    <div class="card-body bg-f2">
            
     <div class="row justfy-center" >
        <div class="col-md-3 col-lg-3" *ngFor="let lst of finalCardViewArray">
         <div class="card" >
           <div class="card_header crdhdr px-1">
            <h5 class="card-title">{{lst.header}}</h5>
           </div>
           <div class="card_d new_card"></div>
           <div class="card-body">
            
               <p class="card-text" *ngFor="let sublist of lst.body"> 
                   <label>{{sublist.field_caption}}</label>
                   <label class="pull-right"><strong>{{sublist.key_val}}</strong></label>
               </p>
             
           </div>
           <div class="card_footer">
            <a href="#" class="btn btn-dark  rounded-pill px-3">Go somewhere</a>
       </div>
       </div>
        </div>     
         </div>
        </div>
    </div>
</div>
        </section>
        </div> -->

  </div>
</div>