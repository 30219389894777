<div class="d-flex minhyt96vh" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
  
      <div class="container-fluid bg-white">
        <div class="row">
          <div class="col-sm-12 p-0">
            <nav aria-label="breadcrumb p-0">
              <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Feedback & Review</li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="col-sm-12 mb-2 p-0">
          <div class="tabs row my-2 mx-0 align-items-center">

  
            <div class="tab-button-outer1 col-sm col-9 px-0 mb-sm-0 mb-2">
              <ul id="tab-button" class="tbsbtns">
                <li id="tabDraft" class="is-active">
                  <a href="javascript:void(0)" class="font9">Feedback & Review List </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-3 px-sm-3 px-0 order-sm-1 order-2" id="masterSearch">
              <div class=" input-group">
                <input type="text" name="Searchdraft" class="searchbar form-control newform-control" onpaste="return false" autocomplete="new-pws"
                  (keyup)="CommonGridSearchMaster(SearchValue.value)" placeholder="Search in Page" #SearchValue id="searchvaluemaster"
                />
                <a href="javascript:void(0)" id="Searchdraft" class="btn  px-2" (click)="CommonGridSearchMaster(SearchValue.value)">
                  <i class="fa fa-search"></i>
                </a>
              </div>
            </div>


          </div>
          <div id="tab01" class="tab-contents mmt5 p-0">
            <div class="col-sm-12 col-md-12  nopadding">
              <div id="grid-theme-wrapper">
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
                <ngx-loading [show]="isLoading"></ngx-loading>

                <div class="table-responsive" *ngIf="productRatingList.length > 0">
                  <igx-grid #grid1 [data]="productRatingList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">

                    <igx-column field="" header="Sr. No." width="80" [filterable]="false">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container w-100 text-center">
                          {{cell.row.index + 1}}
                        </div>
                      </ng-template>
                    </igx-column> 
                    <igx-column field="full_name" header="Customer Name" width="210">
                        <!-- <igx-column field="seller_name" header="Seller Name" width="147"> -->
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                              <span class="cell-vl ">{{ cell.value }}
                              </span>
                           
                          </div>
                        </ng-template>
                      </igx-column>
               
                    
                    <igx-column field="product_name" header="Product Name" width="230">
                        <!-- <igx-column field="product_name" header="Product Name" width="147"> -->
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">                            
                              <span class="cell-vl text-capitalize">{{ cell.value }}
                              </span>
                            
                          </div>
                        </ng-template>
                      </igx-column>
                    <igx-column field="customer_review" header="Review this product(Comments)" width="520">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <p class="font9 px-3 pb-1 mb-1"> {{cell.value}} </p>
                          <p class="bulkorderquery mb-0 px-3" *ngIf="cell.value.length > 100">
                            <a href="#" class="bm-link" data-toggle="modal" data-target="#review" (click)="viewmore(cell.value)">View more</a>
                          </p>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="formatted_date" header="Date" width="120">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span> {{cell.value}} </span>
                        </div>
                      </ng-template>
                    </igx-column>
                    
                    <igx-column field="feedback_status" header="Action" width="150">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container px-2">
                          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                              aria-expanded="false">
                              <span class="py-2 px-3">Action</span>
                              <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                <i class=" fa fa-caret-down" aria-hidden="true"></i>
                              </button>
                            </button>
                            <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                             
                           
                             
                            
                              <li  class="">
                                <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                  <i class="fa fa-trash mr-1" aria-hidden="true"></i>
                                  Delete
                                </a>
                              </li>
                             
                            </ul>
                          </div>
                        </div>
  

                      </ng-template>
                    </igx-column>
                  </igx-grid>
                </div>
                <label class="w-100 text-center text-dark" *ngIf="!isLoading && productRatingList.length == 0">No Record Found</label>
              </div>
            </div>
          </div>
        </div>
  
  

  
  
  
    </div>

  


    <div class="modal fade" id="review" tabindex="-1" aria-labelledby="review" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header px-3 py-2">
              <h6 class="modal-title" id="review"> Review this product(Comments)</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"> &times; </span>
              </button>
            </div>
            <div class="modal-body minhyt70 p-3" data-spy="scroll" id="scroll">
              <p class="font8">{{globalmsg}}</p>
            </div>
      
          </div>
        </div>
      </div>
