import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import Swal from 'sweetalert2'
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostListener("window:onbeforeunload",["$event"])
  clearLocalStorage(event){
      localStorage.clear();
  }
  // @HostListener('window:unload', ['$event'])
  // async unloadHandler(event) {
  //   if (event.currentTarget.performance.navigation.type !== PerformanceNavigation.TYPE_RELOAD) {
  //     localStorage.clear();
  //   }
  // }

  title = 'bpm-project';
  accesToken:any;
  conversionDecryptOutput:any;
  roleId:any;
  constructor(
    private router: Router, private bnIdle: BnNgIdleService,private route: ActivatedRoute) {
    this.bnIdle.startWatching(500).subscribe((res) => {
      const userId = localStorage.getItem("userId");
      if (userId != null) {
        var loggedIn = false;
        if (res) {
          let timerInterval

          Swal.fire({
            position: 'top-end',
            confirmButtonText: 'Keep me login!',
            allowOutsideClick: false,
            width: 250,
            html:
              'Your session will expire in <strong></strong> seconds.<br/><br/>',
            timer: 60000,
            willOpen: () => {
              const content = Swal.getHtmlContainer()
              const $ = content.querySelector.bind(content)
              timerInterval = setInterval(() => {
                Swal.getHtmlContainer().querySelector('strong')
                  .textContent = (Swal.getTimerLeft() / 1000)
                    .toFixed(0)
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {

            if (result.isConfirmed) {
              loggedIn = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              var lights = document.getElementsByClassName("modal-backdrop");
              while (lights.length)
                lights[0].className = lights[0].className.replace(/\bmodal-backdrop \b/g, "");
              this.router.navigate(['/login']);
            }
          })
          setTimeout(() => {
            if (loggedIn == false) {
              var lights = document.getElementsByClassName("modal-backdrop");
              while (lights.length)
                lights[0].className = lights[0].className.replace(/\bmodal-backdrop \b/g, "");
              Swal.close();
              this.router.navigate(['/login']);
            }
          }, 60000);
        }
      }
    })


  }

  ngOnInit(): void {
    
      // this.roleId=localStorage.getItem("role_id") 
     
       
     
     //const userId = localStorage.getItem("userId");
    // let uri = window.location.search;
    // if (uri.length > 1) {
    //   var str = uri
    //   var value = str.split("&");
    //   value.forEach(queryParm => {
    //     if (queryParm.indexOf('accesstoken') >= 0) {
    //       var token = queryParm.split('=')[1];
    //       this.accesToken=decodeURIComponent(token);
    //       console.log("access token",this.accesToken)
    //       this.conversionDecryptOutput = CryptoJS.AES.decrypt(this.accesToken,"6192").toString(CryptoJS.enc.Utf8);
    //       console.log("Decrypted",this.conversionDecryptOutput);
    //     }
    //   });
    //   setTimeout(() => {
    //     if(userId!=null){
    //       if(this.conversionDecryptOutput==userId.toString()){   
    //       }else{
    //         this.router.navigate(['/login']);
    //       }
    //     }else{
    //       this.router.navigate(['/login']);
    //     }
    //   }, 300);
    // }
    
   
  }
  ngAfterViewInit(){
    
  }
}

