import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppSettings } from './AppSetting';

@Injectable({
  providedIn: 'root'
})
export class BusinesscardService {

  url = AppSettings.API_ENDPOINT;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  saveCard(formData) {
    const headers = new HttpHeaders()
    return this.http.post(this.url + "/saveCard", formData, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getCardDetails(card_id) {
    const headers = new HttpHeaders()
    const data = { card_id }
    return this.http.post(this.url + "/getCardDetails", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getAllCardListInDraft(seller_id) {
    const headers = new HttpHeaders()
    const data = { seller_id }
    return this.http.post(this.url + "/getAllCardListInDraft", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  onCardEdit(card_id) {
    const headers = new HttpHeaders()
    const data = { card_id }
    return this.http.post(this.url + "/onCardEdit", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

}
