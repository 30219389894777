import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinesscardService } from 'src/app/_services/businesscard.service';
import { FormService } from 'src/app/_services/form.service';
declare var $: any;

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.css']
})
export class BusinessCardComponent implements OnInit {
  card_id: any;
  card_details: any = [];
  card_title: any;
  description: any;
  extra_features: any = [];
  idc: any;
  is_supported_address: any;
  is_supported_contact_no: any;
  is_supported_designation: any;
  is_supported_email: any;
  is_supported_logo_customization: any;
  is_supported_name: any;
  mrp: any;
  price: any;
  set_quantity: any;
  slides: any = [];
  card_images = [];
  breadcrumb: any = ''
  discountPer: any;
  grand_total_amount: any;
  total_card_quantity: any;
  mainImg: string;
  temp_zoom_img: string;
  constructor(private businessCardService: BusinesscardService, private formService: FormService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      let data = this.formService.decreptedDataUrl(res)
      if (data.length > 1) {
        data = data ? JSON.parse(data) : '';
        this.card_id = data.id;
        this.getCardDetails();
        }
    });
      // this.card_id = 2;
      (function ($) {
        $(document).ready(function () {
            $('.xzoom, .xzoom-gallery').xzoom({ zoomWidth: 700, title: true, tint: '#333', Xoffset: 15 });

            var isTouchSupported = 'ontouchstart' in window;

            if (isTouchSupported) {
                $('.xzoom, .xzoom2, .xzoom3, .xzoom4, .xzoom5').each(function () {
                    var xzoom = $(this).data('xzoom');
                    xzoom.eventunbind();
                });

                $('.xzoom, .xzoom2, .xzoom3').each(function () {
                    var xzoom = $(this).data('xzoom');
                    $(this).hammer().on("tap", function (event) {
                        event.pageX = event.gesture.center.pageX;
                        event.pageY = event.gesture.center.pageY;
                        var s = 1, ls;

                        xzoom.eventmove = function (element) {
                            element.hammer().on('drag', function (event) {
                                event.pageX = event.gesture.center.pageX;
                                event.pageY = event.gesture.center.pageY;
                                xzoom.movezoom(event);
                                event.gesture.preventDefault();
                            });
                        }

                        xzoom.eventleave = function (element) {
                            element.hammer().on('tap', function (event) {
                                xzoom.closezoom();
                            });
                        }
                        xzoom.openzoom(event);
                    });
                });

                $('.xzoom4').each(function () {
                    var xzoom = $(this).data('xzoom');
                    $(this).hammer().on("tap", function (event) {
                        event.pageX = event.gesture.center.pageX;
                        event.pageY = event.gesture.center.pageY;
                        var s = 1, ls;

                        xzoom.eventmove = function (element) {
                            element.hammer().on('drag', function (event) {
                                event.pageX = event.gesture.center.pageX;
                                event.pageY = event.gesture.center.pageY;
                                xzoom.movezoom(event);
                                event.gesture.preventDefault();
                            });
                        }

                        var counter = 0;
                        xzoom.eventclick = function (element) {
                            element.hammer().on('tap', function () {
                                counter++;
                                if (counter == 1) setTimeout(openfancy, 300);
                                event.gesture.preventDefault();
                            });
                        }

                        function openfancy() {
                            if (counter == 2) {
                                xzoom.closezoom();
                                $.fancybox.open(xzoom.gallery().cgallery);
                            } else {
                                xzoom.closezoom();
                            }
                            counter = 0;
                        }
                        xzoom.openzoom(event);
                    });
                });

                $('.xzoom5').each(function () {
                    var xzoom = $(this).data('xzoom');
                    $(this).hammer().on("tap", function (event) {
                        event.pageX = event.gesture.center.pageX;
                        event.pageY = event.gesture.center.pageY;
                        var s = 1, ls;

                        xzoom.eventmove = function (element) {
                            element.hammer().on('drag', function (event) {
                                event.pageX = event.gesture.center.pageX;
                                event.pageY = event.gesture.center.pageY;
                                xzoom.movezoom(event);
                                event.gesture.preventDefault();
                            });
                        }

                        var counter = 0;
                        xzoom.eventclick = function (element) {
                            element.hammer().on('tap', function () {
                                counter++;
                                if (counter == 1) setTimeout(openmagnific, 300);
                                event.gesture.preventDefault();
                            });
                        }

                        function openmagnific() {
                            if (counter == 2) {
                                xzoom.closezoom();
                                var gallery = xzoom.gallery().cgallery;
                                var i, images = new Array();
                                for (i in gallery) {
                                    images[i] = { src: gallery[i] };
                                }
                                $.magnificPopup.open({ items: images, type: 'image', gallery: { enabled: true } });
                            } else {
                                xzoom.closezoom();
                            }
                            counter = 0;
                        }
                        xzoom.openzoom(event);
                    });
                });

            } else {
                //If not touch device

                //Integration with fancybox plugin
                $('#xzoom-fancy').bind('click', function (event) {
                    var xzoom = $(this).data('xzoom');
                    xzoom.closezoom();
                    $.fancybox.open(xzoom.gallery().cgallery, { padding: 0, helpers: { overlay: { locked: false } } });
                    event.preventDefault();
                });

                //Integration with magnific popup plugin
                $('#xzoom-magnific').bind('click', function (event) {
                    var xzoom = $(this).data('xzoom');
                    xzoom.closezoom();
                    var gallery = xzoom.gallery().cgallery;
                    var i, images = new Array();
                    for (i in gallery) {
                        images[i] = { src: gallery[i] };
                    }
                    $.magnificPopup.open({ items: images, type: 'image', gallery: { enabled: true } });
                    event.preventDefault();
                });
            }
        });
    })($);

}

getCardDetails() {
    this.businessCardService.getCardDetails(this.card_id).subscribe(res => {
        console.log("card=======", res)
        this.card_title = res[0].rows[0].card_title;
        this.idc = res[0].rows[0].idc;
        this.price = res[0].rows[0].price;
        this.mrp = res[0].rows[0].mrp;
        this.set_quantity = res[0].rows[0].set_quantity;
        this.description = res[0].rows[0].description;
        this.extra_features = JSON.parse(res[0].rows[0].extra_features);
        this.card_details = JSON.parse(res[0].rows[0].card_details);
        this.is_supported_address = res[0].rows[0].is_supported_address;
        this.is_supported_contact_no = res[0].rows[0].is_supported_contact_no;
        this.is_supported_designation = res[0].rows[0].is_supported_designation;
        this.is_supported_email = res[0].rows[0].is_supported_email;
        this.is_supported_logo_customization = res[0].rows[0].is_supported_logo_customization;
        this.is_supported_name = res[0].rows[0].is_supported_name;
        this.slides = res[0].rows[0].slides ? JSON.parse(res[0].rows[0].slides) : [];
        if (this.slides.length > 0) {
            this.slides = this.slides.filter(x => x.isSelected == true)
        }
        this.card_images = res[1].rows;
        this.mainImg = this.card_images[0]?.document_path
        this.temp_zoom_img='../../assets/images/product/Cards/1020/img1.png';
        this.discountPer = ((((this.mrp ? this.mrp : 0) - (this.price ? this.price : 0)) * 100) / (this.mrp ? this.mrp : 0)).toFixed(1);
        this.grand_total_amount = res[0].rows[0].price;
        this.total_card_quantity = res[0].rows[0].set_quantity;
        for (let obj of this.extra_features) {
            obj.isSelected = false;
        }
    });
}

onAddExtraFeature() {
    let temp_amt = 0;
    for (let obj of this.extra_features) {
        if (obj.isSelected) {
            temp_amt = Number(temp_amt) + Number(obj.price);
        }
    }
    this.grand_total_amount = Number(this.price) + Number(temp_amt);
}
}
