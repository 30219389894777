import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AppSettings } from './AppSetting';

@Injectable({
  providedIn: 'root',
})
export class ProductService {


  url = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient, private router: Router) { }

  getCounty() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getCounty', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  addProduct(formData) {
    const headers = new HttpHeaders();
    headers.append('application/json', 'Content-Type');
    return this.http.post(this.url + '/addProduct', formData, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  getProductDetail(product_id) {
    var data = { product_id };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getProductDetail', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  callBack(product_id) {
    var data = { product_id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/callBack', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  // submitToQC(productId, statuCode, remarks,userId) {
  //   var data = { productId, statuCode, remarks,userId}
  //   const headers = new HttpHeaders()
  //   return this.http.post(this.url + "/qcStatusUpdate", data, { headers }).pipe(map(res => {
  //     return res as any;
  //   }, error => {
  //     return error as any;
  //   }))
  // }
  // getAllProducts(seller_id) {
  //   let data = { seller_id }
  //   const headers = new HttpHeaders()
  //   return this.http.post(this.url + "/getAllProductsForAdmin", data, { headers }).pipe(map(res => {
  //     return res as any;
  //   }, error => {
  //     return error as any;
  //   }))
  // }
  getAllProductsListSellerwisecount(qcStatus, sellerId, searchTerm) {
    let data = { qcStatus, sellerId, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getproductscountsellerwise', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllOrderListsellerwisecount(searchTerm, orderStatus, sellerId, orderStaus2) {
    let data = { searchTerm, orderStatus, sellerId, orderStaus2 };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getallorderssellerwisecount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllOrderListwisecount(searchTerm, orderStatus, orderStaus2) {
    let data = { searchTerm, orderStatus, orderStaus2 };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllOrderListwisecount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllProductsListsellerwise(pagesize, pageCount, qcStatus, sellerId, searchTerm) {
    let data = { pagesize, pageCount, qcStatus, sellerId, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getproductslistsellerwise', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllOrderPaymentListsellerwisecount(searchTerm, sellerId) {
    let data = { searchTerm, sellerId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllOrderPaymentListsellerwisecount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllOrderPaymentListsellerwise(searchTerm, pagesize, pageCount, sellerId?) {
    let data = { searchTerm, pagesize, pageCount, sellerId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllOrderPaymentListsellerwise', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllOrderListsellerwise(
    searchTerm,
    pagesize,
    pageCount,
    orderStatus,
    sellerId,
    orderStatus2?
  ) {
    let data = {
      searchTerm,
      pagesize,
      pageCount,
      orderStatus,
      sellerId,
      orderStatus2,
    };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getorderlistpending', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getManageAllOrderList(
    searchTerm,
    pagesize,
    pageCount,
    orderStatus,
    orderStatus2?
  ) {
    let data = {
      searchTerm,
      pagesize,
      pageCount,
      orderStatus,
      orderStatus2,
    };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getManageAllOrderList', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllProductsList(pagesize?, pageCount?) {
    let data = { pagesize, pageCount };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllProductsForAdmin', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllProductsListSellerWiseForcount(sellerId) {
    let data = { sellerId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllProductssellerwise', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllOrderListWiseForcount() {
    //let data = {  };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getAllOrderListWiseForcount', { headers })
      .pipe(map((res) => {
        return res as any;
      },
        (error) => {
          return error as any;
        }
      )
      );
  }
  getAllOrderListSellerWiseForcount(sellerId) {
    let data = { sellerId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getallordersellerwise', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getTotalOrderHistoryListcount(sellerId) {
    let data = { sellerId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/gettotalorderhistorylist', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  getTotalOrderHistoryCount(searchTerm, orderStatus, orderStaus2) {
    let data = { searchTerm, orderStatus, orderStaus2 };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/gettotalorderhistorycount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getTotalOrderHistoryList(
    searchTerm,
    pagesize,
    pageCount,
    orderStatus,

    orderStatus2?
  ) {
    let data = {
      searchTerm,
      pagesize,
      pageCount,
      orderStatus,
      orderStatus2,
    };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/gettotalorderhistorylistpending', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  getCategory() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCategory", { headers })
  }

  getSubCategory(product_category_id) {
    const headers = new HttpHeaders();
    let data = { product_category_id }
    return this.http.post(this.url + "/getSubCategory", data, { headers })
  }

  getProducts(sub_product_id, category) {
    const headers = new HttpHeaders();
    let data = { sub_product_id, category }
    return this.http.post(this.url + "/getproducts", data, { headers })
  }
  getProductsForbanner(sub_product_id, category){
    const headers = new HttpHeaders();
    let data = { sub_product_id, category }
    return this.http.post(this.url + "/getProductsForbanner", data, { headers })
  }
  getProductForQuickOrder(sub_product_id, category) {
    const headers = new HttpHeaders();
    let data = { sub_product_id, category }
    return this.http.post(this.url + "/getProductForQuickOrder", data, { headers })
  }


  getSellerData(seller_id) {
    var data = { seller_id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getSellerDataProfile', data, {
      headers,
    });
  }
  getAllProductsListcount(qcStatus, searchTerm) {
    let data = { qcStatus, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllProductsForAdmincount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  // getLiveProductListCount(qcStatus, searchTerm){
  //   let data = { qcStatus, searchTerm };
  //   const headers = new HttpHeaders();
  //   return this.http
  //     .post(this.url + '/getLiveProductListCount', data, { headers })
  //     .pipe(
  //       map(
  //         (res) => {
  //           return res as any;
  //         },
  //         (error) => {
  //           return error as any;
  //         }
  //       )
  //     );
  // }

  isOpenProduct(itemId) {
    let data = { itemId };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/isopenproduct', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  productStatusupdate(productId, productStatus, adminRemarks, userId, admin_price, product_type, admin_mrp,tempconfigList?) {
    var data = { productId, productStatus, adminRemarks, userId, admin_price, product_type, admin_mrp,tempconfigList };
    //console.log(data)
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/productStatus', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  changeUserMobileNumber(mobile_no, seller_id){
    let data = { mobile_no, seller_id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/changeUserMobileNumber', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updatePasswordByAdmin(newPass, seller_id){
    let data = { newPass, seller_id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/updateSellerPasswordByAdmin', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updatePasswordByAdminForBuyer(newPass, customer_id){
    let data = { newPass, customer_id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/updatePasswordByAdminForBuyer', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  pendingSellerCount(sellerStatus, searchTerm) {
    var data = { sellerStatus, searchTerm };
    // console.log(data)
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/pendingsellercount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  SellerList(limit, offset, firstStatus, searchTerm) {
    let data = { limit, offset, firstStatus, searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/sellerlist', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  pendingSellerList(pagesize, pageCount, sellerStatus, searchTerm) {
    let data = { pagesize, pageCount, sellerStatus, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/pendingsellerlist', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  buyerStatusupdate(buyerId, buyerStatus, remarks, userId) {
    let data = { buyerId, buyerStatus, remarks, userId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/buyerstatusupdate', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  buyerCount(searchTerm) {
    let data = { searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/buyercount', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  allproductReviewAndRatingCount(searchTerm){
    let data = { searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/allproductReviewAndRatingCount', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  getAllDealType() {
    // let data = { searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/alldealtype', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  getOrderTrakingDetail() {
    let data = {}
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getOrderTrakingDetail", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  allBuyerList(pagesize, pageCount, searchTerm) {
    let data = { pagesize, pageCount, searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/buyerlist', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  allproductReviewAndRating(pagesize, pageCount, searchTerm){
    let data = { pagesize, pageCount, searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/allproductReviewAndRating', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  getAllCustomerOrders(customerId, pagesize, offset, searchTerm) {
    let data = { customerId, pagesize, offset, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllBuyerOrder', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllAdminOrders(customerId) {
    let data = { customerId }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getAllAdminOrders", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  viewBuyerReview(productId, customerId) {
    let data = { productId, customerId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/viewBuyerReview', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  deleteReview(id) {
    let data = { id };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/deleteBuyerReview', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  cancelOrderByUser(order_detail_id) {
    let data = { order_detail_id };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/cancelOrderByUser', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  GetAllSellercountList() {
    let data = {};
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getallsellerlist', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  totalSellerCount() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/totalsellercount', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  individualBuyerOrdersCount(id, searchTerm) {
    let data = { id, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/buyerorderscount', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  totalProductlistCount() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/totalproductcount', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updateSellerStatus(status, sellerId, adminRemarks, userId) {
    const headers = new HttpHeaders();
    let data = { status, sellerId, adminRemarks, userId };
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/updateseller', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  updateCorpShippingAdress(ship_id, contactPerson, emailS, phoneS, shipAddress, countryS, stateS,
    cityS, zipCodeS, defaultAddressS) {
    const headers = new HttpHeaders();
    let data = { ship_id, contactPerson, emailS, phoneS, shipAddress, countryS, stateS, cityS, zipCodeS, defaultAddressS };
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/updateCorpShippingAdress', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  updateCorpBillingAddress(bill_id, companyName, emailB, phoneB, billAddress, countryB, stateB,
    cityB, zipCodeB, defaultAddressB) {
    const headers = new HttpHeaders();
    let data = {
      bill_id, companyName, emailB, phoneB, billAddress, countryB, stateB,
      cityB, zipCodeB, defaultAddressB
    };
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/updateCorpBillingAddress', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  deleteCorpShippingAddress(ship_id){
    const headers = new HttpHeaders();
    let data = { ship_id};
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/deleteCorpShippingAddress', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  deleteCorpBillingAddress(bill_id){
    const headers = new HttpHeaders();
    let data = { bill_id};
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/deleteCorpBillingAddress', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  createOrderForCorporate(cust_id, temproductName, totalAmount, gstAmount, bill_id, ship_id,remarks){
    const headers = new HttpHeaders();
    let data = { cust_id, temproductName, totalAmount, gstAmount, bill_id, ship_id,remarks};
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/createOrderForCorporate', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  updateSellerMessage(sellerId, sellerMessage, userId) {
    const headers = new HttpHeaders();
    let data = { sellerId, sellerMessage, userId };
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.url + '/updateSellerMessage', data, { headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  fetchSellerByStatusCode(status, status2?) {
    const headers = new HttpHeaders();
    let data = { status, status2 };
    headers.append('Content-Type', 'application/json');
    return this.http
      .post(this.url + '/fetchsellerbystatus', data, { headers })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getAllPendingProductsList(pagesize, pageCount, qcStatus, searchTerm) {
    let data = { pagesize, pageCount, qcStatus, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getAllPendingProductsList', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getOrderAcceptance() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getorderacceptance", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getProductPricing() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getproductpricing", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  onProductEdit(product_id) {
    var data = { product_id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/onProductEdit', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  async getnextedsubcategory(category_id: number): Promise<any> {
    try {
      const headers = new HttpHeaders();
      const response = await this.http.get(this.url + `/getAllConnectedSubCatagory/${category_id}`, { headers }).toPromise();
      return response as any;
    } catch (error) {
      console.error('Error fetching nested subcategories:', error);
      throw error;
    }
  }  
  refreshTable(id) {
    var data = { id };

    const headers = new HttpHeaders();
    return this.http.post(this.url + '/refresh', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  getBuyerAddress(id) {
    let data = { id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/buyeraddress', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  getOrderDetails(id) {
    let data = { id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/orderdetials', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  getAllSoldProducts() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/soldproducts', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  getTotalActiveUser() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/totalactiveusers', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  totalLiveProducts() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/totalliveproducts', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  totalSoldProducts() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/totalsoldproducts', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  getYearData() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getYearData", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getGSTData() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getGSTData", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getGstDataListMaster(limit, offset, searchTerm) {
    let data = { limit, offset, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getGstDataListMaster', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );

  }
  getGstDataCount(searchTerm) {
    let data = { searchTerm }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/countForGstDataList', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  getVariationCount(searchTerm){
    let data = { searchTerm }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getVariationCount', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  deleteGstData(gstId) {
    let data = { gstId }
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/deleteGstData', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );

  }
  updateGSTData(gst_id, gst_value) {
    const headers = new HttpHeaders();
    let data = { gst_id, gst_value }
    return this.http.post(this.url + "/updaetGSTData", data, { headers })

  }

  getYearDataListMaster(limit, offset, searchTerm) {
    let data = { limit, offset, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getYearDataListMaster', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  getYearDataCount(searchTerm) {
    let data = { searchTerm }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/countForYearDataList', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updateYearData(year_id, year_value) {
    const headers = new HttpHeaders();
    let data = { year_id, year_value }
    return this.http.post(this.url + "/updateYearMasterData", data, { headers })

  }
  deleteYearData(year_id) {
    let data = { year_id }
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/deleteYearData', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getCountForBanner(searchTerm) {
    let data = { searchTerm }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/countforbanner', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  getManageBanner(limit, offset, searchTerm) {
    let data = { limit, offset, searchTerm };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/getmanagebanner', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  updateManageBanner(data) {
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/updateManageBanner', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  deleteBanner(bannerId) {
    let data = { bannerId }
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/deletebanner', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }


  getAllGroupedProduct(product_id) {
    let data = { product_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getAllGroupedProductsList", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getBrandNameList(seller_id?) {
    let data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getBrandNameList", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  addBrandName(name, sellerId) {
    let data = { name, sellerId }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addBrandName", data, { headers }).pipe(map(res => {
      return res as any;
    }, err => {
      return err as any;
    }))
  }

  getSelectedProduct(id) {
    let data = { id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getSelectedProductDetails", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }


  getSelectedProductAdminSide(id) {
    let data = { id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getSelectedProductDetailsadmin", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getProductCustomization(id) {
    let data = { id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getcustomizableproducts", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getBusinessCardDetails(id) {
    let data = { id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getBusinessCardDetails", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }

    ))
  }

  businessCardSubmit(id, statusCode, remarks) {
    let data = { id, statusCode, remarks }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/businesscardqc", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getProductsDetails(productId) {
    let data = { productId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/productdetailview', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getGroupProductsDetails(productId) {
    let data = { productId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/groupproductdetailview', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  orderAmountsMonthwise() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/orderamountmonthwise', { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  checkMobileAndEmail(mobile_no, email) {
    var data = { mobile_no, email }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/checkMobileAndEmail', data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  sellerRegisteration(seller_name, mobile_no, email) {
    var data = { seller_name, mobile_no, email }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/sellerRegisteration', data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getProductTypes() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getProductTypes", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getAllAttribute() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getAllAttribute", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  addProductAttribute(attribute_code, attribute_lable) {
    let data = { attribute_code, attribute_lable }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addProductAttribute", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  addProductAttributeValue(category,attribute_id, value_code, value_lable) {
    let data = { category,attribute_id, value_code, value_lable }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addProductAttributeValue", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  getAllAttributeValue(attributes_id) {
    const data = { attributes_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getAllAttributeValue", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  addAttributeValue(attribute_id, value_code, value_lable) {
    let data = { attribute_id, value_code, value_lable }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addAttributeValue", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  saveConfigDetail(formData) {
    
    //console.log("dattat",data)
    const headers = new HttpHeaders();
    headers.append('application/json', 'Content-Type');
   return this.http.post(this.url + "/saveConfigDetail", formData, { headers }).pipe(map(res => {
      return res as any;
    }, error => {

    }))
  }
  

  saveCostomizableDetail(brand_name, product_name, category, sub_category, country, manufacture_year,
    product_tags, product_specifications, key_features, product_discription, addAttributeCombination
    , seller_id, editable_item_id, hsn, gstRate) {
    let data = {
      brand_name, product_name, category, sub_category, country, manufacture_year,
      product_tags, product_specifications, key_features, product_discription, addAttributeCombination
      , seller_id, editable_item_id, hsn, gstRate
    }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/saveCustomizableDetail", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  deleteConfigDetails(deleteProd_id) {
    const headers = new HttpHeaders();
    let data = { deleteProd_id }
    return this.http.post(this.url + "/deleteConfigDetails", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  deleteCustomDetails(deleteCust_id) {
    const headers = new HttpHeaders();
    let data = { deleteCust_id }
    return this.http.post(this.url + "/deleteCustomDetails", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  deleteProductCustomDetails(delete_id) {
    const headers = new HttpHeaders();
    let data = { delete_id }
    return this.http.post(this.url + "/deleteProductCustomDetails", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getProductConfig(product_id) {
    let data = { product_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getProductConfig", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  addCustomizableProduct(product_id, artwork, external,customizable_id) {
    let data = { product_id, artwork, external,customizable_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addcustomizableproducts", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  isConfigAvailble(product_id, color, size) {
    let data = { product_id, color, size }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/isConfigAvailble", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getProductConfiguration(product_id) {
    let data = { product_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getProductConfiguration", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  // getBusinessCardCounts(status, searchTerm){
  //   let data = {status, searchTerm}
  //   const headers = new HttpHeaders();
  //   return this.http.post(this.url + "/getBusinessCardCount", data, { headers }).pipe(map(res => {
  //     return res as any;
  //   }, error => {
  //     return error as any;
  //   }))
  // }

  // getBusinessCardsList(status, pagesize, offset, searchTerm){
  //   let data = {status, pagesize, offset,searchTerm}
  //   const headers = new HttpHeaders();
  //   return this.http.post(this.url + "/getBusinessCardList", data, { headers }).pipe(map(res => {
  //     return res as any;
  //   }, error => {
  //     return error as any;
  //   }))
  // }

  addConfigProductImage(formData) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addConfigProductImage", formData, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  // updateConfigurations(product_configuratios, id) {
  //   let data = { product_configuratios, id }
  //   const headers = new HttpHeaders();
  //   return this.http.post(this.url + "/updateConfigurations", data, { headers }).pipe(map(res => {
  //     return res as any;
  //   }, error => {
  //     return error as any;
  //   }));
  // }

  updatecustomizable(product_configuratios, id) {
    let data = { product_configuratios, id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/updatecustomizable", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  updateCustomiseData(product_id, artwork, external) {
    let data = { product_id, artwork, external }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/updatecustmizableproducts", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  updateCartItem(crat_id, quantity, productType, product_price, total_gst_amount, pro_details?, pro_extrafeature?) {
    const headers = new HttpHeaders();
    let data = { crat_id, quantity, productType, product_price, total_gst_amount, pro_details,pro_extrafeature};
    console.log("uodatecart", data)
    return this.http.post(this.url + "/updateCartItemAdmin", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getCartData(customer_id) {
    const headers = new HttpHeaders();
    let data = { customer_id };
    return this.http.post(this.url + "/getCartData", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  removeCartItem(customer_id, item_id) {
    const headers = new HttpHeaders();
    let data = { customer_id, item_id };
    return this.http.post(this.url + "/removeCartItem", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  addToCart(customer_id, items) {
    const headers = new HttpHeaders();
    let data = { customer_id, items };
    console.log("itmemsms", data)
    return this.http.post(this.url + "/addToCartAdmin", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  addCustomerOrder(customer_id, total_amount,payment_id, total_gst_amount, delivery_address_id,selected_billing_id, items,) {
    const headers = new HttpHeaders();
    let data = { customer_id, total_amount, payment_id, total_gst_amount, delivery_address_id,selected_billing_id, items };
    return this.http.post(this.url + "/addCustomerOrder", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  getBrandArea() {
    const headers = new HttpHeaders()
    return this.http.post(this.url + "/getBrandArea", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  saveCustomConfigDetail(formData) {
   const headers = new HttpHeaders();
   headers.append('application/json', 'Content-Type');
    return this.http.post(this.url + "/saveCustomConfigDetail", formData, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  updateConfigurations(formData) {
    
    const headers = new HttpHeaders();
    headers.append('application/json', 'Content-Type');
    return this.http.post(this.url + "/updateConfigurationsAdmin", formData, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

 

  getCustomDetailsByProductID(product_id) {
    let data = { product_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCustomDetailsByProductID", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getConfigDetailsByProductID(product_id) {
    let data = { product_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getConfigDetailsByProductID", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getSimpleProductList(seller_id, base_product_id) {
    const headers = new HttpHeaders();
    const data = { seller_id, base_product_id }
    return this.http.post(this.url + "/getSimpleProductList", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  getBaseProductList(seller_id) {
    const headers = new HttpHeaders();
    const data = { seller_id }
    return this.http.post(this.url + "/getBaseProductList", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  saveProductGroup(seller_id, base_product_id, selectedProductList, editable_item_id) {
    const headers = new HttpHeaders();
    const data = { seller_id, base_product_id, selectedProductList, editable_item_id }
    return this.http.post(this.url + "/saveProductGroup", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getGroupedProductById(product_id) {
    let data = { product_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getGroupedProductById", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getDraftProducts(id, pagesize, pageCount, qcStatus, searchTerm) {
    let data = { id, pagesize, pageCount, qcStatus, searchTerm }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getdraftproducts", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getDraftProductsCounts(id, status, searchTerm) {
    let data = { id, status, searchTerm }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getdraftproductscounts", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  updateProductPrice(seller_id, product_id, updated_price, updated_mrp) {
    const headers = new HttpHeaders();
    let data = { seller_id, product_id, updated_price, updated_mrp };
    return this.http.post(this.url + "/updateProductPrice", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  submitForQc(id) {
    let data = { id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/submitforqcbyadmin", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }

  getAttributeValue(id) {
    // let data = {attribute_id}
    const headers = new HttpHeaders();
    return this.http.post(this.url + `/getAttributeValue/${id}`, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  getAttributelable(category_id) {
    // let data = {attribute_id}
    const headers = new HttpHeaders();
    return this.http.get(this.url + `/getAttributeLable/${category_id}`, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  //Add new deal
  insertNewDealProduct(category, subcategory, productid, price, mrp, quantity, discount, startdate, endDate) {
    const headers = new HttpHeaders();
    let data = { category, subcategory, productid, price, mrp, quantity, discount, startdate, endDate }
    return this.http.post(this.url + "/todaydealproduct", data, { headers })
  }

  insertGstDetails(gst_value) {
    const headers = new HttpHeaders();
    let data = { gst_value }
    return this.http.post(this.url + "/saveGstDetails", data, { headers })
  }
  insertYearMaster(year_value) {
    const headers = new HttpHeaders();
    let data = { year_value }
    return this.http.post(this.url + "/saveYearMasterDetails", data, { headers })
  }
  getProductDealList(pagesize, offset, currentDate, searchTerm) {
    const headers = new HttpHeaders();
    let data = { pagesize, offset, currentDate, searchTerm }
    return this.http.post(this.url + "/productdeallist", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getFutureDealListCount(currentDate, searchTerm) {
    const headers = new HttpHeaders();
    let data = { currentDate, searchTerm }
    return this.http.post(this.url + "/getfuturedealcount", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getDealHistoryCount(currentDate, searchTerm) {
    const headers = new HttpHeaders();
    let data = { currentDate, searchTerm }
    return this.http.post(this.url + "/getdealhistorycount", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getDealHistoryList(pagesize, offset, currentDate, searchTerm) {
    const headers = new HttpHeaders();
    let data = { pagesize, offset, currentDate, searchTerm }
    return this.http.post(this.url + "/gethistorydeallist", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }


  getFutureDealList(pagesize, offset, currentDate, searchTerm) {
    const headers = new HttpHeaders();
    let data = { pagesize, offset, currentDate, searchTerm }
    return this.http.post(this.url + "/getfuturedealList", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }


  currentDealListCount(searchTerm, currentDate) {
    const headers = new HttpHeaders();
    let data = { searchTerm, currentDate }
    return this.http.post(this.url + "/currentdeallistcount", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  updateNewDealProduct(newdealid, category, subcategory, productid, price, mrp, quantity, discount, startdate, endDate) {
    const headers = new HttpHeaders();
    let data = { newdealid, category, subcategory, productid, price, mrp, quantity, discount, startdate, endDate }
    return this.http.post(this.url + "/updatetodaydealproduct", data, { headers })
  }
  deletenewdealrowdata(dealId) {
    const headers = new HttpHeaders();
    let data = { dealId }
    return this.http.post(this.url + "/deletenewdealrowdata", data, { headers })
  }
  getMyOrderDetails(orderId) {
    let data = { orderId };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/myorderdetails', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }

  allBrandList(pagesize, pageCount, searchTerm) {
    let data = { pagesize, pageCount, searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/brandList', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  brandListCount(searchTerm) {
    let data = { searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/brandListCount', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  addBrandNameByAdmin(brandName,brandCode,isspproved) {
    let data = { brandName,brandCode,isspproved };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/addBrandByadmin', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updateBrandNameByAdmin(brandName,brandCode,isspproved,id) {
    let data = { brandName,brandCode,isspproved,id };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/updateBrandByadmin', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  deteleBrand(id) {
    let data = { id};
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/detelebrand', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  brandListApproved(brandNameId) {
    let data = { brandNameId };
    const headers = new HttpHeaders();
    return this.http
      .post(this.url + '/brandListApproved', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }

  bulkOrderQueryList(pagesize, pageCount, searchTerm) {
    let data = { pagesize, pageCount, searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/bulkOrderQueryList', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  bulkOrderListCount(searchTerm) {
    let data = { searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/bulkOrderQueryListCount', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  deleteBulkOrderListData(bulkId) {
    const headers = new HttpHeaders();
    let data = { bulkId }
    return this.http
      .post(this.url + '/deleteBulkOrderListData', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  deleteProductReviewData(rateid){
    const headers = new HttpHeaders();
    let data = { rateid }
    return this.http
      .post(this.url + '/deleteProductReviewData', data, { headers })
      .pipe(
        map(
          (res) => {
            return res as any;
          },
          (error) => {
            return error as any;
          }
        )
      );
  }
  getAllBuyerCatalogue(customerId) {
    const headers = new HttpHeaders()
    const data = { customerId }
    return this.http.post(this.url + "/getAllBuyerCatalogue", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getProductForCreateCatalogue(whereClouse) {
    const headers = new HttpHeaders()
    const data = { whereClouse }
    return this.http.post(this.url + "/getProductForCreateCatalogue", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }));
  }
  getCatalogueType() {
    // const data = { customer_id}
    const headers = new HttpHeaders()
    return this.http.post(this.url + "/getCatalogueTypeList", { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  saveCatalogue(cataloguelist, buyerid, typename, typeid, price_as_per) {
    const data = { cataloguelist, buyerid, typename, typeid }
    const headers = new HttpHeaders()
    return this.http.post(this.url + "/postCatalogueList", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
}