import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation,OnDestroy } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Subscription,Observable } from 'rxjs';
import { FormService } from 'src/app/_services/form.service';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
//import { Editor } from 'ngx-editor';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { SectionConfig, SessionUrlPrams } from 'src/app/field.interface';
import { AppSettings } from '../../_services/AppSetting'
import {DialogLayoutDisplay,ConfirmBoxInitializer} from '@costlydeveloper/ngx-awesome-popup'; 
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DomSanitizer } from '@angular/platform-browser';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';
import{LoginService} from 'src/app/_services/login.service'
import { BreadcrumbComponent } from 'src/app/shared/breadcrumb/breadcrumb.component';
import saveAs  from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import Docxtemplater from 'docxtemplater';
import { IgxCollapsibleIndicatorTemplateDirective } from '@infragistics/igniteui-angular/public_api';
declare var $: any;
@Component({
  selector: 'app-act-process-newx',
  templateUrl: './act-process-newx.component.html',
  styleUrls: ['./act-process-newx.component.css']
})
export class ActProcessNewxComponent implements OnInit {
  editorConfig =
  {
    "editable": true,
    "height": "auto",
    "minHeight": "120",
    "width": "auto",
    "minWidth": "0",
    "translate": "yes",
    "enableToolbar": true,
    "showToolbar": true,
    "placeholder": "Enter text here...",

    "toolbar": [
      ["bold", "italic", "underline"]

    ]
  }
  // add process variables 
  actiontakenid:any;
  recipienttype:any="Internal";
  priority:any="1";
  getUserListCheckCount=0;
  filterConnection:any;
  dropdownVisible:number=1;
  actionList:any[]=[];
  nxtStageId:any;
  isview_field: Promise<boolean>;
  routing_type:any="rule";
  isEndorser:boolean=false;
  tempUserList: any[]=[];
  userList: any[]=[];
  //add new act-process variable
  subscription: Subscription;
  frameworkComponents: any;
  formId:any=0;
  processid:any;
  stageid:any;
  worklistid:any;
  processinstid:any;
  scheme_deptid:any;
  form_viewid:any=0;
  formName:any;
  sessionUrlPrams:SessionUrlPrams; //new added
  tableName:any;
  tablePrimaryKey : any
  SectionConfig:SectionConfig[]=[];  
  formDisplayJSON:any;
  nodeJson:any;
  connJson:any;
  public isLoading = true;
  FormViewLoaded:Promise<boolean>;
  proposalViewLoaded:Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  processing: Promise<boolean>;
  isAddMode:boolean=true;
  is_attachment: boolean=false;
  ErrorOccurred:String="Error occurred!!";
  SuccessMasg:any;
  editValueRow:any;
  editrowid:any;
  refKey:any=0;
  childFormList: any[]=[];
  childFormListPage: any[]=[];
  childFormListTab: any[]=[];
  fieldChildFormList: any[]=[]; //add new
  metaDataList: any[]=[];
  attachmentType: any[]=[];
  metadata: any;
  list: any;
  edit_option:number=1;
  add_edit: Promise<boolean>;
  //add new act-process variable

isClicked: boolean = false;
//editor: Editor;
notsheetContent: any;
notsheetContentOriginal: any;
notesheetList: any[] = [];
notesheetLoaded: Promise<boolean> = Promise.resolve(false);
refrenceSrNo: any;
paraNo: any = 1;
userId: any;
isLoader: boolean = false;
notsheetid: any;
isEdit: boolean = false;
notsheetPageList: any = [];
notsheetPageCount: any = 1;
selectArray: any = [];
notsheetPageListFinal: any = [];
nextPreviousCount: any = 1;
isNoting: boolean;
refrenceType: number = 0;
refrenceId: any = 0;
modalNotesheetContent: any;
isPrint: boolean = false;
notesheet_sr_noPrint: any;
notesheet_para_noPrint: any;
noting_datePrint: any;
notesheet_containtPrint: any;
user_fnamePrint: any;
role_namePrint: any;
reference_namePrint: any;
stickyNotsheetId: any;
stickyNotingDate: any;
stickyuser_fname: any;
stickynotsheet: any;
stickyrole_name: any;
printDivName: string;
isNotingIdChanged: boolean;
selectedItems = [];
selectedItemsRefrence = [];
dropdownSettings: IDropdownSettings = {};
dropdownSettings1: IDropdownSettings = {};
dropdownSettings2: IDropdownSettings = {};
notesheeSrParaList:any;
notesheeImageList:any;
dropdownList = [];
printList: any[] = [];
viewList: any[] = [];
isFile: boolean;
defaultDrop: boolean = true;
refrenceNamee: any;
isNextDisabeled: boolean;
imageObject: any[]=[];
imageBasePath:any=AppSettings.getPdfImagepath;
attachmentTypeCumFileName: any="";
draft_requestid: any;
dept_code:any;
draft_id: any;
pdfData: any;
refSrNo: any;
refPara: any;
viewStatus: boolean=false;
isApproved: boolean = false;
isReject : boolean = false;
isApprovedRejectStatus: Boolean= false;
parkedRemarks: any="";
isParked: boolean=false;

DocumentViewLoaded:Promise<boolean>;
filepath: any="";
fileExt: any ="";
imagepath: any[]=[];

pdf_EstimatedAmount: any="";
pdf_schemeTitle: any="";
pdf_hoa: any="";
pdf_ApproverName: any="";
pdf_ApproverDesignation: any="";
pdf_ApproverDeparment: any="";
roleId: any;
departmentList: any[]=[];
departmentid: any;
officeList: any[]=[];
officeid: any;
attachmentReferenceId : any;
attachmentReferenceName: any;
approvedAmount:any;
headofaccount:any;
lastHeadofAccount: any;
lstHeadofAccount: any;
proposalDate:any;
ministerapproval: any;
isMinisterApproval: boolean=false;
ministerApprovalList: any[]=[];
islolApproved: boolean=false;
lolamount: any;
sanctionAmount: any;
uniqueUID : any;
sanctionOrderList:any[]=[];
draft_scheme_title: any;
draft_scheme_category: any;
draft_proposal_type: any;
draft_funding_category_id: any;
draft_office_id: any;
draft_minister_approval: any;
reporting_office : any;
mom_with_number: any="";
running_txt_val:any;
sanction_order_department_name: any="";
sanction_momno: any="";
isSameDepartmentProcess:boolean=false;
copytoRemarks: any;
decisionstatus: any;
isBuffSheetVisiable: boolean=false;
isnotingAdded: boolean=false;
downloadJsonList: any[]=[]
flag:boolean=false;
isApplicableForApprove: boolean=false;
isApplicableForSanctionOrder: boolean=false;
runningNo:any;
isDfpRule: boolean=false;
ra_amount_status: boolean=false;
constructor(private router: Router, 
  private sanitizer: DomSanitizer,
  private loginService:LoginService,
  private route:ActivatedRoute,
  private formService: FormService, private datePipe: DatePipe,
  private toastrService: ToastrService,) { }

ngOnInit(): void {  
  this.proposalViewLoaded=Promise.resolve(false);
  
this.route.queryParams.subscribe((res)=>{
let data =this.loginService.decreptedDataUrl(res);
if(data.length>1){
  var str =data;
  var value=str.split("&");
  //console.log("uri===>",uri,value)
  value.forEach(queryParm=>{
    if(queryParm.indexOf('frmid')>=0)
    {
      this.formId=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('editrowid')>=0)
    {
      this.editrowid=queryParm.split('=')[1];
      this.refKey=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('worklistid')>=0)
    {
      this.worklistid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('processinstid')>=0)
    {
      this.processinstid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('stageid')>=0)
    {
      this.stageid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('deptid')>=0)
    {
      this.scheme_deptid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('vid')>=0)
    {
      this.form_viewid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('isAddMode')>=0)
    {
      this.isAddMode=queryParm.split('=')[1]=='true'?true:false;
    }
    if(queryParm.indexOf('isView')>=0)
    {      
      let vs=(queryParm.split('=')[1]).toString().replace('"',"");      
      this.viewStatus=(vs=='true' || vs==true)?true:false;      
    }
    if(queryParm.indexOf('isParked')>=0)
    {
      this.isParked=true;
    }
    // console.log("is view==>",this.viewStatus)
  });
  this.sessionUrlPrams={};
  this.sessionUrlPrams={
    frmid:this.formId,
    vid:this.form_viewid,
    UserCode:"UserCode",
    stageid:this.stageid,
    UserName:localStorage.getItem("userFullName"),
    ProfileId:localStorage.getItem("profileId"),
    Location:localStorage.getItem("office"),
    office:localStorage.getItem("office"),
    department:localStorage.getItem("department"),
    processtype:"ext",
    UserId:localStorage.getItem("userId"),
    processCtrl:false,
    processinstid:this.processinstid,
  };
}
  })
  this.getDepartmentList();
  this.roleId=localStorage.getItem("role_id");
  this.reporting_office=localStorage.getItem("reporting_office")?localStorage.getItem("reporting_office"):'';  
  if(this.reporting_office== null || this.reporting_office == undefined || this.reporting_office == "null" || this.reporting_office == "undefined"){
    this.reporting_office="";
  }
  // if(this.roleId == "1"){
  //   this.isApproved=true;
  //   this.isReject=true;       
  // }else{
  //   this.isApproved=false;
  //   this.isReject=false;    
  // }

  this.dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  this.dropdownSettings1 = {
    singleSelection: false,
    idField: 'notesheetid',
    textField: 'srno_parano',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  this.dropdownSettings2 = {
    singleSelection: false,
    idField: 'id',
    textField: 'attachment_desc',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  let uri=this.router.url.split("?");
  
  // console.log("sessionUrlPrams==>",this.sessionUrlPrams);
  this.getFormDetails();
  this.gettingNotSheet();
  //this.editor = new Editor();
  $(".zoomminus").hide();
  // this.colappsin()  
  this.showproposed()
  this.getMinisterApprovalList();
  $("#spnforward").hide();
  $("#spnBeam").hide();
  this.getdownloadJsonList();
  this.getRunningNumber();
  setTimeout(() => { this.proposalViewLoaded=Promise.resolve(true);}, 1000);
}

getMinisterApprovalList(){
  let qry="select * from app_tblsystemenum where varibale_type='ministerapproval'";  
  this.subscription = this.formService.getFormData(qry).subscribe(res => {
      this.ministerApprovalList=res.rows;          
    });
}

getFormDetails() {
  
  this.subscription = this.formService.getFormData("select form_id,form_name,table_name,primary_key,form_display_json,value_field,processid from app_forms where form_id=" + this.formId).subscribe(res => {
    let formList = res.rows;
    let objChildFormIds = "", objChildFormViewIds = "";
    if (formList.length > 0) {
      this.formName = formList[0]["form_name"];
      this.formId = formList[0]["form_id"];
      this.processid = formList[0]["processid"];
      this.tableName = formList[0]["table_name"];
      this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase();
      this.formDisplayJSON = formList[0]["form_display_json"] ? formList[0]["form_display_json"] : [];   
      if (this.formDisplayJSON.length > 0) {
        if (this.processid > 0) {
          this.nodeJson = JSON.parse(this.formDisplayJSON).nodeJson;
          this.connJson = JSON.parse(this.formDisplayJSON).connJson;
          //now getting initial stageid
          var intStageid = this.nodeJson.filter(d => d.type == 'initial')[0]["id"];
          this.SectionConfig = JSON.parse(this.formDisplayJSON).sectionJson;
          // console.log("this.SectionConfig==>", this.SectionConfig)
          // control field hide/show based on process stages...
          this.SectionConfig.forEach(arrField => {
            if (arrField.FieldConfig[0]?.visible_stage != undefined) {
              arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || field.visible_stage.toString() == "");
            }
          });
          this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || d.visible_stage.toString() == "")));
          //this.stageid=intStageid;
        } else {
          this.SectionConfig = JSON.parse(this.formDisplayJSON)
          this.SectionConfig.forEach(arrField => {
            if (arrField.FieldConfig[0]?.visible_stage != undefined) {
              //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
              arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || field.visible_stage.toString() == "");
            }
          });
          this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || d.visible_stage.toString() == "")));
        }
        this.SectionConfig = this.SectionConfig.filter(sec => (sec.FieldConfig?.length > 0 || sec.section_dependent == true) && (sec?.isCaptionVisiable == "always" || sec?.isprocess == "1"));
        //to get child form id
        // console.log("nodeJson==connJson called",this.nodeJson,this.connJson);
        this.fieldChildFormList = [];
        this.SectionConfig.forEach(objSec => {
          objSec.FieldConfig.forEach(objField => {
            if (objField.type == "linkwithchild") {
              objChildFormIds = objChildFormIds + objField.child_form_id + ",";
              //objChildFormViewIds=objChildFormViewIds+objField.child_view_id +",";
              this.fieldChildFormList.push(objField);
            }
          });
        });

      }
            
        this.getFormViewDetails(objChildFormIds, objChildFormViewIds);
        this.getProcessAction();      

    }
   
  });
}

getFormViewDetails(childformids, childformviewid) {
  
  // console.log("field linkwithchild arrObjSect==>", childformids, childformviewid)
  this.subscription = this.formService.getFormViewById(this.form_viewid).subscribe(res => {
    
    let formViewList = res.rows;
    if (formViewList.length > 0) {
      //let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
      let childFormId = formViewList[0]["child_form_ids"] ? formViewList[0]["child_form_ids"] : "";
      this.edit_option = formViewList[0]["edit_option"];
      this.metaDataList = formViewList[0]["meta_data"] ? JSON.parse(formViewList[0]["meta_data"]) : [];
      
      this.attachmentType = formViewList[0]["attachment_type"] ? JSON.parse(formViewList[0]["attachment_type"]) : [];
      let validationList = formViewList[0]["validation"] ? JSON.parse(formViewList[0]["validation"]) : [];
      this.list = {
        "attachmentList": this.attachmentType,
        "validationList": validationList
      }
      // console.log("attachmentType==>",this.attachmentType);
      this.is_attachment = formViewList[0]["is_attachment"] ? formViewList[0]["is_attachment"] : false;
      let child_form_view_json = formViewList[0]["child_form_view_ids"] ? JSON.parse(formViewList[0]["child_form_view_ids"]) : [];

      if (child_form_view_json != undefined && child_form_view_json.length > 0) {
        child_form_view_json = child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
        childFormId = "";
        for (let r = 0; r < child_form_view_json.length; r++) {
          childFormId = childFormId + child_form_view_json[r]["formid"] + ",";
        }
        childFormId = childFormId.substring(0, childFormId.length - 1)
      }
      // console.log("childformids==>", childFormId, child_form_view_json, childformids)
      if (childFormId == "") {
        childFormId = childformids.substring(0, childformids.length - 1);
      } else {
        childFormId = childformids + childFormId;
      }
      // console.log("childformids==>", childFormId, child_form_view_json, childformids)

      if (childFormId != "" && childFormId != undefined && childFormId != null) {
        this.subscription = this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
          this.childFormList = childRes.rows;
          //if(child_form_view_json.length>0){
          for (let a = 0; a < this.childFormList.length; a++) {
            let arrView = child_form_view_json.filter(d => d.formid == this.childFormList[a]["form_id"])
            if (arrView.length > 0) {
              this.childFormList[a]["viewid"] = arrView[0]["viewid"]
              this.childFormList[a]["display_order"] = arrView[0]["orderno"]
              this.childFormList[a]["filterjson"] = arrView[0]["filterjson"]
              this.childFormList[a]["showon"] = arrView[0]["showon"]
              this.childFormList[a]["type"] = "linkwithview"
              this.childFormList[a]["visible_stage"] = arrView[0]["visible_stage"] == undefined ? "" : arrView[0]["visible_stage"];
              this.childFormList[a]["editable_stage"] = arrView[0]["editable_stage"] == undefined ? "" : arrView[0]["editable_stage"];
            } else {
              let fieldArr = this.fieldChildFormList.filter(d => d.child_form_id == this.childFormList[a]["form_id"].toString());
              this.childFormList[a]["viewid"] = 0;
              this.childFormList[a]["display_order"] = 0;
              this.childFormList[a]["filterjson"] = "";
              this.childFormList[a]["showon"] = "page"
              this.childFormList[a]["type"] = "linkwithform"
              this.childFormList[a]["visible_stage"] = fieldArr[0]["visible_stage"];
              this.childFormList[a]["editable_stage"] = fieldArr[0]["editable_stage"];
            }
            // console.log(arrView)
          }
          //}
          this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
          // console.log("this.childFormList==>", this.childFormList, child_form_view_json)
          this.childFormListPage = this.childFormList.filter(d => d.showon === "page");
          this.childFormListTab = this.childFormList.filter(d => d.showon === "tab");
          if (this.isAddMode == false) {
            this.getRowEditData()
          } else {
            //console.log("this.SectionConfig==>",this.SectionConfig)  
            this.SectionConfig.forEach(arrField => {
              arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible == "always" || field?.visible == "add");
            });
            this.FormViewLoaded = Promise.resolve(true);
            this.processing = Promise.resolve(true);            
          }
        });
      } else {
        if (this.isAddMode == false) {
          this.getRowEditData()
        } else {
          //console.log("this.SectionConfig==>",this.SectionConfig)  
          this.SectionConfig.forEach(arrField => {
            arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible == "always" || field?.visible == "add");
          });
          this.FormViewLoaded = Promise.resolve(true);
          this.processing = Promise.resolve(true);          
        }
      }

    }

  });
}

getRowEditData() {
  this.add_edit = Promise.resolve(false);
  this.SectionConfig = null;
  if (this.formDisplayJSON.length > 0) {
    if (this.processid > 0) {
      this.nodeJson = JSON.parse(this.formDisplayJSON).nodeJson;
      this.connJson = JSON.parse(this.formDisplayJSON).connJson;
      //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
      //now getting initial stageid
      let intStageid;
      if (this.stageid == undefined) {
        intStageid = this.nodeJson.filter(d => d.type == 'initial')[0]["id"];
        this.stageid = intStageid
        this.sessionUrlPrams.stageid = intStageid;
      } else {
        intStageid = this.stageid;
      }
      // console.log("intStageid==>", this.stageid, intStageid)
      this.SectionConfig = JSON.parse(this.formDisplayJSON).sectionJson;
      // control field hide/show based on process stages...
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(intStageid) >= 0 || field.visible_stage.toString() == "");
      });
      // control Section hide/show based on process stages...
      this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || d.visible_stage.indexOf(intStageid) >= 0 || d.visible_stage.toString() == "")));
      //this.stageid=intStageid;
      //console.log("this.SectionConfig==>",intStageid, this.SectionConfig)           
    } else {
      this.SectionConfig = JSON.parse(this.formDisplayJSON)
    }
    //console.log("this.SectionConfig==>",this.SectionConfig)  
    this.SectionConfig.forEach(arrField => {
      arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible == "always" || field?.visible == "edit");
    });

    //console.log("this.SectionConfig==>",this.SectionConfig)  

    this.isAddMode = false;
    //this.editrowid=e.rowData[this.tablePrimaryKey];
    this.refKey = this.editrowid
    var strQry = 'select * from ' + this.tableName + ' where ' + this.tablePrimaryKey + '= ' + this.editrowid + ';';
    // console.log(strQry,"in View");
    this.subscription = this.formService.getFormData(strQry).subscribe(res => {
      // console.log("rowData==",res.rows[0]);
      this.editValueRow = JSON.parse(JSON.stringify(res.rows[0]));
      // console.log("this.formAllField===>",this.editValueRow);
      this.FormViewLoaded = Promise.resolve(true);
      // this.processing = Promise.resolve(true);
      if (this.edit_option == 3) {
        if (this.childFormList.length > 0) {
          let formid = this.childFormList[0]["form_id"];
          let tabid = "#tabchildedit" + formid;
          setTimeout(() => { $(tabid).click(); }, 100);
        }
      }
    });
  }
}

getProcessAction(){
  // console.log("this.connJson==>",this.connJson,this.nodeJson)
  if(this.stageid ==undefined){
    this.stageid=this.nodeJson.filter(d =>d.type.toString().trim() == "initial")[0]["id"];
  }
  this.filterConnection = this.connJson.filter(d =>d.from.id == this.stageid);
  // console.log("filterConnection==>",this.filterConnection)
  if(this.filterConnection.length>0){
    var actionIds="";
    for(let c=0; c<this.filterConnection.length; c++){
      //console.log("filterConnection[c][title]==>",this.filterConnection[c]["title"])
      actionIds=actionIds+this.filterConnection[c]["triggerTypeCommand"]+",";
      if(this.filterConnection[c]["title"] == "AU"){
        this.actionList.push({"id":this.filterConnection[c]["id"],"name":this.filterConnection[c]["to"]["title"]})
        this.nxtStageId=this.filterConnection[c]["to"]["id"];
        this.dropdownVisible=2;        
        //this.nxtStageId= this.actiontakenid;
      }
    }
    actionIds=actionIds.substring(0,actionIds.length-1);
    if(this.actionList.length == 0){
      
      this.dropdownVisible=1;
      //this.actiontakenid=0;
      let qry="select optionid,command_name,trigger_type from radx_process_option where optionid in("+actionIds+")";            
      this.subscription=this.formService.getFormData(qry).subscribe(res=>{
        let actionlist=res.rows;
        for(var a=0; a<actionlist.length; a++){
          this.actionList.push({"id":actionlist[a]["optionid"],"name":actionlist[a]["command_name"],"triggertype":actionlist[a]["trigger_type"]})
          
        }

        setTimeout(() => {this.setActionTakenDefault();}, 1000);        
        // if(this.actionList.length>0){
        //   let approveList=this.actionList.filter(d=>d.id == 2);
        //   if(approveList.length>0){this.isApproved=true;}else{this.isApproved=false;}
        //   let rejectList=this.actionList.filter(d=>d.id == 3);
        //   if(rejectList.length>0){this.isReject=true;}else{this.isReject=false;}            
        // }    

      });
    }                 
  }

  this.getUserList(); 
  this.isview_field=Promise.resolve(true);
}

setActionTakenDefault(){
        if(this.actionList.length>0){
          if(this.recipienttype == "Internal"){
            this.actiontakenid=11;
            setTimeout(() => {this.nextStageID();}, 100); 
          }else if(this.recipienttype == "External"){
            this.actiontakenid=1;
            setTimeout(() => {this.nextStageID();}, 100); 
          }                     
        }   
}

nextStageID(){
  // console.log("nextStageID method called actiontakenid==>",this.actiontakenid,this.dropdownVisible)  
  if(this.actiontakenid>0 && this.dropdownVisible==1){
    var nextStagevalJson=this.filterConnection.filter(d =>d.triggerTypeCommand == this.actiontakenid);
    // let trigger_type=this.actionList.filter(d=>d.id==this.actiontakenid);
    // if((trigger_type.length>0 && (trigger_type[0]).triggertype==1) ||  nextStagevalJson[0]["to"]["is_Endorser"]==true)
    // {
    //   this.isEndorser=true;
    //   this.recipienttype="External";
    //   //this.chkActionSkippLogic(true,"show","#div_textarea_indorsmentremarks");
    // }else{
    //   this.isEndorser=false;
    //   this.recipienttype="Internal";
    //   //this.chkActionSkippLogic(false,"show","#div_textarea_indorsmentremarks");
    // }    
    if(nextStagevalJson.length >0){
      var nextStageval= nextStagevalJson[0]["to"]["id"]
      this.nxtStageId=nextStageval;
      // console.log("nextStageID this.actionListId==>",nextStagevalJson,this.nxtStageId);
      this.routing_type=this.nodeJson.filter(d =>d.id==this.nxtStageId)[0]["routing_type"];
      if(this.routing_type=="actor")
      {
        this.getUserList();
      }
        
    }
   
  }else{
    // this.isEndorser=false;
    // this.recipienttype="Internal";
    //this.chkActionSkippLogic(false,"show","#div_textarea_indorsmentremarks");
  }

  if(this.isApprovedRejectStatus==true){
    this.actOnProcess();
  }
  // console.log(" this.isEndorser,this.recipienttype==>", this.isEndorser,this.recipienttype,nextStagevalJson[0]["to"]["is_Endorser"])
}
getUserList(){ 
  if(this.nxtStageId >0){
    if(this.recipienttype == "External"){
      this.getExternalUserList();
    }else{   
      var nextStageJson=this.nodeJson.filter(d =>d.id == this.nxtStageId);
      var destQuery=nextStageJson[0].destination_query;
      var dest_varible=JSON.parse(nextStageJson[0].destination_json);
      // console.log("destQuery,dest_varible==>",nextStageJson,destQuery,dest_varible,this.editValueRow);
      dest_varible.forEach(field =>{
        if(field.variable.includes("department_id")>0){
          destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.department);
        }
      });
      dest_varible.forEach(field =>{
        if(field.variable.includes("office_id")>0){
          destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.office);
        }
      });
      dest_varible.forEach(field =>{
        //console.log("variable Name==>",field.variable,"==>", this.group.get(field.field_binding).value);$('#endregion').value
        if(this.editValueRow[field.field_binding]==undefined && this.editValueRow[field.field_binding]==null){
          if(this.getUserListCheckCount ==10){
            return;
          }
          // this.getUserListCheckCount+=1;
          // setTimeout(() =>this.getUserList(),500)

          setTimeout(() => this.getUserListCheckCount+=1,500)         
          setTimeout(() =>this.getUserList(),500)
        }else{
          destQuery= destQuery.replace(field.variable,this.editValueRow[field.field_binding]);
        }
        
      });
      // console.log("destQuery==>",destQuery);
      //let qry="select id, username from mst_tblusermaster"
      if(destQuery.toString().trim() !=""){
        this.subscription=this.formService.getFormData(destQuery).subscribe(res=>{
          // console.log("destQuery==>",destQuery,res.rows);
          this.userId="";
          this.userList=res.rows; 
          this.userList=this.userList.filter(d=>d.id !=this.sessionUrlPrams.ProfileId)
          this.tempUserList=res.rows;
        if(this.routing_type =="rule"){
            this.userList.forEach(item =>{
              if(this.userId ==""){
                this.userId=item.id
              }else{
                this.userId=this.userId+","+item.id
              }
            });
          }
        })
      }
   }

  }
}

getRAAmountStatus(e){
  this.ra_amount_status=e;
}

actOnProcess1(){
  if(this.ra_amount_status == false  && this.formId == 374){
    this.toastrService.error("Reduce Grant List Amount not equal to HOA list Amount, Please check ",'',{timeOut: 3000,positionClass : "toast-top-center"});
    return false;
  }
  if(this.recipienttype == "0"){
    this.toastrService.error("Recipient Type is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
    return false;
  }
  let remContent="";    
  let checkNoteArr= this.notesheetList.filter(d=>d.notesheet_sr_no==this.refrenceSrNo);
  if(checkNoteArr.length == 0){
    this.toastrService.error("Noteheet comments is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
    return false;
  }else{
    remContent=(checkNoteArr[0].notesheet_containt).toString();
    remContent=remContent.replace("SafeValue must use [property]=binding: ",'')
    remContent=remContent.replace("(see http://g.co/ng/security#xss)",'');   
    remContent=remContent.replace(/<\/?[^>]+(>|$)/g, "");
  }
  
  let committeeDecision="";
  let attachmentFileName=""
  if(this.roleId == 14){
    committeeDecision=this.notesheetList[0]["dsc_proposal_decision"]?this.notesheetList[0]["dsc_proposal_decision"]:"";
    attachmentFileName="Minutes of Meeting (DSC)"
  }else if(this.roleId == 33){
    committeeDecision=this.notesheetList[0]["esc_proposal_decision"]?this.notesheetList[0]["esc_proposal_decision"]:"";
    attachmentFileName="Minutes of Meeting (ESC)"
  }

  
  let fileList=this.viewList.filter(d=>d.worklist_id == this.worklistid && d.attachment_type == attachmentFileName)

  if(committeeDecision != "" && fileList.length == 0 && (this.roleId == 14 || this.roleId == 33)){
    this.toastrService.error(attachmentFileName+" Attachment is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
    return false;
  }
 
 
  this.actOnProcess()   
 
}

actOnProcess()
{
  if(this.recipienttype == "External"){
    if(this.departmentid == undefined || this.departmentid == null || this.departmentid == "" ){
      this.toastrService.error("Department is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
      return false;
    }
    if(this.officeid == undefined || this.officeid == null || this.officeid == "" ){
      this.toastrService.error("Office is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
      return false;
    }
    if(this.departmentid == "17"){
      if(this.ministerapproval == undefined || this.ministerapproval == null || this.ministerapproval == ""){
        this.toastrService.error("Please select Approval from Minister is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
        return false;
      }else if(this.ministerapproval == 156){
        let arr=this.viewList.filter(d=>d.attachment_type == "Minister Approval Letter")
        if(arr.length == 0){
          this.toastrService.error("Please upload Minister Approval Letter.",'',{timeOut: 3000,positionClass : "toast-top-center"});
          return false;
        }    
      } 
      this.isBuffSheetVisiable=true;
    }   
    if((this.sessionUrlPrams.department == "17" || this.sessionUrlPrams.department == "18") && (this.decisionstatus == undefined || this.decisionstatus == "" || this.decisionstatus == null)) {
        this.toastrService.error("Please select decision",'',{timeOut: 3000,positionClass : "toast-top-center"});
        return false;      
    }else if(this.decisionstatus == "1" && this.sanctionOrderList.length == 0 && this.sessionUrlPrams.department == "18") { 
      this.toastrService.error("Please approve amount",'',{timeOut: 3000,positionClass : "toast-top-center"});
      return false;
     
    } 
  } 

  if(this.userId == "" || this.userId == undefined || this.userId== undefined){
    this.toastrService.error("user is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
    return false;
  }
 
  this.isLoader = true;  
  //return new Observable<string>(observer => {
    //this.processSubmitted=false;
    //let nxtstageid=this.form.value["hidden_nxtstageid"]?this.form.value["hidden_nxtstageid"]:0;
    let nxtstageid=this.nxtStageId;
    //let actiontaken=this.form.value["actiontaken"];
    let actiontaken=this.actiontakenid
    // let notesheet_containt=this.form.value["textarea_remarks"];
    // let notesheet_endorsee=this.form.value["textarea_indorsmentremarks"]?this.form.value["textarea_indorsmentremarks"]:"";
    //let touserid=this.form.value["sendto"];
    let touserid=this.userId;    
    var currStageJson=this.nodeJson.filter(d =>d.id ==this.stageid);
    let action_required_by=currStageJson[0]["action_required_by"]
    let checkNoteArr= this.notesheetList.filter(d=>d.notesheet_sr_no==this.refrenceSrNo);
    let dateDueon=$("#dateDueon").val();
    if(dateDueon == "" || dateDueon == undefined || dateDueon == null){
      dateDueon=undefined;
    }
    if( checkNoteArr.length>0)
    {
      if(action_required_by =="all")
      {
        //  update recipient type in noteshhet
        let processid, processinstanceid,notesheetSrNo;
        processid=checkNoteArr[0]["processid"];
        processinstanceid=checkNoteArr[0]["processinstanceid"];
        notesheetSrNo=checkNoteArr[0]["notesheet_sr_no"];
        let updatenotesheetQuery="update radx_process_note set recipient_type='"+this.recipienttype+"',notesheet_endorsee='"+this.mom_with_number+"',department_decision='"+this.decisionstatus+"' where processid="+processid+" and processinstanceid="+processinstanceid+" and notesheet_sr_no="+notesheetSrNo+";"

        // console.log("actOnprocess==>notesheet_containt,nxtstageid,actiontaken,touserid,action_required_by==>",nxtstageid,actiontaken,touserid,action_required_by)
        let checkallworklistitem="select * from radx_worklist where actiontaken='N' and processinstanceid="+this.processinstid+" and stageid="+this.stageid +";";
        let finalQry=updatenotesheetQuery+checkallworklistitem;

        this.formService.getFormData(finalQry).subscribe(res => {
          // console.log("actOnprocess in Case of All Pending With Actors==>",res[1].rows.length,res[1].rows)
          if(res[1].rows.length >1){
            //let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,notesheet_endorsee,createdby_userid,processid,processinstanceid,stageid,action_taken)values(1,'"+notesheet_containt+"','"+notesheet_endorsee+"',"+parseInt(this.SessionUrlPrams.ProfileId)+","+this.processid+","+this.processinstid+","+nxtstageid+","+actiontaken+"); ";
            let worklistupdateQry="";
            if(this.isParked == true){
              worklistupdateQry=" update radx_worklist set updated_at=now() , parked_status=2, actiontaken='Y' where worklistid="+this.worklistid +"; "; 
            }else{
              worklistupdateQry=" update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +"; "; 
            }
            
            this.formService.getFormData(worklistupdateQry).subscribe(res => {
              if(res.status==500){  
                this.toastrService.warning("Server/network Issue Please Contact to Administrator",'',{timeOut: 3000,positionClass : "toast-top-center"});       
                //observer.next("Server/network Issue Please Contact to Administrator");
                this.isLoader = false;
              }else{
                if(this.actiontakenid == 2){
                  this.toastrService.success("Approved Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                }else if(this.actiontakenid == 3){
                  this.toastrService.error("Rejected Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                }else{
                  this.toastrService.success("Submitted Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                }                  
                if(this.isApprovedRejectStatus==true){this.updateApprovedRejectStatus();}   //added by prawej 07-july-2021
                this.ministerApprovalUpdate();
                this.router.navigateByUrl('/inbox');  
                this.isLoader = false;
              }
            },err=>{
              this.toastrService.success("Error Occure while add into task list "+err.error.message,'',{timeOut: 3000,positionClass : "toast-top-center"});       
              //observer.next("Error Occure while add into task list "+err.error.message);
              this.isLoader = false;
            });
          }else{
            // console.log("checking actor/rule driven==>",touserid);
            if(touserid==undefined || touserid==0){
              //rule driven
              // console.log("rule driven==>",touserid);
              var nextStageJson=this.nodeJson.filter(d =>d.id ==nxtstageid);
              var destQuery=nextStageJson[0].destination_query;
              var dest_varible=JSON.parse(nextStageJson[0].destination_json);
              dest_varible.forEach(field =>{
                if(field.variable.includes("department_id")>0){
                  destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.department);
                }
              });
              dest_varible.forEach(field =>{
                if(field.variable.includes("office_id")>0){
                  destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.office);
                }
              })
              dest_varible.forEach(field =>{
                destQuery= destQuery.replace(field.variable,this.editValueRow[field.field_binding]);
              });
              if(destQuery.toString().trim() !=""){
                // console.log("actOnrocess Rule Driven destQuery===>",destQuery);
                this.subscription=this.formService.getFormData(destQuery).subscribe(res=>{
                  let userList=res.rows;
                  if(userList.length>0){
                    // console.log("actOnProcess userList==>",userList)
                    let processinstanceid=this.processinstid;
                    let worklistupdateQry="";
                    if(this.isParked == true){
                      worklistupdateQry=" update radx_worklist set updated_at=now() , parked_status=2, actiontaken='Y' where worklistid="+this.worklistid +"; "; 
                    }else{
                      worklistupdateQry=" update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +"; "; 
                    }
                    
                    //let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,notesheet_endorsee,createdby_userid,processid,processinstanceid,stageid,action_taken)values(1,'"+notesheet_containt+"','"+notesheet_endorsee+"',"+parseInt(this.SessionUrlPrams.ProfileId)+","+this.processid+","+processinstanceid+","+nxtstageid+","+actiontaken+"); ";
                    //let finalQry=processNoteQry + worklistupdateQry;
                    let finalQry=worklistupdateQry;
                    userList.forEach(item =>{
                      let processWorklistQry=`insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken,priority_type,priority_date,recipient_type_txt,department_id)values(${processinstanceid},${nxtstageid},${parseInt(this.sessionUrlPrams.ProfileId)},${item.id},'N',${this.priority},${dateDueon?("'"+dateDueon+"'"):null},'${this.recipienttype}',${this.sessionUrlPrams.department});`;
                        finalQry=finalQry+processWorklistQry
                    });
                    let processInstanceQry="update radx_processinstances set updated_at=now(), current_stageid="+nxtstageid+" ,with_whom_userid="+userList[0].id+" where processinstanceid="+this.processinstid +";";
                    finalQry=finalQry+processInstanceQry;
                    this.subscription=this.formService.getFormData(finalQry).subscribe(res => {
                      if(res.status==500){         
                        //observer.next("Server/network Issue Please Contact to Administrator");
                        this.toastrService.warning("Server/network Issue Please Contact to Administrator",'',{timeOut: 3000,positionClass : "toast-top-center"})
                        this.isLoader = false;
                      }else{
                        if(this.actiontakenid == 2){
                          this.toastrService.success("Approved Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                        }else if(this.actiontakenid == 3){
                          this.toastrService.error("Rejected Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                        }else{
                          this.toastrService.success("Submitted Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                        }  
                        if(this.isApprovedRejectStatus==true){this.updateApprovedRejectStatus();}   //added by prawej 07-july-2021 
                        this.ministerApprovalUpdate();
                        this.router.navigateByUrl('/inbox');  
                        this.isLoader = false;
                      }
                    },err=>{
                      this.toastrService.warning("Error Occure while add into task list "+err.error.message,'',{timeOut: 3000,positionClass : "toast-top-center"})
                      //observer.next("Error Occure while add into task list "+err.error.message);
                      this.isLoader = false;
                    });
                  }else{
                    this.toastrService.warning("No User Assigned",'',{timeOut: 3000,positionClass : "toast-top-center"});
                    //observer.next("No User Assigned");
                    this.isLoader = false;
                  }
                });
              }else{
                this.toastrService.warning("No Rule add in process stages",'',{timeOut: 3000,positionClass : "toast-top-center"});
                //observer.next("No Rule add in process stages");
                this.isLoader = false;
              }
            }else{
              //actor driven
              // console.log("actor driven==>",touserid);
              let processInstanceQry="update radx_processinstances set updated_at=now(), current_stageid="+nxtstageid+" ,with_whom_userid="+touserid+" where processinstanceid="+this.processinstid +";";
              let worklistupdateQry="";
              if(this.isParked == true){
                worklistupdateQry="update radx_worklist set updated_at=now() , parked_status=2, actiontaken='Y' where worklistid="+this.worklistid +";"; 
              }else{
                worklistupdateQry="update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +";"; 
              }
              
              //let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,notesheet_endorsee,createdby_userid,processid,processinstanceid,stageid,action_taken)values(1,'"+notesheet_containt+"','"+notesheet_endorsee+"',"+parseInt(this.SessionUrlPrams.ProfileId)+","+this.processid+","+this.processinstid+","+nxtstageid+","+actiontaken+");";
              // let processWorklistQry="insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken)values("+this.processinstid+","+nxtstageid+","+parseInt(this.sessionUrlPrams.ProfileId)+","+touserid+",'N');";
                 let processWorklistQry=`insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken,priority_type,priority_date,recipient_type_txt,department_id)values(${processinstanceid},${nxtstageid},${parseInt(this.sessionUrlPrams.ProfileId)},${touserid},'N',${this.priority},${dateDueon?("'"+dateDueon+"'"):null},'${this.recipienttype}',${this.sessionUrlPrams.department});`;
              //let finalQry=processNoteQry+processWorklistQry +processInstanceQry +worklistupdateQry
              let finalQry=processWorklistQry +processInstanceQry +worklistupdateQry
              // console.log("actOnrocess actor driven finalQry===>",finalQry);
              this.formService.getFormData(finalQry).subscribe(res => {
                if(res.status==500){  
                  this.toastrService.warning("Server/network Issue Please Contact to Administrator",'',{timeOut: 3000,positionClass : "toast-top-center"});
                  //observer.next("Server/network Issue Please Contact to Administrator");
                  this.isLoader = false;
                }else{
                  if(this.actiontakenid == 2){
                    this.toastrService.success("Approved Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                  }else if(this.actiontakenid == 3){
                    this.toastrService.error("Rejected Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                  }else{
                    this.toastrService.success("Submitted Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"}); 
                  }  
                  if(this.isApprovedRejectStatus==true){this.updateApprovedRejectStatus();}  //added by prawej 07-july-2021 
                  this.ministerApprovalUpdate();
                  this.router.navigateByUrl('/inbox');  
                  this.isLoader = false;
                }
              },err=>{
                this.toastrService.warning("Error Occure while add into task list "+err.error.message,'',{timeOut: 3000,positionClass : "toast-top-center"});
                //observer.next("Error Occure while add into task list "+err.error.message);
                this.isLoader = false;
              });
            }
          }
        });
      }else{
        //in case of any we need to write the code here...
        this.toastrService.warning("Any case not handled",'',{timeOut: 3000,positionClass : "toast-top-center"});
        //observer.next("any case not handled");
        this.isLoader = false;
      }
    }else{
      this.toastrService.warning("Noteheet comment's is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
      //observer.next("Remarks is required");
      this.isLoader = false;
    }
  //});
}
submit(value:any) {
  //console.log("pradeep value.isAddMode===>",JSON.stringify(value),value.isAddMode,value.SuccessMasgLoaded);
  let FormData=value.FormDate;
  this.isLoading=true;
  this.refKey=value.refKey;
  if(value.isAddMode==true){
    if(value.SuccessMasgLoaded=="true"){
      //this.refKey=value.refKey;
      this.isAddMode=false;
      this.toastrService.success("Added Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"});        
    }else{
     this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")",'',{timeOut: 3000,positionClass : "toast-top-center"});        
    }
  }else{
    if(value.SuccessMasgLoaded =="true"){
      //let schemecode=this.fileName(FormData.scheme_type,FormData.process_type,this.refKey);
      let scheme_type=FormData.scheme_type!=undefined?FormData.scheme_type:null;
      let process_type=FormData.process_type!=undefined?FormData.process_type:null;
      let fund_category=FormData.funding_category !=undefined?FormData.funding_category:null;
      let sect_id=FormData.sector_id!=undefined?FormData.sector_id:null;
      let cy_requirment=FormData.curr_year_requirment!=undefined?FormData.curr_year_requirment:null;
      let est_cost;//FormData.estimated_cost!=undefined?FormData.estimated_cost:null;
      let justification_objective=FormData.justification_objective!=undefined?FormData.justification_objective:null;
      // changes by prawej on 17-Aug-2021 for Adding Est_Cost for Every Scheme in Draft tbl        
      if(this.formId == 313){
        est_cost= FormData.curr_year_requirment?FormData.curr_year_requirment:null;      
      }else if(this.formId == 326){
        est_cost= FormData.estimated_cost?FormData.estimated_cost:null;      
      }else if(this.formId == 331 ){
        est_cost= FormData.current_year_expenditure?FormData.current_year_expenditure:null;      
      }else if(this.formId == 349){
        est_cost= FormData.sanction_amount?FormData.sanction_amount:null;
      }
      let strSQLDraftUpdate="update radx_draft_requests set proposal_type ="+scheme_type+" ,scheme_category="+process_type+",funding_category_id="+fund_category+", amount_proposed_cy="+cy_requirment+", scheme_title='"+FormData.scheme_title+"',sector_id="+sect_id+", estimated_cost="+est_cost+", approved_cost="+est_cost+", justification_objective='"+justification_objective+"' where item_id="+value.refKey+" and form_id="+this.formId;
      this.subscription=this.formService.getFormData(strSQLDraftUpdate).subscribe(result =>{
        console.log("updated in draft sucessfully",result.status);                
      });
      this.toastrService.success("Updated Sucessfully !!",'',{timeOut: 3000,positionClass : "toast-top-center"});

      //this.toastrService.success("Updated Sucessfully !!",'',{timeOut: 3000,positionClass : "toast-top-center"});
    }else{        
      this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")",'',{timeOut: 3000,positionClass : "toast-top-center"});
    }
  }
  setTimeout(() => {this.isLoading=false},800);
}
sticky(refPara,index,notesheetid,reference_type,sr,para) {
  this.refSrNo=sr;
  this.refPara=para;
  let arr=this.notesheetList.filter(d=>d.notesheetid == notesheetid)[0]["reference_id"];    
  if(reference_type == 1 || reference_type == 0 || reference_type == null){
    let filnalArr=arr.split(",");
    let finalNSI=filnalArr[index]    
    this.stickyNotsheetId = finalNSI
    var notsheet = this.notesheetList.filter(x => x.notesheetid == finalNSI);
    this.modalNotesheetContent = notsheet[0].notesheet_containt;
    this.stickyNotingDate = notsheet[0].noting_date;
    this.stickyuser_fname = notsheet[0].user_fname;
    this.stickyrole_name = notsheet[0].role_name;    
    setTimeout(() => {
      $(".toast").show();
      $(".toast").addClass("showing");
    }, 400);
  }
}

stickyAttachment(refPara,index,notesheetid,reference_type,sr,para) {
  this.refSrNo=sr;
  this.refPara=para;
  let arr=this.notesheetList.filter(d=>d.notesheetid == notesheetid)[0]["attachment_reference_id"];
    $("#viewformdetail").hide();
    $("#addattr").hide();
    $("#bascidetail").hide();
    $("#corress").hide();
    $("#addfile").hide();
    $("#addnote").hide();
    $("#sendto").hide();
    $("#copyto").hide(); 
    let filnalArr=arr.split(",");
    let currviewListId=filnalArr[index];
    this.showImage(currviewListId)      
    $("#fileViwer").show();
    $("#parkedto").hide();
    $("#copyto").hide(); 
    $("#divproposedapprove").hide();      
}

closetoast() {
  $(".toast").hide();
  $(".toast").removeClass("showing");
  $("#fileViwer").hide();
}
showupload() {
  this.colappsout()
  $("#viewformdetail").hide();
  $("#addattr").show();
  $("#bascidetail").hide();
  $("#corress").hide();
  $("#addfile").hide();
  $("#addnote").hide();
  $("#sendto").hide();
  $("#fileViwer").hide();
  $("#copyto").hide(); 
  $("#parkedto").hide();
  $("#divproposedapprove").hide();
}

addcorres() {
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#corress").show();
  $("#addfile").hide();
  $("#addnote").hide();
  $("#fileViwer").hide();
  $("#copyto").hide(); 
  $("#parkedto").hide();
  $("#divproposedapprove").hide();
}

colappsin() {
  $(".zoomadd").hide();
  $(".zoomminus").show();
  $(".leftgreensheet").hide();
  $(".filerespnew").addClass("col-lg-12");
  $(".newfilebtn").show();
  $(".minimi").hide();
  $("#copyto").hide(); 
  $("#fileViwer").hide();
  // $("#parkedto").hide();
  // $("#divproposedapprove").hide();

}
colappsout() {
  $(".zoomadd").show();
  $(".zoomminus").hide();
  $(".leftgreensheet").show();
  $(".filerespnew").removeClass("col-lg-12");
  $(".newfilebtn").hide();
  $(".minimi").show();
  $("#fileViwer").hide();
  $("#copyto").hide(); 
  // $("#parkedto").hide();
  // $("#divproposedapprove").hide();
}
zoomcall() {
  $(".zoomadd").hide();
  $(".zoomminus").show();
  $(".leftgreensheet").hide();
  $(".filerespnew").addClass("col-lg-12");
  $(".newfilebtn").show();
  $(".minimi").hide();
  $("#fileViwer").hide();
  // $("#parkedto").hide();
  // $("#divproposedapprove").hide();

}
zoomcut() {
  $(".zoomadd").show();
  $(".zoomminus").hide();
  $(".leftgreensheet").show();
  $(".filerespnew").removeClass("col-lg-12");
  $(".newfilebtn").hide();
  $(".minimi").show();
  $("#fileViwer").hide();
  this.showproposed();
  // $("#parkedto").hide();
  // $("#divproposedapprove").hide();
}
addnote() {
  // debugger;
  this.notsheetContent = "";
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#corress").hide();
  $("#addfile").hide();
  $("#addnote").show();
  $("#sendto").hide();
  $("#viewformdetail").hide()
  $("#fileViwer").hide();
  $("#parkedto").hide();
  $("#copyto").hide(); 
  $("#divproposedapprove").hide();
  //$(".hidebt").hide()

  // let lastNoteArray=this.notesheetList.filter(d=>d.notesheetid == this.notesheetList[this.notesheetList.length-1]["notesheetid"]);
  // var createdBy = this.sessionUrlPrams.UserId;
  // var notsheet = this.notesheetList.filter(x => x.createdby_userid == createdBy);
  // if (notsheet.length == 0) {
  //   this.refrenceSrNo = this.notesheetList.length + 1;
  //   this.paraNo = 1;
  // }
  // else {
  //   var notsheetFilter = this.notesheetList.filter(y => y.createdby_userid == createdBy)
  //   this.refrenceSrNo = (this.notesheetList.length - notsheetFilter.length) + 1;
  //   this.paraNo = notsheet.length + 1;      
  // }
  // if(lastNoteArray.length>0){
  //   let nArray=lastNoteArray.filter(d => d.createdby_userid == createdBy)
  //   if(nArray.length>0){
  //     this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]);
  //     this.paraNo = Number(lastNoteArray[0]["notesheet_para_no"])+1;
  //   }else{
  //     this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]) + 1;
  //     this.paraNo = 1;
  //   }
  // }
  //this.editor.commands.focus();
}

onClickCancel() {
  this.isEdit = false;
  $("#bascidetail").show();
  $("#addnote").hide();
  $("#fileViwer").hide();
  $("#parkedto").hide();
  $("#copyto").hide(); 
  $("#divproposedapprove").hide();
}

onEditClick(notesheetid) {
  this.notesheeSrParaList=[];
  this.notesheeImageList=[];
  this.isEdit = true;
  this.notsheetid = notesheetid
  var notsheet = this.notesheetList.filter(
    x => x.notesheetid == notesheetid);
  $("#addnote").show();
  $("#sendto").hide();
  $("#fileViwer").hide();
  $("#viewformdetail").hide();
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#corress").hide();
  $("#addfile").hide();  
  $("#parkedto").hide();
  $("#copyto").hide(); 
  $("#divproposedapprove").hide();
  
  let remContent=(notsheet[0].notesheet_containt).toString();
  remContent=remContent.replace("SafeValue must use [property]=binding: ",'')
  remContent=remContent.replace("(see http://g.co/ng/security#xss)",'')
  this.notsheetContent = remContent;////notsheet[0].notesheet_containt;
  this.refrenceSrNo = notsheet[0].notesheet_sr_no;  
  $("#ddlReferenceType").val(notsheet[0].reference_type); 
  this.refrenceTypeChange(notsheet[0].reference_type?notsheet[0].reference_type:0)

  // console.log(JSON.stringify(notsheet[0]))

  if(notsheet[0].reference_id != null && notsheet[0].reference_id != undefined && notsheet[0].reference_id != "" &&
    notsheet[0].reference_name != null && notsheet[0].reference_name != undefined && notsheet[0].reference_name != "" &&
    notsheet[0].reference_name != "undefined"
  ){
    let refArray=(notsheet[0].reference_id).split(",");
    let refNameArray=(notsheet[0].reference_name).split(",")
    for(let c=0; c< refArray.length; c++){        
        this.notesheeSrParaList.push({
          "notesheetid":Number(refArray[c]),
          "srno_parano":refNameArray[c],
        })
    }
  }

  if(notsheet[0].attachment_reference_id != null && notsheet[0].attachment_reference_id != undefined && notsheet[0].attachment_reference_id != ""){
    let attachment_reference_id=(notsheet[0].attachment_reference_id).split(",");
    let attachment_reference_name=(notsheet[0].attachment_reference_name).split(",")
    for(let c=0; c< attachment_reference_id.length; c++){        
        this.notesheeImageList.push({
          "id":Number(attachment_reference_id[c]),
          "attachment_desc":attachment_reference_name[c],
        })
    }
  }
  
  
}

refrenceName() {
  var refrenceNote = this.notesheetList.filter(
    notsheet => notsheet.notesheetid == this.refrenceId);
  if (refrenceNote.length > 0) {
    if (refrenceNote[0].notesheet_para_no == null) {
      var refrenceName = refrenceNote[0].notesheet_sr_no + '.'
    } else {
      var refrenceName = refrenceNote[0].notesheet_sr_no + '.' + refrenceNote[0].notesheet_para_no;
    }

  } else {
    refrenceName = null;
  }
  return refrenceName
}

onEditSaveClick() {
  let ids="",refName=""; 
  this.refrenceId=null;
  this.refrenceNamee=null;
  
    for(let c=0; c<this.notesheeSrParaList.length; c++){
      ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
      refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
    }
    if(ids != ""){
      this.refrenceId= ids.substring(0,ids.length-1)
      this.refrenceNamee= refName.substring(0,refName.length-1)
    }  

    
    let att_ids="",att_refName=""; 
    let attachmentRefId,attachmentRefName;
    for(let c=0; c<this.notesheeImageList.length; c++){
      att_ids=att_ids+this.notesheeImageList[c]["id"]+",";
      att_refName=att_refName+this.notesheeImageList[c]["attachment_desc"]+","
    }
    if(ids != ""){
      attachmentRefId= att_ids.substring(0,att_ids.length-1)
      attachmentRefName= att_refName.substring(0,att_refName.length-1)
    } 
  
  let sqlQueryEdit = `UPDATE  radx_process_note SET notesheet_containt='${this.notsheetContent}',reference_type=${this.refrenceType}
  ,reference_id='${this.refrenceId}',reference_name='${this.refrenceNamee}'
  ,attachment_reference_id=${attachmentRefId?("'"+attachmentRefId+"'"):null},attachment_reference_name=${attachmentRefName?("'"+attachmentRefName+"'"):null} 
  where notesheetid=${this.notsheetid}`;
  this.formService.getFormData(sqlQueryEdit).subscribe(res => {
    this.gettingNotSheet();
    this.resetNotingControl();
    this.showproposed();
    // window.location.reload();
    (<HTMLInputElement>document.getElementById("noteArea")).value = "";
    this.toastrService.success("Edited Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"});
  })
}

onDeleteClick(notesheetid) {
  const confirmBox = new ConfirmBoxInitializer();
  confirmBox.setTitle('Are you sure?');
  confirmBox.setMessage('You want to delete this!');
  confirmBox.setButtonLabels('YES', 'NO');
  confirmBox.setConfig({
    LayoutType: DialogLayoutDisplay.DANGER // SUCCESS | INFO | NONE | DANGER | WARNING
  });
  const subscription = confirmBox.openConfirmBox$().subscribe(resp => {
    if(resp.Success==true){
      let sqlQueryDelete = `DELETE FROM radx_process_note where notesheetid=${notesheetid}`;
      this.formService.getFormData(sqlQueryDelete).subscribe(res => {
        this.gettingNotSheet();          
      })
  }
});

}
sendreq() {
  this.recipienttype="0"
  this.userList=[];
  this.userId="";
  $("#fileViwer").hide();
  $("#addattr").hide();
  // $("#bascidetail").hide();
  // $("#corress").hide();
  // $("#addfile").hide();
  $("#addnote").hide();
  $("#sendto").show();
  $("#copyto").hide();
  $("#parkedto").hide();
  $("#divproposedapprove").hide();
  $("#viewformdetail").hide();    
  this.isview_field = Promise.resolve(true);
}
viewdetailc() {
  $("#fileViwer").hide();
  $("#bascidetail").show();
}


onSaveNote() {   
  var noteSerialNo = (<HTMLInputElement>document.getElementById("noteSerialNo")).value;
  var createddate = new Date().toString();
  createddate = this.datePipe.transform(createddate, 'yyyy-MM-dd,h:mm a')
  // var createdBy = this.sessionUrlPrams.ProfileId //localStorage.getItem("userId");   
  var regex = /( |<([^>]+)>)/ig;
  let noteContent=  this.notsheetContent.replace(regex, "");
 
  if(noteContent == undefined || noteContent == null || noteContent == ''){
    this.toastrService.warning("Noting is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
    this.notsheetContent="";
    return false;
  }else{      
    this.isLoader = true;
    let sqlQuerySave = `INSERT INTO radx_process_note(notesheet_sr_no, notesheet_containt,createddate,createdby_userid,processid,processinstanceid,stageid,notesheet_para_no,reference_type,reference_id,reference_name,department_id,attachment_reference_id,attachment_reference_name) VALUES (
      ${noteSerialNo},'${this.notsheetContent}','${createddate}',${this.sessionUrlPrams.ProfileId},${this.processid},${this.processinstid},${this.stageid},${this.paraNo},${this.refrenceType},'${this.refrenceId}','${this.refrenceNamee}',${this.sessionUrlPrams.department},${this.attachmentReferenceId?("'"+this.attachmentReferenceId+"'"):null},${this.attachmentReferenceName?("'"+this.attachmentReferenceName+"'"):null})`;
      // console.log("sqlQuerySave ==>",sqlQuerySave)
    this.formService.getFormData(sqlQuerySave).subscribe(res => {
      this.gettingNotSheet();
      this.resetNotingControl();
      this.showproposed();
      this.isLoader = false;
      this.toastrService.success("Added Sucessfully",'',{timeOut: 3000,positionClass : "toast-top-center"});
    })
  }    
}

resetNotingControl(){
  this.isEdit=false;
  $("#ddlReferenceType").val(0);
  this.notsheetContent = "";
  this.notesheeSrParaList=[];    
  this.notesheeImageList=[];
  this.refrenceId='';
  this.attachmentReferenceId = '';
}


gettingNotSheet() {
  let tableName="",primaryKey="",tempcolumn="";
  if(this.formId == "374"){
    tableName="z_entitymast_re_appropriation_001";
    primaryKey="re_appropriation_id";
  }else{
    tableName="z_entitymast_grantinaid_subsidy_scheme_details_001";
    primaryKey="grantinaid_id";
    tempcolumn=", genscheme.dfp_rule_text as delegationrule,genscheme.is_dfp_rule "
  }
  let sqlQuery = `select rpn.notesheetid,rpn.department_decision,rpn.notesheet_endorsee, rpn.notesheet_containt,TO_CHAR(rpn.createddate:: DATE, 'dd/mm/yyyy') as noting_date ,
  rpn.createdby_userid,rpn.notesheet_sr_no,rpn.notesheet_para_no,rpn.reference_type,rpn.reference_id,rpn.reference_name,concat(u.user_fname,' ',u.user_mname,' ',u.user_lname) as user_fname,
  rpn.recipient_type,case rpn.recipient_type when 'External' then concat('ID/- (',d.department_name,')') else concat(des.designation_name,'(',d.department_name,')') end as role_name,
  up.department_id,rdr.draft_requestid, rdr.id as draft_id,rpn.processid,rpn.processinstanceid,ofc.office_name,
  rdr.scheme_title,rdr.scheme_category,rdr.proposal_type,rdr.funding_category_id,rdr.office_id,rdr.minister_approval,rdr.esc_proposal_decision,rdr.department_id as draft_dept_id,
  rpn.attachment_reference_id,rpn.attachment_reference_name,d.department_code_en,d.department_name, rdr.dsc_proposal_decision
  ${tempcolumn}  
  from radx_process_note rpn 
  left join app_tbluserprofile up on rpn.createdby_userid=up.userprofile_id
  inner join app_tbluser u on up.user_id=u.user_id
  inner join app_tblrole r on up.role_id=r.role_id
  inner join z_entitymast_department_001 d on up.department_id=d.department_id
  left join radx_processinstances rp on rp.processinstanceid = rpn.processinstanceid
  left join z_entitymast_designation_001 des on up.designation_id=des.designation_id
  left join radx_draft_requests rdr on rdr.form_id = rp.form_id and rdr.item_id = rp.itemid
  left join ${tableName} genscheme on genscheme.${primaryKey} = rdr.item_id
  left join z_entitymast_manage_offices_001 ofc on ofc.offices_id =rdr.office_id
  where rpn.processinstanceid = ${this.processinstid} and (rpn.department_id=${this.scheme_deptid} or (rpn.department_id=${this.sessionUrlPrams.department} or rpn.recipient_type='External')) order by rpn.notesheetid desc 
      `;  
  // left join radx_draft_requests rdr on rdr.process_id = rp.processid and rdr.item_id = rp.itemid      
  // console.log(sqlQuery)
  this.formService.getFormData(sqlQuery).subscribe(res => {      
    this.notesheetList = [];
    this.dropdownList=[];
    this.notesheetList = res.rows;       
    this.notesheetList = this.notesheetList.reverse();
    if(this.notesheetList.length>0){
      if(this.notesheetList[0]["is_dfp_rule"] == true){
        this.isDfpRule=true;
      }else{
        this.isDfpRule=false;
      }      

      if(this.notesheetList[0]["draft_dept_id"] == this.sessionUrlPrams.department){
        this.isSameDepartmentProcess=true;
      }else{
        this.isSameDepartmentProcess=false;
      }

      let esc_approval_id=this.notesheetList[0]["esc_proposal_decision"];     
      if(esc_approval_id != "" && esc_approval_id != undefined && esc_approval_id != null && this.sessionUrlPrams.department == "17"){
        this.decisionstatus=esc_approval_id;
      }
     
      this.draft_requestid=this.notesheetList[0]["draft_requestid"]?this.notesheetList[0]["draft_requestid"]:'';
      this.draft_id =this.notesheetList[0]["draft_id"]?this.notesheetList[0]["draft_id"]:0;
      this.dept_code=this.notesheetList[0]["department_code_en"]?this.notesheetList[0]["department_code_en"]:"";
      this.draft_scheme_title =this.notesheetList[0]["scheme_title"]?this.notesheetList[0]["scheme_title"]:null;
      this.draft_scheme_category =this.notesheetList[0]["scheme_category"]?this.notesheetList[0]["scheme_category"]:null;
      this.draft_proposal_type =this.notesheetList[0]["proposal_type"]?this.notesheetList[0]["proposal_type"]:null;
      this.draft_funding_category_id =this.notesheetList[0]["funding_category_id"]?this.notesheetList[0]["funding_category_id"]:null;
      this.draft_office_id =this.notesheetList[0]["office_id"]?this.notesheetList[0]["office_id"]:null;
      this.draft_minister_approval =this.notesheetList[0]["minister_approval"];     
      if(this.draft_minister_approval == true){
        this.isMinisterApproval=true;
        this.ministerapproval=156;
      }else if(this.draft_minister_approval == false){
        this.isMinisterApproval=true;
        this.ministerapproval=157;
      }else{
        this.isMinisterApproval=false;
        this.ministerapproval=undefined;
      }
      if((this.ministerapproval == 156 || this.ministerapproval == 157)){
        this.sessionUrlPrams.vid=343;
      }
      this.getSanctionOrderDetail();// for sanction order pdf details
    }
    // console.log(JSON.stringify(this.notesheetList))
    // setTimeout(() => {
      this.notesheetList.forEach(element => {
        if (element.notesheet_para_no) {
          this.dropdownList.push({
            item_id: element.notesheetid,
            item_text: element.notesheet_sr_no + '.' + element.notesheet_para_no,
          })
        } else {
          this.dropdownList.push({
            item_id: element.notesheetid,
            item_text: element.notesheet_sr_no,
          })
        }
      })

    // }, 500);
    for(let r=0; r<this.notesheetList.length; r++){
      if(this.notesheetList[r]["reference_name"] != null && this.notesheetList[r]["reference_name"] != undefined && this.notesheetList[r]["reference_name"] !="" && this.notesheetList[r]["reference_name"] !="0"){
        this.notesheetList[r]["ref_array"]=this.notesheetList[r]["reference_name"].split(",")
      }else{
        this.notesheetList[r]["ref_array"]=[];
      }

      if(this.notesheetList[r]["attachment_reference_name"] != null && this.notesheetList[r]["attachment_reference_name"] != undefined && this.notesheetList[r]["attachment_reference_name"] !="" && this.notesheetList[r]["attachment_reference_name"] !="0"){
        this.notesheetList[r]["att_ref_array"]=this.notesheetList[r]["attachment_reference_name"].split(",")
      }else{
        this.notesheetList[r]["att_ref_array"]=[];
      }

      if(r >0 && this.notesheetList[r]["notesheet_sr_no"] ==this.notesheetList[r-1]["notesheet_sr_no"] ){
        this.notesheetList[r-1]["user_fname"]="";
        this.notesheetList[r-1]["role_name"]="";
      }  
      this.notesheetList[r]["notesheet_containt"]=this.sanitizer.bypassSecurityTrustHtml(this.notesheetList[r]["notesheet_containt"]);     
      let remContent=(this.notesheetList[r]["notesheet_containt"]).toString();
          remContent=remContent.replace("SafeValue must use [property]=binding: ",'')
          remContent=remContent.replace("(see http://g.co/ng/security#xss)",'');   
          remContent=remContent.replace(/<\/?[^>]+(>|$)/g, "");
          this.notesheetList[r]["notesheet_print_containt"]=remContent;
    }
    
    // Set Note No and Paragraph Number 
    let lastNoteArray=this.notesheetList.filter(d=>d.notesheetid == this.notesheetList[this.notesheetList.length-1]["notesheetid"]);
    // var createdBy = this.sessionUrlPrams.p;  
    if(lastNoteArray.length>0){
          let nArray=lastNoteArray.filter(d => d.createdby_userid == this.sessionUrlPrams.ProfileId)         
          if(nArray.length>0){            
            this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]);
            this.paraNo = Number(lastNoteArray[0]["notesheet_para_no"])+1;
          }else{
            this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]) + 1;
            this.paraNo = 1;
          }
        }      
      // console.log("dropdownList", this.dropdownList)

      
      let usernoting=this.notesheetList.filter(notsheet => notsheet.notesheet_sr_no == this.refrenceSrNo);
      if(usernoting.length>0){
        this.isnotingAdded = true;
      }else{
        this.isnotingAdded = false;
      }

      this.notesheetLoaded = Promise.resolve(true);

      // setTimeout(() => {
        this.pageCount();
      // }, 500);
    
  })
}

pageCount() {    
  this.notsheetPageList = [];
  var notsheetCheck: any = [];
  this.notsheetPageCount=1;
  this.selectArray = [];
  for (let i = 0; i < this.notesheetList.length; i++) {
    
    this.notesheetList[i].notesheet_containt;
    notsheetCheck = notsheetCheck + this.notesheetList[i].notesheet_containt;
    let refName="";
    if(this.notesheetList[i].reference_name == "undefined" || this.notesheetList[i].reference_name == "null"){
      refName=null;
    }else{
      refName=this.notesheetList[i].reference_name;
    }
    this.notsheetPageList.push({
      ['createdby_userid']: this.notesheetList[i].createdby_userid,
      ['department_id']: this.notesheetList[i].department_id,
      ['notesheet_containt']: this.notesheetList[i].notesheet_containt,
      ['notesheet_para_no']: this.notesheetList[i].notesheet_para_no?this.notesheetList[i].notesheet_para_no:"",
      ['notesheet_sr_no']: this.notesheetList[i].notesheet_sr_no,
      ['notesheetid']: this.notesheetList[i].notesheetid,
      ['noting_date']: this.notesheetList[i].noting_date,
      ['role_name']: this.notesheetList[i].role_name,
      ['user_fname']: this.notesheetList[i].user_fname,
      ['page_no']: this.notsheetPageCount,
      ['reference_id']: this.notesheetList[i].reference_id?this.notesheetList[i].reference_id:"",
      ['reference_type']: this.notesheetList[i].reference_type?this.notesheetList[i].reference_type:"",
      ['reference_name']: refName?refName:"",
      ['srno_parano']:(this.notesheetList[i].notesheet_sr_no)+"."+(this.notesheetList[i].notesheet_para_no?this.notesheetList[i].notesheet_para_no:""),
      ['ref_array']: this.notesheetList[i].ref_array,
      ['attachment_reference_id']: this.notesheetList[i].attachment_reference_id,
      ['attachment_reference_name']: this.notesheetList[i].attachment_reference_name,
      ['att_ref_array']: this.notesheetList[i].att_ref_array        
    })
    
    if (notsheetCheck.length > 1000) {
      this.notsheetPageCount++;
      notsheetCheck = [];
      continue;
    }
  }
  for (let j = 1; j <= this.notsheetPageCount; j++) {
    this.selectArray.push({
      ['page_no']: j,
    })
  }
  

  this.nextPreviousCount=Number(this.selectArray.length);
  this.pageskip(this.selectArray.length);
  }

pageskip(pageNo) {    
  // if(this.notsheetPageCount==pageNo){
  //   $("#linkNext").removeClass("btn-warning").addClass("btn btn-sm btn-light");
  //   $("#linkPrevious").removeClass(" btn-white").addClass("btn-warning");
  
  // }
  // if(pageNo==1){
  //   $("#linkNext").removeClass(" btn-light").addClass("btn-warning");
  //   $("#linkPrevious").removeClass(" btn-warning").addClass(" btn-white ");
  // }
  this.notsheetPageListFinal = this.notsheetPageList.filter(notsheet => notsheet.page_no == pageNo);

}

pagePlus() {
  
  if (this.nextPreviousCount < this.notsheetPageCount) {
    this.nextPreviousCount++;
    this.pageskip(this.nextPreviousCount);
  }

  if(this.nextPreviousCount == this.notsheetPageCount){
    // $("#linkNext").removeClass("btn-warning").addClass("btn btn-sm btn-light");
    // $("#linkPrevious").removeClass("btn-white").addClass("btn-warning");
  }

}

pageMinus() {
  if(this.nextPreviousCount == 2){
    // $("#linkNext").removeClass(" btn-light").addClass("btn-warning");
    // $("#linkPrevious").removeClass(" btn-warning").addClass(" btn-white ");
  }
  if (this.nextPreviousCount > 1) {
    this.nextPreviousCount--;
    this.pageskip(this.nextPreviousCount);
  }

}

viewformdetail() {
  this.colappsin();
  $("#fileViwer").hide();
  $("#viewformdetail").show();
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#viewdoc").hide();
  $("#addfile").hide();
  $("#addnote").hide();
  $("#sendto").hide();
  $("#parkedto").hide();
  $("#divproposedapprove").hide();

}
printDiv(divName) {
  var printContents = document.getElementById(divName).innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  // window.location.reload();
}

printAll(divName) {
  var printContents = document.getElementById(divName).innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  window.location.reload();
}

refrenceTypeChange(value) {
  if (value == 1) {
    this.isNoting = true;
    this.isFile = false;
    this.defaultDrop = false;
    this.refrenceType = 1
  } else if (value == 2) {
    this.isFile = true;
    this.defaultDrop = false;
    this.isNoting = false;
    this.refrenceType = 2
  }
}

notingSelected(e) { 
  // console.log(JSON.stringify(this.notesheeSrParaList))
  let ids="",refName="";    
  for(let c=0; c<this.notesheeSrParaList.length; c++){
    ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
    refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
  }
  if(ids != ""){
    this.refrenceId= ids.substring(0,ids.length-1)
    this.refrenceNamee= refName.substring(0,refName.length-1)
  }    
}
OnSrParaDeSelect(e){
  let ids="",refName="";    
  for(let c=0; c<this.notesheeSrParaList.length; c++){
    ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
    refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
  }
  if(ids != ""){
    this.refrenceId= ids.substring(0,ids.length-1)
    this.refrenceNamee= refName.substring(0,refName.length-1)
  }    
}
onSelectAllSrPara(e){
  let ids="",refName="";    
  for(let c=0; c<this.notesheeSrParaList.length; c++){
    ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
    refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
  }
  if(ids != ""){
    this.refrenceId= ids.substring(0,ids.length-1)
    this.refrenceNamee= refName.substring(0,refName.length-1)
  } 
}

attachmentSelected(e){
  let ids="",refName="";    
  for(let c=0; c<this.notesheeImageList.length; c++){
    ids=ids+this.notesheeImageList[c]["id"]+",";
    refName=refName+this.notesheeImageList[c]["attachment_desc"]+","
  }
  if(ids != ""){     
    this.attachmentReferenceId= ids.substring(0,ids.length-1)
    this.attachmentReferenceName= refName.substring(0,refName.length-1)     
  }else{
    this.attachmentReferenceId= null;
    this.attachmentReferenceName= null;    
  }
 
}

onClickPrint() {
  var notsheet = []
  this.selectedItems.forEach((y: { item_id: any }) => {
    const noting = this.notesheetList.filter(
      (x) => x.notesheetid == y.item_id
    );
    if (noting.length > 0) notsheet.push(noting[0]);
  });
  this.printList = notsheet

  setTimeout(() => {
    this.printAll('printArea');
  }, 300);

}

viewListEvent(viewlist) {
  this.viewList = viewlist  
}

isViwer(event) {
  // this.deletePdfTempPath() // for delete previous temp file; 
    this.DocumentViewLoaded = Promise.resolve(false);
    this.hideFileAttachmnet();
    this.showImage(event)
}

showImage(id){
  this.imageObject=[]; 
  this.imagepath=[];
    let currentViewList=this.viewList.filter(d=>d.id == id)
    if(currentViewList.length>0){
      this.attachmentTypeCumFileName=currentViewList[0]["attachment_desc"];
      let arr=currentViewList[0]["file_name"].split(".");
      this.fileExt=arr[arr.length-1];
      this.filepath=currentViewList[0]["file_path"]+"/"+currentViewList[0]["file_name"];
      this.imagepath.push(this.imageBasePath+currentViewList[0]["file_path"]+"/"+currentViewList[0]["file_name"]);      
      if(this.fileExt =="pdf"){
        this.flag = false;
      }else{
        this.flag = true;
      }
      this.DocumentViewLoaded = Promise.resolve(true);      
    }
}

hideFileAttachmnet() {
  $("#viewformdetail").hide();
  $("#addattr").hide();
  $("#fileViwer").show();
  $("#addnote").hide();
  $("#parkedto").hide();
  this.showImage($("#ddlDocumentView").val())
  $("#divproposedapprove").hide();
  
}

printAllClick(){
  var printContents = document.getElementById('printAreaAll').innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  window.location.reload();
}

onItemSelect(e){}
OnItemDeSelect(e){}
onSelectAll(e){}

getamount(e){
  let dt=e?e:0;
  if(dt !=0){
    let arr=dt.split("&")
    this.approvedAmount=arr[0]?arr[0]:0;
    this.sanctionAmount=this.approvedAmount;
    this.getSanctionOrderList();
    if(arr.length>1){
      this.proposalDate=arr[1];
    }else{
      this.proposalDate="";
    }
  }
 
}

getheadofaccount(e){  
  let arr=e;
  this.lstHeadofAccount=arr;
  this.lastHeadofAccount=arr[0];
  this.headofaccount=arr[0]["hoa_details"]?arr[0]["hoa_details"] :"";  
}

getApprovalAuthority(e){
  this.isApplicableForApprove=e;
}

getSaction_OrderList(e){  
  this.isApplicableForSanctionOrder=true;  
  if(this.isDfpRule == true){      
    this.viewStatus = true;
    this.sessionUrlPrams.vid=343;
    this.FormViewLoaded = Promise.resolve(false);
    this.isLoader=true;
    setTimeout(() => {
      this.FormViewLoaded = Promise.resolve(true);
      this.isLoader=false;
    }, 500);
  }
  this.getSanctionOrderList();
}



generatesantionOrder(){
  let approvedamount=0,approvedamountintext="";    
  for(let b=0; b<this.sanctionOrderList.length; b++){
    let amt=this.sanctionOrderList[b]["sanction_amount"]?this.sanctionOrderList[b]["sanction_amount"]:"0";
    approvedamount=Number(approvedamount)+Number(amt)
  }
  approvedamountintext=this.formService.convertAmountIntoWords(approvedamount);
  let order_type="";
  let sdt=this.sanctionOrderList[0]["sanction_date"]?this.sanctionOrderList[0]["sanction_date"]:"";
  let dtArr=sdt.split("-");
  let final_dt=dtArr[2].toString().substring(0,2)+"-"+dtArr[1]+"-"+dtArr[0]
  let current_fy=this.sanctionOrderList[0]["sanction_fy"]?this.sanctionOrderList[0]["sanction_fy"]:"";
  let sanctionOrderNo= this.sanctionOrderList[0]["sanction_no"]?this.sanctionOrderList[0]["sanction_no"]:"";  
  let sanctionid=this.sanctionOrderList[0]["sanction_id"]?this.sanctionOrderList[0]["sanction_id"]:"";
  let draftFileNo= this.draft_requestid?this.draft_requestid:"";
  let schemeName=this.sanctionOrderList[0]["scheme_title"]?this.sanctionOrderList[0]["scheme_title"]:"";;
  let headofaccount=this.headofaccount?this.headofaccount:"";
  let proposaldate=this.proposalDate?this.proposalDate:"";
  let sanction_dept=this.sanction_order_department_name;
  let momno=this.sanction_momno;
  let copyto= this.sanctionOrderList[0]["copy_to"]?this.sanctionOrderList[0]["copy_to"]:"";
  let sanction_date= final_dt;
  let ofc_name=this.notesheetList[0]["office_name"]?this.notesheetList[0]["office_name"]:"";
  let delegationrule=this.notesheetList[0]["delegationrule"]?this.notesheetList[0]["delegationrule"]:"";
  let isDfpRule=this.notesheetList[0]["is_dfp_rule"]?this.notesheetList[0]["is_dfp_rule"]:false;
  let head_description="";//this.lastHeadofAccount["grant_head_desc"]+", "+this.lastHeadofAccount["major_head_desc"]+", "+this.lastHeadofAccount["sub_major_head_desc"]+", "+this.lastHeadofAccount["minor_head_desc"]+", "+this.lastHeadofAccount["sub_head_desc"]+", "+this.lastHeadofAccount["sub_sub_head_desc"]+", "+this.lastHeadofAccount["flag_desc"]+", "+this.lastHeadofAccount["voted_id"]+", "+this.lastHeadofAccount["object_head_desc"];
  //let var_major="",var_submajor="",var_minnor="",var_subhead="",var_subsubhead="",var_object="";
  for(let b=0; b<this.lstHeadofAccount.length; b++){
    head_description=head_description+' "'+this.lstHeadofAccount[b]["hoa_details"] +'" ('+this.lstHeadofAccount[b]["grant_head_desc"]+", "+this.lstHeadofAccount[b]["major_head_desc"]+", "+this.lstHeadofAccount[b]["sub_major_head_desc"]+", "+this.lstHeadofAccount[b]["minor_head_desc"]+", "+this.lstHeadofAccount[b]["sub_head_desc"]+", "+this.lstHeadofAccount[b]["sub_sub_head_desc"]+", "+this.lstHeadofAccount[b]["flag_desc"]+", "+this.lstHeadofAccount[b]["voted_id"]+", "+this.lstHeadofAccount[b]["object_head_desc"]+")";
  }
  let arr_finance=this.notesheetList.filter(d=>(d.department_decision == 1 || d.department_decision == 2) && (d.department_id == "18" ))
  let arr_planning=this.notesheetList.filter(d=>(d.department_decision == 1 || d.department_decision == 2) && ( d.department_id == "17"))
  let planning_vidno="",finance_vidno="" ,filename="",dept1="", dept2="",date_of_esc="";
  if(isDfpRule == true){
    filename="../../assets/files/Sanction-Order_DFP.docx"    
    order_type="DFP";
  }else if(arr_finance.length>0 && arr_planning.length>0){
    filename="../../assets/files/sanction-order-pln-finance.docx"
    planning_vidno=arr_planning[0]["notesheet_endorsee"];
    finance_vidno=arr_finance[0]["notesheet_endorsee"];
    dept1=arr_planning[0]["department_name"]
    dept2=arr_finance[0]["department_name"]
    order_type="PF";
  }else if(arr_planning.length>0){    
    planning_vidno=arr_planning[0]["notesheet_endorsee"];
    dept1=arr_planning[0]["department_name"]
    filename="../../assets/files/sanction-order.docx";
    let arr=planning_vidno.split("dated");
    if(arr.length>1){
      date_of_esc=arr[1];
    }
    order_type="P";
  }else if(arr_finance.length>0){
    finance_vidno=arr_finance[0]["notesheet_endorsee"];
    dept2=arr_finance[0]["department_name"]
    filename="../../assets/files/sanction-order-fin.docx";
    order_type="F";
  }

  let data={     
    "currentdate": sanction_date,
    "department_name": localStorage.getItem("department_name")?localStorage.getItem("department_name"):"",
    "user_name":localStorage.getItem("userFullName")?localStorage.getItem("userFullName"):"",
    "designation_name": localStorage.getItem("designation_name")?localStorage.getItem("designation_name"):"",
    "hod_designation":localStorage.getItem("hod_designation")?localStorage.getItem("hod_designation"):"",
    "draftrequestid":draftFileNo,
    "sanctionorderno":sanctionOrderNo,
    "sanctionid":sanctionid,
    "schemename": schemeName,
    "approvedamount": approvedamount,
    "headofaccount":"",
    "head_description": head_description,
    "proposaldate" : proposaldate,
    "office_name" :localStorage.getItem("office_name"),// ofc_name,
    "momno":momno,
    "copyto": copyto,
    "current_fy":current_fy,
    "dept1":dept1,
    "planning_vidno":planning_vidno,
    "dept2":dept2,
    "finance_vidno":finance_vidno,
    "approvedamountintext":approvedamountintext,
    "date_of_esc":date_of_esc,
    "delegationrule":delegationrule
  } 
  
  let qrySanctionJsonUpdate=`update z_entitymast_raw_download_json set sanction_order_json='${JSON.stringify(data)}', order_type='${order_type}' where form_id=${this.formId} and item_id=${this.editrowid};`
  this.formService.getFormData(qrySanctionJsonUpdate).subscribe(res => {
    this.getdownloadJsonList();
  });

  this.loadFile(filename, function (error, content) {
    if (error) { throw error };
    const zip = new JSZip(content);
    const doc = new Docxtemplater().loadZip(zip)
   

    doc.setData(data);
    try {
      doc.render()
    }
    catch (error) {
      var e = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        properties: error.properties,
      }
      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors.map(function (error) { return error.properties.explanation; }).join("\n");
        // console.log('errorMessages', errorMessages);
      }
      throw error;
    }
    var out = doc.getZip().generate({ type: "blob", mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" })
    saveAs(out, "sanction-order.docx")
  })
  
// if(order_type == "PF"){ // for planning and finance
//     this.pdfData = {
//     pageSize: 'legal',
//     // by default we use portrait, you can change it to landscape if you wish
//     pageOrientation: 'portrait',
//     // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
//     pageMargins: [30, 30],
//     content: [      
//       { text: `Government of Meghalaya\n ${localStorage.getItem("department_name").toUpperCase()}\n`, alignment: 'center', bold: true, uppercase: true, fontSize: 12 },
//       {text:"_____________________________________________________________________________________________________",alignment:'center'},
//       {
//         table: {
//           widths: ["*","*","*"],
//           body: [[{text:"No:"+`${sanctionOrderNo}`},{text:"Sanction ID:"+`${sanctionid}`,alignment:'center'},{text:"Dated:"+`${sanction_date}\n\n`,alignment:'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"From :"},{text:`${localStorage.getItem("userFullName")}`+"\n"+"  "+localStorage.getItem("designation_name")+"\n"+"  "+localStorage.getItem("department_name")+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"To :"},{text:" The "+`${localStorage.getItem("office_name")}`+"\n"+"  "+"Meghalaya Shillong"+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"Sub :"},{text:" Sanction for implementation of "+`${schemeName}`+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         text:"Sir,\n"
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t I am directed to convey the sanction of the Governor of Meghalaya for an amount not exceeding Rs.${approvedamount} (Rupees${approvedamountintext}). Only for Implementation of ${schemeName}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The relevant rules and procedures laid down for incurring the expenditure and drawing and disbursement of the money should be strictly adhered to.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\tThe expenditure is debitable to the Head of Account “${head_description}”during the year ${current_fy}..\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\tThe sanction is issued with the concurrence and approval of Planning Department duly communicated videPlanning Department Memo No: ${planning_vidno} and concurrence of Finance Department vide Finance videMemo No:${finance_vidno}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`Your faithfully.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - A`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	Finance (EC-II) Department for Information\n2.	Planning Department for Information\n3.	Finance (B) Department\n4.	Treasury Officer\n5.	Guard file\n6.	Office Copy\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - B`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	The Accountant General (Audit)\n2.	The Accountant General (Accounts)\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`Financial Adviser,\n${localStorage.getItem("department_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//     ],
//   }
// }else if(order_type == "P"){ // for planning
//   this.pdfData = {
//     pageSize: 'legal',
//     // by default we use portrait, you can change it to landscape if you wish
//     pageOrientation: 'portrait',
//     // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
//     pageMargins: [30, 30],
//     content: [      
//       { text: `Government of Meghalaya\n ${localStorage.getItem("department_name").toUpperCase()}\n`, alignment: 'center', bold: true, uppercase: true, fontSize: 12 },
//       {text:"_____________________________________________________________________________________________________",alignment:'center'},
//       {
//         table: {
//           widths: ["*","*","*"],
//           body: [[{text:"No:"+`${sanctionOrderNo}`},{text:"Sanction ID:"+`${sanctionid}`,alignment:'center'},{text:"Dated:"+`${sanction_date}\n\n`,alignment:'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"From :"},{text:`${localStorage.getItem("userFullName")}`+"\n"+"  "+localStorage.getItem("designation_name")+"\n"+"  "+localStorage.getItem("department_name")+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"To :"},{text:" The "+`${localStorage.getItem("office_name")}`+"\n"+"  "+"Meghalaya Shillong"+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"Sub :"},{text:" Sanction for implementation of "+`${schemeName}`+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         text:"Sir,\n"
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t I am directed to convey the sanction of the Governor of Meghalaya for an amount not exceeding Rs.${approvedamount} (Rupees${approvedamountintext}). Only for Implementation of ${schemeName}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The relevant rules and procedures laid down for incurring the expenditure and drawing and disbursement of the money should be strictly adhered to.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\tThe expenditure is debitable to the Head of Account “${head_description}”during the year ${current_fy}..\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\tThe sanction is issued with the concurrence and approval of Empowered Sanction Committee held on ${date_of_esc}and communicated vide Planning Department Memo No:${planning_vidno}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`Your faithfully.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - A`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	Finance (EC-II) Department for Information\n2.	Planning Department for Information\n3.	Finance (B) Department\n4.	Treasury Officer\n5.	Guard file\n6.	Office Copy\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - B`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	The Accountant General (Audit)\n2.	The Accountant General (Accounts)\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`Financial Adviser,\n${localStorage.getItem("department_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//     ],
//   }
// }else if(order_type == "F"){ // for Finance
//   this.pdfData = {
//     pageSize: 'legal',
//     // by default we use portrait, you can change it to landscape if you wish
//     pageOrientation: 'portrait',
//     // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
//     pageMargins: [30, 30],
//     content: [      
//       { text: `Government of Meghalaya\n ${localStorage.getItem("department_name").toUpperCase()}\n`, alignment: 'center', bold: true, uppercase: true, fontSize: 12 },
//       {text:"_____________________________________________________________________________________________________",alignment:'center'},
//       {
//         table: {
//           widths: ["*","*","*"],
//           body: [[{text:"No:"+`${sanctionOrderNo}`},{text:"Sanction ID:"+`${sanctionid}`,alignment:'center'},{text:"Dated:"+`${sanction_date}\n\n`,alignment:'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"From :"},{text:`${localStorage.getItem("userFullName")}`+"\n"+"  "+localStorage.getItem("designation_name")+"\n"+"  "+localStorage.getItem("department_name")+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"To :"},{text:" The "+`${localStorage.getItem("office_name")}`+"\n"+"  "+"Meghalaya Shillong"+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"Sub :"},{text:" Sanction for implementation of "+`${schemeName}`+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         text:"Sir,\n"
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t I am directed to convey the sanction of the Governor of Meghalaya for an amount not exceeding Rs.${approvedamount} (Rupees${approvedamountintext}). Only for Implementation of ${schemeName}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The relevant rules and procedures laid down for incurring the expenditure and drawing and disbursement of the money should be strictly adhered to.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The expenditure is debitable to the Head of Account “${head_description}”during the year ${current_fy}..\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The sanction is issued with the concurrence and approval of Finance Department vide Finance Memo vid No: ${finance_vidno}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`Your faithfully.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - A`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	Finance (EC-II) Department for Information\n2.	Finance (B) Department\n3.	Treasury Officer\n4.	Guard file\n5.	Office Copy\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - B`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	The Accountant General (Audit)\n2.	The Accountant General (Accounts)\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`Financial Adviser,\n${localStorage.getItem("department_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//     ],
//   }
// }else if(order_type == "DFP"){ // for DFP
//   this.pdfData = {
//     pageSize: 'legal',
//     // by default we use portrait, you can change it to landscape if you wish
//     pageOrientation: 'portrait',
//     // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
//     pageMargins: [30, 30],
//     content: [      
//       { text: `Government of Meghalaya\n ${localStorage.getItem("department_name").toUpperCase()}\n`, alignment: 'center', bold: true, uppercase: true, fontSize: 12 },
//       {text:"_____________________________________________________________________________________________________",alignment:'center'},
//       {
//         table: {
//           widths: ["*","*","*"],
//           body: [[{text:"No:"+`${sanctionOrderNo}`},{text:"Sanction ID:"+`${sanctionid}`,alignment:'center'},{text:"Dated:"+`${sanction_date}\n\n`,alignment:'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"From :"},{text:`${localStorage.getItem("userFullName")}`+"\n"+"  "+localStorage.getItem("designation_name")+"\n"+"  "+localStorage.getItem("department_name")+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"To :"},{text:"The "+`${localStorage.getItem("office_name")}`+"\n"+"  "+"Meghalaya Shillong"+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [50,"*"],
//           body: [[{text:"Sub :"},{text:"Sanction for implementation of "+`${schemeName}`+"\n\n"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         text:"Sir,\n"
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t I am directed to convey the sanction of the Governor of Meghalaya for an amount not exceeding Rs.${approvedamount} (Rupees${approvedamountintext}). Only for Implementation of ${schemeName}.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The relevant rules and procedures laid down for incurring the expenditure and drawing and disbursement of the money should be strictly adhered to.\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The expenditure is debitable to the Head of Account “${head_description}”during the year ${current_fy}..\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: [10,"*"],
//           body: [[{text:""},{text:`\u200B\t\t\t\t The sanction is issuedunder the delegated financial power under ${delegationrule}of the Meghalaya Delegation of Financial Power Rule, 2006\n\n`,alignment:'justify'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`Your faithfully.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - A`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	Finance (EC-II) Department for Information\n2.	Finance (B) Department\n3.	Treasury Officer\n4.	Guard file\n5.	Office Copy\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`${localStorage.getItem("designation_name")} of Govt. of Meghalaya\n${localStorage.getItem("office_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*","*"],
//           body: [[{text:`Memo No.${draftFileNo} - B`,alignment:"left"},{text:`Dated Shillong the ${sanction_date}.\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: { 
//           widths: ["*"],
//           body: [[{text:`Copy forwarded to:\n1.	The Accountant General (Audit)\n2.	The Accountant General (Accounts)\n\n`,alignment:"left"}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//       {
//         table: {
//           widths: ["*",300],
//           body: [[{text:""},{text:`By order etc.\n\n`+`Financial Adviser,\n${localStorage.getItem("department_name")}\n\n`,alignment: 'right'}]],
//         }, fontSize: 10, layout: 'noBorders'
//       },
//     ],
//   }
// }
//   var action = 'download';
//   const documentDefinition = this.pdfData;
//   switch (action) {
//     case 'open': pdfMake.createPdf(documentDefinition).open(); break;
//     case 'print': pdfMake.createPdf(documentDefinition).print(); break;
//     case 'download': pdfMake.createPdf(documentDefinition).download('sanction-order.pdf'); break;
//     default: pdfMake.createPdf(documentDefinition).open(); break;
//   }
}

loadFile(url, callback) {
  JSZipUtils.getBinaryContent(url, callback);
}

approve(){
  // this.actiontakenid=2;
  // this.isApprovedRejectStatus=true;    
  // this.nextStageID();

  let fileList=this.viewList.filter(d=>d.worklist_id == this.worklistid && d.attachment_type == "Final Sanction Order")
  if(fileList.length==0){
    this.toastrService.warning("Please Attach Final Sanction Order",'',{timeOut: 3000,positionClass : "toast-top-center"});
    return false;
  }else{
    let sqlQueryEdit = `UPDATE  radx_draft_requests SET status_id=105 where id=`+this.draft_id;  
    this.formService.getFormData(sqlQueryEdit).subscribe(res => {
      this.router.navigateByUrl('/inbox?'+localStorage.getItem('tempToken')); 
    });
  }
 
       
 
}

reject(){    
  this.actiontakenid=3;    
  this.isApprovedRejectStatus=true;    
  this.nextStageID();    
}

parked(){
  $("#viewformdetail").hide();
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#corress").hide();
  $("#addfile").hide();
  $("#addnote").hide();
  $("#sendto").hide();
  $("#fileViwer").hide();
  $("#copyto").hide(); 
  $("#parkedto").show();    
}
ShowsanctionDownload(){
  $("#viewformdetail").hide();
  $("#divproposedapprove").hide();
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#corress").hide();
  $("#addfile").hide();
  $("#addnote").hide();
  $("#sendto").hide();
  $("#fileViwer").hide();
  $("#parkedto").hide(); 
  $("#copyto").show();    
}
parkedClosed(){
  this.viewformdetail();
}

actionParked(){
  var date = new Date();  
  let month=date.getMonth() + 1;
  let m,d;  
  if(month<10){ 
    m="0"+month 
  }else{m=month}
  if(date.getDate()<10){d="0"+date.getDate()}else{d=date.getDate()}
  let year=date.getFullYear();  
  let todayDate=year+"-"+m+"-"+d;  


  let parkedtill = $("#parkedtill").val();
  if(parkedtill =="" || parkedtill == null || parkedtill == undefined){
    this.toastrService.warning("Parked till Date is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
  }else if(this.parkedRemarks == "" || this.parkedRemarks == null || this.parkedRemarks == undefined){
    this.toastrService.warning("Parked Remarks is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
  }else{     
    let sqlQueryEdit = `update radx_worklist set parkedtimestamp='${todayDate}', parkedtill='${parkedtill}', parked_remarks='${this.parkedRemarks}',isparked='Y', parked_status=1 where worklistid=`+this.worklistid;
    this.formService.getFormData(sqlQueryEdit).subscribe(res => {
      this.toastrService.success("File Parked Successfully...",'',{timeOut: 3000,positionClass : "toast-top-center"});
      this.router.navigateByUrl('/inbox');  
    });
  }
  
}

updateApprovedRejectStatus(){ 
  let statusid=0;
   if(this.actiontakenid == 2){
    statusid=105;
   }else if(this.actiontakenid == 3){
    statusid=106;
   }else{
    statusid=107;
   }
  let sqlQueryEdit = `UPDATE  radx_draft_requests SET status_id=${statusid} where id=`+this.draft_id;
  // let sqlQueryEdit = `UPDATE  radx_draft_requests SET approved_reject_status=${this.actiontakenid} where id=`+this.draft_id;
  this.formService.getFormData(sqlQueryEdit).subscribe(res => {
    // console.log("updated.")
  });
}

getSanctionOrderDetail(){
let sanctionQuery=`select dept.department_name, hoa.hoa_details, sys.varibale_name as funding_category, rd.id, rd.draft_requestid, rd.scheme_title, rd.estimated_cost, rd.approved_cost  from radx_draft_requests rd
left join app_tblsystemenum sys on sys.id=rd.funding_category_id 
left join z_entitymast_cnw_head_of_accounts_001 hoa on hoa.construction_schemenew_work_id = rd.item_id
left join z_entitymast_department_001 dept on dept.department_id = rd.department_id
where rd.id=${this.draft_id}`
this.formService.getFormData(sanctionQuery).subscribe(res => {
  let result=res.rows;
  if(result.length>0){
    this.pdf_EstimatedAmount = result[0]["estimated_cost"]?result[0]["estimated_cost"]:"";
    this.pdf_schemeTitle = result[0]["scheme_title"]?result[0]["scheme_title"]:"";
    this.pdf_hoa = result[0]["hoa_details"]?result[0]["hoa_details"]:"";
    // console.log("sanction Details==>",JSON.stringify(result))
  }
});
}
showproposed(){
  this.colappsout();
  $("#viewformdetail").hide();
  $("#addattr").hide();
  $("#bascidetail").hide();
  $("#corress").hide();
  $("#addfile").hide();
  $("#addnote").hide();
  $("#sendto").hide();
  $("#fileViwer").hide();
  $("#parkedto").hide(); 
  $("#divproposedapprove").show();
}
proposedClosed(){
this.viewformdetail();
}

changeRecipientType(){
this.actiontakenid=undefined;
this.userList=[];
this.userId="";
this.departmentid=undefined;
this.officeList=[];
this.officeid=undefined;

  if(this.recipienttype == "Internal"){    
    setTimeout(() => {this.setActionTakenDefault();}, 10);
  }else{
   
    
  }
}
changeOfficeList(){
this.actiontakenid=undefined;
this.userList=[];
this.userId="";
setTimeout(() => {this.setActionTakenDefault();}, 10);
}
getDepartmentList(){
  this.departmentList=[]; 
  let sqlQuery = `select department_id,department_code,department_name  from z_entitymast_department_001 where isactive=true`;
  this.formService.getFormData(sqlQuery).subscribe(res => {     
    this.departmentList=res.rows;    
  });
}

filterUserList(){
    if(this.recipienttype == "Internal"){
      this.userId="";
     let chkboxStatus= $("#assigntocommittee").is(':checked')
      if(chkboxStatus == true){
        this.userList=this.tempUserList.filter(d=>d.role_id == 14 || d.role_id == 33) 
        if(this.userList.length>0){
          this.userId=this.userList[0]["id"];
        }       
      }else{
        this.userList=this.tempUserList;
      }
    }
}

chkApprovedlolAmount(){
  let chkboxStatus= $("#approvedlol").is(':checked')
      if(chkboxStatus == true){
       this.islolApproved=true   
      }else{
        this.islolApproved=false;        
      }
}

getOfficeListByDepartment(){  
this.officeList=[];
this.officeid=undefined;
this.actiontakenid=undefined;
this.userList=[];
this.userId="";
if(this.departmentid != undefined && this.departmentid != null && this.departmentid != ""){
  let sqlQuery = `select offices_id, office_type,office_level,office_code,office_name,coalesce(reporting_office,0) reporting_office
  from z_entitymast_manage_offices_001 where department_id=`+this.departmentid +" and isactive=true order by reporting_office asc";
  // console.log("officeList==>",sqlQuery)  
  this.formService.getFormData(sqlQuery).subscribe(res => {
    this.officeList=res.rows;      
    let hqOffice=this.officeList.filter(d=>d.reporting_office == 0)    
    this.officeList=this.officeList.filter(d=>d.offices_id != this.sessionUrlPrams.office)    
    if(this.officeList.length>0){      
      this.officeid=this.officeList[0]["offices_id"];
      setTimeout(() => {this.setActionTakenDefault();}, 100);
    }
  });
}  
}

getExternalUserList(){
// console.log(this.recipienttype)
// console.log(this.officeid)
if(this.recipienttype == "External" && this.officeid != undefined){
  let sqlQuery = `select up.role_id, up.userprofile_id as id,concat(se.varibale_name,' ',ur.user_fname,' ',ur.user_lname,'(',desg.designation_name,')') 
  as username  from app_tbluserprofile up  left join app_tbluser ur on up.user_id=ur.user_id 
  left join app_tblsystemenum se on se.id=ur.user_prifix 
  left join z_entitymast_designation_001 desg on desg.designation_id=up.designation_id
  left join app_tblrole r on r.role_id=up.role_id where up.department_id=${this.departmentid} and r.role_id not in(3,14,33) and up.office_id=${this.officeid}`;
  // console.log("user list==>",sqlQuery)  
  this.formService.getFormData(sqlQuery).subscribe(res => { 
    this.userId="";
        this.userList=res.rows; 
       if(this.routing_type =="rule"){
          this.userList.forEach(item =>{
            if(this.userId ==""){
              this.userId=item.id
            }else{
              this.userId=this.userId+","+item.id
            }
          });
        }else{
          // let uList=this.userList.filter(d=> d.role_id == 5);
          // if(uList.length>0){
          //   this.userId=uList[0]["id"];
          // }
        }
  });
}
}

ministerApprovalUpdate(){
  let sqlQuery="";
 if(this.ministerapproval == 156){
  sqlQuery='update radx_draft_requests set minister_approval=true where  id='+this.draft_id;   
 }else if(this.ministerapproval == 157){
  sqlQuery='update radx_draft_requests set minister_approval=false where  id='+this.draft_id;  
 }else{
  sqlQuery='update radx_draft_requests set minister_approval=null where  id='+this.draft_id; 
 }
    this.formService.getFormData(sqlQuery).subscribe(res => { 
      
    });
    
  }

sendlolAmount(){

  }
getSanctionOrderList(){
  let updateDraftQuery=` select * from  z_entitymast_scheme_sancation_order_001  where form_id=${this.formId} and item_id=${this.editrowid}`
    this.formService.getFormData(updateDraftQuery).subscribe(response => { 
      this.sanctionOrderList=response.rows;     
      if(this.sanctionOrderList.length>0){        
        // this.sanctionAmount=this.sanctionOrderList[0]["sanction_amount"];
        // this.lolamount=this.sanctionOrderList[0]["release_amount"];
        this.sanction_order_department_name =this.sanctionOrderList[0]["department_name"]?this.sanctionOrderList[0]["department_name"]:"";
        // this.sanction_momno = this.sanctionOrderList[0]["mom_number"]?this.sanctionOrderList[0]["mom_number"]:"";
        let approvallst=this.sanctionOrderList.filter(d=>d.beam_status == true)
        if(approvallst.length>0){          
          this.isApproved=true;
          this.viewStatus=true;
        }
      }    

    });
}

createsantionOrder(){
    if(this.copytoRemarks == "" || this.copytoRemarks == undefined || this.copytoRemarks == null){
      this.toastrService.error("Copy to is required",'',{timeOut: 3000,positionClass : "toast-top-center"});
      return false;
    }
    let remContent=this.copytoRemarks?this.copytoRemarks:""; 
    let firstYear1=this.sanctionOrderList[0]["year_one"].toString();
    let secondYear1=this.sanctionOrderList[0]["year_two"].toString();   
    let running_max_value=1,runningQuery;
    let chkQuery1=`select max_value from z_entitymast_running_number where year1='${firstYear1}' and year2='${secondYear1}'`;
    this.formService.getFormData(chkQuery1).subscribe(res => { 
      if(res.rows.length>0){
        running_max_value=Number(res.rows[0]["max_value"])+1;
        runningQuery=`update z_entitymast_running_number set max_value=${running_max_value} where  year1='${firstYear1}' and year2='${secondYear1}'`
      }else{
        runningQuery=`insert into z_entitymast_running_number(year1,year2,max_value)values(${firstYear1},${secondYear1},${running_max_value})`;
      }
      if(running_max_value<10){
        this.running_txt_val="00"+running_max_value;
      }else if(running_max_value<100){
        this.running_txt_val="0"+running_max_value;
      }else{
        this.running_txt_val=running_max_value;
      }   
      let mdt=new Date().toDateString();
      let marrDt=mdt.split(" ");
      let mfinalDt="dated "+marrDt[2]+" "+marrDt[1]+", "+marrDt[3];      
      this.mom_with_number=localStorage.getItem("department_code_en")+"/"+this.running_txt_val+"/"+firstYear1+"-"+secondYear1.toString().substr(2,2)+", "+mfinalDt;
      // console.log(this.mom_with_number)
      this.formService.getFormData(runningQuery).subscribe(res => {
      this.isLoader=true;
      $("#spnBeam").show();
      $('#spnBeam').css({'color':'red'});
      // save lolamount object_code

      let scheme_code=this.sanctionOrderList[0]["scheme_code"]?this.sanctionOrderList[0]["scheme_code"]:"";
      //let object_head=this.lstHeadofAccount[0]["object_head_code"]?this.lstHeadofAccount[0]["object_head_code"]:"";
      let dept_code=this.lstHeadofAccount[0]["department_code"]?this.lstHeadofAccount[0]["department_code"]:"";
      //let sanction_amount=this.sanctionAmount?this.sanctionAmount:0;
      //let release_amount=this.lolamount?this.lolamount:"";
      //console.log("sanctionOrderList==>",this.sanctionOrderList);
      let sanc_array=[];
      for(var i=0;i<this.sanctionOrderList.length;i++){
        var objJsonHeadWiseDetails={};
        objJsonHeadWiseDetails["object_head"]=this.sanctionOrderList[i]["object_head"];
        objJsonHeadWiseDetails["sanction_amount"]=this.sanctionOrderList[i]["sanction_amount"];
        objJsonHeadWiseDetails["release_amount"]=this.sanctionOrderList[i]["release_amount"];
        sanc_array.push(objJsonHeadWiseDetails);
      }
      let sanction_date="",sanctionDate="";
      if(this.proposalDate != "" && this.proposalDate != "" && this.proposalDate != null){
        let dt=this.proposalDate.split("-");
        let d,m,y;
        d=dt[0];m=dt[1];y=dt[2];
        if(Number(d)<10){d="0"+Number(d)}
        if(Number(m)<10){m="0"+Number(m)}
        sanction_date=y+"-"+m+"-"+d;
        sanctionDate=d+m+y;    
      }   
      let dt= new Date();
      let firstYear,secondYear,month;
      month=(dt.getMonth()+1);
      let fnyear=getCurrentFinancialYear();
      firstYear=fnyear["year1"].toString();
      secondYear=fnyear["year2"].toString();        
      let deptCodeEn=this.dept_code;
      //deptCodeEn=deptCodeEn?deptCodeEn:"";
     
      let saveLolQuery="";
      let chkQuery=`select item_id from z_entitymast_scheme_sancation_order_001 where form_id=${this.formId} and item_id=${this.editrowid}`;
      this.formService.getFormData(chkQuery).subscribe(res => { 
        // json for post sanction
        let sanction_no=deptCodeEn+"/"+this.running_txt_val+"/"+sanctionDate+"/"+this.lastHeadofAccount["major_code"]+"/"+this.lastHeadofAccount["sub_head_code"]
        let json={"year1":firstYear,"year2":secondYear,"scheme_code":scheme_code,"dept_code":dept_code,"sanction_date":sanction_date,"sanction_id":this.processinstid?this.processinstid:1,"sanction_no":sanction_no,
          "json_sanc_array":sanc_array,"smsremarks":remContent };      
          saveLolQuery=`update z_entitymast_scheme_sancation_order_001 set sanction_no='${sanction_no}', mom_number='${this.mom_with_number}' , copy_to='${this.copytoRemarks}'  , sanction_date='${sanction_date}'  where form_id=${this.formId} and item_id=${this.editrowid}`
          // console.log(JSON.stringify(json)) 
          this.formService.pushSantionDetail(json).subscribe(response => {            
            this.isLoader=false;
            //console.log("pushSantionDetail response==>",response)
            if(response["status"] == "success"){  
              let resData=JSON.parse(response["data"])[0];
              console.log("pushSantionDetail resData==>",resData)
              if(resData.status_code.toString().trim() =="00"){
                let updateDraftQuery=`update radx_draft_requests set is_sanction_order_generated=true where id=${this.draft_id}; update z_entitymast_scheme_sancation_order_001 set beam_status=true where form_id=${this.formId} and item_id=${this.editrowid}`
                let finalQuery=saveLolQuery+";"+updateDraftQuery
                this.formService.getFormData(finalQuery).subscribe(response => { 
                });
                $("#spnforward").show();
                $('#spnforward').css({'color':'red'});
                setTimeout(() => {$("#spnforward").hide();}, 2000);
                this.toastrService.success("Sanction Order generated",'',{timeOut: 3000,positionClass : "toast-top-center"});
                this.isApproved=true;            
                this.getSanctionOrderList();
              }else if(resData.status_code.toString().trim() =="XX"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }else if(resData.status_code.toString().trim() =="01"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }else if(resData.status_code.toString().trim() =="02"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }else if(resData.status_code.toString().trim() =="03"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }else if(resData.status_code.toString().trim() =="04"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }else if(resData.status_code.toString().trim() =="05"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }else if(resData.status_code.toString().trim() =="07"){
                this.isLoader=false;
                this.toastrService.error(resData.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
              }   
              // insert beam history
              let instQuery=`insert into z_entitydtl_sanction_order_beam_history(scheme_code,beam_json,status_code,status_msg,createdby)values('${scheme_code}','${JSON.stringify(json)}','${resData.status_code.toString()}','${resData.status_msg}',${this.sessionUrlPrams.UserId})`
              this.formService.getFormData(instQuery).subscribe(beamres => { 
              });
            }         
          },error => {             
            this.isLoader=false;
            let instQuery=`insert into z_entitydtl_sanction_order_beam_history(scheme_code,beam_json,status_code,status_msg,createdby)values('${scheme_code}','${JSON.stringify(json)}','${(error.error.status_code.toString())?error.error.status_code.toString():''}','${(error.error.status_msg)?(error.error.status_msg):""}',${this.sessionUrlPrams.UserId})`
            this.formService.getFormData(instQuery).subscribe(beamres => { 
              });
            this.toastrService.error(error.error.status_msg,'',{timeOut: 3000,positionClass : "toast-top-center"});
          });       
      });
    });
    //setTimeout(() => {this.isLoader=false;}, 7000);
  });
}

getdownloadJsonList(){
  this.downloadJsonList=[];
  let qry=`select id, form_id, item_id, request_id from z_entitymast_raw_download_json where form_id=${this.formId} and item_id=${this.editrowid}`
  this.formService.getFormData(qry).subscribe(res=>{
    this.downloadJsonList=res.rows;
  });
}

getRunningNumber(){
      let fnyear1=getCurrentFinancialYear();
      let firstYear1=fnyear1["year1"].toString();
      let secondYear1=fnyear1["year2"].toString();   
      let running_max_value=1,runningQuery;
      let chkQuery1=`select max_value from z_entitymast_running_number where year1='${firstYear1}' and year2='${secondYear1}'`;
      this.formService.getFormData(chkQuery1).subscribe(res => { 
        if(res.rows.length>0){
          running_max_value=Number(res.rows[0]["max_value"])+1;
          runningQuery=`update z_entitymast_running_number set max_value=${running_max_value} where  year1='${firstYear1}' and year2='${secondYear1}'`
        }else{
          runningQuery=`insert into z_entitymast_running_number(year1,year2,max_value)values(${firstYear1},${secondYear1},${running_max_value})`;
        }
        if(running_max_value<10){
          this.running_txt_val="00"+running_max_value;
        }else if(running_max_value<100){
          this.running_txt_val="0"+running_max_value;
        }else{
          this.running_txt_val=running_max_value;
        }   
        let mdt=new Date().toDateString();
        let marrDt=mdt.split(" ");
        let mfinalDt="dated "+marrDt[2]+" "+marrDt[1]+", "+marrDt[3];      
        this.mom_with_number=localStorage.getItem("department_code_en")+"/"+this.running_txt_val+"/"+firstYear1+"-"+secondYear1.toString().substr(2,2)+", "+mfinalDt;
        // console.log(this.mom_with_number)
        this.formService.getFormData(runningQuery).subscribe(res => {

        });

    });
}
 

ngOnDestroy() { 
  //this.editor.destroy();
  this.attachmentType = null
  this.subscription.unsubscribe();
  this.formService=null;
  this.frameworkComponents=null
  this.processing=null;
  this.formId=null
  this.processid=null
  this.stageid=null
  this.form_viewid=null
  this.formName=null
  this.tableName=null
  this.tablePrimaryKey=null
  this.SectionConfig=null
  this.formDisplayJSON=null
  this.nodeJson=null
  this.connJson=null
  this.FormViewLoaded=null
  this.SuccessMasgLoaded=null
  this.ErrorMasgLoaded=null
  this.isAddMode=null
  this.is_attachment=null
  this.SuccessMasg=null
  this.editrowid=null
  this.refKey=null
  this.childFormList=null
  this.metaDataList=null
  this.attachmentType=null
  this.metadata=null
 this.edit_option=null
}
}


function getCurrentFinancialYear() {
  var financial_year = "";
  var result = {};
  var today = new Date();
  if ((today.getMonth() + 1) <= 3) {
      financial_year = (today.getFullYear() - 1) + "-" + today.getFullYear();
      result={"year1":(today.getFullYear() - 1),"year2":today.getFullYear(),"fy":financial_year}; 
  } else {
      financial_year = today.getFullYear() + "-" + (today.getFullYear() + 1);
      result={"year1":today.getFullYear(),"year2":(today.getFullYear() + 1),"fy":financial_year};
  }
  return result;
}
