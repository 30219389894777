<app-header> </app-header>
<div class="container-fluid  ">
  <div class="row" id="actprocess" style="display: none"> 
    <div  class="col-lg-7  ml-0 prr-4" id="leftmenunew" *ngIf="FormViewLoaded | async">
    <ul class="nav nav-tabs">
      <li class="active"><a data-toggle="tab" class="active" href="#tab{{formId}}">{{formName}}</a>
      </li>
      <!-- <li *ngFor="let lst of childFormList"><a  data-toggle="tab" href="#tab{{lst.form_id}}">{{lst.form_name}}</a>
      </li> -->
      <li *ngIf="is_attachment == true" ><a   data-toggle="tab" href="#tabAttachment">Attachments</a> 
      </li>
    </ul>
    <div class="tab-content tab-contents pt-0 pr-0 pl-0">
      <div id="tab{{formId}}" class="tab-pane fade in active show">
        <hdsbpm-request-form [section]="SectionConfig" [SessionUrlPrams]="sessionUrlPrams" [tableName]="tableName" [tablePrimaryKey]="tablePrimaryKey"  [rowid]="refKey" [childFormList]="childFormListPage" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode" [stageid]="stageid" [processid]="processid"  [connJson]="connJson" [nodeJson]="nodeJson" [processinstid]="processinstid" [worklistid]="worklistid" (processsubmit)="processsubmit($event)"  (submit)="submit($event)"  [list]="list"></hdsbpm-request-form>
      </div>
      <ng-container >
        <div *ngFor="let lst of childFormListTab; index as i" id="tab{{lst.form_id}}" class="tab-pane fade" >
          <hdsbpm-grid-form [lstsection]="lst" [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form>
        </div>
        <div class="tab-pane fade" id="tabAttachment">
          <app-file-attachment id="add" [rowid]="refKey" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
        </div>
      </ng-container>
      
    </div>
  </div>
  <a class="btn btn-warning btn-sm newfilebtn " href="javascript:void(0)" (click)="showfile1()" style="display: none" title="View File"><i class="fa fa-arrow-left"></i></a>
 
    
     
  <div class="col-lg-5 divToDisable pr-0 " id="rightmenunew" *ngIf="FormViewLoaded | async">
    <a  href="javascript:void(0)" (click)="hidefile1()" class="btn btn-warning btn-sm cstmbtnwarning"><i class="fa fa-arrow-right"></i></a>
        <app-notesheet [processinstid]="processinstid"></app-notesheet>
  </div>
  <!-- <a href="javascript:void(0)"  class="btn btn-sm btn-danger maximi">
      <i class="fa fa-expand" aria-hidden="true"></i>
</a> -->
  </div>
  <div class="row" id="actprocessnew" >
    <div class="col-lg-12 p-0">
        <!-- <a href="javascript:void(0)"  class="btn btn-sm btn-danger minimi">
            <i class="fa fa-compress" aria-hidden="true"></i>
    </a> -->
      <app-act-process-new></app-act-process-new>
    </div>
  </div>
  <label  class="font9 successedit3 alert alert-success pt-0 pb-0" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
		  <label class="font9 erroredit3 alert alert-warning pt-0 pb-0" *ngIf="ErrorMasgLoaded | async">Error occurred !!</label>
  <div *ngIf="!processing" class="d-flex justify-content-center cstmspinner">
    <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- <div *ngIf="SuccessMasgLoaded || ErrorMasgLoaded | async" class="d-flex justify-content-center cstmspinner">
    <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
    </div>
  </div> -->
</div>
 
         
           