<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <section>
      <ngx-loading [show]="isLoader"></ngx-loading>
      <!-- <ngx-loading [show]="isListLoader"></ngx-loading> -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 p-0">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                <li class="breadcrumb-item newitm1">
                  <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                </li>

                <li class="breadcrumb-item newitm2  active" aria-current="page"> Seller Profile </li>
              </ol>
            </nav>
          </div>


          <div class="col-sm-12 pt-3">
            <div class="row">
              <div class="col-lg-3 col-md-4 mb-4">
                <div class="card shadow-none">
                  <div class="card-body p-0">
                    <!-- Tabs navs -->

                    <ul class="nav nav-tabs serviceicon" id="ex1" role="tablist">

                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="ex1-tab-1" data-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">
                          <img src="../../../assets/images/icon/personal_details.svg" alt="">
                          <span class="font12">Personal Details </span>
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="ex1-tab-2" data-toggle="tab" (click)="editClose()" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">

                          <img src="../../../assets/images/icon/business_details.svg" alt="">
                          <span class="font12">
                            Business Details
                          </span>
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="ex1-tab-3" data-toggle="tab" (click)="editClose()" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">

                          <img src="../../../assets/images/icon/bank_account_info.svg" alt="">
                          <span class="font12">
                            Bank Account Info
                          </span>
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="ex1-tab-4" data-toggle="tab" (click)="editClose()" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">

                          <img src="../../../assets/images/icon/address.svg" alt="">
                          <span class="font12">
                            Address
                          </span>
                        </a>
                      </li>
                      <!-- <li class="nav-item" role="presentation">
                        <a class="nav-link" id="ex1-tab-5" data-toggle="tab" (click)="editClose()" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">

                          <img src="../../../assets/images/icon/store_address.svg" alt="">
                          <span class="font12">
                            Pickup Address
                          </span>
                        </a>
                      </li> -->
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="ex1-tab-6" data-toggle="tab" (click)="editClose()" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="false">

                          <img src="../../../assets/images/icon/address_proof_document.svg" alt="">
                          <span class="font12">
                            Address Proof Document
                          </span>
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="ex1-tab-7" data-toggle="tab" (click)="editClose()" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false">

                          <img src="../../../assets/images/icon/uploaded_document.svg" alt="">
                          <span class="font12">
                            Uploaded Document
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 col-md-8">
                <!-- Tabs content -->
                <div class="tab-content mb-2" id="ex1-content">
                  <div class="tab-pane fade card shadow-none show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                    <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                      <strong>Personal Details</strong>
                    </h6>
                    <hr class="my-0">
                    <div class="card-body custom-minhyt pt-2">
                      <div class="row">
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="personal-name">Name</label>
                            <input class="form-control" [disabled]="colDisable" id="personal-name" [(ngModel)]="sellerName" type="text" value="Amit Singh"
                              name="sellerName" placeholder="Name" readonly />
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="mobile-no">Mobile No.</label>
                            <input class="form-control" [disabled]="colDisable" id="mobile-no" type="text" [(ngModel)]="phnNumber" name="mobile_no" placeholder="Mobile No."
                            readonly/>
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="email">Email ID</label>
                            <input class="form-control" [disabled]="colDisable" id="email" [(ngModel)]="email" type="text" name="email" placeholder="Email"
                            readonly/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade card shadow-none" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                    
                    <div class="row">
                      <div class="col-10">
                        <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                          <strong>Business Details</strong>
                        </h6>
                      </div>
                      <div class="col-2 py-1 px-4 text-right">
                        <a href="javascript:void(0)" (click)="edit()" class="edit-ic text-end  float-end  btn-sm btn-theme">
                          <i class="fa fa-edit"></i>
                        </a>
                      </div>
                    </div>

                    <hr class="my-0">
                    <div class="card-body custom-minhyt pt-2">
                      <div class="row">
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="bsn">Business Name</label>
                            <input class="form-control" value="IT Company" id="bsn" [disabled]="colDisable" [(ngModel)]="businssName" type="text" name="businessName"
                              placeholder="Business Name" />
                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="store">Ware House (Item dispatch from here)</label>
                            <input class="form-control" id="store" [disabled]="colDisable" value="" [(ngModel)]="storeName" type="text" name="storeName"
                              placeholder="Ware House" />
                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="bs-type">Business Type</label>
                            <select name="" class="form-control" value id="bs-type" [disabled]="colDisable" [(ngModel)]="businssType" name="businessType">
                              <option value="1">Partnerships</option>
                              <option value="2">Corporation</option>
                              <option value="3">Nonprofit Organization</option>
                              <option value="4">Cooperative</option>
                            </select>
                          </div>
                        </div>



                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="gst"> GSTIN </label>
                            <input class="form-control" id="otp" type="text" disabled name="gstNo" [(ngModel)]="gstNo" maxlength="20"
                              value="18AABCU9603R1ZM" placeholder="GSTIN" />
                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="pan"> PAN </label>
                            <input class="form-control" id="otp" type="text" disabled name="panNo" [(ngModel)]="panNo" maxlength="10"
                              value="ABCTY1234D" placeholder="PAN  No" />
                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="cmp"> Company Strength </label>
                            <input class="form-control" id="cmp" value="1000" [disabled]="colDisable" type="text" [(ngModel)]="companyStrength" name="companyStrength"
                              placeholder="Company Strength" />
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="pd-cat">Product Category</label>
                            <ng-select [(ngModel)]="prodCatagory" [disabled]="colDisable" [multiple]="true" name="prodCatagory">
                              <ng-option *ngFor="let c of productCatagory" [value]="c.product_category_id"> {{c.category_name}}</ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mb-2" *ngIf="colDisable==false">
                      <button class="btn btn-theme btn-sm " (click)="updateDetail()">Update</button>
                  </div>
                  </div>
                  <div class="tab-pane fade card shadow-none" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                    <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                      <strong>Bank Account Info</strong>
                    </h6>
                    <hr class="my-0">
                    <div class="card-body custom-minhyt pt-2">
                      <div class="row">
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="name">Account Holder Name </label>
                            <input class="form-control" id="name" [disabled]="colDisable" value="Amit Singh" [(ngModel)]="accountHolderName" type="text"
                              name="accountHolderName" placeholder="Account Holder Name" />
                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="ac">Account Type</label>
                            <select name="" class="form-control" id="ac" [disabled]="colDisable" name="accountType" [(ngModel)]="accountType">
                              <option value="1">Savings</option>
                              <option value="2">Salary</option>
                              <option value="3">Current</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="ifsc"> IFSC Code</label>
                            <input class="form-control" id="ifsc" [disabled]="colDisable" value="CBIN0280233" [(ngModel)]="ifscCode" type="text" name="ifscCode"
                              maxlength="11" placeholder="IFSC Code" />
                          </div>
                        </div>
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="account">Account No.</label>
                            <input class="form-control" id="account" [disabled]="colDisable" value="3279428679" [(ngModel)]="accountNo" type="text" name="accountNo"
                              maxlength="25" placeholder="Account Number" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade card shadow-none" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                   
                    <div class="row">
                      <div class="col-10">
                        <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                          <strong>Address</strong>
                        </h6>
                      </div>
                      <div class="col-2 py-1 px-4 text-right">
                        <a href="javascript:void(0)" (click)="edit()" class="edit-ic text-end  float-end  btn-sm btn-theme">
                          <i class="fa fa-edit"></i>
                      </a>
                      </div>
                    </div>
                   
                   
                    <hr class="my-0">
                    <div class="card-body custom-minhyt pt-2">
                      <div class="row">
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="block">Plot No. / Block No. </label>
                            <input class="form-control" id="block" [disabled]="colDisable" [(ngModel)]="bplotNo" value="Sector-63, electronic city" type="text"
                              name="bplotNo" placeholder="Plot No/ Block No" />


                          </div>
                        </div>
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="sn">Street Name </label>
                            <input class="form-control" id="sn" [disabled]="colDisable" value="h-222, Near haldiram" [(ngModel)]="bstreetName" type="text"
                              name="bstreetName" placeholder="Street Name" />


                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="pin">PIN Code </label>
                            <input class="form-control" id="pin" type="text" (keyup)="getBCityAndState()" maxlength="6" autocomplete="off" [disabled]="colDisable"
                            name="bpinCode" ondrop="return false;" (keypress)="commonFunctionService.numericCheck($event)"
                            [(ngModel)]="bpinCode" placeholder="PIN Code" />


                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="city">City</label>
                            <input class="form-control" type="text" onkeydown="return false;" name="bcity" placeholder="City" [(ngModel)]="bcity" disabled
                                                            autocomplete="off" />


                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group ">
                            <label for="state">State</label>
                            <!-- <input class="form-control" [disabled]="colDisable" value="" [(ngModel)]="bstate" id="sn1" type="text" name="bstate_name"
                            /> -->
                            <select class="form-control" id="state" name="businessStateId" [(ngModel)]="businessStateId" disabled onkeydown="return false;"
                            ondrop="return false;">
                            <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                {{s.state_name}}</option>
                        </select>

                          </div>
                        </div>
                      </div>
                    
                    <div class="row mb-3">
                      <div class="col-12">
                          <div class="">
                              <input class="form-check-input custom2-chkbox" type="checkbox" id="adress" name="adress" value="adress" [(ngModel)]="fillAddress" [disabled]="colDisable"
                                  (change)="RepeatAdress()">

                              <label class="pl-2  ms-0" for="adress">
                                  Same as Business Address
                              </label>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="block1">Plot No. / Block No. </label>
                        <input type="text" name="" id="area" class="form-control textarefield" [disabled]="colDisable || fillAddress"  autocomplete="off" minlength="3" maxlength="250"
                        type="text" name="splotNo" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                        placeholder="Plot NO/ Block No" [(ngModel)]="splotNo">


                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="sn1">Street Name </label>
                        <input class="form-control" [disabled]="colDisable || fillAddress" autocomplete="off" maxlength="100" [(ngModel)]="sstreetName" minlength="3" id="sn1"
                                                            type="text" name="sstreetName" placeholder="Street Name" (keypress)="commonFunctionService.alphaNumericWithCommaDotHiphenValidator($event)"
                                                        />


                      </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="pin1">PIN Code </label>
                        <input class="form-control" [disabled]="colDisable || fillAddress" id="pin1" (keyup)="getSCityAndState()" maxlength="6" autocomplete="off"
                        ondrop="return false;" (keypress)="commonFunctionService.numericCheck($event)"
                        type="text" [(ngModel)]="spinCode" name="spinCode" maxlength="6"
                        placeholder="PIN Code" />


                      </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="city1">City</label>
                        <input class="form-control" type="text" onkeydown="return false;" name="scity" placeholder="City" autocomplete="off" [(ngModel)]="scity"
                                                            disabled />



                      </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="state1">State</label>
                        <select name="state1" class="form-control" id="state1" ondrop="return false;" [(ngModel)]="storeStateId" disabled name="sstate">
                          <option *ngFor="let s of stateList" value="{{s.state_id}}">
                              {{s.state_name}}</option>
                      </select>


                      </div>
                    </div>
                  </div>
                    <div class="text-center mb-2" *ngIf="colDisable==false">
                      <button class="btn btn-theme btn-sm " (click)="updateAddressDetail()">Update</button>
                  </div>
                  </div></div>
                  <!-- <div class="tab-pane fade card shadow-none" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                    <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                      <strong>Pickup Address</strong>
                    </h6>
                    <hr class="my-0">
                    <div class="card-body custom-minhyt pt-2">
                      <div class="row">
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="block1">Plot No. / Block No. </label>
                            <input class="form-control" id="block1" type="text" [disabled]="colDisable" [(ngModel)]="splotNo" name="splotNo" placeholder="Plot No/ Block No"
                              value="Sector-63, electronic city" />


                          </div>
                        </div>
                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="sn1">Street Name </label>
                            <input class="form-control" [disabled]="colDisable" value="h-222, Near haldiram" [(ngModel)]="sstreetName" id="sn1" type="text"
                              name="sstreetName" placeholder="Street Name" />


                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="pin1">PIN Code </label>
                            <input class="form-control" [disabled]="colDisable" id="pin1" type="text" [(ngModel)]="spinCode" name="spinCode" value="201301"
                              maxlength="6" placeholder="Pin Code" />


                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="city1">City</label>
                            <input class="form-control" [disabled]="colDisable" value="" [(ngModel)]="scity" id="sn1" type="text" name="scity" />



                          </div>
                        </div>

                        <div class="col-sm-6 mb-2">
                          <div class="form-group">
                            <label for="state1">State</label>
                            <input class="form-control" [disabled]="colDisable" value="" [(ngModel)]="sstate" id="sn1" type="text" name="sstate_name"
                            />


                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="tab-pane fade card shadow-none" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                    <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                      <strong>Address Proof Document</strong>
                    </h6>
                    <hr class="my-0">
                    <div class="card-body custom-minhyt p-0">
                      <div class="row m-0">
                        <div class="col-sm-12 p-0">
                          <div class="table-responsive white-space-pre">
                            <table class="table table-bordered mb-0 cstmgridhreader vertically-middle">
                              <tr>
                                <th class="px-3">Document Name</th>
                                <th class="text-center w-16">View</th>
                                <th class="text-center w-16">Action</th>
                              </tr>
                              <tr *ngFor="let addproof of sellerAdddressProof">
                                <td class="px-3">{{ addproof.proof_name === 'Business Pan Card'?'Business PAN Card' : addproof.proof_name
                                  }}
                                </td>
                                <!-- <p>../..{{addproof.document_path}}/{{addproof.system_file_name}}</p> -->
                                <td class="text-center" *ngIf="addproof.system_file_name.split('.')[1] != 'pdf'">
                                  <!-- <img
                                      src="../../{{ addproof.document_path }}/{{
                                        addproof.system_file_name
                                      }}"
                                      (click)="previewImages(addproof)"
                                      data-toggle="modal"
                                      data-target="#imagemodal"
                                      class="img-fluid wid90px"
                                      alt="Address Proof"
                                    /> -->
                                  <a href="javascript:void(0)" (click)="previewImages(addproof)" data-toggle="modal" data-target="#imagemodal">View Image</a>
                                </td>
                                <td class="text-center" *ngIf="addproof.system_file_name.split('.')[1] == 'pdf'">
                                  <a href="javascript:void(0)" (click)="openDoc(addproof)">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                    View</a>
                                </td>
                                <td class="text-center">
                                  <button type="button" class="btn btn-sm btn-theme" (click)="downloadImages(addproof)">
                                    <i class="fa fa-download"></i> Download</button>
                                </td>
                              </tr>

                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade card shadow-none" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                    <h6 class="py-2 px-3 font-weight-bold fnt95 mb-0">
                      <strong>Uploaded Document</strong>
                    </h6>
                    <hr class="my-0">
                    <div class="card-body custom-minhyt p-0">
                      <div class="row m-0">
                        <div class="col-sm-12 p-0">
                          <div class="table-responsive white-space-pre">
                            <table class="table table-bordered cstmgridhreader vertically-middle mb-0">
                              <tr>
                                <th class="px-3">Document Name</th>
                                <th class="text-center w-16">View</th>
                                <th class="text-center w-16">Action</th>
                              </tr>
                              <tr *ngFor="let proof of sellerProof; index as i">
                                <td class="px-3">{{ proof.document_type === 'Trademark' ? 'Cancelled Cheque' : proof.document_type }}</td>
                                <td class="text-center" *ngIf="proof.system_file_name.split('.')[1] != 'pdf'">
                                  <a href="javascript:void(0)" data-toggle="modal" data-target="#imagemodal" (click)="previewImages(proof)">View Image</a>
                                </td>
                                <td class="text-center" *ngIf="proof.system_file_name.split('.')[1] == 'pdf'">
                                  <a href="javascript:void(0)" (click)="openDoc(proof)">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                    View</a>
                                </td>
                                <td class="text-center">
                                  <button type="button" class="btn btn-sm btn-theme" (click)="downloadImages(proof)">
                                    <i class="fa fa-download"></i>
                                    Download
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="card mt-4 shadow-none" *ngIf="currentSellerStatus !=181">
                  <h6 class="py-2 px-3 font-weight-bold bg-dark-theme text-white fnt95 mb-0">
                    <strong>Approved/Rejected</strong>
                  </h6>
                  <hr class="my-0">
                  <div class="card-body pb-3 pt-2 shadow-none">
                    <div class="row">
                      <div class="col-sm-12 mb-2">
                        <label class="font9" for="">Remarks
                          <!-- <span class="asterisk">*</span> -->
                        </label>
                        <a href="javascript:void(0)" class="float-right text-decoration-none font8" data-toggle="modal" data-target="#message2seller">
                          <span>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            Message to Seller</span>
                        </a>
                        <textarea class="form-control2 font9" id="remarksId" name="remarks" rows="3" maxlength="500" [(ngModel)]="remarks" placeholder="Enter Remarks here.."></textarea>

                      </div>
                      <div class="col-sm-12 text-center">

                        <button type="button" class="btn btn-sm btn-success px-3 mr-2" (click)="approved()">
                          Approved
                        </button>
                        <button type="button" class="btn btn-sm btn-warning px-3 mr-2" (click)="reject()">
                          Rejected
                        </button>
                        <button type="button" class="btn btn-sm btn-danger px-4" (click)="back()">
                          Back
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<!--Modal starts here-->
<div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="imagemodalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header p-2 align-items-center">
        <h6 class="modal-title font9 font600" id="imagemodalLabel">Image preview</h6>
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <img src="../..{{ previewSrc }}" id="imagepreview" class="img-prev" />
        <!-- <img src="https://vahanmaster.com/wp-content/uploads/2021/06/aadhar.png" id="imagepreview" class="img-prev" > -->

        <!-- href="https://placeimg.com/1000/1000/arch"  -->
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="message2seller">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header p-2 align-items-center">
        <h6 class="modal-title font9 font600">Message to Seller</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset1()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-2">
        <div class="row m-0">
          <div class="col-sm-12">
            <label>Message
              <span class="asterisk">*</span>
            </label>
          </div>
          <div class="col-sm-12 mb-4">
            <textarea name="remarks" id="remarks" placeholder="Write your message here...." class="form-control2" maxlength="500" [(ngModel)]="sellermessage"></textarea>
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-sm btn-danger px-3" data-dismiss="modal" (click)="reset1()">Cancel</button>
          <button type="button" class="btn btn-sm btn-success ml-2 px-3" (click)="sellerMessage()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Modal starts here-->