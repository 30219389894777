import { Component, OnInit} from '@angular/core';
import {FormService} from '../../_services/form.service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
//import {LeftmenuserviceService} from '../../leftmenuservice.service';
import { AppSettings } from 'src/app/_services/AppSetting'
import{LoginService } from 'src/app/_services/login.service'
declare var $:any;
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {
  strng:any;
  lnkList:any[]=[];
  ModuleList: any[]=[];
  linkStatus:Boolean=true;
  loginType: any;
  loginid: any;
  frmId:any='';
  samplelist :any[]=[];
  roleId:any;
  actorType = ''
  conversionEncryptOutput:any;
  department:any="";
  reporting_office: any=""  
  constructor(
    private router: Router,
    private loginService:LoginService,
    private formService:FormService
     
  //  private leftmenu:LeftmenuserviceService
  ) { }
  ngOnInit(): void {
    this.actorType = localStorage.getItem("actorid")
    this.roleId=localStorage.getItem("role_id")
    var uri=this.router.url.split("?")
    this.department=localStorage.getItem("department")
    this.reporting_office=localStorage.getItem("reporting_office")
    
    if(uri.length>1){
      let str = uri[1];
      str= str.replace(/=/g,'');
      let value=str.split("&");
      if(value[0].indexOf("vid")>=0){
        this.frmId = value[0];
      }
      if(value.length >1){
        if(value[1].indexOf("vid")>=0){
          this.frmId = value[1];
        }
      }
      
      this.loginType=value[0]?value[0]:null;
      this.loginid=value[1]?value[1]:null;
    }
   // this.ModuleList = this.leftmenu.getUserData();
    //console.log(this.ModuleList,"Module List-----------------------")
    setTimeout(() => {this.getModuleList();}, 100);
   
      $('.button-left').click(function(){
          $('.sidebar').toggleClass('fliph');
          if ($('.sidebar').has('fliph')==true){
            $('.fliphremove').hide();
            $('#showbr').show();

          }
          else{
            $('#showbr').hide();
            $('.fliphremove').show();


          }

      });
    //   $('.logotxt').click(function(){
    //     $('.sidebar').toggleClass('fliph');
    // }); 
    $(function() {
    var myGroup = $('.list-sidebar');
    myGroup.on('show.bs.collapse','.collapse', function() {
       myGroup.find('.collapse.show').collapse('hide');
    });
  });
    const userId = localStorage.getItem("userId");
    const secureKey = "6192"
    var encrypt = CryptoJS.AES.encrypt(userId, secureKey).toString();
    this.conversionEncryptOutput=encodeURIComponent(encrypt);
  
$("#closefliph").click(function()
{
  $(".fliphremove").hide();
  $("#showbr").show();
})
$("#showbr").click(function()
{
  $(".fliphremove").show();
  $("#showbr").hide();

})
 
  }

  getModuleList(){

    this.ModuleList=[]
    let menulist= localStorage.getItem("menuList")
   
    if(menulist != undefined && menulist != null && menulist != "" && menulist != "undefined"){
      this.ModuleList=menulist?JSON.parse(menulist):[];
      console.log(this.ModuleList,'ModuleList')
    }else{
      this.ModuleList=[];
    }

    
    $(".fliph #package").removeClass("show");
    
    // // console.log("Role Id==>",localStorage.getItem("role_id")) 
    // this.formService.getFormData("select id, modulename,orderno from app_module where isactive=true order by id asc; select fd.order_id,fd.form_id,fd.form_template,fd.is_custom_form, fd.form_link_name form_link,v.link_name form_link_name ,v.view_type,v.view_id,v.child_form_ids,v.is_attachment,fd.module_id,m.modulename,fd.is_table_required from app_forms fd left join app_module m on fd.module_id=m.id left join app_form_view_details v on fd.form_id=v.form_id where (fd.db_status=2 and fd.isactive=true and v.is_link_display=true) or fd.is_custom_form=true order by m.modulename asc").subscribe(res => {
    //  if(res.length>0){
    //   this.ModuleList=res[0].rows;
    //   let linkList=res[1].rows;
    //   // Here complexity is worst
    //   for(let c=0; c<this.ModuleList.length; c++){
    //    this.ModuleList[c]["orderno"]=this.ModuleList[c]["orderno"]?this.ModuleList[c]["orderno"]:0;
    //    let allList=linkList.filter(d =>d.module_id == this.ModuleList[c]["id"])
    //    let sublist = allList.filter(x=>x.order_id!=null &&  x.oder_id!=0 && x.order_id!="null").sort((a,b)=>0-(a.order_id>b.order_id?-1:1));
    //    let thirdlist = allList.filter(x=>x.order_id==null || x.order_id==0)
    //     if(sublist.length>0)
    //     {
    //    thirdlist.forEach(element => {
    //     sublist.push(element)
    //   });
    // }
    // else
    // {
    //   sublist = thirdlist;
    // }
    //    this.ModuleList[c]["links"]=sublist;
    //    this.ModuleList[c]["strng"]=c;
    //  }
    //  this.ModuleList=this.ModuleList.filter(d =>d.links.length>0)
    //  this.ModuleList=this.ModuleList.sort((a, b) => Number(a.orderno) < Number(b.orderno) ? -1 : 1)
     
    //  }
    // });

     $(function() {
      // let val = $("li").find(".active");
      // val.parent().addClass('show');
      $( ".sub-menu li a" ).on('click', function() { 
       if ($("#leftmenu").hasClass("fliph")) {
        $(".sub-menu").removeClass("show");
      //  $("#mytask li").addClass("active")
        }
    //   // $(".sub-menu li.active a").toggleClass("active");
    //      });
    //      $( ".btnnoflip" ).on('click', function() { 
    //       if ($("#leftmenu").hasClass("fliph")) {
    //        $(".sub-menu").removeClass("show");
           
    //        }
    //     //  $("#mytask li").toggleClass("is-active");
    //         });
          
           
      }); 
    
    });



  //   $(".btnnoflip").click(function()
  // {
  //   $(".fliph .sub-menu")
  // });
    // $( ".fliph .list-sidebar li" ).on('click', function() { 
    //   $(".fliph .list-sidebar li").addClass("active");
    // });
    // var selector = '.list-sidebar li';
    // var url = window.location.href;
    // var target = url.split('/');
    //  $(selector).each(function(){
      
    //     if($(this).find('a').attr('href')===('/'+target[target.length-1])){
    //       $(selector).removeClass('active');
    //       $(this).removeClass('active').addClass('active');
    //       alert("ok1");
    //     }
        
    //  });
  }
 
  // methodCall(val)
  // {
  // for(let i=0;i<this.ModuleList.length;i++)
  // {
  // if(this.ModuleList[i]['id']==val['id'])
  // {
  // this.ModuleList[i]['activestatus']=true;
  // }
  // else{
  // this.ModuleList[i]['activestatus']=false;
  // }
  // }
  // }
  gotoFormDesignPage(fid,form_template,view_type,view_id,child_form_ids,is_attachment,form_link_name,is_custom_form,is_table_required,accesstoken,form_view_type){
     // if(is_table_required == false){
    //   this.router.navigateByUrl('/dynamic-custom-form'+"?frmid="+fid+'&vid='+view_id);
    // }else{
      // console.log(form_template,is_custom_form,view_type,form_view_type)
      if(form_view_type != undefined && form_view_type != null && form_view_type != ""){
        form_template=form_view_type;
      }
      if(view_type == 4){
        let data = JSON.stringify('frmid='+fid+'&vid='+view_id);
        let  encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/card-view-display?'+encriptUrl);
      }else if(fid == 387 && view_id == 365){
        let data = JSON.stringify('frmid='+fid+'&vid='+view_id);
        let  encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/brand-listing?'+encriptUrl);
      }
      else if(form_template == 3){      
        let data = JSON.stringify('frmid='+fid+'&vid='+view_id+'&isAddMode='+true+'&accesstoken='+accesstoken);
        let  encriptUrl = this.loginService.encriptDataUrl(data)        
        this.router.navigateByUrl('/request-form?'+encriptUrl);
      }
      else if(is_custom_form == true){      
        let form_link_url =form_link_name.split("?")
        let data = JSON.stringify(form_link_url[1])
        let encriptExtServiceUrl = this.loginService.encriptDataUrl(data);
        this.router.navigateByUrl('/'+form_link_url[0]+'?'+encriptExtServiceUrl);          
        }else{
          if(form_template == 2){
            this.router.navigateByUrl('/display-form-detail?frmid='+fid+'&vid='+view_id+'&accesstoken='+accesstoken);
          }else{
            let data  =JSON.stringify('frmid='+fid+'&vid='+view_id+'&accesstoken='+accesstoken);
            let encriptHunoIgx =this.loginService.encriptDataUrl(data);
            if((child_form_ids != "" && child_form_ids != null && child_form_ids != undefined)||(is_attachment != "" && is_attachment != null && is_attachment != undefined)){

              this.router.navigateByUrl('/huno-main-igx?'+encriptHunoIgx);
            }else{            
            this.router.navigateByUrl('/huno-main-igx?'+encriptHunoIgx);

            }
          }
        }
    //}

    
    let id="#vid"+view_id;
    $("li").removeClass("active");
    setTimeout(() => {
      $(id).addClass("active");
    }, 10);
    
  }

  gotoDashboard(){
    // let loginType=0
    // var id=localStorage.getItem("userId");
    // if(id == "1"){
    //   loginType=1
    // }else{
    //   loginType=2;
    // }
    var id=2;
    let loginType=2;
    // this.router.navigateByUrl('/inbox-outbox');   
  }
  gotoSubmit(){
    // let loginType=0
    // var id=localStorage.getItem("userId");
    // if(id == "1"){
    //   loginType=1
    // }else{
    //   loginType=2;
    // }
    var id=1;
    let loginType=1;
    // this.router.navigateByUrl('/Submitted-Request');   
  }
  removeSpace(val){
    return val.replace(/\s/g,'')
  }
  Routing(value){
    if(value ==='buff-sheet'){
      this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken')); 
  }
  if(value ==='inbox'){
      this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken'));   

  }
  else if (value ==='outbox'){
      this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken'));   

  }
  else if (value ==='drafts'){
      this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken'));   

  }
  else if (value==='committee-meeting'){
      this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken'));   

  }
  else if (value==='status-tracker'){
      this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken'));   

  }
  else if (value==='submitted'){
    this.router.navigateByUrl('/'+value+'?'+localStorage.getItem('tempToken'));   
this.gotoSubmit()
  }
  }

  changePage(){
    this.router.navigateByUrl('/bulkupload?' + localStorage.getItem('tempToken'))
  }

  changePage1(){
    this.router.navigateByUrl('/submite-verification?' + localStorage.getItem('tempToken'))
  }
}
