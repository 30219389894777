
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
import { CommonFunctionService } from '../../_services/common-function.service';
import { RowComp, Promise } from 'ag-grid-community';
import { AppSettings } from '../../_services/AppSetting';
import Swal from 'sweetalert2';
import { promise } from 'selenium-webdriver';
declare var $: any;

@Component({
  selector: 'app-total-seller-list',
  templateUrl: './total-seller-list.component.html',
  styleUrls: ['./total-seller-list.component.css'],
})
export class TotalSellerListComponent implements OnInit {
  isLoading: boolean = true;
  trackStatusCode:any = 181;
  rejected: boolean = false;
  blocked: boolean = false;
  approved: boolean = true;
  suspend: boolean = false
  suspended: any;
  blk_suspend: any = '';
  currentSellerId: any;
  adminRemarks: any;
  trackStatusCode2: any = 0;
  currentSellerName: any = '';
  searchTerm: any;
  isloadgridTrue: any = false;
  token: string;
  seller_id: any;
  user_mobile: any;
  showPassword: boolean = false;
  showPassword1: boolean = false;
  newPass: any;
  cnfrmPass: any;
  constructor(private formService: FormService, private ProductService: ProductService, private toastrService: ToastrService,private router: Router,public commonFunctionService: CommonFunctionService) { }
  LoadPagination: boolean = false;
  LoadPagination2: boolean = false;
  LoadPagination3: boolean = false;
  LoadPagination4: boolean = false;
  // LoadPagination5: boolean = false;

  approvedSeller: any[];
  blockedSeller: any[];
  rejectedSeller: any[];
  suspendedSeller: any[];
  allSeller: any[]=[];
  sellerList: any;
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  tempsellerList: any[];
  userId: any;
  allSellerList: any[];
  allSellerListData:any;
  approvedCount:any;
  rejectCount:any;
  suspendedCount:any;
  blockCount:any;
  MassageOutTime = AppSettings.getMassageOutTime;
  mobile_no: any;
  isdataloaded:Promise<boolean> = Promise.resolve(false);
  ngOnInit(): void {
    
    this.isLoading = true;
    this.token = localStorage.getItem('tempToken')
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.bindNgxDataGridApp(this.page, 1)
    $(document).ready(function(){
      $('ul.tbsbtns li').click(function(){
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
    });
    });
    this.getSellerList();
  }
  getSellerList(){
    this.ProductService.GetAllSellercountList().subscribe(res => {
      this.allSellerListData = res.rows;
      this.approvedCount = (this.allSellerListData.filter(x => x.seller_status == 181)).length;
      this.rejectCount = (this.allSellerListData.filter(x => x.seller_status == 182)).length;
      this.blockCount = (this.allSellerListData.filter(x => x.seller_status == 183 )).length;
      this.suspendedCount = (this.allSellerListData.filter(x => x.seller_status == 184)).length;
    //  console.log( "suspended counttttttttttt",this.suspendedCount)
    })
  }
  LoadGridApp(event) {
    this.isloadgridTrue = true
     this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGridApp(this.page, 2)
  }
  LoadGridRej(event) {
    this.isloadgridTrue = true
    this.configParms = event;
   this.page.size = event.itemsPerPage;
   this.page.totalElements = event.totalItems;
   this.page.pageNumber = event.currentPage;
    this.bindNgxDataGridRej(this.page, 2)

 }
 LoadGridBlk(event) {
  this.isloadgridTrue = true
 this.configParms = event;
 this.page.size = event.itemsPerPage;
 this.page.totalElements = event.totalItems;
 this.page.pageNumber = event.currentPage;
 this.bindNgxDataGridBlk(this.page, 2)
}

LoadGridSusp(event) {
  this.isloadgridTrue = true
  this.configParms = event;
 this.page.size = event.itemsPerPage;
 this.page.totalElements = event.totalItems;
 this.page.pageNumber = event.currentPage;
 this.bindNgxDataGridSusp(this.page, 2)
}

  bindNgxDataGridApp(pageInfo, status) {
    // console.log('approved grid cakked', this.searchTerm)
    this.isLoading =true
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
   let qcStatus = 181;
   if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
   this.ProductService.pendingSellerCount(qcStatus, this.searchTerm).subscribe(resp => {
           this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      // console.log("bindNgxDataGridApp pagesize,offset,qcStatus==>",pagesize,offset,qcStatus)
        offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
        
      this.ProductService.SellerList(pagesize,offset,qcStatus, this.searchTerm).subscribe(res => {
    if(res.rows.length > 0){
      this.allSeller = res.rows
      this.tempsellerList =  this.approvedSeller = res.rows;
      this.isLoading = false;
    }else{
      setTimeout(() => {
       this.allSeller = []
        this.isLoading = false

      }, 100);
    }
      
      })
      this.totalCount = resp.rows[0].count;
     let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;
      this.LoadPagination2 = false;
      this.LoadPagination3 = false;
      this.LoadPagination4 = false;

      this.isdataloaded=Promise.resolve(false);
      this.isdataloaded=Promise.resolve(true);
    })

  }
  bindNgxDataGridRej(pageInfo, status) {
    // console.log('reject grid cakked', this.searchTerm)
this.isLoading = true
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatus = 182;
    offset = this.searchTerm ? 0 : offset
    this.ProductService.pendingSellerCount(qcStatus, this.searchTerm).subscribe(resp => {
            this.page.totalElements = resp.rows[0].count;
       let pageCount = pageInfo.pageNumber
      // console.log("bindNgxDataGridRej pagesize,offset,qcStatus==>",pagesize,offset,qcStatus)
       this.ProductService.SellerList(pagesize,offset,qcStatus, this.searchTerm).subscribe(res => {
        if(res.rows.length > 0){
          this.allSeller = res.rows;
        this.tempsellerList = res.rows;
          this.isLoading = false;
        }else{
          setTimeout(() => {
            this.allSeller = []
          this.isLoading = false;
          }, 100);
        }

       
       })
       this.totalCount = resp.rows[0].count;
      let currPage = status==2?pageInfo.pageNumber:1;
       if (currPage == 0) { currPage = 1; }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
       this.LoadPagination2 = true;
       this.LoadPagination = false;
       this.LoadPagination3 = false;
       this.LoadPagination4 = false;
       this.isdataloaded=Promise.resolve(false);
       this.isdataloaded=Promise.resolve(true);
       //this.isLoading = false;
     })
 
  }
  bindNgxDataGridBlk(pageInfo, status) {
    // console.log('blocked grid cakked', this.searchTerm)

    //this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.isLoading = true
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatus = 183;
    offset = this.searchTerm ? 0 : offset
    this.ProductService.pendingSellerCount(qcStatus, this.searchTerm).subscribe(resp => {
            this.page.totalElements = resp.rows[0].count;
       let pageCount = pageInfo.pageNumber
 
       this.ProductService.SellerList(pagesize,offset,qcStatus, this.searchTerm).subscribe(res => {
     if(res.rows.length > 0){
      this.tempsellerList = res.rows;
      this.allSeller = res.rows;
     this.isLoading = false;
     }else{
       setTimeout(() => {
        this.allSeller = []
        this.isLoading = false;
         }, 100);
     }
       
       
       })
       this.totalCount = resp.rows[0].count;
       let currPage = status==2?pageInfo.pageNumber:1;
       if (currPage == 0) { currPage = 1; }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      //  console.log(this.configParms)
       this.LoadPagination3 = true;
       this.LoadPagination2 = false;
       this.LoadPagination = false;
       this.LoadPagination4 = false;
       this.isdataloaded=Promise.resolve(false);
       this.isdataloaded=Promise.resolve(true);
       //this.isLoading = false;
     })
 

  }

  bindNgxDataGridSusp(pageInfo, status) {
    // console.log('suspended grid cakked', this.searchTerm)
    this.isLoading = true
     let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatusSecond = 184
    offset = this.searchTerm ? 0 : offset
    this.ProductService.pendingSellerCount(qcStatusSecond, this.searchTerm).subscribe(resp => {
            this.page.totalElements = resp.rows[0].count;
       let pageCount = pageInfo.pageNumber
 
       this.ProductService.SellerList(pagesize,offset, qcStatusSecond, this.searchTerm).subscribe(res => {
        // console.log('suspeddddddded datalist', res.rows)

       if(res.rows.length > 0){
        this.tempsellerList = res.rows;
        this.allSeller = res.rows;
       this.isLoading = false;
       }else{
         setTimeout(() => {
       this.isLoading = false;
       this.allSeller = []
         }, 100);
       }
       
       
       })
       this.totalCount = resp.rows[0].count;
       let currPage = status==2?pageInfo.pageNumber:1;
       if (currPage == 0) { currPage = 1; }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
       this.LoadPagination4 = true;
       this.LoadPagination2 = false;
       this.LoadPagination3 = false;
       this.LoadPagination = false;
       this.isdataloaded=Promise.resolve(false);
       this.isdataloaded=Promise.resolve(true);
       //this.isLoading = false;
     })
 

  }

  CommonGridSearchMaster(ev) {
    // console.log('called or not', ev)
    this.searchTerm = ev;
    if(this.approved)
      this.bindNgxDataGridApp(this.page, 1)
      else if(this.rejected)
        this.bindNgxDataGridRej(this.page, 1)
        else if(this.blocked)
          this.bindNgxDataGridBlk(this.page, 1)
          else
          this.bindNgxDataGridSusp(this.page, 1)

    // this.allSeller = this.tempsellerList.filter(item => ((item.seller_name ? (item.seller_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.business_name ? (item.business_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.store_name ? (item.store_name.toString().indexOf(ev) !== -1) : false) || (item.mobile_no ? (item.mobile_no.toLowerCase().indexOf(ev) !== -1) : false)) || (item.email ? (item.email.toLowerCase().indexOf(ev) !== -1) : false))
  }

  getAnySeller(status, status2?) {
    this.ProductService.fetchSellerByStatusCode(status, status2).subscribe((res: any) => {
      this.allSeller = res.rows;
      this.isLoader = false;
      this.tempsellerList = res.rows;
    })
  }


  getRejectedSeller() {
    this.isloadgridTrue = false
    this.searchTerm = ''
    this.rejected = true
    this.approved = false;
    this.blocked = false
    this.suspend = false

    this.isLoader = true;
    this.trackStatusCode2 = 0
    this.trackStatusCode = 182
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridRej(this.page, 1)
  }
  getApprovedSeller() {
    this.isloadgridTrue = false

    this.searchTerm = ''
    this.rejected = false
    this.blocked = false
    this.suspend = false
   this.isLoader = true;
    this.approved = true;
    this.trackStatusCode = 181
    this.trackStatusCode2 = 0
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridApp(this.page, 1)

  }
  getBlockedSeller() {
    this.isloadgridTrue = false

    this.searchTerm = ''
    this.rejected = false
    this.approved = false;
    this.blocked = true
    this.suspend = false
    this.isLoader = true;
    this.trackStatusCode = 183
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridBlk(this.page, 1)
  }

  getSuspendSeller(){
    this.isloadgridTrue = false

    this.searchTerm = ''
    this.rejected = false
    this.approved = false;
    this.blocked = false;
    this.suspend = true;
    this.isLoader = true;
    this.trackStatusCode = 184
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
    this.bindNgxDataGridSusp(this.page, 1)
  }

  reAcitve(code) {
   
    // if (this.adminRemarks == '' || this.adminRemarks == null || this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
    //   this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false
    // }
  
    $("#toBlock").modal('hide');
    $("#myModal").modal('hide');
   let key = code == 183 ? 'block' : code == 184 ? 'suspend' : this.suspended == 183 ? 'unblock' : 'active'
    Swal.fire({
      title: `Do you want to  ${key} ${this.currentSellerName}?<br>`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.updateSellerStatus(code,this.currentSellerId, this.adminRemarks,this.userId).subscribe((res: any) => {
        
          if (this.blocked){
      
              this.getBlockedSeller()
              this.getSellerList();
        Swal.fire('Seller Unblocked!', '', 'success')
          }
          else if (this.approved) {
    
            this.getApprovedSeller()
            this.getSellerList();
        Swal.fire(code == 183? 'Seller Blocked!': 'Seller Suspended!', '', 'success')
          } else if(this.rejected){
     
            this.getRejectedSeller()
            this.getSellerList();
            Swal.fire('Seller Rejected!', '', 'success')

          }else
          {
            this.getSuspendSeller()
            this.getSellerList()
        Swal.fire('Seller Activated!', '', 'success')

          }
    
        })
      } else if (result.isDenied) {
        this.adminRemarks = ''
        // $("#myModal").modal('show');
        $("#toBlock").modal('hide');

      }
    })
  }

  saveId(cell) {
    this.adminRemarks = ''
    this.blk_suspend = ''
    this.suspended = cell.rowData.seller_status;
    this.currentSellerId = cell.rowData.seller_id
    this.currentSellerName = cell.rowData.seller_name
  }
  // getDetails(row){
  //   localStorage.removeItem('approve_reject');
  //   localStorage.setItem("selected_seller_id",row.rowData.seller_id);
  //   this.router.navigateByUrl('/seller-profile');
  // }
  productListing(id){
    localStorage.removeItem('breadcrumb');
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem("sellerName", id.rowData.seller_name);
    let data = JSON.stringify(`sellerName=${id.rowData.seller_name}&selected_seller_id=${id.rowData.seller_id}`);
    let  encriptUrl = this.formService.encriptDataUrl(data) 
    this.router.navigateByUrl(`/seller-wise-product?${encriptUrl}${localStorage.getItem('tempToken')}` );
  }
  orderListing(id){
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem("sellerName", id.rowData.seller_name);
    this.router.navigateByUrl('/manage-order'  + '?' + localStorage.getItem('tempToken'));
  }
  paymentListing(id){
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem("sellerName", id.rowData.seller_name);
    this.router.navigateByUrl('/payment-history' + '?' + localStorage.getItem('tempToken'));
  }
  
  getDetails(row){
    localStorage.removeItem('approve_reject');
    localStorage.setItem("selected_seller_id",row.rowData.seller_id);
    this.router.navigateByUrl('/seller-profile' + '?' + localStorage.getItem('tempToken'));
    //window.open('seller-profile', '_blank')
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  changeMobile(obj){
    console.log("objjj", obj)
    this.seller_id = obj.rowData.seller_id;
    //this.user_mobile = obj.rowData.user_mobile;
    $("#convertModal").modal('show');
  }

  onSubmitMobile(){
    this.mobile_no = this.mobile_no ? this.mobile_no.trim() : ''
    if (this.mobile_no == null || this.mobile_no == undefined || this.mobile_no == '') {
      this.toastrService.warning('Please Enter Contact Number!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.mobile_no !== null || this.mobile_no !== undefined || this.mobile_no !== '') {
      if (this.mobile_no.toString().length == 10) {
        if ((this.mobile_no[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    Swal.fire({
      title: `Are you sure you want to change mobile number?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.changeUserMobileNumber(this.mobile_no, this.seller_id).subscribe((res: any) => {
          if (res.status == 201) {
            this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false
          }else if (res.status == 200){
            this.toastrService.success("Mobile number changed successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
            this.removeClass();
            this.reset();
            $("#convertModal").modal('hide');
            setTimeout(() => {
              window.location.reload()
            }, 500);
          }     
        })  
      }
    });

  }
  reset(){
    this.mobile_no = ''
  }
  resetP(){
    this.newPass = ''
    this.cnfrmPass = ''
  }
  changePassword(obj){
    this.seller_id = obj.rowData.seller_id;
    $("#updatePassword").modal('show');
  }
  showHidePassword() {
    // this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.showPassword= false;
    } else {
      this.showPassword = true;
    }
}
updatePassword()
  { var minNumberofChars = 8;
    var maxNumberofChars = 16;
    let passpattern = /(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/;
    let pattern = /^((?=.*[a-z])(?=.*[A-Z]).*)$/
     if(this.newPass==null || this.newPass==undefined || this.newPass=="" || this.newPass=="undefined" || this.newPass=="null")
     {
      this.toastrService.warning('Please Enter New Password', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false; 
     }
     if (this.newPass !== null || this.newPass !== undefined || this.newPass !== '') {
      if (this.newPass.length < minNumberofChars || this.newPass.length > maxNumberofChars) {
        this.toastrService.warning("Your password must be at least 8 characters", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      } else if (!passpattern.test(this.newPass)) {
        this.toastrService.warning("Password should contain atleast one number and one special character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      } else if (!pattern.test(this.newPass)) {
        this.toastrService.warning("Password should contain atleast one upper case and one lower case character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
     if(this.cnfrmPass==null || this.cnfrmPass==undefined || this.cnfrmPass=="" || this.cnfrmPass=="undefined" || this.cnfrmPass=="null")
     {
      this.toastrService.warning('Please Enter Confirm Password', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false; 
     }
     if(this.newPass!=this.cnfrmPass)
     {
      this.toastrService.warning('New password should be same as confirm password', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false; 
     } 
     
     Swal.fire({
      title: `Are you sure you want to change password?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.updatePasswordByAdmin(this.newPass, this.seller_id).subscribe((res: any) => {
          if (res.status == 200){
            this.toastrService.success("Password changed successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
            this.removeClass();
            this.resetP();
            $("#updatePassword").modal('hide');
            setTimeout(() => {
              window.location.reload()
            }, 500);
          }     
        })  
      }
    });
   
  }
showHidePassword1() {
  // this.showPassword = !this.showPassword;
  if (this.showPassword1) {
    this.showPassword1 = false;
  } else {
    this.showPassword1 = true;
  }
}
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
