<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
     <ng-container *ngIf="FormViewLoaded">
        <app-hdsbpmCardLayout [cardviewlist]="finalCardViewArray" [cardheader]="card_header"> </app-hdsbpmCardLayout> 
     </ng-container>
     </div>
</div>