<div class="d-flex minhyt96vh" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
      <div class="container-fluid bg-white">
        <div class="row">
          <div class="col-sm-12 p-0">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                <li class="breadcrumb-item newitm1">
                  <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                </li>
                <li class="breadcrumb-item newitm2  active" aria-current="page">Add Variations </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="col-sm-12 mb-2 p-0">
          <div class="tabs row my-2 mx-0">
  
            <div class="tab-button-outer1 col-sm col-9 px-0 mb-sm-0 mb-2">
              <ul id="tab-button">
                <li id="tabDraft" class="is-active">
                  <a href="javascript:void(0)" class="font9">Add Variations
                  </a>
                </li>
              </ul>
            </div>
            
            <div class="col-sm-3 px-sm-3 px-0 " id="masterSearch">
              <div class="input-group">
                <!-- <label for="" class="font9 pt-2 pr-3">Search</label> -->
                <input type="text" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                  placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 ml-2 mt-0" onpaste="return false;" (click)="CommonGridSearchMaster(SearchValue.value)">
                  <i class="fa fa-search"></i>
                </a>
              </div>
            </div>
            <div class="col-sm-auto text-right px-0 col-3 order-sm-2 order-1">
                <button type="button" data-toggle="modal" (click)="openAddVariation()" class="btn btn-sm btn-circle btn-circle-sm btn-theme toolbar-item ml-1">
                  <i class="fa fa-plus"></i> 
                </button>
              </div>
            
            <!-- <div class="col-sm-2 px-sm-3 px-0">
                <div class="input-group">
                <label for="" class="font9 pt-2 pr-3">Filter</label>
            <select class="form-control select border-top-0 rounded-0 border-left-0 border-right-0 rounded" >
                <option value="undefined" disabled >-: Cloths :-</option>
                                                     
              </select></div></div> -->
         
          </div>
        </div>
        <div id="tab01" class="tab-contents mmt5 p-0">
          <div class="col-sm-12 col-md-12 nopadding">
            <div id="grid-theme-wrapper">
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoading"></ngx-loading>
              <igx-grid *ngIf="brandList.length > 0" #grid1 [data]="brandList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">
                <igx-column field="" header="Sr. No." width="80" [filterable]="false">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container w-100 text-center">
                      {{cell.row.index + 1}}
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="category_name" header="Category Name" width="400">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"> </span>
                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="code" header="Brand Code" width="400">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"> </span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <igx-column field="attribute_lable" header="Variations Name" width="350">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"> </span>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="value_lable" header="Variations Value" width="350">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"> </span>
                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="is_aproved" header="Status" width="160">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                    
                      <span class="cell-vl" *ngIf="cell.value == true"> Active </span>
                      <span class="cell-vl" *ngIf="cell.value == false"> Inactive </span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <igx-column field="brand_name_id" header="Action" width="160">
                  <ng-template compositeTemp igxCell let-cell="cell">
                    <div class="px-2 address-container">
                      <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="py-2 px-3">Action</span>
                          <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                        </button>
                        <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop6">
                          <!-- <li class="border-bottom" *ngIf="cell.rowData.is_aproved != true">
                            <a href="javascript:void(0)" (click)="onApproved(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                              <i class="fa fa-check mr-1"></i>Approve
                            </a>
                          </li> -->
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onEdit(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                              <i class="fa fa fa-pencil mr-1"></i>Edit
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                              <i class="fa fa fa-trash mr-1"></i>Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </igx-column>
              </igx-grid>
              <label class="w-100 text-center text-dark" *ngIf="!isLoading && brandList.length == 0">No Record Found</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- view model -->
  

   <div id="myModal" class="modal fade p-0" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl">
   
      <div class="modal-content">
        <div class="modal-header align-items-center py-2">
          <h6 class="font600 mb-0">Add Variations</h6>
          <button type="button" class="close" data-dismiss="modal" >
            <span> &times; </span>
          </button>
        </div>
        <div class="modal-body minhyt40">
          <div class="row mx-0 mb-2">
            <div class="col-md-6 mb-3">
                <div class="row">
                  <div class="col-md-4 text-right">
                    <label for="" class="font8">Category name
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <!-- <select class="form-control select" >
                        <option value="undefined" disabled >-: Category name :-</option>
                                                             
                      </select> -->
                      <select name="category" id="category" class="form-control mb-2" [(ngModel)]="category" [disabled]="attribute_id">
                        <option value="undefined" disabled>Select Category</option>
                        <option *ngFor="let cat of categoryList" [value]="cat.product_category_id">
                            {{cat.category_name}}
                        </option>
                    </select>
                  </div>
                </div>
              </div>
         
            <div class="col-md-6 mb-3">
                <div class="row">
                  <div class="col-md-4 text-right">
                    <label for="" class="font8">Is Active
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input type="checkbox" value="true"
                    [(ngModel)]="isActive" class=" mb-2 mt-1 custom2-chkbox">
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-md-2 text-right">
                    <label for="" class="font8">Variation Type
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-10">
                    <!-- <input type="text"  class="form-control mb-2"  [(ngModel)]="variationName"   autocomplete="off" placeholder="Enter Variation Name"
                      maxlength="50" [disabled]="attribute_id"> -->
                      <select name="category sa" id="category2" class="form-control mb-2" [(ngModel)]="attributeType" [disabled]="attribute_id">
                        <option value="undefined" disabled>Select Attribute Type</option>
                        <option *ngFor="let cat of allAttributeList" [value]="cat.attribute_id">
                            {{cat.attribute_lable}}
                        </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-md-2 text-right">
                    <label for="" class="font8">Variation Value Code
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-10">
                    <input type="text"  class="form-control mb-2"  [(ngModel)]="variationName"   autocomplete="off" placeholder="Enter Variation Value Code"
                      maxlength="50">
                  </div>
                </div>
              </div>
           
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-2 text-right">
                        <label for="" class="font8">Variation Value label
                          <span class="asterisk">*</span>
                        </label>
                      </div>
                      <div class="col-md-10">
                        <input type="text"  class="form-control mb-2"   [(ngModel)]="variationValue"  autocomplete="off" placeholder="Enter Variation Value Label"
                           >
                      </div>
                    </div>
                  </div>
          
          </div>
       
        </div>
        <div class="bottom-button-group text-center pb-3">
          <button type="button" class="btn btn-sm px-3 btn-success mr-1" *ngIf="!attribute_id" [disabled]="!variationValue ||(variationValue && !variationValue.trim()) || !variationName ||(variationName && !variationName.trim())|| !category || !attributeType" (click)="addVariation()">Submit</button>
          <button type="button" class="btn btn-sm px-3 btn-success mr-1" *ngIf="attribute_id" [disabled]="!variationValue ||(variationValue && !variationValue.trim()) || !variationName ||(variationName && !variationName.trim())|| !category || !attributeType" (click)="updateVariation()">Update</button>

          <button class="btn btn-sm btn-danger px-3" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>   

  <!-- <div id="myModal" class="modal fade p-0" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header align-items-center py-2">
          <h6 class="font600 mb-0">Convert Corporate</h6>
          <button type="button" class="close" data-dismiss="modal" >
            <span> &times; </span>
          </button>
        </div>
        <div class="modal-body minhyt40">
          <div class="row mx-0 mb-2">
            <div class="col-md-6 mb-3">
              <div class="row">
                <div class="col-md-4 text-right">
                  <label for="" class="font8">Enterprises ID 
                    <span class="asterisk">*</span>
                  </label>
                </div>
                <div class="col-md-8">
                  <input type="text"  class="form-control mb-2" disabled  autocomplete="off" placeholder=""
                    maxlength="50" >
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="row">
                  <div class="col-md-4 text-right">
                    <label for="" class="font8">Enterprises Name
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input type="text"  class="form-control mb-2"   autocomplete="off" placeholder=""
                      maxlength="50" >
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-md-2 text-right">
                    <label for="" class="font8">Payment Terms
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-2 mb-2">
                          <div class="row">
                            <div class="col-md-6 col-6">
                              <input type="radio" name="payment" id="cash" checked class="custom2-chkbox"> <label for="cash" class="pl-2"> Cash</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <input type="radio" name="payment" id="credit" class="custom2-chkbox"> <label for="credit" class="pl-2"> Credit</label>
                            </div>

                          </div>
                  </div>
                </div>
              </div>

                <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="col-md-4 text-right">
                        <label for="" class="font8">Credit Period
                          <span class="asterisk">*</span>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control select" [(ngModel)]="credit_id">
                            <option value="undefined" disabled >-: Select Credit Period :-</option>
                            <option value="30 Days" > 30 Days </option> 
                            <option value="30 Days" > 60 Days </option>                                               
                          </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="col-md-4 text-right">
                        <label for="" class="font8">Credit Limit
                          <span class="asterisk">*</span>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <input type="text"  class="form-control mb-2"   autocomplete="off" placeholder=""
                          maxlength="50" >
                      </div>
                    </div>
                  </div>
          </div>
        </div>
        <div class="bottom-button-group text-center pb-3">
          <button type="button" class="btn btn-sm px-3 btn-success mr-1" >Submit</button>
          <button class="btn btn-sm btn-danger px-3" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>    -->