
<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    
    <div class="container-fluid">
      <div class="row">
      <div class="col-sm-12 p-0">
        <app-breadcrumb [FormName]="formName" [ModuleName]="modulename"></app-breadcrumb>
      </div></div>
      <div class="col-sm-12 p-0">
        <div class="tabs col-lg-12 pl-0 pr-1 mt-2 d-flex">
          <div class="tab-button-outer1 col-lg-6 pl-0">
            <ul id="tab-button">
              <li class="is-active " style="min-width: 80px; min-height: 33px;" id="{{formId}}">
                <a class="noback nobrdr nocrsr pl-0 textdark" href="javascript:void(0)" (click)="getDetails(formId)" style="font-size: 0.9rem !important;">{{formName}}</a>
              </li>
              <!-- <div class="example-header cstmpage pt-2 cstminblock">
                <a href="javascript:void(0)" class="btn btn-sm btn-outline-dark filtericon pt-0 pb-0 hyt24 mr-1" (click)="modalRoot.show(); isResetFilter=false;">
                  <i class="fa fa-filter"></i>
                </a>
                <span *ngIf="viewFilterString" class="badge badge-pill badge-success badge-outlined fnt100 pt-1   pb-1">
                  <div class="float-left" [innerHTML]="viewFilterString"></div>
                  <a href="javascript:void(0)" class="posclose" (click)="removeFilter()">
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div> -->
              <!-- <a href="javascript:void(0)"   class="btn btn-sm btn-outline-dark filtericon pt-0 pb-0 hyt24 mr-1" (click)="modalRoot.show(); isResetFilter=false;"> <i class="fa fa-filter"></i> 
          </a> 
          <span *ngIf="viewFilterString" class="badge badge-pill badge-success badge-outlined fnt100 pt-1   pb-1"> 
            <div class="float-left"  [innerHTML]="viewFilterString"></div> <a href="javascript:void(0)" class="posclose" (click)="removeFilter()" ><i class="fa fa-times"></i> </a> </span> -->
            </ul>
          </div>
          <div class="col-sm-6 cstmposition2 pr-0">

            <div class="sort-wrapper pull-right width100">
              <div class="form-group col-lg-9 pr-0">
                <div class=" input-group">
                  <input type="text" class="searchbar form-control newform-control" placeholder="Search" (keyup)="CommonGridSearch(SearchValue.value)" #SearchValue />
                  <a href="javascript:void(0)" id="lnkSearchValue" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearch(SearchValue.value)">
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </div>


              <div class="topActionButtons">
                <!-- <div class="btn-group">
                  <button type="button" class="btn btn-secondary btn-circle ml-1 btn-circle-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="fa fa-share"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right cstmdop">
                    <a class="dropdown-item px-1" href="javascript:;" (click)="exportCSVHandler()">Export as CSV</a>
                    <a class="dropdown-item px-1" href="javascript:;" (click)="exportExcelHandler()">Export as Excel</a>
                    <div class="dropdown-item">
                      <span class="font8 px-1">Share link with</span>
                      <div class="d-flex">
                        <input type="search" class="shareAddress form-control font8" placeholder="Type email ID" />

                        <button class="btn btn-sm btn-circle btn-secondary btn-circle-sm btn-roll">
                          <i class="fa fa-long-arrow-right"></i>
                        </button>
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <button class="btn btn-primary btn-circle btn-circle-sm" (click)="openDialogXL()">
              <i class="fa fa-plus"></i>
            </button> -->
              </div>
              <div>
                <button type="button" *ngIf="isAdd" class="btn btn-sm btn-circle btn-circle-sm  btn-dark toolbar-item ml-1" (click)="onAddClick()">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-contents mt-1 mmt5 p-0">
          <app-modal #modalRoot class="modal-demo custommodal">
            <ng-container class="app-modal-header p-2">Add Rule</ng-container>
            <ng-container class="app-modal-body">
              <app-user-rule [formId]="formId" [SessionUrlPrams]="sessionUrlPrams" [resetFilter]="isResetFilter" (applyEvent)="apply($event)"></app-user-rule>
            </ng-container>
            <ng-container class="app-modal-footer p-2 text-right">
              <button type="button" id="clos" (click)="modalRoot.hide()" style="display: none">Close</button>
            </ng-container>
          </app-modal>
          <div class="grid__wrapper tableMain p-0">
            <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
            <igx-grid #grid1 [data]="data" width="100%" height="580px" [autoGenerate]="false" [allowFiltering]="false" displayDensity="cosy"
              [filterMode]="'excelStyleFilter'"  [(perPage)]="page.size" (onDataPreLoad)="actionstrip.hide()"
              (onRowSelectionChange)="handleRowSelection($event)">
              <igx-column field="" header="Sr. No." width="80" [filterable]="false">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container w-100 text-center">
                    {{cell.row.index + 1}}
                 </div>
                </ng-template>
              </igx-column>
              <ng-container *ngFor="let col of columnDefs let i=index">
               
                <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]='"200px"' sortable="col.sortable">
                  <ng-template igxCell let-cell="cell">
                    <a class="truncate" href="javascript:void(0)" (click)="ShowViewData(cell)">{{cell.value}}</a>
                  </ng-template>
                </igx-column>

                <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string"
                  sortable="col.sortable"></igx-column>

              </ng-container>
              <igx-action-strip #actionstrip>
                <div class="drop-down-wrapper">
                  <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting" [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu"
                    igxButton="icon">
                    <igx-icon>more_vert</igx-icon>
                  </button>
                  <igx-drop-down #menu [allowItemsFocus]="true">
                    <ng-container *ngFor="let item of columnActionStrip">
                      <igx-drop-down-item (click)="onClickActionStrip(actionstrip.context,item.field)">
                        <div>
                          <span>
                            <i class="fa fa-{{ item.iconName }}"></i>
                            {{ item.field }}
                          </span>
                        </div>
                      </igx-drop-down-item>
                    </ng-container>
                  </igx-drop-down>
                  <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                </div>
              </igx-action-strip>
            </igx-grid>
          </div>
          <div class="modal" id="exampleModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div class="modal-content" id="example-content">

                <div class="modal-body minhyt40 pt-1 pb-0" *ngIf="FormViewLoaded | async">
                  <label class="font9 successedit2 alert alert-success pt-0 pb-0" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
                  <label class="font9 erroredit2 alert alert-warning pt-0 pb-0" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
                  <ul class="nav nav-tabs">
                    <li class="active">
                      <a data-toggle="tab" class="active" href="javscript:void(0)" (click)="tabChanged($event,formId)">{{formName}}</a>
                    </li>
                    <li *ngFor="let lst of childFormList">
                      <a [class]="isAddMode?'a-tabs':''" data-toggle="tab" href="javscript:void(0)" (click)="tabChanged($event,lst.form_id)">{{lst.form_name}}</a>
                    </li>
                    <li *ngIf="formId==401">
                      <a *ngIf="formId == 401" [class]="isAddMode?'a-tabs':''" data-toggle="tab" href="javscript:void(0)" (click)="onCustomClick()">Check List</a>
                    </li>
                    <li *ngIf="formId==401">
                      <a *ngIf="formId == 401" [class]="isAddMode?'a-tabs':''"  href="javascript:void(0);" data-toggle="tab" (click)="onCustomClickReporting()">Reporting</a>
                    </li>
                    <li *ngIf="is_attachment == true">
                      <a [class]="isAddMode?'a-tabs':''" data-toggle="tab" href="javscript:void(0)" (click)="tabChanged($event,formd)">Attachments</a>
                    </li>
                    <li class="pull-right clsicn">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content tab-contents pt-0 pr-0 pl-0">
                    <div id="tab{{formId}}" [class]="selectedIndex ==formId?'tab-pane fade in active show':'tab-pane fade'">
                      <div class="col-sm-12 p-0">
                        <hdsbpm-form-repeat class="hunoModal" [lstsection]="lstConfigJson" [tableName]="tableName" [tablePrimaryKey]="tablePrimaryKey" [SessionUrlPrams]="sessionUrlPrams"
                          [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode" [stageid]="stageid"
                          [processid]="processid" [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)"
                          (submit)="submit($event)"></hdsbpm-form-repeat>
                      </div>
                    </div>
                    <ng-container *ngIf="isAddMode == false && (tabclieked | async)">
                      <div *ngFor="let lst of childFormList; index as i" id="tab{{lst.form_id}}" [class]="selectedIndex ==lst.form_id?'tab-pane fade in active show':'tab-pane fade'">
                        <div class="col-sm-12 p-0" *ngIf="selectedIndex ==lst?.form_id">
                          <hdsbpm-grid-form-crud [lstsection]="lst" [SessionUrlPrams]="sessionUrlPrams" [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form-crud>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tabAttachment{{formd}}" [class]="selectedIndex ==formd?'tab-pane fade in active show':'tab-pane fade'">
                        <div class="col-sm-12 p-0">
                          <app-file-attachment id="add" [viewmode]="isDynamicGridForm" [rowid]="refKey" [metaData]="metaDataList" [form_id]="formId"
                            [documentTypeList]="attachmentType"></app-file-attachment>
                        </div>
                      </div>
                      <div *ngIf="showCustom" id="tabchecklist401" class="tab-pane fade tabchecklist401" >
                        <div class="col-sm-12 p-0">
                          <app-boarding-checklist [editrowid]="editrowid"></app-boarding-checklist>
                        </div>
                      </div>
                      <div *ngIf="showCustom" id="tabreporting401" class="tab-pane fade tabreporting401" >
                        <div class="col-sm-12 p-0">
                          <app-employee-reporting [editrowid]="editrowid"></app-employee-reporting>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                </div>
                <ngx-loading [show]="Addloader"></ngx-loading>
              </div>
            </div>
          </div>
          <div class="modal" id="editModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl modl_width" role="document">
              <div id="edit-content" class="modal-content">
                <div class="modal-body minhyt40 pt-1 pb-0" *ngIf="EditLoaded | async">
                  <label class="font9 successedit2 alert alert-success pt-1 pb-1" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
                  <label class="font9 erroredit2 alert alert-danger pt-1 pb-1" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
                  <ul class="nav nav-tabs">
                    <li class="active" *ngIf="edit_option == 1 || edit_option == 2">
                      <a data-toggle="tab" class="active" href="javascript:void(0);" (click)="tabChanged($event,formId)">{{formName}}</a>
                    </li>
                    <li *ngFor="let lst of childFormList">
                      <a *ngIf="edit_option == 1 || edit_option == 3" [class]="isAddMode?'disabled':''" id="tabchildedit{{lst.form_id}}"
                        data-toggle="tab" href="javascript:void(0);" (click)="tabChanged($event,lst.form_id)">{{lst.form_name}}</a>
                    </li>
                    <li *ngIf="formId==401">
                      <a *ngIf="(edit_option == 1 || edit_option == 3)" [class]="isAddMode?'disabled':''" id="tabchildedit__401" href="javascript:void(0);"
                      data-toggle="tab" (click)="onCustomClick()">Check List</a>
                    </li>
                    <li *ngIf="formId==401">
                      <a *ngIf="(edit_option == 1 || edit_option == 3)" [class]="isAddMode?'disabled':''" id="tabchildeditReport__401" href="javascript:void(0);"
                      data-toggle="tab" (click)="onCustomClickReporting()">Reporting</a>
                    </li>
                    <li *ngIf="is_attachment == true">
                      <a [class]="isAddMode?'disabled':''" data-toggle="tab" href="javascript:void(0)" (click)="tabChanged($event,formd)">Attachments</a>
                    </li>
                    <li class="pull-right clsicn">
                      <button type="button" class="close" aria-label="Close" (click)="closeModel();">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </li>

                  </ul>
                  <div class="tab-content tab-contents pl-0 pr-0  pt-0  ">
                    <div id="tabedit{{formId}}" [class]="(selectedIndex ==formId && !showCustom)?'tab-pane fade in active show':'tab-pane fade'" *ngIf="edit_option == 1 || edit_option == 2">
                      <div class="col-sm-12 p-0">
                        <hdsbpm-form-repeat [lstsection]="lstConfigJson" *ngIf="isDynamicGridForm != true" [SessionUrlPrams]="sessionUrlPrams" [tableName]="tableName"
                          [tablePrimaryKey]="tablePrimaryKey" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode"
                          [isCopyAdd]="isCopyAddStatus" [stageid]="stageid" [processid]="processid" [connJson]="connJson" [nodeJson]="nodeJson"
                          (processsubmit)="processsubmit($event)" (submit)="submit($event)"></hdsbpm-form-repeat>
                        <hdsbpm-formview [section]="formAllField" *ngIf="isDynamicGridForm == true"></hdsbpm-formview>
                      </div>
                    </div>
                    <ng-container *ngIf="isAddMode == false && (tabclieked | async)">
                      <div *ngFor="let lst of childFormList; index as i" id="tabedit{{lst.form_id}}" [class]="selectedIndex ==lst.form_id?'tab-pane fade in active show':'tab-pane fade'">
                        <div class="col-sm-12 p-0" *ngIf="selectedIndex ==lst?.form_id">
                          <hdsbpm-grid-form-crud [lstsection]="lst" *ngIf="(edit_option == 1 || edit_option == 3) && isDynamicGridForm != true" [SessionUrlPrams]="sessionUrlPrams"
                            [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form-crud>
                          <hdsbpm-grid [lstsection]="lst" *ngIf="(edit_option == 1 || edit_option == 3) && isDynamicGridForm == true " [SessionUrlPrams]="sessionUrlPrams"
                            [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid>
                        </div>
                      </div>
                      <div *ngIf="showCustom" id="tabchecklist401" class="tab-pane fade tabchecklist401" >
                        <div class="col-sm-12 p-0">
                          <app-boarding-checklist [editrowid]="editrowid"></app-boarding-checklist>
                        </div>
                      </div>
                      <div *ngIf="showCustom" id="tabreporting401" class="tab-pane fade tabreporting401" >
                        <div class="col-sm-12 p-0">
                          <app-employee-reporting [editrowid]="editrowid"></app-employee-reporting>
                        </div>
                      </div>
                      <div id="tabeditAttachment{{formd}}" [class]="selectedIndex ==formd?'tab-pane fade in active show':'tab-pane fade'">
                        <div class="col-sm-12 p-0">
                          <app-file-attachment id="edit" [rowid]="refKey" [viewmode]="isDynamicGridForm" [metaData]="metaDataList" [form_id]="formId"
                            [documentTypeList]="attachmentType"></app-file-attachment>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <!-- <div *ngIf="tabshowloader" class="d-flex justify-content-center cstmspinner">
          <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
          </div>
        </div>  -->

                  <ngx-loading [show]="tabshowloader"></ngx-loading>
                </div>

              </div>
            </div>
          </div>
          <div class="modal" id="viewModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div id="viewcontent" class="modal-content" *ngIf="ViewLoaded | async">
                <div class="modal-header p-2">
                  <h6 class="modal-title">View</h6>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body minhyt40 pt-1 popcstm" *ngIf="ViewLoaded | async">
                  <hdsbpm-formview [section]="formAllField"></hdsbpm-formview>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="checkListModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">

            <div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
              <div class="modal-content" >
                <div class="modal-header p-2">
                  <h6 class="modal-title">Check List</h6>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
               
                <div class="modal-body minhyt40tvh">
                  <table class="table table-bordered thead-dark">
                    <thead>
                      <tr>
                        <th>SL No.</th>
                        <th>Check List</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let checklist of checkList; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{checklist.varibale_name}}</td>
                        <td><input type="checkbox" id="checkListId_{{checklist.checklist_id}}" (change)="checkListSelection($event,checklist.checklist_id)" name="checkListName" [(ngModel)]="checklist.is_check"></td>
                        <!-- <td><input type="checkbox" id="checkListId_{{checklist.checklist_id}}" name="checkListName" [(ngModel)]="checklist.is_check"></td> -->
                      </tr>
                   
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer p-2">
                  <button type="button" class="btn btn-primary" (click)="SaveChecklist()">Save changes</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <ngx-loading [show]="isLoading"></ngx-loading>
  </div>
</div>