import { Component, OnInit,OnDestroy,ViewEncapsulation } from '@angular/core';
import * as xlsx from 'xlsx';
import { Router } from '@angular/router'; 
import {FormService} from '../../_services/form.service';
import { SectionConfig,SessionUrlPrams } from "../../field.interface";
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import { Subscription } from 'rxjs';
import { GridOptions, IDatasource, IGetRowsParams, GridApi } from 'ag-grid-community';
import { Page } from '../../_models/page';
import { IgxGridComponent,DisplayDensity,IgxCsvExporterService,IgxExcelExporterService, IgxGridRowComponent, Transaction , IgxStringFilteringOperand,ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy,GridPagingMode,  IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-huno-main',
  templateUrl: './huno-main.component.html',
  styleUrls: [
    // '../../../node_modules/primeicons/primeicons.css',
    '../../../assets/scss/ngxdatatable.component.scss',
    '../../../assets/scss/material.scss',
    '../../../assets/scss/ngx-datatable.css',
    // '../../../node_modules/@swimlane/ngx-datatable/release/assets/icons.css',
    './huno-main.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HunoMainComponent implements OnInit,OnDestroy {
  gridApi: GridApi;
  subscription: Subscription;
  frameworkComponents: any;
  formId:any=0;
  processid:any;
  stageid:any;
  form_viewid:any=0;
  formName:any;
  
  tableName:any;
  tablePrimaryKey : any
  SectionConfig:SectionConfig[]=[];  
  formDisplayJSON:any;
  nodeJson:any;
  connJson:any;
  sessionUrlPrams:SessionUrlPrams; //new added

  filtersLoaded: Promise<boolean>;
  FormViewLoaded:Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  ViewLoaded: Promise<boolean>;
  EditLoaded: Promise<boolean>;
  DataRefreshLoaded: Promise<boolean>;
  DataRefreshLoadedngx:boolean=true;
  tabshowloader:boolean=false;  
  isAddMode:boolean=true;
  is_attachment: boolean=false;
  ErrorOccurred:String="Error occurred!!";
  SuccessMasg:any;
  lookupArr:any=[];
  editValueRow:any;
  editrowid:any;
  viewFilterString:any;
  filterCriteria:any="";
  refKey:any=0;

  ddlarrGidView:any=[];
  dateArrGidView:any=[];
  
  childFormList: any[]=[];
  metaDataList: any[]=[];
  attachmentType: any[]=[];

  metadata: any;

  pagesize="10";
  columnDefs:any[]=[];
  rowData:any[]=[];

  isAdd:boolean=false;
  isEdit:boolean=false;
  isView:boolean= false;
  isExpotable:boolean=false;
  isResetFilter:Boolean=false;
  edit_option:number=1;
  add_edit: Promise<boolean>;
  tabclieked: Promise<boolean>=Promise.resolve(false);
  private overlayLoadingTemplate;
  private overlayNoRowsTemplate;
  view_filter:any;
  gridType: string="ag";
  // for ngx grid
  page = new Page();
  sizeList: any[] = [];
  rowLimit: number;
  rowCount:any;
  pageSize:any;
  curPage: any;
  data:any[]=[];
  createAutomationIds: string="";
  updateAutomationIds: string="";
  formAllField:any;
  isDynamicGridForm : boolean=false;
  isviewMode:boolean = false;
  selectedIndex:any; //add by pradeep on 5/5/2021 for selected tab
  form_validation: any[]=[];
  lstConfigJson:any;
  constructor(
    private router: Router,
    private formService : FormService,
    private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
    this.overlayLoadingTemplate =
    '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span></div>';
    this.overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">This is a custom \'no rows\' overlay</span>';
   }

   gridOptions: GridOptions = {
    pagination: true,
    rowModelType: 'infinite',
    // cacheBlockSize: Number(this.pagesize),
    // paginationPageSize: Number(this.pagesize),
    
  };
  
  dataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {   
      this.onBtShowLoading() 
      this.formService.getFormFormViewsData(this.lookupArr,this.tableName,this.tablePrimaryKey,this.filterCriteria,this.view_filter).subscribe(data => {
        //console.log("data==>",data);
        this.rowData=data.rows;
        data=data.rows;
        setTimeout(() => {         
          let rowsThisPage = data.slice(params.startRow, params.endRow);
          let lastRow = -1;
          if (data.length <= params.endRow) {
            lastRow = data.length;
          }
          params.successCallback(
            rowsThisPage, lastRow
          );
          this.onBtHide();
        }, 500);
      },err=>{
        this.toastrService.error(err.error.message)        
      });
    }
  }
  tabChanged(event,tabIndex) {
    this.tabshowloader=true;
    this.tabclieked=Promise.resolve(false);
    //console.log("tab changed",tabIndex,event);
    this.selectedIndex =tabIndex;
    this.tabclieked=Promise.resolve(true);
     setTimeout(() => {
              this.tabshowloader=false;
            },1000); 
     
    

   
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(this.dataSource)
  }
  onBtShowLoading() {
    this.gridApi.showLoadingOverlay();    
  }

  onBtShowNoRows() {
    this.gridApi.showNoRowsOverlay();
  }

  onBtHide() {
    this.gridApi.hideOverlay();
  }
  ngOnInit(): void {
    this.isAddMode=true;
    this.tabclieked=Promise.resolve(false);
    
    let uri=this.router.url.split("?");
    if(uri.length>1){
      var str = uri[1];
      var value=str.split("&");
      if(value[0].indexOf('frmid')>=0){
        this.formId=value[0]?value[0].split('=')[1]:null;
      }
      if(value[0].indexOf('vid')>=0){
        this.form_viewid=value[0]?value[0].split('=')[1]:null;
      }
      if(value[1].indexOf('frmid')>=0){
        this.formId=value[1]?value[1].split('=')[1]:null;
      }
      if(value[1].indexOf('vid')>=0){
        this.form_viewid=value[1]?value[1].split('=')[1]:null;
      }
      this.selectedIndex =this.formId;
      
      this.sessionUrlPrams={
        frmid:this.formId,
        vid:this.form_viewid,
        UserCode:"UserCode",
        UserName:"pradeepsingh",
        ProfileId:1,
        Location:"Location",
        processtype:"ext",
        department:"1",
        office:"1"
      };
    }
    this.getFormDetails(0); 

    this.rowLimit = 10;
    this.page.size = 10
    this.page.totalElements = 0;
    this.sizeList = this.page.sizeList;
    
  }
  getFormDetails(val){
    this.subscription=this.formService.getDataByFormId(this.formId).subscribe(res => {
      // console.log("res===>",res.rows);
      let formList=res.rows;
      if(formList.length>0){
        this.createAutomationIds = formList[0]["create_automation_ids"]?formList[0]["create_automation_ids"]:"";
        this.updateAutomationIds = formList[0]["update_automation_ids"]?formList[0]["update_automation_ids"]:"";        
        this.formName=formList[0]["form_name"];
        this.formId = formList[0]["form_id"];
        this.processid=formList[0]["processid"];
        this.tableName= formList[0]["table_name"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase(); 
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
      }
      this.getFormViewDetails();
    });
  }
  
  getFormViewDetails(){
    this.columnDefs=[];
    //let query="select fv.is_add,fv.is_edit,fv.is_view,fv.is_exportable,fv.child_form_ids, fv.view_id,fv.field_json,fv.is_attachment,fv.meta_data,fv.attachment_type,f.primary_key,fv.edit_option  from app_form_view_details fv left join app_forms f on fv.form_id=f.form_id  where  fv.view_id="+this.form_viewid;
    this.subscription=this.formService.getFormViewById(this.form_viewid).subscribe(res => {
      let formViewList=res.rows;  
      //console.log("fromViewjson===>",formViewList,formViewList[0]["view_type"]);
      let a=0;
      if(formViewList.length>0){
        this.isAdd =formViewList[0]["is_add"]?formViewList[0]["is_add"]:false;
        this.isEdit =formViewList[0]["is_edit"]?formViewList[0]["is_edit"]:false;
        this.isView =formViewList[0]["is_view"]?formViewList[0]["is_view"]:false;
        this.isExpotable =formViewList[0]["is_exportable"]?formViewList[0]["is_exportable"]:false;
        let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        this.edit_option=formViewList[0]["edit_option"];
        this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;                 
        this.view_filter=this.formService.getConditionQueryByJson(formViewList[0]["filter_json"],this.sessionUrlPrams) 
        this.gridType= formViewList[0]["grid_type"]?formViewList[0]["grid_type"]:"ag";  
        let view_type= formViewList[0]["view_type"]?formViewList[0]["view_type"]:1;  
        let data_for_only= formViewList[0]["data_for_only"]?formViewList[0]["data_for_only"]:"all";  
        let child_form_view_json=formViewList[0]["child_form_view_ids"]?JSON.parse(formViewList[0]["child_form_view_ids"]):[];
        this.form_validation=formViewList[0]["validation"]?JSON.parse(formViewList[0]["validation"]):[]
        // console.log(this.form_validation)
        // console.log(child_form_view_json)

        if(child_form_view_json.length>0){
          child_form_view_json=child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId="";
          for(let r=0;r<child_form_view_json.length; r++){
            childFormId=childFormId+child_form_view_json[r]["formid"]+",";
          }
          childFormId=childFormId.substring(0,childFormId.length-1)
        }
        console.log(childFormId,child_form_view_json)
        //console.log("view_filter==>",this.view_filter);
        if(data_for_only !="all" && this.filterCriteria !=""){
          this.filterCriteria= this.filterCriteria +" and a.createdby="+this.sessionUrlPrams.ProfileId; //this.SessionUrlPrams[data_for_only]
        }
        //console.log("prawej ==>",this.is_attachment,this.attachmentType,this.metaDataList)
        if(childFormId != "" && childFormId != undefined && childFormId != null){
          this.subscription=this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
            this.childFormList=childRes.rows;
            if(child_form_view_json.length>0){
              for(let a=0;a<this.childFormList.length;a++){
                let arrView=child_form_view_json.filter(d=>d.formid == this.childFormList[a]["form_id"])
                if(arrView.length>0){
                  this.childFormList[a]["viewid"]=arrView[0]["viewid"]
                  this.childFormList[a]["display_order"]=arrView[0]["orderno"]
                  }else{
                    this.childFormList[a]["viewid"]=0;
                    this.childFormList[a]["display_order"]=0;
                  }
                // console.log(arrView)
              }
            }
            this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
            // console.log(JSON.stringify(this.childFormList)) 
          });
        }

        if(fromViewjson.length>0){
          let selectedFieldList=JSON.parse(fromViewjson)
          var column='';
          //console.log("fromViewjson===>",fromViewjson,view_type);
          selectedFieldList.forEach(fieldprop => {
            if(fieldprop.control_type == 5 || fieldprop.control_type == 10 ){
              if(view_type==1){
                this.ddlarrGidView.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3","control_type":fieldprop.control_type})
              }else{
                this.ddlarrGidView.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":fieldprop.display_field,"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }            
            else if(fieldprop.control_type == 7 || fieldprop.control_type == 8){
              if(view_type==1){
                this.dateArrGidView.push({"index":a,"columnname":fieldprop.field_name,"type":"1"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"4","control_type":fieldprop.control_type})
              }else{
                this.dateArrGidView.push({"index":a,"columnname":fieldprop.field_name,"type":"1"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"4","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":fieldprop.display_field,"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }
            else if(fieldprop.control_type == 14 || fieldprop.control_type == 15 || fieldprop.control_type == 19 || fieldprop.control_type == 22){
              if(view_type==1){
                let displayField=JSON.parse(fieldprop.display_field)
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,
                "reference_form_id":fieldprop.reference_form_id,"value_field":fieldprop.value_field,
                "display_field":displayField[0]["id"],"field_name":fieldprop.field_name, "type":"2","control_type":fieldprop.control_type})
              }else{
                let displayField=JSON.parse(fieldprop.display_field)
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"2","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":displayField[0]["id"],"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }else{
              if(view_type==1){
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name, "type":"1","control_type":fieldprop.control_type})
              }else{
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"1","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":fieldprop.display_field,"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }
            a++;
            this.columnDefs.push({field:fieldprop.field_name.toString().toLowerCase(),headerName:fieldprop.field_caption,sortable:fieldprop.sortable,filter:fieldprop.filterable ,resizable:fieldprop.resizable,width:fieldprop.column_width, wrapText: true,autoHeight: true});
          });
          this.columnDefs.push({
            headerName: 'Action',
            pinned: 'right',
            field:"val",
            cellRenderer: 'buttonRenderer',
            cellRendererParams:{
              actionList:[
                {label:"view",for:"view",status:this.isView,form_status:1,db_status:1,str:"view",onClick:this.onClickView.bind(this)},
                {label:"Edit",for:"view",status:this.isEdit,form_status:1,db_status:1,str:"edit",onClick:this.onEdit.bind(this)},
              ]},
            width:120,
            resizable:true
          });
         
          if(this.gridType == "ngx"){
            this.bindNgxDataGrid(this.page,0)
          }
          
          
          this.DataRefreshLoaded = Promise.resolve(true);
          //this.FormViewLoaded = Promise.resolve(true);
        }
      }
    });
  }
  // setStatus(){
  //   this.isAddMode =true;
  // }
  onAddClick(){
    this.selectedIndex=this.formId;
    this.isDynamicGridForm=false;
    //console.log("this.SectionConfig==>Add Click") 
    this.add_edit=Promise.resolve(false);
    this.SectionConfig=null;
    this.lstConfigJson=null;
    this.isAddMode=true;
    this.FormViewLoaded=Promise.resolve(false);
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control Section hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0  || d.visible_stage.toString() =="")));
        this.stageid=intStageid;
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0  || d.visible_stage.toString() =="")));
        
      }
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible==null || field?.visible=="always" || field?.visible=="add" || field?.visible=="hidden");
      });
      this.SectionConfig=this.SectionConfig.filter(sec=>(sec.FieldConfig?.length >0 || sec.section_dependent==true) && (sec?.isCaptionVisiable =="always"|| sec?.isprocess =="1" ) );
    }
    this.lstConfigJson={      
      "section_config_json":this.SectionConfig,
      "form_validation_json": this.form_validation,
      "form_attachment_json": this.attachmentType
    }
    this.FormViewLoaded=Promise.resolve(true);
    setTimeout(() => {$("#exampleModal").modal('show');}, 100);  
    
  }


  editView(e){
    this.selectedIndex=this.formId;
    this.add_edit=Promise.resolve(false);
    this.EditLoaded=Promise.resolve(false);
     this.tabshowloader=true;
    this.SectionConfig=null;
    this.lstConfigJson=null;
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control Section hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0  || d.visible_stage.toString() =="")));
        this.stageid=intStageid;
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0  || d.visible_stage.toString() =="")));
      }
       //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="edit" || field?.visible=="hidden");
      });
      this.SectionConfig=this.SectionConfig.filter(sec=>(sec.FieldConfig?.length >0 || sec.section_dependent==true) && (sec?.isCaptionVisiable =="always" || sec?.isprocess =="1" ) );
      //console.log("this.SectionConfig==>",this.SectionConfig)  
  
      this.isAddMode=false;
      if(this.gridType != "ngx"){
        this.editrowid=e.rowData[this.tablePrimaryKey];
        this.refKey=e.rowData[this.tablePrimaryKey];
      }else{
        this.editrowid=e[this.tablePrimaryKey];
        this.refKey=e[this.tablePrimaryKey];
      }
      this.subscription=this.formService.getFormRowDataById(this.tableName,this.tablePrimaryKey,this.editrowid).subscribe(res => {
        //console.log("rowData==",res.rows[0]);
        this.editValueRow=JSON.parse(JSON.stringify(res.rows[0]));
        //console.log("sss this.formAllField===>",this.editValueRow,this.edit_option,this.EditLoaded);
        this.lstConfigJson={      
          "section_config_json":this.SectionConfig,
          "form_validation_json": this.form_validation,
          "form_attachment_json": this.attachmentType
        }
        this.EditLoaded=Promise.resolve(true);
        setTimeout(() => {$("#editModal").modal('show');}, 100);   
        
        if(this.edit_option == 3){
            if(this.childFormList.length>0){        
              let formid=this.childFormList[0]["form_id"];
              let tabid="#tabchildedit"+formid;
              setTimeout(() => {$(tabid).click();}, 100);        
            }
        }
      });
    }
      setTimeout(() => {
              this.tabshowloader=false;
            },1000); 
  }
  closeModel(){
    this.selectedIndex=this.formId;
    setTimeout(() => {$("#editModal").modal('hide');}, 0);   
  }
  onClickView(e) {   
    var id="";
    if(this.gridType != "ngx"){
      id=e.rowData[this.tablePrimaryKey];
    }else{
      id=e[this.tablePrimaryKey];      
    }  
    this.subscription=this.formService.getFormRowDataById(this.tableName,this.tablePrimaryKey,id).subscribe(res => {
       this.formAllField=JSON.parse(this.formDisplayJSON);
      for(var r=0; r<this.formAllField.length; r++){ 
        var regConfig=this.formAllField[r]["FieldConfig"]; 
        for(var i=0; i<regConfig.length; i++){
          regConfig[i].value=res.rows[0][regConfig[i].name];
          regConfig[i].type='fieldview';
        } 
        this.formAllField[r].FieldConfig=regConfig;
      }
      this.isDynamicGridForm=true;
      this.editView(e)    
    });
  }

  onEdit(e){
    this.isDynamicGridForm=false;
    this.editView(e)     
  }

  refreshTable(keyvalue:any,add_edit:any)
  {
    //this.subscription=this.formService.getFormData(this.ViewTableQry +" where "+this.tablePrimaryKey +" = "+keyvalue).subscribe(res => {
    let strFilter="a."+this.tablePrimaryKey +" = "+keyvalue;
    this.subscription=this.formService.getFormFormViewsData(this.lookupArr,this.tableName,this.tablePrimaryKey,strFilter,this.view_filter).subscribe(res => {
      let tempRowData=res.rows;
      //console.log(JSON.stringify(res.rows),this.rowData,add_edit)
      for(var c=0; c<tempRowData.length; c++){
        let firstColumnname = this.columnDefs[0]["field"];
        let rowid =tempRowData[c][firstColumnname];
        for(let a=0;a<this.ddlarrGidView.length; a++){
          let columnValue= tempRowData[c][this.ddlarrGidView[a]["columnname"]];
          let jsonList=this.ddlarrGidView[a]["dynamiclist"]?this.ddlarrGidView[a]["dynamiclist"]:[];
          if(jsonList.length>0){
            let valArr= jsonList.filter(d=>d.text == columnValue); 
            let val="";
            if(valArr.length >0){
              val=valArr[0]["binding"];
            }                            
            tempRowData.filter(d=> d[firstColumnname] == rowid)[0][this.ddlarrGidView[a]["columnname"]]=val;                
          }
        }   
        if(add_edit ==1){
          this.rowData.push(tempRowData[c]);
        }else if(add_edit ==2){
          //console.log("asdasjh Not working pks",this.EditLoaded)

          // Not working pks
          //this.rowData.filter(d=> d[this.tablePrimaryKey] == keyvalue)[0]=tempRowData[c]; 
          var i=this.rowData.length;
          while (i--) {
            if(keyvalue.toString().indexOf(this.rowData[i][this.tablePrimaryKey])!=-1){
              this.rowData.splice(i,1);
            }
          }
          this.rowData.push(tempRowData[c]); 
        }
        
      }
      this.rowData=this.rowData.sort((a, b) => a[this.tablePrimaryKey] < b[this.tablePrimaryKey] ? 1 : -1)
      this.DataRefreshLoaded = Promise.resolve(false);
      this.DataRefreshLoaded = Promise.resolve(true);
      
      $("#clos").click();
    },err=>{
      $("#clos").click();
    })
    
    setTimeout(() => this.SuccessMasgLoaded = Promise.resolve(false),3000);
  }

  submit(value:any) {
    //console.log("pradeep value.isAddMode===>",JSON.stringify(value),value.isAddMode,value.ErrorMassage);
    if(value.isAddMode==true){
      if(value.SuccessMasgLoaded=="true"){
        //console.log("value.SuccessMasgLoaded Add==>",value.SuccessMasgLoaded);
        this.toastrService.success("Add Sucessfully !!")    
        this.refKey=value.refKey;
        this.isAddMode=false;
        if(value.primaryKey ==undefined){
          if(this.gridType=="ngx"){
            this.bindNgxDataGrid(this.page,1);
          }else{
            this.refreshTable(value.refKey,1);
          }
        }
      }else{
        this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")")      
      }
      this.callIntimationTrigger(this.formId,value.refKey,this.createAutomationIds,'create');
    }else{
      if(value.SuccessMasgLoaded =="true"){
         this.toastrService.success("Updated Sucessfully !!") 
        if(value.primaryKey ==undefined){
          if(this.gridType=="ngx"){
            this.bindNgxDataGrid(this.page,1);
          }else{
            this.refreshTable(value.refKey,2);
          }
        }
      }else{
        this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")")        
      }
      this.callIntimationTrigger(this.formId,value.refKey,this.updateAutomationIds,'update');
    }
  }
  
  getDetails(val){  
    this.viewFilterString=null;
    this.filterCriteria=null;
    this.isResetFilter=true;
    this.formId=val;
  }

  exportToExcel()
  {
    var downloadList=[];
    if(this.rowData.length == 0){
      this.rowData=this.data;
    }
   
    if(this.rowData.length>0){
      for(let robj of this.rowData){
        var obj={};
        for(let cobj of this.columnDefs)
        {
          obj[cobj.field]=robj[cobj.field];
           //jsob=jsob+'"'+obj.field+'":obj["'+obj.field+'"],';
        }
        downloadList.push(obj);
      }
      console.log(downloadList)
       this.download(downloadList,"CSV Report")
    }
  }
 
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  download(data:any, filename:string){
    var csvData = this.ConvertToCSV(data);
    var a: any = document.createElement("a");
    a.setAttribute('style', 'display:none;border:1');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    
    var isIE = /*@cc_on!@*/false || !!(<any> document).documentMode;
    
    if (isIE)
    {   
        var retVal = navigator.msSaveBlob(blob, filename+'.csv');
    }
    else{
        a.download = filename+'.csv';
    }
    // If you will any error in a.download then dont worry about this. 
    a.click();
  }
  
  
  // convert Json to CSV data
  ConvertToCSV(objArray:any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";
    for (var index in objArray[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
          line += '"'+array[i][index]+'"';
      }
      str += line + '\r\n';
    }
    return str;
  }

  onPageSizeChanged() {
    var value = $('#page-size').val();
    this.pagesize=value;
    this.refreshTable(0,0);    
  }
  removeFilter()
  {
    this.viewFilterString=null;
    this.filterCriteria=null;
    this.isResetFilter=true;
    this.getFormViewDetails();
  }
  
  apply(criteriaStr: any) {
    //console.log("criteriaStr==>",criteriaStr)
    if(criteriaStr!=null)
    {
      this.viewFilterString=criteriaStr["viewStr"];
      this.filterCriteria=criteriaStr["queryStr"];
      this.getFormViewDetails();
    }
  }
  onRowSelected(event){
    // if(this.formId == this.tempFormId){
    //   this.selectedRowId=(event.data[this.temptablePrimaryKey])
    // }
    // console.log(this.selectedRowId)
  }

  processsubmit(value: any){
    if(value.SuccessMasgLoaded=="true"){
      //console.log("process Submitted==>",value.SuccessMasgLoaded);
      this.toastrService.success("Submitted Sucessfully !!")      
    }else{
      this.toastrService.error(value.ErrorOccurred) 
    }
  }

  sendEmail(tableName,tablePrimaryKey,formId,rowid,actionStatus){
    this.formService.sendMail(tableName,tablePrimaryKey,formId,rowid,actionStatus).subscribe(res => {            
    })
  }
  ngOnDestroy() { 
    this.subscription.unsubscribe();
    
    
    this.formService=null;
    this.frameworkComponents=null
    this.formId=null
    this.processid=null
    this.stageid=null
    this.form_viewid=null
    this.formName=null
    this.tableName=null
    this.tablePrimaryKey=null
    this.SectionConfig=null
    this.formDisplayJSON=null
    this.nodeJson=null
    this.connJson=null
    this.filtersLoaded=null
    this.FormViewLoaded=null
    this.SuccessMasgLoaded=null
    this.ErrorMasgLoaded=null
    this.ViewLoaded=null
    this.EditLoaded=null
    this.DataRefreshLoaded=null
    this.isAddMode=null
    this.is_attachment=null
    this.SuccessMasg=null
    this.lookupArr=[]
    this.editValueRow=null
    this.editrowid=null
    this.viewFilterString=null
    this.filterCriteria=null
    this.refKey=null
    this.ddlarrGidView=[]
    this.dateArrGidView=[]
    this.childFormList=[]
    this.metaDataList=null
    this.attachmentType=null
    this.metadata=null
    this.rowData=null
    this.columnDefs=null
    this.pagesize=null
    this.isAdd=null
    this.isEdit=null
    this.isView=null
    this.isExpotable=null
    this.isResetFilter=null
    this.edit_option=null
    this.sessionUrlPrams=null;
  }




  // Below Method for Ngx-Datatable

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.bindNgxDataGrid(this.page,1)
  }
  onPageSizeChange(pageInfo) {
    this.page.size = +pageInfo;
    this.setPage({ offset: this.page.pageNumber });     
  }
  onFooterPage(event){
    let offset=(event.page)
    this.setPage({ offset: offset});
  }

  bindNgxDataGrid(pageInfo,status){
    // console.log(JSON.stringify(pageInfo))
    this.DataRefreshLoadedngx=false;
    let total,pagesize,limit,offset=0;
    pagesize=pageInfo.size;
    if(pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status ==0){
      offset=0;
    }else{
      offset=((pageInfo.pageNumber-1)*pagesize);
    }
    this.data=[];

    //let query="select count(*) as total from mst_tblsystemenum;select * from mst_tblsystemenum order by id limit "+pagesize+" offset "+offset;
    this.formService.getNgxGridData(this.lookupArr,this.tableName,this.tablePrimaryKey,this.filterCriteria,this.view_filter,pagesize,offset,'','').subscribe(res => {
  
      // this.formService.getFormData(query).subscribe(res => {
      this.page.totalElements=res[0].rows[0]["total"];
      this.rowCount=res[0].rows[0]["total"]
      this.data=res[1].rows;
      this.DataRefreshLoadedngx=true;      
    },err=>{
       this.toastrService.error(err.error.message)      
    });
  }

callIntimationTrigger(formid,rowid,automationids,action){
    // console.log(formid,rowid,automationids,action)
    if(automationids != "" && automationids != null && automationids != undefined){
      this.formService.setSchedularForMailAndAutomation(formid,rowid,automationids,action,this.sessionUrlPrams["ProfileId"]).subscribe(res => {
        console.log(JSON.stringify(res))
      });
    }
  }

  


}
