import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormEditComponent } from './form-edit/form-edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DisplayFormDetailComponent } from './display-form-detail/display-form-detail.component';
import { EventCalenderComponent } from './custom-component/event-calender/event-calender.component';
import { FileAttachmentComponent } from './file-attachment/file-attachment.component';
import { InboxOutboxComponent } from './system-component/inbox-outbox/inbox-outbox.component';
import { InprocessCompletedComponent } from './system-component/inprocess-completed/inprocess-completed.component';
import { GridTestNewComponent } from './grid-test-new/grid-test-new.component';
import { HunoMainComponent } from './custom-component/huno-main/huno-main.component';
import { DashboardnewComponent } from './system-component/dashboardnew/dashboardnew.component';
import { DynamicDashboardComponent } from './system-component/dynamic-dashboard/dynamic-dashboard.component';
import { ActProcessComponent } from './system-component/act-process/act-process.component';
import { HunoMainIgxComponent } from './custom-component/huno-main-igx/huno-main-igx.component';
import { ActProcessNewComponent } from './system-component/act-process-new/act-process-new.component';
import { StatusTrackerComponent } from './system-component/status-tracker/status-tracker.component';
import { ActProcessNewxComponent } from './system-component/act-process-newx/act-process-newx.component';
import { AuthGard } from './Auth/auth-gard';
import { MisReportComponent } from './custom-component/mis-report/mis-report.component';
import { ChangePasswordComponent } from './custom-component/change-password/change-password.component';
import { LoginnNewMeghComponent } from './loginn-new-megh/loginn-new-megh.component';
import { ManageBannerComponent } from './custom-component-admin/manage-banner/manage-banner.component';
import { CardDisplayComponent } from './system-component/card-display/card-display.component';
import { ConfirmationMsgComponent } from './confirmation-msg/confirmation-msg.component';
import { SuccessfullmsgComponent } from './successfullmsg/successfullmsg.component';
import { SellerRegistrationListComponent } from './custom-component-admin/seller-registration-list/seller-registration-list.component';
// import { SellerDetailComponent } from './custom-component-admin/seller-detail/seller-detail.component';
import { ProductDetailsComponent } from './custom-component-admin/product-details/product-details.component';

import { ProductListingComponent } from './custom-component-admin/product-listing/product-listing.component';
import { SellerProfileComponent } from './custom-component-admin/seller-profile/seller-profile.component';
import { TotalSellerListComponent } from './custom-component-admin/total-seller-list/total-seller-list.component';
import { TotalProductListingComponent } from './custom-component-admin/total-product-listing/total-product-listing.component';
import { SellerWiseProductComponent } from './custom-component-admin/seller-wise-product/seller-wise-product.component';
import { ManageOrderComponent } from './custom-component-admin/manage-order/manage-order.component';
import { BuyerListComponent } from './custom-component-admin/buyer-list/buyer-list.component';
import { BuyerOrderlistComponent } from './custom-component-admin/buyer-orderlist/buyer-orderlist.component';
// import { SellerRegistrationComponent} from './custom-component-admin/seller-registration/seller-registration.component'
import { AddProductComponent } from './custom-component-admin/add-product/add-product.component';
import { AddConfigurableProductComponent } from './custom-component-admin/add-configurable-product/add-configurable-product.component';
import { AddGroupProductComponent } from './custom-component-admin/add-group-product/add-group-product.component';
import { AddCustomizableProductComponent } from './custom-component-admin/add-customizable-product/add-customizable-product.component';
import { AddNewDealComponent } from './add-new-deal/add-new-deal.component';
import { TotalOrderHistoryComponent } from './custom-component-admin/total-order-history/total-order-history.component';
import { MyOrdersComponent } from './custom-component-admin/my-orders/my-orders.component';
import { BrandListingComponent } from './custom-component-admin/brand-listing/brand-listing.component';
import { AddBusinessCardComponent } from './custom-component-admin/add-business-card/add-business-card.component';
import { BusinessCardComponent } from './custom-component-admin/business-card/business-card.component';
import { UserLoginComponent } from './custom-component-admin/user-login/user-login.component';
import { BuyerAccountComponent } from './custom-component-admin/buyer-account/buyer-account.component';
import { CheckboxRequiredValidator } from '@angular/forms';
import { AddToCartComponent } from './custom-component-admin/add-to-cart/add-to-cart.component';
import { CheckoutComponent } from './custom-component-admin/checkout/checkout.component';
import { WishlistComponent } from './custom-component-admin/wishlist/wishlist.component';
import { QuickOrderComponent } from './custom-component-admin/quick-order/quick-order.component';
import { BulkorderQueryListComponent } from './custom-component-admin/bulkorder-query-list/bulkorder-query-list.component';
import { GstMasterComponent } from './custom-component-admin/gst-master/gst-master.component';
import { ManageAllOrdersComponent } from './custom-component-admin/manage-all-orders/manage-all-orders.component';
import { CreateUserComponent } from './corporate/create-user/create-user.component';
import { UsersComponent } from './corporate/users/users.component';
import { UserDetailsComponent } from './corporate/user-details/user-details.component';
import { CreateOrderComponent } from './order-master/create-order/create-order.component';
import { NewGiftCodeComponent } from './order-master/new-gift-code/new-gift-code.component';
import { GiftCodeComponent } from './order-master/gift-code/gift-code.component';
import { ViewGiftCodeComponent } from './order-master/view-gift-code/view-gift-code.component';
import { YearMasterComponent } from './custom-component-admin/year-master/year-master.component';
import { PaymentHistoryComponent } from './custom-component-admin/payment-history/payment-history.component';
import { AddSellerComponent } from './add-seller/add-seller.component';
import { CreateAccountSellerComponent } from './create-account-seller/create-account-seller.component';
import { RightPermissionComponent } from './custom-component/right-permission/right-permission.component';
import { AddVariationsComponent } from './add-variations/add-variations.component';
import { FeedbackReviewComponent } from './feedback-review/feedback-review.component';

const routes: Routes = [
  { path: '', component: LoginnNewMeghComponent },
  // {path: 'login' ,component : UserLoginComponent},
  { path: 'login', component: LoginnNewMeghComponent },
  // {path: 'seller-registration', component: SellerRegistrationComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGard] },
  {
    path: 'event-calender',
    component: EventCalenderComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'display-form-detail',
    component: DisplayFormDetailComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'file-attachment',
    component: FileAttachmentComponent,
    canActivate: [AuthGard],
  },
  { path: 'inbox', component: InboxOutboxComponent, canActivate: [AuthGard] },
  { path: 'outbox', component: InboxOutboxComponent, canActivate: [AuthGard] },
  {
    path: 'draft',
    component: InprocessCompletedComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'drafts',
    component: InprocessCompletedComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'submitted',
    component: InprocessCompletedComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'approved',
    component: InprocessCompletedComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'grid-attachment',
    component: GridTestNewComponent,
    canActivate: [AuthGard],
  },
  { path: 'request-form', component: FormEditComponent },
  { path: 'huno-main', component: HunoMainComponent, canActivate: [AuthGard] },
  {
    path: 'dashboardnew',
    component: DashboardnewComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'newdashboard',
    component: DashboardnewComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'usersetup',
    loadChildren: () =>
      import('./usersetup/usersetup.module').then((m) => m.UsersetupModule),
  },
  {
    path: 'company-dashboard',
    component: DynamicDashboardComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'act-process',
    component: ActProcessComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'huno-main-igx',
    component: HunoMainIgxComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'act-process-new',
    component: ActProcessNewComponent,
    canActivate: [AuthGard],
  },
  { path: 'status-tracker', component: StatusTrackerComponent },
  {
    path: 'act-process-newx',
    component: ActProcessNewxComponent,
    canActivate: [AuthGard],
  },
  { path: 'misreport', component: MisReportComponent, canActivate: [AuthGard] },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'card-view-display',
    component: CardDisplayComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'confirmation',
    component: ConfirmationMsgComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'successfull-msg',
    component: SuccessfullmsgComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'manage-banner',
    component: ManageBannerComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'seller-list',
    component: SellerRegistrationListComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'total-seller',
    component: TotalSellerListComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'seller-details',
    component: SellerProfileComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'product-details',
    component: ProductDetailsComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'seller-profile',
    component: SellerProfileComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'product-listing',
    component: ProductListingComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'total-product-listing',
    component: TotalProductListingComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'seller-wise-product',
    component: SellerWiseProductComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'manage-order',
    component: ManageOrderComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'buyer-list',
    component: BuyerListComponent,
    canActivate: [AuthGard],
  },
  { path: 'upload-catalog', component: AddProductComponent },
  {
    path: 'buyer-orderlist',
    component: BuyerOrderlistComponent,
    canActivate: [AuthGard],
  },
  { path: 'configurable-product', component: AddConfigurableProductComponent },
  { path: 'group-product', component: AddGroupProductComponent },
  { path: 'customizable-product', component: AddCustomizableProductComponent },
  { path: 'add-new-deal', component: AddNewDealComponent },
  {
    path: 'total-order-history',
    component: TotalOrderHistoryComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'order-details',
    component: MyOrdersComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'brand-listing',
    component: BrandListingComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'add-business-card',
    component: AddBusinessCardComponent,
  },
  {
    path: 'view-business-card',
    component: BusinessCardComponent,
  },
  {
    path: 'user-login',
    component: UserLoginComponent,
  },
  {
    path: 'buyer-account',
    component: BuyerAccountComponent,
  },
  {
    path: 'buyer-account',
    component: CheckboxRequiredValidator,
  },
  {
    path: 'add-to-cart',
    component: AddToCartComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'wishlist',
    component: WishlistComponent,
    canActivate: [AuthGard],
  },
  {
    path: 'quick-order',
    component: QuickOrderComponent,
    canActivate: [AuthGard]
  },
  {
    path: 'bulk-order-query-list',
    component: BulkorderQueryListComponent,
    canActivate: [AuthGard]
  },
  {
    path: 'gst-details',
    component: GstMasterComponent,
    canActivate: [AuthGard]
  },
  {
    path: 'manage-all-orders',
    component: ManageAllOrdersComponent,
    canActivate: [AuthGard]
  }
  ,
  {
    path: 'create-user',
    component: CreateUserComponent
  },
  {
    path: 'users',
    component: UsersComponent
  },
  {
    path: 'year-master',
    component: YearMasterComponent
  },
  {
    path: 'payment-history',
    component: PaymentHistoryComponent
  },
  { path: 'user-details', component: UserDetailsComponent },
  { path: 'create-order', component: CreateOrderComponent },
  { path: 'create-gift-code', component: NewGiftCodeComponent },
  { path: 'gift-code', component: GiftCodeComponent },
  { path: 'view-gift-code', component: ViewGiftCodeComponent },
  { path: 'add-seller', component: AddSellerComponent },
  { path: 'create-account-seller', component: CreateAccountSellerComponent },
  { path: 'right-and-permission', component: RightPermissionComponent },
  { path: 'add-variations', component: AddVariationsComponent },
  { path: 'feedback-review', component: FeedbackReviewComponent },
  


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
