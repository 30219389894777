import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from '../_services/form.service';
import { LoginService } from '../_services/login.service'
import { DomSanitizer } from '@angular/platform-browser';
import * as crypto from 'crypto-js';
import { AppSettings } from '../_services/AppSetting'
import { HttpClient } from '@angular/common/http';

// import * as commonData from '../../assets/json/commonData.json'
declare var $: any;
@Component({
  selector: 'app-loginn-new-megh',
  templateUrl: './loginn-new-megh.component.html',
  styleUrls: ['./loginn-new-megh.component.css']
})
export class LoginnNewMeghComponent implements OnInit {

  captchaVal: any;
  captchasrc: any;
  conversionEncryptOutput: any;
  user_name: any;
  username: String;
  password: any;
  isHindi: Boolean;
  labelObj: any;
  displaymarquee: boolean = false;
  otpUsername: String;
  otpMessage: String;
  err_msg: any;
  timerMsg2: String;
  otpdivStatus: Boolean = false;
  tabView: number = 0;
  msg: String;
  txtMobile: String;
  hashedsalt: String;
  bigzillaurl: String;
  profileList: any[] = [];
  userList: any[] = [];
  menuList: any[] = [];
  viewList: any[] = [];
  capchaCode: any;
  capchaResp: any;
  captchasrcValue: any;
  success_msg: any = "";
  empId = "";
  otp: any;
  timerMsg1: any;
  currentTime: any;
  isOtpVerified: boolean = false;
  smsproduction: boolean; //sms testtttttt
  isOtpSend: boolean = false;
  commonJson: any;
  eOfficeURL: any;
  isLoading:boolean=false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private formService: FormService,
    private loginService: LoginService,
    private sanitizer: DomSanitizer
  ) {
    // this.http.get('../../assets/json/commonData.json').subscribe((res) => {
    //   this.commonJson = res;
    //   let temp1 = this.commonJson.filter(x => x.name == "smsproduction");
    //   this.smsproduction = temp1[0].smsproduction;
    //   let temp2 = this.commonJson.filter(x => x.name == "eOfficeURL");
    //   this.eOfficeURL = temp2[0].URL;
    // });
  }

  ngOnInit(): void {
    // this.getClientIpAddress();
    this.getCaptcha();
    localStorage.clear();
    $("#sidebar-wrapper").hide();
    $(".page-footer").hide();
    $('.sidebar').addClass('fliph');
    $('.sub-menu').removeClass('show');
    $('body').addClass('greybacklogin')
    $(function () {
      var $tabButtonItem = $('#tab-button li'),
        $tabSelect = $('#tab-select'),
        $tabContents = $('.tab-contents'),
        activeClass = 'is-active';
      $tabButtonItem.first().addClass(activeClass);
      $tabContents.not(':first').hide();
      $tabButtonItem.find('a').on('click', function (e) {
        var target = $(this).attr('href');
        $tabButtonItem.removeClass(activeClass);
        $(this).parent().addClass(activeClass);
        $tabSelect.val(target);
        $tabContents.hide();
        $(target).show();
        e.preventDefault();
      });
      $tabSelect.on('change', function () {
        var target = $(this).val(),
          targetSelectNum = $(this).prop('selectedIndex');
        $tabButtonItem.removeClass(activeClass);
        $tabButtonItem.eq(targetSelectNum).addClass(activeClass);
        $tabContents.hide();
        $(target).show();
      });
    });
    $(document).unbind('keypress').bind('keypress', function (e) {
      e.stopImmediatePropagation();
      if (e.keyCode == 13) {
        if (!$('#follow').prop('disabled')) {
          $('#btnLogin').trigger('click');
        }
      }
    });
  }

  login() {
    this.isLoading=true;
    //console.log(localStorage.getItem("userId"),this.captchaVal,this.capchaCode)
    this.capchaCode = this.loginService.getCaptchaDecrypted(this.capchaResp["ctext"])
    var loginType = null;
    var id = null;
    var role_name = null;
    this.err_msg = "";
    if (this.user_name == null || this.user_name == undefined || this.user_name == "" || this.user_name == "null" || this.user_name == "undefined") {
      this.err_msg = "User Name is required";
      this.isLoading=false;
      return false;
    }
    if (this.password == null || this.password == undefined || this.password == "" || this.password == "null" || this.password == "undefined") {
      this.err_msg = "Password is required";
      this.isLoading=false;
      return false;
    }
    if (this.captchaVal == '' || this.captchaVal == null || this.captchaVal == undefined) {
      this.err_msg = "Captcha is required";
      this.isLoading=false;
      return false
    }
    if (this.captchaVal != this.capchaCode) {
      this.err_msg = "captcha is mismatch";
      this.isLoading=false;
      return false
    }

    let currDate = new Date();
    var d, m, y, hour, minute;
    d = currDate.getDate();
    m = currDate.getMonth() + 1;
    y = currDate.getFullYear();
    hour = currDate.getHours();
    minute = currDate.getMonth();
    if (minute < 10) { minute = "0" + minute };
    if (d < 10) { d = "0" + d };
    if (m < 10) { m = "0" + m };
    var finalDt = y + "-" + m + "-" + d;
    let qry = "select sy.varibale_name, usr.user_id, usr.user_fname,usr.user_mname,usr.user_lname,usr.user_emailid,usr.user_mobile,usr.user_pan from app_tbluser usr left join app_tblsystemenum sy on sy.id = usr.user_prifix left join app_tbluserprofile as profile on profile.user_id = usr.user_id	  left join app_tblrole as roles on roles.role_id = profile.role_id  where lower(usr.user_loginname)='" + (this.user_name).toLowerCase() + "' and trim(lower(usr.user_password))='" + (this.password).toLowerCase() + "' " + "and roles.role_id =30  and ( usr.effective_to is null or usr.effective_to::date >='" + finalDt + "') and usr.effective_from::date<='" + finalDt + "'";
    this.formService.getFormData(qry).subscribe(res => {
      if (res.rows.length > 0) {
        let usr_title = "", ut = "";
        ut = res.rows[0]["varibale_name"];
        if (ut != "" && ut != null && ut != undefined && ut != "null" && ut != "undefined") {
          usr_title = ut;
        }
        // this.getToken(this.user_name, this.password)
        let userid = res.rows[0]["user_id"];
        this.empId = res.rows[0]["emp_id"];
        // console.log('--------------------------------------------', this.empId)
        let userFullName;
        if (res.rows[0]["user_mname"] == 'null') {
          userFullName = usr_title + " " + (res.rows[0]["user_fname"] ? res.rows[0]["user_fname"] : "") + " " + (res.rows[0]["user_lname"] ? res.rows[0]["user_lname"] : "");

        } else {
          userFullName = usr_title + " " + (res.rows[0]["user_fname"] ? res.rows[0]["user_fname"] : "") + " " + (res.rows[0]["user_mname"] ? res.rows[0]["user_mname"] : "") + " " + (res.rows[0]["user_lname"] ? res.rows[0]["user_lname"] : "");
        }
        let roleQuery = ` select updated_date,
        DATE_PART('day', CURRENT_TIMESTAMP-created_date) as d_diff,DATE_PART('hour', CURRENT_TIMESTAMP-created_date )as h_diff
        from app_authuser_session where user_id=${userid} order by id desc limit 1;select pro.userprofile_id,pro.role_id,r.role_name,r.role_id
		     from app_tbluserprofile pro left join app_tblrole r on pro.role_id=r.role_id
        left join app_tbluser tblu on pro.user_id=tblu.user_id
      
        where pro.user_id=`+ userid;
 
        

        var encrypt = this.loginService.getEncryptedData(userid.toString())
        // console.log(encrypt)
        this.conversionEncryptOutput = encodeURIComponent(encrypt);
        this.formService.getFormData(roleQuery).subscribe(roleResult => {
          let existsLogin = roleResult[0].rows;
          // console.log(JSON.stringify(existsLogin))
          // if(existsLogin.length>0){
          //   if(existsLogin[0]["d_diff"] == "0" && existsLogin[0]["updated_date"] == null && existsLogin[0]["h_diff"] <= 2){
          //     this.err_msg="User already logged in";
          //     return false;
          //   }
          // }
          this.profileList = roleResult[1].rows;
          if (this.profileList.length > 0) {
            // console.log("this.profileList==>",JSON.stringify(this.profileList))
            localStorage.setItem("userId", userid)
            localStorage.setItem("userName", this.user_name)
            localStorage.setItem("userFullName", userFullName)
            localStorage.setItem("role_id", this.profileList[0]["role_id"])
            localStorage.setItem("role_name", this.profileList[0]["role_name"])
         
            localStorage.setItem("profileId", this.profileList[0]["userprofile_id"])
            localStorage.setItem("user_profile_list", JSON.stringify(this.profileList))
          

            // console.log(AppSettings.SecreatKey)
            var token = crypto.AES.encrypt(this.user_name, (AppSettings.SecreatKey).toString()).toString()
            localStorage.setItem("tempToken", token)

            let role_id = this.profileList[0]["role_id"] ? this.profileList[0]["role_id"] : 0;
            let department_id = this.profileList[0]["department_id"] ? this.profileList[0]["department_id"] : 0;
            this.bindMenu(role_id, department_id)
            this.saveAuthUserSession(userid, this.user_name)
            $('body').removeClass('greybacklogin')
            // if(role_id == 14){
            //   this.router.navigateByUrl('/committee-dashboard?accesstoken='+this.conversionEncryptOutput);
            // }else if(role_id == 5 && department_id != 17 && department_id != 18){
            //   this.router.navigateByUrl('/dashboard?accesstoken='+this.conversionEncryptOutput);
            // }else{
            //   this.router.navigateByUrl('/dashboardnew?accesstoken='+this.conversionEncryptOutput);
            // }            
          }
          // this.isLoading=false;

        });
        // console.log(userid)
        // console.log(JSON.stringify(res.rows))
        
      } else {
        this.isLoading=false;
        this.err_msg = "Invalid User or Password.";
      }
    });


  }

  // getClientIpAddress() {
  //   this.http.get(AppSettings.getClientIpAddress, { responseType: 'text' }).subscribe((res: any) => {
  //     let ipAddress = res.ip;
  //     localStorage.setItem("ipaddress", ipAddress);
  //     // console.log(localStorage.getItem('ipaddress'))
  //   });
  //   // this.http.get(AppSettings.getClientIpAddress,{ responseType: 'text'}).subscribe(data => {
  //   //     let ipaddress=data;

  //   // },err => {
  //   //       console.log(err)
  //   //   });
  // }

  saveAuthUserSession(userid, user_name) {
    var inser_qry = `insert into app_authuser_session (user_id,user_name,ip_address) values(
      ${userid},
      '${user_name}',
      '${localStorage.getItem("ipaddress")}'
      );`
    this.formService.getFormData(inser_qry).subscribe(res => {
      // console.log("Added successfully");
    })
  }


  bindMenu(role_id, department_id) {
    let mennuQry = `select rdm.menu_id, am.menu_txt from z_entitymast_menu_role_department_mapping_001 rdm
    left join app_menu am on am.menu_id=rdm.menu_id where rdm.role_id=${role_id} `
    console.log(mennuQry);
    
    //--and rdm.department_id=${department_id}`
    this.formService.getFormData(mennuQry).subscribe(roleResult => {
      let result = roleResult.rows;
      this.menuList = roleResult.rows;;
      if (result.length > 0) {
        let menuids = "";
        for (let a = 0; a < result.length; a++) {
          menuids = menuids + result[a]["menu_id"] + ",";
        }
        if (menuids != "") {
          menuids = menuids.substring(0, menuids.length - 1);
          let viewQuery = `select string_agg(view_id::text, ',') as view_id from app_menu_link_mapping
          where menu_id in (${menuids});
          select amlm.menu_id,submenu_id,view_id,am.menu_txt from app_menu_link_mapping amlm
          left join app_menu am on am.menu_id=amlm.submenu_id where amlm.menu_id in (${menuids})`

          console.log('viewQuery',viewQuery)
          this.formService.getFormData(viewQuery).subscribe(viewResult => {
            let result1 = viewResult[0].rows;
            let result2 = viewResult[1].rows;
            if (result1.length > 0) {
              let viewIds = result1[0]["view_id"];
              if (viewIds != "") {
                let linkQuery = `select fd.order_id,fd.form_id,fd.form_template,fd.is_custom_form, fd.form_link_name form_link,v.link_name form_link_name ,v.view_type,v.view_id,v.child_form_ids,v.is_attachment,fd.module_id,m.modulename,fd.is_table_required from app_forms fd left join app_module m on fd.module_id=m.id left join app_form_view_details v on fd.form_id=v.form_id 
                where (fd.db_status=2 and fd.isactive=true and v.is_link_display=true and v.view_id in(${viewIds})) or fd.is_custom_form=true `
                console.log(linkQuery);
                
                this.formService.getFormData(linkQuery).subscribe(linkResult => {
                  this.viewList = linkResult.rows;
                  if (this.viewList.length > 0) {
                    for (let c = 0; c < result2.length; c++) {
                      let vid = result2[c]["view_id"];
                      let arrv = this.viewList.filter(d => d.view_id == vid)
                      if (arrv.length > 0) {
                        this.viewList.filter(d => d.view_id == vid)[0]["menu_id"] = result2[c]["menu_id"];
                        this.viewList.filter(d => d.view_id == vid)[0]["submenu_id"] = result2[c]["submenu_id"]?result2[c]["submenu_id"]:result2[c]["menu_id"];
                        this.viewList.filter(d => d.view_id == vid)[0]["submenu_txt"] = result2[c]["menu_txt"]?result2[c]["menu_txt"]:"";
                      }
                    }
                    let arr = this.viewList.filter(d => d.form_id == 339)
                    if (arr.length > 0) {
                      this.viewList.filter(d => d.form_id == 339)[0]["view_id"] = "311";
                    }
                  }
                  console.log('this.viewList=============',JSON.stringify(this.viewList))
                  let subMenulist=[];
                  for (let x = 0; x < this.menuList.length; x++) {
                    let sublist = this.viewList.filter(d => d.menu_id == this.menuList[x]["menu_id"]);
                    subMenulist=[];
                    sublist.forEach(function (value) {
                      if((subMenulist.filter(s=>s.submenu_id == value.submenu_id)).length==0){
                        let sArr=sublist.filter(d=>d.submenu_id == value.submenu_id);
                        subMenulist.push({"submenu_id":value.submenu_id,"submenu_txt":value.submenu_txt,"slink":sArr});
                      }
                    });
                    
                    this.menuList[x]["links"] = subMenulist;
                    this.menuList[x]["strng"] = x;
                  
                  }
                  //this.menuList = this.menuList.filter(d => d.links.length > 0)
                  localStorage.setItem("menuList", JSON.stringify(this.menuList))
                  console.log("pks==>",JSON.stringify(this.menuList));
                  this.gotoDashboard(role_id, department_id)
                });
              }
              // console.log(viewIds)
            } else {
              localStorage.setItem("menuList", undefined)
              this.gotoDashboard(role_id, department_id)
            }
            // console.log(viewResult.rows[0]["view_id"])
          })

        } else {
          localStorage.setItem("menuList", undefined)
          this.gotoDashboard(role_id, department_id)
        }
      } else {
        localStorage.setItem("menuList", undefined)
        this.gotoDashboard(role_id, department_id)
      }
      // console.log(roleResult.rows)
    });
  }

  gotoDashboard(role_id, department_id) {
    if (role_id == 14 || role_id == 33) {
      this.router.navigateByUrl('/employee-detail?' + localStorage.getItem('tempToken'));
      // this.router.navigateByUrl('/committee-dashboard');
    } else if (role_id == 3 && department_id != 17 && department_id != 18) {
      this.router.navigateByUrl('/dashboard?' + localStorage.getItem('tempToken'));
      // this.router.navigateByUrl('/dashboard');

    } else {
      this.router.navigateByUrl('/dashboardnew?' + localStorage.getItem('tempToken'));
      // this.router.navigateByUrl('/dashboardnew');

    }
  }

  getCaptcha() {
    this.captchaVal = "";
    this.loginService.getCatcha().subscribe(res => {
      this.capchaResp = res;
      this.captchasrc = this.sanitizer.bypassSecurityTrustHtml(res["cimagedata"]);
    })
  }

  getToken(username, password) {
    this.loginService.getToken(username, password).subscribe(res => {
      localStorage.setItem("token", res.token)
    })
  }

  timer: any;

  loginNew1() {
    this.isLoading=true;
    this.success_msg = "";
    let userName = crypto.AES.encrypt(this.user_name, AppSettings.SecreatKey).toString();
    let passwords = crypto.AES.encrypt(this.password, AppSettings.SecreatKey).toString();
    // this.getToken(userName, passwords);
    if (!!this.timer) {
      clearTimeout(this.timer);
      this.isLoading=false;
    }
    this.timer = setTimeout(() => {
      this.login();
      // this.isLoading=false;
    }, 1200);
  }

  // forgotPassword() {
  //   this.err_msg = "";
  //   this.success_msg = "";
  //   if (this.user_name == null || this.user_name == undefined || this.user_name == "" || this.user_name == "null" || this.user_name == "undefined") {
  //     this.err_msg = "User Name is required";
  //     return false;
  //   } else {
  //     let query = `select  user_mobile from app_tbluser where user_loginname='${this.user_name}'`
  //     this.formService.getFormData(query).subscribe(res => {
  //       if (res.rows.length > 0) {
  //         let mobileno = res.rows[0]["user_mobile"] ? res.rows[0]["user_mobile"] : "";
  //         if (mobileno == "" || mobileno == null || mobileno == undefined) {
  //           this.err_msg = "Mobile number does not exist";
  //           return false;
  //         } else {
  //           this.loginService.sendotpmessage(this.user_name, mobileno, 'forgotpass').subscribe(res => {
  //             this.success_msg = "otp sended on your registered mobile.";
  //           });
  //         }
  //       } else {
  //         this.err_msg = "user does not exist";
  //         return false;
  //       }

  //     });
  //   }
  // }
  // ============================OTP===============================
  // sendOtp() {
  //   this.isLoading=true;
  //   this.msg = ''
  //   this.timerMsg1 = ''
  //   this.currentTime = this.commonFunctionService.otpResendTime;
  //   this.resendButton(this.commonFunctionService.otpResendTime, 1);
  //   if (this.user_name == null || this.user_name == undefined || this.user_name == "" || this.user_name == "null" || this.user_name == "undefined") {
  //     this.msg = "User Name is required";
  //     this.isLoading=false;
  //     return false;
  //   } else {
  //     if (this.smsproduction != null && this.smsproduction != undefined) {
  //       if (this.smsproduction) {
  //         this.loginService.otpLogin((this.user_name.toString()).trim()).subscribe(result => {
  //           let res: any = result;
  //           if (res.status == 200) {
  //             $('#showmodalbutton').click();
  //             this.otp = ''
  //             this.isOtpSend = true;
  //             this.isLoading=false;
  //           }
  //           else if (res.message === 'Invalid User.') {
  //             this.msg = "User is inavlid.";
  //           }
  //           else if (res.message == 'Mobile no does not exist.') {
  //             this.msg = "User's Mobile no does not exist.";
  //           }
  //           else if (res.message == 'Invalid Mobile No.') {
  //             this.msg = "User's Mobile No. is invalid";
  //           }
  //           else if (res.status == '407') {
  //             this.msg = "Sending OTP failed. Please try after sometime.";
  //           }
  //           this.isOtpVerified = false
  //           this.isLoading=false;
  //         }, error => {
  //           this.msg = 'Network Issue or Something went wrong with data';
  //           this.isLoading=false;
  //         })
  //       }
  //       else {
  //         // this.isLoading=false;
  //         this.loginService.otpLoginForTesting((this.user_name.toString()).trim()).subscribe(result => {
  //           let res:any=result
  //           if (res.status == 200) {
  //             $('#showmodalbutton').click();
  //             this.otp = '123456'
  //             this.isOtpSend = true;
  //             this.isLoading=false;
  //           }
  //           else if (res.message === 'Invalid User.') {
  //             this.msg="User is inavlid.";
  //           }
  //           else if (res.message == 'Mobile no does not exist.') {
  //             this.msg="User's Mobile no does not exist.";
  //           }
  //           else if (res.message == 'Invalid Mobile No.') {
  //             this.msg = "User's Mobile No. is invalid";
  //           }
  //           else if (res.status == '407') {
  //             this.msg= "Sending OTP failed. Please try after sometime.";
  //           }
  //           this.isOtpVerified=false
  //           this.isLoading=false;
  //         }, error => {
  //           this.msg='Network Issue or Something went wrong with data';
  //           this.isLoading=false;
  //         })
  //       }
  //     } else {
  //       this.loginService.otpLogin((this.user_name.toString()).trim()).subscribe(result => {
  //         let res: any = result
  //         if (res.status == 200) {
  //           $('#showmodalbutton').click();
  //           this.otp = ''
  //           this.isLoading=false;
  //         }
  //         else if (res.message === 'Invalid User.') {
  //           this.msg = "User is inavlid.";
  //         }
  //         else if (res.message == 'Mobile no does not exist.') {
  //           this.msg = "User's Mobile no does not exist.";
  //         }
  //         else if (res.message == 'Invalid Mobile No.') {
  //           this.msg = "User's Mobile No. is invalid";
  //         }
  //         else if (res.status == '407') {
  //           this.msg = "Sending OTP failed. Please try after sometime.";
  //         }
  //         this.isOtpVerified = false
  //         this.isLoading=false;
  //       }, error => {
  //         this.msg = 'Network Issue or Something went wrong with data';
  //         this.isLoading=false;
  //       })
  //     }
  //   }
  // }

  resendButton(remaining, id) {
    $('#resendOtpButton' + id).prop('disabled', true);
    $('#resendOtpButton' + id).css('background', '#d48e8c');
    var m: any = Math.floor(remaining / 60);
    var s: any = remaining % 60;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    if (id == 1) {
      this.timerMsg1 = m + ':' + s;
    }
    if (id == 2) {
      this.timerMsg2 = m + ':' + s;
    }
    remaining -= 1;

    if (remaining >= 0 && this.currentTime == remaining + 1) {
      this.currentTime = remaining;
      setTimeout(data => {
        this.resendButton(remaining, id);
      }, 1000);
      return;
    }
    /* this.timerMsg1 = '';
    this.timerMsg2 = ''; */
    if (this.currentTime == 0) {
      $('#resendOtpButton' + id).prop('disabled', false);
      $('#resendOtpButton' + id).css('background', '#ac2925');
    }

  }

  // verifyOTPModal() {
  //   this.isLoading=true;
  //   this.msg = '';
  //   $('#verifyBtnId').prop('disabled', 'disabled');
  //   if (this.otp != undefined && this.otp != null && this.otp != '') {
  //     this.loginService.verifyOtp(this.otp, (this.user_name.toString()).trim()).subscribe(result => {
  //       let res: any = result
  //       if (res.status == 200) {
  //         localStorage.setItem("userData", JSON.stringify(res.user));
  //         this.router.navigate(["/reset-password"])
  //         this.isOtpVerified = true;
  //         $("#submitFile").removeClass("disabled");
  //         $('#verifyBtnId').prop('disabled', false);
  //         $('#otpclosemodalbtn').click();
  //         this.isLoading=false;
  //       }
  //       else {
  //         $("#lblmsg").css("color", "red");
  //         $('#verifyBtnId').prop('disabled', false);
  //         this.msg = res["message"];
  //         this.isLoading=false;
  //         return false
  //       }
  //     }, err => {
  //       $('#verifyBtnId').prop('disabled', false);
  //       this.isLoading=false;
  //     })

  //   } else {
  //     $("#lblmsg").css("color", "red");
  //     $('#verifyBtnId').prop('disabled', false);
  //     this.msg = "OTP  is required.";
  //     $("#txtMobileNo").focus();
  //     this.isLoading=false;
  //     return false;
  //   }
  // }
  // resendOTP(id) {
  //   this.isLoading=true;
  //   this.currentTime = 240;
  //   this.timerMsg1 = ''
  //   this.timerMsg2 = ''
  //   this.resendButton(240, id);
  //   this.loginService.otpLogin((this.user_name.toString()).trim()).subscribe(result => {
  //     let res: any = result;
  //     if (res.status == 200) {
  //       this.otp = ''
  //       this.isOtpSend = true;
  //       this.isLoading=false;
  //     }
  //     else if (res.message === 'Invalid User.') {
  //       this.msg = "User is inavlid.";
  //     }
  //     else if (res.message == 'Mobile no does not exist.') {
  //       this.msg = "User's Mobile no does not exist.";
  //     }
  //     else if (res.message == 'Invalid Mobile No.') {
  //       this.msg = "User's Mobile No. is invalid";
  //     }
  //     else if (res.status == '407') {
  //       this.msg = "Sending OTP failed. Please try after sometime.";
  //     }
  //     this.isOtpVerified = false
  //     this.isLoading=false;
  //   }, error => {
  //     this.msg = 'Network Issue or Something went wrong with data';
  //     this.isOtpVerified = false
  //     this.isLoading=false;
  //   })




  // }

  

}
