declare function api_end_point(): string;
declare function getImagepath(): string;
declare function schedular_api_end_point(): string;
declare function getPdfImagepath(): string;
declare function getClientIpAddress(): string;
declare function getSiteUrl(): string;
declare function getMassageOutTime(): number;
export class AppSettings {  
 public static API_ENDPOINT=api_end_point(); 
 public static getImagepath=getImagepath(); 
 public static schedular_api_end_point=schedular_api_end_point(); 
 public static SecreatKey="Huno@123"; 
 public static getPdfImagepath=getPdfImagepath(); 
 public static getClientIpAddress=getClientIpAddress(); 
 public static getSiteUrl=getSiteUrl();
 public static getMassageOutTime=getMassageOutTime(); 
 }

 