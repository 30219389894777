import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../../../app/_services/form.service';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionService } from 'src/app/_services/common-function.service';
import {BusinesscardService} from 'src/app/_services/businesscard.service';
import { LoginService } from 'src/app/_services/login.service';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
import { AppSettings } from '../../_services/AppSetting';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-add-business-card',
  templateUrl: './add-business-card.component.html',
  styleUrls: ['./add-business-card.component.css']
})
export class AddBusinessCardComponent implements OnInit {
  @ViewChild('inputFile') myInputVariable: ElementRef;
  card_title: any;
  price: any;
  mrp: any;
  quantity: any;
  uploadedGalaryFiles: any = [];
  isEdit: boolean = false;
  extraFeature: any;
  featurePrice: any;
  extraOptionsList: any = [];
  is_supported_name: any;
  is_supported_contact_no: any;
  is_supported_email: any;
  is_supported_designation: any;
  is_supported_Address: any;
  is_supported_logo_customization: any;
  file: any;
  formData: FormData;
  card_discription:any;
  lable: any;
  value: any;
  cardDetails: any=[];
  seller_id: any;
  userId: any;
  Slides=[{'text':'Single Sided','isSelected':false},{'text':'Double Sided','isSelected':false}]
  sellerListEdit:string = 'false';
  idc: any;
  card_id: any;
  tempFilePath: any;
  localurl: any;
  productImagesList: any[] = [];
  CorpWisepeiseList:any[]=[];
  showqtyPrice:any;
  selectedCorpid:any;
  tempcoprlist: any[] = [];
  assign_corp:any;
  corpList:any;
  assign_marketplace:any
  corp_id:any;
  txtHeight: any;
  txtWidth: any;
  minQty:any;
  maxQty:any;
  qtyprice:any;
  qtywiseprice : any;
  Corporate_price: any;
  editExtra: boolean;
  editextraIndex: any;
  editLabel: boolean;
  MassageOutTime = AppSettings.getMassageOutTime;
  editIndex: any;
  feature: any;
  fPrice: any;
  constructor(private toastrService: ToastrService,     
    private loginService:LoginService,  
    private route: ActivatedRoute , private sellerService: SellerRegistrationService, 
    private formService: FormService,public commonFunctionService: CommonFunctionService, private router: Router, private cardService: BusinesscardService) { }

  ngOnInit(): void {
    this.seller_id = localStorage.getItem('selected_seller_id');
    this.userId = localStorage.getItem('userId');
    this.getcorp();
    //console.log('userId---',this.userId)
    
    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      // console.log('check detail ', data)
     
      if (data.length > 1) {
        data = data ? JSON.parse(data) : '';
         this.card_id = data.editable_item;
         this.onCardEdit(this.card_id);
         this.sellerListEdit = localStorage.getItem('editinsellerlist');
         this.getcorp();
        // console.log("data",data.editable_item)
      }
    });
    // if (localStorage.getItem("editable-item") != null && localStorage.getItem("editable-item") != undefined) {
    //   this.onCardEdit(localStorage.getItem("editable-item"));
    // }
  }
 
  onFileChnage(event) {   
    const { files, validity } = event.target;
    let size = Math.round(Number(files[0].size) / 1024)
    if (validity.valid) {
      if (files.length > 0) {
        for(let lst of files){
          let size = Math.round(Number(files[0].size) / 1024)
          var fname = lst.name;
          var re = /(\.jpg|\.jpeg|\.png)$/i;
          if (!re.exec(fname)) {
            this.toastrService.warning("File type not supported!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          var extarr = lst.name.split(".");
        if (extarr.length > 2) {
          this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          this.myInputVariable.nativeElement.value = '';
          return false;
        }
        if (size > 5120) {
          this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          this.myInputVariable.nativeElement.value = '';
          return false;
        }
        if (this.uploadedGalaryFiles.length == 10) {
          this.toastrService.warning("You can upload maximum 10 files", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          this.myInputVariable.nativeElement.value = '';
          return false;
        }
        this.file = files;
        var reader = new FileReader();
        reader.readAsDataURL(lst);
        reader.onload = (e) => {
          console.log("e-->",e);
          this.tempFilePath = e.target.result;
          var img: any = new Image();
          img.src = e.target.result;
          img.onload = () => {
          this.txtHeight = img.height;
          this.txtWidth = img.width;         
            if (this.txtWidth == 1500 && this.txtHeight == 1500) {
              this.toastrService.success('Uploaded image successfully.', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == lst.name)).length > 0) {
                this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.myInputVariable.nativeElement.value = '';
                return false;
              } 
              this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': this.tempFilePath, "file": lst, "file_name": lst.name,"imgUrl": this.localurl, "file_size": Math.round(Number(lst.size) / 1024) });
              this.tempFilePath = ''
              img.src = ''
              $(".overlay-container").addClass("addcontainerdisable");
              this.removeClass()
            } else {
              this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
              this.myInputVariable.nativeElement.value = '';
              $(".overlay-container").addClass("addcontainerdisable");
              this.removeClass()                         
              return false
            }
          }
          // if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == lst.name)).length > 0) {
          //   this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          //   $(".overlay-container").addClass("addcontainerdisable");
          //   this.removeClass()
          //   this.myInputVariable.nativeElement.value = '';
          //   return false;
          // } 
          // this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': this.tempFilePath, "file": lst, "file_name": lst.name,"imgUrl": this.localurl, "file_size": Math.round(Number(lst.size) / 1024) });
          // this.tempFilePath = ''
          // img.src = ''
        };        
        }
        // var fname = files[0].name;
        // var re = /(\.jpg|\.jpeg|\.png)$/i;
        // if (!re.exec(fname)) {
        //   this.toastrService.warning("File Type Not Supported!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        //   $(".overlay-container").addClass("addcontainerdisable");   this.removeClass()

        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // var extarr = files[0].name.split(".");
        // if (extarr.length > 2) {
        //   this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        //   $(".overlay-container").addClass("addcontainerdisable");   this.removeClass()

        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // if (size > 5120) {
        //   this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        //   $(".overlay-container").addClass("addcontainerdisable");   this.removeClass()

        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // this.file = files;
        // var reader = new FileReader();
        // reader.readAsDataURL(this.file[0]);
        // reader.onload = (e) => {
        //   this.tempFilePath=e.target.result;
        //   var img: any = new Image();
        //   img.src = e.target.result;
        //   img.onload = () => {
        //   this.txtHeight = img.height;
        //   this.txtWidth = img.width;         
        //     if (this.txtWidth == 1500 && this.txtHeight == 1500) {
        //       this.toastrService.success('Uploaded image successfully.', '', {
        //         timeOut: this.MassageOutTime,
        //         positionClass: 'toast-top-center',
        //       });
        //       $(".overlay-container").addClass("addcontainerdisable");
        //       this.removeClass()
        //     } else {
        //       this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //       $(".overlay-container").addClass("addcontainerdisable");
        //       this.removeClass()                         
        //       return false
        //     }
        //   }
        // };
      }
    }
  }
  // onUpload() {
  //   if (this.file == null || this.file == undefined) {
  //     this.toastrService.warning("Please select an image", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
  //     $(".overlay-container").addClass("addcontainerdisable");
  //     this.removeClass()
  //     return false;
  //   }
  //   if(this.txtHeight != 1500 || this.txtWidth != 1500){
  //     this.toastrService.warning("Please select valid size images!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
  //     $(".overlay-container").addClass("addcontainerdisable");
  //     this.removeClass()
  //     return false;
  //   }
  //   if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == this.file[0].name)).length > 0) {
  //     this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
  //     $(".overlay-container").addClass("addcontainerdisable");
  //     this.removeClass()
  //     this.myInputVariable.nativeElement.value = '';
  //     return false;
  //   } else {
  //     this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1,'isSavedInDB': false, "file": this.file[0],'file_path':this.tempFilePath, "file_name": this.file[0].name,"imgUrl": this.localurl, "file_size": Math.round(Number(this.file[0].size) / 1024) });
  //     //console.log("this.uploadedGalaryFiles",this.uploadedGalaryFiles)
  //     this.file = null;
  //     this.tempFilePath=undefined;
  //     this.myInputVariable.nativeElement.value = '';
  //   }

  // }
  CorporateChange(){
    //console.log('this.assign_corp',this.assign_corp)
    if(this.assign_corp == false){
      this.tempcoprlist = [];
      this.corp_id = null;
      this.CorpWisepeiseList = [];
      this.showqtyPrice = false;
    }
    //else{
     
     // if(this.corp_id != null && this.corp_id != undefined && this.corp_id != 'undefined')
    //  this.getcorporate('0');
   // }
  
  }
  deleteFile(id) {
    this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
    this.myInputVariable.nativeElement.value = '';
  }

  deleteFromDb(id, imgid){
    this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
    let sqlQry = `delete from z_entitydtl_product_images_001 where product_id = ${this.card_id} and image_id = ${imgid}`
    this.formService.getFormData(sqlQry).subscribe(res =>{
      console.log('yes successfully delete this')
    })
  }
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',

    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],['insertImage'],
      ['insertVideo']
    ]
};
getcorp() {
  this.sellerService.getCorpfromcust().subscribe(res => {
    this.corpList = res;
    console.log(JSON.stringify(this.corpList))
  })
}
getcorporate(temCorpWisepeiseList){
   
  if(temCorpWisepeiseList != '0'){
    this.tempcoprlist = [];
    for (const it of this.corp_id) {
        this.tempcoprlist.push(this.corpList.filter(x => x.add_corporate_id == it)[0])
      }
    for(let c=0; c<this.tempcoprlist.length; c++){        
        let array=temCorpWisepeiseList.filter(d=> d.corp_id == this.tempcoprlist[c]["add_corporate_id"])
        if(array.length>0){
          this.tempcoprlist[c]["Corporate_price"] = array[0]["corp_prise"]         
      }
    }
  }else{
    
    for (const it of this.corp_id) {
      let arr=this.tempcoprlist.filter(x => x.add_corporate_id == it);
      if(arr.length == 0){
        this.tempcoprlist.push(this.corpList.filter(x => x.add_corporate_id == it)[0])
      }        
    }

    let finalData=this.tempcoprlist;

    for (let r=0; r<finalData.length; r++) {
      let arr=this.corp_id.filter(d=> d == finalData[r]["add_corporate_id"])       
      if(arr.length == 0){
        this.tempcoprlist=  this.tempcoprlist.filter(d=>d.add_corporate_id != finalData[r]["add_corporate_id"])
      }        
    }
    

    
  }

    console.log("this.tempcoprlist===",this.tempcoprlist)

}
addCorpWisepeise() {
  let selectedCorpName :any=[] ; 
  if (this.selectedCorpid == null || this.selectedCorpid == undefined || this.selectedCorpid == '') {
    this.toastrService.warning("Please select corporate Name!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if (this.selectedCorpid != null || this.selectedCorpid != undefined || this.selectedCorpid != '') {
    selectedCorpName = this.tempcoprlist.filter(x => x.add_corporate_id == this.selectedCorpid)[0].enterprise_name
  }
  if (this.minQty == null || this.minQty == undefined || this.minQty == '') {
    this.toastrService.warning("Please enter Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if (this.maxQty == null || this.maxQty == undefined || this.maxQty == '') {
    this.toastrService.warning("Please enter Max quantity " , '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if (this.qtyprice == null || this.qtyprice == undefined || this.qtyprice == '') {
    this.toastrService.warning("Please enter price  " , '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if(this.CorpWisepeiseList.length != 0){
    let existLabel = this.CorpWisepeiseList.filter(x=>x.minQty == this.minQty && x.maxQty == this.maxQty && x.qtyprice == this.qtyprice)
    if(existLabel.length > 0){
      this.toastrService.warning("This record allready exists", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
    }else{
  this.CorpWisepeiseList.push({ "selectedCorpName":selectedCorpName,"corpid":this.selectedCorpid,"minQty": this.minQty, "maxQty": this.maxQty, "qtyprice": this.qtyprice });
  this.minQty = null;
  this.maxQty = null;
  this.qtyprice = null;
  this.selectedCorpid = null;
    }
}else{
  this.CorpWisepeiseList.push({"selectedCorpName":selectedCorpName,"corpid":this.selectedCorpid, "minQty": this.minQty, "maxQty": this.maxQty, "qtyprice": this.qtyprice });
  this.minQty = null;
  this.maxQty = null;
  this.qtyprice = null;
  this.selectedCorpid = null;
}
//console.log(' this.CorpWisepeiseList', this.CorpWisepeiseList)
}
removeCorpWisepeise(i) {
  this.CorpWisepeiseList.splice(i, 1);
}
editCorpWisepeise(index) {
  console.log('index======',index ,this.CorpWisepeiseList)
  this.selectedCorpid = this.CorpWisepeiseList[index].corpid;
  this.minQty = this.CorpWisepeiseList[index].minQty;
  this.maxQty = this.CorpWisepeiseList[index].maxQty;
  this.qtyprice = this.CorpWisepeiseList[index].qtyprice;
  this.CorpWisepeiseList.splice(index, 1);
}
  reset() {
    this.card_title = null;
    this.price = null;
    this.mrp = null;
    this.quantity = null;
    this.uploadedGalaryFiles = [];
    this.extraOptionsList = [];
    this.is_supported_name = undefined;
    this.is_supported_contact_no = undefined;
    this.is_supported_email = undefined;
    this.is_supported_designation = undefined;
    this.is_supported_Address = undefined;
    this.is_supported_logo_customization = undefined;
    this.file = undefined;
    this.card_discription=null;
    this.cardDetails=[];
    this.idc=null;
    if(this.Slides.length>0){
      for (let obj of this.Slides) {
        obj.isSelected = false;
      }
    };
    this.showqtyPrice = null;
    this.assign_corp = null;
    this.assign_marketplace  = null;
    this.tempcoprlist = null;
    

  }
  back() {
    // if (localStorage.getItem("editable-card") != null && localStorage.getItem("editable-card") != undefined) {
    //   localStorage.removeItem("editable-card");
    //   this.router.navigateByUrl('/business-cards-listing' + '?' + localStorage.getItem('tempToken'));
    // }else{
      this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
    // }
    
  }

  addExtraFeature() {
    this.extraFeature = this.extraFeature?.trim();
    this.featurePrice = this.featurePrice?.trim();
    if(this.editExtra){
      if (this.extraFeature == null || this.extraFeature == undefined || this.extraFeature == '') {
        this.toastrService.warning("Please Enter External Feature Label!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
      if (isNaN(this.featurePrice) || this.featurePrice == null || this.featurePrice == undefined || this.featurePrice == '' || this.featurePrice == 0) {
        this.toastrService.warning("Please Enter External Feature Price " + '!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
      let exist_extr_featurs=this.extraOptionsList.filter((x, index)=>x.feature == this.extraFeature);
      if(exist_extr_featurs.length > 0){
        this.toastrService.warning(" External Feature Already Exist!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
      this.extraOptionsList[this.editextraIndex]={"feature": this.extraFeature, "price": this.featurePrice }
      this.toastrService.success(" External Feature Updated Successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      this.extraFeature='';
      this.featurePrice='';
      this.editExtra=false;
      this.editextraIndex=null;
      return false;
     }else{  

    if (this.extraFeature == null || this.extraFeature == undefined || this.extraFeature == '') {
      this.toastrService.warning("Please Enter External Feature Label!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (isNaN(this.featurePrice) || this.featurePrice == null || this.featurePrice == undefined || this.featurePrice == '' || this.featurePrice == 0) {
      this.toastrService.warning("Please Enter External Feature Price " + '!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.extraOptionsList.length == 5) {
      this.toastrService.warning("Maximum 5 Features Are Allowed", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if ((this.extraOptionsList.filter(x => x.feature == this.extraFeature)).length > 0) {
      this.toastrService.warning("Extra Feature Already Exist!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.extraOptionsList.push({ "feature": this.extraFeature, "price": this.featurePrice });
    this.toastrService.success(" Extra Feature Added Successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();    
    this.extraFeature = null;
    this.featurePrice = null;

  }
}

  removeExtraFeature(i) {
    // this.extraOptionsList.splice(i, 1);
    Swal.fire({
      title: `Do you want to delete this Extra Features?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.extraOptionsList.splice(i, 1);
     this.toastrService.success("Extra Features deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    this.extraFeature=null
    this.featurePrice=null
    this.editextraIndex=null;
    this.editExtra=false;
      } else if (result.isDenied) {
        this.extraFeature=null
       this.featurePrice=null
      }
    }) 
  }

  editExtraFeature(index) {
    this.editExtra=true;
    this.editextraIndex=index;
    this.extraFeature = this.extraOptionsList[index].feature;
    this.featurePrice = this.extraOptionsList[index].price;
    // this.extraOptionsList.splice(index, 1);
  }
  addDetails() {
    this.lable = this.lable?.trim();
    this.value = this.value?.trim();
    if (this.editLabel) {
      // console.log("editable", this.editLabel);
      // console.log("editIndex", this.editIndex);
      if (this.lable == null || this.lable == undefined || this.lable == '') {
        this.toastrService.warning("Please Enter Label!!!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.value == null || this.value == undefined || this.value == '') {
        this.toastrService.warning("Please Enter Value For Label " + this.lable + '!!!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      let existLabel = this.cardDetails.filter((x, index) => x.lable == this.lable )
      if (existLabel.length > 0) {
        this.toastrService.warning("This Label Already exist", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false
      }
      if (this.cardDetails.length != 0) {
        this.cardDetails[this.editIndex] = { "lable": this.lable, "value": this.value };
        this.toastrService.success("Label updated successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        console.log("this.cardlist==>>>", this.cardDetails)
        this.lable = this.value = '';
        this.editLabel = false;
        this.editIndex = ''
      }
    } else{  

    if (this.lable == null || this.lable == undefined || this.lable == '') {
      this.toastrService.warning("Please Enter Label!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.value == null || this.value == undefined || this.value == '') {
      this.toastrService.warning("Please Enter Value For Label " + this.lable + '!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
 
    if ((this.cardDetails.filter(x => x.lable == this.lable)).length > 0) {
      this.toastrService.warning(" Label Already Exist!", '', {timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.cardDetails.push({ "lable": this.lable, "value": this.value });
    this.toastrService.success("Label Added Successfully!", '', {timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");    
      this.removeClass();
    this.lable = null;
    this.value = null;
    
  }}

  removeDetails(i) {
    // this.cardDetails.splice(i, 1);
    Swal.fire({
      title: `Do you want to delete  label?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.cardDetails.splice(i, 1);
     this.toastrService.success("Label deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    this.lable = this.value = '';
    this.editLabel = false;
    this.editIndex = ''  
      } else if (result.isDenied) {
        this.lable = this.value = '';
      }
    })
  }
  editDetals(index) {
    this.editLabel = true;
    this.editIndex = index;
    this.lable = this.cardDetails[index].lable;
    this.value = this.cardDetails[index].value;
    // this.cardDetails.splice(index, 1);
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  addCard() {
    if((!this.assign_marketplace || this.assign_marketplace == null || this.assign_marketplace == undefined || this.assign_marketplace == '') &&(!this.assign_corp || this.assign_corp == null || this.assign_corp == undefined)){
      this.toastrService.warning("Please select assign product!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.assign_corp == true) {
      if (this.corp_id == null || this.corp_id == undefined || this.corp_id == '') {
      this.toastrService.warning("Please select corporate name", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      }
    }
    for(let corp of this.tempcoprlist){
      corp.Corporate_price = corp.Corporate_price ? corp.Corporate_price.trim() : '';
      if(Number(corp.Corporate_price) == null || Number(corp.Corporate_price) == undefined || (corp.Corporate_price) == ''){
        this.toastrService.warning("Please enter corporate price", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corp.Corporate_price) > Number(this.mrp)){
        this.toastrService.warning("Corporate price must be less than or equal to MRP", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corp.Corporate_price) <= 0){
        this.toastrService.warning("Corporate Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corp.Corporate_price !== undefined && corp.Corporate_price !== null && corp.Corporate_price !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corp.Corporate_price) == false) {
            this.toastrService.warning("Corporate Price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
    }
    this.card_title = this.card_title?.trim()
    if (this.card_title == null || this.card_title == undefined || this.card_title == '') {
      this.toastrService.warning("Please Enter Product Name", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.idc = this.idc?.trim()
    if (this.idc == null || this.idc == undefined || this.idc == '') {
      this.toastrService.warning("Please Enter Product SKU", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.price = this.price?.trim()
    if (this.price == null || this.price == undefined || this.price == '') {
      this.toastrService.warning("Please Enter Card Price", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.price < 0){
      this.toastrService.warning("Selling Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false; 
    }
    if (this.price !== undefined && this.price !== null && this.price !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.price) == false) {
          this.toastrService.warning("Selling Price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    this.mrp = this.mrp?.trim()
    if (this.mrp == null || this.mrp == undefined || this.mrp == '') {
      this.toastrService.warning("Please Enter Card MRP", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.mrp < 0){
      this.toastrService.warning("MRP must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false; 
    }
    if (this.mrp !== undefined && this.mrp !== null && this.mrp !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.mrp) == false) {
          this.toastrService.warning("MRP must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    if (Number(this.price) > Number(this.mrp)) {
      this.toastrService.warning("Selling price must be less then or equal to MRP", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.quantity == null || this.quantity == undefined || this.quantity == '') {
      this.toastrService.warning("Please Enter Set Quantity", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.quantity < 0){
      this.toastrService.warning("Stock must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false; 
    }
    if (this.quantity !== undefined && this.quantity !== null && this.quantity !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.quantity) == false) {
          this.toastrService.warning("Avilable stock must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    let selected = false 
    for (let index = 0; index < this.Slides.length; index++) {
      const element = this.Slides[index];
      if(element.isSelected == true){
        selected = true
      }
      
    }
    if( selected == false){
      this.toastrService.warning("Please select atleast one Slides", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");   this.removeClass()  
          this.removeClass()
          return false;
    }
    if (this.cardDetails.length == 0) {
      this.toastrService.warning("Please Enter Card Details", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.card_discription == null || this.card_discription == undefined || this.card_discription == '') {
      this.toastrService.warning("Please Enter Card Description", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.uploadedGalaryFiles.length <= 0) {
      this.toastrService.warning("Please Add Atleast 1 Product Images", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    $("#save-btn").addClass('disabled');
    var cardDetails: any = {     
      'card_title': this.card_title,'idc':this.idc, 'price': this.price, 'mrp': this.mrp, 'quantity': this.quantity, 'extraOptionsList': JSON.stringify(this.extraOptionsList),
      'is_supported_name': this.is_supported_name, 'is_supported_contact_no': this.is_supported_contact_no, 'is_supported_email': this.is_supported_email,
      'is_supported_designation': this.is_supported_designation, 'is_supported_Address': this.is_supported_Address, 'is_supported_logo_customization': this.is_supported_logo_customization,
      'cardDetails': JSON.stringify(this.cardDetails), 'card_discription': this.card_discription, 'seller_id': this.seller_id, 'adminid': this.userId, 'Slides': JSON.stringify(this.Slides),'card_id':this.card_id,
      corp_id:JSON.stringify(this.corp_id),assign_marketplace:this.assign_marketplace?this.assign_marketplace:null,assign_corp:this.assign_corp?this.assign_corp:null,tempcoprlist: JSON.stringify(this.tempcoprlist),CorpWisepeiseList: JSON.stringify(this.CorpWisepeiseList),
      sellerListEdit :this.sellerListEdit,isAdmin:true
    };
    //console.log("card//", cardDetails)
    this.formData = new FormData();
    var docNames = [];
    this.formData.append("cardDetails", JSON.stringify(cardDetails));
    if (this.uploadedGalaryFiles.length > 0) {
      for (var i = 0; i < this.uploadedGalaryFiles.length; i++) {
        this.formData.append('files', this.uploadedGalaryFiles[i]['file']);
      }
    }
    this.cardService.saveCard(this.formData).subscribe((res:any) => {
     // console.log('card res------->>>>>>>', res)
      if (res.status == 200) {
        this.toastrService.success(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.reset();
        //$("#save-btn").addClass('disabled');
        $(".overlay-container").addClass("addcontainerdisable");
        this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
      }
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        //$("#save-btn").addClass('disabled');
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        // this.reset();
      }else{
      // console.log('chekcsjdksd', res)

      } 
    }, err => { 
      this.toastrService.warning(err.detail, 'SKU already exist', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    })
  }

  onCardEdit(card_id) { 
    this.isEdit = true;
    this.getcorp();
    this.cardService.onCardEdit(card_id).subscribe(res=>{
      console.log("editt", res)
        this.card_title = res[0].rows[0].product_name;
        this.idc = res[0].rows[0].suk;
        this.price = res[0].rows[0].price;
        this.mrp = res[0].rows[0].mrp;
        this.seller_id = res[0].rows[0].seller_id;
        this.card_discription = res[0].rows[0].product_discription;
        this.quantity = res[1].rows[0].set_quantity;
        this.extraOptionsList = JSON.parse(res[1].rows[0].extra_features);
        this.cardDetails = JSON.parse(res[1].rows[0].card_details);
        this.is_supported_Address = res[1].rows[0].is_supported_address;
        this.is_supported_contact_no = res[1].rows[0].is_supported_contact_no;
        this.is_supported_designation = res[1].rows[0].is_supported_designation;
        this.is_supported_email = res[1].rows[0].is_supported_email;
        this.is_supported_logo_customization = res[1].rows[0].is_supported_logo_customization;
        this.is_supported_name = res[1].rows[0].is_supported_name;
        this.Slides = res[1].rows[0].slides ? JSON.parse(res[1].rows[0].slides) : [{ 'text': 'Single Sided', 'isSelected': false }, { 'text': 'Double Sided', 'isSelected': false }];
        this.corp_id = JSON.parse(res[0].rows[0].corp_id);
        this.assign_corp = res[0].rows[0].assign_corp=='false'?null:true;
        this.assign_marketplace = res[0].rows[0].assign_marketplace=='false'?null:true;
        let data4 = res[4].rows ? res[4].rows : [];
        if (data4.length > 0) {
          this.extraOptionsList = data4;
        }
        //this.file = undefined;
        this.productImagesList = res[2].rows;        
        console.log("imagggegegg", this.productImagesList)
        for (const img of this.productImagesList) {
          this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1,"image_id": img.image_id, "isSavedInDB": true, 'file_path': img.document_path, "file_name": img.orignal_file_name });
        }
        this.tempcoprlist   = res[3].rows;
    //     let  temCorpWisepeiseList   = res[3].rows;
    //   this.CorpWisepeiseList=[];
    //  console.log( 'this.CorpWisepeiseList', temCorpWisepeiseList)
    //  if(temCorpWisepeiseList.length > 0){
    //   this.showqtyPrice= true
    //  }
    //   for(let i=0;i<temCorpWisepeiseList.length;i++){
    //    console.log(temCorpWisepeiseList[i].quantity_wise_prise)
    //     let json=JSON.parse(temCorpWisepeiseList[i].quantity_wise_prise);
    //     for(let c=0; c<json.length; c++){
    //       this.CorpWisepeiseList.push({
    //         corpid :  json[c]["corpid"],
    //         maxQty : json[c]["maxQty"],
    //         minQty: json[c]["minQty"],
    //         qtyprice: json[c]["qtyprice"],
    //         selectedCorpName :   json[c]["selectedCorpName"],        
    //       })
    //     }
    //  // console.log(this.CorpWisepeiseList,JSON.parse(temCorpWisepeiseList[i]["quantity_wise_prise"]))
    //   }
      if(this.corp_id != null){
        this.getcorporate(this.tempcoprlist);
        }     
    })
   
  }

  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
