<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item newitm1">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;">User Master</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">User Details</li>
                        </ol>
                    </nav>
                </div>
            </div>



            <div class="card shadow-none bg-gryc mt-3 border-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="border-responsive pb-lg-0 pb-2">
                                <h4 class="">
                                    <strong> JCB India Limited </strong>
                                </h4>
                                <h6 class="font9">
                                    <b>ID: </b> IDC000000054 </h6>
                                <h6 class="font9">
                                    <b>Subscription: </b> Platinum </h6>
                                <h6 class="font9">
                                    <b>User Type: </b> Corporate </h6>
                                <h6 class="font9">
                                    <b>URL: </b>
                                    https://juzby.com/
                                </h6>
                                <h6 class="font9">
                                    <b>Child Store: </b>
                                    120
                                </h6>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="border-responsive py-lg-0 py-2">
                                <h5 class="font-16">
                                    <strong> Contact Person </strong>
                                </h5>
                                <h6 class="font9">Jitendra Singh</h6>
                                <h6 class="font9">9999888800 </h6>
                                <h6 class="font9">jitendra@gmail.com </h6>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="border-responsive py-lg-0 py-2">
                                <h5 class="font-16">
                                    <strong> Credit Limit </strong>
                                </h5>
                                <h6 class="font9">
                                    <b>Used: </b> 120000 </h6>
                                <h6 class="font9">
                                    <b>Available: </b> 120000 </h6>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="pt-lg-0 pt-2">
                                <h5 class="font-16">
                                    <strong> Concern Salesperson </strong>
                                </h5>
                                <h6 class="font9">Dinesh Singh </h6>
                                <h6 class="font9">9999988888 </h6>
                                <h6 class="font9">dinesh.singh@ideacraft.com </h6>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="user-adress-area my-3">
                <div class="row">
                    <div class="col-md-6 mb-mb-0 mb-3">
                        <div class="card shadow-none">
                            <div class="card-header p-3">
                                <h6 class="text-uppercase mb-0 font-weight-bold">
                                    Add Branches
                                </h6>
                            </div>
                            <div class="card-body">
                                <div class="card shadow-none mb-4">
                                    <div class="card-body card-body py-2 px-3">
                                        <strong>Ideacraft eVentures Pvt Ltd </strong>
                                        <div class="dropdown show float-right">
                                            <a class="text-dark dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span>&#8942;</span>
                                            </a>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <a class="text-darkorange px-1 dropdown-item" href="javascript:void(0)">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                                <a class="text-danger px-1 dropdown-item" data-toggle="modal" data-target="#delete_address" href="javascript:void(0)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
                                            </div>
                                        </div>
                                        <p class="mb-1 font8">1011-1018,10th Floor, Tower 4,DLF Corporate Greens,</p>
                                        <p class="mb-1 font8">Southern Peripheral Road,Sector 74A,. Gurgaon,</p>
                                        <p class="mb-1 font8">India - 122004</p>
                                        <strong class="font9">GSTIN : 06AACCI4546G1ZP</strong>
                                        <br>
                                        <strong class="font9">Mobile : 9599088957</strong>
                                    </div>
                                </div>
                                <button class="btn btn-sm px-4 rounded-pill rounded-pill-theme text-dark mb-4" data-toggle="modal" data-target="#branch_details">
                                    <i class="fa fa-plus mr-1"></i> Add Branches </button>
                                <div class="card shadow-none mb-2">
                                    <div class="card-body card-body py-2 px-3">
                                        <strong>Ideacraft eVentures Pvt Ltd </strong>
                                        <div class="dropdown show float-right">
                                            <a class="text-dark dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span>&#8942;</span>
                                            </a>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <a class="text-darkorange px-1 dropdown-item" href="javascript:void(0)">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                                <a class="text-danger px-1 dropdown-item" data-toggle="modal" data-target="#delete_address" href="javascript:void(0)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
                                            </div>
                                        </div>
                                        <p class="mb-1 font8">1011-1018,10th Floor, Tower 4,DLF Corporate Greens,</p>
                                        <p class="mb-1 font8">Southern Peripheral Road,Sector 74A,. Gurgaon,</p>
                                        <p class="mb-1 font8">India - 122004</p>
                                        <strong class="font9">GSTIN : 06AACCI4546G1ZP</strong>
                                        <br>
                                        <strong class="font9">Mobile : 9599088957</strong>
                                    </div>
                                </div>
                                <div class="card shadow-none">
                                    <div class="card-body card-body py-2 px-3">
                                        <strong>JCB India Pvt Ltd </strong>
                                        <div class="dropdown show float-right">
                                            <a class="text-dark dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button" id="dropdownMenuLink2"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span>&#8942;</span>
                                            </a>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                                <a class="text-darkorange px-1 dropdown-item" href="javascript:void(0)">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                                <a class="text-danger px-1 dropdown-item" data-toggle="modal" data-target="#delete_address" href="javascript:void(0)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
                                            </div>
                                        </div>
                                        <p class="mb-1 font8">1011-1018,10th Floor, Tower 4,DLF Corporate Greens,</p>
                                        <p class="mb-1 font8">Southern Peripheral Road,Sector 74A,. Gurgaon,</p>
                                        <p class="mb-1 font8">India - 122004</p>
                                        <strong class="font9">GSTIN : 06AACCI4546G1ZP</strong>
                                        <br>
                                        <strong class="font9">Mobile : 9999999999</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card shadow-none">
                            <div class="card-header p-3">
                                <h6 class="text-uppercase mb-0 font-weight-bold">
                                    Shipping Address (3)
                                </h6>
                            </div>
                            <div class="card-body">
                                <button class="btn btn-sm px-4 rounded-pill rounded-pill-theme text-dark mb-4 rounded-theme" data-toggle="modal" data-target="#shipping_address">
                                    <i class="fa fa-plus mr-1"></i> Add Delivery Address </button>
                                <div class="card shadow-none mb-2">
                                    <div class="card-body card-body py-2 px-3">
                                        <strong>Sanjay</strong>
                                        <div class="dropdown show float-right">
                                            <a class="text-dark dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button" id="dropdownMenuLink3"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span>&#8942;</span>
                                            </a>

                                            <div class="dropdown-menu dropdown-menu2" aria-labelledby="dropdownMenuLink3">
                                                <a class="text-darkorange px-1 dropdown-item" href="javascript:void(0)">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                                <a class="text-danger px-1 dropdown-item" data-toggle="modal" data-target="#delete_address" href="javascript:void(0)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
                                            </div>
                                        </div>
                                        <p class="mb-1 font8">1011-1018,10th Floor, Tower 4,DLF Corporate Greens,</p>
                                        <p class="mb-1 font8">Southern Peripheral Road,Sector 74A,. Gurgaon,</p>
                                        <p class="mb-1 font8">India - 122004</p>
                                        <strong class="font9">Mobile : 9599088957</strong>
                                    </div>
                                </div>
                                <div class="card shadow-none mb-2">
                                    <div class="card-body card-body py-2 px-3">
                                        <strong>Sanjay 1</strong>
                                        <div class="dropdown show float-right">
                                            <a class="text-dark dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button" id="dropdownMenuLink4"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span>&#8942;</span>
                                            </a>

                                            <div class="dropdown-menu dropdown-menu2" aria-labelledby="dropdownMenuLink4">
                                                <a class="text-darkorange px-1 dropdown-item" href="javascript:void(0)">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                                <a class="text-danger px-1 dropdown-item" data-toggle="modal" data-target="#delete_address" href="javascript:void(0)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
                                            </div>
                                        </div>
                                        <p class="mb-1 font8">1011-1018,10th Floor, Tower 4,DLF Corporate Greens,</p>
                                        <p class="mb-1 font8">Southern Peripheral Road,Sector 74A,. Gurgaon,</p>
                                        <p class="mb-1 font8">India - 122004</p>
                                        <strong class="font9">Mobile : 9599088957</strong>
                                    </div>
                                </div>
                                <div class="card shadow-none">
                                    <div class="card-body card-body py-2 px-3">
                                        <strong>Sanjay 2</strong>
                                        <div class="dropdown show float-right">
                                            <a class="text-dark dropdown-toggle text-decoration-none" href="javascript:void(0)" role="button" id="dropdownMenuLink5"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span>&#8942;</span>
                                            </a>

                                            <div class="dropdown-menu dropdown-menu2" aria-labelledby="dropdownMenuLink5">
                                                <a class="text-darkorange px-1 dropdown-item" href="javascript:void(0)">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit</a>
                                                <a class="text-danger px-1 dropdown-item" data-toggle="modal" data-target="#delete_address" href="javascript:void(0)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete</a>
                                            </div>
                                        </div>
                                        <p class="mb-1 font8">1011-1018,10th Floor, Tower 4,DLF Corporate Greens,</p>
                                        <p class="mb-1 font8">Southern Peripheral Road,Sector 74A,. Gurgaon,</p>
                                        <p class="mb-1 font8">India - 122004</p>
                                        <strong class="font9">Mobile : 9599088957</strong>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>


        </div>

    </div>
</div>
<div class="modal fade" id="branch_details">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header px-3 py-2 align-items-center">
                <h6 class="font-weight-bold mb-0">Add Branch</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row m-0">
                    <div class="col-sm-12 mb-2">
                        <label for="">Company Name
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-6 pr-0 mb-2">
                        <label for="">GSTIN
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-6 mb-2">
                        <label for="">Email ID
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="">Phone Number
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="">Address
                            <span class="asterisk">*</span>
                        </label>
                        <textarea class="form-control2"></textarea>
                    </div>

                    <div class="col-sm-12 mb-2">
                        <div class="row">
                            <div class="col-6 mb-2">
                                <label for="">Country
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control">
                                    <option value="" selected disabled>India</option>
                                </select>
                            </div>
                            <div class="col-6 mb-2">
                                <label for="">Province/Territory/State
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control">
                                    <option value="" selected disabled>Haryana</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <label for="">City
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="col-sm-6 mb-2">
                                <label for="">Postal/ZIP Code
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="text-center col-sm-12">
                        <button class="py-1 btn rounded-pill px-4 btn-theme">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="shipping_address">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header px-3 py-2 align-items-center">
                <h6 class="font-weight-bold mb-0">Shipping Address</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row m-0">
                    <div class="col-sm-12 mb-2">
                        <label for="">Contact Person
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-6 pr-0 mb-2">
                        <label for="">Email ID
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-6 mb-2">
                        <label for="">Phone Number
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="">Address
                            <span class="asterisk">*</span>
                        </label>
                        <textarea class="form-control2"></textarea>
                    </div>

                    <div class="col-sm-12 mb-2">
                        <div class="row">
                            <div class="col-6 mb-2">
                                <label for="">Country
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control">
                                    <option value="" selected disabled>India</option>
                                </select>
                            </div>
                            <div class="col-6 mb-2">
                                <label for="">Province/Territory/State
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control">
                                    <option value="" selected disabled>Haryana</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <label for="">City
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control">
                            </div>
                            <div class="col-sm-6 mb-2">
                                <label for="">Postal/ZIP Code
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="text-center col-sm-12">
                        <button class="py-1 btn rounded-pill px-4 btn-theme">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="delete_address">
    <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header px-3 py-2 align-items-center">
                <!-- <h6 class="font-weight-bold mb-0">Shipping Address</h6> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center col-sm-12">
                    <p>Do you want to delete this branch?</p>
                    <button class="py-1 btn rounded-pill px-4 btn-theme mr-2">Yes</button>
                    <button class="py-1 btn rounded-pill px-4 btn-danger">No</button>
                </div>
            </div>
        </div>
    </div>