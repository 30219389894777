import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import{LoginService} from 'src/app/_services/login.service'
import { FormService } from '../_services/form.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-successfullmsg',
  templateUrl: './successfullmsg.component.html',
  styleUrls: ['./successfullmsg.component.css']
})
export class SuccessfullmsgComponent implements OnInit {
  companyName:any;
  companyPan: any;
  companyGstn: any;
  companyCin: any;
  companyEmail: any;  
  companyid: any="96";
  refNo: any="BANK76/3567GST";
  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private formService: FormService,
    private toastrService: ToastrService
  ) { }
 
  ngOnInit(): void {

    this.route.queryParams.subscribe((res)=>{
      // console.log("res==>",res)
      let data =this.loginService.decreptedDataUrl(res);
      // frmid=376&vid=362&company_name=Binno&company_pan=ABCTY1234D&company_gstin=37AADCS0472N2Z0&company_cin=L21091KA2019OPC141331&itemid=99
      if(data.length>1){
        var str = data;      
        var value=str.split("&");
        //console.log("pks uri===>",uri,value)
        this.toastrService.toastrConfig.enableHtml=true;
        value.forEach(queryParm=>{
          if(queryParm.indexOf('company_name')>=0)
          {
            this.companyName=queryParm.split('=')[1];
          }
          if(queryParm.indexOf('company_pan')>=0)
          {
            this.companyPan=queryParm.split('=')[1];
            
          }
          if(queryParm.indexOf('company_gstin')>=0)
          {
            this.companyGstn=queryParm.split('=')[1];
          }
          if(queryParm.indexOf('company_cin')>=0)
          {
            this.companyCin=queryParm.split('=')[1];
          }
          if(queryParm.indexOf('itemid')>=0)
          {
            this.companyid=queryParm.split('=')[1];
          } 

        });
        this.refNo=this.fileName('','',this.companyid);          
          this.generateCompanyRefNo(this.companyid,this.refNo)   
      }
    });
    

    
  }

  generateCompanyRefNo(cid,refNo){
    let qry=`update z_entitymast_company_001 set req_no='${refNo}' where company_id=${cid}`;
    this.formService.getFormData(qry).subscribe(res1 => {

    });

  }

  public fileName(scheme_type,process_type,refKey){
    const nowDate = new Date(); 
    const month = nowDate.getMonth()+1
    const date = nowDate.getDate()+month.toString()+nowDate.getFullYear(); 
    const filename ="REQNO"+"/"+(refKey?this.padLeft(refKey, '0', 4):"00").toString()+"/"+date;
    return filename;
  }
  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
}

gotologin() {
   this.router.navigate(['/login']);
}

}
