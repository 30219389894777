<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/total-seller">Total Seller</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/seller-wise-product">Product Listing</a>
                            </li>
                            <li class="breadcrumb-item font8 active" aria-current="page">Print Collaterals</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="col-sm-12 p-0">
                <div class="tabs  my-2">
                    <div class="tab-button-outer1">
                        <ul id="tab-button" class="tbsbtns">
                            <li id="tabDraft" class="is-active">
                                <a href="javascript:void(0)" class="font8">Print Collaterals</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="card shadow-none">
                <div class="card-body p-3">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <h6 class="fs-6">Basic</h6>
                            <hr class="mt-0">
                        </div>
                    </div>
                    <div class="row align-items-center mb-2">
                        <div class="col-md-auto minwidth-responsive">
                            <label for="">Assign Product</label>
                        </div>
                        <div class="col-md-10">
                            <div class="form-check pl-0">
                                <input class="custom1-chkboxradio" type="checkbox" id="market_price" value="market_price_value" [(ngModel)]="assign_marketplace">
                                <label class="mx-2" for="market_price">Market Place</label>
                            </div>
                            <div class="form-check pl-0">
                                <input class="custom1-chkboxradio" type="checkbox" id="corporate" value="corporate_value" [(ngModel)]="assign_corp" (change)="CorporateChange()">
                                <label class="ml-2" for="corporate">Corporate</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="assign_corp">
                        <div class="col-md-auto minwidth-responsive">
                            <label for="">Select Corporate</label>
                        </div>
                        <div class="col-md">
                            <ng-select multiple="true" name="corpname" id="corpid" class=" mb-2" [(ngModel)]="corp_id" (change)="getcorporate('0')">
                                <ng-option value="undefined" disabled>--Select Corporate--</ng-option>
                                <ng-option *ngFor="let corp of corpList" [value]="corp.add_corporate_id">
                                    {{corp.enterprise_name}} ({{corp.corp_name}})
                                </ng-option>
                            </ng-select>
    
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-md-9">
                            <div class="form-group ">
                                <label for="productTitle">Product name
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control mb-2" id="productTitle" autocomplete="off" placeholder="Enter Product name" maxlength="500"
                                    [(ngModel)]="card_title">
                            </div>
                        </div>
                        </div>
                    <div class="row align-items-center">
                        <!-- <div class="col-md-6">
                            <div class="form-group ">
                                <label for="productTitle">Product name
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control mb-2" id="productTitle" autocomplete="off" placeholder="Enter Product name" maxlength="500"
                                    [(ngModel)]="card_title">
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div class="form-group ">
                                <label for="idc">Product SKU
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control mb-2" id="idc" autocomplete="off" placeholder="Enter Product SKU " maxlength="50"
                                    [(ngModel)]="idc" (keypress)="commonFunctionService.alphaNumericWithCommaDotHiphenValidator($event)" [disabled] = 'isEdit'>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group ">
                                <label for="price">Selling Price (₹)
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" autocomplete="off" min="0" maxlength="15" class="form-control mb-2"
                                    id="productTitle" placeholder="Enter Price" (keypress)="commonFunctionService.amountValidator($event)"
                                    [(ngModel)]="price">
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group ">
                                <label for="price">MRP (₹)
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" autocomplete="off" min="0" maxlength="15" class="form-control mb-2"
                                    id="productTitle" placeholder="Enter MRP" (keypress)="commonFunctionService.amountValidator($event)"
                                    [(ngModel)]="mrp">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card shadow-none p-0 mt-2 mb-2" *ngIf="tempcoprlist.length > 0">
                                <div class="card-header px-2">
                                    <h6 class="mb-0 font600">Corporate Prices</h6>
                                </div>
                                <div class="card-body pt-2">
                                    <div class="row align-items-center my-1" *ngFor="let corp of tempcoprlist; let i=index">
                                        <div class="col-md-2 pr-lg-0">
                                            <label for="">{{corp.enterprise_name}}</label>
                                        </div>
                                        <div class="col-lg-1 col-md-2 col-3 pr-0">
                                            <label> Price (₹) </label>
                                            <input type="text" class="form-control" [name]="corp.enterprise_name" [(ngModel)]="corp.Corporate_price"
                                                autocomplete="off" min="0" maxlength="15" (keypress)="commonFunctionService.amountValidator($event)">
                                        </div>
                                        <!-- <div class="col my-md-0 my-2">
                                            <div class="form-check pl-0">
                                                <input class="custom1-chkboxradio mx-0" name="checkbox_{{i}}" type="checkbox" id="checkbox_{{i}}" [(ngModel)]="corp.is_selected" (click)="getCorpId(corp)">
                                                <label class="ml-2" for="checkbox_{{i}}">Quantity Wise Price</label>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                    <!-- <div class="col-lg-2 align-self-center mt-2">
                                        <div class="form-check pl-0">
                                                <input id="showqtyPrice" class="custom1-chkboxradio ml-0 mr-2" type="checkbox" [(ngModel)]="showqtyPrice">
                                                <label for="showqtyPrice" class=""> Show Quantity Wise Price</label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-lg-10 mt-sm-0 mt-2" *ngIf="showqtyPrice == true">
                                            <div class="row mb-2" >
                                                    <div class="col-lg-2 col-md pr-0 mb-sm-0 mb-2 align-self-end">
                                                        <ng-select [(ngModel)]="selectedCorpid">
                                                            <ng-option value="undefined" disabled selected>Select Corporate</ng-option>
                                                            <ng-option *ngFor="let obj of tempcoprlist" [value]="obj.add_corporate_id">{{obj.enterprise_name}}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <div class="col-lg-1 col-md-2 col-3 pr-0 mb-sm-0 mb-2">
                                                        <label for="">Qty</label>
                                                        <input type="text" class="form-control" [(ngModel)]="minQty" autocomplete="off"
                                                            min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                                    </div>
                                                    <div class="col-auto align-self-end mb-sm-2 mb-3 pr-0">
                                                        <label>to</label>
                                                    </div>
                                                    <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                        <label for="">Qty</label>
                                                        <input type="text" class="form-control" [(ngModel)]="maxQty" autocomplete="off"
                                                            min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                                    </div>
                                                    <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                        <label for="">Price</label>
                                                        <input type="text" class="form-control" [(ngModel)]="qtyprice" autocomplete="off"
                                                            min="0" maxlength="" (keypress)="commonFunctionService.amountValidator($event)">
                                                    </div>
                                                    <div class="col-auto d-flex align-self-end">
                                                        <button class="btn btn-theme btn-sm px-3 py-1" (click)="addCorpWisepeise()">
                                                            <i class="fa fa-plus"></i> Add
                                                        </button>
                                                    </div>
                
                                                  
                                                </div>
                                    </div> -->
                                </div>
                                <!-- <div class="row" *ngIf="CorpWisepeiseList.length>0">
                                      <div class="col-lg-10 offset-lg-2" *ngIf="showqtyPrice == true">
                                                        <div class="table-responsive white-space-pre mt-1">
                                                            <table class="table table-bordered vertically-middle thead mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="text-center w10">Sr. No.</th>
                                                                        <th scope="col">Corporate Name</th>
                                                                        <th scope="col">Min Qty.</th>
                                                                        <th scope="col">Max Qty.</th>
                                                                        <th scope="col">Price</th>
                                                                        <th scope="col" class="text-center w20">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let obj of CorpWisepeiseList;let i=index">
                                                                        <td class="text-center">{{i+1}}</td>
                                                                        <td>{{obj.selectedCorpName}}</td>
                                                                        <td>{{obj.minQty}}</td>
                                                                        <td>{{obj.maxQty}}</td>
                                                                        <td>{{obj.qtyprice}}</td>
                                                                        <td class="text-center">
                                                                            <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editCorpWisepeise(i)">
                                                                                <i class="fa fa-edit mr-lg-1"></i>
                                                                                <span class="d-lg-inline d-none">Edit</span>
                                                                            </button>
                                                                            <button type="button" class="btn btn-sm btn-danger" (click)="removeCorpWisepeise(i)">
                                                                                <i class="fa fa-trash mr-lg-1"></i>
                                                                                <span class="d-lg-inline d-none">Delete</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                </div> -->
                                    <!-- <div class="row align-items-center mb-2">
                                        <div class="col-md-2 pr-lg-0">
                                            <label for="">Price JCB</label>
                                        </div>
                                        <div class="col-lg-1 col-md-2 col-3 pr-0">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col my-md-0 my-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="inlineCheckbox1" value="option2">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-2 pr-lg-0">
                                            <label for="">Price Service Now</label>
                                        </div>
                                        <div class="col-lg-1 col-md-2 col-3 pr-0">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col my-md-0 my-2">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="inlineCheckbox1" value="option2">
                                            </div>
                                        </div>
                                    </div> -->
    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="quantity">Product in 1 set
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text"  autocomplete="off" min="0" class="form-control mb-2" id="productTitle"
                                    placeholder="Enter Product in 1 set " (keypress)="commonFunctionService.numericCheck($event)"
                                    [(ngModel)]="quantity" maxlength="10">
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="row">
                                <div class="w-100">
                                    <label for="" class="mb-1">Print Area
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="px-0 form-check" *ngFor="let obj of Slides;let i=index;">
                                    <input type="checkbox" class="custom1-chkboxradio" id="side{{i}}" [(ngModel)]="obj.isSelected">
                                    <label for="side{{i}}" class="mx-2">{{obj.text}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row actionform ">
                        <div class="col-lg-12">
                            <h6 class="fs-6 mt-2">Extra Features</h6>
                            <hr class="mt-0">
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="externalfeature">Extra Features
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" id="externalfeature" name="externalfeature" autocomplete="off" 
                                    placeholder="Enter External Feature" maxlength="100" [(ngModel)]="extraFeature">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="externalprice">Price (₹)
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" id="externalprice" name="externalprice" autocomplete="off" (keypress)="commonFunctionService.amountValidator($event)"
                                    placeholder="Enter Price" maxlength="7" [(ngModel)]="featurePrice">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button type="button" class="btn btn-sm btn-theme p-1 px-3  mt-md-4 mt-2" (click)="addExtraFeature()">
                                <i class="fa fa-plus"></i> Add</button>
                        </div>


                        <div class="col-lg-12 mt-3">
                            <div class="table-responsive">
                                <table class="table table-bordered vertically-middle min-width118" *ngIf="extraOptionsList && extraOptionsList.length>0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="w10 text-center">Sr. No.</th>
                                            <th scope="col" style="min-width:130px">External Feature</th>
                                            <th scope="col">Price (₹)</th>
                                            <th scope="col" class="text-center w20">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let obj of extraOptionsList;let i=index;">
                                            <td class="text-center">{{i+1}}</td>
                                            <td class="text-sm-left text-center">{{obj.feature}}</td>
                                            <td>{{obj.price}}</td>
                                            <td class="text-center">

                                                <button type="button" class="btn btn-sm btn-theme text-white" (click)="editExtraFeature(i)">
                                                    <i class="fa fa-edit mr-sm-1"></i>
                                                    <span class="d-none d-xl-inline-block ms-1">Edit</span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger ml-2" (click)="removeExtraFeature(i)">
                                                    <i class="fa fa-trash mr-sm-1"></i>
                                                    <span class="d-none d-xl-inline-block ms-1">Delete</span>
                                                </button>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="fs-6">Supported Fields</h6>
                            <div class="mb-3">
                                <hr class="mt-0">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 mb-2">
                            <div class="form-group">
                                <input type="checkbox" class="custom1-chkboxradio" id="name1" autocomplete="off" [(ngModel)]="is_supported_name">
                                <label for="name1" class="pl-2">Name</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-group ">
                                <input type="checkbox" class="custom1-chkboxradio" id="contact" autocomplete="off" [(ngModel)]="is_supported_contact_no">
                                <label for="contact" class="pl-2">Contact No</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-group ">
                                <input type="checkbox" class="custom1-chkboxradio" id="email" autocomplete="off" [(ngModel)]="is_supported_email">
                                <label for="email" class="pl-2">Email</label>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group ">
                                <input type="checkbox" class="custom1-chkboxradio" id="designation" autocomplete="off" [(ngModel)]="is_supported_designation">
                                <label for="designation" class="pl-2">Designation</label>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <input type="checkbox" class="custom1-chkboxradio" id="address" autocomplete="off" [(ngModel)]="is_supported_Address">
                                <label for="address" class="pl-2">Address</label>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <input type="checkbox" class="custom1-chkboxradio" id="logo" autocomplete="off" [(ngModel)]="is_supported_logo_customization">
                                <label for="logo" class="pl-2">Is Supported Customizable Logo</label>
                            </div>
                        </div>
                    </div>
                    <div class="row actionform">
                        <div class="col-lg-12">
                            <h6 class="fs-6 mt-2">Card Details</h6>
                            <hr class="mt-0">
                        </div>

                        <div class="col-md-6">
                            <label for="lable">Label <span class="asterisk">*</span></label>
                            <input type="text" min="0" class="form-control mb-2" maxlength="50" id="lable" autocomplete="off" placeholder="Enter Label"
                                [(ngModel)]="lable">
                        </div>
                        <div class="col-md-3">
                            <label for="value">Value <span class="asterisk">*</span> </label>
                            <input type="text" min="0" class="form-control mb-2" maxlength="50" id="value" autocomplete="off" placeholder="Enter Value"
                                [(ngModel)]="value">
                        </div>
                        <div class="col-md-3">
                            <button type="button" class="btn btn-sm btn-theme p-1 px-3 mt-2 mb-2 mt-md-4" (click)="addDetails()">
                                <i class="fa fa-plus"></i> Add </button>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="table-responsive">
                                <table class="table table-bordered vertically-middle min-width118" *ngIf="cardDetails.length > 0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="w10 text-center">Sr. No.</th>
                                            <th scope="col">Label</th>
                                            <th scope="col">Value</th>
                                            <th scope="col" class="text-center w20">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let obj of cardDetails let i=index">
                                            <td class="text-center">{{i+1}}</td>
                                            <td class="text-wrap">{{obj.lable}}</td>
                                            <td class="text-wrap">{{obj.value}}</td>
                                            <td class="text-center">
                                                <button type="button" class="btn btn-sm btn-theme text-white" (click)="editDetals(i)">
                                                    <i class="fa fa-edit mr-sm-1"></i>
                                                    <span class="d-none d-xl-inline-block ms-1">Edit</span>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-danger ml-2" (click)="removeDetails(i)">
                                                    <i class="fa fa-trash mr-sm-1"></i>
                                                    <span class="d-none d-xl-inline-block ms-1">Delete</span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group mb-3">
                                <label for="discription">Card Description
                                    <span class="asterisk">*</span>
                                </label>
                                <!-- <textarea class="form-control2" id="discription" autocomplete="off" placeholder="Enter Card Description" style="height: 100px;"
                                    maxlength="500" (keypress)="commonFunctionService.alphaNumericWithCommaDotHiphenValidator($event)"
                                    [(ngModel)]="card_discription"></textarea> -->
                                <div class="NgxEditor__Wrapper ng-editor width100 ngxcstm min-hy-200">
                                    <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="card_discription" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="fs-6">Images
                                <span class="asterisk">*</span>
                            </h6>
                        </div>
                        <div class="col-md-12">
                            <div>
                                <div class="row">
                                    <div class="col-lg-9">
                                        <div class="form-group border">
                                            <input type="file" id="Photograph" class="form-control-file form-select pt-2 font8" multiple (change)="onFileChnage($event)" #inputFile>
                                        </div>
                                        <label class="font75">
                                            <!-- Max 10 images of 1500 px * 1500 px.</label> -->
                                            <b class="text-danger">Note :</b> Image should be 1500 px * 1500 px. You can upload maximum 10 images.
                                        </label>

                                    </div>
                                    <!-- <div class="col-lg-3">
                                        <button type="button" class="btn btn-sm btn-theme p-1 px-3 mt-2 mt-md-0 mb-2" (click)="onUpload()">
                                            <i class="fa fa-plus"></i> Add Image
                                        </button>
                                    </div> -->
                                    <div class="col-md-12 mt-2">
                                        <div class="table-responsive">
                                            <table class="table w-100 table-bordered vertically-middle min-width118 cstmheadtable" *ngIf="uploadedGalaryFiles.length>0">
                                                <thead>
                                                    <tr>
                                                        <th class="w10 text-center">Sr. No.</th>
                                                        <th>File Name</th>
                                                        <th class="text-center">Preview</th>
                                                        <th class="text-center w20">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let ob of uploadedGalaryFiles let i=index">
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td>{{ob.file_name}}</td>
                                                        <td *ngIf="!ob.isSavedInDB">
                                                            <img src="{{ob.file_path}}" class="img-fluid rounded mx-auto d-block" width="60" height="60">
                                                        </td>
                                                        <td *ngIf="ob.isSavedInDB">
                                                            <div class="img-fluid rounded mx-auto d-block">
                                                                <img src="../../..{{ob.file_path}}" width="40" height="40" alt="">
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-danger" *ngIf="!ob.isSavedInDB" (click)="deleteFile(ob.id)">
                                                                <i class="fa fa-trash mr-sm-1"></i>
                                                                <span class="d-none d-xl-inline-block ms-1">Delete</span>
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-danger" *ngIf="ob.isSavedInDB" (click)="deleteFromDb(ob.id, ob.image_id)">
                                                                <i class="fa fa-trash mr-sm-1"></i>
                                                                <span class="d-none d-xl-inline-block ms-1">Delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card shadow-none">
                        <div class="card-header p-3">
                            <h6 class="mb-0 font-weight-bold">
                                Product Photos (0/5)
                            </h6>
                        </div>
                        <div class="card-body">
                            <label class="mb-2">Upload your recommendations for product images</label>
                            <h6 class="font-weight-bold">Hide image guidelines</h6>
                            <label>
                                <span class="font-weight-bold font9">Resolution</span>: Use color images with minimum resolution of 1100 x 1100 px.Guidelines:
                                Upload authentic product photos taken in bright lightning.JPEG is preferred in image format,
                                but you also may use TIFF and GIF files.</label>
                            <label class="font-weight-bold my-2">
                                <button class="btn btn-sm px-4 rounded-pill rounded-pill-theme text-dark mr-1" data-toggle="modal" data-target="#branch_details">
                                    <i class="fa fa-plus mr-1"></i> Upload multiple files</button>
                                <span> or drag and drop 1 or more files below.</span>
                            </label>
                            <div class="row my-2">
                                <div class="card custom-card shadow-none text-center bg-white mb-sm-0 mb-4">
                                    <div class="card-body p-0 d-flex flex-column justify-content-end">
                                        <img class="mb-1 px-4 py-1 productimg img-fluid" src="../../../assets/images/product/black_tshirt.png" alt="">
                                        <div class="row m-0">
                                            <div class="bg-secondary text-white col-6 p-0">
                                                <a class="">
                                                    <i class="fa fa-plus"></i>
                                                </a>
                                            </div>
                                            <div class="col-6 p-0 bg-secondary text-white">
                                                <a class="">
                                                    <i class="fa fa-trash"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card custom-card shadow-none text-center bg-white mb-sm-0 mb-4">
                                        <div class="card-body p-0 d-flex flex-column justify-content-end">
                                            <img class="mb-1 px-4 py-1 productimg img-fluid" src="../../../assets/images/product/black_tshirt.png" alt="">
                                            <div class="row m-0">
                                                <div class="bg-secondary text-white col-6 p-0">
                                                    <a class="">
                                                        <i class="fa fa-plus"></i>
                                                    </a>
                                                </div>
                                                <div class="col-6 p-0 bg-secondary text-white">
                                                    <a class="">
                                                        <i class="fa fa-trash"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card custom-card shadow-none text-center bg-white mb-sm-0 mb-4">
                                            <div class="card-body p-0 d-flex flex-column justify-content-end">
                                                <img class="mb-1 px-4 py-1 productimg img-fluid" src="../../../assets/images/product/black_tshirt.png" alt="">
                                                <div class="row m-0">
                                                    <div class="bg-secondary text-white col-6 p-0">
                                                        <a class="">
                                                            <i class="fa fa-plus"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-6 p-0 bg-secondary text-white">
                                                        <a class="">
                                                            <i class="fa fa-trash"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-10">
                                    <div class="row">
                                        <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                            <div class="card-body d-flex justify-content-center align-items-center">
                                                <label for="fileupld" class="cursor-pointer">
                                                    <div class="drag-drop">
                                                        <input type="file" id="fileupld" class="d-none">
                                                        <figure class="figure text-center mb-0">
                                                            <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                        </figure>
                                                        <p class="mb-1">Upload</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                            <div class="card-body d-flex justify-content-center align-items-center">
                                                <label for="fileupld" class="cursor-pointer">
                                                    <div class="drag-drop">
                                                        <input type="file" id="fileupld" class="d-none">
                                                        <figure class="figure text-center mb-0">
                                                            <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                        </figure>
                                                        <p class="mb-1">Upload</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                            <div class="card-body d-flex justify-content-center align-items-center">
                                                <label for="fileupld" class="cursor-pointer">
                                                    <div class="drag-drop">
                                                        <input type="file" id="fileupld" class="d-none">
                                                        <figure class="figure text-center mb-0">
                                                            <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                        </figure>
                                                        <p class="mb-1">Upload</p>
                                                    </div>
                                                </label>

                                            </div>
                                        </div>
                                        <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                            <div class="card-body d-flex justify-content-center align-items-center">
                                                <label for="fileupld" class="cursor-pointer">
                                                    <div class="drag-drop">
                                                        <input type="file" id="fileupld" class="d-none">
                                                        <figure class="figure text-center mb-0">
                                                            <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                        </figure>
                                                        <p class="mb-1">Upload</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mb-sm-0 mb-2 card custom-card shadow-none text-center">
                                            <div class="card-body d-flex justify-content-center align-items-center">
                                                <label for="fileupld" class="cursor-pointer">
                                                    <div class="drag-drop">
                                                        <input type="file" id="fileupld" class="d-none">
                                                        <figure class="figure text-center mb-0">
                                                            <img src="../../../assets/images/icons/add.png" alt="icon" class="figure-img" width="28">
                                                        </figure>
                                                        <p class="mb-1">Upload</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="text-center mt-3">
             
                        <button type="button" class="btn btn-update btn-sm btn-success mr-2 px-3" id="save-btn" (click)="addCard()" *ngIf="!isEdit">
                            Save </button>
                        <button type="button" class="btn btn-update btn-sm btn-theme mr-2 px-3" id="save-btn" (click)="addCard()" *ngIf="isEdit">
                            Update
                        </button>
                               
                        <button type="button" class="btn btn-update btn-sm btn-warning mr-2 px-3" (click)="reset()" *ngIf="!isEdit">
                            Reset
                        </button>
                        <button type="button" class="btn btn-sm btn-danger px-3" (click)="back()">
                            Back
                        </button>


                    </div>
                </div>
            </div>
        </div>
    </div>