<app-header>
</app-header>
<div class="container-fluid ">
	<div class="col-sm-12 p-0">
		<div class="col-sm-12 nopadding">
			<!-- <div class="col-sm-12 nopadding mb-2">
						<div class="row">
							<div class="col-sm-4">
								<h5 class="col-sm-12 nopadding font600 mb-1 mt-3">{{formName}} </h5> 
							</div>
						
						</div>
						<hr class="mt-0 mb-0">
					</div> -->
			<div class="col-sm-12">
				<div class="tabs col-lg-12 pl-1 pr-1 mt-2 d-flex">
					<div class="tab-button-outer font8 col-lg-8">
						<ul id="tab-button">
							<li class="is-active" id="{{formId}}">
								<a href="javascript:void(0)" (click)="getDetails(formId)">{{formName}}</a>
							</li>
							<!-- <li *ngFor="let lst of childFormList" id="{{lst.form_id}}"><a href="javascript:void(0)" (click)="getDetails(lst.form_id)">{{lst.form_name}}</a>
									</li> -->
							<!-- <li *ngIf="is_attachment == true"><a href="javascript:void(0)" (click)="showAttachmentDiv()">Attachments</a></li> -->
						</ul>
					</div>
					<div class="col-sm-4 cstmposition">
						<div class="sort-wrapper pull-right">
							<div class="dropdown ml-lg-auto  toolbar-item" *ngIf="isExpotable">
								<button class="btn btn-secondary btn-sm   rounded-pill pl-3 pr-3  dropdown-toggle" type="button" id="dropdownexport" data-toggle="dropdown"
								 aria-haspopup="true" aria-expanded="false">Export</button>
								<div class="dropdown-menu" aria-labelledby="dropdownexport">
									<a class="dropdown-item" href="javascript:void(0)">Export as PDF</a>
									<a class="dropdown-item" href="javascript:void(0)">Export as DOCX</a>
									<a class="dropdown-item" href="javascript:void(0)" (click)="exportToExcel()">Export as CSV</a>
								</div>
							</div>&nbsp;&nbsp;
							<button type="button" *ngIf="isAdd" class="btn btn-sm  btn-dark rounded-pill pl-3  pr-3 toolbar-item" (click)="onAddClick()">Add</button>&nbsp;</div>
					</div>
				</div>

				<div class="tab-contents mmt5 px-2">
					<div class="example-header cstmpage pt-0 cstmflxs">
						<label class="mr-1">Page Size:</label>
						<select (change)="onPageSizeChanged()" id="page-size" class="cstmselect">
							<option value="10" selected="">10</option>
							<option value="10" selected="">50</option>
							<option value="100">100</option>
							<option value="500">500</option>
							<option value="1000">1000</option>
						</select>&nbsp;&nbsp;
						<a href="javascript:void(0)" class="btn btn-sm btn-outline-dark filtericon pt-0 pb-0 hyt24" (click)="modalRoot.show(); isResetFilter=false;">
							<i class="fa fa-filter"></i>
						</a>&nbsp;&nbsp;
						<span *ngIf="viewFilterString" class="badge badge-pill badge-success badge-outlined fnt100 pt-1   pb-1">
							<div class="float-left" [innerHTML]="viewFilterString"></div>
							<a href="javascript:void(0)" class="posclose" (click)="removeFilter()">
								<i class="fa fa-times"></i>
							</a>
						</span>
					</div>
					<app-modal #modalRoot class="modal-demo custommodal">
						<ng-container class="app-modal-header p-2">Add Rule</ng-container>
						<ng-container class="app-modal-body">
							<!-- <app-user-rule [formId]="formId" [resetFilter]="isResetFilter" (applyEvent)="apply($event)"></app-user-rule> -->
						</ng-container>
						<ng-container class="app-modal-footer p-2 text-right">
							<button type="button" id="clos" (click)="modalRoot.hide()" style="display: none">Close</button>
						</ng-container>
					</app-modal>
					<div class="card-body nopadding">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12 col-md-12   nopadding ">
									<div class="col-sm-12 col-md-12 mt-2 mb-2 nopadding" *ngIf="DataRefreshLoaded | async">
										<ag-grid-angular style="width: 100%;" class="ag-theme-alpine" suppressCsvExport="true" [pagination]="true" [paginationPageSize]="pagesize"
										 [rowData]="rowData" (columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)" domLayout="autoHeight"
										 [frameworkComponents]="frameworkComponents" [columnDefs]="columnDefs"></ag-grid-angular>
									</div>
								</div>
							</div>
						</div>

						<div class="modal" id="exampleModal" tabindex="-1" role="dialog">
							<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
								<div class="modal-content" id="example-content">
									<div class="modal-header p-2">
										<h6 class="modal-title">Add New
											<label style="color:green" class="font9 successedit" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
											<label style="color:red" class="font9 erroredit" *ngIf="ErrorMasgLoaded | async">Error occurred !!</label>
										</h6>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body minhyt40 pt-1 pb-0" *ngIf="FormViewLoaded | async">
										<ul class="nav nav-tabs">
											<li class="active">
												<a data-toggle="tab" class="active" href="#tab{{formId}}">{{formName}}</a>
											</li>
											<li *ngFor="let lst of childFormList">
												<a [class]="isAddMode?'disabled':''" data-toggle="tab" href="#tab{{lst.form_id}}">{{lst.form_name}}</a>
											</li>
											<li *ngIf="is_attachment == true">
												<a [class]="isAddMode?'disabled':''" data-toggle="tab" href="#tabAttachment">Attachments</a>
											</li>
										</ul>
										<div class="tab-content tab-contents pt-0 pr-0 pl-0">
											<div id="tab{{formId}}" class="tab-pane fade in active show">
												<hdsbpm-form-repeat [section]="SectionConfig" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode"
												 [stageid]="stageid" [processid]="processid" [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)"
												 (submit)="submit($event)"></hdsbpm-form-repeat>
											</div>
											<ng-container *ngIf="isAddMode == false">
												<div *ngFor="let lst of childFormList; index as i" id="tab{{lst.form_id}}" class="tab-pane fade">
													<hdsbpm-grid-form [lstsection]="lst" [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form>
												</div>
												<div class="tab-pane fade" id="tabAttachment">
													<app-file-attachment id="add" [rowid]="refKey" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
												</div>
											</ng-container>

										</div>
									</div>
									<!-- <div *ngIf="SuccessMasgLoaded" class="d-flex justify-content-center cstmspinner">
												<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
												</div>
											</div> -->
								</div>
							</div>
						</div>
						<div class="modal" id="editModal" tabindex="-1" role="dialog">
							<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
								<div id="edit-content" class="modal-content">
									<div class="modal-header p-2">
										<h6 class="modal-title">Edit
											<label style="color:green" class="font9 successedit" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
											<label style="color:red" class="font9 erroredit" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
										</h6>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body minhyt40 pt-1 pb-0" *ngIf="EditLoaded | async">
										<ul class="nav nav-tabs">
											<li class="active" *ngIf="edit_option == 1 || edit_option == 2">
												<a data-toggle="tab" class="active" href="#tabedit{{formId}}">{{formName}}</a>
											</li>
											<li *ngFor="let lst of childFormList">
												<a *ngIf="edit_option == 1 || edit_option == 3" [class]="isAddMode?'disabled':''" id="tabchildedit{{lst.form_id}}"
												 data-toggle="tab" href="#tabedit{{lst.form_id}}">{{lst.form_name}}</a>
											</li>
											<li *ngIf="is_attachment == true">
												<a [class]="isAddMode?'disabled':''" data-toggle="tab" href="#tabeditAttachment">Attachments</a>
											</li>
										</ul>
										<div class="tab-content tab-contents pl-0 pr-0  pt-0  ">
											<div id="tabedit{{formId}}" class="tab-pane fade in active show" *ngIf="edit_option == 1 || edit_option == 2">
												<hdsbpm-form-repeat [section]="SectionConfig" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode"
												 [stageid]="stageid" [processid]="processid" [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)"
												 (submit)="submit($event)"></hdsbpm-form-repeat>
											</div>
											<ng-container *ngIf="isAddMode == false">
												<div *ngFor="let lst of childFormList; index as i" id="tabedit{{lst.form_id}}" class="tab-pane fade">
													<hdsbpm-grid-form [lstsection]="lst" *ngIf="edit_option == 1 || edit_option == 3" [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form>
												</div>
												<div class="tab-pane fade" id="tabeditAttachment">
													<app-file-attachment id="edit" [rowid]="refKey" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
												</div>
											</ng-container>
										</div>
									</div>
									<!-- <div *ngIf="SuccessMasgLoaded" class="d-flex justify-content-center cstmspinner">
												<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
												</div>
											</div> -->
								</div>
							</div>
						</div>
						<div class="modal" id="viewModal" tabindex="-1" role="dialog">
							<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
								<div id="viewcontent" class="modal-content" *ngIf="ViewLoaded | async">
									<div class="modal-header p-2">
										<h6 class="modal-title">View</h6>
										<button type="button" class="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body minhyt40" *ngIf="FormViewLoaded | async">
										<dynamic-formview [section]="formAllField"></dynamic-formview>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="!DataRefreshLoaded" class="d-flex justify-content-center cstmspinner">
						<div class="spinner-border" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>