<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-8 col-lg-8">
          <nav aria-label="breadcrumb py-lg-3 py-1 ">
            <ol class="breadcrumb font-13 brd-crm pt-2 mb-0">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" (click)="Viewcart()">View Cart

                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Checkout </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <ul class="nav nav-tabs perftabs" id="myTab" role="tablist">
            <li class="nav-item mr-2" role="presentation">
              <a class="nav-link border-0 active" id="personal-tab" data-toggle="tab" data-target="#personal" type="button" role="tab"
                aria-controls="more" aria-selected="true">Personal Checkout</a>
            </li>
            <li class="nav-item mr-2" role="presentation">
              <a class="nav-link disabled border-0 menu-dis" id="business-tab" data-toggle="tab" data-target="#business" type="button" role="tab"
                aria-controls="business" aria-selected="false">Business Checkout</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="personal" role="tabpanel" aria-labelledby="personal-tab">
              <section class="py-lg-3 py-2">
                <h5>Proceed To Checkout</h5>
                <div class="py-2">
                  <div class="row">
                    <div class="col-md-8 col-lg-8 col-12 mb-3 mb-md-0">

                      <div class="tab-content" id="v-pills-tabContent">
                        <!-- <div class="tab-pane fade border shadow1 rounded bg-white show active p-3 px-4 mb-3"
                                                id="personal" role="tabpanel" aria-labelledby="business-tab">
                                                <div class="form-card mb-3">
                                                    <div class="row mb-3">
                                                        <div class="col-12">
                                                            <h5 class="fs-title">Login</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6 mb-3">
                                                            <div class="form-group input-group">
                                                                <span class="has-float-label">
                                                                    <label for="personal-name">{{customer_full_Name }}
                                                                        {{custome_mobile_No}}</label>
                                                                </span>
            
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                </div>
                                            </div> -->

                        <div class="tab-pane fade border shadow1 rounded bg-white show active mb-3 rounded" id="personal" role="tabpanel" aria-labelledby="business-tab">
                          <div class="form-card">
                            <div class="row ">
                              <div class="col-12">
                                <h5 class="fs-title dlvery-head mb-0">Billing Address</h5>
                              </div>
                            </div>
                            <!-- <div class="row">
                                                        
            
                                                    <div class="col-sm-6 mb-3">
                                                        <div class="form-group input-group">
                                                            <span class="has-float-label">
                                                                <p>sikri,petrol pump ,kelash colony,gali no=3, modinagar gzb</p>
                                                            </span>
            
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="address-card">
                                                  <ng-container *ngIf="billingAddList.length>0">
                                                      <div class="address_sub border-botm p-2 pt-3 px-3" *ngFor="let add of billingAddList;let i=index">

                                                          <div class="row position-relative">
                                                              <div class="col-md-1 col-2 text-center">
                                                                  <input type="radio" class="rad" (change)="onBillingChange()" name="select_address_billing{{add.address_id}}" value="{{add.address_id}}"
                                                                      [(ngModel)]="selected_billing_id">
                                                              </div>

                                                              <div class="col-md-11 col-10 ps-0 font-add">
                                                                  <p class="font600 mb-2 text-capitalize">{{add.name}}
                                                                      <a href="javascript:void(0)" class="editinput text-decoration-none text-danger ms-2 float-right" (click)="showPopup(add.address_id , 'b')"
                                                                      data-toggle="modal" data-target="#remove-item">
                                                                      <i class="fa fa-trash ml-1"></i>
                                                                      <span class=" d-none d-xl-inline-block ml-1">Delete</span>
                                                                  </a>
                                                                  <!-- <span class="mx-2">|</span> -->
                                                                      <a href="javascript:void(0)" class="text-theme text-decoration-none ml-1 float-right" (click)="edit(add.address_id)">
                                                                          <i class="fa fa-edit"></i>
                                                                          <span class="ml-1">Edit</span>
                                                                      </a>
                                                                     
                                                                      <!-- <a href="javascript:void(0)" class="editinput text-decoration-none text-danger me-2"
                                                                  (click)="DeleteAddres1(add.address_id)"><i class="fa fa-trash"></i>
                                                                  <span class="ml-1">Delete</span>
                                                              </a> -->
                                                                   
                                                                  </p>

                                                                  <p class="mb-2">{{add.locatity}}
                                                                      <br> {{add.area_street}},{{add.city}}
                                                                      <br> {{add.state_name}}-{{add.pin_code}}
                                                                  </p>
                                                                  <p class="mb-2">
                                                                      <strong>Phone:</strong> {{add.mobile}} </p>
                                                                  <p class="mb-0">
                                                                      <strong>Email:</strong> {{add.email_address}} </p>
                                                              </div>


                                                              <div class="col-md-4 col-3 text-end"></div>
                                                              <div class="col-md-12 mt-2  offset-md-1 p-0">

                                                              </div>
                                                              <!-- *ngIf="addressList.length==0" -->
                                                          </div>
                                                      </div>
                                                  </ng-container>
                                                  <div class="address-card ">
                                                      <a href="javascript:void(0)" class="text-decoration-none text-theme p-3 d-inline-block" (click)="showAddressForm('b')" *ngIf="!addNew && addBtn">+ Create a new address </a>
                                                  </div>

                                              </div>

                                              <div class="card shadow-none shadow-none border-0 py-2  mb-3 pt-3 py-2 px-3 mb-3" *ngIf="addNew  ">
                                                <div class="row ">
                                                    <!-- <div class="col-md-12">
                                                <h5>Address:</h5>
                                            </div> -->


                                                    <div class="col-sm-6 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="add_name">First Name
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <input class="form-control" maxlength="50" id="add_name" name="add_name" [(ngModel)]="add_name" type="text" (keypress)="commonFunctionService.alphabetsValidator($event)"
                                                                    placeholder="Enter First Name" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="last_name">Last Name
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <input class="form-control" maxlength="50" id="last_name" [(ngModel)]="last_name" name="c" type="text" (keypress)="commonFunctionService.alphabetsValidator($event)"
                                                                    placeholder="Enter Last Name" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="company_name">Company Name
                                                                </label>
                                                                <input class="form-control" maxlength="150" id="company_name" [(ngModel)]="company_name" name="c" type="text" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                    placeholder="Enter Company Name" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-2 ">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="add_mobile">Mobile Number
                                                                    <span class="text-danger">
                                                                        *</span>
                                                                </label>
                                                                <input class="form-control" id="add_mobile" maxlength="10" [(ngModel)]="add_mobile" type="text" name="add_mobile" (keypress)="commonFunctionService.numericCheck($event)"
                                                                    placeholder="Enter Mobile number" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="email_address">E-mail Address
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <input class="form-control" maxlength="50" id="email_address" name="email_address" type="email" [(ngModel)]="email_address"
                                                                    (keypress)="commonFunctionService.eMailValidator($event)"
                                                                    placeholder="Enter E-mail Address" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="pin">PIN Code
                                                                    <span class="text-danger">
                                                                        *</span>
                                                                </label>
                                                                <input class="form-control" maxlength="6" id="pin" type="text" placeholder="Enter PIN Code" (keypress)="commonFunctionService.numericCheck($event)"
                                                                    (keyup)="getCityAndState()" name="pinCode" [(ngModel)]="pinCode"
                                                                    autocomplete="off" ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="locality">Locality
                                                                    <span class="text-danger">
                                                                        *</span>
                                                                </label>
                                                                <input class="form-control" maxlength="100" id="locality" type="text" name="locality" placeholder="Enter Locality" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                    name="locality" [(ngModel)]="locality" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="area">Address
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <!-- <input class="form-control" id="area" type="text" name="area" placeholder="Address(Area and Street)"
                                                     [(ngModel)]="add_area_street" /> -->

                                                                <textarea name="" id="area" class="form-control2 h-auto min_hyt" maxlength="250" type="text" name="area" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                    placeholder="Enter Address(Area and Street)"
                                                                    [(ngModel)]="add_area_street" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"></textarea>

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-2">
                                                        <div class="form-group input-group1">
                                                            <!-- <span class="text-danger"> *</span> -->
                                                            <span class="has-float-label-no">
                                                                <label for="country">Country
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <select class="form-control" id="country" name="country_id" [(ngModel)]="country_id">
                                                                    <option value="undefined">-: Select Country :-</option>
                                                                    <option *ngFor="let con of countryList" value="{{con.country_id}}">{{con.country_name}}
                                                                    </option>
                                                                </select>

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-2">
                                                        <div class="form-group input-group1">
                                                            <!-- <span class="text-danger"> *</span> -->
                                                            <span class="has-float-label-no">
                                                                <label for="state">State
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <select class="form-control" id="state" name="state_id" [(ngModel)]="state" disabled>
                                                                    <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                                                        {{s.state_name}}</option>
                                                                </select>

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-2">
                                                        <!-- <span class="text-danger"> *</span> -->
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="city">City
                                                                    <span class="text-danger"> *</span>
                                                                </label>
                                                                <input class="form-control" id="city" type="text" readonly onkeydown="return false;" name="city" placeholder="City" [(ngModel)]="city"
                                                                />
                                                                <!-- <select name="city_id" class="form-control" id="city"
                                                         name="bcity" [(ngModel)]="city_id">
                                                         <option *ngFor="let c of cityList" [value]="c.city_id">
                                                            {{c.city_name}}</option>
                                                    </select> -->

                                                            </span>

                                                        </div>
                                                    </div>


                                                    <div class="col-sm-6 mb-2">
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="lnd">Landmark </label>
                                                                <input class="form-control" id="landmark" maxlength="50" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                    type="text" name="lnd" placeholder="Enter Landmark(optional)"
                                                                    [(ngModel)]="landmark" autocomplete="off" ondrop="return false"
                                                                    onpaste="return false" />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <div class="form-group input-group1">
                                                            <span class="has-float-label-no">
                                                                <label for="landmark">Alternate Phone </label>
                                                                <input class="form-control" maxlength="10" id="landmark" type="text" name="alt" (keypress)="commonFunctionService.numericCheck($event)"
                                                                    placeholder="Enter Alternate Phone(optional)"
                                                                    [(ngModel)]="alternate_phone" autocomplete="off"
                                                                    ondrop="return false" onpaste="return false"
                                                                />

                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-2" *ngIf="addBtn">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="is_for_future" value="" id="flexCheckDefault">
                                                            <label class="form-check-label pt-1 ml-2" for="flexCheckDefault">
                                                                Don't save address for future use
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-2">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                                                            <label class="form-check-label pt-1 ml-2" for="flexCheckDefault2">
                                                                Save as default delivery address?
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 mb-2" *ngIf="addTypetext == 's' ">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input" (change)="takeBilling()" [(ngModel)]="sameAsBilling" id="sameasbilling">
                                                            <label for="sameasbilling" class="form-check-label pt-1 ml-2">Same as billing address</label>
                                                        </div>
                                                    </div>
                                                    <div class="text-center w-100 d-block  mb-2 mt-3">
                                                        <button class="btn btn-theme2 rounded-pill px-5 mr-3" *ngIf="addBtn" (click)="addAddress()">Add</button>
                                                        <button class="btn btn-secondary rounded-pill px-lg-5 px-4 mr-3" (click)="closeAddressForm()">Cancel</button>
                                                        <button class="btn text-dark  btn-theme rounded-pill px-4" *ngIf="editBtn" (click)="editAddress()">Update address</button>

                                                    </div>

                                                </div>
                                            </div>
                          </div>
                        </div>

                        <!-- <div class="tab-pane fade border shadow1 rounded bg-white show active mb-3 rounded" id="shippingaddress" role="tabpanel"
                          aria-labelledby="business-tab" >
                          <div class="form-card">
                            <div class="row ">
                              <div class="col-12">
                                <h5 class="fs-title dlvery-head mb-0">Shipping Address</h5>
                              </div>
                            </div>

                            <div class="address_sub col-12 border-botm p-2 pt-3 px-3">
                              <div class="row">
                                <div class="col-md-1 col-2 mx-auto">
                                  <input type="radio" class="rad" name="select_address">
                                </div>
                                <div class="col-md-8 col-7 pl-0">
                                  <p class="font600 mb-0 ">Rahul
                                    <a href="javascript:void(0)" class="text-theme text-decoration-none ml-1">
                                      <i class="fa fa-edit"></i> Edit </a>
                                  </p>
                                  <p class="mb-0">Ideacrft Venture
                                    <br> 110 Noida, Towe 4, DLF
                                    <br> 120021 India </p>
                                  <div class="mt-2">
                                    <strong></strong> 9711425615</div>
                                  <div class="mt-2">
                                    <strong></strong> test@ideacraft.com</div>
                                </div>
                                <div class="col-md-3 p-0 col-3 text-right">
                                  <a href="javascript:void(0)" class="text-decoration-none text-theme pr-3"> + Create a new address </a>
                                </div>
                                <div class="col-md-12 mt-2 mb-3 offset-md-1 p-0">
                                  <button class="btn btn-theme p-1 w-20 ml-2 px-2 py-1"> Deliver Here
                                  </button>
                                </div>

                              </div>
                            </div>

                            <div class="address-card p-3">

                              <a class="text-decoration-none text-theme" href="javascript:void(0)">
                                + Create a new address</a>
                            </div>
                          </div>
                        </div> -->

                        <div class="tab-pane fade border shadow1 rounded bg-white show active mb-3 rounded" id="shippingaddressnew" role="tabpanel"
                                                    aria-labelledby="business-tab">
                                                    <div class="form-card">
                                                        <div class="dlvery-head">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-auto">
                                                                    <h5 class="fs-title  mb-0 crad-head">Shipping Address</h5>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="form-check form-check-inline mb-0">
                                                                        <input class="form-check-input" [(ngModel)]="shipType" type="radio" name="inlineRadioOptions" id="singlelocation" value="single"
                                                                            checked>
                                                                        <label class="form-check-label ms-0" for="singlelocation">Ship To Single Location</label>
                                                                    </div>
                                                                    <!-- <div class="form-check form-check-inline mb-0">
                                                                        <input class="form-check-input" [(ngModel)]="shipType" type="radio" name="inlineRadioOptions" id="multilocation" value="multiple">
                                                                        <label class="form-check-label ms-0" for="multilocation">Ship To Multiple Locations</label>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="address-card" *ngIf="shipType == 'single'">
                                                            <ng-container *ngIf="addressList.length>0">

                                                                <div class="address_sub border-botm p-2 pt-3 px-3" *ngFor="let add of addressList;let i=index">


                                                                    <div class="row position-relative">
                                                                        <div class="col-md-1 col-2 text-center">
                                                                            <input type="radio" class="rad" name="select_address{{add.address_id}}" value="{{add.address_id}}" [(ngModel)]="selected_add_id">
                                                                        </div>
                                                                        <!-- <div class="col-md-10 col-8 ps-0">
                                                                <p class="font600 mb-0 ">{{add.name}}<span class="phone ps-3">{{add.mobile}}</span> </p>
                                                                <p class="mb-0">{{add.locatity}},{{add.area_street}},
                                                                    {{add.city}},{{add.state_name}}-{{add.pin_code}}</p>
                                                            </div> -->
                                                                        <div class="col-md-11 col-10 ps-0 font-add">
                                                                            <p class="font600 mb-2 text-capitalize">{{add.name}}
                                                                                <a href="javascript:void(0)" class="editinput text-decoration-none text-danger ms-2 float-right" (click)="showPopup(add.address_id, 's')"
                                                                                data-toggle="modal" data-target="#remove-item">
                                                                                <i class="fa fa-trash ml-1"></i>
                                                                                <span class=" d-none d-xl-inline-block ml-1">Delete</span>
                                                                            </a>
                                                                                <a href="javascript:void(0)" class="text-theme text-decoration-none ml-1 float-right" (click)="edit(add.address_id)">
                                                                                    <i class="fa fa-edit"></i>
                                                                                    <span class="ml-1">Edit</span>
                                                                                </a>
                                                                                <!-- <span class="mx-2">|</span> -->
                                                                                <!-- <a href="javascript:void(0)" class="editinput text-decoration-none text-danger me-2"  (click)="DeleteAddres1(add.address_id)" ><i class="fa fa-trash"></i>
                                                                        <span class="ml-1">Delete</span>
                                                                        </a> -->
                                                                           
                                                                            </p>

                                                                            <p class="mb-2">{{add.locatity}}
                                                                                <br> {{add.area_street}},{{add.city}}
                                                                                <br> {{add.state_name}}-{{add.pin_code}}
                                                                            </p>
                                                                            <p class="mb-2">
                                                                                <strong>Phone:</strong> {{add.mobile}} </p>
                                                                            <p class="mb-0">
                                                                                <strong>Email:</strong> {{add.email_address}} </p>
                                                                        </div>





                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="address-card" *ngIf="shipType != 'multiple'">
                                                            <a href="javascript:void(0)" class="text-decoration-none text-theme p-3 d-inline-block" (click)="showAddressForm('s')" *ngIf="!addNew && addBtn">+ Create a new address</a>
                                                        </div>

                                                        <div class="address_sub border-botm px-3 py-4" *ngIf="shipType == 'multiple'">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-4 text-center mb-2 mb-sm-0 pe-md-4">
                                                                    <div class="uploaddata">
                                                                        <div class="card shadow-none">
                                                                            <label class="justify-content-center cursor-pointer" for="fileupld">
                                                                                <div class="drag-drop">
                                                                                    <input type="file" id="fileupld" #uploadedfile (change)="upload($event)">

                                                                                    <figure class="figure text-center mb-0">
                                                                                        <img class="figure-img" src="../../assets/images/icons/upload.png" alt="icon" width="60">
                                                                                    </figure>
                                                                                    <p class="small mb-1">Drag and drop or click to upload</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="mt-2">
                                                                            <a href="javascript:void(0)" style="color: #2504e4;" (click)="export()" class="d-inline-block text-decoration-none font8">
                                                                                <i class="fa fa-download"></i> Download Template</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-7 uploadstatus ps-md-4" *ngIf="totalInsertedRows">
                                                                    <h6 class="fw-bold mb-2">Upload Status</h6>
                                                                    <div class="row gx-2 align-items-center">
                                                                        <div class="col">
                                                                            <div class="progress">
                                                                                <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="col-auto">
                                                                <div class="ms-2">25%</div>
                                                            </div> -->
                                                                    </div>

                                                                    <p class="font600 text-capitalize mb-1  mt-2" *ngIf="totalInsertedRows">Total {{totalInsertedRows}} {{totalInsertedRows<=1? 'Row'
                                                                        :'Rows'}} Uploaded</p>
                                                                    <!-- <p class="mb-0 font7">Error: 0</p> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                        <div class="novopay-response-modal modal fade" role="dialog" [ngClass]="{'show': showModal}" id="response-modal" [ngStyle]="{'display': 'none'}">
                          {{response}}
                        </div>

                        <div class="novopay-response-modal modal fade" role="dialog" [ngClass]="{'show': showModal}" id="razorpay-response" [ngStyle]="{'display': 'none'}">
                          {{razorpayResponse}}
                        </div>
                        <div class="tab-pane fade border shadow1 rounded bg-white show active rounded" id="personal" role="tabpanel" aria-labelledby="business-tab">
                          <div class="form-card">
                            <div class="row ">
                              <div class="col-12">
                                <h5 class="fs-title dlvery-head mb-0">Order Details</h5>
                              </div>
                            </div>
                            <h6 class="color-dark p-3 mb-0">Your Total is {{rupeeIndian.format(totalAmount)}} for {{cart_items.length}} {{cart_items.length==1?'item':'items'}}.
                            </h6>
                            <div class="addeditems">
                              <div class="address-card  px-4" *ngIf="cart_items.length>0">
                                <div class="address_sub border p-2 px-3">
                                  <div *ngFor="let prod of cart_items let i=index" class="border-bottom1 ">

                                    <div class="row">
                                      <div class="col-md-3 col-xl-2 col-6 mb-3">
                                        <img class="img-cart rounded-0 img-fluid mb-2" src="../../..{{prod.image_path}}" height="100" width="100">

                                      </div>
                                      <div class="col-6 col-md-9 col-xl-8 mb-3">
                                        <p class="font600 mb-0 "> {{prod.product_name}} </p>
                                        <!-- <small>
                                          <a>{{prod.product_discription | slice:0:50}}
                                            <span id="dots">...</span>
                                          </a>
                                        </small>
                                        <small class="d-none">
                                          <p class="mb-1">
                                            <b> Seller: </b> {{prod.seller_name}}</p>
                                        </small> -->
                                        <div class="mb-1">
                                          <h5 class="pric mb-0" *ngIf="prod.product_mrp">

                                            <b> {{rupeeIndian.format(prod.price)}}</b>
                                            <!-- <small class="text-secondary pr-1 prod-mrp">
                                              <del>₹928</del>
                                            </small> -->
                                            <!-- <small class="text-success pl-1" *ngIf="prod.discountPer>0"> {{prod.discountPer}}% OFF </small> -->
                                          </h5>
                                          <h5 class="pric" *ngIf="!prod.product_mrp">
                                            <b> {{rupeeIndian.format(prod.price)}} </b>
                                          </h5>
                                        </div>

                                        <!-- <a class="remove" (click)="remove(prod.product_id)">
                                          <i class="fa fa-trash"></i> Remove</a> -->
                                      </div>
                                      <div class="col-md-3 col-xl-2 col-12 mb-3 text-md-center">
                                        <div class="input-group p-0 add-to-cart align-item-md-center productqty">

                                          <span class="pr-2">Qty</span>

                                          <input type="text" keyDown="false" class="cst-frm h-auto midbox" readonly disabled [(ngModel)]="prod.qtytotal">
                                          <!-- <div class="input-group-append">
  
                                            <span class="input-group-text"> <button class=""
                                                    (click)="plus(prod,i)"><i
                                                        class="fa fa-plus"></i></button> </span>
                                        </div> -->
                                        </div>
                                        <!-- <div class="text-center">
                                    <a  data-bs-toggle="modal" data-bs-target="#uploadmultipleadrs" href="javascript:void(0)" class="upl-bulk-ads font8 text-decoration-none
                                    "><i class="fas fa-map-marker-alt ml-1"></i> Upload Multiple Address</a>
                                    </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row my-4 align-items-center">

                                  <div class="col-md-8">
                                    <h6 class="mb-0"> Order confirmation email will be sent to
                                      <b class="text-success">{{customerEmail}}</b>
                                    </h6>
                                  </div>
                                  <div class="col-md-4  text-right">
                                    <!-- <button class="btn text-dark py-2 btn-theme px-4 rounded-pill" id="order-add" (click)="orderAdd()">Checkout &
                                                                        Pay</button> -->
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div *ngIf="cart_items.length==0" class="text-center">
                          <!-- style=" height: 100vh;justify-content: center;align-items: center;" -->
                          <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
                          <!-- <h2 class="color-dark mb-3">Shopping Cart</h2> -->
                          <p>Shopping Cart is Empty!</p>
                          <a href="javascript:void(0)">
                            <button class="btn cont py-2 color-dark btn-secondary rounded-pill" (click)="continueShopping()">
                              Continue Shopping </button>
                          </a>

                        </div>
                      </div>

                    </div>


                    <div class="col-md-4 col-lg-4 col-12 mb-3 mb-md-0">

                      <div class="payment-summary py-2 gray-bg1 rounded">
                        <div class="px-3 py-2 border-botm">
                          <h5 class="color-dark mb-1">Payment Summary</h5>
                          <p class="mb-2">Your shopping cart {{cart_items.length==1?'contains':'contain'}} {{cart_items.length}} {{cart_items.length==1?'product':'products'}}</p>
                        </div>
                        <div class="py-sb px-4 py-1">
                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="darkcolor">Price ({{cart_items.length}} Items)</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="darkcolor">
                                {{convertToInt()}}
                              </h6>
                            </div>
                          </div>
                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="darkcolor">Delivery Charges</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="darkcolor">
                                <b class="text-success"> FREE </b>
                              </h6>
                            </div>
                          </div>

                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="darkcolor">GST</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="darkcolor">
                                {{rupeeIndian.format(grandGst)}}
                              </h6>
                            </div>
                          </div>
                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="darkcolor">Shipping</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="darkcolor">
                                {{shipingPrice ? shipingPrice : '₹00.00'}}
                              </h6>
                            </div>
                          </div>

                          <div class="row py-2 fnl-am border-botm">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="darkcolor">Coupon Discount</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="darkcolor">
                                ₹00.00
                              </h6>
                            </div>
                          </div>

                          <div class="row  py-2  border-botm">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="dark-color">
                                <strong> Amount Payable</strong>
                              </h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="dark-color">
                                <b>{{rupeeIndian.format(totalAmount)}}</b>
                              </h6>
                            </div>
                          </div>
                          <!-- <div class="fnl-am mt-3 d-none">
                                                    <p class="text-success-theme text-right">₹00.00 You saved on this order</p>
                                                </div>  -->
                          <div class="row py-2 mt-3 fnl-am d-none">
                            <label>Have A Promo Code?</label>
                            <div class="input-group promocode">
                              <input type="text" autocomplete="off" class="form-control">
                              <div class="input-group-append btn btn-secondary px-4">
                                Apply
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="mt-4 mb-4">
                          <div class="text-center" *ngIf="cart_items.length > 0">
                            <button class="btn text-dark py-2 btn-theme2 px-5 rounded-pill" (click)="orderAdd()"> Place Order</button>
                          </div>
                        </div>

                      </div>

                      <!-- <div class="payment-summary py-2 gray-bg1 mt-4 rounded">
                                            <div class="px-3 py-2 border-botm">
                                                <h5 class="color-dark mb-1">In The Cart</h5>
                                                <p class="mb-2">Your shopping cart contains {{cart_items.length}} {{cart_items.length==1?'product':'products'}}</p>
                                            </div>
                                            <div class="py-sb px-4 py-1">
                                                <div class="row py-2 fnl-am">
                                                    <div class="col-md-4 col-lg-3 col-5 pe-0">
                                                        <img  alt="" class="img-fluid" src="../../assets/images/product/products08.png">
                                                    </div>
                                                    <div class="col-md-8 col-lg-9 col-7">
                                                        <h5 class="dark-color pt-0"> Symphony Sumo Household Cooler </h5>
                                                        <div><strong>Color:</strong> gray</div>
                                                        <div><strong>Unit Price:</strong> ₹2335</div>
                                                        <div><strong>Qty:</strong> 15</div>
            
                                                    </div>
                                                </div>
                                                <div class="row py-2 fnl-am">
                                                    <div class="col-md-4 col-lg-3 col-5 pe-0">
                                                        <img  alt="" class="img-fluid" src="../../assets/images/product/products08.png">
                                                    </div>
                                                    <div class="col-md-8 col-lg-9 col-7">
                                                        <h5 class="dark-color pt-0"> Symphony Sumo Household Cooler </h5>
                                                        <div><strong>Color:</strong> gray</div>
                                                        <div><strong>Unit Price:</strong> ₹2335</div>
                                                        <div><strong>Qty:</strong> 15</div>
            
                                                    </div>
                                                </div>
                                                <div class="row py-2 fnl-am">
                                                    <div class="col-md-4 col-lg-3 col-5 pe-0">
                                                        <img  alt="" class="img-fluid" src="../../assets/images/product/products08.png">
                                                    </div>
                                                    <div class="col-md-8 col-lg-9 col-7">
                                                        <h5 class="dark-color pt-0"> Symphony Sumo Household Cooler </h5>
                                                        <div><strong>Color:</strong> gray</div>
                                                        <div><strong>Unit Price:</strong> ₹2335</div>
                                                        <div><strong>Qty:</strong> 15</div>
            
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row fnl-am">
                                                <div class="col-12">
                                                    <p class="mx-auto p-4 mb-0">
                                                        NOTE: All sales are final for items that have been ordered with customization.
                                                    </p>
                                                </div>
                                              
                                            </div>
                                        </div> -->
                    </div>
                  </div>
                </div>
              </section>

            </div>
            <div class="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
              <section class="py-lg-3 py-2">
                <h5>Proceed To Checkout</h5>
                <div class="py-2">
                  <div class="row">
                    <div class="col-md-8 col-lg-8 col-12 mb-3 mb-md-0">
                      <div class="tab-content" id="v-pills-tabContent">
                        <!-- <div class="tab-pane fade border shadow1 rounded bg-white show active p-3 px-4 mb-3"
                                                id="personal" role="tabpanel" aria-labelledby="business-tab">
                                                <div class="form-card mb-3">
                                                    <div class="row mb-3">
                                                        <div class="col-12">
                                                            <h5 class="fs-title">Login</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6 mb-3">
                                                            <div class="form-group input-group">
                                                                <span class="has-float-label">
                                                                    <label for="personal-name">{{customer_full_Name }}
                                                                        {{custome_mobile_No}}</label>
                                                                </span>
            
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                </div>
                                            </div> -->

                                            <div class="tab-pane fade border shadow1 rounded bg-white show active mb-3 rounded" id="personal" role="tabpanel" aria-labelledby="business-tab">
                                              <div class="form-card">
                                                  <div class="row ">
                                                      <div class="col-12">
                                                          <h5 class="fs-title dlvery-head mb-0">Delivery Address</h5>
                                                      </div>
                                                  </div>
                                                  <!-- <div class="row">
                                                  
      
                                              <div class="col-sm-6 mb-3">
                                                  <div class="form-group input-group">
                                                      <span class="has-float-label">
                                                          <p>sikri,petrol pump ,kelash colony,gali no=3, modinagar gzb</p>
                                                      </span>
      
                                                  </div>
                                              </div>
                                          </div> -->
                                                  <div class="card shadow-none shadow-none border-0 py-2  mb-3 pt-3 py-2 px-3 mb-3" *ngIf=" addNew  ">
                                                      <div class="row ">
                                                          <!-- <div class="col-md-12">
                                                      <h5>Address:</h5>
                                                  </div> -->

                                                          <div class="col-sm-6 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="add_name">First Name
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <input class="form-control" maxlength="50" id="add_name" name="add_name" [(ngModel)]="add_name" type="text" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                          placeholder="Enter First Name" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="last_name">Last Name
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <input class="form-control" maxlength="50" id="last_name" name="c" type="text" placeholder="Enter Last Name" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-12 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="company_name">Company Name
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <input class="form-control" maxlength="50" id="company_name" name="c" type="text" placeholder="Enter Company Name" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2 ">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="add_mobile">Mobile Number
                                                                          <span class="text-danger">
                                                                              *</span>
                                                                      </label>
                                                                      <input class="form-control" id="add_mobile" maxlength="10" [(ngModel)]="add_mobile" type="text" name="add_mobile" (keypress)="commonFunctionService.numericCheck($event)"
                                                                          placeholder="Enter Mobile Number" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="email_address">E-mail Address
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <input class="form-control" maxlength="50" id="email_address" (keypress)="commonFunctionService.eMailValidator($event)" name="email_address"
                                                                          type="email" placeholder="Enter E-mail Address "
                                                                          autocomplete="off" ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="pin">PIN Code
                                                                          <span class="text-danger">
                                                                              *</span>
                                                                      </label>
                                                                      <input class="form-control" maxlength="6" id="pin" type="text" placeholder="Enter PIN Code" (keypress)="commonFunctionService.numericCheck($event)"
                                                                          (keyup)="getCityAndState()" name="pinCode" [(ngModel)]="pinCode"
                                                                          autocomplete="off" ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="locality">Locality
                                                                          <span class="text-danger">
                                                                              *</span>
                                                                      </label>
                                                                      <input class="form-control" maxlength="100" id="locality" type="text" name="locality" placeholder="Enter Locality" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                          name="locality" [(ngModel)]="locality" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-12 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="area">Address
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <!-- <input class="form-control" id="area" type="text" name="area" placeholder="Address(Area and Street)"
                                                           [(ngModel)]="add_area_street" /> -->

                                                                      <textarea name="" id="area" class="form-control2 h-auto min_hyt" maxlength="250" type="text" name="area" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                          placeholder="Enter Address(Area and Street)"
                                                                          [(ngModel)]="add_area_street" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"></textarea>

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-4 mb-2">
                                                              <div class="form-group input-group1">
                                                                  <!-- <span class="text-danger"> *</span> -->
                                                                  <span class="has-float-label-no">
                                                                      <label for="country">Country
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <select class="form-control" id="country" name="country_id">
                                                                          <option>India</option>
                                                                          <option>USA</option>
                                                                      </select>

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-4 mb-2">
                                                              <div class="form-group input-group1">
                                                                  <!-- <span class="text-danger"> *</span> -->
                                                                  <span class="has-float-label-no">
                                                                      <label for="state">State
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <select class="form-control" id="state" name="state_id" [(ngModel)]="state" disabled>
                                                                          <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                                                              {{s.state_name}}</option>
                                                                      </select>

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-4 mb-2">
                                                              <!-- <span class="text-danger"> *</span> -->
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="city">City
                                                                          <span class="text-danger"> *</span>
                                                                      </label>
                                                                      <input class="form-control" id="city" type="text" readonly onkeydown="return false;" name="city" placeholder="City" [(ngModel)]="city"
                                                                      />
                                                                      <!-- <select name="city_id" class="form-control" id="city"
                                                               name="bcity" [(ngModel)]="city_id">
                                                               <option *ngFor="let c of cityList" [value]="c.city_id">
                                                                  {{c.city_name}}</option>
                                                          </select> -->

                                                                  </span>

                                                              </div>
                                                          </div>


                                                          <div class="col-sm-6 mb-2">
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="lnd">Landmark </label>
                                                                      <input class="form-control" id="landmark" maxlength="50" (keypress)="commonFunctionService.alphaNumericWithHiphenSpaceSlashValidator($event)"
                                                                          type="text" name="lnd" placeholder="Enter Landmark(optional)"
                                                                          [(ngModel)]="landmark" autocomplete="off" ondrop="return false"
                                                                          onpaste="return false" />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <div class="form-group input-group1">
                                                                  <span class="has-float-label-no">
                                                                      <label for="landmark">Alternate Phone </label>
                                                                      <input class="form-control" maxlength="10" id="landmark" type="text" name="alt" (keypress)="commonFunctionService.numericCheck($event)"
                                                                          placeholder="Enter Alternate Phone(optional)"
                                                                          [(ngModel)]="alternate_phone" autocomplete="off"
                                                                          ondrop="return false" onpaste="return false"
                                                                      />

                                                                  </span>

                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <div class="form-check">
                                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                                                  <label class="form-check-label ms-2" for="flexCheckDefault">
                                                                      Don't save address for future use
                                                                  </label>
                                                              </div>
                                                          </div>
                                                          <div class="col-sm-6 mb-2">
                                                              <div class="form-check">
                                                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                                                                  <label class="form-check-label ms-2" for="flexCheckDefault2">
                                                                      Save as deafault delivery address?
                                                                  </label>
                                                              </div>
                                                          </div>
                                                          <div class="text-center  w-100 d-block mb-2 mt-3">
                                                              <button class="btn btn-theme2 rounded-pill px-5 mr-3" *ngIf="addBtn" (click)="addAddress()">Add</button>
                                                              <button class="btn btn-secondary rounded-pill px-lg-5 px-4 mr-3" (click)="closeAddressForm()">Back</button>
                                                              <button class="btn text-dark  btn-theme rounded-pill px-4" *ngIf="editBtn" (click)="editAddress()">Update address</button>
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div class="address-card">

                                                      <div class="address_sub border-botm p-2 pt-3 px-3" *ngFor="let add of addressList;let i=index;">
                                                          <!-- <h5>
                                                      <div class="dropdown float-right">
                                                          <button class="dropbtn"><img
                                                                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNCAxNiI+CiAgICA8ZyBmaWxsPSIjODc4Nzg3IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgY3g9IjIiIGN5PSIyIiByPSIyIi8+CiAgICAgICAgPGNpcmNsZSBjeD0iMiIgY3k9IjgiIHI9IjIiLz4KICAgICAgICA8Y2lyY2xlIGN4PSIyIiBjeT0iMTQiIHI9IjIiLz4KICAgIDwvZz4KPC9zdmc+Cg=="></button>
                                                          <div class="dropdown-content">
                                                              <a href="javascript:void(0)"
                                                                  (click)="editAddress(add)">Edit</a>
                                                              <span class="divider"></span>
                                                              <a href="javascript:void(0)"
                                                                  (click)="delete(add.address_id)">Delete</a>
      
                                                          </div>
                                                      </div>
                                                  </h5> -->
                                                          <div class="row">
                                                              <div class="col-md-1 col-2 text-center">
                                                                  <input type="radio" class="rad" name="select_address" value="{{add.address_id}}" [(ngModel)]="selected_add_id">
                                                              </div>
                                                              <!-- <div class="col-md-10 col-8 ps-0">
                                                              <p class="font600 mb-0 ">{{add.name}}<span class="phone ps-3">{{add.mobile}}</span> </p>
                                                              <p class="mb-0">{{add.locatity}},{{add.area_street}},
                                                                  {{add.city}},{{add.state_name}}-{{add.pin_code}}</p>
                                                          </div> -->
                                                              <div class="col-md-7 col-10 ps-0 font-add">
                                                                  <p class="font600 mb-0 text-capitalize">{{add.name}}
                                                                      <a href="javascript:void(0)" class="text-theme text-decoration-none ml-1" (click)="edit(add.address_id)">
                                                                          <i class="fa fa-edit"></i>
                                                                          <span class="ml-1">Edit</span>
                                                                      </a>
                                                                  </p>
                                                                  <span class="mx-2">|</span>
                                                                  <!-- <a href="javascript:void(0)" class="editinput text-decoration-none text-danger me-2"  (click)="DeleteAddres1(add.address_id)" ><i class="fa fa-trash"></i>
                                                                  <span class="ml-1">Delete</span>
                                                                  </a> -->
                                                                  <a href="javascript:void(0)" class="editinput text-decoration-none text-danger me-2" (click)="showPopup(add.address_id, 's')"
                                                                      data-toggle="modal" data-target="#remove-item">
                                                                      <i class="fa fa-trash ml-1"></i>
                                                                      <span class=" d-none d-xl-inline-block ml-1">Delete</span>
                                                                  </a>

                                                                  <p class="mb-1">{{add.locatity}}
                                                                      <br> {{add.area_street}},{{add.city}}
                                                                      <br> {{add.state_name}}-{{add.pin_code}}
                                                                  </p>
                                                                  <p class="mb-1">
                                                                      <strong>Phone:</strong> {{add.mobile}} </p>
                                                                  <p class="mb-0">
                                                                      <strong>Email:</strong> {{add.email_address}} </p>
                                                              </div>
                                                              <div class="col-md-3 p-0 col-3 text-end">
                                                                  <a href="javascript:void(0)" class="text-decoration-none text-theme pe-3" (click)="showAddressForm('s')" *ngIf="!addNew && addBtn && i==0">
                                                                      + Create New Address </a>
                                                              </div>
                                                              <!-- <div class="col-md-12 mt-2 mb-3 offset-md-1 p-0">
                                                          <button class="btn btn-theme p-1 w-20 ml-2 px-2 py-1" *ngIf="add.address_id==selected_add_id"> Deliver Here
                                                          </button>
                                                         </div> -->

                                                          </div>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>

                        <!-- <div class="tab-pane fade border shadow1 rounded bg-white show active mb-3 rounded"
                                                id="shippingaddress" role="tabpanel" aria-labelledby="business-tab">
                                                <div class="form-card">
                                                    <div class="row ">
                                                        <div class="col-12">
                                                            <h5 class="fs-title dlvery-head mb-0">Shipping Address</h5>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="address_sub border-botm p-2 pt-3 px-3">
                                                        <div class="row">
                                                            <div class="col-md-1 col-2 mx-auto" >
                                                                <input type="radio" class="rad" name="select_address">
                                                            </div>
                                                         <div class="col-md-8 col-7 pl-0">
                                                             <p class="font600 mb-0 ">Rahul <a href="javascript:void(0)" class="text-theme text-decoration-none ml-1"> <i class="fa fa-edit"></i> Edit </a></p>
                                                             <p class="mb-0">Ideacrft Venture <br>
                                                                110  Noida, Towe 4, DLF<br>
                                                                120021 India </p>
                                                             <div class="mt-2"><strong>Phone:</strong> 9711425615</div>
                                                             <div class="mt-2"><strong>Email:</strong> test@ideacraft.com</div>      
                                                         </div>
                                                         <div class="col-md-3 p-0 col-3 text-right">
                                                              <a href="javascript:void(0)" class="text-decoration-none text-theme pr-3"> + Create a new address </a>
                                                         </div>
                                                        <div class="col-md-12 mt-2 mb-3 offset-md-1 p-0">
                                                            <button class="btn btn-theme p-1 w-20 ml-2 px-2 py-1"> Deliver Here
                                                         </button>
                                                        </div>
            
                                                     </div>
                                                    </div>
            
                                                    <div class="address-card p-3">
                                                        
                                                        <a class="text-decoration-none text-theme" href="javascript:void(0)"> 
                                                            + Create a new address</a>
                                                    </div>
                                                </div>
                                            </div> -->

                        <!-- <div class="tab-pane fade border shadow1 rounded bg-white show active mb-3 rounded d-none"
                                                id="shippingaddress" role="tabpanel" aria-labelledby="business-tab">
                                                <div class="form-card mb-3">
                                                    <div class="row ">
                                                        <div class="col-12">
                                                            <h5 class="fs-title dlvery-head mb-0">Shipping Method</h5>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="card shadow-none shadow-none border-0 py-2  mb-3 pt-4 py-2 px-3 mb-3">
                                                        <div class="row px-4">
                                                            <p class="mb-0">Please enter a shipping address above.</p>
            
                                                            <div class="form-check mb-2">
                                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                                <label class=" ml-2" for="flexRadioDefault1">
                                                                <strong> Standard Delivery : ₹1199.00</strong> 
                                                                <p class="mt-1">Standard Delivery - receive in 3-7 business days</p>
                                                                </label>
                                                            </div>
                                                            <div class="form-check mb-2">
                                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                                <label class=" ml-2" for="flexRadioDefault1">
                                                                <strong> Express Delivery : ₹3199.00</strong> 
                                                                <p class="mt-1">Express Delivery - receive in 3-4 business days</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                </div>
                                            </div> -->

                        <div class="tab-pane fade border shadow1 rounded bg-white show active rounded" id="personal" role="tabpanel" aria-labelledby="business-tab">
                          <div class="form-card">
                            <div class="row ">
                              <div class="col-12">
                                <h5 class="fs-title dlvery-head mb-0">Order Details</h5>
                              </div>
                            </div>
                            <h6 class="color-dark p-3 mb-0">Your Total is ₹{{totalAmount}} for {{cart_items.length}} items.
                            </h6>
                            <div class="row">
                              <div class="address-card col-12 px-4" *ngIf="cart_items.length>0">
                                <div class="address_sub border p-2 px-3">
                                  <div *ngFor="let prod of cart_items let i=index" class="border-bottom1">

                                    <div class="row">
                                      <div class="col-md-3 col-xl-2 col-6 mb-3">
                                        <img class="img-cart img-fluid mb-3" src="../../assets" height="100" width="100">

                                        <!-- <img class="img-cart img-fluid mb-3" src="../../../assets/images/chair-test.webp" alt=""> -->


                                        <div class="input-group mb-3 add-to-cart">
                                          <!-- <div class="input-group-prepend">
                                                                                <span class="input-group-text"> <button class=""
                                                                                        id="minus-btn{{i}}" (click)="minus(prod,i)"><i
                                                                                            class="fa fa-minus"></i></button> </span>
                                                                            </div> -->
                                          <input type="text" keyDown="false" class="cst-frm h-auto midbox" readonly disabled [(ngModel)]="prod.qtytotal">
                                          <!-- <div class="input-group-append">
                
                                                                                <span class="input-group-text"> <button class=""
                                                                                        (click)="plus(prod,i)"><i
                                                                                            class="fa fa-plus"></i></button> </span>
                                                                            </div> -->
                                        </div>
                                      </div>
                                      <div class="col-6 col-md-9 col-xl-8 mb-3">
                                        <p class="font600 mb-0 "> {{prod.product_name}}</p>
                                        <small>
                                          <a>{{prod.product_discription | slice:0:50}}
                                            <span id="dots">...</span>
                                          </a>
                                        </small>
                                        <small>
                                          <p class="mb-1">
                                            <b> Seller: </b>{{prod.seller_name}}</p>
                                        </small>
                                        <div class="mb-1">
                                          <h5 class="pric mb-0">

                                            <b>₹{{prod.price}}</b>
                                            <small class="text-secondary pr-1">
                                              <del>₹{{prod.product_mrp}}</del>
                                            </small>
                                            <small class="text-success pl-1" *ngIf="prod.discountPer>0"> {{prod.discountPer}}% OFF </small>
                                          </h5>
                                          <h5 class="pric">
                                            <b> ₹{{prod.price}} </b>
                                          </h5>
                                        </div>

                                        <a class="remove" (click)="remove(prod.product_id)">
                                          <i class="fa fa-trash"></i> Remove</a>
                                      </div>
                                      <div class="col-md-3 col-xl-2 col-12 mb-3 text-md-center">
                                      </div>

                                    </div>
                                    <!-- <div class="form-check-inline">
                                                                    <button class="btn btn-primary" (click)="plus(prod,i)"><i class="fa fa-plus"></i></button>
                                                                </div>
                                                                <div class="form-check-inline">
                                                                    <input type="text" keyDown="false" class="form-control" readonly [(ngModel)]="prod.qtytotal">
                                                                </div>
                                                                <div class="form-check-inline">
                                                                    <button class="btn btn-primary " id="minus-btn{{i}}" (click)="minus(prod,i)"><i
                                                                            class="fa fa-minus"></i></button>
                                                                </div> -->





                                  </div>


                                </div>
                                <div class="row my-4 align-items-center">

                                  <div class="col-md-8">
                                    <h6 class="mb-0"> Order confirmation email will be sent to
                                      <b>{{customerEmail}}</b>
                                    </h6>
                                  </div>
                                  <div class="col-md-4  text-right">
                                    <button class="btn text-dark py-2 btn-theme px-4 rounded-pill" id="order-add" (click)="orderAdd()">Checkout & Pay
                                    </button>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div *ngIf="cart_items.length==0" class="mx-auto">
                          <!-- style=" height: 100vh;justify-content: center;align-items: center;" -->
                          <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
                          <!-- <h2 class="color-dark mb-3">Shopping Cart</h2> -->
                          <p>Shopping Cart is Empty!</p>
                          <a href="javascript:void(0)">
                            <button class="btn cont py-2 color-dark btn-secondary rounded-pill" (click)="continueShopping()"> Continue Shopping </button>
                          </a>

                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="mx-auto">
                          <button class="btn text-dark py-2 btn-theme2 px-5 rounded-pill" data-toggle="modal" data-target="#orderPlaced"> Place Order</button>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-4 col-lg-4 col-12 mb-3 mb-md-0">

                      <div class="payment-summary py-2 gray-bg1 rounded">
                        <div class="px-3 py-2 border-botm">
                          <h5 class="mb-1">Payment Summary</h5>
                          <p class="mb-2">Your shopping cart {{cart_items.length==1?'contains':'contain'}} {{cart_items.length}} {{cart_items.length==1?'product':'products'}}</p>
                        </div>
                        <div class="py-sb px-4 py-1">
                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="">Price ({{cart_items.length}} Items) </h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="">
                                <b> ₹{{totalAmount}}.00 </b>
                              </h6>
                            </div>
                          </div>
                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="">Delivery Charges</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="">
                                <b class="text-success"> FREE </b>
                              </h6>
                            </div>
                          </div>

                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="">GST</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="">
                                <b> ₹3,289.00</b>
                              </h6>
                            </div>
                          </div>
                          <div class="row border-botm py-2">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="">Shipping</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="">
                                <b> ₹1,189.00</b>
                              </h6>
                            </div>
                          </div>

                          <div class="row py-2 fnl-am border-botm">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="">Coupon Discount</h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="">
                                <b> ₹383.50 </b>
                              </h6>
                            </div>
                          </div>

                          <div class="row  py-2  border-botm">
                            <div class="col-md-6 col-lg-6 col-8">
                              <h6 class="dark-color">
                                <strong> Amount Payable</strong>
                              </h6>
                            </div>
                            <div class="col-md-6 col-lg-6 col-4 text-right">
                              <h6 class="dark-color">
                                <b> ₹5493 </b>
                              </h6>
                            </div>
                          </div>
                          <!-- <div class="fnl-am mt-3">
                                                    <p class="text-success-theme text-right">₹5789.00 You saved on this order</p>
                                                </div>  -->
                          <!-- <div class="row py-2 mt-3 fnl-am">
                                                   <label>Have A Promo Code?</label>
                                                   <div class="input-group promocode">
                                                    <input type="text" autocomplete="off" class="form-control">
                                                    <div class="input-group-append btn btn-secondary px-4">
                                                        Apply
                                                    </div>
                                                   </div>
                                                </div> -->
                        </div>


                      </div>

                      <!-- <div class="payment-summary py-2 gray-bg1 mt-4 rounded d-none"  >
                                            <div class="px-3 py-2 border-botm">
                                                <h5 class="color-dark mb-1">In The Cart</h5>
                                                <p class="mb-2">Your shopping cart contains {{cart_items.length}} {{cart_items.length==1?'product':'products'}}</p>
                                            </div>
                                            <div class="py-sb px-4 py-1">
                                                <div class="row py-2 fnl-am">
                                                    <div class="col-md-4 col-lg-3 col-5 pe-0">
                                                        <img  alt="" class="img-fluid" src="../../assets/images/product/products08.png">
                                                    </div>
                                                    <div class="col-md-8 col-lg-9 col-7">
                                                        <h5 class="dark-color pt-0"> Symphony Sumo Household Cooler </h5>
                                                        <div><strong>Color:</strong> gray</div>
                                                        <div><strong>Unit Price:</strong> ₹2335</div>
                                                        <div><strong>Qty:</strong> 15</div>
            
                                                    </div>
                                                </div>
                                                <div class="row py-2 fnl-am">
                                                    <div class="col-md-4 col-lg-3 col-5 pe-0">
                                                        <img  alt="" class="img-fluid" src="../../assets/images/product/products08.png">
                                                    </div>
                                                    <div class="col-md-8 col-lg-9 col-7">
                                                        <h5 class="dark-color pt-0"> Symphony Sumo Household Cooler </h5>
                                                        <div><strong>Color:</strong> gray</div>
                                                        <div><strong>Unit Price:</strong> ₹2335</div>
                                                        <div><strong>Qty:</strong> 15</div>
            
                                                    </div>
                                                </div>
                                                <div class="row py-2 fnl-am">
                                                    <div class="col-md-4 col-lg-3 col-5 pe-0">
                                                        <img  alt="" class="img-fluid" src="../../assets/images/product/products08.png">
                                                    </div>
                                                    <div class="col-md-8 col-lg-9 col-7">
                                                        <h5 class="dark-color pt-0"> Symphony Sumo Household Cooler </h5>
                                                        <div><strong>Color:</strong> gray</div>
                                                        <div><strong>Unit Price:</strong> ₹2335</div>
                                                        <div><strong>Qty:</strong> 15</div>
            
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row fnl-am">
                                                <div class="col-12">
                                                    <p class="mx-auto p-4 mb-0">
                                                        NOTE: All sales are final for items that have been ordered with customization.
                                                    </p>
                                                </div>
                                              
                                            </div>
                                        </div> -->
                    </div>

                  </div>

                </div>
              </section>

            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="modal fade" id="acknow" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Acknowledgement</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"> &times; </span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mx-auto ack_doc">
              <img class="mb-3" src="../../assets/images/check-circle.gif" alt="">
              <h4 class="text-success"> Successfully Registered</h4>
              <h6>Name: Deepika Singh </h6>
              <h6 class="mb-5">User ID: DS1524311</h6>

              <a class="mb-4 btn btn-sm btn-theme px-5" href="javascript:void(0)">Login</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="orderPlaced" tabindex="-1" aria-labelledby="orderPlacedLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="orderPlacedLabel">Order Placed Successfully</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="continueShopping()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body py-4">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <h4>Your order has been received.</h4>
              <img src="../../assets/images/check-circle.gif" class="img-fluid" width="100">
            </div>
            <div class="mt-3 text-center px-4">
              <h6>
                <strong>Order No: </strong>{{orderNo}}</h6>
              <!-- <h6><strong>Item Name:</strong> Vaio E15 AMD Ryzen 7-3700U/8GB DDR4 RAM/512GB SSD Oreginal...</h6>
                      <h6><strong>Quantity:</strong> 22</h6> -->
              <h6>
                <strong>Total Price For {{cart_items.length}} {{cart_items.length==1?'item':'items'}} : </strong>₹{{totalPrice}}</h6>
            </div>
            <div class="text-center mt-3">
              <button type="button" class="btn text-dark py-2 btn-theme2 px-4 rounded-pill" (click)="continueShopping()">Continue Shopping</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="remove-item" tabindex="-1" aria-labelledby=" remove-item" aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered my-0">
    <div class="modal-content">
      <div class="modal-header ps-icon py-2">
        <h6 class="pt-1 mb-1">Delete Address</h6>
        <button type="button" class="close" (click)="this.itmid=null;" data-dismiss="modal" aria-label="Close">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body px-3 py-4">
        <p class="font9 text-center"> Are you sure you want to delete this address? </p>
        <div class="text-center py-2">
          <button type="button" class="btn py-2 btn-secondary px-3 px-lg-4 rounded-pill btn-sm me-2" (click)="this.itmid=null;" data-dismiss="modal">
            Cancel</button>
          <button type="button" class="btn py-2 btn-theme2 px-lg-4 px-3 rounded-pill btn-sm" data-dismiss="modal" (click)="DeleteAddres1(itmid)">Delete</button>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="modal fade" id="payment" tabindex="-1" aria-labelledby="paymentLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body py-4">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <h4>Your Payment has been received.</h4>
              <img src="../../assets/images/check-circle.gif" class="img-fluid" width="100">
            </div>
            <!-- <app-paymentgetway ></app-paymentgetway> -->
            <div class="text-center mt-3">
              <button type="button" class="btn text-dark py-2 btn-theme px-4 rounded-pill" (click)="continueShopping()">Continue Shopping</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="payment-failed" tabindex="-1" aria-labelledby="payment-failed" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered my-0">
    <div class="modal-content">
      <div class="modal-header ps-icon py-2">
        <h6 class="pt-1 mb-1">Payment Cancelled.</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body px-3 py-4 text-center">
        <h4>Payment Cancelled</h4>
        <img src="../../../assets/images/failed.png" class="img-fluid" width="75">

        <div class="mt-3 text-center px-4">
          <h6>It seems we have not received money.</h6>
        </div>
        <button type="button" class="btn text-dark py-2 btn-theme px-4 rounded-pill mt-2" (click)="buyRazorPay()">Try Again</button>
      </div>
    </div>
  </div>
</div>