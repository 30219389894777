<div class="d-flex minhyt96vh" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid  bg-white">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;">Order Master</a>
                            </li>
                            <li class="breadcrumb-item newitm2  active" aria-current="page">Create Gift Code</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-sm-12">

                    <div class="row mx-0 my-3">
                        <div class="col-sm-12 p-0">
                            <div class="bg-lyt ">
                                <div class="d-flex justify-content-between align-items-center py-3 px-2">
                                    <h6 class="mb-0 font-weight-bold">
                                        Add new gift code
                                    </h6>
                                    <button type="button" class="btn btn-sm px-3 py-1 rounded-pill btn-theme">
                                        <i class="fa fa-plus-circle mr-1"></i> Upload Codes in Bulk</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-md-12">
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Assign gift code
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="form-check pl-0">
                                        <input class="custom1-chkboxradio" type="checkbox" id="market_price" value="market_price" checked>
                                        <label class="mx-2" for="market_price">Market Place</label>
                                    </div>
                                    <div class="form-check pl-0">
                                        <input class="custom1-chkboxradio" type="checkbox" id="corporate" value="corporate" checked>
                                        <label class="mx-2" for="corporate">Corporate</label>
                                    </div>
                                    <!-- <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="inlineCheckbox1">
                                        <label class="form-check-label" for="inlineCheckbox1">Market Place</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="inlineCheckbox2">
                                        <label class="form-check-label" for="inlineCheckbox2">Corporate</label>
                                    </div> -->
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Select Corporate
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-9 col-lg-10">
                                    <div class="card shadow-none ">
                                        <div class=" corporate_category ">
                                            <ng-select class=""  [multiple]="true"  placeholder="Select Product Category">
                                                <ng-option >  Juzby </ng-option>
                                                <ng-option >  JCB   </ng-option>
                                                <ng-option >  NEW India Limited </ng-option>
                                                <ng-option >  ABC New </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Gift Code
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3">
                                    <input type="text" class="form-control" value="QKIU-09JH-IJ07-XX89">
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <button type="button" class="btn btn-sm px-3 py-1 rounded-pill-theme rounded-pill text-dark my-2 my-md-0">Generate Gift Code</button>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-check pl-0">
                                        <label class="mx-2" for="points">Convertible to Points <span class="asterisk">*</span> </label>
                                        <input class="custom1-chkboxradio" type="checkbox" id="points" value="points" checked>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check pl-0">
                                        <label class="mx-2" for="afs">Allow free shipping <span class="asterisk">*</span></label>
                                        <input class="custom1-chkboxradio" type="checkbox" id="afs" value="afs" checked>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Code Type
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3">
                                    <select class="form-control">
                                        <option selected disabled>Select code type</option>
                                        <option>Fixed cart discount</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-lg-1 pr-lg-0">
                                    <label for="">Code Amount
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="input-group">
                                        <input type="text" value="10" class="form-control  text-right">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text py-0 px-1">₹</div>
                                          </div>
                                      </div>
                                    <!-- <input type="text" class="form-control" value="12000"> -->
                                </div>
                                <div class="col-md-3 col-lg-1 mt-md-2 mt-lg-0">
                                    <label for="">Expiry Date
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2 mt-md-2 mt-lg-0">
                                    <input type="date" class="form-control" value="YYYY-MM-DD">
                                </div>
                            </div>

                            <h6 class="font-weight-bold">Usage Restriction</h6>
                            <hr class="mb-3">
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Minimum Spend
                                    </label>
                                </div>
                                <div class="col-md-3">
                                    <input type="text" class="form-control" value="">
                                </div>
                                <div class="col-md-3 col-lg-1 pr-md-0">
                                    <label for="">Maximum Spend
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <input type="text" class="form-control" value="">
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check pl-0">
                                        <label class="mx-2" for="ind_use_only">Individual use only</label>
                                        <input class="custom1-chkboxradio" type="checkbox" id="ind_use_only" value="ind_use_only" checked>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check pl-0">
                                        <label class="mx-2" for="exc_sale_items">Exclude sale items</label>
                                        <input class="custom1-chkboxradio" type="checkbox" id="exc_sale_items" value="exc_sale_items" checked>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Products
                                    </label>
                                </div>
                                <div class="col-md-9 col-lg-10">
                                    <div class="card shadow-none ">
                                        <div class=" corporate_category ">
                                            <ng-select class=""  [multiple]="true"  placeholder="Select Products">
                                                <ng-option >  T-Shirt </ng-option>
                                                <ng-option >  JCB   </ng-option>
                                                <ng-option >  iPhones </ng-option>
                                                <ng-option >  Bottels </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Exclude Products
                                    </label>
                                </div>
                                <div class="col-md-9 col-lg-10">
                                    <div class="card shadow-none">
                                        <div class=" corporate_category ">
                                            <ng-select class=""  [multiple]="true"  placeholder="Select Exclude Products">
                                                <ng-option >  T-Shirt </ng-option>
                                                <ng-option >  JCB   </ng-option>
                                                <ng-option >  iPhones </ng-option>
                                                <ng-option >  Bottels </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Categories
                                    </label>
                                </div>
                                <div class="col-md-9 col-lg-10">
                                    <div class="card shadow-none">
                                        <div class=" corporate_category ">
                                            <ng-select class=""  [multiple]="true"  placeholder="Select Categories">
                                                <ng-option >  Books </ng-option>
                                                <ng-option >  Electronics Item   </ng-option>
                                                <ng-option > Clothes    </ng-option>
                                                <ng-option >  Cards </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Exclude Categories
                                    </label>
                                </div>
                                <div class="col-md-9 col-lg-10">
                                    <div class="card shadow-none">
                                        <div class=" corporate_category ">
                                            <ng-select class=""  [multiple]="true"  placeholder="Select Exclude Categories">
                                                <ng-option >  Books </ng-option>
                                                <ng-option >  Electronics Item   </ng-option>
                                                <ng-option > Clothes    </ng-option>
                                                <ng-option >  Cards </ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Usage Limit Per Code
                                    </label>
                                </div>
                                <div class="col-md-3">
                                    <select class="form-control">
                                        <option selected disabled>Unlimited Usage</option>
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-lg-auto">
                                    <label for="">
                                        Usage Limit Per User
                                    </label>
                                </div>
                                <div class="col-md-3">
                                    <select class="form-control">
                                        <option selected disabled>Unlimited Usage</option>
                                        <option>Unlimited Usage</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-sm-12 text-right my-2">
                                <button class="btn btn-sm px-lg-4 px-3 py-1 rounded-pill btn-theme shadow">Publish</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>