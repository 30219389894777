
<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>

        <!-- <app-header > </app-header> -->
        <div class="container-fluid bckgry pb-0">
            <div class="col-lg-12 p-0">
                <div class="row d-flex ">
                    <div class="col-lg-2  pt-5 register_left d-none d-lg-block vh-95">
                        <div class="register_left1"> 
                        <div class="px-3">
                            <h2 class="text-white"><strong>Registration</strong> Form</h2>
                            <p class="mt-2 mb-3 text-white">This Page is only for <strong> Organization/ Public Sector Unit/ Private Sector/ Govt.</strong></p>  
                        </div>
                        <img class="img-fluid pt-5" alt="main_register" src="../../assets/images/submitted.svg"/>    
                      </div>  
                    </div>
                    <div class="col-lg-10 col-sm-12 d-flex align-item-center">

                        <div class="funds-success-message-container card mt-4 py-4">
                            <div class="funds-checkmark-text-container">
                                <div class="funds-checkmark-container">
                                    <img class="img_w" src="../../assets/images/icon/checksubmit.gif" alt="">
                                   
                                </div>


                            </div>

                            <div class="success-message text-center">
                                <h4 class="text-center pb-3 font-weight-bold mb-0">Thank You</h4>
                                <p >Registration request submitted for approval, once approved by NCRB you will get notified.</p>
                                <div class="row">
                                   <div class="col-sm-12">
                                       <table class="table table-bordered">
                                        <tr>
                                            <td class="lightbg"><label class="font-weight-bold">Reference No.</label></td>
                                            <td><label>{{refNo}}</label></td>
                                        </tr>
                                           <tr>
                                               <td class="lightbg"><label class="font-weight-bold">Company Name</label></td>
                                               <td><label>{{companyName}}</label></td>
                                           </tr>
                                           <tr>
                                            <td class="lightbg"><label class="font-weight-bold">Company PAN</label></td>
                                            <td><label>{{companyPan}}</label></td>
                                        </tr>
                                        <tr>
                                            <td class="lightbg"><label class="font-weight-bold">Company GSTN</label></td>
                                            <td><label>{{companyGstn}}</label></td>
                                        </tr>
                                        <tr>
                                            <td class="lightbg"><label class="font-weight-bold">Company CIN</label></td>
                                            <td><label>{{companyCin}}</label></td>
                                        </tr>
                                       
                                       </table>
                                   </div>
                                   <div class="col-sm-12">
                                       <a href="javascript:void(0)" class="btn btn-dark btn-sm rounded-pill px-3" (click)='gotologin()'>Close</a>
                                   </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>