
<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
<div class="container-fluid">
    <div class="col-sm-12 p-0" *ngIf="FormViewLoaded | async">
      <label  class="font9 successedit2 alert alert-success pt-0 pb-0" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
		  <label class="font9 erroredit2 alert alert-warning pt-0 pb-0" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
    <ul class="nav nav-tabs">
      <li class="active"><a data-toggle="tab" class="active font9" href="#tab{{formId}}">{{formName}}</a>
      </li>
      <li *ngFor="let lst of childFormListTab"><a  data-toggle="tab"  class="font9" href="#tab{{lst.form_id}}">{{lst.form_name}}</a>
      </li>
      <li *ngIf="is_attachment == true" ><a  class="font9" [class]="isAddMode?'a-tabs':''" data-toggle="tab" href="#tabAttachment">Attachments</a> 
      </li>
    </ul>   
    <div class="tab-content tab-contents p-0 mt-2">
      <div id="tab{{formId}}" class="tab-pane fade in active show">
        <hdsbpm-request-form class="formEditHds" [section]="SectionConfig" [SessionUrlPrams]="sessionUrlPrams" [tableName]="tableName" [tablePrimaryKey]="tablePrimaryKey"  [rowid]="refKey" [childFormList]="childFormListPage" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode" [stageid]="stageid" [processid]="processid"  [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)"  (submit)="submit($event)" [list]="list" ></hdsbpm-request-form>
      </div>
      <ng-container >
        <div *ngFor="let lst of childFormListTab; index as i" id="tab{{lst.form_id}}" class="tab-pane fade" >
          <hdsbpm-grid-form [lstsection]="lst" [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form>
        </div>
        <div class="tab-pane fade" id="tabAttachment">
          <app-file-attachment id="add" [rowid]="refKey" [worklistid]="worklistid" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
        </div>
      </ng-container>
      
    </div>
  </div>
  <label  class="font9 successedit3 alert alert-success pt-0 pb-0" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
		  <label class="font9 erroredit3 alert alert-warning pt-0 pb-0" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
 
  <ngx-loading [show]="!processing"></ngx-loading>
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>

</div></div>             