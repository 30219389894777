import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation,OnDestroy ,EventEmitter,Output} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Subscription,Observable } from 'rxjs';
import { FormService } from '../../_services/form.service';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
//import { Editor } from 'ngx-editor';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { SectionConfig, SessionUrlPrams } from '../../field.interface';
import { AppSettings } from '../../_services/AppSetting'
import {DialogLayoutDisplay,ConfirmBoxInitializer} from '@costlydeveloper/ngx-awesome-popup'; 
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DomSanitizer } from '@angular/platform-browser';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

declare var $: any;

import * as CryptoJS from 'crypto-js';
import{LoginService} from '../../_services/login.service'

@Component({
  selector: 'app-act-process-new',
  templateUrl: './act-process-new.component.html',
  styleUrls: ['./act-process-new.component.css']
})
export class ActProcessNewComponent implements OnInit,OnDestroy {
  editorConfig =
    {
      "editable": true,
      "height": "auto",
      "minHeight": "120",
      "width": "auto",
      "minWidth": "0",
      "translate": "yes",
      "enableToolbar": true,
      "showToolbar": true,
      "placeholder": "Enter text here...",

      "toolbar": [
        ["bold", "italic", "underline"]

      ]
    }
    // add process variables 
    actiontakenid:any;
    recipienttype:any="Internal";
    priority:any="1";
    filterConnection:any;
    dropdownVisible:number=1;
    actionList:any[]=[];
    nxtStageId:any;
    isview_field: Promise<boolean>;
    routing_type:any="rule";
    isEndorser:boolean=false;
    userList: any[]=[];
    //add new act-process variable
    subscription: Subscription;
    frameworkComponents: any;
    formId:any=0;
    processid:any;
    stageid:any;
    worklistid:any;
    processinstid:any;
    scheme_deptid:any;
    form_viewid:any=0;
    formName:any;
    sessionUrlPrams:SessionUrlPrams; //new added
    tableName:any;
    tablePrimaryKey : any
    SectionConfig:SectionConfig[]=[];  
    formDisplayJSON:any;
    nodeJson:any;
    connJson:any;
    public isLoading = true;
    FormViewLoaded:Promise<boolean>;
    DocumentViewLoaded:Promise<boolean>;
    filepath: any="";
    fileExt: any="";
    imagepath: any="";
    SuccessMasgLoaded: Promise<boolean>;
    ErrorMasgLoaded: Promise<boolean>;
    processing: Promise<boolean>;
    isAddMode:boolean=true;
    is_attachment: boolean=false;
    ErrorOccurred:String="Error occurred!!";
    SuccessMasg:any;
    editValueRow:any;
    editrowid:any;
    refKey:any=0;
    childFormList: any[]=[];
    childFormListPage: any[]=[];
    childFormListTab: any[]=[];
    fieldChildFormList: any[]=[]; //add new
    metaDataList: any[]=[];
    attachmentType: any[]=[];
    metadata: any;
    list: any;
    edit_option:number=1;
    add_edit: Promise<boolean>;
    //add new act-process variable
  
  isClicked: boolean = false;
  //editor: Editor;
  notsheetContent: any;
  notsheetContentOriginal: any;
  notesheetList: any[] = [];
  notesheetLoaded: Promise<boolean> = Promise.resolve(false);
  refrenceSrNo: any;
  paraNo: any = 1;
  userId: any;
  isLoader: boolean = false;
  notsheetid: any;
  isEdit: boolean = false;
  notsheetPageList: any = [];
  notsheetPageCount: any = 1;
  selectArray: any = [];
  notsheetPageListFinal: any = [];
  nextPreviousCount: any = 1;
  isNoting: boolean;
  refrenceType: number = 0;
  refrenceId: any = 0;
  modalNotesheetContent: any;
  isPrint: boolean = false;
  notesheet_sr_noPrint: any;
  notesheet_para_noPrint: any;
  noting_datePrint: any;
  notesheet_containtPrint: any;
  user_fnamePrint: any;
  role_namePrint: any;
  reference_namePrint: any;
  stickyNotsheetId: any;
  stickyNotingDate: any;
  stickyuser_fname: any;
  stickynotsheet: any;
  stickyrole_name: any;
  printDivName: string;
  isNotingIdChanged: boolean;
  selectedItems = [];
  selectedItemsRefrence = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings1: IDropdownSettings = {};
  dropdownSettings2: IDropdownSettings = {};
  notesheeSrParaList:any;
  notesheeImageList:any;
  dropdownList = [];
  printList: any[] = [];
  viewList: any[] = [];
  isFile: boolean;
  defaultDrop: boolean = true;
  refrenceNamee: any;
  isNextDisabeled: boolean;
  imageObject: any[]=[];
  imageBasePath:any=AppSettings.getImagepath;
  attachmentTypeCumFileName: any="";
  draft_requestid: any;
  draft_id: any;
  pdfData: any;
  refSrNo: any;
  refPara: any;
  viewStatus: boolean=false;
  isApproved: boolean = false;
  isReject : boolean = false;
  isApprovedRejectStatus: Boolean= false;
  parkedRemarks: any="";
  isParked: boolean=false;

  pdf_EstimatedAmount: any="";
  pdf_schemeTitle: any="";
  pdf_hoa: any="";
  pdf_ApproverName: any="";
  pdf_ApproverDesignation: any="";
  pdf_ApproverDeparment: any="";
  roleId: any;
  attachmentReferenceId : any;
  attachmentReferenceName: any;
  templateUrl:any;
  constructor(private router: Router, 
    private sanitizer: DomSanitizer,
    private loginService:LoginService,
    private route:ActivatedRoute,
    private formService: FormService, private datePipe: DatePipe,
    private toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.loadImage()
    this.route.queryParams.subscribe((res)=>{
 let data  = this.loginService.decreptedDataUrl(res)
 if(data.length>1){
  var str =data;
  var value=str.split("&");
  value.forEach(queryParm=>{
    if(queryParm.indexOf('frmid')>=0)
    {
      this.formId=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('editrowid')>=0)
    {
      this.editrowid=queryParm.split('=')[1];
      this.refKey=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('worklistid')>=0)
    {
      this.worklistid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('processinstid')>=0)
    {
      this.processinstid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('stageid')>=0)
    {
      this.stageid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('deptid')>=0)
    {
      this.scheme_deptid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('vid')>=0)
    {
      this.form_viewid=queryParm.split('=')[1];
    }
    if(queryParm.indexOf('isAddMode')>=0)
    {
      this.isAddMode=queryParm.split('=')[1]=='true'?true:false;
    }
    if(queryParm.indexOf('isView')>=0)
    {
      this.viewStatus=queryParm.split('=')[1]=='true'?true:false;
    }
    if(queryParm.indexOf('isParked')>=0)
    {
      this.isParked=true;
    }
    // console.log("is view==>",this.viewStatus)
  });
  this.sessionUrlPrams={};
  this.sessionUrlPrams={
    frmid:this.formId,
    vid:this.form_viewid,
    UserCode:"UserCode",
    stageid:this.stageid,
    UserName:localStorage.getItem("userFullName"),
    ProfileId:localStorage.getItem("profileId"),
    Location:localStorage.getItem("office"),
    office:localStorage.getItem("office"),
    department:localStorage.getItem("department"),
    processtype:"ext",
    UserId:localStorage.getItem("userId"),
    processCtrl:false,
    processinstid:this.processinstid,

  };
}

    })
    this.roleId=localStorage.getItem("role_id");    
    if(this.roleId == "1"){
      this.isApproved=true;
      this.isReject=true;       
    }else{
      this.isApproved=false;
      this.isReject=false;    
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'notesheetid',
      textField: 'srno_parano',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'id',
      textField: 'attachment_desc',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    // let uri=this.router.url.split("?");
  
    console.log("sessionUrlPrams==>",this.sessionUrlPrams);
    this.getFormDetails();
    this.gettingNotSheet();
    //this.editor = new Editor();
    $(".zoomminus").hide();
  }

  

  getFormDetails() {
    
    this.subscription = this.formService.getFormData("select form_id,form_name,table_name,primary_key,form_display_json,value_field,processid from app_forms where form_id=" + this.formId).subscribe(res => {
      let formList = res.rows;
      let objChildFormIds = "", objChildFormViewIds = "";
      if (formList.length > 0) {
        this.formName = formList[0]["form_name"];
        this.formId = formList[0]["form_id"];
        this.processid = formList[0]["processid"];
        this.tableName = formList[0]["table_name"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase();
        this.formDisplayJSON = formList[0]["form_display_json"] ? formList[0]["form_display_json"] : [];   
        if (this.formDisplayJSON.length > 0) {
          if (this.processid > 0) {
            this.nodeJson = JSON.parse(this.formDisplayJSON).nodeJson;
            this.connJson = JSON.parse(this.formDisplayJSON).connJson;
            //now getting initial stageid
            var intStageid = this.nodeJson.filter(d => d.type == 'initial')[0]["id"];
            this.SectionConfig = JSON.parse(this.formDisplayJSON).sectionJson;
            // console.log("this.SectionConfig==>", this.SectionConfig)
            // control field hide/show based on process stages...
            this.SectionConfig.forEach(arrField => {
              if (arrField.FieldConfig[0]?.visible_stage != undefined) {
                arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || field.visible_stage.toString() == "");
              }
            });
            this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || d.visible_stage.toString() == "")));
            //this.stageid=intStageid;
          } else {
            this.SectionConfig = JSON.parse(this.formDisplayJSON)
            this.SectionConfig.forEach(arrField => {
              if (arrField.FieldConfig[0]?.visible_stage != undefined) {
                //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
                arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || field.visible_stage.toString() == "");
              }
            });
            this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || d.visible_stage.toString() == "")));
          }
          this.SectionConfig = this.SectionConfig.filter(sec => (sec.FieldConfig?.length > 0 || sec.section_dependent == true) && (sec?.isCaptionVisiable == "always" || sec?.isprocess == "1"));
          //to get child form id
          console.log("nodeJson==connJson called",this.nodeJson,this.connJson);
          this.fieldChildFormList = [];
          this.SectionConfig.forEach(objSec => {
            objSec.FieldConfig.forEach(objField => {
              if (objField.type == "linkwithchild") {
                objChildFormIds = objChildFormIds + objField.child_form_id + ",";
                //objChildFormViewIds=objChildFormViewIds+objField.child_view_id +",";
                this.fieldChildFormList.push(objField);
              }
            });
          });

        }
      }
      this.getFormViewDetails(objChildFormIds, objChildFormViewIds);
      this.getProcessAction();
    });
  }

  getFormViewDetails(childformids, childformviewid) {
    
    // console.log("field linkwithchild arrObjSect==>", childformids, childformviewid)
    this.subscription = this.formService.getFormViewById(this.form_viewid).subscribe(res => {
      
      let formViewList = res.rows;
      if (formViewList.length > 0) {
        //let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId = formViewList[0]["child_form_ids"] ? formViewList[0]["child_form_ids"] : "";
        this.edit_option = formViewList[0]["edit_option"];
        this.metaDataList = formViewList[0]["meta_data"] ? JSON.parse(formViewList[0]["meta_data"]) : [];
        
        this.attachmentType = formViewList[0]["attachment_type"] ? JSON.parse(formViewList[0]["attachment_type"]) : [];
        let validationList = formViewList[0]["validation"] ? JSON.parse(formViewList[0]["validation"]) : [];
        this.list = {
          "attachmentList": this.attachmentType,
          "validationList": validationList
        }
        // console.log("attachmentType==>",this.attachmentType);
        this.is_attachment = formViewList[0]["is_attachment"] ? formViewList[0]["is_attachment"] : false;
        let child_form_view_json = formViewList[0]["child_form_view_ids"] ? JSON.parse(formViewList[0]["child_form_view_ids"]) : [];

        if (child_form_view_json != undefined && child_form_view_json.length > 0) {
          child_form_view_json = child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId = "";
          for (let r = 0; r < child_form_view_json.length; r++) {
            childFormId = childFormId + child_form_view_json[r]["formid"] + ",";
          }
          childFormId = childFormId.substring(0, childFormId.length - 1)
        }
        // console.log("childformids==>", childFormId, child_form_view_json, childformids)
        if (childFormId == "") {
          childFormId = childformids.substring(0, childformids.length - 1);
        } else {
          childFormId = childformids + childFormId;
        }
        // console.log("childformids==>", childFormId, child_form_view_json, childformids)

        if (childFormId != "" && childFormId != undefined && childFormId != null) {
          this.subscription = this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
            this.childFormList = childRes.rows;
            //if(child_form_view_json.length>0){
            for (let a = 0; a < this.childFormList.length; a++) {
              let arrView = child_form_view_json.filter(d => d.formid == this.childFormList[a]["form_id"])
              if (arrView.length > 0) {
                this.childFormList[a]["viewid"] = arrView[0]["viewid"]
                this.childFormList[a]["display_order"] = arrView[0]["orderno"]
                this.childFormList[a]["filterjson"] = arrView[0]["filterjson"]
                this.childFormList[a]["showon"] = arrView[0]["showon"]
                this.childFormList[a]["type"] = "linkwithview"
                this.childFormList[a]["visible_stage"] = arrView[0]["visible_stage"] == undefined ? "" : arrView[0]["visible_stage"];
                this.childFormList[a]["editable_stage"] = arrView[0]["editable_stage"] == undefined ? "" : arrView[0]["editable_stage"];
              } else {
                let fieldArr = this.fieldChildFormList.filter(d => d.child_form_id == this.childFormList[a]["form_id"].toString());
                this.childFormList[a]["viewid"] = 0;
                this.childFormList[a]["display_order"] = 0;
                this.childFormList[a]["filterjson"] = "";
                this.childFormList[a]["showon"] = "page"
                this.childFormList[a]["type"] = "linkwithform"
                this.childFormList[a]["visible_stage"] = fieldArr[0]["visible_stage"];
                this.childFormList[a]["editable_stage"] = fieldArr[0]["editable_stage"];
              }
              // console.log(arrView)
            }
            //}
            this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
            // console.log("this.childFormList==>", this.childFormList, child_form_view_json)
            this.childFormListPage = this.childFormList.filter(d => d.showon === "page");
            this.childFormListTab = this.childFormList.filter(d => d.showon === "tab");
            if (this.isAddMode == false) {
              this.getRowEditData()
            } else {
              //console.log("this.SectionConfig==>",this.SectionConfig)  
              this.SectionConfig.forEach(arrField => {
                arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible == "always" || field?.visible == "add");
              });
              this.FormViewLoaded = Promise.resolve(true);
              this.processing = Promise.resolve(true);
            }
          });
        } else {
          if (this.isAddMode == false) {
            this.getRowEditData()
          } else {
            //console.log("this.SectionConfig==>",this.SectionConfig)  
            this.SectionConfig.forEach(arrField => {
              arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible == "always" || field?.visible == "add");
            });
            this.FormViewLoaded = Promise.resolve(true);
            this.processing = Promise.resolve(true);
          }
        }

      }

    });
  }

  getRowEditData() {
    this.add_edit = Promise.resolve(false);
    this.SectionConfig = null;
    if (this.formDisplayJSON.length > 0) {
      if (this.processid > 0) {
        this.nodeJson = JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson = JSON.parse(this.formDisplayJSON).connJson;
        //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
        //now getting initial stageid
        let intStageid;
        if (this.stageid == undefined) {
          intStageid = this.nodeJson.filter(d => d.type == 'initial')[0]["id"];
          this.stageid = intStageid
          this.sessionUrlPrams.stageid = intStageid;
        } else {
          intStageid = this.stageid;
        }
        // console.log("intStageid==>", this.stageid, intStageid)
        this.SectionConfig = JSON.parse(this.formDisplayJSON).sectionJson;
        // control field hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(intStageid) >= 0 || field.visible_stage.toString() == "");
        });
        // control Section hide/show based on process stages...
        this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || d.visible_stage.indexOf(intStageid) >= 0 || d.visible_stage.toString() == "")));
        //this.stageid=intStageid;
        //console.log("this.SectionConfig==>",intStageid, this.SectionConfig)           
      } else {
        this.SectionConfig = JSON.parse(this.formDisplayJSON)
      }
      //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible == "always" || field?.visible == "edit");
      });

      //console.log("this.SectionConfig==>",this.SectionConfig)  

      this.isAddMode = false;
      //this.editrowid=e.rowData[this.tablePrimaryKey];
      this.refKey = this.editrowid
      var strQry = 'select * from ' + this.tableName + ' where ' + this.tablePrimaryKey + '= ' + this.editrowid + ';';
      // console.log(strQry,"in View");
      this.subscription = this.formService.getFormData(strQry).subscribe(res => {
        // console.log("rowData==",res.rows[0]);
        this.editValueRow = JSON.parse(JSON.stringify(res.rows[0]));
        console.log("this.formAllField===>",this.editValueRow);
        this.FormViewLoaded = Promise.resolve(true);
        // this.processing = Promise.resolve(true);
        if (this.edit_option == 3) {
          if (this.childFormList.length > 0) {
            let formid = this.childFormList[0]["form_id"];
            let tabid = "#tabchildedit" + formid;
            setTimeout(() => { $(tabid).click(); }, 100);
          }
        }
      });
    }
  }

  getProcessAction(){
    // console.log("this.connJson==>",this.connJson,this.nodeJson)
    if(this.stageid ==undefined){
      this.stageid=this.nodeJson.filter(d =>d.type.toString().trim() == "initial")[0]["id"];
    }
    this.filterConnection = this.connJson.filter(d =>d.from.id == this.stageid);
    console.log("filterConnection==>",this.filterConnection)
    if(this.filterConnection.length>0){
      var actionIds="";
      for(let c=0; c<this.filterConnection.length; c++){
        //console.log("filterConnection[c][title]==>",this.filterConnection[c]["title"])
        actionIds=actionIds+this.filterConnection[c]["triggerTypeCommand"]+",";
        if(this.filterConnection[c]["title"] == "AU"){
          this.actionList.push({"id":this.filterConnection[c]["id"],"name":this.filterConnection[c]["to"]["title"]})
          this.nxtStageId=this.filterConnection[c]["to"]["id"];
          this.dropdownVisible=2;
          //this.nxtStageId= this.actiontakenid;
        }
      }
      actionIds=actionIds.substring(0,actionIds.length-1);
      if(this.actionList.length == 0){
        
        this.dropdownVisible=1;
        //this.actiontakenid=0;
        let qry="select optionid,command_name,trigger_type from radx_process_option where optionid in("+actionIds+")";            
        this.subscription=this.formService.getFormData(qry).subscribe(res=>{
          let actionlist=res.rows;
          for(var a=0; a<actionlist.length; a++){
            this.actionList.push({"id":actionlist[a]["optionid"],"name":actionlist[a]["command_name"],"triggertype":actionlist[a]["trigger_type"]})
            
          }

          // if(this.actionList.length>0){
          //   let approveList=this.actionList.filter(d=>d.id == 2);
          //   if(approveList.length>0){this.isApproved=true;}else{this.isApproved=false;}
          //   let rejectList=this.actionList.filter(d=>d.id == 3);
          //   if(rejectList.length>0){this.isReject=true;}else{this.isReject=false;}            
          // }    

        });
      }                 
    }
    this.getUserList();
    this.isview_field=Promise.resolve(true);
  }


  nextStageID(){
    // console.log("nextStageID method called actiontakenid==>",this.actiontakenid,this.dropdownVisible)
    if(this.actiontakenid>0 && this.dropdownVisible==1){
      var nextStagevalJson=this.filterConnection.filter(d =>d.triggerTypeCommand == this.actiontakenid);
      if(nextStagevalJson.length >0){
        var nextStageval= nextStagevalJson[0]["to"]["id"]

        this.nxtStageId=nextStageval;
        // console.log("nextStageID this.actionListId==>",nextStagevalJson,this.nxtStageId);
        this.routing_type=this.nodeJson.filter(d =>d.id==this.nxtStageId)[0]["routing_type"];
        if(this.routing_type=="actor")
        {
          this.getUserList();
        }
          
      }
      let trigger_type=this.actionList.filter(d=>d.id==this.actiontakenid);
      if((trigger_type.length>0 && (trigger_type[0]).triggertype==1) ||  nextStagevalJson[0]["to"]["is_Endorser"]==true)
      {
        this.isEndorser=true;
        this.recipienttype="External";
        //this.chkActionSkippLogic(true,"show","#div_textarea_indorsmentremarks");
      }else{
        this.isEndorser=false;
        this.recipienttype="Internal";
        //this.chkActionSkippLogic(false,"show","#div_textarea_indorsmentremarks");
      }
    }else{
      this.isEndorser=false;
      this.recipienttype="Internal";
      //this.chkActionSkippLogic(false,"show","#div_textarea_indorsmentremarks");
    }

    if(this.isApprovedRejectStatus==true){
      this.actOnProcess();
    }
    // console.log(" this.isEndorser,this.recipienttype==>", this.isEndorser,this.recipienttype,nextStagevalJson[0]["to"]["is_Endorser"])
  }
  getUserList(){ 
    if(this.nxtStageId >0){
      var nextStageJson=this.nodeJson.filter(d =>d.id == this.nxtStageId);
      var destQuery=nextStageJson[0].destination_query;
      var dest_varible=JSON.parse(nextStageJson[0].destination_json);
      console.log("destQuery,dest_varible==>",nextStageJson,destQuery,dest_varible,this.editValueRow);
      dest_varible.forEach(field =>{
        if(field.variable.includes("department_id")>0){
          destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.department);
        }
      });
      dest_varible.forEach(field =>{
        if(field.variable.includes("office_id")>0){
          destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.office);
        }
      });
      dest_varible.forEach(field =>{
        if(field.variable.includes("session.user_id")>0){
          destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.UserId);
        }
      });
      dest_varible.forEach(field =>{
         //console.log("variable Name==>",field.variable,"==>", this.group.get(field.field_binding).value);$('#endregion').value
         if(this.editValueRow[field.field_binding]==undefined && this.editValueRow[field.field_binding]==null){
          setTimeout(() =>this.getUserList(),500)
         }else{
          destQuery= destQuery.replace(field.variable,this.editValueRow[field.field_binding]);
         }
         
      });
      console.log("destQuery==>",destQuery);
      //let qry="select id, username from mst_tblusermaster"
      if(destQuery.toString().trim() !=""){
        this.subscription=this.formService.getFormData(destQuery).subscribe(res=>{
          // console.log("destQuery==>",destQuery,res.rows);
          this.userId="";
          this.userList=res.rows;         
         if(this.routing_type =="rule"){
            this.userList.forEach(item =>{
              if(this.userId ==""){
                this.userId=item.id
              }else{
                this.userId=this.userId+","+item.id
              }
            });
          }
        })
      }
    }
  }

  actOnProcess()
  {
    
    this.isLoader = true;
    //return new Observable<string>(observer => {
      //this.processSubmitted=false;
      //let nxtstageid=this.form.value["hidden_nxtstageid"]?this.form.value["hidden_nxtstageid"]:0;
      let nxtstageid=this.nxtStageId;
      //let actiontaken=this.form.value["actiontaken"];
      let actiontaken=this.actiontakenid
      // let notesheet_containt=this.form.value["textarea_remarks"];
      // let notesheet_endorsee=this.form.value["textarea_indorsmentremarks"]?this.form.value["textarea_indorsmentremarks"]:"";
      //let touserid=this.form.value["sendto"];
      let touserid=this.userId;
      var currStageJson=this.nodeJson.filter(d =>d.id ==this.stageid);
      let action_required_by=currStageJson[0]["action_required_by"]
      let checkNoteArr= this.notesheetList.filter(d=>d.notesheet_sr_no==this.refrenceSrNo);
      let dateDueon=$("#dateDueon").val();
      if(dateDueon == "" || dateDueon == undefined || dateDueon == null){
        dateDueon=undefined;
      }
      if( checkNoteArr.length>0)
      {
        if(action_required_by =="all")
        {
          //  update recipient type in noteshhet
          let processid, processinstanceid,notesheetSrNo;
          processid=checkNoteArr[0]["processid"];
          processinstanceid=checkNoteArr[0]["processinstanceid"];
          notesheetSrNo=checkNoteArr[0]["notesheet_sr_no"];
          let updatenotesheetQuery="update radx_process_note set recipient_type='"+this.recipienttype+"' where processid="+processid+" and processinstanceid="+processinstanceid+" and notesheet_sr_no="+notesheetSrNo+";"

          console.log("actOnprocess==>notesheet_containt,nxtstageid,actiontaken,touserid,action_required_by==>",nxtstageid,actiontaken,touserid,action_required_by)
          let checkallworklistitem="select * from radx_worklist where actiontaken='N' and processinstanceid="+this.processinstid+" and stageid="+this.stageid +";";
          let finalQry=updatenotesheetQuery+checkallworklistitem;

          this.formService.getFormData(finalQry).subscribe(res => {
            console.log("actOnprocess in Case of All Pending With Actors==>",res[1].rows.length,res[1].rows)
            if(res[1].rows.length >1){
              //let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,notesheet_endorsee,createdby_userid,processid,processinstanceid,stageid,action_taken)values(1,'"+notesheet_containt+"','"+notesheet_endorsee+"',"+parseInt(this.SessionUrlPrams.ProfileId)+","+this.processid+","+this.processinstid+","+nxtstageid+","+actiontaken+"); ";
              let worklistupdateQry="";
              if(this.isParked == true){
                worklistupdateQry=" update radx_worklist set updated_at=now() , parked_status=2, actiontaken='Y' where worklistid="+this.worklistid +"; "; 
              }else{
                worklistupdateQry=" update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +"; "; 
              }
              
              this.formService.getFormData(worklistupdateQry).subscribe(res => {
                if(res.status==500){  
                  this.toastrService.warning("Server/network Issue Please Contact to Administrator");       
                  //observer.next("Server/network Issue Please Contact to Administrator");
                  this.isLoader = false;
                }else{
                  if(this.actiontakenid == 2){
                    this.toastrService.success("Approved Sucessfully"); 
                  }else if(this.actiontakenid == 3){
                    this.toastrService.error("Rejected Sucessfully"); 
                  }else{
                    this.toastrService.success("Submitted Sucessfully"); 
                  }                  
                  if(this.isApprovedRejectStatus==true){this.updateApprovedRejectStatus();}   //added by prawej 07-july-2021
                  this.router.navigateByUrl('/inbox?'+localStorage.getItem('tempToken'));  
                  this.isLoader = false;
                }
              },err=>{
                this.toastrService.success("Error Occure while add into task list "+err.error.message);       
                //observer.next("Error Occure while add into task list "+err.error.message);
                this.isLoader = false;
              });
            }else{
              console.log("checking actor/rule driven==>",touserid);
              if(touserid==undefined || touserid==0){
                //rule driven
                console.log("rule driven==>",touserid);
                var nextStageJson=this.nodeJson.filter(d =>d.id ==nxtstageid);
                var destQuery=nextStageJson[0].destination_query;
                var dest_varible=JSON.parse(nextStageJson[0].destination_json);
                dest_varible.forEach(field =>{
                  if(field.variable.includes("department_id")>0){
                    destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.department);
                  }
                });
                dest_varible.forEach(field =>{
                  if(field.variable.includes("office_id")>0){
                    destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.office);
                  }
                })
                dest_varible.forEach(field =>{
                  if(field.variable.includes("session.user_id")>0){
                    destQuery= destQuery.replace(field.variable,this.sessionUrlPrams.UserId);
                  }
                })
                dest_varible.forEach(field =>{
                  destQuery= destQuery.replace(field.variable,this.editValueRow[field.field_binding]);
                });
                if(destQuery.toString().trim() !=""){
                  console.log("actOnrocess Rule Driven destQuery===>",destQuery);
                  this.subscription=this.formService.getFormData(destQuery).subscribe(res=>{
                    let userList=res.rows;
                    if(userList.length>0){
                      console.log("actOnProcess userList==>",userList)
                      let processinstanceid=this.processinstid;
                      let worklistupdateQry="";
                      if(this.isParked == true){
                        worklistupdateQry=" update radx_worklist set updated_at=now() , parked_status=2, actiontaken='Y' where worklistid="+this.worklistid +"; "; 
                      }else{
                        worklistupdateQry=" update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +"; "; 
                      }
                      
                      //let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,notesheet_endorsee,createdby_userid,processid,processinstanceid,stageid,action_taken)values(1,'"+notesheet_containt+"','"+notesheet_endorsee+"',"+parseInt(this.SessionUrlPrams.ProfileId)+","+this.processid+","+processinstanceid+","+nxtstageid+","+actiontaken+"); ";
                      //let finalQry=processNoteQry + worklistupdateQry;
                      let finalQry=worklistupdateQry;
                      userList.forEach(item =>{
                        let processWorklistQry=`insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken,priority_type,priority_date)values(${processinstanceid},${nxtstageid},${parseInt(this.sessionUrlPrams.ProfileId)},${item.id},'N',${this.priority},${dateDueon?("'"+dateDueon+"'"):null});`;
                          finalQry=finalQry+processWorklistQry
                      });
                      let processInstanceQry="update radx_processinstances set updated_at=now(), current_stageid="+nxtstageid+" ,with_whom_userid="+userList[0].id+" where processinstanceid="+this.processinstid +";";
                      finalQry=finalQry+processInstanceQry;
                      this.subscription=this.formService.getFormData(finalQry).subscribe(res => {
                        if(res.status==500){         
                          //observer.next("Server/network Issue Please Contact to Administrator");
                          this.toastrService.warning("Server/network Issue Please Contact to Administrator")
                          this.isLoader = false;
                        }else{
                          if(this.actiontakenid == 2){
                            this.toastrService.success("Approved Sucessfully"); 
                          }else if(this.actiontakenid == 3){
                            this.toastrService.error("Rejected Sucessfully"); 
                          }else{
                            this.toastrService.success("Submitted Sucessfully"); 
                          }  
                          if(this.isApprovedRejectStatus==true){this.updateApprovedRejectStatus();}   //added by prawej 07-july-2021 
                          this.router.navigateByUrl('/inbox?'+localStorage.getItem('tempToken'));  
                          this.isLoader = false;
                        }
                      },err=>{
                        this.toastrService.warning("Error Occure while add into task list "+err.error.message)
                        //observer.next("Error Occure while add into task list "+err.error.message);
                        this.isLoader = false;
                      });
                    }else{
                      this.toastrService.warning("No User Assigned");
                      //observer.next("No User Assigned");
                      this.isLoader = false;
                    }
                  });
                }else{
                  this.toastrService.warning("No Rule add in process stages");
                  //observer.next("No Rule add in process stages");
                  this.isLoader = false;
                }
              }else{
                //actor driven
                console.log("actor driven==>",touserid);
                let processInstanceQry="update radx_processinstances set updated_at=now(), current_stageid="+nxtstageid+" ,with_whom_userid="+touserid+" where processinstanceid="+this.processinstid +";";
                let worklistupdateQry="";
                if(this.isParked == true){
                  worklistupdateQry="update radx_worklist set updated_at=now() , parked_status=2, actiontaken='Y' where worklistid="+this.worklistid +";"; 
                }else{
                  worklistupdateQry="update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +";"; 
                }
                
                //let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,notesheet_endorsee,createdby_userid,processid,processinstanceid,stageid,action_taken)values(1,'"+notesheet_containt+"','"+notesheet_endorsee+"',"+parseInt(this.SessionUrlPrams.ProfileId)+","+this.processid+","+this.processinstid+","+nxtstageid+","+actiontaken+");";
                // let processWorklistQry="insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken)values("+this.processinstid+","+nxtstageid+","+parseInt(this.sessionUrlPrams.ProfileId)+","+touserid+",'N');";
                   let processWorklistQry=`insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken,priority_type,priority_date)values(${processinstanceid},${nxtstageid},${parseInt(this.sessionUrlPrams.ProfileId)},${touserid},'N',${this.priority},${dateDueon?("'"+dateDueon+"'"):null});`;
                //let finalQry=processNoteQry+processWorklistQry +processInstanceQry +worklistupdateQry
                let finalQry=processWorklistQry +processInstanceQry +worklistupdateQry
                console.log("actOnrocess actor driven finalQry===>",finalQry);
                this.formService.getFormData(finalQry).subscribe(res => {
                  if(res.status==500){  
                    this.toastrService.warning("Server/network Issue Please Contact to Administrator");
                    //observer.next("Server/network Issue Please Contact to Administrator");
                    this.isLoader = false;
                  }else{
                    if(this.actiontakenid == 2){
                      this.toastrService.success("Approved Sucessfully"); 
                    }else if(this.actiontakenid == 3){
                      this.toastrService.error("Rejected Sucessfully"); 
                    }else{
                      this.toastrService.success("Submitted Sucessfully"); 
                    }  
                    if(this.isApprovedRejectStatus==true){this.updateApprovedRejectStatus();}  //added by prawej 07-july-2021 
                    this.router.navigateByUrl('/inbox?'+localStorage.getItem('tempToken'));  
                    this.isLoader = false;
                  }
                },err=>{
                  this.toastrService.warning("Error Occure while add into task list "+err.error.message);
                  //observer.next("Error Occure while add into task list "+err.error.message);
                  this.isLoader = false;
                });
              }
            }
          });
        }else{
          //in case of any we need to write the code here...
          this.toastrService.warning("any case not handled");
          //observer.next("any case not handled");
          this.isLoader = false;
        }
      }else{
        this.toastrService.warning("Noteheet comment's is required");
        //observer.next("Remarks is required");
        this.isLoader = false;
      }
    //});
  }
  submit(value:any) {
    //console.log("pradeep value.isAddMode===>",JSON.stringify(value),value.isAddMode,value.SuccessMasgLoaded);
    let FormData=value.FormDate;
    this.isLoading=true;
    if(value.isAddMode==true){
      if(value.SuccessMasgLoaded=="true"){
        this.refKey=value.refKey;
        this.isAddMode=false;
        this.toastrService.success("Added Sucessfully",'',{timeOut: 3000,});        
      }else{
       this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")");        
      }
    }else{
      if(value.SuccessMasgLoaded =="true"){
        this.toastrService.success("Updated Sucessfully !!",'',{timeOut: 3000,});
      }else{        
        this.toastrService.error("Error occurred!! ("+value.ErrorMassage +")");
      }
    }
    setTimeout(() => {this.isLoading=false},800);
  }

  sticky(refPara,index,notesheetid,reference_type,sr,para) {
    this.refSrNo=sr;
    this.refPara=para;
    let arr=this.notesheetList.filter(d=>d.notesheetid == notesheetid)[0]["reference_id"];    
    if(reference_type == 1 || reference_type == 0 || reference_type == null){
      let filnalArr=arr.split(",");
      let finalNSI=filnalArr[index]    
      this.stickyNotsheetId = finalNSI
      var notsheet = this.notesheetList.filter(x => x.notesheetid == finalNSI);
      this.modalNotesheetContent = notsheet[0].notesheet_containt;
      this.stickyNotingDate = notsheet[0].noting_date;
      this.stickyuser_fname = notsheet[0].user_fname;
      this.stickyrole_name = notsheet[0].role_name;    
      setTimeout(() => {
        $(".toast").show();
        $(".toast").addClass("showing");
      }, 400);
    }
  }

  stickyAttachment(refPara,index,notesheetid,reference_type,sr,para) {
    this.refSrNo=sr;
    this.refPara=para;
    let arr=this.notesheetList.filter(d=>d.notesheetid == notesheetid)[0]["attachment_reference_id"];
      $("#viewformdetail").hide();
      $("#addattr").hide();
      $("#bascidetail").hide();
      $("#corress").hide();
      $("#addfile").hide();
      $("#addnote").hide();
      $("#sendto").hide();
      let filnalArr=arr.split(",");
      let currviewListId=filnalArr[index];
      this.showImage(currviewListId)      
      $("#fileViwer").show();
      $("#parkedto").hide();
      $("#divproposedapprove").hide();      
  }
  closetoast() {
    $(".toast").hide();
    $(".toast").removeClass("showing");
    $("#fileViwer").hide();
  }
  showupload() {
    $("#viewformdetail").hide();
    $("#addattr").show();
    $("#bascidetail").hide();
    $("#corress").hide();
    $("#addfile").hide();
    $("#addnote").hide();
    $("#sendto").hide();
    $("#fileViwer").hide();
    $("#parkedto").hide();
    $("#divproposedapprove").hide();
  }

  addcorres() {
    $("#addattr").hide();
    $("#bascidetail").hide();
    $("#corress").show();
    $("#addfile").hide();
    $("#addnote").hide();
    $("#fileViwer").hide();
    $("#parkedto").hide();
    $("#divproposedapprove").hide();
  }

  colappsin() {
    $(".zoomadd").hide();
    $(".zoomminus").show();
    $(".leftgreensheet").hide();
    $(".filerespnew").addClass("col-lg-12");
    $(".newfilebtn").show();
    $(".minimi").hide();
    $("#fileViwer").hide();
    // $("#parkedto").hide();
    // $("#divproposedapprove").hide();

  }
  colappsout() {
    $(".zoomadd").show();
    $(".zoomminus").hide();
    $(".leftgreensheet").show();
    $(".filerespnew").removeClass("col-lg-12");
    $(".newfilebtn").hide();
    $(".minimi").show();
    $("#fileViwer").hide();
    // $("#parkedto").hide();
    // $("#divproposedapprove").hide();
  }
  zoomcall() {
    $(".zoomadd").hide();
    $(".zoomminus").show();
    $(".leftgreensheet").hide();
    $(".filerespnew").addClass("col-lg-12");
    $(".newfilebtn").show();
    $(".minimi").hide();
    $("#fileViwer").hide();
    // $("#parkedto").hide();
    // $("#divproposedapprove").hide();

  }
  zoomcut() {
    $(".zoomadd").show();
    $(".zoomminus").hide();
    $(".leftgreensheet").show();
    $(".filerespnew").removeClass("col-lg-12");
    $(".newfilebtn").hide();
    $(".minimi").show();
    $("#fileViwer").hide();
    // $("#parkedto").hide();
    // $("#divproposedapprove").hide();
  }
  addnote() {
    // debugger;
    this.notsheetContent = "";
    $("#addattr").hide();
    $("#bascidetail").hide();
    $("#corress").hide();
    $("#addfile").hide();
    $("#addnote").show();
    $("#sendto").hide();
    $("#viewformdetail").hide()
    $("#fileViwer").hide();
    $("#parkedto").hide();
    $("#divproposedapprove").hide();
    //$(".hidebt").hide()

    // let lastNoteArray=this.notesheetList.filter(d=>d.notesheetid == this.notesheetList[this.notesheetList.length-1]["notesheetid"]);
    // var createdBy = this.sessionUrlPrams.UserId;
    // var notsheet = this.notesheetList.filter(x => x.createdby_userid == createdBy);
    // if (notsheet.length == 0) {
    //   this.refrenceSrNo = this.notesheetList.length + 1;
    //   this.paraNo = 1;
    // }
    // else {
    //   var notsheetFilter = this.notesheetList.filter(y => y.createdby_userid == createdBy)
    //   this.refrenceSrNo = (this.notesheetList.length - notsheetFilter.length) + 1;
    //   this.paraNo = notsheet.length + 1;      
    // }
    // if(lastNoteArray.length>0){
    //   let nArray=lastNoteArray.filter(d => d.createdby_userid == createdBy)
    //   if(nArray.length>0){
    //     this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]);
    //     this.paraNo = Number(lastNoteArray[0]["notesheet_para_no"])+1;
    //   }else{
    //     this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]) + 1;
    //     this.paraNo = 1;
    //   }
    // }
    //this.editor.commands.focus();
  }

  onClickCancel() {
    this.isEdit = false;
    $("#bascidetail").show();
    $("#addnote").hide();
    $("#fileViwer").hide();
    $("#parkedto").hide();
    $("#divproposedapprove").hide();
  }

  onEditClick(notesheetid) {
    this.notesheeSrParaList=[];
    this.notesheeImageList=[];
    this.isEdit = true;
    this.notsheetid = notesheetid
    var notsheet = this.notesheetList.filter(
      x => x.notesheetid == notesheetid);
    $("#addnote").show();
    $("#sendto").hide();
    $("#fileViwer").hide();
    $("#viewformdetail").hide();
    let remContent=(notsheet[0].notesheet_containt).toString();
    remContent=remContent.replace("SafeValue must use [property]=binding: ",'')
    remContent=remContent.replace("(see http://g.co/ng/security#xss)",'')
    this.notsheetContent = remContent;////notsheet[0].notesheet_containt;
    this.refrenceSrNo = notsheet[0].notesheet_sr_no;  
    $("#ddlReferenceType").val(notsheet[0].reference_type); 
    this.refrenceTypeChange(notsheet[0].reference_type?notsheet[0].reference_type:0)

    // console.log(JSON.stringify(notsheet[0]))

    if(notsheet[0].reference_id != null && notsheet[0].reference_id != undefined && notsheet[0].reference_id != "" &&
    notsheet[0].reference_name != null && notsheet[0].reference_name != undefined && notsheet[0].reference_name != "" &&
    notsheet[0].reference_name != "undefined"
  ){
    let refArray=(notsheet[0].reference_id).split(",");
    let refNameArray=(notsheet[0].reference_name).split(",")
    for(let c=0; c< refArray.length; c++){        
        this.notesheeSrParaList.push({
          "notesheetid":Number(refArray[c]),
          "srno_parano":refNameArray[c],
        })
    }
  }

  if(notsheet[0].attachment_reference_id != null && notsheet[0].attachment_reference_id != undefined && notsheet[0].attachment_reference_id != ""){
    let attachment_reference_id=(notsheet[0].attachment_reference_id).split(",");
    let attachment_reference_name=(notsheet[0].attachment_reference_name).split(",")
    for(let c=0; c< attachment_reference_id.length; c++){        
        this.notesheeImageList.push({
          "id":Number(attachment_reference_id[c]),
          "attachment_desc":attachment_reference_name[c],
        })
    }
  }
    
    
  }

  refrenceName() {
    var refrenceNote = this.notesheetList.filter(
      notsheet => notsheet.notesheetid == this.refrenceId);
    if (refrenceNote.length > 0) {
      if (refrenceNote[0].notesheet_para_no == null) {
        var refrenceName = refrenceNote[0].notesheet_sr_no + '.'
      } else {
        var refrenceName = refrenceNote[0].notesheet_sr_no + '.' + refrenceNote[0].notesheet_para_no;
      }

    } else {
      refrenceName = null;
    }
    return refrenceName
  }

  onEditSaveClick() {
    let ids="",refName=""; 
    this.refrenceId=null;
    this.refrenceNamee=null;

    for(let c=0; c<this.notesheeSrParaList.length; c++){
      ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
      refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
    }
    if(ids != ""){
      this.refrenceId= ids.substring(0,ids.length-1)
      this.refrenceNamee= refName.substring(0,refName.length-1)
    }  

    
    let att_ids="",att_refName=""; 
    let attachmentRefId,attachmentRefName;
    for(let c=0; c<this.notesheeImageList.length; c++){
      att_ids=att_ids+this.notesheeImageList[c]["id"]+",";
      att_refName=att_refName+this.notesheeImageList[c]["attachment_desc"]+","
    }
    if(ids != ""){
      attachmentRefId= att_ids.substring(0,att_ids.length-1)
      attachmentRefName= att_refName.substring(0,att_refName.length-1)
    } 
    
    let sqlQueryEdit = `UPDATE  radx_process_note SET notesheet_containt='${this.notsheetContent}',reference_type=${this.refrenceType}
      ,reference_id='${this.refrenceId}',reference_name='${this.refrenceNamee}'
      ,attachment_reference_id=${attachmentRefId?("'"+attachmentRefId+"'"):null},attachment_reference_name=${attachmentRefName?("'"+attachmentRefName+"'"):null} 
      where notesheetid=${this.notsheetid}`;
      
    this.formService.getFormData(sqlQueryEdit).subscribe(res => {
      this.gettingNotSheet();
      this.resetNotingControl();
      // window.location.reload();
      (<HTMLInputElement>document.getElementById("noteArea")).value = "";
      this.toastrService.success("Edited Sucessfully");
    })
  }

  onDeleteClick(notesheetid) {
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Are you sure?');
    confirmBox.setMessage('You want to delete this!');
    confirmBox.setButtonLabels('YES', 'NO');
    confirmBox.setConfig({
      LayoutType: DialogLayoutDisplay.DANGER // SUCCESS | INFO | NONE | DANGER | WARNING
    });
    const subscription = confirmBox.openConfirmBox$().subscribe(resp => {
      if(resp.Success==true){
        let sqlQueryDelete = `DELETE FROM radx_process_note where notesheetid=${notesheetid}`;
        this.formService.getFormData(sqlQueryDelete).subscribe(res => {
          this.gettingNotSheet();          
        })
    }
  });

  }
  sendreq() {
    $("#fileViwer").hide();
    $("#addattr").hide();
    // $("#bascidetail").hide();
    // $("#corress").hide();
    // $("#addfile").hide();
    $("#addnote").hide();
    $("#sendto").show();
    // $(".hidebt").hide()
    $("#parkedto").hide();
    $("#divproposedapprove").hide();
    $("#viewformdetail").hide();    
    this.isview_field = Promise.resolve(true);
  }
  viewdetailc() {
    $("#fileViwer").hide();
    $("#bascidetail").show();
  }


  onSaveNote() {   
    var noteSerialNo = (<HTMLInputElement>document.getElementById("noteSerialNo")).value;
    var createddate = new Date().toString();
    createddate = this.datePipe.transform(createddate, 'yyyy-MM-dd,h:mm a')
    
    var regex = /( |<([^>]+)>)/ig;
    let noteContent=  this.notsheetContent.replace(regex, "");
    if(noteContent == undefined || noteContent == null || noteContent == ''){
      this.toastrService.warning("Noting is required");
      this.notsheetContent="";
      return false;
    }else{      
      this.isLoader = true;
      let sqlQuerySave = `INSERT INTO radx_process_note(notesheet_sr_no, notesheet_containt,createddate,createdby_userid,processid,processinstanceid,stageid,notesheet_para_no,reference_type,reference_id,reference_name,department_id,attachment_reference_id,attachment_reference_name) VALUES (
        ${noteSerialNo},'${this.notsheetContent}','${createddate}',${this.sessionUrlPrams.ProfileId},${this.processid},${this.processinstid},${this.stageid},${this.paraNo},${this.refrenceType},'${this.refrenceId}','${this.refrenceNamee}',${this.sessionUrlPrams.department},${this.attachmentReferenceId?("'"+this.attachmentReferenceId+"'"):null},${this.attachmentReferenceName?("'"+this.attachmentReferenceName+"'"):null})`;
      // console.log("sqlQuerySave ==>",sqlQuerySave)
      this.formService.getFormData(sqlQuerySave).subscribe(res => {
        this.gettingNotSheet();
        this.resetNotingControl();
        this.isLoader = false;
        this.toastrService.success("Added Sucessfully");
      })
    }    
  }

  resetNotingControl(){
    this.isEdit=false;
    $("#ddlReferenceType").val(0);
    this.notsheetContent = "";
    this.notesheeSrParaList=[];    
    this.notesheeImageList=[];
    this.refrenceId='';
    this.attachmentReferenceId = '';
  }


  gettingNotSheet() {
    let sqlQuery = `select rpn.notesheetid,case when rpn.notesheet_endorsee  <> '' then rpn.notesheet_endorsee else rpn.notesheet_containt end as notesheet_containt ,TO_CHAR(rpn.createddate:: DATE, 'dd/mm/yyyy') as noting_date ,
    rpn.createdby_userid,rpn.notesheet_sr_no,rpn.notesheet_para_no,rpn.reference_type,rpn.reference_id,rpn.reference_name,concat(u.user_fname,' ',u.user_mname,' ',u.user_lname) as user_fname,
	  rpn.recipient_type,case rpn.recipient_type when 'External' then concat('ID/- (',d.department_name,')') else concat(des.designation_name,'(',d.department_name,')') end as role_name,
    up.department_id,rdr.draft_requestid, rdr.id as draft_id,rpn.processid,rpn.processinstanceid ,
    rpn.attachment_reference_id,rpn.attachment_reference_name
	  from radx_process_note rpn 
    left join app_tbluserprofile up on rpn.createdby_userid=up.userprofile_id
    inner join app_tbluser u on up.user_id=u.user_id
    inner join app_tblrole r on up.role_id=r.role_id
    inner join z_entitymast_department_001 d on up.department_id=d.department_id
    left join radx_processinstances rp on rp.processinstanceid = rpn.processinstanceid
	  left join z_entitymast_designation_001 des on up.designation_id=des.designation_id
	  left join radx_draft_requests rdr on rdr.process_id = rp.processid and rdr.item_id = rp.itemid
    where rpn.processinstanceid = ${this.processinstid} and (rpn.department_id=${this.scheme_deptid} or (rpn.department_id=${this.sessionUrlPrams.department} or rpn.recipient_type='External')) order by rpn.notesheetid desc 
        `;
        
    //console.log("gettingNotSheet==>",sqlQuery)  
    this.formService.getFormData(sqlQuery).subscribe(res => {      
      this.notesheetList = [];
      this.dropdownList=[];
      this.notesheetList = res.rows;
      this.notesheetList = this.notesheetList.reverse();
      if(this.notesheetList.length>0){
        this.draft_requestid=this.notesheetList[0]["draft_requestid"]?this.notesheetList[0]["draft_requestid"]:'';
        this.draft_id=this.notesheetList[0]["draft_id"]?this.notesheetList[0]["draft_id"]:0;
        this.getSanctionOrderDetail();// for sanction order pdf details
      }
      // console.log(JSON.stringify(this.notesheetList))
      // setTimeout(() => {
        this.notesheetList.forEach(element => {
          if (element.notesheet_para_no) {
            this.dropdownList.push({
              item_id: element.notesheetid,
              item_text: element.notesheet_sr_no + '.' + element.notesheet_para_no,
            })
          } else {
            this.dropdownList.push({
              item_id: element.notesheetid,
              item_text: element.notesheet_sr_no,
            })
          }
        })

      // }, 500);
      for(let r=0; r<this.notesheetList.length; r++){
        if(this.notesheetList[r]["reference_name"] != null && this.notesheetList[r]["reference_name"] != undefined && this.notesheetList[r]["reference_name"] !="" && this.notesheetList[r]["reference_name"] !="0"){
          this.notesheetList[r]["ref_array"]=this.notesheetList[r]["reference_name"].split(",")
        }else{
          this.notesheetList[r]["ref_array"]=[];
        } 

        if(this.notesheetList[r]["attachment_reference_name"] != null && this.notesheetList[r]["attachment_reference_name"] != undefined && this.notesheetList[r]["attachment_reference_name"] !="" && this.notesheetList[r]["attachment_reference_name"] !="0"){
          this.notesheetList[r]["att_ref_array"]=this.notesheetList[r]["attachment_reference_name"].split(",")
        }else{
          this.notesheetList[r]["att_ref_array"]=[];
        }

        if(r >0 && this.notesheetList[r]["notesheet_sr_no"] ==this.notesheetList[r-1]["notesheet_sr_no"] ){
          this.notesheetList[r-1]["user_fname"]="";
          this.notesheetList[r-1]["role_name"]="";
        }  
        this.notesheetList[r]["notesheet_containt"]=this.sanitizer.bypassSecurityTrustHtml(this.notesheetList[r]["notesheet_containt"]);     
      }
      
      // Set Note No and Paragraph Number 
      let lastNoteArray=this.notesheetList.filter(d=>d.notesheetid == this.notesheetList[this.notesheetList.length-1]["notesheetid"]);
      // var createdBy = this.sessionUrlPrams.p;  
      if(lastNoteArray.length>0){
            let nArray=lastNoteArray.filter(d => d.createdby_userid == this.sessionUrlPrams.ProfileId)
            if(nArray.length>0){
              this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]);
              this.paraNo = Number(lastNoteArray[0]["notesheet_para_no"])+1;
            }else{
              this.refrenceSrNo = Number(lastNoteArray[0]["notesheet_sr_no"]) + 1;
              this.paraNo = 1;
            }
          }
      
        // console.log("dropdownList", this.dropdownList)
        this.notesheetLoaded = Promise.resolve(true);

        // setTimeout(() => {
          this.pageCount();
        // }, 500);
      
    })
  }

  pageCount() {    
    this.notsheetPageList = [];
    var notsheetCheck: any = [];
    this.notsheetPageCount=1;
    this.selectArray = [];
    for (let i = 0; i < this.notesheetList.length; i++) {
      
      this.notesheetList[i].notesheet_containt;
      notsheetCheck = notsheetCheck + this.notesheetList[i].notesheet_containt;
      let refName="";
      if(this.notesheetList[i].reference_name == "undefined" || this.notesheetList[i].reference_name == "null"){
        refName=null;
      }else{
        refName=this.notesheetList[i].reference_name;
      }
      this.notsheetPageList.push({
        ['createdby_userid']: this.notesheetList[i].createdby_userid,
        ['department_id']: this.notesheetList[i].department_id,
        ['notesheet_containt']: this.notesheetList[i].notesheet_containt,
        ['notesheet_para_no']: this.notesheetList[i].notesheet_para_no?this.notesheetList[i].notesheet_para_no:"",
        ['notesheet_sr_no']: this.notesheetList[i].notesheet_sr_no,
        ['notesheetid']: this.notesheetList[i].notesheetid,
        ['noting_date']: this.notesheetList[i].noting_date,
        ['role_name']: this.notesheetList[i].role_name,
        ['user_fname']: this.notesheetList[i].user_fname,
        ['page_no']: this.notsheetPageCount,
        ['reference_id']: this.notesheetList[i].reference_id?this.notesheetList[i].reference_id:"",
        ['reference_type']: this.notesheetList[i].reference_type?this.notesheetList[i].reference_type:"",
        ['reference_name']: refName?refName:"",
        ['srno_parano']:(this.notesheetList[i].notesheet_sr_no)+"."+(this.notesheetList[i].notesheet_para_no?this.notesheetList[i].notesheet_para_no:""),
        ['ref_array']: this.notesheetList[i].ref_array,
        ['attachment_reference_id']: this.notesheetList[i].attachment_reference_id,
        ['attachment_reference_name']: this.notesheetList[i].attachment_reference_name,
        ['att_ref_array']: this.notesheetList[i].att_ref_array
      })
      
      if (notsheetCheck.length > 1000) {
        this.notsheetPageCount++;
        notsheetCheck = [];
        continue;
      }
    }
    for (let j = 1; j <= this.notsheetPageCount; j++) {
      this.selectArray.push({
        ['page_no']: j,
      })
    }
    
 
    this.nextPreviousCount=Number(this.selectArray.length);
    this.pageskip(this.selectArray.length);
    }

  pageskip(pageNo) {    
    // if(this.notsheetPageCount==pageNo){
    //   $("#linkNext").removeClass("btn-warning").addClass("btn btn-sm btn-light");
    //   $("#linkPrevious").removeClass(" btn-white").addClass("btn-warning");
    
    // }
    // if(pageNo==1){
    //   $("#linkNext").removeClass(" btn-light").addClass("btn-warning");
    //   $("#linkPrevious").removeClass(" btn-warning").addClass(" btn-white ");
    // }
    this.notsheetPageListFinal = this.notsheetPageList.filter(notsheet => notsheet.page_no == pageNo);
   
  }

  pagePlus() {
    
    if (this.nextPreviousCount < this.notsheetPageCount) {
      this.nextPreviousCount++;
      this.pageskip(this.nextPreviousCount);
    }

    if(this.nextPreviousCount == this.notsheetPageCount){
      // $("#linkNext").removeClass("btn-warning").addClass("btn btn-sm btn-light");
      // $("#linkPrevious").removeClass("btn-white").addClass("btn-warning");
    }

  }

  pageMinus() {
    if(this.nextPreviousCount == 2){
      // $("#linkNext").removeClass(" btn-light").addClass("btn-warning");
      // $("#linkPrevious").removeClass(" btn-warning").addClass(" btn-white ");
    }
    if (this.nextPreviousCount > 1) {
      this.nextPreviousCount--;
      this.pageskip(this.nextPreviousCount);
    }

  }

  viewformdetail() {
    $("#fileViwer").hide();
    $("#viewformdetail").show();
    $("#addattr").hide();
    $("#bascidetail").hide();
    $("#viewdoc").hide();
    $("#addfile").hide();
    $("#addnote").hide();
    $("#sendto").hide();
    $("#parkedto").hide();
    $("#divproposedapprove").hide();

  }
  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  printAll(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  refrenceTypeChange(value) {
    if (value == 1) {
      this.isNoting = true;
      this.isFile = false;
      this.defaultDrop = false;
      this.refrenceType = 1
    } else if (value == 2) {
      this.isFile = true;
      this.defaultDrop = false;
      this.isNoting = false;
      this.refrenceType = 2
    }
  }

  notingSelected(e) { 
    // console.log(JSON.stringify(this.notesheeSrParaList))
    let ids="",refName="";    
    for(let c=0; c<this.notesheeSrParaList.length; c++){
      ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
      refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
    }
    if(ids != ""){
      this.refrenceId= ids.substring(0,ids.length-1)
      this.refrenceNamee= refName.substring(0,refName.length-1)
    }    
  }
  OnSrParaDeSelect(e){
    let ids="",refName="";    
    for(let c=0; c<this.notesheeSrParaList.length; c++){
      ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
      refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
    }
    if(ids != ""){
      this.refrenceId= ids.substring(0,ids.length-1)
      this.refrenceNamee= refName.substring(0,refName.length-1)
    }    
  }
  onSelectAllSrPara(e){
    let ids="",refName="";    
    for(let c=0; c<this.notesheeSrParaList.length; c++){
      ids=ids+this.notesheeSrParaList[c]["notesheetid"]+","
      refName=refName+this.notesheeSrParaList[c]["srno_parano"]+","
    }
    if(ids != ""){
      this.refrenceId= ids.substring(0,ids.length-1)
      this.refrenceNamee= refName.substring(0,refName.length-1)
    } 
  }

  attachmentSelected(e){
    let ids="",refName="";
    for(let c=0; c<this.notesheeImageList.length; c++){
      ids=ids+this.notesheeImageList[c]["id"]+",";
      refName=refName+this.notesheeImageList[c]["attachment_desc"]+","
    }
    if(ids != ""){     
      this.attachmentReferenceId= ids.substring(0,ids.length-1)
      this.attachmentReferenceName= refName.substring(0,refName.length-1)     
    }else{
      this.attachmentReferenceId= null;
      this.attachmentReferenceName= null;    
    }
  }

  onClickPrint() {
    var notsheet = []
    this.selectedItems.forEach((y: { item_id: any }) => {
      const noting = this.notesheetList.filter(
        (x) => x.notesheetid == y.item_id
      );
      if (noting.length > 0) notsheet.push(noting[0]);
    });
    this.printList = notsheet

    setTimeout(() => {
      this.printAll('printArea');
    }, 300);

  }

  viewListEvent(viewlist) {
    this.viewList = viewlist    
  }

  isViwer(event) {   
    this.deletePdfTempPath() // for delete previous temp file; 
    this.DocumentViewLoaded = Promise.resolve(false);
    this.hideFileAttachmnet();
    this.showImage(event)
  }

  deletePdfTempPath(){
    let delPdfPath=localStorage.getItem("del_temp_pdf_path");
    delPdfPath=delPdfPath?delPdfPath:"";
    localStorage.setItem("del_temp_pdf_path","");
    if(delPdfPath != undefined && delPdfPath != null && delPdfPath != ""){
      this.formService.deletePdfTempPath(delPdfPath).subscribe(res=>{
        let msg=res["msg"]?res["msg"]:"";
        console.log(msg)
      })
    }
  }

  showImage(id){
  this.imageObject=[]; 
    let currentViewList=this.viewList.filter(d=>d.id == id)
    if(currentViewList.length>0){
      this.attachmentTypeCumFileName=currentViewList[0]["attachment_desc"];
      let arr=currentViewList[0]["file_name"].split(".");
      this.fileExt=arr[arr.length-1];
      this.filepath=currentViewList[0]["file_path"]+"/"+currentViewList[0]["file_name"];
      this.imagepath=this.imageBasePath+currentViewList[0]["file_path"]+"/"+currentViewList[0]["file_name"];
      this.DocumentViewLoaded = Promise.resolve(true);      
    }
  }

  hideFileAttachmnet() {
    $("#viewformdetail").hide();
    $("#addattr").hide();
    $("#fileViwer").show();
    this.showImage($("#ddlDocumentView").val())
    $("#divproposedapprove").hide();
  }

  printAllClick(){
    var printContents = document.getElementById('printAreaAll').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  onItemSelect(e){}
  OnItemDeSelect(e){}
  onSelectAll(e){}
  
  generatesantionOrder(){
    var date= new Date();
    var tm= (new Date().toLocaleString()).split(",")[1]
    var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    var yyyy = ('' + date.getFullYear());        
    var finaldt= (dd+"-"+MM+"-"+yyyy+" "+tm);
    this.pdfData={
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 40, 40, 40, 40 ],
      content: [
      {text: `GOVERMENT OF MEGHALAYA \n `+localStorage.getItem("department_name"),alignment: 'center',bold: true, fontSize:12},
      { table: {
        widths: ['*','*'],                      
        body:[[{text: `\nFile No: `+this.draft_requestid,alignment: 'left',bold: true, fontSize:10},{text: `\nDated : `+finaldt,alignment: 'right',bold: true, fontSize:10}]],
      } ,fontSize:10,layout: 'noBorders',
      },

      { table: {
        widths: [100,'*'],                      
        body:[
          [{text: `\nFrom`,alignment: 'left', fontSize:10},{text: `\nUnder SecretarSz to ttre Govt. of Meghalaya,\nFood Civil Supplies & Consumer Affairs Department`,alignment: 'left', fontSize:10}],
          [{text: `\To : `,alignment: 'left', fontSize:10},{text: localStorage.getItem("department_name"),alignment: 'left', fontSize:10}],
          [{text: `\Subject : `,alignment: 'left',fontSize:10},{text: `Sanction for Family ldentlty Card Scheme fot 2O2O-2O2L .`,alignment: 'left',bold: true, fontSize:10}],
          [{text: `\Ref : `,alignment: 'left',fontSize:10},{text: `Your letter No. DSCA.8 1 / 2OO9 / Pt / 36, dt. 30.O9.2O2O.`,alignment: 'left', fontSize:10}]],
      } ,fontSize:10,layout: 'noBorders',
      },

      {text: "\nSir,\n",alignment: 'left',bold: true, fontSize:10},
      { text: "I am directed to convey the approval of the Governor of Megha-laya to arr amount not exceeding "+this.pdf_EstimatedAmount+"/- being the cost for Implementation "+this.pdf_schemeTitle+".",alignment: 'justify', fontSize:10,margin: [20, 5] },
      { text: "The expenditure is debitabie to the Head of Account '"+this.pdf_hoa+"' during the current Financial year Budget 2O2O - 2021.",alignment: 'justify', fontSize:10,margin: [20, 15] },
      { text: "The relevant rules/procedures laid down for incurring expenditure (i.e. for Drawal and Disbursement of money) shouid be strictly adhered to.",alignment: 'justify', fontSize:10,margin: [20, 10] },
      { text: "This sanction issues with the concurrence of Pialning & Finance(EC-ll) Depa-rtment vide their 11D.1321/2021 dt.19.03.2021 & I/D Fin(EC.lI)3618 /2O2O-21,dt.22.O3.2O2 1 respectively.",alignment: 'justify',fontSize:10,margin: [20, 10] },
      
      { table: {
        widths: [250,'*'],                      
        body:[
          ['',{text: `\nYour faithfully,\n\n\n`,alignment: 'center', fontSize:10}],
          ['',{text: `Under Secretary to the Gort. of Meghalaya,\nFood Civil Supplies & Consumer Affairs Department`,alignment: 'center', fontSize:10}]],
          
      } ,fontSize:10,layout: 'noBorders',
      },

    ]}

      var action='download';
     const documentDefinition = this.pdfData;       
     switch (action) {         
       case 'open': pdfMake.createPdf(documentDefinition).open(); break;
       case 'print': pdfMake.createPdf(documentDefinition).print(); break;
       case 'download': pdfMake.createPdf(documentDefinition).download('sanction-order.pdf'); break;
       default: pdfMake.createPdf(documentDefinition).open(); break;
     }
  }

  approve(){
    this.actiontakenid=2;
    this.isApprovedRejectStatus=true;    
    this.nextStageID();
  }
  reject(){    
    this.actiontakenid=3;    
    this.isApprovedRejectStatus=true;    
    this.nextStageID();    
  }

  parked(){
    console.log("parked")
    $("#viewformdetail").hide();
    $("#addattr").hide();
    $("#bascidetail").hide();
    $("#corress").hide();
    $("#addfile").hide();
    $("#addnote").hide();
    $("#sendto").hide();
    $("#fileViwer").hide();
    $("#parkedto").show();    
  }
  parkedClosed(){
    this.viewformdetail();
  }

  actionParked(){
    var date = new Date();  
    let month=date.getMonth() + 1;
    let m,d;  
    if(month<10){ 
      m="0"+month 
    }else{m=month}
    if(date.getDate()<10){d="0"+date.getDate()}else{d=date.getDate()}
    let year=date.getFullYear();  
    let todayDate=year+"-"+m+"-"+d;  
  

    let parkedtill = $("#parkedtill").val();
    if(parkedtill =="" || parkedtill == null || parkedtill == undefined){
      this.toastrService.warning("Parked till Date is required");
    }else if(this.parkedRemarks == "" || this.parkedRemarks == null || this.parkedRemarks == undefined){
      this.toastrService.warning("Parked Remarks is required");
    }else{     
      let sqlQueryEdit = `update radx_worklist set parkedtimestamp='${todayDate}', parkedtill='${parkedtill}', parked_remarks='${this.parkedRemarks}',isparked='Y', parked_status=1 where worklistid=`+this.worklistid;
      this.formService.getFormData(sqlQueryEdit).subscribe(res => {
        this.toastrService.success("File Parked Successfully...");
        this.router.navigateByUrl('/inbox?'+localStorage.getItem('tempToken'));  
      });
    }
    
  }

  updateApprovedRejectStatus(){ 
    let statusid=0;
     if(this.actiontakenid == 2){
      statusid=105;
     }else if(this.actiontakenid == 3){
      statusid=106;
     }else{
      statusid=107;
     }
    let sqlQueryEdit = `UPDATE  radx_draft_requests SET status_id=${statusid} where id=`+this.draft_id;
    // let sqlQueryEdit = `UPDATE  radx_draft_requests SET approved_reject_status=${this.actiontakenid} where id=`+this.draft_id;
    this.formService.getFormData(sqlQueryEdit).subscribe(res => {
      console.log("updated.")
    });
  }

getSanctionOrderDetail(){
  let sanctionQuery=`select dept.department_name, hoa.hoa_details, sys.varibale_name as funding_category, rd.id, rd.draft_requestid, rd.scheme_title, rd.estimated_cost, rd.approved_cost  from radx_draft_requests rd
  left join app_tblsystemenum sys on sys.id=rd.funding_category_id 
  left join z_entitymast_cnw_head_of_accounts_001 hoa on hoa.construction_schemenew_work_id = rd.item_id
  left join z_entitymast_department_001 dept on dept.department_id = rd.department_id
  where rd.id=${this.draft_id}`
  this.formService.getFormData(sanctionQuery).subscribe(res => {
    let result=res.rows;
    if(result.length>0){
      this.pdf_EstimatedAmount = result[0]["estimated_cost"]?result[0]["estimated_cost"]:"";
      this.pdf_schemeTitle = result[0]["scheme_title"]?result[0]["scheme_title"]:"";
      this.pdf_hoa = result[0]["hoa_details"]?result[0]["hoa_details"]:"";
      // console.log("sanction Details==>",JSON.stringify(result))
    }
  });
}
showproposed(){
    $("#viewformdetail").hide();
    $("#addattr").hide();
    $("#bascidetail").hide();
    $("#corress").hide();
    $("#addfile").hide();
    $("#addnote").hide();
    $("#sendto").hide();
    $("#fileViwer").hide();
    $("#parkedto").hide(); 
    $("#divproposedapprove").show();
}
proposedClosed(){
  this.viewformdetail();
}

imageObjects: any[]=[];
loadImage(){
  this.imageObjects = [{
   image: '../assets/images/1.jpg',
   thumbImage: '../assets/images/1.jpg',
   title: 'Hummingbirds are amazing creatures'
}, {
   image: '../assets/images/2.jpg',
   thumbImage: '../assets/images/2.jpg',
   title: 'Hummingbirds are amazing creatures'
}, {
   image: '../assets/images/3.jpg',
   thumbImage: '../assets/images/3.jpg',
   title: 'Hummingbirds are amazing creatures'
},{
   image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
   thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
   title: 'Hummingbirds are amazing creatures'
}, {
   image: '../assets/images/4.jpg',
   thumbImage: '../assets/images/4.jpg',
   title: 'Hummingbirds are amazing creatures'
}];
}

  ngOnDestroy() { 
    //this.editor.destroy();
    this.attachmentType = null
    this.subscription.unsubscribe();
    this.formService=null;
    this.frameworkComponents=null
    this.processing=null;
    this.formId=null
    this.processid=null
    this.stageid=null
    this.form_viewid=null
    this.formName=null
    this.tableName=null
    this.tablePrimaryKey=null
    this.SectionConfig=null
    this.formDisplayJSON=null
    this.nodeJson=null
    this.connJson=null
    this.FormViewLoaded=null
    this.SuccessMasgLoaded=null
    this.ErrorMasgLoaded=null
    this.isAddMode=null
    this.is_attachment=null
    this.SuccessMasg=null
    this.editrowid=null
    this.refKey=null
    this.childFormList=null
    this.metaDataList=null
    this.attachmentType=null
    this.metadata=null
   this.edit_option=null
  }
  



}
