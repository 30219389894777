import { Component, OnInit } from '@angular/core';
import { Page } from '../../_models/page';
import { ConfigPrams } from "../../field.interface";
import { ProductService } from '../../_services/product.service';
import { loadavg } from 'os';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonFunctionService } from '../../_services/common-function.service';
import { ideacraftAdminService } from '../../_services/ideacraftadmin.service';
import { CustomerLoginSigninService } from '../../_services/customer-login-signin.service';
import { AppSettings } from '../../_services/AppSetting';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
declare var $: any
@Component({
  selector: 'app-buyer-list',
  templateUrl: './buyer-list.component.html',
  styleUrls: ['./buyer-list.component.css']
})
export class BuyerListComponent implements OnInit {
  isLoading: boolean = true;
  LoadPagination: boolean = false;
  public totalCount = 0;
  buyerList: any[] = [];
  page = new Page();
  isView = true
  remarks: any;
  configParms: ConfigPrams;
  TempbuyerList: any[] = [];
  isLoader: boolean;
  userId: string;
  buyerAddress: any[];
  customerName: any;
  customerMobile: any;
  customerEmail: any;
  buyerId: any;
  blocked: boolean;
  currentBuyerName: any = '';
  searchTerm: any;
  isloadgridTrue: boolean = false;
  showPopUp:Boolean=true;
  customerId: any;
  sysEnumList: any =[];
  payment_term: string;
  is_payment: boolean;
  concern_saleperson: any;
  concern_saleperson_email: any;
  concern_saleperson_contact: any;
  enterprise_name: any;
  enterprise_id: any;
  subscription: any;
  credit_id: any;
  credit_limt: any;
  customer_id: any;
  comany_name:any;
  gst_In:any;
  email_id:any;
  phone_number:any;
  billing_address:any;
  country_id:any;
  countryList:any;
  billing_zipcode:any;
  billing_city:any;
  state_id:any;
  stateList:any;
  default_billing_address:any;
  shipping_contact_person:any;
  shipping_emailid:any;
  shipping_phone_number:any;
  shipping_adress:any;
  country_id_ship:any;
  shipping_zipcode:any;
  state_id_ship:any;
  default_shipping_address:any;
  isEdit:any;
  shipping_city:any;
  MassageOutTime = AppSettings.getMassageOutTime;
  cityList: Object;
  newPass: any;
  cnfrmPass: any;
  showPassword: boolean= false;
  showPassword1: boolean = false;
  credit_remaining: any;
  credit_used: any;
  total_credit: any;
  paymentDate: any;
  receivedAmount: any;
  constructor(private ProductService: ProductService, 
    private ideacraftService: ideacraftAdminService,
    private sellerService: SellerRegistrationService,
    private customerLoginSigninService: CustomerLoginSigninService,private toastrService: ToastrService, private router:Router,
    public commonFunctionService: CommonFunctionService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.bindNgxDataGrid(this.page, 1)
    this.getSysEnum();
    this.getAllCountries();
    this.getStateList();
  }

  

  getSysEnum() {
    this.ideacraftService.getSysEnumType('creditPeriod').subscribe((res: any) => {
      this.sysEnumList = res.rows;
    });
  }
  getAllCountries() {
    this.ProductService.getCounty().subscribe((res: any) => {
      this.countryList = res.rows;
    })
  }
  getStateList() {
    this.sellerService.getState().subscribe(res => {
      this.stateList = res;
    })
  }
  getCityList() {
    this.sellerService.getCity().subscribe(res => {
      this.cityList = res;
    })
  }
  getBCityAndState() {
    this.customerLoginSigninService.getCityState(this.billing_zipcode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.billing_city = stateCityList.city_name ? stateCityList.city_name : null;
        this.state_id = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.billing_city = null;
        this.state_id = null;
      }
    })

  }
  getSCityAndState() {
    this.customerLoginSigninService.getCityState(this.shipping_zipcode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.shipping_city = stateCityList.city_name ? stateCityList.city_name : null;
        this.state_id_ship = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.shipping_city = null;
        this.state_id_ship = null;
      }
    })

  }
  choosePaymentTerm() {
    if (this.payment_term == 'cash') {
      this.is_payment = false
    }
    else {
      this.is_payment = true
    }
  }
  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
    
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
  } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    if(!this.isloadgridTrue)
    offset = this.searchTerm ? 0 : offset
    this.ProductService.buyerCount(this.searchTerm).subscribe((resp:any) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.ProductService.allBuyerList(pagesize, offset, this.searchTerm).subscribe((res:any) => {
        if(res.rows.length > 0){
          this.buyerList = res.rows;
           console.log(this.buyerList)
          this.TempbuyerList = this.buyerList;
          for (let i = 0; i < this.buyerList.length; i++) {
            this.buyerList[i]["mobile"] = (this.buyerList[i].mobile_no ? this.buyerList[i].mobile_no : "--");
            this.buyerList[i]["email"] = (this.buyerList[i].email ? this.buyerList[i].email : "--");
            // this.buyerList[i]["full_name"] = (this.buyerList[i].full_name ? this.buyerList[i].full_name : "--");
            this.buyerList[i]['full_name'] = this.buyerList[i].full_name.trim() ? this.buyerList[i].full_name : '--'
          }
          this.isLoading = false;
        }else{
          setTimeout(() => {
            this.buyerList = []
          this.isLoading = false;
          }, 100);
        }
      

      })

      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
   //    this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      // console.log(this.configParms,'========>>>>>>>>>>>buyer')
      this.LoadPagination = true;

    })

  }
  updatePayementReceived(data){
    this.customerId = data.rowData.customer_id
    this.credit_remaining = data.rowData.credit_remaining;
    this.credit_used = data.rowData.credit_used;
    this.total_credit = data.rowData.total_credit;
    console.log("data", this.customerId)
    $("#update_payment").modal('show');
  }
  onSubmitPayment(){
    if(this.paymentDate == null || this.paymentDate == '' || this.paymentDate == undefined){
      this.toastrService.warning("Please enter date!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.receivedAmount == null || this.receivedAmount == '' || this.receivedAmount == undefined){
      this.toastrService.warning("Please enter payment received amount!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.customerLoginSigninService.updatePyamentForCorporate(this.customerId, this.paymentDate, this.receivedAmount).subscribe((res: any) => {
      if (res.status == 200){
        this.toastrService.success("Payment updated successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.removeClass();
        this.onreset();
        $("#update_payment").modal('hide');
        this.router.navigateByUrl('/buyer-list'+ '?' + localStorage.getItem('tempToken'));
      }     
    })

  }
  onreset(){
    this.paymentDate = ''
    this.receivedAmount = ''
  }
  blockBuyer(data) {
        this.buyerId = data.rowData.customer_id;
        this.currentBuyerName = data.rowData.full_name.trim().length ? data.rowData.full_name : 'this buyer'
    this.remarks = '';
    this.blocked = true
    $("#myModal").modal('show');


  }
  unBlockBuyer(data) {
    this.blocked = false
    this.buyerId = data.rowData.customer_id;
    this.currentBuyerName = data.rowData.full_name.trim().length ? data.rowData.full_name : 'this buyer'


    this.remarks = '';
    $("#myModal").modal('show');
  }
  onBlock() {

    // if (this.remarks == '' || this.remarks == undefined || this.remarks == null || (this.remarks || '').trim().length === 0){
    //   this.toastrService.warning("Remarks is required", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   //$("#myModal").modal('hide');
    //   return false;
    // }
    let buyerStatus = 195;
    Swal.fire({
      title: `Do you want to block ${this.currentBuyerName}?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.buyerStatusupdate(this.buyerId, buyerStatus, this.remarks, this.userId).subscribe(res => {
          Swal.fire('Buyer Blocked', '', 'success')
          this.bindNgxDataGrid(this.page, 1)  
          //this.showPopUp=false; 
          $("#myModal").modal('hide');
        })
      } else if (result.isDenied) {
        this.remarks = ''
      }
    })


  }
  onUnblock() {
    // if (this.remarks == '' || this.remarks == undefined || this.remarks == null || (this.remarks || '').trim().length === 0){
    //   this.toastrService.warning("Remarks is required", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   //$("#myModal").modal('hide');
    //   return false;
    // }
    let buyerStatus = 196;
    Swal.fire({
      title: `Do you want to unblock ${this.currentBuyerName}?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.buyerStatusupdate(this.buyerId, buyerStatus, this.remarks, this.userId).subscribe(res => {
          Swal.fire('Buyer Activated', '', 'success')
          this.bindNgxDataGrid(this.page, 1)
         //this.showPopUp=false;
          $("#myModal").modal('hide');
        })
      } else if (result.isDenied) {
        this.remarks = ''
      }
    })


  }
  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;
    this.bindNgxDataGrid(this.page, 1)
    // this.buyerList = this.TempbuyerList.filter(item => ((item.full_name ? (item.full_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.mobile_no ? (item.mobile_no.toLowerCase().indexOf(ev) !== -1) : false) || (item.email ? (item.email.toString().indexOf(ev) !== -1) : false) ))
  }
 addUser(){
  this.router.navigateByUrl('/create-user')
  localStorage.removeItem('editable-user')
 }
  viewAccount(row){
    //console.log("rowssss",row)
    let buyerId =  row.rowData.customer_id;
    let role_id = row.rowData.role_id;
    let data = {
      buyerid: buyerId,
      buyername: row.rowData.full_name,
      mobile: row.rowData.mobile_no,
      email: row.rowData.email
    }
    let data1 = {
      role_id: role_id
    }
     localStorage.setItem('buyer', JSON.stringify(data));
     localStorage.setItem('role_id', JSON.stringify(data1));
    //   this.router.navigateByUrl('buyer-orderlist' + '?' + localStorage.getItem('tempToken'));
    // console.log('View account clicked');
    this.router.navigateByUrl('/buyer-account' + '?' + localStorage.getItem('tempToken'))
  }
  getMinDate(){
    
    let tommorow = new Date().getDate()
    let cur = new Date(new Date().getFullYear(), new Date().getMonth(), tommorow)
    return new Date(cur.getFullYear(),cur.getMonth(),cur.getDate()+1).toISOString().split('T')[0];
  }
  viewDetail(data) {
    let id = data.rowData.customer_id;
    this.customerName = data.rowData.full_name;
    this.customerEmail = data.rowData.email;
    this.customerMobile = data.rowData.mobile;

    $("#exampleModal").modal('show');

    this.ProductService.getBuyerAddress(id).subscribe((res:any) => {
      this.buyerAddress = res.rows
    })

  }

  allBuyerOrders(row){
    let buyerId =  row.rowData.customer_id;
    let data = {
      buyerid: buyerId,
      buyername: row.rowData.full_name
    }
     localStorage.setItem('buyer', JSON.stringify(data));
      this.router.navigateByUrl('buyer-orderlist' + '?' + localStorage.getItem('tempToken'));
  }
  openConvertModal(obj){
    //console.log("objjeje", obj)  
    this.customer_id = obj.rowData.customer_id;
    this.enterprise_name = obj.rowData.full_name
    //console.log("obj.rowData.customer_id", this.customer_id)
    $("#convertModal").modal('show');
  }
  onUserEdit(obj) {
    //
    //localStorage.setItem('OnEditable','True')
     //console.log('check for edit product', obj)   
     
      localStorage.setItem("editable-user", obj.rowData.customer_id);
      this.router.navigateByUrl('/create-user' + '?' + localStorage.getItem('tempToken'))
   
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  reset(){
    this.payment_term = ''
    this.subscription = ''
    this.concern_saleperson = ''
    this.concern_saleperson_email= ''
    this.concern_saleperson_contact = ''
    this.credit_id = ''
    this.credit_limt = ''
    this.enterprise_name = ''
    this.comany_name = ''
    this.gst_In = ''
    this.email_id = ''
    this.phone_number = ''
    this.billing_address = ''
    this.country_id = ''
    this.state_id = ''
    this.billing_city = ''
    this.billing_zipcode = ''
    this.default_billing_address = ''
    this.shipping_contact_person = ''
    this.shipping_emailid = ''
    this.shipping_phone_number = ''
    this.shipping_adress = ''
    this.country_id_ship = ''
    this.state_id_ship = ''
    this.shipping_city = ''
    this.shipping_zipcode = ''
    this.default_shipping_address = ''
    this.credit_id = ''
    this.credit_limt = ''
  }
 saveUserDetails(){   
    this.enterprise_name = this.enterprise_name ? this.enterprise_name.trim() : ''
    if(this.enterprise_name == null || this.enterprise_name == undefined || this.enterprise_name == ''){
      this.toastrService.warning("Please Enter Enterprise Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    } 
  if(!this.payment_term){
    this.toastrService.warning("Please Choose Payment Term!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if(this.payment_term == 'credit'){
    if(this.credit_id == null || this.credit_id == undefined || this.credit_id == ''){
    this.toastrService.warning("Please Select Credit Period!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
    }
    this.credit_limt = this.credit_limt ? this.credit_limt.trim() : ''
    if(this.credit_limt == null || this.credit_limt == undefined || this.credit_limt == ''){
    this.toastrService.warning("Please Enter Credit Limit!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
    }
  }
  if(!this.subscription){
    this.toastrService.warning("Please Choose Subscription Type!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  } 
  this.concern_saleperson = this.concern_saleperson ? this.concern_saleperson.trim() : ''
  if(this.concern_saleperson == null || this.concern_saleperson == undefined || this.concern_saleperson ==''){
    this.toastrService.warning("Please Enter Concern Sale Person Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  } 
  if (this.concern_saleperson_email == null || this.concern_saleperson_email == undefined || this.concern_saleperson_email == '') {
    this.toastrService.warning('Please Enter Concern Sale Person Email!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
  }
  if (this.concern_saleperson_email !== null || this.concern_saleperson_email !== undefined || this.concern_saleperson_email !== '') {
    var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (this.concern_saleperson_email.substring(0).length > 0) {
      var email = this.concern_saleperson_email.substring(0).toString();
      if (pattrn.test(email) == false) {
        this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      }
    }
  }
  if (this.concern_saleperson_contact == null || this.concern_saleperson_contact == undefined || this.concern_saleperson_contact == '') {
    this.toastrService.warning('Please Enter Concern Sale Person Contact Number!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
    $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
  }
  if (this.concern_saleperson_contact !== null || this.concern_saleperson_contact !== undefined || this.concern_saleperson_contact !== '') {
    if (this.concern_saleperson_contact.toString().length == 10) {
      if ((this.concern_saleperson_contact[0]) < 6) {
        this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      }
    } else {
      this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
  }  
  this.comany_name = this.comany_name ? this.comany_name.trim() : ''
    if(this.comany_name == null || this.comany_name == undefined || this.comany_name == ''){
      this.toastrService.warning("Please Enter Company Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.gst_In == null || this.gst_In == undefined || this.gst_In == '') {
      this.toastrService.warning('Please Enter GST No', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.gst_In !== undefined && this.gst_In !== null && this.gst_In !== " ") {
      var pattrn = new RegExp('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9]{1}[zZ][a-zA-Z0-9]{1}$')
      if (this.gst_In.substring(0).length > 0) {
        var newpancard = this.gst_In.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid GST No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      }
    }
    if (this.email_id == null || this.email_id == undefined || this.email_id == '') {
      this.toastrService.warning('Please Enter Email For Billing!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.email_id !== null || this.email_id !== undefined || this.email_id !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.email_id.substring(0).length > 0) {
        var email = this.email_id.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.phone_number == null || this.phone_number == undefined || this.phone_number == '') {
      this.toastrService.warning('Please Enter Phone Number For Billing!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.phone_number !== null || this.phone_number !== undefined || this.phone_number !== '') {
      if (this.phone_number.toString().length == 10) {
        if ((this.phone_number[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    this.billing_address = this.billing_address ? this.billing_address.trim() : ''
    if(this.billing_address == null || this.billing_address == undefined || this.billing_address == ''){
      this.toastrService.warning("Please Enter Address for Billing!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.country_id == null || this.country_id == undefined || this.country_id == ''){
      this.toastrService.warning("Please Select Country!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.state_id == null || this.state_id == undefined || this.state_id == ''){
      this.toastrService.warning("Please Select Province/Territory/State !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.billing_city == null || this.billing_city == undefined || this.billing_city == ''){
      this.toastrService.warning("Please Enter City Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.billing_zipcode == null || this.billing_zipcode == undefined || this.billing_zipcode == ''){
      this.toastrService.warning("Please Enter Zip Code!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.shipping_contact_person = this.shipping_contact_person ? this.shipping_contact_person.trim() : ''
    if(this.shipping_contact_person == null || this.shipping_contact_person == undefined || this.shipping_contact_person == ''){
      this.toastrService.warning("Please Enter Contact Person Name For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.shipping_emailid == null || this.shipping_emailid == undefined || this.shipping_emailid == '') {
      this.toastrService.warning('Please Enter Email For Shipping!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.shipping_emailid !== null || this.shipping_emailid !== undefined || this.shipping_emailid !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.shipping_emailid.substring(0).length > 0) {
        var email = this.shipping_emailid.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.shipping_phone_number == null || this.shipping_phone_number == undefined || this.shipping_phone_number == '') {
      this.toastrService.warning('Please Enter Phone Number For Shipping!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.shipping_phone_number !== null || this.shipping_phone_number !== undefined || this.shipping_phone_number !== '') {
      if (this.shipping_phone_number.toString().length == 10) {
        if ((this.shipping_phone_number[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    this.shipping_adress = this.shipping_adress ? this.shipping_adress.trim() : ''
    if(this.shipping_adress == null || this.shipping_adress == undefined || this.shipping_adress == ''){
      this.toastrService.warning("Please Enter Address for Billing!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.country_id_ship == null || this.country_id_ship == undefined || this.country_id_ship == ''){
      this.toastrService.warning("Please Select Country For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.state_id_ship == null || this.state_id_ship == undefined || this.state_id_ship == ''){
      this.toastrService.warning("Please Select Province/Territory/State !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.shipping_city == null || this.shipping_city == undefined || this.shipping_city == ''){
      this.toastrService.warning("Please Enter City Name For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.shipping_zipcode == null || this.shipping_zipcode == undefined || this.shipping_zipcode == ''){
      this.toastrService.warning("Please Enter Zip Code For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
 
  var corporateDetails: any = {
   enterprise_name: this.enterprise_name, payment_term: this.payment_term,
    credit_id: this.credit_id, credit_limt: this.credit_limt, subscription: this.subscription, 
    concern_saleperson: this.concern_saleperson, concern_saleperson_email: this.concern_saleperson_email, concern_saleperson_contact: this.concern_saleperson_contact,
    comany_name: this.comany_name, gst_In: this.gst_In, email_id: this.email_id, phone_number: this.phone_number, billing_address: this.billing_address, country_id: this.country_id,
    state_id: this.state_id, billing_city: this.billing_city, billing_zipcode: this.billing_zipcode, default_billing_address: this.default_billing_address, 
    shipping_contact_person: this.shipping_contact_person, shipping_emailid: this.shipping_emailid, shipping_phone_number: this.shipping_phone_number, shipping_adress: this.shipping_adress,
    country_id_ship: this.country_id_ship, state_id_ship: this.state_id_ship, shipping_city: this.shipping_city, shipping_zipcode: this.shipping_zipcode, default_shipping_address: this.default_shipping_address    
  };
  this.customerLoginSigninService.saveUserDetails(corporateDetails, this.customer_id).subscribe((res: any) => {
    if (res.status == 200){
      this.toastrService.success("User Details updated successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      this.removeClass();
      this.reset();
      $("#convertModal").modal('hide');
      this.router.navigateByUrl('/buyer-list'+ '?' + localStorage.getItem('tempToken'));
    }     
  })
 }
 changePassword(obj){
  this.customer_id = obj.rowData.customer_id;
  $("#updatePassword").modal('show');
}
resetP(){
  this.newPass = ''
  this.cnfrmPass = ''
}
showHidePassword() {
  // this.showPassword = !this.showPassword;
  if (this.showPassword) {
    this.showPassword= false;
  } else {
    this.showPassword = true;
  }
}
updatePassword()
{ var minNumberofChars = 8;
  var maxNumberofChars = 16;
  let passpattern = /(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/;
  let pattern = /^((?=.*[a-z])(?=.*[A-Z]).*)$/
   if(this.newPass==null || this.newPass==undefined || this.newPass=="" || this.newPass=="undefined" || this.newPass=="null")
   {
    this.toastrService.warning('Please Enter New Password', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false; 
   }
   if (this.newPass !== null || this.newPass !== undefined || this.newPass !== '') {
    if (this.newPass.length < minNumberofChars || this.newPass.length > maxNumberofChars) {
      this.toastrService.warning("Your password must be at least 8 characters", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    } else if (!passpattern.test(this.newPass)) {
      this.toastrService.warning("Password should contain atleast one number and one special character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    } else if (!pattern.test(this.newPass)) {
      this.toastrService.warning("Password should contain atleast one upper case and one lower case character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
  }
   if(this.cnfrmPass==null || this.cnfrmPass==undefined || this.cnfrmPass=="" || this.cnfrmPass=="undefined" || this.cnfrmPass=="null")
   {
    this.toastrService.warning('Please Enter Confirm Password', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false; 
   }
   if(this.newPass!=this.cnfrmPass)
   {
    this.toastrService.warning('New password should be same as confirm password', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false; 
   } 
   
   Swal.fire({
    title: `Are you sure you want to change password?`,
    showDenyButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result) => {
    if (result.isConfirmed) {
      this.ProductService.updatePasswordByAdminForBuyer(this.newPass, this.customer_id).subscribe((res: any) => {
        if (res.status == 200){
          this.toastrService.success("Password changed successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          this.removeClass();
          this.resetP();
          $("#updatePassword").modal('hide');
          setTimeout(() => {
            window.location.reload()
          }, 500);
        }     
      })  
    }
  });
 
}
showHidePassword1() {
// this.showPassword = !this.showPassword;
if (this.showPassword1) {
  this.showPassword1 = false;
} else {
  this.showPassword1 = true;
}
}
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
