import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { CommonFunctionService } from '../../_services/common-function.service';
import { ProductService } from '../../_services/product.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/_services/login.service';
import { AppSettings } from 'src/app/_services/AppSetting';
//import Locals from 'ngx-editor/lib/Locals';
declare var $: any;
@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.css']
})
export class ProductListingComponent implements OnInit {
  seller_id: any;
  adminRemarks: any;
  admin_price: any;
  QC_id: any;
  view_item_id: any;
  product_type_id: any;
  isView:Promise<boolean>
  allProductList: any[]=[];
  configProductList: any[];
  tempconfigList: any[];
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  LoadPagination2: boolean = false;
  LoadPagination3: boolean = false;
  business: boolean = false;
  TempallProductList: any;
  isLoading: boolean = false;
  userId: any;
  allProductListData: any;
  totalCountData: any;
  productId: any;
  allProductListfilter: any;
  isOpen: any = false;
  isConfirm: boolean;
  searchTerm: any;
  isloadgridTrue: any = false;
  draftList: any[];
  isdraft: boolean = true;
  draftCountList: any;
  pendingCountList: any;
  pendingpro: boolean = false;
  totalBusinessCount: any;
  selectedCardId: any;
  liveProductListCount: any;
  mrp: any[];
  productType: any = 0;
  perprice: any[];
  singleproductPrice: any;
  sellinPrice: any;
  sellinMRP: any;
  singleproductMRP: any;
  price: any;
  role_id: string;
  customer_id: string;
  MassageOutTime = AppSettings.getMassageOutTime;
  selling_price: any;
  //  approvedCount : any;
  //  rejectCount : any;
  //  blockCount : any;
  //  suspendedCount : any;
  constructor(private productService: ProductService,
    private formService: FormService, private loginService: LoginService,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,
    private router: Router) { }

  ngOnInit(): void {

    this.seller_id = localStorage.getItem('sellerId');
    // this.getAllProducts();
    $(document).ready(function () {
      $('ul.tbsbtns li').click(function () {
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
      });
    });
    this.isLoading = true;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10

    this.bindNgxDataGrid2(this.page, 1)
    this.getProductList();

    //this.getBusinesscountforcard()

  }

  // getBusinesscountforcard(){
  //   this.productService.getBusinessCardCounts(171, '').subscribe(res =>{
  //     this.totalBusinessCount = res.rows[0].count
  //   })
  // }

  submitTOQCBusiness(data) {
    // localStorage.setItem("business_card_id", data.rowData.id);
    this.selectedCardId = data.rowData.card_id
    this.isOpen = false
    this.adminRemarks = '';
    this.admin_price = '';
    $("#myModal").modal('show');

  }
  getProductList() {
    this.productService.getAllProductsListcount(172, this.searchTerm).subscribe((resp: any) => {
      this.pendingCountList = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
      console.log("pending count List", this.pendingCountList)
    })
  }
  // getTotalLiveProductsCount(){
  //   this.productService.getLiveProductListCount(173, this.searchTerm).subscribe(res =>{
  //    //  console.log('check for toal live products', res)
  //     this.liveProductListCount = res.rows[0]?.count ? res.rows[0].count : +res.rows.length;
  //   })
  // }
  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    // this.isdraft = true
    this.bindNgxDataGrid(this.page, 1)
  }
  LoadGrid3(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    // this.isdraft = true
    //this.bindNgxDataGrid3(this.page, 1)
  }
  LoadGrid2(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid2(this.page, 1)
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatus = 172;
    if (!this.isloadgridTrue)
      offset = this.searchTerm ? 0 : offset
    this.productService.getAllProductsListcount(qcStatus, this.searchTerm).subscribe((resp: any) => {
      this.page.totalElements = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllPendingProductsList(pagesize, offset, qcStatus, this.searchTerm).subscribe(res => {
        if (res[0].rows.length >= 0) {
          this.allProductList = res[0].rows;
          this.configProductList = res[1].rows;
          // console.log("pendingproduct", this.configProductList)

          this.getProductList()
          let tempObj;
          for (let obj of this.allProductList) {

            if (obj.product_type == 198) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj?.price;
              obj.mrp = tempObj?.mrp;
              obj.purchase_price = tempObj?.purchase_price;
              obj.suk = tempObj?.sku;
            }
            if (obj.product_type == 208) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj?.price;
              obj.mrp = tempObj?.mrp;
              obj.purchase_price = tempObj?.purchase_price;
              obj.suk = tempObj?.sku;
            }

          }
          //console.log('responsessssssssssssssssssssss', this.allProductList)
          this.TempallProductList = this.allProductList;
          this.isLoader = false;
        }
        else {
          setTimeout(() => {
            this.allProductList = []
            this.isLoader = false;
          }, 100)
        }
      })
      this.totalCount = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;
      this.LoadPagination2 = false;

      //this.isLoading = false;
    })

  }

  // bindNgxDataGrid3(pageInfo, status) {
  //   this.isLoader=true;
  //    let total, pagesize, limit, offset = 0;
  //    pagesize = pageInfo.size;
  //    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
  //      offset = 0;
  //    } else {
  //      offset = ((pageInfo.pageNumber - 1) * pagesize);

  //    }
  //    let qcStatus = 171;
  //    if(!this.isloadgridTrue)
  //   offset = this.searchTerm ? 0 : offset
  //    this.productService.getBusinessCardCounts(qcStatus, this.searchTerm).subscribe(resp => {
  //      this.page.totalElements = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
  //      // console.log('check length for count', this.page.totalElements)
  //      let pageCount = pageInfo.pageNumber
  //      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
  //      this.productService.getBusinessCardsList(qcStatus, pagesize, offset, this.searchTerm).subscribe(res => {
  //        if (res.rows.length > 0) {
  //          this.allProductList = res.rows;
  //          let tempObj;
  //         console.log('responsessssssssssssssssssssss', this.allProductList)
  //          this.TempallProductList = this.allProductList;
  //          this.isLoader = false;
  //        }
  //        else {
  //          setTimeout(() => {
  //            this.allProductList = []
  //            this.isLoader = false;
  //          }, 100)
  //        }
  //      })
  //      this.totalCount = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
  //      let currPage = pageInfo.pageNumber;
  //      if (currPage == 0) { currPage = 1; }
  //      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
  //      this.LoadPagination2 = true;
  //      this.LoadPagination = false;
  //      this.isLoader = false;
  //      this.isLoading = false;
  //    })

  //  }


  bindNgxDataGrid2(pageInfo, status) {
    this.productService.getDraftProductsCounts(localStorage.getItem('userId'), 171, this.searchTerm).subscribe((resp: any) => {
      this.draftCountList = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
    })
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatus = 171;
    if (!this.isloadgridTrue)
      offset = this.searchTerm ? 0 : offset
    this.productService.getDraftProductsCounts(localStorage.getItem('userId'), qcStatus, this.searchTerm).subscribe((resp: any) => {
      this.page.totalElements = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
      // console.log('check length for count', this.page.totalElements)
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getDraftProducts(localStorage.getItem('userId'), pagesize, offset, qcStatus, this.searchTerm).subscribe(res => {
        if (res[0].rows.length > 0) {
          this.allProductList = res[0].rows;
          this.configProductList = res[1].rows;
          console.log('this.allProductList', this.allProductList)
          let tempObj;
          for (let obj of this.allProductList) {
            if (obj.product_type == 198) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp
              obj.purchase_price = tempObj.purchase_price
              obj.suk = tempObj.sku
            }
            if (obj.product_type == 208) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp
              obj.purchase_price = tempObj.purchase_price
              obj.suk = tempObj.sku
            }
          }
          // console.log('responsessssssssssssssssssssss', this.allProductList)
          this.TempallProductList = this.allProductList;
          this.isLoader = false;
        }
        else {
          setTimeout(() => {
            this.allProductList = []
            this.isLoader = false;
          }, 100)
        }
      })
      this.totalCount = resp.rows[0]?.count ? resp.rows[0].count : +resp.rows.length;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination2 = true;
      this.LoadPagination = false;
      //this.isLoader = false;
      //this.isLoading = false;
    })

  }

  CommonGridSearchMaster(ev) {
    this.searchTerm = ev
    if (this.isdraft)
      this.bindNgxDataGrid2(this.page, 1)
    else
      this.bindNgxDataGrid(this.page, 1)
    // this.allProductList = this.TempallProductList.filter(item => ((item.seller_name ? (item.seller_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.product_name ? (item.product_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.category_name ? (item.category_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.mrp ? (item.mrp.toString().indexOf(ev) !== -1) : false) || (item.price ? (item.price.toString().indexOf(ev) !== -1) : false)))
  }

  onProductView(data) {
    console.log('ppppppppppp', data)
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    this.role_id = ''
    this.customer_id = ''
    $('#scroll').animate({ scrollTop: 0 }, 'slow');


    this.isView = Promise.resolve(false);

    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      this.isView = Promise.resolve(true);

      $("#exampleModal").modal('show');
    });



  }
  closeModal() {
    // this.isView = false;
    // $("#exampleModal").modal('hide');
    this.adminRemarks = '';
    this.admin_price = '';
    $("#mi-modal").modal('hide');
    $("#myModal").modal('hide');
    $("#myModal1").modal('hide');

  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  submitforQc() {
    this.productService.submitForQc(this.view_item_id).subscribe(res => {
      this.toastrService.success("Product Successfully Submitted For QC", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      $("#myModal").modal('hide');
      $("#myModal1").modal('hide');
      this.draftProductAddByAdmin();
      this.getProductList()

    })
  }

  onCancle() {
    $("#myModal").modal('hide');
    $("#myModal1").modal('hide');
  }

  submitToQC(data) {
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    this.selling_price = data.rowData.price;
    let newary = this.configProductList.filter(x => x.product_id == this.view_item_id);
    this.tempconfigList = newary
    this.isOpen = false
    this.adminRemarks = '';
    this.productType = data.rowData.product_type;
    // this.singleproductPrice = data.rowData.price;
    // this.singleproductMRP = data.rowData.mrp;
    this.productId = data.rowData.product_id;
    if (data.rowData.product_type == 198 || data.rowData.product_type == 208) {
      console.log(this.tempconfigList)
      $("#myModal1").modal('show');
    } else {
      this.singleproductPrice = data.rowData.price;
      this.singleproductMRP = data.rowData.mrp;
      $("#myModal").modal('show');
    }

  }

  editBusinessCard(row) {
    let data = JSON.stringify({ "editable_item": row.rowData.card_id, "seller_id": row.rowData.seller_id });
    let encriptUrl = this.loginService.encriptDataUrl(data)
    this.router.navigateByUrl('/add-business-card?' + encriptUrl);
  }
  onConfirm() {
    let dataenterd = this.admin_price;
    this.sellinPrice = (((this.singleproductPrice * dataenterd) / 100).toFixed(0));
    //console.log("entered value  after: ",this.sellinPrice);
    let mrpenter = this.admin_price;
    this.sellinMRP = 0;
    //this.sellinMRP = (((this.singleproductMRP * mrpenter) / 100).toFixed(0));
    //console.log("selling mrp.", this.sellinMRP);
    let productId = localStorage.getItem('view-item')
    // if (this.adminRemarks == '' || this.adminRemarks == undefined || this.adminRemarks == null || (this.adminRemarks || '').trim().length === 0){
    //   this.toastrService.warning("Remarks is required", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //    $(".overlay-container").addClass("addcontainerdisable");
    //    //$("#myModal").modal('hide');
    //    return false;

    // }
    // $("#mi-modal").modal('show');
    if (this.productType == 198 || this.productType == 208) {
      for (let obj of this.tempconfigList) {
        
        if (obj.admin_price == null || obj.admin_price == undefined || obj.admin_price == '') {
          this.toastrService.warning("Please enter selling price", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          //$("#myModal").modal('hide');
          return false;
        }
        if(obj.admin_price < 0){
          this.toastrService.warning("Selling price must be greater or equal to 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false; 
        }
        if (obj.admin_price !== undefined && obj.admin_price !== null && obj.admin_price !== "") {
          let pattrn = /^[0-9]+$/;
            if (pattrn.test(obj.admin_price) == false) {
              this.toastrService.warning("Selling price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
              $(".overlay-container").addClass("addcontainerdisable");
               this.removeClass();
              return false; 
          }
        }
        if((Number((((obj.price * obj.admin_price) / 100).toFixed(0))) + Number(obj.price))>obj.mrp){
          this.toastrService.warning("Selling price not greater than MRP", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          //$("#myModal").modal('hide');
          return false;
        }
      }
    } else{
      if (this.admin_price == '' || this.admin_price == undefined || this.admin_price == null) {
        this.toastrService.warning("Please enter selling price", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        //$("#myModal").modal('hide');
        return false; 
      }
      if(this.admin_price < 0){
        this.toastrService.warning("Selling price must be greater or equal to 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (this.admin_price !== undefined && this.admin_price !== null && this.admin_price !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(this.admin_price) == false) {
            this.toastrService.warning("Selling price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if((Number(this.sellinPrice) + Number(this.singleproductPrice))>this.singleproductMRP){
        this.toastrService.warning("Selling price not greater than MRP", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        //$("#myModal").modal('hide');
        return false;
      }

    }


    let quStatus = 173;

    Swal.fire({
      title: `Do you want to approve this product?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.productStatusupdate(this.productId, quStatus, this.adminRemarks, this.userId, this.sellinPrice, this.productType, this.sellinMRP, JSON.stringify(this.tempconfigList)).subscribe(res => {
          Swal.fire('Approved', '', 'success')
          this.bindNgxDataGrid(this.page, 1)
          this.adminRemarks = ''
          this.admin_price = ''
          $("#myModal").modal('hide');
          $("#myModal1").modal('hide');
        })
      } else if (result.isDenied) {
        this.adminRemarks = ''
        this.admin_price = ''
      }
    })


  }
  onReject() {
    // if (this.adminRemarks == '' || this.adminRemarks == undefined || this.adminRemarks == null || (this.adminRemarks || '').trim().length === 0){
    //   this.toastrService.warning("Remarks is required", '', { timeOut: 1000, positionClass: "toast-top-center",disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    // }
    //$("#myModal").modal('hide');

    // this.productService.productStatusupdate(this.productId, quStatus, this.adminRemarks,this.userId).subscribe(res => {
    //   this.toastrService.success('Product Rejected','', { timeOut: 1000, positionClass: "toast-top-center" });
    //   $("#myModal").modal('hide');

    //   this.bindNgxDataGrid(this.page, 1)

    // })
    let quStatus = 174;
    Swal.fire({

      title: `Do you want to reject this product ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.productStatusupdate(this.productId, quStatus, this.adminRemarks, this.userId, '', '', '').subscribe(res => {
          Swal.fire('Rejected', '', 'success')
          this.bindNgxDataGrid(this.page, 1)
          this.adminRemarks = ''
          this.admin_price = ''
          $("#myModal").modal('hide');
          $("#myModal1").modal('hide');
        })
      } else if (result.isDenied) {
        // this.adminRemarks = ''
        // this.admin_price = ''
        // $("#myModal").modal('hide');
      }
    })

  }

  onRejectBusiness() {
    this.isOpen = true
    this.isConfirm = true
    $("#mi-modal").modal('hide');
    // console.log('check if block for isconfirm', this.isConfirm)
    let quStatus = 174;
    this.productService.businessCardSubmit(this.selectedCardId, quStatus, this.adminRemarks).subscribe(res => {
      this.toastrService.success("Card Approved.", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      $("#myModal").modal('hide');
      //this.bindNgxDataGrid3(this.page, 1);
      //this.getBusinesscountforcard();
    })
  }

  showSellerDetails(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem('approve_reject', 'false')
    // this.router.navigateByUrl('seller-profile', );
  }
  productListing(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    this.router.navigateByUrl('/seller-wise-product' + '?' + localStorage.getItem('tempToken'));

  }
  orderListing(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    //this.router.navigateByUrl('seller-profile');
  }

  confirmMessage() {
    this.isOpen = true
    this.isConfirm = true
    $("#mi-modal").modal('hide');
    // console.log('check if block for isconfirm', this.isConfirm)
    let quStatus = 173;
    this.productService.productStatusupdate(this.productId, quStatus, this.adminRemarks, this.userId, '', '', '').subscribe(res => {
      this.toastrService.success("Product Approved", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      $("#myModal").modal('hide');
      $("#myModal1").modal('hide');
      this.bindNgxDataGrid(this.page, 1)
    })

  }

  onConfirmBusiness() {
    this.isOpen = true
    this.isConfirm = true
    $("#mi-modal").modal('hide');
    // console.log('check if block for isconfirm', this.isConfirm)
    let quStatus = 173;
    this.productService.businessCardSubmit(this.selectedCardId, quStatus, this.adminRemarks).subscribe(res => {
      this.toastrService.success("Card Approved.", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      $("#myModal").modal('hide');
      $("#myModal1").modal('hide');
      // this.bindNgxDataGrid3(this.page, 1);
      //this.getBusinesscountforcard();
    })

  }


  goToLink() {
    window.open('seller-profile', "_blank");
  }

  draftProductAddByAdmin() {
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.business = false;
    this.isdraft = true;
    this.pendingpro = false
    this.page.pageNumber = 0
    this.page.totalPages = 0;
    this.bindNgxDataGrid2(this.page, 1)

  }

  pendinglist() {
    this.isdraft = false;
    this.business = false;
    this.pendingpro = true;
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber = 0
    this.page.totalPages = 0;
    this.bindNgxDataGrid(this.page, 1)
  }

  onProductEdit(obj) {
    //localStorage.setItem('OnEditable','True')
    // console.log('check for edit product', obj)
    localStorage.setItem('editinsellerlist', 'false');
    localStorage.setItem('breadcrumb', 'true');
    if (obj.rowData.product_type == 197) {             // 197	"Simple"
      localStorage.setItem("editable-item", obj.rowData.product_id);
      localStorage.setItem('selected_seller_id', obj.rowData.seller_id);
      this.router.navigateByUrl('/upload-catalog' + '?' + localStorage.getItem('tempToken'))
    }
    else if (obj.rowData.product_type == 198) {           // 198	"Configurable"
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/configurable-product?' + encriptUrl);
    }
    else if (obj.rowData.product_type == 199) {          // 199	"Grouped"
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/group-product?' + encriptUrl);
    }
    else if (obj.rowData.product_type == 208) {          // 208	"customizable"
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/customizable-product?' + encriptUrl);
    }
    else if (obj.rowData.product_type == 209) {          // Business card
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/add-business-card?' + encriptUrl);
    }
  }

  draftBusinessCardAddByAdmin() {
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.business = true;
    this.isdraft = false,
      this.pendingpro = false
    this.page.pageNumber = 0
    this.page.totalPages = 0;
    // this.bindNgxDataGrid3(this.page, 1)

  }

  viewBusiness(row) {
    let data = JSON.stringify({ "id": row.rowData.card_id });
    let encriptUrl = this.loginService.encriptDataUrl(data)
    this.router.navigateByUrl('/view-business-card?' + encriptUrl);
  }

  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
