import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'; 
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import {FormService} from '../../_services/form.service';
import { SectionConfig,SessionUrlPrams } from "../../field.interface";
import { ToastrService } from 'ngx-toastr';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import {DialogLayoutDisplay,ConfirmBoxInitializer} from '@costlydeveloper/ngx-awesome-popup'; 
declare var $: any;
import * as CryptoJS from 'crypto-js';
import { AppSettings } from 'src/app/_services/AppSetting'
import{LoginService} from 'src/app/_services/login.service'

@Component({
  selector: 'app-inbox-outbox',
  templateUrl: './inbox-outbox.component.html',
  styleUrls: ['./inbox-outbox.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InboxOutboxComponent  implements OnInit {
  columnActionStrip: any[] = [];

  gridColumnApi:any;
  gridApi:any;
  loginType:any;
  pagesize="10";
  columnDefs:any[]=[];
  rowData:any[]=[];
  columnDefs1:any[]=[];
  rowData1:any[]=[];
  frameworkComponents: any;
  frameworkComponents1: any;
  sessionUrlPrams:SessionUrlPrams; //new added
  loginid:any;
  role_name:any;
  user_name:any;
  formId:any;
  formViewList:any[]=[];
  FormViewLoaded:Promise<boolean>;
  SubmitViewLoaded:Promise<boolean>;
  InboxLoaded:Promise<boolean>;
  OutboxLoaded:Promise<boolean>;
  fromViewjson:any;
  selectedFieldList:any[]=[];
  ViewTableQry:any;
  tableName:any;

  inboxRows:any[]=[];
  inboxColumndef:any[]=[];
  outboxRows:any[]=[];
  outboxColumndef:any[]=[];

  EditLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  SuccessMasg:any;
  ErrorOccurred:String="Error occurred!!";
  SuccessMasgLoaded: Promise<boolean>;
  processid: any;
  refKey:any;
  tablePrimaryKey: any;
  formDisplayJSON: any;
  SectionConfig: any[]=[];
  nodeJson: any[]=[];  
  connJson: any[]=[];
  processinstid:any;
  worklistid:any;
  edit_option:any=1;
  metaDataList: any[]=[];
  attachmentType: any[]=[];
  is_attachment: boolean= false;
  childFormList: any[]=[];
  formName: any;
  isAddMode:boolean=false;
  editValueRow:any;
  stageid: any;
  currentRouter: any="";
  OverlaySetting: any;
  constructor(private router:Router,
    private toastrService: ToastrService,
    private loginService:LoginService,
  private formService:FormService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.OverlaySetting = {
      positionStrategy: new ConnectedPositioningStrategy({
        horizontalDirection: HorizontalAlignment.Left,
        horizontalStartPoint: HorizontalAlignment.Right,
        verticalStartPoint: VerticalAlignment.Bottom,

      }),
      scrollStrategy: new NoOpScrollStrategy()
    };
   }

  ngOnInit(): void {
  let url = this.router.url.split("?")[0]
    if(url == "/outbox"){
      this.currentRouter="outbox";
    }else if(url == "/inbox"){
      this.currentRouter="inbox";
    }

    // console.log(localStorage.getItem("userId"));
    $("body").removeClass("greyback");   
    
    $(function() {
      var $tabButtonItem = $('#tab-button li'),
      $tabSelect = $('#tab-select'),
      $tabContents = $('.tab-contents'),
      activeClass = 'is-active';
      $tabButtonItem.first().addClass(activeClass);
      $tabContents.not(':first').hide();
      $tabButtonItem.find('a').on('click', function(e) {
        var target = $(this).attr('href');
        $tabButtonItem.removeClass(activeClass);
        $(this).parent().addClass(activeClass);
        $tabSelect.val(target);
        $tabContents.hide();
        $(target).show();
        e.preventDefault();
      });
      $tabSelect.on('change', function() {
        var target = $(this).val(),
        targetSelectNum = $(this).prop('selectedIndex');
        $tabButtonItem.removeClass(activeClass);
        $tabButtonItem.eq(targetSelectNum).addClass(activeClass);
        $tabContents.hide();
        $(target).show();
      });
    });


    this.sessionUrlPrams={
      frmid:this.formId,
      vid:0,
      UserCode:"UserCode",
      UserName:localStorage.getItem("userFullName"),
      ProfileId:localStorage.getItem("profileId"),
      Location:localStorage.getItem("office"),
      department:localStorage.getItem("department"),
      processtype:"ext",
    };

   
    // console.log("Current Router==>",this.currentRouter)

    this.getInboxList();
    this.getOutboxList();
  }

  getInboxList()
  {
     var strInboxQry=`select w.worklistid,frm.form_name,TO_CHAR(w.sendtime:: DATE, 'dd/mm/yyyy') sendtime,CONCAT(su.user_fname,' ',su.user_mname,' ',su.user_lname) sendername, dr.is_sanction_order_generated,
     CONCAT(ru.user_fname,' ',ru.user_mname,' ',ru.user_lname) as receivername,pr.process_name,pi.form_id,pi.itemid,CONCAT(wu.user_fname,' ',wu.user_mname,' ',wu.user_lname) as withwhom ,pi.processinstanceid,
     dr.draft_requestid as requestid,w.is_open,w.actiontaken,w.stageid,dr.scheme_title,d.department_name,dr.department_id,dr.form_vid,ses.varibale_name as scheme_category,'[1,1]' as val
      from radx_worklist w
      left join app_tbluserprofile s on w.sourceid=s.userprofile_id
      left join app_tbluser su on s.user_id=su.user_id
      left join app_tbluserprofile rec on w.destinationid=rec.userprofile_id
      left join app_tbluser ru on rec.user_id=ru.user_id
      left join radx_processinstances pi on w.processinstanceid=pi.processinstanceid
      left join app_tbluserprofile whom on pi.with_whom_userid=whom.userprofile_id
      left join app_tbluser wu on whom.user_id=wu.user_id
      left join radx_process pr on pi.processid=pr.processid
      left join radx_draft_requests dr on w.processinstanceid=dr.processinstid
      left join app_forms frm on frm.form_id=dr.form_id
      left join z_entitymast_department_001 d on dr.department_id=d.department_id
      left join app_tblsystemenum ses on dr.scheme_category=ses.id
      where  w.actiontaken='N' and (w.parked_status is null or w.parked_status = 2) and w.destinationid=${this.sessionUrlPrams.ProfileId} order by w.sendtime desc`;
      this.formService.getFormData(strInboxQry).subscribe(res=>{
      this.inboxColumndef=[
        {"field": "requestid","headerName": "Request ID","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
        {"field": "scheme_title","headerName": "Scheme Title","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
        {"field": "form_name","headerName": "Proposal Type","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
        {"field": "department_name","headerName": "Department Name","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
        {"field": "sendtime","headerName": "Received Date","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
        {"field": "sendername","headerName": "Received From","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
        
      ]  
      this.columnActionStrip.push({ field: "View Process", iconName: "external-link-square" });
      this.inboxRows=res.rows;
      this.InboxLoaded = Promise.resolve(true);
     })
  }

  getOutboxList(){
    this.outboxRows=[];
    this.outboxColumndef=[];
      var strInboxQry=`select w.worklistid,frm.form_name,sesy.varibale_name as sender_prefix,TO_CHAR(w.sendtime:: DATE, 'dd/mm/yyyy') sendtime,CONCAT(su.user_fname,' ',su.user_mname,' ',su.user_lname) sendername,
      CONCAT(ru.user_fname,' ',ru.user_mname,' ',ru.user_lname) as receivername,pr.process_name,pi.form_id,pi.itemid,CONCAT(wu.user_fname,' ',wu.user_mname,' ',wu.user_lname) as withwhom ,pi.processinstanceid,
      dr.draft_requestid, dr.id as draftid,w.is_open,w.actiontaken,w.stageid,dr.scheme_title,d.department_name,dr.form_vid,ses.varibale_name as scheme_category,'[1,1]' as val
      from radx_worklist w
      left join app_tbluserprofile s on w.sourceid=s.userprofile_id
      left join app_tbluser su on s.user_id=su.user_id
      left join app_tbluserprofile rec on w.destinationid=rec.userprofile_id
      left join app_tbluser ru on rec.user_id=ru.user_id
      left join radx_processinstances pi on w.processinstanceid=pi.processinstanceid
      left join app_tbluserprofile whom on pi.with_whom_userid=whom.userprofile_id
      left join app_tbluser wu on whom.user_id=wu.user_id
      left join radx_process pr on pi.processid=pr.processid
      left join radx_draft_requests dr on w.processinstanceid=dr.processinstid
      left join app_forms frm on frm.form_id=dr.form_id
      left join z_entitymast_department_001 d on dr.department_id=d.department_id
      left join app_tblsystemenum ses on dr.scheme_category=ses.id
      left join app_tblsystemenum sesy on su.user_prifix=sesy.id
      where  w.sourceid =${this.sessionUrlPrams.ProfileId} order by w.sendtime desc`
  //  where w.actiontaken='Y' and w.destinationid =${this.sessionUrlPrams.ProfileId} order by w.sendtime desc      
    //where  w.actiontaken='Y' and w.destinationid=7 order by w.sendtime desc   
     this.formService.getFormData(strInboxQry).subscribe(res=>{      
      this.outboxColumndef=[
        {"field": "draft_requestid","headerName": "Request ID","sortable": true,"filter": true,"resizable": true,"width": "200","wrapText": true,"autoHeight": true},
        {"field": "scheme_title","headerName": "Scheme Title","sortable": true,"filter": true,"resizable": true,"width": 250,"wrapText": true,"autoHeight": true},
        {"field": "form_name","headerName": "Proposal Type","sortable": true,"filter": true,"resizable": true,"width": 250,"wrapText": true,"autoHeight": true},
        {"field": "department_name","headerName": "Department Name","sortable": true,"filter": true,"resizable": true,"width": 100,"wrapText": true,"autoHeight": true},
        {"field": "sendtime","headerName": "Send Date","sortable": true,"filter": true,"resizable": true,"width": 100,"wrapText": true,"autoHeight": true},
        {"field": "sendername","headerName": "Received From","sortable": true,"filter": true,"resizable": true,"width": 100,"wrapText": true,"autoHeight": true},
        {"field": "withwhom","headerName": "With Whom","sortable": true,"filter": true,"resizable": true,"width": 75,"wrapText": true,"autoHeight": true},
          // {
          //   headerName: 'Action',
          //   field:"val",
          //   pinned: 'right',
          //   cellRenderer: 'buttonRenderer',
          //   cellRendererParams:{
          //     actionList:[
          //       {label:"Call back",form_status:1,db_status:1,str:"view",onClick:this.onCallback.bind(this)},
          //     ]
          //   },
          //   width:120,
          //   resizable:true
          // } 
      ]  
      this.outboxColumndef.push({field:"",headerName:"Action", wrapText: true,autoHeight: true});
      // console.log("outbox==>",JSON.stringify(res.rows))
      this.outboxRows=res.rows;
      // console.log("res.rows==>",JSON.stringify(res.rows))
      this.OutboxLoaded = Promise.resolve(true);
     })
  }


  

  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  OnTracker(obj){
  let fileNo = obj.rowData.draft_requestid;
  // console.log("trac",fileNo);
  this.router.navigateByUrl('/status-tracker?'+localStorage.getItem('tempToken')+'@'+fileNo);
}
  onCallback(obj){
    // console.log(JSON.stringify(obj.rowData))
    let draftid=obj.rowData["draftid"]
    let is_open=obj.rowData["is_open"]
    if(is_open == true){
      this.toastrService.warning("You can't call back.")
    }else{
      const confirmBox = new ConfirmBoxInitializer();
      confirmBox.setTitle('Are you sure?');
      confirmBox.setMessage('You want to call back!');
      confirmBox.setButtonLabels('YES', 'NO');
      confirmBox.setConfig({
        LayoutType: DialogLayoutDisplay.INFO // SUCCESS | INFO | NONE | DANGER | WARNING
      });
      const subscription = confirmBox.openConfirmBox$().subscribe(resp => {
        if(resp.Success==true){          
            let selQuery="select * from radx_worklist where processinstanceid="+obj.rowData["processinstanceid"]+" and actiontaken='Y' order by worklistid desc"
            this.formService.getFormData(selQuery).subscribe(res => {
              let result=res.rows;
              // console.log(result)
              if(result.length>0){
                let delQuery="delete from radx_worklist where worklistid="+obj.rowData["worklistid"] +";";
                let updateProcessInstanceQry="update radx_processinstances set with_whom_userid="+result[0]["destinationid"]+" where processinstanceid="+obj.rowData["processinstanceid"]+";";
                let updateQry=" update radx_worklist set actiontaken='N' where worklistid="+result[0]["worklistid"]+";";
                let finalQry=updateQry+updateProcessInstanceQry+delQuery;
                this.formService.getFormData(finalQry).subscribe(res => {
                  this.toastrService.success("callback successfully completed.")
                  this.getOutboxList();
                });         
              }else{
                let updateDraftStatusId="update radx_draft_requests set status_id=108 where id="+draftid+";"
                let delQuery="delete from radx_worklist where worklistid="+obj.rowData["worklistid"] +";";
                let delProcessInsQuery="delete from radx_processinstances where processinstanceid="+obj.rowData["processinstanceid"] +";";
                let finalQry=delQuery+delProcessInsQuery+updateDraftStatusId;
                this.formService.getFormData(finalQry).subscribe(res => {
                  this.toastrService.success("callback successfully completed.")
                  this.getOutboxList();
                });  
              }
            });
        }
      });     
    }
    
  }

  onProcess(obj){

    // console.log("obj.rowData",obj.rowData);
    this.EditLoaded=Promise.resolve(false);
    this.formId=obj.rowData["form_id"];
    let rowId=obj.rowData["itemid"];
    this.stageid=obj.rowData["stageid"];
    this.processinstid=obj.rowData["processinstanceid"];
    this.worklistid=obj.rowData["worklistid"];
    this.refKey=rowId;
    let vid=obj.rowData["form_vid"];
    let deptid=obj.rowData["department_id"];
    let is_open=obj.rowData["is_open"]?obj.rowData["is_open"]:false;
    let is_sanction_order_generated =obj.rowData["is_sanction_order_generated"]?obj.rowData["is_sanction_order_generated"]:false;
    if(this.formId==313){
      vid=286;
    }else if(this.formId==301){
      vid=273;
    }
    if(is_open == false){
      let updateqry="update radx_worklist set is_open=true where worklistid="+this.worklistid;
      this.formService.getFormData(updateqry).subscribe(res => {

      });
    }
    let data =""
    if(is_sanction_order_generated == true){
      data  = JSON.stringify('frmid='+this.formId+'&deptid='+deptid+'&editrowid='+rowId+'&worklistid='+this.worklistid+'&processinstid='+this.processinstid+'&stageid='+this.stageid+'&isAddMode=false&vid='+vid+'&isView=true')
    }else{
      data  = JSON.stringify('frmid='+this.formId+'&deptid='+deptid+'&editrowid='+rowId+'&worklistid='+this.worklistid+'&processinstid='+this.processinstid+'&stageid='+this.stageid+'&isAddMode=false&vid='+vid+'&isView=false')
    }
    
    let encriptUrl = this.loginService.encriptDataUrl(data)
    this.router.navigateByUrl('/act-process-newx?'+encriptUrl);
    
  }

  getFormDetails(rowId){
    this.formService.getFormData("select v.view_id, f.form_id,f.form_name,f.table_name,f.primary_key,f.form_display_json,f.value_field,f.processid from app_forms f left join app_form_view_details v on f.form_id=v.form_id where f.form_id="+this.formId).subscribe(res => {
      let formList=res.rows;
      let viewid;
      if(formList.length>0){
        this.formName=formList[0]["form_name"];
        this.processid=formList[0]["processid"];
        this.tableName= formList[0]["table_name"];
        viewid=formList[0]["view_id"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase(); 
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
        if(this.formDisplayJSON.length>0){         
          if(this.processid >0){
            this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
            this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
            this.connJson=JSON.parse(this.formDisplayJSON).connJson;
          }else{
            this.SectionConfig=JSON.parse(this.formDisplayJSON)
          }
          
        }
      }
      //  this.edit_option=1
      //  this.EditLoaded=Promise.resolve(true);
      //  $("#editModal").modal('show');
      this.getFormViewDetails(viewid,rowId);
    });
  }
  
  getFormViewDetails(viewid,rowId){
    this.columnDefs=[];

    var strQry='select * from '+this.tableName+' where '+this.tablePrimaryKey+'= '+rowId+';';    
    // this.formService.getFormData(strQry).subscribe(res => {
    //   this.editValueRow=JSON.parse(JSON.stringify(res.rows[0]));
    // });
    let query="select fv.is_add,fv.is_edit,fv.is_view,fv.is_exportable,fv.child_form_ids, fv.view_id,fv.field_json,fv.is_attachment,fv.meta_data,fv.attachment_type,f.primary_key,fv.edit_option  from app_form_view_details fv left join app_forms f on fv.form_id=f.form_id  where  fv.view_id="+viewid;
    let finalQry=strQry+query;
    this.formService.getFormData(finalQry).subscribe(res => {
      //console.log(res)
      this.editValueRow=JSON.parse(JSON.stringify(res[0].rows[0]));
      // console.log(this.editValueRow)
      let formViewList=res[1].rows;
      if(formViewList.length>0){
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        this.edit_option=formViewList[0]["edit_option"];
        this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;
        if(childFormId != "" && childFormId != undefined && childFormId != null){
          this.formService.getFormData(" select form_id,form_name,table_name,primary_key,form_display_json,value_field from app_forms where form_id in("+childFormId.split(",")+")").subscribe(childRes => {
            this.childFormList=childRes.rows;
          });
        }
        setTimeout(() => {
          this.edit_option=1
          this.EditLoaded=Promise.resolve(true);
          $("#editModal").modal('show');
        }, 10);
       
        // if(fromViewjson.length>0){          
        //   this.formService.getFormData(this.ViewTableQry).subscribe(res => {
        //     this.rowData=res.rows;
        //     this.rowData=this.rowData.sort((a, b) => a[this.tablePrimaryKey] > b[this.tablePrimaryKey] ? 1 : -1)
        //     $("#clos").click();
        //   },err=>{
        //     $("#clos").click();
        //   });
        //   this.FormViewLoaded = Promise.resolve(true);
        // }
      }
    });
  }

  processsubmit(value: any){
    //console.log("process submit == >",value)
    this.ErrorMasgLoaded = Promise.resolve(false); 
    let json=value["value"];
    let actionid=value["actionid"];
    let notesheet_containt=json["textarea_remarks"];
    let touserid=Number(json["sendto"]);
    //console.log(actionid,notesheet_containt,json["actiontaken"],touserid)
    if( notesheet_containt.toString().trim()!="" && touserid.toString().trim() !="0"){
      let processInstanceQry="update radx_processinstances set updated_at=now(), current_stageid="+actionid+" ,with_whom_userid="+touserid+" where processinstanceid="+this.processinstid +";";
      let worklistupdateQry="update radx_worklist set updated_at=now() , actiontaken='Y' where worklistid="+this.worklistid +";"; 
      // this.formService.getFormData(processInstanceQry).subscribe(res => {
      //   if(res.status==500){         
      //     this.ErrorMasgLoaded = Promise.resolve(true); 
      //   }else{
      let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,createdby_userid,processid,processinstanceid,stageid)values(1,'"+notesheet_containt+"',"+parseInt(localStorage.getItem("userId"))+","+this.processid+","+this.processinstid+","+actionid+");";
      let processWorklistQry="insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken)values("+this.processinstid+","+actionid+","+parseInt(localStorage.getItem("userId"))+","+touserid+",'N');";
      let finalQry=processNoteQry+processWorklistQry +processInstanceQry +worklistupdateQry
      this.formService.getFormData(finalQry).subscribe(res => {
        this.SuccessMasg="Submitted successfully !!";        
          this.SuccessMasgLoaded = Promise.resolve(true);
      });
    }else{
      this.ErrorMasgLoaded = Promise.resolve(true); 
      if( notesheet_containt.toString().trim()==""){
        this.ErrorOccurred="Remarks is required";   
      }else{
        this.ErrorOccurred="Send to is required";   
      }
        
    }
    
    //   }
    // // console.log(processInstanceQry)
    // })
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
 
}


}
