<div class="row">

    <div class="col-md-4 col-6 mb-4">
        <!-- <div class="card text-center shadow-none rounded-0 h-100" (click)="showDetail(obj.product_id,obj.product_type)"> -->
        <div class="card text-center shadow-none rounded-0 h-100">
            <a href="javascript:void(0)">
                <div class="product-img">
                    <img class="img-fluid" src="../../../assets/images/product/prduct002.png">
                </div>
            </a>
            <div class="card-body p-3">
                <div class="text-left">
                    <h4 class="productdesc mb-0">Light Shirt
                    </h4>
                    <div class="my-2">
                        <h4 class="roductprice d-inline-block mr-2 mb-0">₹2300</h4>
                        <span class="beforeprice">
                            <small>
                                <span class="text-decoration-line-through">₹4999</span>
                            </small>
                            <span class="discountdeal">
                                <span class="text-heightlight ml-1">
                                    55% Off
                                </span>
                            </span>
                        </span>
                    </div>
                    <div class="starrating">
                        <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                            4.2
                            <i class="fa fa-star"></i>
                        </span>
                        <span class="ml-1">
                            (2544)
                        </span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-5 text-left">
                        <a href="javasript:void(0)" class="text-decoration-none btn btn-sm py-1 px-3 btn-danger btn-bottom rounded-pill">
                            <i class="fa fa-trash mr-1"></i>
                            <span class="d-none d-xl-inline-block">Delete</span>
                        </a>
                    </div>
                    <div class="col-7 text-right">
                        <a href="javascript:void(0)" class="text-decoration-none btn btn-sm py-1 px-3 btn-theme btn-bottom rounded-pill menu-dis">
                            <i class="fa fa-cart-plus mr-1"></i>
                            <span class="d-none d-xl-inline-block">Add to cart</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-6 mb-4">
        <!-- <div class="card text-center shadow-none rounded-0 h-100" (click)="showDetail(obj.product_id,obj.product_type)"> -->
        <div class="card text-center shadow-none rounded-0 h-100">
            <a href="javascript:void(0)">
                <div class="product-img">
                    <img class="img-fluid" src="../../../assets/images/product/prduct005.png">
                </div>
            </a>
            <div class="card-body p-3">
                <div class="text-left">
                    <h4 class="productdesc mb-0">Dark Shirt
                    </h4>
                    <div class="my-2">
                        <h4 class="roductprice d-inline-block mr-2 mb-0">₹2300</h4>
                        <span class="beforeprice">
                            <small>
                                <span class="text-decoration-line-through">₹4999</span>
                            </small>
                            <span class="discountdeal">
                                <span class="text-heightlight ml-1">
                                    55% Off
                                </span>
                            </span>
                        </span>
                    </div>
                    <div class="starrating">
                        <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                            4.2
                            <i class="fa fa-star"></i>
                        </span>
                        <span class="ml-1">
                            (2544)
                        </span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-5 text-left">
                        <a href="javasript:void(0)" class="text-decoration-none btn btn-sm py-1 px-3 btn-danger btn-bottom rounded-pill">
                            <i class="fa fa-trash mr-1"></i>
                            <span class="d-none d-xl-inline-block">Delete</span>
                        </a>
                    </div>
                    <div class="col-7 text-right">
                        <a href="javascript:void(0)" class="text-decoration-none btn btn-sm py-1 px-3 btn-theme btn-bottom rounded-pill menu-dis">
                            <i class="fa fa-cart-plus mr-1"></i>
                            <span class="d-none d-xl-inline-block">Add to cart</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-6 mb-4">
            <!-- <div class="card text-center shadow-none rounded-0 h-100" (click)="showDetail(obj.product_id,obj.product_type)"> -->
            <div class="card text-center shadow-none rounded-0 h-100">
              <a href="javascript:void(0)"><div class="product-img"> <img  class="img-fluid" src="../../../assets/images/product/Apparel.png">
              </div></a>
              <div class="card-body p-3">
                <div class="text-left">
                  <h4 class="productdesc mb-0">Dark Shirt
                  </h4>
                  <div class="my-2">
                    <h4 class="productprice d-inline-block mr-2 mb-0">₹2300</h4>
                    <span class="beforeprice">
                      <small> <span class="text-decoration-line-through">₹4999</span></small>
                      <span class="discountdeal">
                        <span class="text-heightlight ml-1">
                          55% Off
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="starrating"><span class="btn btn-theme btn-sm py-1 px-2  text-white">
                      4.2 <i class="fa fa-star"></i>
                    </span>
                    <span class="ml-1">
                      (2544)
                    </span>
                  </div>
                </div>
        
                <div class="row mt-3">
                  <div class="col-5 text-left">
                    <a href="javasript:void(0)"
                      class="text-decoration-none btn btn-sm py-1 px-3 btn-danger btn-bottom rounded-pill"><i
                        class="fa fa-trash mr-1"></i> <span class="d-none d-xl-inline-block">Delete</span></a>
                  </div>
                  <div class="col-7 text-right">
                               <a href="javascript:void(0)" class="text-decoration-none btn btn-sm py-1 px-3 btn-theme btn-bottom rounded-pill menu-dis"><i class="fa fa-cart-plus mr-1"></i> <span class="d-none d-xl-inline-block">Add to cart</span></a>
                            </div> 
                </div>
              </div>
            </div>
          </div>
</div>