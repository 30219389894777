import { BrowserModule } from '@angular/platform-browser';
import { NgModule,Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest ,HTTP_INTERCEPTORS} from '@angular/common/http'
import { CommonModule,DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import {TreeModule} from 'primeng/tree';
import {ContextMenuModule} from 'primeng/contextmenu';
import { HttpClientModule } from '@angular/common/http';
import {LoginService} from './_services/login.service';
import {FormService} from './_services/form.service';
import { ModalModule } from 'ng-modal-lib';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';
//import { NgxEditorModule } from 'ngx-editor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {TableModule} from 'primeng/table';
import { DndModule } from 'ngx-drag-drop';
import { AngularEditorModule } from '@kolkov/angular-editor';
//import { ProductDetailsCustomizeComponent } from './custom-component-admin/product-details-customize/product-details-customize.component';
import { FormEditComponent } from './form-edit/form-edit.component';
//import { CarouselModule } from 'ngx-owl-carousel-o';
import { ButtonRendererComponent } from './button-renderer/button-renderer.component';
import { GridFromButtonRendererComponent } from './components/dynamic-grid-form-btnrenderer/dynamic-grid-form-btnrenderer.component'
// import { ExtRadxProcessComponent } from './components/process-ext-component/radx-process-ext-component'
import { AgGridModule } from 'ag-grid-angular';
import { LeftMenuComponent } from './shared/left-menu/left-menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserFilterComponent } from './user-filter/user-filter.component';
import { DisplayFormDetailComponent } from './display-form-detail/display-form-detail.component';
import { EventCalenderComponent } from './custom-component/event-calender/event-calender.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import dayGridPlugin from '@fullcalendar/daygrid';  
import interactionPlugin from '@fullcalendar/interaction';
import { FileAttachmentComponent } from './file-attachment/file-attachment.component';  
import { GridTestNewComponent } from './grid-test-new/grid-test-new.component';  
import { ImageViewerModule } from "ngx-image-viewer";
import { SafePipe } from './safe.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { InboxOutboxComponent } from './system-component/inbox-outbox/inbox-outbox.component';
import { InprocessCompletedComponent } from './system-component/inprocess-completed/inprocess-completed.component';
import {HdsClientLibModule} from 'hds-client-lib'
import { AppSettings } from './_services/AppSetting';
import { HunoMainComponent } from './custom-component/huno-main/huno-main.component';
import { DashboardnewComponent } from './system-component/dashboardnew/dashboardnew.component';
import { MainStripComponent } from './shared/main-strip/main-strip.component';
import { DynamicDashboardComponent } from './system-component/dynamic-dashboard/dynamic-dashboard.component';
import { NotesheetComponent } from './shared/notesheet/notesheet.component';
import { ActProcessComponent } from './system-component/act-process/act-process.component';
import { ChartsModule } from 'ng2-charts';
import { UserRuleComponent } from './custom-component/user-rule/user-rule.component';
import {IgxGridModule,IgxTabsModule,
	IgxButtonModule,IgxCsvExporterService,IgxExcelExporterService,
	IgxFocusModule,
	IgxActionStripModule,} from '@infragistics/igniteui-angular';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
// import { NgxPaginationModule } from 'ngx-pagination';
//import { NgxPaginationModule } from 'ngx-pagination';
//import { NgxPaginationModule } from 'ngx-pagination';
import { HdsMainTabGroupComponent } from './custom-component/hds-main-tab-group/hds-main-tab-group.component';
import { HunoMainIgxComponent } from './custom-component/huno-main-igx/huno-main-igx.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CustomizeSettingComponent } from './shared/customize-setting/customize-setting.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ActProcessNewComponent } from './system-component/act-process-new/act-process-new.component';
import { StatusTrackerComponent } from './system-component/status-tracker/status-tracker.component';
import { FileAttachmentNewComponent } from './file-attachment-new/file-attachment-new.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxAwesomePopupModule, ConfirmBoxConfigModule } from '@costlydeveloper/ngx-awesome-popup';
import { ActProcessNewxComponent } from './system-component/act-process-newx/act-process-newx.component';
import { AuthInterceptor } from './Auth/auth-interceptor'

import { AuthGard} from './Auth/auth-gard';
import { MisReportComponent } from './custom-component/mis-report/mis-report.component';
import { DocumentViewComponent } from './custom-component/document-view/document-view.component';
import { ChangePasswordComponent } from './custom-component/change-password/change-password.component';
import { LoginnNewMeghComponent } from './loginn-new-megh/loginn-new-megh.component';
import { CardDisplayComponent } from './system-component/card-display/card-display.component';
import { ConfirmationMsgComponent } from './confirmation-msg/confirmation-msg.component';
import { SuccessfullmsgComponent } from './successfullmsg/successfullmsg.component';
import { ManageBannerComponent } from './custom-component-admin/manage-banner/manage-banner.component';
import { SellerRegistrationListComponent } from './custom-component-admin/seller-registration-list/seller-registration-list.component';
// import { SellerDetailComponent } from './custom-component-admin/seller-detail/seller-detail.component';
import { ProductListingComponent } from './custom-component-admin/product-listing/product-listing.component';
import { SellerProfileComponent } from './custom-component-admin/seller-profile/seller-profile.component';
import { ProductDetailsComponent } from './custom-component-admin/product-details/product-details.component';
import { TotalSellerListComponent } from './custom-component-admin/total-seller-list/total-seller-list.component';
import { TotalProductListingComponent } from './custom-component-admin/total-product-listing/total-product-listing.component';
import { SellerWiseProductComponent } from './custom-component-admin/seller-wise-product/seller-wise-product.component';
import { IgxTooltipModule} from 'igniteui-angular';
import { ManageOrderComponent } from './custom-component-admin/manage-order/manage-order.component';
import { BuyerListComponent } from './custom-component-admin/buyer-list/buyer-list.component';
import { BuyerOrderlistComponent } from './custom-component-admin/buyer-orderlist/buyer-orderlist.component';
// import { SellerRegistrationComponent} from './custom-component-admin/seller-registration/seller-registration.component'
import { AddConfigurableProductComponent} from './custom-component-admin/add-configurable-product/add-configurable-product.component'
import { AddGroupProductComponent} from './custom-component-admin/add-group-product/add-group-product.component'
import {AddProductComponent } from './custom-component-admin/add-product/add-product.component';
import { AddCustomizableProductComponent } from './custom-component-admin/add-customizable-product/add-customizable-product.component';
import { AddNewDealComponent } from './add-new-deal/add-new-deal.component';
import { TotalOrderHistoryComponent } from './custom-component-admin/total-order-history/total-order-history.component';
import { MyOrdersComponent } from './custom-component-admin/my-orders/my-orders.component';
import { BrandListingComponent} from './custom-component-admin/brand-listing/brand-listing.component';
import { AddBusinessCardComponent } from './custom-component-admin/add-business-card/add-business-card.component';
import { BusinessCardComponent } from './custom-component-admin/business-card/business-card.component';
import { BusinessCardListComponent } from './custom-component-admin/business-card-list/business-card-list.component';
import { UserLoginComponent } from './custom-component-admin/user-login/user-login.component';
import { BuyerAccountComponent } from './custom-component-admin/buyer-account/buyer-account.component';
import { AddToCartComponent } from './custom-component-admin/add-to-cart/add-to-cart.component';
import { CheckoutComponent } from './custom-component-admin/checkout/checkout.component';
import { WishlistComponent } from './custom-component-admin/wishlist/wishlist.component';
import { QuickOrderComponent } from './custom-component-admin/quick-order/quick-order.component';
import { BulkorderQueryListComponent } from './custom-component-admin/bulkorder-query-list/bulkorder-query-list.component';
import { GstMasterComponent } from './custom-component-admin/gst-master/gst-master.component';
import { ManageAllOrdersComponent } from './custom-component-admin/manage-all-orders/manage-all-orders.component';
import { FormatTimePipe} from "./custom-component-admin/user-login/user-login.component";
import { CreateUserComponent } from './corporate/create-user/create-user.component';
import { UsersComponent } from './corporate/users/users.component';
import { UserDetailsComponent } from './corporate/user-details/user-details.component';
import { CreateOrderComponent } from './order-master/create-order/create-order.component';
import { NewGiftCodeComponent } from './order-master/new-gift-code/new-gift-code.component';
import { GiftCodeComponent } from './order-master/gift-code/gift-code.component';
import { ViewGiftCodeComponent } from './order-master/view-gift-code/view-gift-code.component';
import { YearMasterComponent } from './custom-component-admin/year-master/year-master.component';
import { PaymentHistoryComponent } from './custom-component-admin/payment-history/payment-history.component';
import { AddSellerComponent } from './add-seller/add-seller.component';
import { CreateAccountSellerComponent } from './create-account-seller/create-account-seller.component';
import { RightPermissionComponent } from './custom-component/right-permission/right-permission.component';
import { AddVariationsComponent } from './add-variations/add-variations.component';
import { FeedbackReviewComponent } from './feedback-review/feedback-review.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);


@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      // Prevent caching in IE, in particular IE11.
      // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    HeaderComponent,
    FooterComponent,
    GridFromButtonRendererComponent,
    ButtonRendererComponent,
    LeftMenuComponent,
    DashboardComponent,
    UserFilterComponent,
    DisplayFormDetailComponent,
    EventCalenderComponent,
    FileAttachmentComponent,
    // CarouselModule, 
    ProductDetailsComponent,
    SellerProfileComponent,
    ProductListingComponent,
    FormEditComponent,
    InboxOutboxComponent,
    AddProductComponent,
    InprocessCompletedComponent,
    GridTestNewComponent,
    HunoMainComponent,
    AddConfigurableProductComponent,
    AddGroupProductComponent,
    DashboardnewComponent,
    MainStripComponent,
    DynamicDashboardComponent,
    // SellerRegistrationComponent,
    NotesheetComponent,
    ActProcessComponent,
    UserRuleComponent,
    HdsMainTabGroupComponent,
    HunoMainIgxComponent,
    BreadcrumbComponent,
    ActProcessNewComponent,
    StatusTrackerComponent,
    FileAttachmentNewComponent,
    ActProcessNewxComponent,
    MisReportComponent,
    DocumentViewComponent,
    ChangePasswordComponent,
    LoginnNewMeghComponent,
    CustomizeSettingComponent,
    CardDisplayComponent,
    ConfirmationMsgComponent,
    SuccessfullmsgComponent,
    ManageBannerComponent,
    SellerRegistrationListComponent,
    TotalSellerListComponent,
    TotalProductListingComponent,
    SellerWiseProductComponent,
    ManageOrderComponent,
    //ProductDetailsCustomizeComponent,
    BuyerListComponent,
    BuyerOrderlistComponent,
    AddCustomizableProductComponent,
    AddNewDealComponent,
    TotalOrderHistoryComponent,
    // SellerDetailComponent
    MyOrdersComponent,
    BrandListingComponent,
    AddBusinessCardComponent,
    BusinessCardComponent,
    BusinessCardListComponent,
    UserLoginComponent,
    BuyerAccountComponent,
    AddToCartComponent,
    CheckoutComponent,
    WishlistComponent,
    QuickOrderComponent,
    BulkorderQueryListComponent,
    GstMasterComponent,
    ManageAllOrdersComponent,
    FormatTimePipe,
    CreateUserComponent,
    UsersComponent,
    UserDetailsComponent,
    CreateOrderComponent,
    NewGiftCodeComponent,
    GiftCodeComponent,
    ViewGiftCodeComponent,
    YearMasterComponent,
    PaymentHistoryComponent,
    AddSellerComponent,
    CreateAccountSellerComponent,
    RightPermissionComponent,
    AddVariationsComponent,
    FeedbackReviewComponent,
  ],
  imports: [
    //UsersetupModule,
    BrowserModule,
    AppRoutingModule,FullCalendarModule,
    TreeModule,
    HdsClientLibModule.forRoot({apiUrl:AppSettings.API_ENDPOINT,dbType:"pg"}),
    ContextMenuModule,
    HttpClientModule,
    DndModule,
    IgxTooltipModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    ModalModule,
    AutoCompleteModule,
    NgMultiSelectDropDownModule,
    //NgxEditorModule,
    NgSelectModule,
    TabViewModule,ImageViewerModule.forRoot(),
    AgGridModule.withComponents([]),
    ChartsModule,
    NgxDatatableModule,
    TableModule,
    IgxGridModule,
    IgxTabsModule,
    IgxButtonModule,    
    IgxFocusModule,
    IgxActionStripModule,
    NgxLoadingModule,
    AngularEditorModule,
    //NgxPaginationModule,
    //NgxPaginationModule,
    //NgxPaginationModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgImageSliderModule,    
    NgxAwesomePopupModule.forRoot(), 
    ConfirmBoxConfigModule.forRoot()
  ],
 
    providers: [LoginService,FormService,DatePipe,IgxCsvExporterService,AuthGard,
      IgxExcelExporterService,BnNgIdleService,{
        provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
      }],
  bootstrap: [AppComponent],
})
export class AppModule { }
