<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Seller List</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="col-sm-12  mb-2 p-0">

        <div id="tab01" class="tab-contents mmt5 p-0 border-0">
          <div class="col-sm-12 col-md-12  nopadding">
            <div id="grid-theme-wrapper">
              <div class="tabs row my-2 mx-0">
                <!-- <div class="tab-button-outer1  col-lg-6 pl-0">
                  <ul id="tab-button">
                    <li id="tabDraft" class="is-active">
                      <a href="javascript:void(0)" class="font8">Seller List
                      </a>
                    </li>
                  </ul>
                </div> -->
                <div class="col-sm-9 px-0 mb-sm-0 mb-2">
                  <div class="white-space-pre-x">
                    <div class="tab-button-outer1">
                      <ul id="tab-button" class="tbsbtns">
                        <li id="tabDraft" class="is-active">
                          <a href="javascript:void(0)" class="font8" (click)="getApprovedSeller(); SearchValue.value = ''">Approved
                            <span>({{approvedCount}})</span>
                          </a>
                        </li>
                        <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getRejectedSeller(); SearchValue.value = ''">Rejected
                            <span>({{rejectCount}})</span>
                          </a>
                        </li>
                        <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getBlockedSeller(); SearchValue.value = ''">Blocked
                            <span>({{blockCount}})</span>
                          </a>
                        </li>
                        <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getSuspendSeller(); SearchValue.value = '' ">Suspended
                            <span>({{suspendedCount}})</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 px-sm-3 px-0" id="masterSearch">
                  <div class=" input-group">
                    <input type="text" class="searchbar form-control newform-control" onpaste="return false" autocomplete="off" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                      placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                    <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="border">
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridApp($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridRej($event)" *ngIf="LoadPagination2 == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridBlk($event)" *ngIf="LoadPagination3 == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridSusp($event)" *ngIf="LoadPagination4 == true"></app-hds-main-tab-group>
                <ngx-loading [show]="isLoading"></ngx-loading>
                <div class="table-responsive" *ngIf="allSeller.length > 0">
                  <igx-grid #grid1 [data]="allSeller" width="100%" *ngIf="approved" height="" [autoGenerate]="false" [allowFiltering]="true"
                    displayDensity="cosy" [filterMode]="'excelStyleFilter'">
                    <igx-column field="seller_name" header="Seller Name" width="250">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="javascript:void(0)">
                            <span class="cell-vl text-capitalize" (click)="getDetails(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="business_name" header="Business Name" width="340">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="store_name" header="Ware House" width="320">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="mobile_no" header="Mobile Number" width="160">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="" [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <!-- <igx-column field="pan_no" header="Pan Number" width="320">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="gstin_no" header="GST Number" width="292">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column> -->
                    <igx-column field="" header="Action" width="150" *ngIf="approved">
                      <ng-template #compositeTemp igxCell let-cell="cell">

                        <!-- <div class="dropdown">
                          <button type="button" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-bars"></i>
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" data-toggle="modal" href="#" (click)="saveId(cell)" data-target="#toBlock">Block/Suspend</a>
                            <a class="dropdown-item" href="#" (click)="productListing(cell)">Product Listing</a>
                            <a class="dropdown-item" href="#" (click)="orderListing(cell)"> Order Listing</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" (click)="paymentListing(cell)"> Payment Listing</a>
                          </div>
                        </div> -->
                        <div class="px-2 address-container">
                          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                              aria-expanded="false">
                              <span class="py-2 px-3">Action</span>
                              <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                <i class=" fa fa-caret-down" aria-hidden="true"></i>
                              </button>
                            </button>

                            <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop1">
                              <li class="border-bottom">
                                <a href="javascript:void(0)" data-toggle="modal" (click)="saveId(cell)" data-target="#toBlock" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                  <i class="fa fa-ban" aria-hidden="true"></i> Block/Suspend</a>

                              </li>

                              <li class="border-bottom">
                                <a href="javascript:void(0)" (click)="productListing(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                                  <i class="fa fa-list" aria-hidden="true"></i>
                                  Product Listing </a>

                              </li>



                              <li class="border-bottom">
                                <a href="javascript:void(0)" (click)="paymentListing(cell)" class="text-decoration-none font-weight-bold text-info dropdown-item">
                                  <i class="fa fa-history" aria-hidden="true"></i>

                                  Payment History </a>

                              </li>
                              <li class="border-bottom">
                                <a href="javascript:void(0)" (click)="orderListing(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                  <i class="fa fa-first-order" aria-hidden="true"></i>
                                  Order History </a>
                              </li>
                              <!-- <li class="border-bottom">
                                <a href="javascript:void(0)" (click)="changeMobile(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                                  <i class="fa fa-mobile"></i>
                                  Change Mobile</a>
                              </li> -->
                              <li >
                                <a href="javascript:void(0)" (click)="changePassword(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                  <i class="fa fa-unlock" aria-hidden="true"></i>
                                  Change Password</a>
                              </li>
                            </ul>

                          </div>
                        </div>
                        <!-- <div class="address-container mr-2 my-2">
                      <button data-toggle="modal" class="btn btn-danger btn-sm mr-2" (click)="saveId(cell)" data-target="#toBlock"> Block/Suspend</button> -->
                        <!-- <i class="fa fa-ban"></i> -->
                        <!-- </div>

                    <div class="address-container mr-2 my-2">

                      <button (click)="productListing(cell)" class="btn btn-sm btn-secondary mr-2">
                        Product Listing -->
                        <!-- <i class="fa fa-archive"></i> -->
                        <!-- </button>

                    </div>
                    <div class="address-container mr-2 my-2">

                      <button (click)="orderListing(cell)" class="btn btn-sm btn-theme mr-2">
                        Orders History -->
                        <!-- <i class="fa fa-shopping-bag"></i> -->
                        <!-- </button>

                    </div>
                    <div class="address-container mr-2 my-2">

                      <button (click)="paymentListing(cell)" class="btn btn-sm btn-success">
                        Payment History -->
                        <!-- <i class="fa fa-credit-card-alt"></i> -->
                        <!-- </button>

                    </div> -->

                      </ng-template>
                    </igx-column>
                    <!-- <igx-column field="" header="Admin Action" width="150" *ngIf="approved">
                      <ng-template #compositeTemp igxCell let-cell="cell">                      
                        <div class="px-2 address-container">
                          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                              aria-expanded="false">
                              <span class="py-2 px-3">Action</span>
                              <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                <i class=" fa fa-caret-down" aria-hidden="true"></i>
                              </button>
                            </button>

                            <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop1"> 
                              <li >
                                <a href="javascript:void(0)" (click)="changeMobile(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                                  
                                  Change Mobile</a>
                              </li>
                              <li >
                                <a href="javascript:void(0)" (click)="changePassword(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                 
                                  Change Password</a>
                              </li>
                            </ul>

                          </div>
                        </div>
                      </ng-template>
                    </igx-column> -->

                  </igx-grid>
                </div>
                <div class="table-responsive" *ngIf="allSeller.length > 0">
                  <igx-grid #grid2 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [(perPage)]="page.size" *ngIf="rejected">
                    <!-- <igx-column field="seller_id" header="Seller ID" width="292">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <a href="/seller-profile" target="_blank">
                        <span (click)="getDetails(cell)">{{ cell.value }}
                        </span>
                      </a>
                    </div>
                  </ng-template>
                </igx-column> -->
                    <igx-column field="seller_name" header="Seller Name" width="260">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="javascript:void(0)">
                            <span class="cell-vl text-capitalize" (click)="getDetails(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="business_name" header="Business Name" width="340">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="store_name" header="Ware House" width="360">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="mobile_no" header="Mobile Number" width="160">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="" [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>

                  </igx-grid>
                </div>
                <div class="table-responsive" *ngIf="allSeller.length > 0">
                  <igx-grid #grid3 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [(perPage)]="page.size" *ngIf="blocked">
                    <igx-column field="seller_name" header="Seller Name" width="340">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="javascript:void(0)">
                            <span class="cell-vl text-capitalize" (click)="getDetails(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="business_name" header="Business Name" width="340">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="store_name" header="Ware House" width="340">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>

                    <igx-column field="mobile_no" header="Mobile Number" width="160">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="" [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="seller_status" header="Action" width="150">
                      <ng-template #compositeTemp igxCell let-cell="cell">

                        <div class="px-2 address-container">
                          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">

                            <div class="" role="group">
                              <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                                aria-expanded="false">
                                <span class="py-2 px-3">Action</span>
                                <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                  <i class=" fa fa-caret-down" aria-hidden="true"></i>
                                </button>
                              </button>
                              <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop2" appendTo="body">
                                <li class="border-bottom">
                                  <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal" (click)="saveId(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                    <i class="fa fa-unlock-alt" aria-hidden="true"></i> Unblock</a>

                                </li>

                                <li class="border-bottom" *ngIf="!blocked">
                                  <a href="javascript:void(0)" (click)="productListing(cell)" class="text-decoration-none font-weight-bold  text-primary dropdown-item">
                                    <i class="fa fa-list" aria-hidden="true"></i>
                                    Product Listing </a>

                                </li>



                                <li class="border-bottom" *ngIf="!blocked">
                                  <a href="javascript:void(0)" (click)="paymentListing(cell)" class="text-decoration-none font-weight-bold text-info dropdown-item">
                                    <i class="fa fa-history" aria-hidden="true"></i>

                                    Payment History </a>

                                </li>
                                <li *ngIf="!blocked">
                                  <a href="javascript:void(0)" (click)="orderListing(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                    <i class="fa fa-first-order" aria-hidden="true"></i>
                                    Order History </a>
                                </li>
                               
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </igx-column>
                  </igx-grid>
                </div>
                <div class="table-responsive" *ngIf="allSeller.length > 0">
                  <igx-grid #grid4 [data]="allSeller" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [(perPage)]="page.size" *ngIf="suspend">

                    <igx-column field="seller_name" header="Seller Name" width="260">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="javascript:void(0)">
                            <span class="cell-vl text-capitalize" (click)="getDetails(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="business_name" header="Business Name" width="360">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="store_name" header="Ware House" width="360">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="mobile_no" header="Mobile Number" width="160">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="" [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="seller_status" header="Action" width="150">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="px-2 address-container">
                          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                              aria-expanded="false">
                              <span class="py-2 px-3">Action</span>
                              <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                <i class=" fa fa-caret-down" aria-hidden="true"></i>
                              </button>
                            </button>

                            <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop2">
                              <li class="border-bottom">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#myModal" (click)="saveId(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                  <img src="../../../assets/images/icon/active.svg" alt="" class="mb-1"> Active</a>

                              </li>

                              <li class="border-bottom" *ngIf="!suspend">
                                <a href="javascript:void(0)" (click)="productListing(cell)" class="text-decoration-none font-weight-bold  text-primary dropdown-item">
                                  <i class="fa fa-list" aria-hidden="true"></i>
                                  Product Listing </a>

                              </li>



                              <li class="border-bottom" *ngIf="!suspend">
                                <a href="javascript:void(0)" (click)="paymentListing(cell)" class="text-decoration-none font-weight-bold text-info dropdown-item">
                                  <i class="fa fa-history" aria-hidden="true"></i>

                                  Payment History </a>

                              </li>
                              <li *ngIf="!suspend">
                                <a href="javascript:void(0)" (click)="orderListing(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                  <i class="fa fa-first-order" aria-hidden="true"></i>
                                  Order History </a>

                              </li>
                            </ul>


                          </div>
                        </div>


                      </ng-template>
                    </igx-column>
                  </igx-grid>
                </div>
                <label class="w-100 text-center text-dark" *ngIf="!isLoading && allSeller.length == 0">No Record Found</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="myModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header p-2">
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <label>Remarks
                    <!-- <span class="asterisk">*</span> -->
                  </label>
                </div>
                <div class="col-sm-12 ">
                  <textarea name="remarks" id="remarks" [(ngModel)]="adminRemarks" placeholder="Enter remarks here...." class="form-control2"
                    maxlength="500"></textarea>
                </div>
              </div>
            </div>
            <div class="pt-2 text-center ">

              <div class="unBlocked" *ngIf="suspended == 183">
                <!-- <label for="unblocked " class="m-3">Unblock</label> -->
                <!-- <input type="checkbox" #unb required name="unblocked" id="unblocked" ngModel> -->
                <button class="btn btn-sm text-white bg-success  px-4 mr-2" (click)="reAcitve(181)">Unblock</button>
                <button class="btn btn-sm text-white bg-danger px-4" data-dismiss="modal">Close</button>
              </div>
              <div class="unsuspend" *ngIf="suspended == 184">
                <button class="btn btn-sm text-white bg-success  px-4 mr-2" (click)="reAcitve(181)">Active</button>
                <button class="btn btn-sm text-white bg-danger px-4" data-dismiss="modal">Close</button>
                <!-- <label for="unsuspend" class="m-3">Active</label>
                  <input type="checkbox" #sus required name="unsuspend" id="unsuspend" ngModel> -->
              </div>

            </div>

            <!-- <div class="modal-footer p-2">
              <button class="btn btn-sm btn-theme bg-primary border-primary mr-2" [disabled]="!myForm.valid" (click)="reAcitve(181)">Submit</button>
              <button class="btn btn-sm btn-danger" data-dismiss="modal">Close</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal" id="toBlock" >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="col-sm-12 col-lg-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <label>Remarks</label>
                      </div>
                      <div class="col-sm-12">
                        <textarea name="remarks" id="remarks" [(ngModel)]="adminRemarks"  class="form-control2" maxlength="500"></textarea>
                    </div>
                    </div>
                  </div>
                  <div class="action">
                      <div class="block form-check d-flex">
                        <div>
                          <label for="block " class="mr-3">Block</label>
                          <input type="radio" class="  mr-2" value="183"  [(ngModel)]="blk_suspend" id="block" name="block">
                        </div>
                        <div>
                          <label for="susp" class="mr-3">Suspend</label>
                          <input type="radio" class="mr-2 " value="184" [(ngModel)]="blk_suspend"  id="susp" name="block">
                        </div>
                     
                      </div>
                     
                  </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" [disabled]="blk_suspend == ''" (click)="reAcitve(blk_suspend)">Submit</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div> -->
    <div class="modal fade" id="toBlock">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header p-2 align-items-center">
            <h6 class="modal-title font9 font600">Action</h6>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2">
            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <label>Remarks
                    <!-- <span class="asterisk">*</span> -->
                  </label>
                </div>
                <div class="col-sm-12 mb-2">
                  <textarea name="remarks" id="remarks" placeholder="Enter remarks here...." [(ngModel)]="adminRemarks" class="form-control2"
                    maxlength="500"></textarea>
                </div>
              </div>
            </div>
            <div class="action">
              <div class="block form-check d-flex pl-3">
                <div class="row m-0 pb-2">
                  <div class="form-check pl-0">
                    <input type="radio" class="form-check-input m-0" value="183" [(ngModel)]="blk_suspend" id="block" name="block">
                    <label for="block" class="mx-2">Block</label>
                  </div>
                  <div class="form-check pl-2">
                    <input type="radio" class="form-check-input m-0" value="184" [(ngModel)]="blk_suspend" id="susp" name="block">
                    <label for="susp" class="mx-2">Suspend</label>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="text-center  pb-3">
            <button type="button" class="btn btn-sm text-white bg-success  px-4 mr-2" [disabled]="blk_suspend == ''" (click)="reAcitve(blk_suspend)">Submit</button>
            <button type="button" class="btn btn-sm text-white bg-danger px-4" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="convertModal" class="modal fade p-0" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header align-items-center py-2">
        <h6 class="font600 mb-0">Change Mobile</h6>
        <button type="button" class="close" data-dismiss="modal" (click)="reset()">
          <span> &times; </span>
        </button>
      </div>
      <div class="modal-body p-0">
      
  
            <div class="col-md-12 p-4">
              <label for="" class="font8">Mobile Number 
                <span class="asterisk">*</span>
              </label>
              <input type="text"  class="form-control mb-2" autocomplete="off" placeholder=""
              [(ngModel)]="mobile_no" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10"  >
            </div>
       
      </div>
      <div class="bottom-button-group text-center pb-3">
        <button type="button" class="btn btn-sm px-3 btn-success mr-1" (click)="onSubmitMobile()">Submit</button>
        <button class="btn btn-sm btn-danger px-3" data-dismiss="modal" (click)="reset()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div id="updatePassword" class="modal fade p-0" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header align-items-center py-2">
        <h6 class="font600 mb-0">Change Password</h6>
        <button type="button" class="close" data-dismiss="modal" (click)="resetP()">
          <span> &times; </span>
        </button>
      </div>
      <div class="col-sm-12 text-start p-4">
        <div class="form-group mb-2 position-relative">
        <label for="new_pass">New password<span class="text-danger"> *</span></label>
        <input type="password" class="form-control" id="newPass" placeholder=""   maxlength="30"
        
         name="newPass" ondrop="return false;"ondrag="return false;" [(ngModel)]="newPass"  autocomplete="off"[type]=" showPassword ? 'text' : 'password' " maxlength="16">
         <span class="lock_sh" (click)='showHidePassword()'
             *ngIf="showPassword==true"><i class="fa fa-eye-slash fa-w-20"></i>
            </span>
            <span class="lock_sh" (click)='showHidePassword()'
                *ngIf="showPassword==false">
                <i class="fa fa-eye fa-w-20"></i>
            </span>    
                                  
       </div>
       <div class="form-group mb-2 position-relative">
        <label for="cnfrmPass">Confirm password<span class="text-danger"> *</span></label>
        <input type="password" class="form-control" id="cnfrmPass" placeholder="" [type]=" showPassword1 ? 'text' : 'password' "    maxlength="30"
         name="cnfrmPass" ondrop="return false;"ondrag="return false;" [(ngModel)]="cnfrmPass"  autocomplete="off">
         <span class="lock_sh" (click)='showHidePassword1()'
         *ngIf="showPassword1==true"><i class="fa fa-eye-slash fa-w-20"></i>
        </span>
        <span class="lock_sh" (click)='showHidePassword1()'
            *ngIf="showPassword1==false">
            <i class="fa fa-eye fa-w-20"></i>
        </span>    
       </div>
      </div>
      <div class="bottom-button-group text-center pb-3">
        <button type="button" class="btn btn-sm px-3 btn-success mr-1" (click)="updatePassword()">Submit</button>
        <button class="btn btn-sm btn-danger px-3" data-dismiss="modal" (click)="resetP()">Cancel</button>
      </div>
    </div>
  </div>
</div>

 