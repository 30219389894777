import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Page } from '../../_models/page';
import { Router } from '@angular/router';
import { ProductService } from '../../_services/product.service';
import Swal from 'sweetalert2';
import { RowComp, Promise } from 'ag-grid-community';
declare var $: any;

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.css']
})
export class QuickOrderComponent implements OnInit {
  isLoading: boolean = true;
  isShow: boolean = true;
  searchTerm: any;
  isloadgridTrue: any = false;
  token: string;
  isEdit:any = false;
  categoryList: Object;
  subCategoryList: any;
  allProductList: any;
  category: any;
  selectedProdcut: any;
  productdetail: any;
  priceListproductwise:any;
  sub_category: any;
  userId: string;

  constructor(private ProductService: ProductService, private toastrService: ToastrService,private router: Router) { 
    
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userId = localStorage.getItem("userId");  
    this.getAllCat()  
    
  }

  getAllCat() {
    this.ProductService.getCategory().subscribe(res => {
      this.categoryList = res;
    })
  }
  getMRPPrice(){
    
    this.productdetail = this.allProductList.filter(item => item.product_id == this.selectedProdcut)
    console.log('producdetal', this.productdetail)

  }

  getSubCategory() {
    this.ProductService.getSubCategory(this.category).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.subCategoryList = res;
      } else {
        this.subCategoryList = [];
      }
    })
  }
  getProducts(){
    this.ProductService.getProductForQuickOrder(this.sub_category, this.category).subscribe(res =>{
      console.log('res============',res)
      this.allProductList = res[0].rows;
      this.priceListproductwise = res[1].rows;
      let tempObj;
      for(let obj of this.allProductList){
        if(obj.product_type==198){
          tempObj=(res[1].rows.filter(x=>x.product_id==obj.product_id))[0];
          obj.price=tempObj.price;
          obj.mrp = tempObj.mrp
          obj.purchase_price = tempObj.purchase_price
          obj.suk = tempObj.sku
        }
        if(obj.product_type==208){
          tempObj=(res[1].rows.filter(x=>x.product_id==obj.product_id))[0];
          obj.price=tempObj.price;
          obj.mrp = tempObj.mrp
          obj.purchase_price = tempObj.purchase_price
          obj.suk = tempObj.sku
        }
      }
       //console.log('check for price', this.allProductList)
    })
  }

}
