<!-- <div class="d-flex minhyt80" id="wrapper">
    <div class="" id="sidebar-wrapper" *ngIf="!isView">
      <app-left-menu></app-left-menu>
    </div>
    <div class="" id="page-content-wrapper" >
        <app-header *ngIf="!isView"></app-header> -->

<section class="px-2 py-3 product_discription_box">
    <div class="">
        <div class="col-sm-12">
            <div class="row">
                <div id="leftSideContainer" class="col-sm-12 col-md-4 col-lg-4">
                    <div class="show prod-img" *ngIf="uploadedGalaryFiles.length > 0">
                        <!-- src="../../..{{ changedImage }}" -->
                        <img src="../../..{{ changedImage }}" id="show-img" alt="product image-upload" class="img-fluid mb-2" />
                    </div>
                    <!-- <div class="d-flex dd-img" >
                        <img  src="../..{{img.file_path}}" alt="not found" (click)="changeImage(img)" class="img-wdth" *ngFor="let img of uploadedGalaryFiles">
                    </div> -->
                    <div id="carouselExample" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner row w-100 mx-auto" role="listbox">
                            <div class="carousel-item col-md-3 " *ngFor="let img of uploadedGalaryFiles; index as i" [ngClass]="{active: i==0}">
                                <div class="panel panel-default">
                                    <div class="panel-thumbnail">
                                        <a href="javascript:void(0)" class="thumb">
                                            <img class="img-fluid mx-auto d-block" src="../..{{img.file_path}}" (click)="changeImage(img)" alt="slide {{i+1}}">
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <a class="carousel-control-next " href="#carouselExample" role="button" data-slide="next">
                            <span class="carousel-control-next-icon carousel-icons" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                        <a class="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon carousel-icons" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>

                    </div>


                    <!-- <p class="text-center py-1"><i class="fa fa-search-plus"></i> Hover to Zoom</p> -->
                </div>
                <div id="rightSideContainer" class="col-sm-12 col-md-8 col-lg-8">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="mb-1 text-capitalize">{{ product_name }}</h4>

                            <div class="prod_review py-2" *ngIf="!isView">
                                <span class="ratingrate">4.5
                                    <i class="fas fa-star"></i>
                                </span>
                                <span class="text-green me-2">IN STOCK {{stock_status}}</span>
                                <span class="font8 text-gray">41 Reviews</span>
                            </div>
                            <div class="recent-bought-wrap" *ngIf="!isView">
                                <img src="../../assets/images/coupon-3-fill.png" class="img-fluid" />
                                <span class="inner-text">580 people bought this recently</span>
                            </div>


                            <div class="prod_price_block o-hidden mb-2" *ngIf="mrp">
                                <span class="fontbig font-weight-bolder">₹{{ price }}</span>
                                <span class="  text-uppercase text-muted">
                                    mrp
                                </span>
                                <span class="text-uppercase text-muted" *ngIf="mrp && discountPer == 0">{{mrp}}</span>
                                <span class="text-muted deleted " *ngIf="mrp && discountPer > 0">₹{{ mrp }}&nbsp;
                                </span>
                                <span class="text-green text-uppercase  ps-1 font-weight-bolder" *ngIf="discountPer > 0">&nbsp;{{ discountPer == "-Infinity" ? 0 : discountPer }}% off</span>
                            </div>
                            <div class="clearfix"></div>
                            <div class="tablecombz-quantity-wanted d-flex align-items-center pt-3 d-none" *ngIf="!isView">
                                <span class="font9 font-weight-bolder">Update Qty : </span>
                                <div class="quantity_wanted_input inline-block ms-1 ">
                                    <div class="input-group">
                                        <span class="input-group-btn d-flex">
                                            <button type="button" class="btn btn-default btn-number btn-sm" disabled="disabled" data-type="minus" data-field="quant[1]">
                                                <span class="fas fa-minus"></span>
                                            </button>
                                        </span>
                                        <input type="text" maxlength="3" name="quant[1]" class="form-control input-number wid70p text-center" value="0" min="0" max="100"
                                        />
                                        <span class="input-group-btn d-flex">
                                            <button type="button" class="btn btn-default btn-number btn-sm" data-type="plus" data-field="quant[1]">
                                                <span class="fas fa-plus"></span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div class="ps-2">
                                    <p class="m-0 font7 text-gray">Qty Per Pack : 1 Piece</p>
                                    <div class="clearfix"></div>
                                    <p class="m-0 font7 text-gray">Min Orderable Qty : 1 Pack</p>
                                </div>
                            </div>
                            <table class="table table-bordered my-2 product-table-padding d-none">
                                <tbody>
                                    <tr>
                                        <td class="text-end">Quantity</td>
                                        <td class="text-center">48-95</td>
                                        <td class="text-center">96-143</td>
                                        <td class="text-center">144-191</td>
                                        <td class="text-center">191-239</td>
                                        <td class="text-center">239+</td>
                                    </tr>
                                    <tr>
                                        <td class="text-end">Price per item</td>
                                        <td class="text-center">
                                            <i class="fas fa-rupee-sign fw-normal">&nbsp;</i>799
                                        </td>
                                        <td class="text-center">
                                            <i class="fas fa-rupee-sign">&nbsp;</i>760
                                        </td>
                                        <td class="text-center">
                                            <i class="fa fa-rupee-sign">&nbsp;</i>750
                                        </td>
                                        <td class="text-center">
                                            <i class="fa fa-rupee-sign">&nbsp;</i>740
                                        </td>
                                        <td class="text-center">
                                            <i class="fa fa-rupee-sign">&nbsp;</i>720
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="row mx-0 my-3" *ngIf="productType == 198 || productType==208 && productColor.length > 0">
                                <div class="col-sm-12 bbpl0" >
                                    <div class="input-group align-items-center ">
                                        <span class="inline-block font8 text-gray me-2-bt5">
                                            First Attribute
                                        </span>

                                        <a href="javascript:void(0)" class="sizeofcolor" *ngFor="let col of productColor" (click)="onColorSelection(col.attribute_value1)"
                                            [ngClass]="{'active': col.attribute_value1 == selected_color}" [ngStyle]="{'background-color' : col.attribute_value1 =='Wine' ? '#990012' : col.attribute_value1 ? col.attribute_value1 : null}">

                                        </a>
                                        <!-- <a href="javascript:void(0)" class="sizeofcolor infoffect">
                                    </a>
                                    <a href="javascript:void(0)" class="sizeofcolor greenffect">
                                    </a>
                                    <a href="javascript:void(0)" class="sizeofcolor orangeffect">
                                    </a>
                                    <a href="javascript:void(0)" class="sizeofcolor blackffect">
                                    </a>
                                    <a href="javascript:void(0)" class="sizeofcolor text-dark addicon">
                                        <i class="fas fa-plus"></i>
                                    </a> -->
                                    </div>
                                </div>
                            </div>
                       
                            <ul class="mx-0 mb-3 p-0 product-size-config" *ngIf="productType == 198 || productType==208 && productSize.length > 0">
                                <span class="inline-block font8 text-gray me-2-bt5" *ngIf="productSize.length > 0">
                                    Second Attribute
                                </span>
                                <li class="" *ngFor="let size of productSize" (click)="onSizeSelection(size.attribute_value2)" [ngClass]="{'active': size.attribute_value2 == selected_size}">{{size.attribute_value2}}</li>
                            </ul>
                        
                            <span class="d-none">*Minimum order quantity: 48</span>
                            <div class="clearfix"></div>
                            <div class="col-sm-12-col-lg-12 col-md-12 py-4 d-none ">
                                <div class="card shadow-none" style="background: #f9f9f9">
                                    <div class="card-body">
                                        <h5>YOUR BRANDING: HOW THIS WORKS</h5>

                                        <form id="msform" class="py-3">
                                            <ul id="progressbar">
                                                <li class="active" id="account">
                                                    <span class="fas icon_reg fa-cloud-upload-alt"></span>
                                                    <span>Upload</span>
                                                </li>
                                                <li id="personal">
                                                    <span class="icon_reg fas fa-shopping-cart">
                                                        &nbsp;
                                                    </span>
                                                    <span>Place Order</span>
                                                </li>
                                                <li id="payment">
                                                    <span class="icon_reg fas fa-pencil-alt"> </span>
                                                    <span>We Review</span>
                                                </li>
                                                <li id="confirm">
                                                    <span class="icon_reg fas fa-pencil-alt"> </span>
                                                    <span>Your Review </span>
                                                </li>
                                                <li id="confirm">
                                                    <span class="icon_reg fas fa-cogs"> </span>
                                                    <span>Production </span>
                                                </li>
                                            </ul>
                                            <br />
                                            <fieldset class="px-3">
                                                <div class="col-sm-12 col-md-12 col-lg-12 text-start">
                                                    <div class="align-items-center">
                                                        <span class="font18 me-2">Select Branding Area:</span>
                                                        <input type="checkbox" class="ccheckbox-wid20" />
                                                        <span class="font18 mx-2">Front</span>
                                                        <input type="checkbox" class="ccheckbox-wid20" />
                                                        <span class="font18 mx-2">Back</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-lg-12 col-md-12 my-3">
                                                    <div class="row">
                                                        <div class="col-sm-6 col-md-6 col-lg-6 mb-2">
                                                            <div class="card shadow-none bg-light-gray">
                                                                <div class="card-header bg-none">
                                                                    <h6 class="text-center mb-0">Front</h6>
                                                                </div>
                                                                <div class="card-body">
                                                                    <div class="image-upload">
                                                                        <label for="file-input" style="font-size: 45px">
                                                                            <i class="fas fa-2s fa-cloud-upload-alt"></i>
                                                                        </label>
                                                                        <p class="text-center font8">
                                                                            Drag and drop or click to upload
                                                                        </p>
                                                                        <input id="file-input" type="file" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6 col-lg-6 mb-2">
                                                            <div class="card shadow-none bg-light-gray disablebtn" aria-disabled="true">
                                                                <div class="card-header bg-none">
                                                                    <h6 class="text-center mb-0">Back</h6>
                                                                </div>
                                                                <div class="card-body">
                                                                    <div class="image-upload">
                                                                        <label for="file-input1" style="font-size: 45px">
                                                                            <i class="fas fa-2s fa-cloud-upload-alt"></i>
                                                                        </label>
                                                                        <p class="text-center font8">
                                                                            Drag and drop or click to upload
                                                                        </p>
                                                                        <input id="file-input1" type="file" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <input type="button" name="next" class="next action-button" value="Next" /> -->
                                            </fieldset>
                                            <!-- <fieldset>
                                            <div class="form-card">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <h5 class="fs-title">Place Order</h5>
                                                    </div>
                                                    <div class="col-5">
            
                                                    </div>
                                                </div>
            
                                                
            
                                            </div>
            
                                            <input type="button" name="next" class="next action-button" value="Next" />
                                            <input type="button" name="previous" class="previous action-button-previous"
                                                value="Previous" />
                                        </fieldset>
                                        <fieldset>
                                            <div class="form-card">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <h5 class="fs-title">We Review:</h5>
                                                    </div>
                                                    <div class="col-5">
            
                                                    </div>
                                                </div>
            
                                                
            
                                            </div>
            
                                            <input type="button" name="next" class="next action-button" value="Next" />
                                            <input type="button" name="previous" class="previous action-button-previous"
                                                value="Previous" />
                                        </fieldset>
                                        <fieldset>
                                            <div class="form-card">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <h5 class="fs-title">Your Review:</h5>
                                                    </div>
                                                    <div class="col-5">
            
                                                    </div>
                                                </div>
            
                                                
            
                                            </div>
            
                                            <input type="button" name="next" class="next action-button" value="Next" />
                                            <input type="button" name="previous" class="previous action-button-previous"
                                                value="Previous" />
                                        </fieldset>
                                        <fieldset>
                                            <div class="form-card">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <h5 class="fs-title">Production:</h5>
                                                    </div>
                                                    <div class="col-5">
            
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <input type="button" name="next"  class="next action-button"
                                                value="Submit" />
                                            <input type="button" name="previous" class="previous action-button-previous"
                                                value="Previous" />
            
                                        </fieldset> -->
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 py-3" *ngIf="!isView">
                                <div class="button-group d-flex">
                                    <button href="javascript:void(0)" disabled class="btn btn-gray me-2 w-50">
                                        Save for later
                                    </button>
                                    <button href="javascript:void(0)" disabled class="btn btn-theme w-50">
                                        Add to Cart
                                    </button>
                                </div>
                            </div>
                            <div class="clearfix" *ngIf="productType == 208 ">
                              
                                <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                    Art-work Area
                                </h6>
                                <ul class="pl_24px">
                                    <li class="font8" *ngFor="let key of artwork">{{ key.artlabel }}</li>
                                </ul>

                                <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                    Extra Features
                                </h6>

                                <div class="table-responsive">
                                    <table class="table table-bordered cstmborder" *ngIf="features.length > 0">
                                        <tbody>
                                            <tr>
                                                <th>Option</th>
                                                <th>Price</th>
                                            </tr>

                                            <tr >

                                                <td>{{features}}</td>
                                                <td>₹ {{fPrice}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                            <div *ngIf="productType == 198 || productType == 208 || productType == 197">
                                <div class="quantity-item mb-3" >
                                    <div class="quantity-item mb-3" *ngIf="bundledPriceList.length > 0">
                                        <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                            Bundled Details
                                        </h6>
                                        <div class="row align-items-center">
                                            <div class="col-auto pr-2">
                                                <h6 class="font8 font-weight-bold d-inline-block">
                                                    <i> Quantity</i>
                                                </h6>
                                                <h6 class="font8 font-weight-bold mb-0">
                                                    <i> Price per item</i>
                                                </h6>
                                            </div>
                                            <div class="col-auto px-2" *ngFor="let bun of bundledPriceList; index as i">
                                                <h6 class="font8 price-bor d-inline-block"> {{bun.min_qty}} - {{bun.max_qty ? bun.max_qty : 'Above'}}</h6>
                                                <h6 class="font8 font-weight-bold mb-0">
                                                    ₹ {{bun.price}}
                                                </h6>
                                            </div>
    
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                            <!-- <div class="clearfix" *ngIf="productType == 198 || productType == 208">
                               
                                <h5 class="text-uppercase">Bundled Details</h5>
                                <ul >
                                    <li> Min Quantity - {{min_qty}}</li>
                                    <li> Max Quantity - {{max_qty}}</li>
                                    <li> Price - {{bundledPrice}}</li>
                                </ul>

                            </div> -->
                            <div class="clearfix" *ngIf="productType == 209">

                                <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                    Extra Features
                                </h6>

                                <div class="table-responsive">
                                    <table class="table table-bordered cstmborder" *ngIf="features.length > 0">
                                        <tbody>
                                            <tr>
                                                <th>Feature</th>
                                                <th>Price</th>
                                            </tr>

                                            <tr >

                                                <td>{{features}}</td>
                                                <td>₹ {{fPrice}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="clearfix" *ngIf="productType == 209">
                                <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                    Card Details
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered cstmborder" *ngIf="card_details.length > 0">
                                        <tbody>
                                            <tr>
                                                <th>Label</th>
                                                <th>Value</th>
                                            </tr>

                                            <tr *ngFor="let key of card_details let i=index">

                                                <td>{{key.lable}}</td>
                                                <td>{{key.value}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                            <div class="clearfix" *ngIf="productType == 209">
                                <p class="fon16">
                                    <span class="font-weight-bold">Set Quantity : </span> {{ set_quantity }}
                                </p>
                            </div>


                            <div class="clearfix" *ngIf="productType == 208 || productType == 198 || productType == 197 || productType == 199">
                                <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                    PRODUCT SPECIFICATIONS
                                </h6>
                                <div class="table-responsive">
                                    <table class="table cstmborder" *ngIf="productSpecifications.length > 0">
                                        <tbody class="table-bordered">
                                            <tr>
                                                <th>Label</th>
                                                <th>Value</th>
                                            </tr>

                                            <tr *ngFor="let obj of productSpecifications let i=index">

                                                <td>{{obj.specification_label_name}}</td>
                                                <td>{{obj.value_name}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="" *ngIf="productType == 208 || productType == 198 || productType == 197 || productType == 199">
                                <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                    KEY FEATURES
                                </h6>
                                <ul class="pl_24px" *ngIf=" keyFeatures.length > 0">
                                    <li class="font8" *ngFor="let key of keyFeatures">{{ key.key }}</li>
                                </ul>
                            </div>
                            <!-- <div class="">
                                <h5 class="text-uppercase">Card Details</h5>
                                <table class="table table-bordered mt-4 cstmborder"
                                    *ngIf="card_details.length > 0">
                                    <tbody>
                                        <tr>
                                            <th>Label</th>
                                            <th>Value</th>
                                        </tr>

                                        <tr *ngFor="let obj of card_details let i=index">

                                            <td >{{obj.lable}}</td>
                                            <td>{{obj.value}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                            <div class="d-none">
                                <p class=" fnt16 font-weight-bold mb-2"> RETURN POLICY</p>
                                <div class="col-sm-12 col-lg-12 py-3 bg-light">
                                    <div class="row">
                                        <div class="col-6 col-md-3">
                                            <p class="d-flex align-items-center text-decoration-none m-0">
                                                <span class="returnableicon">
                                                    <img src="../../../assets/images/undo-button.svg" class="" />
                                                </span>
                                                <span class="font8 text-dark">
                                                    Upto 7 days
                                                    <br /> returnable
                                                </span>
                                            </p>
                                        </div>

                                        <div class="col-6 col-md-3">
                                            <p class="d-flex align-items-center text-decoration-none m-0">
                                                <span class="returnableicon">
                                                    <img src="../../../assets/images/box.svg" class="" />
                                                </span>
                                                <span class="font8 text-dark">
                                                    Wrong
                                                    <br /> Product
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-6 col-md-3">
                                            <p class="d-flex align-items-center text-decoration-none m-0">
                                                <span class="returnableicon">
                                                    <img src="../../../assets/images/fragile.svg" class="" />
                                                </span>
                                                <span class="font8 text-dark">
                                                    Damaged
                                                    <br /> Product
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-6 col-md-3">
                                            <p class="d-flex align-items-center text-decoration-none m-0">
                                                <span class="returnableicon">
                                                    <img src="../../../assets/images/undo-button.svg" class="" />
                                                </span>
                                                <span class="font8 text-dark">
                                                    Defective
                                                    <br /> Product
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-5"></div>

                        <div class="clearfix"></div>
                        <div class="col-sm-12 col-lg-12">
                            <h6 class="fnt16 font-weight-bold mb-2 text-uppercase">
                                PRODUCT INFORMATION
                            </h6>
                            <p class="productinfo font8" [innerHTML]="product_discription"> </p>
                        </div>

                        <div class="qcstatus">
                            <!-- <button class="btn btn-sm btn-theme mr-2">Approve</button>
                            <button class="btn btn-sm btn-gray">Reject</button> -->
                        </div>
                        <div class="card shadow-none py-2 px-3" *ngIf="productType==199">
                            <h5 class="pt-2"> Buy group product and save upto 10% </h5>
                            <div class="row pds">
                                <div class="col-sm-6 col-6 col-md-6 col-lg-3 my-2 col-plus">
                                    <div class="card shadow-none">
                                        <div class="card-body ">
                                            <a href="javascript:void(0)" class="pl_img" [routerLink]="['/product-details']">
                                                <img src="../..{{uploadedGalaryFiles[0]?.file_path}}" class="img-fluid">
                                            </a>

                                            <div class="text-center pt-2">
                                                <h6>{{product_name}}</h6>

                                                <!-- <div class="text-center">
                                                    <a href="javascript:void(0)" class="bg-danger colorico"></a>
                                                    <a href="javascript:void(0)" class="bg-primary colorico"></a>
                                                    <a href="javascript:void(0)" class="bg-success colorico"></a>
                                                    <a href="javascript:void(0)" class="bg-warning colorico"></a>
                                                    <a href="javascript:void(0)" class="colorico text-dark plusicon">
                                                        <i class="fas fa-plus"></i>
                                                    </a>
                            
                                                </div> -->
                                                <span class="font600">
                                                    <i class="fa fa-rupee-sign">&nbsp;</i>{{price}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="fa fa-plus pls-ic"></i>
                                </div>
                                <div class="col-sm-6 col-6 col-md-6 col-lg-3 my-2 col-plus" *ngFor="let pro of groupProdDetail">
                                    <div class="card shadow-none">
                                        <div class="card-body " (click)="viewProducts(pro)">
                                            <a href="javascript:void(0)" class="pl_img">
                                                <img src="{{prodImage}}" class="img-fluid">
                                            </a>

                                            <div class="text-center pt-3">
                                                <h6>{{pro.product_name}}</h6>

                                                <!-- <div class="text-center">
                                                    <a href="javascript:void(0)" class="bg-danger colorico"></a>
                                                    <a href="javascript:void(0)" class="bg-primary colorico"></a>
                                                    <a href="javascript:void(0)" class="bg-success colorico"></a>
                                                    <a href="javascript:void(0)" class="bg-warning colorico"></a>
                                                    <a href="javascript:void(0)" class="colorico text-dark plusicon">
                                                        <i class="fas fa-plus"></i>
                                                    </a>
                            
                                                </div> -->
                                                <span class="font600">
                                                    <i class="fas fa-rupee-sign">&nbsp;</i>{{pro.price}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="frm">

                                        <div class="form-check">
                                            <input class="form-check-input" [checked]="setState" (click)="getCheckedProduct($event,pro.product_id,pro.price)" type="checkbox"
                                                value="" id="flexCheckDefault">
                                        </div>
                                    </div>
                                    <i class="fa fa-plus pls-ic"></i>
                                </div>

                            </div>

                            <div class="row my-3">
                                <div class="col-md-7 col-12 mb-2 productdsort">
                                    <div class="row">
                                        <div class="col-lg-3 ps-rl col-3 m-0">
                                            <span class="text-secondary">
                                                <small> Item </small>
                                            </span>
                                            <h6>₹{{price}}</h6>
                                            <strong class="itm-ic">+</strong>
                                            <!-- <i class="fa itm-ic fa-plus"></i> -->
                                        </div>

                                        <div class="col-lg-3 ps-rl col-3 p-0 m-0">
                                            <span class="text-secondary">
                                                <small>{{groupedItem +" "}}Item </small>
                                            </span>
                                            <h6>₹ {{tPrice}} </h6>
                                            <!-- <i class="fa itm-ic fa-equals"></i> -->
                                            <strong class="itm-ic">=</strong>

                                        </div>
                                        <div class="col-lg-3 ps-rl col-3 p-0 m-0">
                                            <span class="text-secondary">
                                                <small>Total</small>
                                            </span>
                                            <h6>₹ {{total}} </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-12 text-end text-sm-start">
                                    <button class="btn btn-success py-2" disabled>
                                        <i class="fa fa-cart-plus"></i> ADD {{groupedItem + 1}} Items to cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- </div> -->