import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gift-code',
  templateUrl: './gift-code.component.html',
  styleUrls: ['./gift-code.component.css']
})
export class GiftCodeComponent implements OnInit {

  constructor() { }
  allSeller:any;
  ngOnInit(): void {
  }

}
