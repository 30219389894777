import { Component, OnInit,ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { FormService } from '../../_services/form.service';
import { LoginService } from '../../_services/login.service'
import { Router } from '@angular/router';
import { SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { IgxGridComponent,DisplayDensity,IgxCsvExporterService,IgxExcelExporterService, IgxGridRowComponent, Transaction , IgxStringFilteringOperand,ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy,GridPagingMode,  IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { Subscription } from 'rxjs';
import { Page } from '../../_models/page';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
declare var $:any;
@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.css']
})
export class DynamicDashboardComponent implements OnInit {
  workbench:any;
  @ViewChild("grid1", { read: IgxGridComponent, static: true })
  public grid1: IgxGridComponent;
  selectedAndVisibleRows = []; 
  visibleRows; 

  subscription: Subscription;
  labelObj: any;
  isHindi: Boolean;
  isListCalendarStatus: Boolean = false
  
  public isLoading = true;
  public totalCount = 0;
  //public mode = GridPagingMode.remote;

  //====companyData============
  companyData: any[] = [];
  orderCompanyData: any[] = [];
  columnDefsCompSummary : any [] = [];
  orderColumnDefsCompSummary : any [] = [];
  submitColumnDefsCompSummary : any [] = [];
  verifiedCompanyColumnDefSummary : any [] = [];
  verifiedCompanyCount = 0;
  verifiedcompanyData: any [] = [];
  newCompanyCount = 0;
  newOderCount = 0;
  isCompanyList :any = 1;

  constructor(
    private formService: FormService,
    private loginService: LoginService,
    private router: Router,    private csvExportService: IgxCsvExporterService,
    private excelExportService: IgxExcelExporterService,
  ) { }
 
 


 
  ngOnInit(): void {
    $("#sidebar-wrapper").show();
    $("#logoemblm").removeClass('nodisplay');    
    $(".page-footer").removeClass("footrr");
   this. getCompanyData()
   this.getNewOrderCount();
   this.getVerifiedCompany();
  }

  getCompanyData(){
    this.companyData = [];
    let strQuery = `select email_id,e_name,mobile_no,father_name,present_address,permanent_address,govt_id from z_entitydtl_employee_001 where (emp_status IS NULL or emp_status = 'pending') and createdby=${localStorage.getItem("profileId")}`;
    this.formService.getFormData(strQuery).subscribe(res=>{
      this.columnDefsCompSummary.push({ field: 'email_id', headerName: 'Email', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.columnDefsCompSummary.push({ field: 'e_name', headerName: 'Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.columnDefsCompSummary.push({ field: 'mobile_no', headerName: 'Mobile Number', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.columnDefsCompSummary.push({ field: 'father_name', headerName: 'Father Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.columnDefsCompSummary.push({ field: 'present_address', headerName: 'Present Address', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });
      this.columnDefsCompSummary.push({ field: 'permanent_address', headerName: 'Permanent Address', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });
      this.columnDefsCompSummary.push({ field: 'govt_id', headerName: 'Govt Id', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });         
      this.companyData = res.rows;
      this.newCompanyCount=res.rows.length;
    })
  }
  getNewOrderCount(){
    this.orderCompanyData = [];
    let strQuery = `select email_id,e_name,mobile_no,father_name,present_address,permanent_address,govt_id from z_entitydtl_employee_001 where  emp_status='submitted' and createdby=${localStorage.getItem("profileId")}`;
    this.formService.getFormData(strQuery).subscribe(res=>{
      this.submitColumnDefsCompSummary.push({ field: 'email_id', headerName: 'Email', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.submitColumnDefsCompSummary.push({ field: 'e_name', headerName: 'Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.submitColumnDefsCompSummary.push({ field: 'mobile_no', headerName: 'Mobile Number', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.submitColumnDefsCompSummary.push({ field: 'father_name', headerName: 'Father Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.submitColumnDefsCompSummary.push({ field: 'present_address', headerName: 'Present Address', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });
      this.submitColumnDefsCompSummary.push({ field: 'permanent_address', headerName: 'Permanent Address', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });
      this.submitColumnDefsCompSummary.push({ field: 'govt_id', headerName: 'Govt Id', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });     
      this.orderCompanyData = res.rows;
      this.newOderCount=res.rows.length;
    })
  }

  getVerifiedCompany(){
    this.verifiedcompanyData = [];
    let strQuery = `select employee_id, email_id,e_name,mobile_no,father_name,present_address,permanent_address,govt_id from z_entitydtl_employee_001 where  emp_status = 'verified' and createdby=${localStorage.getItem("profileId")}`;
    this.formService.getFormData(strQuery).subscribe(res=>{
      this.verifiedCompanyColumnDefSummary.push({ field: 'email_id', headerName: 'Email', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.verifiedCompanyColumnDefSummary.push({ field: 'e_name', headerName: 'Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.verifiedCompanyColumnDefSummary.push({ field: 'mobile_no', headerName: 'Mobile Number', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.verifiedCompanyColumnDefSummary.push({ field: 'father_name', headerName: 'Father Name', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });    
      this.verifiedCompanyColumnDefSummary.push({ field: 'present_address', headerName: 'Present Address', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });
      this.verifiedCompanyColumnDefSummary.push({ field: 'permanent_address', headerName: 'Permanent Address', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });
      this.verifiedCompanyColumnDefSummary.push({ field: 'govt_id', headerName: 'Govt Id', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });        
      this.verifiedCompanyColumnDefSummary.push({ field: 'employee_id', headerName: 'Action', sortable:false, filter: false, resizable: false,wrapText: true, autoHeight: true });        
      this.verifiedcompanyData = res.rows;
      this.verifiedCompanyCount=res.rows.length;
    })
  }
  // bindData(ind){
  //   this.isCompanyList =ind;
  // }

  bindData(ind){
      // this.isCompanyList =ind;
      if(ind == 1){
        let data ="frmid=419&vid=396&accesstoken=U2FsdGVkX19HkiLWoX19GgnS%2FdedPWv5dkucNwt4XB4%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/huno-main-igx?'+encriptUrl);
      }else if(ind == 2){
        let data ="frmid=419&vid=397&accesstoken=U2FsdGVkX1%2BCyVwQRgKljTiiVdlzl4l4W%2BDn5JJqQ0Y%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/huno-main-igx?'+encriptUrl);
      }else if(ind == 3){
        let data ="frmid=419&vid=398&accesstoken=U2FsdGVkX1%2BzmSM76GLsNwPJekNGkvile8tzb8U12ao%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/huno-main-igx?'+encriptUrl);
      }
  }

  onProfileIdChange(event) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboardnew?'+localStorage.getItem('tempToken')]);
    });
    
  }
}
