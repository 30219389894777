import { Component, OnInit,OnDestroy ,ViewChild} from '@angular/core';
import * as xlsx from 'xlsx';
import { Router } from '@angular/router'; 
import { SectionConfig,SessionUrlPrams,ConfigPrams } from "../field.interface";
import {FormService} from '../_services/form.service';
import { ButtonRendererComponent } from '../button-renderer/button-renderer.component';
import { Subscription } from 'rxjs';
import { Page } from '../_models/page';
import { IgxGridComponent,DisplayDensity,IgxCsvExporterService,IgxExcelExporterService, IgxGridRowComponent, Transaction , IgxStringFilteringOperand,ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy,GridPagingMode,  IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ToastrService } from 'ngx-toastr';
import{LoginService } from 'src/app/_services/login.service'
import * as CryptoJS from 'crypto-js';
declare var $: any;

@Component({
  selector: 'app-display-form-detail',
  templateUrl: './display-form-detail.component.html',
  styleUrls: ['./display-form-detail.component.css']
})
export class DisplayFormDetailComponent implements OnInit,OnDestroy {
  @ViewChild('displayGrid') displayGrid: IgxGridComponent;
  selectedAndVisibleRows = []; 
  visibleRows; 
  displayDensity = DisplayDensity.compact;
  subscription: Subscription;
  frameworkComponents: any;
  formId:any=0;
  processid:any;
  stageid:any;
  form_viewid:any=0;
  formName:any;
  
  tableName:any;
  tablePrimaryKey : any
  
  FormViewLoaded:Promise<boolean>;
  
  ViewTableQry:any;
  
  viewFilterString:any;
  filterCriteria:any;
  
  ddlarrGidView:any=[];
  
  childFormList: any[]=[];
  metaDataList: any[]=[];
  
  pagesize="10";
  columnDefs:any[]=[];
  rowData:any[]=[];

  isAdd:boolean=false;
  isEdit:boolean=false;
  isView:boolean= false;
  isExpotable:boolean=false;
  isResetFilter:Boolean=false;

  OverlaySetting:any;
  configParms:ConfigPrams;
  isLoading: boolean=false;
  page = new Page();
  data:any[]=[];
  columnActionStrip:any[]=[];
  currentSelectedRow: any;
  LoadPagination: boolean=false;
  lookupArr: any[]=[];
  public totalCount =0;
  sessionUrlPrams:SessionUrlPrams; //new added
  view_filter: any;
  gridFilter:any="";
  dateArrGidView:any=[];
  modulename:any="";
  formDisplayJSON: any;
  formAllField:any;

  selectedIndex:any;
  add_edit:any;
  EditLoaded:any;
  tabshowloader:any;
  SectionConfig:any;
  nodeJson:any;
  connJson:any;
  isAddMode:any;
  editrowid:any;
  refKey:any;
  lstConfigJson:any;
  form_validation:any;
  editValueRow: any;
  edit_option : any;
  isDynamicGridForm: boolean=false;
  attachmentType: any;
  is_attachment: any;
  Addloader=false;
  tabclieked: Promise<boolean>=Promise.resolve(false);
  isDelete:boolean= false;
  sortingOrder: string="asc"; 
  conversionEncryptOutput: any;
  constructor(
    private router: Router,
    private formService : FormService,
    private excelExportService: IgxExcelExporterService,
    private toastrService: ToastrService,
    private csvExportService: IgxCsvExporterService,
    private loginService: LoginService
  ){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.OverlaySetting={
      positionStrategy: new ConnectedPositioningStrategy({
          horizontalDirection: HorizontalAlignment.Left,
          horizontalStartPoint: HorizontalAlignment.Right,
          verticalStartPoint: VerticalAlignment.Bottom,
          
      }),
      scrollStrategy: new NoOpScrollStrategy()
    };
  }
  
  ngOnInit(): void {
    this.configParms={currentPage:1,totalItems:this.totalCount,itemsPerPage:10}
    let uri=this.router.url.split("?");
    console.log('uri',uri)
    if(uri.length>1){
      var str = uri[1];
      var value=str.split("&");
      if(value[0].indexOf('frmid')>=0){
        this.formId=value[0]?value[0].split('=')[1]:null;
        console.log('this.formId',this.formId)
      }
      if(value[0].indexOf('vid')>=0){
        this.form_viewid=value[0]?value[0].split('=')[1]:null;
      }
      if(value[1].indexOf('frmid')>=0){
        this.formId=value[1]?value[1].split('=')[1]:null;
      }
      if(value[1].indexOf('vid')>=0){
        this.form_viewid=value[1]?value[1].split('=')[1]:null;
      }
    }

    const userId = localStorage.getItem("userId");
    const secureKey = "6192"
    var encrypt = CryptoJS.AES.encrypt(userId, secureKey).toString();
    this.conversionEncryptOutput=encodeURIComponent(encrypt);

    this.getFormDetails();
  }
  getFormDetails(){
   this.subscription=this.formService.getDataByFormId(this.formId).subscribe(res => {
      let formList=res.rows;
      if(formList.length>0){
        this.formName=formList[0]["form_name"];
        this.formId = formList[0]["form_id"];
        this.processid=formList[0]["processid"];
        this.tableName= formList[0]["table_name"];
        this.modulename=formList[0]["modulename"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase(); 
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
      }
      this.getFormViewDetails();
    });
  }
  getFormViewDetails(){
    this.columnDefs=[];
    this.subscription=this.formService.getFormViewById(this.form_viewid).subscribe(res => {
      let formViewList=res.rows;
      let a=0;
      if(formViewList.length>0){
        this.isAdd =formViewList[0]["is_add"]?formViewList[0]["is_add"]:false;
        this.isEdit =formViewList[0]["is_edit"]?formViewList[0]["is_edit"]:false;
        this.isView =formViewList[0]["is_view"]?formViewList[0]["is_view"]:false;
        this.isDelete =formViewList[0]["is_delete"]?formViewList[0]["is_delete"]:false;
        this.sortingOrder=formViewList[0]["sorting_order"]?formViewList[0]["sorting_order"]:"asc";
        this.isExpotable =formViewList[0]["is_exportable"]?formViewList[0]["is_exportable"]:false;
        
        let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        this.edit_option=formViewList[0]["edit_option"];
        this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;                 
        
        this.view_filter=this.formService.getConditionQueryByJson(formViewList[0]["filter_json"],this.sessionUrlPrams) 
        let view_type= formViewList[0]["view_type"]?formViewList[0]["view_type"]:1;  
        let data_for_only= formViewList[0]["data_for_only"]?formViewList[0]["data_for_only"]:"all";  
        let child_form_view_json=formViewList[0]["child_form_view_ids"]?JSON.parse(formViewList[0]["child_form_view_ids"]):[];
        this.form_validation=formViewList[0]["validation"]?JSON.parse(formViewList[0]["validation"]):[]
        
        if(child_form_view_json.length>0){
          child_form_view_json=child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId="";
          for(let r=0;r<child_form_view_json.length; r++){
            childFormId=childFormId+child_form_view_json[r]["formid"]+",";
          }
          childFormId=childFormId.substring(0,childFormId.length-1)
        }
        if(data_for_only !="all" && this.filterCriteria !=""){
          this.filterCriteria= this.filterCriteria +" and a.createdby="+this.sessionUrlPrams.ProfileId; //this.SessionUrlPrams[data_for_only]
        }
        if(childFormId != "" && childFormId != undefined && childFormId != null){
          this.subscription=this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
            this.childFormList=childRes.rows;
            if(child_form_view_json.length>0){
              for(let a=0;a<this.childFormList.length;a++){
                let arrView=child_form_view_json.filter(d=>d.formid == this.childFormList[a]["form_id"])
                if(arrView.length>0){
                  this.childFormList[a]["viewid"]=arrView[0]["viewid"]
                  this.childFormList[a]["display_order"]=arrView[0]["orderno"]
                  }else{
                    this.childFormList[a]["viewid"]=0;
                    this.childFormList[a]["display_order"]=0;
                  }
              }
            }
            this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
            // console.log(JSON.stringify(this.childFormList)) 
          });
        }


        if(fromViewjson.length>0){
          let selectedFieldList=JSON.parse(fromViewjson)
          var column='';

           selectedFieldList.forEach(fieldprop => {
            if(fieldprop.control_type == 5 || fieldprop.control_type == 10 ){
              if(view_type==1){
                this.ddlarrGidView.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3","control_type":fieldprop.control_type})
              }else{
                this.ddlarrGidView.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":fieldprop.display_field,"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }            
            else if(fieldprop.control_type == 7 || fieldprop.control_type == 8){
              if(view_type==1){
                this.dateArrGidView.push({"index":a,"columnname":fieldprop.field_name,"type":"1"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"4","control_type":fieldprop.control_type})
              }else{
                this.dateArrGidView.push({"index":a,"columnname":fieldprop.field_name,"type":"1"})
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"4","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":fieldprop.display_field,"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }
            else if(fieldprop.control_type == 14 || fieldprop.control_type == 15 || fieldprop.control_type == 19 || fieldprop.control_type == 22){
              if(view_type==1){
                let displayField=JSON.parse(fieldprop.display_field)
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,
                "reference_form_id":fieldprop.reference_form_id,"value_field":fieldprop.value_field,
                "display_field":displayField[0]["id"],"field_name":fieldprop.field_name, "type":"2","control_type":fieldprop.control_type})
              }else{
                let displayField=JSON.parse(fieldprop.display_field)
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"2","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":displayField[0]["id"],"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }else{
              if(view_type==1){
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name, "type":"1","control_type":fieldprop.control_type})
              }else{
                this.lookupArr.push({"index":a,"view_type":view_type,"columnname":fieldprop.field_name,"type":"1","level":fieldprop.level,"from_table_name":fieldprop.from_table_name,"to_table_name":fieldprop.to_table_name,"display_field":fieldprop.display_field,"value_field":fieldprop.value_field,"parent_field_name":fieldprop.parent_field_name,"control_type":fieldprop.control_type})
              }
            }
            a++;
            this.columnDefs.push({field:fieldprop.field_name.toString().toLowerCase(),headerName:fieldprop.field_caption,sortable:fieldprop.sortable,filter:fieldprop.filterable ,resizable:fieldprop.resizable,width:fieldprop.column_width, wrapText: true,autoHeight: true});
          });
         
          this.columnActionStrip.push({field: "Edit", iconName: "edit"});

          this.bindNgxDataGrid(this.page,0)
          this.FormViewLoaded = Promise.resolve(true);
          
        }
      }
    });
  }
  

  onClickView(e){    
    let id=e._rowData[this.tablePrimaryKey];
    this.subscription=this.formService.getFormRowDataById(this.tableName,this.tablePrimaryKey,id).subscribe(res => {
       this.formAllField=JSON.parse(this.formDisplayJSON);
      for(var r=0; r<this.formAllField.length; r++){ 
        var regConfig=this.formAllField[r]["FieldConfig"]; 
        for(var i=0; i<regConfig.length; i++){
          regConfig[i].value=res.rows[0][regConfig[i].name];
          regConfig[i].type='fieldview';
        } 
        this.formAllField[r].FieldConfig=regConfig;
      }
      this.isDynamicGridForm=true;
      this.editView(e)
    });
  }

  gotoFormDesignPage(fid,vid){ 
    let data = JSON.stringify('frmid='+fid+'&vid='+vid+'&isAddMode='+true+'&accesstoken='+this.conversionEncryptOutput);
    let  encriptUrl = this.loginService.encriptDataUrl(data)        
    this.router.navigateByUrl('/request-form?'+encriptUrl);
    // this.router.navigateByUrl('/request-form?frmid='+fid+'&vid='+vid+'&isAddMode=true');      
  }

  onEdit(e){
    let editrowid=e.rowData[this.tablePrimaryKey];
    let data = JSON.stringify('frmid='+this.formId+'&vid='+this.form_viewid+'&editrowid='+editrowid+'&isAddMode=false&accesstoken='+this.conversionEncryptOutput);
    // console.log("editrowid==>",editrowid);
    let  encriptUrl = this.loginService.encriptDataUrl(data)        
    this.router.navigateByUrl('/request-form?'+encriptUrl);
    // this.router.navigateByUrl('/request-form?frmid='+this.formId+'&vid='+this.form_viewid+'&editrowid='+editrowid+'&isAddMode=false');
  }
  
  exportToExcel()
  {
    var downloadList=[];
    if(this.rowData.length>0)
    {
      for(let robj of this.rowData)
      {
        var obj={};
        for(let cobj of this.columnDefs)
        {
          obj[cobj.field]=robj[cobj.field];
           //jsob=jsob+'"'+obj.field+'":obj["'+obj.field+'"],';
        }
        downloadList.push(obj);
      }
      //console.log(downloadList)
       this.download(downloadList,"CSV Report")
    }
  }
  
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  download(data:any, filename:string){
    var csvData = this.ConvertToCSV(data);
    var a: any = document.createElement("a");
    a.setAttribute('style', 'display:none;border:1');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    
    var isIE = /*@cc_on!@*/false || !!(<any> document).documentMode;
    
    if (isIE)
    {   
        var retVal = navigator.msSaveBlob(blob, filename+'.csv');
    }
    else{
        a.download = filename+'.csv';
    }
    // If you will any error in a.download then dont worry about this. 
    a.click();
  }
  
  
  // convert Json to CSV data
  ConvertToCSV(objArray:any) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
        var row = "";
  
        for (var index in objArray[0]) {
            //Now convert each value to string and comma-seprated
            row += index + ',';
        }
        row = row.slice(0, -1);
        //append Label row with line break
        str += row + '\r\n';
  
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','
  
                line += '"'+array[i][index]+'"';
            }
  
            str += line + '\r\n';
        }
  
        return str;
    }
  
    onPageSizeChanged() {
      //var value = document.getElementById('page-size').value;
      var value = $('#page-size').val();
      //console.log(value);
      this.pagesize=value;
      this.getFormViewDetails();
      this.FormViewLoaded = Promise.resolve(false); 
      this.FormViewLoaded = Promise.resolve(true);  
    }

    LoadGrid(event){      
      this.configParms=event;
      this.isLoading = true;
      //console.log("config==>",event,this.config);
      this.page.size=event.itemsPerPage;
      this.page.totalElements=event.totalItems;
      this.page.pageNumber=event.currentPage;
      this.bindNgxDataGrid(this.page,1)      
    }

    bindNgxDataGrid(pageInfo,status){
      // console.log(JSON.stringify(pageInfo))
      let total,pagesize,limit,offset=0;
      pagesize=pageInfo.size;
      if(pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status ==0){
        offset=0;
      }else{
        offset=((pageInfo.pageNumber-1)*pagesize);
      }
      this.data=[];
      this.formService.getNgxGridData(this.lookupArr,this.tableName,this.tablePrimaryKey,this.filterCriteria,this.view_filter,pagesize,offset,this.gridFilter,this.sortingOrder).subscribe(res => {
        this.page.totalElements=res[0].rows[0]["total"];
        this.data=res[1].rows;
        this.totalCount = res[0].rows[0]["total"];
        let currPage=pageInfo.pageNumber;
        if(currPage == 0){currPage=1;}
        this.configParms={currentPage:currPage,totalItems:this.totalCount,itemsPerPage:pagesize} 
        this.LoadPagination=true;
        this.isLoading = false;
      },err=>{
        this.toastrService.error(err.error.message)    
      });
    }

    handleRowSelection(e){
      this.currentSelectedRow=e["newSelection"][0]
    }

    ShowViewData(e){
      this.onClickView({"_rowData":e.rowData})
      // setTimeout(() => {
      //   this.onClickView({"_rowData":this.currentSelectedRow})
      // }, 100);  
    }

  onClickActionStrip(e,actiontype){
    // console.log(e._rowData,actiontype)
    let editrowid=e._rowData[this.tablePrimaryKey];   
    let data = JSON.stringify('frmid='+this.formId+'&vid='+this.form_viewid+'&editrowid='+editrowid+'&isAddMode=false&accesstoken='+this.conversionEncryptOutput);
    // console.log("editrowid==>",editrowid);
    let  encriptUrl = this.loginService.encriptDataUrl(data)        
    this.router.navigateByUrl('/request-form?'+encriptUrl); 
    // this.router.navigateByUrl('/request-form?frmid='+this.formId+'&vid='+this.form_viewid+'&editrowid='+editrowid+'&isAddMode=false');
  }


  removeFilter()
  {
    this.viewFilterString=null;
    this.filterCriteria=null;
    this.isResetFilter=true;
    this.getFormViewDetails();
  }
  CommonGridSearch(SearchValue){
    //console.log("txtSearch==>",SearchValue)
    if(SearchValue.length)
    {
      this.gridFilter=SearchValue.toString().trim();
      this.LoadPagination = false;
      this.page.size=10;
      this.page.pageNumber=1;
      this.bindNgxDataGrid(this.page,1)
    }else{
      this.gridFilter="";
      this.LoadPagination = false;
      this.page.size=10;
      this.page.pageNumber=1;
      this.bindNgxDataGrid(this.page,1)
    }
  }
  apply(criteriaStr: any) {
    //console.log("criteriaStr==>",criteriaStr)
    if(criteriaStr!=null)
    {
      this.viewFilterString=criteriaStr["viewStr"];
      this.filterCriteria=criteriaStr["queryStr"];
      //this.getFormViewDetails();
      this.bindNgxDataGrid(this.page,1)
    }
  }

  public filteredAndSelected(grid) {
    this.selectedAndVisibleRows = [];
    console.log("Grid==>",grid)
    this.visibleRows = grid.selectionService.getRowIDs(
        grid.selectionService.allData
    );

    if (grid.selectedRows.length > 0) {
        grid.selectedRows.forEach(row => {
            if (this.visibleRows.indexOf(row) >= 0) {
                this.selectedAndVisibleRows.push(row);
            }
        });
    }
    return this.visibleRows;
  }
  exportCSVHandler(){
    this.exportGridToCSV(this.displayGrid);
  }
  exportExcelHandler(){
    this.exportGridToExcel(this.displayGrid);
  }
  exportGridToExcel(grid: IgxGridComponent) {
    // this.excelExportService.export(grid, new IgxExcelExporterOptions(this.fileName()));
    this.filteredAndSelected(grid);
     // if there aren't any rows after the filtering has been applied do not export anything
    if (this.visibleRows.length > 0) {
        // component.ts
        this.excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
         // if there are any selected rows within the filtered data -> export only them
        // if there are no selected rows within filtered data -> export only the filtered data
        this.excelExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxExcelExporterOptions(this.fileName()));
    }
  }

  exportTreeGridToExcel(grid: IgxTreeGridComponent) {
    this.excelExportService.export(grid, new IgxExcelExporterOptions(this.fileName()));
    this.filteredAndSelected(grid);
    if (this.visibleRows.length > 0) {
        this.excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
        this.excelExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxExcelExporterOptions(this.fileName()));
    }
  }

  exportGridToCSV(grid: IgxGridComponent) {
    this.filteredAndSelected(grid);

    if (this.visibleRows.length > 0) {
        this.csvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
        this.csvExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxCsvExporterOptions(this.fileName(), CsvFileTypes.CSV));
    }
  }

  exportTreeGridToCSV(grid: IgxTreeGridComponent) {
    this.filteredAndSelected(grid);

    if (this.visibleRows.length > 0) {
        this.csvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
        this.csvExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxCsvExporterOptions(this.fileName(), CsvFileTypes.CSV));
    }
  }
  public fileName(){
    const nowDate = new Date(); 
    const month = nowDate.toLocaleString('default', { month: 'short' })
    const date = nowDate.getDate()+ '-'+month+'-' +nowDate.getFullYear(); 
    const url = this.router.url.replace(/\//g, "");
    const filename = url.charAt(0).toUpperCase() + url.slice(1);
    return filename+"_"+date;
  }
  tabChanged(event,tabIndex) {
    this.tabshowloader=true;
    this.Addloader=true;
    this.tabclieked=Promise.resolve(false);
    this.selectedIndex =tabIndex;
    this.tabclieked=Promise.resolve(true);
    setTimeout(() => {
      this.tabshowloader=false;
      this.Addloader=false;
    },1000); 
  }
  closeModel(){
    this.selectedIndex=this.formId;
    setTimeout(() => {$("#editModal").modal('hide');}, 0);   
  }
  editView(e){
    this.selectedIndex=this.formId;
    this.add_edit=Promise.resolve(false);
    this.EditLoaded=Promise.resolve(false);
     this.tabshowloader=true;
    this.SectionConfig=null;
    this.lstConfigJson=null;
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control Section hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0  || d.visible_stage.toString() =="")));
        this.stageid=intStageid;
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
        this.SectionConfig.forEach(arrField => {
          if(arrField.FieldConfig[0]?.visible_stage !=undefined){
            arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0 || field.visible_stage.toString() =="");
          }
        });
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0  || d.visible_stage.toString() =="")));
      }
       
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="edit" || field?.visible=="hidden");
      });
      this.SectionConfig=this.SectionConfig.filter(sec=>(sec.FieldConfig?.length >0 || sec.section_dependent==true) && (sec?.isCaptionVisiable =="always" || sec?.isprocess =="1" ) );
      
  
      //need to check e.rowData pks 12/05/21
      this.isAddMode=false;
      this.editrowid=e._rowData[this.tablePrimaryKey];
      this.refKey=e._rowData[this.tablePrimaryKey];
      
      this.subscription=this.formService.getFormRowDataById(this.tableName,this.tablePrimaryKey,this.editrowid).subscribe(res => {
        //console.log("rowData==",res.rows[0]);
        this.editValueRow=JSON.parse(JSON.stringify(res.rows[0]));
        //console.log("sss this.formAllField===>",this.editValueRow,this.edit_option,this.EditLoaded);
        this.lstConfigJson={      
          "section_config_json":this.SectionConfig,
          "form_validation_json": this.form_validation,
          "form_attachment_json": this.attachmentType
        }
        this.EditLoaded=Promise.resolve(true);
        setTimeout(() => {$("#editModal").modal('show');}, 100);   
        
        if(this.edit_option == 3){
            if(this.childFormList.length>0){        
              let formid=this.childFormList[0]["form_id"];
              let tabid="#tabchildedit"+formid;
              setTimeout(() => {$(tabid).click();}, 100);        
            }
        }
      });
    }
    setTimeout(() => {
      this.tabshowloader=false;
    },1000); 
  }

    ngOnDestroy() { 
      this.subscription.unsubscribe();
      this.formService=null;
      this.frameworkComponents=null
      this.formId=null
      this.processid=null
      this.stageid=null
      this.form_viewid=null
      this.formName=null
      this.tableName=null
      this.tablePrimaryKey=null
      this.FormViewLoaded=null
      
      //this.is_attachment=null
      this.ViewTableQry=null
      this.viewFilterString=null
      this.filterCriteria=null
      this.ddlarrGidView=null
      this.childFormList=null
      this.metaDataList=null
      // this.attachmentType=null
      // this.metadata=null
      this.rowData=null
      this.columnDefs=null
      this.pagesize=null
      this.isAdd=null
      this.isEdit=null
      this.isView=null
      this.isExpotable=null
      this.isResetFilter=null
      //this.edit_option=null
    } 
   
}


