import { Component, OnInit } from '@angular/core';
import { FormService } from '../../_services/form.service';
import * as crypto from 'crypto-js';
import { AppSettings } from '../../_services/AppSetting'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router'; 
declare var $:any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  oldPassword: any;
  newPassword: any;
  confirmPassword: any;
  err_msg: string;
  constructor(private formService: FormService,private toastrService: ToastrService,
    private router: Router,) { }

  ngOnInit(): void {
    $(".page-footer").hide()
  }

  ChangePassword() {
    this.err_msg = "";
    var minNumberofChars = 8;
    var maxNumberofChars = 16;
    let passpattern = /(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/;
    // let pattern = /^((?=.*[a-z])(?=.*[A-Z]).*)$/
    if (this.oldPassword == null || this.oldPassword == undefined || this.oldPassword == "" || this.oldPassword == "null" || this.oldPassword == "undefined") {
      this.err_msg = "Current Password is required";
      return false;
    } else if (this.newPassword == null || this.newPassword == undefined || this.newPassword == "" || this.newPassword == "null" || this.newPassword == "undefined") {
      this.err_msg = "New Password is required";
      return false;
    } else if (this.newPassword.length < minNumberofChars || this.newPassword.length > maxNumberofChars) {
      this.err_msg = "Your password must be at least 8 characters";
      return false;
    } else if (!passpattern.test(this.newPassword)) {
      this.err_msg = "password should contain atleast one number and one special character";
      return false
    } else if (!passpattern.test(this.newPassword)) {
      this.err_msg = "password should contain atleast one upper case and one lower case character";
      return false
    } else if (this.confirmPassword == null || this.confirmPassword == undefined || this.confirmPassword == "" || this.confirmPassword == "null" || this.confirmPassword == "undefined") {
      this.err_msg = "ConfirmPassword Password is required";
      return false;
    } else if (this.newPassword != this.confirmPassword) {
      this.err_msg = "Password Mismatch";
      return false;
    } else {
      let id = localStorage.getItem("loginusername");      
      let userId = crypto.AES.encrypt(id, AppSettings.SecreatKey).toString();
      console.log(this.oldPassword)
      let oldPassword = crypto.AES.encrypt(this.oldPassword, AppSettings.SecreatKey).toString();
      let newPassword = crypto.AES.encrypt(this.newPassword, AppSettings.SecreatKey).toString();
      this.formService.changePassword(userId, oldPassword, newPassword).subscribe(data => {
        console.log("nwpaswird", data)
        if (data.error == "false") {
          this.toastrService.success(data, 'Password Change Successfully', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
          
          this.router.navigate(["/login"])
        }
        else {
          this.toastrService.warning(data.msg ,'', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 });
        }
      })
    }
    this.newPassword = ''
    this.confirmPassword = ''

  }


}
