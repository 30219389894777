import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionService } from 'src/app/_services/common-function.service';
import { CustomerLoginSigninService } from 'src/app/_services/customer-login-signin.service';
import { CustomerServiceService } from 'src/app/_services/customer-service.service';
import { LoginService } from 'src/app/_services/login.service';
import { ProductService } from 'src/app/_services/product.service';
import { SellerRegistrationService } from 'src/app/_services/seller-registration.service';
import { AppSettings } from '../../_services/AppSetting';
//import * as XLSX from 'xlsx';
declare var $: any;
type AOA = any[][];
declare var $: any;
declare var Razorpay: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  data: AOA = [[1, 2], [3, 4]];
  @ViewChild('uploadedfile') myInputVariable: ElementRef;
  response;
  razorpayResponse;
  MassageOutTime = AppSettings.getMassageOutTime;
  showModal = false;
  cart_items: any = [];
  customer_id: any;
  sameAsBilling: boolean = false;
  custome_mobile_No: any;
  customer_full_Name: any;
  addressList: any = [];
  addNew: boolean = false;
  isLoading: boolean = false;
  pinCode: any;
  streetName: any;
  blockNo: any;
  alternate_phone: any;
  landmark: any;
  add_area_street: any;
  locality: any;
  add_mobile: any;
  is_for_future: boolean = false;
  shipType: any = 'single';
  add_name: any;
  last_name: any;
  company_name: any;
  email_address: any;
  selected_billing_id: any;

  country_id: any;
  stateList: any = [];
  cityList: any = [];
  state: any;
  city: any;
  stateCityList: any;
  address_id: any;
  defaultDelevAddress: boolean = true;
  product_price: number;
  inputnumber: any;
  totalAmount: number;
  selected_add_id: any;
  grandTotal: number;
  addList: any = [];
  addressId: any;
  addBtn: boolean = true;
  editBtn: boolean;
  customerEmail: any;
  countryList: any;
  orderNo: any;
  totalPrice: any;
  delete: any;
  add: any;
  shipingPrice: any = 0;
  grandGst: number;
  paymentId: any;
  totalamountforOrder: any;
  totalgstFororder: number;
  razorpay: any;
  newAddressId: any;
  rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  arrayBuffer: any | ArrayBuffer;
  multipleAddressList: any[] = [];
  excFile: Blob;
  sqlQuery: string;
  totalProductCount: number;
  totalInsertedRows: number = 0;
  addTypetext: any;
  billingAddList: any[] = [];
  tempAddresslist: any;
  gift_amount: any = 0;
  gift_id: any;
  count: number = 0;
  userCount: number = 0;
  userwise_gift_code_usageid: any;
  usage_limit_per_user_count: any;
  savedAmount: number;
  keylist: any[] = [];
  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    event.stopPropagation();
    return true;
  }

  constructor(private router: Router, private loginService: LoginService,
    public commonFunctionService:CommonFunctionService, private productService: 
    ProductService, private route: ActivatedRoute, 
    private customerLoginSigninService: CustomerLoginSigninService, 
    private sellerRegistrationService: SellerRegistrationService, 
    private cd:  ChangeDetectorRef,
    private toastrService: ToastrService,
    private customerService: CustomerServiceService) {
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.ngOnInit();
        }
     
      });
      history.pushState(null, '');
     }
     razorPayOptions = {
      key: 'rzp_test_tuM4aYCzbirOfi',
      amount: '', // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      
      name: 'Ideacraft PVT LTD', // company name or product name
      // image: "../../assets/images/login-logo.png",
      description: 'Ideacraft PVT LTD',  // product description
      theme: {
        "color": "#ee8732",
        },
      order_id: '', // order_id created by you in backend
      handler: ((response, error) => {
        // options.response = response;
      
        // call your backend api to verify payment signature & capture transaction
      }),
      modal: {}
    }
    razorpayObject = new Razorpay(this.razorPayOptions);

  ngOnInit(): void {
    let data1 = localStorage.getItem('buyer')
    data1 = JSON.parse(data1) 
    this.customer_id = data1['buyerid']
    //console.log("buyerid", this.customerId)
    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      if (data.length > 0) {
        this.cart_items = JSON.parse(data);        
        this.customer_id = this.cart_items[0].customer_id;
        let cart = JSON.parse(data);
      //   this.grandGst = 0;
      //   for (const gst of this.cart_items) {
      //     this.grandGst = Number(this.grandGst) + Number(gst.total_gst_amount);
      //     this.grandGst = parseFloat(Number(this.grandGst).toFixed(2));
      //  }
      //  this.totalAmount = 0;
      //  let discountPer = 0;
      //  for (let pro of this.cart_items) {
      //   this.totalAmount = Number(this.totalAmount ? this.totalAmount : 0) + Number(pro.price ? pro.price : 0);
      //   discountPer = Math.round((((pro.product_mrp ? pro.product_mrp : 0) - (pro.price ? pro.price : 0)) * 100) / (pro.product_mrp ? pro.product_mrp : 0));
      //   pro.discountPer = discountPer;
      // }
      //  this.totalAmount = Number(this.totalAmount) + Number(this.grandGst)  ;    
      //  this.totalAmount = parseFloat(Number(this.totalAmount).toFixed(2));
      // }else{
      //   this.getCartData();

       }
    });

    this.getCartData();
    this.getCustomerInfo();
    this.getAllAddress();
    this.getStateList();
    this.getCityList();
    this.getAllCountries();
  }
  getCustomerInfo() {
    this.customerLoginSigninService.getCustomerDetail(this.customer_id).subscribe(res => {
      this.custome_mobile_No = res[0].mobile_no;
      this.customer_full_Name = res[0].fullname;
      this.customerEmail = res[0].email;
    })
  }
  getAllAddress() {
    //debugger
    this.customerLoginSigninService
      .getCustomerAddresses(this.customer_id)
      .subscribe((res) => {
        console.log('custaddres', res);
        if (res.rows.length > 0) {
          this.addressList = res.rows.filter(
            (x) => x.is_for_future && x.is_billing_add == false
          );
          this.tempAddresslist = res.rows.filter(
            (x) => x.is_for_future && x.is_billing_add == false
          );
          this.billingAddList = res.rows.filter(
            (x) =>
              x.is_billing_add || (x.is_billing_add == null && x.is_for_future)
          );
        } else {
          this.addressList = [];
          this.billingAddList = [];
        }
      });
  }
  
  takeBilling() {
    //debugger
    if (!this.selected_billing_id) {
      this.toastrService.warning('Please select billing address', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      this.sameAsBilling = false;
      $('#sameasbilling').prop('checked', false);
      return true;
    }
    if (this.sameAsBilling) {
      let ShipingDetail = this.billingAddList.filter(
        (x) => x.address_id == this.selected_billing_id
      );
      ShipingDetail[0].is_for_future =this.is_for_future;
     // this.addList = res[0];
          // this.add_name = ShipingDetail[0].name;
          // this.add_mobile = ShipingDetail[0].mobile;
          // this.pinCode = ShipingDetail[0].pin_code;
          // this.locality = ShipingDetail[0].locatity;
          // this.add_area_street = ShipingDetail[0].area_street;
          // this.city = ShipingDetail[0].city;
          // this.state = ShipingDetail[0].state_id;
          // this.landmark = ShipingDetail[0].landmark;
          // this.alternate_phone = ShipingDetail[0].alternate_phone;
          // this.address_id = ShipingDetail[0].address_id;
          // this.last_name = ShipingDetail[0].last_name;
          // this.email_address = ShipingDetail[0].email_address;
          // this.company_name = ShipingDetail[0].company_name;
          // this.country_id = ShipingDetail[0].country_id;
          if(!ShipingDetail[0].is_for_future){
            debugger
            this.customerLoginSigninService
            .saveAddressDetail(
             null,
              ShipingDetail[0].customer_id,
              ShipingDetail[0].name,
              ShipingDetail[0].mobile,
              ShipingDetail[0].pin_code,
              ShipingDetail[0].locatity,
              ShipingDetail[0].area_street,
              ShipingDetail[0].city,
              ShipingDetail[0].state_id,
              ShipingDetail[0].landmark,
              ShipingDetail[0].alternate_phone,
              ShipingDetail[0].last_name,
              ShipingDetail[0].company_name,
              ShipingDetail[0].email_address,
              ShipingDetail[0].country_id,
              ShipingDetail[0].is_for_future,
             's'
            )
            .subscribe((res) => {
              this.is_for_future = false;
            //  this.newAddressId = res['address_id'];
            });
          }
      this.selected_add_id = this.selected_billing_id;
      this.addressList.push(ShipingDetail[0]);
      this.addNew = false;
      this.toastrService.success('Address Saved', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
    }
  }
  Viewcart(){
    this.router.navigateByUrl('add-to-cart')
  }
  getAllCountries() {
    this.productService.getCounty().subscribe((res:any) => {
      this.countryList = res.rows;
    })
  }
  edit(address_id) {
    this.addNew = true;
    this.editBtn = true;
    this.addBtn = false;

    this.customerLoginSigninService
      .getAddresses(this.customer_id, address_id)
      .subscribe((res) => {
        if (res.length > 0) {
          this.addList = res[0];
          this.add_name = this.addList.name;
          this.add_mobile = this.addList.mobile;
          this.pinCode = this.addList.pin_code;
          this.locality = this.addList.locatity;
          this.add_area_street = this.addList.area_street;
          this.city = this.addList.city;
          this.state = this.addList.state_id;
          this.landmark = this.addList.landmark;
          this.alternate_phone = this.addList.alternate_phone;
          this.address_id = this.addList.address_id;
          this.last_name = this.addList.last_name;
          this.email_address = this.addList.email_address;
          this.company_name = this.addList.company_name;
          this.country_id = this.addList.country_id;
        }
      });
  }
  editAddress() {
    this.add_name = this.add_name?.trim();
    if (
      this.add_name == null ||
      this.add_name == undefined ||
      this.add_name == ''
    ) {
      this.toastrService.warning('First Name is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    this.last_name = this.last_name?.trim();
    if (
      this.last_name == null ||
      this.last_name == undefined ||
      this.last_name == ''
    ) {
      this.toastrService.warning('Last Name is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    this.add_mobile = this.add_mobile.trim();
    if (
      this.add_mobile == null ||
      this.add_mobile == undefined ||
      this.add_mobile == ''
    ) {
      this.toastrService.warning('Mobile No. is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    if (
      this.add_mobile !== '' &&
      this.add_mobile !== null &&
      this.add_mobile !== undefined
    ) {
      if (this.add_mobile.toString().length == 10) {
        if (this.add_mobile[0] < 6) {
          this.toastrService.warning('Please enter valid Mobile No.', '', {
            timeOut: this.MassageOutTime,
            positionClass: 'toast-top-center',
          });
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return false;
        }
      } else {
        this.toastrService.warning(
          'Please enter valid 10-digit Mobile No.',
          '',
          { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
        );
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();

        return false;
      }
    }
    this.email_address = this.email_address.trim();
    if (
      this.email_address == null ||
      this.email_address == undefined ||
      this.email_address == ''
    ) {
      this.toastrService.warning('Email Address is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.email_address !== undefined &&
      this.email_address !== null &&
      this.email_address !== ''
    ) {
      let pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.email_address.substring(0).length > 0) {
        var email = this.email_address.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning('Please enter valid Email.', '', {
            timeOut: this.MassageOutTime,
            positionClass: 'toast-top-center',
          });
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return false;
        }
      }
    }
    if (
      this.pinCode == null ||
      this.pinCode == undefined ||
      this.pinCode == ''
    ) {
      this.toastrService.warning('PinCode is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    this.locality = this.locality?.trim();
    if (
      this.locality == null ||
      this.locality == undefined ||
      this.locality == ''
    ) {
      this.toastrService.warning('Locality is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    this.add_area_street = this.add_area_street?.trim();
    if (
      this.add_area_street == null ||
      this.add_area_street == undefined ||
      this.add_area_street == ''
    ) {
      this.toastrService.warning('Address is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.country_id == null ||
      this.country_id == undefined ||
      this.country_id == ''
    ) {
      this.toastrService.warning('Please Select Country', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    if (this.state == null || this.state == undefined || this.state == '') {
      this.toastrService.warning('State is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (this.city == null || this.city == undefined || this.city == '') {
      this.toastrService.warning('City is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.alternate_phone !== '' &&
      this.alternate_phone !== null &&
      this.alternate_phone !== undefined
    ) {
      if (this.alternate_phone.toString().length == 10) {
        if (this.alternate_phone[0] < 6) {
          this.toastrService.warning(
            'Please enter valid Alternate Mobile No.',
            '',
            { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
          );
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return false;
        }
      } else {
        this.toastrService.warning(
          'Please enter valid 10-digit Alternate Mobile No.',
          '',
          { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
        );
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();

        return false;
      }
    }
    this.customerLoginSigninService
      .saveAddressDetail(
        this.address_id,
        this.customer_id,
        this.add_name,
        this.add_mobile,
        this.pinCode,
        this.locality,
        this.add_area_street,
        this.city,
        this.state,
        this.landmark,
        this.alternate_phone,
        this.last_name,
        this.company_name,
        this.email_address,
        this.country_id,
        this.is_for_future
      )
      .subscribe((res) => {
        this.is_for_future = false;
        this.closeAddressForm();
        this.reSet();
        // this.getAllAddress();
        // this.address_id=null;
        this.toastrService.success('update successfully', '', {
          timeOut: this.MassageOutTime,
          positionClass: 'toast-top-center',
        });
      });
  }
  itmid:any;
  showPopup(addressId, txt) {
    this.addressList = this.tempAddresslist;
    if (txt == 's') {
      if (this.addressList.filter((x) => x.address_id == addressId).length > 0)
        this.itmid = addressId;
    } else {
      if (txt == 'b') {
        if (
          this.billingAddList.filter((x) => x.address_id == addressId).length >
          0
        )
          this.itmid = addressId;
      }
    }
  }
  DeleteAddres1(addressId) {
    this.customerLoginSigninService
      .deleteAddresses1(addressId)
      .subscribe((res) => {
        this.toastrService.success('Deleted Successfully', '', {
          timeOut: this.MassageOutTime,
          positionClass: 'toast-top-center',
        });
        this.getAllAddress();
      });
  }
  getStateList() {
    this.sellerRegistrationService.getState().subscribe(res => {
      this.stateList = res;
    })
  }
  getCityList() {
    this.sellerRegistrationService.getCity().subscribe(res => {
      this.cityList = res;
    })
  }
  getCityAndState() {
    this.customerLoginSigninService.getCityState(this.pinCode).subscribe(res => {
      this.stateCityList = res[0];
      if (this.stateCityList) {
        this.city = this.stateCityList.city_name ? this.stateCityList.city_name : null;
        this.state = this.stateCityList.state_name ? this.stateCityList.state_name : null;
      } else {
        this.city = null;
        this.state = null;
      }
    })
  }
  addAddress() {
    this.add_name = this.add_name?.trim();
    if (
      this.add_name == null ||
      this.add_name == undefined ||
      this.add_name == ''
    ) {
      this.toastrService.warning('First Name is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    this.last_name = this.last_name?.trim();
    if (
      this.last_name == null ||
      this.last_name == undefined ||
      this.last_name == ''
    ) {
      this.toastrService.warning('Last Name is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    //this.add_mobile = this.add_mobile.trim();
    if (
      this.add_mobile == null ||
      this.add_mobile == undefined ||
      this.add_mobile == ''
    ) {
      this.toastrService.warning('Mobile No. is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.add_mobile !== '' &&
      this.add_mobile !== null &&
      this.add_mobile !== undefined
    ) {
      if (this.add_mobile.toString().length == 10) {
        if (this.add_mobile[0] < 6) {
          this.toastrService.warning('Please enter valid Mobile No.', '', {
            timeOut: this.MassageOutTime,
            positionClass: 'toast-top-center',
          });
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return false;
        }
      } else {
        this.toastrService.warning(
          'Please enter valid 10-digit Mobile No.',
          '',
          { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
        );
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();

        return false;
      }
    }
    //this.email_address = this.email_address.trim();
    if (
      this.email_address == null ||
      this.email_address == undefined ||
      this.email_address == ''
    ) {
      this.toastrService.warning('Email Address is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.email_address !== undefined &&
      this.email_address !== null &&
      this.email_address !== ''
    ) {
      let pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.email_address.substring(0).length > 0) {
        var email = this.email_address.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning('Please enter valid Email.', '', {
            timeOut: this.MassageOutTime,
            positionClass: 'toast-top-center',
          });
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return false;
        }
      }
    }
    if (
      this.pinCode == null ||
      this.pinCode == undefined ||
      this.pinCode == ''
    ) {
      this.toastrService.warning('PinCode is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    this.locality = this.locality?.trim();
    if (
      this.locality == null ||
      this.locality == undefined ||
      this.locality == ''
    ) {
      this.toastrService.warning('Locality is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    this.add_area_street = this.add_area_street?.trim();
    if (
      this.add_area_street == null ||
      this.add_area_street == undefined ||
      this.add_area_street == ''
    ) {
      this.toastrService.warning('Address is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.country_id == null ||
      this.country_id == undefined ||
      this.country_id == ''
    ) {
      this.toastrService.warning('Please Select Country', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (this.state == null || this.state == undefined || this.state == '') {
      this.toastrService.warning('State is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');

      this.removeClass();
      return false;
    }
    if (this.city == null || this.city == undefined || this.city == '') {
      this.toastrService.warning('City is Required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
    if (
      this.alternate_phone !== '' &&
      this.alternate_phone !== null &&
      this.alternate_phone !== undefined
    ) {
      if (this.alternate_phone.toString().length == 10) {
        if (this.alternate_phone[0] < 6) {
          this.toastrService.warning(
            'Please enter valid Alternate Mobile No.',
            '',
            { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
          );
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return false;
        }
      } else {
        this.toastrService.warning(
          'Please enter valid 10-digit Alternate Mobile No.',
          '',
          { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
        );
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();

        return false;
      }
    }

    this.addTypetext = this.sameAsBilling ? '' : this.addTypetext;

    this.customerLoginSigninService
      .saveAddressDetail(
        this.address_id,
        this.customer_id,
        this.add_name,
        this.add_mobile,
        this.pinCode,
        this.locality,
        this.add_area_street,
        this.city,
        this.state,
        this.landmark,
        this.alternate_phone,
        this.last_name,
        this.company_name,
        this.email_address,
        this.country_id,
        this.is_for_future,
        this.addTypetext
      )
      .subscribe((res) => {
        this.is_for_future = false;
        this.newAddressId = res['address_id'];
        // this.getCityAndState()
        if (this.addTypetext == 'b' || this.addTypetext == '') {
          this.billingAddList.push({
            address_id: res['result'].address_id,
            name: res['result'].name,
            mobile: res['result'].mobile,
            locatity: res['result'].locatity,
            area_street: res['result'].area_street,
            city: res['result'].city,
            state_name: this.stateList.filter(
              (x) => x.state_id == res['result'].state_id
            )[0].state_name,
            pin_code: res['result'].pin_code,
            email_address: res['result'].email_address,
          });
        } else {
          this.addressList.push({
            address_id: res['result'].address_id,
            name: res['result'].name,
            mobile: res['result'].mobile,
            locatity: res['result'].locatity,
            area_street: res['result'].area_street,
            city: res['result'].city,
            state_name: this.stateList.filter(
              (x) => x.state_id == res['result'].state_id
            )[0].state_name,
            pin_code: res['result'].pin_code,
            email_address: res['result'].email_address,
          });
        }

        // this.getAllAddress();
        // this.reSet();
        this.closeAddressForm();

        this.toastrService.success('Address Saved', '', {
          timeOut: this.MassageOutTime,
          positionClass: 'toast-top-center',
        });
      });
  }
  ConvertToInt(val){
    return parseInt(val);
  }
  reSet() {
    this.addNew = true;
    this.addNew = true;
    this.add_name = null;
    this.add_mobile = null;
    this.pinCode = null;
    this.locality = null;
    this.add_area_street = null;
    this.city = null;
    this.state = null;
    this.landmark = null;
    this.alternate_phone = null;
    this.email_address = null;
    this.last_name = '';
    this.company_name = '';
  }
  closeAddressForm() {
    this.addNew = false;
  }
  showAddressForm(text?) {
    this.addTypetext = text;
    this.addNew = true;
    this.editBtn = false;
    this.reSet();
  }
  onBillingChange() {
    $('#sameasbilling').prop('checked', false);
    this.addressList = this.tempAddresslist;
  }
  getCartData(a?) {
    // this.productService.getCartData(this.customer_id).subscribe(res => {
    //   if (res.rows.length > 0) {
    //     this.cart_items = res.rows;
    //     this.totalAmount = 0;
    //     let discountPer = 0;
    //     for (let pro of this.cart_items) {
    //       this.totalAmount = Number(this.totalAmount ? this.totalAmount : 0) + Number(pro.total_amount ? pro.total_amount : 0);
    //       discountPer = Math.round((((pro.product_mrp ? pro.product_mrp : 0) - (pro.price ? pro.price : 0)) * 100) / (pro.product_mrp ? pro.product_mrp : 0));
    //       pro.discountPer = discountPer;
    //     }
    //   } else {
    //     this.cart_items = [];
    //   }
    // });
    this.productService.getCartData(this.customer_id).subscribe(
      (res) => {
        
        console.log("this.product",res)
        if(res.length > 0){
          let productsDetails = res[0].rows;
          let configurabaleDetails = res[1] ? res[1].rows : [];
          console.log("configurabaleDetails",configurabaleDetails)
          let extraFeaturesDetails = res[2] ? res[2].rows : [];          
        console.log("extraFeaturesDetails",extraFeaturesDetails)
          let bundlePriceList = res[3] ? res[3].rows : [];
          let isLoading = false;
          if (res[0].rows.length > 0) {
            this.cart_items = [];
  
            for (let obj of res[0].rows) {
              if (
                obj.product_type == 208 ||
                obj.product_type == 209 ||
                obj.product_type == 198
              ) {
                let item = res[1].rows.filter((x) => x.crat_id == obj.crat_id);
                let extraFeatureList = res[2].rows.filter(
                  (x) => x.cart_id == obj.crat_id
                );
                let bundlePrice = bundlePriceList.filter(
                  (x) => x.product_id == obj.product_id
                );
                let minOrdQty = res[0].rows.filter(
                  (x) => x.crat_id == item[0].crat_id
                )[0].min_order_qty;
                let firstExtraPrice = 0;
                let price = 0;
                if (item.length > 0 && obj.product_type == 209) {
                  for (const ext of extraFeatureList) {
                    if (ext.isSelected) {
                      firstExtraPrice =
                        Number(firstExtraPrice) + Number(ext.price);
                    }
                  }
                  price = (Number(obj.base_price) + Number(firstExtraPrice)) * Number(obj.qtytotal);
                  item = JSON.parse(item[0].details);
                } else {
                  if (extraFeatureList.length) {
                    for (const ext of extraFeatureList) {
                      if (ext.isSelected) {
                        firstExtraPrice =
                          Number(firstExtraPrice) + Number(ext.price);
                      }
                    }
                  }
                  item.forEach((dtl) => {
                    price =
                      price +
                      (Number(dtl.price) +
                        Number(dtl.admin_price) +
                        Number(Number(firstExtraPrice))) *
                        Number(dtl.quantity);
                  });
                  if (bundlePrice.length) {
                    price = bundlePrice.filter(
                      (x) =>  Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty )[0]?.price ?  (Number(bundlePrice.filter( (x) =>   Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty  )[0]?.price) + Number(firstExtraPrice)) * Number(obj.qtytotal)
                      : Number(price);
                    //  let   bunprice = this.bundlePriceList.filter((x) => Number(minOrdQty) >= +x.min_qty && Number(minOrdQty) <= +x.max_qty)[0]?.price ? this.bundlePriceList.filter((x) => Number(minOrdQty) >= +x.min_qty && Number(minOrdQty) <= +x.max_qty)[0]?.price * Number(minOrdQty): (Number(price));
                  }
                }
                
                this.cart_items.push({
                  crat_id: obj.crat_id,
                  gst_amount: Number(obj.gst_rate),
                  total_gst_amount: (Number(price) * Number(obj.gst_rate)) / 100,
                  gst_rate: obj.gst_rate,
                  sku: item[0].sku ? item[0].sku : obj.sku,
                  customer_id: obj.customer_id,
                  product_id: obj.product_id,
                  qtytotal: obj.qtytotal,
                  price: price,
                  product_mrp: obj.product_mrp,
                  total_amount: obj.total_amount,
                  product_name: obj.product_name,
                  product_discription: obj.product_discription,
                  firstExtraPrice: firstExtraPrice,
                  seller_id: obj.seller_id,
                  seller_name: obj.seller_name,
                  base_price: obj.base_price,
                  image_path: obj.image_path,
                  product_type: obj.product_type,
                  extra_features: extraFeatureList ? extraFeatureList : '',
                  details: item,
                  slides: item[0].slides,
                  min_order_qty: minOrdQty,
                  card_logo_img_path: configurabaleDetails.length>0 && configurabaleDetails[0].card_logo_img_path,
                  artwork_area: extraFeaturesDetails.length>0 && extraFeaturesDetails[0].document_path ,
                  bundle_price: bundlePrice,
                  gift_amount: this.gift_amount ? this.gift_amount : 0,
                  gift_id: this.gift_id,
                  userwise_gift_code_usageid: this.userwise_gift_code_usageid,
                  usage_limit_per_user_count: this.usage_limit_per_user_count,
                  category: obj.category,
                  admin_price : obj.admin_price,
                  admin_mrp: obj.admin_mrp
                });
                firstExtraPrice = 0;
                extraFeatureList = '';
                price = 0;
              } else if (
                (obj.product_type != 208 &&
                  obj.product_type != 209 &&
                  obj.product_type != 198 &&
                  obj.product_type == null) ||
                obj.product_type == 197
              ) {
                let bundlePrice = bundlePriceList.filter(
                  (x) => x.product_id == obj.product_id
                );
                let price = 0
                price = (Number(obj.price) + Number(obj.admin_price)) * Number(obj.qtytotal);
                if (bundlePrice.length) {
                  price = bundlePrice.filter(
                    (x) =>  Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty )[0]?.price ?  (Number(bundlePrice.filter( (x) =>   Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty  )[0]?.price)) * Number(obj.qtytotal)
                    : Number(price);
                }
                
              //console.log("this.product",price)
                this.cart_items.push({
                  crat_id: obj.crat_id,
                  stock_quantity: obj.stock_quantity,
                  gst_amount: Number(obj.gst_rate),
                  total_gst_amount: ((Number(price) * Number(obj.gst_rate)) / 100),
                  sku: obj.sku,
                  gst_rate: Number(obj.gst_rate),
                  customer_id: obj.customer_id,
                  product_id: obj.product_id,
                  qtytotal: obj.qtytotal,
                  price: price,
                  product_mrp: obj.product_mrp,
                  total_amount: obj.total_amount,
                  min_order_qty: obj.min_order_qty,
                  product_name: obj.product_name,
                  product_discription: obj.product_discription,
                  seller_id: obj.seller_id,
                  seller_name: obj.seller_name,
                  base_price: obj.base_price,
                  image_path: obj.image_path,
                  product_type: obj.product_type,
                  gift_amount: this.gift_amount ? this.gift_amount : 0,
                  gift_id: this.gift_id,
                  bundle_price: bundlePrice,
                  userwise_gift_code_usageid: this.userwise_gift_code_usageid,
                  usage_limit_per_user_count: this.usage_limit_per_user_count,
                  category: obj.category,
                  admin_price : obj.admin_price,
                  admin_mrp: obj.admin_mrp
                });
                price = 0;
                // this.cart_items2.push({
                //   'crat_id': obj.crat_id, 'stock_quantity': obj.stock_quantity, 'gst_amount': Number(obj.gst_amount), 'total_gst_amount': Number(obj.gst_amount) * Number(obj.qtytotal), 'sku': obj.sku, 'customer_id': obj.customer_id, 'product_id': obj.product_id, 'qtytotal': obj.qtytotal, 'price': Number(obj.price) + Number(obj.admin_price), 'product_mrp': obj.product_mrp, 'total_amount': obj.total_amount, 'product_name': obj.product_name,
                //   'product_discription': obj.product_discription, 'seller_id': obj.seller_id, 'seller_name': obj.seller_name, 'base_price': obj.base_price, 'image_path': obj.image_path, 'product_type': obj.product_type, 'gift_amount': this.gift_amount ? this.gift_amount : 0, 'gift_id': this.gift_id, 'userwise_gift_code_usageid': this.userwise_gift_code_usageid, 'usage_limit_per_user_count': this.usage_limit_per_user_count, 'category': obj.category
                // });
              }
            }
          }
          else {
            this.cart_items = [];
          }
          this.grandGst = 0;
          this.savedAmount = 0;
          let tempMrp = 0;
          let qty = 0;
  
          for (const gst of this.cart_items) {
            if (
              gst.product_type == 197 ||
              !gst.product_type ||
              gst.product_type == 209
            ) {
              this.grandGst =
                Number(this.grandGst) + Number(gst.total_gst_amount);
              this.grandGst = +parseFloat(this.grandGst.toString()).toFixed(2);
              // this.savedAmount =
              //   Number(this.savedAmount) +
              //   (Number(gst.qtytotal) * Number(gst.product_mrp) -
              //     Number(gst.price));
            this.savedAmount =  Number(this.savedAmount) +  ((Number(gst.qtytotal) * (Number(gst.product_mrp) + Number(gst.admin_mrp))) - (Number(gst.qtytotal) * (Number(gst.base_price))));
              
          } else {
              for (const dtl of gst.details) {
                if (gst.product_type == 209) {
                  this.savedAmount =
                    Number(this.savedAmount) +
                    (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
                    (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
                } else {
                  // this.grandGst = (Number(this.grandGst) + ((Number(dtl.price) + (+dtl.admin_price)) * Number(gst.gst_rate) / 100)) * Number(dtl.quantity);
  
                  this.savedAmount =
                    Number(this.savedAmount) +
                    (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
                    (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
                }
              }
  
              this.grandGst =
                Number(this.grandGst) +
                (Number(gst.price) * Number(gst.gst_rate)) / 100;
              this.grandGst = +parseFloat(this.grandGst.toString()).toFixed(2);
              // this.savedAmount = Number(this.savedAmount) + ((Number(gst.qtytotal) * Number(gst.product_mrp)) -  Number(gst.price));
            }
          }
          this.getGrandTotalForGuest();
          this.isLoading = false;
          if (a == '1') {
            for (const min of this.cart_items) {
              if (Number(min.min_order_qty) > Number(min.qtytotal)) {
                this.toastrService.warning(
                  `minimum quantity can not be less than ${min.min_order_qty} for product ${min.product_name}!`,
                  '',
                  { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                );
                $('.overlay-container').addClass('addcontainerdisable');
                this.removeClass();
      
                return true;
              }
  
              if (
                min.product_type == 208 ||
                min.product_type == 209 ||
                min.product_type == 198
              ) {
                for (const dtl of min.details) {
                  if (Number(min.min_order_qty) > Number(dtl.quantity)) {
                    this.toastrService.warning(
                      `minimum quantity can not be less than ${min.min_order_qty} for product ${min.product_name} - ${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
                        dtl.attribute_value2 ? dtl.attribute_value2 : ''
                      }!`,
                      '',
                      { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                    );
                    $('.overlay-container').addClass('addcontainerdisable');
                    this.removeClass();
                    return true;
                  }
                  if (
                    Number(dtl.quantity) > Number(dtl.stockQty) &&
                    dtl.stockQty != '0'
                  ) {
                    this.toastrService.warning(
                      `${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
                        dtl.attribute_value2 ? dtl.attribute_value2 : ''
                      } only ${dtl.stockQty} stock available`,
                      '',
                      { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                    );
                    $('.overlay-container').addClass('addcontainerdisable');
                    this.removeClass();
                    return false;
                    // }
                  } else {
                    if (dtl.stockQty == '0') {
                      this.toastrService.warning(
                        `${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
                          dtl.attribute_value2 ? dtl.attribute_value2 : ''
                        } Out of Stock `,
                        '',
                        { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                      );
                      $('.overlay-container').addClass('addcontainerdisable');
                      this.removeClass();
                      return false;
                    }
                  }
                }
              } else if (
                (min.product_type != 208 &&
                  min.product_type != 209 &&
                  min.product_type != 198 &&
                  min.product_type == null) ||
                min.product_type == 197
              ) {
                if (
                  Number(min.qtytotal) > Number(min.stock_quantity) &&
                  min.stock_quantity != '0'
                ) {
                  this.toastrService.warning(
                    ` ${Number(min.stock_quantity)} stock available`,
                    '',
                    { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                  );
                  $('.overlay-container').addClass('addcontainerdisable');
                  this.removeClass();
  
                  return false;
                } else {
                  if (min.stock_quantity == '0') {
                    this.toastrService.warning(
                      ` ${min.product_name} out of stock! `,
                      '',
                      { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                    );
                    $('.overlay-container').addClass('addcontainerdisable');
                    this.removeClass();
  
                    return false;
                  }
                }
              }
            }
            this.cart_items[0].gift_amount = this.gift_amount;
            this.cart_items[0].gift_id = this.gift_id;
            this.cart_items[0].userwise_gift_code_usageid =
              this.userwise_gift_code_usageid;
            this.cart_items[0].usage_limit_per_user_count =
              this.usage_limit_per_user_count;
            for (const tot of this.cart_items) {
              tot.total_amount = Number(this.totalAmount);
            }
            let total: any;
            total = Number(this.totalAmount) + Number(this.shipingPrice);
            let total_gst_amount = parseFloat(Number(this.grandGst).toFixed(2));
  
            total = Math.round(total);
            this.buyRazorPay();
          }
        }
       
      },
      (err) => {
        console.log(
          'something went wrong while fetching product from addtocart'
        );
      }
    );
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }
  getGrandTotalForGuest() {
    this.totalAmount = 0;
    let temp_qty = 0;
    for (let prod of this.cart_items) {
      if (prod.product_type == 197 || !prod.product_type ||  prod.product_type == 209  ) {
        this.totalAmount = Number(this.totalAmount) + Number(prod.price);
      } else {
        for (const dtl of prod.details) {
          temp_qty = temp_qty + Number(dtl.quantity);
        }
        this.totalAmount = Number(this.totalAmount) + Number(prod.price);
      }
    }
    if (this.grandGst) {
      this.totalAmount = Number(this.totalAmount) + Number(this.grandGst);
      this.totalAmount = parseFloat(Number(this.totalAmount).toFixed(2));
    }
    this.isLoading = false;
  }
  plus(product, i) {
    $('#minus-btn' + i).removeClass('disabled');
    product.qtytotal = Number(product.qtytotal) + 1;
    var startingPrise = product.base_price
    product.price = Number(startingPrise) * Number(product.qtytotal);
    $('#minus-btn' + i).addClass('disabled');
    this.productService.updateCartItem(product.crat_id, product.qtytotal, product.price, product.price, '').subscribe(res => {
      $('#minus-btn' + i).removeClass('disabled');
      this.getCartData();
    })
  }
  minus(product, i) {
    if (product.qtytotal == 1) {
      // this.btnDisable = true;
      $('#minus-btn' + i).addClass('disabled');
    } else {
      product.qtytotal = Number(product.qtytotal) - 1;
      var startingPrise = product.base_price
      product.price = Number(startingPrise) * Number(product.qtytotal);
      $('#minus-btn' + i).addClass('disabled');
      this.productService.updateCartItem(product.crat_id, product.qtytotal, product.price, product.price, '').subscribe(res => {
        $('#minus-btn' + i).removeClass('disabled');
        this.getCartData();
      })
    }
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  convertToInt() {
    let subtotal = Number(
      Number(this.totalAmount) -
        Number(this.grandGst) 
    );
    return this.rupeeIndian.format(parseFloat(Number(subtotal).toFixed(2)));
  }
  //  goToDetail(id) {
  //   let data = JSON.stringify('selected_prod_id=' + id);
  //   let encriptUrl = this.loginService.encriptDataUrl(data)
  //   this.router.navigateByUrl('/product-details?' + encriptUrl);
  //   // localStorage.setItem("selected_prod_id", id)
  //   // this.router.navigateByUrl('product-details')

  // }
  remove(id) {
    if (this.cart_items.length == 1) {
      this.toastrService.warning(
        'You can not remove this item from here.',
        '',
        { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
      );
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    } else {
      if (this.cart_items[0].quck_order) {
        this.cart_items = this.cart_items.splice(
          this.cart_items.findIndex((item) => item.product_id != id),
          1
        );
        this.grandGst = 0;
        for (const gst of this.cart_items) {
          this.grandGst = Number(this.grandGst) + Number(gst.total_gst_amount);
          this.grandGst = parseFloat(Number(this.grandGst).toFixed(2));
          //  gst.qtytotal = gst.qty;
        }
        this.totalAmount = 0;
        let discountPer = 0;
        for (let pro of this.cart_items) {
          this.totalAmount =
            Number(this.totalAmount ? this.totalAmount : 0) +
            Number(pro.price ? pro.price : 0);
          discountPer = Math.round(
            (((pro.product_mrp ? pro.product_mrp : 0) -
              (pro.price ? pro.price : 0)) *
              100) /
              (pro.product_mrp ? pro.product_mrp : 0)
          );
          pro.discountPer = discountPer;
        }
        this.totalAmount = Number(this.totalAmount) + Number(this.grandGst);
        this.totalAmount = parseFloat(Number(this.totalAmount).toFixed(2));
      } else {
        this.productService.removeCartItem(this.customer_id, id).subscribe(
          (res) => {
            if (res.status == 200) {
              this.toastrService.success(res.message, '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.getCartData();
              // setTimeout(() => {
              //   window.location.reload();
              // }, 100);
            }
          },
          (err) => {
            this.toastrService.error('Something went wrong !', '', {
              timeOut: this.MassageOutTime,
              positionClass: 'toast-top-center',
            });
            return false;
          }
        );
      }
    }
  }
  orderAdd() {
    if (
      this.customer_id == null ||
      this.customer_id == undefined ||
      this.customer_id == 'undefined' ||
      this.customer_id == ''
    ) {
      $('#login-modal').modal('show');
      return false;
    }
    if (
      this.selected_billing_id == null ||
      this.selected_billing_id == undefined ||
      this.selected_billing_id == ''
    ) {
      this.toastrService.warning('Please select billing address', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    if (this.shipType == 'single') {
      if (
        this.selected_add_id == null ||
        this.selected_add_id == undefined ||
        this.selected_add_id == ''
      ) {
        this.toastrService.warning('Please select shipping address', '', {
          timeOut: this.MassageOutTime,
          positionClass: 'toast-top-center',
        });
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();
        return false;
      }
    } else {
      console.log(this.multipleAddressList, this.shipType);
      if (
        this.multipleAddressList == undefined ||
        this.multipleAddressList == null
      ) {
        this.toastrService.warning('Please upload address details ', '', {
          timeOut: this.MassageOutTime,
          positionClass: 'toast-top-center',
        });
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();
        return false;
      }
      if (this.multipleAddressList.length < 1) {
        this.toastrService.warning('Please upload address details ', '', {
          timeOut: this.MassageOutTime,
          positionClass: 'toast-top-center',
        });
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();
        return false;
      }
    }
    $("#order-add").addClass('disabled');
    // this.cart_items[0].total_amount = Number(this.totalAmount)
    for (const tot of this.cart_items) {
      tot.total_amount = Number(this.totalAmount);
    }
    let total:any
    total = Number(this.totalAmount) + Number(this.shipingPrice);
    let total_gst_amount = parseFloat(Number(this.grandGst).toFixed(2))
    this.getCartData(1)
    // $('#payment').modal('show');

    total = Math.round(total)
    this.buyRazorPay()
  }

  continueShopping(){
    $('#orderPlaced').modal('hide');
    this.router.navigateByUrl('/buyer-account')
  }

  buyRazorPay() {
    $('#payment-failed').modal('hide');
      // this.totalAmount 
    let amount  = Math.round(Number(this.totalAmount))
      this.customerService.makeOrderPayment(amount, 'INR').subscribe( async res =>{
        console.log('sorderpyemntsusce',res)
        this.razorPayOptions.amount = (Number(res.amount) * 100) + '';
        this.razorPayOptions.order_id = res.id
        // this.razorPayOptions.key = res.key
        this.razorPayOptions['handler'] = await this.razorPaySuccessHandler.bind(this);
        this.razorPayOptions["modal.ondismiss"] = await this.paymentCancel.bind(this);
         this.razorpay = new Razorpay(this.razorPayOptions)
          if(this.customer_id || this.totalAmount && this.grandGst)
          this.razorpay.open();
        this.razorpay.on('payment.failed', function (response: any){
          if(response.error.reason == "payment_failed"){
            $('#payment-failed').modal('show')
            this.failedPaymentId = response.error.metadata.payment_id;
            this.failedPaymentOrderId = this.razorPayOptions.order_id;
            this.toastrService.error("Payment failed for Payment ID:" + response.error.metadata.payment_id  , '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          }else{
          }
          
          //this.error = response.error.reason;
  
      }
  
  );
        // console.log('check for response', res);
     })
    
  }
  public razorPaySuccessHandler(response) {
    this.paymentId = response.razorpay_payment_id
    this.showModal = true;
    if(this.paymentId){
    //   if(this.shipType != 'single')
    //  this.multipleAddressList =  JSON.stringify(this.multipleAddressList) 
      this.customerService.checkPaymentstatus(this.razorPayOptions.order_id, this.paymentId, response.razorpay_signature).subscribe(res=>{
        if(res.status == "captured"){
           this.productService.addCustomerOrder(this.customer_id, this.totalAmount,
            this.paymentId,
            this.grandGst,
            this.selected_add_id,
            this.selected_billing_id,
            JSON.stringify(this.cart_items)).subscribe(res => {
            if (res.status == 200) {
              this.toastrService.success("Order placed successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
             
              this.orderNo = res.result.rows[0].order_number;
              this.totalPrice = res.result.rows[0].total_amount;
              // $('#orderPlaced').modal('hide');
              if(this.totalPrice > 0)
              $('#orderPlaced').modal('show');
            } else {
              this.toastrService.warning("Failed", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
              $("#order-add").resmoveClass('disabled');
            }
          })
        }
      }, err=>{
        this.toastrService.error(" Something went wrong", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      })
    }else{
    this.toastrService.error("Payment failed", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    }
    this.cd.detectChanges()
    // document.getElementById('razorpay-response').style.display = 'block';
  }
  paymentCancel(res){
    $('#payment-failed').modal('show')
    this.toastrService.error("Payment failed", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    // $(".overlay-container").addClass("addcontainerdisable");
  }
  checkForExcelColumn(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((value, index) => value === arr2[index]);
  }

  // async upload(event) {
  //   if (
  //     this.customer_id == null ||
  //     this.customer_id == undefined ||
  //     this.customer_id == 'undefined' ||
  //     this.customer_id == ''
  //   ) {
  //     $('#login-modal').modal('show');
  //     return false;
  //   }
  //   this.excFile = event.target.files[0];

  //   let files = event.target.files;
  //   var fname = files[0].name;
  //   var re = /(\.xlsx|\.xlsb)$/i;
  //   if (!re.exec(fname)) {
  //     this.toastrService.warning('File Type Not Supported!', '', {
  //       timeOut: this.MassageOutTime,
  //       positionClass: 'toast-top-center',
  //     });
  //     this.myInputVariable.nativeElement.value = '';
  //     $('.overlay-container').addClass('addcontainerdisable');
  //     this.removeClass();
  //     return ;
  //   }
  //   var extarr = files[0].name.split('.');
  //   if (extarr.length > 2) {
  //     this.toastrService.warning(
  //       'File name can not have more than one extension',
  //       '',
  //       { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
  //     );
  //     this.myInputVariable.nativeElement.value = '';
  //     $('.overlay-container').addClass('addcontainerdisable');
  //     this.removeClass();
  //     this.removeClass();
  //     return ;
  //   }
  //   let fileReader = new FileReader();
  //   fileReader.onload = async (e) => {
  //     this.arrayBuffer = fileReader.result;
  //     var data = new Uint8Array(this.arrayBuffer);
  //     var arr = new Array();
  //     for (var i = 0; i != data.length; ++i)
  //       arr[i] = String.fromCharCode(data[i]);
  //     var bstr = arr.join('');
  //     var workbook = XLSX.read(bstr, { type: 'binary' });
  //     var first_sheet_name = workbook.SheetNames[0];
  //     var worksheet = workbook.Sheets[first_sheet_name];
  //     this.multipleAddressList = XLSX.utils.sheet_to_json(worksheet, {
  //       raw: true,
  //     });
    
  //     this.sqlQuery = '';
  //     let orderQty = 0;
      
  //     console.log(this.multipleAddressList, 'this.multipleAddressList');
  //     for (let ob of this.multipleAddressList) {
  //       let exelkey = Object.keys(ob);
  //       // let myheader = ['Full Name',	'Mobile No.',	'E-mail ID',	'PIN Code',	'Locality',	'Address',	'Country',	'State	City',	'Landmark']
  //       // myheader.every(x => x)
  //       for (let key in ob) {
  //         if (key.includes('(Qty)')) {
  //           let sku = key.split('(')[0]
  //           let obj  = {sku: sku, qty: ob[key]}
  //           this.keylist.push(obj);
  //         }
  //       }
  //     }


  //     const result = this.keylist.reduce((acc, obj) => {
  //       const key = obj.sku;
  //       if (!acc[key]) {
  //         acc[key] = { sku: key, qty: 0 };
  //       }
  //       acc[key].qty += obj.qty;
  //       return acc;
  //     }, {});
      
  //     this.keylist = Object.values(result);
  //     let totalQuantityInSheet = 0;
  //     let totalOrderedQuqntity = 0;
  //     for (const sum of this.keylist) {
  //         totalQuantityInSheet += sum.qty
  //     }
  //     // this.cart_items.forEach((item) => {
  //       for (const item of this.cart_items) {
  //       totalOrderedQuqntity += Number(item.qtytotal);
  //       if(item.product_type == 198 || item.product_type == 208){
  //             for (const dtl of item.details) {
  //                let newList =   this.keylist.filter(x => x.sku == dtl.sku) || [];
  //                if(newList.length > 0){
  //                   if(newList[0].qty != dtl.quantity){
  //                     this.toastrService.warning(`${dtl.sku} quantity should be ${dtl.quantity}`, '', {
  //                       timeOut: this.MassageOutTime,
  //                       positionClass: 'toast-top-center',
  //                     });
  //                     this.myInputVariable.nativeElement.value = '';
  //                     this.multipleAddressList = [];
  //                     $('.overlay-container').addClass('addcontainerdisable');
  //                     this.removeClass();
  //                     // break;
  //                     return

  //                   }
  //                }
  //             }
  //       }else{
  //         let newList =   this.keylist.filter(x => x.sku == item.sku) || [];
  //         if(newList.length > 0){
  //            if(newList[0].qty != item.qtytotal){
  //             //  console.log('sku quantity msit sim')
  //             this.toastrService.warning(`${item.sku} quantity should be ${item.qtytotal} `, '', {
  //               timeOut: this.MassageOutTime,
  //               positionClass: 'toast-top-center',
  //             });
  //             this.myInputVariable.nativeElement.value = '';
  //             this.multipleAddressList = [];
  //             $('.overlay-container').addClass('addcontainerdisable');
  //             this.removeClass();
  //             // break;
  //             return
  //           }
  //         }
         
  //       }
     
  //     }
   

  //     for (let multi of this.multipleAddressList) {
  //       let sname = null;
  //       multi['Full Name'] = multi['Full Name'].trim();
  //       if (
  //         multi['Full Name'] == null ||
  //         multi['Full Name'] == undefined ||
  //         multi['Full Name'] == ''
  //       ) {
  //         this.toastrService.warning('Full Name is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();
  //         return false;
  //       }
  //       if (
  //         multi['Mobile No.'] == null ||
  //         multi['Mobile No.'] == undefined ||
  //         multi['Mobile No.'] == ''
  //       ) {
  //         this.toastrService.warning('Mobile No.. is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       var phoneno = /^\(?([6-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{6})$/;
  //       if (phoneno.test(multi['Mobile No.']) == false) {
  //         this.toastrService.warning(
  //           'Please enter valid 10-digit Mobile No.',
  //           '',
  //           { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
  //         );
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();
  //         return false;
  //       }
  //       if (
  //         multi['E-mail ID'] == null ||
  //         multi['E-mail ID'] == undefined ||
  //         multi['E-mail ID'] == ''
  //       ) {
  //         this.toastrService.warning('E-mail ID is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       var validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //       if (validEmailRegex.test(multi['E-mail ID']) == false) {
  //         this.toastrService.warning(
  //           `${multi['E-mail ID']} is not a valid Email-ID`,
  //           '',
  //           { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
  //         );
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       if (
  //         multi['PIN Code'] == null ||
  //         multi['PIN Code'] == undefined ||
  //         multi['PIN Code'] == ''
  //       ) {
  //         this.toastrService.warning('PinCode is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       if (multi['PIN Code'].toString().length != 6) {
  //         this.toastrService.warning(
  //           'PinCode should be length of 6 Digit',
  //           '',
  //           { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
  //         );
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       if (
  //         multi['Address'] == null ||
  //         multi['Address'] == undefined ||
  //         multi['Address'] == ''
  //       ) {
  //         this.toastrService.warning('Address is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       if (
  //         multi['Country'] == null ||
  //         multi['Country'] == undefined ||
  //         multi['Country'] == ''
  //       ) {
  //         this.toastrService.warning('Please Select Country', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       if (
  //         multi['State'] == null ||
  //         multi['State'] == undefined ||
  //         multi['State'] == ''
  //       ) {
  //         this.toastrService.warning('State is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       if (
  //         multi['City'] == null ||
  //         multi['City'] == undefined ||
  //         multi['City'] == ''
  //       ) {
  //         this.toastrService.warning('City is Required', '', {
  //           timeOut: this.MassageOutTime,
  //           positionClass: 'toast-top-center',
  //         });
  //         this.myInputVariable.nativeElement.value = '';
  //         this.multipleAddressList = [];
  //         $('.overlay-container').addClass('addcontainerdisable');
  //         this.removeClass();

  //         return false;
  //       }
  //       // orderQty = orderQty + this.total_quantity_EXCEL(multi);
  //     }
  //     if (totalOrderedQuqntity == totalQuantityInSheet) {
  //       this.totalInsertedRows = this.multipleAddressList.length;
  //       this.toastrService.success('Uploaded Successfully', '', {
  //         timeOut: this.MassageOutTime,
  //         positionClass: 'toast-top-center',
  //       });
  //       $('.overlay-container').addClass('addcontainerdisable');
  //       this.removeClass();
  //       return true;
  //     } else {
  //       this.toastrService.warning('Order Quantity Mismatch', '', {
  //         timeOut: this.MassageOutTime,
  //         positionClass: 'toast-top-center',
  //       });
  //       $('.overlay-container').addClass('addcontainerdisable');
  //       this.removeClass();
  //       this.myInputVariable.nativeElement.value = '';
  //       this.multipleAddressList = [];
  //       return true;
  //     }
  //   };
  //   fileReader.readAsArrayBuffer(this.excFile);
  // }

  // total_quantity_EXCEL(arr) {
  //   let a: any = Object.fromEntries(
  //     Object.entries(arr).filter(([key]) => key.includes('(Qty)'))
  //   );
  //   var sum = 0;
  //   for (var el in a) {
  //     if (a.hasOwnProperty(el)) {
  //       sum += parseFloat(a[el]);
  //     }
  //   }
  //   return sum;
  // }
  ngAfterViewChecked() {
    $('.toast-close-button').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
    $('.toast-container').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
  }
}