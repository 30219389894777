import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../_services/AppSetting'
import * as CryptoJS from 'crypto-js';
import { resolve } from 'url';
import { Router,ActivatedRoute } from '@angular/router'; 

let SecreatKey="#@Huno123*&";
//let e_auth_url=
@Injectable()
export class LoginService {
  
    constructor(
        private http: HttpClient,
        private route :ActivatedRoute,
        private router: Router,
        
      ) { }
      url=AppSettings.API_ENDPOINT;
      private _jsonURL = '../assets/js/menu1.json';
      // private processJsonUrl = '../assets/js/menu.json';
      private processJsonUrl = '../assets/js/process-json.json';
        

      getLabelJSON(): Observable<any> {
        return this.http.get(this._jsonURL).pipe(map((data) => {
          return data as any;
        }, error => {
          console.log("there was an error sending the query", error);
        }
        ));
      }

      getFormData(queryStr){
        console.log("queryStr-->",queryStr)
        const headers = new HttpHeaders()
        headers.append('application/json', 'Content-Type')
        var QueryObj={"strSQL":queryStr};
        return this.http.post(this.url,QueryObj , { headers }).pipe(map(res => {
          //console.log(JSON.stringify(res));
          return res as any;
        },error => {
          return error as any;
        }))
      }

      getProcessJSON(): Observable<any> {
        return this.http.get(this.processJsonUrl).pipe(map((data) => {
          return data as any;
        }, error => {
          console.log("there was an error sending the query", error);
        }
        ));
      }

      getEncryptedData(data){
        console.log(data)
        let EncryptedData=  CryptoJS.AES.encrypt(data, SecreatKey).toString();
        return EncryptedData;
      }

      getIndustry(){
        const headers = new HttpHeaders();
        return this.http.post(this.url + "/getIndustry", { headers }).pipe(map(res => {
          return res as any;
        }, error => {
          return error as any;
        }))
      }

      getDecryptedData(data){
        let DecryptedData=  CryptoJS.AES.decrypt(data, SecreatKey).toString();
        return DecryptedData;
      }

      getCaptchaDecrypted(data){
          var slted = CryptoJS.AES.decrypt(data, SecreatKey);
          var capchaCode = slted.toString(CryptoJS.enc.Utf8);        
          return capchaCode;
      }

      getCatcha(): Observable<any> {
        return this.http.post(this.url + '/getCaptcha',null).pipe(map((data) => {
          return data as any;
        }, error => {
          console.log("there was an error sending the query", error);
        }
        ));
      }

      getToken(username ,password) {
        return this.http.post(this.url + '/token/login',{username,password}).pipe(map((data) => {
          return data as any;
        }, error => {
          console.log("there was an error sending the query", error);
        }
        ));
      }

      getUsrData() {
        return this.http.post(this.url + '/token/getUserData',null).pipe(map((data) => {
               return data as any;
             }, error => {
               console.log("there was an error sending the query", error);
             }
             ));
           }


           decreptedDataUrl(data){
            
            let removeSpeaceUrl = Object.keys(data)[0]?.replace(/\s/g,'+');
            // console.log("url" , removeSpeaceUrl+'==')
            let decriptData = CryptoJS.AES.decrypt( removeSpeaceUrl+'==', AppSettings.SecreatKey).toString(CryptoJS.enc.Utf8);
           // console.log("encriptData==>",decriptData)
            return  decriptData;
            
        }        
        
        encriptDataUrl(data){
          let encriptData =CryptoJS.AES.encrypt(data, AppSettings.SecreatKey).toString()
          return encriptData;

        }

}