<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

            <div class="container-fluid ">
                    <div class="tabs col-lg-12 nopadding py-3">
                            <div class="tab-button-outer cstmmobiletab">
                                    <ul id="tab-button">
                                      <li *ngIf="currentRouter == 'draft'"><a href="#tab01" class="font8">Parked</a></li>
                                      <li *ngIf="currentRouter == 'submitted'"><a href="#tab02" class="font8">Submitted</a></li>
                                      <li *ngIf="currentRouter == 'approved' && clickLink == 'approved'"><a href="#tab02" class="font8">Approved</a></li>
                                      <li *ngIf="currentRouter == 'drafts'"><a href="#tab03" class="font8">Draft</a></li>

                                    </ul>
                    
                                    
                    </div>
                    <div id="tab01" class="tab-contents mmt5 p-0" *ngIf="currentRouter == 'draft'">
                            <div class="col-sm-12" *ngIf="(InboxLoaded | async)"> 
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12   nopadding "   >
                                       <div class="col-sm-12 col-md-12 mt-2 mb-2 nopadding">
                                            <!-- <ag-grid-angular 
                                            style="width: 100%;" 
                                                class="ag-theme-alpine"
                                                suppressCsvExport="true"
                                                [pagination]="true"
                                                [paginationPageSize]="pagesize"
                                                [rowData]="inboxRows" (columnResized)="onColumnResized($event)"
                                                (columnVisible)="onColumnVisible($event)"
                                                domLayout="autoHeight"
                                                [frameworkComponents]="frameworkComponents"
                                                [columnDefs]="inboxColumndef">
                                            </ag-grid-angular> -->
                                            <igx-grid #grid1 [data]="inboxRows" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="10" (onDataPreLoad)="actionstrip.hide()" (onRowSelectionChange)="handleRowSelection($event)">
                                              <ng-container *ngFor="let col of inboxColumndef let i=index">
                                                  <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                                                      <ng-template igxCell let-cell="cell">
                                                          {{cell.value}}
                                                          <!-- <a class="truncate" href="javascript:void(0)"
                    (click)="ShowViewData(cell)">{{cell.value}}</a> -->
                                                      </ng-template>
                                                  </igx-column>
                                                  <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable" [filterable]="col.filter"></igx-column>
      
                                              </ng-container>
                                              <igx-action-strip #actionstrip>
                                                  <div class="drop-down-wrapper">
                                                      <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting" [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu" igxButton="icon">
                        <igx-icon>more_vert</igx-icon>
                      </button>
                                                      <igx-drop-down #menu [allowItemsFocus]="true">
                                                          <ng-container *ngFor="let item of columndraftActionStrip">
                                                              <igx-drop-down-item (click)="onProcess(actionstrip.context,item.field)">
                                                                  <div>
                                                                      <span><i class="fa fa-{{ item.iconName }}"></i>
                                {{ item.field }}
                              </span>
                                                                  </div>
                                                              </igx-drop-down-item>
                                                          </ng-container>
                                                      </igx-drop-down>
                                                      <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                                                  </div>
                                              </igx-action-strip>
                                          </igx-grid>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                    </div>

                    <div id="tab02" class="tab-contents p-0 mmt5 " *ngIf="currentRouter == 'submitted' || currentRouter == 'approved'">
                            <div class="col-sm-12" *ngIf="(OutboxLoaded | async)" > 
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12   nopadding "   >
                                       <div class="col-sm-12 col-md-12 mt-2 mb-2 nopadding">                                   
                                    <igx-grid #grid1 [data]="outboxRows" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="10" (onDataPreLoad)="actionstrip.hide()" (onRowSelectionChange)="handleRowSelection($event)">
                                      <ng-container *ngFor="let col of outboxColumndef let i=index">
                                          <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                                              <ng-template igxCell let-cell="cell">
                                                {{cell.value}}                                                  
                                              </ng-template>
                                          </igx-column>
                                          <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable" [filterable]="col.filter"></igx-column>
                                          
                                          <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName == 'Action' && currentRouter == 'approved'"  [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                                            <ng-template igxCell let-cell="cell">
                                              <a href="javascript:void(0)" class=" btn btn-warning btn-sm rounded-pill px-3 " (click)="viewproposal(cell)">View </a>
                                            </ng-template>
                                         </igx-column>
                                      </ng-container>
                                      
                                  </igx-grid>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                        </div>

                        <div id="tab03" class="tab-contents mmt5 p-0" *ngIf="currentRouter == 'drafts'">
                                <div class="row">
                                        <div class="col-lg-12">                      
                                          <app-modal #modalRoot class="modal-demo custommodal">
                                            <ng-container class="app-modal-header p-2">Add Rule</ng-container>
                                            <ng-container class="app-modal-body">
                                              <app-user-rule [formId]="formId" [SessionUrlPrams]="sessionUrlPrams"
                                                [resetFilter]="isResetFilter" (applyEvent)="apply($event)"></app-user-rule>
                                            </ng-container>
                                            <ng-container class="app-modal-footer p-2 text-right">
                                              <button type="button" id="clos" (click)="modalRoot.hide()" style="display: none">Close</button>
                                            </ng-container>
                                          </app-modal>
                                          <div class="grid__wrapper tableMain p-0">
                                            <igx-grid #grid1 
                                              [data]="inboxRows" 
                                              width="100%" height="" 
                                              [autoGenerate]="false"
                                              [allowFiltering]="true" 
                                              displayDensity="cosy" 
                                              [filterMode]="'excelStyleFilter'"
                                              [paging]="true" [perPage]="10"
                                              (onDataPreLoad)="actionstrip.hide()"
                                              (onRowSelectionChange)="handleRowSelection($event)">
                                              <ng-container *ngFor="let col of inboxColumndef let i=index">
                                                <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                                                  [minWidth]='"200px"' [sortable]="col.sortable" [filterable]="col.filter">
                                                  <ng-template igxCell let-cell="cell">
                                                    {{cell.value}}                                                 
                                                  </ng-template>
                                                </igx-column>
                                                <igx-column [field]="col.field" [header]="col.headerName"
                                                  *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                                                  [filterable]="col.filter"></igx-column>
                      
                                              </ng-container>
                                              <igx-action-strip #actionstrip>
                                                <div class="drop-down-wrapper">
                                                  <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting"
                                                    [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu" igxButton="icon">
                                                    <igx-icon>more_vert</igx-icon>
                                                  </button>
                                                  <igx-drop-down #menu [allowItemsFocus]="true">
                                                    <ng-container *ngFor="let item of columnActionStrip">
                                                      <igx-drop-down-item (click)="onProcesss(actionstrip.context)">
                                                        <div>
                                                          <span><i class="fa fa-{{ item.iconName }}"></i>
                                                            {{ item.field }}
                                                          </span>
                                                        </div>
                                                      </igx-drop-down-item>
                                                    </ng-container>
                                                  </igx-drop-down>
                                                  <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                                                </div>
                                              </igx-action-strip>
                                            </igx-grid>
                                          </div>
                                        </div>
                                      </div>
                        </div>

                </div>
                </div>


</div></div>