<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/total-seller">
                  Seller List</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Product Listing
                <b>({{sellerName}})</b>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="date-filter-box">
        <div class="mt-2">
          <h6 class="font-weight-bold mb-sm-3">Select a time period</h6>
          <div class="row mb-3">
            <div class="col-sm-2 col-md-1 text-sm-right">
              <label for="start-date">From Date</label>
            </div>
            <div class="col-sm-3 col-md-2">
              <input type="date" class="form-control" [(ngModel)]="fromDate" id="start-date" max="{{getMinDate()}}">
            </div>
            <div class="col-sm-2 col-md-1 text-sm-right">
              <label for="end-date">To Date</label>
            </div>
            <div class="col-sm-3 col-md-2  pl-md-0">
              <input type="date" class="form-control" [(ngModel)]="todate" id="end-date" max="{{getMinDate()}}">
            </div>
            <div class="col-sm pl-sm-0 text-sm-left text-center">
              <button (click)="filterdatewise()" class="btn btn-sm btn-theme py-1 px-sm-3 px-4 mt-2 mt-md-0">
                <i class="fa fa-search mr-1"></i> Search</button>
            </div>
            <div class="col-sm-2 col-md-1 text-sm-right offset-lg-2">
              <label for="category">Category
              </label>
            </div>
            <div class="col-sm-3 col-md-2">
              <select name="category" id="category" class="form-control" (change)="onSelectCategory()" name="selectedCategory" [(ngModel)]="selectedCategory">
                <option value="undefined">Select Category</option>
                <option *ngFor="let cat of categoryList" [value]="cat.product_category_id">
                  {{cat.category_name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>


      <div class="col-sm-12 mb-2 p-0">
        <div class="tabs row my-2 mx-0">

          <!-- <ul class="breadcrumb">
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Pictures</a></li>
                </ul> -->
          <div class="col-lg-5 px-0 mb-2">
            <div class="white-space-pre-x">
              <div class="tab-button-outer1">
                <ul id="tab-button" class="tbsbtns">
                  <li id="tabDraft" class="is-active">
                    <a href="javascript:void(0)" class="font8" (click)="ProductApprovedList()">Approved List
                      <span>({{approvedCount}})</span>
                    </a>
                  </li>
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="productSuspendedList()">Suspended List
                      <span>({{suspendedCount}})</span>
                    </a>
                  </li>
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="productBlockList()">Block List
                      <span>({{blockCount}})</span>
                    </a>
                  </li>
                  <li id="" class="mb-sm-0 mb-2">
                    <a href="javascript:void(0)" class="font8" (click)="productRejectedList()">Rejected List
                      <span>({{rejectCount}})</span>
                    </a>
                  </li>
                  <!-- <li id="" class="">
                                <a href="javascript:void(0)" class="font8" (click)="productArchivedList()">Archived List
                                  <span>(0)</span>
                                </a>
                              </li> -->
                  <!-- <li id="" class="">
                                      <a href="javascript:void(0)">
                                        <select name="" id="" class=" " (change)="addNew()" [(ngModel)]="product_type_id">
                                            <option value="undefined">Add New Listings</option>
                                            <option *ngFor="let pro of productsTypeList" value="{{pro.id}}">{{pro.varibale_name}}</option>
                                        </select>
                                        <span>&nbsp; </span>
                                      </a>
                                    </li> -->

                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-auto px-0 newlistings">
            <select name="" id="" class=" form-control ml-lg-2" (change)="addNew()" [(ngModel)]="product_type_id">
              <option value="undefined">Add New Listings</option>
              <option *ngFor="let pro of productsTypeList" value="{{pro.id}}">{{pro.varibale_name}}</option>
            </select>
          </div>
          <!-- <div class="col-lg-2">
              
            </div> -->
          <div class="col-sm d-none d-md-block px-0" id="masterSearch">
            <div class="sort-wrapper pull-right width100">
              <div class="form-group col-lg-12 pr-0">
                <div class=" input-group">
                  <input type="search" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                    placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                  <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                    <i class="fa fa-search"></i>
                  </a>
                </div>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
        <div id="tab01" class="tab-contents mmt5 p-0">
          <div class="col-sm-12 col-md-12  nopadding">
            <div id="grid-theme-wrapper" *ngIf='approvedDataShow'>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoader"></ngx-loading>
              <div class="table-responsive" *ngIf="allProductList.length > 0">
                <igx-grid #grid1 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="/seller-profile" target="_blank">
                          <span (click)="showSellerDetails(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="product_name" header="Product Name" width="360">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mrp" header="MRP" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{ cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="category_name" header="Product Category" width="167">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="price" header="Price" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null  ">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="purchase_price" header="Purchase Price(₹)" width="148">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="producttype" header="Product Type" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="brand_name" header="Brand Name" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="hsn" header="HSN" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="suk" header="SKU" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="date" header="Upload Date" width="155">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->

                  <!-- <igx-column field="qcstatus_value" header="Status" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                       
                      </div>
                    </ng-template>
                  </igx-column> -->

                  <igx-column field="" header="Action" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="px-2">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop6">
                            <li class="border-bottom">
                              <a href="javascript:void(0)" (click)="updateStatusPopup(cell,'block')" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa-ban mr-1"></i> Block
                              </a>

                            </li>
                            <li class="border-bottom">
                              <a href="javascript:void(0)" (click)="onProductEdit(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa-pencil mr-1" aria-hidden="true"></i> Edit  </a>
                              <!-- <a href="javascript:void(0)" (click)="updateStatusPopup(cell,'edit')" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa-ban mr-1"></i> Edit
                              </a> -->

                            </li>
                            <li>
                              <a href="javascript:void(0)" (click)="updateStatusPopup(cell, 'suspend')" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">

                                <i class="fa fa-times-circle mr-1"></i>
                                Suspend
                              </a>

                            </li>


                          </ul>

                        </div>
                      </div>



                      <!-- <div class="address-container">

                      <button (click)="onProductEdit(cell)" [disabled]="cell.rowData.createdby != 325" class="btn btn-sm btn-success px-4">
                        Edit
                      </button>

                    </div> -->
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>

            <!-- Suspended -->
            <div id="grid-theme-wrapper" *ngIf='susendedDataShow'>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid1($event)" *ngIf="LoadPagination1 == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoader"></ngx-loading>
              <div class="table-responsive" *ngIf="allProductList.length > 0">
                <igx-grid #grid2 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="/seller-profile" target="_blank">
                            <span (click)="showSellerDetails(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column> -->
                  <igx-column field="product_name" header="Product Name" width="360">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mrp" header="MRP" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{ cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="category_name" header="Product Category" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="price" header="Price" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null  ">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <!-- <igx-column field="purchase_price" header="Purchase Price(₹)" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="producttype" header="Product Type" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="brand_name" header="Brand Name" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="hsn" header="HSN" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <igx-column field="" header="Action" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="px-2">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                            <li>
                              <a href="javascript:void(0)" (click)="onProductView(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                <i class="fa fa-check-circle"></i> Active
                              </a>

                            </li>

                          </ul>

                        </div>
                      </div>


                      <!-- <div class="address-container">


                      <button (click)="onProductView(cell)" class="btn btn-sm px-3 btn-theme bg-success border-success  mr-2">
                        <i class="fa fa-check-circle"></i> Active
                      </button>

                    </div> -->

                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
            <!-- Block-->
            <div id="grid-theme-wrapper" *ngIf='blockDataShow'>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid2($event)" *ngIf="LoadPagination2 == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoader"></ngx-loading>
              <div class="table-responsive" *ngIf="allProductList.length > 0">
                <igx-grid #grid3 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <a href="/seller-profile" target="_blank">
                              <span (click)="showSellerDetails(cell)">{{ cell.value }}
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </igx-column> -->
                  <igx-column field="product_name" header="Product Name" width="320">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mrp" header="MRP" width="160">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{ cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="category_name" header="Product Category" width="145300">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="price" header="Price" width="160">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null  ">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <!-- <igx-column field="purchase_price" header="Purchase Price(₹)" width="148">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="producttype" header="Product Type" width="180">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="brand_name" header="Brand Name" width="160">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="hsn" header="HSN" width="160">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="date" header="Upload Date" width="160">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <!-- <igx-column field="qcstatus_value" header="Status" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                           
                          </div>
                        </ng-template>
                      </igx-column> -->

                  <igx-column field="qcstatus" header="Action" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="px-2">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop7">
                            <li>
                              <a href="javascript:void(0)" (click)="onProductView(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">

                                <i class="fa fa-unlock-alt"></i> Unblock
                              </a>

                            </li>


                          </ul>

                        </div>
                      </div>

                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
            <div id="grid-theme-wrapper" *ngIf='rejectDataShow'>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid3($event)" *ngIf="LoadPagination3 == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoader"></ngx-loading>
              <div class="table-responsive" *ngIf="allProductList.length > 0">
                <igx-grid #grid4 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <a href="/seller-profile" target="_blank">
                              <span (click)="showSellerDetails(cell)">{{ cell.value }}
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </igx-column> -->
                  <igx-column field="product_name" header="Product Name" width="360">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mrp" header="MRP" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{ cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="category_name" header="Product Category" width="185300">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="price" header="Price" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null  ">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="purchase_price" header="Purchase Price(₹)" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="producttype" header="Product Type" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="brand_name" header="Brand Name" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="hsn" header="HSN" width="185">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="date" header="Upload Date" width="155">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
            <div id="grid-theme-wrapper" *ngIf='archivedDataShow'>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid4($event)" *ngIf="LoadPagination4 == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoader"></ngx-loading>
              <div class="table-responsive" *ngIf="allProductList.length > 0">
                <igx-grid #grid5 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <a href="/seller-profile" target="_blank">
                              <span (click)="showSellerDetails(cell)">{{ cell.value }}
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </igx-column> -->
                  <igx-column field="product_name" header="Product Name" width="375">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mrp" header="MRP" width="110">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{ cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="category_name" header="Product Category" width="156300">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="price" header="Price" width="100">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container" *ngIf="cell.rowData.createdby != null  ">
                        <a href="javascript:void(0)" *ngIf="cell.rowData.product_type == 197 || cell.rowData.product_type == 199">
                          <span class="cell-vl " (click)="onPriceEdit(cell)">{{cell.value ? cell.value : 0 }}
                          </span>
                        </a>
                        <span *ngIf="cell.rowData.product_type == 208 || cell.rowData.product_type == 198" class="cell-vl " [innerHtml]="cell.value"></span>

                      </div>
                      <div class="address-container" *ngIf="cell.rowData.createdby == null">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <!-- <igx-column field="purchase_price" header="Purchase Price(₹)" width="148">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="producttype" header="Product Type" width="156">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="brand_name" header="Brand Name" width="156">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="hsn" header="HSN" width="112">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                        <!-- [innerHtml]="cell.value ? cell.value : 0" -->
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="date" header="Upload Date" width="155">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
            <div id="grid-theme-wrapper" *ngIf='groupedDataShow'>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid5($event)" *ngIf="LoadPagination5 == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoader"></ngx-loading>
              <div class="table-responsive" *ngIf="allProductList.length > 0">
                <igx-grid #grid6 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <a href="/seller-profile" target="_blank">
                              <span (click)="showSellerDetails(cell)">{{ cell.value }}
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </igx-column> -->
                  <igx-column field="product_name" header="Product Name" width="375">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="category_name" header="Product Category" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="price" header="Product Price" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mrp" header="MRP" width="110">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="date" header="Upload Date" width="155">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
            
          </div>
          <label class="w-100 text-center text-dark" *ngIf="!isLoader && allProductList.length == 0">No Record Found</label>
        </div>
      </div>

    </div>
  </div>

  <!-- view model -->

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header p-2 align-items-center">
          <h6 class="modal-title font600 font9" id="exampleModalLabel">Product Details</h6>
          <button type="button" class="close" data-dismiss="modal">
            <span> &times;</span>
          </button>
        </div>
        <div class="modal-body minhyt70 p-0" *ngIf="isView">
          <app-product-details [isView]="isView" [view_item_id]="view_item_id"></app-product-details>
        </div>
        <!-- <div class="modal-footer p-2">
          <button type="button" class="btn btn-theme bg-danger border-danger btn-sm b" data-dismiss="modal">
            Close
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <!-- -----------------confim modal----------- -->

  <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-2 align-items-center">
          <h6 class="modal-title font600 font9" id="exampleModalLabel">Remarks</h6>
          <button type="button" class="close" data-dismiss="modal">
            <span> &times;</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body pb-0">
          <div class="col-sm-12 col-lg-12">
            <div class="row">

              <div class="col-sm-12">
                <textarea name="remarks" id="remarks" placeholder="Enter remarks here...." [(ngModel)]="adminRemarks" class="form-control2"
                  maxlength="500"></textarea>

              </div>
            </div>
          </div>
          <div class="col-sm-12 text-center py-3">
            <button type="button" pButton (click)="onBlock()" *ngIf='action == "block"' label="Block" class="btn btn-theme btn-sm mr-2">
              Block
            </button>
            <button type="button" pButton (click)="onSuspended()" *ngIf='action == "suspend"' label="Suspended" class="btn btn-theme btn-sm mr-2">
              Suspend
            </button>
            <button type="button" pButton (click)="unBlock()" *ngIf="qcstatus == 185" label="Unblock" class="btn btn-theme btn-sm mr-2">
              Unblock
            </button>
            <button type="button" pButton (click)="onActive()" *ngIf="qcstatus == 186" label="Active" class="btn btn-success border-success btn-sm mr-2">
              Active
            </button>
            <button type="button" pButton (click)="reset()" label="Rejected" class="btn btn-danger btn-sm mr-2">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade in" id="listingprice" tabindex="-1" aria-labelledby="listingpriceLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-header p-2 align-items-center">
          <h6 class="modal-title font600 font9" id="exampleModalLabel">Product Price</h6>
          <button type="button" class="close" data-dismiss="modal">
            <span> &times;</span>
          </button>
        </div>
        <div class="modal-body ">
          <div class="col-sm-12">
            <h6>Update Listings Price</h6>

            <input type="text" class="form-control" placeholder="Price" [(ngModel)]="updated_price" (keypress)="commonFunctionService.amountValidator($event)">
            <label class="opecity0-5">Current Price
              <span>₹{{current_price}}</span>
            </label>

            <input type="text" class="form-control mt-2" placeholder="MRP" [(ngModel)]="updated_mrp" (keypress)="commonFunctionService.amountValidator($event)">

            <label class="opecity0-5">Current MRP
              <span>₹{{current_mrp}}</span>
            </label>
          </div>
          <div class="col-sm-12 py-2 text-center">
            <button type="button" class="btn btn-gray btn-sm mr-2 buyerButtonWidth " data-bs-dismiss="modal" (click)="closeEditpopup()">Close</button>
            <button type="button" class="btn btn-theme btn-sm" (click)="updateProductPrice()">Save Changes</button>
          </div>
        </div>
        <!-- <div class="modal-footer">
  
          </div> -->
      </div>
    </div>
  </div>
</div>