import { ideacraftAdminService } from '../../_services/ideacraftadmin.service';
import { FormService } from '../../../app/_services/form.service';
import Swal from 'sweetalert2';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams, } from '../../field.interface';
import { ProductService } from 'src/app/_services/product.service';
declare var $: any;

@Component({
  selector: 'app-year-master',
  templateUrl: './year-master.component.html',
  styleUrls: ['./year-master.component.css']
})
export class YearMasterComponent implements OnInit {
  @ViewChild('myInputVariable') myInputVariable: ElementRef;
  yearValue: any; 
  isActive: any;
  isShow: boolean = true;
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  isLoading: boolean = true;
  userId: any;
  searchTerm: any;
  yearId: any;
  yearDataList: any[]

  constructor(private formservice: FormService,
    private toastrService: ToastrService,
    private ideacraftService: ideacraftAdminService,
    private productService: ProductService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userId = localStorage.getItem('userId');
    this.configParms = {
      currentPage: 1,
      totalItems: this.totalCount,
      itemsPerPage: 10,
    };
    this.page.size = 10;
    this.bindNgxDataGrid(this.page, 1);
  }
  LoadGrid(event) {
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
  }

  bindNgxDataGrid(pageInfo, status) {
    let total,
      pagesize,
      limit,
      offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = (pageInfo.pageNumber - 1) * pagesize;
    }

    this.productService.getYearDataCount(this.searchTerm).subscribe((resp: any) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      this.productService.getYearDataListMaster(pagesize, offset, this.searchTerm).subscribe((res: any) => {
        if (res.rows.length > 0) {
          this.yearDataList = res.rows;
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.isLoading = false;
          }, 100);
        }
      });
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) {
        currPage = 1;
      }
      this.configParms = {
        currentPage: currPage,
        totalItems: this.totalCount,
        itemsPerPage: pagesize,
      };
      this.LoadPagination = true;
      this.isLoading = false;
    });
  }
  resetForm() {
    this.yearValue = '';
  }
  update() {
    $('#myModal').modal('hide');
    if (this.yearValue == null || this.yearValue == undefined || this.yearValue == '') {
      this.toastrService.warning("Please enter year value!", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    this.productService.updateYearData(this.yearId, this.yearValue).subscribe((res: any) => {
      //console.log('ressssssssss', res);
      if (res.rows.length > 0) {
        $('#add-new-deal').modal('hide');
        this.toastrService.success('Year value updated successfully.', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");
        this.bindNgxDataGrid(this.page, 1);
      }
    })
  }
  onEdit(year) {
    this.isShow = false;
    year = year.rowData;
    this.yearValue = year.year_value;
    this.yearId = year.year_id;
  }
  openpopup() {
    this.yearValue = '';
    $('#myModal').modal('show');
    this.isShow = true;
  }
  onSubmit() {

    if (this.yearValue == null || this.yearValue == undefined || this.yearValue == '') {
      this.toastrService.warning("Please enter year value!", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }

    this.productService.insertYearMaster(this.yearValue).subscribe((res: any) => {
      // console.log('ressssssssss', res);
      if (res.rows.length > 0) {
        $('#myModal').modal('hide');
        this.toastrService.success('Year successfully added.', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");

      }
      this.bindNgxDataGrid(this.page, 1);
      this.resetForm();


    })
  }

  CommonGridSearchMaster(ev) {
    this.searchTerm = ev
    this.bindNgxDataGrid(this.page, 1)
    // this.bannerDataList = this.TempbannerDataList.filter((item) =>  item.title ? item.title.toLowerCase().indexOf(ev) !== -1 : false    );
  }
  onDelete(year) {
    year = year.rowData;
    Swal.fire({
      title: `Are you sure you want to delete this year?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteYearData(year.year_id).subscribe((res) => {
          Swal.fire('Deleted year successfully!', '', 'success');
          this.bindNgxDataGrid(this.page, 1);
        }
        );
      }
    });
  }

  ngAfterViewChecked() {
    $('.toast-close-button').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
    $('.toast-container').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
  }

}
