import { Component, OnInit,Input } from '@angular/core';
//import { Validators, Editor, Toolbar } from "ngx-editor";
import {FormService} from '../../_services/form.service';
declare var $:any;
@Component({
  selector: 'app-notesheet',
  templateUrl: './notesheet.component.html',
  styleUrls: ['./notesheet.component.css']
})
export class NotesheetComponent implements OnInit {
  @Input() processinstid:any;
  @Input() worklistId:any;
  notesheetList:any[]=[];
  notesheetLoaded:Promise<boolean>=Promise.resolve(false);
  //editor: Editor=new Editor();
  // toolbar: Toolbar = [
  //   ["bold", "italic"],
  //   ["underline", "strike"],
  //   ["code", "blockquote"],
  //   ["ordered_list", "bullet_list"],
  //   [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
  //   ["text_color", "background_color"],
  //   ["align_left", "align_center", "align_right", "align_justify"]
  // ];
  //templateTxt:any="";
  constructor(private formService:FormService) { }

  ngOnInit(): void {
    $("#senddata").hide();
    $("#attachfileshow").hide();
    $("#addnoteshow").hide();

    let sqlQuery=`select rpn.notesheetid,case when rpn.notesheet_endorsee  <> '' then rpn.notesheet_endorsee else rpn.notesheet_containt end as notesheet_containt ,TO_CHAR(rpn.createddate:: DATE, 'dd/mm/yyyy') as noting_date ,
    rpn.createdby_userid,concat(u.user_fname,' ',u.user_mname,' ',u.user_lname) as user_fname,concat(r.role_name,'(',d.department_name,')') as role_name,
    up.department_id from radx_process_note rpn 
    left join app_tbluserprofile up on rpn.createdby_userid=up.userprofile_id
    inner join app_tbluser u on up.user_id=u.user_id
    inner join app_tblrole r on up.role_id=r.role_id
    inner join z_entitymast_department_001 d on up.department_id=d.department_id
          where rpn.processinstanceid = ${this.processinstid} and (up.department_id=${localStorage.getItem("department")} or rpn.notesheet_endorsee  <> '' ) order by rpn.notesheetid desc 
        `;
//  select rpn.notesheetid, rpn.notesheet_containt,TO_CHAR(rpn.createddate:: DATE, 'dd/mm/yyyy') as noting_date ,rpn.createdby_userid,u.user_fname,r.role_name 
//     from radx_process_note rpn left join app_tbluserprofile up on rpn.createdby_userid=up.userprofile_id
//     inner join app_tbluser u on up.user_id=u.user_id
//     inner join app_tblrole r on up.role_id=r.role_id
//      where rpn.processinstanceid = ${this.processinstid} order by rpn.notesheetid desc
     console.log("notesheetid===>",this.processinstid,sqlQuery);
     this.formService.getFormData(sqlQuery).subscribe(res => {
       //console.log("notesheetid===>",res.rows[0]);
      this.notesheetList=[]; 
      this.notesheetList=res.rows;          
      this.notesheetLoaded=Promise.resolve(true);
    })   
  }
  showattach()
  {
    $("#senddata").hide();
    $("#wizard1").hide();
    $("#addnoteshow").hide();
    $("#attachfileshow").show();
  }
  
  showsend()
  {
    $("#senddata").show();
    $("#wizard1").hide();
    $("#attachfileshow").hide();
    $("#addnoteshow").hide();
    
  }
  closenote()
  {
    $("#senddata").hide();
    $("#wizard1").show();
    $("#attachfileshow").hide();
    $("#addnoteshow").hide();
  }

  closesend()
  {
    $("#senddata").hide();
    $("#attachfileshow").hide();
    
    $("#wizard1").show();
  }
  closeattach()
  {
    $("#attachfileshow").hide();
    $("#senddata").hide();
    $("#wizard1").show();
  }
  
  shownote()
  {
     
    $("#wizard1").hide();
    
    $("#senddata").hide();
    
    $("#attachfileshow").hide();
    $("#addnoteshow").show();
  }
}
