import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import {
  SectionConfig,
  SessionUrlPrams,
  ConfigPrams,
} from '../../field.interface';
import { Router } from '@angular/router';
import { ProductService } from '../../_services/product.service';
import Swal from 'sweetalert2';
import { AppSettings } from '../../_services/AppSetting';
declare var $: any;

@Component({
  selector: 'app-brand-listing',
  templateUrl: './brand-listing.component.html',
  styleUrls: ['./brand-listing.component.css'],
})
export class BrandListingComponent implements OnInit {
  brandList: any[] = [];
  isspproved:any;
  brandNameId: any;
  isView: boolean;
  page = new Page();
  configParms: ConfigPrams;
  LoadPagination: boolean = false;
  TempallOrderList: any;
  isLoading: boolean = false;
  action: any;
  searchTerm: any;
  isLoadGrid: boolean = false;
  sellerId: string;
  public totalCount = 0;
  brandName:any;
  brandCode:any;
  isapproved : any;
  MassageOutTime = AppSettings.getMassageOutTime;
  brandid:any;
  constructor(
    private ProductService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.configParms = {
      currentPage: 1,
      totalItems: this.totalCount,
      itemsPerPage: 10,
    };
    this.page.size = 10;
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.bindNgxDataGrid(this.page, 1);

    $(document).ready(function () {
      $('ul.tbsbtns li').click(function () {
        $('li').removeClass('is-active');
        $(this).addClass('is-active');
      });
    });
  }
  LoadGrid(event) {
    this.isLoadGrid = true;
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
  }
  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;
    this.bindNgxDataGrid(this.page, 1);
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true;
    let total,
      pagesize,
      limit,
      offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = (pageInfo.pageNumber - 1) * pagesize;
    }
    if (!this.isLoadGrid) offset = this.searchTerm ? 0 : offset;
    this.ProductService.brandListCount(this.searchTerm).subscribe((resp) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      offset =
        this.page.totalElements < offset
          ? 0
          : this.page.totalElements > offset
            ? offset
            : 0;
      this.ProductService.allBrandList(
        pagesize,
        offset,
        this.searchTerm
      ).subscribe((res) => {
        if (res.rows.length > 0) {
          this.brandList = res.rows;
          if(this.brandList.length > 0){
            for(let obj of this.brandList){
              if(obj.seller_name == null || obj.seller_name == undefined || obj.seller_name == ''){
              obj.seller_name = 'Admin'
            }

            }
          }
          // console.log('resppppp', res.rows);
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.brandList = [];
            this.isLoading = false;
          }, 100);
        }
      });

      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) {
        currPage = 1;
      }
      this.configParms = {
        currentPage: currPage,
        totalItems: this.totalCount,
        itemsPerPage: pagesize,
      };
      this.LoadPagination = true;
    });
  }
  onApproved(row) {
    this.brandNameId = row.rowData.brand_name_id;
    Swal.fire({
      title: `Do you want to Approve?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.brandListApproved(this.brandNameId).subscribe(
          (res) => {
            Swal.fire('Brand List Approved', '', 'success');
            this.bindNgxDataGrid(this.page, 1);
          }
        );
      }
    });
  }
  createbrandCode(){
    this.brandName = this.brandName?.trim();
    if (this.brandName == null || this.brandName == 'null' || this.brandName == '' || this.brandName == undefined) {
      this.toastrService.warning("Brand name is required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
     this.removeClass()
      return false;
    }
    const brandname = this.brandName.replace(/\s/g,'');
    const brandcode = brandname.slice(0, 3);
    const stringRandLen = 6384
    var stringRandomId = stringRandLen
    var stringRandomArray = new Uint32Array(stringRandLen)
    stringRandomId = 0
    let randomNumber = Math.floor((crypto.getRandomValues(stringRandomArray)[stringRandomId++] * 2.3283064365386963e-10) * 1000000)
     this.brandCode = brandcode +  randomNumber
  }
  addBrandName() {
    this.brandName = this.brandName?.trim();
    if (this.brandName == null || this.brandName == 'null' || this.brandName == '' || this.brandName == undefined) {
      this.toastrService.warning("Brand name is required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
     this.removeClass()
      return false;
    }
    
    this.ProductService.addBrandNameByAdmin(this.brandName,this.brandCode,this.isapproved).subscribe((res:any) => {
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.brandName = '';
        this.brandCode = '';
        this.isapproved = false;
        return false;
      }else{
      this.toastrService.success("Brand name added successfully ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      this.brandName = ''
      this.brandCode = ''
      this.isapproved = false;
      $('#myModal').modal('hide')
      this.bindNgxDataGrid(this.page, 1);
    }
    })
   
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  reSet(){
    this.brandName = '';
    this.brandCode = '';
   this.isapproved = false;
  }
  onDelete(e){
    let id =  e.rowData.brand_name_id;
    console.log("brandiid", id)
    Swal.fire({
      title: `Do you want to delete Brand?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.deteleBrand(id).subscribe(
          (res) => {
            Swal.fire('Brand deleted successfully', '', 'success');
            this.bindNgxDataGrid(this.page, 1);
          }
        );
      }
    });
  }
  onEdit(e){
   
    this.brandid = e.rowData.brand_name_id
    let tempbrandList = this.brandList.filter(x => x.brand_name_id == this.brandid)
    this.brandCode = tempbrandList[0].code?tempbrandList[0].code:'';
    this.brandName = tempbrandList[0].name;
    this.isapproved = tempbrandList[0].is_aproved;
    $('#myModal').modal('show')
  }

  updateBrandName(){
    this.brandName = this.brandName?.trim();
    if (this.brandName == null || this.brandName == 'null' || this.brandName == '' || this.brandName == undefined) {
      this.toastrService.warning("Brand name is required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
     this.removeClass()
      return false;
    }
    // this.brandCode = this.brandCode?.trim();
    // if (this.brandCode == null || this.brandCode == 'null' || this.brandCode == '' || this.brandCode == undefined) {
    //   this.toastrService.warning("Brand Code is Required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }
    this.ProductService.updateBrandNameByAdmin(this.brandName,this.brandCode,this.isapproved,this.brandid ).subscribe((res:any) => {
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.brandName = '';
        this.brandCode = '';
        this.isapproved = false;
        return false;
      }else{
      this.toastrService.success("Brand name updated successfully ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      this.brandName = ''
      this.brandCode = ''
      this.isapproved = false;
      $('#myModal').modal('hide')
      this.bindNgxDataGrid(this.page, 1);
    }
    })
  }
}
