import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerServiceService } from 'src/app/_services/customer-service.service';
import { LoginService } from 'src/app/_services/login.service';
import { ProductService } from 'src/app/_services/product.service';
import { FormService } from 'src/app/_services/form.service';
import Swal from 'sweetalert2';
declare var $:any;

@Component({
  selector: 'app-buyer-account',
  templateUrl: './buyer-account.component.html',
  styleUrls: ['./buyer-account.component.css'] 
})
export class BuyerAccountComponent implements OnInit {
  catalogueList: any[] = [] ;
  showCreateForm: boolean = false;
  customerId: any;
  parent_cat_id: any;
  whereClouse: any;
  filter: number;
  p: any;
  p2: any;
  
  p3: any;
  cart_list: any = [];
  productList: any=[];
  selectedCatalogue: any
  selectedBrand: any
  addcatalogue: any = false;
  office_supplies: any[] = [] ;
  print_collaterals: any[]=[];
  merchandise: any[]=[];
  itemsPerPage = [{
    id: 0,
    value: 5
  },
  {
    id: 1,
    value: 10
  },
  {
    id: 2,
    value: 15
  },
  ]
  perPage: any  = 5;
  perPage2: any  = 5;
  perPage3: any  = 5;
  selectedCategory: any
  productId: any;
  wishList: any = [];
  catalogueTypeList: any[];
  brandNameList: any;
  categoryList: any;
  createdCatalogueList: any[] = [];
  price_as_per: any = 'Actual';
  tempCatalogueTypeList: any[] = [];
  tempCatalogeList: any[] = [];
  tempProductList: any[] = [];
  firstCatalogueType: any;
  userId: string;
  catalogueTypeListTemp: any;
  buyerName: any;
  item: any;
  items: any[] = [];
  createdBrandNameList: any;
  temCreatedBrandNameList: any;
  tempBrandList: any;
  filteredBrandlistCat: any[];
  filteredBrandlist: any[];
  filteredCategoryList: any[];
  currentUrl: string;
  temCategoryList: any;
  categoryListCat: any;

  /*    */ 
     addressList: any[] = [];
  tempAddresslist: any[] = [];
  addTypetext: any;
  reSet:any;
  add_name:any;
  last_name:any;
  email_address:any;
  company_name:any;
  getCityAndState:any;
  pinCode:any;
  locality:any;
  add_area_street:any;
  country_id:any;
  state:any;
  stateList:any;
  city:any;
  landmark:any;
  add_mobile:any;
  addAddress:any;
   current_password:any;
   showPassword:any;
   new_password:any;
   showPassword1:any;
   confirm_password:any;
   showPassword2:any;
   back:any;
   save:any;


  cardlist: any;
  billingAddList: any;
  role_id: any;
  mobile: any;
  email: any;
  selectedRating: string;
  constructor(private toastrService: ToastrService, private router: Router, 
    private customerService: CustomerServiceService,private route: ActivatedRoute, 
    private productService: ProductService,private formService: FormService,
    private loginService: LoginService) { 
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    }

  ngOnInit(): void {
    // setTimeout(() => {
    //   $(document).ready(function(){
    //     $(document).on("click",".adddeliveryaddress",function(){
    //       $('.allAddress').hide();
    //       $('.addNewAddress').show();
    //     });
    //   });
    // }, 1000)

    setTimeout(() => {
      $(document).ready(function () {
        $(document).on("click", ".adddeliveryaddress", function () {
          $('.allAddress').hide();
          $('.addNewAddress').show();
        });
      });
      $(document).on("click", ".backtoaddress", function () {
        $('.allAddress').show();
        $('.addNewAddress').hide();
      });
    }, 1000)


    this.getCatalogueTypeList()
    this.getAllCat();
    this.getBrandName();
    let data1 = localStorage.getItem('buyer')
    data1 = JSON.parse(data1) 
    this.customerId = data1['buyerid']
    let data2 = localStorage.getItem('role_id')
    data2 = JSON.parse(data2) 
    this.role_id = data2['role_id']
    console.log("buerrer", this.role_id)
    this.buyerName = data1['buyername']
    this.mobile = data1['mobile']
    this.email = data1['email']
    this.userId = localStorage.getItem('userId');
    console.log("data111111", this.buyerName, this.mobile,this.email);
    if (this.customerId != null) {
      this.getCartData();
    } else {
      this.cart_list = localStorage.getItem('cart_items') ? JSON.parse(localStorage.getItem('cart_items')) : [];
    }
    
    this.getAllBuyerCatalogue();
    this.getBuyerAllAddress();
   
  }
  getBuyerAllAddress() {
    this.customerService.getBuyerAddress(this.customerId).subscribe(res => {
      this.addressList = res.rows.filter(x => x.is_for_future && x.is_billing_add == false);
      this.tempAddresslist = res.rows.filter(x => x.is_for_future && x.is_billing_add == false);
      this.billingAddList = res.rows.filter(x => x.is_billing_add || x.is_billing_add == null && x.is_for_future)
    })
  }
  getAllBuyerCatalogue() {
    this.customerService.getAllBuyerCatalogue(this.customerId,this.userId).subscribe((res:any) => {
    console.log("respos", res)
      if (res.rows.length > 0) {
        this.tempCatalogeList = res.rows;
        //console.log("meenaksee=========================",  this.tempCatalogeList )
        for(let obj of this.tempCatalogeList){
          if(this.tempCatalogueTypeList.filter(x =>x.catalogue_type_id == obj.catalogue_type_id).length == 0){
            this.tempCatalogueTypeList.push({"catalogue_type_id": obj.catalogue_type_id, name: obj.catalogue_type_name});
          }
        }
        for (const obj of this.tempCatalogeList) {
          let discount = ''
          obj.qty = obj.coalesce;
          let   starRating = [{
            index: 1, israted: false, imgurl:'../../../assets/images/icon/notrated.svg'
          },{
            index: 2, israted: false, imgurl:'../../../assets/images/icon/notrated.svg'
          },{
            index: 3, israted: false, imgurl:'../../../assets/images/icon/notrated.svg'
          },{
            index: 4, israted: false, imgurl:'../../../assets/images/icon/notrated.svg'
          },{
            index: 5, israted: false, imgurl:'../../../assets/images/icon/notrated.svg'
          }]
          for (let i = 0; i < obj.avg_rating; i++) {
              starRating[i].israted = true
              starRating[i].imgurl = '../../../assets/images/icon/rated.svg'
          }
        obj.star =  starRating
        discount = ((((obj.mrp ? Number(obj.mrp) : 0) - (obj.price ? Number(obj.price ) : 0)) * 100) / (obj.mrp ? Number(obj.mrp) : 0)).toFixed(2);
        obj.discount = discount

      }
        console.log('this.tempCatalogueTypeList',this.tempCatalogueTypeList)
        this.print_collaterals = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 217);
        this.merchandise = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 216);
        this.office_supplies = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 215);
        this.firstCatalogueType  = this.tempCatalogueTypeList[0].catalogue_type_id
        this.getCartProduct()
      }
      this.filteredBrandlistCat = []
      this.filteredCategoryList = []
      for (const a of this.tempCatalogeList) {
        if (this.filteredBrandlistCat.filter(x => x.brand_id == a.brandid).length == 0) {
          if (a.brandid)
            this.filteredBrandlistCat.push(a)
        }
        if(this.filteredCategoryList.filter(x => x.cat_id == a.cat_id).length ==0){
          if(a.cat_id)
            this.filteredCategoryList.push(a);
        }
        //console.log('print catogry', this.filteredCategoryList)
        this.getBrandNameForCreatedList();
      }
      this.getAllCatCreatedCat()
    })
  }
  showCreateCatalogueForm(catType) {
    this.selectedCatalogue = catType;
    let cond = ` and assign_marketplace = 'true' `;
    if (this.role_id && this.role_id == '47' && this.customerId){
      cond = ` and (assign_marketplace = 'true' OR (assign_corp = 'true' AND pro.corp_id IS NOT NULL
      AND ('${this.customerId}' = ANY(string_to_array(replace(replace(pro.corp_id, '[', ''), ']', ''), ',')::text[])))) `
    }
      if(catType == 215){
        let arr = []
        this.office_supplies.forEach(itm => arr.push(Number(itm.product_id)))
        if(arr.length > 0){
          this.whereClouse = ` where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.product_type = 197 and pro.product_id not in(${arr})  and pro.isactive = true ${cond}`;
        }else{
          this.whereClouse = ` where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.product_type = 197  and pro.isactive = true ${cond}`;
        }
      }
     else if(catType == 216){
      let arr = []
      this.merchandise.forEach(itm => arr.push(Number(itm.product_id)))
      if(arr.length > 0){
        this.whereClouse = `where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.product_type in(198, 208) and pro.product_id not in(${arr}) and pro.isactive = true ${cond}`;
      }else{
        this.whereClouse = `where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.product_type in(198, 208) and pro.isactive = true ${cond}`;
      } 
      }
     else if(catType == 217){
      let arr = []
      this.print_collaterals.forEach(itm => arr.push(Number(itm.product_id)))
      if(arr.length > 0){
        this.whereClouse = `where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.product_type = 209 and pro.product_id not in(${arr}) and pro.isactive = true ${cond}`;
      }else{
        this.whereClouse = `where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.product_type = 209 and pro.isactive = true ${cond}`;
      }  
      }
      else{
        this.whereClouse = 'where seller.isactive=true and pro.isactive=true and seller.seller_status=181 and pro.qcstatus=173 and pro.isactive = true'+cond;
      }
    // } 

    // if (this.filter == 1) {
    //   this.whereClouse = this.whereClouse + ' ' + 'order by pro.createddate desc'
    //   this.productList = [];
    // } else if (this.filter == 2) {
    //   this.whereClouse = this.whereClouse + ' ' + "order by to_number(pro.price,'9999999') asc"
    //   this.productList = [];
    // } else if (this.filter == 3) {
    //   this.whereClouse = this.whereClouse + ' ' + "order by to_number(pro.price,'9999999') desc"
    //   this.productList = [];
    // }
    this.customerService.getProductForCreateCatalogue(this.whereClouse, this.customerId).subscribe(res => {
      this.showCreateForm = true;
      let tempProd = [];
      tempProd = res.rows;
      // tempProd = res[0].rows;
     // let tempProdImg = [];
      //tempProdImg = res[1].rows;
     // let tempProdconfig = res[2].rows;
      //console.log("congigg", tempProdconfig)
      let tempprice, discount, tempmrp, tempadminprice, tempadminmrp;
      if (tempProd.length > 0) {
        this.productList = [];
        this.tempProductList = []
        for (let i = 0; i < tempProd.length; i++) {

          let starRating = [{
            index: 1, israted: false, imgurl: '../../../assets/images/icon/notrated.svg'
          }, {
            index: 2, israted: false, imgurl: '../../../assets/images/icon/notrated.svg'
          }, {
            index: 3, israted: false, imgurl: '../../../assets/images/icon/notrated.svg'
          }, {
            index: 4, israted: false, imgurl: '../../../assets/images/icon/notrated.svg'
          }, {
            index: 5, israted: false, imgurl: '../../../assets/images/icon/notrated.svg'
          }]



          discount = 0;
         // let imgs = tempProdImg.filter(x => x.product_id == tempProd[i].product_id);
          if (tempProd[i].product_type == 198 || tempProd[i].product_type == 208) {
            tempprice = tempProd[i].price;//tempProdconfig.filter(x => x.product_id == tempProd[i].product_id)[0]?.price;
            tempmrp = tempProd[i].mrp;//tempProdconfig.filter(x => x.product_id == tempProd[i].product_id)[0]?.mrp;
            tempadminprice =tempProd[i].admin_price;// tempProdconfig.filter(x => x.product_id == tempProd[i].product_id)[0]?.admin_price;
            tempadminmrp = tempProd[i].admin_mrp;//tempProdconfig.filter(x => x.product_id == tempProd[i].product_id)[0]?.admin_mrp;
            if (tempmrp != null || tempmrp != undefined || tempmrp != '') {
              discount = (((((+tempmrp)+(+tempadminmrp) ? (Number(tempmrp)+(+tempadminmrp)) : 0) - ((+tempprice)+(+tempadminprice) ? (Number(tempprice)+(+tempadminprice)) : 0)) * 100) / ((+tempmrp)+(+tempadminmrp) ? (Number(tempmrp)+(+tempadminmrp)) : 0)).toFixed(2);
            }
          } else {
            tempprice = tempProd[i].price;
            tempadminprice = tempProd[i].admin_price;
            //tempprice = tempProd[i].price;
            if (tempProd[i].mrp != null || tempProd[i].mrp != undefined || tempProd[i].mrp != '') {
              discount = (((((+tempProd[i].mrp)+(+tempProd[i].admin_mrp) ? (+tempProd[i].mrp)+(+tempProd[i].admin_mrp) : 0) - ((+tempProd[i].price)+(+tempProd[i].admin_price) ? (+tempProd[i].price)+(+tempProd[i].admin_price) : 0)) * 100) / ((+tempProd[i].mrp)+(+tempProd[i].admin_mrp) ? (+tempProd[i].mrp)+(+tempProd[i].admin_mrp) : 0)).toFixed(2);
            }
            tempmrp = tempProd[i].mrp;
            tempadminmrp = tempProd[i].admin_mrp; 
          }
          // debugger
          let avg = tempProd[i].avg_rating ? tempProd[i].avg_rating : 0
          for (let i = 0; i < Number(avg); i++) {
            starRating[i].israted = true
            starRating[i].imgurl = '../../../assets/images/icons/rated.svg'
          }
          this.productList.push({ 'product_id': tempProd[i].product_id, 'star': starRating, 'count': tempProd[i].count, 'seller_id': tempProd[i].seller_id, 'product_name': tempProd[i].product_name, 'product_type': tempProd[i].product_type, 'price': (+tempprice)+(+tempadminprice), 'document_path': tempProd[i].document_path ? tempProd[i].document_path : null, 'mrp': (+tempmrp)+(+tempadminmrp), 'isChecked': false, 'brand_id': tempProd[i].brand_id, 'category': tempProd[i].category, 'discountPer': discount });
          this.tempProductList.push({ 'product_id': tempProd[i].product_id, 'star': starRating, 'count': tempProd[i].count, 'seller_id': tempProd[i].seller_id, 'product_name': tempProd[i].product_name, 'product_type': tempProd[i].product_type, 'price': (+tempprice)+(+tempadminprice), 'document_path': tempProd[i].document_path ? tempProd[i].document_path : null, 'mrp': (+tempmrp)+(+tempadminmrp), 'isChecked': false, 'brand_id': tempProd[i].brand_id, 'category': tempProd[i].category, 'discountPer': discount, });
        }
        if (this.firstCatalogueType == 215) {
          for (const off of this.office_supplies) {
            this.productList = this.tempProductList.filter(x => x.product_id != off.product_id)
          }
        } else if (this.firstCatalogueType == 216) {
          for (const mer of this.merchandise) {
            this.productList = this.tempProductList.filter(x => x.product_id != mer.product_id)
          }
        } else if (this.firstCatalogueType == 217) {
          for (const print of this.print_collaterals) {
            this.productList = this.tempProductList.filter(x => x.product_id != print.product_id)
          }
        }
        // console.log('check fro produclistu', this.productList);
        this.filteredBrandlist = []
        for (const a of this.productList) {
          if (this.filteredBrandlist.filter(x => x.brand_id == a.brand_id).length == 0) {
            if (a.brand_id)
              this.filteredBrandlist.push(a)
          }
          this.getBrandName()

        }
      } else {
        this.productList = []

      }
    });
    let a = []
    for (const pr of this.productList) {
      pr.isChecked = false;
}
    for (const temp of this.catalogueTypeListTemp) {
        if(this.tempCatalogueTypeList.filter(x=> x.catalogue_type_id == temp.id).length){
          continue;
        }else{
          a.push(temp);
        }
         
    }
    this.catalogueTypeList = a;
    
  }
  showCatalogueList() {
    this.showCreateForm = false;
    // this.perPage = 5
  }
  getCartData() {
    this.productService.getCartData(this.customerId).subscribe(res => {
      this.cart_list = res[0].rows;
    });
  }
  navigateToCartPage(){
    this.router.navigateByUrl('/add-to-cart')
  }
  getCatalogueTypeList(){
    this.customerService.getCatalogueType().subscribe((res:any) =>{
        this.catalogueTypeList = res;
        this.catalogueTypeListTemp = res
         //console.log('dksjfksjfksjfd', res)
    })
  }

  getBrandName() {
    this.productService.getBrandNameList().subscribe(res => {
      this.brandNameList = res;
      this.tempBrandList = res;
      let newlist = [];
      // for (const brand of this.filteredBrandlist) {
      //   this.tempBrandList.filter(x => x.brand_name_id == brand.brand_id);
      // }

      for (let i = 0; i < this.filteredBrandlist?.length; i++) {
        this.tempBrandList.filter(x => {
          if (x.brand_name_id == this.filteredBrandlist[i].brand_id) {
            newlist.push(x)
          }
        });

      }
      this.brandNameList = newlist;
      if (this.brandNameList.length > 0) {
        for (let obj of this.brandNameList) {
          obj.isSelected = false;
        }
      }
    })
  }

getAllCat() {
  this.customerService.getCategory().subscribe(res => {
    this.categoryList = res;
  })
}

createCatalogue(){
  if(!this.addcatalogue && !this.selectedCatalogue)  {
    this.toastrService.warning("Please select catalogue type", '', { timeOut: 2000, positionClass: "toast-top-center"})
    return true;
  }
  let selectedCatalogue = this.catalogueTypeListTemp.filter(x => this.addcatalogue ? Number(x.id) == Number(this.firstCatalogueType) : Number(x.id) == Number(this.selectedCatalogue))
  let typeName = selectedCatalogue[0].varibale_name;
  let typeid = selectedCatalogue[0].id;
// console.log('check resonse', selectedCatalogue)
if(this.createdCatalogueList.length < 1){
  this.toastrService.warning("Please select products", '', { timeOut: 2000, positionClass: "toast-top-center"})
  return true;
}
  this.customerService.saveCatalogue(JSON.stringify(this.createdCatalogueList), this.customerId,this.userId, typeName, typeid, this.price_as_per).subscribe((res:any) =>{
    if(res.status == 201){
      this.toastrService.success('Your catalogue created !', '', { timeOut: 3000, positionClass: 'toast-top-center', disableTimeOut: false, tapToDismiss: false, closeButton: true, extendedTimeOut: 0 })
      this.p = 1;
      this.perPage = 5
      for (const temp of this.tempProductList) {
        temp.isChecked = false
    }
    for (const pro of this.productList) {
        pro.isChecked = false
    }
      this.getAllBuyerCatalogue();
      this.showCatalogueList()
      this.createdCatalogueList = []
    }else{
      for (const temp of this.tempProductList) {
        temp.isChecked = false
    }
    for (const pro of this.productList) {
        pro.isChecked = false
    }

    this.createdCatalogueList = []
      this.toastrService.error('Something went wrong !', '', { timeOut: 3000, positionClass: 'toast-top-center', disableTimeOut: false, tapToDismiss: false, closeButton: true, extendedTimeOut: 0 })
      this.p = 1;
      this.perPage = 5
    }
  }, err => {
    this.toastrService.error('Something went wrong !', '', { timeOut: 3000, positionClass: 'toast-top-center', disableTimeOut: false, tapToDismiss: false, closeButton: true, extendedTimeOut: 0 })
    this.p = 1;
    this.perPage = 5
    for (const temp of this.tempProductList) {
      temp.isChecked = false
  }
  for (const pro of this.productList) {
      pro.isChecked = false
  }

  this.createdCatalogueList = []
  })
}




plus(product, i) {
  product.quantity = product.quantity < 0 ? 0 : product.quantity
    // if(Number(product.qty) < Number(product.qty))
    if(Number(product.quantity) == 0){
      this.toastrService.warning(`${product.product_name} Out of stock`, '', { timeOut: 2000, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
        return true;
   
  }else{
    if( Number(product.qty) > Number(product.quantity) ){
    this.toastrService.warning(`Only ${product.quantity} is available ${product.product_name}  !`, '', { timeOut: 2000, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    return true;
    }
  }
  if(product.qty == 0){
    this.addToCart(product, i);
   // return true;
   // this.currentUrl = this.router.url;
   // this.router.navigateByUrl(this.currentUrl + '?' + ' ');
 }
  this.currentUrl = this.router.url;
  this.router.navigateByUrl(this.currentUrl + '?' + ' ');
  if (this.customerId != null && this.customerId != undefined) {
    // this.btnDisable = false
    
    $('#minus-btn' + i).removeClass('disabled');
    product.qty = Number(product.qty) + 1;
    var startingPrice = product.base_price;
    let total_amount = Number(startingPrice) * Number(product.qty);
     //console.log('skjfsdlkfjsdklfsdjf', product)
    $('#minus-btn' + i).addClass('disabled');
    this.productService.updateCartItem(product.crat_id, product.qty, product.base_price, total_amount, 0).subscribe(res => {
      //console.log("cartidddd", product.crat_id)
      $('#minus-btn' + i).removeClass('disabled');
    //   this.getGrandTotalForGuest()
     })

  } else {
    // var cartItem = this.product.filter(product => product.product_id == id);
    // this.btnDisable = false
    $('#minus-btn' + i).removeClass('disabled');
    product.qty = product.qty + 1;
    var startingPrice = product.base_price
    product.price = Number(startingPrice) * Number(product.qty);
    // const index = this.product.map(object => object.product_id).indexOf(id);
    // this.product[index].price = this.productPrise;
     localStorage.setItem("cart_items", JSON.stringify(product))
    // this.getGrandTotalForGuest()
  }
}
minus(product, i) {
  if (product.qty == 0) {
    // this.btnDisable = true;
    $('#minus-btn' + i).addClass('disabled');
  }else {
    if (this.customerId != null && this.customerId != undefined) {
      product.qty = Number(product.qty) - 1;
      var startingPrice = product.base_price
      let total_amount = Number(startingPrice) * Number(product.qty);
      // console.log('minus', product)

      $('#minus-btn' + i).addClass('disabled');
      this.productService.updateCartItem(product.crat_id, product.qty, product.base_price, total_amount, 0).subscribe(res => {
       console.log("minus card", product.crat_id)
        $('#minus-btn' + i).removeClass('disabled');
      //   this.getGrandTotalForGuest()
       })
    }
    else {
      // this.product1 =JSON.parse( localStorage.getItem('cart_items'))
      // var cartItem = this.product1.filter(product => product.product_id == id);
      // this.btnDisable = false
      $('#minus-btn' + i).removeClass('disabled');
      product.qty = product.qty - 1;
      var startingPrice = product.base_price
      // this.productPrise = Number(startingPrice) * Number(this.inputnumber);
      product.price = Number(startingPrice) * Number(product.qty);
      // const index = this.product.map(object => object.product_id).indexOf(id);
      // this.product[index].price = this.productPrise;
      // localStorage.setItem("cart_items", JSON.stringify(this.product))
      // this.getGrandTotalForGuest()
    }
  }
}
createArrayForCatalogue(list){
  if(list.isChecked)
    this.createdCatalogueList.push(list);
  else
  this.createdCatalogueList.splice(this.createdCatalogueList.findIndex(item => item.product_id == list.product_id), 1)
  
    console.log('check list', this.createdCatalogueList)
}

filterCreatedCataloguebyBrand() {
  

  // debugger
  if (this.selectedBrand != 'undefined') {
    if (this.firstCatalogueType == 215) {
      this.office_supplies = this.tempCatalogeList.filter(x => x.catalogue_type_id == 215 && Number(x.brandid) == Number(this.selectedBrand));
    } else if (this.firstCatalogueType == 216) {
      this.merchandise = this.tempCatalogeList.filter(x => x.catalogue_type_id == 216 && Number(x.brandid) == Number(this.selectedBrand));
    } else if (this.firstCatalogueType == 217) {
      this.merchandise = this.tempCatalogeList.filter(x => x.catalogue_type_id == 217 && Number(x.brandid) == Number(this.selectedBrand));
    }
  }else{
   this.print_collaterals = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 217);
  this.merchandise = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 216);
  this.office_supplies = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 215);
  }

}


onSelectbrand(){
  this.selectedCategory = 'undefined'
  if(this.selectedBrand != 'undefined'){
    this.productList = this.tempProductList.filter(x => Number(x.brand_id) == Number(this.selectedBrand));
  }else{
    this.productList = this.tempProductList
  }

}

onSelectCategory(){
  this.selectedBrand = 'undefined';
  if(this.selectedCategory != 'undefined'){
    this.productList = this.tempProductList.filter(x => Number(x.category) == Number(this.selectedCategory));
    // console.log('sjflksjfdlksfjresssid', {pro: this.productList, tempor: this.tempProductList})
 
  }else{
    this.productList = this.tempProductList
  }
}
filterByRatingCat(){  
   
  if (this.selectedRating != 'undefined') {
      if (this.firstCatalogueType == 215) {
        this.office_supplies = this.tempCatalogeList.filter(x => x.catalogue_type_id == 215 && Number(x.avg_rating) == Number(this.selectedRating));
      } else if (this.firstCatalogueType == 216) {
        this.merchandise = this.tempCatalogeList.filter(x => x.catalogue_type_id == 216 && Number(x.avg_rating) == Number(this.selectedRating));
      } else if (this.firstCatalogueType == 217) {
        this.merchandise = this.tempCatalogeList.filter(x => x.catalogue_type_id == 217 && Number(x.avg_rating) == Number(this.selectedRating));
      }
    }else{
     this.print_collaterals = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 217);
    this.merchandise = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 216);
    this.office_supplies = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 215);
    }
     console.log(this.tempCatalogeList);

}
onSelectCategoryCat(){
  if (this.selectedCategory != 'undefined') {
    if (this.firstCatalogueType == 215) {
      this.office_supplies = this.tempCatalogeList.filter(x => x.catalogue_type_id == 215 && Number(x.cat_id) == Number(this.selectedCategory));
    } else if (this.firstCatalogueType == 216) {
      this.merchandise = this.tempCatalogeList.filter(x => x.catalogue_type_id == 216 && Number(x.cat_id) == Number(this.selectedCategory));
    } else if (this.firstCatalogueType == 217) {
      this.merchandise = this.tempCatalogeList.filter(x => x.catalogue_type_id == 217 && Number(x.cat_id) == Number(this.selectedCategory));
    }
  }else{
   this.print_collaterals = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 217);
  this.merchandise = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 216);
  this.office_supplies = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 215);
  }
}
addToCart(pro, i) {
  let temp = []
  if (this.customerId) {
    // this.item.qtytotal = 1;
    // this.items.push()
    temp.push({
      customer_id: this.customerId,
      product_id: pro.product_id,
      qtytotal: 1,
      price : pro.price,
      mrp: pro.mrp,
      total_amount: Number(pro.price) * 1,
      product_name: pro.product_name,
      product_discription: pro.product_discription,
      sellerId: pro.seller_id,
      sellerName: pro.seller_name,
      base_price: pro.price,
      image_path: pro.product_img_path,
      sku: pro.suk,
      stockQty: pro.quantity,
      product_type: pro.product_type,
      gst_amount:  Number(pro.price) * Number(pro.gst_rate)/100 
    });

    this.items.push(temp);

    console.log('check item list', temp);
    this.productService.addToCart(this.customerId, temp).subscribe((res:any) => {
      if (res.status == 203) {
        this.toastrService.success(res.message, '', { timeOut: 2000, positionClass: "toast-top-center" });
        //this.router.navigate(['add-to-cart'])
        pro.crat_id = res.crat_id[0].crat_id;
        pro.qty = 1
      } else {
        pro.crat_id = res.crat_id[0].crat_id;
          pro.qty = 1
        // let idp = `idp${i}`
        // let idb = `idb${i}`
        // document.getElementById(idp).classList.remove('d-none');
        // document.getElementById(idb).classList.add('d-none');
        //this.router.navigateByUrl(this.router.url + '?' +' ')
        //pro.crat_id = res.crat_id[0].crat_id;
        
        this.toastrService.success('Product Added in cart', '', { timeOut: 2000, positionClass: "toast-top-center" });
          $(".overlay-container").remove("addcontainerdisable");
          this.currentUrl = this.router.url;
          this.router.navigateByUrl(this.currentUrl + '?' + ' ');
          this.removeClass()    
          this.router.navigateByUrl(this.router.url + '?' +' ')
          return false;
      }
    })
  } else {
    this.items = localStorage.getItem('cart_items') ? JSON.parse(localStorage.getItem('cart_items')) : [];
    let temp = [];
    temp = this.items.filter(x => x.product_id == this.item.product_id);
    if (temp.length == 0) {
      this.item.qtyTotal = 1;
      this.items.push(this.item);
      localStorage.setItem('cart_items', JSON.stringify(this.items));
      //this.router.navigate(['add-to-cart'])
    } else {
      this.toastrService.warning('Already add in cart', '', { timeOut: 2000, positionClass: "toast-top-center" });
      //this.router.navigate(['add-to-cart'])
      this.removeClass();
      return false
    }
  }

}
getBrandNameForCreatedList() {
  this.productService.getBrandNameList().subscribe(res => {
    this.createdBrandNameList = res;
    //console.log("createdBrandNameList",this.createdBrandNameList)
    this.temCreatedBrandNameList = res
    let newlist = []
    // for (const brand of this.filteredBrandlist) {
    //   this.tempBrandList.filter(x => x.brand_name_id == brand.brand_id);
    // }

    for (let i = 0; i < this.filteredBrandlistCat.length; i++) {
      this.temCreatedBrandNameList.filter(x => {
        if (x.brand_name_id == this.filteredBrandlistCat[i].brandid) {
          newlist.push(x)
        }
      });

    }
    //this.createdBrandNameList = newlist;
    if (this.createdBrandNameList.length > 0) {
      for (let obj of this.createdBrandNameList) {
        obj.isSelected = false;
      }
    }
  })
}
getAllCatCreatedCat() {
  this.customerService.getCategory().subscribe(res => {
    this.categoryListCat = res;
    this.temCategoryList = res
    let newlist = [];
    // for (const brand of this.filteredBrandlist) {
    //   this.tempBrandList.filter(x => x.brand_name_id == brand.brand_id);
    // }
    for (let i = 0; i < this.filteredCategoryList.length; i++) {
      this.temCategoryList.filter(x => {
        if (x.product_category_id == this.filteredCategoryList[i].cat_id) {
          newlist.push(x)
        }
      });

    }
    this.categoryListCat = newlist;
    if (this.categoryListCat.length > 0) {
      for (let obj of this.categoryListCat) {
        obj.isSelected = false;
      }
    }


  })
}


removeClass() {
  setTimeout(() => {
    $(".overlay-container").removeClass("addcontainerdisable");
  }, 1000);
}
removeDisp(pro, i){
   this.addToCart(pro, i)
 }

onSelectPage(value) {
  this.perPage = value.target.value
  // console.log(this.perPage)
}
removeCatalogueProduct(catdtlid){
  Swal.fire({
    title: `Are you sure you want to remove ?`,
    showDenyButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result) => {
    if (result.isConfirmed) {
      this.customerService.removeCatalogueProduct(catdtlid).subscribe(res => {
        Swal.fire('Remove item successfully!', '', 'success');
        this.firstCatalogueType = catdtlid;
        this.getAllBuyerCatalogue()
        
      }
      );
    }
  });

  // this.customerService.removeCatalogueProduct(catdtlid).subscribe(res => {
  //     this.firstCatalogueType = catdtlid;
  //   this.getAllBuyerCatalogue()
  //   location.reload()
  // })
 }
filterCatalogueTypeWise(id){
// console.log('idddddd', id);
this.firstCatalogueType = id;
  //  if(id)
  //     this.catalogueList = this.tempCatalogeList.filter(x => Number(x.catalogue_type_id) == Number(id));
  //  else
  //     this.catalogueList = this.tempCatalogeList;
}

searchInProductList(ev){
  this.productList = this.tempProductList.filter(item => ((item.product_name ? item.product_name.toLowerCase().indexOf(ev.toLowerCase()) === 0 : false)));
}

searchInCatalogueList(ev){
  if(ev){
    if(this.firstCatalogueType == 215)
    this.office_supplies = this.tempCatalogeList.filter(item =>  ((item.product_name ? item.product_name.toLowerCase().indexOf(ev.toLowerCase()) === 0 && Number(item.catalogue_type_id) == Number(this.firstCatalogueType): false ) || (item.price ? item.price.indexOf(ev) === 0 && Number(item.catalogue_type_id) == Number(this.firstCatalogueType): false )));
    if(this.firstCatalogueType == 216)
    this.merchandise = this.tempCatalogeList.filter(item =>  ((item.product_name ? item.product_name.toLowerCase().indexOf(ev.toLowerCase()) === 0 && Number(item.catalogue_type_id) == Number(this.firstCatalogueType): false ) || (item.price ? item.price.indexOf(ev) === 0 && Number(item.catalogue_type_id) == Number(this.firstCatalogueType): false )));
    if(this.firstCatalogueType == 217)
    this.print_collaterals = this.tempCatalogeList.filter(item =>  ((item.product_name ? item.product_name.toLowerCase().indexOf(ev.toLowerCase()) === 0 && Number(item.catalogue_type_id) == Number(this.firstCatalogueType): false ) || (item.price ? item.price.indexOf(ev) === 0 && Number(item.catalogue_type_id) == Number(this.firstCatalogueType): false )));
    
  }
  else {
        this.print_collaterals = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 217);
        this.merchandise = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 216);
        this.office_supplies = this.tempCatalogeList.filter(x=> x.catalogue_type_id == 215);
  }

}
filterByRating() {
  // console.log("this.productList",this.productList)
  // this.selectedRating = 'undefined'
  // if (this.selectedRating != 'undefined') {
  //   this.productList = this.tempProductList.filter(x => Number(x.avg_rating) == Number(this.selectedRating));
  // } else {
  //   this.productList = this.tempProductList
  // }
  if (this.selectedRating !== 'undefined') {
    this.productList = this.tempProductList.filter((product) => {
    
      const ratedStars = product.star.filter((star) => star.israted === true);
      return ratedStars.length === Number(this.selectedRating);
    });
  
  } else {
    this.productList = this.tempProductList;
  }
}
getCartProduct(){
  let sql = `select * from z_entitymast_user_cart_001 where customer_id = ${this.customerId}`
  this.formService.getFormData(sql).subscribe(res =>{
    this.cardlist = res.rows
    for (const cart of this.office_supplies) {
        for (let i = 0; i < this.cardlist.length; i++) {
            if(cart.product_id == this.cardlist[i].product_id)  {
              cart.qty = this.cardlist[i].quantity;
            }          
        }
    }
  })
}

}
