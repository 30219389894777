<div class="d-flex minhyt96vh" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary " style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>

              <li class="breadcrumb-item newitm2  active" aria-current="page">Manage Banner List </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="col-sm-12  mb-2 p-0">

        <div class="tabs row my-2 mx-0">

          <div class="tab-button-outer1 col-sm col-9 px-0 mb-sm-0 mb-2">
            <ul id="tab-button">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font9">Manage Banner
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-3 px-sm-3 px-0 order-sm-1 order-2" id="masterSearch">
            <div class=" input-group">
              <input type="text" class="searchbar form-control newform-control" onpaste="return false;" autocomplete="off" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
              <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 ml-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
          <div class="col-sm-auto text-right px-0 col-3 order-sm-2 order-1">
            <button type="button" class="btn btn-sm btn-circle btn-circle-sm  btn-theme toolbar-item ml-1" (click)="openpopup()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div id="tab01" class="tab-contents mmt5 p-0">

          <div class="col-sm-12 col-md-12 nopadding ">
            <div id="grid-theme-wrapper">
              <!-- [(perPage)]="page.size" -->
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoading"></ngx-loading>
              <div class="table-responsive" *ngIf="bannerDataList.length > 0">
                <igx-grid #grid1 [data]="bannerDataList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">
                  <igx-column field="varibale_name" header="Banner Type" width="200">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="title" header="Title" width="350">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="description" header="Description" width="450">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="is_active" header="Active" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="2 cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <igx-column field="" header="Action" width="150">
                    <ng-template compositeTemp igxCell let-cell="cell">
                      <div class="px-2 address-container">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                            <li class="border-bottom">
                              <a href="javascript:void(0)" data-toggle="modal" (click)="onEdit(cell)" data-target="#myModal" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa fa-pencil mr-1"></i> Edit </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa fa-trash mr-1"></i> Delete </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ng-template>
                  </igx-column>

                </igx-grid>
              </div>
              <label class="w-100 text-center text-dark" *ngIf="!isLoading && bannerDataList.length == 0">No Record Found</label>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div id="myModal" class="modal fade p-0" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header align-items-center py-2">
            <h6 class="font600 mb-0">{{isShow ? "Add New Banner " : "Edit Banner"}}</h6>
            <button type="button" class="close" data-dismiss="modal" (click)="resetForm()">
              <span> &times; </span>
            </button>
          </div>
          <div class="modal-body min-vh-70  pt-1">
            <!-- <h6 class="px-2 mb-0 mt-0 font8">Edit Banner</h6> -->

            <div class="col-sm-12 px-md-4">
              <div class="row my-md-3 align-items-center">
                <div class="col-md-2">
                  <label class="text-sm-right d-block">
                    Slider Type
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 mb-md-0">
                  <select class="form-control select" [(ngModel)]="sliderType">
                    <option value="" disabled selected>-- Select Slider Type --</option>
                    <option value="1">Web</option>
                    <option value="2">Mobile</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label class="text-sm-right d-block">
                    Banner Type
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 mb-md-0">
                  <select class="form-control select" [(ngModel)]="bannerType" (change)="onBannerTypeChange()">
                    <option value="" disabled selected>-- Select Banner Type --</option>
                    <option [value]="type.id" *ngFor="let type of bannertTypeList; index as i">{{type.varibale_name}}</option>
                    <!-- <option value="2">Bottom Banner</option>
                    <option value="3">Right Top</option>
                    <option value="4">Right Bottom</option> -->

                  </select>
                </div>
              </div>
             
              <div class="row  ">
                <div class="col-md-2">
                  <label class="text-sm-right d-block">Title
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 ">
                  <input type="text" id="txtTitleId" name="txtTitle" [(ngModel)]="txtTitle" class="form-control" maxlength="50"
                    placeholder="Enter Title" autocomplete="off" (keypress)="commonFunctionService.alphaNumericWithSpecialSymbolValidator($event)">
                </div>
                <div class="col-md-2">
                  <label class="text-sm-right d-block">
                    Category
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 ">
                  <select name="category" id="category" class="form-control mb-2" [(ngModel)]="category" (change)="categoryChanged()">
                    <option value="undefined" disabled>Select Category</option>
                    <option *ngFor="let cat of categoryList" [value]="cat.product_category_id">
                        {{cat.category_name}}
                    </option>
                </select>
                </div>
                <div class="col-md-2">
                  <label class="text-sm-right d-block">
                    Sub Category
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 ">
                  <select name="sub-category" id="sub-category" class="form-control mb-2" [(ngModel)]="sub_category"
                  (change)="getProducts()">
                        <option value="undefined" disabled>Select Sub Category</option>
                        <option *ngFor="let cat of subCategoryList" [value]="cat.product_category_id">
                            {{cat.category_name}}
                        </option>
                    </select>
                </div>
                <div class="col-md-2">
                  <label class="text-sm-right d-block">
                    Product
                    <!-- <span class="asterisk"> *</span> -->
                  </label>
                </div>
                <div class="col-md-4 mb-2">
                  <select name="" id="" class="form-control" [(ngModel)]="selectedProdcut">
                    <option value="undefined" disabled selected>Select Product</option>
                    <option [value]="pro.product_id" *ngFor="let pro of allProductList;">
                      {{pro.product_name}}
                    </option>
                  </select>
                </div>
              </div>
              <!-- <div class="row my-md-3 align-items-center">
                <div class="col-md-2">
                  <label class="text-sm-right d-block">Visible From
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 mb-md-0">
                  <input type="date" name="visibleFrom" id="visibleFrom" min="{{getMinDate()}}" required [(ngModel)]="visibleFrom" class="form-control">
                </div>
                <div class="col-md-2">
                  <label class="text-sm-right d-block">Visible Until
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-4 mb-2 mb-md-0">
                  <input type="date" name="visibleUntil" id="visibleUntil" min="{{getMinDate()}}" required [(ngModel)]="visibleUntil" class="form-control">
                </div>
              </div> -->
              <div class="row  align-items-center">
                <div class="col-md-2">
                  <label class="text-sm-right d-block">Banner Image
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-3 mb-2 mb-md-0">
                  <div class="form-group m-0">
                    <input type="file" name="bannerImage" #myInputVariable class="form-control-file border font8" id="bannerImage" accept="image/*"
                      (change)="onChange($event)">
                  </div>
                  <p class="shortnote" *ngIf="bannerType == 204">(Please upload image size 770x375)
                    <span class="asterisk12">*</span>
                  </p>
                  <p class="shortnote" *ngIf="bannerType == 205">(Please upload image size 1000x165)
                    <span class="asterisk12">*</span>
                  </p>
                  <p class="shortnote" *ngIf="bannerType == 206 || bannerType == 207">(Please upload image size 315x155)
                    <span class="asterisk12">*</span>
                  </p>
                  <!-- <p class="shortnote">(Please upload image size 315x155)<span class="asterisk12">*</span></p>
                   <p class="shortnote">(Please upload image size 1000x165)<span class="asterisk12">*</span></p> -->
                </div>
                <div class="col-md-2 py-1 p-sm-0 mb-2 mb-md-0">
                  <input type="checkbox" name="isactive" id="isactive" [(ngModel)]="isActive" class="checkboxcustom">
                  <label class="ml-2" for="isactive">
                    Is Active
                  </label>
                </div>


                <div class="col-lg-1 col-md-2">
                  <label class="text-sm-right d-block">Alternate Name
                    <!-- <span class="asterisk"> *</span> -->
                  </label>
                </div>
                <div class="col-md-4 mb-2 ">
                  <input type="text" name="alternateName" placeholder="Enter an Alternate Name" class="form-control" id="alternateName" required
                    [(ngModel)]="alternateName" onpaste="return false" oncopy="return false" autocomplete="off" ondrop="return false"
                    maxlength="50">
                </div>
              </div>

              <div class="row my-md-2 align-items-center">
                <div class="col-md-2">
                  <label class="text-sm-right d-block">
                    Image Width (in pixels)
                  </label>

                </div>

                <div class="col-md-4 mb-2 mb-md-0">

                  <input type="text" placeholder="Image Width" [(ngModel)]="txtWidth" class="form-control" disabled>
                </div>
                <div class="col-md-2">
                  <label class="text-sm-right d-block">Image Height (in pixels)
                    <!-- <span class="asterisk"> *</span> -->
                  </label>
                </div>
                <div class="col-md-4 mb-2 mb-md-0">
                  <input type="text" placeholder="Image Height" [(ngModel)]="txtHeight" class="form-control" disabled>
                </div>
              </div>

              <div class="row my-md-3">

                <div class="col-md-2">
                  <label class="text-sm-right d-block">Description
                    <span class="asterisk"> *</span>
                  </label>
                </div>
                <div class="col-md-10">
                  <textarea maxlength="500" class="form-control" placeholder="Description" name="txtDescription" id="txtDescriptionID" [(ngModel)]="txtDescription"
                  (keypress)="commonFunctionService.alphaNumericWithSpecialSymbolValidator($event)" autocomplete="off"></textarea>
                </div>
              </div>
            </div>
            <div class="col-sm-12 text-center m-auto pb-2 pt-3 pt-md-2">

              <button type="submit" id="btnSave" class="btn btn-sm btn-success  px-3 mr-2" *ngIf="isShow" (click)="Submit()">Save</button>
              <button type="submit" class="btn btn-sm btn-theme px-3 mr-2" *ngIf="!isShow" (click)="update()">Update</button>
              <button type="button" (click)="resetForm()" class="btn btn-sm btn-danger px-3">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>