<div class="card">
 <div class="col-sm-12 ">
     <ul class="topmenu newtopmenu d-flex justify-content-center">
         <li>
             <a href="javascript:void(0)" class="btn btn-sm active  " >
                 <i class="fa fa-home fa-2x"></i>
             </a>
         </li>
         <li>
            <a href="javascript:void(0)" class="btn btn-sm " >
                <i class="fa fa-users "></i>
                Admin
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="btn btn-sm  " >
                <i class="fa fa-users"></i>
                Department Head
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="btn btn-sm  " >
                <i class="fa fa-users"></i>
                Group Head
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="btn btn-sm " >
                <i class="fa fa-users"></i>
                Incharge
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="btn btn-sm " >
                <i class="fa fa-users"></i>
                Office Clerk
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="btn btn-sm " >
                <i class="fa fa-users"></i>
              Assitant Officer
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="btn btn-sm " >
                <i class="fa fa-users"></i>
               Operator 
            </a>
        </li>
     </ul>
 </div>