import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CommonFunctionService } from '../../_services/common-function.service';
import { FormService } from '../../_services/form.service';
import { ProductService } from '../../_services/product.service';
import { Router } from '@angular/router';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
import { ideacraftAdminService } from '../../_services/ideacraftadmin.service';
import { read } from 'fs';
import Swal from 'sweetalert2';
import { AppSettings } from '../../_services/AppSetting';
import { CustomerLoginSigninService } from '../../_services/customer-login-signin.service';
//import { Editor } from 'ngx-editor';
declare var $: any;

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  corporate_type: any;
  enterprise_name: any;
  any_url: any;
  specific_url: any;
  payment_term: any;
  credit_limt: any;
  subscription: any;
  contact_person: any;
  contact_number:any;
  concern_saleperson: any;
  email_address: any;
  concern_saleperson_email: any;
  concern_saleperson_contact: any;
  comany_name: any;
  gst_In: any;
  email_id: any;
  phone_number: any;
  billing_address:any;
  billing_city: any;
  billing_zipcode:any;
  default_billing_address: any;
  shipping_contact_person: any;
  shipping_emailid: any;
  shipping_phone_number: any;
  shipping_adress: any;
  shipping_city: any;
  shipping_zipcode: any;
  default_shipping_address: any;
  countryList: any = [];
  country_id: any;
  country_id_ship: any;
  stateList: any = [];
  state_id: any;
  state_id_ship: any;
  sysEnumList: any =[];
  credit_id: any;
  show: boolean = false;
  is_url: boolean= false;
  is_payment: boolean = false;
  is_credit: boolean = false;
  formData: FormData;
  MassageOutTime = AppSettings.getMassageOutTime;
  corp_password: any;
  cityList: any;
  enterprise_id: any;
  isEdit: boolean = false;
  customer_id: any;
  credit_remaining:any;
  total_credit :any;
  constructor(private productService: ProductService,private customerLoginSigninService: CustomerLoginSigninService,
    private formService: FormService, private sellerService: SellerRegistrationService,
    private ideacraftService: ideacraftAdminService,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,
    private router: Router) { }

  ngOnInit(): void {
        this.getAllCountries();
        this.getStateList();
        this.getSysEnum();
        if (localStorage.getItem("editable-user") != null && localStorage.getItem("editable-user") != undefined) {
          //debugger
          this.onUserEdit(localStorage.getItem("editable-user"));    
         
        //  this.getcorp();
        }
  }
  getSysEnum() {
    this.ideacraftService.getSysEnumType('creditPeriod').subscribe((res: any) => {
      debugger
      this.sysEnumList = res.rows;
    });
  }
  getAllCountries() {
    this.productService.getCounty().subscribe((res: any) => {
      this.countryList = res.rows;
    })
  }
  getStateList() {
    this.sellerService.getState().subscribe(res => {
      this.stateList = res;
    })
  }
  chooseCorpType() {
    if (this.corporate_type == 'Retail') {
      this.show = false
    }
    else {
      this.show = true
    }
  }
  chooseRetails(){
    if(this.corporate_type == 'Retail'){
      this.is_credit = false
    }else{
      this.is_credit = true
    }
  }
  chooseUrl() {
    if (this.any_url == 'no') {
      this.is_url = false
    }
    else {
      this.is_url = true
    }
  }
  choosePaymentTerm() {
    if (this.payment_term == 'cash') {
      this.is_payment = false
    }
    else {
      this.is_payment = true
    }
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  getCityList() {
    this.sellerService.getCity().subscribe(res => {
      this.cityList = res;
    })
  }
  getBCityAndState() {
    this.customerLoginSigninService.getCityState(this.billing_zipcode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.billing_city = stateCityList.city_name ? stateCityList.city_name : null;
        this.state_id = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.billing_city = null;
        this.state_id = null;
      }
    })

  }
  getSCityAndState() {
    this.customerLoginSigninService.getCityState(this.shipping_zipcode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.shipping_city = stateCityList.city_name ? stateCityList.city_name : null;
        this.state_id_ship = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.shipping_city = null;
        this.state_id_ship = null;
      }
    })

  }
  onSaveCorporateDetails(){
    if(!this.corporate_type){
      this.toastrService.warning("Please Choose Corporate Type!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.corporate_type == 'Enterprise'){
      this.enterprise_name = this.enterprise_name ? this.enterprise_name.trim() : ''
      if(this.enterprise_name == null || this.enterprise_name == undefined || this.enterprise_name == ''){
        this.toastrService.warning("Please Enter Enterprise Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }     
    }
    if(!this.any_url){
      this.toastrService.warning("Please Choose Any Specific URL!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.any_url == 'yes'){
      this.specific_url = this.specific_url ? this.specific_url.trim() : ''
      if(this.specific_url == null || this.specific_url == undefined || this.specific_url == ''){
        this.toastrService.warning("Please Enter URL!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }     
    }
    if(!this.payment_term){
      this.toastrService.warning("Please Choose Payment Term!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.payment_term == 'credit'){
      if(this.credit_id == null || this.credit_id == undefined || this.credit_id == ''){
      this.toastrService.warning("Please Select Credit Period!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      }
      this.credit_limt = this.credit_limt ? this.credit_limt.trim() : ''
      if(this.credit_limt == null || this.credit_limt == undefined || this.credit_limt == ''){
      this.toastrService.warning("Please Enter Credit Limit!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      }
      if(this.isEdit && (this.credit_limt < this.total_credit)){
        if(this.credit_remaining>=(this.total_credit - this.credit_limt)){
          this.toastrService.warning("Please settle credit limit first by buyer!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false;
        } 
      }
    }
    if(!this.subscription){
      this.toastrService.warning("Please Choose Subscription Type!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.contact_person = this.contact_person ? this.contact_person.trim() : ''
    if(this.contact_person == null || this.contact_person == undefined || this.contact_person == ''){
      this.toastrService.warning("Please Enter Contact Person Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.contact_number = this.contact_number ? this.contact_number.trim() : ''
    if (this.contact_number == null || this.contact_number == undefined || this.contact_number == '') {
      this.toastrService.warning('Please Enter Contact Number!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.contact_number !== null || this.contact_number !== undefined || this.contact_number !== '') {
      if (this.contact_number.toString().length == 10) {
        if ((this.contact_number[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    this.concern_saleperson = this.concern_saleperson ? this.concern_saleperson.trim() : ''
    if(this.concern_saleperson == null || this.concern_saleperson == undefined || this.concern_saleperson ==''){
      this.toastrService.warning("Please Enter Concern Sale Person Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.email_address == null || this.email_address == undefined || this.email_address == '') {
      this.toastrService.warning('Please Enter Email Address!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.email_address !== null || this.email_address !== undefined || this.email_address !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.email_address.substring(0).length > 0) {
        var email = this.email_address.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.concern_saleperson_email == null || this.concern_saleperson_email == undefined || this.concern_saleperson_email == '') {
      this.toastrService.warning('Please Enter Concern Sale Person Email!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.concern_saleperson_email !== null || this.concern_saleperson_email !== undefined || this.concern_saleperson_email !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.concern_saleperson_email.substring(0).length > 0) {
        var email = this.concern_saleperson_email.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.concern_saleperson_contact == null || this.concern_saleperson_contact == undefined || this.concern_saleperson_contact == '') {
      this.toastrService.warning('Please Enter Concern Sale Person Contact Number!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.concern_saleperson_contact !== null || this.concern_saleperson_contact !== undefined || this.concern_saleperson_contact !== '') {
      if (this.concern_saleperson_contact.toString().length == 10) {
        if ((this.concern_saleperson_contact[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    
    if(!this.isEdit){
      var minNumberofChars = 6;
      var maxNumberofChars = 16;
      let passpattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/;
      let pattern = /^((?=.*[a-z])(?=.*[A-Z]).*)$/
      if (this.corp_password == null || this.corp_password == undefined || this.corp_password == "" || this.corp_password == "null" || this.corp_password == "undefined") {
        this.toastrService.warning("Password is required", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      } else if (this.corp_password.length < minNumberofChars || this.corp_password.length > maxNumberofChars) {
        this.toastrService.warning("Your Password must be at least 6 characters", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      } else if (!pattern.test(this.corp_password)) {
        this.toastrService.warning("Password should contain atleast one upper case and one lower case character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false
      } else if (!passpattern.test(this.corp_password)) {
        this.toastrService.warning("Password should contain atleast one number and one special character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false
      }
    }
    
    this.comany_name = this.comany_name ? this.comany_name.trim() : ''
    if(this.comany_name == null || this.comany_name == undefined || this.comany_name == ''){
      this.toastrService.warning("Please Enter Company Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.gst_In == null || this.gst_In == undefined || this.gst_In == '') {
      this.toastrService.warning('Please Enter GST No', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.gst_In !== undefined && this.gst_In !== null && this.gst_In !== " ") {
      var pattrn = new RegExp('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9]{1}[zZ][a-zA-Z0-9]{1}$')
      if (this.gst_In.substring(0).length > 0) {
        var newpancard = this.gst_In.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid GST No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      }
    }
    if (this.email_id == null || this.email_id == undefined || this.email_id == '') {
      this.toastrService.warning('Please Enter Email For Billing!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.email_id !== null || this.email_id !== undefined || this.email_id !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.email_id.substring(0).length > 0) {
        var email = this.email_id.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.phone_number == null || this.phone_number == undefined || this.phone_number == '') {
      this.toastrService.warning('Please Enter Phone Number For Billing!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.phone_number !== null || this.phone_number !== undefined || this.phone_number !== '') {
      if (this.phone_number.toString().length == 10) {
        if ((this.phone_number[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    this.billing_address = this.billing_address ? this.billing_address.trim() : ''
    if(this.billing_address == null || this.billing_address == undefined || this.billing_address == ''){
      this.toastrService.warning("Please Enter Address for Billing!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.country_id == null || this.country_id == undefined || this.country_id == ''){
      this.toastrService.warning("Please Select Country!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.state_id == null || this.state_id == undefined || this.state_id == ''){
      this.toastrService.warning("Please Select Province/Territory/State !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.billing_city == null || this.billing_city == undefined || this.billing_city == ''){
      this.toastrService.warning("Please Enter City Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.billing_zipcode == null || this.billing_zipcode == undefined || this.billing_zipcode == ''){
      this.toastrService.warning("Please Enter Zip Code!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.shipping_contact_person = this.shipping_contact_person ? this.shipping_contact_person.trim() : ''
    if(this.shipping_contact_person == null || this.shipping_contact_person == undefined || this.shipping_contact_person == ''){
      this.toastrService.warning("Please Enter Contact Person Name For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.shipping_emailid == null || this.shipping_emailid == undefined || this.shipping_emailid == '') {
      this.toastrService.warning('Please Enter Email For Shipping!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.shipping_emailid !== null || this.shipping_emailid !== undefined || this.shipping_emailid !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.shipping_emailid.substring(0).length > 0) {
        var email = this.shipping_emailid.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.shipping_phone_number == null || this.shipping_phone_number == undefined || this.shipping_phone_number == '') {
      this.toastrService.warning('Please Enter Phone Number For Shipping!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"});
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.shipping_phone_number !== null || this.shipping_phone_number !== undefined || this.shipping_phone_number !== '') {
      if (this.shipping_phone_number.toString().length == 10) {
        if ((this.shipping_phone_number[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    this.shipping_adress = this.shipping_adress ? this.shipping_adress.trim() : ''
    if(this.shipping_adress == null || this.shipping_adress == undefined || this.shipping_adress == ''){
      this.toastrService.warning("Please Enter Address for Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.country_id_ship == null || this.country_id_ship == undefined || this.country_id_ship == ''){
      this.toastrService.warning("Please Select Country For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.state_id_ship == null || this.state_id_ship == undefined || this.state_id_ship == ''){
      this.toastrService.warning("Please Select Province/Territory/State !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.shipping_city == null || this.shipping_city == undefined || this.shipping_city == ''){
      this.toastrService.warning("Please Enter City Name For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.shipping_zipcode == null || this.shipping_zipcode == undefined || this.shipping_zipcode == ''){
      this.toastrService.warning("Please Enter Zip Code For Shipping!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    var corporateDetails: any = {
      corporate_type: this.corporate_type, enterprise_name: this.enterprise_name, any_url: this.any_url, specific_url: this.specific_url, payment_term: this.payment_term,
      credit_id: this.credit_id, credit_limt: this.credit_limt, subscription: this.subscription, contact_person: this.contact_person, contact_number: this.contact_number,
      concern_saleperson: this.concern_saleperson, email_address: this.email_address, concern_saleperson_email: this.concern_saleperson_email, concern_saleperson_contact: this.concern_saleperson_contact,
      comany_name: this.comany_name, gst_In: this.gst_In, email_id: this.email_id, phone_number: this.phone_number, billing_address: this.billing_address, country_id: this.country_id,
      state_id: this.state_id, billing_city: this.billing_city, billing_zipcode: this.billing_zipcode, default_billing_address: this.default_billing_address, 
      shipping_contact_person: this.shipping_contact_person, shipping_emailid: this.shipping_emailid, shipping_phone_number: this.shipping_phone_number, shipping_adress: this.shipping_adress,
      country_id_ship: this.country_id_ship, state_id_ship: this.state_id_ship, shipping_city: this.shipping_city, shipping_zipcode: this.shipping_zipcode, default_shipping_address: this.default_shipping_address,
      corp_password: this.corp_password
    };
    if(this.isEdit){
      this.customerLoginSigninService.updateCorporateDetails(corporateDetails, this.customer_id).subscribe((res: any) => {
        if (res.status == 201) {
          this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false
        }else if (res.status == 200){
          this.toastrService.success("Corporate Details Updated Successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          this.removeClass();
          this.reset();
          this.router.navigateByUrl('/buyer-list'+ '?' + localStorage.getItem('tempToken'));
        }     
      })
    }else{
      this.customerLoginSigninService.saveCorporateDetails(corporateDetails).subscribe((res: any) => {
        if (res.status == 201) {
          this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false
        }else if (res.status == 200){
          this.toastrService.success("Corporate Details Save Successfully!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          this.removeClass();
          this.reset();
          this.router.navigateByUrl('/buyer-list'+ '?' + localStorage.getItem('tempToken'));
        }     
      }) 
    }
     
  }

  reset(){
    this.corporate_type= ''
    this.any_url = ''
    this.payment_term = ''
    this.subscription = ''
    this.contact_person = ''
    this.contact_number = ''
    this.concern_saleperson = ''
    this.email_address = ''
    this.concern_saleperson_email= ''
    this.concern_saleperson_contact = ''
    this.comany_name = ''
    this.gst_In = ''
    this.email_id = ''
    this.phone_number = ''
    this.billing_address = ''
    this.country_id = ''
    this.state_id = ''
    this.billing_city = ''
    this.billing_zipcode = ''
    this.default_billing_address = ''
    this.shipping_contact_person = ''
    this.shipping_emailid = ''
    this.shipping_phone_number = ''
    this.shipping_adress = ''
    this.country_id_ship = ''
    this.state_id_ship = ''
    this.shipping_city = ''
    this.shipping_zipcode = ''
    this.default_shipping_address = ''
    this.credit_id = ''
    this.credit_limt = ''
    this.enterprise_name = ''
    this.specific_url = ''
    this.corp_password = ''
  }
  onBack(){
    this.router.navigateByUrl('/buyer-list' + '?' + localStorage.getItem('tempToken'))
  }
  onUserEdit(customer_id){
    this.isEdit = true;
    this.customerLoginSigninService.onUserEdit(customer_id).subscribe(res => {
      debugger
      let data1 = res[1].rows
      console.log("data111111", data1)
    this.customer_id = res[0].rows[0].customer_id;
    this.corporate_type= res[0].rows[0].corporate_type;
    this.any_url = res[0].rows[0].is_specific_url;
    this.payment_term = res[0].rows[0].payment_term;
    this.subscription = res[0].rows[0].subscription;
    this.contact_person = res[0].rows[0].contact_person;
    this.contact_number = res[0].rows[0].contact_number;
    this.concern_saleperson = res[0].rows[0].salesperson_name;
    this.email_address = res[0].rows[0].email_address;
    this.concern_saleperson_email= res[0].rows[0].salesperson_email;
    this.concern_saleperson_contact = res[0].rows[0].salesperson_no;
    this.comany_name = res[0].rows[0].company_name;
    this.gst_In = res[0].rows[0].gst;
    this.email_id = res[0].rows[0].email;
    this.phone_number = res[0].rows[0].phone_no;
    this.billing_address = res[0].rows[0].address;
    this.country_id = res[0].rows[0].country;
    this.state_id = res[0].rows[0].state;
    this.billing_city = res[0].rows[0].city;
    this.billing_zipcode = res[0].rows[0].zip_code;
    this.default_billing_address = res[0].rows[0].default_billing_address;
    this.shipping_contact_person = res[1].rows[0].contact_person;
    this.shipping_emailid = res[1].rows[0].email;
    this.shipping_phone_number = res[0].rows[0].phone_no;
    this.shipping_adress = res[1].rows[0].address;
    this.country_id_ship = res[1].rows[0].country;
    this.state_id_ship = res[1].rows[0].state;
    this.shipping_city = res[1].rows[0].city;
    this.shipping_zipcode = res[1].rows[0].zip_code;
    this.default_shipping_address = res[1].rows[0].default_shipping_address;
    this.credit_id = res[0].rows[0].credit_period;
    this.credit_limt = res[0].rows[0].credit_limit;
    this.total_credit = res[0].rows[0].total_credit;
    this.credit_remaining =res[0].rows[0].credit_remaining;
    this.enterprise_name = res[0].rows[0].enterprise_name;
    this.specific_url = res[0].rows[0].url;
    this.corp_password = res[0].rows[0].corporate_type;
    this.enterprise_id = res[0].rows[0].enterprise_id;
    if (this.corporate_type == 'Retail') {
      this.show = false
    }
    else {
      this.show = true
    }
    if(this.corporate_type == 'Retail'){
      this.is_credit = false
    }else{
      this.is_credit = true
    }
    if (this.any_url == 'no') {
      this.is_url = false
    }
    else {
      this.is_url = true
    }
    if (this.payment_term == 'cash') {
      this.is_payment = false
    }
    else {
      this.is_payment = true
    }
    })
  }
}
