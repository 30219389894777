
<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
     
      <div class="container-fluid py-3 bg-white">
          <nav aria-label="breadcrumb p-0">
              <ol class="breadcrumb p-0 bg-white">
                <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/dashboardnew">Dashboard</a></li>                
                <li class="breadcrumb-item active" aria-current="page">Total Order History </li>
              </ol>
            </nav>
        <div class="col-sm-12  mb-2 p-0">
          <div class="tabs col-lg-12 pl-0 pr-1 mt-2 d-flex">
           
            <!-- <ul class="breadcrumb">
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Pictures</a></li>
                </ul> -->
            <div class="tab-button-outer1  col-lg-6 pl-0">
              <ul id="tab-button" class="tbsbtns">
                <li id="tabDraft" class="is-active">
                  <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(187); SearchValue.value = '' ">Pending <span>({{pendingOrderCount}})</span> 
                  </a>
                </li>
                <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(192); SearchValue.value = '' ">Dispatch to Shipper<span>({{readyToShipedOrderCount}})</span> 
                    </a>
                  </li>
                  <li id="" class="">
                      <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(189); SearchValue.value = '' ">Shipped <span>({{shipedOrderCount}})</span> 
                      </a>
                    </li>
                    <li id="" class="">
                        <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(193); SearchValue.value = '' ">Deliverd <span>({{deleveredOrderCount}})</span>  
                        </a>
                      </li>
                      <!-- <li id="" class="">
                        <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(188)">Rejected <span>({{rejectOrderCount}})</span>  
                        </a>
                      </li> -->
                    <li id="" class="">
                      <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(190, 191); SearchValue.value = '' ">Cancelled <span>({{cancledOrderCount}})</span>  
                      </a>
                    </li>
                   
                    <!-- <li id="" class="">
                        <a href="javascript:void(0)" class="font8" >Archived List <span>(0)</span>  
                        </a>
                      </li> -->
                    <!-- <li id="" class="">
                        <a href="javascript:void(0)" class="font8" (click)="">Archived List <span>(0)</span>  
                        </a>
                      </li> -->
              </ul>
            </div>
            <div class="col-sm-6 cstmposition2 d-none d-md-block" id="masterSearch">
              <div class="sort-wrapper pull-right width100">
                <div class="form-group col-lg-9 pr-0">
                  <div class=" input-group">
                    <input type="search" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                      placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                    <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
                <div>
  
                </div>
              </div>
            </div>
          </div>
          <div id="tab01" class="tab-contents mmt5 p-0">
            <div class="col-sm-12 col-md-12 mt-2 nopadding">
              <div id="grid-theme-wrapper" >
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination2 == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination3 == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination4 == true"></app-hds-main-tab-group>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination5 == true"></app-hds-main-tab-group>
                <!-- <ngx-loading [show]="isLoader"></ngx-loading> -->
                <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
  
                <igx-grid #grid1 [data]="allOrderList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <igx-column field="order_number" header="Order No" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                      
                          <span class="cell-vl"  [innerHtml]="cell.value">
                          </span>
                       
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="seller_name" header="Seller Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                      
                          <span class="cell-vl"  [innerHtml]="cell.value">
                          </span>
                       
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_name" header="Product Details" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                         
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                     
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="total_amount" header="Price" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="total_quantity" header="Quantity" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl" [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field=" " header="Action" width="15%">
                      <ng-template compositeTemp igxCell let-cell="cell">
                        <div class="address-container mr-2 text-center">
                          <button data-toggle="modal" class="btn btn-sm text-white bg-success rounded-pill"  (click)="viewDetails(cell)">
                             View Details</button>
                        </div>
                       
                      </ng-template>
                    </igx-column>
                </igx-grid>
              </div>

             
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
    <!-- view model -->
  
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header p-2">
            <h5 class="modal-title" id="exampleModalLabel">Product Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"> &times; </span>
              </button>
          </div>
          <div class="modal-body minhyt70" *ngIf="isView">
            <app-product-details [isView]="isView" [view_item_id]="view_item_id"></app-product-details>
          </div>
          <div class="modal-footer p-2">
            <button type="button" class="btn btn-gray btn-sm" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
     
  </div>