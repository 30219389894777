import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
// var JSZipUtils = require('jszip-utils');
// var Docxtemplater = require('docxtemplater');
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionService {
  hindiRegex: any;
  constructor(
    private http: HttpClient
  ) {
    var numberOfHindiCharacters = 128;
    var unicodeShift = 0x0900;
    var hindiAlphabet = [];
    for (var i = 0; i < numberOfHindiCharacters; i++) {
      hindiAlphabet.push("\\u0" + (unicodeShift + i).toString(16));
    }
    this.hindiRegex = new RegExp("(?:^|\\s)[" + hindiAlphabet.join("") + "]+?(?:\\s|$)", "g");
  }

  otpResendTime = 120;
  dateFormat = 'dd-mm-yyyy';
 
  auditList: any[] = [];



  numericCheck(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    var regex = /^[0-9]+$/;
    if (regex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      return false;
    }
  }

  numericWithDotValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    var regex = /^[0-9 \.]+$/;
    if (regex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      return false;
    }
  }
  numericWithDotMinusValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    var regex = /^[-0-9 \.]+$/;
    if (regex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      return false;
    }
  }

  amountValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    var regex = /^[0-9\.]+$/;
    if (regex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      return false;
    }
  }

  numericWithHiphenValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    var regex = /^[0-9 \-]+$/;
    if (regex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      return false;
    }
  }

  alphabetsValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[A-Za-z ]+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphabetsValidatorWithoutSpace(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[A-Za-z]+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphaNumericValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    //u0900-u097F
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[A-Za-z0-9 ]+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }

  }
  alphaNumericValidatorwithDash(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    //u0900-u097F
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[A-Za-z0-9- ]+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }

  }

  alphaNumericValidatorWithEnterAndComma(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    //u0900-u097F
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[A-Za-z,0-9 ]+$/;
      if (regex.test(String.fromCharCode(keyCode)) || event.keyCode === 13) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  alphaNumericWithSpecialSymbolValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z0-9_\\.\-\\#\\(\\)\\,\\!\\@ //\\s])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphaNumericWithCommaDotHiphenValidator(event) { // ,.-
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z0-9.\-\\,\\s ])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  alphabetWithDotValidator(event) { // ,.-
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z.\\s ])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  alphaNumericWithSlashHiphenValidator(event) { // ,.-
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z0-9-\\/\\s ])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  alphaNumericWithHiphenSpaceSlashValidator(event) { // ,.-
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([0-9a-zA-Z/ -])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  eMailValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z0-9-_\\.\\@])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  timeValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([0-9\\:])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphabetWithBracketAndHiphenValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z() /-])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphabetWithHiphenValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z -])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphaNumericWithSlashValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([a-zA-Z0-9/])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  addressValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[#.0-9a-zA-Z/s, -]+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  alphanumericWithHiphenSpace(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^[0-9a-zA-Zs -]+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  orderNoValidator(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    //Regex for Valid Characters i.e. Alphabets and Numbers.
    if (this.hindiRegex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      var regex = /^([0-9-\\_\\/\\A-Z\\a-z\\ ])+$/;
      if (regex.test(String.fromCharCode(keyCode))) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  convert_yyyy_mm_dd_to_dd_mm_yyyy(reqDate) {
    var resDate = null;
    if (reqDate != null && reqDate != "" && reqDate != undefined) {
      var tmpDate = reqDate.split("-");
      resDate = tmpDate[2] + "-" + tmpDate[1] + "-" + tmpDate[0];
    }
    else {
      resDate = reqDate;
    }
    return resDate;
  }
  
  convert_dd_mm_yyyy_to_yyyy_mm_dd(reqDate) {
    var resDate = null;
    if (reqDate != null && reqDate != "" && reqDate != undefined) {
      var tmpDate = reqDate.split("-");
      resDate = tmpDate[2] + "-" + tmpDate[1] + "-" + tmpDate[0];
    }
    else {
      resDate = reqDate;
    }
    return resDate;
  }

  getLabelJSON(jsonURL) {
    return this.http.get(jsonURL)
      .pipe(map(
        (data) => {
          return data as any;
        },
        error => {
          console.log("there was an error sending the query", error);
        }
      ));
  }
  getMessageJSON(jsonURL): Observable<any> {
    return this.http.get(jsonURL)
      .pipe(map(
        (data) => {
          return data as any;
        },
        error => {
          console.log("there was an error sending the query", error);
        }
      ));
  }

  // removeCulpritChar(data, msgs) {
  //   var str = data.toString();
  //   var conditions = ["select", "delete", "drop", "insert", "update", "union", "xp_", "truncate", "-xp", "--", ";"]
  //   var value = 0;
  //   var str1 = str.toLowerCase();
  //   conditions.forEach(function (word) {
  //     value = value + str1.includes(word);
  //   });
  //   if (value > 0) {
  //     str = str.replace(/select|delete|drop|insert|update|union|xp_|truncate|-xp|--|;/gi, "");
  //     alert("Please don't use culprit character");
  //   }
  //   //msgs.push({ severity: 'warn', summary: '', detail:  "Please don't use culprit character" });

  //   //
  //   let trimStr: String = str;
  //   str = trimStr.removeExtraSpaces();
  //   return str.trim();
  // }
  onOrderNoChange(data) {
    var ordrn = data.substring(0);
    if (ordrn.length > 0) {
      var letters = /^[0-9\\\s\\/\\_\\-]+$/
      if (letters.test(ordrn)) {
        alert("Invalid Order No. Number/ space/special characters alone are not allowed.");
        return null;
      } else {
        return ordrn;
      }
    }
  }
  getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }

  // loadFile(url, callback) {
  //   JSZipUtils.getBinaryContent(url, callback);
  // }
  // getDocxFile(docName, docxList, fileName) {
  //   this.loadFile("../assets/file" + "/" + fileName, function (error, content) {
  //     if (error) { throw error };
  //     const zip = new JSZip(content);
  //     const doc = new Docxtemplater().loadZip(zip)
  //     doc.setData(docxList);
  //     //console.log(docxList)
  //     try {
  //       doc.render()
  //     }
  //     catch (error) {
  //       var e = {
  //         message: error.message,
  //         name: error.name,
  //         stack: error.stack,
  //         properties: error.properties,
  //       }
  //       if (error.properties && error.properties.errors instanceof Array) {
  //         const errorMessages = error.properties.errors.map(function (error) {
  //           return error.properties.explanation;
  //         }).join("\n");
  //         console.log('errorMessages', errorMessages);
  //       }
  //       throw error;
  //     }

  //     var out = doc.getZip().generate({
  //       type: "blob",
  //       mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //     })
  //     saveAs(out, docName);
  //   })
  // }

  checkDateValidation(startDate, endDate) {
    let d, m, y, d1, m1, y1, varStartDate, varEndDate, newStartDate, newEndDate;
    varStartDate = startDate.split("-");
    varEndDate = endDate.split("-");

    d = varStartDate[0];
    m = varStartDate[1];
    y = varStartDate[2];

    d1 = varEndDate[0];
    m1 = varEndDate[1];
    y1 = varEndDate[2];

    let newOrderDate, newcuttOffDate;

    m = m - 1;
    m1 = m1 - 1;
    newStartDate = new Date(y, m, d);
    newEndDate = new Date(y1, m1, d1);
    if (newStartDate >= newEndDate) {
      return true;
    } else {
      return false;
    }

  }

  convertAmountIntoWords(n) {
    var nums = n.toString().split('.')
    var whole = this.Rs(nums[0])
    if (nums[1] == null) nums[1] = 0;
    if (nums[1].length == 1) nums[1] = nums[1] + '0';
    if (nums[1].length > 2) { nums[1] = nums[1].substring(2, length - 1) }
    if (nums.length == 2) {
      if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
      var fraction = this.Rs(nums[1])
      var op = '';
      if (whole == '' && fraction == '') { op = 'Zero only'; }
      if (whole == '' && fraction != '') { op = 'paise ' + fraction + ' only'; }
      if (whole != '' && fraction == '') { op = '' + whole + ' only'; }
      if (whole != '' && fraction != '') { op = '' + whole + 'and paise ' + fraction + ' only'; }
      var amt = n;
      if (amt > 999999999.99) { op = 'Oops!!! The amount is too big to convert'; }
      if (isNaN(amt) == true) { op = 'Error : Amount in number appears to be incorrect. Please Check.'; }
      return op;
    }
  }
  Rs(amount) {
    var words = new Array();
    words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
    amount = amount.toString();
    var atemp = amount.split('.');
    var number = atemp[0].split(',').join('');
    var n_length = number.length;
    var words_string = '';
    if (n_length <= 9) {
      var n_array: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = 0;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + ' ';
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += 'Crores ';
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += 'Lakhs ';
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += 'Thousand ';
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += 'Hundred and ';
        } else if (i == 6 && value != 0) {
          words_string += 'Hundred ';
        }
      }
      words_string = words_string.split(' ').join(' ');
    }
    return words_string;
  }
  onFocus(value, labelNameEn, lableNameHn) {
    if (this.auditList.filter(x => x.labelNameEn == labelNameEn).length == 0) {
      this.auditList.push({ "labelNameEn": labelNameEn, "labelNameHn": lableNameHn, "oldValue": value, "newValue": null });
    }
  }

  onBlur(value, labelNameEn, labelNameHn) {
    var selid = this.auditList.filter(x => x.labelNameEn == labelNameEn)
    if (selid.length > 0) {
      if (selid[0].oldValue == value) {
        this.auditList = this.auditList.filter(x => x.labelNameEn != labelNameEn);
      }
      else {
        selid[0].newValue = value;
      }
    }
  }

  getAuditList() {
    return this.auditList;
  }

}
