<header class="header app-header cstmshadow m-0 megenta headbg">
  <div class="user-panel2 pt-0 mobhide" id="logoemblm">
    <div class="pull-left image d-flex mll-4-new height-40" routerLink="/dashboardnew">
      <img src="../../../assets/images/Ideacraft-IT-icons3.svg" class="width-auto px-2" alt="idecraft">
    </div>
  </div>
  <nav class="navbar navbar-toggleable-md navbar-light pt-0 pb-0">
    <!-- <button class="navbar-toggler navbar-toggler-right cstmtogglebutton button-left cstmbtnmenu" (click)="btnnav()" type="button" data-toggle="collapse" data-target="#" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
      </button> -->

    <div class="navbar-collapse flex-row-reverse mobdesign" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!-- 
        <li class="nav-item dropdown  user-menu mobhide">
          <a class="nav-link cstmlink cstmpadding dropdown-toggle text-white" href="javascript:void(0)"
            id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <label class="hidden-xs accountmon">
              <span >Rs</span>&nbsp; : &nbsp;<span>0</span>
              <span class="m-0 font8 ">Signed in as</span> <br>
              <span class="m-0 " style="font-size: 1.2em"><b>{{role_name}}</b></span>
            </label> 
          </a>
        
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" (click)="setNewRole(obj.userprofile_id)" href="javascript:void(0)"
              *ngFor="let obj of profileJson">{{obj.designation_name}}, {{obj.office_name}}</a>
          </div>  
        </li>-->
        <li class="nav-item dropdown  user-menu border-noneleft">
          <a class="nav-link cstmlink cstmpadding dropdown-toggle text-dark" href="javascript:void(0)" id="navbarDropdownMenuLink"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <label class="loginname text-dark d-flex align-items-center">
              <i class="fa fa-user-circle mr-1 fs1"></i>
              <span class="m-0 font8 hiddenmobile">
                {{user_name}}</span>
              <span class="m-0 font7">{{officeName}}
                <i class="fa fa-angle-down ml-1"></i>
              </span>
            </label>

            <!-- <img src="../../assets/images/profile-user.svg" class="user-image" alt="User Image"> -->

          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <p class="dropdown-item loginnamemob mb-0 font-weight-bold">
              <span class="m-0 font8">{{user_name}}</span>
              <br>
              <span class="m-0 font7">{{departmentName}}</span>
            </p>
            <!-- <a class="dropdown-item" href="javascript:void(0)">Action</a>
            <a class="dropdown-item" href="javascript:void(0)">Another action</a> -->
            <!-- <a class="dropdown-item" href="javascript:void(0)">Settings</a> -->
            <!-- Change Password-->
            <a class="dropdown-item" href="javascript:void(0)" (click)='showChangePassword()'>Change Password</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)='logout()'>Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>