
<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
  
      <div class="container-fluid py-2 bg-white">

       
        <div class="col-sm-12  mb-2 p-0">
          <div class="tab-button-outer1  col-lg-6 pl-0">
            <ul id="tab-button" class="tbsbtns">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font8" (click)="ProductApprovedList()">Approved List <span>({{approvedCount}}
                    )</span>
                </a>
              </li>
              <li id="" class="">
                <a href="javascript:void(0)" class="font8" (click)="productSuspendedList()">Suspended List
                  <span>({{suspendedCount}} )</span>
                </a>
              </li>
              <li id="" class="">
                <a href="javascript:void(0)" class="font8" (click)="productBlockList()">Block List <span>({{blockCount}} )</span>
                </a>
              </li>
              <li id="" class="">
                <a href="javascript:void(0)" class="font8" (click)="productRejectedList()">Rejected List <span>({{rejectCount}}
                    )</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="tabs col-lg-12 pl-0 pr-1 mt-2 d-flex">

         
            <div class="col-sm-6 cstmposition2" id="masterSearch">
              <div class="sort-wrapper pull-right width100">
                <div class="form-group col-lg-9 pr-0">
                  <div class=" input-group">
                    <input type="search" class="searchbar form-control newform-control" onpaste="return false;" autocomplete="off" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                      placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                    <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
                <div>
  
                </div>
              </div>
            </div>
          </div>
          <div id="tab01" class="tab-contents mmt5 p-0">
            <div class="col-sm-12 col-md-12 mt-2 nopadding">
              <div id="grid-theme-wrapper" *ngIf='approvedDataShow'>
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
                <ngx-loading [show]="isLoader"></ngx-loading>
  
                <igx-grid #grid1 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <igx-column field="seller_name" header="Seller Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="/seller-profile" target="_blank">
                          <span class="cell-vl" (click)="showSellerDetails(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_name" header="Product Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl" (click)="onProductView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="category_name" header="Category Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="price" header="Product Price" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="date" header="Upload Date" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
  
                  <!-- <igx-column field="qcstatus_value" header="Status" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                       
                      </div>
                    </ng-template>
                  </igx-column> -->
  
                  <igx-column field="" header="Action" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span>
                          <button (click)="updateStatusPopup(cell,'block')" class="btn btn-sm py-1 px-2 btn-theme rounded-pill mr-2">
                            Block
                          </button>
                        </span>
                      </div>
                      <div class="address-container">
                        <span>
                          <button (click)="updateStatusPopup(cell, 'suspend')" class="btn btn-sm px-2 py-1 btn-gray rounded-pill">
                            Suspended
                          </button>
                        </span>
                      </div>
                      <div class="address-container">
                          <span>
                            <button (click)="productListing(cell)" class="btn btn-sm px-2 py-1 btn-gray rounded-pill">
                              Product Listing
                            </button>
                          </span>
                        </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>

              <!-- Suspended -->
              <div id="grid-theme-wrapper" *ngIf='susendedDataShow'>
                  <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid1($event)" *ngIf="LoadPaginationtwo == true"></app-hds-main-tab-group>
                  <ngx-loading [show]="isLoader"></ngx-loading>
    
                  <igx-grid #grid1 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'">
                    <igx-column field="seller_name" header="Seller Name" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="/seller-profile" target="_blank">
                            <span class="cell-vl" (click)="showSellerDetails(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="product_name" header="Product Name" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <a href="javascript:void(0)">
                            <span class="cell-vl" (click)="onProductView(cell)">{{ cell.value }}
                            </span>
                          </a>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="category_name" header="Category Name" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl" [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="price" header="Product Price" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
                    <igx-column field="date" header="Upload Date" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>
    
                    <!-- <igx-column field="qcstatus_value" header="Status" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                         
                        </div>
                      </ng-template>
                    </igx-column> -->
    
                    <igx-column field="" header="Action" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span>
                            <button (click)="onProductView(cell)" class="btn btn-sm py-1 px-2 btn-theme rounded-pill mr-2">
                              Active
                            </button>
                          </span>
                        </div>
                        
                      </ng-template>
                    </igx-column>
                  </igx-grid>
                </div>
                <!-- Block-->
                <div id="grid-theme-wrapper" *ngIf='blockDataShow'>
                    <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid1($event)" *ngIf="LoadPaginationone == true"></app-hds-main-tab-group>
                    <ngx-loading [show]="isLoader"></ngx-loading>
      
                    <igx-grid #grid1 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                      [filterMode]="'excelStyleFilter'">
                     <igx-column field="seller_name" header="Seller Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="/seller-profile" target="_blank">
                          <span class="cell-vl" (click)="showSellerDetails(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_name" header="Product Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl" (click)="onProductView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="category_name" header="Category Name" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                      <igx-column field="price" header="Product Price" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span [innerHtml]="cell.value"></span>
                          </div>
                        </ng-template>
                      </igx-column>
                      <igx-column field="date" header="Upload Date" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span [innerHtml]="cell.value"></span>
                          </div>
                        </ng-template>
                      </igx-column>
      
                      <!-- <igx-column field="qcstatus_value" header="Status" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                           
                          </div>
                        </ng-template>
                      </igx-column> -->
      
                      <igx-column field="qcstatus" header="Action" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span>
                              <button (click)="onProductView(cell)" class="btn btn-sm py-1 px-2 btn-theme rounded-pill mr-2">
                                Unblock
                              </button>
                            </span>
                          </div>
                          
                        </ng-template>
                      </igx-column>
                    </igx-grid>
                  </div>
                  <div id="grid-theme-wrapper" *ngIf='rejectDataShow'>
                    <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid3($event)" *ngIf="LoadPaginationthree == true"></app-hds-main-tab-group>
                    <ngx-loading [show]="isLoader"></ngx-loading>
      
                    <igx-grid #grid1 [data]="allProductList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                      [filterMode]="'excelStyleFilter'">
                      <igx-column field="seller_name" header="Seller Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <a href="/seller-profile" target="_blank">
                              <span (click)="showSellerDetails(cell)">{{ cell.value }}
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </igx-column>
                      <igx-column field="product_name" header="Product Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <a href="javascript:void(0)">
                              <span (click)="onProductView(cell)">{{ cell.value }}
                              </span>
                            </a>
                          </div>
                        </ng-template>
                      </igx-column>
                      <igx-column field="category_name" header="Category Name" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span [innerHtml]="cell.value"></span>
                          </div>
                        </ng-template>
                      </igx-column>
                      <igx-column field="price" header="Product Price" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span [innerHtml]="cell.value"></span>
                          </div>
                        </ng-template>
                      </igx-column>
                      <igx-column field="date" header="Upload Date" width="">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span [innerHtml]="cell.value"></span>
                          </div>
                        </ng-template>
                      </igx-column>
                  </igx-grid>
                  </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
    <!-- view model -->
  
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header p-2">
            <h5 class="modal-title" id="exampleModalLabel">Product Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"> &times; </span>
              </button>
          </div>
          <div class="modal-body minhyt70" *ngIf="isView">
            <app-product-details [isView]="isView" [view_item_id]="view_item_id"></app-product-details>
          </div>
          <div class="modal-footer p-2">
            <button type="button" class="btn btn-gray btn-sm" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <!-- -----------------confim modal----------- -->
  
    <div class="modal fade" id="myModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="ms-auto p-2">
            <button type="button" class="close btn" data-dismiss="modal">
              &times;
            </button>
          </div>
  
          <!-- Modal body -->
          <div class="modal-body px-3 py-0">
            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <label>Remarks</label>
                </div>
                <div class="col-sm-12">
                  <textarea name="remarks" id="remarks" placeholder="Enter remarks here...." [(ngModel)]="adminRemarks" class="form-control2" maxlength="500"></textarea>
  
                </div>
              </div>
            </div>
            <div class="col-sm-12 text-center py-3">
              <button type="button" pButton (click)="onBlock()" *ngIf='action == "block"' label="Block" class="btn btn-theme btn-sm mr-3">
                Block
              </button>
              <button type="button" pButton (click)="onSuspended()" *ngIf='action == "suspend"'  label="Suspended" class="btn btn-gray btn-sm mr-2 me-2">
                Suspended
              </button>
              <button type="button" pButton (click)="onActive()" *ngIf="qcstatus == 185" label="Unblock" class="btn btn-gray btn-sm mr-2 me-2">
                Unblock
              </button>
              <button type="button" pButton (click)="onActive()" *ngIf="qcstatus == 186" label="Active" class="btn btn-gray btn-sm mr-2 me-2">
                Active
              </button>
              <button type="button" pButton (click)="reset()" label="Rejected" class="btn btn-gray btn-sm mr-2 me-2">
                Cancle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>