import { Component ,ViewChild,OnInit  } from '@angular/core';
import { CalendarApi, FullCalendarComponent} from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { Router } from '@angular/router'; 
import { SectionConfig } from "../../field.interface";
import {FormService} from '../../_services/form.service';
declare var $ :any;

let eventGuid = 0;
const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
// const str1= new Date("2021-03-05").toISOString().replace(/T.*$/, '');

@Component({
  selector: 'app-event-calender',
  templateUrl: './event-calender.component.html',
  styleUrls: ['./event-calender.component.css']
})

export class EventCalenderComponent implements OnInit{
  
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarApi:any;
  
  frameworkComponents: any;

  formId:any=0;
  form_viewid:any=0;
  tempFormId: number =0;
  temptablePrimaryKey: string="";
  formName:any;
  tempformName:any;
  tableName:any;
  tablePrimaryKey : any
  SectionConfig:SectionConfig[]=[];  
  formDisplayJSON:any;

  filtersLoaded: Promise<boolean>;
  FormViewLoaded:Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  ViewLoaded: Promise<boolean>;
  EditLoaded: Promise<boolean>;
  isAddMode:boolean=true;
  is_attachment: boolean=false;

  SuccessMasg:any;
  ViewTableQry:any;
  editValueRow:any;
  editrowid:any;
  viewFilterString:any;
  filterCriteria:any;
  refKey:any=0;

  isAdd:boolean=false;
  isEdit:boolean=false;
  isView:boolean= false;
  isExpotable:boolean=false;
  isResetFilter:Boolean=false;

  INITIAL_EVENTS: any=[];
  calendarOptions: CalendarOptions ={}
  currentEvents: EventApi[] = [];

  constructor(private router: Router,private formService : FormService) { 
  }

  ngOnInit(): void {
    this.isAddMode=true;
    let id;
    let uri=this.router.url.split("?");
    if(uri.length>1){
      var str = uri[1];
          //str= str.replace(/=/g,'');
          var value=str.split("&");
          console.log("value===>",value[0].indexOf('frmid'),value[0].indexOf('vid'),value[1].indexOf('frmid'),value[1].indexOf('vid'))
          if(value[0].indexOf('frmid')>=0){
            this.formId=value[0]?value[0].split('=')[1]:null;
          }
          if(value[0].indexOf('vid')>=0){
            this.form_viewid=value[0]?value[0].split('=')[1]:null;
          }
          if(value[1].indexOf('frmid')>=0){
            this.formId=value[1]?value[1].split('=')[1]:null;
          }
          if(value[1].indexOf('vid')>=0){
            this.form_viewid=value[1]?value[1].split('=')[1]:null;
          }
          console.log("grid===>",str,this.formId,this.form_viewid)
    }
    this.getFormDetails(0); 
  }
  getFormDetails(val){
    this.formService.getFormData("select form_id,form_name,table_name,primary_key,form_display_json,value_field from app_forms where form_id="+this.formId).subscribe(res => {
      let formList=res.rows;
      if(formList.length>0){
        this.formName=formList[0]["form_name"];
        if(val == 0){
          this.tempformName=formList[0]["form_name"];
          this.tempFormId= formList[0]["form_id"];
          this.temptablePrimaryKey = formList[0]["primary_key"];              
        }
        this.formId = formList[0]["form_id"];
        this.tableName= formList[0]["table_name"];
        this.tablePrimaryKey = formList[0]["primary_key"]; 
        //this.parentPrimaryKey = formList[0]["value_field"]?formList[0]["value_field"]:"";
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
        if(this.formDisplayJSON.length>0){
          this.SectionConfig=JSON.parse(this.formDisplayJSON)
          this.filtersLoaded = Promise.resolve(true);
          this.EditLoaded=Promise.resolve(false);
        }
      }
      this.getFormViewDetails();
    });
  }

  getFormViewDetails(){
    let columnDefs=[];
    let query="select fv.is_add,fv.is_edit,fv.is_view,fv.is_exportable,fv.child_form_ids, fv.view_id,fv.field_json,fv.is_attachment,fv.meta_data,fv.attachment_type,fv.calendar_json,f.primary_key  from app_form_view_details fv left join app_forms f on fv.form_id=f.form_id  where  fv.is_default =true  and fv.view_id="+this.form_viewid;
    this.formService.getFormData(query).subscribe(res => {
      let formViewList=res.rows;
      let arr=[],a=0,dateArr=[],lookupArr=[];;
      if(formViewList.length>0){
        this.isAdd =formViewList[0]["is_add"]?formViewList[0]["is_add"]:false;
        this.isEdit =formViewList[0]["is_edit"]?formViewList[0]["is_edit"]:false;
        this.isView =formViewList[0]["is_view"]?formViewList[0]["is_view"]:false;
        this.isExpotable =formViewList[0]["is_exportable"]?formViewList[0]["is_exportable"]:false;
        let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        let calendarJson=formViewList[0]["calendar_json"]?JSON.parse(formViewList[0]["calendar_json"]):[];        
       
        if(fromViewjson.length>0){
          let selectedFieldList=JSON.parse(fromViewjson)
          var column='';
          //console.log("fromViewjson===>",fromViewjson);
          selectedFieldList.forEach(fieldprop => {
            if(fieldprop.control_type == 5 || fieldprop.control_type == 10 || fieldprop.control_type == 19 ){
              arr.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3"})
              lookupArr.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3","control_type":fieldprop.control_type})
            }            
            else if(fieldprop.control_type == 7 || fieldprop.control_type == 8){
              dateArr.push({"index":a,"columnname":fieldprop.field_name,"type":"1"})
              lookupArr.push({"index":a,"columnname":fieldprop.field_name,"type":"1","control_type":fieldprop.control_type})
            }
            else if(fieldprop.control_type == 14 || fieldprop.control_type == 15){
              let displayField=JSON.parse(fieldprop.display_field)
              lookupArr.push({"index":a,"columnname":fieldprop.field_name,
              "reference_form_id":fieldprop.reference_form_id,"value_field":fieldprop.value_field,
              "display_field":displayField[0]["id"],"field_name":fieldprop.field_name, "type":"2","control_type":fieldprop.control_type})
            }else{
              lookupArr.push({"index":a,"columnname":fieldprop.field_name, "type":"1","control_type":fieldprop.control_type})
            }
            a++;
            columnDefs.push({field:fieldprop.field_name.toString().toLowerCase(),headerName:fieldprop.field_caption,sortable:fieldprop.sortable,filter:fieldprop.filterable ,resizable:fieldprop.resizable,width:fieldprop.column_width, wrapText: true,autoHeight: true});
          });
          
          if(lookupArr.length>0){
            let query="";
            for(var z=0; z<lookupArr.length;z++){
              if(lookupArr[z]["type"]=="1" || lookupArr[z]["type"]=="3"){
                column=column+"a."+lookupArr[z]["columnname"]+","  
              }else{
                //console.log("lookupArr==>",query.indexOf(lookupArr[z]["reference_form_id"]))
                column=column+lookupArr[z]["reference_form_id"]+"."+lookupArr[z]["display_field"] +" " +lookupArr[z]["columnname"]+","
                //console.log("query==>",query,lookupArr[z]["reference_form_id"]);
                if(query.indexOf(lookupArr[z]["reference_form_id"])==-1){
                  query =query + " left join "+lookupArr[z]["reference_form_id"] +" "+lookupArr[z]["reference_form_id"]+" on  a."+lookupArr[z]["field_name"]+" = "+lookupArr[z]["reference_form_id"]+"."+lookupArr[z]["value_field"]
                }
              }
            }
            column=column +" a."+formViewList[0]["primary_key"]+",'[1,1]' as val";
            this.ViewTableQry=`select ${column} from ${this.tableName} a `
            this.ViewTableQry=this.ViewTableQry+" "+query
          }
          else{
            column=column+formViewList[0]["primary_key"]+",'[1,1]' as val";
            this.ViewTableQry=`select ${column} from ${this.tableName} a `
          }
          // if(this.tempFormId != this.formId){
          //   this.ViewTableQry=this.ViewTableQry+ " where "+this.temptablePrimaryKey+" ="+this.selectedRowId
          // }
          if(this.filterCriteria!=null && this.filterCriteria!="" && this.filterCriteria!=undefined && this.filterCriteria!="null" && this.filterCriteria!="undefined")
          {
            if(this.ViewTableQry.includes(";"))
            {
              this.ViewTableQry=this.ViewTableQry.replace(/;/g,"");             
            }
            if(this.ViewTableQry.includes("where"))
            {
              this.ViewTableQry=this.ViewTableQry+" and "+this.filterCriteria; 
            }
            else
            {
              this.ViewTableQry=this.ViewTableQry+" where "+this.filterCriteria;
            }
          }
        }
          console.log("ViewTableQry===>",this.ViewTableQry);
          this.formService.getFormData(this.ViewTableQry).subscribe(res => {
            let rowData=res.rows;
             
            // for(var c=0; c<rowData.length; c++){
            //   let firstColumnname = this.columnDefs[0]["field"];
            //   let rowid =rowData[c][firstColumnname];
            //   for(a=0;a<arr.length; a++){
            //     let columnValue= this.rowData[c][arr[a]["columnname"]];
            //     let jsonList=arr[a]["dynamiclist"]?arr[a]["dynamiclist"]:[];
            //     if(jsonList.length>0){
            //       let valArr= jsonList.filter(d=>d.text == columnValue); 
            //       let val="";
            //       if(valArr.length >0){
            //         val=valArr[0]["binding"];
            //       }                            
            //       this.rowData.filter(d=> d[firstColumnname] == rowid)[0][arr[a]["columnname"]]=val;                
            //     }
            //   }   
            //   // for date Format
            //   // for(var x=0; x<dateArr.length; x++){
            //   //   let columnValue= this.rowData[c][dateArr[x]["columnname"]];
            //   //   if(columnValue != "" && columnValue != undefined && columnValue != null){
            //   //     columnValue=columnValue.substring(0,10)
            //   //     let arrDate=columnValue.split("-")
            //   //     let dateString=arrDate[2]+"-"+arrDate[1]+"-"+arrDate[0];
            //   //     this.rowData[c][dateArr[x]["columnname"]]=dateString;                    
            //   //   }                  
            //   // }
            // }
              rowData=rowData.sort((a, b) => a[this.tablePrimaryKey] > b[this.tablePrimaryKey] ? 1 : -1)
              for(var c=0; c<rowData.length; c++){
                this.INITIAL_EVENTS.push({"id":rowData[c][calendarJson[0]["field_name"]],"title":rowData[c][calendarJson[1]["field_name"]],"start":new Date(rowData[c][calendarJson[3]["field_name"]]).toISOString().replace(/T.*$/, ''),"description":rowData[c][calendarJson[2]["field_name"]]})
                // this.INITIAL_EVENTS.push({"id":rowData[c]['join_employee_id'],"title":rowData[c]['name'],"start":new Date(rowData[c]['date_of_birth']).toISOString().replace(/T.*$/, ''),"description":rowData[c]['address']})              
              }  

              this.calendarOptions={
                initialView: 'dayGridMonth',
              //  initialEvents: this.INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
              events: this.INITIAL_EVENTS,
              weekends: true,
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                select: this.handleDateSelect.bind(this),
                eventClick: this.handleEventClick.bind(this),
                //eventMouseEnter : this.handleEventClick.bind(this),
                //eventMouseLeave : this.handleEventLeave.bind(this),
                eventsSet: this.handleEvents.bind(this),
                headerToolbar: {
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,dayGridWeek,dayGridDay'
                },
              };
              console.log("TODAY_STR==>",TODAY_STR)
              //this.INITIAL_EVENTS=rowData;
            // if(this.selectedRowId == 0 && rowData.length>0){
            //   this.selectedRowId=this.rowData[0][this.temptablePrimaryKey];
            // }
            //this.rowData.push(res.rows)
            console.log(this.calendarOptions)
            $("#clos").click();
           },err=>{
            $("#clos").click();
            });
            this.FormViewLoaded = Promise.resolve(true);
      }
    });
  }
  addEvent(){
    //alert("Adding Method")
    //debugger
    // this.calendarApi.addEvent({
    // id: createEventId(),
    // title:this.meetingTitle,    
    // start: this.meetingDate,
    // contact :this.companyName,
    // description :this.meetingTo +","+this.meetingWhome,
    //})

  }
  handleDateSelect(selectInfo: DateSelectArg) {
    //alert(selectInfo)
    //console.log(selectInfo.view,"FIRST STEP")
    //this.isAppointment = true;
    $('#exampleModal').modal('show');
    //alert(selectInfo)
   this.calendarApi = selectInfo.view.calendar;
 
  }
showme()
{
  alert("ok");
 
}
  handleEventClick(clickInfo: EventClickArg) {
      let json1 = clickInfo.event.toJSON()['extendedProps']
     console.log(JSON.stringify(clickInfo.event.toJSON()))
      // $('#viewModal').modal('show');
    
    }
    handleEventLeave(clickInfo: EventClickArg)
    {
   
  //  $('#viewModal').modal('hide');
    }
 
  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
}
