import { Component, OnInit } from '@angular/core';
import { SellerRegistrationService } from '../_services/seller-registration.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionService } from '../_services/common-function.service';
import { timer, Subscription } from "rxjs";
import { FormService } from '../_services/form.service';
import { LoginService } from '../_services/login.service';
import { AppSettings } from '../_services/AppSetting';
declare var $: any;

@Component({
  selector: 'app-create-account-seller',
  templateUrl: './create-account-seller.component.html',
  styleUrls: ['./create-account-seller.component.css']
})
export class CreateAccountSellerComponent implements OnInit {
  mobile_no:any;
  full_name:any;
  new_password:any;
  email:any;
  otp1:any;
  otp2:any;
  otp3:any;
  otp4:any;
  timeLeft: number = 60;
  interval;
  isTimeout: boolean = false;
  otp:any;
  showPassword2: boolean = false
  mob_num:any;
  forgetotp:any;
  otppop: boolean = false;
  userdt:boolean
  MassageOutTime = AppSettings.getMassageOutTime;
  constructor(private sellerService: SellerRegistrationService,private router: Router, public commonFunctionService: CommonFunctionService,
    private toastrService: ToastrService,private formService: FormService,private loginService: LoginService) { }

  ngOnInit(): void {
    this.userdt = true;
   
  }
  startTimer() {
    // this.countDown = this.myService
    // .getCounter(this.tick)
    // .subscribe(() => this.counter--);

    clearInterval(this.interval)
    this.timeLeft = 120;
    this.isTimeout = false
    this.interval = setInterval(() => {
      if (this.timeLeft >= 1) {
       // console.log(this.timeLeft)
        this.timeLeft--;
        // console.log('ifpart', this.timeLeft)

      } else {
        //console.log('elsepar')
        this.timeLeft = 0;
        this.isTimeout = true
        clearInterval(this.interval)
        return true;

      }
    }, 1000)
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  sendOtp() {
    var minNumberofChars = 8;
    var maxNumberofChars = 16;
    let passpattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,16}$/;
    let pattern = /^((?=.*[a-z])(?=.*[A-Z]).*)$/
    this.full_name = this.full_name?.trim();
    if (this.full_name == null || this.full_name == undefined || this.full_name == '') {
      this.toastrService.warning("Please Enter Your Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      
      return false;
    }
    if (this.mobile_no == null || this.mobile_no == undefined || this.mobile_no == '') {
      this.toastrService.warning("Please Enter Mobile Number!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
     
      return false;
    }
    if (this.mobile_no !== "" && this.mobile_no !== null && this.mobile_no !== undefined) {
      // let pattrn = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;
      if (this.mobile_no.toString().length == 10) {
        if ((this.mobile_no[0]) < 6) {
          this.toastrService.warning("Please enter valid Mobile No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          this.otppop = false;
          return false;
        }
      } else {
        this.toastrService.warning("Please enter valid 10-digit Mobile No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
       
        return false;
      }
    }
    if (this.email == null || this.email == undefined || this.email == '') {
      this.toastrService.warning("Please Enter E-mail !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      
      return false;
    }
    if (this.email !== undefined && this.email !== null && this.email !== "") {
      let pattrn = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      //^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$
      if (this.email.substring(0).length > 0) {
        var email = this.email.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          //this.otppop = false;
          this.removeClass();
           return false;

        }
      }
    }
    if (this.new_password == null || this.new_password == undefined || this.new_password == "" || this.new_password == "null" || this.new_password == "undefined") {
      this.toastrService.warning("New Password is required", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      
      return false;
    } else if (this.new_password.length < minNumberofChars || this.new_password.length > maxNumberofChars) {
      this.toastrService.warning("Your Password must be at least 8 characters", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
     
      return false;
    } else if (!pattern.test(this.new_password)) {
      this.toastrService.warning("Password should contain atleast one upper case and one lower case character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      
      return false
    } else if (!passpattern.test(this.new_password)) {
      this.toastrService.warning("Password should contain atleast one number and one special character", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
     
      return false
    }
    
    
    this.checkMobileAndEmail();
    // this.otppop = true;
    // this.userdt = false;
    // this.otp1 = ''
    // this.otp2 = ''
    // this.otp3 = ''
    // this.otp4 = ''
   
    
  }

  checkMobileAndEmail() {
   // debugger
    this.sellerService.checkMobileAndEmail(this.mobile_no.trim(), this.email.trim()).subscribe(res => {
      console.log(res);
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.otppop = false;
        this.userdt = true;
        return false
      } else {
        this.submit();
        $('.cret_ac').hide();
        this.userdt = false;
      //   if (res.status == 200){
      // this.otppop = true;
      // this.userdt = false;
      //   // console.log('sdfsdfsdfsdfs', res)
      //   this.otp = res.otp 
      //   console.log(this.otp)
      //   this.startTimer()       
      //   $('.otp').show();
      //   $('.cret_ac').hide();
       
      // }
    }
  });

  }
  submit() {
   // let u_otp = (this.otp1).toString() + (this.otp2).toString() + (this.otp3).toString() + (this.otp4).toString();
   // if ((this.otp).toString() == u_otp) {
      this.sellerService.sellerRegisteration(this.full_name, this.mobile_no, this.email).subscribe(res => {
        if (res.status == 201) {
          this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false
        } else {
          let seller_id = res.rows[0].seller_id;
          this.createUserAcc(seller_id);
        }
      });
    // } else {
    //   this.toastrService.warning("Please Enter Valid OTP!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //  this.removeClass()
    //  this.otp1 = ''
    //  this.otp2 = ''
    //  this.otp3 = ''
    //  this.otp4 = ''
    //   return true
    // }
  }
  createUserAcc(s_id) {
    let SQLqry = `INSERT INTO public.app_tbluser(user_fname,user_emailid, user_mobile,user_loginname, user_password, effective_from, seller_id)
    VALUES ('${this.full_name}','${this.email}','${this.mobile_no}','${this.email}','${this.new_password}',NOW(),${s_id})returning*;`
    this.formService.getFormData(SQLqry).subscribe(res => {
    //  localStorage.clear();
      if(res.rows.length > 0){
      let userId = res.rows[0]["user_id"]     
        let query = `INSERT INTO public.app_tbluserprofile(
           user_id, role_id, office_id, default_role_id, default_office_id, effective_from, effective_to, createdby, createddate, updatedby, updateddate, isactive)
          VALUES (${userId}, 49, null, null,null, current_timestamp, null, ${s_id}, current_timestamp, null, null, true);`
          this.formService.getFormData(query).subscribe(res => {

          })
      }
      let data = JSON.stringify('seller_id=' + s_id);
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/add-seller?' + encriptUrl);
    })
  }
  sendOtpForForgot() {
    // console.log('sendotp click')
    if (this.mob_num == null || this.mob_num == undefined || this.mob_num == ''  || this.mob_num == 'undefined'  || this.mob_num == 'null') {
      this.toastrService.warning('Please Enter Mobile No.', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      
    }
   
    this.mob_num = this.mob_num ? this.mob_num.trim() : ''
    this.sellerService.checkMobileAndSendOtp(this.mob_num).subscribe(res => {
      if (res.status == 200) {
           this.forgetotp=res.data;
           this.startTimer()
      }
      else
      {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
         this.removeClass()
            return false ;
      }
      
    })

  }
  clickEvent(first, last) {
    // console.log('checkkkkk',  first.target.value)
    if (first.target.value.length) {
      document.getElementById(last).focus();
    }
  }
  showHidePassword2(){
    if (this.showPassword2) {
      this.showPassword2 = false;
    } else {
      this.showPassword2 = true;
    }
  }
}
