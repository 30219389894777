<ngx-loading [show]="isLoader"></ngx-loading>


<div class="d-flex minhyt96vh" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>

              <li class="breadcrumb-item newitm2  active" aria-current="page"> Pending Product List</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="col-sm-12 mb-2 p-0">
        <div class="tabs row my-2 mx-0">
          <!-- <ul class="breadcrumb">
                <li><a href="#">Home</a></li>
                <li><a href="#">Pictures</a></li>
              </ul> -->
          <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
            <!-- <ul id="tab-button">
                  <li id="tabDraft" class="is-active">
                    <a href="javascript:void(0)" (click)="draftProductAddByAdmin()" class="font8">Draft
                    </a>
                  </li>
                </ul> -->
            <ul id="tab-button" class="tbsbtns">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font8" (click)="draftProductAddByAdmin(); SearchValue.value = ''">Draft
                  <span>({{draftCountList}})</span>
                </a>
              </li>
              <!-- <li id="" class="">
                <a
                  href="javascript:void(0)"
                  class="font8"
                  (click)="draftBusinessCardAddByAdmin(); SearchValue.value = ''"
                  >Pending Business Card
                  <span >({{totalBusinessCount}})</span>
                </a>
              </li> -->
              <li id="" class="">
                <a href="javascript:void(0)" class="font8" (click)="pendinglist(); SearchValue.value = ''">Pending For QC Product List
                  <span>({{pendingCountList}})</span>
                </a>
              </li>
              <!-- <li id="" class="">
                <a
                  href="javascript:void(0)"
                  class="font8"
                  (click)="allLiveProducts(); SearchValue.value = ''"
                  >All Live Products
                  <span >({{liveProductListCount}})</span>
                </a>
              </li> -->
            </ul>
          </div>
          <!-- <div class="tab-button-outer1  col-lg-6 pl-0">
            <ul id="tab-button">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" (click)="pendinglist()" class="font8">Pending For QC Product List
                </a>
              </li>
            </ul>
          </div> -->

          <div class="col-sm-3 px-sm-3 px-0" id="masterSearch">
            <div class="input-group">
              <input type="search" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
              <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
        </div>
        <div id="tab01" class="tab-contents mmt5 p-0">
          <div class="col-sm-12 col-md-12 nopadding">
            <div id="grid-theme-wrapper">
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>

              <igx-grid #grid1 [data]="allProductList" *ngIf="pendingpro && allProductList.length > 0" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true"
                displayDensity="cosy" [filterMode]="'excelStyleFilter'">
                <igx-column field="seller_name" header="Seller Name" width="210">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <a href="javascript:void(0)">
                        <span class="cell-vl " (click)="goToLink(); showSellerDetails(cell)">{{ cell.value }}
                        </span>
                      </a>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="product_name" header="Product Name" width="230">
                  <!-- <igx-column field="product_name" header="Product Name" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <a href="javascript:void(0)">
                        <span class="cell-vl text-capitalize" (click)="onProductView(cell)">{{ cell.value }}
                        </span>
                      </a>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="suk" header="SKU" width="150">
                  <!-- <igx-column field="suk" header="SKU" width="135"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="mrp" header="MRP  (₹)" width="150">
                  <!-- <igx-column field="mrp" header="MRP  (₹)" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">

                      <span class="cell-vl ">{{ cell.value }}
                      </span>

                    </div>
                  </ng-template>
                </igx-column>

                <igx-column field="price" header="Sell Price (₹)" width="150">
                  <!-- <igx-column field="price" header="Sell Price (₹)" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl ">{{ cell.value }}
                      </span>
                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="purchase_price" header="Purchase Price (₹)" width="150">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl ">{{ cell.value }}
                      </span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <!-- <igx-column
                  field="category_name"
                  header="Product Type"
                  width="200"
                >
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column> -->

                <igx-column field="producttype" header="Product Type" width="160">
                  <!-- <igx-column field="producttype" header="Product Type" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>

                <!-- <igx-column field="brand_name" header="Brand Name" width="147">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value ? cell.value : 0"></span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <!-- <igx-column field="hsn" header="HSN" width="147">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value ? cell.value : 0"></span>
                    </div>
                  </ng-template>
                </igx-column> -->


                <igx-column field="date" header="Upload Date" width="160">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl" [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>

                <!-- <igx-column field="qcstatus_value" header="Status" width="">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                     
                    </div>
                  </ng-template>
                </igx-column> -->


                <igx-column field="" header="Action" width="150">
                  <!-- <igx-column field="" header="Action" width="100"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container px-2">
                      <div class="btn-group" role="group" data-toggle="dropdown" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="py-2 px-3">Action</span>
                          <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                        </button>
                        <ul class="dropdown-menu p-0" aria-labelledby="btnGroupDrop5">
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onProductView(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              View </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" (click)="submitToQC(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                              <i class="fa fa-paper-plane" aria-hidden="true"></i>
                              QC Process </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- <div class="px-2">
                      <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop5" type="button" class="py-2 px-3 btn btn-sm btn-light shadow-none dropdown-toggle" data-toggle="dropdown"
                          aria-expanded="false">
                          Action
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        <ul class="dropdown-menu p-0" aria-labelledby="btnGroupDrop5">
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onProductView(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              View </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" (click)="submitToQC(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                              <i class="fa fa-paper-plane" aria-hidden="true"></i>
                              QC Process </a>
                          </li>
                        </ul>
                      </div>
                    </div> -->


                    <!-- <div class="address-container px-2">
                      <button (click)="onProductView(cell)" class="btn btn-sm btn-primary mr-2">
                        <i class="fa fa-eye mr-sm-1"></i>
                        <span class="d-sm-inline px-0 d-none">View</span>
                      </button>
                      <button (click)="submitToQC(cell)" class="btn btn-sm btn-theme">
                        <i class="fa fa-paper-plane mr-sm-1" aria-hidden="true"></i>
                        <span class="d-sm-inline px-0 d-none">QC Process</span>
                      </button>
                    </div> -->

                  </ng-template>
                </igx-column>
              </igx-grid>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid2($event)" *ngIf="LoadPagination2 == true"></app-hds-main-tab-group>
              <igx-grid #grid2 [data]="allProductList" *ngIf="isdraft && allProductList.length > 0" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true"
                displayDensity="cosy" [filterMode]="'excelStyleFilter'">
                <igx-column field="seller_name" header="Seller Name" width="210">
                  <!-- <igx-column field="seller_name" header="Seller Name" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <a href="javascript:void(0)">
                        <span class="cell-vl " (click)="goToLink(); showSellerDetails(cell)">{{ cell.value }}
                        </span>
                      </a>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="product_name" header="Product Name" width="230">
                  <!-- <igx-column field="product_name" header="Product Name" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <a href="javascript:void(0)">
                        <span class="cell-vl " (click)="onProductView(cell)">{{ cell.value }}
                        </span>
                      </a>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="suk" header="SKU" width="152">
                  <!-- <igx-column field="suk" header="SKU" width="135"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="mrp" header="MRP (₹)" width="152">
                  <!-- <igx-column field="mrp" header="MRP (₹)" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">

                      <span class="cell-vl ">{{ cell.value }}
                      </span>

                    </div>
                  </ng-template>
                </igx-column>

                <igx-column field="price" header="Sell Price (₹)" width="152">
                  <!-- <igx-column field="price" header="Sell Price (₹)" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">

                      <span class="cell-vl ">{{ cell.value }}
                      </span>

                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="purchase_price" header="Purchase Price (₹)" width="150">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">

                      <span class="cell-vl ">{{ cell.value }}
                      </span>

                    </div>
                  </ng-template>
                </igx-column> -->
                <!-- <igx-column
                  field="category_name"
                  header="Product Type"
                  width="200"
                >
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <igx-column field="producttype" header="Product Type" width="160">
                  <!-- <igx-column field="producttype" header="Product Type" width="147"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="brand_name" header="Brand Name" width="147">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value ? cell.value : 0"></span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <!-- <igx-column field="hsn" header="HSN" width="147">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value ? cell.value : 0"></span>
                    </div>
                  </ng-template>
                </igx-column> -->
                <igx-column field="date" header="Upload Date" width="160">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl" [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>

                <!-- <igx-column field="qcstatus_value" header="Status" width="147">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container p-3">
                    <span> {{cell.value == 'Submit For QC' ? 'Pending': cell.value}} </span>
                   
                  </div>
                </ng-template>
              </igx-column> -->

                <igx-column field="" header="Action" width="150">
                  <!-- <igx-column field="" header="Action" width="100"> -->
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container px-2">
                      <div class="btn-group" role="group" data-toggle="dropdown" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="py-2 px-3">Action</span>
                          <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                        </button>
                        <ul class="dropdown-menu p-0" aria-labelledby="btnGroupDrop11">
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onProductView(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              View </a>
                          </li>
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onProductEdit(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                              Edit </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" (click)="submitToQC(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                              <i class="fa fa-paper-plane" aria-hidden="true"></i>
                              Submit to QC </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- <div class="px-2">
                      <div class="btn-group" role="group">
                        <button id="btnGroupDrop11" type="button" class="py-2 px-3 btn btn-sm btn-light shadow-none dropdown-toggle" data-toggle="dropdown"
                          aria-expanded="false">
                          Action
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                        <ul class="dropdown-menu p-0" aria-labelledby="btnGroupDrop11">
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onProductView(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                              <i class="fa fa-eye" aria-hidden="true"></i>
                              View </a>
                          </li>
                          <li class="border-bottom">
                            <a href="javascript:void(0)" (click)="onProductEdit(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                              Edit </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" (click)="submitToQC(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                              <i class="fa fa-paper-plane" aria-hidden="true"></i>
                              Submit to QC </a>
                          </li>
                        </ul>
                      </div>
                    </div> -->



                    <!-- 
                    <div class="address-container px-2">
                      <button (click)="onProductView(cell)" class="btn btn-sm btn-primary mb-2">
                        <i class="fa fa-eye mr-sm-1"></i>
                        <span class="d-sm-inline px-0 d-none">View</span>
                      </button>
                      <button (click)="onProductEdit(cell)" class="btn btn-sm btn-theme mx-2 mb-2">
                        <i class="fa fa fa-pencil mr-sm-1"></i>
                        <span class="d-sm-inline px-0 d-none">Edit</span>
                      </button>
                      <button (click)="submitToQC(cell)" class="btn btn-sm btn-success mb-2">
                        <i class="fa fa-paper-plane mr-sm-1"></i>
                        <span class="d-sm-inline px-0 d-none">Submit To QC</span>
                      </button>
                    </div> -->
                    <!-- <div class="address-container m-2">
                  
                    </div>
                    <div class="address-container my-2">
                      
                    </div> -->




                    <!-- <div class="address-container p-3">
                    <span>
                      <button (click)="productListing(cell)" class="btn btn-sm px-2 py-1 btn-gray rounded-pill">
                        Product Listing
                      </button>
                    </span>
                  </div>
                  <div class="address-container p-3">
                    <span>
                      <button (click)="orderListing(cell)" class="btn btn-sm px-2 py-1 btn-gray rounded-pill">
                        Order
                      </button>
                    </span>
                  </div> -->
                  </ng-template>
                </igx-column>
              </igx-grid>
              <label class="w-100 text-center text-dark" *ngIf="!isLoader && allProductList.length == 0">No Record Found</label>
              <!-- <app-hds-main-tab-group
                [configParms]="configParms"
                (valueChange)="LoadGrid3($event)"
                *ngIf="LoadPagination3 == true"
              ></app-hds-main-tab-group> -->
              <!-- <igx-grid
                #grid3
                [data]="allProductList"
                *ngIf="business"
                width="100%"
                height=""
                [autoGenerate]="false"
                [allowFiltering]="true"
                displayDensity="cosy"
                [filterMode]="'excelStyleFilter'"
              >
              <igx-column
                  field="seller_name"
                  header="Seller Name"
                  width="180"
                >
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <a href="javascript:void(0)">
                        <span
                          class="cell-vl "
                          (click)="goToLink(); showSellerDetails(cell)"
                          >{{ cell.value }}
                        </span>
                        </a>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column
                  field="card_title"
                  header="card Title"
                  width="200"
                >
               
                
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="mrp" header="MRP (₹)" width="143">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container p-3">
                        <span class="cell-vl " [innerHtml]="cell.value ? cell.value : 0"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                <igx-column field="price" header="Product Price(Rs)" width="150">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3">
                      <span class="cell-vl " [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>
    
              
                <igx-column field="" header="Action" width="300">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container p-3 mr-sm-1 my-2">
                      <button
                        (click)="viewBusiness(cell)"
                        class="btn btn-sm btn-primary  mr-2"
                      >
                        View
                      </button>
                    </div>
                    <div class="address-container p-3 mr-sm-1 my-2">
                      <button
                        (click)="editBusinessCard(cell)"
                        class="btn btn-sm  btn-theme  mr-2"
                      >
                        Edit
                      </button>
                    </div>
                    <div class="address-container p-3 mr-sm-1 my-2">
                      <button
                        (click)="submitTOQCBusiness(cell)"
                        class="btn btn-sm btn-success"
                      >
                      QC Process
                      </button>
                    </div>
                     <div class="address-container p-3">
                    <span>
                      <button (click)="productListing(cell)" class="btn btn-sm px-2 py-1 btn-gray rounded-pill">
                        Product Listing
                      </button>
                    </span>
                  </div>
                  <div class="address-container p-3">
                    <span>
                      <button (click)="orderListing(cell)" class="btn btn-sm px-2 py-1 btn-gray rounded-pill">
                        Order
                      </button>
                    </span>
                  </div> 
                  </ng-template>
                </igx-column>
              </igx-grid> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- view model -->

<div class="modal fade p-0" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header p-2 align-items-center">
        <h6 class="modal-title font9 font600">Product Details</h6>
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body minhyt70 p-0" data-spy="scroll" id="scroll" *ngIf="isView | async">
        <app-product-details [isView]="isView" [role_id]="role_id" [customerid]="customer_id" [view_item_id]="view_item_id"></app-product-details>
      </div>
      <!-- <div class="modal-footer p-2">
          <button type="button" class="btn btn-gray btn-sm rounded-pill px-2" data-dismiss="modal">
            Close
          </button>
        </div> -->
    </div>
  </div>
</div>

<!---confim modal  -->

<!-- <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" id="mi-modal">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">Are you sure to Approve this <span class="fw-bold">Product Id: {{productId}}</span></h5>
        <button type="button" class="close" data-dismiss="modal"  (click)="closeModal()"aria-label="Close"><span aria-hidden="true">&times;</span></button>
        
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm rounded-pill btn-theme" id="modal-btn-si" (click)="confirmMessage()">Yes</button>
        <button type="button" class="btn btn-sm rounded-pill btn-gray" id="modal-btn-no" (click)="closeModal()" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->

<div class="modal fade" id="mi-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="ms-auto">
        <button type="button" class="close btn" (click)="closeModal()" data-dismiss="modal">
          &times;
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="col-sm-12 col-lg-12 text-center">
          <i class="fa fa-exclamation-triangle" style="font-size: 3em; color: #ffa64b"></i>
          <h4 class="pt-3 mb-0">Are you sure to Approve this product?</h4>
          <p class="font9">Product ID: {{ productId }}</p>
        </div>
        <div class="col-sm-12 text-center py-3">
          <button type="button" pButton (click)="confirmMessage()" label="Yes" class="btn btn-theme btn-sm mr-3 px-4">
            <span class="ui-button-text ui-clickable">Yes</span>
          </button>
          <button type="button" pButton data-dismiss="modal" (click)="closeModal()" label="No" class="btn btn-gray btn-sm me-2  px-4">
            <span class="ui-button-text ui-clickable">No</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-2">
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body pb-0">
        <div class="row">
          <div class="col-sm-12 col-lg-12" *ngIf="!isdraft">
            <!-- <i
              class="fa fa-exclamation-triangle"
              style="font-size: 3em; color: #ffa64b"
            ></i> -->
            <div class="row mx-0">
              <div class="col-sm-6">
                <div class="form-group ">
                  <label for="price">Price
                    
                  </label>
                  <input class="form-control" type="text" [(ngModel)]="selling_price" disabled>
                </div>
                </div>
                <div class="col-sm-6">
                <div class="form-group ">
                  <label for="price">Selling Price (%)
                    <span class="asterisk">*</span>
                  </label>
                  <input type="text" autocomplete="off" min="0" maxlength="2" class="form-control mb-2"
                    id="productTitle" placeholder="Enter Price" (keypress)="commonFunctionService.amountValidator($event)"
                    [(ngModel)]="admin_price">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-12">
                <label for=""> Remarks

                </label>
              </div>
              <div class="col-sm-12">
                <textarea name="remarks" id="remarks" placeholder="Enter Remarks here.." [(ngModel)]="adminRemarks" class="form-control2"
                  maxlength="500"></textarea>
              </div>
            </div>
            <!-- <h4 class="pt-3 mb-0">Are you sure?</h4> -->
            <!-- <p class="font9">Confirm to proceed</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center py-3" *ngIf="pendingpro">
            <button type="button" pButton (click)="onConfirm()" label="Approved" class="btn btn-theme bg-success border-success btn-sm  px-2 mr-2">
              Approved
            </button>
            <button type="button" pButton (click)="onReject()" label="Rejected" class="btn btn-gray btn-sm me-2 bg-danger border-danger  px-2">
              Rejected
            </button>
          </div>
          <div class="col-sm-12 text-center py-3" *ngIf="business ">
            <button type="button" pButton (click)="onConfirmBusiness()" label="Approved" class="btn btn-theme bg-success border-success btn-sm  px-2 mr-2">
              Approved
            </button>
            <button type="button" pButton (click)="onRejectBusiness()" label="Rejected" class="btn btn-gray btn-sm me-2 bg-danger border-danger  px-2">
              Rejected
            </button>
          </div>
        </div>
        <div class="" *ngIf="isdraft">
          <div class="col-sm-12 col-lg-12 text-center">
            <i class="fa fa-exclamation-triangle" style="font-size: 3em;color: #ffa64b;"></i>
            <h4 class="pt-3 mb-0">Are you sure?</h4>
            <p class="font9">Confirm to proceed</p>
          </div>
          <div class="col-sm-12 text-center py-3">
            <button type="button" pButton (click)="submitforQc()" label="Yes" class="btn btn-success btn-sm px-4 mr-2">
              <span class="ui-button-text ui-clickable">Yes</span>
            </button>
            <button type="button" pButton (click)="onCancle()" label="No" class="btn btn-secondary btn-sm px-4">
              <span class="ui-button-text ui-clickable">No</span>
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- <div>
  <button class="btn btn-primary" data-toggle="modal" data-target="#myModal1" > Test Modal </button>
</div> -->

<div class="modal fade" id="myModal1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-2">
        <button type="button" class="close" data-dismiss="modal">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body  pb-3">
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="row mx-0" *ngIf="!isdraft">
              <div class="col-sm-12 tbl-hyt">
                <table class="table table-bordered cstmprTbl">
                  <tr>
                    <th class="w20">SKU</th>
                    <!-- <th width="w20">Description</th> -->
                    <th class="text-center">Price (₹)</th>
                    <th class="text-center">MRP (₹)</th>
                    <th class="w-25">Selling Fee (%) </th>
                  </tr>
                  <tr *ngFor="let pro of tempconfigList;let i=index">
                    <td> {{pro.sku}} </td>
                    <!-- <td>{{pro.product_description}}</td> -->
                    <td class="text-center"> {{pro.price| number : '1.2-2'}}
                    </td>
                    <td class="text-center"> {{pro.mrp| number : '1.2-2'}}
                    </td>
                    <td>
                      <input type="text" autocomplete="off" min="0" maxlength="2" class="form-control my-1"
                        id="productTitle" (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="pro.admin_price"
                        [ngModelOptions]="{standalone: true}"> </td>
                  </tr>
                  <!-- <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr>

                  <tr>
                    <td> SKUABC005 </td>
                    <td> <input type="number" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0"
                        maxlength="2" class="form-control mb-2" id="productTitle" placeholder="Enter Selling Fee (%)"
                        (keypress)="commonFunctionService.amountValidator($event)" [(ngModel)]="admin_price"> </td>
                  </tr> -->

                </table>
              </div>
              <div class="col-sm-12">
                <label for=""> Remarks

                </label>
              </div>
              <div class="col-sm-12">
                <textarea name="remarks" id="remarks" placeholder="Enter Remarks here.." [(ngModel)]="adminRemarks" class="form-control2"
                  maxlength="500"></textarea>
              </div>
            </div>
            <!-- <div class="row mx-0 mt-2">
              
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center py-3" *ngIf="pendingpro ">
            <button type="button" pButton (click)="onConfirm()" label="Approved" class="btn btn-theme bg-success border-success btn-sm  px-2 mr-2">
              Approved
            </button>
            <button type="button" pButton (click)="onReject()" label="Rejected" class="btn btn-gray btn-sm me-2 bg-danger border-danger  px-2">
              Rejected
            </button>
          </div>
          <div class="col-sm-12 text-center py-3" *ngIf="business ">
            <button type="button" pButton (click)="onConfirmBusiness()" label="Approved" class="btn btn-theme bg-success border-success btn-sm  px-2 mr-2">
              Approved
            </button>
            <button type="button" pButton (click)="onRejectBusiness()" label="Rejected" class="btn btn-gray btn-sm me-2 bg-danger border-danger  px-2">
              Rejected
            </button>
          </div>
        </div>
        <div class="" *ngIf="isdraft">
          <div class="col-sm-12 col-lg-12 text-center">
            <i class="fa fa-exclamation-triangle" style="font-size: 3em;color: #ffa64b;"></i>
            <h4 class="pt-3 mb-0">Are you sure?</h4>
            <p class="font9">Confirm to proceed</p>
          </div>
          <div class="col-sm-12 text-center py-3">
            <button type="button" pButton (click)="submitforQc()" label="Yes" class="btn btn-success btn-sm px-4 mr-2">
              <span class="ui-button-text ui-clickable">Yes</span>
            </button>
            <button type="button" pButton (click)="onCancle()" label="No" class="btn btn-secondary btn-sm px-4">
              <span class="ui-button-text ui-clickable">No</span>
            </button>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>