import { Component, OnInit,OnDestroy } from '@angular/core';
import { Router } from '@angular/router'; 
import {FormService} from '../../_services/form.service';
import { SectionConfig,SessionUrlPrams } from "../../field.interface";
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

declare var $: any; 

@Component({
  selector: 'app-act-process',
  templateUrl: './act-process.component.html',
  styleUrls: ['./act-process.component.css']
})
export class ActProcessComponent implements OnInit,OnDestroy {
  subscription: Subscription;
  frameworkComponents: any;
  formId:any=0;
  processid:any;
  stageid:any;
  worklistid:any;
  processinstid:any;
  form_viewid:any=0;
  formName:any;
  sessionUrlPrams:SessionUrlPrams; //new added
  tableName:any;
  tablePrimaryKey : any
  SectionConfig:SectionConfig[]=[];  
  formDisplayJSON:any;
  nodeJson:any;
  connJson:any;
  public isLoading = true;
  //filtersLoaded: Promise<boolean>;
  FormViewLoaded:Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  processing: Promise<boolean>;
  //ViewLoaded: Promise<boolean>;
  //EditLoaded: Promise<boolean>;
  //DataRefreshLoaded: Promise<boolean>;
  isAddMode:boolean=true;
  is_attachment: boolean=false;
  ErrorOccurred:String="Error occurred!!";
  SuccessMasg:any;
  //ViewTableQry:any;
  editValueRow:any;
  editrowid:any;
  //viewFilterString:any;
  //filterCriteria:any;
  refKey:any=0;

  //ddlarrGidView:any=[];
  //dateArrGidView:any=[];
  
  childFormList: any[]=[];
  childFormListPage: any[]=[];
  childFormListTab: any[]=[];
  fieldChildFormList: any[]=[]; //add new
  metaDataList: any[]=[];
  attachmentType: any[]=[];

  metadata: any;
  list: any;
  //pagesize="10";
  //columnDefs:any[]=[];
  //rowData:any[]=[];

  //isAdd:boolean=false;
  //isEdit:boolean=false;
  //isView:boolean= false;
  //isExpotable:boolean=false;
  //isResetFilter:Boolean=false;
  edit_option:number=1;
  add_edit: Promise<boolean>;

  constructor(
    private router: Router,
    private formService : FormService,
    private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
  }

  ngOnInit(): void {
    let uri=this.router.url.split("?");
    if(uri.length>1){
      var str = uri[1];
      var value=str.split("&");
      //console.log("uri===>",uri,value)
      value.forEach(queryParm=>{
        if(queryParm.indexOf('frmid')>=0)
        {
          this.formId=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('editrowid')>=0)
        {
          this.editrowid=queryParm.split('=')[1];
          this.refKey=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('worklistid')>=0)
        {
          this.worklistid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('processinstid')>=0)
        {
          this.processinstid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('stageid')>=0)
        {
          this.stageid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('vid')>=0)
        {
          this.form_viewid=queryParm.split('=')[1];
        }
        if(queryParm.indexOf('isAddMode')>=0)
        {
          this.isAddMode=queryParm.split('=')[1]=='true'?true:false;
        }
      });
      this.sessionUrlPrams={};
      this.sessionUrlPrams={
        frmid:this.formId,
        vid:this.form_viewid,
        UserCode:"UserCode",
        stageid:this.stageid,
        UserName:localStorage.getItem("userFullName"),
        ProfileId:localStorage.getItem("profileId"),
        Location:localStorage.getItem("office"),
        department:localStorage.getItem("department"),
        processtype:"ext",
      };
      console.log("act-on-process init===>",this.sessionUrlPrams,this.formId,this.form_viewid,this.editrowid,this.isAddMode,this.stageid,this.processinstid,this.worklistid);
    }
    this.getFormDetails(); 
    $(".maximi").click(function()
    {
     $("#actprocessnew").show();
     $("#actprocess").hide();
    })
    $(".minimi").click(function()
    {
     $("#actprocessnew").hide();
     $("#actprocess").show();
    })

    
  }


  getFormDetails(){
    this.subscription=this.formService.getFormData("select form_id,form_name,table_name,primary_key,form_display_json,value_field,processid from app_forms where form_id="+this.formId).subscribe(res => {
      let formList=res.rows;
      let objChildFormIds="",objChildFormViewIds="";
      if(formList.length>0){
        this.formName=formList[0]["form_name"];
        this.formId = formList[0]["form_id"];
        this.processid=formList[0]["processid"];
        this.tableName= formList[0]["table_name"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase(); 
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
        if(this.formDisplayJSON.length>0){
          if(this.processid >0){
            this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
            this.connJson=JSON.parse(this.formDisplayJSON).connJson;
            //now getting initial stageid
            var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
            this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
            console.log("this.SectionConfig==>",this.SectionConfig)
            // control field hide/show based on process stages...
            this.SectionConfig.forEach(arrField => {
              if(arrField.FieldConfig[0]?.visible_stage !=undefined){
                arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0 || field.visible_stage.toString() =="");
              }
            });
            this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==intStageid)).length>0  || d.visible_stage.toString() =="")));
            //this.stageid=intStageid;
          }else{
            this.SectionConfig=JSON.parse(this.formDisplayJSON)
            this.SectionConfig.forEach(arrField => {
              if(arrField.FieldConfig[0]?.visible_stage !=undefined){
                //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
                arrField.FieldConfig = arrField.FieldConfig.filter(field =>((field.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0 || field.visible_stage.toString() =="");
              }
            });
            this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl=>fl==this.form_viewid)).length>0  || d.visible_stage.toString() =="")));
          }
          this.SectionConfig=this.SectionConfig.filter(sec=>(sec.FieldConfig?.length >0 || sec.section_dependent==true) && (sec?.isCaptionVisiable =="always" || sec?.isprocess =="1" ) );
          //to get child form id
          
          this.fieldChildFormList=[];
          this.SectionConfig.forEach(objSec => {
            objSec.FieldConfig.forEach(objField=>{
              if(objField.type == "linkwithchild")
              {
                objChildFormIds=objChildFormIds+objField.child_form_id +",";
                //objChildFormViewIds=objChildFormViewIds+objField.child_view_id +",";
                this.fieldChildFormList.push(objField);
              }
            });
          });
         
        }
      }
      this.getFormViewDetails(objChildFormIds,objChildFormViewIds);
    });
  }
  getFormViewDetails(childformids,childformviewid){
    console.log("field linkwithchild arrObjSect==>",childformids,childformviewid)
    this.subscription=this.formService.getFormViewById(this.form_viewid).subscribe(res => {
      let formViewList=res.rows;  
      if(formViewList.length>0){
        //let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        this.edit_option=formViewList[0]["edit_option"];
        this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        let validationList=formViewList[0]["validation"]?JSON.parse(formViewList[0]["validation"]):[];
        this.list={
          "attachmentList":this.attachmentType,
          "validationList" : validationList
        }
        this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;                 
        let child_form_view_json=formViewList[0]["child_form_view_ids"]?JSON.parse(formViewList[0]["child_form_view_ids"]):[];
        
        if(child_form_view_json !=undefined && child_form_view_json.length>0){
          child_form_view_json=child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId="";
          for(let r=0;r<child_form_view_json.length; r++){
            childFormId=childFormId+child_form_view_json[r]["formid"]+",";
          }
          childFormId=childFormId.substring(0,childFormId.length-1)
        }
        console.log("childformids==>",childFormId,child_form_view_json,childformids)
        if(childFormId ==""){
          childFormId=childformids.substring(0,childformids.length-1);
        }else{
          childFormId=childformids+childFormId;
        }
        console.log("childformids==>",childFormId,child_form_view_json,childformids)

        if(childFormId != "" && childFormId != undefined && childFormId != null){
          this.subscription=this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
            this.childFormList=childRes.rows;
            //if(child_form_view_json.length>0){
              for(let a=0;a<this.childFormList.length;a++){
                let arrView=child_form_view_json.filter(d=>d.formid == this.childFormList[a]["form_id"])
                if(arrView.length>0){
                  this.childFormList[a]["viewid"]=arrView[0]["viewid"]
                  this.childFormList[a]["display_order"]=arrView[0]["orderno"]
                  this.childFormList[a]["filterjson"]=arrView[0]["filterjson"]
                  this.childFormList[a]["showon"]=arrView[0]["showon"]
                  this.childFormList[a]["type"]="linkwithview"
                  this.childFormList[a]["visible_stage"]=arrView[0]["visible_stage"]==undefined?"":arrView[0]["visible_stage"];
                  this.childFormList[a]["editable_stage"]=arrView[0]["editable_stage"]==undefined?"":arrView[0]["editable_stage"];
                  }else{
                    let fieldArr=this.fieldChildFormList.filter(d=>d.child_form_id==this.childFormList[a]["form_id"].toString());
                    this.childFormList[a]["viewid"]=0;
                    this.childFormList[a]["display_order"]=0;
                    this.childFormList[a]["filterjson"]="";
                    this.childFormList[a]["showon"]="page"
                    this.childFormList[a]["type"]="linkwithform"
                    this.childFormList[a]["visible_stage"]=fieldArr[0]["visible_stage"];
                    this.childFormList[a]["editable_stage"]=fieldArr[0]["editable_stage"];
                  }
                // console.log(arrView)
              }
            //}
            this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
            console.log("this.childFormList==>",this.childFormList,child_form_view_json) 
            this.childFormListPage=this.childFormList.filter(d=>d.showon==="page");
            this.childFormListTab=this.childFormList.filter(d=>d.showon==="tab");
            if(this.isAddMode ==false){
              this.getRowEditData()
            }else{
              //console.log("this.SectionConfig==>",this.SectionConfig)  
              this.SectionConfig.forEach(arrField => {
                arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="add");
              });
              this.FormViewLoaded=Promise.resolve(true);
              this.processing=Promise.resolve(true);
            }
          });
        }else{
          if(this.isAddMode ==false){
            this.getRowEditData()
          }else{
            //console.log("this.SectionConfig==>",this.SectionConfig)  
            this.SectionConfig.forEach(arrField => {
              arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="add");
            });
            this.FormViewLoaded=Promise.resolve(true);
            this.processing=Promise.resolve(true);
          }
        }

      }
     
    });
  }
  getRowEditData(){
    this.add_edit=Promise.resolve(false);
    this.SectionConfig=null;
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
        //now getting initial stageid
        let intStageid;
        if(this.stageid==undefined){
          intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
          this.stageid=intStageid
          this.sessionUrlPrams.stageid=intStageid;
        }else{
          intStageid=this.stageid;
        }
        console.log("intStageid==>",this.stageid,intStageid)
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control field hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(intStageid)>=0 || field.visible_stage.toString() =="");
        });
        // control Section hide/show based on process stages...
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined ||d.visible_stage.indexOf(intStageid)>=0 || d.visible_stage.toString() =="")));
        //this.stageid=intStageid;
        //console.log("this.SectionConfig==>",intStageid, this.SectionConfig)           
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
      }
       //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="edit");
      });

      //console.log("this.SectionConfig==>",this.SectionConfig)  

      this.isAddMode=false;
      //this.editrowid=e.rowData[this.tablePrimaryKey];
      this.refKey=this.editrowid
      var strQry='select * from '+this.tableName+' where '+this.tablePrimaryKey+'= '+this.editrowid+';';
      // console.log(strQry,"in View");
      this.subscription=this.formService.getFormData(strQry).subscribe(res => {
        // console.log("rowData==",res.rows[0]);
        this.editValueRow=JSON.parse(JSON.stringify(res.rows[0]));
        //console.log("this.formAllField===>",this.editValueRow);
        this.FormViewLoaded=Promise.resolve(true);
        this.processing=Promise.resolve(true);
        if(this.edit_option == 3){
            if(this.childFormList.length>0){        
              let formid=this.childFormList[0]["form_id"];
              let tabid="#tabchildedit"+formid;
              setTimeout(() => {$(tabid).click();}, 100);        
            }
        }
      });
    }
    // filtersLoaded
    //@Input() isAddMode: boolean;// Added by PKS
    //@Input() editValueRow:any;// Added by PKS
    

  }

  processsubmit(value: any){
    console.log("process value.isAddMode===>",JSON.stringify(value),value.isAddMode,value.SuccessMasgLoaded);
    this.isLoading=true;
    if(value.SuccessMasgLoaded=="true"){
      //this.SuccessMasgLoaded=Promise.resolve(true);
      let formData=value.value;
      if(value.actiontype=='actonprocess'){
        if(formData.actiontaken==2){
          let strSQLDraftUpdate="update radx_draft_requests set status_id=105 where item_id="+value.itemid+"and form_id="+value.formid;
          console.log("strSQLDraft==>",strSQLDraftUpdate)
          this.subscription=this.formService.getFormData(strSQLDraftUpdate).subscribe(result =>{
            console.log("status update in draft sucessfully",result.status);
          });
        }else if(formData.actiontaken==3){
          let strSQLDraftUpdate="update radx_draft_requests set status_id=106 where item_id="+value.itemid+"and form_id="+value.formid;
          console.log("strSQLDraft==>",strSQLDraftUpdate)
          this.subscription=this.formService.getFormData(strSQLDraftUpdate).subscribe(result =>{
            console.log("status update in draft sucessfully",result.status);
          });
        }
      }
      
      this.toastrService.success("Submitted Sucessfully !!",'',{timeOut: 3000,});
      // this.SuccessMasg="Submitted Sucessfully !!"
    }else{
      this.toastrService.error(value.ErrorOccurred,'',{timeOut: 3000,});
      
    }
    setTimeout(() => {this.isLoading=false},800);
  }

  gotoback(){
    //this.router.navigateByUrl('/display-form-detail?'+this.tempFormId);
  }
  // getControlType(field_binding,form_display_json){    
  //   let SectionConfig;
  //   if(form_display_json != null){
  //     SectionConfig=form_display_json;
  //   }else{
  //     SectionConfig=this.SectionConfig;
  //   }
  //   var typeVal="";    
  //   for(var r=0; r<SectionConfig.length; r++){ 
  //     var regConfig=SectionConfig[r]["FieldConfig"]; 
  //     let arrSection= regConfig.filter(d => d.name == field_binding);
  //     if(arrSection.length>0){
  //       typeVal=arrSection[0]["type"];
  //     }
  //   }
  //   return typeVal;
  // }


  //   submit(value:any) {
  //   console.log("Form Edit pradeep",value);
  //   // console.log(this.childFormList)
  //   let queryList=[]
  //       Object.entries(value["value"]).forEach(item => {
  //         queryList.push({"columnName":item[0], "columnValue":item[1]})
  //       })
  //       let strColumn="",strValue="";      
  //       for(var c=0; c<queryList.length; c++){        
  //           let secRepeat=this.SectionConfig.filter(d=>d.binding_name==queryList[c]["columnName"] && d.repeatable_save_as.toLowerCase() =="json" );
  //           strColumn=strColumn+queryList[c]["columnName"]+",";
  //           let chkVal=queryList[c]["columnValue"];
  //           let strVal=null;
  //           if(chkVal != null){
  //             if(this.getControlType(queryList[c]["columnName"],null) !=='' && this.getControlType(queryList[c]["columnName"],null) !=='number' && this.getControlType(queryList[c]["columnName"],null) !=='datetime' && this.getControlType(queryList[c]["columnName"],null) !=='decimal'  && this.getControlType(queryList[c]["columnName"],null) !=='date' &&  this.getControlType(queryList[c]["columnName"],null) !=='checkbox' && this.getControlType(queryList[c]["columnName"],null) !=='radiobutton' && this.getControlType(queryList[c]["columnName"],null) !=='lookup' && this.getControlType(queryList[c]["columnName"],null) !=='dependentlookup' && this.getControlType(queryList[c]["columnName"],null) !=='autocomplete'){
  //               strVal="'"+queryList[c]["columnValue"]+"'";
  //             }else if(this.getControlType(queryList[c]["columnName"],null) =='checkbox'){              
  //               strVal=(queryList[c]["columnValue"]=='true'||queryList[c]["columnValue"]==true)?true:false;              
  //             }else if(this.getControlType(queryList[c]["columnName"],null) =='autocomplete'){
  //               strVal="'"+queryList[c]["columnValue"]?queryList[c]["columnValue"]["text"]:''+"'";
  //             }else if(this.getControlType(queryList[c]["columnName"],null) =='date' || this.getControlType(queryList[c]["columnName"],null) =='datetime'){
  //               strVal=queryList[c]["columnValue"]?"'"+queryList[c]["columnValue"]+"'":null;
  //             }
  //             else{
  //               if(secRepeat.length >0){
  //                 strVal=(queryList[c]["columnValue"] !=="" && queryList[c]["columnValue"] !=="0") ?"'"+JSON.stringify(queryList[c]["columnValue"])+"'":null;
  //               }else{
  //                 strVal=(queryList[c]["columnValue"] !=="" && queryList[c]["columnValue"] !=="0") ?queryList[c]["columnValue"]:null;
  //               }
                
  //             }
  //           }
  //           strValue=strValue+strVal+",";
          
  //       }
  //       strColumn=strColumn.substring(0,strColumn.length-1);
  //       strValue=strValue.substring(0,strValue.length-1);
  //       let query="insert into "+this.tableName+ "("+strColumn+") values ("+strValue+") RETURNING "+this.tablePrimaryKey + ";"
  //       this.subscription=this.formService.getFormData(query).subscribe(res => {
  //           if(res.status==500){
  //             this.ErrorMasgLoaded = Promise.resolve(true); 
  //           }else{
  //             this.refKey=res.rows[0][this.tablePrimaryKey];
  //             // saving child data
  //                 for(var a=0; a<this.childFormList.length; a++){
  //                   let list=value["childforms"].get(this.childFormList[a]["form_id"]);
  //                   if(list != undefined){        
  //                     let childData=value["childforms"].get("childData");
  //                     let formId=list["formid"];
  //                     let childTableName=list["tablename"];
  //                     let childPrimaryKey=list["primary_key"];
  //                     let childLength =list["value"].size;
  //                     for(var c=0; c<childLength; c++){
  //                         this.saveChildRecord(list["value"].get(c),childTableName,childPrimaryKey,formId,this.refKey)
  //                     }
  //                   }
  //                 }
  //             // =============             
  //           }
  //         },err=>{           
  //           alert("Due to some Issue data not saved successfully");
  //         })

  // }

 

  // saveChildRecord(parsedJSON,table_name,primary_key,formid,refKey){
    
  //    let formJson=this.childFormList.filter(d=> d.form_id == formid)
  //    let form_display_json=[],foriegn_key="";
  //    if(formJson.length>0){
  //     form_display_json=formJson[0]["form_display_json"]?JSON.parse(formJson[0]["form_display_json"]):[];
  //     for(var r=0;r<form_display_json.length; r++){
  //       for(let c=0; c<form_display_json[r]["FieldConfig"].length; c++){
  //         if(form_display_json[r]["FieldConfig"][c]["pkfk"] == "F"){
  //           // console.log(form_display_json[r]["FieldConfig"][c]["name"])
  //           foriegn_key=form_display_json[r]["FieldConfig"][c]["name"]
  //         }
  //       }
  //     }
  //    }

     

  //   let queryList=[]
  //   Object.entries(parsedJSON).forEach(item => {
  //     let val;
  //     if(foriegn_key == item[0]){
  //       val=refKey?refKey:this.refKey;
  //     }else{val=item[1]}
  //     queryList.push({"columnName":item[0], "columnValue":val})
  //   })
  //   let strColumn="",strValue="";      
  //   for(var c=0; c<queryList.length; c++){        
  //       strColumn=strColumn+queryList[c]["columnName"]+",";
  //       let chkVal=queryList[c]["columnValue"];
  //       let strVal=null;
  //       if(chkVal != null){
  //         if(this.getControlType(queryList[c]["columnName"],form_display_json) !=='' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='number' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='datetime' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='decimal'  && this.getControlType(queryList[c]["columnName"],form_display_json) !=='date' &&  this.getControlType(queryList[c]["columnName"],form_display_json) !=='checkbox' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='radiobutton' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='lookup' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='dependentlookup' && this.getControlType(queryList[c]["columnName"],form_display_json) !=='autocomplete'){
  //           strVal="'"+queryList[c]["columnValue"]+"'";
  //         }else if(this.getControlType(queryList[c]["columnName"],form_display_json) =='checkbox'){              
  //           strVal=(queryList[c]["columnValue"]=='true'||queryList[c]["columnValue"]==true)?true:false;              
  //         }else if(this.getControlType(queryList[c]["columnName"],form_display_json) =='autocomplete'){
  //           strVal="'"+queryList[c]["columnValue"]?queryList[c]["columnValue"]["text"]:''+"'";
  //         }else if(this.getControlType(queryList[c]["columnName"],form_display_json) =='date' || this.getControlType(queryList[c]["columnName"],form_display_json) =='datetime'){
  //           strVal=queryList[c]["columnValue"]?"'"+queryList[c]["columnValue"]+"'":null;
  //         }
  //         else{
  //             strVal=(queryList[c]["columnValue"] !=="" && queryList[c]["columnValue"] !=="0") ?queryList[c]["columnValue"]:null;            
  //         }
  //       }
  //       strValue=strValue+strVal+",";
      
  //   }
  //   strColumn=strColumn.substring(0,strColumn.length-1);
  //   strValue=strValue.substring(0,strValue.length-1);
  //   let query="insert into "+table_name+ "("+strColumn+") values ("+strValue+") RETURNING "+primary_key + ";"
  //   // console.log("query==> prawej",query)      
  //   this.subscription=this.formService.getFormData(query).subscribe(res => {
  //     if(res.status==500){
  //       this.ErrorMasgLoaded = Promise.resolve(true); 
  //     }else{
  //       // this.refKey=res.rows[0][this.tablePrimaryKey]; 
  //         // this.SuccessMasg="Save successfully !!";
  //     }
  //   },err=>{
  //     // this.ErrorMasgLoaded = Promise.resolve(true); 
  //     alert("Due to some Issue data not saved successfully");
  //   })
  // }
 

  ngOnDestroy() { 
    this.subscription.unsubscribe();
    this.formService=null;
    this.frameworkComponents=null
    this.processing=null;
    this.formId=null
    this.processid=null
    this.stageid=null
    this.form_viewid=null
    this.formName=null
    this.tableName=null
    this.tablePrimaryKey=null
    this.SectionConfig=null
    this.formDisplayJSON=null
    this.nodeJson=null
    this.connJson=null
    this.FormViewLoaded=null
    this.SuccessMasgLoaded=null
    this.ErrorMasgLoaded=null
    this.isAddMode=null
    this.is_attachment=null
    this.SuccessMasg=null
    this.editrowid=null
    this.refKey=null
    this.childFormList=null
    this.metaDataList=null
    this.attachmentType=null
    this.metadata=null
   this.edit_option=null
  }

  showfile1()
  {
    $("#leftmenunew").removeClass('col-lg-12');
    $("#leftmenunew").addClass('col-lg-7');
    $("#leftmenunew").addClass('prr-4');
    $("#rightmenunew").show();
    $(".newfilebtn").hide();
    $("#rightmenunew").show();
    $(".maximi").show();
    $(".minimi").show();
    
  }  
  hidefile1()
  {
    $("#rightmenunew").hide();
    $("#leftmenunew").removeClass('col-lg-7');
    $("#leftmenunew").removeClass('prr-4');
    $("#leftmenunew").addClass('col-lg-12');
  //  $("#rightmenunew").removeClass('col-lg-6');
    $(".newfilebtn").show();
    $(".maximi").hide();
    $(".minimi").hide();
  }
  
}


