import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from './AppSetting';

@Injectable({
  providedIn: 'root'
})
export class CustomerLoginSigninService {
  url = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient) { }

  checkMobileNumberAndEmail(mobile_no,email,isRegister?){
    var data = { mobile_no,email ,isRegister}
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/checkMobileNumberAndEmailAdmin",data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  saveCustomerDetails(first_name,email,mobile_no,customer_password,
    organization,industry_id,account_type) {
    var data = { first_name,email,mobile_no, customer_password,
      organization,industry_id,account_type}
      console.log("service==",data)
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/saveCustomerDetails", data, { headers })
  }
  saveCorporateDetails(corporateDetails){
    var data = { corporateDetails }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/saveCorporateDetails', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  saveUserDetails(corporateDetails,customer_id){
    var data = { corporateDetails,customer_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/saveUserDetails', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updatePyamentForCorporate(cust_id, paymentDate, receivedAmount){
    var data = { cust_id, paymentDate, receivedAmount }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/updatePyamentForCorporate', data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  updateCorporateDetails(corporateDetails, customer_id){
    var data = { corporateDetails }
    const headers = new HttpHeaders();
    return this.http.put(this.url + `/saveCorporateDetails/${customer_id}`, data, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  onUserEdit(customer_id){
    var data = { customer_id }
    const headers = new HttpHeaders();
    return this.http.get(this.url + `/onUserEdit/${customer_id}`, { headers }).pipe(
      map(
        (res) => {
          return res as any;
        },
        (error) => {
          return error as any;
        }
      )
    );
  }
  getCustomerDetail(customer_id){
    var data = { customer_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCustomerDetail", data, { headers })
  }



  getCustomerAddresses(customerId){
    var data = {customerId}
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCustomerAddresses",data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  getAddresses(customerId,address_id){
    var data = {customerId,address_id}
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getAddresses",data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  saveAddressDetail(address_id,customerId, add_name, add_mobile, pinCode,locality,add_area_street, city, state_id,landmark,alternate_phone,last_name,company_name,email_address,country_id, is_for_future?, is_billing?){
    var data = {address_id,customerId, add_name, add_mobile, pinCode,locality,add_area_street, city, state_id,landmark,alternate_phone,last_name,company_name,email_address,country_id, is_for_future, is_billing}
    console.log("datataaa",data)
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addAddressDetailAdmin",data, { headers })
  }
  deleteAddresses1(address_id){
    
    var data = {address_id}
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/deleteAddresses1",data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  getCityState(pincode){
    var data = { pincode }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCityState",data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
 

}
