import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
import { RowComp, Promise } from 'ag-grid-community';
import Swal from 'sweetalert2';
import { promise } from 'selenium-webdriver';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor() { }
  allSeller:any;
  ngOnInit(): void {
  }

}
