import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 

import { resourceLimits } from 'worker_threads';
import {FormService} from '../../_services/form.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-status-tracker',
  templateUrl: './status-tracker.component.html',
  styleUrls: ['./status-tracker.component.css']
})
export class StatusTrackerComponent implements OnInit {
  txtFileNo: any="";
  list: any[]=[];

  fileNo: any="";
  title: any="";
  estimatedcost: any="";
  fundingCategoryName: any="";
  approvedCost: any="";
  form_id: any="399";
  formList: any[]=[];
  viewList: any[]=[];
  rowList: any[]=[];
  finalCardViewArray : any[]=[];
  card_header: any="";
  constructor(
  private route: ActivatedRoute,
    private formService : FormService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {

    this.list.push(
      {"title":"Free Plan/Virtual Card","cardcost":"Nill","minimumfirstloadvalue":"2000",
      "balanceenquiry":"10","portalandreporting":"Free"}
    )
    this.list.push(
      {"title":"Free Plan/Physical Card (Online Only)","cardcost":"Nill","minimumfirstloadvalue":"3000",
      "atmwithdrawn":"20","annualmaintanance":"50","balanceenquiry":"10","portalandreporting":"Free"}
    )
    this.list.push(
      {"title":"Free Plan/Physical Card","cardcost":"Nill","minimumfirstloadvalue":"5000",
      "atmwithdrawn":"20","annualmaintanance":"50","petrolsurcharge":"1.18","insurancesurcharge":"2","balanceenquiry":"10","portalandreporting":"Free"}
    )

    this.getFormAndList();
    // this.route.queryParams.subscribe((data)=>{    
    //   let tem = JSON.stringify(data).split('@')[1]
    //   let tem1 = tem.split('"')
    //   this.txtFileNo = tem1[0]?tem1[0]:"";    
    //     if(this.txtFileNo !='' && this.txtFileNo != undefined && this.txtFileNo != null){        
    //       this.searchFile();
    //     }   
    // })
  }

  getFormAndList(){
    let qry=`select form_id, form_name, table_name from app_forms where form_id=${this.form_id}; 
    select view_id,field_json,card_header from app_form_view_details where form_id=${this.form_id} and view_type=4 order by view_id;`
    this.formService.getFormData(qry).subscribe(res=>{
      this.formList=res[0].rows;
      this.viewList=res[1].rows;
      if(this.formList.length>0){
        this.getRowData(this.formList[0]["table_name"]);
      }
      if(this.viewList.length>0){
        this.card_header=this.viewList[0]["card_header"]?this.viewList[0]["card_header"]:"";
      }

    });
  }

  getRowData(table_name){
    let viewJsonList=this.viewList[0]["field_json"]?JSON.parse(this.viewList[0]["field_json"]):[];
    let columnsName='';
    if(viewJsonList.length>0){
      for(let r=0; r<viewJsonList.length; r++){
        columnsName=columnsName+viewJsonList[r]["field_name"]+", ";        
      }
    
    }

    setTimeout(() => {
      if(columnsName != ""){
        columnsName= columnsName.substring(0,columnsName.length-2)
      }
        let finalArray=[],subrow=[];
        let rowQuery=`select ${columnsName} from ${table_name}`;
        this.formService.getFormData(rowQuery).subscribe(response=>{
          this.rowList=response.rows;  
          for(let n=0; n<this.rowList.length; n++){
            let colArray=columnsName.split(",")
            subrow=[];
            let obj=[];
            for(let m=0; m<colArray.length; m++){  
              let subobj={}          
              let key=colArray[m].trim();
              let val=this.rowList[n][(colArray[m]).trim()];
              subobj["key_val"]=val;     
              
              let arr=viewJsonList.filter(d=>d["field_name"] == key)     
              if(arr.length>0){
                subobj["display_as"]=arr[0]["displayAs"];   
                subobj["field_caption"]=arr[0]["field_caption"];
              }else{
                subobj["display_as"]="Body";
                subobj["field_caption"]=arr[0]["field_caption"];
              }  
              obj.push(subobj)             
            }
            subrow.push(obj)
            finalArray.push(obj)           
          } 
          for(let a=0; a<finalArray.length; a++){
            let header=finalArray[a].filter(d=>d.display_as == "header");
            let title=finalArray[a].filter(d=>d.display_as == "subtitle")
            let subtitle=finalArray[a].filter(d=>d.display_as == "title")
            let image=finalArray[a].filter(d=>d.display_as == "image")
            let body=finalArray[a].filter(d=>d.display_as == "body")
            let footer=finalArray[a].filter(d=>d.display_as == "footer")


            this.finalCardViewArray.push({
              header:header[0]["key_val"],
              title:title.length>0?title[0]["key_val"]:"",
              subtitle:subtitle.length>0?subtitle[0]["key_val"]:"",
              image:image.length>0?image[0]["key_val"]:"",
              body:body,
              footer:footer.length>0?footer[0]["key_val"]:"Subscribe now",
            })
          }
          // console.log(JSON.stringify(this.finalCardViewArray))
        });
     
    }, 10);
   
    
  }


  // searchFile(){
  //     this.reset(1);
  //     // console.log(this.txtFileNo)
  //     if(this.txtFileNo == undefined || this.txtFileNo == null || this.txtFileNo== ""){
  //       this.toastrService.warning("File No. is required.")
  //       return false;
  //     }else{
  //     this.txtFileNo=((this.txtFileNo).toString().toLowerCase()).trim();
  //     let qry=`select pi.processinstanceid, 
  //               draft.scheme_title,draft.estimated_cost, draft.approved_cost, stm.varibale_name,
  //               draft.process_id,draft.item_id, frm.table_name, frm.primary_key from radx_draft_requests draft 
  //               left join radx_processinstances pi on pi.processid = draft.process_id and pi.itemid = draft.item_id
  //               left join app_forms frm on frm.form_id = draft.form_id
  //               left join app_tblsystemenum stm on stm.id = draft.funding_category_id
  //               where lower(draft_requestid)='${this.txtFileNo}'`
              
  //     this.formService.getFormData(qry).subscribe(res=>{
  //       let result= res.rows;
  //       // console.log("result==>",result)
  //       if(result.length>0){
  //         let pid=result[result.length-1]["processinstanceid"];
  //         this.fileNo=this.txtFileNo;
  //         this.title=result[result.length-1]["scheme_title"];
  //         this.fundingCategoryName=result[result.length-1]["varibale_name"];
  //         this.estimatedcost=result[result.length-1]["estimated_cost"];
  //         this.approvedCost=result[result.length-1]["approved_cost"];   
  //         this.getLastApprovedAmount(result[result.length-1]["process_id"],result[result.length-1]["item_id"],pid)        
  //         this.getFileStages(pid);
  //       }else{
  //         this.toastrService.warning("No status found")
  //       }
  //     });
  //   }      
  // }

  // getLastApprovedAmount(process_id,item_id,processInstanceId){
  //   let qry=`select proposed_amount from radx_scheme_proposal_amount where processinstanceid=${processInstanceId} order by id`;
  //     this.formService.getFormData(qry).subscribe(res=>{
  //       let arrAmount=res.rows;
  //       if(arrAmount.length>0){
  //         this.estimatedcost=arrAmount[0]["proposed_amount"]?arrAmount[0]["proposed_amount"]:"";
  //         this.approvedCost=arrAmount[arrAmount.length-1]["proposed_amount"]?arrAmount[arrAmount.length-1]["proposed_amount"]:"";
  //       }
  //     })
  //   // let qry=`select processinstanceid from radx_processinstances where processid=${process_id} and itemid=${item_id}`;
  //   // this.formService.getFormData(qry).subscribe(res=>{
  //   //   if(res.rows.length>0){
  //   //     let processInstanceId=res.rows[0]["processinstanceid"];
  //   //     let approveQry=`select * from radx_scheme_proposal_amount where processinstanceid=${processInstanceId} order by id desc`;
  //   //     this.formService.getFormData(approveQry).subscribe(appRes=>{
  //   //       if(appRes.rows.length>0){            
  //   //         this.approvedCost=appRes.rows[0]["proposed_amount"]
  //   //       }          
  //   //     });
  //   //   }
  //   // });
   
  // }

  // getFileStages(pid){
  //   let qry=`select wl.worklistid,wl.sendtime dt,
  //   TO_CHAR(wl.sendtime :: DATE,'dd-mm-yyyy') as sendtime,
  //   wl.sourceid,wl.destinationid, 
  //   concat(usr.user_fname,' ',usr.user_mname,' ',usr.user_lname) as source_user,
  //   concat(usrs.user_fname,' ',usrs.user_mname,' ',usrs.user_lname) as target_user
  //   from radx_worklist wl
  //   left join app_tbluserprofile up on up.userprofile_id =wl.sourceid
  //   left join app_tbluserprofile ups on ups.userprofile_id =wl.destinationid
  //   left join app_tbluser usr on usr.user_id =up.user_id              
  //   left join app_tbluser usrs on usrs.user_id =ups.user_id 
  //   where processinstanceid =${pid}
  //             `
  //   this.formService.getFormData(qry).subscribe(res=>{
  //     this.stageList=res.rows;
  //     if(this.stageList.length>0){
  //       this.stageList= this.stageList.sort((a, b) => a.worklistid < b.worklistid ? -1 : 1)
  //     }      
  //   });
  // }

  // getProcessDetails(table_name,primary_key,item_id){
  //   let qry=`select * from ${table_name} where ${primary_key} = ${item_id}`
  //   this.formService.getFormData(qry).subscribe(res=>{
  //     // console.log(JSON.stringify(res.rows))
  //     // this.stageList=res.rows;      
  //   });
  // }

  // reset(status){
  //   if(status == 0){
  //     this.txtFileNo ="";
  //   }
  //   this.stageList =[]
  //   this.fileNo ="";
  //   this.title ="";
  //   this.estimatedcost="";
  //   this.fundingCategoryName="";
  //   this.approvedCost="";
  // }
}
