<div class="settings">
  <div class="settings-panel">
    <div class="settings-content">
      <div class="settings-title">
        <!-- <button type="button" class="close text-white float-end" (click)="closeseting()" aria-label="Close"><i
            class="fa fa-times"></i></button> -->
        <div class="settingSidebar">
          <a href="javascript:void(0)" class="settingPanelToggle">
            <i class="fa fa-spin fa-cog"></i>
          </a>
          <div class="settingSidebar-body ps-container ps-theme-default" tabindex="2"
            style="overflow: hidden; outline: none;">
            <div class=" fade show active">
              <div class="setting-panel-header">Theme Customizer</div>
              <div class="p-15 border-bottom">
                <h6 class="font-medium m-b-10">Theme Layout</h6>
                <div class="selectgroup layout-color w-50 lightmode">
                  <label> <span class="control-label p-r-20">Light</span>
                    <input type="radio" name="custom-switch-input" value="1" class="custom-switch-input" checked="">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="selectgroup layout-color  w-50 darkmode">
                  <label> <span class="control-label p-r-20">Dark&nbsp;</span>
                    <input type="radio" name="custom-switch-input" value="2" class="custom-switch-input"> <span
                      class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="p-15 border-bottom">
              <h6 class="font-medium m-b-10">Sidebar Colors</h6>
              <div class="sidebar-setting-options">
                <ul class="sidebar-color list-unstyled mb-0">
                  <li title="white" class="">
                    <div class="white"></div>
                  </li>
                  <li title="blue" class="">
                    <div class="blue"></div>
                  </li>
                  <li title="coral" class="">
                    <div class="coral"></div>
                  </li>
                  <li title="purple" class="">
                    <div class="purple"></div>
                  </li>
                  <li title="allports" class="">
                    <div class="allports"></div>
                  </li>
                  <li title="barossa">
                    <div class="barossa"></div>
                  </li>
                  <li title="fancy">
                    <div class="fancy"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="p-15 border-bottom">
              <h6 class="font-medium m-b-10">Theme Colors</h6>
              <div class="theme-setting-options">
                <ul class="choose-theme list-unstyled mb-0">
                  <li title="white" class="">
                    <div class="white"></div>
                  </li>
                  <li title="blue" class="">
                    <div class="blue"></div>
                  </li>
                  <li title="coral">
                    <div class="coral"></div>
                  </li>
                  <li title="purple" class="">
                    <div class="purple"></div>
                  </li>
                  <li title="allports">
                    <div class="allports"></div>
                  </li>
                  <li title="barossa">
                    <div class="barossa"></div>
                  </li>
                  <li title="fancy">
                    <div class="fancy"></div>
                  </li>
                  <li title="cyan" class="">
                    <div class="cyan"></div>
                  </li>
                  <li title="orange">
                    <div class="orange"></div>
                  </li>
                  <li title="green">
                    <div class="green"></div>
                  </li>
                  <li title="red" class="">
                    <div class="red"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="p-15 border-bottom">
              <h6 class="font-medium m-b-10">Layout Options</h6>
              <div class="theme-setting-options">
                <label> <span class="control-label p-r-20">Compact
                    Sidebar Menu</span> <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input"
                    id="mini_sidebar_setting"> <span class="custom-switch-indicator"></span>
                </label>
              </div>
              <div class="theme-setting-options">
                <label> <span class="control-label p-r-20">
                  Sidebar Left
                </span> <input type="radio" checked name="swictcheck" class="custom-switch-input"
                 id="leftside"> <span class="custom-switch-indicator"></span>
                </label>
              </div>
              <div class="theme-setting-options">
               
                <label> <span class="control-label p-r-20"> Sidebar Top</span>
                   <input type="radio"  name="swictcheck" class="custom-switch-input"
                  id="topside" > <span class="custom-switch-indicator"></span>
                </label>
              </div>
            </div>
            
            <div class="mt-3 mb-3 align-center">
              <a href="#" class="btn btn-sm btn-icon icon-left btn-outline-primary btn-restore-theme">
                <i class="fas fa-undo"></i> Restore Default
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>