import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-confirmation-msg',
  templateUrl: './confirmation-msg.component.html',
  styleUrls: ['./confirmation-msg.component.css']
})
export class ConfirmationMsgComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  gotologin() {
    // window.open("about:blank", "_self");
    // window.close();
     this.router.navigate(['/login']);
  }
}
