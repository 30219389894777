import { Component, OnInit } from '@angular/core';
import { SellerRegistrationService } from './../_services/seller-registration.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from './../_services/AppSetting';
import { ProductService } from './../_services/product.service';
import { Page } from '../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams, } from '../field.interface';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-add-variations',
  templateUrl: './add-variations.component.html',
  styleUrls: ['./add-variations.component.css']
})
export class AddVariationsComponent implements OnInit {
  searchTerm: any;
  credit_id: any;
  allAttributeList: any = [];
  category: any = undefined;
  variationValue: any;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  variationName: any;
  isActive: boolean = true;
  MassageOutTime = AppSettings.getMassageOutTime;
  constructor(private sellerService: SellerRegistrationService, private toastrService: ToastrService,private productService: ProductService) { }
  brandList: any[] = [];
  isLoading: boolean = false;
  categoryList: any;
  attributeType:any;
  isLoadGrid: boolean = false;

  ngOnInit(): void {
    this.configParms = {
      currentPage: 1,
      totalItems: this.totalCount,
      itemsPerPage: 10,
    };
    this.page.size = 10;
    this.getAllCat();
    //this.getVariation();
    this.getAttribute();
    this.bindNgxDataGrid(this.page, 1);
  }
  getAttribute() {
    this.productService.getAllAttribute().subscribe((res: any) => {
      if (res.length > 0) {
        this.allAttributeList = res;
      } else {
        this.allAttributeList = [];
      }
    })
  }
  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;
    this.bindNgxDataGrid(this.page, 1);
  }
  LoadGrid(event) {
    this.isLoadGrid = true;
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
  }
  bindNgxDataGrid(pageInfo, status) {
    let total,
      pagesize,
      limit,
      offset = 0;
    pagesize = pageInfo.size;
    //debugger
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = (pageInfo.pageNumber - 1) * pagesize;
    }
    if (!this.isLoadGrid) offset = this.searchTerm ? 0 : offset;
    this.searchTerm = this.searchTerm ||''
    this.productService.getVariationCount(this.searchTerm).subscribe((resp: any) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.isLoading = true;
    this.sellerService.getVariation(pagesize, offset, this.searchTerm).subscribe((res:any) => {
     
     // this.isLoading = false;
      if(res.status==200){
        if(res.data)
        this.brandList =res.data;
        else this.brandList = []
        console.log(this.brandList)
      }
      if(res.status == 400){
        if(res.data)
        this.brandList =res.data;
        else this.brandList = []
        console.log(this.brandList)
      }
      this.isLoading= false
      
   //   this.categoryList = res;
    })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) {
        currPage = 1;
      }
      this.configParms = {
        currentPage: currPage,
        totalItems: this.totalCount,
        itemsPerPage: pagesize,
      };
      this.LoadPagination = true;
      //this.isLoading = false;
    });
  }
  getAllCat() {
    this.isLoading = true;
    this.sellerService.getCategory().subscribe(res => {
      this.isLoading = false;
      this.categoryList = res;
    })
  }
  addVariation() {

   // let temp = this.variationValue.split(',');
 //   let tempLowerString = this.variationValue.toLowerCase().split(',');;
    // if (new Set(tempLowerString).size !== temp.length) {
    // //  alert('duplicate value in attribute value')
    //   this.toastrService.warning("Duplicate in attribute value", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   return false;
    // }
   // debugger
    this.isLoading = true;
    let data = {isActive:this.isActive,category:this.category,type:this.attributeType,name:this.variationName.trim(),value:this.variationValue}
    this.sellerService.createVariation(data).subscribe((res:any) => {
      this.isLoading = false;
      if(res.status!=200){
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
         return false;
      }else{
        $('#myModal').modal('hide');
        this.toastrService.success("Successfully Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.variationName = null;
        this.variationValue = null;
        this.category = null;
        this.attributeType = null;
        //this.getVariation()
        this.bindNgxDataGrid(this.page, 1);
        return false;
      }
      console.log(res)
   //   this.categoryList = res;
    })
  }
  // getVariation() {
  //   this.isLoading = true;
  //   this.sellerService.getVariation().subscribe((res:any) => {
  //     this.isLoading = false;
  //     if(res.status==200){
  //       this.brandList =res.data;
  //       console.log(this.brandList)
  //     }
      
  //  //   this.categoryList = res;
  //   })
  // }
  onDelete(obj) {
    obj = obj.rowData.id;
    Swal.fire({
      title: `Are you sure you want to delete this variation ?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        // this.productService.deleteYearData(year.year_id).subscribe((res) => {
        //   Swal.fire('Deleted year successfully!', '', 'success');
        //   this.bindNgxDataGrid(this.page, 1);
        // }
       // );
       this.sellerService.deleteVariation(obj).subscribe((res:any) => {
        this.isLoading = false;
        if(res.status!=200){
          this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
           return false;
        }else{
        //  $('#myModal').modal('hide');
          this.toastrService.success("Variation deleted Successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
         // this.variationName = null;
         // this.variationValue = null;
         // this.category = null;
          //this.getVariation()
          this.bindNgxDataGrid(this.page, 1);
          return false;
        }
      })
      // alert('delete panding')
      }
    });
  }
  attribute_id:any;
  onEdit(e){
    this.isActive = e.rowData.is_active;
    this.category = e.rowData.category_id;
    this.variationName = e.rowData.value_code;
    this.variationValue = e.rowData.value_lable;
    this.attribute_id = e.rowData.id;
    this.attributeType = e.rowData.attribute_id;
    console.log('attribute_idattribute_idattribute_id',this.attribute_id)
    $('#myModal').modal('show')
  }
  openAddVariation(){
    this.isActive = true;
    this.category =undefined;
    this.variationName = null;
    this.variationValue = null;
    this.attribute_id  = null;
    this.attributeType = undefined;
    $('#myModal').modal('show')
  }
  updateVariation() {
   // let temp = this.variationValue.split(',');
   // let tempLowerString = this.variationValue.toLowerCase().split(',');
    // if (new Set(tempLowerString).size !== temp.length) {
    // //  alert('duplicate value in attribute value')
    //   this.toastrService.warning("Duplicate in attribute value", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   return false;
    // }
    this.isLoading = true;
    let data = {isActive:this.isActive,category:this.category,type:this.attributeType,name:this.variationName.trim(),value:this.variationValue}
    this.sellerService.updateVariation(data,this.attribute_id).subscribe((res:any) => {
      this.isLoading = false;
      if(res.status!=200){
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
         return false;
      }else{
        $('#myModal').modal('hide');
        this.toastrService.success("Successfully Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.variationName = null;
        this.variationValue = null;
        this.category = null;
        this.attributeType = null;
        //this.getVariation()
        this.bindNgxDataGrid(this.page, 1);
        return false;
      }
    })
  }
}
