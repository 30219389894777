<div class="d-flex minhyt80" id="wrapper">
        
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>
        <div class="container-fluid pb-0">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb p-0">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Right & Permission</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="container-fluid pb-0">
                <div class="tabs row my-2">
                    <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
                        <ul id="tab-button">
                            <li id="tabDraft" class="is-active">
                                <a href="javascript:void(0)" class="font9">Right & Permission
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">

                            <div class="card-header p-2">
                                <div class="row">
                                    <div class="col-md-9">
                                        <h6 class="font600 font9 mb-0">All Users</h6>
                                    </div>
                                <div class="col-md-3">
                                    <div class="flaot-right">
                                        <input type="text" class="form-control" placeholder="Search User">
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="card-body p-0 rolemng">

                                 <div class="card shadow-none">
                                    <div class="card-header" style="border-bottom: none;">
                                        <div class="row">
                                            <div class="col-sm-4 col">
                                                <h6 class="mb-0"><b>Name</b></h6>
                                            </div>
                                            <div class="col-sm-8 col">
                                                <h6 class="mb-0 ml-4"><b>Role</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                 </div>


                                 <div class="card shadow-none border-0 border-bottom">
                                     <div class="card-body bg-md-light px-3 py-2">
                                         <div class="row d-flex align-items-center">
                                             <div class="col-sm-4">
                                                <div class="media">
                                                    <img class="mr-2" src="../../../assets/images/userimg01.png" alt="" width="36">
                                                    <div class="media-body">
                                                        <h6 class="mb-0"><b>Mohit Kumar</b></h6>
                                                        <p class="font8 mb-0"><i class="fa fa-envelope-o"></i> test@gmail.com</p>
                                                    </div>
                                                  </div>
                                             </div>
                                             <div class="col-sm-8">
                                                <div class="row">
                                                    <div class="col text-center">
                                                        <Strong class="font8">Manage Orders</Strong>
                                                        <div class="checkbox-wrapper-2" title="Access View Orders, Search Orders, and View Shipments">
                                                            <input type="checkbox" class="customcheckBox" checked>
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Returns</Strong>
                                                        <div class="checkbox-wrapper-2" title="Add notes, change status of return requests">
                                                            <input type="checkbox" class=" customcheckBox">
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Reviews</Strong>
                                                        <div class="checkbox-wrapper-2" title="Access View Product Reviews">
                                                            <input type="checkbox" class=" customcheckBox">
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Payments</Strong>
                                                        <div class="checkbox-wrapper-2" title="Access Payments">
                                                            <input type="checkbox" class=" customcheckBox">
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Gift Card</Strong>
                                                        <div class="checkbox-wrapper-2" title="View purchased gift Card and their details.">
                                                            <input type="checkbox" class="customcheckBox" checked>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                         </div>
                                     </div>
                                 </div>
                                 
                                 <div class="card shadow-none border-0 border-bottom">
                                     <div class="card-body bg-md-light px-3 py-2">
                                         <div class="row d-flex align-items-center">
                                             <div class="col-sm-4">
                                                <div class="media">
                                                    <img class="mr-2" src="../../../assets/images/userimg01.png" alt="" width="36">
                                                    <div class="media-body">
                                                        <h6 class="mb-0"><b>Rohit Singh</b></h6>
                                                        <p class="font8 mb-0"><i class="fa fa-envelope-o"></i> test321@gmail.com</p>
                                                    </div>
                                                  </div>
                                             </div>
                                             <div class="col-sm-8">
                                                <div class="row">
                                                    <div class="col text-center">
                                                        <Strong class="font8">Manage Orders</Strong>
                                                        <div class="checkbox-wrapper-2" title="Access View Orders, Search Orders, and View Shipments">
                                                            <input type="checkbox" class=" customcheckBox">
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Returns</Strong>
                                                        <div class="checkbox-wrapper-2" title="Add notes, change status of return requests">
                                                            <input type="checkbox" class=" customcheckBox">
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Reviews</Strong>
                                                        <div class="checkbox-wrapper-2" title="Access View Product Reviews">
                                                            <input type="checkbox" class=" customcheckBox" checked>
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Payments</Strong>
                                                        <div class="checkbox-wrapper-2" title="Access Payments">
                                                            <input type="checkbox" class=" customcheckBox">
                                                        </div>
                                                     </div>
                                                     <div class="col text-center">
                                                        <Strong class="font8">Manage Gift Card</Strong>
                                                        <div class="checkbox-wrapper-2" title="View purchased gift Card and their details.">
                                                            <input type="checkbox" class=" customcheckBox" checked>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div class="card shadow-none border-0 border-bottom">
                                    <div class="card-body bg-md-light px-3 py-2">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-sm-4">
                                               <div class="media">
                                                   <img class="mr-2" src="../../../assets/images/userimg01.png" alt="" width="36">
                                                   <div class="media-body">
                                                       <h6 class="mb-0"><b>Dipak Singh</b></h6>
                                                       <p class="font8 mb-0"><i class="fa fa-envelope-o"></i> tesqt321@gmail.com</p>
                                                   </div>
                                                 </div>
                                            </div>
                                            <div class="col-sm-8">
                                               <div class="row">
                                                   <div class="col text-center">
                                                       <Strong class="font8">Manage Orders</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access View Orders, Search Orders, and View Shipments">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Returns</Strong>
                                                       <div class="checkbox-wrapper-2" title="Add notes, change status of return requests">
                                                           <input type="checkbox" class=" customcheckBox" checked> 
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Reviews</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access View Product Reviews">
                                                           <input type="checkbox" class=" customcheckBox" checked>
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Payments</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access Payments">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Gift Card</Strong>
                                                       <div class="checkbox-wrapper-2" title="View purchased gift Card and their details.">
                                                           <input type="checkbox" class=" customcheckBox" checked>
                                                       </div>
                                                    </div>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card shadow-none border-0 border-bottom">
                                    <div class="card-body bg-md-light px-3 py-2">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-sm-4">
                                               <div class="media">
                                                   <img class="mr-2" src="../../../assets/images/userimg01.png" alt="" width="36">
                                                   <div class="media-body">
                                                       <h6 class="mb-0"><b>Priyanka Singh</b></h6>
                                                       <p class="font8 mb-0"><i class="fa fa-envelope-o"></i> Priyanka@gmail.com</p>
                                                   </div>
                                                 </div>
                                            </div>
                                            <div class="col-sm-8">
                                               <div class="row">
                                                   <div class="col text-center">
                                                       <Strong class="font8">Manage Orders</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access View Orders, Search Orders, and View Shipments">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Returns</Strong>
                                                       <div class="checkbox-wrapper-2" title="Add notes, change status of return requests">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Reviews</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access View Product Reviews">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Payments</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access Payments">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Gift Card</Strong>
                                                       <div class="checkbox-wrapper-2" title="View purchased gift Card and their details.">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card shadow-none border-0 border-bottom">
                                    <div class="card-body bg-md-light px-3 py-2">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-sm-4">
                                               <div class="media">
                                                   <img class="mr-2" src="../../../assets/images/userimg01.png" alt="" width="36">
                                                   <div class="media-body">
                                                       <h6 class="mb-0"><b>Abhishek Yadav</b></h6>
                                                       <p class="font8 mb-0"><i class="fa fa-envelope-o"></i> abhishek@gmail.com</p>
                                                   </div>
                                                 </div>
                                            </div>
                                            <div class="col-sm-8">
                                               <div class="row">
                                                   <div class="col text-center">
                                                       <Strong class="font8">Manage Orders</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access View Orders, Search Orders, and View Shipments">
                                                           <input type="checkbox" class="customcheckBox" checked>
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Returns</Strong>
                                                       <div class="checkbox-wrapper-2" title="Add notes, change status of return requests">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Reviews</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access View Product Reviews">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Payments</Strong>
                                                       <div class="checkbox-wrapper-2" title="Access Payments">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                                    <div class="col text-center">
                                                       <Strong class="font8">Manage Gift Card</Strong>
                                                       <div class="checkbox-wrapper-2" title="View purchased gift Card and their details.">
                                                           <input type="checkbox" class=" customcheckBox">
                                                       </div>
                                                    </div>
                                               </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>