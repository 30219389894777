import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerServiceService } from 'src/app/_services/customer-service.service';
import { LoginService } from 'src/app/_services/login.service';
import { ProductService } from 'src/app/_services/product.service';
import { AppSettings } from '../../_services/AppSetting';
declare var $: any;
@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit {
  MassageOutTime = AppSettings.getMassageOutTime;
  product: any = [];
  productId: any;
  productDis: any;
  productDis1: any;
  productDis2: any;
  inputnumber: any;
  productPrise: any;
  btnDisable: boolean = false;
  productList: string;
  singleProductPrise: void;
  product1: any = [];
  customerId: any;
  grandTotal: number = 0;
  currentUrl: any;
  grandGst: any = 0;
  totalQtyPrice: number;
  basePrice: any;
  buyerName: any;
  itmid: any;
  savedAmount: number;
  productsDetails: any;
  rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  currentProGst: any;
  productAmount: any;
  productDetailPriceConf: any[] = [];
  configurabaleDetails: any;
  extraFeaturesDetails: any[] = [];
  bundlePriceList: any[] = [];
  productOriginal: any = [];
  role_id: any;
  constructor(private router: Router, private customerServiceService: CustomerServiceService, private route: ActivatedRoute, private productService: ProductService, private toastrService: ToastrService, private loginService: LoginService) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    let data1 = localStorage.getItem('buyer')
    data1 = JSON.parse(data1) 
    this.customerId = data1['buyerid']
    //console.log("buerrer", this.customerId)
    this.buyerName = data1['buyername']
    let data2 = localStorage.getItem('role_id')
    data2 = JSON.parse(data2) 
    this.role_id = data2['role_id']
    console.log("buerrer", this.role_id)
    this.inputnumber = 1;
    if (this.inputnumber == 1) {
      this.btnDisable = true;
    }
    if (this.customerId != null && this.customerId != undefined) {
      this.getCartData();
    }
    else {
      this.product = localStorage.getItem('cart_items')
        ? JSON.parse(localStorage.getItem('cart_items'))
        : [];
      console.log('stock_quantity', this.product);
      this.grandGst = 0;
      this.savedAmount = 0;
      for (const gst of this.product) {
        if (gst.product_type == 198 ||  gst.product_type == 208) {
          this.grandGst = gst.gst_amount
            ? Number(this.grandGst) +
              (Number(gst.price) * Number(gst.gst_amount)) / 100
            : 0;
          this.grandGst = this.grandGst
            ? parseFloat(this.grandGst).toFixed(2)
            : 0;
        }
         else { 
            if(gst.bundle_price.length > 0){
              //
              gst.gst_rate = gst.gst;
              this.grandGst = gst.gst_rate 
              ? Number(this.grandGst) +
                 ((Number(gst.price) * Number(gst.gst_rate)) / 100)
              : 0;
            this.grandGst = this.grandGst
              ? parseFloat(this.grandGst).toFixed(2)
              : 0;
            }else{
              this.grandGst = gst.gst_amount
              ? Number(this.grandGst) +
                Number(gst.qtytotal) * Number(gst.gst_amount)
              : 0;
            this.grandGst = this.grandGst
              ? parseFloat(this.grandGst).toFixed(2)
              : 0;
            }
         
        }
        if (
          gst.product_type == 197 ||
          !gst.product_type ||
          gst.product_type == 209
        ){
          ////
          this.savedAmount = Number(this.savedAmount) + (Number(gst.qtytotal) * Number(gst.mrp) - Number(gst.qtytotal) * Number(gst.base_price));
          if (gst.product_type != 209)
          if (gst.bundle_price.length) {
                gst.price = gst.bundle_price.filter(
                  (x) =>  Number(gst.qtytotal) >= +x.min_qty &&  Number(gst.qtytotal) <= +x.max_qty )[0]?.price ?  (Number(gst.bundle_price.filter( (x) =>   Number(gst.qtytotal) >= +x.min_qty &&  Number(gst.qtytotal) <= +x.max_qty  )[0]?.price)) * Number(gst.qtytotal)
                  : Number(gst.price);
              }
        }else {
          for (const dtl of gst.details) {
            // if (gst.product_type == 209) {
              // this.savedAmount =
              //   Number(this.savedAmount) +
              //   (Number(dtl.qty) * Number(dtl.mrp) + +dtl.admin_mrp) -
              //   (Number(dtl.qty) * Number(dtl.price) + +dtl.admin_price);
            // } else {
              this.savedAmount =
                Number(this.savedAmount) +
                (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
                (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
            // }
          }
        }
      }
      ////
      this.getGrandTotalForGuest();
    }   

    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      if (data.length > 1) {
        var str = data;
        let temp_id = str.split("selected_prod_id=")[1];
        this.productId = temp_id.substring(0, temp_id.length - 1);
      }
    });
  }
  showgrandTotal:number;
  isLoading: boolean = false;
  getGrandTotalForGuest() {
    ////
    this.grandTotal = 0;
  
    let temp_qty = 0;
    for (let prod of this.product) {
      if (
        prod.product_type == 197 ||
        !prod.product_type ||
        prod.product_type == 209
      ) {
        this.grandTotal = Number(this.grandTotal) + Number(prod.price);
        //console.log("this.grandTotal",this.grandTotal)
      } else {
        for (const dtl of prod.details) {
          temp_qty = temp_qty + Number(dtl.quantity);
        }
        this.grandTotal = Number(this.grandTotal) + Number(prod.price);
        //console.log("this.grandTotal",this.grandTotal)
      }
    }
    if (this.grandGst) {
      this.grandTotal = Number(this.grandTotal) + Number(this.grandGst);
      this.grandTotal = parseFloat(Number(this.grandTotal).toFixed(2));
    }
    this.showgrandTotal =  this.grandTotal;
    this.isLoading = false;
  }

  convertToInt() {
    if (this.grandGst) {
      let subtotal = Number(this.grandTotal) - Number(this.grandGst) ;
      return parseFloat(Number(subtotal).toFixed(2));
    }
    {
      return Number(this.grandTotal);
    }
  }
  convertToInt1(pr, adpr,product) {
    //
    //this.productDetailPriceConf = [];
    if (product.product_type == 197 || !product.product_type) {
     // this.productDetailPriceConf = [];
      if (product.bundle_price.length > 0) {
             let bundleobj = product.bundle_price.find(
               (item) => Number(product.qtytotal) >= Number(item.min_qty) && Number(product.qtytotal) <= Number(item.max_qty)
             );
             if (bundleobj) {
              pr = bundleobj.price;
              adpr = 0;
           }
         }
    } else {
      if (product.product_type != 209) {
        if (product.bundle_price.length > 0) {
          for(let dtl of product.details){
              let bundleobj = product.bundle_price.find(
                (item) => Number(dtl.quantity) >= Number(item.min_qty) && Number(dtl.quantity) <= Number(item.max_qty)
              );
              if (bundleobj) {
                pr = bundleobj.price;
                adpr = 0;
            }
          }
          }
      } 
    }
    let subtotal = Number(pr) + Number(adpr);
    return subtotal;
  }
  removeClass() {
    setTimeout(() => {
      $('.overlay-container').removeClass('addcontainerdisable');
    }, 1000);
  }
  plus(product, i, dtl?) {    
    
    // console.log('yes want to check', product, i)
    if (Number(product.qtytotal) >= Number(product.stock_quantity)) {
      this.toastrService.warning(
        `only ${product.stock_quantity} stock available`,
        '',
        { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
      );
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();

      return false;
    }
     if(product.is_deal == true){
      if (Number(product.qtytotal) >= Number(product.deal_qty)) {
        this.toastrService.warning(
          `only ${product.deal_qty} stock available for deal product`,
          '',
          { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
        );
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();
  
        return false;
      }
    } 
    if (this.customerId != null && this.customerId != undefined) {
      let exprice = 0;
      if (!dtl) {
        //this.grandGst = +this.grandGst - (Number(product.qtytotal) * Number(product.gst_amount))
        this.savedAmount =
          Number(this.savedAmount) -
          (Number(product.qtytotal) * Number(product.product_mrp) -
            Number(product.qtytotal) * Number(product.base_price));
        product.qtytotal = Number(product.qtytotal) + 1;
        this.savedAmount =
          Number(this.savedAmount) +
          (Number(product.qtytotal) * Number(product.product_mrp) -
            Number(product.qtytotal) * Number(product.base_price));
      }

      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(product.qtytotal) >= +x.min_qty && Number(product.qtytotal) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
        } else {
          let no_max_limit = 0;
          no_max_limit = product.bundle_price.filter(
            (x) => Number(product.qtytotal) >= +x.min_qty && x.no_max_limit
          )[0]?.price;
          if (no_max_limit) {
            startingPrise = no_max_limit;
            product.price =
              Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
          } else {
            var startingPrise = Number(product.base_price) + Number(exprice);
          }
        }
        this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
        let gstAmount = 0;
       
        product.price = Number(startingPrise) * Number(product.qtytotal);
        gstAmount = (Number(product.price)*(product.gst_rate)/100); //Number(product.qtytotal) *
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        product.total_gst_amount = Number(gstAmount);
        if (!dtl) {
          this.grandGst = Number(this.grandGst) + Number(gstAmount);
          this.grandGst = parseFloat(this.grandGst).toFixed(2);
        }
        this.productService
        .updateCartItem(product.crat_id, product.qtytotal,  product.product_type, 0, 0)
        .subscribe((res) => {
          $('#minus-btn' + i).removeClass('disabled');
          this.getGrandTotalForGuest();
        });
      }else{
        let savedAmount = 0;
        if (product.product_type == 209) {
          for (const dtl of product.details) {
            if (dtl.qty)
              savedAmount =
                Number(savedAmount) +
                Number(dtl.qty) * Number(product.product_mrp) -
                Number(dtl.qty) * Number(product.base_price);
          }
          this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
          savedAmount = 0;
          dtl.qty = Number(dtl.qty) + 1;
          product.qtytotal = Number(product.qtytotal) + 1;
          for (const dtl of product.details) {
            if (dtl.qty)
              savedAmount =
                Number(savedAmount) +
                Number(dtl.qty) * Number(product.product_mrp) -
                Number(dtl.qty) * Number(product.base_price);
          }
          this.savedAmount = Number(this.savedAmount) + Number(savedAmount);
  
          if (product.extra_features.length) {
            exprice = 0;
            for (const ext of product.extra_features) {
              if (ext.isSelected) {
                exprice = Number(exprice) + Number(ext.price);
              }
            }
            exprice = Number(exprice);
          }
        }
        var startingPrise = Number(product.base_price) + Number(exprice);
   
       // let gstAmount = 0;
       // gstAmount = Number(product.gst_amount); //Number(product.qtytotal) *
      //  product.price = Number(startingPrise) * Number(product.qtytotal);
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        // if (!dtl) {
        //   this.grandGst = Number(this.grandGst) + Number(gstAmount);
        //   this.grandGst = parseFloat(this.grandGst).toFixed(2);
        //   console.log('step 2', this.grandGst);
        // }

        this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
        let gstAmount = 0;
       
        product.price = Number(startingPrise) * Number(product.qtytotal);
        gstAmount = (Number(product.price)*(product.gst_rate)/100); //Number(product.qtytotal) *
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        product.total_gst_amount = Number(gstAmount);
        if (!dtl) {
          this.grandGst = Number(this.grandGst) + Number(gstAmount);
          this.grandGst = parseFloat(this.grandGst).toFixed(2);
        }


        // $('#minus-btn' + i).addClass('disabled');
        if (product.product_type == 209) {
          this.productService
            .updateCartItem(
              product.crat_id,
              product.qtytotal,
              product.product_type,
              0,
              0,
              JSON.stringify(product.details),
              JSON.stringify(product.extra_features)
            )
            .subscribe((res) => {
              $('#minus-btn' + i).removeClass('disabled');
              this.getGrandTotalForGuest();
            });
        } else {
          this.productService
            .updateCartItem(product.crat_id, product.qtytotal, product.product_type, 0, 0)
            .subscribe((res) => {
              $('#minus-btn' + i).removeClass('disabled');
              this.getGrandTotalForGuest();
            });
        }
      }

     
    } else {
      //
      product.gst_rate = product.gst;
      let exprice = 0;
      if (!dtl) {
        this.savedAmount =  Number(this.savedAmount) - ((Number(product.qtytotal) *Number(product.mrp)) -
            (Number(product.qtytotal) * Number(product.base_price)));
        product.qtytotal = Number(product.qtytotal) + 1;
        this.savedAmount =  Number(this.savedAmount) +  (Number(product.qtytotal) * Number(product.mrp))- (Number(product.qtytotal) * Number(product.base_price));
      }
      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(product.qtytotal) >= +x.min_qty && Number(product.qtytotal) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
        } else {
          let no_max_limit = 0;
          no_max_limit = product.bundle_price.filter(
            (x) => Number(product.qtytotal) >= +x.min_qty && x.no_max_limit
          )[0]?.price;
          if (no_max_limit) {
            startingPrise = no_max_limit;
            product.price =
              Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
          } else {
            var startingPrise = Number(product.base_price) + Number(exprice);
          }
        }
        this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
        if(this.grandGst<0){
          this.grandGst = 0;
        }
        let gstAmount = 0;
        product.price = Number(startingPrise) * Number(product.qtytotal);
        gstAmount = (Number(product.price)*Number(product.gst_rate)/100); //Number(product.qtytotal) *
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        product.total_gst_amount = Number(gstAmount);
        if (!dtl) {
          this.grandGst = Number(this.grandGst) + Number(gstAmount);
          this.grandGst = parseFloat(this.grandGst).toFixed(2);
        }
        this.getGrandTotalForGuest();
        console.log('lets checkupdat', this.product);
        localStorage.setItem('cart_items', JSON.stringify(this.product));
        $('#minus-btn' + i).removeClass('disabled');
        // this.productService
        // .updateCartItem(product.crat_id, product.qtytotal, 0, 0, 0)
        // .subscribe((res) => {
        //   $('#minus-btn' + i).removeClass('disabled');
        //   this.getGrandTotalForGuest();
        // });
      }else{
      let savedAmount = 0;
      if (product.product_type == 209) {
        for (const dtl of product.details) {
          if (dtl.qty)
            savedAmount =
              Number(savedAmount) +
              (Number(dtl.qty) * Number(product.mrp) + Number(product.admin_mrp)) -
              Number(dtl.qty) * Number(product.base_price);
        }
        this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
        savedAmount = 0;
        dtl.qty = Number(dtl.qty) + 1;
        product.qtytotal = Number(product.qtytotal) + 1;
        for (const dtl of product.details) {
          if (dtl.qty)
            savedAmount =
              Number(savedAmount) +
              (Number(dtl.qty) * Number(product.mrp) + Number(product.admin_mrp)) -
              Number(dtl.qty) * Number(product.base_price);
        }
        this.savedAmount = Number(this.savedAmount) + Number(savedAmount);
        if (product.extra_features.length) {
          exprice = 0;
          for (const ext of product.extra_features) {
            if (ext.isSelected) {
              exprice = Number(exprice) + Number(ext.price);
            }
          }
          exprice = Number(exprice);
        }
      }
      var startingPrise = Number(product.base_price) + Number(exprice);
     // product.price = Number(startingPrise) * Number(product.qtytotal);
     // let gstAmount = 0;
    //  // gstAmount = Number(product.gst_amount); //Number(product.qtytotal) *
    //   if (!dtl) {
    //     this.grandGst = Number(this.grandGst) + Number(gstAmount);
    //     this.grandGst = parseFloat(this.grandGst).toFixed(2);
    //   }
////
      this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
        let gstAmount = 0;
        product.price = Number(startingPrise) * Number(product.qtytotal);
        gstAmount = (Number(product.price)*Number(product.gst_rate)/100); //Number(product.qtytotal) *
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        product.total_gst_amount = Number(gstAmount);
        if (!dtl) {
          this.grandGst = Number(this.grandGst) + Number(gstAmount);
          this.grandGst = Number(parseFloat(this.grandGst).toFixed(2));
        }


      this.getGrandTotalForGuest();
      console.log('lets checkupdat', this.product);
      localStorage.setItem('cart_items', JSON.stringify(this.product));
      $('#minus-btn' + i).removeClass('disabled');
    }
  }
  }
  minus(product, i, dtl?) {   
    if (product.qtytotal == 1) {
      $('#minus-btn' + i).addClass('disabled');
      return true;
    }
    if (product.product_type == 209) {
      if (dtl.qty == 0) {
        $('#minus-btn' + i).addClass('disabled');
        return true;
      }
    }
    if (this.customerId != null && this.customerId != undefined) {
      if (!dtl) {
        //this.grandGst = Number(this.grandGst) - (Number(product.qtytotal) * Number(product.gst_amount))
        this.savedAmount =
          Number(this.savedAmount) -
          (Number(product.qtytotal) * Number(product.product_mrp) -
            Number(product.qtytotal) * Number(product.base_price));
        product.qtytotal = Number(product.qtytotal) - 1;
        this.savedAmount =
          Number(this.savedAmount) +
          (Number(product.qtytotal) * Number(product.product_mrp) -
            Number(product.qtytotal) * Number(product.base_price));
      }
      let exprice = 0;
      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(product.qtytotal) >= +x.min_qty && Number(product.qtytotal) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
        } else {
          let no_max_limit = 0;
          no_max_limit = product.bundle_price.filter(
            (x) => Number(product.qtytotal) >= +x.min_qty && x.no_max_limit
          )[0]?.price;
          if (no_max_limit) {
            startingPrise = no_max_limit;
            product.price =
              Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
          } else {
            var startingPrise = Number(product.base_price) + Number(exprice);
            // product.price = Number(startingPrise) * Number(product.qtytotal);
          }
        }
        this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
        let gstAmount = 0;
     //   gstAmount = Number(product.gst_amount); //Number(product.qtytotal) *
      //  product.price = Number(startingPrise) * Number(product.qtytotal);
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        // if (!dtl) {
        //   this.grandGst = Number(this.grandGst) - Number(gstAmount);
        //   this.grandGst = parseFloat(this.grandGst).toFixed(2);
        //   console.log('step 2', this.grandGst);
        // }
        product.price = Number(startingPrise) * Number(product.qtytotal);
        gstAmount = (Number(product.price)*(product.gst_rate)/100); //Number(product.qtytotal) *
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        product.total_gst_amount = Number(gstAmount);
        if (!dtl) {
          this.grandGst = Number(this.grandGst) + Number(gstAmount);
          this.grandGst = parseFloat(this.grandGst).toFixed(2);
        }

        this.productService
        .updateCartItem(product.crat_id, product.qtytotal, product.product_type, 0, 0)
        .subscribe((res) => {
          $('#minus-btn' + i).removeClass('disabled');
          this.getGrandTotalForGuest();
        });

      }else{
      let savedAmount = 0;
      if (product.product_type == 209) {
        for (const dtl of product.details) {
          if (dtl.qty)
            savedAmount =
              Number(savedAmount) +
              Number(dtl.qty) * Number(product.product_mrp) -
              Number(dtl.qty) * Number(product.base_price);
        }
        this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
        savedAmount = 0;
        dtl.qty = Number(dtl.qty) - 1;
        product.qtytotal = Number(product.qtytotal) - 1;
        for (const dtl of product.details) {
          if (dtl.qty)
            savedAmount =
              Number(savedAmount) +
              Number(dtl.qty) * Number(product.product_mrp) -
              Number(dtl.qty) * Number(product.base_price);
        }
        this.savedAmount = Number(this.savedAmount) + Number(savedAmount);
        if (product.extra_features.length) {
          exprice = 0;
          for (const ext of product.extra_features) {
            if (ext.isSelected) {
              exprice = Number(exprice) + Number(ext.price);
            }
          }
          exprice = Number(exprice);
        }


      }
      // dtl.qty = Number(product.qtytotal);
      // product.price = Number(product.price) -  Number(product.firstExtraPrice)

      var startingPrise = Number(product.base_price) + Number(exprice);
      // product.price = Number(startingPrise) * Number(product.qtytotal);
      // let gstAmount = 0;
      // gstAmount = Number(product.gst_amount); //Number(product.qtytotal) *
      // console.log('step 5---', gstAmount);
      // if (!dtl) {
      //   this.grandGst = Number(this.grandGst) - Number(gstAmount);
      //   this.grandGst = parseFloat(this.grandGst).toFixed(2);
      //   console.log('step 6 --', this.grandGst);
      // }

      this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
      let gstAmount = 0;
      product.price = Number(startingPrise) * Number(product.qtytotal);
      gstAmount = (Number(product.price)*(product.gst_rate)/100); //Number(product.qtytotal) *
      product.total_gst_amount = Number(gstAmount);
      if (!dtl) {
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
      }
      if (product.product_type == 209) {
        this.productService
          .updateCartItem(
            product.crat_id,
            product.qtytotal,
            product.product_type,
            0,
            0,
            JSON.stringify(product.details),
            JSON.stringify(product.extra_features)
          )
          .subscribe((res) => {
            // $('#minus-btn' + i).removeClass('disabled');
            this.getGrandTotalForGuest();
          });
      } else {
        this.productService
          .updateCartItem(product.crat_id, product.qtytotal, product.product_type, 0, 0)
          .subscribe((res) => {
            // $('#minus-btn' + i).removeClass('disabled');
            this.getGrandTotalForGuest();
          });
      }
    }
    } else {
      product.gst_rate= product.gst;
      if (!dtl) {
        this.savedAmount =  Number(this.savedAmount) - ((Number(product.qtytotal) *Number(product.mrp)) -
            (Number(product.qtytotal) * Number(product.base_price)));
        product.qtytotal = Number(product.qtytotal) - 1;
        this.savedAmount =  Number(this.savedAmount) +  (Number(product.qtytotal) * Number(product.mrp))- (Number(product.qtytotal) * Number(product.base_price));
      }
      let savedAmount = 0;
      let exprice = 0;
      if (product.bundle_price && product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(product.qtytotal) >= +x.min_qty && Number(product.qtytotal) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
        } else {
          let no_max_limit = 0;
          no_max_limit = product.bundle_price.filter(
            (x) => Number(product.qtytotal) >= +x.min_qty && x.no_max_limit
          )[0]?.price;
          if (no_max_limit) {
            startingPrise = no_max_limit;
            product.price =
              Number(startingPrise) * Number(product.qtytotal) + Number(exprice);
          } else {
            var startingPrise = Number(product.base_price) + Number(exprice);
            // product.price = Number(startingPrise) * Number(product.qtytotal);
          }
        }
       
        this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
        if(this.grandGst<0){
          this.grandGst = 0;
        }
        let gstAmount = 0;
        product.price = Number(startingPrise) * Number(product.qtytotal);
        gstAmount = (Number(product.price)*(product.gst_rate)/100); //Number(product.qtytotal) *
        // this.grandGst = parseFloat(this.grandGst).toFixed(2);
        product.total_gst_amount = Number(gstAmount);
        if (!dtl) {
          this.grandGst = Number(this.grandGst) + Number(gstAmount);
          this.grandGst = parseFloat(this.grandGst).toFixed(2);
        }

        this.getGrandTotalForGuest();
        console.log('lets checkupdat', this.product);
        localStorage.setItem('cart_items', JSON.stringify(this.product));
        $('#minus-btn' + i).removeClass('disabled');

      }else{
      if (product.product_type == 209) {
        //debugger
        for (const dtl of product.details) {
          if (dtl.qty)
            savedAmount =
              Number(savedAmount) +
              (Number(dtl.qty) * Number(product.mrp)) -
              (Number(dtl.qty) * Number(product.base_price));
        }
        this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
        savedAmount = 0;
        dtl.qty = Number(dtl.qty) - 1;
        product.qtytotal = Number(product.qtytotal) - 1;
        for (const dtl of product.details) {
          if (dtl.qty)
            savedAmount =
              Number(savedAmount) +
              (Number(dtl.qty) * Number(product.mrp)) -
              (Number(dtl.qty) * Number(product.base_price));
        }
        this.savedAmount = Number(this.savedAmount) + Number(savedAmount);
        if (product.extra_features.length) {
          exprice = 0;
          for (const ext of product.extra_features) {
            if (ext.isSelected) {
              exprice = Number(exprice) + Number(ext.price);
            }
          }
          exprice = Number(exprice);
        }
      }
      var startingPrise = Number(product.base_price) + Number(exprice);
      
      // let gstAmount = 0;
      // gstAmount = Number(product.gst_amount); //Number(product.qtytotal) *
      // if (!dtl) {
      //   this.grandGst = Number(this.grandGst) - Number(gstAmount);
      //   this.grandGst = parseFloat(this.grandGst).toFixed(2);
      // }
      // product.price = Number(startingPrise) * Number(product.qtytotal);
      this.grandGst =  Number(this.grandGst) - (Number(product.total_gst_amount));
      let gstAmount = 0;
      product.price = Number(startingPrise) * Number(product.qtytotal);
      gstAmount = (Number(product.price)*(product.gst_rate)/100); //Number(product.qtytotal) *
      // this.grandGst = parseFloat(this.grandGst).toFixed(2);
      product.total_gst_amount = Number(gstAmount);
      if (!dtl) {
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
      }
     
      this.getGrandTotalForGuest();
      localStorage.setItem('cart_items', JSON.stringify(this.product));
    }
  }
  }
  converToCurrency(x){
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res
  }
  plusConf(product, dtl, i) {
   
    if (Number(dtl.quantity) + 1 > Number(dtl.stockQty)) {
      this.toastrService.warning(
        `${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
          dtl.attribute_value2 ? dtl.attribute_value2 : ''
        } only ${dtl.stockQty} stock available`,
        '',
        { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
      );
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
      // }
    }
    if(product.is_deal == true){
      if (Number(product.quantity) + 1 > Number(product.deal_qty)) {
        this.toastrService.warning(
          `only ${product.deal_qty} stock available for deal product`,
          '',
          { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
        );
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();
  
        return false;
      }
    } 
    let startingPrise = 0;
    let exprice = 0;
    if (this.customerId != null && this.customerId != undefined) {
      let savedAmount = 0;
      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
      savedAmount = 0;
      dtl.quantity = Number(dtl.quantity) + 1;
      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) + Number(savedAmount);
      let cur_quant = 0;
      for (const pro of product.details) {
        cur_quant = Number(cur_quant) + Number(pro.quantity);
      }
      if (product.extra_features.length) {
        exprice = 0;
        for (const ext of product.extra_features) {
          if (ext.isSelected) {
            exprice = Number(exprice) + Number(ext.price);
          }
        }
        exprice = Number(exprice) * Number(cur_quant);
      }
      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(cur_quant) >= +x.min_qty && Number(cur_quant) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(cur_quant) + Number(exprice);
        } else {
          let no_max_limit = 0;
          no_max_limit = product.bundle_price.filter(
            (x) => Number(cur_quant) >= +x.min_qty && x.no_max_limit
          )[0]?.price;
          if (no_max_limit) {
            startingPrise = no_max_limit;
            product.price =
              Number(startingPrise) * Number(cur_quant) + Number(exprice);
          } else {
            let cur_price = 0;
            for (const pro of product.details) {
              cur_price =
                Number(cur_price) +
                Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
            }
            startingPrise = cur_price;
            product.price = Number(startingPrise) + Number(exprice);
            console.log('adminPrice-->>>', startingPrise);
          }
        }
////
        this.grandGst =  Number(this.grandGst) - Number(product.total_gst_amount);
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        let gstAmount = 0;
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = parseFloat(this.grandGst).toFixed(2);

        // $('#minus-btn' + i).addClass('disabled');
        this.productService
          .updateCartItem(
            product.crat_id,
            product.quantity,
            product.product_type,
            0,
            0,
            JSON.stringify(product.details),
            JSON.stringify(product.extra_features)
          )
          .subscribe((res) => {
            $('#minus-btn' + i).removeClass('disabled');
            this.getGrandTotalForGuest();
          });
      } else {
        let cur_price = 0;
        for (const pro of product.details) {
          cur_price =
            Number(cur_price) +
            Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
        }
        let startingPrise = cur_price;
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        product.price = Number(startingPrise) + Number(exprice);
        this.grandGst =  Number(this.grandGst) - Number(product.total_gst_amount);
        let gstAmount = 0;
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        product.total_gst_amount =  (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        $('#minus-btn' + i).addClass('disabled');
        this.productService
          .updateCartItem(
            product.crat_id,
            product.quantity,
            product.product_type,
            0,
            0,
            JSON.stringify(product.details),
            JSON.stringify(product.extra_features)
          )
          .subscribe((res) => {
            $('#minus-btn' + i).removeClass('disabled');
            this.getGrandTotalForGuest();
          });
      }
    } else {
      // var cartItem = this.product.filter(product => product.product_id == id);
      // this.btnDisable = false
      // $('#minus-btn' + i).removeClass('disabled');
      let savedAmount = 0;
      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
      savedAmount = 0;
      dtl.quantity = Number(dtl.quantity) + 1;

      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) + Number(savedAmount);

      let cur_quant = 0;
      for (const pro of product.details) {
        cur_quant = Number(cur_quant) + Number(pro.quantity);
      }
      if (product.extra_features.length) {
        exprice = 0;
        for (const ext of product.extra_features) {
          if (ext.isSelected) {
            exprice = Number(exprice) + Number(ext.price);
          }
        }
        exprice = Number(exprice) * Number(cur_quant);
      }
      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(cur_quant) >= +x.min_qty && Number(cur_quant) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(cur_quant) + Number(exprice);
        } else {
          let no_max_limit = 0;
          no_max_limit = product.bundle_price.filter(
            (x) => Number(cur_quant) >= +x.min_qty && x.no_max_limit
          )[0]?.price;
          if (no_max_limit) {
            startingPrise = no_max_limit;
            product.price =
              Number(startingPrise) * Number(cur_quant) + Number(exprice);
          } else {
            let cur_price = 0;
            for (const pro of product.details) {
              cur_price =
                Number(cur_price) +
                Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
            }
            //  let  startingPrise = product.base_price;
            startingPrise = cur_price;
            product.price = Number(startingPrise) + Number(exprice);
          }
        }

        //  this.grandGst = Number(this.grandGst) - (Number( product.qtytotal) * Number(product.gst_amount))
        this.grandGst =
          Number(this.grandGst) - Number(product.total_gst_amount);
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        //  product.price = Number(startingPrise) * Number(cur_quant);
        let gstAmount = 0;
        //  gstAmount = Number(product.quantity) * Number(product.gst_amount);
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        $('#minus-btn' + i).removeClass('disabled');
        localStorage.setItem('cart_items', JSON.stringify(this.product));
        this.getGrandTotalForGuest();
      } else {
        let cur_price = 0;
        for (const pro of product.details) {
          cur_price =
            Number(cur_price) +
            Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
        }
        //  let  startingPrise = product.base_price;
        let startingPrise = cur_price;
        // this.grandGst = Number(this.grandGst) - (Number( product.qtytotal) * Number(product.gst_amount))
        this.grandGst =
          Number(this.grandGst) - Number(product.total_gst_amount);

        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        // product.price = Number(startingPrise) * Number(cur_quant);
        product.price = Number(startingPrise) + Number(exprice);
        let gstAmount = 0;
        // gstAmount = Number(product.quantity) * Number(product.gst_amount);
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        $('#minus-btn' + i).addClass('disabled');
        localStorage.setItem('cart_items', JSON.stringify(this.product));
        $('#minus-btn' + i).removeClass('disabled');

        this.getGrandTotalForGuest();
      }
    }
  }
  minusConf(product, dtl, i) {
    let startingPrise = 0;
    let exprice = 0;
    if (dtl.quantity == 0) {
      // this.btnDisable = true;
      $('#minus-btn' + i).addClass('disabled');
      return false;
    }

    if (this.customerId != null && this.customerId != undefined) {
      let savedAmount = 0;
      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
      savedAmount = 0;
      dtl.quantity = Number(dtl.quantity) - 1;

      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) + Number(savedAmount);

      let cur_quant = 0;
      for (const pro of product.details) {
        cur_quant = Number(cur_quant) + Number(pro.quantity);
      }

      if (product.extra_features.length) {
        exprice = 0;
        for (const ext of product.extra_features) {
          if (ext.isSelected) {
            exprice = Number(exprice) + Number(ext.price);
          }
        }
        exprice = Number(exprice) * Number(cur_quant);
      }

      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(cur_quant) >= +x.min_qty && Number(cur_quant) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(cur_quant) + Number(exprice);
        } else {
          let cur_price = 0;
          for (const pro of product.details) {
            cur_price =
              Number(cur_price) +
              Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
          }
          //  let  startingPrise = product.base_price;
          startingPrise = cur_price;
          product.price = Number(startingPrise) + Number(exprice);
        }

        //  this.grandGst = Number(this.grandGst) - (Number( product.qtytotal) * Number(product.gst_amount))
        this.grandGst =
          Number(this.grandGst) - Number(product.total_gst_amount);
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        //  product.price = Number(startingPrise) * Number(cur_quant);
        let gstAmount = 0;
        //  gstAmount = Number(product.quantity) * Number(product.gst_amount);
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        if (product.qtytotal == 0) {
          this.removeWhenzero(product.product_id);
          return false;
        }
        // $('#minus-btn' + i).addClass('disabled');
        this.productService
          .updateCartItem(
            product.crat_id,
            product.quantity,
            product.product_type,
            0,
            0,
            JSON.stringify(product.details),
            JSON.stringify(product.extra_features)
          )
          .subscribe((res) => {
            // $('#minus-btn' + i).removeClass('disabled');
            this.getGrandTotalForGuest();
          });
      } else {
        let cur_price = 0;
        for (const pro of product.details) {
          cur_price = 
            Number(cur_price) +
            Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
        }
        let startingPrise = cur_price;
        this.grandGst =  Number(this.grandGst) - Number(product.total_gst_amount);
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        product.price = Number(startingPrise) + Number(exprice);
        let gstAmount = 0;
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;
        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        if (product.qtytotal == 0) {
          this.removeWhenzero(product.product_id);
          return false;
        }
        this.productService
          .updateCartItem(
            product.crat_id,
            product.quantity,
            product.product_type,
            0,
            0,
            JSON.stringify(product.details),
            JSON.stringify(product.extra_features)
          )
          .subscribe((res) => {
            // $('#minus-btn' + i).removeClass('disabled');
            this.getGrandTotalForGuest();
          });
      }
    } else {
      // this.product1 =JSON.parse( localStorage.getItem('cart_items'))
      // var cartItem = this.product1.filter(product => product.product_id == id);
      // this.btnDisable = false
      // $('#minus-btn' + i).removeClass('disabled');
      let savedAmount = 0;
      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) - Number(savedAmount);
      savedAmount = 0;
      dtl.quantity = Number(dtl.quantity) - 1;

      for (const dtl of product.details) {
        if (dtl.quantity)
          savedAmount =
            Number(savedAmount) +
            (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
      }
      this.savedAmount = Number(this.savedAmount) + Number(savedAmount);

      let cur_quant = 0;
      for (const pro of product.details) {
        cur_quant = Number(cur_quant) + Number(pro.quantity);
      }
      if (product.extra_features.length) {
        exprice = 0;
        for (const ext of product.extra_features) {
          if (ext.isSelected) {
            exprice = Number(exprice) + Number(ext.price);
          }
        }
        exprice = Number(exprice) * Number(cur_quant);
      }
      if (product.bundle_price.length) {
        var newPrice = 0;
        newPrice = product.bundle_price.filter(
          (x) =>
            Number(cur_quant) >= +x.min_qty && Number(cur_quant) <= +x.max_qty
        )[0]?.price;
        if (newPrice) {
          startingPrise = newPrice;
          product.price =
            Number(startingPrise) * Number(cur_quant) + Number(exprice);
        } else {
          let cur_price = 0;
          for (const pro of product.details) {
            cur_price =
              Number(cur_price) +
              Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
          }
          //  let  startingPrise = product.base_price;
          startingPrise = cur_price;
          product.price = Number(startingPrise) + Number(exprice);
        }

        //  this.grandGst = Number(this.grandGst) - (Number( product.qtytotal) * Number(product.gst_amount))
        this.grandGst =
          Number(this.grandGst) - Number(product.total_gst_amount);
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        //  product.price = Number(startingPrise) * Number(cur_quant);
        let gstAmount = 0;
        //  gstAmount = Number(product.quantity) * Number(product.gst_amount);
        // gstAmount =  Number(product.price) * Number(product.gst_amount)/100;

        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;

        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        // $('#minus-btn' + i).removeClass('disabled');
        if (product.qtytotal == 0) {
          this.removeWhenzero(product.product_id);
          return false;
        }
        localStorage.setItem('cart_items', JSON.stringify(this.product));
        this.getGrandTotalForGuest();
      } else {
        let cur_price = 0;
        for (const pro of product.details) {
          cur_price =
            Number(cur_price) +
            Number(pro.quantity) * (Number(pro.price) + +pro.admin_price);
        }
        //  let  startingPrise = product.base_price;
        let startingPrise = cur_price;
        // this.grandGst = Number(this.grandGst) - (Number( product.qtytotal) * Number(product.gst_amount))
        this.grandGst =
          Number(this.grandGst) - Number(product.total_gst_amount);
        product.quantity = Number(cur_quant);
        product.qtytotal = Number(cur_quant);
        // product.price = Number(startingPrise) * Number(cur_quant);
        product.price = Number(startingPrise) + Number(exprice);
        let gstAmount = 0;
    
        gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
        product.total_gst_amount =
          (Number(product.price) * Number(product.gst_amount)) / 100;

        this.grandGst = Number(this.grandGst) + Number(gstAmount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        // $('#minus-btn' + i).removeClass('disabled');
        if (product.qtytotal == 0) {
          this.removeWhenzero(product.product_id);
          return false;
        }
        localStorage.setItem('cart_items', JSON.stringify(this.product));
        this.getGrandTotalForGuest();
      }
    }
  }

getCartData(a?) {
  //this.isLoading = true
  this.grandTotal = 0;
  this.productService.getCartData(this.customerId).subscribe(res => {
    this.productsDetails = res[0].rows;
    this.configurabaleDetails = res[1] ? res[1].rows : [];
    this.extraFeaturesDetails = res[2] ? res[2].rows : [];
    this.bundlePriceList = res[3] ? res[3].rows : [];
    this.isLoading = false;
    if (res[0].rows.length > 0) {
      this.product = [];
      for (let obj of res[0].rows) {
        if (
          obj.product_type == 208 ||
          obj.product_type == 209 ||
          obj.product_type == 198
        ) {
          let item = res[1].rows.filter((x) => x.crat_id == obj.crat_id);
          let extraFeatureList = res[2].rows.filter(
            (x) => x.cart_id == obj.crat_id
          );
          let bundlePrice = this.bundlePriceList.filter(
            (x) => x.product_id == obj.product_id
          );
          let minOrdQty = res[0].rows.filter(
            (x) => x.crat_id == item[0].crat_id
          )[0].min_order_qty;
          let firstExtraPrice = 0;
          let price = 0;
          if (item.length > 0 && obj.product_type == 209) {
            for (const ext of extraFeatureList) {
              if (ext.isSelected) {
                firstExtraPrice =
                  Number(firstExtraPrice) + Number(ext.price);
              }
            }
            price = (Number(obj.base_price) + Number(firstExtraPrice))* Number(obj.qtytotal);
            item = JSON.parse(item[0].details);
          } else {
            if (extraFeatureList.length) {
              for (const ext of extraFeatureList) {
                if (ext.isSelected) {
                  firstExtraPrice =
                    Number(firstExtraPrice) + Number(ext.price);
                }
              }
            }
            item.forEach((dtl) => {
              price =
                price +
                (Number(dtl.price) +
                  Number(dtl.admin_price) +
                  Number(Number(firstExtraPrice))) *
                  Number(dtl.quantity);
            });
            if (bundlePrice.length) {
              price = bundlePrice.filter(
                (x) =>  Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty )[0]?.price ?  (Number(bundlePrice.filter( (x) =>   Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty  )[0]?.price) + Number(firstExtraPrice)) * Number(obj.qtytotal)
                : Number(price);
              //  let   bunprice = this.bundlePriceList.filter((x) => Number(minOrdQty) >= +x.min_qty && Number(minOrdQty) <= +x.max_qty)[0]?.price ? this.bundlePriceList.filter((x) => Number(minOrdQty) >= +x.min_qty && Number(minOrdQty) <= +x.max_qty)[0]?.price * Number(minOrdQty): (Number(price));
            }
          }
          this.product.push({
            crat_id: obj.crat_id,
            gst_amount: Number(obj.gst_rate),
            total_gst_amount: (Number(price) * Number(obj.gst_rate)) / 100,
            gst_rate: obj.gst_rate,
            sku: item[0].sku ? item[0].sku : obj.sku,
            customer_id: obj.customer_id,
            product_id: obj.product_id,
            qtytotal: obj.qtytotal,
            price: price,
            product_mrp: obj.product_mrp,
            total_amount: obj.total_amount,
            product_name: obj.product_name,
            product_discription: obj.product_discription,
            firstExtraPrice: firstExtraPrice,
            seller_id: obj.seller_id,
            seller_name: obj.seller_name,
            base_price: obj.base_price,
            image_path: obj.image_path,
            product_type: obj.product_type,
            extra_features: extraFeatureList ? extraFeatureList : '',
            details: item,
            slides: item[0].slides,
            min_order_qty: minOrdQty,
            card_logo_img_path: item[0].card_logo_img_path,
            artwork_area: item[0]?.artwork_area,
            bundle_price: bundlePrice,
            category: obj.category,
            admin_price: obj.admin_price,
            admin_mrp : obj.admin_mrp,
            is_deal: obj.is_deal,
            deal_qty: obj.product_deal_quantity
          });
          firstExtraPrice = 0;
          extraFeatureList = '';
          price = 0;
        } else if (
          (obj.product_type != 208 &&
            obj.product_type != 209 &&
            obj.product_type != 198 &&
            obj.product_type == null) ||
          obj.product_type == 197
        ) {
          let bundlePrice = this.bundlePriceList.filter(
            (x) => x.product_id == obj.product_id
          );
          let price = (Number(obj.price) + Number(obj.admin_price)) * Number(obj.qtytotal);
          if (bundlePrice.length) {
            price = bundlePrice.filter(
              (x) =>  Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty )[0]?.price ?  (Number(bundlePrice.filter( (x) =>   Number(obj.qtytotal) >= +x.min_qty &&  Number(obj.qtytotal) <= +x.max_qty  )[0]?.price)) * Number(obj.qtytotal)
              : Number(price);
          }
          this.product.push({
            crat_id: obj.crat_id,
            stock_quantity: obj.stock_quantity,
            gst_amount: Number(obj.gst_rate) ,
            total_gst_amount: (Number(price) * Number(obj.gst_rate))/100,
            sku: obj.sku,
            gst_rate: Number(obj.gst_rate),
            customer_id: obj.customer_id,
            product_id: obj.product_id,
            qtytotal: obj.qtytotal,
            price: price,
            min_order_qty: obj.min_order_qty,
            product_mrp: Number(obj.product_mrp) + Number(obj.admin_mrp),
            total_amount: obj.total_amount,
            product_name: obj.product_name,
            product_discription: obj.product_discription,
            seller_id: obj.seller_id,
            seller_name: obj.seller_name,
            base_price: obj.base_price,
            image_path: obj.image_path,
            product_type: obj.product_type,
            bundle_price: bundlePrice,
            category: obj.category,
            admin_price : obj.admin_price,
            admin_mrp: obj.admin_mrp,
            is_deal: obj.is_deal,
            deal_qty: obj.product_deal_quantity,
          });
          this.productOriginal.push({
            crat_id: obj.crat_id,
            stock_quantity: obj.stock_quantity,
            gst_amount: Number(obj.gst_amount) * Number(obj.qtytotal),
            total_gst_amount: Number(obj.gst_amount) * Number(obj.qtytotal),
            sku: obj.sku,
            gst_rate: Number(obj.gst_rate),
            customer_id: obj.customer_id,
            product_id: obj.product_id,
            qtytotal: obj.qtytotal,
            price: price,
            min_order_qty: obj.min_order_qty,
            product_mrp: Number(obj.product_mrp) + Number(obj.admin_mrp),
            total_amount: obj.total_amount,
            product_name: obj.product_name,
            product_discription: obj.product_discription,
            seller_id: obj.seller_id,
            seller_name: obj.seller_name,
            base_price: obj.base_price,
            image_path: obj.image_path,
            product_type: obj.product_type,
            bundle_price: bundlePrice,
            category: obj.category,
            admin_price : obj.admin_price,
            admin_mrp: obj.admin_mrp,
            is_deal: obj.is_deal,
            deal_qty: obj.product_deal_quantity
          });
        }
      }
      //this.product1=this.product ;
    } else {
      this.product = [];
    }
    this.grandGst = 0;
    this.savedAmount = 0;
    let tempMrp = 0;
    let qty = 0;
    
    for (const gst of this.product) {
      if ( gst.product_type == 197 ||  !gst.product_type || gst.product_type == 209 ) {
        this.grandGst =   Number(this.grandGst) + Number(gst.total_gst_amount);
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        this.savedAmount =  Number(this.savedAmount) +  ((Number(gst.qtytotal) * (Number(gst.product_mrp) + Number(gst.admin_mrp))) - (Number(gst.qtytotal) * (Number(gst.base_price))));
      } else {
        for (const dtl of gst.details) {
          if (gst.product_type == 209) {
            // this.savedAmount =
            //   Number(this.savedAmount) +
            //   (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
            //   (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
          } else {
            // this.grandGst = (Number(this.grandGst) + ((Number(dtl.price) + (+dtl.admin_price)) * Number(gst.gst_rate) / 100)) * Number(dtl.quantity);
         
            this.savedAmount =
              Number(this.savedAmount) +
              (Number(dtl.quantity) * Number(dtl.mrp) + +dtl.admin_mrp) -
              (Number(dtl.quantity) * Number(dtl.price) + +dtl.admin_price);
          }
        }

        this.grandGst =  Number(this.grandGst) + (Number(gst.price) * Number(gst.gst_rate)) / 100;
        this.grandGst = parseFloat(this.grandGst).toFixed(2);
        // this.savedAmount = Number(this.savedAmount) + ((Number(gst.qtytotal) * Number(gst.product_mrp)) -  Number(gst.price));
      }
    }
    this.getGrandTotalForGuest();
    this.isLoading = false;
if(a=='1'){
      for (const min of this.product) {
        if (Number(min.min_order_qty) > Number(min.qtytotal)) {
          this.toastrService.warning(
            `minimum quantity can not be less than ${min.min_order_qty} for product ${min.product_name}!`,
            '',
            { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
          );
          $('.overlay-container').addClass('addcontainerdisable');
          this.removeClass();

          return true;
        }
        if ( min.product_type == 208 ||  min.product_type == 209 || min.product_type == 198 ) {
          for (const dtl of min.details) {
            if (Number(min.min_order_qty) > Number(dtl.quantity)) {
              this.toastrService.warning(
                `minimum quantity can not be less than ${min.min_order_qty} for product ${min.product_name} - ${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
                  dtl.attribute_value2 ? dtl.attribute_value2 : ''
                }!`,
                '',
                { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
              );
              $('.overlay-container').addClass('addcontainerdisable');
              this.removeClass();
    
              return true;
            }
            if ((Number(dtl.quantity)> Number(dtl.stockQty)) && dtl.stockQty != '0') {
              this.toastrService.warning(
                `${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
                  dtl.attribute_value2 ? dtl.attribute_value2 : ''
                } only ${dtl.stockQty} stock available`,
                '',
                { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
              );
              $('.overlay-container').addClass('addcontainerdisable');
              this.removeClass();
              return false;
              // }
            }else{
              if(dtl.stockQty == '0' && dtl.quantity != '0'){
                this.toastrService.warning(
                  `${dtl.attribute_value1 ? dtl.attribute_value1 : ''} ${
                    dtl.attribute_value2 ? dtl.attribute_value2 : ''
                  } Out of Stock `,
                  '',
                  { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                );
                $('.overlay-container').addClass('addcontainerdisable');
                this.removeClass();
                return false;
              }
            }
            if(min.is_deal){
              if (
                Number(dtl.quantity) > Number(min.deal_qty) &&
                min.deal_qty != '0'
              ) {
                this.toastrService.warning(
                  ` ${Number(min.deal_qty)} stock available for deal product`,
                  '',
                  { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                );
                $('.overlay-container').addClass('addcontainerdisable');
                this.removeClass();

                return false;
              } else {
                if (min.deal_qty == '0') {
                  this.toastrService.warning(
                    ` ${min.product_name} out of stock for deal product! `,
                    '',
                    { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                  );
                  $('.overlay-container').addClass('addcontainerdisable');
                  this.removeClass();

                  return false;
                }
              }
            }
            
            }
        } else if ( (min.product_type != 208 && min.product_type != 209 && min.product_type != 198 && min.product_type == null) || min.product_type == 197 ) {
          if ((Number(min.qtytotal) > Number(min.stock_quantity)) && (min.stock_quantity != '0')) {
            this.toastrService.warning(
              ` ${Number(min.stock_quantity)} stock available`,
              '',
              { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
            );
            $('.overlay-container').addClass('addcontainerdisable');
            this.removeClass();
            return false;
          }else{
            if( (min.stock_quantity == '0' && min.qtytotal != '0')){
              this.toastrService.warning(
                ` ${min.product_name} out of stock! `,
                '',
                { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
              );
              $('.overlay-container').addClass('addcontainerdisable');
              this.removeClass();
  
              return false;
            }
          }
          if(min.is_deal){
            if (
              Number(min.qtytotal) > Number(min.deal_qty) &&
              min.deal_qty != '0'
            ) {
              this.toastrService.warning(
                ` ${Number(min.deal_qty)} stock available for deal product`,
                '',
                { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
              );
              $('.overlay-container').addClass('addcontainerdisable');
              this.removeClass();

              return false;
            } else {
              if (min.deal_qty == '0') {
                this.toastrService.warning(
                  ` ${min.product_name} out of stock for deal product! `,
                  '',
                  { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' }
                );
                $('.overlay-container').addClass('addcontainerdisable');
                this.removeClass();

                return false;
              }
            }
          }
       
        }
      }
      let data = JSON.stringify(this.product);
      let encriptUrl = this.loginService.encriptDataUrl(data);
      this.router.navigateByUrl('/checkout?' + encriptUrl);

    }
  },
  (err) => {
    console.log(
      'something went wrong while fetching product from addtocart'
    );
  }
);
setTimeout(() => {
  this.isLoading = false;
}, 1500);
  
}
  goToDetail(id, product_type) {
    let data = JSON.stringify({'selected_prod_id':id,'isInDeal':false});
    let encriptUrl = this.loginService.encriptDataUrl(data);
    if (product_type == '208' || product_type == '198') {
      this.router.navigateByUrl('/product-details-customize?' + encriptUrl);
    }
    else if (product_type == '209') {
      this.router.navigateByUrl('/business-card?' + encriptUrl);
    }
    else {
      this.router.navigateByUrl('/simple-products?' + encriptUrl);
    }


  }
  continueShopping(){   
    this.router.navigateByUrl('/buyer-account')
  }
  remove(id) {
    if (this.customerId != null && this.customerId != undefined) {
      this.productService.removeCartItem(this.customerId, id).subscribe(res => {
        if (res.status == 200) {
          this.currentUrl = this.router.url;
          this.toastrService.success(res.message, '', {
            timeOut: this.MassageOutTime,
            positionClass: 'toast-top-center',
          });
          this.getCartData();
          this.router.navigateByUrl(this.currentUrl + '?' + ' ');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 100);
        }
      });
    } else {
      var filtered = this.product.filter(product => product.product_id !== id);
      localStorage.setItem('cart_items', JSON.stringify(filtered));
      this.ngOnInit();
    }
  }
  showPopup(id)
  {
      this.itmid=id;


  }
  getGstAmount(product,flag?) {
    //
    this.productDetailPriceConf = [];
    if (product.product_type == 197 || !product.product_type) {
    //  this.grandGst =  +this.grandGst - Number(product.qtytotal) * Number(product.gst_amount);
   //   let gstAmount = 0;
   //   gstAmount = Number(product.qtytotal) * Number(product.gst_amount);
     // this.grandGst = Number(this.grandGst) + Number(gstAmount);
    //  this.grandGst = parseFloat(this.grandGst).toFixed(2);
      //product.total_gst_amount = Number(product.gst_amount) * Number(product.qtytotal);
      this.currentProGst = Number(product.total_gst_amount);
      this.productDetailPriceConf = [];
     
      this.productDetailPriceConf.push(product);
      // if (product.bundle_price.length > 0) {
      //   // for(let dtl of product.details){
      //        let bundleobj = product.bundle_price.find(
      //          (item) => Number(product.qtytotal) >= Number(item.min_qty) && Number(product.qtytotal) <= Number(item.max_qty)
      //        );
      //        if (bundleobj) {
      //         this.productDetailPriceConf[0].base_price = bundleobj.price;
      //      }else{
      //       this.productDetailPriceConf[0].base_price = product.price;
      //     }
      //   // }
      //    }
      this.productAmount = Number(product.price);
      // $('#price-details').modal('show')
    } else {
      if (product.product_type != 209) {
        if(flag){
           let gstAmount = 0;
        // this.grandGst = Number(this.grandGst) - (Number(product.price) * Number(product.gst_amount) / 100) used may be
       this.grandGst =  Number(this.grandGst) - Number(product.total_gst_amount);

       gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
       this.grandGst = Number(this.grandGst) + Number(gstAmount);
       product.total_gst_amount =  (Number(product.price) * Number(product.gst_amount)) / 100;
       this.grandGst = parseFloat(this.grandGst).toFixed(2);
        }
      //  let gstAmount = 0;
       //  // this.grandGst = Number(this.grandGst) - (Number(product.price) * Number(product.gst_amount) / 100) used may be
     //   this.grandGst =  Number(this.grandGst) - Number(product.total_gst_amount);

      //  gstAmount = (Number(product.price) * Number(product.gst_amount)) / 100;
       // this.grandGst = Number(this.grandGst) + Number(gstAmount);
      //  product.total_gst_amount =  (Number(product.price) * Number(product.gst_amount)) / 100;
      //  this.grandGst = parseFloat(this.grandGst).toFixed(2);
        this.currentProGst = Number(product.total_gst_amount);
        this.productAmount = Number(product.price);
        this.productDetailPriceConf = [];
       
        this.productDetailPriceConf.push(product);
        // if (product.bundle_price.length > 0) {
        //   for(let dtl of this.productDetailPriceConf[0].details){
        //       let bundleobj = product.bundle_price.find(
        //         (item) => Number(dtl.quantity) >= Number(item.min_qty) && Number(dtl.quantity) <= Number(item.max_qty)
        //       );
        //       if (bundleobj) {
        //         dtl.price = bundleobj.price;
        //     }else{
        //       dtl.price = dtl.price;
        //     }
        //   }
        //   }
      } else {
        if (product.product_type == 209) {
          this.productAmount = Number(product.price);
          this.currentProGst = '';
          this.productDetailPriceConf = [];
          this.productDetailPriceConf.push(product);
        }
      }
    }
  }



  placeOrder() {
    if (this.customerId == null || this.customerId == undefined || this.customerId == 'undefined' || this.customerId == '') {
      $("#login-modal").modal('show');
      return false;
    }
    else if (this.product.length == 0) {
      this.toastrService.warning('Cart is empty!', '', { timeOut: 2000, positionClass: "toast-top-center" });
      return false;
    }
    else {
      for (const min of this.product) {
          if(min.min_order_qty > min.qtytotal)
          {
            this.toastrService.warning(`minimum quantity can not be less than ${min.min_order_qty} for product ${min.product_name}!`, '', { timeOut: 2000, positionClass: "toast-top-center" });
              return true;
           }
     }
      let data = JSON.stringify(this.product);
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/checkout?' + encriptUrl);
    }
  }
  onAddExtraFeaturePrice(product, option) {
    let temp_qty = 0;
    let tempPrice = 0;
    if (product.product_type == 209) {
      for (let obj of product.details) {
        if (obj.qty != 0) {
          temp_qty = Number(temp_qty) + Number(obj.qty);
        }
      }
    } else {
      for (let obj of product.details) {
        if (obj.quantity != 0) {
          temp_qty = Number(temp_qty) + Number(obj.quantity);
          tempPrice = Number(tempPrice) + Number(obj.price);
        }
      }
    }

    if (option.isSelected) {
      if (product.product_type == 209) {
        product.price =
          Number(product.price) + Number(temp_qty) * Number(option.price);
      } else {
        product.price =
          Number(product.price) + Number(temp_qty) * Number(option.price);
      }
    } else {
      if (product.product_type == 209) {
        product.price =
          Number(product.price) -
          Number(product.qtytotal) * Number(option.price);
      } else {
        product.price =
          Number(product.price) - Number(temp_qty) * Number(option.price);
      }
    }
    this.getGstAmount(product,true);
    console.log('ssssssssssss', product)
    if (this.customerId) {
      this.productService
        .updateCartItem(
          product.crat_id,
          product.qtytotal,
          product.product_type,
          0,
          0,
          JSON.stringify(product.details),
          JSON.stringify(product.extra_features)
        )
        .subscribe((res) => {
          // $('#minus-btn' + i).removeClass('disabled');
          this.getGrandTotalForGuest();
        });
    } else {
      localStorage.setItem('cart_items', JSON.stringify(this.product));
      this.getGrandTotalForGuest();
    }
  }



  removeWhenzero(id) {
    if (this.customerId != null && this.customerId != undefined) {
      this.productService
        .removeCartItem(this.customerId, id)
        .subscribe((res) => {
          if (res.status == 200) {
            this.currentUrl = this.router.url;
            // this.toastrService.success(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
            this.getCartData();
            this.router.navigateByUrl(this.currentUrl + '?' + ' ');
            // setTimeout(() => {
            //   window.location.reload();
            // }, 100);
          }
        });
    } else {
      var filtered = this.product.filter((x) => x.product_id !== id);
      localStorage.setItem('cart_items', JSON.stringify(filtered));
      this.ngOnInit();
    }
  }
   

  // navigateToMyAccount(product) {
  //   if (this.customerId == null || this.customerId == undefined || this.customerId == 'undefined' || this.customerId == '') {
  //     $("#login-modal").modal('show');
  //     return false;
  //   }

  //   this.customerServiceService.saveForLater(product.customer_id, product.product_id, product.price, product.product_mrp,
  //     product.product_name, product.seller_id, product.seller_name, product.image_path).subscribe((res:any) => {
  //       if (res.status == 203) {
  //         this.currentUrl = this.router.url;
  //         this.toastrService.warning(res.message, '', { timeOut: 2000, positionClass: "toast-top-center" });
  //         this.getCartData();
  //         this.router.navigateByUrl(this.currentUrl + '?' + ' ');
  //         return false;
  //       }
  //       else {
  //         this.toastrService.success('Product Added In Wishlist', '', { timeOut: 2000, positionClass: "toast-top-center" });
  //         this.getCartData();
  //         this.router.navigateByUrl(this.currentUrl + '?' + ' ');
  //         return false;
  //         // var id = product.product_id
  //         // var obj = { 'active-order': 'wishlist', 'selected_prod_id': id }
  //         // let data = JSON.stringify(obj);
  //         // let encriptUrl = this.loginService.encriptDataUrl(data)
  //         // this.router.navigateByUrl('/my-account?' + encriptUrl)
  //       }
  //     })

  // }


}
