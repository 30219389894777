import { Component, OnInit, EventEmitter, Output, Input,ViewChild } from '@angular/core';
import { FormService } from '../../_services/form.service';
import { AppSettings } from '../../_services/AppSetting';
import { NgImageSliderComponent } from 'ng-image-slider';
import { IgxCollapsibleIndicatorTemplateDirective } from '@infragistics/igniteui-angular/public_api';
@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent implements OnInit {

  imageObjects: any[] = [];
  imageObject: any[] = [];
  @Input() filepath: any;
  @Input() ext: any;
  @Input() imagepath: any;
  @Input() attachmentTypeCumFileNames: any;
  @ViewChild('nav') slider: NgImageSliderComponent;
  loadImages: Promise<boolean>;
  constructor(private formService: FormService) { }

  ngOnInit(): void {
    this.loadImage();
  }


prevImageClick() {
    this.slider.prev();
}

nextImageClick() {
    this.slider.next();
}

  loadImage() {
    this.imageObjects=[];
    this.loadImages = Promise.resolve(false);    
    if (this.ext == "pdf") {
      this.formService.pdfToImageData(this.filepath).subscribe(res => {
        console.log("res==>",res)
        for (let i = 0; i < res.length; i++) {
          let img = res[i]['path'];
          let fullPath = AppSettings.getPdfImagepath + img;
          localStorage.setItem("del_temp_pdf_path", img)
          this.imageObjects.push({
            image: fullPath,
            thumbImage: fullPath,
            title: res[i]['name']
          })
        }
       
      })
    } else {
      let fullPath = AppSettings.getPdfImagepath + this.filepath;       
      this.imageObjects.push({
        image:fullPath ,
        thumbImage: fullPath ,
        title: "image 1"
      })
    }
  }

  
}
