<div class="col-sm-12">
    <div class="row">
        <div class="col-sm-12 p-0" *ngIf="imageObjects.length>0">
            <div class="card shadow-none">
                <div class="card-header text-center bg-dark p-2">
                    File Name : {{attachmentTypeCumFileNames}}
                </div>
                <div class="card-body bg-dark">
                    <ng-image-slider [infinite]="false" [imagePopup]="true" [paginationShow]="true" [images]="imageObjects" [animationSpeed]="0.3"
                        #nav [imageSize]="{width: '100%', height: 500}">
                    </ng-image-slider>
   
                </div>
                <div class="rightlft">
                        <a (click)="prevImageClick()" class="arrowfrst"><i class="fa fa-caret-left" aria-hidden="true"></i></a>
                        <a (click)="nextImageClick()" class="arrowscnd"><i class="fa fa-caret-right" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>