import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from '../_services/AppSetting'
import { Observable } from 'rxjs';
import { promises } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class SellerRegistrationService {

  constructor(private http: HttpClient) { }

  url = AppSettings.API_ENDPOINT;
  addSellerDetails(sellerDetails) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addSellerDetails", sellerDetails, { headers })
  }

  numericCheck(event) {
    var keyCode = (event.which) ? event.which : event.keyCode;
    var regex = /^[0-9]+$/;
    if (regex.test(String.fromCharCode(keyCode))) {
      return true;
    }
    else {
      return false;
    }
  }

  getCategory() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCategory", { headers })
  }
  createVariation(data) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/variations", data, { headers })
  }
  updateVariation(data,variation_id) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + `/variations/${variation_id}`, data, { headers })
  }
  // updateVariation(variationName,variationValue,variation_id,category) {
  //   const headers = new HttpHeaders();
  //   let data = { variationName, variationValue, variation_id,category}
  //   return this.http.post(this.url + '/updatevariation', data, { headers })
  // }
  // deleteVariation(variation_id) {
  //   const headers = new HttpHeaders();
  //   return this.http.delete(this.url + `/variations/${variation_id}`, { headers })
  // }
  deleteVariation(variation_id) {
    const headers = new HttpHeaders();
    let data = { variation_id }
    return this.http.post(this.url + "/deletevariations", data, { headers })
  }
  getVariation(limit, offset, searchTerm) {
    const headers = new HttpHeaders();
    return this.http.get(this.url + `/variations/${limit}/${offset}/${searchTerm}`, { headers })
  }
  getCop(searchTerm) {
    let data = { searchTerm };
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCorp", data, { headers })
  }
  getCorpfromcust() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCorpfromcust", { headers })
  }


  getState() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getState", { headers })
  }

  getCity() {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCity", { headers })
  }
  getproduct(searchTerm, customerId) {
    let data = { searchTerm, customerId }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getproductforOrder", data, { headers })
  }
  getSubCategory(product_category_id) {
    const headers = new HttpHeaders();
    let data = { product_category_id }
    return this.http.post(this.url + "/getSubCategory", data, { headers })
  }
  async getSubCategorywithpromise(product_category_id): Promise<any> {
    try {
      const headers = new HttpHeaders();
      let data = { product_category_id }
      const response = this.http.post(this.url + "/getSubCategory", data, { headers }).toPromise();
      return response as any;
    } catch (error) {
      console.error('Error fetching nested subcategories:', error);
      throw error;
    }
  }  
 
  updateSellerDetails(seller_id, business_name, store_name, business_type, product_category, gstin_no, pan_no, company_strength) {
    var data = { seller_id, business_name, store_name, business_type, product_category, gstin_no, pan_no, company_strength }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/updateSellerDetails", data, { headers })
  }

  updateSellerAccountDetail(seller_id, account_holder_name, account_type, ifsc_code, account_no) {
    var data = { seller_id, account_holder_name, account_type, ifsc_code, account_no }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/updateSellerAccountDetail", data, { headers })
  }

  updateSellerAddressDetail(seller_id, business_plot_no_or_block_no, business_street_name, business_pin_code,
    business_city, business_state_id, store_plot_no_or_block_no, store_street_name, store_pin_code, store_city, store_state_id, fillAddress) {


    var data = {
      seller_id, business_plot_no_or_block_no, business_street_name, business_pin_code, business_city,
      business_state_id, store_plot_no_or_block_no, store_street_name, store_pin_code, store_city, store_state_id, fillAddress
    }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/updateSellerAddressDetail", data, { headers })
  }
  getSupplierName(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getSupplierName", data, { headers })
  }
  // Create Order
  corpshippingBillingdetails(cust_id) {
    var data = { cust_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/corpshippingBillingdetails", data, { headers })
  }

  uploadProofs(formData) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/uploadProofs', formData, { headers });
  }
  uploadDocs(formData) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/uploadDocs', formData, { headers });
  }

  getuploadProofs(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getuploadProofs', data, { headers });
  }

  getuploadProofs2(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getuploadProofs2', data, { headers });
  }

  getSellerDetail(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getSellerDetail', data, { headers });
  }

  updateBusinessDetail(business_name, store_name, business_type, product_category, gstin_no, pan_no, company_strength, seller_id) {
    var data = { business_name, store_name, business_type, product_category, gstin_no, pan_no, company_strength, seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/updateBusinessDetail', data, { headers });
  }

  getBusinessDetail(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getBusinessDetail', data, { headers });
  }

  getSellerAccountDetail(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getSellerAccountDetail', data, { headers });
  }

  getSpecificationLabelList(product_sub_category) {
    var data = { product_sub_category }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getSpecificationLabelList', data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  getSellerAddressDetail(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/getSellerAddressDetail', data, { headers });
  }

  getCityState(pincode) {
    var data = { pincode }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getCityState", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  finalSellerProfileSubmission(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/finalSellerProfileSubmission', data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }

  checkMobileAndEmail(mobile_no, email) {
    var data = { mobile_no, email, isAdmin: true }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/checkMobileAndEmail', data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  checkMobileAndSendOtp(mobile_no) {
    var data = { mobile_no }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/checkmobileandsendotpforuser1", data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
  sellerRegisteration(seller_name, mobile_no, email) {
    var data = { seller_name, mobile_no, email }
    const headers = new HttpHeaders();
    return this.http.post(this.url + '/sellerRegisteration', data, { headers }).pipe(map(res => {
      return res as any;
    }, error => {
      return error as any;
    }))
  }
}
