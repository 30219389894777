import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
import Swal from 'sweetalert2';
import { CommonFunctionService } from 'src/app/_services/common-function.service';
import { LoginService } from 'src/app/_services/login.service';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
import { AppSettings } from 'src/app/_services/AppSetting';
declare var $: any;
@Component({
  selector: 'app-seller-wise-product',
  templateUrl: './seller-wise-product.component.html',
  styleUrls: ['./seller-wise-product.component.css']
})
export class SellerWiseProductComponent implements OnInit {
  sellerId: any;
  adminRemarks: any;
  QC_id: any;
  view_item_id: any;
  isView: boolean;
  allProductList: any[]=[];
  isLoader: boolean = true;
  susendedDataShow: boolean = false;
  page = new Page();
  configParms: ConfigPrams;
  groupedDataShow: any = false
  public totalCount = 0;
  LoadPagination: boolean = false;
  LoadPagination1: boolean = false;
  LoadPagination2: boolean = false;
  LoadPagination3: boolean = false;
  LoadPagination4: boolean = false;
  LoadPagination5: boolean = false;

  // LoadPaginationone :boolean = false;
  // LoadPaginationtwo : boolean = false;
  // LoadPaginationthree : boolean = false;
  TempallProductList: any;
  isLoading: boolean = false;
  // isLoader: boolean = true;
  userId: any;
  approvedDataShow: boolean = false;
  blockDataShow: boolean = false;
  allProductListData: any;
  archivedDataShow: any = false
  productId: any;
  action: any;
  qcstatus: any;
  rejectDataShow: boolean = false;
  sellerName: any;
  approvedCount: any;
  rejectCount: any;
  blockCount: any;
  suspendedCount: any;
  currentTooltip: any = ""
  searchTerm: any;
  isloadgridTrue: any = false;
  productsTypeList: any[] = [];
  product_type_id: number;
  current_price: any;
  current_mrp: any;
  prod_id_for_price: any;
  updated_price: any;
  updated_mrp: any;
  fromDate: any;
  todate: any;
  categoryList: Object;
  selectedCategory: string;
  MassageOutTime = AppSettings.getMassageOutTime;

  constructor(private productService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService,
    public commonFunctionService: CommonFunctionService,
    private loginService: LoginService,
    private sellerService: SellerRegistrationService,
    private router: Router) { }

  ngOnInit(): void {
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.sellerName = localStorage.getItem('sellerName');
    this.isLoading = true;
    this.approvedDataShow = true;
    this.blockDataShow = false;
    this.susendedDataShow = false;
    this.rejectDataShow = false;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.bindNgxDataGrid(this.page, 1)

    $(document).ready(function () {
      $('ul.tbsbtns li').click(function () {
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
      });
    });
    this.getProductList();
    this.getProductTypes();
    this.getAllCat();

  }
  setTitle(e) {
    this.currentTooltip = e;
  }

  getAllCat() {
    this.sellerService.getCategory().subscribe(res => {
      this.categoryList = res;
    })
  }

  getProductList() {
    this.productService.getAllProductsListSellerWiseForcount(this.sellerId).subscribe(res => {
      this.allProductListData = res.rows;
      console.log('seller name in resp', this.allProductListData)
      //   this.sellerName = this.allProductListData[0]?.seller_name;
      this.approvedCount = (this.allProductListData.filter(x => x.qcstatus == 173)).length;
      console.log('seller name in resp', this.approvedCount)
      this.rejectCount = (this.allProductListData.filter(x => x.qcstatus == 174)).length;
      this.blockCount = (this.allProductListData.filter(x => x.qcstatus == 185)).length;
      this.suspendedCount = (this.allProductListData.filter(x => x.qcstatus == 186)).length;
    })
  }
  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatus = 173;
    if (!this.isloadgridTrue)
      offset = this.searchTerm ? 0 : offset

    this.productService.getAllProductsListSellerwisecount(qcStatus, this.sellerId, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllProductsListsellerwise(pagesize, offset, qcStatus, this.sellerId, this.searchTerm).subscribe(res => {
        if (res[0].rows.length > 0) {
          this.allProductList = res[0].rows;
          console.log("allproduct", this.allProductList)
          let tempObj;
          for (let obj of this.allProductList) {
            if (obj.product_type == 198) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
            if (obj.product_type == 208) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
          }
          // console.log('responsessssssssssssssssssssss', this.allProductList)
          this.TempallProductList = this.allProductList;
          this.isLoader = false;
        }
        else {
          setTimeout(() => {
            this.allProductList = [];
            this.isLoader = false;
          }, 100);
        }


      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;
      this.LoadPagination2 = false;
      this.LoadPagination1 = false;
      this.LoadPagination3 = false;
      this.LoadPagination4 = false;
      this.LoadPagination5 = false;
      // this.LoadPaginationone = false;
      // this.LoadPaginationtwo = false;
      // this.LoadPaginationthree = false;
      this.isLoading = false;
    })

  }


  LoadGrid2(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid2(this.page, 1)
  }
  bindNgxDataGrid2(pageInfo, status) {
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatus = 185;

    if (!this.isloadgridTrue)
      offset = this.searchTerm ? 0 : offset
    this.productService.getAllProductsListSellerwisecount(qcStatus, this.sellerId, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllProductsListsellerwise(pagesize, offset, qcStatus, this.sellerId, this.searchTerm).subscribe(res => {
        if (res[0].rows.length > 0) {
          this.allProductList = res[0].rows;

          let tempObj;
          for (let obj of this.allProductList) {
            if (obj.product_type == 198) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
            if (obj.product_type == 208) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
          }
          // console.log('responsessssssssssssssssssssss', this.allProductList)
          this.TempallProductList = this.allProductList;
          this.isLoader = false;
        }
        else {
          setTimeout(() => {
            this.allProductList = [];
            this.isLoader = false;
          }, 100);
        }

      }, err => {
        console.log('whant to check error part', err)
      })

      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      // this.LoadPaginationone = true;
      this.LoadPagination = false;
      this.LoadPagination2 = true;
      this.LoadPagination1 = false;
      this.LoadPagination3 = false;
      this.LoadPagination4 = false;
      this.LoadPagination5 = false;

      //  this.LoadPaginationtwo = false;
      //   this.LoadPaginationthree = false;
      this.isLoading = false;
    })

  }


  LoadGrid1(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid1(this.page, 1)
  }
  bindNgxDataGrid1(pageInfo, status) {
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }

    if (!this.isloadgridTrue)
      offset = this.searchTerm ? 0 : offset
    let qcStatus = 186;
    this.productService.getAllProductsListSellerwisecount(qcStatus, this.sellerId, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllProductsListsellerwise(pagesize, offset, qcStatus, this.sellerId, this.searchTerm).subscribe(res => {
        if (res[0].rows.length > 0) {
          this.allProductList = res[0].rows;

          let tempObj;
          for (let obj of this.allProductList) {
            if (obj.product_type == 198) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
            if (obj.product_type == 208) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
          }
          // console.log('responsessssssssssssssssssssss', this.allProductList)
          this.TempallProductList = this.allProductList;
          this.isLoader = false;
        }
        else {
          setTimeout(() => {
            this.allProductList = []
            this.isLoader = false;
          }, 100);
        }

      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = false;
      this.LoadPagination2 = false;
      this.LoadPagination1 = true;
      this.LoadPagination3 = false;
      this.LoadPagination4 = false;
      this.LoadPagination5 = false;
      // this.LoadPaginationone = false;
      //   this.LoadPaginationtwo = true;
      //   this.LoadPaginationthree = false;
      this.isLoading = false;
    })

  }


  LoadGrid3(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid3(this.page, 1)
  }
  bindNgxDataGrid3(pageInfo, status) {
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }

    if (!this.isloadgridTrue)
      offset = this.searchTerm ? 0 : offset
    let qcStatus = 174;
    this.productService.getAllProductsListSellerwisecount(qcStatus, this.sellerId, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0

      this.productService.getAllProductsListsellerwise(pagesize, offset, qcStatus, this.sellerId, this.searchTerm).subscribe(res => {
        if (res[0].rows.length > 0) {
          this.allProductList = res[0].rows;
          //   for (const obj of this.allProductList) {
          //     obj.cdate = obj.cdate.split('T')[0]
          // }
          let tempObj;
          for (let obj of this.allProductList) {
            if (obj.product_type == 198) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
            if (obj.product_type == 208) {
              tempObj = (res[1].rows.filter(x => x.product_id == obj.product_id))[0];
              obj.price = tempObj.price;
              obj.mrp = tempObj.mrp;
              obj.purchase_price = tempObj.purchase_price;
              obj.suk = tempObj.sku;
            }
          }
          // console.log('responsessssssssssssssssssssss', this.allProductList)
          this.TempallProductList = this.allProductList;
          this.isLoader = false;
        }
        else {
          setTimeout(() => {
            this.allProductList = []
            this.isLoader = false;
          }, 100);
        }

      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = false;
      this.LoadPagination2 = false;
      this.LoadPagination1 = false;
      this.LoadPagination3 = true;
      this.LoadPagination4 = false;
      this.LoadPagination5 = false;
      // this.LoadPaginationone = false;
      // this.LoadPaginationtwo = false;
      // this.LoadPaginationthree = true;
      this.isLoading = false;
    })

  }

  LoadGrid4(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid4(this.page, 1)
  }

  bindNgxDataGrid4(pageInfo, status) {
    this.allProductList = []
     this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    //  let qcStatus = '';
    // this.productService.getAllProductsListSellerwisecount(qcStatus,this.sellerId).subscribe(resp => {
    //   this.page.totalElements = resp.rows[0].count;
    //   let pageCount = pageInfo.pageNumber
    //   this.productService.getAllProductsListsellerwise(pagesize,offset,qcStatus,this.sellerId).subscribe(res => {
    //    if(res.rows.length > 0){
    //      console.log('check for res.len', res.rows)
    //     this.allProductList = res.rows;
    //     this.TempallProductList = this.allProductList;
    //     this.isLoader = false;
    //    }else{
    //      setTimeout(() => {
    //       this.allProductList = []
    //      this.isLoader = false;
    //      }, 100);
    //    }

    //   })
    //   this.totalCount = resp.rows[0].count;
    //   let currPage = pageInfo.pageNumber;
    //   if (currPage == 0) { currPage = 1; }
    //   this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
    //   this.LoadPagination = true;
    //   // this.LoadPaginationone = false;
    //   // this.LoadPaginationtwo = false;
    //   // this.LoadPaginationthree = true;
    //   this.isLoading = false;
    // })

  }


  CommonGridSearchMaster(ev) {
    this.searchTerm = ev
    if (this.approvedDataShow)
      this.bindNgxDataGrid(this.page, 1)
    else if (this.blockDataShow)
      this.bindNgxDataGrid2(this.page, 1)
    else if (this.susendedDataShow)
      this.bindNgxDataGrid1(this.page, 1)
    else
      this.bindNgxDataGrid3(this.page, 1)
    // this.allProductList = this.TempallProductList.filter(item => ((item.price ? (item.price.toLowerCase().indexOf(ev) !== -1) : false) || (item.product_name ? (item.product_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.uploade_date ? (item.uploade_date.toString().indexOf(ev) !== -1) : false)))
  }

  ProductApprovedList() {
    this.searchTerm = ''
    this.isLoader = false
    this.approvedDataShow = true;
    this.archivedDataShow = false
    this.blockDataShow = false;
    this.susendedDataShow = false
    this.rejectDataShow = false;
    this.bindNgxDataGrid(this.page, 1)
  }
  productBlockList() {
    this.searchTerm = ''
    this.isLoader = false
    this.archivedDataShow = false
    this.approvedDataShow = false;
    this.susendedDataShow = false
    this.blockDataShow = true;
    this.rejectDataShow = false;
    this.bindNgxDataGrid2(this.page, 1)
  }
  productSuspendedList() {
    this.searchTerm = ''
    this.isLoader = false
    this.archivedDataShow = false
    this.approvedDataShow = false;
    this.susendedDataShow = true
    this.blockDataShow = false;
    this.rejectDataShow = false;
    this.bindNgxDataGrid1(this.page, 1)

  }
  productGroupedList() {
    this.groupedDataShow = true
    this.searchTerm = ''
    this.isLoader = false
    this.archivedDataShow = false
    this.approvedDataShow = false;
    this.susendedDataShow = false
    this.blockDataShow = false;
    this.rejectDataShow = false;
    return false;
  }
  productRejectedList() {
    this.searchTerm = ''
    this.isLoader = false
    this.archivedDataShow = false
    this.approvedDataShow = false;
    this.susendedDataShow = false;
    this.blockDataShow = false;
    this.rejectDataShow = true;
    this.bindNgxDataGrid3(this.page, 1)

  }
  productArchivedList() {
    this.searchTerm = ''
    this.isLoader = false
    this.approvedDataShow = false;
    this.susendedDataShow = false;
    this.blockDataShow = false;
    this.rejectDataShow = false;
    this.archivedDataShow = true
    this.allProductList = []
    return false;
    this.bindNgxDataGrid4(this.page, 1)

  }
  onProductViewdetail(data) {
    // console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaa', data)
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    $('#scroll').animate({ scrollTop: 0 }, 'slow');

    this.isView = true;
    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal1").modal('show');
    });

  }
  onProductView(data) {
    this.adminRemarks = ''
    localStorage.setItem("view-item", data);
    this.productId = data.rowData.product_id;
    this.isView = true;
    $("#myModal").modal('show');
    this.action = ''
    this.qcstatus = data.rowData.qcstatus

  }

  closeModal() {
    this.isView = false;
    $("#myModal").modal('hide');
  }


  updateStatusPopup(data, action) {
    this.qcstatus = ''
    this.adminRemarks = ''
    this.action = action
    this.productId = data.rowData.product_id
    $("#myModal").modal('show');
  }
  onBlock() {
    // if (this.adminRemarks == '' || this.adminRemarks == null || this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
    //   this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false
    // }
    let productStatus = 185;
    $("#myModal").modal('hide');

    Swal.fire({
      title: `Do you want to block ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.productStatusupdate(this.productId, productStatus, this.adminRemarks, this.userId, '', '', '','').subscribe(res => {
          Swal.fire('Blocked!', '', 'success')
          this.getProductList();
          this.bindNgxDataGrid(this.page, 1)

        })
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
        this.adminRemarks = ''
      }
    })


  }
  onSuspended() {
    // if (this.adminRemarks == '' || this.adminRemarks == null || this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
    //   this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false
    // }
    let productStatus = 186;
    $("#myModal").modal('hide');

    Swal.fire({
      title: `Do you want to Suspend ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.productStatusupdate(this.productId, productStatus, this.adminRemarks, this.userId, '', '', '').subscribe(res => {
          this.getProductList();
          this.bindNgxDataGrid(this.page, 1)
          Swal.fire('Suspended!', '', 'success')

        })
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
        this.adminRemarks = ''
      }
    })

  }
  onActive() {
    // if (this.adminRemarks == '' || this.adminRemarks == null || this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
    //   this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false
    // }
    let productStatus = 173;


    Swal.fire({
      title: `Do you want to Re-active ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.productStatusupdate(this.productId, productStatus, this.adminRemarks, this.userId, '', '', '').subscribe(res => {
          $("#myModal").modal('hide');
          this.getProductList();
          this.bindNgxDataGrid1(this.page, 1)
          Swal.fire('Now Active!', '', 'success')

        })
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
        this.adminRemarks = ''
      }
    })

  }
  unBlock() {
    // if (this.adminRemarks == '' || this.adminRemarks == null || this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
    //   this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false
    // }
    let productStatus = 173;
    Swal.fire({
      title: `Do you want to Unblock ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.productStatusupdate(this.productId, productStatus, this.adminRemarks, this.userId, '', '', '').subscribe(res => {
          Swal.fire('Saved!', '', 'success')
          $("#myModal").modal('hide');
          this.getProductList();
          this.bindNgxDataGrid2(this.page, 1)

        })
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
        this.adminRemarks = ''
      }
    })

  }

  getMinDate() {

    let tommorow = new Date().getDate()
    let cur = new Date(new Date().getFullYear(), new Date().getMonth(), tommorow)
    return new Date(cur.getFullYear(), cur.getMonth(), cur.getDate() + 1).toISOString().split('T')[0];
  }

  onPriceEdit(row) {
    // console.log("row=====>>>>",row)
    this.current_price = row.rowData.price;
    this.current_mrp = row.rowData.mrp;
    this.prod_id_for_price = row.rowData.product_id;
    $("#listingprice").modal('show');
  }

  closeEditpopup() {
    this.current_price = null;
    this.current_mrp = null;
    this.prod_id_for_price = null;
    this.updated_price = null;
    this.updated_mrp = null;
    $("#listingprice").modal('hide');
  }

  updateProductPrice() {
    if (this.updated_price == null || this.updated_price == undefined || this.updated_price == '') {
      this.toastrService.warning("Please Enter Updated Price!", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false
    }
    if (this.updated_mrp == null || this.updated_mrp == undefined || this.updated_mrp == '') {
      this.toastrService.warning("Please Enter Updated MRP!", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false
    }
    if (Number(this.updated_price) > Number(this.updated_mrp)) {
      this.toastrService.warning("Selling price must be less then or equal to MRP", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false
    }
    this.productService.updateProductPrice(localStorage.getItem("userId"), this.prod_id_for_price, this.updated_price, this.updated_mrp).subscribe(res => {
      if (res.status == 200) {
        this.toastrService.success(res.message, '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");
        this.current_price = null;
        this.current_mrp = null;
        this.prod_id_for_price = null;
        this.updated_price = null;
        this.updated_mrp = null;
        $("#listingprice").modal('hide');
        this.approvedDataShow ? this.ProductApprovedList() : this.archivedDataShow ? this.productArchivedList() : this.blockDataShow ? this.productBlockList() : this.susendedDataShow ? this.productSuspendedList() : this.productRejectedList()
      } else {
        this.toastrService.error('Price Updation Faild', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        $(".overlay-container").addClass("addcontainerdisable");
      }
    })
  }

  reset() {
    this.adminRemarks = '';
    $("#myModal").modal('hide');
  }
  // onUnblock() {
  //   this.productService.productStatus(this.QC_id, 174, this.adminRemarks).subscribe(res => {
  //     this.toastrService.success(res.msg, '', { timeOut: 1000, positionClass: "toast-top-center" });
  //     $("#myModal").modal('hide');
  //     this.bindNgxDataGrid(this.page, 1)

  //   })
  // }
  // callBack(product_id) {
  //   this.productService.callBack(product_id).subscribe(res => {
  //     this.toastrService.success(res.msg, '', { timeOut: 1000, positionClass: "toast-top-center" });
  //     this.getAllProducts();
  //     setTimeout(() => {
  //       this.getList(171, "draft");
  //     }, 100)
  //   })
  // }
  onProductdetailView(data) {
    // console.log('vvvvvvvvvvvvvvvvvvvvvv', data)
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    if (data.rowData.product_type != 199)
      sessionStorage.removeItem('productType')
    else
      sessionStorage.setItem('productType', data.rowData.product_type)
    $('#scroll').animate({ scrollTop: 0 }, 'slow');
    this.isView = true;
    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal").modal('show');
      //this.toastrService.success("Product Approved", '', { timeOut: 1000, positionClass: "toast-top-center" 
    });
  }

  showSellerDetails(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem('approve_reject', 'false')
    this.router.navigateByUrl('/seller-profile' + '?' + localStorage.getItem('tempToken'));

  }

  getProductTypes() {
    this.productService.getProductTypes().subscribe(res => {
      this.productsTypeList = res ? res : [];
      // this.productsTypeList.push({
      //   id: 209,
      //   varibale_name: 'Business card'
      // })
    })
  }

  onProductEdit(obj) {
    // console.log('check for edit product', obj)
    localStorage.setItem('breadcrumb', 'true');
    localStorage.setItem('editinsellerlist', 'true');
    if (obj.rowData.product_type == 197) {             // 197	"Simple"
      localStorage.setItem("editable-item", obj.rowData.product_id);
      localStorage.setItem('selected_seller_id', obj.rowData.seller_id);
      this.router.navigateByUrl('/upload-catalog' + '?' + localStorage.getItem('tempToken'))
    }
    else if (obj.rowData.product_type == 198) {           // 198	"Configurable"
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/configurable-product?' + encriptUrl);
    }
    else if (obj.rowData.product_type == 199) {          // 199	"Grouped"
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/group-product?' + encriptUrl);
    }
    else if (obj.rowData.product_type == 208) {          // 208	"customizable"
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/customizable-product?' + encriptUrl);
    }
    else if (obj.rowData.product_type == 209) {          // Business card
      let data = JSON.stringify({ "editable_item": obj.rowData.product_id });
      let encriptUrl = this.loginService.encriptDataUrl(data)
      this.router.navigateByUrl('/add-business-card?' + encriptUrl);
    }
  }

  addNew() {

    if (this.product_type_id == 197) {
      this.router.navigateByUrl('/upload-catalog' + '?' + localStorage.getItem('tempToken'))
    }
    if (this.product_type_id == 198) {
      this.router.navigateByUrl('/configurable-product')
    }
    if (this.product_type_id == 199) {
      this.router.navigateByUrl('/group-product' + '?' + localStorage.getItem('tempToken'))
    }
    if (this.product_type_id == 200) {
      // this.router.navigateByUrl('/upload-catalog' + '?' + localStorage.getItem('tempToken'))
    }
    if (this.product_type_id == 208) {
      this.router.navigateByUrl('/customizable-product' )
      // '?' + localStorage.getItem('tempToken'
    }
    if (this.product_type_id == 209) {
      this.router.navigateByUrl('/add-business-card' + '?' + localStorage.getItem('tempToken'))
    }
  }
  onSelectCategory() {
    if (this.selectedCategory != 'undefined') {
      this.allProductList = this.TempallProductList.filter(x => x.category == this.selectedCategory);
      //console.log("category", this.allProductList)
    } else {
      this.allProductList = this.TempallProductList
    }
  }
  filterdatewise() {
    //console.log(this.fromDate)
    if (this.fromDate == null || this.fromDate == undefined || this.fromDate == '') {
      this.toastrService.warning('Please select from date', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass()
      return false;
    }
    if (this.todate == null || this.todate == undefined || this.todate == '') {
      this.toastrService.warning('Please select To date', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass()
      return false;
    }
    if (this.fromDate > this.todate) {
      this.toastrService.warning('End Date should be greater than From Date', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass()
      return false;
    }
    this.allProductList = this.TempallProductList.filter(x => x.cdate >= this.fromDate && x.cdate <= this.todate)

  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
