<!-- <div class="d-flex minhyt80" id="wrapper" >	
     <div class="" id="sidebar-wrapper">
        <app-left-menu></app-left-menu>
    </div> 
    <div class="" id="page-content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div> -->
<!-- <div  id="settingbar">
    <app-customize-setting></app-customize-setting>
</div> -->
<router-outlet></router-outlet>
<app-footer></app-footer>