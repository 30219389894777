import { Component, OnInit,OnDestroy } from '@angular/core';
import * as xlsx from 'xlsx';
import { Router } from '@angular/router'; 
import {FormService} from '../_services/form.service';
import { SectionConfig } from "../field.interface";
import { ButtonRendererComponent } from '../button-renderer/button-renderer.component';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-grid-test-new',
  templateUrl: './grid-test-new.component.html',
  styleUrls: ['./grid-test-new.component.css'],
  //encapsulation: ViewEncapsulation.None
})

export class GridTestNewComponent implements OnInit,OnDestroy {
  
  subscription: Subscription;
  frameworkComponents: any;
  formId:any=0;
  processid:any;
  stageid:any;
  form_viewid:any=0;
  formName:any;
  
  tableName:any;
  tablePrimaryKey : any
  SectionConfig:SectionConfig[]=[];  
  formDisplayJSON:any;
  nodeJson:any;
  connJson:any;
  
  filtersLoaded: Promise<boolean>;
  FormViewLoaded:Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  ViewLoaded: Promise<boolean>;
  EditLoaded: Promise<boolean>;
  DataRefreshLoaded: Promise<boolean>;
  isAddMode:boolean=true;
  is_attachment: boolean=false;
  ErrorOccurred:String="Error occurred!!";
  SuccessMasg:any;
  ViewTableQry:any;
  editValueRow:any;
  editrowid:any;
  viewFilterString:any;
  filterCriteria:any;
  refKey:any=0;

  ddlarrGidView:any=[];
  dateArrGidView:any=[];
  
  childFormList: any[]=[];
  metaDataList: any[]=[];
  attachmentType: any[]=[];

  metadata: any;

  pagesize="10";
  columnDefs:any[]=[];
  rowData:any[]=[];

  isAdd:boolean=false;
  isEdit:boolean=false;
  isView:boolean= false;
  isExpotable:boolean=false;
  isResetFilter:Boolean=false;
  edit_option:number=1;
  add_edit: Promise<boolean>;

  constructor(
    private router: Router,
    private formService : FormService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
   }
 
  

  ngOnInit(): void {
    $("#sidebar-wrapper").removeClass( 'nodisplay');
    $("#logoemblm").addClass('nodisplay');
    
    this.isAddMode=true;
    let uri=this.router.url.split("?");
    if(uri.length>1){
      var str = uri[1];
      var value=str.split("&");
      if(value[0].indexOf('frmid')>=0){
        this.formId=value[0]?value[0].split('=')[1]:null;
      }
      if(value[0].indexOf('vid')>=0){
        this.form_viewid=value[0]?value[0].split('=')[1]:null;
      }
      if(value[1].indexOf('frmid')>=0){
        this.formId=value[1]?value[1].split('=')[1]:null;
      }
      if(value[1].indexOf('vid')>=0){
        this.form_viewid=value[1]?value[1].split('=')[1]:null;
      }
      //console.log("grid===>",str,this.formId,this.form_viewid)
    }
    this.getFormDetails(0); 
  }
  getFormDetails(val){
    this.subscription=this.formService.getFormData("select form_id,form_name,table_name,primary_key,form_display_json,value_field,processid from app_forms where form_id="+this.formId).subscribe(res => {
      let formList=res.rows;
      if(formList.length>0){
        this.formName=formList[0]["form_name"];
        this.formId = formList[0]["form_id"];
        this.processid=formList[0]["processid"];
        this.tableName= formList[0]["table_name"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase(); 
        this.formDisplayJSON= formList[0]["form_display_json"]?formList[0]["form_display_json"]:[];
        if(this.formDisplayJSON.length>0){
          if(this.processid >0){
            this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
            this.connJson=JSON.parse(this.formDisplayJSON).connJson;
            //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
            //now getting initial stageid
            var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
            this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
            // control field hide/show based on process stages...
            this.SectionConfig.forEach(arrField => {
              arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(intStageid)>=0 || field.visible_stage.toString() =="");
            });
            // control Section hide/show based on process stages...
            this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined ||d.visible_stage.indexOf(intStageid)>=0 || d.visible_stage.toString() =="")));
            this.stageid=intStageid;
            //console.log("this.SectionConfig==>",intStageid, this.SectionConfig)           
          }else{
            this.SectionConfig=JSON.parse(this.formDisplayJSON)
          }
           //console.log("this.SectionConfig==>",this.SectionConfig)  
          // this.SectionConfig.forEach(arrField => {
          //   arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="add");
          // });

        }
      }
      this.getFormViewDetails();
    });
  }
  
  getFormViewDetails(){
    this.columnDefs=[];
    let query="select fv.is_add,fv.is_edit,fv.is_view,fv.is_exportable,fv.child_form_ids, fv.view_id,fv.field_json,fv.is_attachment,fv.meta_data,fv.attachment_type,f.primary_key,fv.edit_option  from app_form_view_details fv left join app_forms f on fv.form_id=f.form_id  where  fv.view_id="+this.form_viewid;
    this.subscription=this.formService.getFormData(query).subscribe(res => {
      let formViewList=res.rows;
      let lookupArr=[],a=0;
      if(formViewList.length>0){
        this.isAdd =formViewList[0]["is_add"]?formViewList[0]["is_add"]:false;
        this.isEdit =formViewList[0]["is_edit"]?formViewList[0]["is_edit"]:false;
        this.isView =formViewList[0]["is_view"]?formViewList[0]["is_view"]:false;
        this.isExpotable =formViewList[0]["is_exportable"]?formViewList[0]["is_exportable"]:false;
        let fromViewjson=formViewList[0]["field_json"]?formViewList[0]["field_json"]:[];
        let childFormId=formViewList[0]["child_form_ids"]?formViewList[0]["child_form_ids"]:"";
        this.edit_option=formViewList[0]["edit_option"];
        this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;
        //console.log("prawej ==>",this.is_attachment,this.attachmentType,this.metaDataList)
        if(childFormId != "" && childFormId != undefined && childFormId != null){
          this.subscription=this.formService.getFormData(" select form_id,form_name,table_name,primary_key,form_display_json,value_field from app_forms where form_id in("+childFormId.split(",")+")").subscribe(childRes => {
            this.childFormList=childRes.rows;
          });
        }
        if(fromViewjson.length>0){
          let selectedFieldList=JSON.parse(fromViewjson)
          var column='';
          //console.log("fromViewjson===>",fromViewjson);
          selectedFieldList.forEach(fieldprop => {
            if(fieldprop.control_type == 5 || fieldprop.control_type == 10 || fieldprop.control_type == 19 ){
              this.ddlarrGidView.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3"})
              lookupArr.push({"index":a,"columnname":fieldprop.field_name,"dynamiclist":JSON.parse(fieldprop.dynamiclist),"type":"3","control_type":fieldprop.control_type})
            }            
            else if(fieldprop.control_type == 7 || fieldprop.control_type == 8){
              this.dateArrGidView.push({"index":a,"columnname":fieldprop.field_name,"type":"1"})
              lookupArr.push({"index":a,"columnname":fieldprop.field_name,"type":"4","control_type":fieldprop.control_type})
            }
            else if(fieldprop.control_type == 14 || fieldprop.control_type == 15){
              let displayField=JSON.parse(fieldprop.display_field)
              lookupArr.push({"index":a,"columnname":fieldprop.field_name,
              "reference_form_id":fieldprop.reference_form_id,"value_field":fieldprop.value_field,
              "display_field":displayField[0]["id"],"field_name":fieldprop.field_name, "type":"2","control_type":fieldprop.control_type})
            }else{
              lookupArr.push({"index":a,"columnname":fieldprop.field_name, "type":"1","control_type":fieldprop.control_type})
            }
            a++;
            this.columnDefs.push({field:fieldprop.field_name.toString().toLowerCase(),headerName:fieldprop.field_caption,sortable:fieldprop.sortable,filter:fieldprop.filterable ,resizable:fieldprop.resizable,width:fieldprop.column_width, wrapText: true,autoHeight: true});
          });
          this.columnDefs.push({
            headerName: 'Action',
            pinned: 'right',
            field:"val",
            cellRenderer: 'buttonRenderer',
            cellRendererParams:{
              actionList:[
                {label:"view",for:"view",status:this.isView,form_status:1,db_status:1,str:"view",onClick:this.onClickView.bind(this)},
                {label:"Edit",for:"view",status:this.isEdit,form_status:1,db_status:1,str:"edit",onClick:this.onEdit.bind(this)},
              ]},
            width:120,
            resizable:true
          });
          if(lookupArr.length>0){
            let query="";
            for(var z=0; z<lookupArr.length;z++){
              if(lookupArr[z]["type"]=="1" || lookupArr[z]["type"]=="3"){
                column=column+"a."+lookupArr[z]["columnname"]+","  
              }else if(lookupArr[z]["type"]=="4"){
                //TO_CHAR(w.sendtime:: DATE, 'mm/dd/yyyy') sendtime
                column=column+"TO_CHAR(a."+lookupArr[z]["columnname"]+":: DATE, 'mm/dd/yyyy') as "+lookupArr[z]["columnname"]+"," 
              }
              else{
                //console.log("lookupArr==>",query.indexOf(lookupArr[z]["reference_form_id"]))
                column=column+lookupArr[z]["reference_form_id"]+"."+lookupArr[z]["display_field"] +" " +lookupArr[z]["columnname"]+","
                //console.log("query==>",query,lookupArr[z]["reference_form_id"]);
                if(query.indexOf(lookupArr[z]["reference_form_id"])==-1){
                  query =query + " left join "+lookupArr[z]["reference_form_id"] +" "+lookupArr[z]["reference_form_id"]+" on  a."+lookupArr[z]["field_name"]+" = "+lookupArr[z]["reference_form_id"]+"."+lookupArr[z]["value_field"]
                }
              }
            }
            column=column +" a."+formViewList[0]["primary_key"]+",'[1,1]' as val";
            this.ViewTableQry=`select ${column} from ${this.tableName} a `
            this.ViewTableQry=this.ViewTableQry+" "+query
          }
          else{
            column=column+formViewList[0]["primary_key"]+",'[1,1]' as val";
            this.ViewTableQry=`select ${column} from ${this.tableName} a `
          }
          if(this.filterCriteria!=null && this.filterCriteria!="" && this.filterCriteria!=undefined && this.filterCriteria!="null" && this.filterCriteria!="undefined")
          {
            if(this.ViewTableQry.includes(";"))
            {
              this.ViewTableQry=this.ViewTableQry.replace(/;/g,"");             
            }
            if(this.ViewTableQry.includes("where"))
            {
              this.ViewTableQry=this.ViewTableQry+" and "+this.filterCriteria; 
            }
            else
            {
              this.ViewTableQry=this.ViewTableQry+" where "+this.filterCriteria;
            }
          }
          //console.log("ViewTableQry===>",this.ViewTableQry);
          this.subscription=this.formService.getFormData(this.ViewTableQry).subscribe(res => {
            this.rowData=res.rows;
            //  console.log(JSON.stringify(this.rowData))
            for(var c=0; c<this.rowData.length; c++){
              let firstColumnname = this.columnDefs[0]["field"];
              let rowid =this.rowData[c][firstColumnname];
              for(a=0;a<this.ddlarrGidView.length; a++){
                let columnValue= this.rowData[c][this.ddlarrGidView[a]["columnname"]];
                let jsonList=this.ddlarrGidView[a]["dynamiclist"]?this.ddlarrGidView[a]["dynamiclist"]:[];
                if(jsonList.length>0){
                  let valArr= jsonList.filter(d=>d.text == columnValue); 
                  let val="";
                  if(valArr.length >0){
                    val=valArr[0]["binding"];
                  }  
                  let arr= this.rowData.filter(d=> d[firstColumnname] == rowid);
                  if(arr.length>0){
                    this.rowData.filter(d=> d[firstColumnname] == rowid)[0][this.ddlarrGidView[a]["columnname"]]=val;
                  }             
                }
              }   
              // for date Format
              // for(var x=0; x<this.dateArrGidView.length; x++){
              //   let columnValue= this.rowData[c][this.dateArrGidView[x]["columnname"]];
              //   if(columnValue != "" && columnValue != undefined && columnValue != null){
              //     columnValue=columnValue.substring(0,10)
              //     let arrDate=columnValue.split("-")
              //     let dateString=arrDate[1]+"/"+arrDate[2]+"-"+arrDate[0];
              //     this.rowData[c][this.dateArrGidView[x]["columnname"]]=dateString;                    
              //   }                  
              // }
            }
            this.rowData=this.rowData.sort((a, b) => a[this.tablePrimaryKey] > b[this.tablePrimaryKey] ? 1 : -1)
            $("#clos").click();
          },err=>{
            $("#clos").click();
          });
          
          this.DataRefreshLoaded = Promise.resolve(true);
          //this.FormViewLoaded = Promise.resolve(true);
        }
      }
    });
  }
  // setStatus(){
  //   this.isAddMode =true;
  // }
  onAddClick(){
    //console.log("this.SectionConfig==>Add Click") 
    this.add_edit=Promise.resolve(false);
    this.SectionConfig=null;
    this.isAddMode=true;
    this.FormViewLoaded=Promise.resolve(false);
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
        //now getting initial stageid
        var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control field hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(intStageid)>=0 || field.visible_stage.toString() =="");
        });
        // control Section hide/show based on process stages...
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined ||d.visible_stage.indexOf(intStageid)>=0 || d.visible_stage.toString() =="")));
        this.stageid=intStageid;
        //console.log("this.SectionConfig==>",intStageid, this.SectionConfig)           
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
      }
       //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="add");
      });

    }
    this.FormViewLoaded=Promise.resolve(true);
    setTimeout(() => {$("#exampleModal").modal('show');}, 100);  
    
  }
  onClickView(e)
  {
    var strQry='select * from '+this.tableName+' where '+this.tablePrimaryKey+'= '+e.rowData[this.tablePrimaryKey]+';';
    this.subscription=this.formService.getFormData(strQry).subscribe(res => {
      let formAllField=JSON.parse(this.formDisplayJSON);
      for(var r=0; r<formAllField.length; r++){ 
        var regConfig=formAllField[r]["FieldConfig"]; 
        for(var i=0; i<regConfig.length; i++){
          regConfig[i].value=res.rows[0][regConfig[i].name];
          regConfig[i].type='fieldview';
        } 
        formAllField[r].FieldConfig=regConfig;
      }
      this.ViewLoaded=Promise.resolve(true);
      $("#viewModal").modal('show');
    });
  }

  onEdit(e){
    this.add_edit=Promise.resolve(false);
    this.EditLoaded=Promise.resolve(false);
    this.SectionConfig=null;
    if(this.formDisplayJSON.length>0){
      if(this.processid >0){
        this.nodeJson=JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson=JSON.parse(this.formDisplayJSON).connJson;
        //console.log("nodeJson==>",this.nodeJson.filter(d=>d.type =='initial'))
        //now getting initial stageid
        var intStageid=this.nodeJson.filter(d=>d.type=='initial')[0]["id"];
        this.SectionConfig=JSON.parse(this.formDisplayJSON).sectionJson;
        // control field hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(intStageid)>=0 || field.visible_stage.toString() =="");
        });
        // control Section hide/show based on process stages...
        this.SectionConfig=this.SectionConfig.filter(d=>d.FieldConfig?.length>0 || (d.section_dependent==true && (d.visible_stage ==undefined ||d.visible_stage.indexOf(intStageid)>=0 || d.visible_stage.toString() =="")));
        this.stageid=intStageid;
        //console.log("this.SectionConfig==>",intStageid, this.SectionConfig)           
      }else{
        this.SectionConfig=JSON.parse(this.formDisplayJSON)
      }
       //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field?.visible=="always" || field?.visible=="edit");
      });

      //console.log("this.SectionConfig==>",this.SectionConfig)  

      this.isAddMode=false;
      this.editrowid=e.rowData[this.tablePrimaryKey];
      this.refKey=e.rowData[this.tablePrimaryKey];
      var strQry='select * from '+this.tableName+' where '+this.tablePrimaryKey+'= '+e.rowData[this.tablePrimaryKey]+';';
      // console.log(strQry,"in View");
      this.subscription=this.formService.getFormData(strQry).subscribe(res => {
        // console.log("rowData==",res.rows[0]);
        this.editValueRow=JSON.parse(JSON.stringify(res.rows[0]));
        //console.log("this.formAllField===>",this.editValueRow);
        this.EditLoaded=Promise.resolve(true);
        setTimeout(() => {$("#editModal").modal('show');}, 100);   
        
        if(this.edit_option == 3){
            if(this.childFormList.length>0){        
              let formid=this.childFormList[0]["form_id"];
              let tabid="#tabchildedit"+formid;
              setTimeout(() => {$(tabid).click();}, 100);        
            }
        }
      });
    }
    // filtersLoaded
    //@Input() isAddMode: boolean;// Added by PKS
    //@Input() editValueRow:any;// Added by PKS
    

  }
  refreshTable(value:any,keyvalue:any,add_edit:any)
  {
    this.subscription=this.formService.getFormData(this.ViewTableQry +" where "+this.tablePrimaryKey +" = "+keyvalue).subscribe(res => {
      let tempRowData=res.rows;
      console.log(JSON.stringify(res.rows),add_edit)
      for(var c=0; c<tempRowData.length; c++){
        let firstColumnname = this.columnDefs[0]["field"];
        let rowid =tempRowData[c][firstColumnname];
        for(let a=0;a<this.ddlarrGidView.length; a++){
          let columnValue= tempRowData[c][this.ddlarrGidView[a]["columnname"]];
          let jsonList=this.ddlarrGidView[a]["dynamiclist"]?this.ddlarrGidView[a]["dynamiclist"]:[];
          if(jsonList.length>0){
            let valArr= jsonList.filter(d=>d.text == columnValue); 
            let val="";
            if(valArr.length >0){
              val=valArr[0]["binding"];
            }                            
            tempRowData.filter(d=> d[firstColumnname] == rowid)[0][this.ddlarrGidView[a]["columnname"]]=val;                
          }
        }   
        // for date Format
        // for(var x=0; x<this.dateArrGidView.length; x++){
        //   let columnValue= tempRowData[c][this.dateArrGidView[x]["columnname"]];
        //   if(columnValue != "" && columnValue != undefined && columnValue != null){
        //     columnValue=columnValue.substring(0,10)
        //     let arrDate=columnValue.split("-")
        //     let dateString=arrDate[2]+"-"+arrDate[1]+"-"+arrDate[0];
        //     tempRowData[c][this.dateArrGidView[x]["columnname"]]=dateString;                    
        //   }                  
        // }
        if(add_edit ==1){
          this.rowData.push(tempRowData[c]);
        }else if(add_edit ==2){
          //console.log("asdasjh Not working pks",this.EditLoaded)

          // Not working pks
          //this.rowData.filter(d=> d[this.tablePrimaryKey] == keyvalue)[0]=tempRowData[c]; 
          var i=this.rowData.length;
          while (i--) {
            if(keyvalue.toString().indexOf(this.rowData[i][this.tablePrimaryKey])!=-1){
              this.rowData.splice(i,1);
            }
          }
          this.rowData.push(tempRowData[c]); 
        }
        
      }
      this.rowData=this.rowData.sort((a, b) => a[this.tablePrimaryKey] < b[this.tablePrimaryKey] ? 1 : -1)
      this.DataRefreshLoaded = Promise.resolve(false);
      this.DataRefreshLoaded = Promise.resolve(true);
      
      $("#clos").click();
    },err=>{
      $("#clos").click();
    })
    
    setTimeout(() => this.SuccessMasgLoaded = Promise.resolve(false),3000);
  }

 
  
  

  getControlType(field_binding){    
    let SectionConfig;
    SectionConfig=this.SectionConfig;
    var typeVal="";    
    for(var r=0; r<SectionConfig.length; r++){ 
      var regConfig=SectionConfig[r]["FieldConfig"]; 
      let arrSection= regConfig.filter(d => d.name == field_binding);
      if(arrSection.length>0){
        typeVal=arrSection[0]["type"];
      }
    }
    return typeVal;
  }

  submit(value:any) {
     console.log("pradeep this.isAddMode===>",JSON.stringify(value),this.isAddMode);
    // file receved here... with record
    const parsedJSON = value;// JSON.parse(value);
    if( this.isAddMode){
      const parsedJSON = value;// JSON.parse(value);
      let queryList=[];
      let dtlQueryList=[];
      Object.entries(parsedJSON).forEach(item => {
        if(item[0].toString() !=="actiontaken" && item[0].toString() !== "sendto"  && item[0].toString() !=="hidden_nxtstageid"  && item[0].toString() !=="textarea_remarks")
        {
          queryList.push({"columnName":item[0], "columnValue":item[1]})
        }             
        
      })
      //console.log("prawej",queryList)
      let strColumn="",strValue="";
      for(var c=0; c<queryList.length; c++){
        let secRepeat=this.SectionConfig.filter(d=>d.binding_name==queryList[c]["columnName"] && d.repeatable_save_as.toLowerCase() =="table" );
        if(secRepeat.length>0)
        {
          for(var n=0;n<queryList[c]["columnValue"].length;n++){
            let queryListChild=[];
            let strDtlCloumn="",strDtlvalue="";
            Object.entries(queryList[c]["columnValue"][n]).forEach(item =>queryListChild.push({"columnName":item[0], "columnValue":item[1]}))
            for(var j=0; j<queryListChild.length; j++){
              strDtlCloumn=strDtlCloumn+queryListChild[j]["columnName"]+",";
              let strVal=null;
              if(queryListChild[j]["columnValue"] !=null){
                if(this.getControlType(queryListChild[j]["columnName"]) !=='number' && this.getControlType(queryListChild[j]["columnName"]) !=='datetime' && this.getControlType(queryListChild[j]["columnName"]) !=='decimal' && this.getControlType(queryListChild[j]["columnName"]) !=='date' && this.getControlType(queryListChild[j]["columnName"]) !=='checkbox' && this.getControlType(queryListChild[j]["columnName"]) !=='radiobutton' && this.getControlType(queryListChild[j]["columnName"]) !=='lookup' && this.getControlType(queryListChild[j]["columnName"]) !=='dependentlookup' && this.getControlType(queryListChild[j]["columnName"]) !=='autocomplete'){
                  strVal="'"+queryListChild[j]["columnValue"]+"'";
                }else if(this.getControlType(queryListChild[j]["columnName"]) =='checkbox'){
                  strVal=(queryListChild[j]["columnValue"]=='true'||queryListChild[j]["columnValue"]==true)?true:false;
                }else if(this.getControlType(queryListChild[j]["columnName"]) =='autocomplete'){
                  strVal="'"+queryListChild[j]["columnValue"]?queryListChild[j]["columnValue"]["text"]:''+"'";
                }else if(this.getControlType(queryListChild[j]["columnName"]) =='date' || this.getControlType(queryListChild[j]["columnName"]) =='datetime'){
                  strVal=queryListChild[j]["columnValue"]?"'"+queryListChild[j]["columnValue"]+"'":null;
                }
                else{
                  strVal=(queryListChild[j]["columnValue"] !=="" && queryListChild[j]["columnValue"] !=="0") ?queryListChild[j]["columnValue"]:null;
                }
              }
              strDtlvalue=strDtlvalue+strVal+",";
            }
            strDtlCloumn=strDtlCloumn+this.tablePrimaryKey;
            let strInsertDtl="insert into "+this.tableName.substring(0,this.tableName.length-3)+queryList[c]["columnName"]+"("+strDtlCloumn+") ";
            dtlQueryList.push({"instQuery":strInsertDtl, "InstValue":strDtlvalue});
          }
        }else{
          let secRepeat=this.SectionConfig.filter(d=>d.binding_name==queryList[c]["columnName"] && d.repeatable_save_as.toLowerCase() =="json" );
          strColumn=strColumn+queryList[c]["columnName"]+",";
          let chkVal=queryList[c]["columnValue"];
          let strVal=null;
          if(chkVal != null){
            if(this.getControlType(queryList[c]["columnName"]) !=='' && this.getControlType(queryList[c]["columnName"]) !=='number' && this.getControlType(queryList[c]["columnName"]) !=='datetime' && this.getControlType(queryList[c]["columnName"]) !=='decimal' && this.getControlType(queryList[c]["columnName"]) !=='date' &&  this.getControlType(queryList[c]["columnName"]) !=='checkbox' && this.getControlType(queryList[c]["columnName"]) !=='radiobutton' && this.getControlType(queryList[c]["columnName"]) !=='lookup' && this.getControlType(queryList[c]["columnName"]) !=='dependentlookup' && this.getControlType(queryList[c]["columnName"]) !=='autocomplete'){
              strVal="'"+queryList[c]["columnValue"]+"'";
            }else if(this.getControlType(queryList[c]["columnName"]) =='checkbox'){
              strVal=(queryList[c]["columnValue"]==true||queryList[c]["columnValue"]=='true')?true:false;
            }else if(this.getControlType(queryList[c]["columnName"]) =='autocomplete'){
              strVal="'"+queryList[c]["columnValue"]?queryList[c]["columnValue"]["text"]:''+"'";
            }else if(this.getControlType(queryList[c]["columnName"]) =='date'|| this.getControlType(queryList[c]["columnName"]) =='datetime'){
              strVal=queryList[c]["columnValue"]?"'"+queryList[c]["columnValue"]+"'":null;
            }
            else{
              if(secRepeat.length >0){
                strVal=(queryList[c]["columnValue"] !=="" && queryList[c]["columnValue"] !=="0") ?"'"+JSON.stringify(queryList[c]["columnValue"])+"'":null;
              }else{
                strVal=(queryList[c]["columnValue"] !=="" && queryList[c]["columnValue"] !=="0") ?queryList[c]["columnValue"]:null;
              }
            }
          }
          strValue=strValue+strVal+",";
        }
      }
      strColumn=strColumn.substring(0,strColumn.length-1);
      strValue=strValue.substring(0,strValue.length-1);
      let query="insert into "+this.tableName+ "("+strColumn+") values ("+strValue+") RETURNING "+this.tablePrimaryKey + ";"
      //console.log("query==>",query)      
      this.subscription=this.formService.getFormData(query).subscribe(res => {
        if(res.status==500){
          //alert("Due to some Issue Form View not saved successfully");  
          this.ErrorMasgLoaded = Promise.resolve(true); 
        }
        else
        {
          this.refKey=res.rows[0][this.tablePrimaryKey];
          this.sendEmail(this.tableName,this.tablePrimaryKey,this.formId,this.refKey,'create')
          if(dtlQueryList.length >0){
            let dtlQuery="";
            for(var x=0;x<dtlQueryList.length;x++){
              let dtlValue=dtlQueryList[x]["InstValue"] +this.refKey
              dtlQuery=dtlQuery+ dtlQueryList[x]["instQuery"] +" values ("+dtlValue+") ;";
            }
            //console.log("dtlQuery==>",dtlQuery);
            this.subscription=this.formService.getFormData(dtlQuery).subscribe(res => {
              if(res.status==500)
              {
                let strDelete="delete from "+this.tableName +" where "+ this.tablePrimaryKey +" = "+  this.refKey;
                this.formService.getFormData(strDelete);
                this.ErrorMasgLoaded = Promise.resolve(true); 
              }else{
                this.isAddMode=false;
                this.SuccessMasg="Save successfully !!";
                //alert('Save successfully !!');
                this.SuccessMasgLoaded = Promise.resolve(true);
                //$('#exampleModal').modal('hide');
                this.editrowid=this.refKey;
                this.refreshTable(parsedJSON,this.refKey,1);
              }
            });
          }else{
            this.isAddMode=false;
            this.SuccessMasg="Save successfully !!";
            //alert('Save successfully !!');
            this.editrowid=this.refKey;
            this.SuccessMasgLoaded = Promise.resolve(true);
            //$('#exampleModal').modal('hide');
            this.refreshTable(parsedJSON,this.refKey,1);
          }
          
        }
      },err=>{
        this.ErrorMasgLoaded = Promise.resolve(true); 
        alert("Due to some Issue data not saved successfully");
      })
    }else if(!this.isAddMode){
      console.log("Update button click!!")
     // alert("Update button click!!");
      let queryList=[];
      Object.entries(parsedJSON).forEach(item => {
        if(item[0].toString() !=="actiontaken" && item[0].toString() !== "sendto"  && item[0].toString() !=="hidden_nxtstageid" && item[0].toString() !=="textarea_remarks")
        {
          queryList.push({"columnName":item[0], "columnValue":item[1]})
        }             
        
      })

      // Object.entries(parsedJSON).forEach(item => 
      //   queryList.push({"columnName":item[0], "columnValue":item[1]})
      // )
      //console.log("queryList==>",queryList);
      let strColumn="",strValue="";
      for(var c=0; c<queryList.length; c++){
        
        let chkVal=queryList[c]["columnValue"];
        let strVal=null;
        if(chkVal != null){
          if(this.getControlType(queryList[c]["columnName"]) =='checkbox' || this.getControlType(queryList[c]["columnName"]) =='number' || this.getControlType(queryList[c]["columnName"]) =='lookup'  || this.getControlType(queryList[c]["columnName"]) =='dependentlookup'){
            strVal=queryList[c]["columnValue"];
          }else if(this.getControlType(queryList[c]["columnName"]) == 'autocomplete'){
            strVal="'"+queryList[c]["columnValue"]?queryList[c]["columnValue"]["text"]:''+"'";
          }else{
            strVal="'"+queryList[c]["columnValue"]+"'";
          }
          if((this.getControlType(queryList[c]["columnName"]) =='checkbox'  || this.getControlType(queryList[c]["columnName"]) =='date'  || this.getControlType(queryList[c]["columnName"]) =='lookup'  || this.getControlType(queryList[c]["columnName"]) =='dependentlookup') && (chkVal.toString() =="" || chkVal.toString() =="0")){
           // strColumn=strColumn+queryList[c]["columnName"]+"="+strVal+",";
            //console.log("chkVal IF ==>",chkVal.toString())
          }else{
            strColumn=strColumn+queryList[c]["columnName"]+"="+strVal+",";
          }
          
        }
      }
      strColumn=strColumn.substring(0,strColumn.length-1);
      let query="update "+this.tableName+ " set "+strColumn+" where "+this.tablePrimaryKey+" = "+this.editrowid +";";
      console.log(query)
      this.subscription=this.formService.getFormData(query).subscribe(res => {
        if(res.status==500)
        {
          //alert("Due to some Issue Form View not saved successfully");  
          this.ErrorMasgLoaded = Promise.resolve(true); 
        }
        else
        {
          this.SuccessMasg="Update successfully !!";
          this.sendEmail(this.tableName,this.tablePrimaryKey,this.formId,this.editrowid,'update');          
          this.SuccessMasgLoaded = Promise.resolve(true);
          this.refreshTable(parsedJSON,this.refKey,2);
        }
      },err=>{
        this.ErrorMasgLoaded = Promise.resolve(true); 
        alert("Due to some Issue data not saved successfully");
      })
    }
    else{
      this.SuccessMasg="Data already saved successfully !!";
    }
  }
  getDetails(val){  
    this.viewFilterString=null;
    this.filterCriteria=null;
    this.isResetFilter=true;
    this.formId=val;
    // if(this.selectedRowId != 0){
    //   this.SectionConfig=[];
    //   this.getFormDetails(1);
    //   this.filtersLoaded = Promise.resolve(false);
    //   $("li").removeClass("is-active")
    //   $("#"+val).toggleClass("is-active");
      
    // }else{
    //   alert("please select "+ this.tempformName)
    //   return false;
    // }
  }

  exportToExcel()
  {
    var downloadList=[];
    if(this.rowData.length>0)
    {
      for(let robj of this.rowData)
      {
        var obj={};
        for(let cobj of this.columnDefs)
        {
          obj[cobj.field]=robj[cobj.field];
           //jsob=jsob+'"'+obj.field+'":obj["'+obj.field+'"],';
        }
        downloadList.push(obj);
      }
      //console.log(downloadList)
       this.download(downloadList,"CSV Report")
    }
  }
 
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  download(data:any, filename:string){
    var csvData = this.ConvertToCSV(data);
    var a: any = document.createElement("a");
    a.setAttribute('style', 'display:none;border:1');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    
    var isIE = /*@cc_on!@*/false || !!(<any> document).documentMode;
    
    if (isIE)
    {   
        var retVal = navigator.msSaveBlob(blob, filename+'.csv');
    }
    else{
        a.download = filename+'.csv';
    }
    // If you will any error in a.download then dont worry about this. 
    a.click();
  }
  
  
  // convert Json to CSV data
  ConvertToCSV(objArray:any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";
    for (var index in objArray[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
          line += '"'+array[i][index]+'"';
      }
      str += line + '\r\n';
    }
    return str;
  }

  onPageSizeChanged() {
    //var value = document.getElementById('page-size').value;
    var value = $('#page-size').val();
    //console.log(value);
    this.pagesize=value;
    // if(value==10){
    //   this.pageHight="520px;width:100%;float: left;"
    // }else{
    //   this.pageHight="620px;width:100%;float: left;"
    // }
    this.refreshTable("",0,0);    
  }
  removeFilter()
  {
    this.viewFilterString=null;
    this.filterCriteria=null;
    this.isResetFilter=true;
    this.getFormViewDetails();
  }
  
  apply(criteriaStr: any) {
    if(criteriaStr!=null)
    {
      this.viewFilterString=criteriaStr["viewStr"];
      this.filterCriteria=criteriaStr["queryStr"];
      this.getFormViewDetails();
    }
  }
  onRowSelected(event){
    // if(this.formId == this.tempFormId){
    //   this.selectedRowId=(event.data[this.temptablePrimaryKey])
    // }
    // console.log(this.selectedRowId)
  }

  processsubmit(value: any){
    this.ErrorMasgLoaded = Promise.resolve(false); 
    console.log("process submit == >",value)
    let json=value["value"];
    let actionid=value["actionid"];
    let notesheet_containt=json["textarea_remarks"];
    let touserid=Number(json["sendto"]);
    if( notesheet_containt.toString().trim()!="" && touserid.toString().trim() !="0"){
      //console.log(actionid,notesheet_containt,json["actiontaken"],touserid)
      let processInstanceQry="insert into radx_processinstances(itemid,form_id,processid,current_stageid,userid,with_whom_userid)values("+this.editrowid+","+this.formId+","+this.processid+","+json["actiontaken"]+","+parseInt(localStorage.getItem("userId"))+","+touserid+") RETURNING processinstanceid";
      this.subscription=this.formService.getFormData(processInstanceQry).subscribe(res => {
        if(res.status==500){         
          this.ErrorMasgLoaded = Promise.resolve(true); 
        }else{
          let processinstanceid=res.rows[0]["processinstanceid"];
          let processNoteQry="insert into radx_process_note(notesheet_sr_no,notesheet_containt,createdby_userid,processid,processinstanceid,stageid)values(1,'"+notesheet_containt+"',"+parseInt(localStorage.getItem("userId"))+","+this.processid+","+processinstanceid+","+actionid+");";
          let processWorklistQry="insert into radx_worklist(processinstanceid,stageid,sourceid,destinationid,actiontaken)values("+processinstanceid+","+actionid+","+parseInt(localStorage.getItem("userId"))+","+touserid+",'N')";
          let finalQry=processNoteQry+processWorklistQry
          this.subscription=this.formService.getFormData(finalQry).subscribe(res => {
          });

          this.SuccessMasg="Submitted successfully !!";        
          this.SuccessMasgLoaded = Promise.resolve(true);
        }
      // console.log(processInstanceQry)
      })
    }else{
      this.ErrorMasgLoaded = Promise.resolve(true); 
      if( notesheet_containt.toString().trim()==""){
        this.ErrorOccurred="Remarks is required";   
      }else{
        this.ErrorOccurred="Send to is required";   
      }
        
    }
  }

  sendEmail(tableName,tablePrimaryKey,formId,rowid,actionStatus){
    this.formService.sendMail(tableName,tablePrimaryKey,formId,rowid,actionStatus).subscribe(res => {            
    })
  }
  ngOnDestroy() { 
    this.subscription.unsubscribe();
    
    
    this.formService=null;
    this.frameworkComponents=null
    this.formId=null
    this.processid=null
    this.stageid=null
    this.form_viewid=null
    this.formName=null
    this.tableName=null
    this.tablePrimaryKey=null
    this.SectionConfig=null
    this.formDisplayJSON=null
    this.nodeJson=null
    this.connJson=null
    this.filtersLoaded=null
    this.FormViewLoaded=null
    this.SuccessMasgLoaded=null
    this.ErrorMasgLoaded=null
    this.ViewLoaded=null
    this.EditLoaded=null
    this.DataRefreshLoaded=null
    this.isAddMode=null
    this.is_attachment=null
    this.SuccessMasg=null
    this.ViewTableQry=null
    this.editValueRow=null
    this.editrowid=null
    this.viewFilterString=null
    this.filterCriteria=null
    this.refKey=null
    this.ddlarrGidView=null
    this.dateArrGidView=null
    this.childFormList=null
    this.metaDataList=null
    this.attachmentType=null
    this.metadata=null
    this.rowData=null
    this.columnDefs=null
    this.pagesize=null
    this.isAdd=null
    this.isEdit=null
    this.isView=null
    this.isExpotable=null
    this.isResetFilter=null
    this.edit_option=null
  }

}
