import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-seller-registration-list',
  templateUrl: './seller-registration-list.component.html',
  styleUrls: ['./seller-registration-list.component.css']
})
export class SellerRegistrationListComponent implements OnInit {
  sellerList: any[]=[];
  isLoader: boolean = false;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  TempsellerList: any;
  isLoading: boolean = true;
  userId: any;
  allSellerList: any;
  searchTerm: any = '';
  isloadgridTrue: any = false;
  constructor(private formservice: FormService, private toastrService: ToastrService, private router: Router, private ProductService: ProductService) { }
  ngOnInit(): void {
    this.isLoading = true;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.bindNgxDataGrid(this.page, 1)
  }

  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)
  }



  bindNgxDataGrid(pageInfo, status) {
    // debugger
    this.isLoading = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let sellerStatus = 180;
    if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
    this.ProductService.pendingSellerCount(sellerStatus, this.searchTerm).subscribe(resp => {

      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.ProductService.pendingSellerList(pagesize, offset, sellerStatus, this.searchTerm).subscribe(res => {
          if (res.rows.length > 0) {
            this.sellerList = res.rows;
            this.TempsellerList = this.sellerList;
            this.isLoading = false;
          }
          else {
            setTimeout(() => {
            this.isLoading = false;
            }, 100);
         }
        })
         
      

     
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;

    })

  }

  CommonGridSearchMaster(ev) {  
    this.searchTerm = ev
    this.bindNgxDataGrid(this.page, 1)
    // this.ProductService.searchSeller(ev).subscribe(res => {
    //   console.log('search element', res.rows)
    // })
    // this.sellerList = this.TempsellerList.filter(item => ((item.seller_name ? (item.seller_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.business_name ? (item.business_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.store_name ? (item.store_name.toString().indexOf(ev) !== -1) : false) || (item.mobile_no ? (item.mobile_no.toLowerCase().indexOf(ev) !== -1) : false)) || (item.email ? (item.email.toLowerCase().indexOf(ev) !== -1) : false))
  }
  getDetails(row) {
    localStorage.removeItem('approve_reject');
    localStorage.setItem("selected_seller_id", row.rowData.seller_id);
    this.router.navigateByUrl('seller-profile' + '?' + localStorage.getItem('tempToken'));

  }
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
