<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid  bg-white">
            <div class="row">

                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary"
                                    style="text-decoration: none;  cursor: text;"
                                    routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary"
                                    style="text-decoration: none;  cursor: text;">Order Master</a>
                            </li>
                            <li class="breadcrumb-item newitm2  active" aria-current="page">Create Order</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-sm-12">
                    <div class="tabs row my-2 mx-0">
                        <div class="tab-button-outer1 col-12 px-0 mb-sm-0 mb-2">
                          <ul id="tab-button">
                            <li id="tabDraft" class="is-active">
                              <a href="javascript:void(0)" class="font9">Add New Order
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div id="tab01" class="tab-contents p-0">
                            <div class="card shadow-none">  
                                    <div class="card-body">  
                                    <div class="col-sm-12 col-md-12 nopadding">
                                        <div class="row mx-1 mb-3">
                                            <div class="col-md-2 col-lg-1 pl-md-1 pr-md-0">
                                                <label class="font600">Customer Name <span class="asterisk">*</span>
                                                </label>
                                            </div>
                                            <div class="col-md-6">
                                                <ng-select name="corpname" id="corpid" [(ngModel)]="cust_id" (change)="getSelectCust()">
                                                    <ng-option value="undefined" disabled>--Select Customer--</ng-option>
                                                    <ng-option *ngFor="let corp of customerList" [value]="corp.add_corporate_id">
                                                        {{corp.enterprise_name}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-md-3 col-lg-4" *ngIf="credit_remaining">

                                          <h5>      Credit Limit : <b> ₹{{credit_remaining}}</b> </h5>
                                            </div>
                                        </div>
                                        <div class="row mx-1 ">
                                            <div class="col-md-2 col-lg-1 pr-lg-0">
                                                <label class="font600">Reference#</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" class="form-control" [(ngModel)]="referenceNo">
                                            </div>
                                            <!-- <div class="col-md-3 col-lg-4">
                                                <button type="button" class="btn btn-sm px-3 rounded-pill btn-theme mt-2 mt-md-0">
                                                    <i class="fa fa-plus-circle mr-1"></i> Upload Item in Bulk</button>
                                            </div> -->
                                        </div>
                                        <!-- <ng-select  name="corpname" id="corpid" class=" mb-2" [(ngModel)]="product_id" (change)="getProductDetail()">
                                            <ng-option value="undefined" disabled>--Select Prodcut--</ng-option>
                                            <ng-option *ngFor="let p of productList" [value]="p.product_id">
                                                {{p.product_name}}
                                            </ng-option>
                                        </ng-select> -->
                                        <!-- <div class="row my-3">
                                            <div class="col-sm-6 offset-lg-1 offset-sm-2 mb-sm-0 mb-2">
                                                <ng-select aria-placeholder="--select--" (change)="getProductDetail($event)"
                                                    (search)="onSearch($event)"
                                                    placeholder="Type at least 3 characters (product name & SUK No)"
                                                    [(ngModel)]="product_id">
                                                    <ng-option *ngFor="let p of productList"
                                                        [value]="p.product_id">{{p.product_name}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-lg col-sm-3">
                                                <button type="button" class="btn btn-sm px-3 py-1 rounded-pill btn-theme">
                                                    <i class="fa fa-plus-circle mr-1" (click)="addProduct"></i> Add another
                                                    line</button>
                                            </div>
                                        </div> -->
                                        <div class="table-responsive my-3">
                                            <table class="table table-bordered white-space-pre mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="detailWidth">Item Details</th>
                                                        <th scope="col" width="100px" class="text-center">Quantity</th>
                                                        <th scope="col" class="text-center pricewidth">Price</th>
                                                        <!-- <th scope="col" class="text-center pricewidth">MRP</th> -->
                                                        <th scope="col" width="100px" class="text-center">GST(%)</th>
                                                        <th scope="col" width="100px" class="text-center">GST Amount</th>
                                                        <!-- <th scope="col" width="100px" class="text-center">SGST(%)</th>
                                                        <th scope="col" width="100px" class="text-center">Amount</th> -->
                                                        <!-- <th scope="col" width="100px" class="text-center">GST Amount</th> -->
                                                        <th scope="col" width="250px" class="text-center">Total Amount</th>
                                                        <th scope="col" width="150px" class="text-center"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let p of temproductName;let i = index">
                                                        <ng-template [ngIf]="temproductName.length>0">
                                                            <td class="text-wrap ">
                                                                <div class="d-flex align-items-center">
                                                                    <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsDTxpxB05DKK2jW10gJPV7uFN35-IYwumUe1CAx8&s" width="50"> -->
                                                                    <div class="ml-2">
                                                                        <p class="font8 font-weight-bold mb-0 detailWidth">
                                                                            {{p.product_name}}</p>
                                                                        <span class="font7">{{p.suk ? p.suk : p.sku}}</span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td class="text-center">
                                                                <div class="row">
                                                                    <div class="col-lg-12 ">
                                                                    
                                                                <input class="form-control text-center" type="text" 
                                                                    id="textbox_{{i}}" name="quantity" [(ngModel)]="p.cus_quantity"
                                                                    [maxlength]="10" autocomplete="off"
                                                                    onkeypress="return /[0-9]/i.test(event.key)" onpaste="return false;"
                                                                    ondrop="return false;" (keyup)="onChangePrice()">
                                                                </div>
                                                                </div>
                                                                </td>

                                                            <td class=" pricewidth">
                                                                <div class="row mb-2">
                                                                    <div class="col-12  ">
                                                                        <div class="input-group pricewidth pull-right">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text py-0 px-1">₹</div>
                                                                            </div>
                                                                            <input class="form-control" type="text" id="textbox_{{i}}" name="price" [(ngModel)]="p.price"
                                                                                [maxlength]="10" autocomplete="off" onkeypress="return /[0-9]/i.test(event.key)" onpaste="return false;"
                                                                                ondrop="return false;" (keyup)="onChangePrice()">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="price-multiple mb-2">
                                                                    <div class="row">
                                                                     <div class="col-5 price-txt pr-0">
                                                                     Black-small 
                                                                     </div>
                                                                     <div class=" col-7">
                                                                        <div class="input-group ">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text py-0 px-1">₹</div>
                                                                            </div>
                                                                            <input class="form-control" type="text"
                                                                                id="textbox_{{i}}" name="price"
                                                                                [(ngModel)]="p.price" [maxlength]="10" autocomplete="off"
                                                                                onkeypress="return /[0-9]/i.test(event.key)"
                                                                                onpaste="return false;" ondrop="return false;" (change)="onChangePrice(p.price)">
                                                                            
                                                                        </div>
                                                                     </div>
                                                                    </div>
                                                                </div>
                                                                <div class="price-multiple mb-2">
                                                                    <div class="row">
                                                                     <div class="col-5 price-txt pr-0">
                                                                     Black-Large 
                                                                     </div>
                                                                     <div class=" col-7">
                                                                        <div class="input-group ">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text py-0 px-1">₹</div>
                                                                            </div>
                                                                            <input class="form-control" type="text"
                                                                                id="textbox_{{i}}" name="price"
                                                                                [(ngModel)]="p.price" [maxlength]="10" autocomplete="off"
                                                                                onkeypress="return /[0-9]/i.test(event.key)"
                                                                                onpaste="return false;" ondrop="return false;" (change)="onChangePrice(p.price)">
                                                                            
                                                                        </div>
                                                                     </div>
                                                                    </div>
                                                                </div>
                                                                <div class="price-multiple mb-2">
                                                                    <div class="row">
                                                                     <div class="col-5 price-txt pr-0">
                                                                     Black-lg 
                                                                     </div>
                                                                     <div class=" col-7">
                                                                        <div class="input-group ">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text py-0 px-1">₹</div>
                                                                            </div>
                                                                            <input class="form-control" type="text"
                                                                                id="textbox_{{i}}" name="price"
                                                                                [(ngModel)]="p.price" [maxlength]="10" autocomplete="off"
                                                                                onkeypress="return /[0-9]/i.test(event.key)"
                                                                                onpaste="return false;" ondrop="return false;" (change)="onChangePrice(p.price)">
                                                                            
                                                                        </div>
                                                                     </div>
                                                                    </div>
                                                                </div> -->


                                                            </td>
                                                            <!-- <td class="font8 text-center">{{p.mrp}}</td> -->
                                                            <td class="font8 text-center">{{p.gst_rate}}%</td>
                                                            <td class="font8 text-center">{{(p.cus_quantity * p.price)*(p.gst_rate)/100 |
                                                                currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</td>
                                                            <!-- <td class="font8 text-center">{{p.gst_rate/2}}%</td>
                                                            <td class="font8 text-center">{{(p.quantity * p.price)*(p.gst_rate/2)/100 | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</td> -->
                                                            <!-- <td class="text-right font8">{{p.price?'₹':''}}{{p.price}}{{p.price?'.00':''}}</td> -->
                                                            <td class="font8 text-center">{{(p.cus_quantity * p.price) | currency : 'INR' :
                                                                'symbol': '1.2-2' : 'en-IN'}}</td>
                                                            <td class="text-center">
                                                                <!-- <button class="btn btn-sm btn-dark mr-2 py-1" (click)="editprices(i)">
                                                                    <i class="fa fa-edit mr-lg-1"></i>
                                                                    <span class="d-none d-lg-inline-block">Edit</span>
                                                                </button> -->
                                                                <a href="javascript:void(0)" class="text-decoration-none  closebtn"
                                                                    (click)="deleteProduct(i)">
                                                                    <i class="fa fa-times"></i>
                                                                </a>
                                                            </td>
                                                        </ng-template>
                                                    </tr>
                                                    <tr *ngIf="temproductName.length<=0">
                                                        <td colspan="7" class="text-center py-2 font9">No data found</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row">

                                            <div class="col-lg-7">
                                                <div class="row"> 
                                                    <!-- (change)="onchangeproduct($event)" -->
                                                <div class="col-sm-12 cr-or">
                                               

                                                    <ng-select #mySelectElement aria-placeholder="--select--"  (change)="getProductDetail($event)"
                                                    (search)="onSearch($event)"
                                                    [placeholder]="cust_id ? 'Type at least 3 characters (product name & SUK No)' : 'Please select customer name first'"
                                                    [(ngModel)]="sku" [disabled]="!cust_id">
                                                    <ng-option *ngFor="let p of productList"
                                                        [value]="p.sku || p.suk">{{p.product_name}} {{p.sku || p.suk}}</ng-option>
                                                </ng-select>

                                                </div>
                                                <!-- <div class=" col-sm-4">
                                                    <button type="button" class="btn btn-sm px-3 py-1 rounded-pill btn-theme">
                                                        <i class="fa fa-plus-circle mr-1" (click)="addProduct"></i> Add another
                                                        line</button>
                                                </div> -->
                                            </div>
                                            </div>

                                            <div class="col-lg-5 ">
                                                <div class="card shadow-none">
                                                    <div class="card-body p-0">
                                                        <ul class="list-group bg-lights px-4 py-2 border-radius">
                                                            <li
                                                                class="list-group-item d-flex justify-content-between align-items-center px-0">
                                                                Sub Total
                                                                <span class="">{{subTotal| currency : 'INR' : 'symbol': '1.2-2' :
                                                                    'en-IN'}}</span>
                                                            </li>
                                                            <li
                                                                class="list-group-item d-flex justify-content-between align-items-center px-0">
                                                                <div class="d-flex align-items-center">Shipping Charges
                                                                    <span class="ml-2">

                                                                        <div class="input-group">
                                                                            <input type="text" value="10" maxlength="6"
                                                                                class="form-control mw-70 text-right"
                                                                                [(ngModel)]="shippingCharge"
                                                                                (keypress)="commonFunctionService.amountValidator($event)">
                                                                            <div class="input-group-prepend">
                                                                                <div class="input-group-text py-0 px-1">₹</div>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>{{shippingCharge| currency : 'INR' : 'symbol': '1.2-2' :
                                                                    'en-IN'}}</span>
                                                                <!-- <span class="">{{shippingCharge?'₹':''}}{{shippingCharge}}{{shippingCharge?'.00':''}}</span> -->
                                                            </li>
                                                            <!-- <li class="list-group-item d-flex justify-content-between align-items-center px-0"
                                                                *ngFor="let p of temproductName;let i = index">
                                                                CGST[{{p.gst_rate/2}}]%
                                                                <span class="">{{((p.cus_quantity * p.price)*(p.gst_rate)/2)/100 | currency
                                                                    : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</span>
                                                            </li>
                                                            <li class="list-group-item d-flex justify-content-between align-items-center px-0"
                                                                *ngFor="let p of temproductName;let i = index">
                                                                SGST[{{p.gst_rate/2}}]%
                                                                <span class="">{{((p.cus_quantity * p.price)*(p.gst_rate)/2)/100 | currency
                                                                    : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</span>
                                                            </li>
                                                            <li
                                                                class="list-group-item d-flex justify-content-between align-items-center px-0">
                                                                <span class="font-weight-bold">Total</span>
                                                                <span class="font-weight-bold">{{(totalAmount)+(+shippingCharge)|
                                                                    currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</span>
                                                            </li> -->

                                                            <li class="list-group-item border-none-trans align-items-center px-0"
                                                            *ngFor="let p of gstList;let i = index">
                                                            
                                                            <div class="w-100 mb-1">
                                                                CGST[{{p.cgst_rate}}]%
                                                                <span class="float-right">{{p.cgst_value | currency
                                                                    : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</span>
                                                            </div>
                                                            <div class="w-100">
                                                                SGST[{{p.cgst_rate}}]%
                                                                <span class="float-right">{{p.sgst_value | currency
                                                                    : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</span>
                                                            </div>

                                                            <!-- <span class="">SGST {{p.cgst_rate}} - {{p.sgst_value}}</span>
                                                            <span class="">CGST {{p.cgst_rate}} - {{p.cgst_value}}</span> -->
                                                        </li>
                                                        <li
                                                        class="list-group-item border-top mt-3 d-flex justify-content-between align-items-center px-0">
                                                        <span class="font-weight-bold">Total</span>
                                                        <span class="font-weight-bold">{{(totalAmount)+(+shippingCharge)|
                                                            currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}</span>
                                                    </li> 

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <div class="col-md-12 mb-md-0 mb-2">
                                                <!-- <h5 class="font-weight-bold">Products Remark </h5> -->
                                            <label for=""> Products Remark </label>
                                            </div>
                                            <div class="col-md-12 mb-md-0 mb-2">
                                                <textarea name="remarks" id="remarks" class="form-control2"  [(ngModel)]="remarks" >

                                                </textarea>
                                            </div>
                                          
                                        </div>

                                        <div class="mt-4 footer-addrs-block"
                                            *ngIf="cust_id != null && cust_id != undefined && cust_id != 'null' && cust_id != 'undefined'">
                                            <div class="row mb-2">
                                                <div class="col-md-4 mb-md-0 mb-2">
                                                    <h5 class="font-weight-bold">DISPATCH FROM </h5>
                                                    <label>
                                                        <p class="mb-1">1011-1018, 10th Floor Tower 4, DLF Corporate Greens, Southern
                                                            Peripheral Rd, Sector
                                                            74A, Gurugram, Haryana 122004</p>
                                                    </label>
                                                </div>
                                                <div class="col-md-4 mb-md-0 mb-2">
                                                    <h5 class="font-weight-bold">BILLING ADDRESS
                                                        <!-- <button class="btn btn-sm btn-theme px-lg-3 editbtn ml-lg-2 ml-md-0 ml-2" data-toggle="modal" data-target="#editBilAd">
                                                            <i class="fa fa-pencil mr-1"></i> Edit</button> -->
                                                    </h5>
                                                    <div class="row box border-bottom" *ngFor="let obj of billingDetails;let i=index">
                                                        <div class="col-1 pr-0">
                                                            <input type="radio" name="billAdd" id="{{'bill'+i}}" value="{{obj.corporate_billing_details_id}}"
                                                                class="form-check-input custom1-chkboxradio mr-2 mt-0" [(ngModel)]="corporate_billing_details_id">
                                                        </div>
                                                        <div class="col-11">
                                                            <label for="{{'bill'+i}}">
                                                                <strong>{{obj.company_name}}</strong>

                                                                <p class="mb-1">{{obj.full_address}}</p>
                                                                <strong class="d-block" *ngIf="obj.gstin">GSTIN: {{obj.gstin}}</strong>
                                                                <strong>Mobile: {{obj.mobile}}</strong>
                                                            </label>
                                                            <p class="mb-0">
                                                                <a class="fontTheme font600 font8 mr-3" href="javascript:void(0)"
                                                                    data-toggle="modal" data-target="#editBilAd"
                                                                    (click)="onEditAddress(obj)">EDIT</a>
                                                                <a class="fontTheme font600 font8" href="javascript:void(0)" (click)="deleteCorpBillingAddress(obj)">DELETE</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mb-md-0 mb-2">
                                                    <h5 class="font-weight-bold">SHIPPING ADDRESS
                                                        <!-- <button class="btn btn-sm btn-theme px-3 editbtn ml-2">
                                                                <i class="fa fa-pencil mr-1"></i> Edit</button> -->

                                                    </h5>

                                                    <div class="row mb-2 align-items-center">
                                                        <div class="col-1 pr-0">
                                                            <input class="form-check-input custom1-chkboxradio mt-0" type="radio"
                                                                name="ship_adrs" id="inlineCheckbox1" value="single" [(ngModel)]="shipType">
                                                        </div>
                                                        <div class="col-lg-5 col-11">
                                                            <label class="" for="inlineCheckbox1">Ship to Single Location</label>
                                                        </div>

                                                        <div class="col-1 pr-0">
                                                            <input class="form-check-input custom1-chkboxradio mt-0" type="radio"
                                                                name="ship_adrs" id="inlineCheckbox2" value="multiple" [(ngModel)]="shipType">
                                                        </div>
                                                        <div class="col-lg-5 col-11">
                                                            <label class="" for="inlineCheckbox2">Ship to Multiple Locations</label>
                                                        </div>


                                                    </div>
                                                    <!-- <div class="clearfix"></div> -->
                                                    <div class="address-card" *ngIf="shipType == 'single'">
                                                    <div class="row box border-bottom" *ngFor="let obj of shippingDetails;let i=index;">
                                                        <!--  <p class="mb-1">1011-1018, 10th Floor, Tower 4, DLF Corporate Greens, Southern Peripheral Road, Sector
                                                            74, Gurgaon, India - 120013
                                                        </p>
                                                        <strong>Mobile: 9044025200</strong> -->
                                                        <div class="col-1 pr-0">
                                                            <input type="radio" name="shipAdd" id="{{'ship'+i}}" value="{{obj.corporate_shipping_details_id}}"
                                                                class="form-check-input custom1-chkboxradio mr-2 mt-0" [(ngModel)]="corporate_shipping_details_id">
                                                        </div>
                                                        <div class="col-11">
                                                            <label for="{{'ship'+i}}">
                                                                <strong>{{obj.contact_person}}</strong>
                                                                <!-- <strong>{{obj.company_name}}</strong> -->
                                                                <p class="mb-1">{{obj.full_address}}</p>
                                                                <strong>Mobile: {{obj.mobile}}</strong>
                                                            </label>
                                                            <p class="mb-0">
                                                                <a class="fontTheme font600 font8 mr-3" href="javascript:void(0)"
                                                                    data-toggle="modal" data-target="#editShipAd"
                                                                    (click)="onEditShipAddress(obj)">EDIT</a>
                                                                <a class="fontTheme font600 font8" href="javascript:void(0)" (click)="deleteCorpShippingAddress(obj)">DELETE</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 text-sm-right mb-2"
                                            *ngIf="cust_id != null && cust_id != undefined && cust_id != 'null' && cust_id != 'undefined'">
                                            <button class="btn px-lg-4 px-3 py-1 rounded-pill btn-theme btn-sm shadow" (click)="createOrderForCorporate()">Create
                                                Order</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade px-0" id="editBilAd">
    <div class="modal-dialog modal-xl my-3">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center p-2">
                <h6 class="modal-title">Corporate Billing Address</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row m-0">
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Company Name
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="companyName">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Email
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="emailB">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Phone No
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="phoneB">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Address
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <textarea type="text" class="form-control2" [(ngModel)]="billAddress"></textarea>
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Country
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <select name="countryB" id="countryB" [(ngModel)]="countryB" class="form-control mb-2">
                            <option value="undefined">-: Select Country :-</option>
                            <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                {{con.country_name}}
                            </option>
                        </select>
                        <!-- <input type="text" class="form-control" [(ngModel)]="countryB"> -->
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Province/Territory/State
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <select class="form-control mb-2" id="stateB" name="stateB" [(ngModel)]="stateB"
                            >
                            <option value="undefined">-: Select Province/Territory/State :-</option>
                            <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                {{s.state_name}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" [(ngModel)]="stateB"> -->
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>City
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="cityB">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Postal/Zip Code</label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="zipCodeB">
                    </div>
                    <!-- <div class="col-lg-2 col-md-4 col-11 order-md-1 order-2 text-sm-right mb-2">
                        <label>Save as default billing address</label>
                    </div>
                    <div class="col-lg-4 col-md-8 col-1 order-md-2 order-1 mb-2">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="defaultAddressB">
                    </div> -->
                    <div class="col-sm-12 text-center order-3">
                        <button class="btn btn-sm mr-2 btn-theme" (click)="updateCorpBillingAddress()">Update</button>
                        <button class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade px-0" id="editShipAd">
    <div class="modal-dialog modal-xl my-3">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center p-2">
                <h6 class="modal-title">Corporate Shipping Address</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row m-0">
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Contact Person
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="contactPerson">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Email
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="emailS">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Phone No
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="phoneS">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Address
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <textarea type="text" class="form-control2" [(ngModel)]="shipAddress"></textarea>
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Country
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <select name="countryS" id="countryS" [(ngModel)]="countryS" class="form-control mb-2">
                            <option value="undefined">-: Select Country :-</option>
                            <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                {{con.country_name}}
                            </option>
                        </select>
                        <!-- <input type="text" class="form-control" [(ngModel)]="countryS"> -->
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Province/Territory/State
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <select class="form-control mb-2" id="state" name="stateS" [(ngModel)]="stateS"
                            >
                            <option value="undefined">-: Select Province/Territory/State :-</option>
                            <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                {{s.state_name}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" [(ngModel)]="stateS"> -->
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>City
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="cityS">
                    </div>
                    <div class="col-lg-2 col-md-4 text-sm-right mb-2">
                        <label>Postal/Zip Code</label>
                    </div>
                    <div class="col-lg-4 col-md-8 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="zipCodeS">
                    </div>
                    <!-- <div class="col-lg-2 col-md-4 col-11 order-md-1 order-2 text-sm-right mb-2">
                        <label>Save as default shipping address</label>
                    </div>
                    <div class="col-lg-4 col-md-8 col-1 order-md-2 order-1 mb-2">
                        <input type="checkbox" class="form-check-input" [(ngModel)]="defaultAddressS">
                    </div> -->
                    <div class="col-sm-12 text-center order-3">
                        <button class="btn btn-sm mr-2 btn-theme" (click)="updateCorpShippingAdress()">Update</button>
                        <button class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>