<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <!-- <section style="background:#FAF0E5" class="shippingformate">
            <div class="container py-3">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row align-items-center">
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 my-2">
                            <p class="mb-0">
                                <span class="strippedclass"> <i class="fas fa-rupee-sign"></i> </span>
                                No Hidden Fees
                            </p>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 my-2">
                            <p class="mb-0">
                                <span class="strippedclass"> <i class="fas fa-shipping-fast"></i> </span>
                                Free Shipping Offer
                            </p>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 my-2">
                            <p class="mb-0">
                                <span class="strippedclass"> <i class="fas fa-comment-alt"></i> </span>
                                Accessible Art Experts
                            </p>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 my-2">
                            <p class="mb-0">
                                <span class="strippedclass"> <i class="fas fa-award"></i></span>
                                Guaranteed Quality
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item newitm1">
                                <a href="javascript:void(0)" routerLink="/dashboardnew" class="text-primary " style="text-decoration: none;  cursor: text;">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item newitm2">
                                <a href="javascript:void(0)" routerLink="/buyer-list" class="text-primary " style="text-decoration: none;  cursor: text;">Buyer List</a>
                            </li>
                            <li class="breadcrumb-item newitm3">
                                <a href="javascript:void(0)" routerLink="/buyer-account" class="text-primary " style="text-decoration: none;  cursor: text;">Buyer Account
                                    <strong>({{buyerName}})</strong>
                                </a>
                            </li>
                            <li class="breadcrumb-item newitm4  active" aria-current="page">My Cart</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <!-- <section class="py-lg-5 py-2">
                <div class="address-card" *ngIf="product.length>0">

                    <div class="address_sub border p-2 px-3">

                        <div *ngFor="let prod of product let i=index">
                            <img src="../../assets{{prod.image_path}}" height="100" width="100">

                            <p class="font600 mb-0 "> {{prod.product_name}}</p>
                            <small>
                                <a (click)="goToDetail(prod.product_id)">{{prod.product_discription | slice:0:50}}<span
                                        id="dots">...</span></a>
                            </small>
                            <small>
                                <P>Seller:{{prod.seller_name}}</P>
                            </small>

                            <div class="form-check-inline">
                                <button class="btn btn-primary" (click)="plus(prod,i)"><i
                                        class="fa fa-plus"></i></button>
                            </div>
                            <div class="form-check-inline">
                                <input type="text" keyDown="false" class="form-control" readonly
                                    [(ngModel)]="prod.qtytotal">
                            </div>
                            <div class="form-check-inline">
                                <button class="btn btn-primary " id="minus-btn{{i}}" (click)="minus(prod,i)"><i
                                        class="fa fa-minus"></i></button>
                            </div>

                            <b>
                                <p>₹{{prod.price}}</p>
                            </b>
                            <button class="btn btn-default btn-number btn-sm btn-gray"
                                (click)="remove(prod.product_id)">Remove</button>
                        </div>
                    </div>

                    <div class="text-center mb-2">
                        <button class="btn btn-theme btn-sm" (click)="placeOrder()">Place Order</button>
                    </div>
                </div>
            </section> -->

            <section class="py-lg-3 px-lg-4 py-2" *ngIf="product.length!=0">
                <h4 class="color-dark mb-2">Shopping Cart</h4>
                <p class="font9 mb-0 mb-md-2">Your Total is <b> {{showgrandTotal | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}} </b> for <b> {{product.length}} </b> {{product.length==1?'item':'items'}}
                </p>
                <div class="row">
                    <div class="col-md-8 col-lg-8 col-12">
                        <div class="card shadow-none">
                            <div class="top-item text-center d-none d-lg-block">
                                <div class="card-body py-0">
                                    <div class="row row align-items-center">
                                        <div class="col-md-8 col-lg-8 col-4">
                                            <h6 class="color-dark mb-0">Item Details</h6>
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-4">
                                            <h6 class="color-dark mb-0">Quantity</h6>
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-4">
                                            <h6 class="color-dark mb-0">Amount</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="card-body py-0">
                                <div class="cart-box" *ngFor="let prod of product let i=index">
                                    <div class="row align-items-top">
                                        <div class="col-md-7 col-lg-8 col-12 mb-3">
                                            <div class="row">
                                                <div class="col-md-4 col-lg-2 col-3">
                                                    <div class="cart-img">
                                                        <img src="../../..{{prod.image_path}}" class="img-fluid" alt="">
                                                    </div>
                                                </div>
                                                <div class="col-md-8 col-lg-10 col-9">
                                                    <div class="item_box">
                                                        <h6 class="mb-0 color-dark text-capitalize">{{prod.product_name}}
                                                        </h6>
                                                        <p class="sku" *ngIf="prod.product_type=='209'||prod.product_type=='197'">
                                                            <i> SKU : {{prod.sku}}</i>
                                                        </p>
                                                        <div class="pl-0 col-xl-5 col-md-6 pr-0 col-7 text-center mb-2" *ngIf="prod.product_type!='209' && prod.product_type!='208'&& prod.product_type!='198' ">
                                                            <div class="input-group mb-2 add-to-cart p-0">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text ml-0">
                                                                        <button class="" id="minus-btn{{i}}" [disabled]="prod.qtytotal <= 1" (click)="minus(prod,i)">
                                                                            <i class="fa fa-minus"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>

                                                                <input type="text" (keyup)="plus(prod,i,$event)" class="form-control cst-frm h-auto" readonly [(ngModel)]="prod.qtytotal">
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text">
                                                                        <button class="" (click)="plus(prod,i)">
                                                                            <i class="fa fa-plus"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mb-2" *ngIf="prod.product_type=='209'||prod.product_type=='208'||prod.product_type=='198'">
                                                            <div class="row g-1">
                                                                <div class="col-xl-5 col-lg-12">
                                                                    <p class="text-uppercase fw-bold mb-1" *ngIf="prod.extra_features.length > 0">Extra Features</p>
                                                                    <div class="d-flex  mb-1" *ngFor="let feature of prod.extra_features; index as j">
                                                                        <div>
                                                                            <input type="checkbox" name="styled-checkbox_op1_{{prod.product_id + j}}" class="styled-checkbox" (change)="onAddExtraFeaturePrice(prod, feature)"
                                                                                [(ngModel)]="feature.isSelected" id="styled-checkbox_op1_{{prod.product_id + j}}">
                                                                            <label for="styled-checkbox_op1_{{prod.product_id + j}}" class="m-0"></label>
                                                                        </div>
                                                                        <div>
                                                                            <p class="fw-normal mb-0 extra-feature-text-limit mt-1">
                                                                                <span class="extra-feature-text-limit">{{feature.feature}}</span> :
                                                                                <span>₹{{feature.price}}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="d-flex align-items-center mb-1">
                                                                    <div>
                                                                        <input type="checkbox" class="styled-checkbox" id="styled-checkbox_op2">
                                                                        <label for="styled-checkbox_op2">logo : ₹ 50</label>
                                                                    </div>
                                                                    <div>
                                                                        <h6 class="fw-normal fs-6 mb-0"> </h6>
                                                                    </div>
                                                                </div> -->
                                                                </div>
                                                                <div class="col-xl-7 col-lg-12" *ngIf="prod.product_type != 209">
                                                                    <div class="row g-1 py-2 align-items-center" [ngClass]="obj.quantity ? 'border-bottom' : '' " *ngFor="let obj of prod.details; index as i">
                                                                        <div class="col-lg-5  col-6" *ngIf="obj.quantity">
                                                                            <div class="text-limite-extra">
                                                                                <span class="font8 font-crt extra-feature-text-limit1" *ngIf="obj.quantity">{{obj.attribute_value1?""+obj.attribute_value1:''}}</span>
                                                                                -
                                                                                <span class="font8 font-crt extra-feature-text-limit1" *ngIf="obj.quantity">{{obj.attribute_value2?""+obj.attribute_value2:''}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-7 pl-2 col-6" *ngIf="prod.product_type!='209'">
                                                                            <div class="">
                                                                                <div class="input-group add-to-cart p-0">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text ml-0">
                                                                                            <button class="" id="minus-btn{{i}}" [disabled]="prod.qtytotal <= 1" (click)="minusConf(prod, obj, i)">
                                                                                                <!-- [disabled]="prod.qtytotal <= 1" -->
                                                                                                <i class="fa fa-minus"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" (keyup)="plus(prod,i,$event)" class="form-control cst-frm h-auto" readonly [(ngModel)]="prod.qtytotal">
                                                                                    <div class="input-group-append">
                                                                                        <span class="input-group-text">
                                                                                            <button class="" (click)="plusConf(prod, obj, i)">
                                                                                                <i class="fa fa-plus"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-7 col-lg-12">
                                                                    <div class="mb-3 add-to-cart p-0" *ngIf="prod.product_type=='209'">
                                                                        <div class="product-209 mb-2" *ngFor="let obj of prod.details; index as i">
                                                                            <!-- <span>{{obj.name}}</span> -->
                                                                            <div class="col-7 col-sm-11 col-md-6 col-xl-8">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text ml-0">
                                                                                            <button class="" id="minus-btn{{i}}" [disabled]="prod.qtytotal <= 1"  (click)="minus(prod,i, obj)">
                                                                                                <!-- [disabled]="prod.qtytotal <= 1" -->
                                                                                                <i class="fa fa-minus"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                    <input type="text" (keyup)="plus(prod,i,$event)" class="form-control cst-frm h-auto" readonly [(ngModel)]="prod.qtytotal">
                                                                                    <div class="input-group-append">
                                                                                        <span class="input-group-text">
                                                                                            <button class="" (click)="plus(prod,i,obj)">
                                                                                                <i class="fa fa-plus"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- <a href="javascript:void(0)" (click)="remove(prod.product_id)" class="trashicon">
                                                            <i class="fa fa-trash mr-1"></i> Remove</a> -->
                                                        <a href="javascript:void(0)" (click)="showPopup(prod.product_id)" class="trashicon" data-toggle="modal" data-target="#remove-item">
                                                            <i class="fa fa-trash mr-1"></i> Remove</a>

                                                        <!-- <span class="mx-3 "> | </span>
                                                        <a href="javascript:void(0)" class="SaveforLater" (click)="navigateToMyAccount(prod)">
                                                            <i class="fa fa-bookmark mr-1"></i> Save for Later</a> -->
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <!-- <div class="col-md-3 pl-0 col-lg-2 pr-0 col-4 text-center ">
                                        
                                            <div class="input-group mb-3 add-to-cart">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <button class="" id="minus-btn{{i}}"
                                                            (click)="minus(prod,i)"><i class="fa fa-minus"></i></button> </span>
                                                </div>
                                                <input type="text" keyDown="false" class="form-control cst-frm h-auto" readonly
                                                    [(ngModel)]="prod.qtytotal">
                                                <div class="input-group-append">

                                                    <span class="input-group-text"> <button class="" (click)="plus(prod,i)"><i
                                                                class="fa fa-plus"></i></button> </span>
                                                </div>
                                            </div>

                                        </div> -->
                                        <div class="col-md-2 pl-0 col-lg-2 pr-0 col-3 text-center " *ngIf="prod.product_type!='209' && prod.product_type!='208'&& prod.product_type!='198' ">
                                            <!-- <div class="input-group mb-3 add-to-cart">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <button class="" id="minus-btn{{i}}"
                                                        (click)="minus(prod,i)"><i class="fa fa-minus"></i></button> </span>
                                            </div>
                                            <input type="text" keyDown="false" class="form-control cst-frm h-auto" readonly
                                                [(ngModel)]="prod.qtytotal">
                                            <div class="input-group-append">
                                            
                                                <span class="input-group-text"> <button class="" (click)="plus(prod,i)"><i
                                                            class="fa fa-plus"></i></button> </span>
                                            </div>
                                            </div> -->
                                            <h6 class="color-dark text-center mb-0">
                                                <span class="d-md-none">Qty: </span>{{prod.qtytotal}}</h6>
                                        </div>
                                        <div class="col-md-2 pl-0 col-lg-2 pr-0 col-3 text-center " *ngIf="prod.product_type=='209'||prod.product_type=='208'||prod.product_type=='198'">
                                            <h6 class="color-dark text-center mb-0">
                                                <span class="d-md-none">Qty: </span>{{prod.qtytotal }}</h6>
                                            <div class="d-none" *ngFor="let obj of prod.details; index as i">
                                                <!-- <span class="font8 font-crt">{{obj.product_name}}</span> -->
                                                <span class="font8 font-crt" *ngIf="obj.quantity">{{obj.attribute_value1?""+obj.attribute_value1:''}}</span>
                                                <span class="font8 font-crt" *ngIf="obj.quantity">{{obj.attribute_value2?""+obj.attribute_value2:''}}</span>
                                                <div class="input-group mb-1 add-to-cart p-1" *ngIf="prod.product_type!='209' && obj.quantity">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <button class="" id="minus-btn{{i}}" (click)="minus_conf(prod, obj, i)">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control cst-frm h-auto" min="1" keyDown="false" readonly [(ngModel)]="obj.quantity">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">
                                                            <button class="" (click)="plus_conf(prod, obj, i)">
                                                                <i class="fa fa-plus"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class=" mb-3 add-to-cart d-none" *ngIf="prod.product_type=='209'">
                                            <div class="" *ngFor="let obj of prod.details; index as i">
                                                <span>{{obj.name}}</span>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <button class="" id="minus-btn{{i}}"(click)="minus(prod,i, obj)"><i
                                                        class="fa fa-minus"></i></button> </span>
                                                    </div>
                                                    <input type="text" keyDown="false" class="form-control cst-frm h-auto" readonly [(ngModel)]="obj.qty">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"> <button class="" (click)="plus(prod,i,obj)"><i class="fa fa-plus"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            </div> -->
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-6 text-lg-center">
                                            <h5 class="mb-0 color-dark"> {{prod.price | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN' }} </h5>
                                            <a class="price-details" data-target="#price-details" data-toggle="modal" title="Price of product" (click)="getGstAmount(prod)">
                                                Price Detail
                                                <i class="fa fa-caret-right"></i>
                                            </a>

                                        </div>
                                    </div>
                                    <!-- <hr style="color: #e2e2e2;"> -->

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-12">
                        <div class="payment-summary py-2 gray-bg1">
                            <div class="px-3 py-2 border-botm">
                                <h5 class="color-dark mb-1">Payment Summary</h5>
                                <p class="mb-2">Your shopping cart {{product.length==1?'contains':'contain'}} {{product.length}} {{product.length==1?'product':'products'}}</p>
                            </div>
                            <div class="py-sb px-4 py-1">
                                <div class="row border-botm py-2">
                                    <div class="col-md-6 col-lg-6 col-6">
                                        <h6 class="dark-color">Item(s) Subtotal</h6>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-6 text-right">
                                        <h6 class="dark-color">
                                            <b> {{convertToInt() | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}} </b>
                                        </h6>
                                    </div>
                                </div>
                                <div class="row border-botm py-2">
                                    <div class="col-md-6 col-lg-6 col-6">
                                        <h6 class="dark-color"> GST</h6>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-6 text-right">
                                        <h6 class="dark-color">
                                            <b> ₹{{grandGst ? grandGst : '0'}} </b>
                                        </h6>
                                    </div>
                                </div>
                                <div class="row border-botm py-2">
                                    <div class="col-md-6 col-lg-6 col-6">
                                        <h6 class="dark-color"> Shipping Charge</h6>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-6 text-right">
                                        <h6 class="dark-color">
                                            <b> ₹00.00 </b>
                                        </h6>
                                    </div>
                                </div>
                                <div class="row border-botm py-2">
                                    <div class="col-md-6 col-lg-6 col-6">
                                        <h6 class="dark-color"> Coupon Discount</h6>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-6 text-right">
                                        <h6 class="dark-color">
                                            <b> ₹00.00 </b>
                                        </h6>
                                    </div>
                                </div>
                                <div class="row  py-2 fnl-am border-botm">
                                    <div class="col-md-6 col-lg-6 col-6">
                                        <h6 class="dark-color">Amount Payable</h6>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-6 text-right">
                                        <h6 class="dark-color">
                                            <b> {{showgrandTotal | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}} </b>
                                        </h6>
                                    </div>
                                </div>
                                <!-- <p class="text-success text-right mt-4">₹00.00 You saved on this order</p> -->
                                <div class="row py-2 fnl-am mt-1">
                                    <div class="col-lg-12">
                                        <h6 class="text-success1 text-right  mb-4 colo"> You saved
                                            <span class="text-success font-weight-bold">{{rupeeIndian.format(savedAmount ? savedAmount : '00')}}</span> on this order</h6>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-12 mb-2">
                                        <button class="btn btn-theme w-100 py-2 rounded-pill" (click)="placeOrder()" [disabled]="role_id == '47'"> Checkout
                                        </button>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-12 mb-2">
                                        <a href="javascript:void(0)">
                                            <button class="btn w-100 py-2 btn-secondary rounded-pill" (click)="continueShopping()">
                                                Continue Shopping </button>
                                        </a>
                                    </div>
                                </div>
                                <!-- <div class="row py-2 fnl-am">
                                    <div class="col-md-12 col-lg-12 col-12 mb-2">
                                        <button class="btn btn-theme w-100 py-2 rounded-pill" (click)="placeOrder()"> Checkout
                                        </button>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-12 mb-2">
                                        <a href="javascript:void(0)">
                                            <button class="btn w-100 py-2 btn-secondary rounded-pill" (click)="continueShopping()"> Continue Shopping </button>
                                        </a>
                                    </div>
                                </div> -->

                                <!-- <div class="row  py-2 fnl-am">
                                    <div class="col-md-12 col-lg-12 col-12">
                                        <h6 class="dark-color">Have a promo code?</h6>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-12 text-right mb-3">
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control h-auto"
                                                placeholder="Enter coupon code" aria-label="" aria-describedby="">
                                            <span class="input-group-text bg-dark text-white px-4"
                                                id="basic-addon2">Apply</span>
                                        </div>
                                    </div>

                           

                                    <p class="  text-center">Have questions about your shopping cart?
                                        Call our customer satisfaction team at
                                        <br>
                                        1-800-491-3003 or email <br>
                                        promosupport@ideacraft.com
                                    </p>
                                </div> -->
                            </div>


                        </div>


                    </div>


                </div>


            </section>
            <div class="row">
                <div class="col-sm-12">
                    <div *ngIf="product.length==0" class=" m-auto text-center pt-5 pb-5">
                        <p>Shopping Cart is Empty!</p>
                        <a href="javascript:void(0)">
                            <button class="btn btn-sm cont py-2 color-dark" (click)="continueShopping()"> Continue Shopping </button>
                        </a>

                    </div>
                </div>
            </div>

        </div>



    </div>
</div>
<div class="modal fade" id="price-details" tabindex="-1" aria-labelledby="price-details" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered my-0">
        <div class="modal-content">
            <div class="modal-header ps-icon py-2">
                <h6 class="pt-1 mb-1">Price Detail</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                <span>&times;</span>
            </div>
            <div class="modal-body p-3">
                <div *ngIf="productDetailPriceConf.length > 0">
                    <div class="d-flex align-items-start justify-content-between" *ngIf="productDetailPriceConf[0].product_type == 198 || productDetailPriceConf[0].product_type == 208">
                        <h6 class="text-secondary">Qty </h6>
                        <div>
                            <span *ngFor="let dtl of productDetailPriceConf[0].details">
                                <div *ngIf="dtl.quantity" class="qtydetail text-secondary text-right">{{dtl.quantity ?
                                    dtl.quantity + ' * ' : ''}} {{convertToInt1(dtl.price, dtl.admin_price,productDetailPriceConf[0])
                                    ? convertToInt1(dtl.price, dtl.admin_price,productDetailPriceConf[0]) : ''}} =
                                    {{+dtl.quantity*convertToInt1(dtl.price,
                                    dtl.admin_price,productDetailPriceConf[0]) | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}
                                </div>
                            </span>
                            <h6 class="text-secondary text-right"> Total = {{productAmount | currency : 'INR' :
                                'symbol': '1.2-2' : 'en-IN'}}</h6>
                        </div>

                    </div>

                    <div class="d-flex align-items-start justify-content-between" *ngIf="productDetailPriceConf[0].product_type == 197 || productDetailPriceConf[0].product_type == 209 || !productDetailPriceConf[0].product_type">
                        <!-- <h6 class="text-secondary" *ngIf="productDetailPriceConf[0].product_type == 209"> Qty <span class="text-right float-end" *ngFor="let dtl of productDetailPriceConf"> </span> </h6> -->
                        <h6 class="text-secondary">Qty </h6>
                        <div class="">
                            <span *ngFor="let dtl of productDetailPriceConf">
                                <div class="qtydetail text-secondary text-right"> {{dtl.qtytotal ? dtl.qtytotal + ' * ' :
                                    ''}}{{convertToInt1(dtl.base_price, 0,productDetailPriceConf[0]) ? ''+convertToInt1(dtl.base_price, 0,productDetailPriceConf[0]) : ''}} =
                                    {{+dtl.qtytotal*convertToInt1(dtl.base_price, 0,productDetailPriceConf[0]) | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN'}}
                                </div>
                            </span>
                            <h6 class="text-secondary text-right mb-0">Total = {{productAmount | currency : 'INR' :
                                'symbol': '1.2-2' : 'en-IN'}}</h6>
                        </div>
                    </div>


                </div>
                <h6 class="text-secondary">
                    <span class="text-right float-right"></span>
                </h6>
                <h6 class="text-secondary"> GST Amount
                    <span class="text-right float-right">{{currentProGst ? '₹'+currentProGst : '00.00' | currency : 'INR' : 'symbol': '1.2-2' : 'en-IN' }}</span>
                </h6>
                <h6 class="text-secondary mb-3 border-bottom pb-3"> Shipping
                    <span class="text-right float-right">₹0.00</span>
                </h6>
                <h6>
                    <b>Total
                        <span class="text-right float-right">{{rupeeIndian.format(currentProGst ? productAmount +
                            currentProGst : productAmount)}}</span>
                    </b>
                </h6>
            </div>
        </div>
    </div>
</div>
<!-- Remove Item -->
<div class="modal fade" id="remove-item" tabindex="-1" aria-labelledby=" remove-item" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered my-0">
        <div class="modal-content">
            <div class="modal-header ps-icon py-2">
                <h6 class="pt-1 mb-1">Remove Item</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body px-3 py-4">
                <p class="font9 text-center"> Are you sure you want to remove this item? </p>
                <div class="text-center py-2">
                    <button type="button" class="btn py-2 btn-secondary px-3 px-lg-4 rounded-pill btn-sm mr-2" (click)="this.itmid=null;" data-dismiss="modal">
                        Cancel</button>
                    <button type="button" class="btn py-2 btn-theme2 px-lg-4 px-3 rounded-pill btn-sm" data-dismiss="modal" (click)="remove(itmid)">Remove</button>
                </div>
            </div>
        </div>

    </div>
</div>