import { Component, OnInit } from '@angular/core';
import {FormService} from '../../_services/form.service';
import { ToastrService } from 'ngx-toastr';
import{ LoginService } from 'src/app/_services/login.service'
import { Router,ActivatedRoute } from '@angular/router'; 
@Component({
  selector: 'app-card-display',
  templateUrl: './card-display.component.html',
  styleUrls: ['./card-display.component.css']
})
export class CardDisplayComponent implements OnInit {
  form_id: any="399";
  formList: any[]=[];
  viewList: any[]=[];
  rowList: any[]=[];
  finalCardViewArray : any[]=[];
  card_header: any="";
  form_viewid: any="";
  FormViewLoaded:Promise<boolean>;
  constructor(
    private formService: FormService,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private router: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe((res)=>{
      let data=this.loginService.decreptedDataUrl(res)
      if(data.length>1){
        var str = data;
        var value=str.split("&");        
        this.toastrService.toastrConfig.enableHtml=true;
        value.forEach(queryParm=>{
          if(queryParm.indexOf('frmid')>=0)
          {
            this.form_id=queryParm.split('=')[1];
          }          
          if(queryParm.indexOf('vid')>=0)
          {
            this.form_viewid=queryParm.split('=')[1];
          }          
        });
        this.getFormAndList(); 
      }
    });

    // this.getFormAndList();
  }

  getFormAndList(){
    let qry=`select form_id, form_name, table_name from app_forms where form_id=${this.form_id}; 
    select view_id,field_json,card_header from app_form_view_details where form_id=${this.form_id} and view_type=4 order by view_id;`
    this.formService.getFormData(qry).subscribe(res=>{
      this.formList=res[0].rows;
      this.viewList=res[1].rows;
      if(this.formList.length>0){
        this.getRowData(this.formList[0]["table_name"]);
      }
      if(this.viewList.length>0){
        this.card_header=this.viewList[0]["card_header"]?this.viewList[0]["card_header"]:"";
      }
    });
  }

  getRowData(table_name){
    let viewJsonList=this.viewList[0]["field_json"]?JSON.parse(this.viewList[0]["field_json"]):[];
    let columnsName='';
    if(viewJsonList.length>0){
      for(let r=0; r<viewJsonList.length; r++){
        columnsName=columnsName+viewJsonList[r]["field_name"]+", ";        
      }
    
    }
    if(columnsName != ""){
      columnsName= columnsName.substring(0,columnsName.length-2)
    }
    let finalArray=[], subrow=[];
    let rowQuery=`select ${columnsName} from ${table_name}`;
    this.formService.getFormData(rowQuery).subscribe(response=>{
      this.rowList=response.rows;  
      for(let n=0; n<this.rowList.length; n++){

        let colArray=columnsName.split(",")
        subrow=[];
        let obj=[];
        for(let m=0; m<colArray.length; m++){  
          let subobj={}          
          let key=colArray[m].trim();
          let val=this.rowList[n][(colArray[m]).trim()];
          subobj["key_val"]=val;   
          subobj["key"]=key;
          subobj["objFlag"]=false; 
              
          let arr=viewJsonList.filter(d=>d["field_name"] == key)
          if(arr.length>0){
            subobj["display_as"]=arr[0]["displayAs"];  
            subobj["filterJson"]=arr[0]["filterJson"];   
            subobj["field_caption"]=arr[0]["field_caption"];
          }else{
            subobj["display_as"]="Body";
            subobj["field_caption"]=arr[0]["field_caption"];
            subobj["filterJson"]=arr[0]["filterJson"]; 
          }  
            obj.push(subobj)             
        }
        subrow.push(obj)
        finalArray.push(obj)           
      }

      for(let a=0; a<finalArray.length; a++){
        let header=finalArray[a].filter(d=>d.display_as == "header");
        let title=finalArray[a].filter(d=>d.display_as == "subtitle")
        let subtitle=finalArray[a].filter(d=>d.display_as == "title")
        let image=finalArray[a].filter(d=>d.display_as == "image")
        let body=finalArray[a].filter(d=>d.display_as == "body")
        let footer=finalArray[a].filter(d=>d.display_as == "footer")

        this.finalCardViewArray.push({
          header:header[0]["key_val"],
          title:title.length>0?title[0]["key_val"]:"",
          subtitle:subtitle.length>0?subtitle[0]["key_val"]:"",
          image:image.length>0?image[0]["key_val"]:"",
          body:body,
          footer:footer.length>0?footer[0]["key_val"]:"Subscribe now",
        })
      }
      console.log("pks==>",this.finalCardViewArray.length)
      this.FormViewLoaded=Promise.resolve(true);
    });
  }
}
