<div class="d-flex minhyt80" id="wrapper">
      <div class="" id="page-content-wrapper">
        <app-header (profileIdChange)="onProfileIdChange($event)"> </app-header>
        <div class="webdesk">
          <app-left-menu></app-left-menu>
      </div>
    <section class="bckgry">
      <div class="container-fluid py-2">
        <div class="row mb-3 dashboar_new">
          <div class="col-lg-9 prr-1-new">
            <div class="card mt-2 minhy30">
              <div class="card-body px-3 pt-1">
                <h5 class="linktitle mt-2 mb-1">
                  <img src="../../../assets/images/icon/task.svg" />&nbsp;&nbsp;Task
                </h5>
                <div class="row pt-1">

                  <div class="col-md-6 col-sm-12 col-lg-4 my-2" style="cursor: pointer;" (click)="bindData(1)">
                    <div class="card shadow-none">
                      <div class="card-body">
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="pt-2 pb-3 linktitle">
                            <strong>Draft</strong>
                          </h5>
                          <div class="rotate mb-2">
                            <img src="../../../assets/images/process.svg" class="img-fluid imgbg" style="width: 60px;position: absolute;">
                            <h1 class="display-4 mb-0 text-right">{{newCompanyCount}}</h1>
                            <p class="text-right  fw-600 ble  mb-0">Draft Requests</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 col-lg-4 my-2" style="cursor: pointer;" (click)="bindData(2)">
                    <div class="card shadow-none">
                      <div class="card-body">
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="pt-2 pb-3 linktitle">
                            <strong>In Process</strong>
                          </h5>
                          <div class="rotate mb-2">
                            <img src="../../../assets/images/process.svg" class="img-fluid imgbg" style="width: 60px;position: absolute;">
                            <h1 class="display-4 mb-0 text-right">{{newOderCount}}</h1>
                            <p class="text-right  fw-600 ble  mb-0">Verification Requests</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 col-lg-4 my-2" style="cursor: pointer;" (click)="bindData(3)">
                    <div class="card shadow-none">
                      <div class="card-body">
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="pt-2 pb-3 linktitle">
                            <strong>Verification Requests</strong>
                          </h5>
                          <div class="rotate mb-2">
                            <img src="../../../assets/images/verify.svg" class="img-fluid imgbg" style="width: 60px;position: absolute;">
                            <h1 class="display-4 mb-0 text-right">{{verifiedCompanyCount}}</h1>
                            <p class="text-right  fw-600 ble  mb-0">Report Generated</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6 col-sm-12 col-lg-3 my-2">
                    <div class="card shadow-none">
                      <div class="card-body">
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="pt-2 pb-3 linktitle"><strong>Total Company Order</strong></h5>
                          <div class="rotate mb-2">
                            <img src="../../../assets/images/cardissues.svg" class="img-fluid imgbg"
                              style="width: 60px;position: absolute;">
                            <h1 class="display-4 mb-0 text-right">21</h1>
                            <p class="text-right  fw-600 ble  mb-0">Action</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2">
                    <div class="card shadow-none">
                      <div class="card-body">
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="pt-2 pb-3 linktitle"><strong>Total Card Issue</strong></h5>
                          <div class="rotate mb-2">
                            <img src="../../../assets/images/monthlychart.svg" class="img-fluid imgbg"
                              style="width: 60px;position: absolute;">
                            <h1 class="display-4 mb-0 text-right">20</h1>
                            <p class="text-right  fw-600 ble  mb-0">Action</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 pll-0 mt-2">
            <div class="card   minhy30">
              <div class="card-body px-3 pt-1">
                <div class=" pt-1">
                  <h5 class="linktitle mt-1 mb-3">
                    <img src="../../assets/images/icon/bell.svg" />&nbsp;&nbsp;{{labelObj ? (isHindi ? labelObj.notification.labelHn : labelObj.notification.labelEn):'Notification'}}
                  </h5>
                  <div class="">
                    <div class="col-sm-12 alert alert-warning">
                      <label>{{labelObj ? (isHindi ? labelObj.annualdecalaration.labelHn : labelObj.annualdecalaration.labelEn):'Please
                        Declare Annual Asset Declaration for Next Financial Year before 15 April 2020.'}}</label>
                    </div>

                    <div class="col-sm-12 alert alert-warning">
                      <label>{{labelObj ? (isHindi ? labelObj.fillingPARs.labelHn : labelObj.fillingPARs.labelEn):'Regarding Filling
                        PARs -Please fill PARs online through HRMS.'}}</label>
                    </div>
                    <div class="col-sm-12 alert alert-warning">
                      <label>{{labelObj ? (isHindi ? labelObj.updateeventnotify.labelHn : labelObj.updateeventnotify.labelEn):'Please
                        update all event Service book entry.'}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isCompanyList == 1">

          <div class="col-lg-12">
            <div class="card shadow-none">
              <div class="card-body p-0">
                <h6 class="mb-1 p-2 mt-1">
                  <img src="../../../assets/images/icon/task.svg" style="width: 22px;" />&nbsp;&nbsp; Employee Detail</h6>

                <div class="grid__wrapper tableMain p-0">
                  <igx-grid #grid1 [data]="companyData" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="5" rowSelection="single">
                    <ng-container *ngFor="let col of columnDefsCompSummary  let i=index">
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable"
                        [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                          {{cell.value}}
                        </ng-template>
                      </igx-column>
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string"
                        [sortable]="col.sortable" [filterable]="col.filter"></igx-column>
                    </ng-container>
                  </igx-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isCompanyList == 2">

          <div class="col-lg-12">
            <div class="card shadow-none">
              <div class="card-body p-0">
                <h6 class="mb-1 p-2 mt-1">
                  <img src="../../../assets/images/icon/task.svg" style="width: 22px;" />&nbsp;&nbsp; Employee Detail</h6>

                <div class="grid__wrapper tableMain p-0">
                  <igx-grid #grid1 [data]="orderCompanyData" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="5" rowSelection="single">
                    <ng-container *ngFor="let col of submitColumnDefsCompSummary  let i=index">
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable"
                        [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                          {{cell.value}}
                        </ng-template>
                      </igx-column>
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string"
                        [sortable]="col.sortable" [filterable]="col.filter"></igx-column>
                    </ng-container>
                  </igx-grid>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row" *ngIf="isCompanyList == 3">

          <div class="col-lg-12">
            <div class="card shadow-none">
              <div class="card-body p-0">
                <h6 class="mb-1 p-2 mt-1">
                  <img src="../../../assets/images/icon/task.svg" style="width: 22px;" />&nbsp;&nbsp; Submitted Detail</h6>

                <div class="grid__wrapper tableMain p-0">
                  <igx-grid #grid1 [data]="verifiedcompanyData" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                    [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="5">
                    <ng-container *ngFor="let col of verifiedCompanyColumnDefSummary  let i=index">
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]="col.width" [sortable]="col.sortable"
                        [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                          {{cell.value}}
                        </ng-template>
                      </igx-column>
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i != 0" dataType="string"
                        [sortable]="col.sortable" [filterable]="col.filter"></igx-column>
                    </ng-container>
                  </igx-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</div>