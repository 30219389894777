<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid  bg-white">
      <ngx-loading [show]="isLoader" class="mngorderloader"></ngx-loading>
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>
              <!-- <li class="breadcrumb-item newitm1"><a href="javascript:void(0)" routerLink="/total-seller"> Seller List</a></li> -->
              <li class="breadcrumb-item newitm2  active" aria-current="page">Manage All order</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="date-filter-box">
        <div class="mt-2">
          <h6 class="font-weight-bold mb-sm-3">Select a time period</h6>
          <div class="row mb-3">
            <div class="col-sm-2 col-md-1 text-sm-right">
              <label for="start-date">From Date</label>
            </div>
            <div class="col-sm-3 col-md-2">
              <input type="date" class="form-control" [(ngModel)]="fromDate" id="start-date" max="{{getMinDate()}}">
            </div>
            <div class="col-sm-2 col-md-1 text-sm-right">
              <label for="end-date">To Date</label>
            </div>
            <div class="col-sm-3 col-md-2  pl-md-0">
              <input type="date" class="form-control" [(ngModel)]="todate" id="end-date" max="{{getMinDate()}}">
            </div>
            <div class="col-sm-3 col-md-2 pl-md-0 text-sm-left text-center">
              <button (click)="filterdatewise()" class="btn btn-sm btn-theme py-1 px-sm-3 px-4 mt-2 mt-md-0">
                <i class="fa fa-search mr-1"></i> Search</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 my-2 p-0">
        <div class="tabs row my-2 mx-0">
          <div class="col-sm-9 px-0 mb-sm-0 mb-2">
            <div class="white-space-pre-x">
              <div class="tab-button-outer1">
                <ul id="tab-button" class="tbsbtns ollist">
                  <li id="tabDraft" class="is-active">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(187); SearchValue.value = '' ">Pending
                      <span>({{pendingOrderCount}})</span>
                    </a>
                  </li>
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(194); SearchValue.value = '' ">Accepted
                      <span>({{acceptedOrderCount}})</span>
                    </a>
                  </li>
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(192); SearchValue.value = '' ">Dispatch to Shipper
                      <span>({{readyToShipedOrderCount}})</span>
                    </a>
                  </li>
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(189); SearchValue.value = '' ">Shipped
                      <span>({{shipedOrderCount}})</span>
                    </a>
                  </li>
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(193); SearchValue.value = '' ">Delivered
                      <span>({{deleveredOrderCount}})</span>
                    </a>
                  </li>
                  <!-- <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(188)">Rejected <span>({{rejectOrderCount}})</span>  
                          </a>
                        </li> -->
                  <li id="" class="">
                    <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(190, 191); SearchValue.value = '' ">Cancelled
                      <span>({{cancledOrderCount}})</span>
                    </a>
                  </li>

                  <!-- <li id="" class="">
                          <a href="javascript:void(0)" class="font8" >Archived List <span>(0)</span>  
                          </a>
                        </li> -->
                  <!-- <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="">Archived List <span>(0)</span>  
                          </a>
                        </li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-3 px-sm-3 px-0" id="masterSearch">
            <div class=" input-group">
              <input type="search" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
              <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>

        </div>
        <div id="tab01" class="mmt5 p-0">
          <div class="col-sm-12 nopadding">
            <div id="grid-theme-wrapper">
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination2 == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination3 == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination4 == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination5 == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination6 == true"></app-hds-main-tab-group>

              <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->

              <igx-grid *ngIf="allOrderList.length > 0" #grid1 [data]="allOrderList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                [filterMode]="'excelStyleFilter'">
                <igx-column field="date" header="Order Date" width="190">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl " [innerHtml]="cell.value">
                      </span>
                    </div>
                  </ng-template>
                </igx-column>

                <igx-column field="order_number" header="Order Details" width="300">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">

                      <!-- <span class="cell-vl "  [innerHtml]="cell.value">
                            </span> -->
                      <span class="cell-vl">{{cell.rowData.order_number}}</span>
                      <span class="cell-vl ">Buyer Name : <span class="fontTheme pl-0 font600">{{cell.rowData.full_name}}</span>
                      </span>

                    </div>
                  </ng-template>
                </igx-column>
                <igx-column field="product_name" header="Product Details" width="300">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <a href="javascript:void(0)">
                        <span class="cell-vl text-capitalize" (click)="onProductdetailView(cell)">{{cell.value }}</span>
                      </a>
                      <span class="cell-vl ">SKU : {{cell.rowData.sku}}</span>
                      <span class="cell-v1 ">Quantity : {{cell.rowData.total_quantity}}</span> <br>
                      <span *ngIf="cell.rowData.total_quantity>0 && countextrafeature(cell.rowData.extra_features)">Extra Price: ₹{{countextrafeature(cell.rowData.extra_features) * (cell.rowData.total_quantity)}}</span><br>
                      <span class="cell-v1 ">Total Amt : <b> ₹{{cell.rowData.is_bundle ? (cell.rowData.order_price * cell.rowData.total_quantity) : (cell.rowData.product_price * cell.rowData.total_quantity)}} </b> </span>
                      <!-- <span class="cell-v1 " *ngIf="cell.rowData.is_bundle">Total Amt : <b> ₹{{cell.rowData.order_price * cell.rowData.total_quantity}} </b> </span> -->
                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="suk" header="SKU" width="292">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column> -->
                <!-- <igx-column field="total_amount" header="Total Amount" width="292">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column> -->
                <!-- <igx-column field="total_quantity" header="Quantity" width="292">
                        <ng-template #compositeTemp igxCell let-cell="cell">
                          <div class="address-container">
                            <span class="cell-vl " [innerHtml]="cell.value"></span>
                          </div>
                        </ng-template>
                      </igx-column> -->
                <igx-column field="seller_name" header="Seller Name" width="170">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl" [innerHtml]="cell.value"></span>
                    </div>
                  </ng-template>
                </igx-column>

                <!-- <igx-column field="full_name" header="Buyer Name" width="292">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container">
                            <span class="cell-vl " [innerHtml]="cell.value"></span>
                         
                        </div>
                      </ng-template>
                    </igx-column> -->
                <igx-column field="address" header="Buyer Address" width="300">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class="cell-vl" [innerHtml]="cell.value"></span>

                    </div>
                  </ng-template>
                </igx-column>
                <!-- <igx-column field="" header="Action" width="" *ngIf="LoadPagination4 == true">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container px-2">
                      <div class="btn-group" role="group" data-toggle="dropdown" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="py-2 px-3">Action</span>
                          <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                        </button>
                        <ul class="dropdown-menu p-0" aria-labelledby="btnGroupDrop11">
                          <li class="border-bottom">
                            <a href="javascript:void(0)"  class="text-decoration-none font-weight-bold py-2 px-2 text-dark dropdown-item">
                              <img class="img-fluid icon-widthd" src="../../../assets/images/icon/delivery.svg">

                               Delivery status</a>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </igx-column> -->
                <!--Invoice-->
                <igx-column field="" header="Action" width="" *ngIf="LoadPagination4 == true">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container px-2">
                      <div class="btn-group" role="group" data-toggle="dropdown" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="py-2 px-3">Action</span>
                          <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                        </button>
                        <ul class="dropdown-menu p-0" aria-labelledby="btnGroupDrop11">
                          <li class="border-bottom">
                            <a href="javascript:void(0)" class="text-decoration-none font-weight-bold py-2 px-2 text-darkorange dropdown-item" (click)="generatePdf(cell)">
                              <i class="fa fa-download" aria-hidden="true"></i>
                              Seller Invoice</a>
                          </li>
                          <li class="border-bottom">
                            <a href="javascript:void(0)"  class="text-decoration-none font-weight-bold py-2 px-2 text-primary dropdown-item" (click)="generatePdfBuyer(cell)">
                              <i class="fa fa-download" aria-hidden="true"></i>
                              Buyer Invoice </a>
                          </li>                         
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </igx-column>
              </igx-grid>
              <label class="w-100 text-center text-dark" *ngIf="allOrderList.length == 0">No Record Found</label>
            </div>


          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- view model -->

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header p-2 align-items-center">
          <h6 class="modal-title" id="exampleModalLabel">Product Details</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body minhyt70 p-0" *ngIf="isView">
          <app-product-details [isView]="isView" [view_item_id]="view_item_id"></app-product-details>
        </div>
      </div>
    </div>
  </div>

</div>