<div class="d-flex minhyt80" id="wrapper">
        
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>
        <ngx-loading [show]="isLoader"></ngx-loading>
        <div class="container-fluid pb-0">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb p-0">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Add Seller</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <!-- <div class="container d-flex align-items-center justify-content-center pt150">
                <img class="imgSoon" src="../../assets/images/comingDiv.svg" alt="">
            </div> -->
            <div class="container-fluid pb-0">
                <div class="tabs row my-2">
                    <!-- <ul class="breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Pictures</a></li>
                      </ul> -->
                    <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
                        <ul id="tab-button">
                            <li id="tabDraft" class="is-active">
                                <a href="javascript:void(0)" class="font8">Seller Registeration
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-2" *ngIf="personal">
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-header p-2">
                                <h6 class="font600 font9 mb-0">Personal Details</h6>
                            </div>
                            <div class="card-body pt-3">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <label class="font8 ">Your Name
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" disabled id="personal-name" type="text" maxlength="50" [(ngModel)]="sellerName" name="sellerName"
                                        placeholder="Enter Name" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">Mobile Number
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" disabled id="mobile-no" type="text" [(ngModel)]="mobileNo" name="mobileNo" maxlength="10" placeholder="Enter Mobile No."
                                                        />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">Email
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="email" disabled type="text" maxlength="100" name="email" [(ngModel)]="email" placeholder="Enter Email"
                                        />
                                    </div>
                                    <!-- <div class="col-sm-3">
                                        <label class="font8 ">Password
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" class="form-control">
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2" >
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-header p-2">
                                <h6 class="font600 font9 mb-0">Business Details</h6>
                            </div>
                            <div class="card-body pt-3">
                                <div class="row">
                                    <div class="col-sm-3 mb-2">
                                        <label class="font8 ">Business Name
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="bsn" type="text" [(ngModel)]="businessName" name="businessName" maxlength="150" placeholder="Enter Business Name">
                                    </div>
                                    <div class="col-sm-3 mb-2">
                                        <label class="font8 ">Ware House (Item dispatch from here)
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="store" type="text" [(ngModel)]="storeName" name="storeName" maxlength="150" placeholder="Enter ware house"
                                                        />
                                    </div>
                                    <div class="col-sm-3 mb-2">
                                        <label class="font8 ">Business Type
                                            <span class="asterisk">*</span>
                                        </label>
                                        <ng-select [(ngModel)]="businessType" name="businessType" placeholder="Select Business Type">
                                            <!-- <ng-option value="0" selected disabled>Business Type</ng-option> -->
                                            <ng-option value="1">Partnership</ng-option>
                                            <ng-option value="2">Corporation</ng-option>
                                            <ng-option value="3">Nonprofit Organization</ng-option>
                                            <ng-option value="4">Cooperative</ng-option>
                                        </ng-select>

                                    </div>
                                    <div class="col-sm-3 mb-2">
                                        <label class="font8 ">Product Category
                                            <span class="asterisk">*</span>
                                        </label>
                                        <ng-select class="product_category" [(ngModel)]="prod_Catagory" [multiple]="true" name="prod_Catagory" placeholder="Select Product Category">
                                            <ng-option *ngFor="let c of productCatagory" [value]="c.product_category_id"> {{c.category_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">GSTIN
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="otp" type="text" [(ngModel)]="gstNo" name="gstNo" maxlength="15" placeholder="Enter GSTIN"
                                                            style="text-transform: uppercase" />
                                    </div>
                                   
                                    <div class="col-sm-3">
                                        <label class="font8 ">PAN
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="panNo" type="text" [(ngModel)]="panNo" name="panNo" maxlength="10" placeholder="Enter PAN"
                                                            style="text-transform: uppercase" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">Company Strength
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="companyStrength" type="text" (keypress)="commonFunctionService.numericCheck($event)" [(ngModel)]="companyStrength"
                                        name="companyStrength" maxlength="5" placeholder="Enter Company Strength"
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-header p-2">
                                <h6 class="font600 font9 mb-0">Bank Account Info:</h6>
                            </div>
                            <div class="card-body pt-3">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <label class="font8 ">Account Holder's Name
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="name" type="text" maxlength="50" [(ngModel)]="accountHolderName" name="accountHolderName"
                                                            placeholder="Enter Account Holder's Name"  (keypress)="commonFunctionService.alphabetsValidator($event)"/>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">Account Type
                                            <span class="asterisk">*</span>
                                        </label>
                                        <ng-select [(ngModel)]="accountType" name="accountType" placeholder="Select Account Type">




                                            <ng-option value="1">Saving</ng-option>
                                            <ng-option value="2">Salary</ng-option>
                                            <ng-option value="3">Current</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">IFSC Code
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="ifsc" type="text" [(ngModel)]="ifscCode" name="ifscCode" maxlength="11" placeholder="Enter IFSC Code"
                                        style="text-transform: uppercase" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">Account No.
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="account" type="text" (keypress)="commonFunctionService.numericCheck($event)" [(ngModel)]="accountNo"
                                        name="accountNo" maxlength="20" placeholder="Enter Account No." />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2" >
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-header p-2">
                                <h6 class="font600 font9 mb-0">Business Address:</h6>
                            </div>
                            <div class="card-body pt-3">
                                <div class="row">
                                    <div class="col-sm-3 mb-2">
                                        <label class="font8">Plot No./ Block No.
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="block" type="text" ondrop="return false;" autocomplete="off" [(ngModel)]="bplotNo" name="bplotNo"
                                                            maxlength="150" placeholder="Enter Plot No./ Block No." />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8">Street Name
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="sn" type="text" ondrop="return false;" autocomplete="off" [(ngModel)]="bstreetName" name="bstreetName"
                                        maxlength="150" placeholder="Enter Street Name" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8">PIN Code
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="pin" ondrop="return false;" type="text" maxlength="6" (keyup)="getBCityAndState()" (keypress)="commonFunctionService.numericCheck($event)"
                                        autocomplete="off" [(ngModel)]="bpinCode" name="bpinCode" placeholder="Enter PIN Code"
                                    />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8">City
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" type="text" onkeydown="return false;" ondrop="return false;" name="bcity" placeholder="City"
                                        [(ngModel)]="bcity" disabled/>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">State
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select name="bstate" class="form-control" id="bstate" onkeydown="return false;" ondrop="return false;" [(ngModel)]="businessStateId"
                                        disabled>

                                        <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                            {{s.state_name}}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-header d-flex justify-content-between p-2">
                                <h6 class="font600 font9 mb-0">Pickup Address:</h6>
                                    <!-- <div class="form-group form-check">
                                        
                                        <label class="font8 font600" for="exampleCheck1">Same as Business Address</label>
                                    </div> -->
                            </div>
                            <div class="card-body pt-3">
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-check form-check-inline my-md-0 my-1">
                                            <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="adress" name="adress" value="adress" [(ngModel)]="fillAddress" (change)="RepeatAdress()">
                                            <label class="form-check-label ml-2" for="">Same as Business Address</label>
                                        </div>

                                        <!-- <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="adress" name="adress" value="adress" [(ngModel)]="fillAddress" (change)="RepeatAdress()">
                                            <label class="form-check-label ms-0" for="adress">
                                                Same as Business Address
                                            </label>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-3 mb-2">
                                        <label class="font8">Plot No./ Block No.
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="block1" type="text" ondrop="return false;" [(ngModel)]="splotNo" name="splotNo" maxlength="150"
                                                            [disabled]="fillAddress" placeholder="Enter Plot No./ Block No." />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8">Street Name
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="sn1" type="text" ondrop="return false;" [(ngModel)]="sstreetName" name="sstreetName" maxlength="150"
                                        autocomplete="off" placeholder="Enter Street Name" [disabled]="fillAddress"
                                    />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8">PIN Code
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" id="pin1" type="text" (keyup)="getSCityAndState()" ondrop="return false;" (keypress)="commonFunctionService.numericCheck($event)"
                                                            autocomplete="off" [(ngModel)]="spinCode" name="spinCode" maxlength="6"
                                                            placeholder="Enter PIN Code" [disabled]="fillAddress" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8">City
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input class="form-control" type="text" name="scity" placeholder="City" onkeydown="return false;" ondrop="return false;"
                                                            [(ngModel)]="scity" disabled />
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="font8 ">State
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select name="state1" class="form-control" id="state1" onkeydown="return false;" ondrop="return false;" [(ngModel)]="storeStateId"
                                        disabled>
                                        <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                            {{s.state_name}}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 p-0">
                        <div class="card shadow-none">
                            <div class="card-header p-2">
                                <h6 class="font600 font9 mb-0">Documents Upload:</h6>
                            </div>
                            <div class="card-body pt-3">
                                <div class="row">
                                    <div class="col-sm-6 mb-2">
                                        <label class="font8">Select Identification Proof
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select class="form-control" id="aa" name="identificationProof" [(ngModel)]="identificationProof" (change)="selectIdProff()">
                                                        <option value="undefined" selected disabled>Select Identification Proof</option>
                                                        <option *ngFor="let pro of identificationProofList" value="{{pro.id}}">{{pro.varibale_name}}</option>
                                                        <!-- <option value="2">Indian Passport</option>
                                                        <option value="3">Aadhar Card</option>
                                                        <option value="4">Driving Licence</option>
                                                        <option value="5">Voter ID Card</option> -->

                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                            
                                        <div class="" *ngIf="panCard">
                                            <label for="">PAN Card
                                                <span class="asterisk">*</span>
                                            </label>
                                            
                                            <div class="form-group input-group">
                                                <input type="file"  class="form-control" (change)="onIdProofFileChnage($event,1,'id')" #inputFile>

                                            </div>
                                        </div>
                                        <div class="" *ngIf="cerOfIncorporation">
                                            <label for="">Certificate of Incorporation
                                                <span class="asterisk">*</span>
                                            </label>
                                            <div class="form-group input-group">
                                                <input type="file"  class="form-control" (change)="onIdProofFileChnage($event,1,'id')" #inputFile>

                                            </div>
                                        </div>
                                        <div class="" *ngIf="rentAggrement">
                                            <label for="">Rent Agreement
                                                <span class="asterisk">*</span>
                                            </label>
                                            <div class="form-group input-group">
                                                <input type="file"   class="form-control" (change)="onIdProofFileChnage($event,1,'id')" #inputFile>

                                            </div>
                                        </div>
                                        <div class="" *ngIf="gstCertificate">
                                            <label for="">GSTIN Certificate
                                                <span class="asterisk">*</span>
                                            </label>
                                            <div class="form-group input-group">
                                                <input type="file"  class="form-control" (change)="onIdProofFileChnage($event,1,'id')" #inputFile>

                                            </div>
                                        </div>
                                        <div class="" *ngIf="teleLanLineBill">
                                            <label for="">Telephone Landline Bill
                                                <span class="asterisk">*</span>
                                            </label>
                                            <div class="form-group input-group ">
                                                <input type="file"  class="form-control" (change)="onIdProofFileChnage($event,1,'id')" #inputFile>

                                            </div>
                                        </div>
                                        <!-- <div class="" *ngIf="gstCertificate">
                                            <label for="">Voter ID Back</label>
                                            <div class="form-group input-group">
                                                <input type="file" class="form-control"
                                                    (change)="onFileChnage($event)" #inputFile>

                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <label class="font8 ">Select Address Proof
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select class="form-control" id="ac" [(ngModel)]="addressProff" (change)="selectAddressProff()" name="addressProff">
                                            <option value="undefined" selected disabled>Select Address Proof</option>
                                            <option *ngFor="let pro of addressProffList" value="{{pro.id}}">{{pro.varibale_name}}</option>
                                            <!-- <option value="1">PAN Card</option>
                                            <option value="2">Indian Passport</option>
                                            <option value="3" selected>Aadhar Card</option>
                                            <option value="4">Electricity Bill(latest) </option>
                                            <option value="5">Bank Statement </option> -->

                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-2" *ngIf="panCard1">
                                            <label class="font8 ">PAN Card
                                                <span class="asterisk">*</span>
                                            </label>
                                            <input type="file" class="form-control" [(ngModel)]="File" (change)="onIdProofFileChnage($event,2,'add',1)" [ngModelOptions]="{standalone: true}">
                                        </div>
                                    <div class="col-sm-6 mb-2" *ngIf="cerOfIncorporation1">
                                        <label class="font8 ">Certificate of Incorporation
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="file" class="form-control" [(ngModel)]="File1" (change)="onIdProofFileChnage($event,2,'add',2)" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-sm-6 mb-2" *ngIf="rentAggrement1">
                                        <label class="font8 ">Rent Agreement
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="file" class="form-control" [(ngModel)]="File2" (change)="onIdProofFileChnage($event,2,'add', 3)" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-sm-6 mb-2" *ngIf="gstCertificate1">
                                            <label class="font8 ">GSTIN Certificate
                                                <span class="asterisk">*</span>
                                            </label>
                                            <input type="file" class="form-control" [(ngModel)]="File3" (change)="onIdProofFileChnage($event,2,'add', 4)" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        
                                                <div class="col-sm-6 mb-2" *ngIf="teleLanLineBill1">
                                                        <label class="font8 ">Telephone Landline Bill
                                                            <span class="asterisk">*</span>
                                                        </label>
                                                        <input type="file" class="form-control" [(ngModel)]="File4" (change)="onIdProofFileChnage($event,2,'add', 5)" [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                    <div class="col-sm-6 mb-2" *ngIf="addressProff != 221 && identificationProof != 221">
                                                            <label class="font8 ">Registered Business(GSTIN Certificate)
                                                                <span class="asterisk">*</span>
                                                            </label>
                                                            <input type="file" class="form-control" (change)="onDocFileChnage($event,1)" #inputFile>
                                                        </div>
                                            <div class="col-sm-6 mb-2">
                                                <label class="font8 "> Cancelled cheque 
                                                        <span class="asterisk">*</span>
                                                </label>
                                                <input type="file" class="form-control"  (change)="onDocFileChnage($event,2,6)" [(ngModel)]="File5" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-3 text-center">
                    <button class="btn px-lg-4 px-3 btn-theme mr-2" (click)="submit()">Submit</button>
                    <button class="btn px-lg-4 px-3 btn-secondary" (click)="cancle()" >Cancel</button>
                </div>
            </div>
        </div>


        <div class="modal fade" id="acknow" tabindex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyword="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header bg-white border-0 py-1">
                        <!-- <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button> -->
                        </div>
                        <!-- 
                            <h5 class="modal-title">Acknowledgement</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> -->
                        <div class="modal-body">
                        
                            <div class="row">
                                <div class="col-md-10 col-12 mx-auto">
                                    <div class="text-center ack_doc">
                                        <img class="mb-3" src="../../assets/images/check-circle.gif" alt="" width="130">
                                        <h4 class="text-success"> Successfully Registered</h4>
                                        <h6>Welcome {{sellerName}} </h6>
                                        <!-- <h6 class="mb-5">User ID: {{seller_email}}</h6> -->
            
                                        <!-- <a class="mb-4 btn btn-sm btn-theme px-5" (click)="login()">Login</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>

    