
<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>

<ngx-loading [show]="isLoader"></ngx-loading>
<div class="container-fluid  ">
    <div class="row">
        <div class="col-sm-12 p-0">
            <app-breadcrumb [FormName]="formName" [FileNo]="draft_requestid"></app-breadcrumb>  
        </div>
    </div>
    <div class="row d-flex">
        <div class="col-sm-6 fileresp leftgreensheet">
            <div class="widget-box widget-color-blue m-0">
                <div class="widget-header d-flex" style="place-content: center; align-items: center;">
                    <h5 class="widget-title bigger lighter h text-white pr-5">
                        <!-- <i class="ace-icon fa fa-file"></i> File <span *ngIf="draft_requestid != ''">&nbsp;:&nbsp;{{draft_requestid}}</span> -->
                        
                        <a href="javascript:void(0);" *ngIf="isApproved == true" (click)="approve()" class="greensheetlink big180 mr-1 px-3  btn-primary btn-sm" title="Approved" ><i class="fa fa-check big-150"></i></a>
                        <a href="javascript:void(0);" *ngIf="isReject == true" (click)="reject()"  class="greensheetlink big180 mr-1 px-3  btn-sm btn-danger" title="Rejected" ><i class="fa fa-times big-150"></i></a>
                        <a href="javascript:void(0);" *ngIf="isParked == false" (click)="parked()"  class="greensheetlink big180 px-3  btn-sm btn-warning" title="Parked" ><i class="fa fa-hand-o-right big-150"></i></a>
                    </h5>
                    <div class="rytcont m-auto" style="display: inherit;">

                        <a (click)="pageMinus()" href="javascript:void(0)" class="btn btn-sm btn-warning mr-1" style="border-radius: 5px;" id="linkPrevious" [class.disabled]="nextPreviousCount==1?true:false">&lt; </a>
                        <!-- <select [(ngModel)]="nextPreviousCount" id="ddlNotePaging" style="width:90px;display:inline;height: 30px !important;border-radius: 5px !important" class="form-control mr-1" (change)="pageskip($event.target.value)">
                            <option *ngFor="let data of selectArray" [value]="data.page_no">
                                Page {{data.page_no}}
                            </option>
                        </select> -->
                        <ng-select id="ddlNotePaging" [(ngModel)]="nextPreviousCount" (change)="pageskip(nextPreviousCount)" style="margin-top:-3px;width:90px;display:inline;height: 30px !important;border-radius: 5px !important">
                            <ng-option *ngFor="let item of selectArray" [value]="item.page_no">Page &nbsp;{{item.page_no}}</ng-option>
                        </ng-select>
                        <a (click)="pagePlus()" id="linkNext" class="btn btn-sm btn-warning" href="javascript:void(0)" [class.disabled]="nextPreviousCount==selectArray.length?true:false"> &gt; </a> &nbsp; &nbsp;
                    </div>
                    <!-- <a href="javascript:showsendingdetail();" class="greensheetlink" id="btnSendDfa" style="visibility: visible;"><i class="fa fa-arrow-circle-right"></i> Send DFA</a> -->
                    <div class="">
                        <a href="javascript:void(0)" *ngIf="viewStatus == false" (click)="sendreq()" class="greensheetlink" id="btnSend" style="visibility: visible;"><i class="fa  fa-mail-forward"></i> Send</a>
                        <a href="javascript:void(0)" *ngIf="viewStatus == false" (click)="addnote()" class="greensheetlink"><i
                                class="fa fa-plus-circle"></i>Add Note</a>
                        <!-- <a (click)="printDiv('printableArea')" class="greensheetlink big180" style="font-size:15px;" title="Print"><i
                                class="fa fa-print big-150"></i></a> -->
                        <a href="javascript:void(0);" class="greensheetlink big180" (click)="isPrint=true" title="Print by select" ><i
                            class="fa fa-print big-150"></i></a>
                        <a href="javascript:void(0);" class="greensheetlink big180" (click)="printAllClick()" title="Print All" style="font-size:15px;color: #dd5a43!important"><i
                                class="fa fa-file-pdf-o big-150 bg-white"></i></a>
                        
                    </div>



                </div>

                <div class="widget-body">
                    <div class="widget-main no-padding minhyt450 notesheet">
                        <div class="col-lg-12 nopadding" id="wizard">

                            <h2 style="display: none">Fisrt Step</h2>
                            <section class="pt-0" hidden id="printArea" style="background-color: #90EE90!important;">
                                <table class="table" id="tbl1">
                                    <tbody>
                                        <tr *ngFor="let notsheet of printList">
                                            <td class="armada-devider">
                                                <p class="txtcenter">
                                                    {{notsheet.notesheet_sr_no}}.{{notsheet.notesheet_para_no}}</p>
                                                <!-- <p *ngIf="notsheet.createdby_userid==userId"><a
                                                        href="javascript:ancEditNote('104760','0','0','1');">
                                                        <button (click)="onEditClick(notsheet.notesheetid)"
                                                            class="btn btn-sm btn-info" title="Edit"><i
                                                                class="ace-icon fa fa-pencil bigger-120"></i></button></a>
                                                    <br><br> <a href="javascript:ancDeleteNote('104760');">
                                                        <button (click)="onDeleteClick(notsheet.notesheetid)"
                                                            class="btn btn-sm btn-danger" title="Delete"><i
                                                                class="ace-icon fa fa-trash-o bigger-120"></i></button></a>
                                                </p> -->
                                            </td>
                                            <td class="driver-devider">
                                                <p><b>Date &amp; Time</b>: {{notsheet.noting_date}}</p>
                                                <div id="txtNote1" class="txtnoteleft">
                                                    <p>{{notsheet.notesheet_containt}}</p>
                                                </div>
                                                <!-- <p class="text-right">
                                                    <img class="signedwidth" src="../assets/imgSignature/1001.png">
                                                </p> -->
                                                <p class="text-right mb-0">{{notsheet.user_fname}}</p>
                                                <p class="text-right usrname"><b>{{notsheet.role_name}}</b> <br></p>
                                            </td>
                                            <td class="progress-devider">
                                                <p class="text-right " *ngIf="notsheet.reference_id">
                                                    <a (click)="modalOpen(notsheet.reference_id)" data-toggle="modal" data-target="#exampleModal">{{notsheet.reference_name}}</a><br><br>
                                                </p>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                            <section class="pt-0" hidden id="printAreaAll" style="background-color: #90EE90!important;">
                                <table class="table" id="tbl1">
                                    <tbody>
                                        <tr *ngFor="let notsheet of notesheetList">
                                            <td class="armada-devider">
                                                <p class="txtcenter">
                                                    {{notsheet.notesheet_sr_no}}.{{notsheet.notesheet_para_no}}</p>
                                            </td>
                                            <td class="driver-devider">
                                                <p><b>Date &amp; Time</b>: {{notsheet.noting_date}}</p>
                                                <div id="txtNote1" class="txtnoteleft">
                                                    <p>{{notsheet.notesheet_containt}}</p>
                                                </div>
                                                <!-- <p class="text-right">
                                                    <img class="signedwidth" src="../assets/imgSignature/1001.png">
                                                </p> -->
                                                <p class="text-right mb-0" *ngIf="notsheet.user_fname !=''">{{notsheet.user_fname}}</p>
                                                <p class="text-right usrname" *ngIf="notsheet.user_fname !=''"><b>{{notsheet.role_name}}</b> <br></p>
                                            </td>
                                            <td class="progress-devider">
                                                <p class="text-right " *ngIf="notsheet.reference_id">
                                                    <a (click)="modalOpen(notsheet.reference_id)" data-toggle="modal" data-target="#exampleModal"> {{notsheet.reference_name}}</a>
                                                    <br><br>
                                                </p>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                            <section class="pt-0" id="printableArea">
                                <table class="table" id="tbl1" *ngIf="!isPrint">
                                    <tbody>
                                        <tr *ngFor="let notsheet of notsheetPageListFinal">
                                            <td class="armada-devider">
                                                <p class="txtcenter">
                                                    {{notsheet.notesheet_sr_no}}.{{notsheet.notesheet_para_no}}</p>
                                                <p *ngIf="notsheet.notesheet_sr_no==refrenceSrNo">
                                                    <a href="javascript:void(0);">
                                                        <button (click)="onEditClick(notsheet.notesheetid)" class="btn btn-sm btn-info" title="Edit"><i
                                                                class="ace-icon fa fa-pencil bigger-120"></i></button></a>
                                                    <br><br>
                                                    <a href="javascript:void(0);">
                                                        <button (click)="onDeleteClick(notsheet.notesheetid)" class="btn btn-sm btn-danger" title="Delete"><i
                                                                class="ace-icon fa fa-trash-o bigger-120"></i></button></a>
                                                </p>
                                            </td>
                                            <td class="driver-devider">
                                                <p><b>Date &amp; Time</b>:{{notsheet.noting_date}}</p>
                                                <div id="txtNote1" class="txtnoteleft">
                                                    <p [innerHTML]="notsheet.notesheet_containt"></p>
                                                </div>
                                                <!-- <p class="text-right">
                                                    <img class="signedwidth" src="../assets/imgSignature/1001.png">
                                                </p> -->
                                                <p class="text-right mb-0">{{notsheet.user_fname}}</p>
                                                <p class="text-right usrname"><b>{{notsheet.role_name}}</b> <br></p>
                                            </td>
                                            <td class="progress-devider">
                                                <p class="text-right " *ngIf="notsheet.reference_id">
                                                    <ng-container *ngFor="let ref of notsheet.ref_array let i=index">
                                                        <a (click)="sticky(ref,i,notsheet.notesheetid,notsheet.reference_type,notsheet.notesheet_sr_no,notsheet.notesheet_para_no)" href="javascript:void(0)" class="text-link">
                                                            <span *ngIf="ref != 'undefined' && ref != 'null' ">{{ref}}</span>&nbsp;
                                                        </a>
                                                    </ng-container>                                                  
                                                </p>
                                                <p class="text-right " *ngIf="notsheet.attachment_reference_id != null">
                                                    <ng-container *ngFor="let ref of notsheet.att_ref_array let i=index">                                                        
                                                        <a (click)="stickyAttachment(ref,i,notsheet.notesheetid,notsheet.reference_type,notsheet.notesheet_sr_no,notsheet.notesheet_para_no)" href="javascript:void(0)" class="text-link">
                                                            <span *ngIf="ref != 'undefined' && ref != 'null' ">{{ref}}</span>&nbsp;
                                                        </a>
                                                    </ng-container>                                                   
                                                </p>
                                                <ng-container *ngFor="let ns of notesheetList">
                                                    <div *ngIf="ns.notesheetid==stickyNotsheetId &&  notsheet.notesheet_sr_no == refSrNo && notsheet.notesheet_para_no == refPara">
                                                        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="display: none">
                                                            <button type="button" class="mr-2 mb-1 close" (click)="closetoast()">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            <div class="toast-body">
                                                                <div class="col-lg-12 p-0 font7 mt-2">
                                                                    <p></p>
                                                                    <p class="mb-1"><b>Date &amp; Time</b>: {{stickyNotingDate}}
                                                                    </p>
                                                                    <p class="mb-1" [innerHTML]="modalNotesheetContent"></p>
                                                                    <!-- <p >{{modalNotesheetContent}}</p> -->
                                                                    <p class="mb-1 text-right">
                                                                        <strong>{{stickyuser_fname}}</strong>
                                                                    </p>
                                                                    <p class="mb-1 text-right">
                                                                        <strong>{{stickyrole_name}}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </td>

                                        </tr>
                                    </tbody>

                                </table>

                            </section>
                        </div>

                        <div class="card shadow-none" *ngIf="isPrint">
                            <div class="card-body px-0 py-2">
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-12">
                                            <label>Note Sr.No</label></div>
                                        <div class="col-lg-4">
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col-lg-3 text-center">
                                            <button type="button" class="btn btn-success btn-sm" (click)="onClickPrint()" id="btnPrintSubmit">Print</button>
                                            <button type="button" class="btn btn-secondary btn-sm ml-2" (click)="isPrint=false" id="btnPrintClose"> Close</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>

        <a href="javascript:void(0)" title="View File" (click)="colappsout()" class="btn btn-sm newfilebtn" style="display: none;"><i class="fa fa-arrow-right"></i></a>

        <div class="col-sm-6 filerespnew pll-0 prr-0 attachbar pt-0">
            <a href="javascript:void(0)" (click)="colappsin()" class="btn btn-sm cstmbtnwarning"><i
                    class="fa fa-arrow-left"></i></a>
            <div class="widget-box m-0 mb-2" style="border: none">
                <div class="widget-header bordrsty text-center">
                    <a href="javascript:void(0);" class="documentlink btn btn-sm btn-primary hidebt" (click)="viewformdetail()" id="viewfordetail" title="View Form"><i
                            class="fa fa-eye"></i>&nbsp;View Form</a>
                    <a href="javascript:void(0);" (click)="showupload()" class="documentlink btn btn-sm btn-success hidebt" id="addAttachmentId" title="Add Attachments" style="visibility: visible;"><i class="fa fa-plus-circle"></i>&nbsp;Add Att./View Docs.</a>

                    <a href="javascript:void(0);"  (click)="showproposed()" class="documentlink btn btn-sm btn-warning hidebt" id="addProposedAmount" title="Proposed Approve" style="visibility: visible;"><i class="fa fa-plus-circle"></i>&nbsp;Proposed Approve</a>

                    <select id="ddlDocumentView" class="brdrradius hidebt form-control" (change)="hideFileAttachmnet()">
                        <option selected="selected" docpath="0" value="0">View Document</option>
                        <option *ngFor="let view of viewList" [value]="view.id">
                            {{view.attachment_desc}}
                        </option>
                    </select>
                    
                    
                   
                    <a href="javascript:void(0);"  class="hidebt big180 pull-right pdfm" (click)="generatesantionOrder()" title="Download Santion Order" ><i
                        class="fa fa-file-pdf-o big-150 bg-white"></i></a>

                        <a href="javascript:void(0)" class="pull-right big180 zoomadd btn-warning" (click)="zoomcall()"><i class="fa fa-expand"></i></a>
                    <a href="javascript:void(0)" class="pull-right big180 zoomminus btn-warning" (click)="zoomcut()"><i class="fa fa-compress"></i></a>
                    <div class="documentrytcont">
                        
                    </div>
                </div>
                <div class="widget-body">
                    <div id="divDispDoc" class="widget-main no-padding  minhyt450">
                        <fieldset class="">
                            <div id="addnote" style="display: none">
                                <div class="row my-1" style="display: none">
                                    <div class="col-sm-12">
                                        <textarea id="noteArea" class="form-control2" style="height: 20vh;"></textarea>
                                        <!-- <app-ngx-editor [config]="editorConfig" style="height: 200px" [spellcheck]="true"  ></app-ngx-editor> -->

                                    </div>
                                </div>
                                <div class="card shadow-none">
                                    <div class="card-body p-0">
                                        <div class="row my-1 mx-1">
                                            <div class="col-lg-12 p-0">
                                                <!-- <div class="NgxEditor__Wrapper width100 ngxcstm">
                                                    <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                                                    <ngx-editor [editor]="editor" [(ngModel)]="notsheetContent" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
                                                </div> -->
                                            </div>
                                        </div>


                                        <div class="row m-0 my-1 borderbottom pb-1">

                                            <div class="col-lg-3 ">
                                                <label>Notesheet Sr. No.</label>
                                            </div>
                                            <div class="col-lg-3 ">
                                                <input id="noteSerialNo" [(ngModel)]="refrenceSrNo" type="text" class="form-control" placeholder="1" readonly>
                                            </div>

                                        </div>
                                        <div class="row mx-0 my-1 borderbottom">
                                            <!-- <div class="col-lg-3 ">
                                                <label>Reference Type</label>
                                            </div>
                                            <div class="col-lg-3 ">
                                                <Select class="form-control" id="ddlReferenceType" style="background-color: white;" (change)="refrenceTypeChange($event.target.value)">
                                                    <option value="0">--Select--</option>
                                                    <option value="2">Attachment</option>
                                                    <option value="1">Other Notings</option>
                                                </Select>
                                            </div> -->
                                            <div class="col-lg-3 ">
                                                <label>Noting References</label>
                                            </div>
                                            <!-- *ngIf="isNoting" -->
                                            <div class="col-lg-3 " >                                              
                                                <ng-multiselect-dropdown [settings]="dropdownSettings1" [data]="notsheetPageList" [(ngModel)]="notesheeSrParaList" (onSelect)="notingSelected($event)" (onDeSelect)="OnSrParaDeSelect($event)" (onSelectAll)="onSelectAllSrPara($event)">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div class="col-lg-3 ">
                                                <label>Attachment References</label>
                                            </div>
                                            <!-- *ngIf="isFile" -->
                                            <div class="col-lg-3 ">                                               
                                                <ng-multiselect-dropdown [settings]="dropdownSettings2" [data]="viewList" [(ngModel)]="notesheeImageList" (onSelect)="attachmentSelected($event)" (onDeSelect)="attachmentSelected($event)" (onSelectAll)="attachmentSelected($event)">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <!-- <div class="col-lg-3 " *ngIf="defaultDrop">
                                                <select class="form-control">
                                                    <option>--Select--</option>
                                                </select>
                                            </div> -->

                                        </div>
                                        <div class="row my-2 mx-0">
                                            <div class="col-lg-12 text-center  mt-1">
                                                <a *ngIf="!isEdit" href="javascript:void(0)" (click)="onSaveNote()" class="btn btn-sm btn-dark rounded-pill px-3 mr-2">Save</a>
                                                <a *ngIf="isEdit" href="javascript:void(0)" (click)="onEditSaveClick()" class="btn btn-sm btn-dark rounded-pill px-3 mr-2">Update</a>
                                                <a href="javascript:void(0)" class="btn btn-sm btn-secondary rounded-pill px-3" (click)="onClickCancel()">Cancel</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div id="sendto" style="display: none">
                                <div class="card shadow-none mt-1" *ngIf="isview_field | async">
                                    <div class="card-body p-0">
                                        <div class="row mx-0 borderbottom my-1 pb-1">
                                            <div class="col-lg-2">
                                                <label>Recipient's Type </label>
                                            </div>
                                            <div class="col-lg-6">
                                                <ng-select [readonly]="'true'" [(ngModel)]="recipienttype">
                                                    <ng-option value="Internal">Internal</ng-option>
                                                    <ng-option value="External">External</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-1">
                                                <label>Priority</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <select class="form-control" id="priority" name="priority" [(ngModel)]="priority">
                                                    <option value="1">Normal</option>
                                                    <option value="2">Out Today</option>
                                                    <option value="3">Most Immediate</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mx-0 borderbottom my-1 pb-1" [style]="1 != dropdownVisible?'display:none':''">
                                            <div class="col-lg-2">
                                                <label>Action Taken </label>
                                            </div>
                                            <div class="col-lg-6">
                                                <ng-select id="actiontaken" name="actiontaken" placeholder="Please Select" [(ngModel)]="actiontakenid" (change)="nextStageID()">
                                                    <ng-option *ngFor="let item of actionList" [value]="item.id">{{item.name}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-1">
                                                <label>Due on</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="date" id="dateDueon" class="form-control" placeholder="mm/dd/yyyy">
                                            </div>
                                        </div>
                                        <div class="row mx-0 borderbottom my-1 pb-1" *ngIf=" routing_type !='rule'">
                                            <div class="col-lg-2">
                                                <label>Send To</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <ng-select id="userid" name="userid" placeholder="Please Select" [(ngModel)]="userId">
                                                    <ng-option *ngFor="let item of userList" [value]="item.id">{{item.username}}</ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-4">

                                            </div>
                                        </div>
                                        <div class="row mx-0 borderbottom my-1 pb-1" [style]="'display:none'">

                                            <div class="col-lg-2">
                                                <label>Mark for reply tracking</label>
                                            </div>
                                            <div class="col-lg-4">
                                                <input id="repmark" type="checkbox" class="ace ace-switch ace-switch-5">
                                                <span class="lbl middle"></span>
                                            </div>
                                        </div>
                                        <div class="row my-1">
                                            <div class="col-lg-12 text-center">
                                                <a href="javascript:void(0)" class="btn btn-sm btn-dark rounded-pill px-3 mr-2" (click)="actOnProcess()">Forward</a>
                                                <a href="javascript:void(0)" class="btn btn-sm btn-secondary rounded-pill px-3">Close</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div id="parkedto" style="display: none">
                                <div class="card shadow-none mt-1">
                                    <div class="card-body p-0">
                                        <div class="row mx-0 borderbottom my-1 pb-1">
                                            <div class="col-lg-2">
                                                <label>Parked Till Date</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="date" id="parkedtill" class="form-control" placeholder="mm/dd/yyyy">
                                            </div>                                            
                                        </div>
                                        <div class="row mx-0 borderbottom my-1 pb-1">
                                            <div class="col-lg-2">
                                                <label>Remarks</label>
                                            </div>
                                            <div class="col-lg-10">
                                               <textarea class="form-control" style="min-height:15vh;" name="parkedRemarks" id="parkedRemarks" [(ngModel)]="parkedRemarks"></textarea>
                                            </div>
                                        </div>
                                       
                                        <div class="row my-1">
                                            <div class="col-lg-12 text-center">
                                                <a href="javascript:void(0)" class="btn btn-sm btn-dark rounded-pill px-3 mr-2" (click)="actionParked()">Parked</a>
                                                <a href="javascript:void(0)" class="btn btn-sm btn-secondary rounded-pill px-3" (click)="parkedClosed()">Close</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div id="divproposedapprove" style="display: none">
                                <div class="card shadow-none mt-1">
                                    <div class="card-body p-0">
                                        <div class="card">
                                           <app-hoa [processid]="processid" [processinstid]="processinstid" [stageid]="stageid" [isApproved]="isApproved" *ngIf="formId == 313" [SessionUrlPrams]="sessionUrlPrams" [schemeid]="editrowid" [formId]="formId"></app-hoa>
                                           <app-gen-hoa [processid]="processid"  [processinstid]="processinstid" [stageid]="stageid" [isApproved]="isApproved" *ngIf="formId == 331" [SessionUrlPrams]="sessionUrlPrams" [schemeid]="editrowid" [formId]="formId"></app-gen-hoa>
                                           <app-pve-hoa [processid]="processid"  [processinstid]="processinstid" [stageid]="stageid" [isApproved]="isApproved" *ngIf="formId == 326" [SessionUrlPrams]="sessionUrlPrams" [schemeid]="editrowid" [formId]="formId"></app-pve-hoa>
                                           <app-post-hoa [processid]="processid"  [processinstid]="processinstid" [stageid]="stageid" [isApproved]="isApproved" *ngIf="formId == 349" [SessionUrlPrams]="sessionUrlPrams" [schemeid]="editrowid" [formId]="formId"></app-post-hoa>
                                        </div>
                                        <div class="row my-1">
                                            <div class="col-lg-12 text-center">
                                                <a href="javascript:void(0)" class="btn btn-sm btn-secondary rounded-pill px-3" (click)="proposedClosed()">Close</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="card shadow-none" id="bascidetail" style="display: none">
                                <div class="card-body p-0">
                                    <div class="row">
                                        <div class="col-lg-12 col-sm-12">
                                            <table class="table cstmtable table-bordered">
                                                <tr>
                                                    <th class="text-center">Select</th>
                                                    <th class="text-center">Sr. No.</th>
                                                    <th class="text-center">Notesheet No.</th>
                                                    <th>Document Name</th>
                                                    <th>PUC</th>
                                                </tr>
                                                <tr>
                                                    <td class="text-center">
                                                        <input type="checkbox">
                                                    </td>
                                                    <td class="text-center">1</td>
                                                    <td class="text-center">1</td>
                                                    <td><a href="javascript:void(0)">RDAK-1SIG-BN-20180205-843</a></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div id="addattr" style="display: none" *ngIf="FormViewLoaded | async">
                                <app-file-attachment-new id="add" [rowid]="refKey" [worklistid]="worklistid" [viewmode]="viewStatus" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType" (viewlistPass)="viewListEvent($event)" (isViwer)="isViwer($event)"></app-file-attachment-new>
                            </div>
                            <div id="fileViwer" *ngIf="DocumentViewLoaded | async">
                                <app-document-view  [ext]="fileExt" [imagepath]="imagepath" [filepath]="filepath" [attachmentTypeCumFileNames]="attachmentTypeCumFileName"></app-document-view>
                            </div>
                            <div id="viewformdetail">
                                <div class="card shadow-none" *ngIf="FormViewLoaded | async">
                                    <div class="card-body">
                                        <div class="tab-content tab-contents pt-0 pr-0 pl-0">
                                            <hdsbpm-request-form [section]="SectionConfig" [SessionUrlPrams]="sessionUrlPrams" [tableName]="tableName" [tablePrimaryKey]="tablePrimaryKey" [rowid]="refKey" [childFormList]="childFormListPage" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow"
                                                [isAddMode]="isAddMode" [stageid]="stageid" [processid]="processid" [connJson]="connJson" [nodeJson]="nodeJson" [processinstid]="processinstid" [worklistid]="worklistid" (submit)="submit($event)" [list]="list"></hdsbpm-request-form>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- <div id="viewdoc" style="display: none;">
                                <div class="card shadow-none">
                                    <div class="card-body px-0 pt-2">
                                         <div class="row mx-0 my-2">
                                    <div class="col-sm-12 col-lg-2">
                                        <label>Search By</label>
                                    </div>
                                    <div class="col-sm-12 col-lg-10">
                                        <input type="radio" id="reciptno" name="inputrec" value="reciptno"
                                            class="mt-1 mr-1">
                                        <label for="reciptno">Reciplt No.</label> &nbsp;&nbsp;
                                        <input type="radio" id="subj" name="inputrec" value="subj" class="mt-1 mr-1">
                                        <label for="subj">Subject</label>
                                    </div>
                                </div> 
                                        <div class="row my-2 mx-0">
                                            <div class="col-lg-2">
                                                <label>Search By</label>
                                            </div>
                                            <div class="col-lg-4">
                                                <input type="text" class="form-control">

                                            </div>
                                            <div class="col-lg-6">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-sm btn-dark rounded-pill px-3 mr-2">Search</a>
                                                <a href="javascript:void(0)"
                                            class="btn btn-sm btn-secondary rounded-pill px-3 mr-2">Attach</a> 
                                                <a href="javascript:void(0)"
                                                    class="btn btn-sm btn-secondary rounded-pill px-3">Cancel</a>
                                            </div>
                                        </div>
                                        <div class="my-2 mx-0">
                                            <div class="col-lg-12 col-sm-12">
                                                <table class="table cstmtable table-bordered">
                                                    <tr>
                                                        <th class="text-center">Select</th>
                                                        <th class="text-center">Sr. No.</th>
                                                        <th class="text-center">Notesheet No.</th>
                                                        <th>Document Name</th>
                                                        <th>PUC</th>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center">
                                                            <input type="checkbox">
                                                        </td>
                                                        <td class="text-center">1</td>
                                                        <td class="text-center">1</td>
                                                        <td>GOM1</td>
                                                        <td>--</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div></div>