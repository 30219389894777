
<div class="col-sm-12 nopadding">
    <a href="javascript:void(0)" class=" mb-3">Click here to add condition</a>
</div>
<div class="mb-0 font8 d-flex">When  <div class="dropdown mx-1"><a href="javascript:void(0)" class="dropdown-toggle"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">all
</a>
  <div class="dropdown-menu newdropmenu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="javascript:void(0)">all</a>
    <a class="dropdown-item" href="javascript:void(0)">any</a>
    <a class="dropdown-item" href="javascript:void(0)">none</a>
    <a class="dropdown-item" href="javascript:void(0)">not all</a>
  </div>
</div>
of the following apply</div>
<div class="mb-0 font8 d-flex"><div class="dropdown mx-1"><a href="javascript:void(0)" class="dropdown-toggle"  id="dropdownMenuButtonnew" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <>
</a>
<div class="dropdown-menu newdropmenu" aria-labelledby="dropdownMenuButtonnew">
<a class="dropdown-item" href="javascript:void(0)">Delete Condition</a>
<a class="dropdown-item" href="javascript:void(0)">Add Condition</a>

</div>
</div>
<a class="" href="javascript:void(0)"> Applicant Name</a>

<div class="dropdown mx-1"><a href="javascript:void(0)" class="dropdown-toggle"  id="dropdownMenuButtonnew1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> is equal to
</a>
<div class="dropdown-menu newdropmenu" aria-labelledby="dropdownMenuButtonnew1">
<a class="dropdown-item" href="javascript:void(0)">is equal to</a>
<a class="dropdown-item" href="javascript:void(0)">is not equal to</a>
<a class="dropdown-item" href="javascript:void(0)">is null</a>


</div>
</div></div>
