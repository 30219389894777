<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>
        <section>
            <div class="container-fluid">
                <div class="row ">
                    <div class="col-sm-12 p-0">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                                <li class="breadcrumb-item newitm1">
                                    <a href="javascript:void(0)" routerLink="/dashboardnew" class="text-primary " style="text-decoration: none;  cursor: text;">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item newitm2">
                                    <a href="javascript:void(0)" routerLink="/buyer-list" class="text-primary " style="text-decoration: none;  cursor: text;">Buyer List</a>
                                </li>
                                <li class="breadcrumb-item newitm3  active" aria-current="page">Buyer Account
                                    <strong>({{buyerName}})</strong>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="col-sm-12 pt-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 mb-4">
                            <div class="card shadow-none">
                                <div class="card-body p-0">
                                    <!-- Tabs navs -->

                                    <ul class="nav nav-tabs serviceicon" id="ex1" role="tablist">

                                        <!-- <li class="nav-item" role="presentation">
                                          <a  class="nav-link" routerLink="/subscription" id="ex1-tab-1"  data-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1"  aria-selected="true">
                                            <img src="../../../assets/images/icon/dashboard.svg" alt="">
                                            <span class="font12"> DASHBOARD </span>
                                          </a>
                                        </li> -->
   <li class="nav-item" role="presentation">
                                            <a class="nav-link active"   id="ex1-tabs-8" data-toggle="tab" href="#ex1-tabs-9"  role="tab" aria-controls="ex1-tabs-9" aria-selected="false">
                                                <img src="../../../assets/images/icon/user-icon.png" alt="">
                                            <span class="font12"> User Profile</span>
                                          </a>
                                        </li> 

                                        <li class="nav-item active" role="presentation">
                                            <a class="nav-link " id="ex1-tab-2" data-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">
                                                <img src="../../../assets/images/icon/my_order.svg" alt="">
                                                <span class="font12"> User Orders </span>
                                            </a>
                                        </li>
                                        <li class="nav-item " role="presentation">
                                            <a class="nav-link" id="ex1-tab-3" data-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">

                                                <img src="../../../assets/images/icon/my_catelogue.svg" alt="">
                                                <span class="font12">
                                                    User Catalogue
                                                </span>
                                            </a>
                                        </li>
                                        

                                     <!-- <li class="nav-item" role="presentation">
                                            <a class="nav-link " id="ex1-tab-5" data-toggle="tab" href="#ex1-tabs-4"
                                                role="tab" aria-controls="ex1-tabs-4" aria-selected="false">
                                                <img src="../../../assets/images/icon/whislist.svg" alt="">
                                                <span class="font12"> Wishlist</span>
                                            </a>
                                        </li>  -->
                                      
                                        <!-- <li class="nav-item" role="presentation">
                                            <a class="nav-link "  id="ex1-tab-7" data-toggle="tab" href="#ex1-tabs-7"  role="tab" aria-controls="ex1-tabs-7" aria-selected="false">
                                            <img src="../../../assets/images/icon/user_setting.svg" alt="">
                                            <span class="font12">  User Settings</span>
                                          </a>
                                        </li> -->
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link "  id="ex1-tabs-6" data-toggle="tab" href="#ex1-tabs-7"  role="tab" aria-controls="ex1-tabs-8" aria-selected="false">
                                            <img src="../../../assets/images/icon/address.svg" alt="">
                                            <span class="font12"> User Address</span>
                                          </a>
                                        </li>
                                        <!-- <li class="nav-item" role="presentation">
                                            <a class="nav-link "  id="ex1-tabs-8" data-toggle="tab" href="#ex1-tabs-9"  role="tab" aria-controls="ex1-tabs-9" aria-selected="false">
                                                <img src="../../../assets/images/icon/whislist.svg" alt="">
                                            <span class="font12"> Password</span>
                                          </a>
                                        </li>  -->


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-8">
                            <!-- Tabs content -->

                            <div class="tab-content" id="ex1-content">

                                <div class=" tab-pane fade  card shadow-none" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-1">
                                    <h6 class="p-3 fnt95 mb-0">
                                        <strong>User Orders</strong>
                                    </h6>
                                    <hr class="mt-0 mb-1">
                                    <div class="card-body">
                                        <app-my-orders></app-my-orders>
                                    </div>
                                </div>

                                <!-- <div  class=" tab-pane fade  show active card shadow-none" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                            <h6 class="px-3 pt-3"><strong>MY ORDERS</strong>
                                
                            </h6>
                            <hr class="mt-0 px-2">
                           <div class="card-body">
                           <app-my-orders></app-my-orders>
                            </div>
                        </div> -->
                                <div class="tab-pane fade card shadow-none" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                                    <div class="row m-0 py-2 align-items-center">
                                        <div class="col">
                                            <span class="font600">User Catalogue</span>
                                        </div>
                                        <div class="col-sm-7 order-sm-1 order-2 text-sm-right px-sm-0 mt-sm-0 mt-3">
                                            <ng-container *ngIf="showCreateForm">
                                                <button class="btn py-2 btn-sm-mobile btn-theme2 px-lg-4 rounded-pill" (click)="showCatalogueList()">
                                                    <i class="fa fa-eye"></i> User Catalogue
                                                </button>
                                                <button class="btn py-2 btn-sm-mobile btn-theme2 px-lg-4 rounded-pill ml-2" (click)="createCatalogue()" *ngIf="!addcatalogue">
                                                    <i class="fa fa-save"></i> Create Catalogue</button>
                                                <button class="btn py-2 btn-sm-mobile btn-theme2 px-lg-4 rounded-pill mx-2" (click)="createCatalogue()" *ngIf="addcatalogue">
                                                    <i class="fa fa-save"></i> Add in Catalogue</button>
                                            </ng-container>
                                        </div>
                                        <div class="col-auto order-sm-2 order-1 text-right pl-0">
                                            <button class="ml-2 py-1 px-2 btn btn-theme2 btn-sm">
                                                <a href="javascript:void(0)" (click)="navigateToCartPage()" class="text-white text-center text-decoration-none" data-target="#addtocart1"
                                                    data-toggle="modal">
                                                    <!-- <span class="badge badge-light">{{cart_list.length}}</span>&nbsp;&nbsp; -->
                                                    <i class="fa fa-shopping-cart ml" aria-hidden="true"></i>
                                                    <span class="cartItem">{{cart_list.length}}</span>
                                                    <!-- <span class="mycrt d-none d-xl-inline-block">My Cart</span> -->
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <hr class="px-2 my-1">
                                    <!-- No Any Catalog start -->
                                    <div class="card-body h-100" *ngIf="tempCatalogeList.length==0 && !showCreateForm">
                                        <div class="text-center p-lg-5">
                                            <h5 class="mb-4">Could not find any Catalogue in your Account.</h5>
                                            <!-- <p class="font9">Lorem ipsum dummy text lore data come here dummy text lorem ispum Lorem ipsum dummy text lore data come here dummy text lorem ispum Lorem ipsum dummy text lore data come here dummy text lorem ispum </p> -->
                                            <div class="mt-4 mb-5">
                                                <button class="btn py-2 btn-theme2 px-lg-5 rounded-pill btn-sm" (click)="showCreateCatalogueForm('')"> Create Your Catalogue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- No Any Catalog End -->
                                    <!--  Catalog create start -->
                                    <div class="card-body h-100" *ngIf="showCreateForm">
                                        <div class="mycatalogecraete">
                                            <div class="row" *ngIf="!addcatalogue">
                                                <div class="col-md-4 col-sm-6 mb-2">
                                                    <h6 class="mb-0">Catalogue Type</h6>
                                                    <p class="font7">Select the type of catalogue you need to create</p>
                                                </div>
                                                <div class="col-md-4 col-lg-3 col-sm-6 mb-2">
                                                    <select class="form-control py-2" (change)="showCreateCatalogueForm(selectedCatalogue)" [(ngModel)]="selectedCatalogue">
                                                        <option value="" selected disabled>Select Catalogue Type
                                                        </option>
                                                        <option [value]="cat.id" *ngFor="let cat of catalogueTypeList; index as i">
                                                            {{cat.varibale_name}}</option>
                                                        <!-- <option value="">Chair</option> -->
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-12 text-lg-right">

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 col-sm-6 mb-2">
                                                    <select class="form-control py-2" (change)="onSelectbrand()" name="selectedbrand" [(ngModel)]="selectedBrand">
                                                        <option value="undefined">Select Brand</option>
                                                        <option [value]="brand.brand_name_id" *ngFor="let brand of brandNameList; index as i">
                                                            {{brand.name}}</option>
                                                        <!-- <option value="">Polo</option> -->
                                                    </select>
                                                </div>
                                                <div class="col-md-3 col-sm-6 mb-2">
                                                    <select class="form-control py-2" (change)="onSelectCategory()" name="selectedcategory" [(ngModel)]="selectedCategory">
                                                        <option value="undefined">Select Category</option>
                                                        <option *ngFor="let cat of categoryList" [value]="cat.product_category_id">
                                                            {{cat.category_name}}
                                                        </option>
                                                        <!-- <option value=""> Hotel & Restaurant</option> -->
                                                    </select>
                                                </div>
                                                <div class="col-md-3 col-sm-6 mb-2"
                                                >
                                                    <select class="form-control py-2" (change)="filterByRating()" name="selectedRating" [(ngModel)]="selectedRating">
                                                        <option value="undefined">Rating</option>
                                                        <option value="1">1 Star</option>
                                                        <option value="2">2 Stars</option>
                                                        <option value="3">3 Stars</option>
                                                        <option value="4">4 Stars</option>
                                                        <option value="5">5 Stars</option>
                                                    </select>
                                                </div>

                                                <div class="col-md-3 col-lg-3 col-sm-6 mb-2">
                                                    <div class="input-group searcbtn">
                                                        <input type="text" class="form-control box-shadow-none" #SearchValue (keyup)="searchInProductList(SearchValue.value)" placeholder="Search Product">
                                                        <span class="input-group-text">
                                                            <i class="fa fa-search" style="color: #dedede;"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="my-4">
                                                <div class="row">
                                                    <div class="col-md-3 col-6 mb-4" *ngFor="let product of productList ; index as i">
                                                        <div class="card text-center shadow-none">
                                                            <div class="card-body p-2">
                                                                <div class="checkboxcustm">
                                                                    <input class="styled-checkbox" id="styled-checkbox-6_{{i+1}}" (change)="createArrayForCatalogue(product)" type="checkbox"
                                                                        name="checkbox{{i+1}}" value="value1" [(ngModel)]="product.isChecked"
                                                                    />
                                                                    <label for="styled-checkbox-6_{{i+1}}"></label>
                                                                    <img src="../../..{{product.document_path}}" class="img-fluid productimg2">
                                                                </div>
                                                                <a href="javascript:void(0)" class="text-decoration-none">
                                                                    <p class="font9 productdesc">
                                                                        {{product.product_name}}</p>
                                                                </a>
                                                                <div class="main-price-block">
                                                                    <span class="prodprice">₹{{product.price?product.price:0}}</span>
                                                                    <div class="beforeprice">
                                                                        <small>
                                                                            <span class="text-decoration-line-through">₹{{product.mrp? product.mrp : 0}}</span>
                                                                        </small>
                                                                        <span class="discountdeal">
                                                                            <span class="text-heightlight ms-1" *ngIf="product.discountPer != 0">
                                                                                {{product.discountPer?product.discountPer:0}}% Off
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <span class="font8">(1 Each)</span>
                                                                </div>
                                                                <div class="star-rating-block py-1">
                                                                    <img [src]="st.imgurl" class="img-fluid" *ngFor="let st of product.star" />

                                                                    <span>
                                                                        <small class="sm-size"> {{product.count}}
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                                <div class="text-right">
                                                                    <a href="javascript:void(0)">
                                                                        <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon">
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <paging-controls (pageChange)="p = $event" ></paging-controls> -->

                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <!--  Catalog create end -->
                                    <!--  View All Catalog Start -->
                                    <div class="card-body h-100" *ngIf="tempCatalogeList.length>0 && !showCreateForm">
                                        <div class="allcatalogview">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-lg-9 px-2">
                                                            <ul class="nav nav-tabs navtabs-cstm minwdth310p mb-sm-0 mb-2" id="myTab" role="tablist">
                                                                <li class="nav-item" role="presentation" (click)="filterCatalogueTypeWise(cat.catalogue_type_id)" *ngFor="let cat of tempCatalogueTypeList; index as i">
                                                                    <!-- <p>{{cat.name}}</p> -->
                                                                    <button class="nav-link" [ngClass]="{active : cat.catalogue_type_id == firstCatalogueType}" id="home-tab" data-bs-toggle="tab"
                                                                        data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                                        aria-selected="true">
                                                                        {{cat.name}}
                                                                        <i class="fa fa-caret-right"></i>
                                                                    </button>
                                                                </li>
                                                                <!-- <li class="nav-item" role="presentation">
                                          <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                            Merchandise <i class="fa fa-caret-right"></i>
                                          </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <button class="nav-link" id="contact-tab" data-toggle="tab" data-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                            Print Collaterals <i class="fa fa-caret-right"></i>
                                          </button>
                                        </li> -->
                                                            </ul>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <div class="d-inline-block float-right text-right">
                                                                <button class="btn py-lg-2 btn-sm-mobile btn-theme2 px-lg-4 rounded-pill" (click)="showCreateCatalogueForm(firstCatalogueType);addcatalogue = true">
                                                                    <i class="fa fa-edit"></i> Edit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mycatalogecraete mt-4">
                                                        <div class="row">
                                                            <div class="col-md-3 col-sm-6 mb-2">
                                                                <select class="form-control shadow-sm py-2" (change)="filterCreatedCataloguebyBrand()" name="brand_name" [(ngModel)]="selectedBrand">
                                                                    <option value="undefined">Select Brand</option>
                                                                    <option [value]="brand.brand_name_id" *ngFor="let brand of createdBrandNameList; index as i">
                                                                        {{brand.name}}</option>
                                                                    <!-- <option value="">Polo</option> -->
                                                                </select>
                                                            </div>
                                                            <div class="col-md-3 col-sm-6 mb-2">
                                                                <select class="form-control shadow-sm py-2" (change)="onSelectCategoryCat()" name="selectedcategory" [(ngModel)]="selectedCategory">
                                                                    <option value="undefined">Select Category</option>
                                                                    <option *ngFor="let cat of categoryListCat" [value]="cat.product_category_id">
                                                                        {{cat.category_name}}
                                                                    </option>
                                                                    <!-- <option value=""> Hotel & Restaurant</option> -->
                                                                </select>
                                                            </div>
                                                            <div class="col-md-3 col-sm-6 mb-2">
                                                                <select class="form-control shadow-sm py-2" (change)="filterByRatingCat()" name="selectedRating"
                                                                [(ngModel)]="selectedRating">
                                                                <option value="undefined">Rating</option>
                                                                <option value="1">1 Star</option>
                                                                <option value="2">2 Stars</option>
                                                                <option value="3">3 Stars</option>
                                                                <option value="4">4 Stars</option>
                                                                <option value="5">5 Stars</option>
                                                                </select>
                                                            </div>

                                                            <div class="col-md-3 col-lg-3 col-sm-6 mb-2">
                                                                <div class="input-group searcbtn">
                                                                    <input type="text" class="form-control box-shadow-none" #SearchValue2 (keyup)="searchInCatalogueList(SearchValue2.value)"
                                                                        placeholder="Search Product">
                                                                    <span class="input-group-text">
                                                                        <i class="fa fa-search" style="color: #dedede;"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="tab-content" id="myTabContent" *ngIf="firstCatalogueType == 215">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                            <div class="row align-items-center py-2 border-bottom" *ngFor="let pro of office_supplies ; index as i">
                                                                <div class="col-12 col-lg-2">
                                                                    <div class="card p-lg-1 p-2 shadow-none">
                                                                        <a href="javascript:void(0)">
                                                                            <img alt="productname" class="productimg img-fluid" src="../../..{{pro.product_img_path}}">
                                                                        </a>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon">
                                                                                </a>
                                                                            </div>
                                                                            <div class="col text-right">
                                                                                <i class="fa fa-search"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-lg-8 py-2">
                                                                    <div class="mb-1">
                                                                        <h4 class="font9 productdesc">
                                                                            {{pro.product_name}}</h4>
                                                                        <!-- <h4 class="font9 productdesc">Dubaria T6641 Black Ink Cartridge For Epson Printers</h4> -->
                                                                    </div>
                                                                    <div class="main-price-block">
                                                                        <!-- <span class="prodprice">₹168.00 </span> -->
                                                                        <span class="prodprice">₹ {{pro.price}} </span>

                                                                        <!-- <span class="font8">(1 Each)</span> -->
                                                                        <span class="beforeprice">
                                                                            <small>
                                                                                <span class="text-decoration-line-through">₹{{pro.mrp}}</span>
                                                                            </small>
                                                                            <span class="discountdeal">
                                                                                <span class="text-heightlight ms-1" *ngIf="pro.discount != 0">
                                                                                    {{pro.discount?pro.discount:0}}% Off
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="star-rating-block py-1">
                                                                        <img [src]="st.imgurl" class="img-fluid" *ngFor="let st of pro.star" />
                                                                    </div>
                                                                    <a class=" font8 remove-sec" (click)="removeCatalogueProduct(pro.catdtlid)">
                                                                        <i class="fa fa-trash"></i> Remove</a>
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-lg-2 px-sm-2 px-0 mb-2 mb-lg-0">
                                                                    <div class="input-group add-to-cart" id="idp{{i}}">
                                                                        <div class="input-group-prepend">
                                                                            <!-- <span class="input-group-text"> 
                                                        <button class=""><i class="fa fa-minus"></i></button> </span> -->
                                                                            <span class="input-group-text">
                                                                                <button class="" id="minus-btn{{i}}" (click)="minus(pro,i)">
                                                                                    <i class="fa fa-minus"></i>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                        <!-- <input type="text" class="cst-frm h-auto midbox" value="22"> -->
                                                                        <input type="text" keyDown="false" class="form-control cst-frm h-auto" readonly [(ngModel)]="pro.qty">

                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">
                                                                                <button class="" [disabled]="pro.qty >= 5" (click)="plus(pro,i)">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                            </span>
                                                                            <!-- <span class="input-group-text"> <button class="">
                                                      <i class="fa fa-plus"></i></button> </span> -->
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="text-center" id="idb{{i}}">
                                                <button class="btn btn-theme2 px-3 btn-sm rounded-pill mt-2" (click)="removeDisp(pro, i)"> <i class="fa fa-plus"></i> ADD</button>
                                            </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="row paginationcontrol align-items-center mt-3">
                                                                <div class="col-md-1 col-2 pr-0">
                                                                    <select name="perpage" (change)="onSelectPage($event)" class="form-control">
                                                                        <option value="{{page.value}}" *ngFor="let page of itemsPerPage">
                                                                            {{page.value}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-7 col-10">
                                                                    <pagination-controls (pageChange)="p=$event">
                                                                    </pagination-controls>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                            <div class="row">
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">

                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg2 img-fluid" src="../../assets/images/product/prduct002.png">
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Symbol Regular Fit Men's Cotten T-Shirt
                                                                                        </h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">

                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg img-fluid" src="../../assets/images/product/prduct003.png">
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt
                                                                                        </h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">

                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct003.png">
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt
                                                                                        </h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">

                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct006.png">
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler 115L Room/Personal Air Cooler
                                                                                        </h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">

                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct004.png">
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt
                                                                                        </h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">

                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct005.png">
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Symbol Regular Fit Men's Cotten T-Shirt
                                                                                        </h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2  text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                            <div class="row">
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img1.png" class="img-fluid mb-3 mt-2">
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 587600
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid">
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon">
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img3.png" class="img-fluid mb-3 mt-2">
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 587300
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid">
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon">
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img7.png" class="img-fluid mb-3 mt-2">
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 287600
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid">
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid">
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon">
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-content" id="myTabContent" *ngIf="firstCatalogueType == 216">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                            <div class="row align-items-center py-2 border-bottom" *ngFor="let pro of merchandise ; index as i">
                                                                <div class="col-12 col-lg-2">
                                                                    <div class="card p-lg-1 p-2 shadow-none">
                                                                        <a href="javascript:void(0)">
                                                                            <img alt="productname" class="img-fluid productimg" src="../../..{{pro.product_img_path}}" />
                                                                        </a>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                            <div class="col text-right">
                                                                                <i class="fa fa-search"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-lg-8 py-2">
                                                                    <div class="mb-1">
                                                                        <h4 class="font9 productdesc">
                                                                            {{pro.product_name}}</h4>
                                                                        <!-- <h4 class="font9 productdesc">Dubaria T6641 Black Ink Cartridge For Epson Printers</h4> -->
                                                                    </div>
                                                                    <div class="main-price-block">
                                                                        <!-- <span class="prodprice">₹168.00 </span> -->
                                                                        <span class="prodprice">₹ {{pro.price}} </span>

                                                                        <!-- <span class="font8"> <a href="javascript:void(0)">34 </a></span> -->
                                                                        <span class="beforeprice">
                                                                            <small>
                                                                                <span class="text-decoration-line-through">₹{{pro.mrp}}</span>
                                                                            </small>
                                                                            <span class="discountdeal">
                                                                                <span class="text-heightlight ms-1" *ngIf="pro.discount != 0">
                                                                                    {{pro.discount?pro.discount:0}}% Off
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="star-rating-block py-1">
                                                                        <img [src]="st.imgurl" class="img-fluid" *ngFor="let st of pro.star" />
                                                                    </div>

                                                                    <a class=" font8 remove-sec" (click)="removeCatalogueProduct(pro.catdtlid)">
                                                                        <i class="fa fa-trash"></i> Remove</a>

                                                                </div>
                                                                <div class="col-6 col-sm-4 col-lg-2 px-sm-2 px-0 mb-2 mb-lg-0">
                                                                    <div class="input-group add-to-cart" id="idp{{i}}">
                                                                        <div class="input-group-prepend">
                                                                            <!-- <span class="input-group-text"> 
                                                            <button class=""><i class="fa fa-minus"></i></button> </span> -->
                                                                            <!-- <span class="input-group-text">
                                                                                <button class="" id="minus-btn{{i}}" (click)="minus(pro,i)">
                                                                                    <i class="fa fa-minus"></i>
                                                                                </button>
                                                                            </span> -->
                                                                        </div>
                                                                        <!-- <input type="text" class="cst-frm h-auto midbox" value="22"> -->
                                                                        <!-- <input type="text" keyDown="false" class="form-control cst-frm h-auto" readonly [(ngModel)]="pro.qty" /> -->

                                                                        <div class="input-group-append">
                                                                            <!-- <span class="input-group-text">
                                                                                <button class="" [disabled]="pro.qty >= 5" (click)="plus(pro,i)">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                            </span> -->
                                                                            <!-- <span class="input-group-text ms-0"> <button class="" id="minus-btn{{i}}" [disabled]="prod.qtytotal <= 1"
                                                                                (click)="minus(prod,i)"><i class="fa fa-minus"></i></button> </span> -->
                                                                            <!-- <span class="input-group-text"> <button class="">
                                                          <i class="fa fa-plus"></i></button> </span> -->
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="text-center" id="idb{{i}}">
                                                        <button class="btn btn-theme2 px-3 btn-sm rounded-pill mt-2" (click)="removeDisp(pro, i)"><i class="fa fa-plus"></i> ADD</button>
                                                    </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="row paginationcontrol align-items-center mt-3">
                                                                <div class="col-md-1 col-2 pr-0">
                                                                    <select name="perpage" (change)="onSelectPage($event)" class="form-control">
                                                                        <option value="{{page.value}}" *ngFor="let page of itemsPerPage">
                                                                            {{page.value}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-11 col-10">
                                                                    <pagination-controls (pageChange)="p2=$event">
                                                                    </pagination-controls>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                            <div class="row">
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg2 img-fluid" src="../../assets/images/product/prduct002.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Symbol Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg img-fluid" src="../../assets/images/product/prduct001.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct003.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct006.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler 115L Room/Personal Air Cooler</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct004.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct005.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Symbol Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                            <div class="row">
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img1.png" class="img-fluid mb-3 mt-2" />
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 587600
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img3.png" class="img-fluid mb-3 mt-2" />
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 587300
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img7.png" class="img-fluid mb-3 mt-2" />
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 287600
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-content" id="myTabContent" *ngIf="firstCatalogueType == 217">
                                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                            <div class="row align-items-center py-2 border-bottom" *ngFor="let pro of print_collaterals ; index as i">
                                                                <div class="col-12 col-lg-2">
                                                                    <div class="card p-lg-1 p-2 shadow-none">
                                                                        <a href="javascript:void(0)">
                                                                            <img alt="productname" class="productimg img-fluid" src="../../..{{pro.product_img_path}}" />
                                                                        </a>
                                                                        <div class="row">
                                                                            <div class="col">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                            <div class="col text-right">
                                                                                <i class="fa fa-search"></i>
                                                                            </div>
                                                                            <!-- <div class="col text-right"> -->
                                                                            <!-- <button class="btn btn-sm btn-danger">remove</button> -->
                                                                            <!-- </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-lg-8 py-2">
                                                                    <div class="mb-1">
                                                                        <h4 class="font9 productdesc">
                                                                            {{pro.product_name}}</h4>
                                                                        <!-- <h4 class="font9 productdesc">Dubaria T6641 Black Ink Cartridge For Epson Printers</h4> -->
                                                                    </div>
                                                                    <div class="main-price-block">
                                                                        <!-- <span class="prodprice">₹168.00 </span> -->
                                                                        <span class="prodprice">₹ {{pro.price}} </span>
                                                                        <!-- <span class="font8"> <button class="btn btn-sm btn-danger" (click)="removeCatalogueProduct(pro.catdtlid)">remove</button></span> -->
                                                                        <span class="beforeprice">
                                                                            <small>
                                                                                <span class="text-decoration-line-through">₹{{pro.mrp}}</span>
                                                                            </small>
                                                                            <span class="discountdeal">
                                                                                <span class="text-heightlight ms-1" *ngIf="pro.discount != 0">
                                                                                    {{pro.discount?pro.discount:0}}% Off
                                                                                </span>
                                                                            </span>
                                                                        </span>

                                                                        <!-- <span class="font8"> <a href="javascript:void(0)">34 </a></span> -->
                                                                    </div>
                                                                    <div class="star-rating-block py-1">
                                                                        <img [src]="st.imgurl" class="img-fluid" *ngFor="let st of pro.star" />
                                                                    </div>
                                                                    <a class=" font8 remove-sec" (click)="removeCatalogueProduct(pro.catdtlid)">
                                                                        <i class="fa fa-trash"></i> Remove</a>
                                                                    <!-- <span class="" (click)="removeCatalogueProduct(pro.catdtlid)"> <i class="fa fa-trash-alt"></i> remove</span> -->
                                                                </div>
                                                                <div class="col-6 col-sm-4 col-lg-2 px-sm-2 px-0 mb-2 mb-lg-0">
                                                                    <div class="input-group add-to-cart" id="idp{{i}}">
                                                                        <div class="input-group-prepend">
                                                                            <!-- <span class="input-group-text"> 
                                    <button class=""><i class="fa fa-minus"></i></button> </span> -->
                                                                            <!-- <span class="input-group-text">
                                                                                <button class="" id="minus-btn{{i}}" (click)="minus(pro,i)">
                                                                                    <i class="fa fa-minus"></i>
                                                                                </button>
                                                                            </span> -->
                                                                        </div>
                                                                        <!-- <input type="text" class="cst-frm h-auto midbox" value="22"> -->
                                                                        <!-- <input type="text" keyDown="false" class="form-control cst-frm h-auto" readonly [(ngModel)]="pro.qty" /> -->

                                                                        <div class="input-group-append">
                                                                            <!-- <span class="input-group-text">
                                                                                <button class="" [disabled]="pro.qty >= 5" (click)="plus(pro,i)">
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                            </span> -->
                                                                            <!-- <span class="input-group-text"> <button class="">
                                  <i class="fa fa-plus"></i></button> </span> -->
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="text-center" id="idb{{i}}">
                                                      <button class="btn btn-theme2 px-3 btn-sm rounded-pill mt-2" (click)="removeDisp(pro, i)"> <i class="fa fa-plus"></i> ADD</button>
                                                  </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="row paginationcontrol align-items-center mt-3">
                                                                <div class="col-md-1 col-2 pr-0">
                                                                    <select name="perpage" (change)="onSelectPage($event)" class="form-control">
                                                                        <option value="{{page.value}}" *ngFor="let page of itemsPerPage">
                                                                            {{page.value}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-md-7 col-10">
                                                                    <pagination-controls (pageChange)="p3=$event">
                                                                    </pagination-controls>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                            <div class="row">
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg2 img-fluid" src="../../assets/images/product/prduct002.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Symbol Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg img-fluid" src="../../assets/images/product/prduct001.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="productimg img-fluid" src="../../assets/images/product/prduct003.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct006.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler 115L Room/Personal Air Cooler</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct004.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Eyebogler Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body p-0">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <div class="productdesc">
                                                                                    <img alt="" class="img-fluid productimg" src="../../assets/images/product/prduct005.png" />
                                                                                    <div class="text-left p-3">
                                                                                        <h4 class="productdesc mb-1">
                                                                                            Symbol Regular Fit Men's Cotten T-Shirt</h4>
                                                                                        <div class="my-1">
                                                                                            <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                                ₹476.00</h4>
                                                                                            <span class="beforeprice">
                                                                                                <small>
                                                                                                    <span class="text-decoration-line-through">₹ 599.00
                                                                                                    </span>
                                                                                                </small>
                                                                                                <span class="discountdeal">
                                                                                                    <span class="text-heightlight ml-1">
                                                                                                        20% Off
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div class="py-1 starrating">
                                                                                            <span class="btn btn-theme btn-sm py-1 px-2 text-white">
                                                                                                4.2
                                                                                                <i class="fa fa-star"></i>
                                                                                            </span>
                                                                                            <span class="ml-1">
                                                                                                (2544)
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                            <div class="row">
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center rounded-0 shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img1.png" class="img-fluid mb-3 mt-2" />
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 587600
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img3.png" class="img-fluid mb-3 mt-2" />
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 587300
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-6 mb-4">
                                                                    <div class="card text-center shadow-none h-100">
                                                                        <div class="card-body">
                                                                            <a href="javascript:void(0)" class="text-decoration-none">
                                                                                <img src="../../../assets/images/product/Cards/400/img7.png" class="img-fluid mb-3 mt-2" />
                                                                                <p class="font9 productdesc">Box Lines Business Cards Set Of 250 - IDC
                                                                                    - 287600
                                                                                </p>
                                                                            </a>
                                                                            <div class="my-1">
                                                                                <h4 class="mb-1 productprice d-inline-block mr-2">
                                                                                    ₹476.00</h4>
                                                                                <span class="beforeprice">
                                                                                    <small>
                                                                                        <span class="text-decoration-line-through">₹ 599.00
                                                                                        </span>
                                                                                    </small>
                                                                                    <span class="discountdeal">
                                                                                        <span class="text-heightlight ml-1">
                                                                                            20% Off
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div class="star-rating-block py-1">
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/rated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                                <img src="../../../assets/images/icon/notrated.svg" class="img-fluid" />
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <a href="javascript:void(0)">
                                                                                    <img src="../../../assets/images/icon/plusicon.svg" class="img-fluid plusicon" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 text-right">
                                                        <button class="btn py-lg-2 btn-theme2 px-lg-4 rounded-pill" [hidden]="tempCatalogueTypeList.length == 3 " (click)="showCreateCatalogueForm('');addcatalogue = false">
                                                            Create Another Catalogue
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  View All Catalog Start -->
                                    </div>
                                    <!-- <div  class="tab-pane fade card shadow-none"  id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                            <h6 class="px-3 pt-3"><strong>My RFQs</strong>
                            
                            </h6>
                            <hr class="mt-0 px-2">
                           <div class="card-body">
                           
                            </div>
                        </div> -->
                                    <!-- <div  class=" tab-pane fade card shadow-none"  id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                            <h6 class="px-3 pt-3"><strong>Wishlist</strong>
                          
                            </h6>
                            <hr class="mt-0 px-2 mb-2">
                           <div class="card-body wishlist-cart">
                            <app-wishlist></app-wishlist>
                            </div>
                        </div> -->
                                    <!-- <div  class=" tab-pane fade card shadow-none" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                            <h6 class="px-3 pt-3"><strong>User Settings</strong></h6>
                            <hr class="m-0">
                            <div class="card-body">
                              <app-usersetting></app-usersetting>
                           </div>
                        </div> -->
                                    <!-- <div  class=" tab-pane fade card shadow-none" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                            <h6 class="px-3 pt-3"><strong> User Settings</strong>
                             
                            </h6>
                            <hr class="mt-0 px-2">
                           <div class="card-body">
                           
                            </div>
                        </div>
                        <div  class=" tab-pane fade card shadow-none" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8">
                            <h6 class="px-3 pt-3"><strong>Address</strong>
                            
                            </h6>
                            <hr class="mt-0 px-2">
                           <div class="card-body">
                           
                            </div>
                        </div>
                        <div  class=" tab-pane fade card shadow-none" id="ex1-tabs-9" role="tabpanel" aria-labelledby="ex1-tab-9">
                            <h6 class="px-3 pt-3"><strong>Password</strong>
                           
                            </h6>
                            <hr class="mt-0 px-2">
                           <div class="card-body">
                           
                            </div>
                        </div> -->

                                </div>
                                <div class=" tab-pane fade card shadow-none" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                                    <h6 class="px-3 pt-3">
                                        <strong>User Wishlist</strong>
                                    </h6>
                                    <hr class="mt-0 mb-1">
                                    <div class="card-body">
                                        <app-wishlist></app-wishlist>
                                    </div>
                                </div>

                            


                            <div class="tab-pane fade card shadow-none" id="ex1-tabs-7" role="tabpanel"
                            aria-labelledby="ex1-tab-8">
                            <!-- Start  All  Address -->
                            <div class="allAddress">
                                <h5 class="px-3 pt-3"><strong>Address</strong></h5>
                                <hr class="mt-0 px-2">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <h6><strong> Shipping Address ({{addressList.length}})</strong></h6>
                                            <!-- <hr class="mb-0"> -->
                                            <!-- <div class="py-3">
                                                <button type="button" (click)="addTypetext = 's';reSet() "
                                                    class="btn btn-outline-theme rounded-pill px-4 adddeliveryaddress"><i
                                                        class="fa fa-plus me-1"></i> Add Delivery
                                                    Address</button>
                                            </div> -->
                                            <div class="card shadow-none mb-3">
                                                <div class="card-body"
                                                    *ngFor="let ship of addressList; index as i">
                                                    <div
                                                        class="d-flex justify-content-between align-items-center">
                                                        <h6 class="mb-0"><strong>{{ship.name}}</strong></h6>
                                                        <!-- <div class="dropdown position-static">
                                                            <button
                                                                class="btn btn-link btn-sm dropdown-toggle text-dark shadow-none"
                                                                type="button" id="order-dropdown-0"
                                                                data-bs-toggle="dropdown"
                                                                data-boundary="viewport" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <i class="fa fa-ellipsis-h"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-end border py-0"
                                                                aria-labelledby="order-dropdown-0">
                                                                <div class="py-2">
                                                                    <a class="dropdown-item text-success"
                                                                        (click)="edit(ship.address_id, 's')"
                                                                        href="javascript:void(0)">Edit</a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a class="dropdown-item text-danger"
                                                                        (click)="DeleteAddres1(ship.address_id)"
                                                                        href="javascript:void(0)">Delete</a>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="">
                                                        <p class="mb-0">{{ship.area_street}} {{ ship.locality}}
                                                            {{ ship.landmark}} City: {{ship.city}},
                                                            State: {{ship.state_name}}, Country:
                                                            {{ship.country_name}}, {{ship.pin_code}}</p>
                                                        <p class="mb-0"><strong>Contact:</strong>
                                                            {{ship.mobile}}</p>
                                                        <p class="mb-0"><strong>Email:</strong>
                                                            {{ship.email_address}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <h6><strong> Billing Address ({{billingAddList?.length}})</strong>
                                            </h6>
                                            <!-- <hr class="mb-0"> -->
                                            <!-- <div class="py-3">
                                                <button type="button" (click)="addTypetext = 'b';reSet() "
                                                    class="btn btn-outline-theme rounded-pill px-4 adddeliveryaddress"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#adddeliveryaddress"><i
                                                        class="fa fa-plus me-1"></i> Add Billing
                                                    Address</button>
                                            </div> -->
                                            <div class="card shadow-none mb-3">
                                                <div class="card-body"
                                                    *ngFor="let ship of billingAddList; index as i">
                                                    <div
                                                        class="d-flex justify-content-between align-items-center">
                                                        <h6 class="mb-0"><strong>{{ship.name}}</strong></h6>
                                                        <!-- <div class="dropdown position-static">
                                                            <button
                                                                class="btn btn-link btn-sm dropdown-toggle text-dark shadow-none"
                                                                type="button" id="order-dropdown-0"
                                                                data-bs-toggle="dropdown"
                                                                data-boundary="viewport" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <i class="fa fa-ellipsis-h"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-end border py-0"
                                                                aria-labelledby="order-dropdown-0">
                                                                <div class="py-2">
                                                                    <a class="dropdown-item text-success"
                                                                        (click)="edit(ship.address_id, 'b')"
                                                                        href="javascript:void(0)">Edit</a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a class="dropdown-item text-danger"
                                                                        (click)="DeleteAddres1(ship.address_id)"
                                                                        href="javascript:void(0)">Delete</a>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="">
                                                        <p class="mb-0">{{ship.area_street}} {{ ship.locality}}
                                                            {{ ship.landmark}} City: {{ship.city}},
                                                            State: {{ship.state_name}}, Country:
                                                            {{ship.country_name}}, {{ship.pin_code}}</p>
                                                        <p class="mb-0"><strong>Contact:</strong>
                                                            {{ship.mobile}}</p>
                                                        <p class="mb-0"><strong>Email:</strong>
                                                            {{ship.email_address}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End All Address -->

                            <!-- Start Add Add Delivery/Billing  Address -->
                            <div class="addNewAddress" style="display:none;">
                                <h6 class="px-3 pt-3"><strong>Add Delivery Address</strong></h6> 
                                <hr class="mt-0 px-2">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6 pb-2">
                                            <label>First Name <span class="text-danger">*</span></label>
                                            <input type="text" [(ngModel)]="add_name" class="form-control"
                                                placeholder="Enter First Name">
                                        </div>
                                        <div class="col-md-6 pb-2">
                                            <label>Last Name <span class="text-danger">*</span></label>
                                            <input type="text" [(ngModel)]="last_name" class="form-control"
                                                placeholder="Enter Last Name">
                                        </div>
                                        <div class="col-12 pb-2">
                                            <label>Email ID <span class="text-danger">*</span></label>
                                            <input type="email" [(ngModel)]="email_address" class="form-control"
                                                placeholder="Enter Email ID">
                                        </div>

                                        <div class="col-12 pb-2">
                                            <label>Company Name <span class="text-danger">*</span></label>
                                            <input type="text" [(ngModel)]="company_name" class="form-control"
                                                placeholder="Enter Company Name">
                                        </div>
                                        <div class="col-md-6 pb-2">
                                            <label>Pin Code <span class="text-danger">*</span></label>
                                            <input type="text" (keyup)="getCityAndState()" [(ngModel)]="pinCode"
                                                class="form-control" placeholder="Enter Postal/Zip Code">
                                        </div>
                                        <div class="col-md-6 pb-2">
                                            <label>Locality <span class="text-danger">*</span></label>
                                            <input type="text" [(ngModel)]="locality" class="form-control"
                                                placeholder="Enter Locality">
                                        </div>
                                        <div class="col-md-12 pb-2">
                                            <label>Address <span class="text-danger">*</span></label>
                                            <textarea class="form-control textareaheight"
                                                [(ngModel)]="add_area_street"
                                                placeholder="Enter Address"></textarea>
                                        </div>

                                        <div class="col-md-4 pb-2">
                                            <label>Country <span class="text-danger">*</span></label>
                                            <select class="form-control" [(ngModel)]="country_id">
                                                
                                                <option selected value="0">Select Country</option>
                                                <option value="1">India</option>
                                                <option value="2">Nepal</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4 pb-2">
                                            <label>Province/Territory/State <span
                                                    class="text-danger">*</span></label>

                                            <select class="form-control" id="state" name="state_id"
                                                [(ngModel)]="state" disabled>
                                                <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                                    {{s.state_name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4 pb-2">
                                            <label>City <span class="text-danger">*</span></label>
                                            <input type="text" [(ngModel)]="city" class="form-control"
                                                placeholder="Enter City">
                                        </div>
                                        <div class="col-md-6 pb-2">
                                            <label>Landmark <span class="text-dark">(Optional)</span></label>
                                            <input type="text" [(ngModel)]="landmark" class="form-control"
                                                placeholder="Enter Landmark">
                                        </div>
                                        <div class="col-md-6 pb-2 position-relative">
                                            <label>Phone Number <span class="text-danger">*</span></label>
                                            <input type="text" [(ngModel)]="add_mobile" class="form-control"
                                                placeholder="Enter Phone Number" maxlength="10">
                                            <!-- <span class="lock_sh btn"><i class="fa fa-check-circle"></i>
                                                VERIFIED</span> -->
                                        </div>
                                        <div class="col-12">
                                            <div class="pb-2 d-flex align-items-center">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input custom2-chkbox ml-0 align-top"
                                                        id="saveaddress1">
                                                    <label class="form-check-label font9 ms-1"
                                                        for="saveaddress1">Save as a default billing
                                                        address?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex w-100 justify-content-center py-2">
                                            <div class="mr-2">
                                                <button type="button" (click)="addAddress()"
                                                    class="btn btn-theme2 text-center rounded-pill px-lg-5 px-4">Add</button>
                                            </div>
                                            <div>
                                                <button type="button"
                                                    class="btn btn-secondary rounded-pill px-lg-5 px-4 backtoaddress"
                                                    data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Add Add Delivery/Billing  Address -->
                        </div>

                            <div class="tab-pane fade show active card shadow-none" id="ex1-tabs-9" role="tabpanel"
                                    aria-labelledby="ex1-tab-9">
                                    <h6 class="px-3 pt-3"><strong>Personal Details</strong></h6>
                                    <hr class="mt-0 px-2 mb-2">
                                    <div class="card-body pt-1 pb-4 h-100">
                                        <div class="row">
                                            <div class="col-sm-6 mb-3">
                                                <label> First Name: </label> 
                                                <input type="text" class="form-control" disabled value="" [(ngModel)]="buyerName">
                                                <!-- <b> test</b> --> 
                                            </div>
                                            <div class="col-sm-6 mb-3">
                                                <label> Mobile Number: </label>  <input type="text" class="form-control" disabled value="" [(ngModel)]="mobile">
                                            </div>
                                            <div class="col-sm-6 mb-3">
                                                <label> Email: </label>  <input type="text" class="form-control" disabled value="" [(ngModel)]="email">
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div> 

                                <!-- <div class="tab-pane fade card shadow-none" id="ex1-tabs-9" role="tabpanel"
                                    aria-labelledby="ex1-tab-9">
                                    <h6 class="px-3 pt-3"><strong>Change Password</strong></h6>
                                    <hr class="mt-0 px-2 mb-2">
                                    <div class="card-body h-100">
                                        <div class="row">
                                            <div class="col-sm-7">

                                                <div class="pb-3 position-relative ">
                                                    <label>Current Password <span class="text-danger">*</span></label>

                                                    <input type="password" id="c_password" name="c_password"
                                                        class="form-control" placeholder="Enter Current Password"
                                                        [(ngModel)]="current_password"
                                                        placeholder="Enter Current Password" oncopy="return false;"
                                                        onpaste="return false;" ondrag="return false;"
                                                        ondrop="return false;"
                                                        [type]=" showPassword ? 'text' : 'password' " maxlength="16">
                                                    <span class="lock_sh" (click)='showHidePassword()'
                                                        *ngIf="showPassword==true">
                                                        <i class="fa fa-eye-slash fa-w-20"></i>
                                                    </span>
                                                    <span class="lock_sh" (click)='showHidePassword()'
                                                        *ngIf="showPassword==false">
                                                        <i class="fa fa-eye fa-w-20"></i>
                                                    </span>
                                                </div>

                                                <div class="pb-0 position-relative ">
                                                    <label>New Password <span class="text-danger">*</span></label>
                                                    <input type="password" class="form-control" id="n_password"
                                                        name="n_password" placeholder="Enter New Password"
                                                        [(ngModel)]="new_password" oncopy="return false;"
                                                        onpaste="return false;" ondrag="return false;"
                                                        ondrop="return false;"
                                                        [type]=" showPassword1 ? 'text' : 'password' " maxlength="16">
                                                    <span class="lock_sh" (click)='showHidePassword1()'
                                                        *ngIf="showPassword1==true">
                                                        <i class="fa fa-eye-slash fa-w-20"></i>
                                                    </span>
                                                    <span class="lock_sh" (click)='showHidePassword1()'
                                                        *ngIf="showPassword1==false">
                                                        <i class="fa fa-eye fa-w-20"></i>
                                                    </span>
                                                </div>
                                                <div class="form-group mb-3">
                                                    <p class="font8"> (Password should have min length 8 & 1 special
                                                        character, 1 digit, 1 uppercase) </p>
                                                </div>



                                                <div class="pb3 position-relative">
                                                    <label>Confirm Password <span class="text-danger">*</span></label>
                                                    <input type="password" id="conf_password" name="conf_password"
                                                        class="form-control" placeholder="Enter Confirm password"
                                                        [(ngModel)]="confirm_password"
                                                        placeholder="Enter Confirm Password" oncopy="return false;"
                                                        onpaste="return false;" ondrag="return false;"
                                                        ondrop="return false;"
                                                        [type]=" showPassword2 ? 'text' : 'password' " maxlength="16">
                                                    <span class="lock_sh" (click)='showHidePassword2()'
                                                        *ngIf="showPassword2==true">
                                                        <i class="fa fa-eye-slash fa-w-20"></i>
                                                    </span>
                                                    <span class="lock_sh" (click)='showHidePassword2()'
                                                        *ngIf="showPassword2==false">
                                                        <i class="fa fa-eye fa-w-20"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex pb-4 pt-4 justify-content-center">
                                                    <div>
                                                        <button type="button"
                                                            class="btn btn-secondary rounded-pill px-lg-5 px-4 mr-2"
                                                            (click)="back()">Back</button>
                                                    </div>
                                                    <div class="ms-2">
                                                        <button type="button"
                                                            class="btn btn-theme2 text-center rounded-pill px-5"
                                                            (click)="save()">Save</button>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                           
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<!-- </div> -->