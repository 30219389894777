import { Component, OnInit ,ElementRef, ViewChild} from '@angular/core';
import { SellerRegistrationService } from '../_services/seller-registration.service';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../_services/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerLoginSigninService } from '../_services/customer-login-signin.service';
import { CommonFunctionService } from '../_services/common-function.service';
import { LoginService } from '../_services/login.service';
import { AppSettings } from '../_services/AppSetting';

declare var $: any;
@Component({
  selector: 'app-add-seller',
  templateUrl: './add-seller.component.html',
  styleUrls: ['./add-seller.component.css']
})
export class AddSellerComponent implements OnInit {
  @ViewChild('inputFile') myInputVariable: ElementRef;
  seller_id: any;
  getList: Object;
  sellerName: any;
  mobileNo:any;
  email:any;
  business: boolean = true;
  personal: boolean = true;
  getBusinessList: any = [];
  cerOfIncorporation1: boolean;
  uploadedProofsList: any = [];
  cerOfIncorporation: boolean;
  businessName:any;
  isLoader: boolean = false;
  storeName:any;
  businessType: any;
  prod_Catagory:any;
  gstNo:any;
  panNo:any;
  panCard1: boolean;
  gstCertificate:boolean;
  teleLanLineBill1: boolean;
  companyStrength:any;
  anCard1: boolean;
  gstCertificate1:boolean;
  accountDetail: any = [];
  account: boolean = false;
  address: boolean = false;
  confirm: boolean = false;
  rentAggrement1:boolean;
  panCard:boolean;
  rentAggrement:boolean;
  teleLanLineBill:boolean;
  accountHolderName:any;
  accountType:any;
  ifscCode:any;
  accountNo:any;
  addressDetail:any=[];
  bplotNo:any;
  bstreetName:any;
  bpinCode:any;
  bcity:any;
  businessStateId:any;
  splotNo:any;
  sstreetName:any;
  spinCode:any;
  scity:any;
  storeStateId:any;
  fillAddress:any;
  stateList: any = [];
  cityList:any = [];
  productCatagory: any = [];
  uploadedGalaryFiles:any = [];
  formData: FormData;
  identificationProof:any;
  addressProff: any;
  proofTypeList:any=[];
  identificationProofList:any=[];
  addressProffList:any=[];
  File4 : any;
  File3 : any;
  File2 : any;
  File1 : any;
  File:any;
  File5 : any;
  MassageOutTime = AppSettings.getMassageOutTime;
  docs = { 'idProof': false, 'addressProof': false, 'gstProof': false, 'trademark': false, 'BPan': false };
  constructor(private sellerRegistrationService: SellerRegistrationService,private toastrService: ToastrService, private customerLoginSigninService: CustomerLoginSigninService,
    private router: Router, private route: ActivatedRoute, private formService: FormService, public commonFunctionService: CommonFunctionService, private loginService: LoginService) { }

  ngOnInit(): void {
   
    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      if (data.length > 1) {
        var str = data;
        let temp_id = str.split("seller_id=")[1];
        this.seller_id = temp_id.substring(0, temp_id.length - 1);
      }
    });
    this.getProductCatagory();
    this.getStateList();
    this.getCityList();
    this.getAllProofTypes();
    this.getSellerDetail();
   
  }
  getSellerDetail() {
    this.sellerRegistrationService.getSellerDetail(this.seller_id).subscribe(res => {
      this.getList = res;
      this.sellerName = this.getList[0].seller_name
      this.mobileNo = this.getList[0].mobile_no
      this.email = this.getList[0].email
      setTimeout(() => {
        $('#account').removeClass('active');
        $('#business').removeClass('active');
        $('#address').removeClass('active');
        $('#confirm').removeClass('active');
        $(".progress-bar").css("width", '20%')
        this.personal = true;
        // this.business = false;
        // this.account = false;
        // this.address = false;
        // this.confirm = false;
      }, 100)
    })
  }
  selectIdProff() {
    if (this.identificationProof == 220) {
      this.panCard = true
      this.cerOfIncorporation = false
      this.teleLanLineBill = false
      this.rentAggrement = false
      this.gstCertificate = false
    }
    else if (this.identificationProof == 222) {
      this.panCard = false
      this.cerOfIncorporation = true
      this.teleLanLineBill = false
      this.rentAggrement = false
      this.gstCertificate = false
    }
    else if (this.identificationProof == 223) {
      this.panCard = false
      this.cerOfIncorporation = false
      this.teleLanLineBill = true
      this.rentAggrement = false
      this.gstCertificate = false
    }
    else if (this.identificationProof == 224) {
      this.panCard = false
      this.cerOfIncorporation = false
      this.teleLanLineBill = false
      this.rentAggrement = true
      this.gstCertificate = false
    } else if (this.identificationProof == 221) {
      this.panCard = false
      this.cerOfIncorporation = false
      this.teleLanLineBill = false
      this.rentAggrement = false
      this.gstCertificate = true
    }

  }
  RepeatAdress() {
    if (this.fillAddress == true) {
      this.splotNo = this.bplotNo;
      this.sstreetName = this.bstreetName;
      this.spinCode = this.bpinCode;
      this.scity = this.bcity;
      this.storeStateId = this.businessStateId;

    } else {
      this.splotNo = null;
      this.sstreetName = null;
      this.spinCode = null;
      this.scity = null;
      this.storeStateId = null;
    }
  }
  getAllProofTypes() {
    let SQLstr = `SELECT id,varibale_name,isactive FROM public.app_tblsystemenum where varibale_type='proofType' and isactive=true
  ORDER BY id ASC;`
    this.formService.getFormData(SQLstr).subscribe(res => {
      this.proofTypeList = res.rows;
      console.log("proofff", this.proofTypeList)
      this.identificationProofList = res.rows.filter(x => x.id == 220 || x.id == 221 || x.id == 222 );
      this.addressProffList  = res.rows.filter(x => x.id == 223 || x.id == 221 || x.id == 222 || x.id == 224 );

    })
  }
  getBusinessDetail() {
    this.sellerRegistrationService.getBusinessDetail(this.seller_id).subscribe(result => {
      let res: any = result
      if (res.length > 0) {
        this.getBusinessList = res;
        this.businessName = this.getBusinessList[0].business_name;
        this.storeName = this.getBusinessList[0].store_name;
        this.businessType = this.getBusinessList[0].business_type;
        this.prod_Catagory = this.getBusinessList[0].product_category;
        this.gstNo = this.getBusinessList[0].gstin_no;
        this.panNo = this.getBusinessList[0].pan_no;
        this.companyStrength = this.getBusinessList[0].company_strength;
      }
      setTimeout(() => {
        $('#business').addClass('active');
        $(".progress-bar").css("width", '40%')
        this.personal = false;
        this.business = true;
        this.account = false;
        this.address = false;
        this.confirm = false;
      }, 100)
    })
    // this.getAccountDetail()
  }
  getAccountDetail() {
    this.sellerRegistrationService.getSellerAccountDetail(this.seller_id).subscribe(result => {
      let res: any = result
      if (res.length > 0) {
        this.accountDetail = res;
        this.accountHolderName = this.accountDetail[0].account_holder_name
        this.accountType = this.accountDetail[0].account_type
        this.ifscCode = this.accountDetail[0].ifsc_code
        this.accountNo = this.accountDetail[0].account_no
        // this.previos('4');
        setTimeout(() => {
          $('#address').removeClass('active');
          $(".progress-bar").css("width", '60%')
          this.personal = false;
          this.business = false;
          this.account = true;
          this.address = false;
          this.confirm = false;
        }, 100)
      }
    })
  }
  getAddressDetail() {
    this.sellerRegistrationService.getSellerAddressDetail(this.seller_id).subscribe(result => {
      let res: any = result
      if (res.length > 0) {
        this.addressDetail = res;
        this.bplotNo = this.addressDetail[0].business_plot_no_or_block_no
        this.bstreetName = this.addressDetail[0].business_street_name
        this.bpinCode = this.addressDetail[0].business_pin_code
        this.bcity = this.addressDetail[0].business_city
        this.businessStateId = this.addressDetail[0].business_state_id
        this.splotNo = this.addressDetail[0].store_plot_no_or_block_no
        this.sstreetName = this.addressDetail[0].store_street_name
        this.spinCode = this.addressDetail[0].store_pin_code
        this.scity = this.addressDetail[0].store_city
        this.storeStateId = this.addressDetail[0].store_state_id
        this.fillAddress = this.addressDetail[0].is_selected
        // this.previos('5')
        setTimeout(() => {
          $('#confirm').removeClass('active');
          $(".progress-bar").css("width", '80%')
          this.personal = false;
          this.business = false;
          this.account = false;
          this.address = true;
          this.confirm = false;
        }, 100)
      }
    })
  }
  getProductCatagory() {
    this.sellerRegistrationService.getCategory().subscribe(res => {
      this.productCatagory = res;
    })

  }

  getStateList() {
    this.sellerRegistrationService.getState().subscribe(res => {
      this.stateList = res;
    })
  }

  getCityList() {
    this.sellerRegistrationService.getCity().subscribe(res => {
      this.cityList = res;
    })
  }
  getSCityAndState() {
    this.customerLoginSigninService.getCityState(this.spinCode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.scity = stateCityList.city_name ? stateCityList.city_name : null;
        this.storeStateId = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.scity = null;
        this.storeStateId = null;
      }
    })

  }
  getBCityAndState() {
    this.customerLoginSigninService.getCityState(this.bpinCode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.bcity = stateCityList.city_name ? stateCityList.city_name : null;
        this.businessStateId = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.bcity = null;
        this.businessStateId = null;
      }
    })

  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  onDocFileChnage(event, val, pos?) {
    const inputElement = event.target as HTMLInputElement;
    const { files, validity } = event.target;
    if (validity.valid) {
      let size = Math.round(Number(files[0].size) / 1024)
      if (files.length > 0) {
        var fname = files[0].name;
        var re = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
        if (!re.exec(fname)) {
          this.toastrService.warning("File Type Not Supported!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          inputElement.value = '';
          if(pos && pos==6){
            this.File5 = null;
          }
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
        var extarr = files[0].name.split(".");
        if (extarr.length > 2) {
          this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          inputElement.value = '';
          if(pos && pos==6){
            this.File5 = null;
          }
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
        if (size > 5120) {
          this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          inputElement.value = '';
          if(pos && pos==6){
            this.File5 = null;
          }
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
        let uploadfile: File = files;
        this.uploadedGalaryFiles.push(files[0]);
        if (val == 1) {
          this.onDocsUpload('GSTIN', 1);
        } else if (val == 2) {
          this.onDocsUpload('Trademark', 2);
        } else if (val == 3) {
          this.onDocsUpload('BusinessPanCard', 3)
        }
      }
    }
  }
  onIdProofFileChnage(event, val, txt, pos?) {
    //this.isLoader = true
    const { files, validity } = event.target;
    const inputElement = event.target as HTMLInputElement;
    console.log("calllledddddd files",files)
    if (validity.valid) {
      if (files.length > 0) {
        this.isLoader = true
        let size = Math.round(Number(files[0].size) / 1024)
        var fname = files[0].name;
        var re = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
        if (!re.exec(fname)) {
          this.toastrService.warning("File Type Not Supported!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          inputElement.value = '';
          if(pos && pos==1){
            this.File = null;
            this.docs.idProof=false;
          }else if(pos && pos==2){
            this.docs.addressProof= false;
            this.File1 = null;
          }else if(pos && pos==3){
            this.docs.gstProof= false;
            this.File2 = null;
          }else if(pos && pos==4){
            this.docs.trademark=false;
            this.File3 = null;
          }else if(pos && pos==5){
            this.docs.BPan=false;
            this.File4 = null;
          }else if(pos && pos==6){
            this.File5 = null;
          }
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          this.isLoader = false;
          return false;
        }
        var extarr = files[0].name.split(".");
        if (extarr.length > 2) {
          this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          inputElement.value = '';
          if(pos && pos==1){
            this.File = null;
            this.docs.idProof=false;
          }else if(pos && pos==2){
            this.docs.addressProof= false;
            this.File1 = null;
          }else if(pos && pos==3){
            this.docs.gstProof= false;
            this.File2 = null;
          }else if(pos && pos==4){
            this.docs.trademark=false;
            this.File3 = null;
          }else if(pos && pos==5){
            this.docs.BPan=false;
            this.File4 = null;
          }else if(pos && pos==6){
            this.File5 = null;
          }
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          this.isLoader = false;
          return false;
        }
        if (size > 5120) {
          this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          inputElement.value = '';
          if(pos && pos==1){
            this.File = null;
            this.docs.idProof=false;
          }else if(pos && pos==2){
            this.docs.addressProof= false;
            this.File1 = null;
          }else if(pos && pos==3){
            this.docs.gstProof= false;
            this.File2 = null;
          }else if(pos && pos==4){
            this.docs.trademark=false;
            this.File3 = null;
          }else if(pos && pos==5){
            this.docs.BPan=false;
            this.File4 = null;
          }else if(pos && pos==6){
            this.File5 = null;
          }
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          this.isLoader = false;
          return false;
        }
        let uploadfile: File = files;
        this.uploadedGalaryFiles.push(files[0]);
        this.isLoader = false;
        if (val == 1) {
          this.uploadIdProof(txt);
        } else if (val == 2) {
          this.uploadAddressProof(txt);
        }
      }
    }
  }
  uploadIdProof(txt) {
   
    if (txt == 'id') {
      this.docs.idProof = false;
    };
    if (this.uploadedGalaryFiles.length > 0) {
      this.formData = new FormData();
      let filename = this.uploadedGalaryFiles[0].name;
      this.formData.append("filename", filename);
      this.formData.append("files", this.uploadedGalaryFiles[0]);
      this.formData.append("seller_id", this.seller_id);
      this.formData.append("proof_type", 'identificationProof');
      this.formData.append("proof_type_name", this.identificationProof);
      this.sellerRegistrationService.uploadProofs(this.formData).subscribe(res => {
        if (res["status"] == 200) {
          if (txt == 'id') {
            this.docs.idProof = true;
          };
          this.toastrService.success('Identification Proof Uploaded successfully', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          this.uploadedGalaryFiles = [];
          this.formData.delete('filename');
          this.formData.delete('seller_id');
          this.formData.delete('files');
          this.formData.delete('proof_type');
          this.formData.delete('proof_type_name');
        } else {
          this.toastrService.error('Identification Proof addition failed', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      })
    } else {
      this.toastrService.warning('Please select file.', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
  }
  selectAddressProff() {
    console.log('sssssssssssssss', this.addressProff)
    if (this.addressProff == '220') {
      this.panCard1 = true
      this.cerOfIncorporation1 = false
      this.teleLanLineBill1 = false
      // this.rentAggrement = false
      // this.gstCertificate = false
      this.gstCertificate1 = false
    }
    else if (this.addressProff == 222) {
      this.panCard1 = false
      this.cerOfIncorporation1 = true
      this.teleLanLineBill1 = false
      this.rentAggrement1 = false
      this.gstCertificate1 = false
    }
    else if (this.addressProff == 223) {
      this.panCard1 = false
      this.cerOfIncorporation1 = false
      this.teleLanLineBill1 = true
      this.rentAggrement1 = false
      this.gstCertificate1 = false
    }
    else if (this.addressProff == 224) {
      this.panCard1 = false
      this.cerOfIncorporation1 = false
      this.teleLanLineBill1 = false
      this.rentAggrement1 = true
      this.gstCertificate1 = false
    } else if (this.addressProff == 221) {
      this.panCard1 = false
      this.cerOfIncorporation1 = false
      this.teleLanLineBill1 = false
      this.rentAggrement1 = false
      this.gstCertificate1 = true
    }
  }
  uploadAddressProof(txt) {
    if (txt == 'add') {
      this.docs.addressProof = false;
    }
    if (this.uploadedGalaryFiles.length > 0) {
      this.formData = new FormData();
      let filename = this.uploadedGalaryFiles[0].name;
      this.formData.append("filename", filename);
      this.formData.append("files", this.uploadedGalaryFiles[0]);
      this.formData.append("seller_id", this.seller_id);
      this.formData.append("proof_type", 'addressProof');
      this.formData.append("proof_type_name", this.addressProff);

      this.sellerRegistrationService.uploadProofs(this.formData).subscribe(res => {
        if (res["status"] == 200) {
          this.toastrService.success('Address Proof Uploaded successfully', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          if (txt == 'add') {
            this.docs.addressProof = true;
          }
          this.uploadedGalaryFiles = [];
          this.formData.delete('filename');
          this.formData.delete('seller_id');
          this.formData.delete('files');
          this.formData.delete('proof_type');
          this.formData.delete('proof_type_name');
        } else {
          this.toastrService.error('Address Proof addition failed', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      }, err => {
        this.toastrService.error('Address Proof addition failed', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      })
    } else {
      this.toastrService.warning('Please select file.', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
  }
  onDocsUpload(val, n) {
    if (n == 1) {
      this.docs.gstProof = false;
    }
    if (n == 2) {
      this.docs.trademark = false;
    }
    if (n == 3) {
      this.docs.BPan = false;
    }
    if (this.uploadedGalaryFiles.length > 0) {
      this.formData = new FormData();
      let filename = this.uploadedGalaryFiles[0].name;
      this.formData.append("filename", filename);
      this.formData.append("files", this.uploadedGalaryFiles[0]);
      this.formData.append("seller_id", this.seller_id);
      this.formData.append("document_type", val);
      this.identificationProof 
     
      this.sellerRegistrationService.uploadDocs(this.formData).subscribe(res => {
        if (res["status"] == 200) {
          if (n == 1) {
            this.docs.gstProof = true;
          }
          if (n == 2) {
            this.docs.trademark = true;
          }
          if (n == 3) {
            this.docs.BPan = true;
          }
          this.toastrService.success('Document Uploaded successfully', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          this.uploadedGalaryFiles = [];
          this.formData.delete('filename');
          this.formData.delete('seller_id');
          this.formData.delete('files');
          this.formData.delete('document_type');
        } else {
          this.toastrService.error('Document addition failed', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      });
    } else {
      this.toastrService.warning('Please select file.', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
  }
  // login() {
  //   $('#acknow').modal('hide');
  //   localStorage.clear();
  //   this.router.navigate(['login']);
  // }
  cancle(){
    this.businessName = null;
    this.storeName = null;
    this.prod_Catagory = null;
    this.gstNo = null;
    this.companyStrength = null;
    this.panNo = null;
    this.accountHolderName = null;
    this.accountType = null;
    this.accountNo = null;
    this.ifscCode = null;
    this.bplotNo = null;
    this.bstreetName = null;
    this.bpinCode = null;
    this.bcity = null;
    this.identificationProof = null;
    //this.bstate = null;
    this.splotNo = null;
    this.sstreetName = null;
    this.spinCode = null;
    this.scity = null;
    //this.sstate = null;
    this.businessType = null;
    this.storeStateId = null;
    this.businessStateId = null;
    this.addressProff = null;
  }
  submit() {
    if (this.businessName == null || this.businessName == undefined || this.businessName == '') {
      this.toastrService.warning('Please Enter Business Name. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.storeName == null || this.storeName == undefined || this.storeName == '') {
      this.toastrService.warning('Please Enter Store Name. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.businessType == null || this.businessType == undefined || this.businessType == '') {
      this.toastrService.warning('Please Select Business Type. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.prod_Catagory == null || this.prod_Catagory == undefined || this.prod_Catagory == '') {
      this.toastrService.warning('Please Select Product Category. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.gstNo == null || this.gstNo == undefined || this.gstNo == '') {
      this.toastrService.warning('Please Enter GSTIN. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.gstNo !== undefined && this.gstNo !== null && this.gstNo !== " ") {
      var pattrn = new RegExp('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9]{1}[zZ][a-zA-Z0-9]{1}$')
      if (this.gstNo.substring(0).length > 0) {
        var newpancard = this.gstNo.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid GST No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          //this.isLoading = false;
          return false;

        }
      }

    }
    if (this.panNo == null || this.panNo == undefined || this.panNo == '') {
      this.toastrService.warning('Please Enter PAN. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.panNo !== undefined && this.panNo !== null && this.panNo !== " ") {

      let pattrn = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
      if (this.panNo.substring(0).length > 0) {
        var newpancard = this.panNo.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid PAN Card No. in Capital Letter", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          //this.isLoading = false;
          return false;

        }
      }
    }
    if (this.companyStrength == null || this.companyStrength == undefined || this.companyStrength == '') {
      this.toastrService.warning('Please Enter Company Strength. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.companyStrength == 0 ) {
      this.toastrService.warning('Please Enter Valid Company Strength', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.accountHolderName = this.accountHolderName?.trim()
    if (this.accountHolderName == null || this.accountHolderName == undefined || this.accountHolderName == '') {
      this.toastrService.warning('Please Enter Account Holder Name. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.accountType == null || this.accountType == undefined || this.accountType == '') {
      this.toastrService.warning('Please enter your Account No. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.accountNo = this.accountNo?.trim()
    if (this.accountNo == null || this.accountNo == undefined || this.accountNo == '') {
      this.toastrService.warning('Please enter your Account No. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.accountNo == 0){
      this.toastrService.warning('Please enter your valid Account No. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.ifscCode == null || this.ifscCode == undefined || this.ifscCode == '') {
      this.toastrService.warning('Please Enter IFSC Code. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.ifscCode !== undefined && this.ifscCode !== null && this.ifscCode !== " ") {
      var pattrn = new RegExp("^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$")
      if (this.ifscCode.substring(0).length > 0) {
        var newpancard = this.ifscCode.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid IFSC Code.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      }
    }
    if (this.bplotNo == null || this.bplotNo == undefined || this.bplotNo == '') {
      this.toastrService.warning('Please Enter Plot No./ Block No. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.bplotNo.length < 3 || this.bplotNo.length > 100){
      this.toastrService.warning('Business Plot no. should be in-between 3 digits to 100 digits!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.bstreetName == null || this.bstreetName == undefined || this.bstreetName == '') {
      this.toastrService.warning('Please Enter Street Name. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.bstreetName.length < 3 || this.bstreetName.length > 100){
      this.toastrService.warning("Business Street name  should be in-between 3 characters to 100 characters!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.bpinCode == null || this.bpinCode == undefined || this.bpinCode == '') {
      this.toastrService.warning('Please Enter PIN Code. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.bcity == null || this.bcity == undefined || this.bcity == '') {
      this.toastrService.warning('Please Enter City. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.splotNo == null || this.splotNo == undefined || this.splotNo == '') {
      this.toastrService.warning('Please Enter Plot No./ Block No. ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.splotNo.length < 3 || this.splotNo.length > 100){
      this.toastrService.warning("Pickup Plot no. should be in-between 3 digits to 100 digits", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.sstreetName == null || this.sstreetName == undefined || this.sstreetName == '') {
      this.toastrService.warning('Please Enter Street Name . ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.sstreetName.length < 3 || this.sstreetName.length > 100){
      this.toastrService.warning("Pickup Street name should be in-between 3 characters to 100 Character!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.spinCode == null || this.spinCode == undefined || this.spinCode == '') {
      this.toastrService.warning('Please Enter PIN Code . ', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.docs.idProof == false || this.docs.idProof == null || this.docs.idProof == undefined) {
      this.toastrService.warning('Please Select Identification Proof', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.docs.addressProof == false || this.docs.addressProof == null || this.docs.addressProof == undefined) {
      this.toastrService.warning('Please Select Address Proof', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    // if (this.docs.gstProof == false || this.docs.gstProof == null || this.docs.gstProof == undefined) {
    //   this.toastrService.warning('Please Select Registered Business', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass();
    //   return false;
    // }
    if (this.docs.trademark == false || this.docs.trademark == null || this.docs.trademark == undefined) {
      this.toastrService.warning('Please select cancelled cheque', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    // if (this.docs.BPan == false || this.docs.BPan == null || this.docs.BPan == undefined) {
    //   this.toastrService.warning('Please Select Business PAN Card', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass();
    //   return false;
    // }
    if (this.identificationProof == false || this.identificationProof == null || this.identificationProof == undefined) {
      this.toastrService.warning('Please Select Identification Proof', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.addressProff == false || this.addressProff == null || this.addressProff == undefined) {
      this.toastrService.warning('Please Select Address Proof', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;

    }
    if(this.addressProff && this.identificationProof){
      if (  this.identificationProof == 221 || this.addressProff == 221 ) {
    
      }else{
        if (this.docs.gstProof == false || this.docs.gstProof == null || this.docs.gstProof == undefined) {
          this.toastrService.warning('Please Select Registered Business(GSTIN Certificate)', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      
      }
    }

 this.sellerRegistrationService.getuploadProofs(this.seller_id).subscribe(result => {
      if (result[0].length > 0) {
        let rows0 = result[0];
        let proof_type;
        for (let obj of rows0) {
          this.uploadedProofsList.push({ 'upload_date': obj.created_at });
        }
      }
      if (result[1].length > 0) {
        let rows1 = result[1];
        for (let obj of rows1) {
          this.uploadedProofsList.push({ 'upload_date': obj.created_at });
        }
      }
      if (this.uploadedProofsList.length >= 2 ) {
        // this.finalSubmission();
        $('#acknow').modal('show');
      } else {
        this.toastrService.warning('Please Upload all docments,When you upload all the document then your profile considered complete', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
      }
    })
    this.sellerRegistrationService.updateSellerAccountDetail(this.seller_id, this.accountHolderName, this.accountType, this.ifscCode, this.accountNo).subscribe(res => {
      // this.next('3');
      this.getAddressDetail() 
     
      // this.next('2');
    })
    this.sellerRegistrationService.updateSellerAddressDetail(this.seller_id, this.bplotNo, this.bstreetName, this.bpinCode,
      this.bcity, this.businessStateId, this.splotNo, this.sstreetName, this.spinCode, this.scity, this.storeStateId, this.fillAddress).subscribe(res => {
        // this.next('4');
      })
      this.sellerRegistrationService.updateBusinessDetail(this.businessName, this.storeName, this.businessType, this.prod_Catagory
        , this.gstNo, this.panNo, this.companyStrength, this.seller_id).subscribe(res => {
        })
         
         //localStorage.clear();
         setTimeout(() => {
          $("#acknow").modal("hide");
         this.router.navigate(["/seller-list"]);
         }, 2000)
        
  }
  close(){
    
    this.router.navigate(["/seller-list"]);
  }
  
}
