// import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges} from '@angular/core';
import {FormService} from '../../_services/form.service';
@Component({
  selector: 'app-hds-main-tab-group',
  templateUrl: './hds-main-tab-group.component.html',
  styleUrls: ['./hds-main-tab-group.component.css']
})
export class HdsMainTabGroupComponent implements OnInit {
  public TotalPages:number;
  @Output() valueChange = new EventEmitter();
  @Input() configParms:any;
  public filter = '';
  public maxSize:number =10;
  public directionLinks = true;
  public autoHide = false;
  public pageShowing = [];
  ispagingdataloaded:Promise<boolean> = Promise.resolve(false);
  constructor( 
    
    ) { }
  
  ngOnInit(): void {    
    this.pageShowing.push(
      {"labelDisplay":10, "labelValue": 10},
      {"labelDisplay":50, "labelValue": 50},
      {"labelDisplay":70, "labelValue": 70},
      {"labelDisplay":100, "labelValue": 100},
      {"labelDisplay":"ALL", "labelValue": this.configParms.totalItems});
    this.setPaginng();
   
    if(parseInt(this.configParms.totalItems)<this.pageShowing[0].labelValue)
    {
      this.configParms.itemsPerPage=this.configParms.totalItems;
    }
    else
    {
      this.configParms.itemsPerPage=this.pageShowing[0].labelValue;
    }
    console.log("pks==>",this.configParms);
    this.configParms.currentPage=1;
    this.ispagingdataloaded=Promise.resolve(false);
    this.ispagingdataloaded=Promise.resolve(true);
  }
 
  onPageNext() {
    this.configParms.currentPage = parseInt(this.configParms.currentPage)+1;
    if(parseInt(this.configParms.currentPage)>this.TotalPages)
    {
      this.configParms.currentPage=this.TotalPages;
    }
    else
    {
      this.valueChange.emit(this.configParms); 
    }
   }
   onPagePrevious() {
    this.configParms.currentPage = parseInt(this.configParms.currentPage)-1;
    if(parseInt(this.configParms.currentPage)<1)
    {
      this.configParms.currentPage=this.TotalPages;
    }
    else
    {
      this.valueChange.emit(this.configParms); 
    }
  
   }
  //  onPageNumberChange(obj) {
  //   this.config.currentPage = obj;
  //  this.valueChange.emit(); 
  //  }
    onFirstPage() {
    this.configParms.currentPage =1;
   this.valueChange.emit(this.configParms); 
   }
   onLastPage() {
   this.configParms.currentPage =this.TotalPages;
   this.valueChange.emit(this.configParms); 
   }
   setPaginng()
   {
    this.TotalPages=Math.ceil(parseInt(this.configParms.totalItems)/this.maxSize);
    if(this.TotalPages==0)
    {this.TotalPages=1}
   }
itemsPerPageChanged(val: number) { 
  if(val>parseInt(this.configParms.totalItems)){
    this.configParms.itemsPerPage=this.configParms.totalItems;
  }else{
    this.configParms.itemsPerPage = val;
  }

  this.maxSize=val;
  this.configParms.currentPage =1;
  this.valueChange.emit(this.configParms);
  this.setPaginng();  
 // this.ngOnInit();
  }
  ngOnChanges(changes : SimpleChanges):void{
    this.configParms = changes.configParms.currentValue;
    this.setPaginng();
    if(parseInt(this.configParms.totalItems)<this.pageShowing[0]?.labelValue)
    {
      this.configParms.itemsPerPage=this.configParms.totalItems;
    }
  }
}
