import { Component, OnInit } from '@angular/core';
import { Page } from '../_models/page';
import { ConfigPrams } from "../field.interface";
import { ProductService } from '../_services/product.service';
import { loadavg } from 'os';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonFunctionService } from '../_services/common-function.service';
import { ideacraftAdminService } from '../_services/ideacraftadmin.service';
import { CustomerLoginSigninService } from '../_services/customer-login-signin.service';
import { AppSettings } from '../_services/AppSetting';
import { SellerRegistrationService } from '../_services/seller-registration.service';
declare var $: any
@Component({
  selector: 'app-feedback-review',
  templateUrl: './feedback-review.component.html',
  styleUrls: ['./feedback-review.component.css']
})
export class FeedbackReviewComponent implements OnInit {
  isLoading: boolean = true;
  LoadPagination: boolean = false;
  public totalCount = 0;
  productRatingList: any[] = [];
  page = new Page();
  isView = true
  remarks: any;
  configParms: ConfigPrams;
  isLoader: boolean;  
  currentBuyerName: any = '';  searchTerm: any;
  isloadgridTrue: boolean = false;  
  sysEnumList: any =[];  
  comany_name:any; 
  MassageOutTime = AppSettings.getMassageOutTime;
  globalmsg: string;

  constructor(private ProductService: ProductService, 
    private ideacraftService: ideacraftAdminService,
    private sellerService: SellerRegistrationService,
    private customerLoginSigninService: CustomerLoginSigninService,private toastrService: ToastrService, private router:Router,
    public commonFunctionService: CommonFunctionService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.bindNgxDataGrid(this.page, 1)
    this.getSysEnum();
  }

  

  getSysEnum() {
    this.ideacraftService.getSysEnumType('creditPeriod').subscribe((res: any) => {
      this.sysEnumList = res.rows;
    });
  }
  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
    
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
  } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    if(!this.isloadgridTrue)
    offset = this.searchTerm ? 0 : offset
    this.ProductService.allproductReviewAndRatingCount(this.searchTerm).subscribe((resp:any) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.ProductService.allproductReviewAndRating(pagesize, offset, this.searchTerm).subscribe((res:any) => {
        if(res.rows.length > 0){
          this.productRatingList = res.rows;
           console.log(this.productRatingList)          
          this.isLoading = false;
        }else{
          setTimeout(() => {
            this.productRatingList = []
          this.isLoading = false;
          }, 100);
        }
      

      })

      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
   //    this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      // console.log(this.configParms,'========>>>>>>>>>>>buyer')
      this.LoadPagination = true;

    })

  }
  viewmore(msg) {
    this.globalmsg = ''
    this.globalmsg = msg;
  }
  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;
    this.bindNgxDataGrid(this.page, 1)

  }
  onDelete(rateid) {
    console.log("rateiddd", rateid)
    rateid = rateid.rowData;
    Swal.fire({
      title: `Are you sure you want to delete this product review ?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.deleteProductReviewData(rateid.id).subscribe(
          (res) => {
            Swal.fire('Product review data deleted successfully', '', 'success');
            this.bindNgxDataGrid(this.page, 1);
          }
        );
      }
    })

  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}

