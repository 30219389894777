<div style="justify-content: center;align-items: center;display: flex;">
  <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
  <!-- *ngIf="isLoading" -->
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>



<div class="modal-content">
  <div class="modal-header py-2">
    <h6 class="modal-title">
      <strong>Add New Buyer</strong>
    </h6>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="edit()">
      <span aria-hidden="true" (click)="reset()">&times;</span>
    </button>
  </div>


  <div class="modal-body bg-white min-height">

    <div class="col-sm-12 ">
      <div class="row">


        <div class="col-12">
          <label for="mobileNo">Mobile Number
            <span class="text-danger">*</span>
          </label>
          <span>
            <a href="javascript:void(0)" class="txtedt float-right" (click)="edit()" *ngIf="isMobDisable">
              Edit</a>
          </span>
          <input type="text" class="form-control" name="mobileNo" (keypress)="commonFunctionService.numericCheck($event)" [disabled]="isMobDisable"
            maxlength="10" placeholder="Enter Mobile Number" [(ngModel)]="mobileNo" id="mobileNo" autocomplete="off" onpaste="return false;"
            ondrop="return false;">
        </div>


        <div class="col-sm-12" *ngIf="isMobDisable">
          <div class="row">
            <!-- <div class="col-sm-12">
              <span class="font8" *ngIf="isMobDisable">Your OTP is : {{otptext}}</span>
              <span class="font8 float-right mt-1">
                <a href="javascript:void(0)" class="font600 text-decoration-none rsn" *ngIf="!isTimeout">Didn't receive OTP? {{ timeLeft | formatTime}}
                </a>
              </span>

              <span class="font8 float-right mt-1" *ngIf="isMobDisable">
                <a href="javascript:void(0)" class="font600 txtedt mob-edit" *ngIf="isTimeout" (click)="sendOtp()"> Resend</a>
              </span>

            </div> -->
            <!-- <div class="col-12 pb-2">
              <label for="FirstName"> OTP
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" name="otp" maxlength="4" (keypress)="commonFunctionService.numericCheck($event)"
                placeholder="Enter OTP" [(ngModel)]="otp" maxlength="4" id="otp" onpaste="return false;" ondrop="return false;"
                autocomplete="off">

            </div> -->
            <div class="col-12 pb-2">
              <label for="FirstName">Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" name="firstName" maxlength="10" (keypress)="commonFunctionService.alphabetsValidator($event)"
                placeholder="Enter Name" [(ngModel)]="firstName" maxlength="20" id="FirstName" onpaste="return false;" ondrop="return false;"
                autocomplete="off">

            </div>

            <div class="col-12 pb-2">
              <label for="Email">Email
                <span class="text-danger">*</span>
              </label>
              <input type="email" class="form-control" maxlength="50" name="email" placeholder="Enter E-mail Address" (keypress)="commonFunctionService.eMailValidator($event)"
                [(ngModel)]="email" id="Email" onpaste="return false;" ondrop="return false;" autocomplete="off"  oninput="this.value = this.value.toLowerCase();">

            </div>

            <div class="col-12 pb-2">
              <label for="Password">Password
                <span class="text-danger"> *</span>
              </label>
              <input type="password" class="form-control" name="password" maxlength="50" placeholder="Enter Password" [(ngModel)]="password"
                id="Password" onpaste="return false;" ondrop="return false;" autocomplete="off">

              <p class="font8 mt-2 text-start">8 characters minimum, case sensitive, no space</p>
            </div>

          </div>
        </div>

        <hr *ngIf="isMobDisable" class="m-0">
        <div class="col-sm-12 col-lg-12" *ngIf="isMobDisable">
          <h6>I primarily shop for:</h6>
          <div class="mb-2 clearfix">
            <div class="form-check form-check-inline me-4">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" [(ngModel)]="shopFor" (change)="chooseOption()" id="inlineRadio1"
                value="mySelf">
              <label class="form-check-label ml-0" for="inlineRadio1">Myself</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" [(ngModel)]="shopFor" (change)="chooseOption()" id="inlineRadio2"
                value="Business">
              <label class="form-check-label ml-0" for="inlineRadio2">A Business</label>
            </div>
          </div>

          <div *ngIf="show">
            <div class="pb-2 mt-1" *ngIf="show">
              <label for="Organization">Organization
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" name="organization" [(ngModel)]="organization" maxlength="10" placeholder="Enter Organization"
                maxlength="20" id="Organization" onpaste="return false;" ondrop="return false;" autocomplete="off">

            </div>
            <div class="pb-2">
              <label for="Industry">Industry</label>
              <select class="form-select form-control" id="Industry" [(ngModel)]="industryId">
                <option value="undefined" disabled selected>-- Select Industry --</option>
                <option *ngFor="let obj of IndustryList" value="{{obj.industry_type_id}}">{{obj.industry_type_name}}
                </option>
              </select>

            </div>
            <div class="pb-3 d-flex align-items-center">
              <div class="form-check-inline">
                <input class="form-check-input" type="checkbox" id="SpecialOffers">
                <label class="form-check-label font9 ml-1" for="SpecialOffers">
                  Send Me Periodic Emails And Special Offers
                </label>
              </div>
              <!-- <input type="checkbox" class="me-2 wid18 height18" >
                        <label class="font9">Send Me Periodic Emails And Special Offers</label> -->
            </div>
          </div>

        </div>
        <div class="col-sm-12  text-center" *ngIf="!isMobDisable">
          <button class="btn btn-theme text-center creatact rounded-pill px-lg-4 mt-3 mb-1 py-1 px-3 btn-sm" (click)="sendOtp()"> Continue </button>
        </div>
        <div class="col-sm-12 text-center" *ngIf="isMobDisable">
          <button class="btn  btn-theme text-center creatact rounded-pill px-lg-4 py-1 px-3 btn-sm" (click)="createAccount()">
            Continue </button>
        </div>
      </div>
    </div>
  </div>
</div>