import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
import { AppSettings } from '../../_services/AppSetting';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { now } from 'moment';
const randomFixedInteger = (length) => {
  return Math.floor(Math.pow(10, length-1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length-1) - 1));
}
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare var $: any;

@Component({
  selector: 'app-manage-all-orders',
  templateUrl: './manage-all-orders.component.html',
  styleUrls: ['./manage-all-orders.component.css']
})
export class ManageAllOrdersComponent implements OnInit {
  sellerId: any;
  cancledDataShow: any
  // deliveredDataShow:any
  adminRemarks: any;
  QC_id: any;
  view_item_id: any;
  isView: boolean;
  allOrderList: any = [];
  isLoader: boolean = false;
  ShippedDataShow: boolean = false;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  LoadPagination2: boolean = false;
  LoadPagination3: boolean = false;
  LoadPagination4: boolean = false;
  LoadPagination5: boolean = false;
  LoadPagination6: boolean = false;
  deliveredDataShow: boolean = false;
  TempallOrderList: any;
  isLoading: boolean = true;
  userId: any;
  rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  pendingDataShow: boolean = false;
  dispatchDataShow: boolean = false;
  allOrderListData: any;
  productId: any;
  action: any;
  qcstatus: any;
  rejectDataShow: boolean = false;
  sellerName: any;
  pendingOrderCount: any;
  rejectOrderCount: any;
  shipedOrderCount: any;
  readyToShipedOrderCount: any;
  acceptedOrderCount: any;
  cancledOrderCount: any;
  deleveredOrderCount: any;
  currentTooltip: any = ""
  orderListData: any;
  LoadPaginationfive: boolean = false;
  totalCountPending: any;
  currentOrderStatus: any = '';
  currentOrderStatus2: any = '';
  searchTerm: any;
  MassageOutTime = AppSettings.getMassageOutTime;
  isLoadGrid: boolean = false;
  startDate: any;
  fromDate: any;
  todate: any;
  orderNUmber: any;
  gstAmount: any;
  invoiceprice: number;
  invoicetotal: number;
  invoicemrp: number;
  discount: number;
  constructor(private productService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.sellerName = localStorage.getItem('sellerName');
    this.isLoader = true;
    this.pendingDataShow = true;
    this.dispatchDataShow = false;
    this.ShippedDataShow = false;
    this.rejectDataShow = false;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.getProductOrderList();
    this.bindNgxDataGrid(this.page, 1, 187)

    $(document).ready(function () {
      $('ul.tbsbtns li').click(function () {
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
      });
    });

  }
  setTitle(e) {
    this.currentTooltip = e;
  }
  noofdays() {
    var date1 = new Date("10/12/2022");
    var date2 = new Date(new Date());

    var Time = date2.getTime() - date1.getTime();
    var Days = Math.round(Time / (1000 * 3600 * 24)); //Diference in Days
    console.log("dayysys:", Days)
  }

  getProductOrderList() {
    let byseller = ''
    this.isLoader = true
    this.productService.getAllOrderListWiseForcount().subscribe(res => {
      this.orderListData = res.rows;
      // console.log("listdata===", this.orderListData)
      this.pendingOrderCount = (this.orderListData.filter(x => x.order_status == 187)).length;
      this.rejectOrderCount = (this.orderListData.filter(x => x.order_status == 188)).length;
      this.shipedOrderCount = (this.orderListData.filter(x => x.order_status == 189)).length;
      this.readyToShipedOrderCount = (this.orderListData.filter(x => x.order_status == 192)).length;
      this.acceptedOrderCount = (this.orderListData.filter(x => x.order_status == 194)).length;
      this.cancledOrderCount = (this.orderListData.filter(x => x.order_status == 190)).length;
      byseller = (this.orderListData.filter(x => x.order_status == 191)).length;
      this.cancledOrderCount = byseller + this.cancledOrderCount
      this.deleveredOrderCount = (this.orderListData.filter(x => x.order_status == 193)).length;
      setTimeout(() => {
        this.isLoader = false;
      }, 5000);
    })
  }
  LoadGrid(event) {
    this.isLoadGrid = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1, undefined)

  }
  getMinDate() {

    let tommorow = new Date().getDate()
    let cur = new Date(new Date().getFullYear(), new Date().getMonth(), tommorow)
    return new Date(cur.getFullYear(), cur.getMonth(), cur.getDate() + 1).toISOString().split('T')[0];
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  filterdatewise() {
    //console.log(this.fromDate)
    if (this.fromDate == null || this.fromDate == undefined || this.fromDate == '') {
      this.toastrService.warning('Please select from date', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass()
      return false;
    }
    if (this.todate == null || this.todate == undefined || this.todate == '') {
      this.toastrService.warning('Please select To date', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass()
      return false;
    }
    if (this.fromDate > this.todate) {
      this.toastrService.warning('End Date should be greater than From Date', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass()
      return false;
    }
    this.allOrderList = this.TempallOrderList.filter(x => x.cdate >= this.fromDate && x.cdate <= this.todate)
    //console.log('tttttttttttttttttttttttttt', this.allOrderList)
    // this.fromDate = ''
    // this.todate = ''
    // for (const obj of this.TempallOrderList) {
    //       debugger
    //       if(obj.cdate == this.fromDate)
    //       console.log('sssssssss', obj.cdate)
    //       if(obj.cdate == this.todate)
    //         console.log('ssssssssssstoda', obj.cdate)
    // }
  }

  bindNgxDataGrid(pageInfo, status, orderStatus, orderStatus2?) {
    this.currentOrderStatus = orderStatus ? orderStatus : this.currentOrderStatus
    this.currentOrderStatus2 = orderStatus2 ? orderStatus2 : this.currentOrderStatus2
    // console.log('check orderstatus', {first: this.currentOrderStatus, second: this.currentOrderStatus2})
    //this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    //  let orderStatus = 187;


    if (!this.isLoadGrid)
      offset = this.searchTerm ? 0 : offset
    this.productService.getAllOrderListwisecount(this.searchTerm, this.currentOrderStatus, this.currentOrderStatus2).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      // console.log('countss', this.page.totalElements)
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getManageAllOrderList(this.searchTerm, pagesize, offset, this.currentOrderStatus, this.currentOrderStatus2).subscribe(res => {
        console.log('check response', res.rows)
        this.allOrderList = res.rows;
        for (const obj of this.allOrderList) {
          obj.cdate = obj.cdate.split('T')[0]
        }
        // this.sellerName = this.allOrderList[0]?.seller_name;
        this.TempallOrderList = res.rows;
        //this.isLoader = false;
      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      if (orderStatus == 187) {
        this.LoadPagination = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination5 = this.LoadPagination6 = false;
      }
      if (orderStatus == 192) {
        this.LoadPagination2 = true;
        this.LoadPagination = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination5 = this.LoadPagination6 = false;
      }
      if (orderStatus == 189) {
        this.LoadPagination3 = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination = this.LoadPagination5 = this.LoadPagination6 = false;
      }
      if (orderStatus == 193) {
        this.LoadPagination4 = true;
        this.LoadPagination2 = this.LoadPagination = this.LoadPagination3 = this.LoadPagination5 = this.LoadPagination6 = false;
      }
      if (orderStatus == 190 && orderStatus2 == 191) {
        this.LoadPagination5 = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination = this.LoadPagination6 = false;
      }
      if (orderStatus == 194) {
        this.LoadPagination6 = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination = this.LoadPagination5 = false;
      }

    })

  }
  countextrafeature(obj) {
    //console.log("objjjjj", obj)
    let extra_feature = JSON.parse(obj);
    if (extra_feature != null) {
      let extra_feature_price = 0;
      for (let i = 0; i < extra_feature.length; i++) {
        if (extra_feature[i].isSelected)
          extra_feature_price += Number(extra_feature[i].price);
      }
      return extra_feature_price;
    }
  }
  CommonGridSearchMaster(ev) {
    // console.log('check orderstatus foro searc', {first: this.currentOrderStatus, second: this.currentOrderStatus2})

    this.searchTerm = ev;
    // this.getAllTypeOrder()
    if (this.currentOrderStatus2)
      this.bindNgxDataGrid(this.page, 1, this.currentOrderStatus, this.currentOrderStatus2);
    else
      this.bindNgxDataGrid(this.page, 1, this.currentOrderStatus);
    // this.allOrderList = this.TempallOrderList.filter(item => ((item.order_number ? (item.order_number.toLowerCase().indexOf(ev) !== -1) : false) || (item.product_name ? (item.product_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.order_number ? (item.order_number.toLowerCase().indexOf(ev) !== -1) : false)))
  }

  getAllTypeOrder(code, code2?) {
    this.searchTerm = ''
    this.currentOrderStatus = ''
    this.currentOrderStatus2 = ''
    // console.log('check for status code', {code: code,code2: code2})
    // this.can = false;
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber = 0
    this.page.totalPages = 0;
    if (code2)
      this.bindNgxDataGrid(this.page, 1, code, code2)
    else {
      this.bindNgxDataGrid(this.page, 1, code)
      this.currentOrderStatus2 = ''
    }
  }




  closeModal() {
    this.isView = false;
    $("#myModal").modal('hide');
  }


  onProductdetailView(data) {
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    $('#scroll').animate({ scrollTop: 0 }, 'slow');
    this.isView = true;
    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal").modal('show');
      //this.toastrService.success("Product Approved", '', { timeOut: 3000, positionClass: "toast-top-center" 
    });
  }

  showSellerDetails(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem('approve_reject', 'false')
    this.router.navigateByUrl('/seller-profile' + '?' + localStorage.getItem('tempToken'));

  }
  getDocumentDefinition(item) {
    let orderNUmber = item.rowData.order_number;
   let selleroriginPincode = item.rowData.store_pin_code;
    let sellercity = item.rowData.store_city;
    let sellerstate = item.rowData.store_state_id;
    let seller_StateName  = item.rowData.state_name
    let seller_address_line_1 = item.rowData.store_plot_no_or_block_no;
    let seller_address_line_2 = item.rowData.store_street_name;
    let gstNo = item.rowData.gstin_no;    
    var invoice = Math.floor( Math.random() * 1 ) ;
    let invoiceno = invoice.toString();
     let invoiceNo = randomFixedInteger(13)
     let orderDate = item.rowData.createddate.slice(0,10)
     let today = new Date()
     //let invoicedate = new Date(new Date().getFullYear(), new Date().getMonth(), today)
     let invoicedate = JSON.stringify(today)
     invoicedate = invoicedate.slice(1,11)
    return {
      content: [[
        {
          stack: [{
            fontSize: 9,
            margin: [0, 0, 0, 40],
            stack: [{ text: "TAX INVOICE", alignment: 'center', margin: [0, 0, 0, 2] },
            { text: "(Issued under section 31 of GST Act,2017)", alignment: 'center', margin: [0, 0, 0, 4] },
            { text: "Ideacraft eVentures Pvt. Ltd", alignment: 'center', bold: 'true', margin: [0, 0, 0, 6] },
            { text: '1011-1018, 10th Floor Tower 4, DLF Corporate Greens, Southern Peripheral Rd', alignment: 'center', bold: 'true' },
            { text: 'Sector 74A, Gurugram, Haryana-122004', alignment: 'center', bold: 'true' },]

          },
          {
            fontSize: 9,
            table: {
              body: [
                [
                  { stack: [{ text: 'IdeaCraft GSTIN No : 07AAJCM7312H1ZG', style: 'mb4' }, { text: 'IdeaCraft PAN No : AAJCM7312H', style: 'mb4' }, { text: 'IdeaCraft State Code : 07', style: 'mb4' }, { text: 'IdeaCraft Invoice Sr. No : 71120220078861', style: 'mb4' }, { text: `IdeaCraft Invoice Date : ${orderDate}`}], bold: 'true', margin: [0, 2] },
                  { stack: [{ text: 'Date &Time of Supply;' }, { text: 'Place of Supply : Haryana ' }, { text: 'Tax payable on reverse charge basis : No' }], bold: 'true', margin: [0, 2] },

                ],


              ], widths: ['*', '*']
            },

          },
          {
            fontSize: 9,
            table: {
              body: [
                [
                  { stack: [{ text: 'Detail of Consignee (Shipped To) :', bold: 'true', style: 'mb4' }, { stack: [{ text: 'Name:Ideacraft Eventures Private Limited' }, { text: 'Address: Plot No. 359, Near Groz Engineering,Before toll plaza Nat, Village Kherki Daula' }, { text: 'City: Gurugram' }, { text: 'State: Haryana' }, { text: 'Pin Code: 122004' }, { text: 'GSTIN No: 06AACCI4546G1ZP' }, { text: 'PAN No: AACCI4546G' },{text: 'Email: sanjay@ideacraft.com'}, { text: 'Phone No: 9971022356' }] }], margin: [0, 2] },
                  { stack: [{ text: 'Shipped From :', bold: 'true', style: 'mb4' }, { stack: [{ text: `City: ${sellercity}`}, { text: `State: ${seller_StateName}`}, {text: `Pincode: ${selleroriginPincode}`}], }, { text: `Address: ${seller_address_line_1}, ${seller_address_line_2}` }, { text: `GSTIN No: ${gstNo}`}], margin: [0, 2] },
                ],], widths: ['*', '*']
            }
          },
          {
            fontSize: 9,
            table: {
              body: [
                [
                  { stack: [{ text: 'Detail of Buyer (Billed To) :', bold: 'true', style: 'mb4' }, { stack: [{ text: 'Name: Ideacraft Eventures Private Limited' }, { text: 'Address: Plot No. 359, Near Groz Engineering,Before toll plaza Nat, Village Kherki Daula' }, { text: 'City: Gurugram' }, { text: 'State: Haryana' },{ text: 'Pin Code: 122004' }, { text: 'GSTIN No: 06AACCI4546G1ZP' }, { text: 'PAN No: AACCI4546G' },{text: 'Email: sanjay@ideacraft.com'}, { text: 'Phone No: 9971022356' }] }], margin: [0, 2] },
                  { stack: [{ text: `Invoice No: ${(invoiceNo)}` }, { text: `Customer Order No: ${(orderNUmber)}` }, { text: `Invoice Date: ${(orderDate)}`}, { text: 'Irn NO:' }, { text: 'aae0fb57b89bf4220491f34cbb6af0709acdba6f2c36fa460aa120407d246806' }, { text: 'Irn date: 2022-02-24' }, { text: 'E-Waybill No' }, { text: 'Transport:' }, { text: 'Vehicle No:' }, { text: 'Station:' }, { text: 'Vendor Code:' }, { text: 'Remarks:' }], margin: [0, 2] },
                ],], widths: ['*', 'auto']
            }
          },
          {
            color: 'black',
            table: {
              headerRows: 1,
              widths: [20, 50, 'auto', 50, '*', 50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: this.orderdetailslist(),
            }, fontSize: 7
          },
          {

            table: {

              widths: ['*', '*'],
              heights: 50,
              body: [
                [{
                  stack: [
                    { text: 'Terms & Conditions' },
                    { text: '1. Goods once sold will not be taken back' },
                    { text: '2. All claims for Exchange and Warranty will be taken as per Ideacraft Policy only.' },
                    { text: '3. All matters under this sale are subject to Haryana Jurisdiction only.' },
                    { text: '4. In case of Non-Delivery or any other dispute please contact IdeaCraft:' },
                    {
                      stack:[
                    { text: 'a. Customer Care No: 0124-6010900' },
                    { text: 'b. NEFT Details:' },
                    
                        { text: '   Ideacraft eVentures Pvt. Ltd.' },
                        { text: '   Bank: Kotak Mahindra Bank Ltd.' },
                        { text: '   A/c No.: 02912090000547 , IFS Code:KKBK0000291' },
                        { text: '   Branch: DLF PH-4, Galleria Market Complex, Gurugram -122002' },
                        { text: 'c. Corporate office:' },
                    { text: '   1011-1018, 10th Floor, Tower 4,DLF Corporate Greens, Southern Peripheral Road,' },
                    { text: '   Sector 74A, Gurgaon,Haryana – 122004, India. ' },
                      ],
                      style: 'superMargin'
                    },                
                    
                  ], fontSize: 6
                }, { alignment: 'center', margin: [5, 10], stack: [{ text: 'Authorized Signature (For Ideacraft eVentures Pvt. Ltd.)', bold: true, fontSize: 9, margin: [0, 10, 0, 0] }] }],
              ]
            }
          },
          {
            table: {
              widths: ['*'],
              body: [
                [{ text: `Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are 
                true and correct. `, alignment: 'left', color: '', fontSize: 9, margin: [0, 0, 0, 0] }]
    
              ],
            }, layout: 'noBorders',
          },], margin: 10
        }]

      ],
      styles: {
        myHeading: {
          fontSize: 16,
          bold: true
        },
        bigger: {
          fontSize: 15,
          italics: true
        },
        textDanger: {
          color: 'red',
          italics: true
        },
        font9: {
          fontSize: 9
        },
        mb4: {
          margin: [0, 0, 0, 4]
        },
        mb40: {
          margin: [0, 0, 0, 40]
        },
        tableHeader: {
          bold: true,
          alignment: 'center'
        },
        superMargin: {
          margin: [10, 0, 30, 0],
        }
      }
    };
    
  }
  newjson: any;
  generatePdf(item,action = 'open') { 
    let extra_feature = JSON.parse(item.rowData.extra_features);
    let extra_feature_price = 0;
     if(extra_feature != null){  
    if(extra_feature && extra_feature.length>0){
      for (let i = 0; i < extra_feature.length; i++) {
        if(extra_feature[i].isSelected)
        extra_feature_price += Number(extra_feature[i].price);
    }
    }}
    if(item.rowData.product_type == 198 || item.rowData.product_type == 208){
      if(item.rowData.is_bundle == true){
        this.gstAmount = (((Number((item.rowData.order_price))*Number(item.rowData.total_quantity))* Number(item.rowData.gst_rate))/100)
        this.invoiceprice = (Number(item.rowData.order_price))
        this.invoicetotal = (Number(item.rowData.order_price)+Number(extra_feature_price))*Number(item.rowData.total_quantity)
        this.invoicemrp = Number(item.rowData.product_mrp) * Number(item.rowData.total_quantity)
        this.discount = Number(this.invoicemrp)-(Number(item.rowData.order_price)*Number(item.rowData.total_quantity))
      }else{
        this.gstAmount = ((Number(((item.rowData.product_price)-Number(item.rowData.admin_price))+(Number(extra_feature_price)*Number(item.rowData.total_quantity)))* Number(item.rowData.gst_rate))/100)
        this.gstAmount =  parseFloat(this.gstAmount).toFixed(2);
        this.invoiceprice = (Number((item.rowData.product_price)-Number(item.rowData.admin_price)))
        this.invoicemrp = Number((item.rowData.product_mrp)-Number(item.rowData.admin_mrp)) * Number(item.rowData.total_quantity)
        this.invoicetotal = (Number(((item.rowData.product_price)-Number(item.rowData.admin_price))*Number(item.rowData.total_quantity))+(Number(extra_feature_price)*Number(item.rowData.total_quantity)))
        this.discount = Number(this.invoicemrp)-(Number((item.rowData.product_price)-Number(item.rowData.admin_price))*Number(item.rowData.total_quantity))
      }
    
    }
    if(item.rowData.product_type == 197 || item.rowData.product_type == 209){
      if(item.rowData.is_bundle == true){
        this.gstAmount = (((Number(item.rowData.order_price))* Number(item.rowData.gst_rate))/100)* Number(item.rowData.total_quantity)
        this.gstAmount =  parseFloat(this.gstAmount).toFixed(2);
        this.invoiceprice = Number(item.rowData.order_price)
        this.invoicemrp = Number(item.rowData.product_mrp)-Number(item.rowData.admin_mrp)
        this.discount = (Number(this.invoicemrp)-Number(this.invoiceprice))*Number(item.rowData.total_quantity)
        this.invoicetotal = Number(item.rowData.total_quantity) * (Number(this.invoiceprice)+Number(extra_feature_price))
      }
      else{
        this.gstAmount = ((((Number(item.rowData.product_price)-Number(item.rowData.admin_price))+Number(extra_feature_price))* Number(item.rowData.gst_rate))/100)* Number(item.rowData.total_quantity)
        this.gstAmount =  parseFloat(this.gstAmount).toFixed(2);
        this.invoiceprice = Number(item.rowData.product_price)-Number(item.rowData.admin_price)
        this.invoicemrp = Number(item.rowData.product_mrp)-Number(item.rowData.admin_mrp)
        this.discount = (Number(this.invoicemrp)-Number(this.invoiceprice))*Number(item.rowData.total_quantity)
        this.invoicetotal = Number(item.rowData.total_quantity) * (Number(this.invoiceprice)+Number(extra_feature_price))
      }
    }
       
    console.log("itemitemitem",item)
    this.newjson = [{
      'description': `${item.rowData.product_name}`,
      'hsn': `${item.rowData.hsn ? item.rowData.hsn : '-'}`,
      'qty': `${item.rowData.total_quantity}`,
      'unit': '-',
      'price': `${this.invoiceprice}`,
      'discount': Number(this.discount),
      'Extra Feature Price': `${extra_feature_price ? extra_feature_price : 0}`,
      'total': `${this.invoicetotal}`,
      'IGST': `${item.rowData.gst_rate ? item.rowData.gst_rate : '-'}`,
      'IGST Amount': `${this.gstAmount}`,
      'amount': Number(this.invoicetotal) + Number(this.gstAmount)

    }
    ]

    const documentDefinition = this.getDocumentDefinition(item);
    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print': pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;
      default: pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }
  orderdetailslist() {
    // console.log('----------------',this.newjson);

    let tbl = [];
    tbl.push([
      { text: 'Sr. No.', alignment: 'center', bold: true },
      { text: 'Description of Goods ', alignment: 'center', bold: true },
      { text: 'HSN', alignment: 'center', bold: true },
      { text: 'Qty', alignment: 'center', bold: true },
      { text: 'Unit', alignment: 'center', bold: true },
      { text: 'Price', alignment: 'center', bold: true },
      // { text: 'Gross Earning', alignment: 'center', bold: true },
      { text: 'Discount', alignment: 'center', bold: true },
      { text: 'Extra Feature Price', alignment: 'center', bold: true },

      { text: 'Total', alignment: 'center', bold: true },
      { text: 'IGST %', alignment: 'center', bold: true },
      { text: 'IGST Amount', alignment: 'center', bold: true },
      { text: 'Amount', alignment: 'center', bold: true }


    ])

    for (var c = 0; c < this.newjson.length; c++) {
      tbl.push([
        { text: c + 1, alignment: 'center' },
        { text: this.newjson[c]["description"], alignment: 'center' },
        this.newjson[c]["hsn"],
        { text: this.newjson[c]["qty"], alignment: 'center' },
        { text: this.newjson[c]["unit"], alignment: 'center' },
        { text: this.newjson[c]["price"], alignment: 'center' },
        // {text:this.newjson[c]["Gross Earning"],alignment:'center'},
        { text: this.newjson[c]["discount"], alignment: 'center' },
        { text: this.newjson[c]["Extra Feature Price"], alignment: 'center' },
        { text: this.newjson[c]["total"], alignment: 'center' },
        { text: this.newjson[c]["IGST"], alignment: 'center' },
        { text: this.newjson[c]["IGST Amount"], alignment: 'center' },
        { text: this.newjson[c]["amount"], alignment: 'center' }

      ])

    }
    let n = ''
    let total = this.newjson[0].amount
    tbl.push([
      { text: 'Grand Total', alignment: 'center', bold: true, colSpan: 3 },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true, colSpan: 2 },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: n, alignment: 'center', bold: true },
      { text: `${this.rupeeIndian.format(total)}`, alignment: 'center', bold: true }
    ])
    tbl.push([
      { text: `Total Invoice Value :${total}`, alignment: 'center', bold: true, colSpan: 12 },

    ])
    tbl.push([
      { text: `Total Invoice Value :${this.convertAmountIntoWords(total)}`, alignment: 'left', bold: true, colSpan: 12 },

    ])
    return tbl;
  }
  Rs(amount) {
    var words = new Array();
    words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
    amount = amount.toString();
    var atemp = amount.split('.');
    var number = atemp[0].split(',').join('');
    var n_length = number.length;
    var words_string = '';
    if (n_length <= 9) {
      var n_array: any[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = 0;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + ' ';
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += 'Crores ';
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += 'Lakhs ';
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += 'Thousand ';
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += 'Hundred and ';
        } else if (i == 6 && value != 0) {
          words_string += 'Hundred ';
        }
      }
      words_string = words_string.split(' ').join(' ');
    }
    return words_string;
  }

  convertAmountIntoWords(n) {
    var nums = n.toString().split('.')
    var whole = this.Rs(nums[0])
    if (nums[1] == null) nums[1] = 0;
    if (nums[1].length == 1) nums[1] = nums[1] + '0';
    if (nums[1].length > 2) { nums[1] = nums[1].substring(2, length - 1) }
    if (nums.length == 2) {
      if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
      var fraction = this.Rs(nums[1])
      var op = '';
      if (whole == '' && fraction == '') { op = 'Zero only'; }
      if (whole == '' && fraction != '') { op = 'paise ' + fraction + ' only'; }
      if (whole != '' && fraction == '') { op = '' + whole + ' only'; }
      if (whole != '' && fraction != '') { op = '' + whole + 'and paise ' + fraction + ' only'; }
      var amt = n;
      if (amt > 999999999.99) { op = 'Oops!!! The amount is too big to convert'; }
      if (isNaN(amt) == true) { op = 'Error : Amount in number appears to be incorrect. Please Check.'; }
      //  console.log(op,"op")
      return op;
    }
  }
  getDocumentDefinitionBuyer(item) {
    let orderNUmber = item.rowData.order_number;
   let selleroriginPincode = item.rowData.store_pin_code;
    let sellercity = item.rowData.store_city;
    let seller_StateName  = item.rowData.state_name;
    let buyerName = item.rowData.name;
    let locality = item.rowData.locatity;
    let areaStreet = item.rowData.area_street;
    let city = item.rowData.city;
    let state = item.rowData.state_name;
    let pincode = item.rowData.pin_code;
    let mobile = item.rowData.mobile;
    var invoice = Math.floor( Math.random() * 1 ) ;
    let invoiceno = invoice.toString();
     let invoiceNo = randomFixedInteger(13)
     let today = new Date()
     let orderDate = item.rowData.createddate.slice(0,10)
     //let invoicedate = new Date(new Date().getFullYear(), new Date().getMonth(), today)
     let invoicedate = JSON.stringify(today)
     invoicedate = invoicedate.slice(1,11)
    return {
      content: [[
        {
          stack: [{
            fontSize: 9,
            margin: [0, 0, 0, 40],
            stack: [{ text: "TAX INVOICE", alignment: 'center', margin: [0, 0, 0, 2] },
            { text: "(Issued under section 31 of GST Act,2017)", alignment: 'center', margin: [0, 0, 0, 4] },
            { text: "Ideacraft eVentures Pvt. Ltd", alignment: 'center', bold: 'true', margin: [0, 0, 0, 6] },
            { text: '1011-1018, 10th Floor Tower 4, DLF Corporate Greens, Southern Peripheral Rd', alignment: 'center', bold: 'true' },
            { text: 'Sector 74A, Gurugram, Haryana-122004', alignment: 'center', bold: 'true' },]

          },
          {
            fontSize: 9,
            table: {
              body: [
                [
                  { stack: [{ text: 'IdeaCraft GSTIN No : 07AAJCM7312H1ZG', style: 'mb4' }, { text: 'IdeaCraft PAN No : AAJCM7312H', style: 'mb4' }, { text: 'IdeaCraft State Code : 07', style: 'mb4' }, { text: 'IdeaCraft Invoice Sr. No : 71120220078861', style: 'mb4' }, { text: `IdeaCraft Invoice Date : ${orderDate}`}], bold: 'true', margin: [0, 2] },
                  { stack: [{ text: 'Date &Time of Supply;' }, { text: 'Place of Supply : Haryana ' }, { text: 'Tax payable on reverse charge basis : No' }], bold: 'true', margin: [0, 2] },

                ],


              ], widths: ['*', '*']
            },

          },
          {
            fontSize: 9,
            table: {
              body: [
                [
                  { stack: [{ text: 'Detail of Consignee (Shipped To) :', bold: 'true', style: 'mb4' }, { stack: [{ text: `Name:${buyerName}`}, { text: `Address: ${areaStreet} ${locality}`}, { text: `City: ${city}`}, { text: `State: ${state}`}, { text: `Pin Code: ${pincode}`}, { text: `Phone No: ${mobile}`}] }], margin: [0, 2] },
                  { stack: [{ text: 'Shipped From :', bold: 'true', style: 'mb4' }, { stack: [{ text: `City: ${sellercity}`}, { text: `State: ${seller_StateName}`}, {text: `Pincode: ${selleroriginPincode}`}], }], margin: [0, 2] },
                ],], widths: ['*', '*']
            }
          },
          {
            fontSize: 9,
            table: {
              body: [
                [
                  { stack: [{ text: 'Detail of Buyer (Billed To) :', bold: 'true', style: 'mb4' }, { stack: [{ text: `Name: ${buyerName}`}, { text: `Address:${areaStreet} ${locality} `}, { text: `City: ${city}`}, { text: `State: ${state}`},{ text: `Pin Code: ${pincode}`}]}], margin: [0, 2] },
                  { stack: [{ text: `Invoice No: ${(invoiceNo)}` }, { text: `Customer Order No: ${(orderNUmber)}` }, { text: `Invoice Date: ${(orderDate)}`}, { text: 'Irn NO:' }, { text: 'aae0fb57b89bf4220491f34cbb6af0709acdba6f2c36fa460aa120407d246806' }, { text: 'Irn date: 2022-02-24' }, { text: 'E-Waybill No' }, { text: 'Transport:' }, { text: 'Vehicle No:' }, { text: 'Station:' }, { text: 'Vendor Code:' }, { text: 'Remarks:' }], margin: [0, 2] },
                ],], widths: ['*', 'auto']
            }
          },
          {
            color: 'black',
            table: {
              headerRows: 1,
              widths: [20, 50, 'auto', 50, '*', 50, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: this.orderdetailslist(),
            }, fontSize: 7
          },
          {

            table: {

              widths: ['*', '*'],
              heights: 50,
              body: [
                [{
                  stack: [
                    { text: 'Terms & Conditions' },
                    { text: '1. Goods once sold will not be taken back' },
                    { text: '2. All claims for Exchange and Warranty will be taken as per Ideacraft Policy only.' },
                    { text: '3. All matters under this sale are subject to Haryana Jurisdiction only.' },
                    { text: '4. In case of Non-Delivery or any other dispute please contact IdeaCraft:' },
                    {
                      stack:[
                    { text: 'a. Customer Care No: 0124-6010900' },
                    { text: 'b. NEFT Details:' },
                    
                        { text: '   Ideacraft eVentures Pvt. Ltd.' },
                        { text: '   Bank: Kotak Mahindra Bank Ltd.' },
                        { text: '   A/c No.: 02912090000547 , IFS Code:KKBK0000291' },
                        { text: '   Branch: DLF PH-4, Galleria Market Complex, Gurugram -122002' },
                        { text: 'c. Corporate office:' },
                    { text: '   1011-1018, 10th Floor, Tower 4,DLF Corporate Greens, Southern Peripheral Road,' },
                    { text: '   Sector 74A, Gurgaon,Haryana – 122004, India. ' },
                      ],
                      style: 'superMargin'
                    },                
                    
                  ], fontSize: 6
                }, { alignment: 'center', margin: [5, 10], stack: [{ text: 'Authorized Signature (For Ideacraft eVentures Pvt. Ltd.)', bold: true, fontSize: 9, margin: [0, 10, 0, 0] }] }],
              ]
            }
          },
          {
            table: {
              widths: ['*'],
              body: [
                [{ text: `Declaration: We declare that this invoice shows the actual price of the goods described and that all particulars are 
                true and correct. `, alignment: 'left', color: '', fontSize: 9, margin: [0, 0, 0, 0] }]
    
              ],
            }, layout: 'noBorders',
          }
          ,], margin: 10
        }]

      ],
      styles: {
        myHeading: {
          fontSize: 16,
          bold: true
        },
        bigger: {
          fontSize: 15,
          italics: true
        },
        textDanger: {
          color: 'red',
          italics: true
        },
        font9: {
          fontSize: 9
        },
        mb4: {
          margin: [0, 0, 0, 4]
        },
        mb40: {
          margin: [0, 0, 0, 40]
        },
        tableHeader: {
          bold: true,
          alignment: 'center'
        },
        superMargin: {
          margin: [10, 0, 30, 0],
        }
      }
    };
    
  }
  generatePdfBuyer(obj,action = 'open'){
    let extra_feature_price = 0;
    if (obj.rowData.extra_features && obj.rowData.extra_features.length > 0) {
      for (let i = 0; i < obj.rowData.extra_features.length; i++) {
        if (obj.rowData.extra_features[i].isSelected)
          extra_feature_price += Number(obj.rowData.extra_features[i].price);
      }
    }
    if (obj.rowData.product_type == 198 || obj.rowData.product_type == 208) {
      if (obj.rowData.is_bundle == true) {
        //this.gstAmount = ((((Number((obj.order_price)+Number(extra_feature_price)))*Number(obj.total_quantity))* Number(obj.gst_rate))/100)
        this.gstAmount = ((((Number(obj.rowData.order_price) + (extra_feature_price)) * Number(obj.rowData.total_quantity)) * Number(obj.rowData.gst_rate)) / 100)
        this.invoiceprice = (Number(obj.rowData.order_price))
        this.invoicetotal = (Number(obj.rowData.order_price) + Number(extra_feature_price)) * Number(obj.rowData.total_quantity)
        this.invoicemrp = Number(obj.rowData.product_mrp) * Number(obj.rowData.total_quantity)
        this.discount = Number(this.invoicemrp) - (Number(obj.rowData.order_price) * Number(obj.rowData.total_quantity))
      } else {
        this.gstAmount = ((((Number(obj.rowData.product_price) + Number(extra_feature_price)) * Number(obj.rowData.total_quantity)) * Number(obj.rowData.gst_rate)) / 100)
        this.gstAmount = parseFloat(this.gstAmount).toFixed(2);
        console.log("this.gstAmount",this.gstAmount)
        this.invoiceprice = (Number(obj.rowData.product_price) / Number(obj.rowData.total_quantity))
        this.invoicemrp = Number(obj.rowData.product_mrp) * Number(obj.rowData.total_quantity)
        this.invoicetotal = (Number(obj.rowData.product_price) + (Number(extra_feature_price) * Number(obj.rowData.total_quantity)))
        this.discount = Number(this.invoicemrp) - (Number(obj.rowData.product_price))
      }

    }
    if (obj.rowData.product_type == 197 || obj.rowData.product_type == 209){
      if (obj.is_bundle == true) {
        this.gstAmount = (((Number(obj.rowData.order_price)) * Number(obj.rowData.gst_rate)) / 100) * Number(obj.rowData.total_quantity)
        this.gstAmount = parseFloat(this.gstAmount).toFixed(2);
        this.invoiceprice = Number(obj.rowData.order_price)
        this.invoicemrp = Number(obj.rowData.product_mrp) + Number(obj.rowData.admin_mrp)
        this.discount = (Number(this.invoicemrp) - Number(this.invoiceprice)) * Number(obj.rowData.total_quantity)
        this.invoicetotal = Number(obj.rowData.total_quantity) * (Number(this.invoiceprice) + Number(extra_feature_price))
      }
      else {
        this.gstAmount = ((((Number(obj.rowData.order_price)) + Number(extra_feature_price)) * Number(obj.rowData.gst_rate)) / 100) * Number(obj.rowData.total_quantity)
        this.gstAmount = parseFloat(this.gstAmount).toFixed(2);
        this.invoiceprice = Number(obj.rowData.order_price)
        this.invoicemrp = Number(obj.rowData.product_mrp) + Number(obj.rowData.admin_mrp)
        this.discount = (Number(this.invoicemrp) - Number(this.invoiceprice)) * Number(obj.rowData.total_quantity)
        this.invoicetotal = Number(obj.rowData.total_quantity) * (Number(this.invoiceprice) + Number(extra_feature_price))
      } 
    }
    
    console.log("itemitemitem",obj)
    this.newjson = [{
      'description': `${obj.rowData.product_name}`,
      'hsn': `${obj.rowData.hsn ? obj.rowData.hsn : '-'}`,
      'qty': `${obj.rowData.total_quantity}`,
      'unit': '-',
      'price': `${this.invoiceprice}`,
      'discount': Number(this.discount),
      'Extra Feature Price': `${extra_feature_price ? extra_feature_price : 0}`,
      'total': `${this.invoicetotal}`,
      'IGST': `${obj.rowData.gst_rate ? obj.rowData.gst_rate : '-'}`,
      'IGST Amount': `${this.gstAmount}`,
      'amount': Number(this.invoicetotal) + Number(this.gstAmount)

    }
    ]

    const documentDefinition = this.getDocumentDefinitionBuyer(obj);
    switch (action) {
      case 'open': pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print': pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;
      default: pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
