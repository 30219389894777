import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Router } from '@angular/router';
import { ProductService } from '../../_services/product.service';
declare var $: any;

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css'],
})
export class MyOrdersComponent implements OnInit {
  customer_id: string;
  orderPrimaryDetail: any = [];
  orderSecDetails: any = [];
  trackOrderHistory: any;
  isLoading: boolean = false;
  revtype: any;
  rateOrderId: any;
  rateOrderNumber: any;
  rateProductId: any;
  rateRevType: any;
  rateRevTypeId: any;
  buyerName: any;
  multiAddress: any;
  billingAddressList: any;
  productDescription: any;
  hsn: any;
  quantity: any;
  price: any;
  gstRate: any;
  total: any;
  locality: any;
  areaStreet: any;
  city: any;
  pinCode: any;
  stateName: any;
  orderNumber: any;
  mobile: any;
  totalAmount: any;
  totalGstAmount: any;
  gift_amount: any;
  rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  constructor(
    private productService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    let data1 = localStorage.getItem('buyer')
    data1 = JSON.parse(data1) 
    this.customer_id = data1['buyerid']
    //console.log("buerrer", this.customerId)
    this.buyerName = data1['buyername']
    //this.customer_id = localStorage.getItem('customer_id');
    this.getOrderHistory();
    //this.getOrderTrakingDetail();

    $(".order-track-text-stat a").click(function(event){
      $(this).removeClass('shrinked');
      if(!$(this).hasClass('expanded')) {
      let currentHeight  =  $('.collapse').height();
      let rem = 0.0625 * currentHeight;
      let prevheight = 24;
      let newHeight  = JSON.stringify(Number(prevheight) + Number(rem)) + 'rem';
 
      // newHeight = JSON.stringify(newHeight) + 'px'
        $(".order-track-status-line").animate({
          height: newHeight
        });
        $(".order-track-step").animate({
          height: newHeight
        });
        $(this).toggleClass('expanded');
      }
      else{
        $(".order-track-status-line").animate({
          height: "4rem"
        });
        $(".order-track-step").animate({
          height: "4rem"
        });
        $(this).toggleClass('expanded');
      }
      
    });

  }

  getOrderHistory(){
    this.isLoading = true
    this.productService.getAllAdminOrders(this.customer_id).subscribe(res =>{
      
      this.orderPrimaryDetail = res ? res[0].rows :false;
      this.orderSecDetails = res ? res[1].rows : false;
      this.trackOrderHistory = res ? res[2].rows : false;
      this.multiAddress = res ? res[3].rows : false;
      this.billingAddressList = res ? res[4].rows : false;
      if(this.orderPrimaryDetail.length > 0){
        this.buyerName = this.orderPrimaryDetail[0].name;
        this.locality = this.orderPrimaryDetail[0].locatity;
        this.areaStreet = this.orderPrimaryDetail[0].area_street;
        this.city = this.orderPrimaryDetail[0].city;
        this.pinCode = this.orderPrimaryDetail[0].pin_code;
        this.stateName = this.orderPrimaryDetail[0].state_name;
        this.orderNumber = this.orderPrimaryDetail[0].order_number;
        this.mobile = this.orderPrimaryDetail[0].mobile;
        this.totalAmount = this.orderPrimaryDetail[0].total_amount;
        this.totalGstAmount = this.orderPrimaryDetail[0].total_gst_amount;
        this.gift_amount = this.orderPrimaryDetail[0].gift_amount;
        for (const pri of this.orderPrimaryDetail) {
          pri.orderdetails = this.orderSecDetails.filter(x => x.order_id == pri.order_id);
          if(pri.is_multi_add){
            pri.multiAdd = this.multiAddress.filter(x => x.order_id == pri.order_id);
          }
          pri.billingAddress = this.billingAddressList.filter(x => x.order_id == pri.order_id);
      }
      }
      if(this.orderSecDetails.length > 0){
        this.productDescription = this.orderSecDetails[0].product_name;
        this.hsn = this.orderSecDetails[0].hsn;
        this.quantity = this.orderSecDetails[0].total_quantity;
        this.price = this.orderSecDetails[0].price; 
        this.gstRate = this.orderSecDetails[0].gst_rate; 
        this.total = this.orderSecDetails[0].product_price; 
        for(let obj of this.orderSecDetails){
          obj.orderList = this.trackOrderHistory.filter(x=>x.buyer_order_id == obj.order_detail_id )
          obj.placed_order = obj.orderList.filter(x => x.track_status == 187)[0]?.createddate;
          obj.acc_order = obj.orderList.filter(x => x.track_status == 194)[0]?.createddate;
          obj.readytoship = obj.orderList.filter(x => x.track_status == 192)[0]?.createddate;
          obj.shipped = obj.orderList.filter(x => x.track_status == 189)[0]?.createddate;
          obj.deliverd = obj.orderList.filter(x => x.track_status == 193)[0]?.createddate;
          obj.cancel_order = obj.orderList.filter(x => x.track_status == 191 || x.track_status == 190)[0]?.createddate;
          obj.extra_features =  obj.extra_features ?   JSON.parse(obj.extra_features) : [] ;
          obj.artwork_area =  obj.artwork_area ? obj.artwork_area : '';
          obj.details =  obj.details ?  JSON.parse(obj.details) : [];
          obj.product_price = Number(obj.total_quantity) * Number(obj.product_price);
        } 
      }     
        this.isLoading = false;
         
     
         })
  }

  getOrderTrakingDetail(){
    this.productService.getOrderTrakingDetail().subscribe(res=>{
    
    })
  }

  // convertToInt(amount, gst){
  //   let subtotal = Number(amount) - Number(gst);
  //   return parseFloat(Number(subtotal).toFixed(2))

  //  }
   convertToInt(amount, gst, gift_amount){
    gst = gst ? gst : 0
    gift_amount = gift_amount && gift_amount!= 'null' && gift_amount!= null ?gift_amount: 0;
     let subtotal = (Number(amount) - Number(gst))+Number(gift_amount);
     let fix = parseFloat(Number(subtotal).toFixed(2))
    return this.rupeeIndian.format(fix)
    }

   writeReview(id, number, product_id, revtype, revtypeid?){
    this.rateOrderId = id;
    this.rateOrderNumber = number;
    this.rateProductId = product_id;
    this.rateRevType = revtype;
    this.rateRevTypeId = revtypeid;

    // let data = {id: id, number: number}
    // $('#rating-rv').modal('show')

   }

  //  navigateToDetailPage(product_id, product_type_id, isInDeal) {
  //   let siteUrl = AppSettings.getSiteUrl;
  //   let data = JSON.stringify({ 'selected_prod_id': product_id, 'isInDeal': isInDeal ? isInDeal : false });
  //   let encriptUrl = this.loginService.encriptDataUrl(data)
  //   if (product_type_id == 197) {
  //     window.open(siteUrl + '/simple-products' + '?' + encriptUrl, '_blank');
  //   }
  //   if (product_type_id == 198) {
  //     window.open(siteUrl + '/product-details-customize' + '?' + encriptUrl, '_blank');
  //   }
  //   if (product_type_id == 199) {
  //     window.open(siteUrl + '/simple-products' + '?' + encriptUrl, '_blank');
  //   }
  //   if (product_type_id == 208) {
  //     window.open(siteUrl + '/product-details-customize' + '?' + encriptUrl, '_blank');
  //   }
  //   if (product_type_id == 209) {
  //     window.open(siteUrl + '/business-card' + '?' + encriptUrl, '_blank');
  //   }
  // }


}
