<div class="d-flex minhyt96vh" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>
              <li class="breadcrumb-item newitm2  active" aria-current="page">Brand Listing </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="col-sm-12 mb-2 p-0">
        <div class="tabs row my-2 mx-0">

          <div class="tab-button-outer1 col-sm col-9 px-0 mb-sm-0 mb-2">
            <ul id="tab-button">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font9">Manage Brand
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-3 px-sm-3 px-0 order-sm-1 order-2" id="masterSearch">
            <div class="input-group">
              <input type="text" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
              <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 ml-2 mt-0" onpaste="return false;" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
          <div class="col-sm-auto text-right px-0 col-3 order-sm-2 order-1">
            <button type="button" data-toggle="modal" data-target="#myModal" class="btn btn-sm btn-circle btn-circle-sm btn-theme toolbar-item ml-1">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div id="tab01" class="tab-contents mmt5 p-0">
        <div class="col-sm-12 col-md-12 nopadding">
          <div id="grid-theme-wrapper">
            <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
            <ngx-loading [show]="isLoading"></ngx-loading>
            <igx-grid *ngIf="brandList.length > 0" #grid1 [data]="brandList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
              [filterMode]="'excelStyleFilter'">
              <igx-column field="" header="Sr. No." width="80" [filterable]="false">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container w-100 text-center">
                    {{cell.row.index + 1}}
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="name" header="Brand Name" width="400">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <!-- <igx-column field="code" header="Brand Code" width="400">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column> -->
              <igx-column field="seller_name" header="Seller Name" width="400">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="date" header="Upload Date" width="180">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="is_aproved" header="Status" width="160">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <!-- <span class="cell-vl " [innerHtml]="cell.value"> </span> -->
                    <span class="cell-vl" *ngIf="cell.value == true"> Active </span>
                    <span class="cell-vl" *ngIf="cell.value == false"> Inactive </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="brand_name_id" header="Action" width="160">
                <ng-template compositeTemp igxCell let-cell="cell">
                  <div class="px-2 address-container">
                    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                      <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                        aria-expanded="false">
                        <span class="py-2 px-3">Action</span>
                        <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                          <i class=" fa fa-caret-down" aria-hidden="true"></i>
                        </button>
                      </button>
                      <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop6">
                        <li class="border-bottom" *ngIf="cell.rowData.is_aproved != true">
                          <a href="javascript:void(0)" (click)="onApproved(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                            <i class="fa fa-check mr-1"></i>Approve
                          </a>
                        </li>
                        <li class="border-bottom">
                          <a href="javascript:void(0)" (click)="onEdit(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                            <i class="fa fa fa-pencil mr-1"></i>Edit
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                            <i class="fa fa fa-trash mr-1"></i>Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </igx-column>
            </igx-grid>
            <label class="w-100 text-center text-dark" *ngIf="!isLoading && brandList.length == 0">No Record Found</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- view model -->

<div id="myModal" class="modal fade p-0" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header align-items-center py-2">
        <h6 class="font600 mb-0">Add New Brand</h6>
        <button type="button" class="close" data-dismiss="modal" (click)="reSet()">
          <span> &times; </span>
        </button>
      </div>
      <div class="modal-body minhyt40">
        <div class="row mx-0 mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 text-right">
                <label for="" class="font8">Brand Name
                  <span class="asterisk">*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input type="text" [(ngModel)]="brandName" class="form-control mb-2" id="productTitle" autocomplete="off" placeholder="Enter Brand Name"
                  maxlength="50" (change)="createbrandCode()">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 text-right">
                <label for="" class="font8">Brand Code</label>
              </div>
              <div class="col-md-8">
                <input type="text" [(ngModel)]="brandCode" name="brandCode" class="form-control mb-2" id="productTitle" autocomplete="off"
                  placeholder=" Brand Code (Auto generated)" maxlength="50" readonly>

              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 text-right">
                <label for="" class="font8">Is Approved</label>
              </div>
              <div class="col-md-8">
                <div class="form-check p-0">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="isapproved" name="isspproved">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="bottom-button-group text-center pb-3">
        <button type="button" class="btn btn-sm px-3 btn-success mr-1" (click)="addBrandName()" *ngIf="!brandid">Save</button>
        <button type="button" class="btn btn-sm px-3 btn-theme2 mr-1" (click)="updateBrandName()" *ngIf="brandid">Update</button>
        <button class="btn btn-sm btn-warning px-3 mr-1" (click)="reSet()">Reset</button>
        <button class="btn btn-sm btn-danger px-3" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>