import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-gift-code',
  templateUrl: './view-gift-code.component.html',
  styleUrls: ['./view-gift-code.component.css']
})
export class ViewGiftCodeComponent implements OnInit {

  constructor() { }
  allSeller:any;
  ngOnInit(): void {
  }

}
