<div class="d-flex minhyt80" id="wrapper">
    <ngx-loading [show]="isLoading"></ngx-loading>
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid bg-white">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item newitm1">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                            </li>

                            <li class="breadcrumb-item newitm2  active" aria-current="page">Pending Seller List </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="col-sm-12 mb-2 p-0">
                <div class="tabs row my-2 mx-0">
                    <!-- <ul class="breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Pictures</a></li>
                  </ul> -->
                    <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
                        <ul id="tab-button">
                            <li id="tabDraft" class="is-active">
                                <a href="javascript:void(0)" class="font9">Pending Seller List
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-3 px-sm-3 px-0" id="masterSearch">
                        <div class="input-group">
                            <input type="search" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                            <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                                <i class="fa fa-search"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="tab01" class="tab-contents mmt5 p-0">
                    <div class="col-sm-12 col-md-12  nopadding ">
                        <div id="grid-theme-wrapper">
                            <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
                            <div class="table-responsive" *ngIf="sellerList.length > 0">
                                <igx-grid #grid1 [data]="sellerList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                                    [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">
                                    <igx-column field="seller_name" header="Seller Name" width="250">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="cell-vl ">{{ cell.value }}
                                                </span>
                                            </div>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="business_name" header="Business Name" width="250">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="cell-vl " [innerHtml]="cell.value"></span>
                                            </div>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="store_name" header="Address" width="200">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="cell-vl " [innerHtml]="cell.value"></span>
                                            </div>
                                        </ng-template>
                                    </igx-column>
                                    <igx-column field="mobile_no" header="Mobile Number" width="200">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="cell-vl" [innerHtml]="cell.value"></span>
                                            </div>
                                        </ng-template>
                                    </igx-column>
                                    <!-- <igx-column field="mobile_no" header="Mobile No" width="195">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="" [innerHtml]="cell.value"></span>
                                            </div>
                                        </ng-template>
                                    </igx-column> -->
                                    <igx-column field="pan_no" header="PAN" width="165">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="cell-vl" [innerHtml]="cell.value"></span>
                                            </div>
                                        </ng-template>
                                    </igx-column>

                                    <igx-column field="gstin_no" header="GST Number" width="205">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container">
                                                <span class="cell-vl" [innerHtml]="cell.value"></span>
                                            </div>
                                        </ng-template>
                                    </igx-column>
                                    <!-- <igx-column field="is_approved" header="Status" width="">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container" >
                                                <span *ngIf="cell.value == true"> Approved </span>
                                                <span *ngIf="cell.value == false"> Pending/Rejected </span>
                                            </div>
                                        </ng-template>
                                    </igx-column>  -->


                                    <igx-column field="" header="Action" width="150">
                                        <ng-template #compositeTemp igxCell let-cell="cell">
                                            <div class="address-container px-2">
                                                <div class="btn-group" role="group" data-toggle="dropdown" aria-label="Button group with nested dropdown">
                                                    <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <span class="py-2 px-3">Action</span>
                                                        <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                                                        </button>
                                                    </button>
                                                    <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop6">
                                                        <li>
                                                            <a href="javascript:void(0)" (click)="getDetails(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">

                                                                <i class="fa fa-cog mr-1"></i> Process
                                                            </a>

                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>

                                        </ng-template>
                                    </igx-column>


                                </igx-grid>
                            </div>
                            <label class="w-100 text-center text-dark" *ngIf="!isLoading && sellerList.length == 0">No Record Found</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>