<div class="dropdown toolbar-item">
        <a href="javascript:void(0)" class="dropdown-toggle text-dark"  type="button"  container="body" id="dropdownexport"
        data-toggle="dropdown" aria-haspopup="true" title="Action" aria-expanded="false"> <i class="fa fa-ellipsis-v ml-1"> </i>   </a>
        <div class="dropdown-menu" aria-labelledby="dropdownexport" style="overflow: visible !important;">
          <span class="dropdown-menu-arrow"></span>
        <div *ngFor="let obj of params.actionList" >
        
          <span *ngIf="obj.for == undefined || obj.for == null || obj.for == ''">
          <a class="dropdown-item" href="javascript:void(0)" *ngIf="obj.form_status==params.value[0] || obj.db_status==params.value[1]" (click)="onClick($event,obj.str)"><i class="fa fa-circle-o"></i> {{obj.label}}</a>
        </span>

        <span *ngIf="obj.for == 'view'">
          <a class="dropdown-item" href="javascript:void(0)" *ngIf="obj.status" (click)="onClick($event,obj.str)"><i class="fa fa-circle-o"></i> {{obj.label}}</a>
        </span>
        
         </div>
        </div>
      </div>