import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-gift-code',
  templateUrl: './new-gift-code.component.html',
  styleUrls: ['./new-gift-code.component.css']
})
export class NewGiftCodeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
