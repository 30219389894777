<div class="d-flex" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb p-0">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" routerLink="/dashboardnew" class="text-primary text-decoration-none">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a href="javascript:void(0)" routerLink="/buyer-list" class="text-decoration-none text-primary">Buyer List</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Buyer Order List
                <strong>({{buyerName}})</strong>
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-12 mb-2">
          <div class="tabs row my-2 mx-0">
            <!-- <ul class="breadcrumb">
                      <li><a href="#">Home</a></li>
                      <li><a href="#">Pictures</a></li>
                    </ul> -->
            <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
              <ul id="tab-button" class="tbsbtns">
                <li id="tabDraft" class="is-active">
                  <a href="javascript:void(0)" class="font8">Order List </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-3 px-sm-3 px-0" id="masterSearch">
              <div class="input-group">
                <input type="text" class="searchbar form-control newform-control" onpaste="return false;" autocomplete="off" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                  placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                  <i class="fa fa-search"></i>
                </a>
              </div>
            </div>
          </div>
          <div id="tab01" class="tab-contents mmt5 p-0">
            <div class="col-sm-12 col-md-12 nopadding">
              <div id="grid-theme-wrapper">
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
                <ngx-loading [show]="isLoading"></ngx-loading>

                <igx-grid #grid1 [data]="orderList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <!-- [(perPage)]="page.size" -->

                  <igx-column field="order_number" header="Order Number" width="388">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <!-- <a href="javascript:void(0)" > -->
                        <span class="cell-vl " data-toggle="modal" data-target="imagemodal">{{ cell.value }}
                        </span>
                        <!-- </a> -->
                      </div>
                    </ng-template>
                  </igx-column>


                  <igx-column field="order_date" header="Order Date" width="275">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class=" cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>

                  <!-- <igx-column field="total_quantity" header="Total Quantity" width="">
                  <ng-template #compositeTemp igxCell let-cell="cell">
                    <div class="address-container">
                      <span class=" cell-vl" [innerHtml]="cell.value"></span>

                    </div>

                  </ng-template>
                </igx-column> -->
                  <igx-column field="total_amount" header="Total Amount" width="378">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">

                        <span class="cell-vl ">{{ cell.value }}</span>

                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="total_quantity" header="Action" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="px-2">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                            <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop6">
                            <li>
                              <a href="javascript:void(0)" (click)="ViewOrderDetail(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                                View </a>
                            </li>
                          </ul>

                        </div>
                      </div>

                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="imagemodalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h5 class="modal-title" id="imagemodalLabel">Image preview</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"> &times; </span>
            </button>
          </div>
          <div class="modal-body p-0">
            <img src="../..{{previewSrc}}" id="imagepreview" class="img-prev" />
            <!-- <img src="https://vahanmaster.com/wp-content/uploads/2021/06/aadhar.png" id="imagepreview" class="img-prev" > -->

            <!-- href="https://placeimg.com/1000/1000/arch"  -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal" id="myModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header p-2">
            <h6 class="modal-title">Rating & Review</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2">
            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-2 pr-0">

                  <strong>Rating : </strong>
                </div>
                <div class="col-sm-10 pl-0">
                  <div class="rating">
                    <i class="rating__star fa fa-star  "
                      [ngClass]="buyerRating > 1 ? 'text-warning' : (buyerRating == 1 ? 'text-danger' : 'text-secondary')"></i>
                    <i class="rating__star fa fa-star  "
                      [ngClass]="buyerRating >= 2 ? 'text-warning' : 'text-secondary'"></i>
                    <i class="rating__star fa fa-star  "
                      [ngClass]="buyerRating >= 3 ? 'text-warning' : 'text-secondary'"></i>
                    <i class="rating__star fa fa-star  "
                      [ngClass]="buyerRating >= 4 ? 'text-warning' : 'text-secondary'"></i>
                    <i class="rating__star fa fa-star  "
                      [ngClass]="buyerRating >= 5 ? 'text-warning' : 'text-secondary'"></i>
                  </div>
                </div>
              </div> -->

    <!-- <div class="row"> -->
    <!-- <div class="col-sm-12">
                          <label>Remarks</label>
                        </div> -->
    <!-- <div class="col-sm-12">
                  Review <textarea name="review" id="review" [(ngModel)]="buyerReview" class="form-control2"
                    maxlength="500" disabled></textarea>
                </div>
              </div>
            </div>
            <div class="action">
              <div class="block form-check d-flex"> -->
    <!-- <div class="row m-0 py-2">
                    
                    <input type="radio" class="ml-3 "  id="susp" name="block">
                    <label for="susp" class="ml-2" (click)="deletebuyerReview()">Delete</label>
  
                  </div> -->

    <!-- </div>

            </div>
          </div>
          <div class="text-center p-2">
            <button type="button" class="btn btn-sm btn-danger rounded-pill "
              (click)="deletebuyerReview()">Delete</button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header p-2 align-items-center">
            <h6 class="modal-title" id="exampleModalLabel">Order Details</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"> &times; </span>
            </button>
          </div>
          <div class="modal-body minhyt70 buyer p-4">
            <div class="products bor-btm" *ngFor="let pro of allProducts; index as i; ">

              <div class="row">
                <div class="col-md-2 col-2">
                  <div class="pro-image">
                    <img src="../../../{{pro.product_image_path}}" class="iimg-fluid pro-order" alt="not found">
                    <!-- <img src="../../../assets/images/1.jpg" class="img-fluid pro-order" alt="image"> -->
                  </div>
                </div>
                <div class="col-md-10 col-10">
                  <!-- <h6 class="mb-2" >Products Details</h6> -->
                  <h6 class="font8">
                    <strong> Product Name: </strong> {{pro.product_name}} </h6>

                  <h6 class="font8">
                    <strong> Product Price: </strong> {{pro.product_price}} </h6>
                  <!-- <hr class="my-2"> -->

                  <div *ngFor="let rev of buyerRatingAndReviews; index as j">
                    <div *ngIf="i == j">
                      <h6 class="mb-2">
                        <b> Rating & reviews </b>
                      </h6>
                      <div class="rating">
                        <strong>Rating : </strong>
                        <i class="rating__star fa fa-star  " [ngClass]="rev.customer_ratings > 1 ? 'text-warning' : (rev.customer_ratings == 1 ? 'text-danger' : 'text-secondary')"></i>
                        <i class="rating__star fa fa-star  " [ngClass]="rev.customer_ratings >= 2 ? 'text-warning' : 'text-secondary'"></i>
                        <i class="rating__star fa fa-star  " [ngClass]="rev.customer_ratings >= 3 ? 'text-warning' : 'text-secondary'"></i>
                        <i class="rating__star fa fa-star  " [ngClass]="rev.customer_ratings >= 4 ? 'text-warning' : 'text-secondary'"></i>
                        <i class="rating__star fa fa-star  " [ngClass]="rev.customer_ratings >= 5 ? 'text-warning' : 'text-secondary'"></i>
                      </div>
                      <!-- <div class="rating">
                           <h6 class="font8">   <strong>Rating : </strong> {{rev.customer_ratings}} </h6>
                      </div> -->
                      <div class="reviews">
                        <h6 class="font8">
                          <strong class="success">Reviews:</strong> {{rev.customer_review}} </h6>
                        <button type="button" (click)="deletebuyerReview()" class="btn btn-gray btn-sm">
                          Disable Rating & Review
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>