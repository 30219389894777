<div class="d-flex minhyt96vh" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>
              <li class="breadcrumb-item newitm2  active" aria-current="page">Add New Deal</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- 
      <div class="col-sm-12 p-0">
        <div class="tabs col-lg-12 pl-0 pr-1 my-2 ">
          <div class="tab-button-outer1 col-lg-12 pl-0">
            <ul id="tab-button">
              <li class="is-active">
                <a href="javascript:void(0)" class="font8">Add New Deal</a>
              </li>
            </ul>
          </div>
      </div>
      </div> -->

      <div class="col-sm-12 mb-2 p-0 mt-2">

        <div id="tab01" class=" mmt5 p-0">
          <div class="col-sm-12 col-md-12  nopadding">
            <div id="grid-theme-wrapper">
              <div class="tabs row my-2 mx-0">

                <!-- <div class="tab-button-outer1  col-lg-6 pl-0">
                    <ul id="tab-button">
                      <li id="tabDraft" class="is-active">
                        <a href="javascript:void(0)" class="font8">Seller List
                        </a>
                      </li>
                    </ul>
                  </div> -->
                <div class="col mb-sm-0 mb-2 px-0">
                  <div class="white-space-pre-x">
                    <div class="tab-button-outer1">
                      <ul id="tab-button" class="tbsbtns">
                        <li id="tabDraft" class="is-active">
                          <!-- SearchValue.value = '' -->
                          <a href="javascript:void(0)" class="font8" (click)="getCurrentDeal(); SearchValue.value='' ">Current Deal
                            <span>({{currentDealCount}})</span>
                          </a>
                        </li>
                        <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getFutureDeal(); SearchValue.value='' ">Future Deal
                            <span>({{futureDealCount}})</span>
                          </a>
                        </li>
                        <li id="" class="">
                          <a href="javascript:void(0)" class="font8" (click)="getDealHistory(); SearchValue.value =''">History Deal
                            <span>({{historyDealCount}})</span>
                          </a>
                        </li>
                        <!-- <li id="" class="">
          <a href="javascript:void(0)" class="font8" (click)="getSuspendSeller(); SearchValue.value = '' ">Suspended
            <span >({{suspendedCount}})</span>
          </a>
        </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 col pl-sm-3 pl-0" id="masterSearch">
                  <div class="input-group">
                    <input type="text" class="searchbar form-control newform-control" onpaste="return false;" autocomplete="off" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                      placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                    <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
                <div class="col-auto text-right px-0">
                  <button class="btn btn-sm py-1 btn-theme" (click)="reset();  isEdit = true" data-toggle="modal" data-target="#add-new-deal">
                    <i class="fa fa-plus mr-sm-1"></i>
                    <span class="d-sm-inline d-none">New Deal</span>
                  </button>
                </div>
              </div>

              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridCurr($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridFuture($event)" *ngIf="LoadPagination2 == true"></app-hds-main-tab-group>
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGridHistory($event)" *ngIf="LoadPagination3 == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoading"></ngx-loading>
              <div class="table-responsive" *ngIf="alldealList.length > 0">
                <igx-grid #grid1 [data]="alldealList" width="100%" *ngIf="current" height="" [autoGenerate]="false" [allowFiltering]="true"
                  displayDensity="cosy" [filterMode]="'excelStyleFilter'">
                  <!-- <ngx-loading [show]="isLoading"></ngx-loading>  -->
                  <igx-column field="product_name" header="Products Name" width="260">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="category_name" header="Product Category" width="260">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_deal_quantity" header="Quantity" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_mrp" header="Price" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="discount_percent" header="Offer(%)" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value + '%'"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <!-- <igx-column field="start_date" header="startDate" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <!-- <igx-column field=" " header="Action" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column> -->

                  <igx-column field="product_name" header="Action" width="150">
                    <ng-template compositeTemp igxCell let-cell="cell">
                      <div class="px-2 address-container">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                            <li class="border-bottom">
                              <a href="javascript:void(0)" (click)="onEdit(cell)" data-toggle="modal" data-target="#add-new-deal" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa-pencil mr-1"></i> Edit </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa-trash mr-1"></i> Delete </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
              <div class="table-responsive" *ngIf="alldealList.length > 0">
                <igx-grid #grid2 [data]="alldealList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'" [(perPage)]="page.size" *ngIf="future">
                  <!-- <ngx-loading [show]="isLoading"></ngx-loading>  -->
                  <igx-column field="product_name" header="Products Name" width="260">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="category_name" header="Product Category" width="260">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_deal_quantity" header="Quantity" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_mrp" header="Price" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="discount_percent" header="Offer(%)" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value + '%'"></span>
                      </div>
                    </ng-template>
                  </igx-column>


                  <igx-column field="product_name" header="Action" width="150">
                    <ng-template compositeTemp igxCell let-cell="cell">
                      <div class="px-2 address-container">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">

                            <li class="border-bottom">
                              <a href="javascript:void(0)" (click)="onEdit(cell)" data-toggle="modal" data-target="#add-new-deal" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa-pencil mr-1"></i> Edit</a>

                            </li>
                            <li>
                              <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa-trash mr-1"></i> Delete </a>
                            </li>

                          </ul>

                        </div>
                      </div>

                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
              <div class="table-responsive" *ngIf="alldealList.length > 0">
                <igx-grid #grid3 [data]="alldealList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'" [(perPage)]="page.size" *ngIf="history">
                  <!-- <ngx-loading [show]="isLoading"></ngx-loading>  -->
                  <igx-column field="product_name" header="Products Name" width="260">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductView(cell)">{{ cell.value }}
                          </span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="category_name" header="Product Category" width="260">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_deal_quantity" header="Quantity" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="product_mrp" header="Price" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="discount_percent" header="Offer(%)" width="210">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl " [innerHtml]="cell.value + '%'"></span>
                      </div>
                    </ng-template>
                  </igx-column>


                  <igx-column field="product_name" header="Action" width="150">
                    <ng-template compositeTemp igxCell let-cell="cell">
                      <div class="px-2 address-container">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">

                            <li class="border-bottom">
                              <a href="javascript:void(0)" (click)="onEdit(cell)" data-toggle="modal" data-target="#add-new-deal" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa-pencil mr-1"></i> Edit </a>

                            </li>
                            <li>
                              <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa-trash mr-1"></i> Delete </a>
                            </li>

                          </ul>

                        </div>
                      </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
              <label class="w-100 text-center text-dark" *ngIf="!isLoading && alldealList.length == 0">No Record Found</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="myModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="ms-auto p-2">
            <button type="button" class="close btn" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body px-3 py-0">
            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <label>Remarks</label>
                </div>
                <div class="col-sm-12 ">
                  <textarea name="remarks" id="remarks" [(ngModel)]="adminRemarks" placeholder="Enter remarks here...." class="form-control2"
                    maxlength="500"></textarea>
                </div>
              </div>
            </div>
            <div class=" py-3 text-center ">

              <div class="unBlocked" *ngIf="suspended == 183">
                <!-- <label for="unblocked " class="m-3">Unblock</label> -->
                <!-- <input type="checkbox" #unb required name="unblocked" id="unblocked" ngModel> -->
                <button class="btn btn-theme bg-primary border-primary mr-2 btn-sm " (click)="reAcitve(181)">Unblock</button>
                <button class="btn btn-sm btn-theme border-danger bg-danger" data-dismiss="modal">Close</button>
              </div>
              <div class="unsuspend" *ngIf="suspended == 184">
                <button class="btn btn-theme bg-primary border-primary mr-2 btn-sm" (click)="reAcitve(181)">Active</button>
                <button class="btn btn-sm btn-danger border-danger bg-danger" data-dismiss="modal">Close</button>
                <!-- <label for="unsuspend" class="m-3">Active</label>
                    <input type="checkbox" #sus required name="unsuspend" id="unsuspend" ngModel> -->
              </div>

            </div>

            <!-- <div class="modal-footer p-2">
                <button class="btn btn-sm btn-theme bg-primary border-primary mr-2" [disabled]="!myForm.valid" (click)="reAcitve(181)">Submit</button>
                <button class="btn btn-sm btn-danger" data-dismiss="modal">Close</button>
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal" id="toBlock" >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                  <div class="col-sm-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12">
                          <label>Remarks</label>
                        </div>
                        <div class="col-sm-12">
                          <textarea name="remarks" id="remarks" [(ngModel)]="adminRemarks"  class="form-control2" maxlength="500"></textarea>
                      </div>
                      </div>
                    </div>
                    <div class="action">
                        <div class="block form-check d-flex">
                          <div>
                            <label for="block " class="mr-3">Block</label>
                            <input type="radio" class="  mr-2" value="183"  [(ngModel)]="blk_suspend" id="block" name="block">
                          </div>
                          <div>
                            <label for="susp" class="mr-3">Suspend</label>
                            <input type="radio" class="mr-2 " value="184" [(ngModel)]="blk_suspend"  id="susp" name="block">
                          </div>
                       
                        </div>
                       
                    </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" [disabled]="blk_suspend == ''" (click)="reAcitve(blk_suspend)">Submit</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> -->
    <div class="modal" id="toBlock">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header p-2">
            <h6 class="modal-title">Action</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2">
            <div class="col-sm-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <label>Remarks</label>
                </div>
                <div class="col-sm-12 mb-3">
                  <textarea name="remarks" id="remarks" placeholder="Enter remarks here...." [(ngModel)]="adminRemarks" class="form-control2"
                    maxlength="500"></textarea>
                </div>
              </div>
            </div>
            <div class="action">
              <div class="block form-check d-flex">
                <div class="row m-0 py-2">
                  <input type="radio" class="ps-top" value="183" [(ngModel)]="blk_suspend" id="block" name="block">
                  <label for="block " class="ml-2">Block</label>

                  <input type="radio" class="ml-3 ps-top" value="184" [(ngModel)]="blk_suspend" id="susp" name="block">
                  <label for="susp" class="ml-2">Suspend</label>

                </div>

              </div>

            </div>
          </div>
          <div class="text-center  p-2">
            <button type="button" class="btn btn-sm text-white bg-success border-success mr-2" [disabled]="blk_suspend == ''" (click)="reAcitve(blk_suspend)">Submit</button>
            <button type="button" class="btn btn-sm text-white bg-danger border-danger" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="add-new-deal" tabindex="-1" role="dialog" aria-labelledby="add-new-dealLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-2">
        <h6 class="modal-title" id="add-new-dealLabel">
          <strong> Add new deal </strong>
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <div class="row">
          <div class="col-sm-6 mb-2">
            <label for="">Select Category
              <span class="asterisk"> * </span>
            </label>

            <select name="category" id="category" class="form-control" [(ngModel)]="category" (change)="getSubCategory()">
              <option value="" disabled selected>Select Category</option>
              <option [value]="cat.product_category_id" *ngFor="let cat of categoryList">
                {{cat.category_name}}
              </option>
            </select>
          </div>
          <div class="col-sm-6 mb-2">
            <label for="">Select Sub Category
              <span class="asterisk"> * </span>
            </label>

            <select name="sub-category" id="sub-category" class="form-control" [(ngModel)]="sub_category" (change)="getProducts()">
              <option value="" disabled selected>Select Sub Category</option>
              <option *ngFor="let sub of subCategoryList" [value]="sub.product_category_id">
                {{sub.category_name}}
              </option>
            </select>
          </div>
          <div class="col-sm-12 mb-2">
            <label for="">Select Product
              <span class="asterisk"> * </span>
            </label>
            <select name="" id="" class="form-control" [(ngModel)]="selectedProdcut" (change)="getMRPPrice()" [disabled]="!isEdit">
              <option value="" disabled selected>Select Product</option>
              <option [value]="pro.product_id" *ngFor="let pro of allProductList;">
                {{pro.product_name}}
              </option>
              <!-- <option value=""> Pen </option>
                <option value=""> Cap </option> -->
            </select>
          </div>
          <div class="col-sm-4 mb-2">
            <label for="">Quantity (Min Order Qty.- {{minOrderQty}})
              <span class="asterisk"> * </span>
            </label>
            <input type="text" class="form-control" min="0" maxlength="10" [(ngModel)]="quantity" placeholder="Enter Quantity" onpaste="return false"
              oncopy="return false" ondrag="return false" ondrop="return false" onkeypress="return /[0-9]/i.test(event.key)">

          </div>
          <div class="col-sm-4 mb-2">
            <label for="">MRP (₹)</label>
            <input type="text" class="form-control" [(ngModel)]="mrp" placeholder="Enter MRP" disabled>

          </div>
          <div class="col-sm-4 mb-2">
            <label for="">Price (₹)</label>
            <input type="text" class="form-control" [(ngModel)]="price" placeholder="Enter Price" disabled>

          </div>
          <div class="col-sm-4 mb-2">
            <label for="">Discount (%)
              <span class="asterisk"> * </span>
            </label>
            <input type="text" class="form-control" [(ngModel)]="discount" min="0" placeholder="Enter Discount(%)" maxlength="2" onpaste="return false"
              oncopy="return false" ondrag="return false" ondrop="return false" onkeypress="return /[0-9]/i.test(event.key)">

          </div>
          <div class="col-sm-4 mb-2">
            <label for="">Start Date
              <span class="asterisk"> * </span>
            </label>
            <input type="date" class="form-control" [(ngModel)]="startDate" placeholder="Enter Date" min="{{getMinDate()}}">

          </div>
          <div class="col-sm-4 mb-2">
            <label for="">End Date
              <span class="asterisk"> * </span>
            </label>
            <input type="date" class="form-control" [(ngModel)]="endDate" placeholder="End Date" min="{{getMinDate()}}">

          </div>


          <div class="col-sm-12 mb-2 mt-4 text-center">
            <button class="btn btn-sm btn-theme px-3  mr-2" (click)="onSubmit()" *ngIf="isEdit"> Add</button>
            <button class="btn btn-sm btn-theme mr-2" (click)="onUpdate()" *ngIf="!isEdit"> Update</button>
            <button class="btn btn-sm btn-danger" (click)="reset()" *ngIf="isEdit"> Reset</button>
          </div>


        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>

<!-- view model -->

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header p-2">
        <h6 class="modal-title" id="exampleModalLabel">Product Details</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"> &times; </span>
        </button>
      </div>
      <div class="modal-body minhyt70 p-0" data-spy="scroll" id="scroll" *ngIf="isView">
        <app-product-details [isView]="isView" [view_item_id]="view_item_id"></app-product-details>
      </div>
      <!-- <div class="modal-footer p-2">
      <button type="button" class="btn btn-gray btn-sm rounded-pill px-2" data-dismiss="modal">
        Close
      </button>
    </div> -->
    </div>
  </div>
</div>