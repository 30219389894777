

<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="grid__wrapper tableMain p-0">
            <igx-grid #grid1 [data]="misReportRows" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
              [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">
              <ng-container *ngFor="let col of misReportColumndef let i=index">
                <igx-column [field]="col.field" [header]="col.headerName" [dataType]="'string'" [minWidth]='"200px"' [sortable]="col.sortable"
                  [filterable]="col.filter">
                  <ng-template igxCell let-cell="cell">
                    <a class="truncate" href="javascript:void(0)" (click)="ShowViewData(cell)">{{cell.value}}</a>
                  </ng-template>
                </igx-column>
              </ng-container>
            </igx-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>