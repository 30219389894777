
      

<div class="card mb-1   remove-shadow pb-0  no-border">
    <ngx-loading [show]="isLoading"></ngx-loading>
     <div class="card-body nopadding d-flex ">     
          
        <div class="col-sm-12 nopadding width100n" id="{{id}}smallscreen">
            <div class="card remove-shadow no-border">
                 <div class="card-body nopadding">
                   <div class="col-sm-12 absl"  >
                      <label style="color:green" class="font9 successedit" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
                   </div>
                   <div class="col-sm-12 absl"  *ngIf="ErrorMasgLoaded | async">
                         <label style="color:red" class="font9 successedit" *ngIf="ErrorMasgLoaded | async">{{SuccessMasg}}</label>
                   </div>
                   <div class="card-header pl-1">
                      <strong>List of Attachments</strong>
                  </div>
                  <!-- <h6 class="col-sm-12 pb-1 pl-1 pt-1 nopadding font600 mb-0 mt-0"></h6> -->
                  <div class="col-lg-12 nopadding" *ngIf="isApproved == false">
                    <div class="row m-2">
                   <div class="col-sm-6 col-md-6 col-lg-3">
                      <label  class="font8">Document Type <span class="warningred">*</span></label>
                      <select id="ddlAttachmentType" class="form-control" [(ngModel)]="attachment_type">
                         <option  *ngFor="let lst of documentTypeList" value="{{lst.name}}">{{lst.name}}</option>                  
                      </select>
                   </div>
                   <div class="col-sm-6 col-md-6 col-lg-3">
                      <label class="font8">Document Name <span class="warningred">*</span></label>
                      <input type="text" class="form-control" id="docName" name="docName"  autocomplete="off" [(ngModel)]="document_name" >
                   </div>
                   <div class="col-sm-6 col-md-6 col-lg-4">
                      <label  class="font8 p-0 col-sm-12">Upload Document <span class="warningred">*</span></label>
                      <input type="file"  #myPhoto class="font8" #document id="document"  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"  (change)="onUploadDocument($event)" />
                   </div>
                   <div class="col-sm-6 col-md-6 col-lg-2">
                     <button type="button" class="btn btn-sm btn-dark rounded-pill px-2 mt-4" (click)="addattachment()" >Add New</button> &nbsp;
                    
                   </div>
                  </div>
                </div>
              <hr class="mb-0 mt-0" />
              <table class="table table-bordered mt-0 mb-0">
                 <thead>
                 <tr>
                    <th class="tablesecondheader text-center"  width="10%">Sr No.</th>
                    <th class="tablesecondheader text-center">Document Name</th>
                     <th class="tablesecondheader text-center">Document Type</th> 
                    <th class="tablesecondheader text-center" >Action</th> 
                 </tr>
                </thead>
                <tbody>
 
                 <tr *ngFor="let view of viewlist;let i = index">
                   <td class="text-center"><label  class="font8">{{i+1}}</label></td>
                   <td><label class="font8">{{view.attachment_desc}}</label></td>
                      <td><label class="font8">{{view.attachment_type}}  </label></td>
                         <td class="text-center">
                            <a href="javascript:void(0)" *ngIf="view.ext == 'jpeg' || view.ext == 'jpg' || view.ext == 'png' || view.ext == 'gif' || view.ext == 'pdf'" class="btn btn-sm btn-dark" (click)="viewfile(view.id)"><i class="fa fa-eye"></i> </a> &nbsp;
                            <a (click)="downloadfile(view.file_name,view.file_path,id)" class="btn btn-sm btn-outline-dark"> <i class="fa fa-arrow-down"></i></a>&nbsp;
                             <a href="javascript:void(0)" *ngIf="viewmode == false && worklistid == view.worklist_id" class="btn btn-sm btn-outline-dark" (click)="deleteFile(view.id,view.worklist_id)"><i class="fa fa-trash"></i> </a>
                            </td>
                </tr>
             </tbody>
              </table>
           </div></div>
           <!-- <hr class="mb-0 mt-0" /> -->
           <!-- <div class="card remove-shadow mt-1">
           <div class="card-header pl-1">
               <strong>Add New</strong>
           </div>
           <div class="card-body nopadding" id="divDocument">
          
           </div>
        </div> -->
        </div>
        <div class="col-sm-4 pr-0 divToDisable pad0 widdth100" id="{{id}}filesec" style="display: none;">
           <div class="widget-box widget-color-info mt-0">
              <div class="widget-header">
                 <h5 class="widget-title bigger lighter h">
                    <i class="ace-icon fa fa-file"></i>
                    Attachments
                 </h5>
                 <div class="rytcont">
                         <a class="mr-2 expendfile text-dark" (click)="Fullscreen(id)" href="javascript:void(0)"><i class="fa fa-window-maximize" aria-hidden="true"></i></a>
                         <a class="mr-2 expendfilen text-dark" (click)="minscreen(id)" style="display: none"  href="javascript:void(0)"><i class="fa fa-window-restore" aria-hidden="true"></i></a>
                    
                     <a class="text-dark"  (click)="hidefile(id)" href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    <!-- <a class="btn btn-warning btn-sm cstmbtnwarning"  (click)="hidefile()" href="javascript:void(0)"><i class="fa fa-arrow-right"></i></a> -->
                 </div>
              </div>
              <div class="widget-body">
                 <div class="widget-main no-padding   ">
                    <div class="col-lg-12 nopadding" id="wizard">
                       <section class="nopadding" style="width: 100%; height:66vh;">
                         <!-- <ngx-image-viewer *ngIf="flag==true" [src]="path_url" ></ngx-image-viewer>
                         <iframe *ngIf="flag==false" style="width: 100%; height:100%;" [src]="path_url[0] | safe"></iframe> -->
                       </section>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
