import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import * as xlsx from 'xlsx';
import { Router, ActivatedRoute } from '@angular/router';
import { FormService } from '../../_services/form.service';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import { Subscription } from 'rxjs';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { Page } from '../../_models/page';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../../_services/AppSetting'
import { LoginService } from '../../_services/login.service'
import { DialogLayoutDisplay, ConfirmBoxInitializer } from '@costlydeveloper/ngx-awesome-popup';
declare var $: any;
import * as CryptoJS from 'crypto-js';
import { EALREADY } from 'constants';

@Component({
  selector: 'app-huno-main-igx',
  templateUrl: './huno-main-igx.component.html',
  styleUrls: ['./huno-main-igx.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HunoMainIgxComponent implements OnInit {
  @ViewChild("grid1", { read: IgxGridComponent, static: true })
  public grid1: IgxGridComponent;
  displayDensity = DisplayDensity.compact;
  LoadPagination: boolean = false;
  loading = false;
  Addloader = false;
  subscription: Subscription;
  formId: any = 0;
  processid: any;
  stageid: any;
  form_viewid: any = 0;
  formName: any;
  modulename: any;
  configParms: ConfigPrams;
  tableName: any;
  tablePrimaryKey: any
  SectionConfig: SectionConfig[] = [];
  formDisplayJSON: any;
  nodeJson: any;
  connJson: any;
  sessionUrlPrams: SessionUrlPrams; //new added

  filtersLoaded: Promise<boolean>;
  FormViewLoaded: Promise<boolean>;
  SuccessMasgLoaded: Promise<boolean>;
  ErrorMasgLoaded: Promise<boolean>;
  ViewLoaded: Promise<boolean>;
  EditLoaded: Promise<boolean>;
  DataRefreshLoaded: Promise<boolean>;
  tabshowloader: boolean = false;
  isAddMode: boolean = true;
  is_attachment: boolean = false;
  ErrorOccurred: String = "Error occurred!!";
  SuccessMasg: any;
  lookupArr: any = [];
  editValueRow: any;
  editrowid: any;
  viewFilterString: any;
  filterCriteria: any = "";
  gridFilter: any = "";
  view_filter: any;
  refKey: any = 0;

  ddlarrGidView: any = [];
  dateArrGidView: any = [];

  childFormList: any[] = [];
  metaDataList: any[] = [];
  attachmentType: any[] = [];
  metadata: any;

  //pagesize="10";
  columnDefs: any[] = [];
  columnActionStrip: any[] = [];
  OverlaySetting: any;//=FormService.OverlaySetting;
  rowData: any[] = [];
  page = new Page();
  data: any[] = [];

  isAdd: boolean = false;
  isEdit: boolean = false;
  isView: boolean = false;
  isExpotable: boolean = false;
  isResetFilter: Boolean = false;
  edit_option: number = 1;
  add_edit: Promise<boolean>;
  tabclieked: Promise<boolean> = Promise.resolve(false);



  createAutomationIds: string = "";
  updateAutomationIds: string = "";
  formAllField: any;
  isDynamicGridForm: boolean = false;
  isviewMode: boolean = false;
  selectedIndex: any; //add by pradeep on 5/5/2021 for selected tab
  selectedAndVisibleRows = []; ///add by pradeep on 18/5/2021 for excel download
  visibleRows; //add by pradeep on 18/5/2021 for excel download

  public totalCount = 0;
  public mode = GridPagingMode.remote;
  public isLoading = true;
  form_validation: any[] = [];
  lstConfigJson: any;
  currentSelectedRow: any;
  isDelete: boolean = false;
  isCopyAdd: boolean = false;
  isCopyAddStatus: boolean = false;
  sortingOrder: string = "asc";
  hunoencripturl: any;
  showCustom: boolean=false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private formService: FormService, private csvExportService: IgxCsvExporterService,
    private excelExportService: IgxExcelExporterService,
    private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.OverlaySetting = {
      positionStrategy: new ConnectedPositioningStrategy({
        horizontalDirection: HorizontalAlignment.Left,
        horizontalStartPoint: HorizontalAlignment.Right,
        verticalStartPoint: VerticalAlignment.Bottom,

      }),
      scrollStrategy: new NoOpScrollStrategy()
    };
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      if (data.length > 1) {
        var str = data;
        var value = str.split("&");
        if (value[0].indexOf('frmid') >= 0) {
          this.formId = value[0] ? value[0].split('=')[1] : null;
        }
        if (value[0].indexOf('vid') >= 0) {
          this.form_viewid = value[0] ? value[0].split('=')[1] : null;
        }
        if (value[1].indexOf('frmid') >= 0) {
          this.formId = value[1] ? value[1].split('=')[1] : null;
        }
        if (value[1].indexOf('vid') >= 0) {
          this.form_viewid = value[1] ? value[1].split('=')[1] : null;
        }
        this.selectedIndex = this.formId;
        this.sessionUrlPrams = {};
        this.sessionUrlPrams = {
          frmid: this.formId,
          vid: this.form_viewid,
          UserCode: "UserCode",
          stageid: this.stageid,
          UserName: localStorage.getItem("userFullName"),
          ProfileId: localStorage.getItem("profileId"),
          Location: localStorage.getItem("office"),
          office: localStorage.getItem("office"),
          department: localStorage.getItem("department"),
          UserId: localStorage.getItem("userId"),
        };
      }
    });

    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.isAddMode = true;
    this.tabclieked = Promise.resolve(false);
    this.getFormDetails(0);
    this.page.size = 10
    $(document).unbind('keypress').bind('keypress', function (e) {
      e.stopImmediatePropagation();
      if (e.keyCode == 13) {
        $('#lnkSearchValue').trigger('click');
      }
    });
  }

  tabChanged(event, tabIndex) {
    this.showCustom=false;
    $("#tabchildedit__401").removeClass('active'); 
    $("#tabchildeditReport__401").removeClass('active');
    this.tabshowloader = true;
    this.Addloader = true;
    this.tabclieked = Promise.resolve(false);
    this.selectedIndex = tabIndex;
    this.tabclieked = Promise.resolve(true);
    setTimeout(() => {
      this.tabshowloader = false;
      this.Addloader = false;
    }, 1000);
  }


  checkList: any;
  checkListName: any;
  checkedListName: any;
  checkedList: any;
  finalCheckListName:any[]= [];
  newCheckedList:any[]=[];
  arrayRemove(arr, value) { 
    
    return arr.filter(function(ele){ 
        return ele != value; 
    });
}
  checkListSelection(event, data: any) {
    if(event.target.checked !== false){
      this.newCheckedList.push(data);
    }else
    {
      this.newCheckedList=this.arrayRemove(this.newCheckedList,data);
    }
  }
  getAllreadyCheckedList() {
    var str_array;
    var condQry = `SELECT * FROM z_entitydtl_checklist__001 Where employee_id=${this.editrowid};`
    this.formService.getFormData(condQry).subscribe(res => {
      this.checkedList = res.rows;
      this.checkedListName = this.checkedList[0].checklist_name;
      str_array = this.checkedListName.split(',');
      for (var i = 0; i < str_array.length; i++) {
        this.newCheckedList.push(Number(str_array[i]));
        $('#checkListId_'+str_array[i]).prop('checked',true);
      }
    });

  }

  
  getCheckList() {
    var condQry = `SELECT id as checklist_id,varibale_type,varibale_name,false as is_check FROM app_tblsystemenum Where  varibale_type='Checklist' order by id ASC;`
    this.formService.getFormData(condQry).subscribe(res => {
      this.checkList = res.rows;
    });

  }
  // checkListiii:any;
  // bjfk(){
  //   var condQry = `select * from z_entitydtl_language__001 where employee_id = 1`
  //   this.formService.getFormData(condQry).subscribe(res => {
  //     this.checkListiii = res.rows;

  //     console.log('------------------------',this.checkListiii)
  //   });
  // }
  onCustomClick() {
    this.showCustom=true;
    // this.bjfk();
    // this.getAllreadyCheckedList();
    // this.getCheckList();
    this.tabshowloader = true;
    this.Addloader = true;
    if($('#tabedit401').siblings().length==0){
      this.tabclieked = Promise.resolve(false);
      this.selectedIndex = 401;
      this.tabclieked = Promise.resolve(true);
    }
    setTimeout(() => {
      $(".tab-pane").removeClass('active in show');
      $(".tabchecklist401").addClass('active in show'); 
      $("#tabchildedit__401").addClass('active'); 
      this.tabshowloader = false;
      this.Addloader = false;
    }, 1000);
  }
  onCustomClickReporting() {
    this.showCustom=true;
    this.tabshowloader = true;
    this.Addloader = true;
    if($('#tabreporting401').siblings().length==0){
      this.tabclieked = Promise.resolve(false);
      this.selectedIndex = 401;
      this.tabclieked = Promise.resolve(true);
    }
    setTimeout(() => {
      $(".tab-pane").removeClass('active in show');
      $(".tabreporting401").addClass('active in show'); 
      $("#tabchildeditReport__401").addClass('active'); 
      this.tabshowloader = false;
      this.Addloader = false;
    }, 1000);
  }
  SaveChecklist() {
    var str = '';
    var create_by = this.sessionUrlPrams.UserId;
    for (var i = 0; i < this.checkList.length; i++) {
      if (this.checkList[i].is_check) {
        str = str + this.checkList[i].checklist_id + ',';
      }
    }
    if (str != null && str != undefined && str != "" && str != "null" && str != "undefined") {
      str = str.substring(0, str.length - 1);
    }
    this.checkListName = str;
    var condQry = null;
    if(this.checkedList.length>0){
      condQry = `update z_entitydtl_checklist__001 set checklist_name='${this.newCheckedList}',updateddate=current_timestamp,updatedby=${create_by} where employee_id=${this.editrowid};`
    } else {
      condQry = `insert into z_entitydtl_checklist__001 (checklist_name,employee_id,createdby) values('${this.checkListName}',${this.editrowid},${create_by});`
    }
    this.formService.getFormData(condQry).subscribe(res => {
      if(res.command=="UPDATE"){
        this.toastrService.success("Successfully Updated",  '',  {timeOut: 3000,positionClass : "toast-top-center"})
        
        // this.toastrService.success("Successfully Updated",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        // $(".overlay-container").addClass("addcontainerdisable");
        return false;
      }
      else{

       
        this.toastrService.success("Successfully Added",  '',  {timeOut: 3000,positionClass : "toast-top-center"})
     
      // this.toastrService.success("Successfully Added",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      // $(".overlay-container").addClass("addcontainerdisable");
      return false; 
    }

    });
  }



  getFormDetails(val) {
    this.subscription = this.formService.getDataByFormId(this.formId).subscribe(res => {
      //console.log("res===>",res.rows);
      let formList = res.rows;
      if (formList.length > 0) {
        this.createAutomationIds = formList[0]["create_automation_ids"] ? formList[0]["create_automation_ids"] : "";
        this.updateAutomationIds = formList[0]["update_automation_ids"] ? formList[0]["update_automation_ids"] : "";
        this.formName = formList[0]["form_name"];
        this.modulename = formList[0]["modulename"];
        this.formId = formList[0]["form_id"];
        this.processid = formList[0]["processid"];
        this.tableName = formList[0]["table_name"];
        this.tablePrimaryKey = (formList[0]["primary_key"]).toLowerCase();
        this.formDisplayJSON = formList[0]["form_display_json"] ? formList[0]["form_display_json"] : [];
      }
      this.getFormViewDetails();
    });
  }

  getFormViewDetails() {
    this.columnDefs = [];
    this.columnActionStrip = [];
    this.subscription = this.formService.getFormViewById(this.form_viewid).subscribe(res => {
      let formViewList = res.rows;
      let a = 0;
      if (formViewList.length > 0) {
        this.isAdd = formViewList[0]["is_add"] ? formViewList[0]["is_add"] : false;
        this.isEdit = formViewList[0]["is_edit"] ? formViewList[0]["is_edit"] : false;
        this.isView = formViewList[0]["is_view"] ? formViewList[0]["is_view"] : false;
        this.isDelete = formViewList[0]["is_delete"] ? formViewList[0]["is_delete"] : false;
        this.sortingOrder = formViewList[0]["sorting_order"] ? formViewList[0]["sorting_order"] : "asc";
        this.isExpotable = formViewList[0]["is_exportable"] ? formViewList[0]["is_exportable"] : false;
        this.isCopyAdd = formViewList[0]["is_copyadd"] ? formViewList[0]["is_copyadd"] : false;

        let fromViewjson = formViewList[0]["field_json"] ? formViewList[0]["field_json"] : [];
        let childFormId = formViewList[0]["child_form_ids"] ? formViewList[0]["child_form_ids"] : "";
        this.edit_option = formViewList[0]["edit_option"];
        this.metaDataList = formViewList[0]["meta_data"] ? JSON.parse(formViewList[0]["meta_data"]) : [];
        this.attachmentType = formViewList[0]["attachment_type"] ? JSON.parse(formViewList[0]["attachment_type"]) : [];
        this.is_attachment = formViewList[0]["is_attachment"] ? formViewList[0]["is_attachment"] : false;

        this.view_filter = this.formService.getConditionQueryByJson(formViewList[0]["filter_json"], this.sessionUrlPrams);
        let view_type = formViewList[0]["view_type"] ? formViewList[0]["view_type"] : 1;
        let data_for_only = formViewList[0]["data_for_only"] ? formViewList[0]["data_for_only"] : "all";
        let child_form_view_json = formViewList[0]["child_form_view_ids"] ? JSON.parse(formViewList[0]["child_form_view_ids"]) : [];
        this.form_validation = formViewList[0]["validation"] ? JSON.parse(formViewList[0]["validation"]) : []

        if (child_form_view_json.length > 0) {
          child_form_view_json = child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId = "";
          for (let r = 0; r < child_form_view_json.length; r++) {
            childFormId = childFormId + child_form_view_json[r]["formid"] + ",";
          }
          childFormId = childFormId.substring(0, childFormId.length - 1)
        }

        if (data_for_only != "all" && this.filterCriteria != "") {
          this.filterCriteria = this.filterCriteria + " and a.createdby=" + this.sessionUrlPrams.ProfileId; //this.SessionUrlPrams[data_for_only]
        }
        if (childFormId != "" && childFormId != undefined && childFormId != null) {
          this.subscription = this.formService.getChildFormDataByIds(childFormId).subscribe(childRes => {
            this.childFormList = childRes.rows;
            if (child_form_view_json.length > 0) {
              for (let a = 0; a < this.childFormList.length; a++) {
                let arrView = child_form_view_json.filter(d => d.formid == this.childFormList[a]["form_id"])
                if (arrView.length > 0) {
                  this.childFormList[a]["viewid"] = arrView[0]["viewid"]
                  this.childFormList[a]["display_order"] = arrView[0]["orderno"]
                } else {
                  this.childFormList[a]["viewid"] = 0;
                  this.childFormList[a]["display_order"] = 0;
                }
              }
            }
            this.childFormList.sort((a, b) => a.display_order < b.display_order ? -1 : 1)
            // console.log(JSON.stringify(this.childFormList)) 
          });
        }

        if (fromViewjson.length > 0) {
          let selectedFieldList = JSON.parse(fromViewjson)
          var column = '';
          //console.log("fromViewjson===>",fromViewjson,view_type);
          selectedFieldList.forEach(fieldprop => {
            if (fieldprop.control_type == 5 || fieldprop.control_type == 10) {
              if (view_type == 1) {
                this.ddlarrGidView.push({ "index": a, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3", "control_type": fieldprop.control_type })
              } else {
                this.ddlarrGidView.push({ "index": a, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": fieldprop.display_field, "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            }
            else if (fieldprop.control_type == 7 || fieldprop.control_type == 8) {
              if (view_type == 1) {
                this.dateArrGidView.push({ "index": a, "columnname": fieldprop.field_name, "type": "1" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "4", "control_type": fieldprop.control_type })
              } else {
                this.dateArrGidView.push({ "index": a, "columnname": fieldprop.field_name, "type": "1" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "4", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": fieldprop.display_field, "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            }
            else if (fieldprop.control_type == 14 || fieldprop.control_type == 15 || fieldprop.control_type == 19 || fieldprop.control_type == 22) {
              if (view_type == 1) {
                let displayField = JSON.parse(fieldprop.display_field)
                this.lookupArr.push({
                  "index": a, "view_type": view_type, "columnname": fieldprop.field_name,
                  "reference_form_id": fieldprop.reference_form_id, "value_field": fieldprop.value_field,
                  "display_field": displayField[0]["id"], "field_name": fieldprop.field_name, "type": "2", "control_type": fieldprop.control_type
                })
              } else {
                let displayField = JSON.parse(fieldprop.display_field)
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "2", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": displayField[0]["id"], "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            } else {
              if (view_type == 1) {
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "1", "control_type": fieldprop.control_type })
              } else {
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "1", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": fieldprop.display_field, "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            }
            a++;
            this.columnDefs.push({ field: fieldprop.field_name.toString().toLowerCase(), headerName: fieldprop.field_caption, sortable: fieldprop.sortable, filter: fieldprop.filterable, resizable: fieldprop.resizable, width: fieldprop.column_width, wrapText: true, autoHeight: true });
          });
          if (this.isEdit == true) {
            this.columnActionStrip.push({ field: "Edit", iconName: "edit" });
          }
          if (this.isDelete == true) {
            this.columnActionStrip.push({ field: "Delete", iconName: "edit" });
          }
          if (this.isCopyAdd == true) {
            this.columnActionStrip.push({ field: "Copy Add", iconName: "edit" });
          }

          this.bindNgxDataGrid(this.page, 0)
          this.DataRefreshLoaded = Promise.resolve(true);
        }
      }
    });
  }

  LoadGrid(event) {
    this.configParms = event;
    this.isLoading = true;
    //console.log("config==>",event,this.config);
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)

  }
  bindNgxDataGrid(pageInfo, status) {
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);
    }
    this.data = [];
    this.formService.getNgxGridData(this.lookupArr, this.tableName, this.tablePrimaryKey, this.filterCriteria, this.view_filter, pagesize, offset, this.gridFilter, this.sortingOrder).subscribe(res => {
      this.page.totalElements = res[0].rows[0]["total"];
      this.data = res[1].rows;
     
      this.totalCount = res[0].rows[0]["total"];
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;
      this.isLoading = false;
    }, err => {

     
      this.toastrService.success(err.error.message,  '',  {timeOut: 3000,positionClass : "toast-top-center"})
      //this.toastrService.success(err.error.message,  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
    });
  }

  onAddClick() {
    this.Addloader = true;
    this.selectedIndex = this.formId;
    this.isDynamicGridForm = false;
    this.add_edit = Promise.resolve(false);
    this.SectionConfig = null;
    this.lstConfigJson = null;
    this.isAddMode = true;
    this.FormViewLoaded = Promise.resolve(false);
    if (this.formDisplayJSON.length > 0) {
      if (this.processid > 0) {
        this.nodeJson = JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson = JSON.parse(this.formDisplayJSON).connJson;
        var intStageid = this.nodeJson.filter(d => d.type == 'initial')[0]["id"];
        this.SectionConfig = JSON.parse(this.formDisplayJSON).sectionJson;
        // control Section hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          if (arrField.FieldConfig[0] ?.visible_stage != undefined) {
            arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || field.visible_stage.toString() == "");
          }
        });
        this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig ?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || d.visible_stage.toString() == "")));
        this.stageid = intStageid;
      } else {
        this.SectionConfig = JSON.parse(this.formDisplayJSON)
        this.SectionConfig.forEach(arrField => {
          if (arrField.FieldConfig[0] ?.visible_stage != undefined) {
            //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
            arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || field.visible_stage.toString() == "");
          }
        });
        this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig ?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || d.visible_stage.toString() == "")));
      }
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field ?.visible == null || field ?.visible == "always" || field ?.visible == "add" || field ?.visible == "hidden");
      });
      this.SectionConfig = this.SectionConfig.filter(sec => (sec.FieldConfig ?.length > 0 || sec.section_dependent == true) && (sec ?.isCaptionVisiable == "always" || sec ?.isprocess == "1" ));
    }
    this.lstConfigJson = {
      "section_config_json": this.SectionConfig,
      "form_validation_json": this.form_validation,
      "form_attachment_json": this.attachmentType
    }

    this.FormViewLoaded = Promise.resolve(true);
    setTimeout(() => { $("#exampleModal").modal('show'); }, 100);
    setTimeout(() => { this.Addloader = false; }, 600);

  }

  editView(e) {
    this.selectedIndex = this.formId;
    this.showCustom=false;
    this.add_edit = Promise.resolve(false);
    this.EditLoaded = Promise.resolve(false);
    this.tabshowloader = true;
    this.SectionConfig = null;
    this.lstConfigJson = null;
    if (this.formDisplayJSON.length > 0) {
      if (this.processid > 0) {
        this.nodeJson = JSON.parse(this.formDisplayJSON).nodeJson;
        this.connJson = JSON.parse(this.formDisplayJSON).connJson;
        var intStageid = this.nodeJson.filter(d => d.type == 'initial')[0]["id"];
        this.SectionConfig = JSON.parse(this.formDisplayJSON).sectionJson;
        // control Section hide/show based on process stages...
        this.SectionConfig.forEach(arrField => {
          if (arrField.FieldConfig[0] ?.visible_stage != undefined) {
            arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || field.visible_stage.toString() == "");
          }
        });
        this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig ?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == intStageid)).length > 0 || d.visible_stage.toString() == "")));
        this.stageid = intStageid;
      } else {
        this.SectionConfig = JSON.parse(this.formDisplayJSON)
        this.SectionConfig.forEach(arrField => {
          if (arrField.FieldConfig[0] ?.visible_stage != undefined) {
            //arrField.FieldConfig = arrField.FieldConfig.filter(field => field.visible_stage.indexOf(this.form_viewid)>=0 || field.visible_stage.toString() =="");
            arrField.FieldConfig = arrField.FieldConfig.filter(field => ((field.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || field.visible_stage.toString() == "");
          }
        });
        this.SectionConfig = this.SectionConfig.filter(d => d.FieldConfig ?.length > 0 || (d.section_dependent == true && (d.visible_stage == undefined || ((d.visible_stage.toString().trim().split(",")).filter(fl => fl == this.form_viewid)).length > 0 || d.visible_stage.toString() == "")));
      }
      //console.log("this.SectionConfig==>",this.SectionConfig)  
      this.SectionConfig.forEach(arrField => {
        arrField.FieldConfig = arrField.FieldConfig.filter(field => field ?.visible == "always" || field ?.visible == "edit" || field ?.visible == "hidden");
      });
      this.SectionConfig = this.SectionConfig.filter(sec => (sec.FieldConfig ?.length > 0 || sec.section_dependent == true) && (sec ?.isCaptionVisiable == "always" || sec ?.isprocess == "1" ));
      //console.log("this.SectionConfig==>",this.SectionConfig)  

      //need to check e.rowData pks 12/05/21
      this.isAddMode = false;
      this.editrowid = e._rowData[this.tablePrimaryKey];
      this.refKey = e._rowData[this.tablePrimaryKey];

      this.subscription = this.formService.getFormRowDataById(this.tableName, this.tablePrimaryKey, this.editrowid).subscribe(res => {
        //console.log("rowData==",res.rows[0]);
        this.editValueRow = JSON.parse(JSON.stringify(res.rows[0]));
        //console.log("sss this.formAllField===>",this.editValueRow,this.edit_option,this.EditLoaded);
        this.lstConfigJson = {
          "section_config_json": this.SectionConfig,
          "form_validation_json": this.form_validation,
          "form_attachment_json": this.attachmentType
        }
        this.EditLoaded = Promise.resolve(true);
        setTimeout(() => { $("#editModal").modal('show'); }, 100);

        if (this.edit_option == 3) {
          if (this.childFormList.length > 0) {
            let formid = this.childFormList[0]["form_id"];
            let tabid = "#tabchildedit" + formid;
            setTimeout(() => { $(tabid).click(); }, 100);
          }
        }
      });
    }
    setTimeout(() => {
      this.tabshowloader = false;
    }, 1000);
  }

  closeModel() {
    this.selectedIndex = this.formId;
    setTimeout(() => { $("#editModal").modal('hide'); }, 0);
  }

  onClickView(e) {
    var id = "";
    //need to check e.rowdata pks 12/05/2021
    // id=e.rowData[this.tablePrimaryKey];
    id = e._rowData[this.tablePrimaryKey];
    this.showCustom=false;
    this.subscription = this.formService.getFormRowDataById(this.tableName, this.tablePrimaryKey, id).subscribe(res => {
      this.formAllField = JSON.parse(this.formDisplayJSON);
      for (var r = 0; r < this.formAllField.length; r++) {
        var regConfig = this.formAllField[r]["FieldConfig"];
        for (var i = 0; i < regConfig.length; i++) {
          regConfig[i].value = res.rows[0][regConfig[i].name];
          regConfig[i].type = 'fieldview';
        }
        this.formAllField[r].FieldConfig = regConfig;
      }
      this.isDynamicGridForm = true;
      this.editView(e)
    });
  }

  onClickActionStrip(e, actiontype) {
    if (actiontype == "Delete") {
      const confirmBox = new ConfirmBoxInitializer();
      confirmBox.setTitle('Are you sure?');
      confirmBox.setMessage('You want to delete this!');
      confirmBox.setButtonLabels('YES', 'NO');
      confirmBox.setConfig({
        LayoutType: DialogLayoutDisplay.DANGER // SUCCESS | INFO | NONE | DANGER | WARNING
      });
      const subscription = confirmBox.openConfirmBox$().subscribe(resp => {
        if (resp.Success == true) {
          let delQry = "delete from " + this.tableName + " where " + this.tablePrimaryKey + "=" + e._rowData[this.tablePrimaryKey] + ";"
          this.formService.getFormData(delQry).subscribe(res => {
           
            this.toastrService.success("Deleted Successfully.",  '',  {timeOut: 3000,positionClass : "toast-top-center"})
          //  this.toastrService.success("Deleted Successfully.",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
          //  $(".overlay-container").addClass("addcontainerdisable");
              this.bindNgxDataGrid(this.page, 1)
          }, err => {
           // console.log('err',err)
            this.toastrService.error("Record is already used can not deleted",  '',  {timeOut: 3000,positionClass : "toast-top-center"})
          
              });
        }
      });
    }
    else {
      if (actiontype == "Copy Add") {
        this.isCopyAddStatus = true;
      } else {
        this.isCopyAddStatus = false;
      }
      this.isDynamicGridForm = false;
      this.editView(e)
    }
  }

  refreshTable(keyvalue: any, add_edit: any) {
    //this.subscription=this.formService.getFormData(this.ViewTableQry +" where "+this.tablePrimaryKey +" = "+keyvalue).subscribe(res => {
    let strFilter = "a." + this.tablePrimaryKey + " = " + keyvalue;
    this.subscription = this.formService.getFormFormViewsData(this.lookupArr, this.tableName, this.tablePrimaryKey, strFilter, this.view_filter).subscribe(res => {
      let tempRowData = res.rows;
      //console.log(JSON.stringify(res.rows),this.rowData,add_edit)
      for (var c = 0; c < tempRowData.length; c++) {
        let firstColumnname = this.columnDefs[0]["field"];
        let rowid = tempRowData[c][firstColumnname];
        for (let a = 0; a < this.ddlarrGidView.length; a++) {
          let columnValue = tempRowData[c][this.ddlarrGidView[a]["columnname"]];
          let jsonList = this.ddlarrGidView[a]["dynamiclist"] ? this.ddlarrGidView[a]["dynamiclist"] : [];
          if (jsonList.length > 0) {
            let valArr = jsonList.filter(d => d.text == columnValue);
            let val = "";
            if (valArr.length > 0) {
              val = valArr[0]["binding"];
            }
            tempRowData.filter(d => d[firstColumnname] == rowid)[0][this.ddlarrGidView[a]["columnname"]] = val;
          }
        }
        if (add_edit == 1) {
          this.rowData.push(tempRowData[c]);
        } else if (add_edit == 2) {
          //console.log("asdasjh Not working pks",this.EditLoaded)

          // Not working pks
          //this.rowData.filter(d=> d[this.tablePrimaryKey] == keyvalue)[0]=tempRowData[c]; 
          var i = this.rowData.length;
          while (i--) {
            if (keyvalue.toString().indexOf(this.rowData[i][this.tablePrimaryKey]) != -1) {
              this.rowData.splice(i, 1);
            }
          }
          this.rowData.push(tempRowData[c]);
        }

      }
      this.rowData = this.rowData.sort((a, b) => a[this.tablePrimaryKey] < b[this.tablePrimaryKey] ? 1 : -1)
      this.DataRefreshLoaded = Promise.resolve(false);
      this.DataRefreshLoaded = Promise.resolve(true);

      $("#clos").click();
    }, err => {
      $("#clos").click();
    })

    setTimeout(() => this.SuccessMasgLoaded = Promise.resolve(false), 3000);
  }


  submit(value: any) {
    //console.log("pradeep value.isAddMode===>",JSON.stringify(value),value.isAddMode,value.ErrorMassage);
    if (value.isAddMode == true) {
      if (value.SuccessMasgLoaded == "true") {
        //console.log("value.SuccessMasgLoaded Add==>",value.SuccessMasgLoaded);
        if(this.formId == 377){
          let updatekey  =   value.refKey;
          const category_name = value.FormDate.category_name.replace(/\s/g,'');
          const catcode = category_name.slice(0, 3);
          // const stringRandLen = 16384
          // var stringRandomId = stringRandLen
          // var stringRandomArray = new Uint32Array(stringRandLen)
          // stringRandomId = 0
          // let randomNumber = Math.floor((crypto.getRandomValues(stringRandomArray)[stringRandomId++] * 2.3283064365386963e-10) * 1000000)
           let category_code = catcode +  updatekey
          let strSQLDraftUpdate="update "+this.tableName+" set  category_code= '"+category_code+"' where product_category_id =" +updatekey ;
         // console.log('strSQLDraftUpdate',strSQLDraftUpdate)
          this.subscription=this.formService.getFormData(strSQLDraftUpdate).subscribe(result =>{
                      
          });
        }
        this.toastrService.success("Added Successfully !!",  '',  {timeOut: 3000,positionClass : "toast-top-center"})
     
        // this.toastrService.success("Added Successfully !!",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        // $(".overlay-container").addClass("addcontainerdisable");
        this.refKey = value.refKey;
        this.isAddMode = false;
        if (value.primaryKey == undefined) {
          this.bindNgxDataGrid(this.page, 1);
        }
      } else {
        this.toastrService.error("Error occurred!! (" + value.ErrorMassage + ")",  '',  {timeOut: 3000,positionClass : "toast-top-center"})
        // this.toastrService.error("Error occurred!! (" + value.ErrorMassage + ")",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
        // $(".overlay-container").addClass("addcontainerdisable");
     
      }
      this.callIntimationTrigger(this.formId, value.refKey, this.createAutomationIds, 'create');
    } else {
      if (value.SuccessMasgLoaded == "true") {
       // console.log("value.SuccessMasgLoaded Updated==>",value.SuccessMasgLoaded);
       
        //  if(this.formId == 377){
        //     console.log(value.FormDate.category_name)
        //     const category_name = value.FormDate.category_name.replace(/\s/g,'');
        //     const catcode = category_name.slice(0, 3);
        //     const stringRandLen = 16384
        //     var stringRandomId = stringRandLen
        //     var stringRandomArray = new Uint32Array(stringRandLen)
        //     stringRandomId = 0
        //     let randomNumber = Math.floor((crypto.getRandomValues(stringRandomArray)[stringRandomId++] * 2.3283064365386963e-10) * 1000000)
        //     let category_code = catcode +  this.refKey + randomNumber
        //     console.log('this.formId',this.formId,category_code)    
        //     let strSQLDraftUpdate="update "+this.tableName+" set  category_code= '"+category_code+"' where product_category_id =" +this.refKey ;
        //     console.log('strSQLDraftUpdate',strSQLDraftUpdate)
        //     this.subscription=this.formService.getFormData(strSQLDraftUpdate).subscribe(result =>{
                        
        //     });
        //   }


        this.toastrService.success("Updated Successfully !!",  '', {timeOut: 3000,positionClass : "toast-top-center"})
      //  this.toastrService.success("Updated Successfully !!",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
       // $(".overlay-container").addClass("addcontainerdisable");
        if (value.primaryKey == undefined) {
          this.bindNgxDataGrid(this.page, 1);
        }
      } else {
        this.toastrService.error("Error occurred!! (" + value.ErrorMassage + ")",  '', {timeOut: 3000,positionClass : "toast-top-center"})
       // this.toastrService.error("Error occurred!! (" + value.ErrorMassage + ")",  '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      //  $(".overlay-container").addClass("addcontainerdisable");
      }
      this.callIntimationTrigger(this.formId, value.refKey, this.updateAutomationIds, 'update');
    }
  }

  getDetails(val) {
    this.viewFilterString = null;
    this.filterCriteria = null;
    this.gridFilter = "";
    this.isResetFilter = true;
    this.formId = val;
  }


  removeFilter() {
    this.viewFilterString = null;
    this.filterCriteria = null;
    this.isResetFilter = true;
    this.getFormViewDetails();
  }
  CommonGridSearch(SearchValue) {
    if (SearchValue.length) {
      this.gridFilter = SearchValue.toString().trim();
      this.LoadPagination = false;
      this.page.size = 10;
      this.page.pageNumber = 1;
      this.bindNgxDataGrid(this.page, 1)
    } else {
      this.gridFilter = "";
      this.LoadPagination = false;
      this.page.size = 10;
      this.page.pageNumber = 1;
      this.bindNgxDataGrid(this.page, 1)
    }
  }
  apply(criteriaStr: any) {
    //console.log("criteriaStr==>",criteriaStr)
    if (criteriaStr != null) {
      this.viewFilterString = criteriaStr["viewStr"];
      this.filterCriteria = criteriaStr["queryStr"];
      //this.getFormViewDetails();
      this.bindNgxDataGrid(this.page, 1)
    }
  }
  processsubmit(value: any) {
    if (value.SuccessMasgLoaded == "true") {
      this.toastrService.success("Submitted Successfully !!", '', {timeOut: 3000,positionClass : "toast-top-center"})
      //console.log("process Submitted==>",value.SuccessMasgLoaded);
   //   this.toastrService.success("Submitted Successfully !!", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //  $(".overlay-container").addClass("addcontainerdisable");
    
    } else {
      this.toastrService.error(value.ErrorOccurred)
    }
  }
  public filteredAndSelected(grid) {
    this.selectedAndVisibleRows = [];
    this.visibleRows = grid.selectionService.getRowIDs(
      grid.selectionService.allData
    );

    if (grid.selectedRows.length > 0) {
      grid.selectedRows.forEach(row => {
        if (this.visibleRows.indexOf(row) >= 0) {
          this.selectedAndVisibleRows.push(row);
        }
      });
    }
    return this.visibleRows;
  }
  exportCSVHandler() {
    this.exportGridToCSV(this.grid1);
  }
  exportExcelHandler() {
    this.exportGridToExcel(this.grid1);
  }
  exportGridToExcel(grid: IgxGridComponent) {
    // this.excelExportService.export(grid, new IgxExcelExporterOptions(this.fileName()));
    this.filteredAndSelected(grid);
    // if there aren't any rows after the filtering has been applied do not export anything
    if (this.visibleRows.length > 0) {
      // component.ts
      this.excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
        if (args.header == "Id") {
          args.cancel = true;
        }
      });
      // if there are any selected rows within the filtered data -> export only them
      // if there are no selected rows within filtered data -> export only the filtered data
      this.excelExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxExcelExporterOptions(this.fileName()));
    }
  }

  exportTreeGridToExcel(grid: IgxTreeGridComponent) {
    this.excelExportService.export(grid, new IgxExcelExporterOptions(this.fileName()));
    this.filteredAndSelected(grid);
    if (this.visibleRows.length > 0) {
      this.excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
        if (args.header == "Id") {
          args.cancel = true;
        }
      });
      this.excelExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxExcelExporterOptions(this.fileName()));
    }
  }

  exportGridToCSV(grid: IgxGridComponent) {
    this.filteredAndSelected(grid);

    if (this.visibleRows.length > 0) {
      this.csvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
        if (args.header == "Id") {
          args.cancel = true;
        }
      });
      this.csvExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxCsvExporterOptions(this.fileName(), CsvFileTypes.CSV));
    }
  }

  exportTreeGridToCSV(grid: IgxTreeGridComponent) {
    this.filteredAndSelected(grid);

    if (this.visibleRows.length > 0) {
      this.csvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
        if (args.header == "Id") {
          args.cancel = true;
        }
      });
      this.csvExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxCsvExporterOptions(this.fileName(), CsvFileTypes.CSV));
    }
  }
  public fileName() {
    const nowDate = new Date();
    const month = nowDate.toLocaleString('default', { month: 'short' })
    const date = nowDate.getDate() + '-' + month + '-' + nowDate.getFullYear();
    const url = this.router.url.replace(/\//g, "");
    const filename = url.charAt(0).toUpperCase() + url.slice(1);
    return filename + "_" + date;
  }
  ngOnDestroy() {

    this.subscription.unsubscribe();
    this.formService = null;
    this.formId = null
    this.processid = null
    this.stageid = null
    this.form_viewid = null
    this.formName = null
    this.tableName = null
    this.tablePrimaryKey = null
    this.SectionConfig = null
    this.formDisplayJSON = null
    this.nodeJson = null
    this.connJson = null
    this.filtersLoaded = null
    this.FormViewLoaded = null
    this.SuccessMasgLoaded = null
    this.ErrorMasgLoaded = null
    this.ViewLoaded = null
    this.EditLoaded = null
    this.DataRefreshLoaded = null
    this.isAddMode = null
    this.is_attachment = null
    this.SuccessMasg = null
    this.lookupArr = []
    this.editValueRow = null
    this.editrowid = null
    this.viewFilterString = null
    this.filterCriteria = null
    this.gridFilter = "";

    this.refKey = null
    this.ddlarrGidView = []
    this.dateArrGidView = []
    this.childFormList = []
    this.metaDataList = null
    this.attachmentType = null
    this.metadata = null
    this.rowData = null
    this.columnDefs = null
    this.isAdd = null
    this.isEdit = null
    this.isView = null
    this.isExpotable = null
    this.isResetFilter = null
    this.edit_option = null
    this.sessionUrlPrams = null;
    this.selectedAndVisibleRows = []; ///add by pradeep on 18/5/2021 for excel download
    this.visibleRows = null; //add by pradeep on 18/5/2021 for excel download
  }



  callIntimationTrigger(formid, rowid, automationids, action) {
    // console.log(formid,rowid,automationids,action)
    if (automationids != "" && automationids != null && automationids != undefined) {
      this.formService.setSchedularForMailAndAutomation(formid, rowid, automationids, action, this.sessionUrlPrams["ProfileId"]).subscribe(res => {
        //console.log(JSON.stringify(res))
      });
    }
  }

  handleRowSelection(e) {
    this.currentSelectedRow = e["newSelection"][0]
  }

  ShowViewData(e) {
    this.onClickView({ "_rowData": e.rowData })
  }


  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
