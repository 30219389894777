<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>
        <div class="row m-0">
            <div class="col-sm-12 p-0">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                        <li class="breadcrumb-item">
                            <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/total-seller">Seller List</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Add Seller</li>
                    </ol>
                </nav>
            </div>
            <div class="col-sm-12 p-0 bg-white">
                <div class="container my-5">
                    <div class="row justify-content-center">
                        <div class="col-sm-12">
                            <div class="card shadow-none">
                                <div class="row m-0">
                                    <div class="col-md-12 col-lg-7 col-12 d-none d-lg-block p-0">
                                        <div class="seler-bg-img">
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-5 col-12 p-0">
                                        <div class="" *ngIf="userdt">
                                            <div class="form-text px-lg-2 ">
                                                <div class="cret_ac mb-3 mt-3">
                                                    <h5 class="text-center mb-2">Create Account</h5>
                                                    <div class="">
                                                        <div class="card p-lg-3 p-3 m-3">
                                                            <div class="form-group mb-2">
                                                                <label for="" class="mb-0">Your Name
                                                                    <span class="asterisk"> *</span>
                                                                </label>

                                                                <input type="text" (keypress)="commonFunctionService.alphabetsValidator($event)" class="form-control" ondrop="return false;"
                                                                    placeholder="" [(ngModel)]="full_name" maxlength="50">
                                                            </div>
                                                            <div class="form-group mb-2">
                                                                <label for="" class="mb-0">Mobile Number
                                                                    <span class="asterisk">
                                                                        *</span>
                                                                </label>
                                                                <input type="text" class="form-control" ondrop="return false;" placeholder="" maxlength="10" [(ngModel)]="mobile_no" (keypress)="commonFunctionService.numericCheck($event)">
                                                            </div>
                                                            <div class="form-group mb-2">
                                                                <label for="" class="mb-0">Email
                                                                    <span class="asterisk"> *</span>
                                                                </label>
                                                                <input type="email" class="form-control" ondrop="return false;" placeholder="" [(ngModel)]="email" maxlength="50" (keypress)="commonFunctionService.eMailValidator($event)"
                                                                    oninput="this.value = this.value.toLowerCase();">
                                                            </div>
                                                            <div class="form-group mb-2 position-relative">
                                                                <label for="" class="mb-0">Password
                                                                    <span class="asterisk"> *</span>
                                                                </label>
                                                                <input type="password" class="form-control" ondrop="return false;" placeholder="" [(ngModel)]="new_password" maxlength="16"
                                                                    [type]=" showPassword2 ? 'text' : 'password' ">
                                                                <span class="lock_sh" (click)='showHidePassword2()' *ngIf="showPassword2==true">
                                                                    <i class="fa fa-eye-slash fa-w-20"></i>
                                                                </span>
                                                                <span class="lock_sh" (click)='showHidePassword2()' *ngIf="showPassword2==false">
                                                                    <i class="fa fa-eye fa-w-20"></i>
                                                                </span>
                                                            </div>
                                                            <div class="form-group mb-1">
                                                                <p class="font8"> Password should have minimum length 8 with at least one special
                                                                    character, one digit, one case sensitive
                                                                </p>
                                                            </div>


                                                            <div class="form-group mb-2 mt-1">
                                                                <button class="sign-in" type="button" (click)="sendOtp()"> Continue </button>
                                                            </div>
                                                            <div class="form-group mb-1">
                                                                <p class="font7">By Signing up you agree to our
                                                                    <a href="javascript:void(0)">Privacy Policy
                                                                    </a> and
                                                                    <a href="javascript:void(0)">Terms </a> of use </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                        <div class=" d-flex align-items-center justify-content-center" *ngIf="otppop">
                                            <div class="form-text px-lg-2 py-5">
                                                <div class="otp">
                                                    <h5 class="text-center mb-0 mt-2">Verification Code</h5>
                                                    <div class="px-lg-3 p-2">
                                                        <div class="card p-lg-3 p-3 m-3">
                                                            <p class="text-center font8 mb-3 font600">Please type the Verification code sent to {{email}} </p>
                                                            <p class="text-center">OTP :-{{otp}} </p>
                                                            <!-- <p class="text-center my-2">
                                                                    <span class="text-secondary">Didn't receive the OTP?</span>
                                                                    <b class="px-2">
                                                                        <a href="javascript:void(0)" class="text-decoration-none" *ngIf="!isTimeout">{{ timeLeft | formatTime}}</a>
                                                                    </b>
                                                                    <a href="javascript:void(0);" class="text-success" *ngIf="isTimeout" (click)="sendOtpForForgot()" >Resend OTP</a>
                                                                </p> -->
                                                            <div class="row mb-4 verify">
                                                                <div class="col-lg-3 col-3">
                                                                    <input type="text" class="form-control" placeholder="" maxlength="1" [(ngModel)]="otp1" (keypress)="commonFunctionService.numericCheck($event)"
                                                                        id='ist' (keyup)="clickEvent($event,'sec')" autocomplete="off"
                                                                        required autofocus>
                                                                </div>
                                                                <div class="col-lg-3 col-3">
                                                                    <input type="text" class="form-control" placeholder="" maxlength="1" [(ngModel)]="otp2" (keypress)="commonFunctionService.numericCheck($event)"
                                                                        id='sec' (keyup)="clickEvent($event,'third')" autocomplete="off"
                                                                        required autofocus>
                                                                </div>
                                                                <div class="col-lg-3 col-3">
                                                                    <input type="text" class="form-control" placeholder="" maxlength="1" [(ngModel)]="otp3" (keypress)="commonFunctionService.numericCheck($event)"
                                                                        id='third' (keyup)="clickEvent($event,'fourth')" autocomplete="off"
                                                                        required autofocus>
                                                                </div>
                                                                <div class="col-lg-3 col-3">
                                                                    <input type="text" class="form-control" placeholder="" maxlength="1" [(ngModel)]="otp4" (keypress)="commonFunctionService.numericCheck($event)"
                                                                        id='fourth' (keyup)="clickEvent($event,'')" autocomplete="off"
                                                                        required autofocus>
                                                                </div>

                                                            </div>



                                                            <div class="form-group mb-2 mt-1">
                                                                <button class="sign-in" (click)="submit()"> Submit </button>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>