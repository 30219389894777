import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-card-list',
  templateUrl: './business-card-list.component.html',
  styleUrls: ['./business-card-list.component.css']
})
export class BusinessCardListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
