import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit ,Input, ViewChild , ElementRef} from '@angular/core';
import { ImageViewerModule } from 'ngx-image-viewer';
import {FormService} from '../_services/form.service';
import { AppSettings } from '../_services/AppSetting'
import { ToastrService } from 'ngx-toastr';
declare var $ :any;
@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.css']
})
export class FileAttachmentComponent implements OnInit {
  SuccessMasg: any;
  SuccessMasgLoaded: Promise<boolean>;  
  ErrorMasgLoaded: Promise<boolean>;
  path_url:any=[];
  path_url2: any = [];
  startPath = AppSettings.getImagepath;
  flag:any;
  viewlist:any[]= [];
  attachment_type:any;
  document_name:any;
  filePath:any;
  filename:any;
  @ViewChild('myPhoto') myInputVariable: ElementRef;
  photoUpload:any[]=[]; 
  public formData: FormData = new FormData(); 
  @Input() metaData:any[] = [];
  @Input() rowid: number=0;
  @Input() rowData:any[]=[];
  @Input() documentTypeList: any[]=[];
  @Input() form_id:any;
  @Input() id: string="";
  @Input() viewmode: boolean=false;
  finalMetaDataJson:any[]=[];
  public isLoading = false;

  imageBasePath:any=AppSettings.getImagepath;
  attachmentTypeCumFileName: any="";
  DocumentViewLoaded:Promise<boolean>;
  filepath: any="";
  fileExt: any="";
  imagepath:any[]=[];

  constructor(
    private formService : FormService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.isLoading=false;    
    this.getAllAttachmentList();
  }
  Fullscreen(id)
    {
      let filesec="#"+id+"filesec";
      let smallscreen="#"+id+"smallscreen";
      $(smallscreen).hide();
      $(filesec).removeClass('col-sm-4');
      $(filesec).removeClass('width40n');
      $(filesec).addClass('width100n');
      $('.expendfilen').show();
      $('.expendfile').hide();
    }

    minscreen(id){
      let filesec="#"+id+"filesec";
      let smallscreen="#"+id+"smallscreen";
      $(smallscreen).show();
      $(smallscreen).removeClass('col-sm-12');
       $(smallscreen).addClass('width60n');
       $(filesec).removeClass('col-sm-4');
       $(filesec).addClass('width40n');
       $(filesec).removeClass('width100n');
      $('.expendfilen').hide();
      $('.expendfile').show();
    }
  
  hidefile(id){  
    let filesec="#"+id+"filesec";
    let smallscreen="#"+id+"smallscreen";  
    $(filesec).hide();
    $(smallscreen).removeClass("col-sm-12");
    $(smallscreen).addClass("width100n");
    $(smallscreen).show();
    $(".expendfilen ").hide();
    $(".expendfile").show();
    $(smallscreen).removeClass("width60n");
    $(smallscreen).removeClass("divToDisable");
    $(filesec).removeClass("col-sm-4");
    $(filesec).removeClass("width40n");
  }
  viewfile(filename,filepath,ids,id){
    this.imagepath=[];
    this.DocumentViewLoaded = Promise.resolve(false);
    let filesec="#"+id+"filesec";
    let smallscreen="#"+id+"smallscreen";
    $(filesec).show();
    $(smallscreen).addClass("divToDisable");
    $(smallscreen).removeClass("col-sm-12");
    $(smallscreen).removeClass("width100n");
    $(smallscreen).addClass("width60n");
    $(filesec).addClass("width40n");
    $(filesec).removeClass("col-sm-4");
    let currentViewList=this.viewlist.filter(d=>d.id == ids)
    if(currentViewList.length>0){
      this.attachmentTypeCumFileName=currentViewList[0]["attachment_desc"];
      let arr=currentViewList[0]["file_name"].split(".");
      this.fileExt=arr[arr.length-1];
      this.filepath=currentViewList[0]["file_path"]+"/"+currentViewList[0]["file_name"];      
      this.imagepath.push(this.imageBasePath+currentViewList[0]["file_path"]+"/"+currentViewList[0]["file_name"])
      if(this.fileExt =="pdf"){
        this.flag = false;
      }else{
        this.flag = true;
      }
      this.DocumentViewLoaded = Promise.resolve(true);      
    }
  }

  getAllAttachmentList(){
    this.formService.getFormData("select id,attachment_type,attachment_desc,file_name,file_path from app_form_attachemnts where form_id="+this.form_id+" and record_id="+this.rowid).subscribe(res=>{
      this.viewlist= res.rows; 
      for(let i = 0 ; i <this.viewlist.length ; i++ ){
        let ext = this.viewlist[i]['file_name'].split(".")
        let ext1 = ext[ext.length-1]
        if(ext.length>0){
        this.viewlist[i]["ext"]=ext1; 
        } 
      }    
      //console.log("viewlist==>",this.viewlist)   
    });
  }

  onUploadDocument(event){
    this.photoUpload=[];
    const { files, validity } = event.target;   
    if(files[0].name.toString().toLowerCase().indexOf(".pdf")!=-1 || files[0].name.toString().toLowerCase().indexOf(".doc")!=-1 || files[0].name.toString().toLowerCase().indexOf(".docx")!=-1 || files[0].name.toString().toLowerCase().indexOf(".xlx") !=-1 || files[0].name.toString().toLowerCase().indexOf(".jpeg")!=-1 || files[0].name.toString().toLowerCase().indexOf(".jpg")!=-1 || files[0].name.toString().toLowerCase().indexOf(".png")!=-1)
    {
    if (validity.valid) {
    if(files.length>0){
        this.photoUpload.push(files[0])
      }
    }else{      
      this.toastrService.warning("Only Pdf /Doc/Excel/ Image File Allowed")      
      this.myInputVariable.nativeElement.value="";
    }
  }
  }
  
  addattachment(){  
    this.isLoading=true;  
    if(this.attachment_type == null || this.attachment_type == undefined || this.attachment_type == ""){
      this.toastrService.warning("Document Type is required.")      
      setTimeout(() => {this.isLoading=false},400);
      return false;
    }
    if(this.document_name == null || this.document_name == undefined || this.document_name == ""){
      this.toastrService.warning("Document Name is required.")     
      setTimeout(() => {this.isLoading=false},400);
      return false;
    }
    if(this.photoUpload.length == 0){
      this.toastrService.warning("File is required.")     
      setTimeout(() => {this.isLoading=false},400);
      return false;     
    }
    
    this.delete()
    this.formData = new FormData();
    let filename=this.attachment_type+"_"+this.form_id+"_"+this.rowid;
    this.formData.append("fileid",filename);
    this.formData.append("recordid",this.rowid.toString());
    this.formData.append("files",this.photoUpload[0])

    this.finalMetaDataJson=[];  
    this.formService.uploadFormData(this.formData).subscribe(res=>{      
      this.filePath=res['filePath'];
      this.filename=res['filename'];
      var insertquery = 'insert into app_form_attachemnts(form_id , record_id ,attachment_type  , file_name , file_path, attachment_desc , form_data , process_id , created_by ) values ('+this.form_id+","+this.rowid+",'"+this.attachment_type+"','"+this.filename+"','"+this.filePath+"','"+this.document_name+"','"+JSON.stringify(this.finalMetaDataJson)+"',null,1) RETURNING id";
      this.formService.getFormData(insertquery).subscribe(res=>{
        let pKey=res.rows[0]["id"];
        this.toastrService.success("Save successfully.")  
        let extAr=(this.filename).split(".");
        let ext="";
        if(extAr.length>1){
          ext=extAr[extAr.length-1];
        }  
        this.viewlist.push({"id":pKey,"attachment_type":this.attachment_type, "attachment_desc":this.document_name,"file_name":this.filename,"file_path":this.filePath,"ext":ext})
        setTimeout(() => {
          this.isLoading=false;
          this.myInputVariable.nativeElement.value = "";  
          this.attachment_type=undefined;
          this.document_name=""; 
        },400);  
         
      });
      
    })
    setTimeout(() => {this.isLoading=false;}, 3000);
  }

  downloadfile(filename, filepath, id) {
    this.path_url2.push(this.startPath + "/" + filepath + "/" + filename + '#toolbar=1&navpanes=0')
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = this.path_url2;
    a.target="_blank";
    a.download = filename;
    a.click();
    document.body.removeChild(a);
  }
  
  delete(){
    this.formData.delete("fileid");
    this.formData.delete("recordid");
    this.formData.delete("files");
  }

  deleteFile(id){
    this.formService.getFormData("delete from app_form_attachemnts where id="+id).subscribe(res=>{
      this.toastrService.success("Deleted Sucessfully.")
      this.viewlist=this.viewlist.filter(d =>d.id !=id)
    });
  }

 
}

