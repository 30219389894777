<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <!-- <div class="col-sm-12 p-0"> -->
    <!-- <app-breadcrumb [FormName]="formName" [ModuleName]="modulename"></app-breadcrumb> -->
    <!-- </div> -->
    <ngx-loading [show]="!FormViewLoaded"></ngx-loading>
    <div class="container-fluid ">
      <div class="col-sm-12 p-0">
        <div class="col-sm-12 nopadding">
          <div class="col-sm-12 p-0">


            <div class="card-body nopadding" *ngIf="FormViewLoaded | async">
              <div class="modal" id="exampleModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                  <div class="modal-content" id="example-content">
                    <div class="modal-header p-2">
                      <h6 class="modal-title">Add New
                        <label style="color:red" class="font9 erroredit" *ngIf="ErrorMasgLoaded | async">Error occurred !!</label>
                      </h6>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="row">
                
                  <div class="col-sm-12 p-0">
                    <div class="tabs row my-2 mx-0">
                      <div class="tab-button-outer1 col-sm px-0 mb-sm-0 mb-2">
                        <ul id="tab-button">
                          <li class="is-active" id="{{formId}}">
                            <a href="javascript:void(0)" class="font9" (click)="getDetails(formId)">{{formName}}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-3 col-sm-4 col pl-0 pr-md-2 pr-1" id="masterSearch">
                        <div class=" input-group">
                          <input type="search" class="searchbar form-control newform-control" placeholder="Search in Page" #SearchValue />
                          <a href="javascript:void(0)" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearch(SearchValue.value)">
                            <i class="fa fa-search"></i>
                          </a>
                        </div>
                      </div>
                      <div class="col-auto text-right px-0">
                        <div class="topActionButtons">
                          <div class="btn-group">
                            <button type="button" class="btn btn-secondary btn-circle ml-1 btn-circle-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fa fa-share"></i>
                            </button>
                            <div class="dropdown-menu cstmdop">
                              <a class="dropdown-item px-1" href="javascript:;" (click)="exportCSVHandler()">Export as CSV</a>
                              <a class="dropdown-item px-1" href="javascript:;" (click)="exportExcelHandler()">Export as Excel</a>
                              <!-- <a class="dropdown-item px-1" href="javascript:;">Export as PDF</a> -->
                              <div class="dropdown-item">
                                <span class="font8 px-1">Share link with</span>
                                <div class="d-flex">
                                  <input type="search" class="shareAddress form-control font8" placeholder="Type email ID" />

                                  <button class="btn btn-sm btn-circle btn-secondary btn-circle-sm btn-roll">
                                    <i class="fa fa-long-arrow-right"></i>
                                  </button>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-auto text-right px-0">
                        <button type="button" *ngIf="isAdd" class="btn btn-sm btn-circle btn-circle-sm  btn-dark toolbar-item ml-1" (click)="gotoFormDesignPage(formId,form_viewid)">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>

                    


                    </div>
                  </div>


                  <div class="col-sm-12 col-md-12 nopadding add-carporate-page">
                    <div class="card shadow-none">
                      <div class="card-body p-0">
                        <div class="grid__wrapper tableMain p-0">
                          <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination"></app-hds-main-tab-group>
                          <igx-grid class="formDetailHds" #displayGrid [data]="data" width="100%" height="580px" [autoGenerate]="false" [allowFiltering]="true"
                            displayDensity="cosy" [filterMode]="'excelStyleFilter'" [(perPage)]="page.size" (onDataPreLoad)="actionstrip.hide()"
                            (onRowSelectionChange)="handleRowSelection($event)">
                            <igx-column field="" header="Sr. No." width="100" [filterable]="false">
                              <ng-template #compositeTemp igxCell let-cell="cell">
                                <div class="address-container w-100 text-center">
                                  {{cell.row.index + 1}}
                                </div>
                              </ng-template>
                            </igx-column>
                            <ng-container *ngFor="let col of columnDefs let i=index">

                              <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'" [minWidth]='"200px"'>
                                <ng-template igxCell let-cell="cell">
                                  <a class="truncate" href="javascript:void(0)" (click)="ShowViewData(cell)">
                                    <span class="cell-vl">
                                      {{cell.value}}</span>
                                  </a>
                                </ng-template>
                              </igx-column>
                              <igx-column [field]="col.field" [header]="col.headerName" *ngIf="col.headerName != 'Action' && i !=0" dataType="string" sortable="col.sortable"></igx-column>

                            </ng-container>
                            <igx-action-strip #actionstrip>
                              <div class="drop-down-wrapper">
                                <button [igxToggleAction]="menu" [overlaySettings]="OverlaySetting" [igxToggleOutlet]="outlet" [igxDropDownItemNavigation]="menu"
                                  igxButton="icon">
                                  <igx-icon>more_vert</igx-icon>
                                </button>
                                <igx-drop-down #menu [allowItemsFocus]="true">
                                  <ng-container *ngFor="let item of columnActionStrip">
                                    <igx-drop-down-item (click)="onClickActionStrip(actionstrip.context,item.field)">
                                      <div>
                                        <span>
                                          <i class="fa fa-{{ item.iconName }}"></i>
                                          {{ item.field }}
                                        </span>
                                      </div>
                                    </igx-drop-down-item>
                                  </ng-container>
                                </igx-drop-down>
                                <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
                              </div>
                            </igx-action-strip>
                          </igx-grid>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="modal" id="editModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div id="edit-content" class="modal-content">
      <div class="modal-body minhyt40 pt-1 pb-0" *ngIf="EditLoaded | async">
        <label class="font9 successedit2 alert alert-success pt-1 pb-1" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
        <label class="font9 erroredit2 alert alert-danger pt-1 pb-1" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
        <ul class="nav nav-tabs">
          <li class="active" *ngIf="edit_option == 1 || edit_option == 2">
            <a data-toggle="tab" class="active" href="javascript:void(0);" (click)="tabChanged($event,formId)">{{formName}}</a>
          </li>
          <li *ngFor="let lst of childFormList">
            <a *ngIf="edit_option == 1 || edit_option == 3" [class]="isAddMode?'disabled':''" id="tabchildedit{{lst.form_id}}" data-toggle="tab"
              href="javascript:void(0);" (click)="tabChanged($event,lst.form_id)">{{lst.form_name}}</a>
          </li>
          <li *ngIf="is_attachment == true">
            <a [class]="isAddMode?'disabled':''" data-toggle="tab" href="javascript:void(0)" (click)="tabChanged($event,formd)">Attachments</a>
          </li>
          <li class="pull-right clsicn">
            <button type="button" class="close" aria-label="Close" (click)="closeModel();">
              <span aria-hidden="true">&times;</span>
            </button>
          </li>

        </ul>
        <div class="tab-content tab-contents pl-0 pr-0  pt-0  ">
          <div id="tabedit{{formId}}" [class]="selectedIndex ==formId?'tab-pane fade in active show':'tab-pane fade'" *ngIf="edit_option == 1 || edit_option == 2">
            <div class="col-sm-12 p-0">
              <hdsbpm-form-repeat [lstsection]="lstConfigJson" *ngIf="isDynamicGridForm != true" [SessionUrlPrams]="sessionUrlPrams" [tableName]="tableName"
                [tablePrimaryKey]="tablePrimaryKey" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode"
                [stageid]="stageid" [processid]="processid" [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)"
                (submit)="submit($event)"></hdsbpm-form-repeat>
              <hdsbpm-formview [section]="formAllField" *ngIf="isDynamicGridForm == true"></hdsbpm-formview>
            </div>
          </div>
          <ng-container *ngIf="isAddMode == false && (tabclieked | async)">
            <div *ngFor="let lst of childFormList; index as i" id="tabedit{{lst.form_id}}" [class]="selectedIndex ==lst.form_id?'tab-pane fade in active show':'tab-pane fade'">
              <div class="col-sm-12 p-0" *ngIf="selectedIndex ==lst?.form_id">
                <hdsbpm-grid-form-crud [lstsection]="lst" *ngIf="(edit_option == 1 || edit_option == 3) && isDynamicGridForm != true" [SessionUrlPrams]="sessionUrlPrams"
                  [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form-crud>
                <hdsbpm-grid [lstsection]="lst" *ngIf="(edit_option == 1 || edit_option == 3) && isDynamicGridForm == true " [SessionUrlPrams]="sessionUrlPrams"
                  [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid>
              </div>
            </div>
            <div id="tabeditAttachment{{formd}}" [class]="selectedIndex ==formd?'tab-pane fade in active show':'tab-pane fade'">
              <div class="col-sm-12 p-0">
                <app-file-attachment id="edit" [rowid]="refKey" [viewmode]="isDynamicGridForm" [metaData]="metaDataList" [form_id]="formId"
                  [documentTypeList]="attachmentType"></app-file-attachment>
              </div>
            </div>
          </ng-container>
        </div>
        <ngx-loading [show]="tabshowloader"></ngx-loading>
      </div>

    </div>
  </div>
</div>