<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="sidebar-wrapper">
        <!-- <app-left-menu></app-left-menu> -->
    </div>
    <div class="" id="page-content-wrapper">

        <!-- <app-header> </app-header> -->
<div class="container-fluid py-0">
<div class="row">
    <div class="col-lg-3 col-md-4 col-sm-12 leftbg vh-100 d-none d-lg-block">
        <h3 class="login100-form-title pb-3 pt-5 text-left px-3 text-white" style="line-height: 1.5;">
        Change Password 
        </h3>
        <!-- <h6 class="px-3 pb-5 text-white">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</h6> -->

        <div class="image-reset mt-5">
        <img src="../../../assets/images/g10.svg" class="img-fluid">
        </div>
    </div>
        <div class="col-lg-9 m-auto">
            <div class="row justify-content-center">
                <div class="col-lg-9">
               <button type="button" routerLink="/dashboardnew" class="mt-3 btn btn-danger rounded-pill px-4 font8"> <i class="fa fa-arrow-left"></i> Back</button>
            <div class="card shadow-none " style=" border: none;">
                <div class="card-body p-3">
                    <div class="col-sm-12 font8 pb-4 text-center" style="color: red;">{{err_msg}}</div>
                    <div class="form-group row pt-2 m-0">
                        <label for="inputPassword" class="col-sm-3 col-form-label n font8 ">Current Password
                            <span style="color: red;"> *</span>
                        </label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control h-2em" maxlength="15" id="inputPassword" placeholder="Current Password" [(ngModel)]="oldPassword">
                        </div>
                    </div>
                    <div class="form-group row pt-2 m-0">
                        <label for="inputPassword" class="col-sm-3 col-form-label n font8 ">New Password
                            <span style="color: red;"> *</span>
                        </label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control h-2em" maxlength="15" id="inputPassword" placeholder="New Password" [(ngModel)]="newPassword">
                        </div>
                    </div>
                    <div class="form-group row pt-2 m-0">
                        <label for="inputPassword" class="col-sm-3 col-form-label n font8 ">Confirm Password
                            <span style="color: red;"> *</span>
                        </label>
                        <div class="col-sm-8 pb-2">
                            <input type="password" class="form-control h-2em" maxlength="15" id="inputPassword" placeholder="Confirm Password" [(ngModel)]="confirmPassword">
                        </div>
                        
                    </div>

                  

                    <div class="col-sm-12 text-center py-1 mt-3" >
                        <button type="button" id="changePasswordPopup" class="btn btn-theme rounded-pill px-4 font8" (click)="ChangePassword()">Change Password</button>
                    </div>
                    <div class="row m-0 mt-3">
                        <div class="col-sm-12 font7 alert alert-warning py-2">
                            <h6><label class="font8"><b>Note : Password should meet the following criteria</b> </label></h6>
                            <ol type="1" class="px-2" style="font-size: 12px">
                                <li>Number of character should be between 8 to 16</li>
                                <li>Atleast one special character</li>
                                <li>Atleast one numeric character</li>
                                <li>Atleast one upper case and one lower case character</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
    </div>
</div>