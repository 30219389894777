import { AfterViewInit, Component, OnInit,ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { FormService } from '../../_services/form.service';
import { LoginService } from '../../_services/login.service'
import { Router } from '@angular/router';
import { SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../_services/product.service';
//import { SectionConfig,SessionUrlPrams,ConfigPrams } from "../../field.interface";
import { IgxGridComponent,DisplayDensity,IgxCsvExporterService,IgxExcelExporterService, IgxGridRowComponent, Transaction , IgxStringFilteringOperand,ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy,GridPagingMode,  IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { Subscription } from 'rxjs';
import { Page } from '../../_models/page';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {DialogLayoutDisplay,ConfirmBoxInitializer} from '@costlydeveloper/ngx-awesome-popup'; 
import * as Chart from 'chart.js';
// import { Color } from 'ag-grid-community';
declare var $: any;
@Component({
  selector: 'app-dashboardnew', 
  templateUrl: './dashboardnew.component.html',
  styleUrls: ['./dashboardnew.component.css']
})
export class DashboardnewComponent implements OnInit {

  workbench:any;
  @ViewChild("grid1", { read: IgxGridComponent, static: true })
  public grid1: IgxGridComponent;
  selectedAndVisibleRows = []; 
  visibleRows; 
  subscription: Subscription;
  labelObj: any;
  isHindi: Boolean;
  isListCalendarStatus: Boolean = false;
  page = new Page();
  LoadPagination: boolean=false;
  buyerCount : any;
  //====companyData============
  totalSellerCount: any[] = [];
  totalProductCount: any[] = [];
  totalProductPendingQCCount : any;
  totalsellerPendingCount : any [] = [];
  soldProductsPrice: any = '';
  allActiveUser: any = '';
  allLiveProducts: any = '';
  orderMonthwise: any = [];
  month: any[] = [];
  ordersAmount: any[] = [];
  totalSoldProductsCount: any;
  // submitColumnDefsCompSummary : any [] = [];
  // activeCompanyData : any [] = [];
  // activeCompanyColumnDefSummary : any [] = [];
  // newCompanyCount:any = 0
  // newOderCount = 0
  // activeCompanyCount = 0;
  // isCompanyList :any = 10;

  // verifiedcompanyData : any [] = [];
  // verifiedCompanyColumnDefSummary : any [] = [];
  // verifiedCompanyCount = 0




  constructor(
    private formService: FormService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private router: Router,    private csvExportService: IgxCsvExporterService,
    private excelExportService: IgxExcelExporterService,private productService:ProductService
  ) { }
 
  ngOnInit(): void {
    this.orderAmountsMonthwise()
    this.getBuyerCount();
    this.getTotalSeller();
    this.getTotalProduct();
    this.getTotalProductPendingQC();
    this.getTotalSellerPending();

    $("#sidebar-wrapper").show();
    $("#logoemblm").removeClass('nodisplay');   
    $(".page-footer").removeClass("footrr");
    $(".page-footer").show();
 
//    const canvas1 = <HTMLCanvasElement> document.getElementById('chart-bars');
//    const ctx = canvas1.getContext('2d');

// new Chart(ctx, {
//  type: "bar",
//  data: {
//    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//    datasets: [{
//      label: "Sales",
//      //tension: 0.4,
//      borderWidth: 0,
//      pointRadius: 0,
//      backgroundColor: "#fff",
//      data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
//      maxBarThickness: 2
//    }, ],
//  },
//  options: {
//    responsive: true,
//    maintainAspectRatio: false,
//    legend: {
//      display: false,
//    },
//    tooltips: {
//      enabled: true,
//      mode: "index",
//      intersect: false,
//    },
//    scales: {
//      yAxes: [{
//        gridLines: {
//          display: false,
//        },
//        ticks: {
//          suggestedMin: 0,
//          suggestedMax: 500,
//          beginAtZero: true,
//          padding: 0,
//          fontSize: 14,
//          lineHeight: 3,
//          fontColor: "#fff",
//          fontStyle: 'normal',
//          fontFamily: "Open Sans",
//        },
//      }, ],
//      xAxes: [{
//        gridLines: {
//          display: false,
//        },
//        ticks: {
//          display: false,
//          padding: 20,
//        },
//      }, ],
//    },
//  },
// });



this.getAllSoldProducts()
this.getTotalActiveUser()
this.getTotalLiveProducts()
this.totalSoldProducts()
this.orderAmountsMonthwise()
  }

getTotalSeller(){
  this.productService.totalSellerCount().subscribe(res => {
      this.totalSellerCount = res.rows[0].count;
      
     })
}
getTotalProduct(){
  this.productService.totalProductlistCount().subscribe(res => {
      this.totalProductCount = res.rows[0].count;
    })
}
getTotalProductPendingQC(){
  let qcStatus = 172;
  this.productService.getAllProductsListcount(qcStatus, '').subscribe(res => {
    this.getDraftCount(res.rows[0].count)
      // this.totalProductPendingQCCount = res.rows[0].count;
    })
}
getTotalSellerPending(){
  let sellerStatus = 180;
  this.productService.pendingSellerCount(sellerStatus, '').subscribe(res => {
      this.totalsellerPendingCount = res.rows[0].count;
    })
}

getBuyerCount(){
  this.productService.buyerCount('').subscribe(res => {
    this.buyerCount = res.rows[0].count;
  })
  
}

getDraftCount(pending){
  this.productService.getDraftProductsCounts(localStorage.getItem('userId'), 171, '' ).subscribe(res =>{
    this.totalProductPendingQCCount = +pending + Number(res.rows[0].count);
  })
}
 

  bindData(ind){
      if(ind == 1){
        let data ="frmid=383&vid=354&accesstoken=U2FsdGVkX1%2Bh65G6050rfCL7hTqTRqqcEXkexRN4B8U%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/seller-list?'+encriptUrl);
      }
      else if(ind == 2){
        let data ="frmid=384&vid=355&accesstoken=U2FsdGVkX198uKk%2BPyIs6LwSZbVML0kN5b5IAcAuzjs%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/product-listing?'+encriptUrl);
      }
      else if(ind == 3){
        let data ="frmid=383&vid=354&accesstoken=U2FsdGVkX18SMuCvAW%2BPG2mVHgPkrHe1ag0To7LEuck%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/total-seller?'+encriptUrl);
      }
      else if(ind == 4){
        let data ="frmid=384&vid=355&accesstoken=U2FsdGVkX198uKk%2BPyIs6LwSZbVML0kN5b5IAcAuzjs%3D"       
        let encriptUrl = this.loginService.encriptDataUrl(data)
        this.router.navigateByUrl('/buyer-list?'+encriptUrl);
      }
      // else if(ind == 5){
      //   let data ="frmid=384&vid=355&accesstoken=U2FsdGVkX198uKk%2BPyIs6LwSZbVML0kN5b5IAcAuzjs%3D"       
      //   let encriptUrl = this.loginService.encriptDataUrl(data)
      //   this.router.navigateByUrl('/manage-all-orders?'+encriptUrl);
      // }
  }

 getAllSoldProducts(){
   this.productService.getAllSoldProducts().subscribe(res =>{
     this.soldProductsPrice = res.rows[0].sum;
     //this.router.navigateByUrl('/manage-all-orders');
   })

 }
 getTotalActiveUser(){
   this.productService.getTotalActiveUser().subscribe(res =>{
     this.allActiveUser = +res[0].rows[0].count + +res[1].rows[0].count
   })
 }

 getTotalLiveProducts(){
   this.productService.totalLiveProducts().subscribe(res =>{
    //  console.log('check for toal live products', res)
     this.allLiveProducts = res.rows[0].count
   })
 }

 async orderAmountsMonthwise(){
   
const canvas = <HTMLCanvasElement> document.getElementById('chart-line');
const ctx2 = canvas.getContext('2d');
var gradientStroke1 = ctx2.createLinearGradient(0, 230, 0, 50);

gradientStroke1.addColorStop(1, 'rgba(255,164,73,0.5)');
gradientStroke1.addColorStop(0.2, 'rgba(72,72,176,0.0)');
gradientStroke1.addColorStop(0, 'rgba(255,164,73,0)'); //purple colors

var gradientStroke2 = ctx2.createLinearGradient(0, 230, 0, 50);

gradientStroke2.addColorStop(1, 'rgba(240,2,23,0.2)');
gradientStroke2.addColorStop(0.2, 'rgba(72,72,176,0.0)');
gradientStroke2.addColorStop(0, 'rgba(240,2,23,0)'); //purple colors
  let array = []
  let i =0
  await  this.productService.orderAmountsMonthwise().subscribe(res =>{
      //console.log('check for orderamount with month', res)
      this.orderMonthwise = res.rows;
         this.month =  this.orderMonthwise.map(x => x.month_name )
         //console.log("onth", this.month)
        this.ordersAmount = this.orderMonthwise.map(x => {
          new Chart(ctx2, {
            type: "line",
            data: {
              labels: [...this.month],
              datasets: [{
                  //label: "Mobile apps",
                  //tension: 0.4,
                  borderWidth: 0,
                  pointRadius: 0,
                 // borderColor: "#FF9F3C",
                  //borderWidth: 3,
                  backgroundColor: gradientStroke1,
                  data: [...this.ordersAmount],
                  maxBarThickness: 6
           
                }
                           ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
                mode: "index",
                intersect: false,
              },
              scales: {
                yAxes: [{
                  gridLines: {
                    borderDash: [2],
                    borderDashOffset: 2,
                    color: '#dee2e6',
                    zeroLineColor: '#dee2e6',
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [2],
                    drawBorder: false,
                  },
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 500,
                    beginAtZero: true,
                    padding: 10,
                    fontSize: 11,
                    fontColor: '#adb5bd',
                    lineHeight: 3,
                    fontStyle: 'normal',
                    fontFamily: "Open Sans",
                  },
                }, ],
                xAxes: [{
                  gridLines: {
                    zeroLineColor: 'rgba(0,0,0,0)',
                    display: false,
                  },
                  ticks: {
                    padding: 10,
                    fontSize: 11,
                    fontColor: '#adb5bd',
                    lineHeight: 3,
                    fontStyle: 'normal',
                    fontFamily: "Open Sans",
                  },
                }, ],
              },
            },
           });
           
             return x.total 
         } )
        // console.log("amont", this.ordersAmount)
        // console.log('only outhsoide', this.ordersAmount)

    
         
    })

 }

 totalSoldProducts(){
   this.productService.totalSoldProducts().subscribe(res =>{
    //  console.log('check totalsoldproduct', res)
     this.totalSoldProductsCount = res.rows[0].count
   })
 }

 ngAfterViewChecked() {
  $(".toast-close-button").click(function () {
    $(".overlay-container").removeClass("addcontainerdisable");

  })
  $(".toast-container").click(function () {
    $(".overlay-container").removeClass("addcontainerdisable");
  })

}

}
