<div class="d-flex minhyt96vh" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>

    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb p-0">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Users List</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="col-sm-12 mb-2 p-0">
        <div class="tabs row my-2 mx-0 align-items-center">
          <!-- <ul class="breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Pictures</a></li>
                  </ul> -->

          <div class="tab-button-outer1 col-sm col-9 px-0 mb-sm-0 mb-2">
            <ul id="tab-button" class="tbsbtns">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font9">Users List </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-3 px-sm-3 px-0 order-sm-1 order-2" id="masterSearch">
            <div class=" input-group">
              <input type="text" name="Searchdraft" class="searchbar form-control newform-control" onpaste="return false" autocomplete="new-pws"
                (keyup)="CommonGridSearchMaster(SearchValue.value)" placeholder="Search in Page" #SearchValue id="searchvaluemaster"
              />
              <a href="javascript:void(0)" id="Searchdraft" class="btn  px-2" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
          <div class="col-sm-auto text-right px-0 col-3 order-sm-2 order-1">
            <button type="button" class="btn btn-sm btn-circle btn-circle-sm btn-theme toolbar-item ml-1" (click)="addUser()">
              <i   class="fa fa-plus"></i>
            </button>
          </div>
          <!-- <div class="col-sm-auto text-right px-0 col-3 order-sm-2 order-1">
            <button type="button" data-toggle="modal" data-target="#login-modal" class="btn btn-sm btn-circle btn-circle-sm btn-theme toolbar-item ml-1">
              <i class="fa fa-plus"></i>
            </button>
          </div> -->
        </div>
        <div id="tab01" class="tab-contents mmt5 p-0">
          <div class="col-sm-12 col-md-12  nopadding">
            <div id="grid-theme-wrapper">
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoading"></ngx-loading>
              <!--  -->
              <div class="table-responsive" *ngIf="buyerList.length > 0">
                <igx-grid #grid1 [data]="buyerList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">

                  <!-- <igx-column field="varibale_name" header="Status" width="100">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column> -->
                  <igx-column field="" header="Sr. No." width="80" [filterable]="false">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container w-100 text-center">
                        {{cell.row.index + 1}}
                      </div>
                    </ng-template>
                  </igx-column> 
                  <igx-column field="full_name" header="User Details" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="enterprise_name" header="Enterprise Name" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }} </span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="mobile_no" header="Mobile No" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl ">{{ cell.value }}</span>
                      </div>
                    </ng-template>
                  </igx-column>
                  
                  <igx-column field="email" header="Email" width="200">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="text-lowercase cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="corporate_type" header="User Type" width="120">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value== 'Enterprise' ? 'Corporate' :'Retail' "></span>
                       
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="subscription" header="Subscription" width="120">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value ? cell.value : 'free'"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="url" header="Specific URL" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="text-lowercase cell-vl" [innerHtml]="cell.value == null || cell.value == 'null' ? 'No' : cell.value "></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="payment_term" header="Payment Term" width="120">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value ? cell.value : 'cash'"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="buyer_status" header="Action" width="150">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container px-2">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                            aria-expanded="false">
                            <span class="py-2 px-3">Action</span>
                            <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                              <i class=" fa fa-caret-down" aria-hidden="true"></i>
                            </button>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                            <li *ngIf="cell.value != 195" class="border-bottom">
                              <a href="javascript:void(0)" (click)="blockBuyer(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa-ban mr-1" aria-hidden="true"></i>
                                Block
                              </a>
                            </li>
                            <li *ngIf="cell.value == 195" class="border-bottom">
                              <a href="javascript:void(0)" (click)="unBlockBuyer(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                <i class="fa fa-unlock-alt mr-1" aria-hidden="true"></i> Unblock</a>
                            </li>
                            <li *ngIf="cell.value != 195 && cell.rowData.referance_id != null" class="border-bottom">
                              <a href="javascript:void(0)" (click)="onUserEdit(cell)" class="text-decoration-none font-weight-bold text-success dropdown-item">
                                <i class="fa fa-pencil mr-1" aria-hidden="true"></i>
                                Edit</a>
                            </li>
                            <!-- <li *ngIf="cell.value != 195" class="border-bottom">
                              <a href="javascript:void(0)" (click)="viewDetail(cell)" class="text-decoration-none font-weight-bold text-primary dropdown-item">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                Profile</a>
                            </li> -->
                            <li *ngIf="cell.value != 195" class="border-bottom">
                              <a href="javascript:void(0)" class="text-decoration-none font-weight-bold text-info dropdown-item" (click)="viewAccount(cell)">
                                <img src="../../../assets/images/icon/account-settings-info.svg" alt=""> View Account </a>
                            </li>
                            <li *ngIf="cell.rowData.referance_id == null && cell.value != 195" class="border-bottom">
                              <a href="javascript:void(0)" (click)="openConvertModal(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa-pencil mr-1" aria-hidden="true"></i>
                                Make Enterprise account</a>
                            </li>
                            <li *ngIf="cell.rowData.referance_id != null && cell.value != 195" class="border-bottom">
                              <a href="javascript:void(0)" (click)="updatePayementReceived(cell)"  class="text-decoration-none font-weight-bold text-dark dropdown-item">
                                <img src="../../../assets/images/icon/pyment.svg" alt=""> 
                                  Update Payment Received  </a>
                            </li>
                            <li *ngIf="cell.value != 195" class="border-bottom">
                              <a href="javascript:void(0)" (click)="allBuyerOrders(cell)" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa-first-order mr-1" aria-hidden="true"></i>
                                Order History </a>
                            </li>
                            <li *ngIf="cell.value != 195" >
                              <a href="javascript:void(0)" (click)="changePassword(cell)" class="text-decoration-none font-weight-bold text-dark dropdown-item">
                                <i class="fa fa-unlock mr-1" aria-hidden="true"></i>
                                Change Password </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- <div class="address-container" >
                        <span>
                          <button class="btn btn-sm btn-info rounded-pill px-3" disabled>
                           Rating & Review
                          </button>
                        </span>
                      </div> -->
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
              <label class="w-100 text-center text-dark" *ngIf="!isLoading && buyerList.length == 0">No Record Found</label>
            </div>
          </div>
        </div>
      </div>


      <!--Buyer Details Modal starts-->

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header align-items-center p-2">
              <h6 class="modal-title" id="exampleModalLabel">Buyer Detail</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body pb-4 px-3" data-spy="scroll" id="scroll">
              <div class="row m-0">
                <div class="col-sm-4 brd-l">
                  <div class="card border-0 shadow-none">
                    <div class="row mb-3">
                      <div class="col-sm-12">
                        <h5 class="fs-title">Personal Details</h5>
                      </div>
                    </div>
                    <div class="row pb-3">
                      <div class="col-5 d-flex justify-content-between">
                        <label class="font-weight-bold" for="">
                          Buyer Name
                        </label>
                        <label>
                          :
                        </label>
                      </div>
                      <div class="col-7">
                        <label for=""> {{customerName}} </label>
                      </div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="row py-3">
                      <div class="col-5 d-flex justify-content-between">
                        <label class="font-weight-bold" for="">
                          Mobile Number
                        </label>
                        <label>
                          :
                        </label>
                      </div>
                      <div class="col-7">
                        <label for=""> {{customerMobile}} </label>
                      </div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="row pt-3">
                      <div class="col-5 d-flex justify-content-between">
                        <label class="font-weight-bold" for="">Email
                        </label>
                        <label>
                          :
                        </label>
                      </div>
                      <div class="col-7">
                        <label for=""> {{customerEmail}} </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8">
                  <div class="card border-0 shadow-none">
                    <div class="row m-0">
                      <div class="col-sm-12 mb-3">
                        <h5 class="fs-title pl-0 ml-0">Address Details</h5>
                      </div>
                    </div>
                    <div class="row m-0" *ngFor="let address of buyerAddress; index as i">
                      <div class="col-sm-12">
                        <p>
                          <i class="fa fa-map-marker pr-2"></i> {{address?.locatity}}, {{address?.area_street}}, {{address?.city}}, {{address?.state_name}},
                          {{address?.pin_code}} </p>
                        <hr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Buyer Details Modal ends-->

      <div  class="modal fade" id="myModal">                           
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header p-2">
              <button type="button" class="close" data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>

            <!-- Modal body -->
            <div  class="modal-body pb-2">
              <div class="col-sm-12 col-lg-12">
                <!-- <i
                class="fa fa-exclamation-triangle"
                style="font-size: 3em; color: #ffa64b"
              ></i> -->
                <div  class="row">
                  <div class="col-sm-12">
                    <label>Remarks
                      <!-- <span class="asterisk"> * </span> -->
                    </label>
                  </div>
                  <div class="col-sm-12">
                    <textarea name="remarks" id="remarks" [(ngModel)]="remarks" placeholder="Enter remarks here...." class="form-control2" maxlength="500"></textarea>

                  </div>
                </div>
                <!-- <h4 class="pt-3 mb-0">Are you sure?</h4> -->
                <!-- <p class="font9">Confirm to proceed</p> -->
              </div>
              <div class="col-sm-12 text-center pt-2">
                <button type="button" *ngIf="blocked" pButton (click)="onBlock()" label="Block" class="btn btn-danger btn-sm px-3 mr-2">
                  Block
                </button>
                <button type="button" *ngIf="!blocked" pButton (click)="onUnblock()" label="Unblock" class="btn btn-gray btn-sm me-2 px-3">
                  Unblock
                </button>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="modal fade p-0" id="login-modal" tabindex="-1" aria-labelledby="login-modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered justify-content-center modal-md">
        <app-user-login></app-user-login>
      </div>
    </div>


  </div>
  <!-- Modal -->


  <div id="convertModal" class="modal fade p-0" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header align-items-center py-2">
          <h6 class="font600 mb-0">Convert Corporate</h6>
          <button type="button" class="close" data-dismiss="modal" >
            <span> &times; </span>
          </button>
        </div>
        <div class="modal-body minhyt40">
          <div class="row mx-0 mb-2">
            <div class="col-md-6 mb-3">
              <div class="row">
                <div class="col-md-4 text-right">
                  <label for="" class="font8">Enterprises ID 
                    <span class="asterisk">*</span>
                  </label>
                </div>
                <div class="col-md-8">
                  <input type="text"  class="form-control mb-2" disabled  autocomplete="off" placeholder=""
                    [(ngModel)]="enterprise_id">
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="row">
                  <div class="col-md-4 text-right">
                    <label for="" class="font8">Enterprises Name
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input type="text"  class="form-control mb-2"   autocomplete="off" placeholder=""
                      maxlength="50" [(ngModel)]="enterprise_name" (keypress)="commonFunctionService.alphaNumericWithSpecialSymbolValidator($event)">
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <div class="row">
                  <div class="col-md-2 text-right">
                    <label for="" class="font8">Payment Terms
                      <span class="asterisk">*</span>
                    </label>
                  </div>
                  <div class="col-md-3 mb-2">
                          <div class="row">
                            <div class="col-md-6 col-6">
                              <input class="form-check-input align-top custom1-chkboxradio mx-0" type="radio" name="payment_term" id="cash" value="cash" [(ngModel)]="payment_term" (change)="choosePaymentTerm()">
                                        <label class="form-check-label" for="cash">Cash</label>
                            </div>
                            <div class="col-md-6 col-6">
                              <input class="form-check-input align-top custom1-chkboxradio mx-0" type="radio" name="payment_term" id="credit" value="credit" [(ngModel)]="payment_term" (change)="choosePaymentTerm()">
                              <label class="form-check-label" for="credit">Credit</label>
                            </div>

                          </div>
                  </div>
                </div>
              </div>
              <div class="col-lg" *ngIf="is_payment">
                <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="col-md-4 text-right">
                        <label for="" class="font8">Credit Period
                          <span class="asterisk">*</span>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control select" [(ngModel)]="credit_id">
                          <option value="undefined" disabled >-: Select Credit Period :-</option>
                          <option [value]="type.id" *ngFor="let type of sysEnumList; index as i">{{type.varibale_name}}</option>                                                                                            
                          </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="col-md-4 text-right">
                        <label for="" class="font8">Credit Limit
                          <span class="asterisk">*</span>
                        </label>
                      </div>
                      <div class="col-md-8">
                        <input type="text"  class="form-control mb-2"   autocomplete="off" placeholder=""
                        [(ngModel)]="credit_limt" onkeypress="return /[0-9]/i.test(event.key)" maxlength="20" >
                      </div>
                    </div>
                  </div>
                  </div>

                  <div class="row w-100 m-0 mb-3">
                    <div class="col-lg-2 mb-lg-0 mb-md-2 mb-0 text-right">
                        <label for="">Subscription
                            <span class="asterisk">*</span>
                        </label>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <div class="form-check form-check-inline my-md-0 my-1">
                            <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="free" value="free" [(ngModel)]="subscription">
                            <label class="form-check-label" for="free">Free</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="gold" value="gold" [(ngModel)]="subscription">
                            <label class="form-check-label" for="gold">Gold</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <div class="form-check form-check-inline my-md-0 my-1">
                            <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="diamond" value="diamond" [(ngModel)]="subscription"> 
                            <label class="form-check-label" for="diamond">Diamond</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="platinum" value="platinum" [(ngModel)]="subscription">
                            <label class="form-check-label" for="platinum">Platinum</label>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mb-2 w-100">
                  <!-- <div class="col-md-4 mb-2">
                      <label class="" for="">Contact Person
                          <span class="asterisk">*</span>
                      </label>
                      <input type="text" class="form-control" [(ngModel)]="contact_person" (keypress)="commonFunctionService.alphabetsValidator($event)" maxlength="50">
                  </div> -->
                  <!-- <div class="col-md-4 mb-2">
                      <label class="" for="">Contact Number
                          <span class="asterisk">*</span>
                      </label>
                      <input type="text" class="form-control"   maxlength="10" autocomplete="off">
                  </div> -->
                  <div class="col-md-4 mb-2">
                      <label class="" for="">Concern Salesperson Name
                          <span class="asterisk">*</span>
                      </label>
                      <input type="text" class="form-control" [(ngModel)]="concern_saleperson" (keypress)="commonFunctionService.alphabetsValidator($event)" maxlength="50" autocomplete="off">
                  </div>
                  <!-- <div class="col-md-4 mb-2">
                      <label class="" for="">Email Address
                          <span class="asterisk">*</span>
                      </label>
                      <input type="email" class="form-control"   maxlength="100">
                  </div> -->
                  <div class="col-md-4 mb-2">
                      <label class="" for="">Concern Salesperson Email
                          <span class="asterisk">*</span>
                      </label>
                      <input type="email" class="form-control" [(ngModel)]="concern_saleperson_email" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100">
                  </div>
                  <div class="col-md-4 mb-2">
                      <label class="" for="">Concern Salesperson Contact No.
                          <span class="asterisk">*</span>
                      </label>
                      <input type="text" class="form-control" [(ngModel)]="concern_saleperson_contact" (keypress)="commonFunctionService.numericCheck($event)"  maxlength="10" autocomplete="off">
                  </div>
                  <!-- <div class="col-md-4 mb-2" >
                      <label class="" for="">Password
                          <span class="asterisk">*</span>
                      </label>
                      <input type="text" class="form-control"  maxlength="20" autocomplete="off">
                  </div> -->
              </div>

              <div class="row m-0 w-100">
                <div class="col-sm-6 mb-sm-0 mb-3">
                    <h6 class="font-weight-bold">Billing Details</h6>
                    <hr class="mb-2">
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Company Name
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="comany_name" (keypress)="commonFunctionService.alphaNumericWithSpecialSymbolValidator($event)" maxlength="100" autocomplete="off">
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">GSTIN
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="gst_In" style="text-transform: uppercase" maxlength="15" autocomplete="off">
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Email ID
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="email_id" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100">
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Phone Number
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="phone_number" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10" autocomplete="off"> 
                        <span class="verified">
                            <!-- <i clafss="fa fa-check-circle-o" aria-hidden="true"></i> -->
                            <!-- <img class="mr-1 d-flex align-items-center" src="../../../assets/images/icon/verified.svg"> VERIFIED -->
                        </span>
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Address
                            <span class="asterisk">*</span>
                        </label>
                        <textarea type="text" class="form-control2" [(ngModel)]="billing_address" maxlength="500"></textarea>
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <div class="row">
                            <div class="col-6 mb-2">
                                <label for="">Country
                                    <span class="asterisk">*</span>
                                </label>
                                <select name="country_id" id="country_id" class="form-control mb-2" [(ngModel)]="country_id">
                                    <option value="undefined" disabled>-: Select Country :-</option>
                                    <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                        {{con.country_name}}
                                    </option>
                                </select>
                                <!-- <select class="form-control">
                                    <option value="" selected disabled>India</option>
                                </select> -->
                            </div>
                            <div class="col-sm-6 mb-2">
                                <label for="">Postal/ZIP Code
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="billing_zipcode" maxlength="6" (keyup)="getBCityAndState()" (keypress)="commonFunctionService.numericCheck($event)">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <label for="">City
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="billing_city" maxlength="50" disabled>
                            </div>
                            <div class="col-6 mb-2">
                                <label for="">Province/Territory/State
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control mb-2" id="state_id" name="state_id" [(ngModel)]="state_id" disabled
                    >
                    <option value="undefined">-: Select Province/Territory/State :-</option>
                    <option *ngFor="let s of stateList" value="{{s.state_id}}">
                        {{s.state_name}}</option>
                </select>
                                <!-- <select class="form-control">
                                    <option value="" selected disabled>Haryana</option>
                                </select> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12 p-0 mb-2">
                        <div class="form-check form-check-inline my-md-0 my-1">
                            <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="default_billing_address" value="option1" [(ngModel)]="default_billing_address"> 
                            <label class="form-check-label ml-2" for="default_billing_address">Save as default billing address?</label>
                        </div>
                    </div> -->
                </div>
                <div class="col-sm-6">
                    <h6 class="font-weight-bold">Shipping Details</h6>
                    <hr class="mb-2">
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Contact Person
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="shipping_contact_person" (keypress)="commonFunctionService.alphabetsValidator($event)" maxlength="100" autocomplete="off">
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Email ID
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="shipping_emailid" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100">
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Phone Number
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" class="form-control" [(ngModel)]="shipping_phone_number" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10" autocomplete="off">
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <label for="">Address
                            <span class="asterisk">*</span>
                        </label>
                        <textarea type="text" class="form-control2" [(ngModel)]="shipping_adress" maxlength="500"></textarea>
                    </div>
                    <div class="col-sm-12 p-0 mb-0">
                        <div class="row">
                            <div class="col-6 mb-0">
                                <label for="">Country
                                    <span class="asterisk">*</span>
                                </label>
                                <select name="country_id" id="country_id" class="form-control mb-2" [(ngModel)]="country_id_ship">
                                    <option value="undefined" disabled>-: Select Country :-</option>
                                    <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                        {{con.country_name}}
                                    </option>
                                </select>
                                <!-- <select class="form-control">
                                    <option value="" selected disabled>India</option>
                                </select> -->
                            </div>
                            <div class="col-sm-6 mb-0">
                                <label for="">Postal/ZIP Code
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="shipping_zipcode" maxlength="6" (keyup)="getSCityAndState()" (keypress)="commonFunctionService.numericCheck($event)">
                            </div>
                            <!-- <div class="col-6 mb-2">
                                <label for="">Province/Territory/State
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control mb-2" id="state_id" name="state_id" [(ngModel)]="state_id_ship"
                    >
                    <option value="undefined">-: Select Province/Territory/State :-</option>
                    <option *ngFor="let s of stateList" value="{{s.state_id}}">
                        {{s.state_name}}</option>
                </select>
                                
                            </div> -->
                        </div>
                    </div>
                    <div class="col-sm-12 p-0 mb-2">
                        <div class="row">
                            <div class="col-sm-6 mb-2">
                                <label for="">City
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="shipping_city" disabled>
                            </div>
                            <div class="col-6 mb-2">
                                <label for="">Province/Territory/State
                                    <span class="asterisk">*</span>
                                </label>
                                <select class="form-control mb-2" id="state_id" name="state_id" [(ngModel)]="state_id_ship" disabled
                    >
                    <option value="undefined">-: Select Province/Territory/State :-</option>
                    <option *ngFor="let s of stateList" value="{{s.state_id}}">
                        {{s.state_name}}</option>
                </select>
                                <!-- <select class="form-control">
                                    <option value="" selected disabled>Haryana</option>
                                </select> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-12 p-0 mb-2">
                        <div class="form-check form-check-inline my-md-0 my-1">
                            <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="default_shipping_address" value="option1" [(ngModel)]="default_shipping_address">
                            <label class="form-check-label ml-2" for="default_shipping_address">Save as default shipping address?</label>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="col-sm-12 text-center mt-2">
                    <button class="btn px-lg-5 px-3 py-1 rounded-pill btn-theme btn-sm shadow" (click)="onSaveCorporateDetails()" *ngIf="!isEdit">Save</button>
                    <button class="btn btn-sm btn-success px-3 mr-2" id="save-btn" (click)="onSaveCorporateDetails()"  *ngIf="isEdit">
                        Update
                    </button>
                </div> -->
            </div>


          </div>
        </div>
        <div class="bottom-button-group text-center pb-3">
          <button type="button" class="btn btn-sm px-3 btn-success mr-1" (click)="saveUserDetails()">Submit</button>
          <button class="btn btn-sm btn-danger px-3" data-dismiss="modal" (click)="reset()">Cancel</button>
        </div>
      </div>
    </div>
  </div>  
  
  <div id="updatePassword" class="modal fade p-0" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header align-items-center py-2">
          <h6 class="font600 mb-0">Change Password</h6>
          <button type="button" class="close" data-dismiss="modal" (click)="resetP()">
            <span> &times; </span>
          </button>
        </div>
        <div class="col-sm-12 text-start p-4">
          <div class="form-group mb-2 position-relative">
          <label for="new_pass">New password<span class="text-danger"> *</span></label>
          <input type="text" class="form-control" id="newPass"  maxlength="30" autocomplete="off"
          
           name="newPass" ondrop="return false;"ondrag="return false;" [(ngModel)]="newPass"  [type]=" showPassword ? 'text' : 'password' " maxlength="16">
           <span class="lock_sh" (click)='showHidePassword()'
               *ngIf="showPassword==true"><i class="fa fa-eye-slash fa-w-20"></i>
              </span>
              <span class="lock_sh" (click)='showHidePassword()'
                  *ngIf="showPassword==false">
                  <i class="fa fa-eye fa-w-20"></i>
              </span>    
                                    
         </div>
         <div class="form-group mb-2 position-relative">
          <label for="cnfrmPass">Confirm password<span class="text-danger"> *</span></label>
          <input type="text" class="form-control" id="cnfrmPass" [type]=" showPassword1 ? 'text' : 'password' "  maxlength="30"
           name="cnfrmPass" ondrop="return false;"ondrag="return false;" [(ngModel)]="cnfrmPass">
           <span class="lock_sh" (click)='showHidePassword1()'
           *ngIf="showPassword1==true"><i class="fa fa-eye-slash fa-w-20"></i>
          </span>
          <span class="lock_sh" (click)='showHidePassword1()'
              *ngIf="showPassword1==false">
              <i class="fa fa-eye fa-w-20"></i>
          </span>    
         </div>
        </div>
        <div class="bottom-button-group text-center pb-3">
          <button type="button" class="btn btn-sm px-3 btn-success mr-1" (click)="updatePassword()">Submit</button>
          <button class="btn btn-sm btn-danger px-3" data-dismiss="modal" (click)="resetP()">Cancel</button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="update_payment">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header px-3 py-2 align-items-center">
          <h6 class="font-weight-bold mb-0">Update Payment Received</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onreset()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-3">
          <div class="row">
            <div class="col-md-6  col-12 mb-2">
              <label>Total Credit Amount (₹) </label>
              <input type="text" [(ngModel)]="total_credit" value="200000" disabled class="form-control">
            </div>
            <div class="col-md-6 col-12 mb-2">
              <label>Total Use Amount (₹) </label>
              <input type="text" [(ngModel)]="credit_used" value="65000" disabled class="form-control">
            </div>
            <div class="col-md-6 col-12 mb-2">
              <label>Remaining Limit Amount (₹) </label>
              <input type="text" [(ngModel)]="credit_remaining" value="10600" disabled class="form-control">
            </div>
            <div class="col-md-6 col-12 mb-2">
              <label>Date <span class="asterisk">*</span></label>
              <input type="date" class="form-control" [(ngModel)]="paymentDate" max="{{getMinDate()}}">
            </div>
            <div class="col-md-6 col-12 mb-2">
              <label>Payment Received Amount (₹) <span class="asterisk">*</span></label>
              <input type="text" class="form-control" [(ngModel)]="receivedAmount" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10">
            </div>
          </div>
          <div class="text-center col-sm-12 mt-3">
            <button class="py-1 btn rounded-pill px-4 btn-danger mr-2" data-dismiss="modal" (click)="onreset()">Cancel</button>
            <button class="py-1 btn rounded-pill px-4 btn-theme " (click)="onSubmitPayment()">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>