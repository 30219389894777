<div class="d-flex minhyt96vh" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid bg-white">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item newitm1">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;">User Master</a>
                            </li>
                            <li class="breadcrumb-item newitm2  active" aria-current="page">Create User</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-sm-12">
                    <div class="row mx-0 my-3">
                        <div class="col-sm-12 pl-0">
                            <div class="bg-lyt py-3 px-2">
                                <h6 class="mb-0 font-weight-bold">Add New User</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-md-12">
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Corporate Type
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline my-md-0 my-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="corporate_type" id="inlineRadio1" value="Retail" [(ngModel)]="corporate_type" (change)="chooseCorpType();chooseRetails()">
                                        <label class="form-check-label" for="inlineRadio1">Retail</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="corporate_type" id="inlineRadio2" value="Enterprise"
                                             [(ngModel)]="corporate_type" (change)="chooseCorpType();chooseRetails()">
                                        <label class="form-check-label" for="inlineRadio2">Enterprise</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center" *ngIf="show">
                                <div class="col-lg-3 col-md-5 col-sm-6 mb-sm-0 mb-1">
                                    <label>Enterprise ID (Auto Generated)
                                        <span class="asterisk">*</span>
                                    </label>
                                    <input type="text" class="form-control" disabled placeholder="IDC0000000001" [(ngModel)]="enterprise_id" >
                                </div>
                                <div class="col-lg-3 col-md-5 col-sm-6 align-self-lg-center align-self-end">
                                    <label>Enterprise Name
                                        <span class="asterisk">*</span>
                                    </label>
                                    <input type="text" class="form-control" [(ngModel)]="enterprise_name" (keypress)="commonFunctionService.alphaNumericWithSpecialSymbolValidator($event)"
                                    maxlength="100">
                                </div>
                            </div>
                            <!-- <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Is there any child
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline my-md-0 my-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="any_child" id="yes_child" value="option1" checked>
                                        <label class="form-check-label" for="yes_child">Yes</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="any_child" id="no_child" value="option2">
                                        <label class="form-check-label" for="no_child">No</label>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                        <div class="col-md-3 col-sm-5 col-8 mb-md-0 mb-4 pl-lg-5">
                            <!-- <label class="border-0 card shadow-none text-center align-items-center cursor-pointer" for="fileupld">
                                <div class="drag-drop">
                                    <input type="file" id="fileupld">
                                    <h6 class="mb-0 font-weight-bold">Upload Corporate Logo</h6>
                                    <label class="small">Size 300 X 200 px</label>
                                    <div class="mt-4">
                                        <figure class="figure text-center mb-0">
                                            <img class="figure-img" src="../../assets/images/icon/upload.png" alt="icon" width="60">
                                        </figure>
                                        <br>
                                        <label class="small">Drag and drop or click to upload</label>
                                    </div>
                                </div>

                            </label> -->

                            <!-- <div class="card shadow-none rounded bg-lyt align-items-center justify-content-center" role="button">
                                <div class="card-body">
                                    <div class="text-center">
                                        <h6 class="mb-0 font-weight-bold">Upload Corporate Logo</h6>
                                        <label for="">Size 300 X 200 px</label>
                                    </div>
                                    <div class="mt-5">
                                        <img src="" alt="">
                                        <label for="">Drag and drop or click to upload</label>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-12">
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2">
                                    <label for="">Any Specific URL
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline my-md-0 my-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="any_url" id="yes_url" value="yes" [(ngModel)]="any_url" (change)="chooseUrl()">
                                        <label class="form-check-label" for="yes_url">Yes</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2 mb-md-0 mb-4">
                                    <div class="form-check form-check-inline mb-md-0 mb-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="any_url" id="no_url" value="no" [(ngModel)]="any_url" (change)="chooseUrl()">
                                        <label class="form-check-label" for="no_url">No</label>
                                    </div>
                                </div>
                                <div class="col-md" *ngIf="is_url">
                                    <input type="text" class="form-control" value=" " [(ngModel)]="specific_url">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-3 col-lg-2 mb-lg-0 mb-md-3">
                                    <label for="">Payment Term
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline my-md-0 my-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="payment_term" id="cash" value="cash" [(ngModel)]="payment_term" (change)="choosePaymentTerm()">
                                        <label class="form-check-label" for="cash">Cash</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2 mb-md-0 mb-4" *ngIf="is_credit">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="payment_term" id="credit" value="credit" [(ngModel)]="payment_term" (change)="choosePaymentTerm()">
                                        <label class="form-check-label" for="credit">Credit</label>
                                    </div>
                                </div>
                                <div class="col-lg" *ngIf="is_payment && is_credit">
                                    <div class="row align-items-center">
                                        <div class="col-sm-auto col-5 mb-sm-0 mb-2">
                                            <label class="mr-2">Credit Period
                                                <span class="asterisk">*</span>
                                            </label>
                                        </div>
                                        <div class="col-sm col-7 mb-sm-0 mb-2">
                                            <select class="form-control select" [(ngModel)]="credit_id">
                                                <option value="undefined" disabled >-: Select Credit Period :-</option>
                                                <option [value]="type.id" *ngFor="let type of sysEnumList; index as i">{{type.varibale_name}}</option>                                               
                                              </select>
                                            <!-- <select type="text" class="form-control">
                                                <option value="" selected disabled>30 Days</option>
                                            </select> -->
                                        </div>
                                        <div class="col-sm-auto col-5">
                                            <label class="mx-2">Credit Limit
                                                <span class="asterisk">*</span>
                                            </label>
                                        </div>
                                        <div class="col-sm col-7">
                                            <input type="text" class="form-control" value="" [(ngModel)]="credit_limt" onkeypress="return /[0-9]/i.test(event.key)" maxlength="20">
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 mb-lg-0 mb-md-2 mb-0">
                                    <label for="">Subscription
                                        <span class="asterisk">*</span>
                                    </label>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline my-md-0 my-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="free" value="free" [(ngModel)]="subscription">
                                        <label class="form-check-label" for="free">Free</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="gold" value="gold" [(ngModel)]="subscription">
                                        <label class="form-check-label" for="gold">Gold</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline my-md-0 my-1">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="diamond" value="diamond" [(ngModel)]="subscription"> 
                                        <label class="form-check-label" for="diamond">Diamond</label>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input custom1-chkboxradio mx-0" type="radio" name="subscription" id="platinum" value="platinum" checked [(ngModel)]="subscription">
                                        <label class="form-check-label" for="platinum">Platinum</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 mb-2">
                        <div class="col-md-4 mb-2">
                            <label class="" for="">Contact Person
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="contact_person" (keypress)="commonFunctionService.alphabetsValidator($event)" maxlength="50">
                        </div>
                        <div class="col-md-4 mb-2">
                            <label class="" for="">Contact Number
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="contact_number" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10" autocomplete="off" [disabled] = 'isEdit'>
                        </div>
                        <div class="col-md-4 mb-2">
                            <label class="" for="">Email Address
                                <span class="asterisk">*</span>
                            </label>
                            <input type="email" class="form-control" [(ngModel)]="email_address" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100" [disabled] = 'isEdit'>
                        </div>
                        <div class="col-md-4 mb-2">
                            <label class="" for="">Concern Salesperson Name
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="concern_saleperson" (keypress)="commonFunctionService.alphabetsValidator($event)" maxlength="50" autocomplete="off">
                        </div>
                        
                        <div class="col-md-4 mb-2">
                            <label class="" for="">Concern Salesperson Email
                                <span class="asterisk">*</span>
                            </label>
                            <input type="email" class="form-control" [(ngModel)]="concern_saleperson_email" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100">
                        </div>
                        <div class="col-md-4 mb-2">
                            <label class="" for="">Concern Salesperson Contact No.
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="concern_saleperson_contact" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10" autocomplete="off">
                        </div>
                        <div class="col-md-4 mb-2" *ngIf="!isEdit">
                            <label class="" for="">Password
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="corp_password" maxlength="20" autocomplete="off">
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-sm-6 mb-sm-0 mb-3">
                            <h6 class="font-weight-bold">Billing Details</h6>
                            <hr class="mb-2">
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Company Name
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="comany_name" (keypress)="commonFunctionService.alphaNumericWithSpecialSymbolValidator($event)" maxlength="100" autocomplete="off">
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">GSTIN
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="gst_In" style="text-transform: uppercase" maxlength="15" autocomplete="off">
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Email ID
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="email_id" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100">
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Phone Number
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="phone_number" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10" autocomplete="off"> 
                                <span class="verified">
                                    <!-- <i clafss="fa fa-check-circle-o" aria-hidden="true"></i> -->
                                    <!-- <img class="mr-1 d-flex align-items-center" src="../../../assets/images/icon/verified.svg"> VERIFIED -->
                                </span>
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Address
                                    <span class="asterisk">*</span>
                                </label>
                                <textarea type="text" class="form-control2" [(ngModel)]="billing_address" maxlength="500"></textarea>
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <div class="row">
                                    <div class="col-6 mb-2">
                                        <label for="">Country
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select name="country_id" id="country_id" class="form-control mb-2" [(ngModel)]="country_id">
                                            <option value="undefined" disabled>-: Select Country :-</option>
                                            <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                                {{con.country_name}}
                                            </option>
                                        </select>
                                        <!-- <select class="form-control">
                                            <option value="" selected disabled>India</option>
                                        </select> -->
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <label for="">Postal/ZIP Code
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" class="form-control" [(ngModel)]="billing_zipcode" maxlength="6" (keyup)="getBCityAndState()" (keypress)="commonFunctionService.numericCheck($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <div class="row">
                                    <div class="col-sm-6 mb-2">
                                        <label for="">City
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" class="form-control" [(ngModel)]="billing_city" maxlength="50" disabled>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <label for="">Province/Territory/State
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select class="form-control mb-2" id="state_id" name="state_id" [(ngModel)]="state_id" disabled
                            >
                            <option value="undefined">-: Select Province/Territory/State :-</option>
                            <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                {{s.state_name}}</option>
                        </select>
                                        <!-- <select class="form-control">
                                            <option value="" selected disabled>Haryana</option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-12 p-0 mb-2">
                                <div class="form-check form-check-inline my-md-0 my-1">
                                    <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="default_billing_address" value="option1" [(ngModel)]="default_billing_address"> 
                                    <label class="form-check-label ml-2" for="default_billing_address">Save as default billing address?</label>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-sm-6">
                            <h6 class="font-weight-bold">Shipping Details</h6>
                            <hr class="mb-2">
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Contact Person
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="shipping_contact_person" (keypress)="commonFunctionService.alphabetsValidator($event)" maxlength="100" autocomplete="off">
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Email ID
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="shipping_emailid" (keypress)="commonFunctionService.eMailValidator($event)" maxlength="100">
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Phone Number
                                    <span class="asterisk">*</span>
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="shipping_phone_number" (keypress)="commonFunctionService.numericCheck($event)" maxlength="10" autocomplete="off">
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <label for="">Address
                                    <span class="asterisk">*</span>
                                </label>
                                <textarea type="text" class="form-control2" [(ngModel)]="shipping_adress" maxlength="500"></textarea>
                            </div>
                            <div class="col-sm-12 p-0 mb-0">
                                <div class="row">
                                    <div class="col-6 mb-0">
                                        <label for="">Country
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select name="country_id" id="country_id" class="form-control mb-2" [(ngModel)]="country_id_ship">
                                            <option value="undefined" disabled>-: Select Country :-</option>
                                            <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                                {{con.country_name}}
                                            </option>
                                        </select>
                                        <!-- <select class="form-control">
                                            <option value="" selected disabled>India</option>
                                        </select> -->
                                    </div>
                                    <div class="col-sm-6 mb-0">
                                        <label for="">Postal/ZIP Code
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" class="form-control" [(ngModel)]="shipping_zipcode" maxlength="6" (keyup)="getSCityAndState()" (keypress)="commonFunctionService.numericCheck($event)">
                                    </div>
                                    <!-- <div class="col-6 mb-2">
                                        <label for="">Province/Territory/State
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select class="form-control mb-2" id="state_id" name="state_id" [(ngModel)]="state_id_ship"
                            >
                            <option value="undefined">-: Select Province/Territory/State :-</option>
                            <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                {{s.state_name}}</option>
                        </select>
                                        
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-sm-12 p-0 mb-2">
                                <div class="row">
                                    <div class="col-sm-6 mb-2">
                                        <label for="">City
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" class="form-control" [(ngModel)]="shipping_city" disabled>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <label for="">Province/Territory/State
                                            <span class="asterisk">*</span>
                                        </label>
                                        <select class="form-control mb-2" id="state_id" name="state_id" [(ngModel)]="state_id_ship" disabled
                            >
                            <option value="undefined">-: Select Province/Territory/State :-</option>
                            <option *ngFor="let s of stateList" value="{{s.state_id}}">
                                {{s.state_name}}</option>
                        </select>
                                        <!-- <select class="form-control">
                                            <option value="" selected disabled>Haryana</option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-12 p-0 mb-2">
                                <div class="form-check form-check-inline my-md-0 my-1">
                                    <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="default_shipping_address" value="option1" [(ngModel)]="default_shipping_address">
                                    <label class="form-check-label ml-2" for="default_shipping_address">Save as default shipping address?</label>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-sm-12 text-center mt-2">
                            <button class="btn btn-sm btn-success mr-2 px-3" (click)="onSaveCorporateDetails()" *ngIf="!isEdit">Save</button>
                            <button class="btn btn-sm btn-success px-3 mr-2" id="save-btn" (click)="onSaveCorporateDetails()"  *ngIf="isEdit">
                                Update
                            </button>
                            <button class="btn btn-sm btn-danger px-3" (click)="onBack()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>