<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    
    <section class="bckgry">
      <div class="container-fluid py-2">
        <div class="row mb-3">
          <div class="col-lg-12 px-3">
            <div class="">
              <div class="">
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2"
                    style="cursor: pointer"
                    (click)="bindData(1)">
                    <div class="card shadow-none pb-2 pb-lg-0 cardbg h-100">
                      <div class="card-body p-2">
                        <div class="">
                          <h5 class="linktitle">
                            <strong>Seller Onboarding</strong>
                          </h5>
                          
                          <div class="row">
                            <div class="col-9">
                              <h4 class="display-4 mb-0">
                                {{ totalsellerPendingCount }}<br />
                              </h4>
                            </div>
                            <div class="col text-right pl-0">
                              <img
                                src="../../../assets/images/icon/onboading.svg"
                                class="img-fluid imgbg"
                              />
                            </div>
                          </div>

                          <div class=""><label class="lblcls font-weight-bold">Pending for approval</label>
                        </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 col-lg-3 my-2"
                    style="cursor: pointer"
                    (click)="bindData(2)">
                    <div class="card shadow-none pb-2 pb-lg-0 cardbg h-100">
                      <div class="card-body p-2">
                        <div class="">
                          <h5 class="linktitle">
                            <strong>Product</strong>
                          </h5>
                          
                          <div class="row">
                            <div class="col-9">
                              <h4 class="display-4 mb-0">
                                {{ totalProductPendingQCCount }}<br />
                              </h4>
                            </div>
                            <div class="col text-right pl-0">
                              <img
                                src="../../../assets/images/icon/product.svg"
                                class="img-fluid imgbg"
                              />
                            </div>
                          </div>

                          <div class=""><label class="lblcls font-weight-bold">Draft/Pending For QC</label>
                        </div>

                        </div>
                      </div>
                    </div>
                  </div>
                     
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2"
                    style="cursor: pointer"
                    (click)="bindData(3)">
                    <div class="card shadow-none pb-2 pb-lg-0 cardbg h-100">
                      <div class="card-body p-2">
                        <div class="">
                          <h5 class=" linktitle">
                            <strong>Total Sellers</strong>
                          </h5>
                          
                          <div class="row">
                            <div class="col-9">
                              <h4 class="display-4 mb-0">
                                {{ totalSellerCount }}<br />
                              </h4>
                            </div>
                            <div class="col text-right pl-0">
                              <img
                                src="../../../assets/images/icon/total_seller.svg"
                                class="img-fluid imgbg"
                              />
                            </div>
                          </div>

                          <div class=""><label class="lblcls font-weight-bold">Approved/Rejected</label>
                        </div>

                        </div>
                      </div>
                    </div>
                  </div>
                   
                  <div class="col-md-6 col-sm-12 col-lg-3 my-2"
                    style="cursor: pointer"
                    (click)="bindData(4)">
                    <div class="card shadow-none pb-2 pb-lg-0 cardbg h-100">
                      <div class="card-body p-2">
                        <div class="">
                          <h5 class="linktitle">
                            <strong>Total Buyers</strong>
                          </h5>
                          
                          <div class="row">
                            <div class="col-8">
                              <h4 class="display-4 mb-0">
                                {{ buyerCount }}<br />
                              </h4>
                            </div>
                            <div class="col text-right pl-0">
                              <img
                                src="../../../assets/images/icon/total_buyer.svg"
                                class="img-fluid imgbg"
                              />
                            </div>
                          </div>

                          <!-- <div class="mt-2"><label class="lblcls font-weight-bold">Approved/Rejected</label> -->
                        </div>

                        </div>
                      </div>
                    </div>
              
                  <!-- <div class="col-md-6 col-sm-12 col-lg-3 my-2" style="cursor: pointer;" (click)="bindData(4)">
                    <div class="card shadow-none pb-2 cardbg">
                      <div class="card-body p-1">
                        <div class="col-sm-12 col-lg-12">
                          <h5 class="pt-2 pb-3 linktitle"><strong>Total Buyers</strong></h5>
                          <div class="rotate mb-2">
                            <img src="../../../assets/images/icon/product.png" class="img-fluid imgbg"
                              style="width: 55px;position: absolute;">
                              <div class="pull-right">
                                <h1 class="display-4 mb-0 text-right"><br/>
                                 {{ (buyerCount) }}
                                </h1>
                                <label class="lblcls font-weight-bold"></label>
                              </div>
                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-3  mt-2">
            <div class="card  cst-hyt">
              <div class="card-body px-3 pt-1 minhy30new">
                <div class="pt-1">
                  <h5 class="linktitle mt-1 mb-3">
                    <img
                      src="../../assets/images/icon/bell.svg"
                    />&nbsp;&nbsp;{{
                      labelObj
                        ? isHindi
                          ? labelObj.notification.labelHn
                          : labelObj.notification.labelEn
                        : "Notification"
                    }}
                  </h5>
                  <div class="">
                    <div class="col-sm-12 alert alert-orange">
                      <label> Company Signup: </label>
                    </div>

                    <div class="col-sm-12 alert alert-orange">
                      <label> Verification Pending Request: </label>
                    </div>
                    <div class="col-sm-12 alert alert-orange">
                      <label> Total company: </label>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <div class="row mb-3">
          <div class="col-lg-6 mb-lg-0 mb-4 prr-1-new">
            <div class="card shadow hyt-400">
              <div class="card-body px-3 pt-2">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="">
                      <h5 class="mb-2">Current Status</h5>
                    </div>
                    <div class="col-sm-6 text-right">
                      <!-- <p class="text-sm">
                        (<span class="font-weight-bolder">+23%</span>) than last
                        week
                      </p> -->
                    </div>
                  </div>
                </div>

                <div class="border-radius-lg activeproducts px-3">
                  <div class="row">
      
                   
                    <div class="col-sm-6 col-md-6 col-lg-6 py-2">
                        <div class="row">
                            <div class="col-12">
                         
                          <p class="text-xs mb-0 w-75 font-weight-bold font9">
                              Users(Seller & Buyer)
                          </p>
                         </div>
                          
                         <div class="col-9 mt-4"> 
                            <h4 class="font-weight-bolder">{{allActiveUser}}</h4>
                          </div>
                          <div class="col-3 text-right">
                              <img src="../../../assets/images/icon/dashboardicon/user-default.svg" alt="" class="src">
                            </div>
                       
                        <div class="col-12 mb-1">
                        <div class="progress w-75">
                          <div
                            class="progress-bar bg-dark w-90" role="progressbar" aria-valuenow="90"
                            aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>
                      </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6 py-2"
                     
                      >
                          <div class="row">
                              <div class="col-12">
                           
                            <p class="text-xs mb-0 w-75 font-weight-bold font9">
                              Sold Products
                            </p>
                           </div>
                            
                           <div class="col-9 mt-4"> 
                              <h4 class="font-weight-bolder">{{totalSoldProductsCount}}</h4>
                            </div>
                            <div class="col-3 text-right">
                                <img src="../../../assets/images/icon/dashboardicon/my-order.svg" alt="" class="src">
                              </div>
                         
                          <div class="col-12 mb-1">
                          <div class="progress w-75">
                            <div
                              class="progress-bar bg-dark w-90" role="progressbar" aria-valuenow="90"
                              aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                          </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 py-2">
                            <div class="row">
                                <div class="col-12">
                             
                              <p class="text-xs mb-0 w-75 font-weight-bold font9">
                                  Sales
                              </p>
                             </div>
                              
                             <div class="col-9 mt-4"> 
                                <h4 class="font-weight-bolder">₹{{soldProductsPrice}}</h4>
                              </div>
                              <div class="col-3 text-right">
                                  <img src="../../../assets/images/icon/dashboardicon/growth.svg" alt="" class="src">
                                </div>
                           
                            <div class="col-12 mb-1">
                            <div class="progress w-75">
                              <div
                                class="progress-bar bg-dark w-90" role="progressbar" aria-valuenow="90"
                                aria-valuemin="0" aria-valuemax="100">
                              </div>
                            </div>
                          </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6 py-2">
                              <div class="row">
                                  <div class="col-12">
                               
                                <p class="text-xs mb-0 w-75 font-weight-bold font9">
                                  Live Products
                                </p>
                               </div>
                                
                               <div class="col-9 mt-4"> 
                                  <h4 class="font-weight-bolder">{{allLiveProducts}}</h4>
                                </div>
                                <div class="col-3 text-right">
                                    <img src="../../../assets/images/icon/dashboardicon/liveproducts.svg" alt="" class="src">
                                  </div>
                             
                              <div class="col-12 mb-1">
                              <div class="progress w-75">
                                <div
                                  class="progress-bar bg-dark w-90" role="progressbar" aria-valuenow="90"
                                  aria-valuemin="0" aria-valuemax="100">
                                </div>
                              </div>
                            </div>
                              </div>
                            </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card shadow">
              <div class="card-header pt-2 bg-white no-border">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="mb-2">
                      <h5>Orders overview</h5>
                    </div>
                    <div class="col-sm-6 text-right">
                      <p class="text-sm mb-0 font9">
                       
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body px-3 pt-0">
                <div class="chart">
                  <div class="chartjs-size-monitor">
                    <div class="chartjs-size-monitor-expand">
                      <div class=""></div>
                    </div>
                    <div class="chartjs-size-monitor-shrink">
                      <div class=""></div>
                    </div>
                  </div>
                  <canvas
                    id="chart-line"
                    class="chart-canvas chartjs-render-monitor"
                    height="300"
                    width="600"
                    style="display: block; width: 600px; height: 300px"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row dashboar_new" >
          
          <div class="col-lg-12">
            <div class="card shadow-none">
              <div class="card-body p-0"> 
            <h6 class="mb-1 p-2 mt-1"><img src="../../../assets/images/icon/task.svg" style="width: 22px;"/>&nbsp;&nbsp; Company Detail</h6>
     
                <div class="grid__wrapper tableMain p-0">
                  <igx-grid #grid1 [data]="companyData" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true"
                    displayDensity="cosy" [filterMode]="'excelStyleFilter'" rowSelection="single">
                    <ng-container *ngFor="let col of columnDefsCompSummary  let i=index">
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                        [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                          {{cell.value}}
                        </ng-template>
                      </igx-column>
                      <igx-column [field]="col.field" [header]="col.headerName"
                        *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                        [filterable]="col.filter"></igx-column>
                    </ng-container>
                  </igx-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" > 
          <div class="col-lg-12">
            <div class="card shadow-none">
              <div class="card-body p-0"> 
            <h6 class="mb-1 p-2 mt-1"><img src="../../../assets/images/icon/task.svg" style="width: 22px;"/>&nbsp;&nbsp; Submitted Detail</h6>
     
                <div class="grid__wrapper tableMain p-0">
                  <igx-grid #grid1 [data]="" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true"
                    displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="5">
                    <ng-container *ngFor="let col of submitColumnDefsCompSummary  let i=index">
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                        [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                          {{cell.value}}
                        </ng-template>
                      </igx-column>
                      <igx-column [field]="col.field" [header]="col.headerName"
                        *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                        [filterable]="col.filter"></igx-column>

                        <igx-column [field]="col.field" [header]="col.headerName"  *ngIf="col.headerName == 'Action'" [dataType]="'string'"
                        [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                            <a (click)="changeStatus(cell.value)" class="btn text-link downloadlink"><button class="btn btn-dark btn-sm rounded-pill px-3 mr-3" type="button">Verified</button></a>
                        </ng-template>
                        
                      </igx-column>
                    </ng-container>
                  </igx-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" > 
          <div class="col-lg-12">
            <div class="card shadow-none">
              <div class="card-body p-0"> 
            <h6 class="mb-1 p-2 mt-1"><img src="../../../assets/images/icon/task.svg" style="width: 22px;"/>&nbsp;&nbsp; Active Company</h6>
     
                <div class="grid__wrapper tableMain p-0">
                  <igx-grid #grid1 [data]="activeCompanyData" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true"
                    displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="5" rowSelection="single">
                    <ng-container *ngFor="let col of activeCompanyColumnDefSummary  let i=index">
                      <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                        [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                        <ng-template igxCell let-cell="cell">
                          {{cell.value}}
                        </ng-template>
                      </igx-column>
                      <igx-column [field]="col.field" [header]="col.headerName"
                        *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                        [filterable]="col.filter"></igx-column>
                        
                    </ng-container>
                  </igx-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" > 
            <div class="col-lg-12">
              <div class="card shadow-none">
                <div class="card-body p-0"> 
              <h6 class="mb-1 p-2 mt-1"><img src="../../../assets/images/icon/task.svg" style="width: 22px;"/>&nbsp;&nbsp; Verified Company</h6>
       
                  <div class="grid__wrapper tableMain p-0">
                    <igx-grid #grid1 [data]="verifiedcompanyData" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true"
                      displayDensity="cosy" [filterMode]="'excelStyleFilter'" [paging]="true" [perPage]="5" rowSelection="single">
                      <ng-container *ngFor="let col of verifiedCompanyColumnDefSummary  let i=index">
                        <igx-column [field]="col.field" [header]="col.headerName" *ngIf="i==0" [dataType]="'string'"
                          [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                          <ng-template igxCell let-cell="cell">
                            {{cell.value}}
                          </ng-template>
                          
                        </igx-column>
                        <igx-column [field]="col.field" [header]="col.headerName"
                          *ngIf="col.headerName != 'Action' && i != 0" dataType="string" [sortable]="col.sortable"
                          [filterable]="col.filter"></igx-column>

                          <igx-column [field]="col.field" [header]="col.headerName"  *ngIf="col.headerName == 'Action'" [dataType]="'string'"
                          [minWidth]="col.width" [sortable]="col.sortable" [filterable]="col.filter">
                          <ng-template igxCell let-cell="cell">
                              <a (click)="gotReportpage(cell.value)" class="btn text-link downloadlink"><i class="fa fa-eye"></i></a>
                          </ng-template>
                          
                        </igx-column>
                      </ng-container>
                    </igx-grid>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
      </div>
    </section>
    
  </div>
</div>
