import { Component, OnInit,ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { FormService } from '../_services/form.service';
import { Router } from '@angular/router';
import { SessionUrlPrams, ConfigPrams } from "../field.interface";
//import { SectionConfig,SessionUrlPrams,ConfigPrams } from "../../field.interface";
import { IgxGridComponent,DisplayDensity,IgxCsvExporterService,IgxExcelExporterService, IgxGridRowComponent, Transaction , IgxStringFilteringOperand,ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy,GridPagingMode,  IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { Subscription } from 'rxjs';
import{LoginService} from 'src/app/_services/login.service'
import { Page } from '../_models/page';


import * as pluginDataLabels from 'chartjs-plugin-datalabels';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent  implements OnInit {

  @ViewChild("grid1", { read: IgxGridComponent, static: true })
  public grid1: IgxGridComponent;
  selectedAndVisibleRows = []; 
  visibleRows; 

  subscription: Subscription;
  labelObj: any;
  isHindi: Boolean;
  isListCalendarStatus: Boolean = false;
  taskList: any[] = [];
  announcementList: any[] = [];
  roleId: Number;
  formId: any=335;
  columnDefs: any[] = [];
  columnActionStrip: any[] = [];
  OverlaySetting: any;//=FormService.OverlaySetting;
  rowData: any[] = [];
  page = new Page();
  data: any[] = [];
  filterCriteria: any = "";
  lookupArr: any = [];
  ddlarrGidView: any = [];
  dateArrGidView: any = [];
  configParms: ConfigPrams;
  public isLoading = true;
  public totalCount = 0;
  //public mode = GridPagingMode.remote;

  tableName: any;
  tablePrimaryKey: any
  gridFilter: any = "";
  view_filter: any;
  currentSelectedRow: any;
  isResetFilter: Boolean = false;
  viewFilterString: any;

  inboxCount: any = "0";
  outboxCount: any = "0";
  draftCount: any = "0";
  submittedCount: any = "0";
  approvedCount: any = "0";
  LoadPagination: boolean=false;
  public lineChartData: ChartDataSets[] = [
    { data: [ 65, 59, 80, 65, 45, 55, 76], label: 'Approved', fill: false },
    { data: [ 5, 50, 75, 87, 43, 46, 95], label: 'Rejected', fill: false },
    { data: [ 20, 70, 10, 95, 65, 70, 40], label: 'In Process', fill: false },
  ];
  public lineChartLabels: Label[] = [];// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public lineChartOptions: (ChartOptions & { annotation?: any }) = {
    responsive: true,
    maintainAspectRatio: false,     
  };
  public lineChartLegend = true;
  public lineChartType = 'bar';
  public lineChartPlugins = [pluginDataLabels];
  public lineChartColors: Color[] = [
    { // greyrgba(172, 115, 15, 1)
      backgroundColor: 'rgba(0,128,0,0.8)',
      borderColor: 'rgba(0,128,0,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // red10,128,0,1
      backgroundColor: 'rgba(255,0,0,0.8)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(172, 115, 15, 0.8)',
      borderColor: 'rgba(0,0,255,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }

  ];
  sessionUrlPrams: SessionUrlPrams;
  SchemeCategory: any[] = [];

  inboxColumndef: any[]=[];
  inboxRows:any[]=[];
  InboxLoaded:Promise<boolean>;
  constructor(
    private formService: FormService,private loginService :LoginService,
    private router: Router,    private csvExportService: IgxCsvExporterService,
    private excelExportService: IgxExcelExporterService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.OverlaySetting={
      positionStrategy: new ConnectedPositioningStrategy({
          horizontalDirection: HorizontalAlignment.Left,
          horizontalStartPoint: HorizontalAlignment.Right,
          verticalStartPoint: VerticalAlignment.Bottom,
          
      }),
      scrollStrategy: new NoOpScrollStrategy()
    };
   }
  public pieChartOptions: ChartOptions = {
    title: {
      text: 'Estimated Cost Breakup',
      display: true
    },
    responsive: true,
  };
  public schemeCategoreyOptions: ChartOptions = {
    title: {
      text: 'Scheme Category',
      display: true
    },
    responsive: true,
  };
  public fundingCategoreyOptions: ChartOptions = {
    title: {
      text: 'Funding Category',
      display: true
    },
    responsive: true,
  };
  public pieChartColorsOne: Array < any > = [{
    backgroundColor: ['#FFC0CB', '#ce8f10', '#FFA500','#00FFFF','#800080'],
    borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
 }];
 public pieChartColorsTwo: Array < any > = [{
  backgroundColor: ['green', 'black', 'rgba(148,159,177,0.2)','#FF00FF','#808000','#FFA500','#00FFFF'],
  borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
}];
public pieChartColorsThree: Array < any > = [{
  backgroundColor: ['red', '#ADD8E6', '#008000','#0000FF','#FFFF00','#00FF00','#FF00FF','#808000','#342D7E'],
  borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
}];
  public pieChartLabels: Label[] = [['0-100'], ['100-200'], ['200-300'],['300-400'],['400-500'],['>500']];
  public pieChartData: SingleDataSet = [200, 100, 80,60,70,50];
  public pieChartLabelsTwo: Label[] = [['Article 275(i)'], ['Centrally Spo'], ['Centeral'],['EMRS'],['N.E.C'],['NESIDS'],['NLCPR'],['RIDF'],['SCA To TSS'],['State']];
  public pieChartDataTwo: SingleDataSet = [90, 60, 80,60,70,50,70,50,80,75];
  public pieChartLabelsThree: Label[] = [['Vehicle Purchase'], ['Construction'], ['Capital Expenditure'],['Machinery'],['Scholarship'],['Subsidy'],['Creation of Posts']];
  public pieChartDataThree: SingleDataSet = [90, 60, 80,60,70,50,70];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  ngOnInit(): void {
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.tableName = "vw_scheme_requests";
    this.tablePrimaryKey = "id";
    $("#sidebar-wrapper").show();
    $("app-footer").show();
    // $("#sidebar-wrapper").addClass( 'nodisplay');
    $("#logoemblm").removeClass('nodisplay');
    //  let query="select concat(user_fname,' ' ,user_mname, ' ',user_lname) as fullname from app_tbluser";
    //  this.formService.getFormData(query).subscribe(res=>{
    //    console.log(JSON.stringify(res.rows))
    //  });
    this.sessionUrlPrams = {
      frmid: this.formId,
      vid: 0,
      UserCode: "UserCode",
      UserName: localStorage.getItem("userFullName"),
      ProfileId: localStorage.getItem("profileId"),
      Location: localStorage.getItem("office"),
      department: localStorage.getItem("department"),
      processtype: "ext",
    };
    console.log("PKS set profile id after role change==>",this.sessionUrlPrams);
    this.getAllSchemeCategory();
    this.getInboxOutboxCount();
    this.getFormViewDetails();
    this.getDraftList();
  }

  getAllSchemeCategory() {
    this.lineChartLabels = [];
    let query = `select id, short_name  from app_tblsystemenum where isactive=true and varibale_type='SchemeCategory'`
    this.formService.getFormData(query).subscribe(res => {
      this.SchemeCategory = res.rows;
      // this.lineChartLabels.push('')
      for (let c = 0; c < this.SchemeCategory.length; c++) {
        this.lineChartLabels.push(this.SchemeCategory[c]["short_name"])
      }
    });
  }

  showcalendarlist() {
    this.isListCalendarStatus = true;
    var element = document.getElementById("listbtn1");
    element.classList.add("active");
    var element2 = document.getElementById("calenderbtn");
    element2.classList.remove("active");
  }
  showcalendar() {
    this.isListCalendarStatus = false;
    var element1 = document.getElementById("calenderbtn");
    element1.classList.add("active");
    var element3 = document.getElementById("listbtn1");
    element3.classList.remove("active");
  }
  gotoNextPage(val) {
    if (val == "parked") {
      localStorage.setItem("linkname","parked")
      this.router.navigateByUrl('/parked?'+ localStorage.getItem('tempToken'));
    } else if (val == "pending") {
      localStorage.setItem("linkname","inbox")
      this.router.navigateByUrl('/inbox?'+ localStorage.getItem('tempToken'));
    } else if (val == "inprocess") {
      // localStorage.setItem("linkname","parked")
      this.router.navigateByUrl('/submitted?'+ localStorage.getItem('tempToken'));
    } else if (val == "outbox") {
      localStorage.setItem("linkname","outbox")
      this.router.navigateByUrl('/outbox?'+ localStorage.getItem('tempToken'));
    }else if (val == "approved") {
      localStorage.setItem("linkname","approved")
      this.router.navigateByUrl('/approved?'+ localStorage.getItem('tempToken'));
    }
  }

  getInboxOutboxCount() {
    
    let inboxCountQuery = `select w.worklistid from radx_worklist w  inner join radx_draft_requests d on w.processinstanceid=d.processinstid
      where  w.actiontaken='N' and w.destinationid=${this.sessionUrlPrams.ProfileId} order by w.sendtime desc`;

    let draftCountQuery = `select dr.draft_requestid
              from radx_draft_requests dr where dr.status_id=108 and dr.createdby=${this.sessionUrlPrams.ProfileId}`

    let outboxCountQuery = `select w.worklistid
                        from radx_worklist w  inner join radx_draft_requests d on w.processinstanceid=d.processinstid where  w.sourceid =${this.sessionUrlPrams.ProfileId} order by w.sendtime desc`;

    let submittedCountQuery = `select dr.draft_requestid 
              from radx_draft_requests dr where dr.status_id=107 and dr.createdby=${this.sessionUrlPrams.ProfileId}  order by dr.createddate desc`;

    let approvedCountQuery = `select dr.draft_requestid 
              from radx_draft_requests dr where dr.status_id=105 and dr.createdby=${this.sessionUrlPrams.ProfileId}  order by dr.createddate desc`;


    let finalQuery = inboxCountQuery + " ; " + draftCountQuery + " ; " + outboxCountQuery + " ; " + submittedCountQuery + ";" + approvedCountQuery + ";";

    this.formService.getFormData(finalQuery).subscribe(res => {
      this.inboxCount = res[0].rows.length;
      this.draftCount = res[1].rows.length;
      this.outboxCount = res[2].rows.length;
      this.submittedCount = res[3].rows.length;
      this.approvedCount=res[4].rows.length;
    })
  }
  getFormViewDetails() {
    this.columnDefs = [];
    this.columnActionStrip = [];
    this.subscription = this.formService.getFormViewById(307).subscribe(res => {
      let formViewList = res.rows;
      let a = 0;
      if (formViewList.length > 0) {
        // this.isAdd =formViewList[0]["is_add"]?formViewList[0]["is_add"]:false;
        // this.isEdit =formViewList[0]["is_edit"]?formViewList[0]["is_edit"]:false;
        // this.isView =formViewList[0]["is_view"]?formViewList[0]["is_view"]:false;
        // this.isExpotable =formViewList[0]["is_exportable"]?formViewList[0]["is_exportable"]:false;

        let fromViewjson = formViewList[0]["field_json"] ? formViewList[0]["field_json"] : [];
        let childFormId = formViewList[0]["child_form_ids"] ? formViewList[0]["child_form_ids"] : "";
        // this.edit_option=formViewList[0]["edit_option"];
        // this.metaDataList=formViewList[0]["meta_data"]?JSON.parse(formViewList[0]["meta_data"]):[];
        // this.attachmentType=formViewList[0]["attachment_type"]?JSON.parse(formViewList[0]["attachment_type"]):[];
        // this.is_attachment=formViewList[0]["is_attachment"]?formViewList[0]["is_attachment"]:false;                 

        //this.view_filter=this.formService.getConditionQueryByJson(formViewList[0]["filter_json"],this.sessionUrlPrams) 
        let view_type = formViewList[0]["view_type"] ? formViewList[0]["view_type"] : 1;
        let data_for_only = formViewList[0]["data_for_only"] ? formViewList[0]["data_for_only"] : "all";
        let child_form_view_json = formViewList[0]["child_form_view_ids"] ? JSON.parse(formViewList[0]["child_form_view_ids"]) : [];
        //this.form_validation=formViewList[0]["validation"]?JSON.parse(formViewList[0]["validation"]):[]

        if (child_form_view_json.length > 0) {
          child_form_view_json = child_form_view_json.sort((a, b) => a.orderno < b.orderno ? -1 : 1)
          childFormId = "";
          for (let r = 0; r < child_form_view_json.length; r++) {
            childFormId = childFormId + child_form_view_json[r]["formid"] + ",";
          }
          childFormId = childFormId.substring(0, childFormId.length - 1)
        }
        if (data_for_only != "all" && this.filterCriteria != "") {
          this.filterCriteria = this.filterCriteria + " and a.createdby=" + this.sessionUrlPrams.ProfileId; //this.SessionUrlPrams[data_for_only]
        }

        if (fromViewjson.length > 0) {
          let selectedFieldList = JSON.parse(fromViewjson)
          var column = '';
          //console.log("fromViewjson===>",fromViewjson,view_type);
          selectedFieldList.forEach(fieldprop => {
            if (fieldprop.control_type == 5 || fieldprop.control_type == 10) {
              if (view_type == 1) {
                this.ddlarrGidView.push({ "index": a, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3", "control_type": fieldprop.control_type })
              } else {
                this.ddlarrGidView.push({ "index": a, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "dynamiclist": JSON.parse(fieldprop.dynamiclist), "type": "3", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": fieldprop.display_field, "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            }
            else if (fieldprop.control_type == 7 || fieldprop.control_type == 8) {
              if (view_type == 1) {
                this.dateArrGidView.push({ "index": a, "columnname": fieldprop.field_name, "type": "1" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "4", "control_type": fieldprop.control_type })
              } else {
                this.dateArrGidView.push({ "index": a, "columnname": fieldprop.field_name, "type": "1" })
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "4", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": fieldprop.display_field, "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            }
            else if (fieldprop.control_type == 14 || fieldprop.control_type == 15 || fieldprop.control_type == 19 || fieldprop.control_type == 22) {
              if (view_type == 1) {
                let displayField = JSON.parse(fieldprop.display_field)
                this.lookupArr.push({
                  "index": a, "view_type": view_type, "columnname": fieldprop.field_name,
                  "reference_form_id": fieldprop.reference_form_id, "value_field": fieldprop.value_field,
                  "display_field": displayField[0]["id"], "field_name": fieldprop.field_name, "type": "2", "control_type": fieldprop.control_type
                })
              } else {
                let displayField = JSON.parse(fieldprop.display_field)
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "2", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": displayField[0]["id"], "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            } else {
              if (view_type == 1) {
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "1", "control_type": fieldprop.control_type })
              } else {
                this.lookupArr.push({ "index": a, "view_type": view_type, "columnname": fieldprop.field_name, "type": "1", "level": fieldprop.level, "from_table_name": fieldprop.from_table_name, "to_table_name": fieldprop.to_table_name, "display_field": fieldprop.display_field, "value_field": fieldprop.value_field, "parent_field_name": fieldprop.parent_field_name, "control_type": fieldprop.control_type })
              }
            }
            a++;
            this.columnDefs.push({ field: fieldprop.field_name.toString().toLowerCase(), headerName: fieldprop.field_caption, sortable: fieldprop.sortable, filter: fieldprop.filterable, resizable: fieldprop.resizable, width: fieldprop.column_width, wrapText: true, autoHeight: true });
          });
          //this.columnActionStrip.push({field: "Edit", iconName: "edit"});
          this.bindNgxDataGrid(this.page, 0)
        }
      }
    });
  }
  LoadGrid(event) {
    this.configParms = event;
    this.isLoading = true;
    //console.log("config==>",event,this.config);
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)

  }

  bindNgxDataGrid(pageInfo,status){  
    let total,pagesize,limit,offset=0;
    pagesize=pageInfo.size;
    if(pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status ==0){
      offset=0;
    }else{
      offset=((pageInfo.pageNumber-1)*pagesize);
    }
    this.data=[];
    this.formService.getNgxGridData(this.lookupArr,this.tableName,this.tablePrimaryKey,this.filterCriteria,this.view_filter,pagesize,offset,this.gridFilter,'').subscribe(res => {
      this.page.totalElements=res[0].rows[0]["total"];
      this.data=res[1].rows;
      this.totalCount = res[0].rows[0]["total"];
      let currPage=pageInfo.pageNumber;
      if(currPage == 0){currPage=1;}
      this.configParms={currentPage:currPage,totalItems:this.totalCount,itemsPerPage:pagesize} 
      this.LoadPagination=true;
      this.isLoading = false;      
    },err=>{
      //this.toastrService.error(err.error.message)    
    });
  }
  handleRowSelection(e){
    this.currentSelectedRow=e["newSelection"][0]
  }
  removeFilter(){    
    this.viewFilterString=null;
    this.filterCriteria=null;
    this.isResetFilter=true;
    this.bindNgxDataGrid(this.page,1)
  }
  exportCSVHandler(){
    this.exportGridToCSV(this.grid1);
  }
  exportExcelHandler(){
    this.exportGridToExcel(this.grid1);
  }
  exportGridToExcel(grid: IgxGridComponent) {
    // this.excelExportService.export(grid, new IgxExcelExporterOptions(this.fileName()));
    this.filteredAndSelected(grid);
     // if there aren't any rows after the filtering has been applied do not export anything
    if (this.visibleRows.length > 0) {
        // component.ts
        this.excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
         // if there are any selected rows within the filtered data -> export only them
        // if there are no selected rows within filtered data -> export only the filtered data
        this.excelExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxExcelExporterOptions(this.fileName()));
    }
  }

  exportTreeGridToExcel(grid: IgxTreeGridComponent) {
    this.excelExportService.export(grid, new IgxExcelExporterOptions(this.fileName()));
    this.filteredAndSelected(grid);
    if (this.visibleRows.length > 0) {
        this.excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
        this.excelExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxExcelExporterOptions(this.fileName()));
    }
  }

  exportGridToCSV(grid: IgxGridComponent) {
    this.filteredAndSelected(grid);

    if (this.visibleRows.length > 0) {
        this.csvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
        this.csvExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxCsvExporterOptions(this.fileName(), CsvFileTypes.CSV));
    }
  }

  exportTreeGridToCSV(grid: IgxTreeGridComponent) {
    this.filteredAndSelected(grid);

    if (this.visibleRows.length > 0) {
        this.csvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
            if (args.header == "Id") {
            args.cancel = true;
           }
         });
        this.csvExportService.exportData(this.selectedAndVisibleRows.length > 0 ? this.selectedAndVisibleRows : this.visibleRows, new IgxCsvExporterOptions(this.fileName(), CsvFileTypes.CSV));
    }
  }
  public filteredAndSelected(grid) {
    this.selectedAndVisibleRows = [];
    this.visibleRows = grid.selectionService.getRowIDs(
        grid.selectionService.allData
    );

    if (grid.selectedRows.length > 0) {
        grid.selectedRows.forEach(row => {
            if (this.visibleRows.indexOf(row) >= 0) {
                this.selectedAndVisibleRows.push(row);
            }
        });
    }
    return this.visibleRows;
  }
  public fileName(){
    const nowDate = new Date(); 
    const month = nowDate.toLocaleString('default', { month: 'short' })
    const date = nowDate.getDate()+ '-'+month+'-' +nowDate.getFullYear(); 
    const url = this.router.url.replace(/\//g, "");
    const filename = url.charAt(0).toUpperCase() + url.slice(1);
    return filename+"_"+date;
  }
  

  apply(criteriaStr: any) {
    //console.log("criteriaStr==>",criteriaStr)
    if(criteriaStr!=null)
    {
      this.viewFilterString=criteriaStr["viewStr"];
      this.filterCriteria=criteriaStr["queryStr"];
      //this.getFormViewDetails();
      this.bindNgxDataGrid(this.page,1)
    }
  }

  onProfileIdChange(event) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboardnew?'+localStorage.getItem('tempToken')]);
    });    
  }


  getDraftList(){
    var strQry=`select dr.draft_requestid,sys.varibale_name scheme_category,d.department_name department_id,dr.item_id,dr.scheme_title,
    case when dr.proposal_type ='1' then 'New'
    when dr.proposal_type ='2' then 'Ongoing'
    when dr.proposal_type ='1' then 'Continuing' end proposal_type,TO_CHAR(dr.createddate:: DATE, 'dd/mm/yyyy') application_date,form_id,form_vid,process_id,'[1,1]' as val 
    from radx_draft_requests dr left join app_tblsystemenum sys on dr.scheme_category=sys.id 
    left join z_entitymast_department_001 d on d.department_id=dr.department_id
    where dr.status_id=108 and dr.createdby=${this.sessionUrlPrams.ProfileId}  order by dr.createddate desc;`
     this.formService.getFormData(strQry).subscribe(res=>{      
      this.inboxColumndef=[
        {"field": "scheme_title","headerName": "Scheme Title","sortable": true,"filter": true,"resizable": true,"width": "450","wrapText": true,"autoHeight": true},
        {"field": "scheme_category","headerName": "Scheme Category","sortable": true,"filter": true,"resizable": true,"width": "250","wrapText": true,"autoHeight": true},
        {"field": "department_id","headerName": "Department Name","sortable": true,"filter": true,"resizable": true,"width": "300","wrapText": true,"autoHeight": true},
        {"field": "application_date","headerName": "Application Date","sortable": true,"filter": true,"resizable": true,"width": "150","wrapText": true,"autoHeight": true},
        // { headerName: 'Action',
        //   field:"val",
        //   pinned: 'right',
        //   cellRenderer: 'buttonRenderer',
        //   cellRendererParams:{
        //     actionList:[
        //       {label:"Edit",form_status:1,db_status:1,str:"view",onClick:this.onProcess.bind(this)},
        //     ]
        //   },
        //   width:120,
        //   resizable:true
        // } 
      ]    
      this.columnActionStrip.push({field: "Edit", iconName: "edit"});
      this.inboxRows=res.rows;
      // console.log(JSON.stringify(this.inboxRows))
      this.InboxLoaded = Promise.resolve(true);
     })
  }
  onProcess(obj){    
    // console.log("obj==>",obj._rowData)
    let row=obj._rowData;
    let data  =JSON.stringify('frmid='+row.form_id+'&editrowid='+row.item_id+'&vid='+row.form_vid+'&isAddMode=false');
    let encriptrequest =this.loginService.encriptDataUrl(data);
    this.router.navigateByUrl('request-form?'+encriptrequest);
  }
}
