import { Component, OnInit } from '@angular/core';
import { CustomerLoginSigninService } from '../../_services/customer-login-signin.service';
import { LoginService } from '../../_services/login.service';
import { FormService } from '../../_services/form.service';
import { ProductService } from '../../_services/product.service';
import { CommonFunctionService } from '../../_services/common-function.service';
import { Router } from '@angular/router';
import { AppSettings } from '../../_services/AppSetting';
import * as crypto from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Pipe, PipeTransform } from "@angular/core"; 
import { timer, Subscription } from "rxjs";
declare var $: any;
@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {
  countDown: Subscription;
  counter = 120;
  tick = 1000;
 
  firstName: any;
  lastName: any;
  mobileNo: any;
  email: any;
  success_msg: string;
  password: any;
  otp: any;
  otp1: any;
  otp2: any;
  otp3: any;
  otp4: any;
  user_name: any;
  passwordd: any;
  timer: any;
  err_msg: string;
  currentUrl: string;
  userName: any;
  isLoading: boolean = false;
  buyerStatus: any;
  show: boolean = false;
  myself: any;
  business: any;
  shopFor: any;
  IndustryList: any;
  organization: any;
  industryId: any;
  isMobDisable: boolean = false;
  otptext: string;
  showPassword: boolean = false;
  showlogintxt: boolean=true;
  timeLeft: number = 60;
  interval;
  isTimeout: boolean = false;
  constructor(private customerLoginSigninService: CustomerLoginSigninService, private toastrService: ToastrService, private productService: ProductService,
    private router: Router, private loginService: LoginService, private formService: FormService, public commonFunctionService: CommonFunctionService) { }
    
    
    ngOnInit(): void {
      $('.create-account').show();

    $('.tooltiptext').hide();
      $('.csttooltip').click(function () {
       $('.tooltiptext').toggleClass('show');
    });

    this.getAllIndustryTypeName()
    $('.otp').hide();
    $('.create-account').hide();
    $('.welideacraft').hide();
    $('.getUsernameForm').hide();
    $('.submitEmailForm').hide();
    $('.getPasswordForm').hide();

    $('.creatact').click(function () {
      $('.create-account').show();
      $('#signinwindow').hide();
      $('.welideacraft').hide();
      $('.submitEmailForm').hide();
      $('.getUsernameForm').hide();
      $('.getPasswordForm').hide();
    });
    $('.getUsername').click(function () {
      $('.create-account').hide();
      $('#signinwindow').hide();
      $('.welideacraft').hide();
      $('.submitEmailForm').hide();
      $('.getUsernameForm').show();
      $('.getPasswordForm').hide();
    });
    $('.canselbtn').click(function () {
      $('.create-account').hide();
      $('#signinwindow').show();
      $('.welideacraft').hide();
      $('.getUsernameForm').hide();
      $('.submitEmailForm').hide();
      $('.getPasswordForm').hide();
    });
    $('.submitemail').click(function () {
      $('.create-account').hide();
      $('#signinwindow').hide();
      $('.welideacraft').hide();
      $('.getUsernameForm').hide();
      $('.submitEmailForm').show();
      $('.getPasswordForm').hide();
    });
    $('.getPassword').click(function () {
      $('.create-account').hide();
      $('#signinwindow').hide();
      $('.welideacraft').hide();
      $('.submitEmailForm').hide();
      $('.getUsernameForm').show();
    });



    // $('.gotowelcome').click(function () {
    //   $('.create-account').hide();
    //   $('#signinwindow').hide();
    //   $('.welideacraft').show();
    // });


    // $('.cont').click(function(){
    //   $('.create-account').hide();
    //   $('.otp').show();

    // });


  }

  chooseOption() {
    if (this.shopFor == 'mySelf') {
      this.show = false
    }
    else {
      this.show = true
    }
  }

  getAllIndustryTypeName() {
    this.loginService.getIndustry().subscribe(res => {
      this.IndustryList = res;
    })
  }

  showLogin() {
    this.showlogintxt=true;
    setTimeout(() => {
      $('.create-account').hide();
      $('#signinwindow').show();
      $(".crtact").hide()
      $(".crtact").addClass("d-none")
      $(".sgn").show()
    }, 100)

  }
  createAccount1(){
    this.showlogintxt=false;
  }
  startTimer() {
    // this.countDown = this.myService
    // .getCounter(this.tick)
    // .subscribe(() => this.counter--);

    clearInterval(this.interval)
    this.timeLeft = 120;
    this.isTimeout = false
    this.interval = setInterval(() => {
      if (this.timeLeft >= 1) {
       // console.log(this.timeLeft)
        this.timeLeft--;
        // console.log('ifpart', this.timeLeft)

      } else {
        //console.log('elsepar')
        this.timeLeft = 0;
        this.isTimeout = true
        clearInterval(this.interval)
        return true;

      }
    }, 1000)
  }
  reset() {
    this. firstName=null;
    this.lastName= null;
    this.mobileNo= null;
    this.email= null;
    this.password=null;
    this.otp=null;
    this.user_name=null;
    this.user_name =null;
    this.myself=null;
    this.business=null;
    this.shopFor=null;
    this.IndustryList=null;
    this.organization=null;
   this.industryId=null;
   this. otptext= null;
this.showPassword= null;
this.showlogintxt= null;   
  }
  sendOtp() {
    // console.log('sendotp click')
    if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == '') {
      this.toastrService.warning('Please Enter Mobile No', '', { timeOut: 2000, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
      
    }
    if (this.mobileNo !== null || this.mobileNo !== undefined || this.mobileNo !== '') {
      if (this.mobileNo.toString().length == 10) {
        if ((this.mobileNo[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No', '', { timeOut: 2000, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false
        } else {
          this.email = this.email ? this.email.trim() : ''
          this.customerLoginSigninService.checkMobileNumberAndEmail(this.mobileNo.trim(), this.email.trim()).subscribe(res => {
            if (res.status == 201) {
              // this.isMobDisable = true;
              // this.otptext = '1234'
              this.toastrService.warning(res.message, '', { timeOut: 2000, positionClass: "toast-top-center" });
              $(".overlay-container").addClass("addcontainerdisable");
               this.removeClass()
                  return false ;
                  
            }else{
              this.isMobDisable = true;
              // if (res.status == 200){
              //   console.log('sdfsdfsdfsdfs', res)
              //   this.isMobDisable = true;
              //   this.otptext = res.otp
              //   this.startTimer()
              // }
             
            }
          })
        
        }
      }
      else {
        this.isMobDisable = false;
        this.toastrService.warning("Please enter valid 10-digit Mobile No.", '', { timeOut: 2000, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    // this.checkMobileNumberAndEmail();
   

  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    },2000);
  }
  // sendOtp() {
  //   if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == '') {
  //     this.toastrService.warning('Please Enter Mobile No', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 });
      
  //      $(".overlay-container").addClass("addcontainerdisable");
  //     return false;
  //     //$(".overlay-container").addClass("addcontainerdisable");
  //   }
  //   if (this.mobileNo !== null || this.mobileNo !== undefined || this.mobileNo !== '') {
  //     if (this.mobileNo.toString().length == 10) {
  //       if ((this.mobileNo[0]) < 6) {
  //         this.toastrService.warning('Please Enter valid Mobile No', '', { timeOut: 1000, positionClass: "toast-top-center" });
  //         return false
  //       } else {
  //         this.isMobDisable = true;
  //         this.otptext = '1234'
  //       }
  //     }
  //     else {
  //       this.isMobDisable = false;
  //     }
  //   }
  // }

  edit() {
    // alert('hello')
    this.isMobDisable = false;
    this.otptext = ''
    this.otp = ''
  }

  createAccount() {
    // if (this.otp != this.otptext) {
    //   this.toastrService.warning('Please Enter Correct OTP', '', { timeOut: 1000, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //     this.removeClass()
    //     return false;
    // }
    var minNumberofChars = 8;
    var maxNumberofChars = 16
    let passpattern = /(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/;
    let pattern1 = /^((?=.*[a-z])(?=.*[A-Z]).*)$/
    this.firstName = this.firstName?.trim();
    if (this.firstName == null || this.firstName == undefined || this.firstName == '') {
      this.toastrService.warning('Please Enter First Name', '', { timeOut: 1000, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    // this.lastName = this.lastName?.trim();
    // if (this.lastName == null || this.lastName == undefined || this.lastName == '') {
    //   this.toastrService.warning('Please Enter Last Name', '', { timeOut: 1000, positionClass: "toast-top-center" });
    //   return false;
    // }
    if (this.email == null || this.email == undefined || this.email == '') {
      this.toastrService.warning('Please Enter Email', '', { timeOut: 1000, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.email !== null || this.email !== undefined || this.email !== '') {
      var pattrn = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (this.email.substring(0).length > 0) {
        var email = this.email.substring(0).toString();
        if (pattrn.test(email) == false) {
          this.toastrService.warning("Please enter valid Email.", '', { timeOut: 2000, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      }
    }
    if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == '') {
      this.toastrService.warning('Please Enter Mobile No', '', { timeOut: 1000, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.mobileNo !== null || this.mobileNo !== undefined || this.mobileNo !== '') {
      if (this.mobileNo.toString().length == 10) {
        if ((this.mobileNo[0]) < 6) {
          this.toastrService.warning('Please Enter valid Mobile No', '', { timeOut: 1000, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
        }
      } else {
        this.toastrService.warning('Please Enter valid 10 -digit Mobile No', '', { timeOut: 1000, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    if (this.password == null || this.password == undefined || this.password == '') {
      this.toastrService.warning('Please Enter Password', '', { timeOut: 1000, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    if (this.password !== null || this.password !== undefined || this.password !== '') {
      if (this.password.length < minNumberofChars || this.password.length > maxNumberofChars) {
        this.toastrService.warning("Your password must be at least 8 characters", '', { timeOut: 1000, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      } else if (!passpattern.test(this.password)) {
        this.toastrService.warning("password should contain atleast one number and one special character", '', { timeOut: 1000, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      } else if (!pattern1.test(this.password)) {
        this.toastrService.warning("password should contain atleast one upper case and one lower case character", '', { timeOut: 1000, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    if (this.shopFor == null || this.shopFor == undefined || this.shopFor == '') {
      this.toastrService.warning('Please Select Shop for Type', '', { timeOut: 1000, positionClass: "toast-top-center" });
       $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
    }
    this.checkMobileNumberAndEmail(true);

  }

  checkMobileNumberAndEmail(isRegister?) {
    this.customerLoginSigninService.checkMobileNumberAndEmail(this.mobileNo.trim(), this.email.trim(),isRegister).subscribe(res => {
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: 1800, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      } else {
        this.customerLoginSigninService.saveCustomerDetails(this.firstName, this.email, this.mobileNo,
          this.password, this.organization, this.industryId, this.shopFor).subscribe(res => {
            this.toastrService.success("Create Account Successfully", '', { timeOut: 1000, positionClass: "toast-top-center" });
            this.loginNew1();
            this.user_name = this.mobileNo;
            this.passwordd = this.password;
            location.reload()
            setTimeout(() => {
              $('.create-account').hide();
              $('#signinwindow').hide();
              $('.welideacraft').show();
            }, 100)
          }, err => {
            this.toastrService.warning("something went wrong", '', { timeOut: 1000, positionClass: "toast-top-center" });
          })
      }
    })
  }

  startShopping() {
    setTimeout(() => {
      $('.create-account').hide();
      $('#signinwindow').hide();
      $('.welideacraft').hide();
    }, 100)
  }

  showHidePassword() {
    // this.showPassword = !this.showPassword;
    if(this.showPassword){
      this.showPassword=false;
    }else{
      this.showPassword=true;
    }
  }

  
  getToken(username, password) {
    this.loginService.getToken(username, password).subscribe(res => {
      localStorage.setItem("token", res.token)
    })
  }

  loginNew1() {
    this.success_msg = "";
    this.isLoading = true;
    let userName = crypto.AES.encrypt(this.user_name, AppSettings.SecreatKey).toString();
    let passwords = crypto.AES.encrypt(this.password, AppSettings.SecreatKey).toString();
    //this.getToken(userName, passwords);
    if (!!this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.login();
      this.isLoading = false;
    }, 1200);
  }

  login() {
    this.isLoading = true;
    // this.capchaCode = this.loginService.getCaptchaDecrypted(this.capchaResp["ctext"])
    var loginType = null;
    var id = null;
    var role_name = null;
    this.err_msg = "";
    if (this.user_name == null || this.user_name == undefined || this.user_name == "" || this.user_name == "null" || this.user_name == "undefined") {
      this.err_msg = "User Name is required";
      return false;
    }
    if (this.passwordd == null || this.passwordd == undefined || this.passwordd == "" || this.passwordd == "null" || this.passwordd == "undefined") {
      this.err_msg = "Password is required";
      return false;
    }
    let currDate = new Date();
    var d, m, y, hour, minute;
    d = currDate.getDate();
    m = currDate.getMonth() + 1;
    y = currDate.getFullYear();
    hour = currDate.getHours();
    minute = currDate.getMonth();
    if (minute < 10) { minute = "0" + minute };
    if (d < 10) { d = "0" + d };
    if (m < 10) { m = "0" + m };
    var finalDt = y + "-" + m + "-" + d;
    let query = `select atc.customer_id,atc.customer_login_id,atc.customer_fname,atc.customer_lname,atc.customer_emailid,atc.customer_mobile,
    cdtl.buyer_status from app_tblcustomer atc
    left join z_entitymast_customer_details_001 cdtl on atc.customer_id = cdtl.customer_id
    where ((customer_emailid ='${this.user_name.toString().trim()}') or (customer_mobile ='${this.user_name.toString().trim()}'))
      and (customer_password='${this.passwordd.toString().trim()}')`
    this.formService.getFormData(query).subscribe(res => {
      this.isLoading = false;
      if (res.rows.length == 0) {
        this.toastrService.warning('Invalid UserName or Password', '', { timeOut: 1000, positionClass: "toast-top-center" });
      }
      console.log("res------------", res.rows)
      this.currentUrl = this.router.url;
      if (res.rows.length > 0) {
        this.buyerStatus = res.rows[0].buyer_status
        if (this.buyerStatus == "195") {
          this.toastrService.warning("Your Account has been Blocked ", '', { timeOut: 1000, positionClass: "toast-top-center" });
          return false
        }
        else {
          localStorage.setItem("customer_id", res.rows[0].customer_id);
          localStorage.setItem("customer_login_id", res.rows[0].customer_login_id);
         

          if (localStorage.getItem('cart_items') != null || localStorage.getItem('cart_items') != undefined) {
            // this.productService.addToCart(res.rows[0].customer_id, JSON.parse(localStorage.getItem('cart_items'))).subscribe(res => {
            //   localStorage.removeItem('cart_items')
            // })
            alert('you have commented some')
          }

          $('.modal').modal("hide");
          this.router.navigateByUrl(this.currentUrl +'?'+'/');
          this.isLoading = false;
        }
      } else {
        this.err_msg = "Invalid User or Password.";
        this.isLoading = false;
      }
    });
  }
}
@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    //MM:SS format
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );

    // for HH:MM:SS
    //const hours: number = Math.floor(value / 3600);
    //const minutes: number = Math.floor((value % 3600) / 60);
    //return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}

