<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
  
      <div class="container-fluid  bg-white">
        <div class="row">
          <div class="col-sm-12 p-0">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                <li class="breadcrumb-item newitm1">
                  <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                </li>
                <li class="breadcrumb-item newitm1">
                  <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/total-seller"> Seller List</a>
                </li>
                <li class="breadcrumb-item newitm2  active" aria-current="page">Payment History
                  <b>({{sellerName}})</b>
                </li>
              </ol>
            </nav>
          </div>
        </div>
  
        <div class="col-sm-12  my-2 p-0">
          
          <div class="tabs col-lg-12 pl-0 pr-1 my-2 d-flex">
            <div class="tab-button-outer1 col-lg-8 px-0">
              <ul id="tab-button">
                <li id="tabDraft" class="is-active">
                  <a href="javascript:void(0)" class="font9">Payment History
                  </a>
                </li>
             
                <!-- <li id="" class="">
                  <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(194); SearchValue.value = '' ">Accepted
                    <span>({{acceptedOrderCount}})</span>
                  </a>
                </li>
                <li id="" class="">
                  <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(192); SearchValue.value = '' ">Dispatch to Shipper
                    <span>({{readyToShipedOrderCount}})</span>
                  </a>
                </li>
                <li id="" class="">
                  <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(189); SearchValue.value = '' ">Shipped
                    <span>({{shipedOrderCount}})</span>
                  </a>
                </li>
                <li id="" class="">
                  <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(193); SearchValue.value = '' ">Delivered
                    <span>({{deleveredOrderCount}})</span>
                  </a>
                </li>
               
                <li id="" class="">
                  <a href="javascript:void(0)" class="font8" (click)="getAllTypeOrder(190, 191); SearchValue.value = '' ">Cancelled
                    <span>({{cancledOrderCount}})</span>
                  </a>
                </li> -->
  
              </ul>
            </div>
            <div class="col-sm-4 cstmposition2 d-none d-md-block pr-0" id="masterSearch">
              <div class="sort-wrapper pull-right width100">
                <div class="form-group col-lg pr-0">
                  <div class=" input-group">
                    <input type="search" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                      placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
                    <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </div>
                <div>
  
                </div>
              </div>
            </div>
          </div>
          <div id="tab01" class="mmt5 p-0">
            <div class="col-sm-12 col-md-12  nopadding">
              <div id="grid-theme-wrapper">
                <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
               
                <ngx-loading [show]="isLoader"></ngx-loading>
                <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->
  
                <igx-grid #grid1 [data]="allOrderPaymentList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'">
                  <igx-column field="product_name" header="Product Details" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container p-3">
                        <a href="javascript:void(0)">
                          <span class="cell-vl " (click)="onProductdetailView(cell)">{{cell.value }}</span>
                        </a>
                      </div>
                    </ng-template>
                  </igx-column>
                     <igx-column field="full_name" header="Buyer Name" width="">
                      <ng-template #compositeTemp igxCell let-cell="cell">
                        <div class="address-container p-3">
                          <span class="cell-vl " [innerHtml]="cell.value"></span>
                        </div>
                      </ng-template>
                    </igx-column>                                 
                  <igx-column field="total_amount" header="Total Amount" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container p-3">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="total_gst_amount" header="GST Amount" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container p-3">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>   
                  <igx-column field="total_quantity" header="Quantity" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container p-3">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>             
                  <igx-column field="payment_id" header="Payment Id" width="">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container p-3">
                        <span class="cell-vl " [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
    <!-- view model -->
  
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header p-2">
            <h6 class="modal-title" id="exampleModalLabel">Product Details</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body minhyt70 p-0" *ngIf="isView">
            <app-product-details [isView]="isView" [view_item_id]="view_item_id"></app-product-details>
          </div>
        </div>
      </div>
    </div>
  
  </div>