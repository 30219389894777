import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import {
  SectionConfig,
  SessionUrlPrams,
  ConfigPrams,
} from '../../field.interface';
import { Router } from '@angular/router';
import { ProductService } from '../../_services/product.service';
import Swal from 'sweetalert2';
declare var $: any;


@Component({
  selector: 'app-bulkorder-query-list',
  templateUrl: './bulkorder-query-list.component.html',
  styleUrls: ['./bulkorder-query-list.component.css']
})
export class BulkorderQueryListComponent implements OnInit {
  bulkOrderList: any[] = [];
  brandList: any[] = [];
  brandNameId: any;
  isView: boolean;
  page = new Page();
  configParms: ConfigPrams;
  LoadPagination: boolean = false;
  TempallOrderList: any;
  isLoading: boolean = false;
  action: any;
  searchTerm: any;
  isLoadGrid: boolean = false;
  sellerId: string;
  public totalCount = 0;
  globalmsg: any;

  constructor(
    private ProductService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.configParms = {
      currentPage: 1,
      totalItems: this.totalCount,
      itemsPerPage: 10,
    };
    this.page.size = 10;
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.bindNgxDataGrid(this.page, 1);

    $(document).ready(function () {
      $('ul.tbsbtns li').click(function () {
        $('li').removeClass('is-active');
        $(this).addClass('is-active');
      });
    });
  }
  LoadGrid(event) {
    this.isLoadGrid = true;
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
  }
  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;
    this.bindNgxDataGrid(this.page, 1);
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true;
    let total,
      pagesize,
      limit,
      offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = (pageInfo.pageNumber - 1) * pagesize;
    }
    if (!this.isLoadGrid) offset = this.searchTerm ? 0 : offset;
    this.ProductService.bulkOrderListCount(this.searchTerm).subscribe((resp) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      offset =
        this.page.totalElements < offset
          ? 0
          : this.page.totalElements > offset
            ? offset
            : 0;
      this.ProductService.bulkOrderQueryList(
        pagesize,
        offset,
        this.searchTerm
      ).subscribe((res) => {
        if (res.rows.length > 0) {
          this.bulkOrderList = res.rows;
          // console.log('resppppp', res.rows);
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.bulkOrderList = [];
            this.isLoading = false;
          }, 100);
        }
      });

      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) {
        currPage = 1;
      }
      this.configParms = {
        currentPage: currPage,
        totalItems: this.totalCount,
        itemsPerPage: pagesize,
      };
      this.LoadPagination = true;
    });
  }
  viewmore(msg) {
    this.globalmsg = ''
    this.globalmsg = msg;
  }

  onDelete(bulkId) {
    bulkId = bulkId.rowData;
    Swal.fire({
      title: `Are you sure you want to delete this Bulk Order Query ?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.deleteBulkOrderListData(bulkId.bulk_order_id).subscribe(
          (res) => {
            Swal.fire('Bulk Order Data deleted successfully', '', 'success');
            this.bindNgxDataGrid(this.page, 1);
          }
        );
      }
      // this.ProductService.deleteBulkOrderListData(bulkId.bulk_order_id).subscribe((res) => {
      //   this.toastrService.warning('Bulk Order Data deleted successfully.', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      //   $(".overlay-container").removeClass("addcontainerdisable");
      //   this.bindNgxDataGrid(this.page, 1);
      // });
    })

  }

}
