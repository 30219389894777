import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from '../_services/AppSetting'

@Injectable({
  providedIn: 'root'
})
export class ideacraftAdminService {

  constructor(private http: HttpClient) { }

  url = AppSettings.API_ENDPOINT;

  addSellerDetails(sellerDetails) {
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/addSellerDetails", sellerDetails, { headers })
  }
  getSellerData(seller_id) {
    var data = { seller_id }
    const headers = new HttpHeaders();
    return this.http.post(this.url + "/getSellerData", data, { headers })
  }
  
  getSysEnumType(type){
    let data = { type }
  const headers = new HttpHeaders();
  return this.http.post(this.url + "/sysenumtype", data, { headers }).pipe(map(res => {
    return res as any;
  }, error => {
    return error as any;
  }));
  }  

  checkValidity(value){
    let possiblityCheck = [null, undefined, 'null', 'undefined', 'NULL', 'UNDEFINED', '', 0];
    if(possiblityCheck.includes(value)){
      console.log('not allowed')
      return true;
    }
    else{
      console.log('allowed')
      return false;
    }

  }

  
}
