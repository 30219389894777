import { ideacraftAdminService } from '../../_services/ideacraftadmin.service';
import { FormService } from '../../../app/_services/form.service';
import Swal from 'sweetalert2';
import { AppSettings } from '../../_services/AppSetting';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams, } from '../../field.interface';
import { ProductService } from 'src/app/_services/product.service';
declare var $: any;

@Component({
  selector: 'app-gst-master',
  templateUrl: './gst-master.component.html',
  styleUrls: ['./gst-master.component.css']
})
export class GstMasterComponent implements OnInit {
  @ViewChild('myInputVariable') myInputVariable: ElementRef;
  gstValue: any;
  isActive: any;
  isShow: boolean = true;
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  isLoading: boolean = true;
  userId: any;
  searchTerm: any;
  gstId: any;
  MassageOutTime = AppSettings.getMassageOutTime;
  constructor(
    private formservice: FormService,
    private toastrService: ToastrService,
    private ideacraftService: ideacraftAdminService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userId = localStorage.getItem('userId');
    this.configParms = {
      currentPage: 1,
      totalItems: this.totalCount,
      itemsPerPage: 10,
    };
    this.page.size = 10;
    this.bindNgxDataGrid(this.page, 1);
    //this.resetForm();
  }
  LoadGrid(event) {
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
  }

  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true
    let total,
      pagesize,
      limit,
      offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = (pageInfo.pageNumber - 1) * pagesize;
    }

    this.productService.getGstDataCount(this.searchTerm).subscribe((resp: any) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      this.productService.getGstDataListMaster(pagesize, offset, this.searchTerm).subscribe((res: any) => {
        if (res.rows.length > 0) {
          this.gstDataList = res.rows;
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.gstDataList = [];
            this.isLoading = false;
          }, 100);
        }
      });
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) {
        currPage = 1;
      }
      this.configParms = {
        currentPage: currPage,
        totalItems: this.totalCount,
        itemsPerPage: pagesize,
      };
      this.LoadPagination = true;
      //this.isLoading = false;
    });
  }
  resetForm() {
    this.gstValue = '';
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  update() {
    $('#myModal').modal('hide');
    if (this.gstValue == null || this.gstValue == undefined || this.gstValue == '') {
      this.toastrService.warning("Please Enter GST Value!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    let aa=this.gstDataList.filter((x)=>x.value==this.gstValue);
    if(aa.length > 0){
      this.toastrService.warning("GST Value already exist!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.productService.updateGSTData(this.gstId, this.gstValue).subscribe((res: any) => {
      //console.log('ressssssssss', res);
      if (res.rows.length > 0) {
        $('#add-new-deal').modal('hide');
        this.toastrService.success('GST Value Updated successfully.', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        this.bindNgxDataGrid(this.page, 1);
      }
    })
  }
  onEdit(gst) {
    this.isShow = false;
    gst = gst.rowData;
    console.log("gstt", gst)
    this.gstValue = gst.value;
    this.gstId = gst.gst_id;
  }
  openpopup() {
    this.gstValue = '';
    $('#myModal').modal('show');
    this.isShow = true;
  }
  onSubmit() {

    if (this.gstValue == null || this.gstValue == undefined || this.gstValue == '') {
      this.toastrService.warning("Please Enter GST Value!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    let aa=this.gstDataList.filter((x)=>x.value==this.gstValue);
    if(aa.length > 0){
      this.toastrService.warning("GST Value already exist!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }

    this.productService.insertGstDetails(this.gstValue).subscribe((res: any) => {
      // console.log('ressssssssss', res);
      if (res.rows.length > 0) {
        $('#myModal').modal('hide');
        this.toastrService.success('GST Value successfully added.', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
      }
      this.bindNgxDataGrid(this.page, 1);
      this.resetForm();


    })
  }


  gstDataList: any[] = [];

  CommonGridSearchMaster(ev) {
    this.searchTerm = ev
    this.bindNgxDataGrid(this.page, 1)
    // this.bannerDataList = this.TempbannerDataList.filter((item) =>  item.title ? item.title.toLowerCase().indexOf(ev) !== -1 : false    );
  }
  onDelete(gst) {
    gst = gst.rowData;
    Swal.fire({
      title: `Are you sure you want to delete this gst value?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteGstData(gst.gst_id).subscribe((res) => {
          Swal.fire('Deleted gst value successfully!', '', 'success');
          this.bindNgxDataGrid(this.page, 1);
        }
        );
      }
    });
  }

  ngAfterViewChecked() {
    $('.toast-close-button').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
    $('.toast-container').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
  }
}

