import { ideacraftAdminService } from '../../_services/ideacraftadmin.service';
import { FormService } from '../../../app/_services/form.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams, } from '../../field.interface';
import { Router } from '@angular/router';
import {
  IgxGridComponent,
  DisplayDensity,
  IgxCsvExporterService,
  IgxExcelExporterService,
  IgxGridRowComponent,
  Transaction,
  IgxStringFilteringOperand,
  ConnectedPositioningStrategy,
  HorizontalAlignment,
  VerticalAlignment,
  NoOpScrollStrategy,
  GridPagingMode,
  IgxExcelExporterOptions,
  IgxCsvExporterOptions,
  CsvFileTypes,
  IgxTreeGridComponent,
  IColumnExportingEventArgs,
} from '@infragistics/igniteui-angular';
import { ProductService } from 'src/app/_services/product.service';
import Swal from 'sweetalert2';
import { AppSettings } from '../../_services/AppSetting';
import { CommonFunctionService } from '../../_services/common-function.service';
import { SellerRegistrationService } from '../../_services/seller-registration.service';

declare var $: any;
@Component({
  selector: 'app-manage-banner',
  templateUrl: './manage-banner.component.html',
  styleUrls: ['./manage-banner.component.css'],
})
export class ManageBannerComponent implements OnInit {
  @ViewChild('myInputVariable') myInputVariable: ElementRef;
  sliderType: any;
  txtTitle: any;
  txtDescription: any;
  visibleFrom: any;
  bannerType: any;
  visibleUntil: any;
  txtWidth: any;
  txtHeight: any;
  isActive: any;
  bannerImage: any;
  alternateName: any;
  isShow: boolean = true;
  soaFiles: any;
  filepath: any;
  sliderList: any;
  banner_id: any;
  formData: FormData = new FormData();
  MassageOutTime = AppSettings.getMassageOutTime;
  TempbannerDataList: any;
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  isLoading: boolean = true;
  userId: any;
  bannertTypeList: any;
  uploadedWidth: number;
  uploadHeight: number;
  mindate: string;
  filename: any;
  msg: string;
  searchTerm: any;
  categoryList: any=[];
  subCategoryList: any=[];
  category: any;
  sub_category: any;
  allProductList: any=[];
  selectedProdcut: any;
  constructor(
    private formservice: FormService,
    private toastrService: ToastrService,
    private ideacraftService: ideacraftAdminService,
    public commonFunctionService: CommonFunctionService,private sellerService: SellerRegistrationService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userId = localStorage.getItem('userId');
    this.configParms = {
      currentPage: 1,
      totalItems: this.totalCount,
      itemsPerPage: 10,
    };
    this.page.size = 10;
    this.bindNgxDataGrid(this.page, 1);
    this.getBannerType();
    this.getAllCat();
    //this.resetForm();
  }
  LoadGrid(event) {
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1);
  }

  getBannerType() {
    this.ideacraftService.getSysEnumType('BannerType').subscribe((res: any) => {
      this.bannertTypeList = res.rows;
    });
  }
  getAllCat() {
    this.sellerService.getCategory().subscribe(res => {
      this.categoryList = res;
    })
  }

  getSubCategory() {
    this.sellerService.getSubCategory(this.category).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.subCategoryList = res;
      } else {
        this.subCategoryList = [];
      }      
    })
  }
  getProducts(){
    this.productService.getProductsForbanner(this.sub_category, this.category).subscribe(res =>{
      // console.log('res============',res)
     this.allProductList = res[0].rows;
    })
  }
  categoryChanged() {
    this.getSubCategory();
    this.sub_category = undefined;
    this.selectedProdcut = undefined;
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true
    let total,
      pagesize,
      limit,
      offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = (pageInfo.pageNumber - 1) * pagesize;
    }

    this.productService.getCountForBanner(this.searchTerm).subscribe((resp: any) => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      this.productService.getManageBanner(pagesize, offset, this.searchTerm).subscribe((res: any) => {
        if (res.rows.length > 0) {
          this.bannerDataList = res.rows;
          this.TempbannerDataList = res.rows;
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.bannerDataList = []
            this.TempbannerDataList = []
            this.isLoading = false;
          }, 100);
        }
      });
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) {
        currPage = 1;
      }
      this.configParms = {
        currentPage: currPage,
        totalItems: this.totalCount,
        itemsPerPage: pagesize,
      };
      this.LoadPagination = true;
      //this.isLoading = false;
    });
  }
  resetForm() {
    this.sliderType = '';
    this.txtTitle = '';
    this.txtDescription = '';
    this.visibleFrom = '';
    this.visibleUntil = '';
    this.txtWidth = '';
    this.txtHeight = '';
    this.isActive = '';
    this.bannerType = '';
    this.myInputVariable.nativeElement.value = ''
    this.alternateName = '';
    this.soaFiles = [];
    this.filename = null;
    this.bannerImage = null;
    this.category = undefined;
    this.sub_category = undefined;
    this.selectedProdcut = undefined
    //console.log("image rsestt",this.soaFiles )
    //$('#myModal').modal('hide');
  }
  update() {
    $('#myModal').modal('hide');
    let data = {
      sliderType: this.sliderType,
      title: this.txtTitle,
      visibleFrom: this.visibleFrom,
      visibleUntil: this.visibleUntil,
      discription: this.txtDescription,
      isActive: this.isActive,
      imageWidth: this.txtWidth,
      bannerType: this.bannerType,
      imageheight: this.txtHeight,
      alternateName: this.alternateName,
      bannerImage: this.soaFiles,
      masterId: this.banner_id,
      category: this.category,
      sub_category: this.sub_category,
      product_id : this.selectedProdcut
    };
    this.productService.updateManageBanner(data).subscribe((res) => {
      this.toastrService.success('Banner Updated successfully.', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      this.removeClass();
      //$('.overlay-container').addClass('addcontainerdisable');
      this.bindNgxDataGrid(this.page, 1);
    });
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  onEdit(banner) {
    //console.log("bannerrr", banner)
    this.isShow = false;
    banner = banner.rowData;
    this.sliderType = banner.slider_type;
    this.txtTitle = banner.title;
    this.visibleFrom = banner.visible_from;
    //this.visibleFrom = this.visibleFrom.split('T')[0];
    this.visibleUntil = banner.visible_until;
    //this.visibleUntil = this.visibleUntil.split('T')[0];
    this.soaFiles = banner.banner_image;
    this.bannerType = banner.banner_type;
    this.isActive = banner.is_active;
    this.alternateName = banner.alternate_name;
    this.txtWidth = banner.image_width;
    this.txtHeight = banner.image_height;
    this.txtDescription = banner.description;
    this.banner_id = banner.banner_master_id;
    this.category = banner.category_id;
    this.sub_category = banner.sub_category_id;
    this.selectedProdcut = banner.product_id;
    this.getSubCategory()
    this.getProducts();
  }
  onChange(event) {
    // if(this.bannerType == 0 || this.bannerType == null || this.bannerType == undefined || this.bannerType == ''){
    //   this.toastrService.warning('Please select banner type first', '', {
    //     timeOut: 1000,
    //     positionClass: 'toast-top-center',
    //   });
    //   // this.myInputVariable.nativeElement.value = '';
    //   $('.overlay-container').addClass('addcontainerdisable');
    //   return false;
    // }

    // this.soaFiles = [];
    const { files, validity } = event.target;
    let size = Math.round(Number(files[0].size) / 1024);
    let image = files[0];
    if (size > 5120) {
      this.toastrService.warning('File size can not greater than 5MB', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center',
      });
      this.removeClass();
       this.myInputVariable.nativeElement.value = '';
      // $('.overlay-container').addClass('addcontainerdisable');
      return false;
    }
    if (files.length > 0) {
      let fr = new FileReader();
      fr.readAsDataURL(image);
      fr.onload = (e) => {
        var img: any = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.txtHeight = img.height;
          this.txtWidth = img.width;
          if (this.bannerType == 206 || this.bannerType == 207) {
            if (this.txtWidth == 315 && this.txtHeight == 155) {
              this.toastrService.success('Uploaded image has valid Height and Width.', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.removeClass();
              // $('.overlay-container').addClass('addcontainerdisable');
              // return false;

            } else {
              this.toastrService.warning('Image should be (width - 315 and height - 155)', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.removeClass();
              this.msg = `your image width is ${this.txtWidth}px and height is ${this.txtHeight}px. Image should be (width - 315 and height - 155)`;
               this.myInputVariable.nativeElement.value = '';
              // $('.overlay-container').addClass('addcontainerdisable');
              return false
            }
          }
          if (this.bannerType == 205) {
            if (this.txtWidth == 1000 && this.txtHeight == 165) {
              this.toastrService.success('Uploaded image has valid Height and Width.', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.removeClass();
              // $('.overlay-container').addClass('addcontainerdisable');
              // return false;
            } else {
              this.toastrService.warning('Image should be (width - 1000 and height - 165)', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.removeClass();
               this.myInputVariable.nativeElement.value = '';
              // $('.overlay-container').addClass('addcontainerdisable');
              return false
            }
          }
          if (this.bannerType == 204) {
            if (this.txtWidth == 770 && this.txtHeight == 375) {
              this.toastrService.success('Uploaded image has valid Height and Width.', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.removeClass();
              // $('.overlay-container').addClass('addcontainerdisable');
              // return false;
            } else {
              this.toastrService.warning('Image should be (width - 770 and height - 375)', '', {
                timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center',
              });
              this.removeClass();
               this.myInputVariable.nativeElement.value = '';
              // $('.overlay-container').addClass('addcontainerdisable');
              return false
            }
          }

          if (validity.valid) {
            if (files.length > 0) {
              this.filename = event.target.files[0].name
              this.soaFiles.push(files[0]);
            }
          } else {
            this.toastrService.warning('Please Upload valid File', '', { timeOut: this.MassageOutTime,
              positionClass: 'toast-top-center'});
              this.removeClass();
            // $('.overlay-container').addClass('addcontainerdisable');
            return false
          }
        };
      };
    }


  }

  openpopup() {
    this.sliderType = '';
    this.txtTitle = '';
    this.txtDescription = '';
    this.visibleFrom = '';
    this.visibleUntil = '';
    this.txtWidth = '';
    this.bannerType = '';
    this.txtHeight = '';
    this.myInputVariable
    this.isActive = '';
    this.alternateName = '';
    this.soaFiles = [];
    $('#myModal').modal('show');
    this.isShow = true;
  }
  Submit() {
    console.log('dkkkkkkkkkkkkkkkkkkkkkkkkkkkkk', this.soaFiles)
    if (this.ideacraftService.checkValidity(this.sliderType)) {
      this.toastrService.warning('Please select sliderType', '', {
        timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    if (this.ideacraftService.checkValidity(this.bannerType)) {
      this.toastrService.warning('Please select Banner Type', '', {
        timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }

    if (
      this.txtTitle == undefined ||
      this.txtTitle == null ||
      this.txtTitle == ''
    ) {
      this.toastrService.warning('Banner Title is required', '', {
        timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    this.category = this.category ? this.category.trim() : '';
    if (this.category == null || this.category == undefined || this.category == '') {
      this.toastrService.warning("Please select category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    
    this.sub_category = this.sub_category ? this.sub_category.trim() : '';
    if (this.sub_category == null || this.sub_category == undefined || this.sub_category == '') {
      this.toastrService.warning("Please select sub-category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // this.selectedProdcut = this.selectedProdcut ? this.selectedProdcut.trim() : '';
    // if (this.selectedProdcut == null || this.selectedProdcut == undefined || this.selectedProdcut == '') {
    //   this.toastrService.warning("Please select product!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }

    // if (this.ideacraftService.checkValidity(this.visibleFrom)) {
    //   this.toastrService.warning('Visible from date is required', '', {
    //     timeOut: this.MassageOutTime,
    //     positionClass: 'toast-top-center'
    //   });
    //   $('.overlay-container').addClass('addcontainerdisable');
    //   this.removeClass();
    //   return false;
    // }
    // if (this.ideacraftService.checkValidity(this.visibleUntil)) {
    //   this.toastrService.warning('Visible until date is required', '', {
    //     timeOut: this.MassageOutTime,
    //             positionClass: 'toast-top-center'
    //   });
    //   $('.overlay-container').addClass('addcontainerdisable');
    //   this.removeClass();
    //   return false;
    // }
    // if (this.ideacraftService.checkValidity(this.visibleFrom)) {
    //   this.toastrService.warning('Visible from date is required', '', {
    //     timeOut: this.MassageOutTime,
    //             positionClass: 'toast-top-center'
    //   });
    //   $('.overlay-container').addClass('addcontainerdisable');
    //   this.removeClass();
    //   return false;
    // }
    if (this.visibleFrom > this.visibleUntil) {
      this.toastrService.warning(
        'Visible until date should be greater than visible from date!',
        '',
        {
          timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
        }
      );
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    if (this.soaFiles.length == 0) {
      this.toastrService.warning('Please upload banner image', '', {
        timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    if (
      this.txtDescription == '' ||
      this.txtDescription == null ||
      this.txtDescription == undefined
    ) {
      this.toastrService.warning('Description is required', '', {
        timeOut: this.MassageOutTime,
        positionClass: 'toast-top-center'
      });
      $('.overlay-container').addClass('addcontainerdisable');
      this.removeClass();
      return false;
    }
    // if (
    //   this.alternateName == '' ||
    //   this.alternateName == null ||
    //   this.alternateName == undefined
    // ) {
    //   this.toastrService.warning('Alternate name is required', '', {
    //     timeOut: this.MassageOutTime,
    //     positionClass: 'toast-top-center'
    //   });
    //   $('.overlay-container').addClass('addcontainerdisable');
    //   this.removeClass();
    //   return false;
    // }
    let data = {
      sliderType: this.sliderType,
      txtTitle: this.txtTitle,
      visibleFrom: this.visibleFrom,
      visibleUntil: this.visibleUntil,
      txtDescription: this.txtDescription,
      isActive: this.isActive,
      txtWidth: this.txtWidth,
      bannertype: this.bannerType,
      txtHeight: this.txtHeight,
      alternateName: this.alternateName,
      category : this.category,
      sub_category: this.sub_category,
      product_id : this.selectedProdcut
    };
    this.formData = new FormData();
    this.formData.append('fileid', this.soaFiles[0].name);
    this.formData.append('files', this.soaFiles[0]);
    this.formData.append('reqData', JSON.stringify(data));

    this.formservice.saveBanner(this.formData).subscribe((res: any) => {
      console.log("resss", res)
      if (res.status == 201) {
        this.toastrService.warning('Banner Title Already Exist', '', {
          timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
        });
        this.removeClass();
      }
      else {
        this.toastrService.success('Banner Added successfully.', '', {
          timeOut: this.MassageOutTime,
                positionClass: 'toast-top-center'
        });
        $('.overlay-container').addClass('addcontainerdisable');
        this.removeClass();
        $('#myModal').modal('hide');
        this.bindNgxDataGrid(this.page, 1);
      }

    });
  }

  bannerDataList: any[] = [];

  CommonGridSearchMaster(ev) {
    this.searchTerm = ev
    this.bindNgxDataGrid(this.page, 1)
    // this.bannerDataList = this.TempbannerDataList.filter((item) =>  item.title ? item.title.toLowerCase().indexOf(ev) !== -1 : false    );
  }
  onDelete(banner) {
    banner = banner.rowData;
    // console.log('banner delete', banner.banner_master_id);
    Swal.fire({
      title: `Are you sure you want to delete this banner ?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService
          .deleteBanner(banner.banner_master_id)
          .subscribe((res) => {
            Swal.fire('Deleted banner successfully!', '', 'success');
            this.bindNgxDataGrid(this.page, 1);
          }
          );
      }
    });

  }

  onBannerTypeChange() {
    this.soaFiles = [];
    this.myInputVariable.nativeElement.value = '';
  }

  getMinDate() {
    let tommorow = new Date().getDate()
    let cur = new Date(new Date().getFullYear(), new Date().getMonth(), tommorow)
    return new Date(cur.getFullYear(), cur.getMonth(), cur.getDate() + 1).toISOString().split('T')[0];
  }

  ngAfterViewChecked() {
    $('.toast-close-button').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
    $('.toast-container').click(function () {
      $('.overlay-container').removeClass('addcontainerdisable');
    });
  }
}
