import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { filter } from 'rxjs/operators';
import { CommonFunctionService } from 'src/app/_services/common-function.service';
import { FormService } from 'src/app/_services/form.service';
import { LoginService } from 'src/app/_services/login.service';
import { ProductService } from 'src/app/_services/product.service';
import { SellerRegistrationService } from 'src/app/_services/seller-registration.service';
import Swal from 'sweetalert2';
import { AppSettings } from '../../_services/AppSetting';
import { debug } from 'console';
declare var $: any;

@Component({
  selector: 'app-add-configurable-product',
  templateUrl: './add-configurable-product.component.html',
  styleUrls: ['./add-configurable-product.component.css']
})
export class AddConfigurableProductComponent implements OnInit {
  @ViewChild('inputFile') myInputVariable: ElementRef;
  categoryList: any = [];
  subCategoryList: any = [];
  category: any;
  countryList: any = [];
  lable: any;
  value: any;
  productSpecifications: any = [];
  isView: boolean = false;
  keyFeatures: any = [];
  key: any;
  price: any;
  mrp: any;
  purchasePrice: any;
  allAttributeList: any = [];
  AttributeLableList: any = [];
  minOrderQuantity: any = 1;
  checkBox: HTMLElement;
  showColorTable: boolean = false;
  selectedList: any = [];
  sellerListEdit:string = 'false';
  attributeCode: any;
  attributeLable: any;
  attributeValueCode: any;
  attributeValue: any;
  allAttributeListValue: any = [];
  AttributeId: any;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  brandName: any;
  productName: any;
  subCategory: any;
  countryId: any;
  manufactureYear: any;
  productTags: any
  productDiscription: any;
  attributeValueId: any;
  maxQuantity: any;
  minQuantity: any;
  bundlePrice: any;
  checkedAttributeValue: any = [];
  sellerId: string;
  showColumn: any = ['#', 'SKU', 'Quantity'];
  tempRemove: any;
  temppAttr: any;
  removeAttribute: any;
  attri_id: any;
  atributeDetails: any = [];
  created_product_id: any;
  product_configuratios: any = [];
  table_col: any = [];
  editable_item_id: any;
  file: any;
  uploadedGalaryFiles: any = [];
  formData: FormData;
  attributeId: any;
  AttributeList: any;
  attributeValue1: any;
  attributeValue2: any;
  attribute1: any;
  attribute2: any;
  addAttributeCombination: any = [];
  AttributeSize: any;
  AttributeColor: any;
  bundlindex:number;
  color: any = null;
  size: any = null;
  count: any = 0;
  hsn: any;
  gstRate: any;
  valueCode: any;
  valueLabel: any;
  selectedAttribute: any;
  brandNameList: any;
  brandCode: string;
  brand_name: any;
  lableList: any[] = [];
  getOrderAcceptList: any[] = [];
  getPricingList: any[] = [];
  asPerOrder: any;
  asPerPrice: any;
  noLimit: boolean = false;
  lableValueName: any;
  breadcrumb: string;
  bundleAtribute: any[] = [];
  brand_id: any;
  imgurl: any[] = []
  gstList: any;
  tempGetOrderAccepList: any;
  globlFileIndex: any;
  userId: any;
  txtHeight: any;
  txtWidth: any;
  yearList: any;
  pricingId: any;
  // showDiv: boolean;
  assign_corp: any;
  assign_marketplace: any;
  tempcoprlist: any = [];
  corp_id: any
  selectedCorpid: any;
  minQty: any;
  maxQty: any;
  qtyprice: any;
  CorpWisepeiseList: any = [];
  showqtyPrice: boolean;
  corpList: any;
  editLabel: boolean;
  editIndex: any;
  editFeaturesIndex: any;
  editFeatures: boolean;
  localurl:any;
  MassageOutTime = AppSettings.getMassageOutTime;
  savedCorporatePriceConfigurationWise: any;
  corporateBundlePriceList: any;
  editCorpBundle: any;
  editCorpBundleIndex: any;
  imagesList: any;
 
  constructor(private sanitizer: DomSanitizer, private productService: ProductService, private loginService: LoginService, private route: ActivatedRoute,
    private formService: FormService, private sellerService: SellerRegistrationService,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,
    private router: Router) { }

  ngOnInit(): void {
     
    this.breadcrumb = localStorage.getItem('breadcrumb');
    // this.sellerId = localStorage.getItem('sellerId')
    this.getOrderAcceptance()
    this.getProductPricing()
    this.getAllCat();
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.userId = localStorage.getItem('userId')
    this.route.queryParams.subscribe((res) => {
      let data = this.loginService.decreptedDataUrl(res)
      // console.log('check detail ', data)

      if (data.length > 1) {
        console.log('data,',typeof data)
        data = data ? JSON.parse(data) : '';
        this.editable_item_id = data.editable_item;
        this.getConfigDetailsByProductID();
        this.sellerListEdit = localStorage.getItem('editinsellerlist');
        
      }else{
        this.getcorp()
      }
        });
        
    this.getAllCountries();
    this.getBrandName();
    this.getGstList();
    this.getYearData();
    $(document).ready(function () {
      $(".progress-bar").css("width", '33%')
    });
  }
  getcorp(temCorpWisepeiseList?) {
    
    this.sellerService.getCorpfromcust().subscribe(res => {
      this.corpList = res;
      console.log('getCorpfromcustgetCorpfromcust', this.corpList)
      if(this.corp_id != null)
      this.getcorporate(temCorpWisepeiseList);

    })
  }


  getcorporate(temCorpWisepeiseList) {
     
    if (temCorpWisepeiseList != '0') {
      this.tempcoprlist = [];
      for (const it of this.corp_id) {
        this.tempcoprlist.push(this.corpList.filter(x => x.add_corporate_id == it)[0])
      }
      const result = this.savedCorporatePriceConfigurationWise.reduce((acc, item) => {
        const { corp_id, ...rest } = item;
        if (!acc[corp_id]) {
          acc[corp_id] = [rest];
        } else {
          acc[corp_id].push(rest);
        }
        return acc;
      },{});
       
      for (const temp of this.tempcoprlist) {
        temp.productCnf = temp.productCnf || [];
        temp.CorpWisepeiseList = this.corporateBundlePriceList.filter(x => x.corp_id == temp.add_corporate_id) || []
        for (const key in result) {
              if(temp.add_corporate_id == key){
                for (const key1 in result[key]) {
                    temp.productCnf.push(result[key][key1]);   
            }
              }

        }
        if( temp.CorpWisepeiseList && temp.CorpWisepeiseList.length>0){
          temp.showqtyPrice = true;
        }
      
          
      }
      // for (let c = 0; c < this.tempcoprlist.length; c++) {
      //   let array = temCorpWisepeiseList.filter(d => d.corp_id == this.tempcoprlist[c]["add_corporate_id"])
      //   if (array.length > 0) {
      //     this.tempcoprlist[c]["Corporate_price"] = array[0]["corp_prise"]
      //   }
      // }
    } else {
       
      for (const it of this.corp_id) {
        let arr = this.tempcoprlist.filter(x => x.add_corporate_id == it);
        if (arr.length == 0) {
          this.tempcoprlist.push(this.corpList.filter(x => x.add_corporate_id == it)[0])
        }
      }

      let finalData = this.tempcoprlist;

      for (let r = 0; r < finalData.length; r++) {
        let arr = this.corp_id.filter(d => d == finalData[r]["add_corporate_id"])
        if (arr.length == 0) {
          this.tempcoprlist = this.tempcoprlist.filter(d => d.add_corporate_id != finalData[r]["add_corporate_id"])
        }
      }

  for (const key of this.tempcoprlist) {
    key.CorpWisepeiseList = key.CorpWisepeiseList || [];
  }

    }
//this.getProductConfig();

  }
  addCorpWisepeise(corpObj) {
    let selectedCorpName: any = '';
      if (corpObj.minQty == null || corpObj.minQty == undefined || corpObj.minQty == '') {
        this.toastrService.warning("Please enter Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corpObj.minQty) <= Number(this.minOrderQuantity)){
        this.toastrService.warning("Minimum quantity must be greater than minimum order quantity", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corpObj.minQty !== undefined && corpObj.minQty !== null && corpObj.minQty !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corpObj.minQty) == false) {
            this.toastrService.warning("Minimum quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if (corpObj.maxQty == null || corpObj.maxQty == undefined || corpObj.maxQty == '') {
        this.toastrService.warning("Please enter Max quantity ", '', {timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corpObj.maxQty) <= 0 ){
        this.toastrService.warning("Maximum quantity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corpObj.maxQty !== undefined && corpObj.maxQty !== null && corpObj.maxQty !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corpObj.maxQty) == false) {
            this.toastrService.warning("Maximum quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ((Number(corpObj.maxQty) < Number(corpObj.minQty)) || (Number(corpObj.maxQty) == Number(corpObj.minQty))) {
        this.toastrService.warning("Max Quantity should be Greater than Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (corpObj.qtyprice == null || corpObj.qtyprice == undefined || corpObj.qtyprice == '') {
        this.toastrService.warning("Please enter price  ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(corpObj.qtyprice <= 0 ){
        this.toastrService.warning("Bundle price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corpObj.qtyprice !== undefined && corpObj.qtyprice !== null && corpObj.qtyprice !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corpObj.qtyprice) == false) {
            this.toastrService.warning("Bundle price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ( corpObj.CorpWisepeiseList.length != 0) {
        let existLabel =  corpObj.CorpWisepeiseList.filter(x => x.minQty == corpObj.minQty && x.maxQty == corpObj.maxQty && x.qtyprice == corpObj.qtyprice)
        if (existLabel.length > 0) {
          this.toastrService.warning("This record already exists", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
        }        
        else {
          let shouldPush = true;
          for (let i = 0; i < corpObj.CorpWisepeiseList.length; i++) {
            if(this.editCorpBundleIndex !=i ){
            if (Number(corpObj.minQty) >= Number(corpObj.CorpWisepeiseList[i].minQty) && Number(corpObj.minQty) <= Number(corpObj.CorpWisepeiseList[i].maxQty)) {
              shouldPush = false;
              this.toastrService.warning("Enter Different range", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
              return;
            } else if (Number(corpObj.maxQty) >= Number(corpObj.CorpWisepeiseList[i].minQty) && Number(corpObj.maxQty) <= Number(corpObj.CorpWisepeiseList[i].maxQty)) {
              shouldPush = false;
              this.toastrService.warning("Enter Different range", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
              return;
            }else if (Number(corpObj.maxQty) > Number(corpObj.CorpWisepeiseList[i].maxQty) && Number(corpObj.minQty) < Number(corpObj.CorpWisepeiseList[i].minQty)) {
              shouldPush = false;
              this.toastrService.warning("Enter Different range", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
              return;
            }
          }
          }
          if (shouldPush) {
            if(this.editCorpBundleIndex>-1){
              corpObj.CorpWisepeiseList[this.editCorpBundleIndex].minQty=corpObj.minQty;
              corpObj.CorpWisepeiseList[this.editCorpBundleIndex].maxQty=corpObj.maxQty;
              corpObj.CorpWisepeiseList[this.editCorpBundleIndex].qtyprice=corpObj.qtyprice;
              this.editCorpBundleIndex=-1;
            }else{
              corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
            }            
           // corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
            corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
            this.selectedCorpid = '';
          console.log("corpObj.CorpWisepeiseList",corpObj.CorpWisepeiseList)
          }

        }
          //corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
          
        }
      else {
        corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
        corpObj.minQty = '';
        corpObj.maxQty = '';
        corpObj.qtyprice = '';
        this.selectedCorpid = '';
        console.log("corpObj.CorpWisepeiseList---",corpObj.CorpWisepeiseList)
      }
    
    
  }
  removeCorpWisepeise(i, obj) {
    Swal.fire({
      title: `Do you want to delete ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        obj.CorpWisepeiseList.splice(i, 1);
     this.toastrService.success("Bundle deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();  
      } else if (result.isDenied) {
        obj.minQty = '';
        obj.maxQty = '';
        obj.qtyprice = '';
      }
    })
  }
  editCorpWisepeise(index, obj) {
    obj.minQty = obj.CorpWisepeiseList[index].minQty;
    obj.maxQty = obj.CorpWisepeiseList[index].maxQty;
    obj.qtyprice = obj.CorpWisepeiseList[index].qtyprice;
    //obj.CorpWisepeiseList.splice(index, 1);
    this.editCorpBundle=true;
    this.editCorpBundleIndex=index;
  }

  getAllCat() {
    this.sellerService.getCategory().subscribe(res => {
      this.categoryList = res;
    })
  }
  getOrderAcceptance() {
    this.productService.getOrderAcceptance().subscribe((res: any) => {
      this.getOrderAcceptList = res;
      this.tempGetOrderAccepList = res
    })
  }
  getGstList() {
    this.productService.getGSTData().subscribe(res => {
      this.gstList = res;
      //console.log("gstdatata", this.gstList)
    })
  }
  getYearData() {
    this.productService.getYearData().subscribe(res => {
      this.yearList = res;
    })
  }
  getProductPricing() {
    this.productService.getProductPricing().subscribe((res: any) => {
      this.getPricingList = res;
      this.pricingId = this.getPricingList[1].id;
    })
  }
  nextedsubcategory:any=[];
  getSpecificationLabel() {
    this.sellerService.getSpecificationLabelList(this.subCategory).subscribe(res => {
      this.lableList = res[0].rows;
      this.lableValueName = res[1].rows
      var lableValues = [];
      for (let obj of this.lableList) {
        lableValues = [];
        lableValues = this.lableValueName.filter(x => x.product_specification_label_id == obj.product_specification_label_id)
        obj.lableValues = lableValues;
        obj.selectedValue = null;

      }
    })
    this.nextedsubcategory=[];
    this.getnextedSubCategory(this.subCategory,0);
  }

  getSubCategory() {
    // console.log('dfjshdfhdskfsdklfjsdklfjdskf', this.category)
    this.sellerService.getSubCategory(this.category).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.subCategoryList = res;
      } else {
        this.subCategoryList = [];
      }
    })
  }
  getnextedSubCategory(sub,val) {
    
    this.nextedsubcategory = this.nextedsubcategory.slice(0,val)
    this.sellerService.getSubCategory(sub).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.nextedsubcategory[val] = {index:val,res:res,selected:undefined};
       // this.subCategoryList = res;
      }     
    })
  }
  categoryChanged() {
    this.getSubCategory();
    this.subCategory = undefined;
    this.nextedsubcategory = [];
    this.lableList = [];
  }
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '7rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',

    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'], ['insertImage'],
      ['insertVideo']
    ]
  };

  getAllCountries() {
    this.productService.getCounty().subscribe((res: any) => {
      this.countryList = res.rows;
    })
  }

  addSpecifications() {
    this.lable = this.lable?.trim();
    this.value = this.value?.trim();
    if (this.editLabel) {
 
      if (this.lable == null || this.lable == undefined || this.lable == '') {
        this.toastrService.warning("Please Enter Label!!!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.value == null || this.value == undefined || this.value == '') {
        this.toastrService.warning("Please Enter Value For Label " + this.lable + '!!!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      let existLabel = this.lableList.filter((x, index) => x.label_name == this.lable )
      if (existLabel.length) {
        this.toastrService.warning("This Label Already exist", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false
      }
      if (this.lableList.length != 0) {
        this.lableList[this.editIndex] = { "label_name": this.lable, "label_valuelist": this.value, 'is_custom': true };
        this.toastrService.success("Label Updated successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.lable = this.value = '';
        this.editLabel = false;
        this.editIndex = ''
      }
    } else {

      if (this.lable == null || this.lable == undefined || this.lable == '') {
        this.toastrService.warning("Please Enter Label!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.value == null || this.value == undefined || this.value == '') {
        this.toastrService.warning("Please Enter Value For Label " + this.lable + '!', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.lableList.length != 0) {
        let existLabel = this.lableList.filter(x => x.label_name == this.lable)
        if (existLabel.length > 0) {
          this.toastrService.warning("This Label Already exist", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
        } else {
          this.lableList.push({ "label_name": this.lable, "label_valuelist": this.value, 'is_custom': true });
          this.toastrService.success("Label added successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
          this.lable = null;
          this.value = null;
          return false;
        }
      } else {
        this.lableList.push({ "label_name": this.lable, "label_valuelist": this.value, 'is_custom': true });
        this.toastrService.success("Label added successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.lable = null;
        this.value = null;
        return false; 

      }
    }
  }
  removeSpecifications(i) {
    // this.lableList.splice(i, 1);
    // this.lable = null;
    // this.value = null;
    // this.editIndex = null;
    // this.editLabel = false;
    Swal.fire({
      title: `Do you want to delete label?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
     this.lableList.splice(i, 1);
     this.toastrService.success("Label deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    this.lable = this.value = '';
    this.editLabel = false;
    this.editIndex = null;
      } else if (result.isDenied) {
        this.lable = this.value = '';
      }
    })
  }
  editSpecifications(index) {
    this.editLabel = true;
    this.editIndex = index;
    this.lable = this.lableList[index].label_name;
    this.value = this.lableList[index].label_valuelist;
    // this.lableList.splice(index, 1);
  }

  addKeyFeatures() {
    this.key = this.key?.trim();
    if(this.editFeatures){
      if(this.key == null || this.key == undefined || this.key == ''){
        this.toastrService.warning("Please enter key features", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      let existFeatures = this.keyFeatures.filter((x, index) => x.key ==this.key );
      if(existFeatures.length > 0){
       this.toastrService.warning("key feature Already Exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
       $(".overlay-container").addClass("addcontainerdisable");
       this.removeClass()
      return false;
      }
      if(this.keyFeatures.length != 0){
        this.keyFeatures[this.editFeaturesIndex]= {"key": this.key};
        this.toastrService.success("Key Feature updated successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        // console.log("this.editFeaturesIndex==>>>", this.editFeaturesIndex)
        this.key  = '';
        this.editFeatures = false;
        this.editFeaturesIndex = '';
        return false;
      }

    }else{ 

    if (this.key == null || this.key == undefined || this.key == '') {
      this.toastrService.warning("Please enter key features!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    let aa = this.keyFeatures.filter(x => x.key ==this.key);
    if(aa.length > 0){
      this.toastrService.warning("key feature Already Exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.keyFeatures.push({ "key": this.key });
    this.toastrService.success("Key Feature added successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    this.key = null;
    return false;
  }
}
  removeKeyFeatures(i) {
    Swal.fire({
      title: `Do you want to delete key Feature?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.keyFeatures.splice(i, 1);
     this.toastrService.success("Key Feature deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    this.key  = '';
    this.editFeatures = false;
    this.editFeaturesIndex = '';
      } else if (result.isDenied) {
        this.key  = '';
      }
    })

  }
  editKeyFeatures(index) {
    this.editFeatures=true;
    this.editFeaturesIndex=index;
    this.key = this.keyFeatures[index].key;
    // this.keyFeatures.splice(index, 1);
  }
  getAttribute() {
    this.productService.getAllAttribute().subscribe(res => {
      if (res.length > 0) {
        this.allAttributeList = res;
      } else {
        this.allAttributeList = [];
      }
    })
  }
  getAllAttributeLable(category) {
    this.productService.getAttributelable(category).subscribe(res => {
      this.AttributeLableList = res.data;
    })
  }
  // selectAttribute(val,attribute_id){
  //   if(this.attribute1 && this.attribute2 && this.attribute1==this.attribute2){
  //     this.toastrService.warning("Please Select Different Attribute", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
  //     if(val==1){
  //       this.attribute1 = null;
  //     }else{
  //       this.attribute2 = null;
  //     }
  //   }else{
  //     this.getAllAttributeValue(val,attribute_id);
  //   }
  // }
  getAllAttributeValue(id) {
    this.productService.getAttributeValue(id).subscribe(res => {
      this.AttributeList = res;
      //this.allAttributeList =res;
      // if(val==1){
      //   this.AttributeColor = res;
      // }else{
      //   this.AttributeSize = res;
      // }
      this.AttributeColor= this.AttributeList.filter(x => x.attribute_id == 1)

      this.AttributeSize = this.AttributeList.filter(x => x.attribute_id == 2)
    })
  }
  //  add(){
  //   if(this.color || this.size)
  //     this.addAttributeCombination.push({ "color": this.color, "size": this.size });
  //     else{
  //       this.toastrService.warning("Please add atleast one attribute", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
  //       return false
  //     }

  //     this.color = null;
  //     this.size = null;
  //   }
  add() {

    if ((this.color == null || this.color == '' || this.color == undefined) && (this.size == null || this.size == '' || this.size == undefined)) {
      this.toastrService.warning("Please Select Atleast one Attribute !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.addAttributeCombination.length != 0) {
      for (let obj of this.addAttributeCombination) {
        if (obj.color == this.color && obj.size == this.size) {
          this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          this.color = null;
          this.size = null;
          return false;
        }
      }
      this.addAttributeCombination.push({ "color": this.color, "size": this.size });
      this.color = null;
      this.size = null;
    } else {
      this.addAttributeCombination.push({ "color": this.color, "size": this.size });
      this.color = null;
      this.size = null;
    }
  }
  removeConfig(i) {
    if (this.addAttributeCombination[i].isSaved) {
      Swal.fire({
        title: `Do you want to delete Attributes?`,
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          let sqlQry = `delete from z_entitydtl_product_configuration_001 where product_configuration_id = ${this.addAttributeCombination[i].product_configuration_id};
          delete from z_entitydtl_corpwise_product_prise_001 where configuration_id = ${this.addAttributeCombination[i].product_configuration_id}`
          this.formService.getFormData(sqlQry).subscribe(res => {
          this.addAttributeCombination.splice(i, 1);
        })
       this.toastrService.success("Attributes deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
        } else if (result.isDenied) {
          
        }
      })   
     
    } else {
      Swal.fire({
        title: `Do you want to delete Attributes?`,
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
        this.addAttributeCombination.splice(i, 1);
       this.toastrService.success("Attributes deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
        } else if (result.isDenied) {
          
        }
      })     
    }
  }
  reset() {
    this.color = null;
    this.size = null;
  }
  openAttributeModel() {
    $('#myModal').modal('show');
    this.attributeCode = null;
    this.attributeLable = null;
  }
  openAttributeValueModel(attribute_id) {
    this.AttributeId = attribute_id;
    $('#myModal2').modal('show');
  }

  addAttribute() {
    if (this.selectedAttribute == null || this.selectedAttribute == '' || this.selectedAttribute == undefined) {
      this.toastrService.warning("Please Select Attribute !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('#myModal').modal('show');
      $(".overlay-container").addClass("addcontainerdisable"); this.removeClass()

      this.removeClass()
      return false;
    }
    this.valueCode = this.valueCode?.trim();
    if (this.valueCode == null || this.valueCode == '' || this.valueCode == undefined) {
      this.toastrService.warning("Please Enter ValueCode!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('#myModal').modal('show');
      $(".overlay-container").addClass("addcontainerdisable"); this.removeClass()

      this.removeClass()
      return false;
    }
    this.valueCode = this.valueCode?.trim();
    if (this.valueLabel == null || this.valueLabel == '' || this.valueLabel == undefined) {
      this.toastrService.warning("Please Enter ValueLabel!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $('#myModal').modal('show');
      $(".overlay-container").addClass("addcontainerdisable"); this.removeClass()

      this.removeClass()
      return false;
    }
    this.productService.addProductAttributeValue(this.category,this.selectedAttribute, this.valueCode, this.valueLabel).subscribe((res: any) => {
       
      if (res.status == 201) {
        this.toastrService.warning("Attribute Already Exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
      }
      else {
        this.toastrService.success("Attribute added Successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        $('#myModal').modal('hide');
        this.selectedAttribute = this.valueCode = this.valueLabel = ''
        this.getAllAttributeValue(this.category);
        this.closeAttributeModel()
      }
    })
  }
  closeAttributeModel() {
    this.selectedAttribute = null;
    this.valueCode = '';
    this.valueLabel = '';
  }
  resetAttributeValue() {
    this.selectedAttribute = null
    this.valueCode = null
    this.valueLabel = null
  }
  // checkedAttribute(event, attributeId) {
  //   this.attri_id = attributeId
  //   if (event.target.checked == true) {
  //     this.showColorTable = true;
  //     if(this.selectedList.filter(x=>x==attributeId).length==0){
  //       this.selectedList.push(attributeId);
  //     }
  //     this.temppAttr = this.allAttributeList.filter(x => x.attribute_id == attributeId);
  //     this.showColumn.push(this.temppAttr[0].attribute_lable)
  //     this.getAttributeValue(attributeId)
  //   }
  //   else {
  //     this.tempRemove = this.allAttributeList.filter(x => x.attribute_id == attributeId)
  //     this.showColumn = this.arrayRemove(this.showColumn, this.tempRemove[0].attribute_lable)
  //     this.showColorTable = false;
  //     this.selectedList = this.arrayRemove(this.selectedList, attributeId);

  //     this.allAttributeListValue = this.allAttributeListValue.filter(x => x.attribute_id != attributeId);
  //     this.getAttributeValue(null)
  //   }
  // }

  selectedAttributeValue(event, value_id, attr_id) {
    if (event.target.checked == true) {
      let tempData = this.checkedAttributeValue.filter(x => x.value_id == value_id);
      if (tempData.length == 0) {
        this.checkedAttributeValue.push({ 'attribute_id': attr_id, 'value_id': value_id });
      }
    } else {
      this.checkedAttributeValue = this.checkedAttributeValue.filter(x => x.value_id !== value_id)
    }
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }
  // getAttributeValue(attributeId) {
  //   this.productService.getAllAttributeValue(JSON.stringify(this.selectedList)).subscribe((res:any) => {
  //     let tempData = res;
  //     let tempAttr, tempVal;
  //     if (attributeId) {
  //       let temp = this.allAttributeListValue.filter(x => x.attribute_id == attributeId);
  //       if (temp.length == 0) {
  //         tempAttr = this.allAttributeList.filter(x => x.attribute_id == attributeId);
  //         tempVal = tempData.filter(x => x.attribute_id == attributeId);
  //         this.allAttributeListValue.push({ 'attribute_id': tempAttr[0].attribute_id, 'attribute_lable': tempAttr[0].attribute_lable, 'attribute_value': tempVal })
  //         }

  //     }
  //   })
  // }
  // addAttributeValue() {
  //   this.productService.addAttributeValue(this.AttributeId, this.attributeValueCode,
  //     this.attributeValue).subscribe((res:any) => {
  //       if (res.status == 201) {
  //         this.toastrService.warning("Attribute Value Already Exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
  //         $(".overlay-container").addClass("addcontainerdisable");
  //         this.removeClass()

  //       }
  //       else {
  //         this.toastrService.success("Attribute Value Add Successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
  //         $(".overlay-container").addClass("addcontainerdisable");
  //         this.removeClass()
  //         this.getAttributeValue(null)
  //         this.AttributeId = null;
  //       }
  //     })
  // }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  saveBasicConfiguration() {
    // console.log('this.assign_corp',this.assign_corp)
    //  console.log('this.addKeyFeatures.length',this.addKeyFeatures.length)
    if((this.assign_marketplace == null || this.assign_marketplace == undefined || this.assign_marketplace == '') &&(this.assign_corp == null || this.assign_corp == undefined)){
      this.toastrService.warning("Please select assign product!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.assign_corp == true) {
      if (this.corp_id == null || this.corp_id == undefined || this.corp_id == '') {
        this.toastrService.warning("Please select corporate name", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    // if (this.tempcoprlist > 0) {
    //   console.log('this.tempcoprlist==========',this.tempcoprlist)
    //   for(let obj of this.tempcoprlist){
    //   if (obj.Corporate_price == null || obj.Corporate_price == undefined || obj.Corporate_price == '') {
    //   this.toastrService.warning("Please add corporate wise price", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    //   }
    //   }
    // }
    let a = this.brandNameList.filter(x => x.brand_name_id == this.brand_id)
    this.brand_id = a[0] ? a[0].brand_name_id : null;
    this.brandName = a[0] ? a[0].name : null;

    this.brandName = this.brandName?.trim();
    if (this.brandName == null || this.brandName == undefined || this.brandName == '') {
      this.toastrService.warning("Please Enter Brand Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.productName = this.productName?.trim();
    if (this.productName == null || this.productName == undefined || this.productName == '') {
      this.toastrService.warning("Please Enter Product Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.hsn = this.hsn?.trim();

    if (this.hsn == null || this.hsn == undefined || this.hsn == '') {
      this.toastrService.warning("Please Enter HSN No!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.hsn !== undefined && this.hsn !== null && this.hsn !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.hsn) == false) {
          this.toastrService.warning("HSN No. Should be Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    if (this.hsn?.length < 4 || this.hsn?.length > 8) {
      this.toastrService.warning("HSN No. should be four to Eight digit!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.gstRate = this.gstRate?.trim();
    if (this.gstRate == null || this.gstRate == undefined || this.gstRate == '') {
      this.toastrService.warning("Please Enter GST Rate!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.minOrderQuantity == null || this.minOrderQuantity == undefined || this.minOrderQuantity == '') {
      this.toastrService.warning("Please Enter Minimum Order Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.minOrderQuantity !== undefined && this.minOrderQuantity !== null && this.minOrderQuantity !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.minOrderQuantity) == false) {
          this.toastrService.warning("Minimum Order Quantity Should be Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    // this.category = this.category?.trim();
    if (this.category == null || this.category == undefined || this.category == '') {
      this.toastrService.warning("Please Select Category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }

    if (this.subCategory == null || this.subCategory == undefined || this.subCategory == '') {
      this.toastrService.warning("Please Select SubCategory!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.countryId == null || this.countryId == undefined || this.countryId == '') {
      this.toastrService.warning("Please select country of manufacture!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.manufactureYear == null || this.manufactureYear == undefined || this.manufactureYear == '') {
      this.toastrService.warning("Please select manufacture year!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // if (this.lableList.length == 0) {
    //   this.toastrService.warning("Please Add Label & values!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }
    for (let list of this.lableList) {
      if (list.is_custom == true)
        continue;
      if (list.selectedValue == null) {
        this.toastrService.warning("Please Select All Specification Values!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    if(this.nextedsubcategory.length>0){
      let nextedcategoryvalue = (this.nextedsubcategory[this.nextedsubcategory.length-1].selected);
      let nextsub =  nextedcategoryvalue? (typeof nextedcategoryvalue === "string")?nextedcategoryvalue.trim():nextedcategoryvalue:'';
      if (nextsub == null || nextsub == undefined || nextsub == '') {
       this.toastrService.warning("Please select nexted sub-category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
       $(".overlay-container").addClass("addcontainerdisable");
       this.removeClass()
       return false;
      }else{
        this.subCategory = nextsub
      }
     }
    // if(this.key == null || this.key == undefined || this.key == ''){
    //   this.toastrService.warning("Please Add Key Feature!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false; 
    // }
    if (this.keyFeatures.length == 0) {
      this.toastrService.warning("Please Add Key Feature!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    //this.productDiscription = this.productDiscription?.trim();
    if (this.productDiscription == null || this.productDiscription == undefined || this.productDiscription == '') {
      this.toastrService.warning("Please Enter Product Description!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }


    setTimeout(() => {
      $('#step2').addClass('active');
      $(".progress-bar").css("width", '66%')
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
    //  this.getAllAttributeLable(this.category);
    //this.selectAttribute(this.category);
   // this.selectAttribute(this.category);
    this.getAttribute();
    this.getAllAttributeValue(this.category);
     // this.getAllAttributeValue();
    }, 100)
  }
  onChange(event) {
    if (event.target.checked) {
      this.asPerPrice = this.pricingId
    } else {
      this.asPerPrice = 0
    }
    // this.showDiv = event.target.checked

  }
  onFileChnage(event) {
   
    const { files, validity } = event.target;
    if (validity.valid) {
      if (files.length > 0) {
        for (let lst of files) {
          let size = Math.round(Number(files[0].size) / 1024)
          var fname = lst.name;
          var re = /(\.jpg|\.jpeg|\.png)$/i;
          if (!re.exec(fname)) {
            this.toastrService.warning("File type not supported!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          var extarr = lst.name.split(".");
          if (extarr.length > 2) {
            this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          if (size > 5120) {
            this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          if (this.uploadedGalaryFiles.length == 10) {
            this.toastrService.warning("You can upload maximum 10 files", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
         // this.file = files;
         let fr = new FileReader();
         fr.readAsDataURL(lst);
         fr.onload = (e) => {
          var img: any = new Image();
          img.src = e.target.result;
          img.onload = () => {
            this.txtHeight = img.height;
            this.txtWidth = img.width;
            if (this.txtWidth == 1500 && this.txtHeight == 1500) {                    
                    if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == lst.name)).length > 0) {
                      this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
                      $(".overlay-container").addClass("addcontainerdisable");
                      this.removeClass()
                      this.myInputVariable.nativeElement.value = '';
                      return false;
                    }
                    this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': img.src, "file": lst, "file_name": lst.name, "file_size": Math.round(Number(lst.size) / 1024) });
                    img.src = ''
                    img.src = ''
                    this.toastrService.success('Uploaded image successfully.', '', {
                      timeOut: this.MassageOutTime,
                      positionClass: 'toast-top-center',
                    });
                    $(".overlay-container").addClass("addcontainerdisable");
                    this.removeClass()
                  } else {
                    this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
                    this.myInputVariable.nativeElement.value = '';
                    $(".overlay-container").addClass("addcontainerdisable");
                    this.removeClass()
                    return false
                  }
                  
                }
                
                
          }
        //  let reader = new FileReader();
        //  reader.readAsDataURL(lst);
        //   reader.onload = (e) => {
        //     
        //    this.tempFilePath = e.target.result;
        //    var img: any = new Image();
        //     img.src = e.target.result;
        //    console.log("img.height",img.src)
            // img.onload = () => {
            //   
            // this.txtHeight = img.height;
            //   this.txtWidth = img.width;
            //   console.log("txtHight",this.txtHeight,this.txtWidth)
            //   if (this.txtWidth == 1500 && this.txtHeight == 1500) {
            //     this.toastrService.success('Uploaded image successfully.', '', {
            //       timeOut: this.MassageOutTime,
            //       positionClass: 'toast-top-center',
            //     });
            //     $(".overlay-container").addClass("addcontainerdisable");
            //     this.removeClass()
            //   } else {
            //     this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            //     $(".overlay-container").addClass("addcontainerdisable");
            //     this.removeClass()
            //     return false
            //   }
            // }
            // if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == lst.name)).length > 0) {
            //   console.log(this.uploadedGalaryFiles)
            //   this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            //   $(".overlay-container").addClass("addcontainerdisable");
            //   this.removeClass()
            //   this.myInputVariable.nativeElement.value = '';
            //   return false;
            // }
            // this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': this.tempFilePath, "file": lst, "file_name": lst.name, "imgUrl": this.localurl, "file_size": Math.round(Number(lst.size) / 1024) });
            // this.tempFilePath = ''
            // //img.src = ''
         // };
        }
        //var fname = files[0].name;
        //var re = /(\.jpg|\.jpeg|\.png)$/i;
        // if (!re.exec(fname)) {
        //   this.toastrService.warning("File type not supported!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //   $(".overlay-container").addClass("addcontainerdisable");
        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // var extarr = files[0].name.split(".");
        // if (extarr.length > 2) {
        //   this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //   $(".overlay-container").addClass("addcontainerdisable");
        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // if (size > 5120) {
        //   this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //   $(".overlay-container").addClass("addcontainerdisable");
        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // this.file = files;
        // var reader = new FileReader();
        // reader.readAsDataURL(this.file[0]);
        // reader.onload = (e) => {
        //   this.tempFilePath = e.target.result;
        //   var img: any = new Image();
        //   img.src = e.target.result;
        //   img.onload = () => {
        //   this.txtHeight = img.height;
        //   this.txtWidth = img.width;         
        //     if (this.txtWidth == 1500 && this.txtHeight == 1500) {
        //       this.toastrService.success('Uploaded image successfully.', '', {
        //         timeOut: this.MassageOutTime,
        //         positionClass: 'toast-top-center',
        //       });
        //       $(".overlay-container").addClass("addcontainerdisable");
        //       this.removeClass()
        //     } else {
        //       this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //       $(".overlay-container").addClass("addcontainerdisable");
        //       this.removeClass()                         
        //       return false
        //     }
        //   }
        // };
      }
    }
  }
  // onFileChnage(event, product_id, product_configuration_id, i) {
  //   this.globlFileIndex = i
  //   const { files, validity } = event.target;
  //   let size = Math.round(Number(files[0].size) / 1024)
  //   if (validity.valid) {
  //     if (files.length > 0) {
  //       var fname = files[0].name;
  //       var re = /(\.jpg|\.jpeg|\.png)$/i;
  //       if (!re.exec(fname)) {
  //         this.toastrService.warning("File Type Not Supported!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
  //         $(".overlay-container").addClass("addcontainerdisable");
  //         this.removeClass()
  //         this.myInputVariable.nativeElement.value = '';
  //         return false;
  //       }
  //       var extarr = files[0].name.split(".");
  //       if (extarr.length > 2) {
  //         this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
  //         $(".overlay-container").addClass("addcontainerdisable");
  //         this.removeClass()
  //         this.myInputVariable.nativeElement.value = '';
  //         return false;
  //       }
  //       if (size > 5120) {
  //         this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
  //         $(".overlay-container").addClass("addcontainerdisable");
  //         this.removeClass()
  //         this.myInputVariable.nativeElement.value = '';
  //         return false;
  //       }
  //       this.file = files;
  //       var reader = new FileReader()
  //       reader.readAsDataURL(this.file[0])
  //       reader.onload = (e) => {

  //         var img: any = new Image();
  //         img.src = e.target.result;
  //         img.onload = () => {
  //           this.txtHeight = img.height;
  //           this.txtWidth = img.width;
  //           if (this.txtWidth == 1500 && this.txtHeight == 1500) {
  //             this.product_configuratios[i].imgurl = e.target.result;
  //             this.product_configuratios[i].imgUploaded = true;
  //             this.uploadedGalaryFiles.push(files[0]);
  //             this.onUpload(product_id, product_configuration_id);
  //           } else {
  //             this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
  //             this.myInputVariable.nativeElement.value = '';
  //             $(".overlay-container").addClass("addcontainerdisable");
  //            this.removeClass()
  //             return false
  //           }
  //         }
  //       }

  //     }
  //   }
  // }

  onUpload(product_id, product_configuration_id) {
    this.formData = new FormData();
    let filename = this.uploadedGalaryFiles[0].name;
    this.formData.append("filename", filename);
    this.formData.append("files", this.uploadedGalaryFiles[0]);
    this.formData.append("createdby", this.userId);
    this.formData.append("product_id", product_id);
    this.formData.append("product_configuration_id", product_configuration_id);


    this.productService.addConfigProductImage(this.formData).subscribe((res: any) => {
      if (res.status == 200) {
        this.product_configuratios[this.globlFileIndex].img_path = res.result[0].document_path;
        this.product_configuratios[this.globlFileIndex].img_name = res.result[0].orignal_file_name;
        this.toastrService.success("Uploaded image successfully.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.removeClass()
        this.uploadedGalaryFiles = [];
        this.formData.delete('filename');
        this.formData.delete('files');
        this.formData.delete('product_id');
        this.formData.delete('product_configuration_id');
      }
      return res
    })
  }
  deleteFile(id) {
    this.myInputVariable.nativeElement.value = '';
    this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
  }
  deleteFromDb(id, imgid) {
    Swal.fire({
      title: `Are you sure you want to delete?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
    this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
    let sqlQry = `delete from z_entitydtl_product_images_001 where product_id = ${localStorage.getItem("editable-item")} and image_id = ${imgid}`
    this.formService.getFormData(sqlQry).subscribe(res => {
      Swal.fire('Image deleted successfully!', '', 'success');
      //console.log('yes successfully delete this')
    })
      }else if (result.isDenied) {
        
      }
    });  
  
    // this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
    // let sqlQry = `delete from z_entitydtl_product_images_001 where  image_id = ${imgid}`
    // this.formService.getFormData(sqlQry).subscribe(res => {
    // })
  }
  saveAttributeDetail() {
    if (this.assign_corp == true) {
      if (this.corp_id == null || this.corp_id == undefined || this.corp_id == '') {
        this.toastrService.warning("Please select corporate name", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        return false;
      }
    }
    // for (const obj of this.getOrderAcceptList) {
    //   obj.attr = false
    // }
    let a = this.brandNameList.filter(x => x.brand_name_id == this.brand_id)
    this.brand_id = a[0].brand_name_id;
    this.brandName = a[0].name;

    this.brandName = this.brandName?.trim();
    if (this.brandName == null || this.brandName == undefined || this.brandName == '') {
      this.toastrService.warning("Please Enter Brand Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    this.productName = this.productName?.trim();
    if (this.productName == null || this.productName == undefined || this.productName == '') {
      this.toastrService.warning("Please Enter Product Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    this.hsn = this.hsn?.trim();
    if (this.hsn == null || this.hsn == undefined || this.hsn == '') {
      this.toastrService.warning("HSN is required!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    if (this.hsn?.length < 4 || this.hsn?.length > 8) {
      this.toastrService.warning("HSN No. should be four to Eight digit!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.gstRate = this.gstRate?.trim();
    if (this.gstRate == null || this.gstRate == undefined || this.gstRate == '') {
      this.toastrService.warning("GST Rate is required!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    this.category = this.category?.trim();
    if (this.category == null || this.category == undefined || this.category == '') {
      this.toastrService.warning("Please Select Category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }

    if (this.countryId == null || this.countryId == undefined || this.countryId == '') {
      this.toastrService.warning("Please select country of manufacture!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.manufactureYear == null || this.manufactureYear == undefined || this.manufactureYear == '') {
      this.toastrService.warning("Please select manufacture year!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.minOrderQuantity == null || this.minOrderQuantity == undefined || this.minOrderQuantity == 0) {
      this.toastrService.warning("Please Enter Minimum Order Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    //this.productDiscription = this.productDiscription?.trim();
    if (this.productDiscription == null || this.productDiscription == undefined || this.productDiscription == '') {
      this.toastrService.warning("Please Enter Product Discription!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      return false;
    }
    // if (this.uploadedGalaryFiles.length <= 0) {
    //   this.toastrService.warning("Please enter atleast 1 product images!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    // }
    if (this.addAttributeCombination.length == 0) {
      if ((this.color == null || this.color == '' || this.color == undefined) && (this.size == null || this.size == '' || this.size == undefined)) {
        this.toastrService.warning("Please Select Atlest one Attribute !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
    }
    for (let list of this.lableList) {
      if (list.is_custom == true)
        continue;
      if (list.selectedValue == null) {
        this.toastrService.warning("Please Select All Specification Values!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    if(this.nextedsubcategory.length>0){
      let nextedcategoryvalue = (this.nextedsubcategory[this.nextedsubcategory.length-1].selected);
      let nextsub =  nextedcategoryvalue? (typeof nextedcategoryvalue === "string")?nextedcategoryvalue.trim():nextedcategoryvalue:'';
      if (nextsub == null || nextsub == undefined || nextsub == '') {
       this.toastrService.warning("Please select nexted sub-category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
       $(".overlay-container").addClass("addcontainerdisable");
       this.removeClass()
       return false;
      }else{
        this.subCategory = nextsub
      }
     }
    $("#save-btn").addClass('disabled');
     
    var productDetails: any = {
     userId: localStorage.getItem('userId'),
     brand_name: this.brandName,
     brand_id : this.brand_id,
     product_name: this.productName,
     category: this.category,
     sub_category:this.subCategory,
     country:this.countryId,
     manufacture_year:this.manufactureYear,
     product_tags:this.productTags,
     lableList :JSON.stringify(this.lableList),
     key_features: JSON.stringify(this.keyFeatures),
     product_discription:this.productDiscription,
     addAttributeCombination:JSON.stringify(this.addAttributeCombination),
     seller_id:this.sellerId,
     editable_item_id: this.editable_item_id || this.created_product_id,
     hsn:this.hsn,
     gstRate:this.gstRate,
     minOrderQty:this.minOrderQuantity,
     corp_id:JSON.stringify(this.corp_id)?JSON.stringify(this.corp_id):null,
     assign_marketplace:this.assign_marketplace?this.assign_marketplace:false,
     assign_corp:this.assign_corp?this.assign_corp:false,
     tempcoprlist:JSON.stringify(this.tempcoprlist),
     CorpWisepeiseList: JSON.stringify(this.CorpWisepeiseList),
     sellerListEdit :this.sellerListEdit,
     isAdmin:true
    };
    
    this.formData = new FormData();
    var docNames = [];
    //console.log(this.uploadedGalaryFiles);

    this.formData.append("productDetails", JSON.stringify(productDetails));
    //console.log(this.uploadedGalaryFiles);

    // if (this.uploadedGalaryFiles.length > 0) {
    //   for (var i = 0; i < this.uploadedGalaryFiles.length; i++) {
    //     this.formData.append('files', this.uploadedGalaryFiles[i]['file']);

    //   }
    // }
    this.productService.saveConfigDetail(this.formData).subscribe(res => {
     
      //this.isLoading = false;
      if (res.status == 200) {
        $("#save-btn").removeClass('disabled');
        this.toastrService.success(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.created_product_id = res.product_id;
        this.getProductConfig()
        // this.toastrService.success("Successfully Saved", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'})
        // $(".overlay-container").addClass("addcontainerdisable");  
        let qry = ` select attribute_value1 color, attribute_value2 size, product_configuration_id from z_entitydtl_product_configuration_001 where product_id=${this.created_product_id};`
          this.formService.getFormData(qry).subscribe(res => {
            this.addAttributeCombination = res.rows;
            for (const att of this.addAttributeCombination) {
              att.isSaved = true
            }
          })
         
          setTimeout(() => {
            $('#step3').addClass('active');
            $(".progress-bar").css("width", '100%')
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
          }, 100)
         
        this.reset();
        //this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
      }
      if (res.status == 201) {
        $("#save-btn").removeClass('disabled');
        this.toastrService.error(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        // this.reset();
      }
      if (res.status == 207) {
        $("#save-btn").removeClass('disabled');
        this.toastrService.error(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        // this.reset();
      }
    })
    //     for (const atr of this.addAttributeCombination) {
    //       if(atr.color && atr.size){
    //         for (const both of this.getOrderAcceptList) {
    //           both.attr = true;
    //         }
    //       }
    //       if(atr.color && !atr.size){
    //          for (const both of this.getOrderAcceptList) {
    //           if(both.id == 213){
    //             both.attr = true;
    //           }
    //       }
    //       }
    //       if(!atr.color && atr.size ){
    //         for (const both of this.getOrderAcceptList) {
    //           if(both.id == 212){
    //             both.attr = true;
    //           }
    //       }
    //   }
    // } 

    // this.productService.saveConfigDetail(this.brandName, this.brand_id, this.productName, this.category, this.subCategory, this.countryId,
    //   this.manufactureYear, this.productTags, JSON.stringify(this.lableList), JSON.stringify(this.keyFeatures),
    //   this.productDiscription, JSON.stringify(this.addAttributeCombination), this.sellerId, this.editable_item_id, this.hsn, this.gstRate, this.minOrderQuantity, JSON.stringify(this.corp_id) ? JSON.stringify(this.corp_id) : null, this.assign_marketplace ? this.assign_marketplace : false, this.assign_corp ? this.assign_corp : false, JSON.stringify(this.tempcoprlist), JSON.stringify(this.CorpWisepeiseList)).subscribe((res: any) => {
    //     // console.log("res====>>>>", res)
    //     if (res.status == 200) {
    //       this.created_product_id = res.product_id;
    //       this.getProductConfig()
    //       this.toastrService.success("Successfully Saved", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
    //       let qry = ` select attribute_value1 color, attribute_value2 size, product_configuration_id from z_entitydtl_product_configuration_001 where product_id=${this.editable_item_id};`
    //       this.formService.getFormData(qry).subscribe(res => {
    //         this.addAttributeCombination = res.rows;
    //         for (const att of this.addAttributeCombination) {
    //           att.isSaved = true
    //         }
    //       })
    //       $(".overlay-container").addClass("addcontainerdisable");
    //       setTimeout(() => {
    //         $('#step3').addClass('active');
    //         $(".progress-bar").css("width", '100%')
    //         this.step1 = false;
    //         this.step2 = false;
    //         this.step3 = true;
    //       }, 100)
    //     }
    //   })

  }

  getAttributeDetail() {
    // this.allAttributeListValue = [];
    //this.showColumn = ['#', 'SKU', 'Quantity']
    setTimeout(() => {
      $('#step2').addClass('active');
      $(".progress-bar").css("width", '66%')
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
    }, 100)
  }
  onPreviousButton() {
    this.editable_item_id = this.created_product_id;
    // this.productService.saveConfigDetail(this.brandName,this.brand_id,this.productName,this.category,this.subCategory,this.countryId,
    //   this.manufactureYear,this.productTags,JSON.stringify(this.lableList), JSON.stringify(this.keyFeatures),
    //  this.productDiscription,JSON.stringify(this.addAttributeCombination),this.sellerId,this.editable_item_id, this.hsn, this.gstRate,this.minOrderQuantity).subscribe((res) => {

    setTimeout(() => {
      $('#step2').addClass('active');
      $(".progress-bar").css("width", '66%')
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
    }, 100)


  }

  getBasicConfiguration() {
    setTimeout(() => {
      $('#step1').addClass('active');
      $(".progress-bar").css("width", '33%')
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
    }, 100)
  }
  CorporateChange() {
    //console.log('this.assign_corp',this.assign_corp)
    if (this.assign_corp == false) {
      this.tempcoprlist = [];
      this.corp_id = null;
      this.CorpWisepeiseList = [];
      this.showqtyPrice = false;
    }
    //else{

    // if(this.corp_id != null && this.corp_id != undefined && this.corp_id != 'undefined')
    //  this.getcorporate('0');
    // }

  }
  addBundleQuantity() {
        if (this.noLimit) {
          this.maxQuantity = null;
          if ((this.minQuantity == null || this.minQuantity == '' || this.minQuantity == undefined)) {
            this.toastrService.warning("Please Enter Min Quantity !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if(this.minQuantity < 0){
            this.toastrService.warning("Min quanity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass();
            return false; 
          }
          if (this.minQuantity !== undefined && this.minQuantity !== null && this.minQuantity !== "") {
            let pattrn = /^[0-9]+$/;
              if (pattrn.test(this.minQuantity) == false) {
                this.toastrService.warning("Min quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
                $(".overlay-container").addClass("addcontainerdisable");
                 this.removeClass();
                return false; 
            }
          }
          if ((this.bundlePrice == null || this.bundlePrice == '' || this.bundlePrice == undefined)) {
            this.toastrService.warning("Please Enter Bundle Price !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if(this.bundlePrice < 0){
            this.toastrService.warning("Bundle Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass();
            return false; 
          }
          if (this.bundlePrice !== undefined && this.bundlePrice !== null && this.bundlePrice !== "") {
            let pattrn = /^[0-9]+$/;
              if (pattrn.test(this.bundlePrice) == false) {
                this.toastrService.warning("Bundle price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
                $(".overlay-container").addClass("addcontainerdisable");
                 this.removeClass();
                return false; 
            }
          }
          if (((Number(this.minOrderQuantity) >= Number(this.minQuantity)))) {
            this.toastrService.warning("Min Quantity in Bundle should be greater than Min Order Quantity", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if (this.bundleAtribute.length != 0) {
            for (let i =0;this.bundleAtribute.length>i;i++) {
              let obj = this.bundleAtribute[i];
              if(this.bundlindex !=i ){
              if (obj.min_qty == this.minQuantity && obj.price == this.bundlePrice) {
                this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
                return false;
              }
              if (obj.no_limit == this.noLimit) {
                this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
                return false;
              }
            }
            }
            
            if(this.bundlindex>-1){
              this.bundleAtribute[this.bundlindex].min_qty=this.minQuantity;
            //  this.bundleAtribute[this.bundlindex].max_qty=this.maxQuantity;
              this.bundleAtribute[this.bundlindex].price=this.bundlePrice;
              this.bundlindex=-1;
            }else{
              this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
            }
            //this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
            this.minQuantity = null;
            this.maxQuantity = null;
            this.bundlePrice = null;
            this.noLimit = null;
          } else {
           
            this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
            this.minQuantity = null;
            this.maxQuantity = null;
            this.bundlePrice = null;
            this.noLimit = null;
    
          }
        } else {
          if ((this.minQuantity == null || this.minQuantity == '' || this.minQuantity == undefined)) {
            this.toastrService.warning("Please Enter Min Quantity !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if(this.minQuantity < 0){
            this.toastrService.warning("Min quanity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass();
            return false; 
          }
          if (this.minQuantity !== undefined && this.minQuantity !== null && this.minQuantity !== "") {
            let pattrn = /^[0-9]+$/;
              if (pattrn.test(this.minQuantity) == false) {
                this.toastrService.warning("Min quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
                $(".overlay-container").addClass("addcontainerdisable");
                 this.removeClass();
                return false; 
            }
          }
          if ((this.maxQuantity == null || this.maxQuantity == '' || this.maxQuantity == undefined)) {
            this.toastrService.warning("Please Enter Max Quantity !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if(this.maxQuantity < 0){
            this.toastrService.warning("Max quanity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass();
            return false; 
          }
          if (this.maxQuantity !== undefined && this.maxQuantity !== null && this.maxQuantity !== "") {
            let pattrn = /^[0-9]+$/;
              if (pattrn.test(this.maxQuantity) == false) {
                this.toastrService.warning("Max quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
                $(".overlay-container").addClass("addcontainerdisable");
                 this.removeClass();
                return false; 
            }
          }
          if ((this.bundlePrice == null || this.bundlePrice == '' || this.bundlePrice == undefined)) {
            this.toastrService.warning("Please Enter Bundle Price !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if(this.bundlePrice < 0){
            this.toastrService.warning("Bundle Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass();
            return false; 
          }
          if (this.bundlePrice !== undefined && this.bundlePrice !== null && this.bundlePrice !== "") {
            let pattrn = /^[0-9]+$/;
              if (pattrn.test(this.bundlePrice) == false) {
                this.toastrService.warning("Bundle price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
                $(".overlay-container").addClass("addcontainerdisable");
                 this.removeClass();
                return false; 
            }
          }
          if (((Number(this.minOrderQuantity) >= Number(this.minQuantity)))) {
            this.toastrService.warning("Please Enter Min quantity greater to minimum order quantity", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if ((Number(this.maxQuantity) < Number(this.minQuantity)) || (Number(this.maxQuantity) == Number(this.minQuantity))) {
            this.toastrService.warning("Max Quantity should be Greater than Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            return false;
          }
          if (this.bundleAtribute.length != 0) {
            for (let i =0;this.bundleAtribute.length>i;i++) {
              let obj = this.bundleAtribute[i];
              if(this.bundlindex !=i ){
              if (obj.no_limit == true) {
                if ((this.minQuantity == null || this.minQuantity == '' || this.minQuantity == undefined) || (this.bundlePrice == null || this.bundlePrice == '' || this.bundlePrice == undefined)) {
                  this.toastrService.warning("Please Enter Min and Price !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                  $(".overlay-container").addClass("addcontainerdisable");
                  this.removeClass()
                  return false;
                } else {
                  if ((Number(this.minQuantity > obj.min_qty)) || (Number(this.maxQuantity > obj.min_qty))) {
                    this.toastrService.warning("Please Enter Different Quantity Range!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                    $(".overlay-container").addClass("addcontainerdisable");
                    this.removeClass()
                    return false;
                  } else
                    continue;
                }
              }
              if (obj.min_qty == this.minQuantity && obj.max_qty == this.maxQuantity && obj.price == this.bundlePrice) {
                this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
                return false;
              }
              //  console.log('chhhhhhhhhhhhhhhhhhhhhh', obj.min_qty)
              if ((Number(this.minQuantity) >= Number(obj.min_qty)) && (Number(this.minQuantity) <= Number(obj.max_qty))) {
                this.toastrService.warning("Enter different range", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
                return false;
              }
              if ((Number(this.maxQuantity) >= Number(obj.min_qty)) && (Number(this.maxQuantity) <= Number(obj.max_qty))) {
                this.toastrService.warning("Enter different range", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
                return false;
              }
              if ((Number(this.maxQuantity) > Number(obj.max_qty)) && (Number(this.minQuantity) < Number(obj.min_qty))) {
                this.toastrService.warning("Enter different range", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
                return false;
              }
            }
            }
            if(this.bundlindex>-1){
              this.bundleAtribute[this.bundlindex].min_qty=this.minQuantity;
              this.bundleAtribute[this.bundlindex].max_qty=this.maxQuantity;
              this.bundleAtribute[this.bundlindex].price=this.bundlePrice;
              this.bundlindex=-1;
            }else{
              this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
            }
           
            this.minQuantity = null;
            this.maxQuantity = null;
            this.bundlePrice = null;
            this.noLimit = null
          } else {
            this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
            this.minQuantity = null;
            this.maxQuantity = null;
            this.bundlePrice = null;
            this.noLimit = null;
          }
    
        }
    
    
      }
  // clearList(){
  //   this.bundleAtribute = []
  // }
  removeBundleAttribute(index, obj) {
    //this.bundleAtribute.splice(index, 1);
    Swal.fire({
      title: `Do you want to delete ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.bundleAtribute.splice(index, 1);
        let delqry = `delete from z_entitydtl_product_price_configuration_001 where product_price_configuration_id = ${obj.product_price_configuration_id}`
        this.formService.getFormData(delqry).subscribe(res =>{
          this.bundleAtribute.splice(index, 1);
          this.toastrService.success("Bundle deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' });
         $(".overlay-container").addClass("addcontainerdisable");
         this.removeClass();  
        })
      } else if (result.isDenied) {
        this.minQuantity =''
    this.maxQuantity = ''
    this.bundlePrice = ''
    this.noLimit = false
      }
    })
  }
  editBundleAttribute(index) {
    this.bundlindex = index;
    this.minQuantity = this.bundleAtribute[index]?.min_qty;
    this.maxQuantity = this.bundleAtribute[index]?.max_qty;
    this.bundlePrice = this.bundleAtribute[index]?.price;
    this.noLimit = this.bundleAtribute[index]?.no_limit;
   // this.bundleAtribute.splice(index, 1);
  }

  backToListing() {
    this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
  }
  getProductConfig() {
    
    this.productService.getProductConfig(this.created_product_id).subscribe((res: any) => {
      this.product_configuratios = res.rows;
       
      for (const temp of this.tempcoprlist) {
       
      let newConfigurations =  this.product_configuratios.filter(x => !x.sku) || [];
      temp.productCnf =  temp.productCnf || [];
      if(temp.productCnf.length==0){
        temp.productCnf = JSON.parse(JSON.stringify(this.product_configuratios));
      }
     
            if(newConfigurations.length > 0 ){
              for (const list of newConfigurations) {
                if(!temp.productCnf.filter(x=> x.product_configuration_id==list.product_configuration_id ).length){
                  temp.productCnf.push(JSON.parse(JSON.stringify(list))) ; 
                  for (const temp1 of temp.productCnf) {
                    temp1.corpPrice = temp1.corpPrice || '';
                  }
                }
                     }
            }
         
        // JSON.parse(JSON.stringify(this.product_configuratios));
        temp.productCnf = temp.productCnf.filter( x => this.product_configuratios.some( x2 => x2.product_configuration_id == x.product_configuration_id))
      }
    })
  }
  updateConfigurations() {
     
    let temp_sku = [];
    for (let obj of this.product_configuratios) {
      temp_sku = [];
      if (obj.sku == null || obj.sku == undefined || obj.sku == '') {
        this.toastrService.warning("Please Enter SKU!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
      if (obj.price == null || obj.price == undefined || obj.price == '') {
        this.toastrService.warning("Please Enter Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
      if(obj.price < 0){
        this.toastrService.warning("Selling Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (obj.price !== undefined && obj.price !== null && obj.price !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(obj.price) == false) {
            this.toastrService.warning("Selling Price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
       if (obj.quantity == null || obj.quantity == undefined || obj.quantity == '') {
        this.toastrService.warning("Please Enter Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
        if(obj.quantity < 0){
        this.toastrService.warning("Quantity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
     if (obj.quantity !== undefined && obj.quantity !== null && obj.quantity !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(obj.quantity) == false) {
            this.toastrService.warning("Quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if (obj.mrp == null || obj.mrp == undefined || obj.mrp == '' || obj.mrp == 0) {
        this.toastrService.warning("Please Enter MRP!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
      if(obj.mrp < 0){
        this.toastrService.warning("MRP must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
     if (obj.mrp !== undefined && obj.mrp !== null && obj.mrp !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(obj.mrp) == false) {
            this.toastrService.warning("MRP must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      for(let temp of this.tempcoprlist){
        for(let corp of temp.productCnf){
        corp.corpPrice = corp.corpPrice ? corp.corpPrice.trim() : '';
        if(Number(corp.corpPrice ) == null || Number(corp.corpPrice) == undefined || (corp.corpPrice) == ''){
          this.toastrService.warning("Please enter corporate price", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false;
        }
        if(Number(corp.corpPrice ) > Number(obj.mrp)){
          this.toastrService.warning("Corporate price must be less than or equal to MRP", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          return false;
        }
        if(Number(corp.corpPrice ) < 0){
          this.toastrService.warning("Corporate Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false; 
        }
        if (corp.corpPrice  !== undefined && corp.corpPrice  !== null && corp.corpPrice  !== "") {
          let pattrn = /^[0-9]+$/;
            if (pattrn.test(corp.corpPrice) == false) {
              this.toastrService.warning("Corporate Price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
              $(".overlay-container").addClass("addcontainerdisable");
               this.removeClass();
              return false; 
          }
        }        
      }
      }
      // else if (obj.purchase_price == null || obj.purchase_price == undefined || obj.purchase_price == '' || obj.purchase_price == 0){
      //   this.toastrService.warning("Please Enter Purchase Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      //   $(".overlay-container").addClass("addcontainerdisable");
      //   return false;
      // }
      // else if (obj.imgUploaded == false) {
      //   this.toastrService.warning("Please Select Image !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      //   $(".overlay-container").addClass("addcontainerdisable"); this.removeClass()
      //   this.removeClass();
      //   return false;
      // }
      // arr = this.product_configuratios.filter(x => x.sku == obj.sku)
      // console.log('check this ', arr.length)
      // if(arr.length > 1){
      //   this.toastrService.warning("SKU Should be unique for each record!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      //   $(".overlay-container").addClass("addcontainerdisable");
      //   return false;
      // }
      temp_sku = this.product_configuratios.filter(item => item.sku == obj.sku);
      if (temp_sku.length > 1) {
        this.toastrService.warning("Duplicate SKU Not Allowed!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable"); 
        this.removeClass();
        return false;
      }
      if (Number(obj.mrp) < Number(obj.price)) {
        this.toastrService.warning("MRP Should be Greater than or Equal to Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
    }
    // if (this.asPerOrder == null || this.asPerOrder == undefined || this.asPerOrder == '') {
    //   this.toastrService.warning("Please Enter Orders As Per!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass();
    //   return false;
    // }

    // if (this.asPerPrice == null || this.asPerPrice == undefined || this.asPerPrice == '') {
    //   this.toastrService.warning("Please Enter asPerPrice!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass();
    //   return false;
    // }
    
    if (this.asPerPrice == 211) {
      if (this.bundleAtribute.length == 0) {
        this.toastrService.warning("Please Enter Bundle Specifications!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
    }
    if (this.uploadedGalaryFiles.length <= 0) {
      this.toastrService.warning("Please enter atleast 1 product images!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    $("#save-btn").addClass('disabled');
    var productDetails: any = {
      product_configuratios : JSON.stringify(this.product_configuratios)?JSON.stringify(this.product_configuratios):'',
      bundleAtribute : JSON.stringify(this.bundleAtribute)?JSON.stringify(this.bundleAtribute):'',
      asPerOrder: this.asPerOrder?this.asPerOrder:'',
      asPerPrice : this.asPerPrice?this.asPerPrice:'',
      noLimit : this.noLimit?this.noLimit:'',
      id : localStorage.getItem('userId'),
      product_id:this.editable_item_id,
      tempcoprlist: JSON.stringify(this.tempcoprlist)
};
this.formData = new FormData();
    var docNames = [];
    this.formData.append("productDetails", JSON.stringify(productDetails));
    if (this.uploadedGalaryFiles.length > 0) {
      for (var i = 0; i < this.uploadedGalaryFiles.length; i++) {
        this.formData.append('files', this.uploadedGalaryFiles[i]['file']);

      }
    }
    this.productService.updateConfigurations(this.formData).subscribe((res: any) => {
      //console.log("resssposeeee", res)
      if (res.status == 200) {
        this.toastrService.success(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
      } else {
        if (res.status == 201) {
          this.toastrService.error(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          return false;
          // this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
        }
      }
    })
    //}
    // if (this.uploadedGalaryFiles.length == 0) {
    //   this.toastrService.warning("Please Add Product Images!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    // }   
    // this.productService.updateConfigurations(this.formData).subscribe((res: any) => {
    //   //console.log("resssposeeee", res)
    //   if (res.status == 200) {
    //     this.toastrService.success(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
    //     $(".overlay-container").addClass("addcontainerdisable");
    //     this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
    //   } else {
    //     if (res.status == 201) {
    //       this.toastrService.error(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
    //       $(".overlay-container").addClass("addcontainerdisable");
    //       return false;
    //       // this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
    //     }
    //   }
    // })
  }

  getConfigDetailsByProductID() {
    this.addAttributeCombination = [];
    this.productService.getConfigDetailsByProductID(this.editable_item_id).subscribe(async res => {
      let data1 = res[0].rows ? res[0].rows : [];
      let data2 = res[1].rows ? res[1].rows : [];
      let data3 = res[2].rows ? res[2].rows : [];
      let data4 = res[3].rows ? res[3].rows : [];
      let data5 = res[5].rows ? res[5].rows : [];
      this.corporateBundlePriceList = res[8].rows
      this.bundleAtribute = data5;
     //  
      if(this.bundleAtribute.length > 0){
        this.asPerPrice = true
      }
      this.imagesList = res[7].rows ? res[7].rows : []
      this.uploadedGalaryFiles = this.imagesList;
      console.log("this.uploadedGalaryFiles",this.uploadedGalaryFiles)
      if(this.uploadedGalaryFiles.length){
        // for (const img of this.uploadedGalaryFiles) {
        //       img.isSavedInDB = true;
        // }
        for (let i=0;i<this.uploadedGalaryFiles.length;i++) {
          let img = this.uploadedGalaryFiles[i];
          this.uploadedGalaryFiles[i]=({ "id": i, "image_id": img.image_id, "isSavedInDB": true, 'file_path': img.document_path, "file_name": img.orignal_file_name });
        }
      }

      this.brand_id = data1[0].brand_id ? data1[0].brand_id : data1[0].brand_name;
      // this.brand_id=data1[0].brand_name;
      this.brandName = data1[0].brand_name;
      this.productName = data1[0].product_name;
      this.hsn = data1[0].hsn;
      this.category = data1[0].category;
      this.asPerPrice = Number(data1[0].product_pricing_as_per) ==211 ? Number(data1[0].product_pricing_as_per):undefined;
      this.asPerOrder = Number(data1[0].order_as_per);
      this.subCategory = data1[0].sub_category;

      let data:any = await  this.productService.getnextedsubcategory(this.subCategory);
      if(data && data.rows.length>2){
        data = data.rows;
        this.nextedsubcategory = [];
        data =  data.reverse()
        for(let i = 2 ;data.length>i ;i++){
          let catdata = await this.sellerService.getSubCategorywithpromise(data[i-1].product_category_id);
          this.nextedsubcategory.push({index:i-2,res:catdata,selected:data[i].product_category_id})
        }
        this.subCategory = data[1].product_category_id+'';
      }

      this.minOrderQuantity = data1[0].min_order_qty;
      this.countryId = data1[0].country;
      this.manufactureYear = data1[0].manufacture_year;
      this.productTags = data1[0].product_tags;
      //this.hsn = data1[0].hsn;
      this.gstRate = data1[0].gst_rate;
      this.productSpecifications = JSON.parse(data1[0].product_specifications);
      this.keyFeatures = JSON.parse(data1[0].key_features);
      this.productDiscription = data1[0].product_discription;
      this.corp_id = JSON.parse(res[0].rows[0].corp_id);
      this.assign_corp = res[0].rows[0].assign_corp=='false'?null:true;
      this.assign_marketplace = res[0].rows[0].assign_marketplace=='false'?null:true;
      this.lableList = res[4].rows
      this.addAttributeCombination = data2;
      for (const conf of this.addAttributeCombination) {
        conf.isSaved = true;
      }
      for (let obj of this.lableList) {

        obj.lableValues = JSON.parse(obj.specification_label_value_name_list)//obj.specification_label_value_name_list ? JSON.parse(obj.specification_label_value_name_list) : [];
        obj.selectedValue = Number(obj.selectedValue)
      }
      // this.checkedAttributeValue 
      this.getSubCategory();
      // this.getGstList();
      // this.getYearData();
      let temCorpWisepeiseList = res[6].rows;
      this.savedCorporatePriceConfigurationWise =  res[6].rows;
      console.log('res7', res[8].rows);
      // this.product_configuratios = res[6].rows;
      this.CorpWisepeiseList = [];
      // console.log( 'this.CorpWisepeiseList', temCorpWisepeiseList)
      // if (temCorpWisepeiseList.length > 0) {
      //   this.showqtyPrice = true
      // }
      for (let i = 0; i < temCorpWisepeiseList.length; i++) {
        let json = JSON.parse(temCorpWisepeiseList[i].quantity_wise_prise);
        for (let c = 0; c < json.length; c++) {
          this.CorpWisepeiseList.push({
            configuration_id: temCorpWisepeiseList[i]["configuration_id"],
            corpPrice: temCorpWisepeiseList[i]["corp_prise"],
            id: temCorpWisepeiseList[i]["id"],
            corpid: json[c]["corpid"],
            maxQty: json[c]["maxQty"],
            minQty: json[c]["minQty"],
            qtyprice: json[c]["qtyprice"],
            selectedCorpName: json[c]["selectedCorpName"],
          })
        }
      }
 
     // if (this.corp_id != null) {
        this.getcorp(temCorpWisepeiseList)
    //  }

     

    })

  }

  getBrandName() {
    this.productService.getBrandNameList(this.sellerId).subscribe(res => {
      this.brandNameList = res;
    })
  }

  addBrandName() {
    this.brand_name = this.brand_name?.trim();
    if (this.brand_name == null || this.brand_name == 'null' || this.brand_name == '' || this.brand_name == undefined) {
      this.toastrService.warning("Brand Name is Required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // this.brandCode = this.brandCode?.trim();
    // if (this.brandCode == null || this.brandCode == 'null' || this.brandCode == '' || this.brandCode == undefined) {
    //   this.toastrService.warning("Brand Code is Required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }
    //console.log('brand name', this.brand_name, this.brandCode,this.sellerId)
    this.productService.addBrandName(this.brand_name, this.sellerId).subscribe((res: any) => {
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.brand_name = '';
        this.brandCode = '';
        return false;
      } else {
       this.toastrService.success("Brand Name add Successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        this.brand_name = ''
        this.brandCode = ''
        $('#addbrand').modal('hide')
        this.getBrandName()
      }
    })


  }
  resetBrand() {
    this.brand_name = '',
      this.brandCode = ''
  }
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
