import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { resolve } from 'url';
import * as CryptoJS from 'crypto-js';
import { AppSettings } from '../_services/AppSetting'
import { ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy } from '@infragistics/igniteui-angular';
//import { map } from 'rxjs/operators';

@Injectable()

export class FormService {
  fieldsArray:any[]=[];
  public config: any = {
    id: 'advanced',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    SortingColumnname: '',
    OrderBy: true,
    AuthId: '',
    Comments: '',
    AuthAuditFlag: '',
    AuthCommandName: '',
    AuthComments: '',
    Mode: '',
    ListMode: '',
    ScreenCode: '',
    PageGlobalSearchVal:'',
    Type : '',
  };
  
  public OverlaySetting={
    positionStrategy: new ConnectedPositioningStrategy({
        horizontalDirection: HorizontalAlignment.Left,
        horizontalStartPoint: HorizontalAlignment.Right,
        verticalStartPoint: VerticalAlignment.Bottom,
        
    }),
    scrollStrategy: new NoOpScrollStrategy()
  };
    constructor(
        private http: HttpClient,
        private router: Router,
        
      ) { }

      private _jsonURL = '../assets/js/menu1.json';
       url=AppSettings.API_ENDPOINT;
       schedular_api_end_point=AppSettings.schedular_api_end_point;

       getLabelJSON(): Observable<any> {
        return this.http.get(this._jsonURL).pipe(map((data) => {
          return data as any;
        }, error => {
          console.log("there was an error sending the query", error);
        }
        ));
      }

      getPaging(res: number) {
        this.config.totalItems = res;
      }





   

      uploadSOAFile(formData) : Observable<any> {
        const headers = new HttpHeaders()
        headers.append('Content-Type','application/json')
       return this.http.post(this.url + '/soaFileUpload', formData, { headers })
        .pipe(map(res => {  
          return res;
        }))
      }

      encriptDataUrl(data) {
        let encriptData = CryptoJS.AES.encrypt(data, AppSettings.SecreatKey).toString()
        return encriptData;
    
      }
      decreptedDataUrl(data) {

        let removeSpeaceUrl = Object.keys(data)[0].replace(/\s/g, '+');
        // console.log("url" , removeSpeaceUrl+'==')
        let decriptData = CryptoJS.AES.decrypt(removeSpeaceUrl + '==', AppSettings.SecreatKey).toString(CryptoJS.enc.Utf8);
        //console.log("encriptData==>",decriptData)
        return decriptData;
    
      }

      uploadFormData(formData) : Observable<any> {
        const headers = new HttpHeaders()
        headers.append('Content-Type','application/json')
       return this.http.post(this.url + '/fileUpload', formData, { headers })
        .pipe(map(res => {  
          return res;
        }))
      }
      saveBanner(formData) {
        const headers = new HttpHeaders()
        headers.append('Content-Type','application/json')
       return this.http.post(this.url + '/saveBanner', formData, { headers })
        .pipe(map(res => {  
          return res;
        }))
      }
      up
      sendMail(table_name,primary_key,form_id,rowid,actionStatus) {
        var json={
          "table_name":table_name,
          "primary_key":primary_key,
          "form_id":form_id,
          "rowid":rowid,
          "action_status":actionStatus,
          "user_id":localStorage.getItem("userId")?localStorage.getItem("userId"):null
        }
        const headers = new HttpHeaders()
        headers.append('Content-Type','application/json')
       return this.http.post(this.url + '/sendmail', json, { headers })
        .pipe(map(res => {  
          return res;
        }))
      }

  apiService() {
    return this.http.get('https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/olympicWinners.json')
  }
  getFormData(queryStr){
    console.log("queryStr-->",queryStr)
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={"strSQL":queryStr};
    return this.http.post(this.url,QueryObj , { headers }).pipe(map(res => {
      //console.log(JSON.stringify(res));
      return res as any;
    },error => {
      return error as any;
    }))
  }


  getCrimeReport(payload){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    //var QueryObj={"strSQL":queryStr};
    return this.http.post("http://localhost:9081/restclient/details",payload,{headers}).pipe(map(res => {
      //console.log(JSON.stringify(res));
      return res as any;
    },error => {
      return error as any;
    }))
  }
 
  pushSantionDetail(queryStr){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')    
    return this.http.post(this.url+"/pushSanctionDetailsApi",queryStr , { headers }).pipe(map(res => {
      //console.log(JSON.stringify(res));
      return res as any;
    },error => {
      return error as any;
    }))
  }

  getDataByFormId(id){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={id};
    return this.http.post(this.url+"/form",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  getFormViewById(form_viewid){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={form_viewid};
    return this.http.post(this.url+"/view",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  getFormDataById(form_id){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={form_id};
    return this.http.post(this.url+"/formbyid",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  getChildFormDataByIds(child_form_ids){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={child_form_ids};
    return this.http.post(this.url+"/childform",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  getFormRowDataById(tablename,tablePrimaryKey,rowid):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={tablename,tablePrimaryKey,rowid};
    return this.http.post(this.url+"/formrow",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  getFormFormViewsData(lookupArr,tableName,tablePrimaryKey,filterCriteria,viewFilter):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={lookupArr,tableName,tablePrimaryKey,filterCriteria,viewFilter};
    return this.http.post(this.url+"/formviewdata",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  callFormDataAdd(strColumn,strValue,tableName,tablePrimaryKey,createdBy){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={strColumn,strValue,tableName,tablePrimaryKey,createdBy};
    return this.http.post(this.url+"/callFormDataAdd",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  callFormDataBatchInsert(dtlQueryList,refKey){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={dtlQueryList,refKey};
    return this.http.post(this.url+"/callFormDataBatchInsert",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  callFormDataEdit(strColumn,tableName,tablePrimaryKey,refKey,updatedBy){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={strColumn,tableName,tablePrimaryKey,refKey,updatedBy};
    return this.http.post(this.url+"/callFormDataEdit",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  callFormDataDelete(tableName,tablePrimaryKey,refKey){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={tableName,tablePrimaryKey,refKey};
    return this.http.post(this.url+"/callFormDataDelete",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }

  getNgxGridData(lookupArr,tableName,tablePrimaryKey,filterCriteria,viewFilter,pagesize,offset,commonFilter,sortingOrder):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={lookupArr,tableName,tablePrimaryKey,filterCriteria,viewFilter,pagesize,offset,commonFilter,sortingOrder};
    return this.http.post(this.url+"/formviewdataforngxgrid",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }

  callIntimationTrigger(formid,rowid,automationids,action,tablename,primarykey,createdby):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={formid,rowid,automationids,action,tablename,primarykey,createdby};
    console.log("schedular_api_end_point==>",QueryObj)
    //return this.http.post(this.url+"/sendmail",QueryObj , { headers }).pipe(map(res => { 
    return this.http.post(this.schedular_api_end_point,QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }

  mailSend(mailTo,mailBcc,mailContent,mailSubject,mailForm):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={mailTo,mailBcc,mailContent,mailSubject,mailForm};
    return this.http.post(this.url+"/mailsend",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }


  sendsms(mailTo,mailBcc,mailContent,mailSubject,mailForm):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={mailTo,mailBcc,mailContent,mailSubject,mailForm};
    return this.http.post(this.url+"/sendsms",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }

  setSchedularForMailAndAutomation(formid,rowid,automationids,action,createdby):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={
      "formid": formid,
      "rowid":rowid,
      "automationids":automationids,
      "action":action,
      "createdby":createdby
    };
    console.log("Query ==>",QueryObj)
    return this.http.post(AppSettings.schedular_api_end_point,QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }
  
  checkValid(field_id,operator,value,control_id)
  {
    //console.log(field_id,operator,value)
     var selfields=this.fieldsArray.filter(x=>x.field_id==field_id);
     if(selfields.length>0)
     {
       var flag=false;
         for(let i=0;i<selfields.length;i++)
         {
           if(operator==1)
           {
             if(selfields[i].value.toLowerCase().includes(value.toLowerCase()))
             {
              flag=true;
              break;
             }
           }
           else if(operator==2)
           {
            if(!(selfields[i].value.toLowerCase().includes(value.toLowerCase())))
            {
             flag=true;
             break;
            }
           }
           else if(operator==3)
           {
            if(control_id==1 || control_id==2 || control_id==3 || control_id==11 || control_id==12)
            {
              if(selfields[i].value.toLowerCase()==value.toLowerCase())
              {
              flag=true;
              break;
              }
            }
            else if(control_id==10 ||  control_id==14 || control_id==15 || control_id==19 || control_id==22)
            {
              //console.log(control_id)
              var ids='';
              if(value.length>0)
              {
                for(let ob of value){ ids=ids+((control_id==10)?("'"+ob.id+"'"):(ob.id))+"," }
                ids=ids.substring(0,ids.length-1);
              }
              //console.log(ids)
              if(ids.includes(selfields[i].value))
              {
                flag=true;
                break;
              } 
            }
            else if(control_id==4 || control_id==5 || control_id==6 || control_id==9 || control_id==13)
            {
              if(selfields[i].value==value)
              {
               flag=true;
               break;
              }
            }
             
           }
           else if(operator==4)
           {
            if(control_id==1 || control_id==2 || control_id==3 || control_id==11 || control_id==12)
            {
              if(selfields[i].value.toLowerCase()!=value.toLowerCase())
              {
              flag=true;
              break;
              }
            }
            else if(control_id==10 ||  control_id==14 || control_id==15 || control_id==19 || control_id==22)
            {
              var ids='';
              if(value.length>0)
              {
                for(let ob of value){ ids=ids+((control_id==10)?("'"+ob.id+"'"):(ob.id))+"," }
                ids=ids.substring(0,ids.length-1);
              }
              if(!(ids.includes(selfields[i].value)))
              {
                flag=true;
                break;
              } 
            }
            else if(control_id==4 || control_id==5 || control_id==6 || control_id==9 || control_id==13)
            {
              if(selfields[i].value!=value)
              {
               flag=true;
               break;
              }
            }
           }
           else if(operator==5)
           {
            if(selfields[i].value<value)
            {
             flag=true;
             break;
            } 
           }
           else if(operator==6)
           {
            if(selfields[i].value>value)
            {
             flag=true;
             break;
            }
           }
           else if(operator==7 || operator=="7")
           {
             var val=new Date(selfields[i].value)
             if(val.constructor ===Date)
             {
              if(val.getTime() == new Date(value).getTime())
              {
               flag=true;
               break;
              }                
             }
           }
           else if(operator==8)
           {
            var val=new Date(selfields[i].value)
            if(val.constructor ===Date)
            {
             if(val > new Date(value))
             {
              flag=true;
              break;
             }                
            }
           }
           else if(operator==9)
           {
            var val=new Date(selfields[i].value)
            if(val.constructor ===Date)
            {
             if(val < new Date(value))
             {
              flag=true;
              break;
             }                
            }
           }
           else if(operator==10)
           {
            if(selfields[i].value<=value)
            {
             flag=true;
             break;
            }
           }
           else if(operator==11)
           {
            if(selfields[i].value>=value)
            {
             flag=true;
             break;
            }
           }
           else if(operator==12)
           {
            if(selfields[i].value.toLowerCase().startsWith(value.toLowerCase()))
            {
             flag=true;
             break;
            }
           }
           else if(operator==13)
           {
            if(selfields[i].value.toLowerCase().endsWith(value.toLowerCase()))
            {
             flag=true;
             break;
            }
           }
         }
         return flag;
     }
     else{
       return false;
     }
  }

   checkValidation(field_array,json)
   {
     var string='';
     try{
     this.fieldsArray=field_array;
    if(json.length>0)
    {
         if(json[0].list.length>0)
         {
            for(let i=0;i< json[0].list.length;i++)
            {
              if(i==0)
              {
                string=string+"(";
              }
              var obj=json[0].list[i];
               if(obj.type=='row')
               {
                string=string+this.checkValid(obj.field_id,obj.condition_id,obj.value,obj.control_type)+((json[0].operator=='and')?"&&":"||");
               }
               else
               {
                 if(obj.list.length>0)
                 {
                  for(let j=0;j< obj.list.length;j++)
                  { 
                     if(j==0)
                     {
                      string=string+"("
                     }
                     var obj1=  obj.list[j];
                     if(obj1.type=='row')
                     {
                      string=string+this.checkValid(obj1.field_id,obj1.condition_id,obj1.value,obj1.control_type)+((obj.operator=='and')?"&&":"||");
                     }
                     else
                     {
                        if(obj1.list.length>0)
                        {
                          for(let k=0;k< obj1.list.length;k++)
                          {
                            if(k==0)
                            {
                              string=string+"("
                            }
                            var obj2=obj1.list[k];
                            if(obj2.type=='row')
                            {
                              string=string+this.checkValid(obj2.field_id,obj2.condition_id,obj2.value,obj2.control_type)+((obj1.operator=='and')?"&&":"||");
                            }
                            else
                            {
                              if(obj2.list.length>0)
                              {
                                for(let l=0;l< obj2.list.length;l++)
                                {
                                  if(l==0)
                                  {
                                    string=string+"("
                                  }
                                  var obj3=obj2.list[l];
                                  if(obj3.type=='row')
                                  {
                                    string=string+this.checkValid(obj3.field_id,obj3.condition_id,obj3.value,obj3.control_type)+((obj2.operator=='and')?"&&":"||");
                                  }
                                  else
                                  {
                                    if(obj3.list.length>0)
                                    {
                                      for(let m=0;m< obj3.list.length;m++)
                                      {
                                        if(m==0)
                                        {
                                          string=string+"("
                                        }
                                        var obj4=obj3.list[m];
                                        if(obj4.type=='row')
                                        {
                                          string=string+this.checkValid(obj4.field_id,obj4.condition_id,obj4.value,obj4.control_type)+((obj3.operator=='and')?"&&":"||");
                                        }
                                        else
                                        {
                                          if(obj4.list.length>0)
                                          {
                                            for(let n=0;n< obj4.list.length;n++)
                                            {
                                              if(n==0)
                                              {
                                                string=string+"("
                                              }
                                              var obj5=obj4.list[n];
                                              if(obj5.type=='row')
                                              {
                                                string=string+this.checkValid(obj5.field_id,obj5.condition_id,obj5.value,obj5.control_type)+((obj4.operator=='and')?"&&":"||");
                                              }
                                              else
                                              {
                                                if(obj5.list.length>0)
                                                {
                                                  for(let o=0;o< obj5.list.length;o++)
                                                  {
                                                    if(o==0)
                                                    {
                                                      string=string+"("
                                                    }
                                                    var obj6=obj5.list[o];
                                                    if(obj6.type=='row')
                                                    {
                                                      string=string+this.checkValid(obj6.field_id,obj6.condition_id,obj6.value,obj6.control_type)+((obj5.operator=='and')?"&&":"||");
                                                    }
                                                    else
                                                    {
                                                      if(obj6.list.length>0)
                                                      {
                                                        for(let p=0;p< obj6.list.length;p++)
                                                        {
                                                          if(p==0)
                                                          {
                                                            string=string+"("
                                                          }
                                                          var obj7=obj6.list[p];
                                                          if(obj7.type=='row')
                                                          {
                                                            string=string+this.checkValid(obj7.field_id,obj7.condition_id,obj7.value,obj7.control_type)+((obj6.operator=='and')?"&&":"||");
                                                          }
                                                          else
                                                          {
                                                            if(obj7.list.length>0)
                                                            {
                                                              for(let q=0;q< obj7.list.length;q++)
                                                              {
                                                                if(q==0)
                                                                {
                                                                  string=string+"("
                                                                }
                                                                var obj8=obj7.list[q];
                                                                if(obj8.type=='row')
                                                                {
                                                                  string=string+this.checkValid(obj8.field_id,obj8.condition_id,obj8.value,obj8.control_type)+((obj7.operator=='and')?"&&":"||");
                                                                }     
                                                              }
                                                              string=string.substring(0,string.length-2)+")"+((obj6.operator=='and')?"&&":"||")      
                                                            }
                                                          }
                                                        }
                                                        string=string.substring(0,string.length-2)+")"+((obj5.operator=='and')?"&&":"||")      
                                                      }
                                                    }
                                                  }
                                                  string=string.substring(0,string.length-2)+")"+((obj4.operator=='and')?"&&":"||")
                                                }   
                                              }
                                            }
                                            string=string.substring(0,string.length-2)+")"+((obj3.operator=='and')?"&&":"||")  
                                          }     
                                        }
                                      }
                                      string=string.substring(0,string.length-2)+")"+((obj2.operator=='and')?"&&":"||")      
                                    }
                                  }     
                                }
                                string=string.substring(0,string.length-2)+")"+((obj1.operator=='and')?"&&":"||")          
                              }       
                            }             
                          }
                          string=string.substring(0,string.length-2)+")"+((obj.operator=='and')?"&&":"||")      
                        }    
                     }
                  }
                  string=string.substring(0,string.length-2)+")"+((json[0].operator=='and')?"&&":"||")
                  }
               }
            }
            string=string.substring(0,string.length-2)+")"
           }
    }
    //console.log(string);
    return eval(string)
    }
    catch(err)
    {
      return false;
    }
   }

   getFieldListFromRuleJson(json)
   {
     var fieldArr=[];
     try{
    if(json.length>0)
    {
         if(json[0].list.length>0)
         {
            for(let i=0;i< json[0].list.length;i++)
            {
              var obj=json[0].list[i];
               if(obj.type=='row')
               {
                  fieldArr.push({"field_id":obj.field_id,"value":null});
               }
               else
               {
                 if(obj.list.length>0)
                 {
                  for(let j=0;j< obj.list.length;j++)
                  { 
                     var obj1=  obj.list[j];
                     if(obj1.type=='row')
                     {
                      fieldArr.push({"field_id":obj1.field_id,"value":null})
                     }
                     else
                     {
                        if(obj1.list.length>0)
                        {
                          for(let k=0;k< obj1.list.length;k++)
                          {
                            var obj2=obj1.list[k];
                            if(obj2.type=='row')
                            {
                              fieldArr.push({"field_id":obj2.field_id,"value":null});
                            }
                            else
                            {
                              if(obj2.list.length>0)
                              {
                                for(let l=0;l< obj2.list.length;l++)
                                {
                                  var obj3=obj2.list[l];
                                  if(obj3.type=='row')
                                  {
                                    fieldArr.push({"field_id":obj3.field_id,"value":null})
                                  }
                                  else
                                  {
                                    if(obj3.list.length>0)
                                    {
                                      for(let m=0;m< obj3.list.length;m++)
                                      {
                                        var obj4=obj3.list[m];
                                        if(obj4.type=='row')
                                        {
                                          fieldArr.push({"field_id":obj4.field_id,"value":null})
                                        }
                                        else
                                        {
                                          if(obj4.list.length>0)
                                          {
                                            for(let n=0;n< obj4.list.length;n++)
                                            {
                                              var obj5=obj4.list[n];
                                              if(obj5.type=='row')
                                              {
                                                fieldArr.push({"field_id":obj5.field_id,"value":null})
                                              }
                                              else
                                              {
                                                if(obj5.list.length>0)
                                                {
                                                  for(let o=0;o< obj5.list.length;o++)
                                                  {
                                                    var obj6=obj5.list[o];
                                                    if(obj6.type=='row')
                                                    {
                                                      fieldArr.push({"field_id":obj6.field_id,"value":null})  
                                                    }
                                                    else
                                                    {
                                                      if(obj6.list.length>0)
                                                      {
                                                        for(let p=0;p< obj6.list.length;p++)
                                                        {
                                                          var obj7=obj6.list[p];
                                                          if(obj7.type=='row')
                                                          {
                                                            fieldArr.push({"field_id":obj7.field_id,"value":null})
                                                          }
                                                          else
                                                          {
                                                            if(obj7.list.length>0)
                                                            {
                                                              for(let q=0;q< obj7.list.length;q++)
                                                              {
                                                                var obj8=obj7.list[q];
                                                                if(obj8.type=='row')
                                                                {
                                                                  fieldArr.push({"field_id":obj8.field_id,"value":null})
                                                                }     
                                                              }      
                                                            }
                                                          }
                                                        }  
                                                      }
                                                    }
                                                  }
                                                }   
                                              }
                                            }
                                          }     
                                        }
                                      }
                                    }
                                  }     
                                }    
                              }       
                            }             
                          }
                        }    
                     }
                  }
                  }
               }
            }
           }
    }
    return fieldArr;
    }
    catch(err)
    {
      return [];
    }
   }


   getConditionQueryByJson(criteriaJsonList,sessionParams){
    //console.log("sessionParams==>",criteriaJsonList,sessionParams )
    try{
      criteriaJsonList=JSON.parse(criteriaJsonList)
      var resolveList='';
     if(criteriaJsonList.length>0)
     {
     
          if(criteriaJsonList[0].list.length>0)
          {
             for(let i=0;i< criteriaJsonList[0].list.length;i++)
             {
               if(i==0)
               {
                 resolveList=resolveList+" ( ";
               }
               var obj=criteriaJsonList[0].list[i];
                if(obj.type=='row')
                {
                  if(obj.is_session_value ==true){
                    //console.log("sessionParams==>",sessionParams,sessionParams[obj.session_value])
                    obj.value=sessionParams[obj.session_value];
                  }
                 if(obj.condition_id==1)
                 resolveList=resolveList+" lower(a."+obj.field_id+") "+" "+"like"+" "+"lower('%"+obj.value+"%') "+criteriaJsonList[0].operator
                 else if(obj.condition_id==2)
                   resolveList=resolveList+" lower(a."+obj.field_id+") "+" "+"not like"+" "+"lower('%"+obj.value+"%') "+criteriaJsonList[0].operator
                 else if(obj.condition_id==12 || obj.condition_id==13)
                 resolveList=resolveList+" lower(a."+obj.field_id+") "+" "+"like"+" "+((obj.condition_id==12)?("lower('"+obj.value+"%') "):("lower('%"+obj.value+"') "))+criteriaJsonList[0].operator
                   else if(obj.condition_id==3 || obj.condition_id==4)
                 {
                  
                   if(obj.control_type==1 || obj.control_type==2 || obj.control_type==3 || obj.control_type==11 || obj.control_type==12)
                    resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" =":" !=")+" '"+obj.value+"' "+criteriaJsonList[0].operator
                   else if(obj.control_type==10 ||  obj.control_type==14 || obj.control_type==15)
                   {
                     var ids='';
                     
                     if(obj.is_session_value ==true){
                        ids=obj.value
                      //resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" in":" not in")+" ("+obj.value+") "+criteriaJsonList[0].operator 
                     }
                     else if(obj.value.length>0)
                     {
                      
                       for(let ob of obj.value){ ids=ids+((obj.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                       ids=ids.substring(0,ids.length-1);
                     }
                     //console.log("resolveList==>",resolveList,ids)
                     resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" in":" not in")+" ("+ids+") "+criteriaJsonList[0].operator 

                   }
                   else if(obj.control_type==4 || obj.control_type==5 || obj.control_type==6 || obj.control_type==9 || obj.control_type==13)
                   {
                       resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==3)?" =":" !=")+" "+obj.value+" "+criteriaJsonList[0].operator
                   }
                   
                 }
                 else if(obj.condition_id==5 || obj.condition_id==6)
                 {
                   if(obj.control_type==7 || obj.control_type==8)
                     resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==5)?" <":" >")+" '"+obj.value+"'::date "+criteriaJsonList[0].operator
                   else
                     resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==5)?" < ":" > ")+obj.value+" "+criteriaJsonList[0].operator
                 }
                 else if(obj.condition_id==7 || obj.condition_id==8 || obj.condition_id==9)
                 {
                   if(obj.control_type==7 || obj.control_type==8)
                   {
                     if(obj.condition_id==7)
                       resolveList=resolveList+" a."+obj.field_id+"::date = '"+obj.value+"'::date "+criteriaJsonList[0].operator
                     else if(obj.condition_id==8 || obj.condition_id==9)
                       resolveList=resolveList+" a."+obj.field_id+((obj.condition_id==8)?" > '":" < '")+obj.value+"'::date "+criteriaJsonList[0].operator
                   } 
                 }
                }
                else
                {
                  if(obj.list.length>0)
                  {
                   for(let j=0;j< obj.list.length;j++)
                   { 
                      if(j==0)
                      {
                        resolveList=resolveList+" ( "
                      }
                      var obj1=  obj.list[j];
                      
                      if(obj1.type=='row')
                      {
                        if(obj1.is_session_value ==true){
                          obj1.value=sessionParams[obj1.session_value];
                        }
                       if(obj1.condition_id==1)
                       resolveList=resolveList+" lower(a."+obj1.field_id+") "+" "+"like"+" "+"lower('%"+obj1.value+"%') "+obj.operator
                       else if(obj1.condition_id==2)
                         resolveList=resolveList+" lower(a."+obj1.field_id+") "+" "+"not like"+" "+"lower('%"+obj1.value+"%') "+obj.operator
                         else if(obj1.condition_id==12 || obj1.condition_id==13)
                         resolveList=resolveList+" lower(a."+obj1.field_id+") "+" "+"like"+" "+((obj1.condition_id==12)?("lower('"+obj1.value+"%') "):("lower('%"+obj1.value+"') "))+obj.operator
                         else if(obj1.condition_id==3 || obj1.condition_id==4)
                       {
                         if(obj1.control_type==1 || obj1.control_type==2 || obj1.control_type==3 || obj1.control_type==11 || obj1.control_type==12)
                         resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==3)?" =":" !=")+" '"+obj1.value+"' "+obj.operator
                         else if(obj1.control_type==10 ||  obj1.control_type==14 || obj1.control_type==15)
                         {
                           var ids='';
                           if(obj1.value.length>0)
                           {
                             for(let ob of obj1.value){ ids=ids+((obj1.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                             ids=ids.substring(0,ids.length-1);
                           }
                           resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==3)?" in":" not in")+" ("+ids+") "+obj.operator 
                         }
                         else if(obj1.control_type==4 || obj1.control_type==5 || obj1.control_type==6 || obj1.control_type==9 || obj1.control_type==13)
                         {
                             resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==3)?" =":" !=")+" "+obj1.value+" "+obj.operator
                         }
                       }
                       else if(obj1.condition_id==5 || obj1.condition_id==6)
                       {
                         if(obj1.control_type==7 || obj1.control_type==8)
                           resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==5)?" <":" >")+" '"+obj1.value+"'::date "+obj.operator
                         else
                           resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==5)?" < ":" > ")+obj1.value+" "+obj.operator
                       }
                       else if(obj1.condition_id==7 || obj1.condition_id==8 || obj1.condition_id==9)
                       {
                         if(obj1.control_type==7 || obj1.control_type==8)
                         {
                           if(obj1.condition_id==7)
                             resolveList=resolveList+" a."+obj1.field_id+"::date = '"+obj1.value+"'::date "+obj.operator
                           else if(obj1.condition_id==8 || obj1.condition_id==9)
                             resolveList=resolveList+" a."+obj1.field_id+((obj1.condition_id==8)?" > '":" < '")+obj1.value+"'::date "+obj.operator
                         } 
                       }
                      }
                      else
                      {
                         if(obj1.list.length>0)
                         {
                           for(let k=0;k< obj1.list.length;k++)
                           {
                             if(k==0)
                             {
                               resolveList=resolveList+" ( "
                             }
                             var obj2=obj1.list[k];
                             if(obj2.type=='row')
                             {
                              if(obj2.is_session_value ==true){
                                obj2.value=sessionParams[obj2.session_value];
                              }
                               if(obj2.condition_id==1)
                               resolveList=resolveList+" lower(a."+obj2.field_id+") "+" "+"like"+" "+"lower('%"+obj2.value+"%') "+obj1.operator
                               else if(obj2.condition_id==2)
                                 resolveList=resolveList+" lower(a."+obj2.field_id+") "+" "+"not like"+" "+"lower('%"+obj2.value+"%') "+obj1.operator
                                 else if(obj2.condition_id==12 || obj2.condition_id==13)
                                 resolveList=resolveList+" lower(a."+obj2.field_id+") "+" "+"like"+" "+((obj2.condition_id==12)?("lower('"+obj2.value+"%') "):("lower('%"+obj2.value+"') "))+obj1.operator
                                 else if(obj2.condition_id==3 || obj2.condition_id==4)
                               {
                                 if(obj2.control_type==1 || obj2.control_type==2 || obj2.control_type==3 || obj2.control_type==11 || obj2.control_type==12)
                                 resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==3)?" =":" !=")+" '"+obj2.value+"' "+obj1.operator
                                 else if(obj2.control_type==10 ||  obj2.control_type==14 || obj2.control_type==15)
                                 {
                                   var ids='';
                                   if(obj2.value.length>0)
                                   {
                                     for(let ob of obj2.value){ ids=ids+((obj2.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                     ids=ids.substring(0,ids.length-1);
                                   }
                                   resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==3)?" in":" not in")+" ("+ids+") "+obj1.operator 
                                 }
                                 else if(obj2.control_type==4 || obj2.control_type==5 || obj2.control_type==6 || obj2.control_type==9 || obj2.control_type==13)
                                 {
                                     resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==3)?" =":" !=")+" "+obj2.value+" "+obj1.operator
                                 }
                               }
                               else if(obj2.condition_id==5 || obj2.condition_id==6)
                               {
                                 if(obj2.control_type==7 || obj2.control_type==8)
                                   resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==5)?" <":" >")+" '"+obj2.value+"'::date "+obj1.operator
                                 else
                                   resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==5)?" < ":" > ")+obj2.value+" "+obj1.operator
                               }
                               else if(obj2.condition_id==7 || obj2.condition_id==8 || obj2.condition_id==9)
                               {
                                 if(obj2.control_type==7 || obj2.control_type==8)
                                 {
                                   if(obj2.condition_id==7)
                                     resolveList=resolveList+" a."+obj2.field_id+"::date = '"+obj2.value+"'::date "+obj1.operator
                                   else if(obj2.condition_id==8 || obj2.condition_id==9)
                                     resolveList=resolveList+" a."+obj2.field_id+((obj2.condition_id==8)?" > '":" < '")+obj2.value+"'::date "+obj1.operator
                                 } 
                               }
                             }
                             else
                             {
                               if(obj2.list.length>0)
                               {
                                 for(let l=0;l< obj2.list.length;l++)
                                 {
                                   if(l==0)
                                   {
                                     resolveList=resolveList+" ( "
                                   }
                                   var obj3=obj2.list[l];
                                   if(obj3.type=='row')
                                   {
                                    if(obj3.is_session_value ==true){
                                      obj3.value=sessionParams[obj3.session_value];
                                    }
                                     if(obj3.condition_id==1)
                                     resolveList=resolveList+" lower(a."+obj3.field_id+") "+" "+"like"+" "+"lower('%"+obj3.value+"%') "+obj2.operator
                                     else if(obj3.condition_id==2)
                                       resolveList=resolveList+" lower(a."+obj3.field_id+") "+" "+"not like"+" "+"lower('%"+obj3.value+"%') "+obj2.operator
                                       else if(obj3.condition_id==12 || obj3.condition_id==13)
                                       resolveList=resolveList+" lower(a."+obj3.field_id+") "+" "+"like"+" "+((obj3.condition_id==12)?("lower('"+obj3.value+"%') "):("lower('%"+obj3.value+"') "))+obj2.operator
                                       else if(obj3.condition_id==3 || obj3.condition_id==4)
                                     {
                                       if(obj3.control_type==1 || obj3.control_type==2 || obj3.control_type==3 || obj3.control_type==11 || obj3.control_type==12)
                                       resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==3)?" =":" !=")+" '"+obj3.value+"' "+obj2.operator
                                       else if(obj3.control_type==10 ||  obj3.control_type==14 || obj3.control_type==15)
                                       {
                                         var ids='';
                                         if(obj3.value.length>0)
                                         {
                                           for(let ob of obj3.value){ ids=ids+((obj3.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                           ids=ids.substring(0,ids.length-1);
                                         }
                                         resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==3)?" in":" not in")+" ("+ids+") "+obj2.operator 
                                       }
                                       else if(obj3.control_type==4 || obj3.control_type==5 || obj3.control_type==6 || obj3.control_type==9 || obj3.control_type==13)
                                       {
                                           resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==3)?" =":" !=")+" "+obj3.value+" "+obj2.operator
                                       }
                                     }
                                     else if(obj3.condition_id==5 || obj3.condition_id==6)
                                     {
                                       if(obj3.control_type==7 || obj3.control_type==8)
                                         resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==5)?" <":" >")+" '"+obj3.value+"'::date "+obj2.operator
                                       else
                                         resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==5)?" < ":" > ")+obj3.value+" "+obj2.operator
                                     }
                                     else if(obj3.condition_id==7 || obj3.condition_id==8 || obj3.condition_id==9)
                                     {
                                       if(obj3.control_type==7 || obj3.control_type==8)
                                       {
                                         if(obj3.condition_id==7)
                                           resolveList=resolveList+" a."+obj3.field_id+"::date = '"+obj3.value+"'::date "+obj2.operator
                                         else if(obj3.condition_id==8 || obj3.condition_id==9)
                                           resolveList=resolveList+" a."+obj3.field_id+((obj3.condition_id==8)?" > '":" < '")+obj3.value+"'::date "+obj2.operator
                                       } 
                                     }
                                   }
                                   else
                                   {
                                     if(obj3.list.length>0)
                                     {
                                       for(let m=0;m< obj3.list.length;m++)
                                       {
                                         if(m==0)
                                         {
                                           resolveList=resolveList+" ( "
                                         }
                                         var obj4=obj3.list[m];
                                         if(obj4.type=='row')
                                         {
                                          if(obj4.is_session_value ==true){
                                            obj4.value=sessionParams[obj4.session_value];
                                          }
                                           if(obj4.condition_id==1)
                                           resolveList=resolveList+" lower(a."+obj4.field_id+") "+" "+"like"+" "+"lower('%"+obj4.value+"%') "+obj3.operator
                                           else if(obj4.condition_id==2)
                                             resolveList=resolveList+" lower(a."+obj4.field_id+") "+" "+"not like"+" "+"lower('%"+obj4.value+"%') "+obj3.operator
                                             else if(obj4.condition_id==12 || obj4.condition_id==13)
                                             resolveList=resolveList+" lower(a."+obj4.field_id+") "+" "+"like"+" "+((obj4.condition_id==12)?("lower('"+obj4.value+"%') "):("lower('%"+obj4.value+"') "))+obj3.operator
                                             else if(obj4.condition_id==3 || obj4.condition_id==4)
                                           {
                                             if(obj4.control_type==1 || obj4.control_type==2 || obj4.control_type==3 || obj4.control_type==11 || obj4.control_type==12)
                                             resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==3)?" =":" !=")+" '"+obj4.value+"' "+obj3.operator
                                             else if(obj4.control_type==10 ||  obj4.control_type==14 || obj4.control_type==15)
                                             {
                                               var ids='';
                                               if(obj4.value.length>0)
                                               {
                                                 for(let ob of obj4.value){ ids=ids+((obj4.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                 ids=ids.substring(0,ids.length-1);
                                               }
                                               resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==3)?" in":" not in")+" ("+ids+") "+obj3.operator 
                                             }
                                             else if(obj4.control_type==4 || obj4.control_type==5 || obj4.control_type==6 || obj4.control_type==9 || obj4.control_type==13)
                                             {
                                                 resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==3)?" =":" !=")+" "+obj4.value+" "+obj3.operator
                                             }
                                           }
                                           else if(obj4.condition_id==5 || obj4.condition_id==6)
                                           {
                                             if(obj4.control_type==7 || obj4.control_type==8)
                                               resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==5)?" <":" >")+" '"+obj4.value+"'::date "+obj3.operator
                                             else
                                               resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==5)?" < ":" > ")+obj4.value+" "+obj3.operator
                                           }
                                           else if(obj4.condition_id==7 || obj4.condition_id==8 || obj4.condition_id==9)
                                           {
                                             if(obj4.control_type==7 || obj4.control_type==8)
                                             {
                                               if(obj4.condition_id==7)
                                                 resolveList=resolveList+" a."+obj4.field_id+"::date = '"+obj4.value+"'::date "+obj3.operator
                                               else if(obj4.condition_id==8 || obj4.condition_id==9)
                                                 resolveList=resolveList+" a."+obj4.field_id+((obj4.condition_id==8)?" > '":" < '")+obj4.value+"'::date "+obj3.operator
                                             } 
                                           }
                                         }
                                         else
                                         {
                                           if(obj4.list.length>0)
                                           {
                                             for(let n=0;n< obj4.list.length;n++)
                                             {
                                               if(n==0)
                                               {
                                                 resolveList=resolveList+" ( "
                                               }
                                               var obj5=obj4.list[n];
                                               if(obj5.type=='row')
                                               {
                                                if(obj5.is_session_value ==true){
                                                  obj5.value=sessionParams[obj5.session_value];
                                                }
                                                 if(obj5.condition_id==1)
                                                 resolveList=resolveList+" lower(a."+obj5.field_id+") "+" "+"like"+" "+"lower('%"+obj5.value+"%') "+obj4.operator
                                                 else if(obj5.condition_id==2)
                                                   resolveList=resolveList+" lower(a."+obj5.field_id+") "+" "+"not like"+" "+"lower('%"+obj5.value+"%') "+obj4.operator
                                                   else if(obj5.condition_id==12 || obj5.condition_id==13)
                                             resolveList=resolveList+" lower(a."+obj5.field_id+") "+" "+"like"+" "+((obj5.condition_id==12)?("lower('"+obj5.value+"%') "):("lower('%"+obj5.value+"') "))+obj4.operator
                                                 else if(obj5.condition_id==3 || obj5.condition_id==4)
                                                 {
                                                   if(obj5.control_type==1 || obj5.control_type==2 || obj5.control_type==3 || obj5.control_type==11 || obj5.control_type==12)
                                                   resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==3)?" =":" !=")+" '"+obj5.value+"' "+obj4.operator
                                                   else if(obj5.control_type==10 ||  obj5.control_type==14 || obj5.control_type==15)
                                                   {
                                                     var ids='';
                                                     if(obj5.value.length>0)
                                                     {
                                                       for(let ob of obj5.value){ ids=ids+((obj5.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                       ids=ids.substring(0,ids.length-1);
                                                     }
                                                     resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==3)?" in":" not in")+" ("+ids+") "+obj4.operator 
                                                   }
                                                   else if(obj5.control_type==4 || obj5.control_type==5 || obj5.control_type==6 || obj5.control_type==9 || obj5.control_type==13)
                                                   {
                                                       resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==3)?" =":" !=")+" "+obj5.value+" "+obj4.operator
                                                   }
                                                 }
                                                 else if(obj5.condition_id==5 || obj5.condition_id==6)
                                                 {
                                                   if(obj5.control_type==7 || obj5.control_type==8)
                                                     resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==5)?" <":" >")+" '"+obj5.value+"'::date "+obj4.operator
                                                   else
                                                     resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==5)?" < ":" > ")+obj5.value+" "+obj4.operator
                                                 }
                                                 else if(obj5.condition_id==7 || obj5.condition_id==8 || obj5.condition_id==9)
                                                 {
                                                   if(obj5.control_type==7 || obj5.control_type==8)
                                                   {
                                                     if(obj5.condition_id==7)
                                                       resolveList=resolveList+" a."+obj5.field_id+"::date = '"+obj5.value+"'::date "+obj4.operator
                                                     else if(obj5.condition_id==8 || obj5.condition_id==9)
                                                       resolveList=resolveList+" a."+obj5.field_id+((obj5.condition_id==8)?" > '":" < '")+obj5.value+"'::date "+obj4.operator
                                                   } 
                                                 }
                                               }
                                               else
                                               {
                                                 if(obj5.list.length>0)
                                                 {
                                                   for(let o=0;o< obj5.list.length;o++)
                                                   {
                                                     if(o==0)
                                                     {
                                                       resolveList=resolveList+" ( "
                                                     }
                                                     var obj6=obj5.list[o];
                                                     if(obj6.type=='row')
                                                     {
                                                      if(obj6.is_session_value ==true){
                                                        obj6.value=sessionParams[obj6.session_value];
                                                      }
                                                       if(obj6.condition_id==1)
                                                           resolveList=resolveList+" lower(a."+obj6.field_id+") "+" "+"like"+" "+"lower('%"+obj6.value+"%') "+obj5.operator
                                                         else if(obj6.condition_id==2)
                                                           resolveList=resolveList+" lower(a."+obj6.field_id+") "+" "+"not like"+" "+"lower('%"+obj6.value+"%') "+obj5.operator
                                                           else if(obj6.condition_id==12 || obj6.condition_id==13)
                                                           resolveList=resolveList+" lower(a."+obj6.field_id+") "+" "+"like"+" "+((obj6.condition_id==12)?("lower('"+obj6.value+"%') "):("lower('%"+obj6.value+"') "))+obj5.operator
                                                           else if(obj6.condition_id==3 || obj6.condition_id==4)
                                                         {
                                                           if(obj6.control_type==1 || obj6.control_type==2 || obj6.control_type==3 || obj6.control_type==11 || obj6.control_type==12)
                                                           resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==3)?" =":" !=")+" '"+obj6.value+"' "+obj5.operator
                                                           else if(obj6.control_type==10 ||  obj6.control_type==14 || obj6.control_type==15)
                                                           {
                                                             var ids='';
                                                             if(obj6.value.length>0)
                                                             {
                                                               for(let ob of obj6.value){ ids=ids+((obj6.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                               ids=ids.substring(0,ids.length-1);
                                                             }
                                                             resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==3)?" in":" not in")+" ("+ids+") "+obj5.operator 
                                                           }
                                                           else if(obj6.control_type==4 || obj6.control_type==5 || obj6.control_type==6 || obj6.control_type==9 || obj6.control_type==13)
                                                           {
                                                               resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==3)?" =":" !=")+" "+obj6.value+" "+obj5.operator
                                                           }
                                                         }
                                                         else if(obj6.condition_id==5 || obj6.condition_id==6)
                                                         {
                                                           if(obj6.control_type==7 || obj6.control_type==8)
                                                             resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==5)?" <":" >")+" '"+obj6.value+"'::date "+obj5.operator
                                                           else
                                                             resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==5)?" < ":" > ")+obj6.value+" "+obj5.operator
                                                         }
                                                         else if(obj6.condition_id==7 || obj6.condition_id==8 || obj6.condition_id==9)
                                                         {
                                                           if(obj6.control_type==7 || obj6.control_type==8)
                                                           {
                                                             if(obj6.condition_id==7)
                                                               resolveList=resolveList+" a."+obj6.field_id+"::date = '"+obj6.value+"'::date "+obj5.operator
                                                             else if(obj6.condition_id==8 || obj6.condition_id==9)
                                                               resolveList=resolveList+" a."+obj6.field_id+((obj6.condition_id==8)?" > '":" < '")+obj6.value+"'::date "+obj5.operator
                                                           } 
                                                         }
                                                     }
                                                     else
                                                     {
                                                       if(obj6.list.length>0)
                                                       {
                                                         for(let p=0;p< obj6.list.length;p++)
                                                         {
                                                           if(p==0)
                                                           {
                                                             resolveList=resolveList+" ( "
                                                           }
                                                           var obj7=obj6.list[p];
                                                           if(obj7.type=='row')
                                                           {
                                                            if(obj7.is_session_value ==true){
                                                              obj7.value=sessionParams[obj7.session_value];
                                                            }
                                                               if(obj7.condition_id==1)
                                                               resolveList=resolveList+" lower(a."+obj7.field_id+") "+" "+"like"+" "+"lower('%"+obj7.value+"%') "+obj6.operator
                                                             else if(obj7.condition_id==2)
                                                               resolveList=resolveList+" lower(a."+obj7.field_id+") "+" "+"not like"+" "+"lower('%"+obj7.value+"%') "+obj6.operator
                                                               else if(obj7.condition_id==12 || obj7.condition_id==13)
                                                               resolveList=resolveList+" lower(a."+obj7.field_id+") "+" "+"like"+" "+((obj7.condition_id==12)?("lower('"+obj7.value+"%') "):("lower('%"+obj7.value+"') "))+obj6.operator
                                                             else if(obj7.condition_id==3 || obj7.condition_id==4)
                                                             {
                                                               if(obj7.control_type==1 || obj7.control_type==2 || obj7.control_type==3 || obj7.control_type==11 || obj7.control_type==12)
                                                               resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==3)?" =":" !=")+" '"+obj7.value+"' "+obj6.operator
                                                               else if(obj7.control_type==10 ||  obj7.control_type==14 || obj7.control_type==15)
                                                               {
                                                                 var ids='';
                                                                 if(obj7.value.length>0)
                                                                 {
                                                                   for(let ob of obj7.value){ ids=ids+((obj7.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                                   ids=ids.substring(0,ids.length-1);
                                                                 }
                                                                 resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==3)?" in":" not in")+" ("+ids+") "+obj6.operator 
                                                               }
                                                               else if(obj7.control_type==4 || obj7.control_type==5 || obj7.control_type==6 || obj7.control_type==9 || obj7.control_type==13)
                                                               {
                                                                   resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==3)?" =":" !=")+" "+obj7.value+" "+obj6.operator
                                                               }
                                                             }
                                                             else if(obj7.condition_id==5 || obj7.condition_id==6)
                                                             {
                                                               if(obj7.control_type==7 || obj7.control_type==8)
                                                                 resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==5)?" <":" >")+" '"+obj7.value+"'::date "+obj6.operator
                                                               else
                                                                 resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==5)?" < ":" > ")+obj7.value+" "+obj6.operator
                                                             }
                                                             else if(obj7.condition_id==7 || obj7.condition_id==8 || obj7.condition_id==9)
                                                             {
                                                               if(obj7.control_type==7 || obj7.control_type==8)
                                                               {
                                                                 if(obj7.condition_id==7)
                                                                   resolveList=resolveList+" a."+obj7.field_id+"::date = '"+obj7.value+"'::date "+obj6.operator
                                                                 else if(obj7.condition_id==8 || obj7.condition_id==9)
                                                                   resolveList=resolveList+" a."+obj7.field_id+((obj7.condition_id==8)?" > '":" < '")+obj7.value+"'::date "+obj6.operator
                                                               } 
                                                             }
                                                           }
                                                           else
                                                           {
                                                             if(obj7.list.length>0)
                                                             {
                                                               for(let q=0;q< obj7.list.length;q++)
                                                               {
                                                                 if(q==0)
                                                                 {
                                                                   resolveList=resolveList+" ( "
                                                                 }
                                                                 var obj8=obj7.list[q];
                                                                 if(obj8.type=='row')
                                                                 {
                                                                  if(obj8.is_session_value ==true){
                                                                    obj8.value=sessionParams[obj8.session_value];
                                                                  }
                                                                   if(obj8.condition_id==1)
                                                                     resolveList=resolveList+" lower(a."+obj8.field_id+") "+" "+"like"+" "+"lower('%"+obj8.value+"%') "+obj7.operator
                                                                   else if(obj8.condition_id==2)
                                                                     resolveList=resolveList+" lower(a."+obj8.field_id+") "+" "+"not like"+" "+"lower('%"+obj8.value+"%') "+obj7.operator
                                                                     else if(obj8.condition_id==12 || obj8.condition_id==13)
                                                                     resolveList=resolveList+" lower(a."+obj8.field_id+") "+" "+"like"+" "+((obj8.condition_id==12)?("lower('"+obj8.value+"%') "):("lower('%"+obj8.value+"') "))+obj7.operator
                                                                     else if(obj8.condition_id==3 || obj8.condition_id==4)
                                                                   {
                                                                     if(obj8.control_type==1 || obj8.control_type==2 || obj8.control_type==3 || obj8.control_type==11 || obj8.control_type==12)
                                                                      resolveList=resolveList+" lower(a."+obj8.field_id+") "+((obj8.condition_id==3)?" =":" !=")+" lower('%"+obj8.value+"%') "+obj7.operator
                                                                      else if(obj8.control_type==10 ||  obj8.control_type==14 || obj8.control_type==15)
                                                                      {
                                                                        var ids='';
                                                                        if(obj8.value.length>0)
                                                                        {
                                                                          for(let ob of obj8.value){ ids=ids+((obj8.control_type==10)?("'"+ob.id+"'"):(ob.id))+"," }
                                                                          ids=ids.substring(0,ids.length-1);
                                                                        }
                                                                        resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==3)?" in":" not in")+" ("+ids+") "+obj7.operator 
                                                                      }
                                                                      else if(obj8.control_type==4 || obj8.control_type==5 || obj8.control_type==6 || obj8.control_type==9 || obj8.control_type==13)
                                                                      {
                                                                          resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==3)?" =":" !=")+" "+obj8.value+" "+obj7.operator
                                                                      }
                                                                   }
                                                                   else if(obj8.condition_id==5 || obj8.condition_id==6)
                                                                   {
                                                                      if(obj8.control_type==7 || obj8.control_type==8)
                                                                       resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==5)?" <":" >")+" '"+obj8.value+"'::date "+obj7.operator
                                                                      else
                                                                       resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==5)?" < ":" > ")+obj8.value+" "+obj7.operator
                                                                   }
                                                                   else if(obj8.condition_id==7 || obj8.condition_id==8 || obj8.condition_id==9)
                                                                   {
                                                                     if(obj8.control_type==7 || obj8.control_type==8)
                                                                     {
                                                                       if(obj8.condition_id==7)
                                                                         resolveList=resolveList+" a."+obj8.field_id+"::date = '"+obj8.value+"'::date "+obj7.operator
                                                                       else if(obj8.condition_id==8 || obj8.condition_id==9)
                                                                         resolveList=resolveList+" a."+obj8.field_id+((obj8.condition_id==8)?" > '":" < '")+obj8.value+"'::date "+obj7.operator
                                                                     } 
                                                                   }
                                                                 }     
                                                               }
                                                               resolveList=resolveList.substring(0,resolveList.length-((obj7.operator=='and')?3:2))+" ) "+obj6.operator      
                                                             }
                                                           }
                                                         }
                                                         resolveList=resolveList.substring(0,resolveList.length-((obj6.operator=='and')?3:2))+" ) "+obj5.operator      
                                                       }
                                                     }
                                                   }
                                                   resolveList=resolveList.substring(0,resolveList.length-((obj5.operator=='and')?3:2))+" ) "+obj4.operator      
                                                 }   
                                               }
                                             }
                                             resolveList=resolveList.substring(0,resolveList.length-((obj4.operator=='and')?3:2))+" ) "+obj3.operator      
                                           }     
                                         }
                                       }
                                       resolveList=resolveList.substring(0,resolveList.length-((obj3.operator=='and')?3:2))+" ) "+obj2.operator      
                                     }
                                   }     
                                 }
                                 resolveList=resolveList.substring(0,resolveList.length-((obj2.operator=='and')?3:2))+" ) "+obj1.operator           
                               }       
                             }             
                           }
                           resolveList=resolveList.substring(0,resolveList.length-((obj1.operator=='and')?3:2))+" ) "+obj.operator         
                         }    
                      }
                   }
                  resolveList=resolveList.substring(0,resolveList.length-((obj.operator=='and')?3:2))+" ) "+criteriaJsonList[0].operator
                   }
                }
             }
             console.log("queryStr",resolveList)    
             resolveList=resolveList.substring(0,resolveList.length-((criteriaJsonList[0].operator=='and')?3:2))+" ) "
            }
     }
    
     return resolveList;
    //  console.log("queryStr",resolveList)    
   }
     catch(err)
     {
        return null
     }
   }


   saveAuditData(formid,rowid,auditjson):Observable<any>{
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    var QueryObj={formid,rowid,auditjson};
    return this.http.post(this.url+"/saveauditdata",QueryObj , { headers }).pipe(map(res => { 
      return res as any;
      },error => {
        return error as any;
    }))
  }

  pdfToImageData(filePath){    
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    let pdf_path ={"pdf_path":filePath}    
    return this.http.post(this.url + '/pdftoimage',pdf_path, { headers }).pipe(map((data) => {
      return data as any;
    }, error => {
      console.log("there was an error sending the query", error);
    }
    ));
  }
  deletePdfTempPath(filePath){    
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    let pdf_path ={"pdf_folder_path":filePath}    
    return this.http.post(this.url + '/deletepdfdirectory',pdf_path, { headers }).pipe(map((data) => {
      return data as any;
    }, error => {
      console.log("there was an error sending the query", error);
    }
    ));
  }

  changePassword(userId,oldPassword,newPassword){
    const headers = new HttpHeaders()
    let json={"userId":userId,"oldPassword":oldPassword,"newPassword" :newPassword}
    console.log(json)
    headers.append('application/json', 'Content-Type')
    return this.http.post(this.url+'/changePasswordForNicAdmin',json, { headers }).pipe(map(res => {
      return res as any;
    },error => {
      return error as any;
    }))
  }

  
  loginNew(username,password,finalDt){
    const headers = new HttpHeaders()
    headers.append('application/json', 'Content-Type')
    return this.http.post(this.url+'/loginnew',{username,password,finalDt}, { headers }).pipe(map(res => {
      return res as any;
    },error => {
      return error as any;
    }))
  }

  convertAmountIntoWords(n){
    var nums = n.toString().split('.')
    var whole = this.Rs(nums[0])
    if(nums[1]==null)nums[1]=0;
    if(nums[1].length == 1 )nums[1]=nums[1]+'0';
    if(nums[1].length> 2){nums[1]=nums[1].substring(2,length - 1)}
    if(nums.length == 2){
    if(nums[0]<=9){nums[0]=nums[0]*10} else {nums[0]=nums[0]};
    var fraction = this.Rs(nums[1])
    var op='';
    if(whole=='' && fraction==''){ op= 'Zero only'; }
    if(whole=='' && fraction!=''){op= 'paise ' + fraction + ' only';}
    if(whole!='' && fraction==''){op='' + whole + ' only';} 
    if(whole!='' && fraction!=''){op='' + whole + 'and paise ' + fraction + ' only';}
    var amt=n;
    if(amt > 999999999.99){op='Oops!!! The amount is too big to convert';}
    if(isNaN(amt) == true ){op='Error : Amount in number appears to be incorrect. Please Check.';}
  //  console.log(op,"op")
   return op;  
  }
  }
   Rs(amount){
    var words = new Array();
    words[0] = 'Zero';words[1] = 'One';words[2] = 'Two';words[3] = 'Three';words[4] = 'Four';words[5] = 'Five';words[6] = 'Six';words[7] = 'Seven';words[8] = 'Eight';words[9] = 'Nine';words[10] = 'Ten';words[11] = 'Eleven';words[12] = 'Twelve';words[13] = 'Thirteen';words[14] = 'Fourteen';words[15] = 'Fifteen';words[16] = 'Sixteen';words[17] = 'Seventeen';words[18] = 'Eighteen';words[19] = 'Nineteen';words[20] = 'Twenty';words[30] = 'Thirty';words[40] = 'Forty';words[50] = 'Fifty';words[60] = 'Sixty';words[70] = 'Seventy';words[80] = 'Eighty';words[90] = 'Ninety';var op;
    amount = amount.toString();
    var atemp = amount.split('.');
    var number = atemp[0].split(',').join('');
    var n_length = number.length;
    var words_string = '';
    if(n_length <= 9){
    var n_array: any[]= [0, 0, 0, 0, 0, 0, 0, 0, 0];
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++){
    received_n_array[i] = number.substr(i, 1);}
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++){
    n_array[i] = received_n_array[j];}
    for (var i = 0, j = 1; i < 9; i++, j++){
    if(i == 0 || i == 2 || i == 4 || i == 7){
    if(n_array[i] == 1){
    n_array[j] = 10 + parseInt(n_array[j]);
    n_array[i] = 0;}}}
    let value = 0;
    for (var i = 0; i < 9; i++){
    if(i == 0 || i == 2 || i == 4 || i == 7){
    value = n_array[i] * 10;} else {
    value = n_array[i];}
    if(value != 0){
    words_string += words[value] + ' ';}
    if((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Crores ';}
    if((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Lakhs ';}
    if((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Thousand ';}
    if(i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)){
    words_string += 'Hundred and ';} else if(i == 6 && value != 0){
    words_string += 'Hundred ';}}
    words_string = words_string.split(' ').join(' ');}
    return words_string;
  }

  
}



