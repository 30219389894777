import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { CommonFunctionService } from '../../_services/common-function.service';
import { ProductService } from '../../_services/product.service';
declare var $: any;
@Component({
  selector: 'app-total-product-listing',
  templateUrl: './total-product-listing.component.html',
  styleUrls: ['./total-product-listing.component.css']
})
export class TotalProductListingComponent implements OnInit {
  seller_id: any;
  adminRemarks: any;
  QC_id: any;
  view_item_id: any;
  isView: boolean;
  allProductList: any;
  isLoader: boolean = true;
  susendedDataShow: boolean = false;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  LoadPaginationone :boolean = false;
  LoadPaginationtwo : boolean = false;
  LoadPaginationthree : boolean = false;
  TempallProductList: any;
  isLoading: boolean = false;
  // isLoader: boolean = true;
  userId: any;
  approvedDataShow: boolean = false;
  blockDataShow: boolean = false;
  allProductListData: any;
  productId:any;
  action: any;
  qcstatus: any;
  rejectDataShow:boolean = false;
  approvedCount : any;
  rejectCount : any;
  blockCount : any;
  suspendedCount : any;
  searchTerm: any;
  isloadgridTrue: any;
  constructor(private productService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,
    private router: Router) { }

  ngOnInit(): void {
    this.seller_id = localStorage.getItem('sellerId');
    this.isLoading = true;
    this.approvedDataShow = true;
    this.blockDataShow = false;
    this.susendedDataShow = false;
    this.rejectDataShow = false;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
  //  this.LoadPagination = true;
    this.bindNgxDataGrid(this.page, 1)

    $(document).ready(function(){
      $('ul.tbsbtns li').click(function(){
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
    });
    });
  this.getProductList();
  }
  getProductList(){
  this.productService.getAllProductsList().subscribe(res => {
    this.allProductListData = res.rows;
    this.approvedCount = (this.allProductListData.filter(x => x.qcstatus == 173)).length;
    this.rejectCount = (this.allProductListData.filter(x => x.qcstatus == 174)).length;
    this.blockCount = (this.allProductListData.filter(x => x.qcstatus == 185)).length;
    this.suspendedCount = (this.allProductListData.filter(x => x.qcstatus == 186)).length;
   // console.log( this.suspendedCount)
  })
}
  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)
  }
  bindNgxDataGrid(pageInfo, status) {
 
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
   let qcStatus = 173;
   if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
    this.productService.getAllProductsListcount(qcStatus, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllPendingProductsList(pagesize,offset,qcStatus, this.searchTerm).subscribe(res => {
      if(res.rows.length > 0){
        this.allProductList = res.rows;
        this.isLoader = false;
        this.TempallProductList = this.allProductList;
      }else{
        setTimeout(() => {
          this.allProductList = []
        this.isLoader = false;
        }, 100);
      }
       
      
      })
      this.totalCount = resp.rows[0].count;
     let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      // console.log("total product==>",this.configParms);
      this.LoadPagination = true;
      this.LoadPaginationone = false;
      this.LoadPaginationtwo = false;
      this.LoadPaginationthree = false;
      this.isLoading = false;
    })

  }


  LoadGrid1(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid1(this.page, 1)
  }
  bindNgxDataGrid1(pageInfo, status) {
    
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
   let qcStatus = 185;
   if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
    this.productService.getAllProductsListcount(qcStatus, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllPendingProductsList(pagesize,offset,qcStatus, this.searchTerm).subscribe(res => {
        if(res.rows.length > 0){
          this.allProductList = res.rows;
          this.isLoader = false;
          this.TempallProductList = this.allProductList;
        }else{
          setTimeout(() => {
          this.allProductList = []
          this.isLoader = false;
          }, 100);
        }
      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPaginationone = true;
      this.LoadPagination = false;
      this.LoadPaginationtwo = false;
      this.LoadPaginationthree = false;
      this.isLoading = false;
    })

  }


  LoadGrid2(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid2(this.page, 1)
  }
  bindNgxDataGrid2(pageInfo, status) {
 
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
   let qcStatus = 186;
   
if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
    this.productService.getAllProductsListcount(qcStatus, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllPendingProductsList(pagesize,offset,qcStatus, this.searchTerm).subscribe(res => {
        if(res.rows.length > 0){
          this.allProductList = res.rows;
          this.isLoader = false;
          this.TempallProductList = this.allProductList;
        }else{
          setTimeout(() => {
          this.allProductList = []
         this.isLoader = false;
          }, 100);
        }
        })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = false;
      this.LoadPaginationone = false;
      this.LoadPaginationtwo = true;
      this.LoadPaginationthree = false;
      this.isLoading = false;
    })

  }


  LoadGrid3(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid2(this.page, 1)
  }
  bindNgxDataGrid3(pageInfo, status) {
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
   let qcStatus = 174;
   if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
    this.productService.getAllProductsListcount(qcStatus, this.searchTerm).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      let pageCount = pageInfo.pageNumber;
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
        this.productService.getAllPendingProductsList(pagesize,offset,qcStatus, this.searchTerm ).subscribe(res => {
          if(res.rows.length > 0){
            this.allProductList = res.rows;
            this.isLoader = false;
            this.TempallProductList = this.allProductList;
          }else{
            setTimeout(() => {
          this.allProductList = []
            this.isLoader = false;
            }, 100);
          }
      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = false;
      this.LoadPaginationone = false;
      this.LoadPaginationtwo = false;
      this.LoadPaginationthree = true;
      this.isLoading = false;
    })

  }

  CommonGridSearchMaster(ev) {
    this.searchTerm = ev
      if(this.approvedDataShow)
        this.bindNgxDataGrid(this.page, 1)
        else if(this.blockDataShow)
          this.bindNgxDataGrid1(this.page, 1)
          else if(this.susendedDataShow)
            this.bindNgxDataGrid2(this.page, 1)
            else  
              this.bindNgxDataGrid3(this.page, 1)

    // this.allProductList = this.TempallProductList.filter(item => ((item.seller_name ? (item.seller_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.product_name ? (item.product_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.category_name ? (item.category_name.toString().indexOf(ev) !== -1) : false)))
  }

  ProductApprovedList() {
    this.searchTerm = ''
    this.approvedDataShow = true;
    this.blockDataShow = false;
    this.susendedDataShow = false
    this.rejectDataShow = false;
    this.bindNgxDataGrid(this.page, 1)
  }
  productBlockList() {
    this.searchTerm = ''
    this.approvedDataShow = false;
    this.susendedDataShow = false
    this.blockDataShow = true;
    this.rejectDataShow = false;
    this.bindNgxDataGrid1(this.page, 1)
   
   
  }
  productSuspendedList() {
    this.searchTerm = ''
    this.approvedDataShow = false;
    this.susendedDataShow = true
    this.blockDataShow = false;
    this.rejectDataShow = false;
    this.bindNgxDataGrid2(this.page, 1)
  
  }
  productRejectedList(){
    this.searchTerm = ''
    this.approvedDataShow = false;
    this.susendedDataShow = false;
    this.blockDataShow = false;
    this.rejectDataShow = true;
    this.bindNgxDataGrid3(this.page, 1)

  }
  onProductView(data) {
    this.adminRemarks = ''
    localStorage.setItem("view-item", data);
    this.productId = data.rowData.product_id;
    this.isView = true;
    $("#myModal").modal('show');
      this.action = ''
      this.qcstatus = data.rowData.qcstatus
  
 }
  closeModal() {
    this.isView = false;
    $("#myModal").modal('hide');
  }


  updateStatusPopup(data, action) {
    this.qcstatus = ''
    this.adminRemarks = ''
    this.action = action
    this.productId = data.rowData.product_id
    $("#myModal").modal('show');
  }
  onBlock() {
    if ( this.adminRemarks == '' ||this.adminRemarks == null ||this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
      this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false
    }
    let productStatus = 185;
    
    this.productService.productStatusupdate(this.productId,productStatus, this.adminRemarks,this.userId, '', '', '').subscribe(res => {
      this.toastrService.success("Product Blocked Successfully", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
        $("#myModal").modal('hide');
      this.getProductList();
     // this.LoadGrid(event); 
     this.bindNgxDataGrid(this.page, 1)
      // this.LoadPagination = true;
      // this.LoadPaginationone = false;
      // this.LoadPaginationtwo = false;
      // this.LoadPaginationthree = false;
    })
  }
  onSuspended() {
    if ( this.adminRemarks == '' ||this.adminRemarks == null ||this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
      this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false
    }
    let productStatus = 186;
    this.productService.productStatusupdate(this.productId,productStatus, this.adminRemarks,this.userId, '', '', '').subscribe(res => {
      this.toastrService.success(res.msg, 'Product Suspended Successfully', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      $("#myModal").modal('hide');
      // this.LoadPagination = true;
      // this.LoadPaginationone = false;
      // this.LoadPaginationtwo = false;
      // this.LoadPaginationthree = false;
      this.getProductList();
      this.bindNgxDataGrid(this.page, 1)
      
    })
  }
  onActive() {
    if ( this.adminRemarks == '' ||this.adminRemarks == null ||this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
      this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
      return false
    }
    let productStatus = 173;
    this.productService.productStatusupdate(this.productId,productStatus, this.adminRemarks,this.userId, '', '', '').subscribe(res => {
     
    
      this.toastrService.success(res.msg, 'Product Unblocked Successfully', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
            $("#myModal").modal('hide');
    // this.LoadPagination = true;
    // this.LoadPaginationone = false;
    // this.LoadPaginationtwo = false;
    // this.LoadPaginationthree = false;
    this.getProductList();
     this.bindNgxDataGrid(this.page, 1)
     
     })
  }
  reset(){
    this.adminRemarks = '';
    $("#myModal").modal('hide');
  }

  
 
  // onUnblock() {
  //   this.productService.productStatus(this.QC_id, 174, this.adminRemarks).subscribe(res => {
  //     this.toastrService.success(res.msg, '', { timeOut: 1000, positionClass: "toast-top-center" });
  //     $("#myModal").modal('hide');
  //     this.bindNgxDataGrid(this.page, 1)

  //   })
  // }
  // callBack(product_id) {
  //   this.productService.callBack(product_id).subscribe(res => {
  //     this.toastrService.success(res.msg, '', { timeOut: 1000, positionClass: "toast-top-center" });
  //     this.getAllProducts();
  //     setTimeout(() => {
  //       this.getList(171, "draft");
  //     }, 100)
  //   })
  // }
  
  productListing(id){
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
  this.router.navigateByUrl('/seller-wise-product' + '?' + localStorage.getItem('tempToken')  );
  }
  showSellerDetails(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem('approve_reject', 'false')
    this.router.navigateByUrl('/seller-profile'  + '?' + localStorage.getItem('tempToken') );
  }
  onProductdetailView(data) {
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    $('#scroll').animate({ scrollTop: 0 }, 'slow');
   
    
    this.isView = true;
    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal").modal('show');
      //this.toastrService.success("Product Approved", '', { timeOut: 1000, positionClass: "toast-top-center" 
    });
  }

  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
}
