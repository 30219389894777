<div class="d-flex minhyt96vh" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>

              <li class="breadcrumb-item newitm2  active" aria-current="page">Bulk Order List </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="col-sm-12 mb-2 p-0">

        <div class="tabs row my-2 mx-0">
          <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
            <ul id="tab-button">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font9">Bulk Order List
                </a>
              </li>
            </ul>

            <!-- <ul id="tab-button" class="tbsbtns">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font8">Bulk Order List </a>
              </li>
              <li id="tabDraft" class="is-active">
                  <a href="javascript:void(0)" class="font8">Approved Brand List </a>
                </li>

            </ul> -->
          </div>
          <div class="col-sm-3 pr-0" id="masterSearch">
            <div class="input-group">
              <input type="text" class="searchbar form-control newform-control" autocomplete="off" onpaste="return false;" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
              <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 mt-0" onpaste="return false;" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="tab01" class="tab-contents mmt5 p-0 bulk-order-query-list">
        <div class="col-sm-12 col-md-12 nopadding">
          <div id="grid-theme-wrapper">
            <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
            <ngx-loading [show]="isLoading"></ngx-loading>
            <igx-grid *ngIf="bulkOrderList.length > 0" #grid1 [data]="bulkOrderList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
              [filterMode]="'excelStyleFilter'">
              <!-- <igx-column field="Srno" header="Sr No" width="" >
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl" [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>   -->
              <igx-column field="full_name" header="Full Name" width="200">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="mobile_no" header="Mobile No" width="175">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="email" header="Email ID" width="228">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell-vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="requirement" header="Requirement Message" width="228">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <p class="bulkorderquery cell-vl  mb-1" [innerHtml]="cell.value"></p>
                    <p class="bulkorderquery mb-0">
                      <a href="#" class="bm-link" data-toggle="modal" data-target="#bulk-sms" (click)="viewmore(cell.value)">View more</a>
                    </p>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="ref_no" header="Reference No" width="204">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <span class="cell- vl " [innerHtml]="cell.value"> </span>
                  </div>
                </ng-template>
              </igx-column>
              <igx-column field="" header="Action" width="150">
                <ng-template #compositeTemp igxCell let-cell="cell">
                  <div class="address-container">
                    <div class="pl-4">
                      <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                          aria-expanded="false">
                          <span class="py-2 px-3">Action</span>
                          <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                            <i class=" fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                        </button>
                        <!-- <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle py-2" data-toggle="dropdown">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                </button> -->
                        <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                          <li>
                            <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                              <i class="fa fa fa-trash mr-1"></i> Delete </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </igx-column>

            </igx-grid>
            <label class="w-100 text-center text-dark" *ngIf="!isLoading && bulkOrderList.length == 0">No Record Found</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- view model -->



<div class="modal fade" id="bulk-sms" tabindex="-1" aria-labelledby="bulk-sms" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header px-3 py-2">
        <h6 class="modal-title" id="bulk-sms"> Bulk Order Message</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"> &times; </span>
        </button>
      </div>
      <div class="modal-body minhyt70 p-3" data-spy="scroll" id="scroll">
        <p class="font8">{{globalmsg}}</p>
      </div>

    </div>
  </div>
</div>