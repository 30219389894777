<div class="d-flex minhyt80" id="wrapper">
  <div class="" id="page-content-wrapper">
    <app-header> </app-header>
    <div class="webdesk">
      <app-left-menu></app-left-menu>
    </div>
    <!-- <ngx-loading [show]="isLoading"></ngx-loading> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 p-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
              <li class="breadcrumb-item newitm1">
                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
              </li>

              <li class="breadcrumb-item newitm2  active" aria-current="page">Manage GST Details </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="col-sm-12 mb-2 p-0">

        <div class="tabs row my-2 mx-0">

          <div class="tab-button-outer1 col-sm col-9 px-0 mb-sm-0 mb-2">
            <ul id="tab-button">
              <li id="tabDraft" class="is-active">
                <a href="javascript:void(0)" class="font9">Manage GST
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-3 px-sm-3 px-0 order-sm-1 order-2" id="masterSearch">
            <div class="input-group">
              <input type="text" class="searchbar form-control newform-control" onpaste="return false;" autocomplete="off" (keyup)="CommonGridSearchMaster(SearchValue.value)"
                placeholder="Search in Page" #SearchValue id="searchvaluemaster" />
              <a href="javascript:void(0)" id="Searchdraft" class="btn btn-cstm px-2 ml-2 mt-0" (click)="CommonGridSearchMaster(SearchValue.value)">
                <i class="fa fa-search"></i>
              </a>
            </div>
          </div>
          <div class="col-sm-auto text-right px-0 col-3 order-sm-2 order-1">
            <button type="button" class="btn btn-sm btn-circle btn-circle-sm  btn-theme toolbar-item ml-1" (click)="openpopup()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div id="tab01" class="tab-contents mmt5 p-0">

          <div class="col-sm-12 col-md-12 nopadding ">
            <div id="grid-theme-wrapper">
              <!-- [(perPage)]="page.size" -->
              <app-hds-main-tab-group [configParms]="configParms" (valueChange)="LoadGrid($event)" *ngIf="LoadPagination == true"></app-hds-main-tab-group>
              <ngx-loading [show]="isLoading"></ngx-loading>
              <div class="table-responsive" *ngIf="gstDataList.length > 0">
                <igx-grid #grid1 [data]="gstDataList" width="100%" height="" [autoGenerate]="false" [allowFiltering]="true" displayDensity="cosy"
                  [filterMode]="'excelStyleFilter'" [(perPage)]="page.size">
                  <igx-column field="" header="Sr. No." width="80" [filterable]="false">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container w-100 text-center">
                        {{cell.row.index + 1}}
                      </div>
                    </ng-template>
                  </igx-column> 
                  <igx-column field="value" header="GST Value(%)" width="850">
                    <ng-template #compositeTemp igxCell let-cell="cell">
                      <div class="address-container">
                        <span class="cell-vl" [innerHtml]="cell.value"></span>
                      </div>
                    </ng-template>
                  </igx-column>
                  <igx-column field="" header="Action" width="300">
                    <ng-template compositeTemp igxCell let-cell="cell">
                        <div class="px-2 address-container">
                            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-light shadow-none dropdown-toggle px-0 py-0" data-toggle="dropdown"
                                aria-expanded="false">
                                <span class="py-2 px-3">Action</span>
                                <button class="btn btn-sm btn-light shadow-none py-2 border-top-0 border-bottom-0 border-right-0">
                                  <i class=" fa fa-caret-down" aria-hidden="true"></i>
                                </button>
                              </button>
                              <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop1">
                                <li class="border-bottom">
                                  <a href="javascript:void(0)" data-toggle="modal" (click)="onEdit(cell)" data-target="#myModal" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                    <i class="fa fa fa-pencil mr-1"></i> Edit </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                    <i class="fa fa fa-trash mr-1"></i> Delete </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                      <!-- <div class="px-2">
                        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                          <button id="btnGroupDrop5" type="button" class="py-2 px-3 btn btn-sm btn-light shadow-none dropdown-toggle" data-toggle="dropdown"
                            aria-expanded="false">
                            Action
                          </button>
                          <button id="btnGroupDrop5" type="button" class="py-2 btn btn-sm btn-light shadow-none dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                          </button>
                          <ul class="dropdown-menu px-0" aria-labelledby="btnGroupDrop5">
                            <li class="border-bottom">
                              <a href="javascript:void(0)" data-toggle="modal" (click)="onEdit(cell)" data-target="#myModal" class="text-decoration-none font-weight-bold text-darkorange dropdown-item">
                                <i class="fa fa fa-pencil mr-1"></i> Edit </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" (click)="onDelete(cell)" class="text-decoration-none font-weight-bold text-danger dropdown-item">
                                <i class="fa fa fa-trash mr-1"></i> Delete </a>
                            </li>
                          </ul>
                        </div>
                      </div> -->
                    </ng-template>
                  </igx-column>
                </igx-grid>
              </div>
              <label class="w-100 text-center text-dark" *ngIf="!isLoading && gstDataList.length == 0">No Record Found</label>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div id="myModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header d-flex align-items-center py-2">
            <h6 class="mb-0">
              <strong>{{isShow ? "Add GST Details " : "Edit GST"}}</strong>
            </h6>
            <button type="button" class="close" data-dismiss="modal" (click)="resetForm()">
              <span> &times; </span>
            </button>
          </div>
          <div class="modal-body">
            <!-- <h6 class="px-2 mb-0 mt-0 font8">Edit Banner</h6> -->


            <div class="row mb-3 mt-1 m-0">
              <div class="col-sm-3 text-sm-right">
                <label>GST Value :
                  <span class="asterisk"> *</span>
                </label>
              </div>
              <div class="col-sm-9">
                <input type="text" id="gst" name="gst" [(ngModel)]="gstValue" class="form-control" maxlength="2" onpaste="return false;"
                  placeholder="Enter GST Value" oncopy="return false;" autocomplete="off" ondrop="return false;" onkeypress="return /[0-9]/i.test(event.key)" >
              </div>
            </div>

            <div class="text-center my-2">
              <button type="button" (click)="resetForm()" class="btn btn-sm btn-warning px-3 mr-2">Reset</button>
              <button type="submit" id="btnSave" class="btn btn-sm btn-success  px-3 mr-2" *ngIf="isShow" (click)="onSubmit()">Save</button>
              <button type="submit" class="btn btn-sm btn-success px-3" *ngIf="!isShow" (click)="update()">Update</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


</div>