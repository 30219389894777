<div class="widget-box widget-color-blue mt-0 border-l-t-r ">
    <div class="widget-header">
        <h5 class="widget-title bigger lighter h text-white">
            <i class="ace-icon fa fa-file"></i>
            File
            <span style="font-size: 12px">123563</span>
        </h5>
       
        <!-- <div class="rytcont">
            <select name="documentlist" class="mr-3">
                <option value="selectdoc">Select Document</option>
                <option value="doc1">Document 1</option>
                <option value="doc2">Document 2</option>
                <option value="doc3">Document 3</option>
             
              </select>
            <a class="greensheetlink big180" href="javascript:void(0)" (click)="showsend()">
             <i class="fa fa-share big-150"></i>  Send
            </a>
            <a class="greensheetlink big180" href="javascript:void(0)" (click)="shownote()">
                    <i class="fa fa-plus-circle big-150"></i>   Add Note
                   </a>
            <a class="greensheetlink big180" href="javascript:void(0)" (click)="showattach()">
                    <i class="fa fa-plus-circle big-150"></i>   Attachment 
                   </a>
                 
       
        </div> -->
    </div>
    <div class="widget-body">
        <div class="widget-main no-padding minhyt100 notesheet">
            <div class="col-lg-12 nopadding" id="wizard1">
                <section class="nopadding">
                    <table class="table" id="tbl1">
                        <tbody>
                             <tr  *ngFor="let lst of notesheetList; let i=index">
                                    <td class="armada-devider">
                                        <p class="txtcenter">{{(i+1)}}</p>
                                    </td>
                                    <td class="driver-devider">
                                        <p class="mb-0">
                                             {{lst.noting_date}}  
                                             <!-- <span class="pull-right" > <a href="javascript:void(0)"  >1.2</a> </span> -->
                                        </p>
                                       
                                        <div id="txtNote1" class="txtnoteleft">
                                            <p [innerHTML]="lst.notesheet_containt"></p>
                                            <!-- <p>{{lst.notesheet_containt}}</p> -->
                                            <!-- <p>
                                                Content Should be here
                                            </p> -->
                                        </div>
                                        <br/>
                                        <div style="float:right">
                                            {{lst.user_fname}} <br/>
                                            {{lst.role_name}} 
                                             <!-- Rajkumar Singh -->
                                        </div>
                                    </td>
                                    <td class="progress-devider">
                                        &nbsp;
                                    </td>
                                </tr>
                        </tbody>
                        
                    </table>
                </section>
            </div>
            <!-- <div class="card shdow" id="senddata">
                <div class="card-body px-1">
                    <div class="col-lg-12" >
                        <div class="row mb-2">
                            <div class="col-lg-12 pull-right">
                                <a href="javascript:void(0)" class="pull-right font8"><i class="fa fa-file"></i> View Docs</a>
                            </div>
                        </div>
                        <hr class="my-1">
                        <div class="row my-1">
                            <div class="col-sm-2">
                                <label>Reciptient's Type</label>
                            </div>
                            <div class="col-sm-4">
                                <select class="form-control" >
                                    <option>Internal</option>
                                </select>
                            </div>
                            <div class="col-sm-2">
                                <label>Send To</label>
                            </div>
                            <div class="col-sm-4">
                                <select class="form-control" >
                                    <option>--Select Send--</option>
                                </select>
                            </div>
                        </div>
                        <hr class="my-1">
                        <div class="row my-1">
                            <div class="col-sm-2">
                                <label>Priority</label>
                            </div>
                            <div class="col-sm-4">
                                <select class="form-control" >
                                    <option>Normal</option>
                                </select>
                            </div>
                            <div class="col-sm-2">
                                <label>Due Date</label>
                            </div>
                            <div class="col-sm-4">
                                <input type="date" placeholder="mm/dd/yyyy" class="form-control">
                            </div>
                        </div>
                        <hr class="my-1">
                        <div class="row my-1">
                            <div class="col-sm-2">
                                <label>Mark for reply tracking</label>
                            </div>
                            <div class="col-sm-10">
                                <input class="mt-1" type="checkbox">
                            </div>
                        </div>
                        <hr class="my-1">
                        <div class="row my-1">
                            <div class="col-sm-12 text-right">
                                 <a href="javascript:void(0)" class="btn btn-dark rounded-pill px-3 mr-2" >
                                 Forward
                                </a>
                                <a href="javascript:void(0)" (click)="closesend()" class="btn btn-secondary rounded-pill px-3" >
                                    Close
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="card shdow" id="attachfileshow">
                <div class="card-body px-1">
                    <div class="row my-1">
                        <div class="col-sm-6">
                            <input type="file">
                        </div>
                        <div class="col-sm-2">
                            <label>Document Name</label>
                        </div>
                        <div class="col-sm-4">
                           <input class="form-control" placeholder="Document Name">
                        </div>
                    </div>
                    <hr class="my-1">
                    <div class="row my-1">
                            <div class="col-sm-12 text-right">
                                    <a href="javascript:void(0)" class="btn btn-dark rounded-pill px-3 mr-2" >
                                            Upload File
                                        </a>
                                        <a href="javascript:void(0)" (click)="closeattach()" class="btn btn-secondary rounded-pill px-3" >
                                               Close
                                           </a>
                            </div>
                    </div>
                    <hr class="my-1">
                    <div class="row my-1">
                        <div class="col-sm-12">
                                <div class="example-header cstmpage pt-0 cstmflxs pull-left my-2">
                                        <label class="mr-1">Page Size:</label>
                                        <select id="page-size" class="cstmselect">
                                            <option value="10" selected="">10</option>
                                            <option value="10" selected="">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>&nbsp;&nbsp;
                                        <a href="javascript:void(0)" class="btn btn-sm btn-outline-dark filtericon pt-0 pb-0 hyt24 mttop-3" > <i class="fa fa-filter"></i> 
                                        </a>
                                    </div>
                            <table class="table table-bordered cstmtbl">
                                <tr>
                                    <th class="text-center"> Sr No.</th>
                                    <th>Document Name</th>
                                    <th>Notesheet</th>
                                </tr>
                                <tr class="bg-white">
                                    <td colspan="3">No Record Found</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="card shdow" id="addnoteshow">
                <div class="card-body px-1">
                 
                    <div class="row my-1">
                        <div class="col-sm-12">
                            
                            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
							<ngx-editor [editor]="editor" [(ngModel)]="templateTxt"></ngx-editor>
                        </div>
                    </div>
                    <hr class="my-1">
                    <div class="row my-1">
                        <div class="col-sm-2">
                            <label>Notesheet Sr. No.</label>
                        </div>
                        <div class="col-sm-4">
                            <input type="text" class="form-control" placeholder="Notesheet Sr. No.">
                        </div>
                        <div class="col-sm-2">
                                <label>Reference Type</label>
                            </div>
                            <div class="col-sm-4">
                               <select class="form-control">
                                   <option>--Select--</option>
                               </select>
                            </div>
                     </div>
                     <hr class="my-1">
                     <div class="row my-1">
                         <div class="col-sm-2">
                            <label>Select Relevant Reference
                                </label>
                         </div>
                         <div class="col-sm-4">
                             <input class="form-control">
                         </div>
                     </div>
                     <hr class="my-1">
                     <div class="row my-1">
                     <div class="col-sm-12 text-right">
                         <a href="javascript:void(0)" class="btn btn-dark rounded-pill px-3 mr-2">
                             Save
                         </a>
                         <a href="javascript:void(0)" class="btn btn-secondary rounded-pill px-3" (click)="closenote()">
                              Close
                            </a>
                            </div>
                </div>
            </div> -->
        </div>
    </div>
</div>