
		<app-header></app-header>
		<div class="container-fluid ">
			<div class="col-sm-12 p-0">
				<div class="col-sm-12 nopadding">
					<div class="col-sm-12 p-0">
						<div class="tabs col-lg-12 pl-0 pr-1 mt-2 d-flex">
							<div class="tab-button-outer1 font8 col-lg-6 pl-0">
								<ul id="tab-button">
									<li class="is-active" style="min-width: 80px; min-height: 33px;" id="{{formId}}"><a href="javascript:void(0)" (click)="getDetails(formId)">{{formName}}</a>
									</li>
									<div class="example-header cstmpage pt-2 cstminblock" *ngIf="gridType != 'ngx'">
										
										<a href="javascript:void(0)"   class="btn btn-sm btn-outline-dark filtericon pt-0 pb-0 hyt24 mr-1" (click)="modalRoot.show(); isResetFilter=false;"> <i class="fa fa-filter"></i> 
										</a> <span *ngIf="viewFilterString" class="badge badge-pill badge-success badge-outlined fnt100 pt-1   pb-1"> <div class="float-left"  [innerHTML]="viewFilterString"></div> <a href="javascript:void(0)" class="posclose" (click)="removeFilter()" ><i class="fa fa-times"></i> </a> </span>
									</div>
									<a href="javascript:void(0)"  *ngIf="gridType == 'ngx'"  class="btn btn-sm btn-outline-dark filtericon pt-0 pb-0 hyt24 mr-1" (click)="modalRoot.show(); isResetFilter=false;"> <i class="fa fa-filter"></i> 
									</a> 
									<span *ngIf="viewFilterString && gridType == 'ngx'" class="badge badge-pill badge-success badge-outlined fnt100 pt-1   pb-1"> 
										<div class="float-left"  [innerHTML]="viewFilterString"></div> <a href="javascript:void(0)" class="posclose" (click)="removeFilter()" ><i class="fa fa-times"></i> </a> </span>
									<!-- <li *ngFor="let lst of childFormList" id="{{lst.form_id}}"><a href="javascript:void(0)" (click)="getDetails(lst.form_id)">{{lst.form_name}}</a>
									</li> -->
									<!-- <li *ngIf="is_attachment == true"><a href="javascript:void(0)" (click)="showAttachmentDiv()">Attachments</a></li> -->
								</ul>
							</div>
							<div class="col-sm-6 cstmposition2 pr-0">
								<div class="sort-wrapper pull-right width100">
									<div class="form-group col-lg-9 nodisplay">
										<div class=" input-group">
											<input type="text" class="form-control width100 mr-2" placeholder="Search in Page" />
											<a href="javascript:void(0)" class="btn btn-cstm px-2"><i class="fa fa-search"></i></a>
										</div>
									</div>
									<div class="dropdown ml-lg-auto  toolbar-item" *ngIf="isExpotable">
										<button class="btn btn-secondary btn-sm   rounded-pill pl-3 pr-3  dropdown-toggle" type="button" id="dropdownexport" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
										<div class="dropdown-menu" aria-labelledby="dropdownexport" > <a class="dropdown-item" href="javascript:void(0)">Export as PDF</a>
											<a class="dropdown-item" href="javascript:void(0)">Export as DOCX</a>
											<a class="dropdown-item" href="javascript:void(0)" (click)="exportToExcel()">Export as CSV</a>
										</div>
									</div>&nbsp;&nbsp;
									<div>
									<button type="button" *ngIf="isAdd" class="btn btn-sm  btn-dark rounded-pill pl-3  pr-3 toolbar-item" (click)="onAddClick()">Add</button>&nbsp;</div>

									</div>
							</div>
						</div>
						
						<div class="tab-contents mmt5 p-0">
							
							<app-modal #modalRoot class="modal-demo custommodal">
								<ng-container class="app-modal-header p-2">Add Rule</ng-container>
								<ng-container class="app-modal-body">
									<app-user-rule [formId]="formId" [SessionUrlPrams]="sessionUrlPrams" [resetFilter]="isResetFilter" (applyEvent)="apply($event)"></app-user-rule>
								</ng-container>
								<ng-container class="app-modal-footer p-2 text-right">
									<button type="button" id="clos" (click)="modalRoot.hide()" style="display: none">Close</button>
								</ng-container>
							</app-modal>
							<div class="card-body nopadding" >
								<div class="col-sm-12">
									<div class="row">
										<div class="col-sm-12 col-md-12   nopadding ">
											<div class="col-sm-12 col-md-12 mt-0 mb-0 nopadding" *ngIf="gridType != 'ngx'">
												<div class="col-sm-12 p-0 "  *ngIf="DataRefreshLoaded  | async" >
												<ag-grid-angular style="width: 100%;" 
												class="ag-theme-alpine" 
												[overlayLoadingTemplate]="overlayLoadingTemplate"
      											[overlayNoRowsTemplate]="overlayNoRowsTemplate"
												suppressCsvExport="true"[gridOptions]="gridOptions" 
												[paginationPageSize]="pagesize"
												[cacheBlockSize]="pagesize"
												(columnResized)="onColumnResized($event)" (columnVisible)="onColumnVisible($event)" domLayout="autoHeight" [frameworkComponents]="frameworkComponents" [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" ></ag-grid-angular>
											
											</div>
											<div class="example-header cstmpage pt-2 cstposabs" *ngIf="gridType != 'ngx'">
												<label class="mr-1" *ngIf="gridType != 'ngx'">Page Size:</label>
												<select (change)="onPageSizeChanged()" id="page-size" class="cstmselect" *ngIf="gridType != 'ngx'">
													<option value="10" selected="">10</option>
													<option value="20" >20</option>
													<option value="30">30</option>
													<option value="50">50</option>													
												</select>&nbsp;&nbsp;</div>
											
											</div>

											<div *ngIf="gridType == 'ngx'">
											<ngx-datatable
											class="material"
											[rows]="data"
											[columnMode]="'force'"
											[headerHeight]="48"
											[footerHeight]="60"
											rowHeight="auto"
											[externalPaging]="true"
											[count]="page.totalElements"
											[offset]="page.pageNumber"
											[limit]="page.size"
											(page)="setPage($event)">
											<ngx-datatable-column *ngFor="let lst of columnDefs"  [sortable]="lst.sortable" name="{{lst.headerName}}" prop="{{lst.field}}" [width]="lst.width">
											  <ng-template let-rowIndex="rowIndex" let-row="row" let-value="value" ngx-datatable-cell-template>
												<div *ngIf="lst.headerName != 'Action'">
												   {{value}}
												</div>
												<div *ngIf="lst.headerName == 'Action'">
													<a title="Action" *ngIf="isEdit"  href="javascript:void(0)" (click)="onEdit(row)" class="text-body"><i class="fa fa-edit"></i> </a>&nbsp;&nbsp;
													<a title="Action" *ngIf="isView"  href="javascript:void(0)" (click)="onClickView(row)"><i class="fa fa-eye"></i> </a>
											   </div>
											   </ng-template>
											</ngx-datatable-column> 
											
											<ngx-datatable-footer>
											  <ng-template     
												  ngx-datatable-footer-template                          
												  let-rowCount="rowCount"
												  let-pageSize="pageSize"
												  let-selectedCount="selectedCount"
												  let-curPage="curPage"
												  let-offset="offset">
												  Total Results: {{rowCount}}
												  <span *ngIf="rowCount != 0" class="mx-2"> | Page Size:</span>
													  <div>
														  <select  style="padding: 2px 4px" type="text" *ngIf="rowCount != 0" [(ngModel)]="page.size" (ngModelChange)="onPageSizeChange($event)" >
															  <option *ngFor="let size of sizeList" [value]="size"> {{size}}</option>
														  </select>
													  </div>
												  <span [hidden]="selectedCount<=0"> | Selected: {{selectedCount}}</span>
												  <datatable-pager
													  [pagerLeftArrowIcon]="'datatable-icon-left'"
													  [pagerRightArrowIcon]="'datatable-icon-right'"
													  [pagerPreviousIcon]="'datatable-icon-prev'"
													  [pagerNextIcon]="'datatable-icon-skip'"
													  [page]="page.pageNumber"
													  [size]="page.size"
													  [count]="page.totalElements"
													  [hidden]="!((page.totalElements / page.size) > 1)"
													  (change)="onFooterPage($event)">
												  </datatable-pager>
											  </ng-template>
										  </ngx-datatable-footer> 
										  </ngx-datatable>
										</div>

										</div>
									</div>
								</div>
								
								<div class="modal" id="exampleModal" tabindex="-1" role="dialog">
									<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
										<div class="modal-content" id="example-content">
											<!-- <div class="modal-header p-2">
												<h6 class="modal-title">Add New
												
                                                </h6>
												
											</div> -->
											<div class="modal-body minhyt40 pt-1 pb-0" *ngIf="FormViewLoaded | async">
												<label  class="font9 successedit2 alert alert-success pt-0 pb-0" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
												<label class="font9 erroredit2 alert alert-warning pt-0 pb-0" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
												<ul class="nav nav-tabs">
													<li class="active"><a data-toggle="tab" class="active" href="#tab{{formId}}">{{formName}}</a>
													</li>
													<li *ngFor="let lst of childFormList"><a  [class]="isAddMode?'disabled':''" data-toggle="tab" href="#tab{{lst.form_id}}">{{lst.form_name}}</a>
													</li>
													<li *ngIf="is_attachment == true" ><a  [class]="isAddMode?'disabled':''" data-toggle="tab" href="#tabAttachment">Attachments</a> 
													</li>
													<li class="pull-right clsicn">
														<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
														</button>
													</li>
												</ul>
												<div class="tab-content tab-contents pt-0 pr-0 pl-0">
													<div id="tab{{formId}}" class="tab-pane fade in active show">
														<div class="col-sm-12 p-0">
														<hdsbpm-form-repeat [lstsection]="lstConfigJson" [tableName]="tableName" [tablePrimaryKey]="tablePrimaryKey" [SessionUrlPrams]="sessionUrlPrams"  [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode" [stageid]="stageid" [processid]="processid"  [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)"  (submit)="submit($event)"></hdsbpm-form-repeat>
													    </div>
													</div>
													<ng-container *ngIf="isAddMode == false">
														<div *ngFor="let lst of childFormList; index as i" id="tab{{lst.form_id}}" class="tab-pane fade" >
															<div class="col-sm-12 p-0">
																<hdsbpm-grid-form [lstsection]="lst" [SessionUrlPrams]="sessionUrlPrams" [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form>
															</div>														
														</div>
														<div class="tab-pane fade" id="tabAttachment">
															<div class="col-sm-12 p-0">
															<app-file-attachment id="add" [viewmode]="isDynamicGridForm" [rowid]="refKey" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
															 </div>
														</div>
													</ng-container>
													
												</div>
											</div>
											<!-- <div *ngIf="SuccessMasgLoaded" class="d-flex justify-content-center cstmspinner">
												<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
												</div>
											</div> -->
										</div>
									</div>
								</div>
								<div class="modal" id="editModal" tabindex="-1" role="dialog">
									<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
										<div id="edit-content" class="modal-content">
											<!-- <div class="modal-header p-2">
												<h6 class="modal-title">&nbsp;
													
												</h6> 
											
											</div> -->
											<div class="modal-body minhyt40 pt-1 pb-0"  *ngIf="EditLoaded | async">
												<label  class="font9 successedit2 alert alert-success pt-1 pb-1" *ngIf="SuccessMasgLoaded | async">{{SuccessMasg}}</label>
													<label  class="font9 erroredit2 alert alert-danger pt-1 pb-1" *ngIf="ErrorMasgLoaded | async">{{ErrorOccurred}}</label>
												<ul class="nav nav-tabs">
														<li class="active" *ngIf="edit_option == 1 || edit_option == 2"><a data-toggle="tab" class="active" href="javascript:void(0);" (click)="tabChanged($event,formId)">{{formName}}</a>
														</li>													
														<li *ngFor="let lst of childFormList" ><a *ngIf="edit_option == 1 || edit_option == 3"  [class]="isAddMode?'disabled':''" id="tabchildedit{{lst.form_id}}" data-toggle="tab" href="javascript:void(0);" (click)="tabChanged($event,lst.form_id)">{{lst.form_name}}</a>
														</li>													
														<li *ngIf="is_attachment == true" ><a  [class]="isAddMode?'disabled':''" data-toggle="tab" href="javascript:void(0)" (click)="tabChanged($event,formd)">Attachments</a> 
														</li>
														<li class="pull-right clsicn">
															<button type="button" class="close"  aria-label="Close" (click)="closeModel();"> <span aria-hidden="true">&times;</span>
															</button>
														</li>
														
												</ul>
												<div class="tab-content tab-contents pl-0 pr-0  pt-0  ">
													<div id="tabedit{{formId}}" [class]="selectedIndex ==formId?'tab-pane fade in active show':'tab-pane fade'" *ngIf="edit_option == 1 || edit_option == 2">
														<div class="col-sm-12 p-0">
															<hdsbpm-form-repeat [lstsection]="lstConfigJson" *ngIf="isDynamicGridForm != true" [SessionUrlPrams]="sessionUrlPrams"  [tableName]="tableName" [tablePrimaryKey]="tablePrimaryKey" [ViewValueLoaded]="add_edit" [editValueRow]="editValueRow" [isAddMode]="isAddMode" [stageid]="stageid" [processid]="processid"  [connJson]="connJson" [nodeJson]="nodeJson" (processsubmit)="processsubmit($event)" (submit)="submit($event)"></hdsbpm-form-repeat>
															<hdsbpm-formview [section]="formAllField" *ngIf="isDynamicGridForm == true"></hdsbpm-formview>
														</div>
													</div>
													<ng-container *ngIf="isAddMode == false && (tabclieked | async)">	
														<div *ngFor="let lst of childFormList; index as i" id="tabedit{{lst.form_id}}" [class]="selectedIndex ==lst.form_id?'tab-pane fade in active show':'tab-pane fade'" >
															<div class="col-sm-12 p-0" *ngIf="selectedIndex ==lst?.form_id">
																<hdsbpm-grid-form [lstsection]="lst" *ngIf="(edit_option == 1 || edit_option == 3) && isDynamicGridForm != true" [SessionUrlPrams]="sessionUrlPrams"  [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid-form>
																<hdsbpm-grid  [lstsection]="lst" *ngIf="(edit_option == 1 || edit_option == 3) && isDynamicGridForm == true " [SessionUrlPrams]="sessionUrlPrams"  [rowid]="refKey" (submit)="submit($event)"></hdsbpm-grid>
															</div>
														</div>														
														<div   id="tabeditAttachment{{formd}}" [class]="selectedIndex ==formd?'tab-pane fade in active show':'tab-pane fade'">
															<div class="col-sm-12 p-0">
															<app-file-attachment id="edit" [rowid]="refKey" [viewmode]="isDynamicGridForm" [metaData]="metaDataList" [form_id]="formId" [documentTypeList]="attachmentType"></app-file-attachment>
															 </div>
														</div>
													</ng-container>
												</div>
												 <div *ngIf="tabshowloader" class="d-flex justify-content-center cstmspinner">
												<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
												</div>
											</div> 
											</div>
											
										</div>
									</div>
								</div>
								<div class="modal" id="viewModal" tabindex="-1" role="dialog">
									<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
										<div id="viewcontent" class="modal-content" *ngIf="ViewLoaded | async">
											<div class="modal-header p-2">
												<h6 class="modal-title">View</h6>
												<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
												</button>
											</div>
											<div class="modal-body minhyt40 pt-1 popcstm" *ngIf="ViewLoaded | async">
												<hdsbpm-formview [section]="formAllField"></hdsbpm-formview>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="!DataRefreshLoaded" class="d-flex justify-content-center cstmspinner">
								<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
								</div>
							</div>
							<div *ngIf="!DataRefreshLoadedngx" class="d-flex justify-content-center cstmspinner">
								<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span>
								</div>
							</div>
						</div>


						
					</div>
				</div>
			</div>
		</div>
