import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../_services/form.service';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
import { Router } from '@angular/router';
import { ProductService } from '../../_services/product.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { CommonFunctionService } from '../../_services/common-function.service';
import { CustomerLoginSigninService } from '../../_services/customer-login-signin.service';

import { AppSettings } from 'src/app/_services/AppSetting';
declare var $:any
@Component({
  selector: 'app-seller-profile',
  templateUrl: './seller-profile.component.html',
  styleUrls: ['./seller-profile.component.css']
})
export class SellerProfileComponent implements OnInit {
  @ViewChild('inputFile') myInputVariable: ElementRef;
  sellerDetailList: any = [];
  seller_id: any;
  addressProff: any;
  sellerAccountDetails: any;
  sellerBusinessDetails: any;
  sellerName: any;
  businssName: any;
  storeName: any;
  businssType: any;
  prodCatagory: any;
  gstNo: any;
  panNo: any;
  companyStrength: any;
  accountHolderName: any;
  accountType: any;
  ifscCode: any;
  isLoader: boolean = false;
  isListLoader:boolean=false
  accountNo: any;
  bplotNo: any;
  bstreetName: any;
  bpinCode: any;
  bcity: any;
  bstate: any;
  splotNo: any;
  sstreetName: any;
  spinCode: any;
  scity: any;
  sstate: any;
  colDisable: boolean = true;
  identificationProof: any;
  panCard: boolean;
  indianPassport: boolean;
  aadharCard: boolean;
  drivingLicence: boolean;
  voterIDCard: boolean;
  panCard1: boolean;
  indianPassport1: boolean;
  allCategorylist: any;
  aadharCard1: boolean;
  electricityBill: boolean;
  bankStatement: boolean;
  productCatagory: any = [];
  phnNumber: any;
  email: any;
  stateList: any = [];
  cityList: any = [];
  storeStateId: any;
  businessStateId: any;
  originalFileName: any;
  systemFileName: any;
  proffId: any;
  proofType: any;
  proofTypeList: any = [];
  orignalFileName: any;
  uploadedGalaryFiles: any = [];
  file: any;
  formData: FormData;
  idFilePath: any;
  userId:any;
  remarks:any;
  sellermessage: any;
  sellerProof:any;
  previewSrc:any = ''
  sellerAdddressProof:any;
  currentSellerStatus: any = '';
  MassageOutTime = AppSettings.getMassageOutTime;
  fillAddress: any;
  constructor(private formService: FormService,private customerLoginSigninService: CustomerLoginSigninService, private sellerRegistrationService: SellerRegistrationService, private toastrService: ToastrService,private router: Router,
    private productService :ProductService,public commonFunctionService: CommonFunctionService) { }

  ngOnInit(): void {
    this.seller_id = localStorage.getItem("selected_seller_id");
    console.log("this.seller", this.seller_id)
    this.getSellerDetails()
    this.getProductCatagory();
    this.userId = localStorage.getItem("userId");
    this.getUploadDoc();
    this.getStateList();
    this.getCityList();
    // console.log( this.sellerDetailList,' this.selllerdeatls')
    $("#pop").on("click", function() {
      $(this).modal();
   });
  }
  getUploadDoc() {
    this.sellerRegistrationService.getuploadProofs(this.seller_id).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.originalFileName = res[0].orignal_file_name;
        this.systemFileName = res[0].system_file_name;
        this.proffId = res[0].proof_id;
        this.proofType = res[0].proof_type
        let idArr = res.filter(x => x.proof_type == "identificationProof");
        let addArr = res.filter(x => x.proof_type == "addressProof");
        if (idArr.length > 0) {
       
        }
        if (addArr.length > 0) {
          this.addressProff = addArr[0].name_of_proof;
        }
      }
    })
  }
  getStateList() {
    this.sellerRegistrationService.getState().subscribe(res => {
      this.stateList = res;
    })
  }

  getCityList() {
    this.sellerRegistrationService.getCity().subscribe(res => {
      this.cityList = res;
    })
  }
  getBCityAndState() {
    this.customerLoginSigninService.getCityState(this.bpinCode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.bcity = stateCityList.city_name ? stateCityList.city_name : null;
        this.businessStateId = stateCityList.state_name ? stateCityList.state_name : null;
        console.log('222222222222222222', this.bcity)
        console.log('3333333333333333', this.businessStateId)
        console.log(stateCityList);
      } else {
        this.bcity = null;
        this.businessStateId = null;
      }
    })

  }

  getSCityAndState() {
    this.customerLoginSigninService.getCityState(this.spinCode).subscribe(res => {
      let stateCityList = res[0];
      if (stateCityList) {
        this.scity = stateCityList.city_name ? stateCityList.city_name : null;
        this.storeStateId = stateCityList.state_name ? stateCityList.state_name : null;
      } else {
        this.scity = null;
        this.storeStateId = null;
      }
    })

  }
   getSellerDetails() {
    this.isLoader = true;
   
    this.productService.getSellerData(this.seller_id).subscribe(result => {
      
     let res:any = result
      this.currentSellerStatus = res[0].seller_status
      
      if(res.length>0){
        this.isLoader = false;
        
     this.sellerDetailList = res[0].rows;
    
      this.sellerAccountDetails = res[1].rows;
     
      this.sellerBusinessDetails = res[2].rows;
      
      this.sellerAdddressProof = res[3].rows;
    
      this.sellerProof = res[4].rows;
      
   
      this.allCategorylist= res[5].rows;
     
      this.sellerName = this.sellerDetailList[0].seller_name;
      this.businssName = this.sellerDetailList[0].business_name
      this.storeName = this.sellerDetailList[0].store_name
      this.businssType = this.sellerDetailList[0].business_type
      this.prodCatagory = this.sellerDetailList[0].product_category
      this.gstNo = this.sellerDetailList[0].gstin_no
      this.panNo = this.sellerDetailList[0].pan_no
      this.phnNumber = this.sellerDetailList[0].mobile_no
      this.email = this.sellerDetailList[0].email
      this.currentSellerStatus = this.sellerDetailList[0].seller_status
     
      this.companyStrength = this.sellerDetailList[0].company_strength
      this.accountHolderName = this.sellerAccountDetails[0].account_holder_name
      this.accountType = this.sellerAccountDetails[0].account_type
      this.ifscCode = this.sellerAccountDetails[0].ifsc_code
      this.accountNo = this.sellerAccountDetails[0].account_no
      this.bplotNo = this.sellerBusinessDetails[0].business_plot_no_or_block_no
      this.bstreetName = this.sellerBusinessDetails[0].business_street_name
      this.bpinCode = this.sellerBusinessDetails[0].business_pin_code
      this.bcity = this.sellerBusinessDetails[0].business_city
      this.businessStateId = this.sellerBusinessDetails[0].business_state_id
      this.splotNo = this.sellerBusinessDetails[0].store_plot_no_or_block_no
      this.sstreetName = this.sellerBusinessDetails[0].store_street_name
      this.spinCode = this.sellerBusinessDetails[0].store_pin_code
      this.scity = this.sellerBusinessDetails[0].store_city
      this.storeStateId = this.sellerBusinessDetails[0].store_state_id
      this.fillAddress = this.sellerBusinessDetails[0].is_selected
       this.isLoader = false;
     
      }
      
       
    })
    
    
  }
   noWhitespaceValidator(remark) {
    const isWhitespace = (remark || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
getProductCatagory() {
  this.sellerRegistrationService.getCategory().subscribe(res => {
    this.productCatagory = res;
  })

}
  
  approved(){
    // if ( this.remarks == '' ||this.remarks == null ||this.remarks == undefined || (this.remarks || '').trim().length === 0) {
    //   this.toastrService.warning('Remark is required', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false
    // }
  // let space =  this.noWhitespaceValidator(this.remarks)
  // console.log('whiteeeeeeeeeeeee space', space)

    // let status = 181;
    // this.productService.updateSellerStatus(status,this.seller_id,this.remarks,this.userId).subscribe(res => {
     
    //   this.toastrService.success("Approved Successfully", '', { timeOut: 3000, positionClass: "toast-top-center" });
    // this.reset();  
    // })
     
Swal.fire({
  title: `Do you want to approve ${this.sellerName}?`,
  showDenyButton: true,
  // showCancelButton: true,
  confirmButtonText: 'Yes',
  denyButtonText: `No`,
}).then((result) => {
  if (result.isConfirmed) {
      let status = 181;
    this.productService.updateSellerStatus(status,this.seller_id,this.remarks,this.userId).subscribe(res => {
    Swal.fire('Approved!', '', 'success');
         this.reset();  
    })
  } else if (result.isDenied) {
    this.remarks = ''
  }
})
  }
  reset1(){
    this.sellermessage = ''

  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }

  sellerMessage(){
    if ( this.sellermessage == '' ||this.sellermessage == null ||this.sellermessage == undefined || (this.sellermessage || '').trim().length === 0) {
      this.toastrService.warning('Message is required', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false
    }
       
Swal.fire({
  title: ` Do you want to send message to ${this.sellerName}?`,
  showDenyButton: true,
  // showCancelButton: true,
  confirmButtonText: 'Yes',
  denyButtonText: `No`,
}).then((result) => {
  if (result.isConfirmed) {
     this.productService.updateSellerMessage(this.seller_id,this.sellermessage, this.userId).subscribe(res => {
      //console.log("resssss", res)
    Swal.fire('Send Message!', '', 'success');
    $('#message2seller').modal("hide");
        
    })
  } else if (result.isDenied) {
    this.sellermessage = ''
  }
})
  }
reset(){
  this.remarks ='';
  this.router.navigateByUrl('/seller-list' + '?' + localStorage.getItem('tempToken'));

}
  reject(){
  //   let status= 182;
    if ( this.remarks == '' ||this.remarks == null ||this.remarks == undefined || (this.remarks || '').trim().length === 0) {
      this.toastrService.warning('Remark is required', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
  //  this.productService.updateSellerStatus(status,this.seller_id,this.remarks,this.userId).subscribe(res => {
  //     this.toastrService.success("Rejected Successfully", '', { timeOut: 3000, positionClass: "toast-top-center" });
  //     this.reset();  
  //   })
  Swal.fire({
    title: `Do you want to reject ${this.sellerName}?`,
    showDenyButton: true,
    // showCancelButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result) => {
    if (result.isConfirmed) {
        let status = 182;
      this.productService.updateSellerStatus(status,this.seller_id,this.remarks,this.userId).subscribe(res => {
      Swal.fire('Rejected!', '', 'success');
        this.reset();  
      })
    } else if (result.isDenied) {
      this.remarks = ''
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })
  }
  
  edit() {
    this.colDisable = false;
  }
  editClose() {
    this.colDisable = true;
  }
  updateDetail() {
    if (this.businssName == null || this.businssName == undefined || this.businssName == '') {
      this.toastrService.warning("Please Enter Business Name !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.storeName == null || this.storeName == undefined || this.storeName == '') {
      this.toastrService.warning("Please Enter Store Name !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.businssType == null || this.businssType == undefined || this.businssType == '') {
      this.toastrService.warning("Please Select Business Type !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.prodCatagory == null || this.prodCatagory == undefined || this.prodCatagory == '') {
      this.toastrService.warning("Please Select Product Category !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.gstNo == null || this.gstNo == undefined || this.gstNo == '') {
      this.toastrService.warning("Please Enter GSTIN NO !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.gstNo !== undefined && this.gstNo !== null && this.gstNo !== " ") {
      var pattrn = new RegExp('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9]{1}[zZ][a-zA-Z0-9]{1}$')
      if (this.gstNo.substring(0).length > 0) {
        var newpancard = this.gstNo.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid GST No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          //this.isLoading = false;
          return false;

        }
      }

    }
    if (this.panNo == null || this.panNo == undefined || this.panNo == '') {
      this.toastrService.warning("Please Enter PAN No !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.panNo !== undefined && this.panNo !== null && this.panNo !== " ") {
      let pattrn = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
      if (this.panNo.substring(0).length > 0) {
        var newpancard = this.panNo.substring(0).toString();
        if (pattrn.test(newpancard) == false) {
          this.toastrService.warning("Please enter valid PAN Card No.", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
          return false;

        }
      }
    }
    if (this.companyStrength == null || this.companyStrength == undefined || this.companyStrength == '') {
      this.toastrService.warning("Please Select Company Strength !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.companyStrength == 0 ) {
      this.toastrService.warning('Please Enter Valid Company Strength', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.sellerRegistrationService.updateSellerDetails(this.seller_id, this.businssName, this.storeName, this.businssType, this.prodCatagory, this.gstNo, this.panNo, this.companyStrength).subscribe(res => {
      this.toastrService.success("Updated Successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      this.getSellerDetails()
      this.colDisable = true;
    })

  }
  RepeatAdress() {
    if (this.fillAddress == true) {
      this.splotNo = this.bplotNo;
      this.sstreetName = this.bstreetName;
      this.spinCode = this.bpinCode;
      this.scity = this.bcity;
      this.storeStateId = this.businessStateId;

    } else {
      this.splotNo = null;
      this.sstreetName = null;
      this.spinCode = null;
      this.scity = null;
      this.storeStateId = null;
    }
  }
  updateAddressDetail() {
    if (this.bplotNo == null || this.bplotNo == undefined || this.bplotNo == '') {
      this.toastrService.warning("Please Enter Business Plot No/ Block No!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.bplotNo.length < 3 || this.bplotNo.length > 100){
      this.toastrService.warning("Business Plot no. should be in-between 3 digits to 100 digits!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.bstreetName == null || this.bstreetName == undefined || this.bstreetName == '') {
      this.toastrService.warning("Please Enter Business Street Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.bstreetName.length < 3 || this.bstreetName.length > 100){
      this.toastrService.warning("Business Street name  should be in-between 3 characters to 100 characters!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.bpinCode == null || this.bpinCode == undefined || this.bpinCode == '') {
      this.toastrService.warning("Please Enter Business PIN Code!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.bcity == null || this.bcity == undefined || this.bcity == '') {
      this.toastrService.warning("Please Select Business City!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.businessStateId == null || this.businessStateId == undefined || this.businessStateId == '') {
      this.toastrService.warning("Please Select Business State!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }

    if (this.splotNo == null || this.splotNo == undefined || this.splotNo == '') {
      this.toastrService.warning("Please Enter Store Plot No/ Block No!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.splotNo.length < 3 || this.splotNo.length > 100){
      this.toastrService.warning("Store Plot no. should be in-between 3 digits to 100 digits", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }

    if (this.sstreetName == null || this.sstreetName == undefined || this.sstreetName == '') {
      this.toastrService.warning("Please Enter Store Street Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if(this.sstreetName.length < 3 || this.sstreetName.length > 100){
      this.toastrService.warning("Store Street name should be in-between 3 characters to 100 Character!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }

    if (this.spinCode == null || this.spinCode == undefined || this.spinCode == '') {
      this.toastrService.warning("Please Enter Store Pin Code!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.scity == null || this.scity == undefined || this.scity == '') {
      this.toastrService.warning("Please Select Store City!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.storeStateId == null || this.storeStateId == undefined || this.storeStateId == '') {
      this.toastrService.warning("Please Select Store State!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.sellerRegistrationService.updateSellerAddressDetail(this.seller_id, this.bplotNo, this.bstreetName, this.bpinCode,
      this.bcity, this.businessStateId, this.splotNo, this.sstreetName, this.spinCode, this.scity, this.storeStateId, this.fillAddress).subscribe(res => {
        this.toastrService.success("Updated Successfully", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        this.getSellerDetails()
        this.colDisable = true;
      })
  }
  back(){
    this.router.navigateByUrl('/seller-list?');
  }
  zoomin: boolean = false;
  scale = 100;
  top = 0;
  left = 0;
  enlargeImg(d) {
   d = d.toString()
    this.zoomin = !this.zoomin;
    // Set image size to 1.5 times original
    let img = document.getElementById(d);
    let zoom = this.zoomin
   
    if (this.zoomin == true) {
      img.style.transform = "scale(4)";
      // Animation effect
      img.style.transition = "transform 0.90s ease";
      img.setAttribute("title", "click to zoom out");
    } else {
      img!.style.transform = "scale(1)";
      // Animation effect
      img!.style.transition = "transform 0.90s ease";
    }
    window.addEventListener('click', function (event:any) {
      if (event.target.id != d && zoom == true) {
          img!.style.transform = "scale(1)";
          // Animation effect
          img!.style.transition = "transform 0.90s ease";
        return  true
      } else {
          // console.log('clicked outside');
      }
      
  });

  }

// Get arbitrary element with id "my-element"
viewPdf(source){
  var link = document.createElement('a');
link.href = 'https://placeimg.com/1000/1000/arch';
link.download = 'Download.jpg';
document.body.appendChild(link);
link.click();
document.body.removeChild(link);
//   console.log('sourceeeeeeeeeeee', source)
//   // const fileName = source.split('/').pop();
// var el = document.createElement("a");
// el.setAttribute("href", source);
// el.setAttribute("download", source);
// document.body.appendChild(el);
//  el.click();
// el.remove();
}

openDoc(proof) {
  let pdfUrl = `${proof.document_path}/${proof.system_file_name}`
  window.open(AppSettings.getImagepath + pdfUrl + '#page=' + 1, '_blank', '',);
  // console.log('check image path', {getimage: AppSettings.getImagepath, getpdf:AppSettings.getPdfImagepath})
}

previewImages(proof){
  this.previewSrc = `${proof.document_path}/${proof.system_file_name}`
}

downloadImages(obj) {
// console.log('check pathh', obj.document_path)
  //../../../assets/banner/bannerImage/2022/5/2/image_2022_04_07T13_43_10_661Z.png.png'// '
  let path = AppSettings.getImagepath
    let filePath = path + obj.document_path + '/' + obj.system_file_name;
    // let path = '../../../assets/banner/bannerImage/2022/5/2/image_2022_04_07T13_43_10_661Z.png.png'
      // let path = 'http://nocpl.web.humanitics.com/hdsbpm/soa/2022/3/10/1646898070941.jpg';
    var FileSaver = require('file-saver');
    FileSaver.saveAs(filePath);
 
}

ngAfterViewChecked() {
  $(".toast-close-button").click(function () {
    $(".overlay-container").removeClass("addcontainerdisable");

  })
  $(".toast-container").click(function () {
    $(".overlay-container").removeClass("addcontainerdisable");
  })

}

}
