import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/_services/product.service';
import { Page } from '../../_models/page';
import { ConfigPrams } from "../../field.interface";
import Swal from 'sweetalert2';
declare var $:any 
@Component({
  selector: 'app-buyer-orderlist',
  templateUrl: './buyer-orderlist.component.html',
  styleUrls: ['./buyer-orderlist.component.css']
})
export class BuyerOrderlistComponent implements OnInit {
  orderList: any = '';
  customerId: any;
  isLoading : boolean = true
  buyerReview:any = ''
  previewSrc:any = ''
  configParms: ConfigPrams;
  page = new Page()
  LoadPagination: boolean = true
  public totalCount = 0;
  TemporderList: any;
  userId: string;
  currentReviewId: any;
  buyerRating: any = '';
  is_disabled: boolean;
  buyerName: any;
  currentOrderId: any = '';
  allProducts: any[] = [];
  buyerRatingAndReviews: any;
  searchTerm: any;
  isloadgridTrue: any = false;
  constructor(private productService: ProductService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    let data = localStorage.getItem('buyer')
    data = JSON.parse(data) 
    this.customerId = data['buyerid']
    console.log("buerrer", this.customerId)
    this.buyerName = data['buyername']
    this.bindNgxDataGrid(this.page, 1)
  }



  LoadGrid(event) {
    this.isloadgridTrue = true
    this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1)
  }
  bindNgxDataGrid(pageInfo, status) {
    this.isLoading = true
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
  } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
               
if(!this.isloadgridTrue)
offset = this.searchTerm ? 0 : offset
    this.productService.individualBuyerOrdersCount(this.customerId, this.searchTerm).subscribe((resp:any) => {
      this.page.totalElements = resp.rows.length;
      // console.log('check length', this.page.totalElements)
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      let pageCount = pageInfo.pageNumber
      this.productService.getAllCustomerOrders(this.customerId, pagesize, offset, this.searchTerm).subscribe((res:any) => {
        if(res.rows.length > 0){
          this.orderList = res.rows;
          // console.log('check response for ', this.orderList)
          this.TemporderList = this.orderList;
           this.isLoading = false;
        }else{
          setTimeout(() => {
            this.orderList = []
          this.isLoading = false;
          }, 100);
        }
        this.totalCount = resp.rows.length;
        let currPage = pageInfo.pageNumber;
        if (currPage == 0) { currPage = 1; }
        this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
        this.LoadPagination = true;

      })

      

    })

  }

  // getAllCustomerOrders() {
  //   this.productService.getAllCustomerOrders(this.customerId).subscribe(res => {
  //     console.log('check response', res.rows)
  //     this.orderList = res.rows ? res.rows : [];
  //   })
  // }

  cancelOrderByUser(obj){
    this.productService.cancelOrderByUser(obj.order_detail_id).subscribe((res:any) => {
     if(res.status==200){
      this.toastrService.success(res.message, '', { timeOut: 2000, positionClass: "toast-top-center" });
      // this.getAllCustomerOrders();
     }else{
      this.toastrService.error("Something went wrong please try after some time.", '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
      $(".overlay-container").addClass("addcontainerdisable");
    }
    })
  }
  CommonGridSearchMaster(ev) {
    this.searchTerm = ev;
    this.bindNgxDataGrid(this.page, 1)
    // this.orderList = this.TemporderList.filter(item => ((item.product_name ? (item.product_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.total_amount ? (item.total_amount.toLowerCase().indexOf(ev) !== -1) : false) || (item.order_status ? (item.order_status.toLowerCase().indexOf(ev) !== -1) : false) ))
  }

  viewReview(productId){
    this.buyerRating = ''
    // $("#myModal").modal('show');
    this.productService.viewBuyerReview(productId, this.customerId).subscribe((res:any) =>{
      this.buyerReview = res.rows[0]?.customer_review;
      this.buyerRating = res.rows[0]?.customer_ratings
      this.buyerRatingAndReviews = res.rows
      //loop for on array in html with image price and name
      this.currentReviewId = res.rows[0]?.id
      this.is_disabled = res.rows[0]?.is_disabled
      // console.log('respose for review table', res)
    })
  }
  deletebuyerReview(){
    // $("#myModal").modal('hide');
if(this.is_disabled==true){
  // $("#myModal").modal('show');
  return this.toastrService.warning('Already Deleted','',{ timeOut: 2000, positionClass: "toast-top-center" })
}else{
    Swal.fire({
      title: `Do you want to Disable this Review ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.deleteReview(this.currentReviewId).subscribe(res => {
                Swal.fire('Disabled Successfully!', '', 'success');
        })
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }
  }

  previewImages(img){
    this.previewSrc = img.rowData.product_image_path
  }

  ViewOrderDetail(row){
    this.currentOrderId = row.rowData.order_id
    let productId = row.rowData.product_id;
    let productIdArray = []
    this.productService.getOrderDetails(this.currentOrderId).subscribe((res:any) =>{
      // console.log('to check for veiw order', res.rows)
      this.allProducts = res.rows
      for (let data in res.rows) {
        productIdArray[data] = res.rows[data].product_id
      }
      // console.log('check for in ', productIdArray)

      $('#exampleModal').modal('show')
      this.viewReview(productIdArray)
    })
  }
 
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}

