import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CommonFunctionService } from '../../_services/common-function.service';
import { FormService } from '../../_services/form.service';
import { ProductService } from '../../_services/product.service';
import { Router } from '@angular/router';
import { SellerRegistrationService } from '../../_services/seller-registration.service';
import { read } from 'fs';
import Swal from 'sweetalert2';
import { AppSettings } from '../../_services/AppSetting';
//import { Editor } from 'ngx-editor';
declare var $: any;
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {
  @ViewChild('inputFile') myInputVariable: ElementRef;
  showqtyPrice: any;
  selectedCorpid: any;
  tempcoprlist: any[] = [];
  removeClientId: any;
  bundleAtribute:any[] = []
  uploadedProductsList: any = [];
  columns: any[]
  rows: any = [];
  totalCount: Number = 0;
  closeResult: string;
  //editor: Editor;
  corp_id: any;
  bundlindex:number;
  MassageOutTime = AppSettings.getMassageOutTime;
  html: '';
  dataParams: any = {
    page_num: '',
    page_size: ''
  };
  assign_corp: any;
  corpList: any;
  assign_marketplace: any
  productSpecifications: any = [];
  lable: any;
  value: any;
  keyFeatures: any = [];
  key: any;
  file: any;
  uploadedGalaryFiles: any = [];
  brand_name: any;
  product_name: any;
  suk: any;
  price: any;
  mrp: any;
  quantity: any;
  stock_status: any;
  this_item_has_weight: any = 2;
  weigth: any;
  category: any;
  sub_category: any;
  manufacture_year: any;
  product_tags: any;
  gstRate: any;
  minOrderQuantity: any = 1;
  puerchasePrice: any;
  hsn: any;
  product_discription: any;
  formData: FormData;
  categoryList: any;
  seller_id: any;
  countryList: any;
  country_id: any;
  draftCount: any;
  submitQcCount: any;
  liveCount: any;
  rejectedCount: any;
  allProductList: any = [];
  isView: boolean = false;
  isEdit: boolean = false;
  sellerListEdit:string = 'false';
  qcStatus: any;
  productId: any;
  private toggle: boolean = false;
  showForm: boolean = false;
  productID: any;
  subCategoryList: any = [];
  brandNameList: any;
  brandName: any;
  brandCode: any;
  lableValueName: any;
  lableList: any = [];
  breadcrumb: string;
  file_preview: any
  brand_id: any;
  gstList: any;
  localurl: any;
  tempFilePath: string | ArrayBuffer;
  hasWeight: boolean = false;
  productImagesList: any[] = [];
  CorpWisepeiseList: any[] = [];
  MarketWisepeiseList: any[] =[];
  oldSku: any;
  txtHeight: any;
  txtWidth: any;
  msg: string;
  yearList: any;
  minQty: any;
  maxQty: any;
  MaxQty:any;
  MinQty: any;
  Qtyprice: any;
  qtyprice: any;
  qtywiseprice: any;
  Corporate_price: any;
  editLabel: boolean;
  editIndex: any;
  editFeaturesIndex: any;
  editFeatures: boolean;
  editCorpBundle: boolean;
  editBundle: Boolean;
  editCorpBundleIndex: any;
  editBundleIndex: any;
  shown : boolean = false;
  checked: boolean ;
  marketlist:any[] = [];
  minQuantity: any;
  maxQuantity: any;
  bundlePrice: any;
  noLimit: any;
  asPerPrice: any;
  pricingId: any;
  bundlePricelist: any[] = [];
  constructor(private productService: ProductService,
    private formService: FormService, private sellerService: SellerRegistrationService,
    private toastrService: ToastrService, public commonFunctionService: CommonFunctionService,
    private router: Router) { }
  ngOnInit(): void {
    //this.editor = new Editor();
    //
    this.seller_id = localStorage.getItem('selected_seller_id');
    this.breadcrumb = localStorage.getItem('breadcrumb');
   
    this.getAllCat();
    this.getAllCountries();
    this.getBrandName();
    this.getGstList();
    this.getYearData();
    this.getcorp();
    if (localStorage.getItem("editable-item") != null && localStorage.getItem("editable-item") != undefined) {
      //
      this.sellerListEdit = localStorage.getItem('editinsellerlist');
      this.onProductEdit(localStorage.getItem("editable-item"));    
     
    //  this.getcorp();
    }
  }

  addNew() {
    this.showForm = true;
    this.reset();

  }

  // getCorpId(event){
  //  console.log("event srikant===================",event);

  // }
  getGstList() {
    this.productService.getGSTData().subscribe(res => {
      this.gstList = res;
      //console.log("gstdatata", this.gstList)
    })
  }
  getYearData() {
    this.productService.getYearData().subscribe(res => {
      this.yearList = res;
    })
  }
  back() {
    //this.reset();
    this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }

      
  addSpecifications() {
    this.lable = this.lable?.trim();
    this.value = this.value?.trim();
    if (this.editLabel) {
  
      if (this.lable == null || this.lable == undefined || this.lable == '') {
        this.toastrService.warning("Please Enter Label!!!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.value == null || this.value == undefined || this.value == '') {
        this.toastrService.warning("Please Enter Value For Label " + this.lable + '!!!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      let existLabel = this.lableList.filter((x, index) => x.label_name == this.lable )
      if (existLabel.length > 0) {
        this.toastrService.warning("This Label Already exist", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false
      }
      if (this.lableList.length != 0) {
        this.lableList[this.editIndex] = { "label_name": this.lable, "label_valuelist": this.value, 'is_custom': true };
        
        this.toastrService.success("Label updated successfully !", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.lable = this.value = '';
        this.editLabel = false;
        this.editIndex = ''
        return false;
       
      }
    } else {

      if (this.lable == null || this.lable == undefined || this.lable == '') {
        this.toastrService.warning("Please enter label!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.value == null || this.value == undefined || this.value == '') {
        this.toastrService.warning("Please enter value for label " + this.lable + '!', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.lableList.length != 0) {
        let existLabel = this.lableList.filter(x => x.label_name == this.lable)
        if (existLabel.length > 0) {
          this.toastrService.warning("This label already exists", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
        } else {
          this.lableList.push({ "label_name": this.lable, "label_valuelist": this.value, 'is_custom': true });
          this.toastrService.success("Label added successfully !", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          this.lable = null;
          this.value = null;
        }
      } else {
        this.lableList.push({ "label_name": this.lable, "label_valuelist": this.value, 'is_custom': true });
        this.toastrService.success("Label added successfully !", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.lable = null;
        this.value = null;
      }
    }
  }
  removeSpecifications(i) {
    // this.lableList.splice(i, 1);
    // this.lable = this.value = '';
    Swal.fire({
      title: `Do you want to delete label ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.lableList.splice(i, 1);
     this.toastrService.success("label deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    this.lable = this.value = '';
    this.editLabel = false;
    this.editIndex = ''
      } else if (result.isDenied) {
        this.lable = this.value = '';
      }
    })
  }
  editSpecifications(index) {
    this.editLabel = true;
    this.editIndex = index;
    this.lable = this.lableList[index].label_name;
    this.value = this.lableList[index].label_valuelist;
    // this.lableList.splice(index, 1);
  }
  addKeyFeatures() {
    this.key = this.key?.trim();
    if(this.editFeatures){
      if(this.key == null || this.key == undefined || this.key == ''){
        this.toastrService.warning("Please enter key features", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      let existFeatures = this.keyFeatures.filter((x, index) => x.key ==this.key );
      if(existFeatures.length > 0){
       this.toastrService.warning("this key feature Already Exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
       $(".overlay-container").addClass("addcontainerdisable");
       this.removeClass()
      return false;
      }
      if(this.keyFeatures.length != 0){
        this.keyFeatures[this.editFeaturesIndex]= {"key": this.key};
        this.toastrService.success("Key Feature updated successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        // console.log("this.editFeaturesIndex==>>>", this.editFeaturesIndex)
        this.key  = '';
        this.editFeatures = false;
        this.editFeaturesIndex = '';
        return false;
      }

    }else{   
    if (this.key == null || this.key == undefined || this.key == '') {
      this.toastrService.warning("Please enter key features", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // console.log("this.key",this.key);
    let aa = this.keyFeatures.filter(x => x.key ==this.key);
    if(aa.length > 0){
      this.toastrService.warning("this key feature Already Exist", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      this.key = '';
      return false;
    }
    this.keyFeatures.push({ "key": this.key });
    this.toastrService.success("Key Feature added successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    this.key = '';
    return false;
  }
}
  removeKeyFeatures(i) {
    // this.keyFeatures.splice(i, 1);
    // this.key  = '';
    Swal.fire({
      title: `Do you want to delete key feature ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
       this.keyFeatures.splice(i, 1);
     this.toastrService.success("key feature deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();
    this.key  = '';
    this.editFeatures = false;
    this.editFeaturesIndex = '';
      } else if (result.isDenied) {
        this.key  = '';
      }
    })
  }
  editKeyFeatures(index) {
    this.editFeatures=true;
    this.editFeaturesIndex=index;
    this.key = this.keyFeatures[index].key;
    // this.keyFeatures.splice(index, 1);
  }

  onFileChnage(event) {
   
    const { files, validity } = event.target;
    if (validity.valid) {
      if (files.length > 0) {
        for (let lst of files) {
          let size = Math.round(Number(files[0].size) / 1024)
          var fname = lst.name;
          var re = /(\.jpg|\.jpeg|\.png)$/i;
          if (!re.exec(fname)) {
            this.toastrService.warning("File type not supported!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          var extarr = lst.name.split(".");
          if (extarr.length > 2) {
            this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          if (size > 5120) {
            this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
          if (this.uploadedGalaryFiles.length == 10) {
            this.toastrService.warning("You can upload maximum 10 files", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.myInputVariable.nativeElement.value = '';
            return false;
          }
         // this.file = files;
         let fr = new FileReader();
         fr.readAsDataURL(lst);
         fr.onload = (e) => {
          var img: any = new Image();
          img.src = e.target.result;
          img.onload = () => {
            this.txtHeight = img.height;
            this.txtWidth = img.width;
            if (this.txtWidth == 1500 && this.txtHeight == 1500) {                   
                    if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == lst.name)).length > 0) {
                      this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
                      $(".overlay-container").addClass("addcontainerdisable");
                      this.removeClass()
                      this.myInputVariable.nativeElement.value = '';
                      return false;
                    }
                    this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': img.src, "file": lst, "file_name": lst.name, "imgUrl": this.localurl, "file_size": Math.round(Number(lst.size) / 1024) });
                    img.src = ''
                    img.src = ''
                    this.toastrService.success('Uploaded image successfully.', '', {
                      timeOut: this.MassageOutTime, positionClass: 'toast-top-center'
                    });
                    $(".overlay-container").addClass("addcontainerdisable");
                    this.removeClass()
                  } else {
                    this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
                    this.myInputVariable.nativeElement.value = '';
                    $(".overlay-container").addClass("addcontainerdisable");
                    this.removeClass()
                    return false
                  }
                  
                }
                
                
          }
        //  let reader = new FileReader();
        //  reader.readAsDataURL(lst);
        //   reader.onload = (e) => {
        //     
        //    this.tempFilePath = e.target.result;
        //    var img: any = new Image();
        //     img.src = e.target.result;
        //    console.log("img.height",img.src)
            // img.onload = () => {
            //   
            // this.txtHeight = img.height;
            //   this.txtWidth = img.width;
            //   console.log("txtHight",this.txtHeight,this.txtWidth)
            //   if (this.txtWidth == 1500 && this.txtHeight == 1500) {
            //     this.toastrService.success('Uploaded image successfully.', '', {
            //       timeOut: this.MassageOutTime,
            //       positionClass: 'toast-top-center',
            //     });
            //     $(".overlay-container").addClass("addcontainerdisable");
            //     this.removeClass()
            //   } else {
            //     this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            //     $(".overlay-container").addClass("addcontainerdisable");
            //     this.removeClass()
            //     return false
            //   }
            // }
            // if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == lst.name)).length > 0) {
            //   console.log(this.uploadedGalaryFiles)
            //   this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
            //   $(".overlay-container").addClass("addcontainerdisable");
            //   this.removeClass()
            //   this.myInputVariable.nativeElement.value = '';
            //   return false;
            // }
            // this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': this.tempFilePath, "file": lst, "file_name": lst.name, "imgUrl": this.localurl, "file_size": Math.round(Number(lst.size) / 1024) });
            // this.tempFilePath = ''
            // //img.src = ''
         // };
        }
        //var fname = files[0].name;
        //var re = /(\.jpg|\.jpeg|\.png)$/i;
        // if (!re.exec(fname)) {
        //   this.toastrService.warning("File type not supported!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //   $(".overlay-container").addClass("addcontainerdisable");
        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // var extarr = files[0].name.split(".");
        // if (extarr.length > 2) {
        //   this.toastrService.warning("File name can not have more than one extension", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //   $(".overlay-container").addClass("addcontainerdisable");
        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // if (size > 5120) {
        //   this.toastrService.warning("File size can not greater than 5MB", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //   $(".overlay-container").addClass("addcontainerdisable");
        //   this.removeClass()
        //   this.myInputVariable.nativeElement.value = '';
        //   return false;
        // }
        // this.file = files;
        // var reader = new FileReader();
        // reader.readAsDataURL(this.file[0]);
        // reader.onload = (e) => {
        //   this.tempFilePath = e.target.result;
        //   var img: any = new Image();
        //   img.src = e.target.result;
        //   img.onload = () => {
        //   this.txtHeight = img.height;
        //   this.txtWidth = img.width;         
        //     if (this.txtWidth == 1500 && this.txtHeight == 1500) {
        //       this.toastrService.success('Uploaded image successfully.', '', {
        //         timeOut: this.MassageOutTime,
        //         positionClass: 'toast-top-center',
        //       });
        //       $(".overlay-container").addClass("addcontainerdisable");
        //       this.removeClass()
        //     } else {
        //       this.toastrService.warning('Image should be (1500 * 1500) px', '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        //       $(".overlay-container").addClass("addcontainerdisable");
        //       this.removeClass()                         
        //       return false
        //     }
        //   }
        // };
      }
    }
  }

  // onUpload() {
  //   console.log("this.uploadedGalaryFiles-->",this.uploadedGalaryFiles)
  //   if (this.file.length == 0) {
  //     this.toastrService.warning("Please select an image!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
  //     $(".overlay-container").addClass("addcontainerdisable");
  //     this.removeClass()
  //     return false;
  //   }
  //   if(this.txtHeight != 1500 || this.txtWidth != 1500){
  //     this.toastrService.warning("Please select a valid size image!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
  //     $(".overlay-container").addClass("addcontainerdisable");
  //     this.removeClass()
  //     return false;
  //   }
  //   if ((this.uploadedGalaryFiles.filter(x => x["file_name"] == this.file[0].name)).length > 0) {
  //     this.toastrService.warning("File already added!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
  //     $(".overlay-container").addClass("addcontainerdisable");
  //     this.removeClass()
  //     this.myInputVariable.nativeElement.value = '';
  //     return false;
  //   } 
  //   else {
  //     // this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, 'isSavedInDB': false, 'file_path': this.tempFilePath, "file": this.file[0], "file_name": this.file[0].name, "imgUrl": this.localurl, "file_size": Math.round(Number(this.file[0].size) / 1024) });
  //     // this.file = null;
  //     // this.myInputVariable.nativeElement.value = '';
  //   }
  // }

  deleteFile(id) {

    this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
    this.myInputVariable.nativeElement.value = '';
  }
  deleteFromDb(id, imgid) {
    Swal.fire({
      title: `Are you sure you want to delete?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
    this.uploadedGalaryFiles = this.uploadedGalaryFiles.filter(x => x.id != id);
    let sqlQry = `delete from z_entitydtl_product_images_001 where product_id = ${localStorage.getItem("editable-item")} and image_id = ${imgid}`
    this.formService.getFormData(sqlQry).subscribe(res => {
      Swal.fire('Image deleted successfully!', '', 'success');
      //console.log('yes successfully delete this')
    })
      }else if (result.isDenied) {
        
      }
    });  
    
  }
  reset() {
    this.productSpecifications = [];
    this.lable = null;
    this.value = null;
    this.keyFeatures = [];
    this.lableList = []
    this.key = null;
    this.file = null;
    this.uploadedGalaryFiles = [];
    this.product_name = null;
    this.suk = null;
    this.price = null;
    this.quantity = null;
    this.stock_status = null;
    this.this_item_has_weight = null;
    this.weigth = null;
    this.category = null;
    this.sub_category = null;
    this.product_discription = null;
    this.myInputVariable.nativeElement.value = '';
    this.country_id = null;
    this.mrp = null;
    this.hsn = '';
    this.puerchasePrice = null;
    this.gstRate = null;
    this.brand_name = null;
    this.manufacture_year = null;
    this.product_tags = null;
    this.brandNameList = []
    this.showqtyPrice = null;
    this.assign_corp = null;
    this.assign_marketplace = null;
    this.tempcoprlist = null;
  }
  getAllCat() {
    this.sellerService.getCategory().subscribe(res => {
      this.categoryList = res;
    })
  }

  getSubCategory() {
    this.sellerService.getSubCategory(this.category).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.subCategoryList = res;
      } else {
        this.subCategoryList = [];
      }      
    })
  }
 
  getAllCountries() {
    this.productService.getCounty().subscribe((res: any) => {
      this.countryList = res.rows;
    })
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '7rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',

    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'], ['insertImage'],
      ['insertVideo']
    ]
  };

  getList(val, id) {
    let draft = document.getElementById('draft').classList;
    let qc = document.getElementById('qc').classList;
    let lp = document.getElementById('lp').classList;
    let qcr = document.getElementById('qcr').classList;
    let addnew = document.getElementById('addnew').classList;
    if (id == 'draft') {
      draft.add('btn-active');
      qc.remove('btn-active')
      lp.remove('btn-active')
      qcr.remove('btn-active')
    } else if (id == 'qc') {
      draft.remove('btn-active');
      qc.add('btn-active')
      lp.remove('btn-active')
      qcr.remove('btn-active')
    } else if (id == 'lp') {
      draft.remove('btn-active');
      qc.remove('btn-active')
      lp.add('btn-active')
      qcr.remove('btn-active')
    } else if (id == 'qcr') {
      draft.remove('btn-active');
      qc.remove('btn-active')
      lp.remove('btn-active')
      qcr.add('btn-active')
    }
    if (val == 0) {
      this.uploadedProductsList = this.allProductList
    } else {
      this.qcStatus = val;
      this.uploadedProductsList = this.allProductList.filter(x => x.qcstatus == val);
    }
    this.showForm = false;
  }

  getcorp() {
    this.sellerService.getCorpfromcust().subscribe(res => {
      this.corpList = res;
    })
  }
  getcorporate(temCorpWisepeiseList) {
    if (temCorpWisepeiseList != '0') {
      for (const key of this.tempcoprlist) {
        key.CorpWisepeiseList  = this.bundlePricelist.filter(x => x.corpid == key.add_corporate_id) || []
        if(key.CorpWisepeiseList.length>0)
        key.showqtyPrice = true
      }
    } else {
      if(this.tempcoprlist.length<this.corp_id.length){
        for (const it of this.corp_id) {
          let arr = this.tempcoprlist.filter(x => x.add_corporate_id == it) || [];
          if (!arr.length) {
            // arr[0].CorpWisepeiseList = [];
            this.tempcoprlist.push(this.corpList.filter(x => x.add_corporate_id == it)[0]);
           let index = this.tempcoprlist.findIndex(x => x.add_corporate_id == it);
           this.tempcoprlist[index].CorpWisepeiseList = []
          }
        }
      }else{
        for (const it of this.tempcoprlist) {
          let arr = this.corp_id.filter(x => x == it.add_corporate_id);
          if (arr.length==0) {
            this.tempcoprlist = this.tempcoprlist.filter(x => x.add_corporate_id != it.add_corporate_id);
          }
        }
      }
      

      // let finalData = this.tempcoprlist;

      // for (let r = 0; r < finalData.length; r++) {
      //   let arr = this.corp_id.filter(d => d == finalData[r]["add_corporate_id"])
      //   if (arr.length == 0) {
      //     this.tempcoprlist = this.tempcoprlist.filter(d => d.add_corporate_id != finalData[r]["add_corporate_id"])
      //   }
      // }

      // for (const key of this.tempcoprlist) {
      //   key.CorpWisepeiseList  = []
        
      // }

    }

 


  }
  CorporateChange() {
    //console.log('this.assign_corp',this.assign_corp)
    if (this.assign_corp == false) {
      this.tempcoprlist = [];
      this.corp_id = null;
      this.CorpWisepeiseList = [];
      this.showqtyPrice = false;
    }
    //else{

    // if(this.corp_id != null && this.corp_id != undefined && this.corp_id != 'undefined')
    //  this.getcorporate('0');
    // }

  }
  checkedbox(value){
  //   if(document.getElementById('abc').this.checked==true){
  //     this.shown= true
  //   }
  //   else if(document.getElementById('abc').checked==false)
  //     this.shown= false;
  // }
  }
  
  addCorpWisepeise(corpObj) {
    //
    let selectedCorpName: any = '';
  
      if (corpObj.minQty == null || corpObj.minQty == undefined || corpObj.minQty == '') {
        this.toastrService.warning("Please enter Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corpObj.minQty) <= Number(this.minOrderQuantity)){
        this.toastrService.warning("Minimum quantity must be greater than minimum order quantity", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corpObj.minQty !== undefined && corpObj.minQty !== null && corpObj.minQty !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corpObj.minQty) == false) {
            this.toastrService.warning("Minimum quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if (corpObj.maxQty == null || corpObj.maxQty == undefined || corpObj.maxQty == '') {
        this.toastrService.warning("Please enter Max quantity ", '', {timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corpObj.maxQty) <= 0 ){
        this.toastrService.warning("Maximum quantity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corpObj.maxQty !== undefined && corpObj.maxQty !== null && corpObj.maxQty !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corpObj.maxQty) == false) {
            this.toastrService.warning("Maximum quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ((Number(corpObj.maxQty) < Number(corpObj.minQty)) || (Number(corpObj.maxQty) == Number(corpObj.minQty))) {
        this.toastrService.warning("Max Quantity should be Greater than Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (corpObj.qtyprice == null || corpObj.qtyprice == undefined || corpObj.qtyprice == '') {
        this.toastrService.warning("Please enter price  ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(corpObj.qtyprice <= 0 ){
        this.toastrService.warning("Bundle price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corpObj.qtyprice !== undefined && corpObj.qtyprice !== null && corpObj.qtyprice !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corpObj.qtyprice) == false) {
            this.toastrService.warning("Bundle price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ( corpObj.CorpWisepeiseList.length != 0) {
        let existLabel =  corpObj.CorpWisepeiseList.filter(x => x.minQty == corpObj.minQty && x.maxQty == corpObj.maxQty && x.qtyprice == corpObj.qtyprice)
        if (existLabel.length > 0) {
          this.toastrService.warning("This record allready exists", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
        }        
        else {
          let shouldPush = true;
          for (let i = 0; i < corpObj.CorpWisepeiseList.length; i++) {
            if(this.editCorpBundleIndex !=i ){
            if (Number(corpObj.minQty) >= Number(corpObj.CorpWisepeiseList[i].minQty) && Number(corpObj.minQty) <= Number(corpObj.CorpWisepeiseList[i].maxQty)) {
              shouldPush = false;
              this.toastrService.warning("Enter Different range", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
              return;
            } else if (Number(corpObj.maxQty) >= Number(corpObj.CorpWisepeiseList[i].minQty) && Number(corpObj.maxQty) <= Number(corpObj.CorpWisepeiseList[i].maxQty)) {
              shouldPush = false;
              this.toastrService.warning("Enter Different range", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
              return;
            }else if (Number(corpObj.maxQty) > Number(corpObj.CorpWisepeiseList[i].maxQty) && Number(corpObj.minQty) < Number(corpObj.CorpWisepeiseList[i].minQty)) {
              shouldPush = false;
              this.toastrService.warning("Enter Different range", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center'});
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass()
          corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
              return;
            }
          }
          }
          if (shouldPush) {
//
            if(this.editCorpBundleIndex>-1){
              corpObj.CorpWisepeiseList[this.editCorpBundleIndex].minQty=corpObj.minQty;
              corpObj.CorpWisepeiseList[this.editCorpBundleIndex].maxQty=corpObj.maxQty;
              corpObj.CorpWisepeiseList[this.editCorpBundleIndex].qtyprice=corpObj.qtyprice;
              this.editCorpBundleIndex=-1;
            }else{
              corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
            }            
           // corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
            corpObj.minQty = '';
            corpObj.maxQty = '';
            corpObj.qtyprice = '';
            this.selectedCorpid = '';
          console.log("corpObj.CorpWisepeiseList",corpObj.CorpWisepeiseList)
          }

        }
          //corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
          
        }
      else {
        corpObj.CorpWisepeiseList.push({ "selectedCorpName": corpObj.enterprise_name, "corpid": corpObj.add_corporate_id, "minQty": corpObj.minQty, "maxQty": corpObj.maxQty, "qtyprice": corpObj.qtyprice });
        corpObj.minQty = '';
        corpObj.maxQty = '';
        corpObj.qtyprice = '';
        this.selectedCorpid = '';
        console.log("corpObj.CorpWisepeiseList---",corpObj.CorpWisepeiseList)
      }
    
    
  }
  addMarketWisepeise()
{    
  if (this.MinQty == null || this.MinQty == undefined || this.MinQty == '') {
    this.toastrService.warning("Please enter Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if (this.MaxQty == null || this.MaxQty == undefined || this.MaxQty == '') {
    this.toastrService.warning("Please enter Max quantity ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if (this.Qtyprice == null || this.Qtyprice == undefined || this.Qtyprice == '') {
    this.toastrService.warning("Please enter price  ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass()
    return false;
  }
  if (this.MarketWisepeiseList.length != 0) {
    let existLabel = this.MarketWisepeiseList.filter(x => x.MinQty == this.MinQty && x.MaxQty == this.MaxQty && x.Qtyprice == this.Qtyprice)
    if (existLabel.length > 0) {
      this.toastrService.warning("This record allready exists", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
    } else {
      this.MarketWisepeiseList.push({ "MinQty": this.MinQty, "MaxQty": this.MaxQty, "Qtyprice": this.Qtyprice });
      this.MinQty = '';
      this.MaxQty = '';
      this.Qtyprice = '';
     
    }
  } else {
    this.MarketWisepeiseList.push({ "MinQty": this.MinQty, "MaxQty": this.MaxQty, "Qtyprice": this.Qtyprice });
    this.MinQty = '';
    this.MaxQty = '';
    this.Qtyprice = '';
   
  }
} 
 removeCorpWisepeise(i, obj) {
  //obj.CorpWisepeiseList.splice(i, 1);
  Swal.fire({
    title: `Do you want to delete ?`,
    showDenyButton: true,
    // showCancelButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result) => {
    if (result.isConfirmed) {
      obj.CorpWisepeiseList.splice(i, 1);
   this.toastrService.success("Bundle deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' });
  $(".overlay-container").addClass("addcontainerdisable");
  this.removeClass();  
    } else if (result.isDenied) {
      obj.minQty = '';
      obj.maxQty = '';
      obj.qtyprice = '';
    }
  })
  }
  editCorpWisepeise(index, obj) {
    obj.minQty = obj.CorpWisepeiseList[index].minQty;
    obj.maxQty = obj.CorpWisepeiseList[index].maxQty;
    obj.qtyprice = obj.CorpWisepeiseList[index].qtyprice;
    //obj.CorpWisepeiseList.splice(index, 1);
    this.editCorpBundle=true;
    this.editCorpBundleIndex=index;
  }
  editMarketWisepeise(index){
    this.MinQty = this.MarketWisepeiseList[index].MinQty;
    this.MaxQty = this.MarketWisepeiseList[index].MaxQty;
    this.Qtyprice = this.MarketWisepeiseList[index].Qtyprice;
    this.marketlist.splice(index, 1);
  }
  removeMarketWisepeise(i){
    this.MarketWisepeiseList.splice(i, 1);  
   
  }
  addProduct() {  
    if((!this.assign_marketplace || this.assign_marketplace == null || this.assign_marketplace == undefined || this.assign_marketplace == '') &&(!this.assign_corp || this.assign_corp == null || this.assign_corp == undefined || this.assign_corp == '')){
      this.toastrService.warning("Please select assign product!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.assign_corp == true) {
      if (this.corp_id == null || this.corp_id == undefined || this.corp_id == '') {
        this.toastrService.warning("Please select corporate name", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    for(let corp of this.tempcoprlist){
      corp.Corporate_price = corp.Corporate_price ? corp.Corporate_price.trim() : '';
      if(Number(corp.Corporate_price) == null || Number(corp.Corporate_price) == undefined || (corp.Corporate_price) == ''){
        this.toastrService.warning("Please enter corporate price", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corp.Corporate_price) > Number(this.mrp)){
        this.toastrService.warning("Corporate price must be less than or equal to MRP", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(Number(corp.Corporate_price) <= 0){
        this.toastrService.warning("Corporate Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (corp.Corporate_price !== undefined && corp.Corporate_price !== null && corp.Corporate_price !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(corp.Corporate_price) == false) {
            this.toastrService.warning("Corporate Price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if(corp.showqtyPrice== true){
        if(corp.CorpWisepeiseList.length == 0){
        this.toastrService.warning("Please Enter Bundle Specifications for corporate!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
        }
      }
    }

    // if (this.tempcoprlist.length > 0) {
    //   for(let a of this.tempcoprlist)
    //   {
    //       if(a.Corporate_price == ''){
    //         this.toastrService.warning("Please ===", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
    //         $(".overlay-container").addClass("addcontainerdisable");
    //         return false;
    //       }

    //   }

    // }

    let a = this.brandNameList.filter(x => x.brand_name_id == this.brand_id)
    this.brand_id = a[0] ? a[0].brand_name_id : null;
    this.brand_name = a[0] ? a[0].name : null;

    this.brand_name = this.brand_name ? this.brand_name.trim() : '';
    if (this.brand_name == null || this.brand_name == undefined || this.brand_name == '') {
      this.toastrService.warning("Please Enter Brand Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.product_name = this.product_name ? this.product_name.trim() : '';
    if (this.product_name == null || this.product_name == undefined || this.product_name == '') {
      this.toastrService.warning("Please Enter Product Name!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.suk = this.suk?.trim();
    if (this.suk == null || this.suk == undefined || this.suk == '') {
      this.toastrService.warning("Please Enter SKU!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    this.price = this.price ? this.price.trim() : '';
    if (this.price == null || this.price == undefined || this.price == '') {
      this.toastrService.warning("Please Enter Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.price <= 0){
      this.toastrService.warning("Selling Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false; 
    }
    if (this.price !== undefined && this.price !== null && this.price !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.price) == false) {
          this.toastrService.warning("Selling Price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    this.mrp = this.mrp ? this.mrp.trim() : '';
    if (this.mrp == null || this.mrp == undefined || this.mrp == '') {
      this.toastrService.warning("Please Enter Product MRP!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.mrp <= 0){
      this.toastrService.warning("MRP must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false; 
    }
    if (this.mrp !== undefined && this.mrp !== null && this.mrp !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.mrp) == false) {
          this.toastrService.warning("MRP must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    // this.puerchasePrice = this.puerchasePrice ? this.puerchasePrice.trim() : '';
    // if (this.puerchasePrice == null || this.puerchasePrice == undefined || this.puerchasePrice == '' || this.puerchasePrice == 0) {
    //   this.toastrService.warning("Please Enter Purchase Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    // }
    this.hsn = this.hsn ? this.hsn.trim() : '';
    if (this.hsn == null || this.hsn == undefined || this.hsn == '') {
      this.toastrService.warning("HSN is required!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.hsn !== undefined && this.hsn !== null && this.hsn !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.hsn) == false) {
          this.toastrService.warning("HSN No. Should be Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    if (this.hsn?.length < 4 || this.hsn?.length > 8) {
      this.toastrService.warning("HSN No. should be four to Eight digit!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.asPerPrice == true) {
      if (this.bundleAtribute.length == 0) {
        this.toastrService.warning("Please Enter Bundle Specifications!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false;
      }
    }
    this.gstRate = this.gstRate ? this.gstRate.trim() : '';
    if (this.gstRate == null || this.gstRate == undefined || this.gstRate == '') {
      this.toastrService.warning("GST rate is required!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // this.minOrderQuantity = this.minOrderQuantity ?  this.minOrderQuantity.trim() : '';
    if (this.minOrderQuantity == null || this.minOrderQuantity == undefined || this.minOrderQuantity == '') {
      this.toastrService.warning("Minimum order quantity is required!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.minOrderQuantity < 1) {
      this.toastrService.warning("Minimum order quantity Should be atleast 1", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false
    }
   
    if (this.minOrderQuantity !== undefined && this.minOrderQuantity !== null && this.minOrderQuantity !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.minOrderQuantity) == false) {
          this.toastrService.warning("Minimum order quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
    if (Number(this.price) > Number(this.mrp)) {
      this.toastrService.warning("Selling price must be less than or equal to MRP", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
   
    if (this.quantity == null || this.quantity == undefined || this.quantity == '') {
      this.toastrService.warning("Please Enter Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if(this.quantity < 0){
      this.toastrService.warning("Stock must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false; 
    }
    if (this.quantity !== undefined && this.quantity !== null && this.quantity !== "") {
      let pattrn = /^[0-9]+$/;
        if (pattrn.test(this.quantity) == false) {
          this.toastrService.warning("Avilable stock must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
          $(".overlay-container").addClass("addcontainerdisable");
           this.removeClass();
          return false; 
      }
    }
   
    // this.stock_status = this.stock_status?.trim();
    // if (this.stock_status == null || this.stock_status == undefined || this.stock_status == '') {
    //   this.toastrService.warning("Please Select Stock Status!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }
    this.category = this.category ? this.category.trim() : '';
    if (this.category == null || this.category == undefined || this.category == '') {
      this.toastrService.warning("Please select category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    
    this.sub_category = this.sub_category ? this.sub_category.trim() : '';
    if (this.sub_category == null || this.sub_category == undefined || this.sub_category == '') {
      this.toastrService.warning("Please select sub-category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.country_id == null || this.country_id == undefined || this.country_id == '') {
      this.toastrService.warning("Please select country of manufacture!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.manufacture_year == null || this.manufacture_year == undefined || this.manufacture_year == '') {
      this.toastrService.warning("Please select manufacture year!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // if (this.this_item_has_weight == null || this.this_item_has_weight == undefined || this.this_item_has_weight == '') {
    //   this.toastrService.warning("Please Select Item Has Weight Section!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }

    this.weigth = this.weigth?.trim();
    if (this.this_item_has_weight == 1) {
      if (this.weigth == null || this.weigth == undefined || this.weigth == '') {
        this.toastrService.warning("Please enter weight!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    // if (this.productSpecifications == null || this.productSpecifications == undefined || this.productSpecifications == '') {
    //   this.toastrService.warning("Please Enter Product Specifications!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    // }
    // if (this.productSpecifications.length == 0) {
    //   this.toastrService.warning("Please Enter Atleast 1 Product Specifications!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   return false;
    // }
        //  ====P S====>
    // if (this.lableList == 0) {
    //   this.toastrService.warning("Please select label & values!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass();
    //   return false;
    // }
            //  ====P S====>
    for (let list of this.lableList) {
      if (list.selectedValue == null) {
        if (!list.is_custom) {
          this.toastrService.warning("Please select all specification values!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" });
          $(".overlay-container").addClass("addcontainerdisable");
          this.removeClass();
          return false;
        }
      }
    }
    if (this.keyFeatures == null || this.keyFeatures == undefined || this.keyFeatures == '') {
      this.toastrService.warning("Please enter product key features!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.keyFeatures.length == 0) {
      this.toastrService.warning("Please enter atleast 1 key feature!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    //this.product_discription = this.product_discription ?  this.product_discription.trim() : '';
    if (this.product_discription == null || this.product_discription == undefined || this.product_discription == '') {
      this.toastrService.warning("Please enter product description!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    if (this.uploadedGalaryFiles.length <= 0) {
      this.toastrService.warning("Please enter atleast 1 product images!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    for (let list of this.lableList) {
      if (list.is_custom == true)
        continue;
      if (list.selectedValue == null) {
        this.toastrService.warning("Please select specification values!", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
    }
    if(this.nextedsubcategory.length>0){
      let nextedcategoryvalue = (this.nextedsubcategory[this.nextedsubcategory.length-1].selected);
      let nextsub =  nextedcategoryvalue? (typeof nextedcategoryvalue === "string")?nextedcategoryvalue.trim():nextedcategoryvalue:'';
      if (nextsub == null || nextsub == undefined || nextsub == '') {
       this.toastrService.warning("Please select nexted sub-category!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
       $(".overlay-container").addClass("addcontainerdisable");
       this.removeClass()
       return false;
      }else{
        this.sub_category = nextsub
      }
     }
    $("#save-btn").addClass('disabled');
    var productDetails: any = {
      userId: localStorage.getItem('userId'),
      lableList: JSON.stringify(this.lableList), keyFeatures: JSON.stringify(this.keyFeatures), product_name: this.product_name,
      suk: this.suk, price: this.price, purchasePrice: this.puerchasePrice, hsn: this.hsn, gstRate: this.gstRate, quantity: this.quantity, stock_status: this.stock_status, this_item_has_weight: this.this_item_has_weight, weigth: this.weigth,
      category: this.category, product_discription: this.product_discription, seller_id: this.seller_id, country_id: this.country_id, product_id: localStorage.getItem("editable-item"), mrp: this.mrp,
      brand_name: this.brand_name, brand_id: this.brand_id, sub_category: this.sub_category, manufacture_year: this.manufacture_year, product_tags: this.product_tags, min_order_quantity: this.minOrderQuantity,
      corp_id: JSON.stringify(this.corp_id), assign_marketplace: this.assign_marketplace ? this.assign_marketplace : null, assign_corp: this.assign_corp ? this.assign_corp : null, tempcoprlist: JSON.stringify(this.tempcoprlist), CorpWisepeiseList: JSON.stringify(this.CorpWisepeiseList), marketWisePriceList: JSON.stringify(this.bundleAtribute),sellerListEdit :this.sellerListEdit,isAdmin:true
    };
    this.formData = new FormData();
    var docNames = [];
    this.formData.append("productDetails", JSON.stringify(productDetails));
    if (this.uploadedGalaryFiles.length > 0) {
      for (var i = 0; i < this.uploadedGalaryFiles.length; i++) {
        this.formData.append('files', this.uploadedGalaryFiles[i]['file']);
      }
    }
    this.productService.addProduct(this.formData).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastrService.success(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        this.reset();
        $("#save-btn").removeClass('disabled');
        this.router.navigateByUrl('/product-listing' + '?' + localStorage.getItem('tempToken'));
      }
      if (res.status == 207) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        $("#save-btn").removeClass('disabled');
        // this.reset();
      }
    })

  }

  addBrandName() {
    this.brandName = this.brandName?.trim();
    if (this.brandName == null || this.brandName == 'null' || this.brandName == '' || this.brandName == undefined) {
      this.toastrService.warning("Brand name is required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      return false;
    }
    // this.brandCode = this.brandCode?.trim();
    // if (this.brandCode == null || this.brandCode == 'null' || this.brandCode == '' || this.brandCode == undefined) {
    //   this.toastrService.warning("Brand Code is Required", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   this.removeClass()
    //   return false;
    // }
    //console.log('brand name', this.brandName, this.brandCode,this.seller_id)
    this.productService.addBrandName(this.brandName, this.seller_id).subscribe((res: any) => {
      if (res.status == 201) {
        this.toastrService.warning(res.message, '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.brandName = '';
        this.brandCode = '';
        return false;
      } else {
        this.toastrService.success("Brand name added successfully ", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        this.brandName = ''
        this.brandCode = ''
        $('#addbrand').modal('hide')
        this.getBrandName()
      }
    })


  }
  getWeightValue() {
    if (this.this_item_has_weight == 1) {
      this.hasWeight = true
    } else {
      this.hasWeight = false
    }
  }
  getBrandName() {
    this.productService.getBrandNameList(this.seller_id).subscribe(res => {
      this.brandNameList = res;
    })
  }
  categoryChanged() {
    this.getSubCategory();
    this.sub_category = undefined;
    this.nextedsubcategory = [];
    this.lableList = [];
  }
  nextedsubcategory:any=[];
  getSpecificationLabel() {
    this.sellerService.getSpecificationLabelList(this.sub_category).subscribe(res => {
      this.lableList = res[0].rows;
      this.lableValueName = res[1].rows
      var lableValues = [];
      for (let obj of this.lableList) {
        lableValues = [];
        lableValues = this.lableValueName.filter(x => x.product_specification_label_id == obj.product_specification_label_id)
        obj.lableValues = lableValues;
        obj.selectedValue = null;

      }
    })
    this.nextedsubcategory=[];
    this.getnextedSubCategory(this.sub_category,0);
  }
  getnextedSubCategory(sub,val) {
    
    this.nextedsubcategory = this.nextedsubcategory.slice(0,val)
    this.sellerService.getSubCategory(sub).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.nextedsubcategory[val] = {index:val,res:res,selected:undefined};
       // this.subCategoryList = res;
      }     
    })
  }
  resetBrand() {
    this.brand_name = '',
      this.brandCode = ''
  }
  onProductEdit(id) {
    //
    this.isView = false;
    this.isEdit = true;
    this.showForm = true;
    this.productId = id;
    //  this.getBrandName()
      // console.log(this.brandNameList);
      // this.getcorp();
      this.productService.onProductEdit(id).subscribe(async res => {
        this.productImagesList = res[3].rows || [];
        this.bundlePricelist = res[5].rows || [];
        this.bundleAtribute = res[6].rows || [];
        if(this.bundleAtribute.length > 0){
          this.asPerPrice = true
        }
        this.MarketWisepeiseList = res[5].rows;
        for (const img of this.productImagesList) {
          this.uploadedGalaryFiles.push({ "id": this.uploadedGalaryFiles.length + 1, "image_id": img.image_id, "isSavedInDB": true, 'file_path': img.document_path, "file_name": img.orignal_file_name });
        }
        this.productSpecifications = JSON.parse(res[0].rows[0].product_specifications);
        this.keyFeatures = JSON.parse(res[0].rows[0].key_features);
        //this.brand_name = res[0].rows[0].brand_name;
        this.brand_id = res[0].rows[0].brand_id;
        this.product_name = res[0].rows[0].product_name;
        this.suk = res[0].rows[0].suk;
        this.oldSku = res[0].rows[0].suk;
        this.price = res[0].rows[0].price;
        this.mrp = res[0].rows[0].mrp;
        this.quantity = res[0].rows[0].quantity;
        this.stock_status = res[0].rows[0].stock_status;
        this.this_item_has_weight = res[0].rows[0].this_item_has_weight;
        this.weigth = res[0].rows[0].weight;
        this.category = res[0].rows[0].category;
        this.hsn = res[0].rows[0].hsn;
        this.puerchasePrice = res[0].rows[0].purchase_price;
        this.minOrderQuantity = res[0].rows[0].min_order_qty;
        this.gstRate = res[0].rows[0].gst_rate;
        this.sub_category = res[0].rows[0].sub_category;
        
        let data:any = await  this.productService.getnextedsubcategory(this.sub_category);
        if(data && data.rows.length>2){
          data = data.rows;
          this.nextedsubcategory = [];
          data =  data.reverse()
          for(let i = 2 ;data.length>i ;i++){
            let catdata = await this.sellerService.getSubCategorywithpromise(data[i-1].product_category_id);
            this.nextedsubcategory.push({index:i-2,res:catdata,selected:data[i].product_category_id})
          }
          this.sub_category = data[1].product_category_id+'';
        }
        this.product_discription = res[0].rows[0].product_discription;
        this.country_id = res[0].rows[0].country;
        this.manufacture_year = res[0].rows[0].manufacture_year;
        this.product_tags = res[0].rows[0].product_tags;
        this.corp_id = JSON.parse(res[0].rows[0].corp_id);
        //
        this.assign_corp = res[0].rows[0].assign_corp=='false'?null:true;
        this.assign_marketplace = res[0].rows[0].assign_marketplace=='false'?null:true;

        // this.uploadedGalaryFiles = [];
        this.lableList = res[2].rows
        // console.log('lableslist', this.lableList)
        for (let obj of this.lableList) {
          obj.lableValues = JSON.parse(obj.specification_label_value_name_list)//obj.specification_label_value_name_list ? JSON.parse(obj.specification_label_value_name_list) : [];
          obj.selectedValue = Number(obj.selectedValue)
        }
        this.getSubCategory();
        this.getWeightValue();

        // this.getGstList();
        // this.getYearData();
        this.tempcoprlist = res[4].rows;
        // for (const key of this.tempcoprlist) {
        //   
        //   key.showqtyPrice = true

        // }
        this.CorpWisepeiseList = [];
        this.CorpWisepeiseList = res[6].rows;
    //     console.log("this.CorpWisepeiseList",this.CorpWisepeiseList)
         for (const corp of this.bundlePricelist) {
          corp.selectedCorpName = this.corpList.filter(x => x.add_corporate_id == corp.corpid)[0].enterprise_name;
         }
    //  console.log("xyz",this.CorpWisepeiseList)
    //     console.log('this.CorpWisepeiseList', temCorpWisepeiseList,this.tempcoprlist )
    //     if (temCorpWisepeiseList.length > 0) {
    //       this.showqtyPrice = true
    //     }
    //     for (let i = 0; i < temCorpWisepeiseList.length; i++) {
    //       console.log(temCorpWisepeiseList[i].quantity_wise_prise)
    //       let json = JSON.parse(temCorpWisepeiseList[i].quantity_wise_prise);
          // for (let c = 0; c < json.length; c++) {
             
          //     this.CorpWisepeiseList[c].selectedCorpName = json[c]["selectedCorpName"]
          //     corpid: json[c]["corpid"],
          //     maxQty: json[c]["maxQty"],
          //     minQty: json[c]["minQty"],
          //     qtyprice: json[c]["qtyprice"],
          //   })
          // }
          // console.log(this.CorpWisepeiseList,JSON.parse(temCorpWisepeiseList[i]["quantity_wise_prise"]))
      //   }
        if (this.corp_id != null) {
          this.getcorporate(this.bundlePricelist);
        }
      //   console.log(this.CorpWisepeiseList)
      });
  }

  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

  removeBundleAttribute(index) {
    //this.bundleAtribute.splice(index, 1);
    Swal.fire({
      title: `Do you want to delete ?`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.bundleAtribute.splice(index, 1);
     this.toastrService.success("Bundle deleted successfully", '', { timeOut: this.MassageOutTime, positionClass: 'toast-top-center' });
    $(".overlay-container").addClass("addcontainerdisable");
    this.removeClass();  
      } else if (result.isDenied) {
        this.minQuantity =''
    this.maxQuantity = ''
    this.bundlePrice = ''
    this.noLimit = ''
      }
    })
  }
  editBundleAttribute(index) {
    //
    this.bundlindex = index;
    this.minQuantity = this.bundleAtribute[index]?.min_qty;
    this.maxQuantity = this.bundleAtribute[index]?.max_qty;
    this.bundlePrice = this.bundleAtribute[index]?.price;
    this.noLimit = this.bundleAtribute[index]?.no_limit;
   // this.bundleAtribute.splice(index, 1);
  }

  addBundleQuantity() {
//
    if (this.noLimit) {
      this.maxQuantity = null;
      if ((this.minQuantity == null || this.minQuantity == '' || this.minQuantity == undefined)) {
        this.toastrService.warning("Please Enter Min Quantity !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(this.minQuantity < 0){
        this.toastrService.warning("Min quanity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (this.minQuantity !== undefined && this.minQuantity !== null && this.minQuantity !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(this.minQuantity) == false) {
            this.toastrService.warning("Min quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ((this.bundlePrice == null || this.bundlePrice == '' || this.bundlePrice == undefined)) {
        this.toastrService.warning("Please Enter Bundle Price !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(this.bundlePrice < 0){
        this.toastrService.warning("Bundle Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (this.bundlePrice !== undefined && this.bundlePrice !== null && this.bundlePrice !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(this.bundlePrice) == false) {
            this.toastrService.warning("Bundle price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if (((Number(this.minOrderQuantity) >= Number(this.minQuantity)))) {
        this.toastrService.warning("Min Quantity in Bundle should be greater than Min Order Quantity", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.bundleAtribute.length != 0) {
        for (let i =0;this.bundleAtribute.length>i;i++) {
          let obj = this.bundleAtribute[i];
          if(this.bundlindex !=i ){
          // if (obj.min_qty == this.minQuantity && obj.price == this.bundlePrice) {
          //   this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
          //   $(".overlay-container").addClass("addcontainerdisable");
          //   this.removeClass()
          //   this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
          //   return false;
          // }
          if (obj.no_limit == this.noLimit) {
            this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
            return false;
          }
        }
        }
       
        if(this.bundlindex>-1){
          this.bundleAtribute[this.bundlindex].min_qty=this.minQuantity;
        //  this.bundleAtribute[this.bundlindex].max_qty=this.maxQuantity;
          this.bundleAtribute[this.bundlindex].price=this.bundlePrice;
          this.bundlindex=-1;
        }else{
          this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
        }
        //this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
        this.minQuantity = null;
        this.maxQuantity = null;
        this.bundlePrice = null;
        this.noLimit = null;
      } else {
       
        this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
        this.minQuantity = null;
        this.maxQuantity = null;
        this.bundlePrice = null;
        this.noLimit = null;

      }
    } else {
      if ((this.minQuantity == null || this.minQuantity == '' || this.minQuantity == undefined)) {
        this.toastrService.warning("Please Enter Min Quantity !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(this.minQuantity < 0){
        this.toastrService.warning("Min quanity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (this.minQuantity !== undefined && this.minQuantity !== null && this.minQuantity !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(this.minQuantity) == false) {
            this.toastrService.warning("Min quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ((this.maxQuantity == null || this.maxQuantity == '' || this.maxQuantity == undefined)) {
        this.toastrService.warning("Please Enter Max Quantity !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(this.maxQuantity < 0){
        this.toastrService.warning("Max quanity must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (this.maxQuantity !== undefined && this.maxQuantity !== null && this.maxQuantity !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(this.maxQuantity) == false) {
            this.toastrService.warning("Max quantity must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if ((this.bundlePrice == null || this.bundlePrice == '' || this.bundlePrice == undefined)) {
        this.toastrService.warning("Please Enter Bundle Price !", '', {  timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if(this.bundlePrice < 0){
        this.toastrService.warning("Bundle Price must be greater than 0", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass();
        return false; 
      }
      if (this.bundlePrice !== undefined && this.bundlePrice !== null && this.bundlePrice !== "") {
        let pattrn = /^[0-9]+$/;
          if (pattrn.test(this.bundlePrice) == false) {
            this.toastrService.warning("Bundle price must be in Numeric digit", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
            $(".overlay-container").addClass("addcontainerdisable");
             this.removeClass();
            return false; 
        }
      }
      if (((Number(this.minOrderQuantity) >= Number(this.minQuantity)))) {
        this.toastrService.warning("Please Enter Min quantity greater to minimum order quantity", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if ((Number(this.maxQuantity) < Number(this.minQuantity)) || (Number(this.maxQuantity) == Number(this.minQuantity))) {
        this.toastrService.warning("Max Quantity should be Greater than Min Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
        $(".overlay-container").addClass("addcontainerdisable");
        this.removeClass()
        return false;
      }
      if (this.bundleAtribute.length != 0) {
        for (let i =0;this.bundleAtribute.length>i;i++) {
          let obj = this.bundleAtribute[i];
          if(this.bundlindex !=i ){
          if (obj.no_limit == true) {
            if ((this.minQuantity == null || this.minQuantity == '' || this.minQuantity == undefined) || (this.bundlePrice == null || this.bundlePrice == '' || this.bundlePrice == undefined)) {
              this.toastrService.warning("Please Enter Min and Price !", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
              $(".overlay-container").addClass("addcontainerdisable");
              this.removeClass()
              return false;
            } else {
              if ((Number(this.minQuantity > obj.min_qty)) || (Number(this.maxQuantity > obj.min_qty))) {
                this.toastrService.warning("Please Enter Different Quantity Range!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
                $(".overlay-container").addClass("addcontainerdisable");
                this.removeClass()
                return false;
              } else
                continue;
            }
          }
          if (obj.min_qty == this.minQuantity && obj.max_qty == this.maxQuantity && obj.price == this.bundlePrice) {
            this.toastrService.warning("These Attributes are Already Added", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
            return false;
          }
          //  console.log('chhhhhhhhhhhhhhhhhhhhhh', obj.min_qty)
          if ((Number(this.minQuantity) >= Number(obj.min_qty)) && (Number(this.minQuantity) <= Number(obj.max_qty))) {
            this.toastrService.warning("Enter different range", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
            return false;
          }
          if ((Number(this.maxQuantity) >= Number(obj.min_qty)) && (Number(this.maxQuantity) <= Number(obj.max_qty))) {
            this.toastrService.warning("Enter different range", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
            return false;
          }
          if ((Number(this.maxQuantity) > Number(obj.max_qty)) && (Number(this.minQuantity) < Number(obj.min_qty))) {
            this.toastrService.warning("Enter different range", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"  })
            $(".overlay-container").addClass("addcontainerdisable");
            this.removeClass()
            this.minQuantity = this.maxQuantity = this.bundlePrice = this.noLimit = null;
            return false;
          }
        }
        }
        if(this.bundlindex>-1){
          this.bundleAtribute[this.bundlindex].min_qty=this.minQuantity;
          this.bundleAtribute[this.bundlindex].max_qty=this.maxQuantity;
          this.bundleAtribute[this.bundlindex].price=this.bundlePrice;
          this.bundlindex=-1;
        }else{
          this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
        }
       
        this.minQuantity = null;
        this.maxQuantity = null;
        this.bundlePrice = null;
        this.noLimit = null
      } else {
        this.bundleAtribute.push({ "min_qty": this.minQuantity, "max_qty": this.maxQuantity, "price": this.bundlePrice, "no_limit": this.noLimit });
        this.minQuantity = null;
        this.maxQuantity = null;
        this.bundlePrice = null;
        this.noLimit = null;
      }

    }


  }

  onChange(event) {
    // 
    // if (event.target.checked) {
    //   this.asPerPrice = this.pricingId
    // } else {
    //   this.asPerPrice = 0
    // }
    // this.showDiv = event.target.checked

  }

  ngOnDestroy() {
    localStorage.removeItem('editable-item');
  }
}
