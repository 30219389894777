
<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
    <div class="h-100 page-wraper" id="page-content-wrapper" style="background: #f8f9fa">
   

        <div class="container-fluid bg-white">
            <div class="row">
            <div class="col-sm-12 p-0">
                <nav aria-label="breadcrumb p-0" *ngIf="!breadcrumb">
                    <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/dashboardnew">Dashboard</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/total-seller">Total Seller</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                    routerLink="/seller-wise-product">Product Listing</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Group Products</li>
                    </ol>
                </nav>
                <nav aria-label="breadcrumb p-0" *ngIf="breadcrumb">
                    <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                routerLink="/dashboardnew">Dashboard</a></li>
                       
                        <li class="breadcrumb-item"><a href="javascript:void(0)"
                                    routerLink="/product-listing">Pending Products</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Add Group Products</li>
                    </ol>
                </nav>
            </div>
          </div>

          <div class="col-sm-12 p-0">
            <div class="tabs  my-2">
              <div class="tab-button-outer1">
                <ul id="tab-button" class="tbsbtns">
                  <li id="tabDraft" class="is-active">
                    <a href="javascript:void(0)" class="font8">Group Product </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

            <div class="card shadow-none p-3">
                <div class="">
                    <div class="row">
                        <!-- <div class="row mb-2">
                            <div class="col-md-2 text-center">
                                <label for="base_product"> Base Product</label>
                            </div>
                            <div class="col-md-10">
                                <ng-select name="base_product_id" placeholder="--Select Product--"
                                    [(ngModel)]="base_product_id" (change)="OnBaseChange()" *ngIf="!isEdit">
                                    <ng-option *ngFor="let pro of base_product_list" value="{{pro.product_id}}">
                                        {{pro.product_name}}</ng-option>
                                </ng-select>
                                <input type="text" [value]="group_name"  *ngIf="isEdit" [disabled]="isEdit" class="form-control">
                            </div>

                        </div> -->
                        <div class="col-sm-12">
                        <div class="row">
                            <div class="col-md-2">
                                <label for="base_product"> Base Product</label>
                            </div>
                            <div class="col-md-4">
                                <ng-select name="base_product_id" placeholder="--Select Base Product--"
                                    [(ngModel)]="base_product_id" (change)="OnBaseChange()" *ngIf="!isEdit">
                                    <ng-option *ngFor="let pro of base_product_list" value="{{pro.product_id}}">
                                        {{pro.product_name}}</ng-option>
                                </ng-select>
                                <input type="text" [value]="group_name"  *ngIf="isEdit" [disabled]="isEdit" class="form-control">
                            </div>

                            <div class="col-md-2">
                                <label for="base_product"> Group Product</label>
                            </div>
                            <div class="col-md-4 mb-3">
                                <ng-select name="productsList" placeholder="-- Select Product --"
                                    [(ngModel)]="product_id">
                                    <ng-template ng-header-tmp>
                                        No. of Products: {{simpleProductList.length}}
                                    </ng-template>
                                    <ng-option *ngFor="let pro of simpleProductList" value="{{pro.product_id}}">
                                        {{pro.product_name}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-2 p-sm-0 col-lg-1">
                                <button class="btn btn-sm btn-theme" (click)="addProductInList()"> <i
                                        class="fa fa-plus"></i> ADD </button>
                            </div>
                        </div>

                        <div class="mt-2" *ngIf="selectedProductList.length>0">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w10 text-center">Sr. No.</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Price</th>
                                        <!-- <th scope="col">Quantity</th> -->
                                        <th scope="col" class="text-center w20">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let pro of selectedProductList; let i =index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td>
                                            {{pro.product_name}}
                                        </td>
                                        <td>
                                            <input type="text" onpaste="return false;" ondrop="return false;"
                                                autocomplete="off" min="0" maxlength="15" class="form-control mb-2"
                                                id="productTitle" placeholder="Price"
                                                (keypress)="commonFunctionService.amountValidator($event)"
                                                [(ngModel)]="pro.price">
                                            <!-- {{pro.price}} -->
                                        </td>
                                        <!-- <td>
                                            <input type="text" onpaste="return false;" ondrop="return false;"
                                                autocomplete="off" min="0" class="form-control mb-2" id="productTitle"
                                                placeholder="Quantity"
                                                (keypress)="commonFunctionService.numericCheck($event)"
                                                [(ngModel)]="pro.quantity" maxlength="10">
                                        </td> -->
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm btn-danger"
                                                (click)="removeProduct(i)"><i class="fa fa-trash mr-1"></i> Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 pt-2">
                        <div class="text-center">
                            <button class="btn btn-sm btn-gray px-3 mr-2" (click)="back()">
                                Back
                            </button>
                            <button class="btn btn-sm btn-theme px-3 mr-2" (click)="saveProductGroup()"
                                *ngIf="!editable_item_id">Save</button>
                            <button class="btn btn-sm btn-theme px-3 mr-2" (click)="saveProductGroup()"
                                *ngIf="editable_item_id">Update</button>
                            <button class="btn btn-sm btn-danger px-3" (click)="reset()" *ngIf="!editable_item_id">Reset</button>
                
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>