import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() FormName:any;
  @Input() ModuleName:any;
  @Input() FileNo:any;
  constructor() { }

  ngOnInit(): void {
    this.FormName=this.FormName?this.FormName:"";
    this.ModuleName=this.ModuleName?this.ModuleName:"";
    this.FileNo=this.FileNo?this.FileNo:"";
  }

}
