import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../app/_services/form.service';
import { Page } from '../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../_services/product.service';
import { RowComp, Promise } from 'ag-grid-community';
import Swal from 'sweetalert2';
import { AppSettings } from '../_services/AppSetting';
import { promise } from 'selenium-webdriver';
declare var $: any;

@Component({
  selector: 'app-add-new-deal',
  templateUrl: './add-new-deal.component.html',
  styleUrls: ['./add-new-deal.component.css']
})
export class AddNewDealComponent implements OnInit {
  isLoading: boolean = true;
  isShow: boolean = true;
  trackStatusCode:any = 181;
  future: boolean = false;
  history: boolean = false;
  current: boolean = true;
  suspend: boolean = false
  suspended: any;
  blk_suspend: any = '';
  currentSellerId: any;
  adminRemarks: any;
  trackStatusCode2: any = 0;
  currentSellerName: any = '';
  searchTerm: any;
  isloadgridTrue: any = false;
  token: string;
  isEdit:boolean = true;
  categoryList: Object;
  subCategoryList: any;
  allProductList: any=[];
  category: any;
  selectedProdcut: any;
  productdetail: any;
  ids: any = null;
  futureDealCount: any;
  historyDealCount: any;
  newdealid: any;
  view_item_id: any;
  isView: boolean;
  priceListproductwise:any;
  MassageOutTime = AppSettings.getMassageOutTime;
  stockQuantity: number;
  minOrderQty: number = 0;
  constructor(private ProductService: ProductService, private toastrService: ToastrService,private router: Router) { }
  LoadPagination: boolean = false;
  LoadPagination2: boolean = false;
  LoadPagination3: boolean = false;
  LoadPagination4: boolean = false;
  // LoadPagination5: boolean = false;

  approvedSeller: any[];
  blockedSeller: any[];
  rejectedSeller: any[];
  suspendedSeller: any[];
  endDate: any;
  discount: any;
  startDate: any;
  price: any;
  mrp: any;
  quantity: any = 0;
  allSeller: any[];
  sellerList: any;
  isLoader: boolean = true;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  tempsellerList: any[];
  userId: any;
  allSellerList: any[];
  allSellerListData:any;
  sub_category: any;
  currentDealCount:any;
  rejectCount:any;
  suspendedCount:any;
  blockCount:any;
  isdataloaded:Promise<boolean> = Promise.resolve(false);
  //........new 
  finalDt :any;
  alldealList:any[]=[];
  ngOnInit(): void {
    this.isLoading = true;
    this.token = localStorage.getItem('tempToken')
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
  
    $(document).ready(function(){
      $('ul.tbsbtns li').click(function(){
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
    });
    });
    this.finalDt = new Date().toISOString().split('T')[0];
    this.getDealList();
    this.getAllCat()
    
    let currDate = new Date();
    var d, m, y, hour, minute;
    d = currDate.getDate();
    m = currDate.getMonth() + 1;
    y = currDate.getFullYear();
    hour = currDate.getHours();
    minute = currDate.getMonth();
    if (minute < 10) { minute = "0" + minute };
    if (d < 10) { d = "0" + d };
    if (m < 10) { m = "0" + m };
    // this.finalDt = y + "-" + m + "-" + d;
    this.bindNgxDataGridCurrent(this.page, 1);
  }
  getDealList(){
    this.ProductService.getAllDealType().subscribe(res => {
      this.allSellerListData = res.rows;
      //console.log('want to check count', res.rows)
      let data = new Date()
      this.currentDealCount = (this.allSellerListData.filter(x => ((x.new_start_date <= this.finalDt) && (x.new_end_date >= this.finalDt) ))).length
      //console.log('ddddddddcount', this.currentDealCount);
      this.futureDealCount = (this.allSellerListData.filter(x => ((x.new_start_date > this.finalDt) && (x.new_end_date > this.finalDt) ))).length;
      this.historyDealCount = (this.allSellerListData.filter(x => ((x.new_start_date < this.finalDt) && (x.new_end_date < this.finalDt) ) )).length;
    })
  }
  LoadGridCurr(event) {
    this.isloadgridTrue = true
     this.configParms = event;
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGridCurrent(this.page, 2)
  }
  LoadGridFuture(event) {
    this.isloadgridTrue = true
    this.configParms = event;
   this.page.size = event.itemsPerPage;
   this.page.totalElements = event.totalItems;
   this.page.pageNumber = event.currentPage;
    this.bindNgxDataGridFuture(this.page, 2)

 }
 LoadGridHistory(event) {
  this.isloadgridTrue = true
 this.configParms = event;
 this.page.size = event.itemsPerPage;
 this.page.totalElements = event.totalItems;
 this.page.pageNumber = event.currentPage;
 this.bindNgxDataGridHistory(this.page, 2)
}

LoadGridSusp(event) {
  this.isloadgridTrue = true
  this.configParms = event;
 this.page.size = event.itemsPerPage;
 this.page.totalElements = event.totalItems;
 this.page.pageNumber = event.currentPage;
 this.bindNgxDataGridSusp(this.page, 2)
}

  bindNgxDataGridCurrent(pageInfo, status) {
    this.isLoading = true;
    // console.log('current grid cakked', this.searchTerm)
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
   if(!this.isloadgridTrue)
   offset = this.searchTerm ? 0 : offset
   this.ProductService.currentDealListCount(this.searchTerm,this.finalDt).subscribe(resp => {
           this.page.totalElements = resp.rowCount;
          // console.log(this.page.totalElements,'this.page.totalElements')
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
        
    this.ProductService.getProductDealList(pagesize,offset,this.finalDt,this.searchTerm).subscribe(res => {
    if(res.rows.length > 0){
      this.alldealList = res.rows
      this.getDealList();
      //console.log(this.alldealList)
    //  this.tempsellerList =  this.approvedSeller = res.rows;
      this.isLoading = false;
    }else{
      setTimeout(() => {
       this.alldealList = []
        this.isLoading = false
      }, 100);
    }
      
      })
      this.totalCount = resp.rowCount;
     let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      this.LoadPagination = true;
      this.LoadPagination2 = false;
      this.LoadPagination3 = false;
      // this.isLoading = false;
      this.isdataloaded=Promise.resolve(false);
      this.isdataloaded=Promise.resolve(true);
    })

  }
  bindNgxDataGridFuture(pageInfo, status) {
    // console.log('reject grid cakked', this.searchTerm)
    this.isLoading = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    offset = this.searchTerm ? 0 : offset
    this.ProductService.getFutureDealListCount(this.finalDt, this.searchTerm).subscribe(resp => {
            this.page.totalElements = resp.rowCount;
       let pageCount = pageInfo.pageNumber
      // console.log("bindNgxDataGridRej pagesize,offset,qcStatus==>",pagesize,offset,qcStatus)
       this.ProductService.getFutureDealList(pagesize,offset,this.finalDt, this.searchTerm).subscribe(res => {
        if(res.rows.length > 0){
          //console.log('check data aaaaa', res.rows);
          this.alldealList = res.rows;
          this.getDealList();
        this.tempsellerList = res.rows;
          this.isLoading = false;
        }else{
          setTimeout(() => {
            this.alldealList = []
            this.tempsellerList = [];
          this.isLoading = false;
          }, 100);
        }

       
       })
       this.totalCount = resp.rowCount;
      let currPage = status==2?pageInfo.pageNumber:1;
       if (currPage == 0) { currPage = 1; }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
       this.LoadPagination2 = true;
       this.LoadPagination = false;
       this.LoadPagination3 = false;
     
       this.isdataloaded=Promise.resolve(false);
       this.isdataloaded=Promise.resolve(true);
       //this.isLoading = false;
     })
 
  }
  bindNgxDataGridHistory(pageInfo, status) {
    // console.log('history grid cakked', this.searchTerm)
    this.isLoading = true;
    //this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    offset = this.searchTerm ? 0 : offset
    this.ProductService.getDealHistoryCount(this.finalDt, this.searchTerm).subscribe(resp => {
            this.page.totalElements = resp.rowCount;
       let pageCount = pageInfo.pageNumber
 
       this.ProductService.getDealHistoryList(pagesize,offset,this.finalDt, this.searchTerm).subscribe(res => {
     if(res.rows.length > 0){
      this.tempsellerList = res.rows;
      this.alldealList = res.rows;
      this.getDealList();
     this.isLoading = false;
     }else{
       setTimeout(() => {
        this.tempsellerList = [];
      this.alldealList = [];
        this.isLoading = false;
         }, 100);
     }
       
       
       })
       this.totalCount = resp.rowCount;
       let currPage = status==2?pageInfo.pageNumber:1;
       if (currPage == 0) { currPage = 1; }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      //  console.log(this.configParms)
       this.LoadPagination3 = true;
       this.LoadPagination2 = false;
       this.LoadPagination = false;
       this.isdataloaded=Promise.resolve(false);
       this.isdataloaded=Promise.resolve(true);
       //this.isLoading = false;
     })
 

  }

  bindNgxDataGridSusp(pageInfo, status) {
    // console.log('suspended grid cakked', this.searchTerm)
     let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
    let qcStatusSecond = 184
    offset = this.searchTerm ? 0 : offset
    this.ProductService.pendingSellerCount(qcStatusSecond, this.searchTerm).subscribe(resp => {
            this.page.totalElements = resp.rows[0].count;
       let pageCount = pageInfo.pageNumber
 
       this.ProductService.SellerList(pagesize,offset, qcStatusSecond, this.searchTerm).subscribe(res => {
        // console.log('suspeddddddded datalist', res.rows)

       if(res.rows.length > 0){
        this.tempsellerList = res.rows;
        this.allSeller = res.rows;
       this.isLoading = false;
       }else{
         setTimeout(() => {
       this.isLoading = false;
       this.allSeller = []
         }, 100);
       }
       
       
       })
       this.totalCount = resp.rows[0].count;
       let currPage = status==2?pageInfo.pageNumber:1;
       if (currPage == 0) { currPage = 1; }
       this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
       this.LoadPagination4 = true;
       this.LoadPagination2 = false;
       this.LoadPagination3 = false;
       this.LoadPagination = false;
       this.isdataloaded=Promise.resolve(false);
       this.isdataloaded=Promise.resolve(true);
       this.isLoading = false;
     })
 

  }

  CommonGridSearchMaster(ev) {
    // console.log('called or not', ev)
    this.searchTerm = ev;
    if(this.current)
      this.bindNgxDataGridCurrent(this.page, 1)
      else if(this.future)
        this.bindNgxDataGridFuture(this.page, 1)
        else if(this.history)
          this.bindNgxDataGridHistory(this.page, 1)
   

    // this.allSeller = this.tempsellerList.filter(item => ((item.seller_name ? (item.seller_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.business_name ? (item.business_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.store_name ? (item.store_name.toString().indexOf(ev) !== -1) : false) || (item.mobile_no ? (item.mobile_no.toLowerCase().indexOf(ev) !== -1) : false)) || (item.email ? (item.email.toLowerCase().indexOf(ev) !== -1) : false))
  }

  getAnySeller(status, status2?) {
    this.ProductService.fetchSellerByStatusCode(status, status2).subscribe((res: any) => {
      this.allSeller = res.rows;
      this.isLoading = false;
      this.tempsellerList = res.rows;
    })
  }


  getFutureDeal() {
    this.isloadgridTrue = false
    this.searchTerm = ''
    this.future = true
    this.current = false;
    this.history = false
    this.suspend = false

    this.isLoading = true;
    this.trackStatusCode2 = 0
    this.trackStatusCode = 182
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridFuture(this.page, 1)
  }
  getCurrentDeal() {
    this.isloadgridTrue = false

    this.searchTerm = ''
    this.future = false
    this.history = false
    this.suspend = false
   this.isLoading = true;
    this.current = true;
    this.trackStatusCode = 181
    this.trackStatusCode2 = 0
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridCurrent(this.page, 1)

  }
  getBlockedSeller() {
    this.isloadgridTrue = false

    this.searchTerm = ''
    this.future = false
    this.current = false;
    this.history = true
    this.suspend = false
    this.isLoading = true;
    this.trackStatusCode = 183
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridHistory(this.page, 1)
  }

  getDealHistory(){
    this.isloadgridTrue = false
    this.searchTerm = ''
    this.future = false
    this.current = false;
    this.history = true;
    // this.suspend = true;
    this.isLoading = true;
    this.trackStatusCode = 184
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
   
    this.bindNgxDataGridHistory(this.page, 1)
  }

  reAcitve(code) {

    if (this.adminRemarks == '' || this.adminRemarks == null || this.adminRemarks == undefined || (this.adminRemarks || '').trim().length === 0) {
      this.toastrService.warning('Remark is required', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false
    }
  
    $("#toBlock").modal('hide');
    $("#myModal").modal('hide');
   let key = code == 183 ? 'block' : code == 184 ? 'suspend' : this.suspended == 183 ? 'unblock' : 'active'
    Swal.fire({
      title: `Do you want to  ${key} ${this.currentSellerName}?<br>`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.updateSellerStatus(code,this.currentSellerId, this.adminRemarks,this.userId).subscribe((res: any) => {
        
          if (this.history){
      
              this.getBlockedSeller()
              this.getDealList();
        Swal.fire('Seller Unblocked!', '', 'success')
          }
          else if (this.current) {
    
            this.getCurrentDeal()
            this.getDealList();
        Swal.fire(code == 183? 'Seller history!': 'Seller Suspended!', '', 'success')
          } else if(this.future){
     
            this.getFutureDeal()
            this.getDealList();
            Swal.fire('Seller future!', '', 'success')

          }else
          {
            this.getDealHistory()
            this.getDealList()
        Swal.fire('Seller Activated!', '', 'success')

          }
    
        })
      } else if (result.isDenied) {
        this.adminRemarks = ''
        // $("#myModal").modal('show');
        $("#toBlock").modal('hide');

      }
    })
  }

  getAllCat() {
    this.ProductService.getCategory().subscribe(res => {
      this.categoryList = res;
    })
  }

  getMRPPrice(){
    
    this.productdetail = this.allProductList.filter(item => item.product_id == this.selectedProdcut)
    //console.log('producdetal', this.productdetail)
    this.price = Number(this.productdetail[0].price)+Number(this.productdetail[0].admin_price)
    this.mrp = Number(this.productdetail[0].mrp)+Number(this.productdetail[0].admin_mrp)
    this.stockQuantity = Number(this.productdetail[0].quantity)
    this.minOrderQty   = Number(this.productdetail[0].min_order_qty)
    //console.log(" this.stockQuantity", this.stockQuantity)

  }

  getProducts(){
    this.ProductService.getProducts(this.sub_category, this.category).subscribe(res =>{
      console.log('res============',res)
     this.allProductList = res[0].rows;
      this.priceListproductwise = res[1].rows;
      let tempObj;
      for(let obj of this.allProductList){
        if(obj.product_type==198){
          tempObj=(res[1].rows.filter(x=>x.product_id==obj.product_id))[0];
          obj.price=Number(tempObj.price)+Number(tempObj.admin_price);
          obj.mrp = Number(tempObj.mrp)+Number(tempObj.admin_mrp)
          obj.purchase_price = tempObj.purchase_price
          obj.suk = tempObj.sku
          obj.quantity = tempObj.quantity
          console.log("obj.quanity",obj.quantity)
        }
        if(obj.product_type==208){
          tempObj=(res[1].rows.filter(x=>x.product_id==obj.product_id))[0];
          obj.price=Number(tempObj.price)+Number(tempObj.admin_price);
          obj.mrp = Number(tempObj.mrp)+Number(tempObj.admin_mrp)
          obj.purchase_price = tempObj.purchase_price
          obj.suk = tempObj.sku
          obj.quantity = tempObj.quantity
        }
      }
       //console.log('check for price', this.allProductList)
    })
  }
  getMinDate(){
    
    let tommorow = new Date().getDate()
    let cur = new Date(new Date().getFullYear(), new Date().getMonth(), tommorow)
    return new Date(cur.getFullYear(),cur.getMonth(),cur.getDate()+1).toISOString().split('T')[0];
  }



  onSubmit(){
    //debugger
    this.category = this.category?.trim();
    if (this.category == null || this.category == undefined || this.category == '') {
      this.toastrService.warning("Please Select Category!", '',{ timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.sub_category= this.sub_category?.trim();
    if (this.sub_category == null || this.sub_category == undefined || this.sub_category == ''){
      this.toastrService.warning("Please Select Sub Category!", '',{ timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.selectedProdcut= this.selectedProdcut?.trim();
    if (this.selectedProdcut == null || this.selectedProdcut == undefined || this.selectedProdcut == ''){
      this.toastrService.warning("Please Select Product!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.quantity== null || this.quantity == undefined || this.quantity == 0 ){
      this.toastrService.warning("Please Enter Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.mrp == null || this.mrp == undefined || this.mrp == '' || this.mrp == 0){
      this.toastrService.warning("Please Enter MRP!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if ( this.price == null || this.price == undefined || this.price == ''){
      this.toastrService.warning("Please Enter Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if ( this.discount == null || this.discount == undefined || this.discount == '' || this.discount == 0){
      this.toastrService.warning("Please Enter Discount!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.startDate == null || this.startDate == undefined || this.startDate == ''){
      this.toastrService.warning("Please Select Start Date!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.startDate > this.endDate) {
      this.toastrService.warning('End date should be greater than Start date!', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.endDate == null || this.endDate == undefined || this.endDate == ''){
      this.toastrService.warning("Please Select End Date!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.quantity >= this.stockQuantity ){
      this.toastrService.warning("Stock Not Available!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.ProductService.insertNewDealProduct(this.category, this.sub_category, this.selectedProdcut, this.price, this.mrp, this.quantity, this.discount, this.startDate, this.endDate).subscribe((res: any) =>{
     console.log('ressssssssss', res);
      if(res.rows.length > 0){
        $('#add-new-deal').modal('hide');
        this.toastrService.success('New deal product successfully added.', '', {timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
        this.bindNgxDataGridCurrent(this.page, 1);
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass()
      
    }
    $('li').removeClasss("is-active");
        //$(this).addClass("is-active");
    //this.bindNgxDataGridCurrent(this.page, 1);
    this.reset();   
    
    })
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }

  onUpdate(){
    this.category = this.category?.trim();
    if (this.category == null || this.category == undefined || this.category == '') {
      this.toastrService.warning("Please Select Category!", '',{ timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.sub_category= this.sub_category?.trim();
    if (this.sub_category == null || this.sub_category == undefined || this.sub_category == ''){
      this.toastrService.warning("Please Select Sub Category!", '',{ timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
   // this.selectedProdcut= this.selectedProdcut?.trim();
    if (this.selectedProdcut == null || this.selectedProdcut == undefined || this.selectedProdcut == ''){
      this.toastrService.warning("Please Select Product!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.quantity== null || this.quantity == undefined || this.quantity == 0 ){
      this.toastrService.warning("Please Enter Quantity!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.mrp == null || this.mrp == undefined || this.mrp == '' || this.mrp == 0){
      this.toastrService.warning("Please Enter MRP!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if ( this.price == null || this.price == undefined || this.price == ''){
      this.toastrService.warning("Please Enter Price!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if ( this.discount == null || this.discount == undefined || this.discount == '' || this.discount == 0){
      this.toastrService.warning("Please Enter Discount!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    
    if (this.startDate == null || this.startDate == undefined || this.startDate == ''){
      this.toastrService.warning("Please Select Start Date!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    if (this.startDate > this.endDate){
      this.toastrService.warning("End date should be greater than Start date!", '',{ timeOut: this.MassageOutTime, positionClass: "toast-top-center"})
      return false;
    }
    if (this.endDate == null || this.endDate == undefined || this.endDate == ''){
      this.toastrService.warning("Please Select End Date!", '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
      return false;
    }
    this.ProductService.updateNewDealProduct(this.newdealid,this.category, this.sub_category, this.selectedProdcut, this.price, this.mrp, this.quantity, this.discount, this.startDate, this.endDate).subscribe((res: any) =>{
     //console.log('ressssssssss', res);
      if(res.rows.length > 0){
        $('#add-new-deal').modal('hide');
        this.toastrService.success('New deal product successfully updated.', '', { timeOut: this.MassageOutTime, positionClass: "toast-top-center" })
      $(".overlay-container").addClass("addcontainerdisable");
      this.removeClass();
     
    }
    this.bindNgxDataGridCurrent(this.page, 1);
    this.reset();
    })
  }

reset(){
  
   this.category = this.sub_category = this.selectedProdcut = this.price = this.mrp = this.quantity = this.discount =  this.startDate = this.endDate = '';
   this.subCategoryList = [];
   this.allProductList=[];
   this.minOrderQty = 0
   //8888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888this.bindNgxDataGridHistory(this.page, 1);
   
}
onEdit(row) {
  // this.isShow = false;
 // debugger
  
  this.isEdit = false;
  row = row.rowData;
  this.allProductList.push({
    product_id:Number(row.product_id),
    product_name:row.product_name
  })
  console.log("rowatata", row)
  this.category = row.product_category_id;
  this.sub_category = row.product_sub_category_id;
  this.selectedProdcut = Number(row.product_id);
  this.price = row.selling_price;
  this.newdealid = row.id;
  this.mrp = row.product_mrp;
  this.quantity = row.product_deal_quantity;
  this.discount = row.discount_percent;
  this.startDate = row.start_date;
  this.startDate = this.startDate.split('T')[0];
  this.endDate = row.end_date;
  this.endDate = this.endDate.split('T')[0];
  //console.log("editt", row);
  this.getSubCategory();
 // this.getProducts(); 
   // this.allProductList.push({"product_id": this.selectedProdcut, "product_name":row.product_name})
  
}

  getSubCategory() {
    this.ProductService.getSubCategory(this.category).subscribe(result => {
      let res: any = result;
      if (res.length > 0) {
        this.subCategoryList = res;
      } else {
        this.subCategoryList = [];
      }
    })
  }
 
  onDelete(dealId){
    dealId = dealId.rowData;
     //console.log('banner delete', dealId.id);
     Swal.fire({
      title: `Are you sure you want to delete this Deal ?`,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ProductService.deletenewdealrowdata(dealId.id).subscribe((res) => {
            Swal.fire('Deal Deleted successfully!', '', 'success');
            if(this.current)
        this.bindNgxDataGridCurrent(this.page, 1);
        if(this.future)
        this.bindNgxDataGridFuture(this.page, 1);
        if(this.history)
        this.bindNgxDataGridHistory(this.page, 1);

          }
          );
      }
    });
    // this.ProductService.deletenewdealrowdata(dealId.id).subscribe((res) => {
    //   this.toastrService.info('New Deal Data deleted successfully.', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
    //   $(".overlay-container").addClass("addcontainerdisable");
    //   if(this.current)
    //   this.bindNgxDataGridCurrent(this.page, 1);
    //   if(this.future)
    //   this.bindNgxDataGridFuture(this.page, 1);
    //   if(this.history)
    //   this.bindNgxDataGridHistory(this.page, 1);
    // });
  }

  saveId(cell) {
    this.adminRemarks = ''
    this.blk_suspend = ''
    this.suspended = cell.rowData.seller_status;
    this.currentSellerId = cell.rowData.seller_id
    this.currentSellerName = cell.rowData.seller_name
  }
  // getDetails(row){
  //   localStorage.removeItem('approve_reject');
  //   localStorage.setItem("selected_seller_id",row.rowData.seller_id);
  //   this.router.navigateByUrl('/seller-profile');
  // }
  productListing(id){
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem("sellerName", id.rowData.seller_name);
    this.router.navigateByUrl('/seller-wise-product'  + '?' + localStorage.getItem('tempToken') );
  }
  orderListing(id){
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem("sellerName", id.rowData.seller_name);
    this.router.navigateByUrl('/manage-order'  + '?' + localStorage.getItem('tempToken') );
  }
  paymentListing(id){
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
   // this.router.navigateByUrl('/seller-wise-product');
  }

  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }
  onProductView(data) {
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    $('#scroll').animate({ scrollTop: 0 }, 'slow');


    this.isView = true;
    this.ProductService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal").modal('show');
    });

  }
}

