<div class="" id="page-content-wrapper" class="">
<div class="container-fluid contr pt-2 px-5 pb-0 backgroundlogin vh-95"> 
    <header class="head">
        <!-- <img src="../../assets/images/logo.png" class="img-fluid width100px"> -->
 
    </header>
    <section class="p-md-5">

        <!-- <div class="login_txt">
            <a href="javascript:void(0)" class="btn btn-sm btn-primary mr-2 "  id="btnbankit" (click)="changeRouter(1)"> NCRB Login </a>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary mr-2" id="btnsbi" (click)="changeRouter(2)"> NIC Login </a>
            <a href="javascript:void(0)" class="btn btn-sm btn-primary" id="btncomp" (click)="changeRouter(3)"> Corporate Login </a>
        </div> -->

        <!---NCRB LOgin start-->
<div class="row" id="ncrbloginb">      
    <div class="col-lg-5 col-sm-12 col-md-8 card card-container logincontainer ">
        <img id="profile-img" class="profile-img-card py-3 img-fluid" src='../../assets/images/login-logo.png' > 
        <!-- <span  style="color: #000000;"><h6><strong>Scheme Management System</strong> </h6></span> -->
        <div class="col-sm-12 col-lg-12 p-0" id="signinscreen">
            <!-- <p id="profile-name" class="profile-name-card pb-3 text-white"> 
                <span *ngIf="actorType == '1'"> NCRB <span>Login</span> </span>          
                <span *ngIf="actorType == '2'"> Corporate & MSME <span>Login</span> </span>
                <span *ngIf="actorType == '4'"> NIC <span>Login</span> </span>
            </p>
             -->
            <!-- <span class="font8" style="color: red">Hi patel</span> -->
        <!-- <h6 class="text-center color-white"> New Opportunity Consultancy Pvt. Ltd </h6> -->
        <!-- <form class="form-signin"> -->
            <div class="card-body">
            <div class="mb-2 text-left">
                <label class="text-dark pb-1 font9">{{labelObj ? (isHindi ? labelObj.usrname.labelHn : labelObj.usrname.labelEn):"User Name"}} </label>
                <input type="username" id="inputEmail" name="inputEmail" class="form-control cstminput" placeholder="User Name"   [(ngModel)]="user_name"  required autofocus autocomplete="off">
              <!-- <input  class="form-control" id="uname" name="uname" required autocomplete="off" type="text" [(ngModel)]="username" placeholder="{{labelObj ? (isHindi ? labelObj.usrname.labelHn : labelObj.usrname.labelEn):'User Name'}}"> -->
                <span id="msgReqUserName" style="color:red; display: none" class="absolutecls1 font_Weight"> {{labelObj ? (isHindi ? labelObj.reqUserName.labelHn : labelObj.reqUserName.labelEn):"User Name is required."}}</span>
              </div>
              <div class="mb-3 text-left">
                <label class="text-dark pb-1 font9">Password</label>
                <input type="password" id="inputPassword" name="inputPassword" class="form-control cstminput" placeholder="Password" [(ngModel)]="password"  required autocomplete="off">
                <!-- <input class="form-control" id="passwrd" name="passwrd" type="password" required autocomplete="new-password" [(ngModel)]="password" placeholder="{{labelObj ? (isHindi ? labelObj.pass.labelHn : labelObj.pass.labelEn):'Password'}}"> -->
                <span id="msgReqPassword" style="color:red; display: none" class="absolutecls font_Weight"> {{labelObj ? (isHindi ? labelObj.reqPassword.labelHn : labelObj.reqPassword.labelEn):"Password is required."}}</span>                               
              </div>
            <div class="mb-3 text-left row m-0 captchadesign">
                <span [innerHTML]="captchasrc" class="captcha"></span>
                <!-- <span class="cpcha">{{capchaCode}}</span> -->
                <input type="text" onpaste="return false" id="txtInput" autocomplete="off" 
                    class="form-control wid40" [(ngModel)]="captchaVal" />
                <button style="height: 30px; width: 12%" id="refresh" (click)="getCaptcha();"
                    class="btn btn-sm bg-megenta shadow-none btnrefr ml-1"><i class="fa fa-refresh"></i></button>
                <br />
                <span id="msgReqCapcha" style="color:red;" class="absolutecls ml-2 font_Weight">
                </span>

            </div>

            <!-- <div class="mb-3 input-group align-item-center">
                <input type="checkbox" class="mr-2 checkbox_new">
                <label class="font8 text-dark font9">Remember me</label>
                <a href="javasccrip:void(0)" class="text-white pull-right font9 ml-auto" title="forgot password">
                    <span class="text-dark font9">Forgot Password?</span>
                </a>
            </div> -->
            <button class="btn btn-lg bg-megenta btn-block btn-signin mb-2" type="button" id="btnLogin" (click)="loginNew1()">{{labelObj ? (isHindi ? labelObj.login.labelHn : labelObj.login.labelEn):"Login"}}</button>
       <!-- <div class="text-center">
           <a href="javascript:void(0)" class="websitego"><span class="font9">Go to website</span></a>
       </div> -->
       <h6 class="text-center mt-1 mb-0" style="color: red">{{err_msg}}</h6>
        </div>
        <!-- <div class="text-white text-center" *ngIf="actorType == '2'">
            <label class="text-whhite">Don't have an account? <a href="javascript:void(0)" (click)="signupscrn()"><span class="websitego">Sign up here</span></a></label>
        </div> -->
        <!-- </form> -->
        <!-- <a href="#" class="forgot-password text-center">
            Forgot password?
        </a> -->
        <!-- <span class="font8" style="color: red">{{err_msg}}</span> -->
    </div>

        <!-- <div class="col-sm-12 col-lg-12 p-0" id="signupscreen">
            <p id="profile-name" class="profile-name-card pb-3 text-white"> -->
                <!-- <span class="font7" style="color: #b3b3b3;">Version 1.0.0.0</span><br>
                <span class="font7" style="color: #b3b3b3;">Copyright © Humanitics Dimensions Software Pvt. Ltd., 2021.
                    </span> -->
                    <!-- Sign up -->
                    <!-- <span>Login</span> -->
            <!-- </p> -->
            <!-- <form class="form-signin"> -->
               
          
            <!-- <span class="font8" style="color: red">abc</span> -->
        <!-- </div> -->
    <!-- </div> -->
    <!-- /card-container -->

      

<!-- </div> -->
 <!---NCRB LOgin end-->

</div>
</div>

<!-- /container -->
</section>
</div>
<!-- <app-footer></app-footer> -->
</div>
