import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
//import { OwlOptions } from 'ngx-owl-carousel-o';
// import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/_services/login.service';
import { CommonFunctionService } from '../../_services/common-function.service';
import { FormService } from '../../_services/form.service';
import { ProductService } from '../../_services/product.service';
import { AppSettings } from '../../_services/AppSetting';
declare var $: any;
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {
  @Input() isView: boolean = false;
  @Input() view_item_id: any;
  @Input() customerid:any;
  @Input() role_id: any
  productId: any;
  productList: any;
  
  productSpecifications: any[] = [];
  businessCard: any[] = [];
  lable: any;
  value: any;
  keyFeatures: any= [];
  key: any;
  file: any;
  uploadedGalaryFiles: any = [];
  product_name: any;
  sku: any;
  price: any;
  mrp: any;
  quantity: any;
  stock_status: any;
  this_item_has_weight: any;
  weigth: any;
  category: any;
  first: any;
  second: any;
  third: any;
  four: any;
  five: any;
  product_discription: any;
  formData: FormData;
  categoryList: any;
  discountPer: any;
  temp: any;
  changedImage: any[] = [];
  country_id: any;
  items: any = [];
  productType: any;
  groupProdDetail: any;
  groupedProducts: any = [];
  tempGrpArray: any = [];
  total: number;
  groupProdImages: any;
  prodImage: any;
  totalPrice: any[];
  addItems: number;
  tPrice: any = 0;
  setState: boolean = true
  groupedItem: any = 0;
  temparray: any;
  tempArrayForConf: any[] = [];
  selected_color: any;
  selected_size: any;
  item: any;
  productColor: any;
  productSize: any;
  grpArr: any[] = [];
  main_img: any;
  totalItem: any;
  features: any;
  artwork: any;
  extra_features: any = [];
  set_quantity: any;
  card_details: any = [];
  sellerName: any;
  max_qty: any;
  min_qty: any;
  bundledPrice: any;
  bundledPriceList: any[] = [];
  productPricing: any;
  fPrice: any;

  constructor(
    
    private productService: ProductService,
    private formService: FormService,
    private loginService : LoginService,
    private toastrService: ToastrService,
    public commonFunctionService: CommonFunctionService,
    private router: Router
  ) {
    // console.log('isview', this.view_item_id)
  }
  ngAfterViewInit(): void {}
  //   customOptions: OwlOptions = {
  //     loop: true,
  //     margin: 20,
  //     dots: true,
  //     responsive: {
  //       0: {
  //         items: 4
  //       },
  //       400: {
  //         items: 4
  //       },
  //       600: {
  //         items: 4
  //       },
  //       1000: {
  //         items: 4
  //       }
  //     },
  //     // nav: true
  //   }

  // customOptions: OwlOptions = {
  //   loop: true,
  //   dots: false,
  //   autoplay: true,
  //   navSpeed: 700,

  //   navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right></i>"'],
  //   lazyLoad: true,
  //   responsive: {
  //     0: {
  //       items: 4
  //     },
  //     400: {
  //       items: 4
  //     },
  //     600: {
  //       items: 4
  //     },
  //     1000: {
  //       items: 4
  //     }
  //   },
  //   nav: true
  // }
  //activeSlides: SlidesOutputData;

  slidesStore: any[];

  // getPassedData(data: SlidesOutputData) {
  //   this.activeSlides = data;
  //   console.log(this.activeSlides);
  // }
  ngOnInit(): void {
    // console.log('check pro ', this.view_item_id)
   this.view_item_id = this.view_item_id ? this.view_item_id : sessionStorage.getItem('view-item') ? sessionStorage.getItem('view-item') : this.view_item_id
    if (this.view_item_id != null && this.view_item_id != undefined) {
      // console.log('check id after change', this.view_item_id)
      this.getSelectedProduct(this.view_item_id);
      this.getGroupedProduct()
    }

    // if(localStorage.getItem('view-item')!=null){
    //   this.getSelectedProduct(localStorage.getItem('view-item'));
    // }

    //======================= Images ===================
    // (function ($) {
    //   $.fn.zoomImage = function (paras) {
    //     var defaultParas = {
    //       layerW: 100,
    //       layerH: 100,
    //       layerOpacity: 0.2,
    //       layerBgc: '#000',
    //       showPanelW: 500,
    //       showPanelH: 500,
    //       marginL: 5,
    //       marginT: 0,
    //     };

    //     paras = $.extend({}, defaultParas, paras);

    //     $(this).each(function () {
    //       var self = $(this).css({ position: 'relative' });
    //       var selfOffset = self.offset();
    //       var imageW = self.width();
    //       var imageH = self.height();

    //       self.find('img').css({
    //         width: '100%',
    //         height: '100%',
    //       });

    //       var wTimes = paras.showPanelW / paras.layerW;

    //       var hTimes = paras.showPanelH / paras.layerH;

    //       var img = $('<img>')
    //         .attr('src', self.attr('href'))
    //         .css({
    //           position: 'absolute',
    //           left: '0',
    //           top: '0',
    //           width: imageW * wTimes,
    //           height: imageH * hTimes,
    //         })
    //         .attr('id', 'big-img');

    //       var layer = $('<div>').css({
    //         display: 'none',
    //         position: 'absolute',
    //         left: '0',
    //         top: '0',
    //         backgroundColor: paras.layerBgc,
    //         width: paras.layerW,
    //         height: paras.layerH,
    //         opacity: paras.layerOpacity,
    //         border: '1px solid #ccc',
    //         cursor: 'crosshair',
    //       });

    //       var showPanel = $('<div>')
    //         .css({
    //           display: 'none',
    //           position: 'absolute',
    //           overflow: 'hidden',
    //           left: imageW + paras.marginL,
    //           top: paras.marginT,
    //           width: paras.showPanelW,
    //           height: paras.showPanelH,
    //         })
    //         .append(img);

    //       self.append(layer).append(showPanel);

    //       self
    //         .on('mousemove', function (e) {
    //           var x = e.pageX - selfOffset.left;
    //           var y = e.pageY - selfOffset.top;

    //           if (x <= paras.layerW / 2) {
    //             x = 0;
    //           } else if (x >= imageW - paras.layerW / 2) {
    //             x = imageW - paras.layerW;
    //           } else {
    //             x = x - paras.layerW / 2;
    //           }

    //           if (y < paras.layerH / 2) {
    //             y = 0;
    //           } else if (y >= imageH - paras.layerH / 2) {
    //             y = imageH - paras.layerH;
    //           } else {
    //             y = y - paras.layerH / 2;
    //           }

    //           layer.css({
    //             left: x,
    //             top: y,
    //           });

    //           img.css({
    //             left: -x * wTimes,
    //             top: -y * hTimes,
    //           });
    //         })
    //         .on('mouseenter', function () {
    //           layer.show();
    //           showPanel.show();
    //         })
    //         .on('mouseleave', function () {
    //           layer.hide();
    //           showPanel.hide();
    //         });
    //     });
    //   };
    // })($);
    
    // ========================================================================
    // $('.show').zoomImage();
    $('.show-small-img:first-of-type').css({
      border: 'solid 1px #951b25',
      padding: '2px',
    });
    $('.show-small-img:first-of-type')
      .attr('alt', 'now')
      .siblings()
      .removeAttr('alt');
    $('.show-small-img').click(function () {
      $('#show-img').attr('src', $(this).attr('src'));
      $('#big-img').attr('src', $(this).attr('src'));
      $(this).attr('alt', 'now').siblings().removeAttr('alt');
      $(this)
        .css({ border: 'solid 1px #951b25', padding: '2px' })
        .siblings()
        .css({ border: 'none', padding: '0' });
      if ($('#small-img-roll').children().length > 4) {
        if (
          $(this).index() >= 3 &&
          $(this).index() < $('#small-img-roll').children().length - 1
        ) {
          $('#small-img-roll').css('left', -($(this).index() - 2) * 100 + 'px');
        } else if (
          $(this).index() ==
          $('#small-img-roll').children().length - 1
        ) {
          $('#small-img-roll').css(
            'left',
            -($('#small-img-roll').children().length - 4) * 76 + 'px'
          );
        } else {
          $('#small-img-roll').css('left', '0');
        }
      }
    });

    $('#next-img').click(function () {
      $('#show-img').attr(
        'src',
        $(".show-small-img[alt='now']").next().attr('src')
      );
      $('#big-img').attr(
        'src',
        $(".show-small-img[alt='now']").next().attr('src')
      );
      $(".show-small-img[alt='now']")
        .next()
        .css({ border: 'solid 1px #951b25', padding: '2px' })
        .siblings()
        .css({ border: 'none', padding: '0' });
      $(".show-small-img[alt='now']")
        .next()
        .attr('alt', 'now')
        .siblings()
        .removeAttr('alt');
      if ($('#small-img-roll').children().length > 4) {
        if (
          $(".show-small-img[alt='now']").index() >= 3 &&
          $(".show-small-img[alt='now']").index() <
            $('#small-img-roll').children().length - 1
        ) {
          $('#small-img-roll').css(
            'left',
            -($(".show-small-img[alt='now']").index() - 2) * 76 + 'px'
          );
        } else if (
          $(".show-small-img[alt='now']").index() ==
          $('#small-img-roll').children().length - 1
        ) {
          $('#small-img-roll').css(
            'left',
            -($('#small-img-roll').children().length - 4) * 76 + 'px'
          );
        } else {
          $('#small-img-roll').css('left', '0');
        }
      }
    });

    $('#prev-img').click(function () {
      $('#show-img').attr(
        'src',
        $(".show-small-img[alt='now']").prev().attr('src')
      );
      $('#big-img').attr(
        'src',
        $(".show-small-img[alt='now']").prev().attr('src')
      );
      $(".show-small-img[alt='now']")
        .prev()
        .css({ border: 'solid 1px #951b25', padding: '2px' })
        .siblings()
        .css({ border: 'none', padding: '0' });
      $(".show-small-img[alt='now']")
        .prev()
        .attr('alt', 'now')
        .siblings()
        .removeAttr('alt');
      if ($('#small-img-roll').children().length > 4) {
        if (
          $(".show-small-img[alt='now']").index() >= 3 &&
          $(".show-small-img[alt='now']").index() <
            $('#small-img-roll').children().length - 1
        ) {
          $('#small-img-roll').css(
            'left',
            -($(".show-small-img[alt='now']").index() - 2) * 76 + 'px'
          );
        } else if (
          $(".show-small-img[alt='now']").index() ==
          $('#small-img-roll').children().length - 1
        ) {
          $('#small-img-roll').css(
            'left',
            -($('#small-img-roll').children().length - 4) * 76 + 'px'
          );
        } else {
          $('#small-img-roll').css('left', '0');
        }
      }
    });

 
    $('.btn-number').click(function (e) {
      e.preventDefault();

      var fieldName = $(this).attr('data-field');
      var type = $(this).attr('data-type');
      var input = $("input[name='" + fieldName + "']");
      var currentVal = parseInt(input.val());
      if (!isNaN(currentVal)) {
        if (type == 'minus') {
          if (currentVal > input.attr('min')) {
            input.val(currentVal - 1).change();
          }
          if (parseInt(input.val()) == input.attr('min')) {
            $(this).attr('disabled', true);
          }
        } else if (type == 'plus') {
          if (currentVal < input.attr('max')) {
            input.val(currentVal + 1).change();
          }
          if (parseInt(input.val()) == input.attr('max')) {
            $(this).attr('disabled', true);
          }
        }
      } else {
        input.val(0);
      }
    });
    $('.input-number').focusin(function () {
      $(this).data('oldValue', $(this).val());
    });
    $('.input-number').change(function () {
      var minValue = parseInt($(this).attr('min'));
      var maxValue = parseInt($(this).attr('max'));
      var valueCurrent = parseInt($(this).val());

      var name = $(this).attr('name');
      if (valueCurrent >= minValue) {
        $(
          ".btn-number[data-type='minus'][data-field='" + name + "']"
        ).removeAttr('disabled');
      } else {
        alert('Sorry, the minimum value was reached');
        $(this).val($(this).data('oldValue'));
      }
      if (valueCurrent <= maxValue) {
        $(
          ".btn-number[data-type='plus'][data-field='" + name + "']"
        ).removeAttr('disabled');
      } else {
        alert('Sorry, the maximum value was reached');
        $(this).val($(this).data('oldValue'));
      }
    });
    $('.input-number').keydown(function (e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if (
        $.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (
        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }
    });

       var owl = $('.owl-carousel');

    // owl.owlCarousel({
    //   loop: true,
    //   nav: true,
    //   margin: 10,
    //   responsive: {
    //     0: {
    //       items: 1,
    //     },
    //     600: {
    //       items: 3,
    //     },
    //     960: {
    //       items: 5,
    //     },
    //     1200: {
    //       items: 6,
    //     },
    //   },
    // });
    $('.owl-carousel').owlCarousel({
      loop:true,
      margin:10,
      nav:true,
      responsive:{
          0:{
              items:1
          },
          600:{
              items:3
          },
          1000:{
              items:5
          }
      }
  })
    owl.on('mousewheel', '.owl-stage', function (e) {
      if (e.deltaY > 0) {
        owl.trigger('next.owl');
      } else {
        owl.trigger('prev.owl');
      }
      e.preventDefault();
    });


$('#carouselExample').on('slide.bs.carousel', function (e) {

  var $e = $(e.relatedTarget);
  var idx = $e.index();
  var itemsPerSlide = 4;
  var totalItems = $('.carousel-item').length;
  
  if (idx >= totalItems-(itemsPerSlide-1)) {
      var it = itemsPerSlide - (totalItems - idx);
      for (var i=0; i<it; i++) {
          //append slides to end
          if (e.direction=="") {
              $('.carousel-item').eq(i).appendTo('.carousel-inner');
          }
        //   if (e.direction=="right") {
        //     $('.carousel-item').eq(i).appendTo('.carousel-inner');
        // }
          else {
              $('.carousel-item').eq(i).appendTo('.carousel-inner');
          }
      }
  }
});


// $('#carouselExample').carousel({ 
//               // interval: 2000
//       });

  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isView) {
      // this.isView = changes['isView'].currentValue;
      //console.log('check for isvalue', this.isView)
    }
    if (this.view_item_id) {
      this.view_item_id = changes['view_item_id'].currentValue;
      this.productId = this.view_item_id;
      this.getSelectedProduct(this.view_item_id);
    }
  }
  getSelectedProduct(id) {
      this.productService.getSelectedProductAdminSide(id).subscribe((res) => {
      let bundlePrice = res[4].rows ? res[4].rows : [];
      this.bundledPriceList = bundlePrice ? bundlePrice : [];
      this.productSpecifications = res[3].rows;
      for(let obj of this.productSpecifications){
        if(obj.is_custom){
          obj.value_name = obj.label_valuelist
        }else{
          obj.specificationLabelValue = JSON.parse(obj.specification_label_value_name_list)
          obj.value_name = obj.specificationLabelValue.filter(x=>x.product_specification_label_value_id == obj.specification_label_value_id)[0].lebal_value
          
        }
      }
      this.productType = res[0].rows[0].product_type
        this.keyFeatures = JSON.parse(res[0].rows[0].key_features);
        this.product_name = res[0].rows[0].product_name;
        this.sku = res[0].rows[0].suk;
        this.price = res[0].rows[0].price;
        this.mrp = res[0].rows[0].mrp;
        this.quantity = res[0].rows[0].quantity;
        this.stock_status = res[0].rows[0].stock_status;
        this.this_item_has_weight = res[0].rows[0].this_item_has_weight;
        this.weigth = res[0].rows[0].weight;
        this.category = res[0].rows[0].category;
        this.product_discription = res[0].rows[0].product_discription;
        this.mrp = res[0].rows[0].mrp;
        this.country_id = res[0].rows[0].country;
        this.uploadedGalaryFiles = [];
        console.log("ressss", res)
        if (res[1].rows.length > 0) {
          let temp = res[1].rows;
          for (let i = 0; i < temp.length; i++) {
            this.uploadedGalaryFiles.push({
              id: i + 1,
              file_path: temp[i].document_path,
            });
            this.main_img = this.uploadedGalaryFiles[0].file_path

          }
        }
       
        this.temp = this.uploadedGalaryFiles;
        this.changedImage = this.uploadedGalaryFiles[0]?.file_path;
        this.first = this.uploadedGalaryFiles[0]?.file_path;
        this.second = this.uploadedGalaryFiles[1]?.file_path;
        this.third = this.uploadedGalaryFiles[2]?.file_path;
        this.four = this.uploadedGalaryFiles[3]?.file_path;
        this.five = this.uploadedGalaryFiles[4]?.file_path;
         //console.log('check images', this.uploadedGalaryFiles);
        this.discountPer = (((((this.mrp ? this.mrp : 0) - (this.price ? this.price : 0)) * 100) / (this.mrp ? this.mrp : 0)).toFixed(2))
      //  console.log("disount", this.discountPer)
        this.item = {
          "sellerName": localStorage.getItem('sellerName'), "sellerId": localStorage.getItem('selected_seller_id'), "weigth": this.weigth, "stock_status": this.stock_status, "product_discription": this.product_discription, "productSpecifications": this.productSpecifications, "keyFeatures": this.keyFeatures,
          "product_id": res[0].rows[0].product_id, "product_name": this.product_name, "sku": this.sku, "base_price": this.price, "price": this.price, "mrp": this.mrp, "category": this.category, "qtytotal": 1, 'image_path': this.uploadedGalaryFiles[0] ? this.uploadedGalaryFiles[0].file_path : null
        }
        this.grpArr.push(this.item)
        this.total = this.price;
        // console.log('now checking this producttype', this.productType)
        if (this.productType == 199) {
          this.getGroupedProduct();
        }
        if (this.productType == 198) {
          this.price = res[2].rows[0].price;
        this.selected_color=res[2].rows[0].attribute_value1
        this.selected_size=res[2].rows[0].attribute_value2
        this.getProductConfiguration();        
        }
        if(this.productType == 208){
          this.price = res[2].rows[0].price;
          this.selected_color=res[2].rows[0].attribute_value1
          this.selected_size=res[2].rows[0].attribute_value2
          this.getProductConfiguration();
          this.getProductCustomization()
        }
        if(this.productType == 209){
          this.getBusinessCardDetails();
        }
        
      });
  
  }

  changeImage(img) {
    this.changedImage = img.file_path;
  }

  getProductCustomization(){
    this.productService.getProductCustomization(this.view_item_id).subscribe((res:any) =>{
      
      console.log('check response for customization', res);
      this.artwork = JSON.parse(res.rows[0].artwork_lable)
      this.features = res.rows[0].feature
      this.fPrice = res.rows[0].price
      //console.log('check response for customization', this.artwork, this.features);

    })
  }

  getBusinessCardDetails(){
    this.productService.getBusinessCardDetails(this.view_item_id).subscribe((res:any) => {
      //console.log("business", res)
      this.extra_features = JSON.parse(res.rows[0].extra_features)
      this.card_details = JSON.parse(res.rows[0].card_details)
      this.set_quantity = res.rows[0].set_quantity;
      this.features = res.rows[0].feature
      this.fPrice = res.rows[0].price

    })
  }
  removeClass() {
    setTimeout(() => {
      $(".overlay-container").removeClass("addcontainerdisable");
    }, 1000);
  }
  onColorSelection(color){
    this.selected_color=color;
    if((this.selected_size!=null || this.selected_size!=undefined) && (this.selected_color!=null || this.selected_color!=undefined)){
      this.productService.isConfigAvailble(this.view_item_id, this.selected_color, this.selected_size).subscribe((res:any) => {
        if (res.rows.length == 0){
          // this.toastrService.warning('Selected Combination Not Available  ', '', { timeOut: 3000, positionClass: 'toast-top-center' })
          // $(".overlay-container").addClass("addcontainerdisable");
          // this.removeClass();
          // return false;
        }
        else{
          this.price=res.rows[0].price;
          this.item.base_price=res.rows[0].price;
          this.item.price=res.rows[0].price;
        }
      })
    }
  }

  onSizeSelection(size){
    this.selected_size=size;
    if((this.selected_size!=null || this.selected_size!=undefined) && (this.selected_color!=null || this.selected_color!=undefined)){
      this.productService.isConfigAvailble(this.view_item_id, this.selected_color, this.selected_size).subscribe((res:any) => {
        if (res.rows.length == 0) {
          // this.toastrService.warning('Selected Combination Not Available  ', '', { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: true, closeButton: true, extendedTimeOut: 0 })
          // $(".overlay-container").addClass("addcontainerdisable");
          // return false;
        }
        else{
          this.price=res.rows[0].price;
          this.item.base_price=res.rows[0].price;
          this.item.price=res.rows[0].price;
        }
      })
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('view-item');
  }


  viewProducts(pro){
    // console.log('want to check pro', pro)
    // sessionStorage.setItem('view-item', pro.product_id)
    
    // window.open('product-details', '_blank')
     let siteUrl = AppSettings.getSiteUrl;
    //let siteUrl = 'http://14.102.15.89:5200';
    let data = JSON.stringify('selected_prod_id=' + pro.product_id);
    let encriptUrl = this.loginService.encriptDataUrl(data)
    window.open(siteUrl + '/product-details' + '?' + encriptUrl, '_blank');
    
  }
 

   getGroupedProduct(){
    this.productService.getAllGroupedProduct(this.productId).subscribe(res => {
      this.groupProdDetail = res[0].rows;
      this.groupedItem = this.groupProdDetail.length
      this.totalItem = this.groupedItem + 1
      this.tPrice = 0;
      for (let p of this.groupProdDetail) {
        this.tPrice = Number(p.price) + Number(this.tPrice)
      }
      this.total = Number(this.price) + Number(this.tPrice)
      this.groupProdImages = res[1].rows;
      // this.prodImage = this.groupProdImages[0].document_path
      let imgs = [], item = {};
      this.groupedProducts = [];
      for (let obj of this.groupProdDetail) {
        imgs = this.groupProdImages.filter(x => x.product_id == obj.product_id);
        item = {
          "sellerName": this.sellerName, "sellerId": obj.seller_id, "weigth": obj.weigth, "stock_status": obj.stock_status, "product_discription": obj.product_discription, "productSpecifications": obj.product_specifications, "keyFeatures": obj.key_features,
          "product_id": obj.product_id, "product_name": obj.product_name, "sku": obj.suk, "base_price": obj.price, "price": obj.price,"mrp": obj.mrp, "category": obj.category, "qtytotal": 1, 'image_path': imgs[0].document_path
        }
        this.groupedProducts.push(item);
        this.grpArr.push(item);
        this.tempGrpArray = this.grpArr;
      }

    })
   }

   getCheckedProduct(event,prodId,price){
    this.totalPrice=[]
     if(event.target.checked == true){
     this.totalPrice.push(Number(price))
     this.groupedItem++
    //  console.log('333333333333',this.groupedItem)
     this.addItems = this.totalPrice.length
     for(let p of this.totalPrice){
       (this.tPrice) = Number(p) + Number(this.tPrice)
     }
     
     this.total = Number(this.price) + Number(this.tPrice)
    }else{
      this.groupedItem--
      // console.log('4444444444444',this.groupedItem)
      this.totalPrice=[]
      this.totalPrice.push(Number(price))
      this.tPrice = this.tPrice - price
      this.total = Number(this.price) + Number(this.tPrice)
    }
 
  }


  getProductConfiguration() {
    this.productService.getProductConfiguration(this.view_item_id).subscribe(res => {
      // console.log('resssssssssssssssssssss', res)
      this.productColor = res[0] ? res[0].rows : [];
      this.productSize = res[1] ? res[1].rows : [];
      this.productSize = this.productSize.filter(x => x.attribute_value2);
        this.productColor = this.productColor.filter(x => x.attribute_value1);
        console.log("att",this.productColor )
    })
  }

  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
