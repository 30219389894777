<!--Trial Starts Here-->
<div class="accordion myorders" id="accordionExample">
  <div class="card shadow-none border-0" *ngIf="orderSecDetails.length>0">
    <div class="border mb-3" *ngFor="let obj1 of orderPrimaryDetail;let j=index;">
      <div class="card-header px-3 py-2" id="headingOne">
        <h2 class="mb-0">
          <button class="p-0 btn btn-link btn-block text-left text-dark font-weight-bold text-decoration-none" type="button" data-toggle="collapse"
            data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" [attr.data-target]="'#collapseOne' + j">
            Order Number : {{obj1.order_number}} | Placed on : {{obj1.createddate | date:'dd/MM/yyyy'}}
            <span class="float-right">
              <i class="fa fa-caret-up" aria-hidden="true"></i>
            </span>
          </button>

        </h2>

      </div>

      <div id="collapseOne{{j}}" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" *ngFor="let obj of obj1.orderdetails; index as i">
        <div class="card-body">
          <div class="col-sm-12">
            <div class="row mb-3 border-bottom">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-lg-3 col-5 col-md-3">
                    <div class="text-center orderimg mb-2" >
                      <img src="../../..{{obj.product_image_path}}" alt="img" class="img-fluid wid100px">
                    </div>
                    <div class="orderimg text-center py-2">
                      <h6 class="mb-0">STATUS </h6>
                      <h6 class="font600 mb-0" [ngClass]="{'text-warning': (obj.order_status != 193 && obj.order_status != 191), 'text-success' : obj.order_status == 193,'text-danger' : obj.order_status == 191  }">{{obj.order_status == 187 ? 'Pending' : obj.order_status == 194 ? 'Accepted' : obj.order_status ==
                        192 ? 'Ready To Ship' : obj.order_status == 189 ? 'Shipped': obj.order_status == 193 ? "Delivered"
                        : obj.order_status == 191 ? "Cancelled": '--' }}</h6>
                    </div>
                  </div>
                  <div class="col-lg-9 col-7 col-md-9 px-sm-2 px-0">
                    <h6 class="pt-2" >
                      <a href="javascript:void(0)" class="text-decoration-none font1 text-capitalize">
                        {{obj.product_name}}
                      </a>
                    </h6>
                    <!-- <p class="sku"><i> Ash Gray</i></p> -->
                     <div class="row"> 
                      <ng-container *ngIf="obj.extra_features">
                        <div class="col-sm-6" *ngIf="obj.extra_features.length >0">
                          <p *ngFor="let ext of obj.extra_features; index as i">
                            <span *ngIf="ext.isSelected">{{ext.feature}} - {{rupeeIndian.format(ext.price*obj.total_quantity)}}</span>
                          </p>
                        </div>
                      </ng-container>
                    <ng-container *ngIf="obj.details">
                        <div class="col-sm-6">
                          <!-- <h6>Size Wise Qty</h6>
                          | <span>M : 20</span> | <span>L: 15</span>  | <span>XL: 10</span> -->
                          <p *ngFor="let ext of obj.details; index as i">
                            <span *ngIf="ext.quantity == obj.total_quantity && ext.sku == obj.sku">{{ext.attribute_value2}} - {{ext.attribute_value1}} : {{ext.quantity}}</span>
                          </p>
                        </div>
                      </ng-container>

                    </div> 
                    <div class="row m-0">
                      <p>
                        <span *ngIf="obj.total_quantity != 'null'">Qty.{{obj.total_quantity + ' | '}}</span> |
                        <strong>{{rupeeIndian.format(obj.is_bundle ?(obj.order_price*obj.total_quantity):obj.product_price)}}</strong>
                        <span class="font8" *ngIf="obj.product_type != 209"> (Price Exclusive of GST)</span>
                      </p>
                    </div>
                    <div class="row d-none">
                      <div class="col-sm-12" *ngIf="obj.order_status == 192 || obj.order_status ==193 || obj.order_status ==189 ">
                        <button type="button" data-toggle="modal" data-bs-target="#trackpackage" class="btn btn-secondary rounded-pill px-4 py-2 me-2 mb-2 menu-dis">Track Package</button>
                        <button type="button" class="btn btn-theme rounded-pill px-4 py-2 mx-2 mb-2 menu-dis">Buy Again</button>
                        <button type="button" class="btn btn-secondary rounded-pill px-4 py-2 mb-2 menu-dis">
                          <i class="fa fa-download"></i> Invoice</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-sm-4 mb-2 rightbtngrp">
                <button type="button" [disabled]="!obj.deliverd" data-bs-target="#rating-rv" data-toggle="modal" (click)="writeReview(obj.order_id, obj.order_number, obj.product_id, 'sellerRev', obj.seller_id)"
                  class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100">Leave seller feedback
                </button>
                <button type="button" [disabled]="!obj.deliverd" data-bs-target="#rating-rv" data-toggle="modal" (click)="writeReview(obj.order_id, obj.order_number, obj.product_id, 'deliveryRev')"
                  class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100">Leave delivery feedback
                </button>
                <button type="button" [disabled]="!obj.deliverd" data-bs-target="#rating-rv" data-toggle="modal" (click)="writeReview(obj.order_id, obj.order_number, obj.product_id, 'productRev')"
                  class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100">Write a product review
                </button>
                <button class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100" data-toggle="modal" data-target="#cancelOrder" disabled>
                  Cancel Order
                </button>
              </div> -->
              <div class="col-sm-12 mt-4 py-4 ordertrackingprogress">
                <!-- *ngFor="let ord of obj.orderList" -->
                <div class="row justify-content-between">
                  <div class="order-tracking col" [ngClass]="{completed : obj.placed_order}">
                    <span class="is-complete"></span>
                    <!-- <p><strong>Accepted</strong><br><span>Mon, June 24</span></p> -->
                    <p>
                      <strong>Order Placed</strong>
                      <br>
                      <span *ngIf="obj.placed_order">{{obj.placed_order | date:'dd/MM/yyyy'}}</span>
                    </p>

                  </div>
                  <div class="order-tracking col" [ngClass]="{completed : obj.acc_order}" *ngIf="!obj.cancel_order">
                    <span class="is-complete"></span>
                    <!-- <p><strong>Processed</strong><br><span>Tue, June 25</span></p> -->
                    <p>
                      <strong>Accepted</strong>
                      <br>
                      <span *ngIf="obj.acc_order">{{obj.acc_order | date:'dd/MM/yyyy'}}</span>
                    </p>

                  </div>
                  <div class="order-tracking col" [ngClass]="{completed : obj.readytoship}" *ngIf="!obj.cancel_order">
                    <span class="is-complete"></span>
                    <!-- <p><strong>Packed</strong><br><span>Tue, June 25</span></p> -->
                    <p>
                      <strong>Ready to ship</strong>
                      <br>
                      <span *ngIf="obj.readytoship">{{obj.readytoship | date:'dd/MM/yyyy'}}</span>
                    </p>
                  </div>
                  <div class="order-tracking col" [ngClass]="{completed : obj.shipped}" *ngIf="!obj.cancel_order">
                    <span class="is-complete"></span>
                    <p>
                      <strong>Shipped</strong>
                      <br>
                      <span *ngIf="obj.shipped">{{obj.shipped | date:'dd/MM/yyyy'}}</span>
                    </p>
                  </div>
                  <div class="order-tracking col" [ngClass]="{completed : obj.deliverd}" *ngIf="!obj.cancel_order">
                    <span class="is-complete"></span>
                    <p>
                      <strong>Delivered</strong>
                      <br>
                      <span *ngIf="obj.deliverd">{{obj.deliverd | date:'dd/MM/yyyy'}}</span>
                    </p>
                  </div>
                  <div class="order-tracking col" *ngIf="obj.cancel_order">
                    <span class="is-complete"></span>
                    <p>
                      <strong>Cancelled</strong>
                      <br>
                      <span *ngIf="obj.cancel_order">{{obj.cancel_order | date:'dd/MM/yyyy'}}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 text-center">
                <button class="btn btn-default px-4 py-2 rounded-pill mr-2 mb-2 border">Track ID : {{obj.track_id}}
                </button>
                <button class="btn btn-default px-4 py-2 rounded-pill mb-2 border">Courier By : {{obj.delivery_partner}}
                </button>

              </div>
            </div>
            <!-- <div class="row mb-3">
              <div class="col-sm-9">
                  <div class="row">
                      <div class="col-sm-2 col-4">
                          <div class="text-center orderimg mb-2">
                              <img src="../../../assets/images/product/products06.png" class="img-fluid wid100px ">
                            </div>
                            <div class="orderimg text-center py-2">
                              <h6 class="mb-0">STATUS </h6>
                              <h6 class="font600 mb-0">DELIVERED</h6>
                            </div>
                      </div>
                      <div class="col-sm-10 col-8">
                          <h6 class=" pt-2">
                              <a href="javascript:void(0)" class="text-decoration-none font1">
                                  SPARX Blue Men's Shoes  
                              </a>
                          </h6>
                          <p  class="sku"><i > Ash Blue</i></p>
                          <div class="row">
                              <div class="col-sm-7">
                                  <h6>Size Wise Qty</h6>
                                  <p><span>UK-8 : 10 </span> | <span>UK-9 : 20</span> | <span> UK-10 : 15</span>  | <span> UK-11 : 10</span></p>
                              </div>
                          </div>
                          <div class="row">
                              <p><span>Qty. 55</span> | <strong>₹ 765</strong> <span class="font8">(Price Inclusive of GST)</span></p>
                          </div>
                          <div class="row">
                              <div class="col-sm-12">
                                  <button type="button" class="btn btn-secondary rounded-pill px-4 py-2 me-2">Track package</button>
                                  <button type="button" class="btn btn-theme rounded-pill px-4 py-2 me-2">Buy Again</button>
                                  <button type="button" class="btn btn-secondary rounded-pill px-4 py-2"><i class="fas fa-download"></i> Invoice</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-3 mb-2">
                  <button type="button" class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100">Leave seller feedback</button>
                  <button type="button" class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100">Leave delivery feedback</button>
                  <button type="button" class="btn btn-outline-dark rounded-pill px-4 py-2 mb-2 w-100">Write a product review</button>
              </div>
              <div class=" col-sm-12  mt-4 py-4">
                  <div class="row justify-content-between">
                      <div class="order-tracking completed">
                          <span class="is-complete"></span>
                          <p><strong>Accepted</strong><br><span>Mon, June 24</span></p>
                      </div>
                      <div class="order-tracking completed">
                          <span class="is-complete"></span>
                          <p><strong>Processed</strong><br><span>Tue, June 25</span></p>
                      </div>
                      <div class="order-tracking completed">
                          <span class="is-complete"></span>
                          <p><strong>Packed</strong><br><span>Tue, June 25</span></p>
                      </div>
                      <div class="order-tracking completed">
                          <span class="is-complete"></span>
                          <p><strong>Shipped</strong><br><span>Tue, June 25</span></p>
                      </div>
                      <div class="order-tracking completed">
                          <span class="is-complete"></span>
                          <p><strong>Delivered</strong><br><span>Fri, June 28</span></p>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 text-center">
                  <button class="btn btn-secondary px-4 py-2 rounded-pill me-2 mb-2" disabled>Track ID : 1210218620916</button>
                  <button class="btn btn-secondary px-4 py-2 rounded-pill mb-2" disabled>Courier By : Delhivery Surface</button>
              </div>
          </div> -->
            
          </div>
        </div>
      </div>
      <div id="collapseOne{{j}}" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" *ngFor="let obj of obj1.orderdetails; index as i">
        <div class="px-2 pb-3 pt-0">
          <div class="col-sm-12 card shadow-none p-0">
            <div class="card-header">
              <h6 class="font1 mb-0">
                <strong>Order Details</strong>
                <!-- <label>
              <span class="mx-2">
                Wallet</span><img class="visacls" src="../../../assets/images/visa_PNG30.png"> <span>**** 7758</span>
            </label> -->
              </h6>
            </div>
            <div class="card-body address-para">
  
              <div class="row">
                <div class="col-sm-4" *ngIf="!obj1.is_multi_add">
                  <h6 class="font600">Shipping Address</h6>
                  <p class="mb-1">{{obj1.name}} {{obj1.last_name}}</p>
                  <p class="mb-1">{{obj1.company_name}}</p>
                  <p>{{obj1.area_street}}, {{obj1.landmark}}, {{obj1.locatity}}, {{obj1.city}},({{obj1.pin_code}}),
                    {{obj1.state_name}}, India</p>
                  <p class="mb-1"><strong>Phone</strong> : +91-{{obj1.mobile}}</p>
                  <p class="mb-1"><strong>Email</strong> : {{obj1.email_address}}</p>
                </div>
                <div class="col-sm-4" *ngIf="obj1.is_multi_add">
                  <h6 class="font600">Shipping Address</h6>
                 
                    <button (click)="downloadAddress(obj1.multiAdd)" class="btn btn-theme2 px-lg-3 rounded-pill mb-3 btn-sm"><i class="fas fa-download me-1"></i> Download Shipping Address</button>
          
                </div>
                <div class="col-sm-4" *ngIf="!obj1.billingAddress.length">
                  <h6 class="font600">Billing Address</h6>
                  <p class="mb-1">{{obj1.name}} {{obj1.last_name}}</p>
                  <p class="mb-1">{{obj1.company_name}}</p>
                  <p>{{obj1.area_street}}, {{obj1.landmark}}, {{obj1.locatity}}, {{obj1.city}},({{obj1.pin_code}}),
                    {{obj1.state_name}}, India</p>
                  <p class="mb-1"><strong>Phone</strong> : +91-{{obj1.mobile}}</p>
                  <p class="mb-1"><strong>Email</strong> : {{obj1.email_address}}</p>
                </div>
                <div class="col-sm-4" *ngIf="obj1.billingAddress.length">
                  <h6 class="font600">Billing Address</h6>
                  <p class="mb-1">{{obj1.billingAddress[0].name}} {{obj1.billingAddress[0].last_name}}</p>
                  <p class="mb-1">{{obj1.billingAddress[0].company_name}}</p>
                  <p>{{obj1.billingAddress[0].area_street}}, {{obj1.billingAddress[0].landmark}}, {{obj1.billingAddress[0].locatity}}, {{obj1.billingAddress[0].city}}, ({{obj1.billingAddress[0].pin_code}}),
                    {{obj1.state_name}}, India</p>
                  <p class="mb-1"><strong>Phone</strong> : +91-{{obj1.billingAddress[0].mobile}}</p>
                  <p class="mb-1"><strong>Email</strong> : {{obj1.billingAddress[0].email_address}}</p>
                </div>
                <div class="col-sm-4">
                  <h6 class="font600">Order Summary </h6>
                  <p class="mb-1">
                    <span>Item(s) Subtotal :</span>
                    <span class="float-right ">{{convertToInt(obj1.total_amount, obj1.total_gst_amount,obj1.gift_amount)}}</span>
                  </p>
                  <p class="mb-1">
                    <span>GST :</span>
                    <span class="float-right "> {{obj1.total_gst_amount ? rupeeIndian.format(obj1.total_gst_amount) : '₹00.00'}}</span>
                  </p>
                  <p class="mb-1">
                    <span>Shipping :</span>
                    <span class="float-right ">₹00.00</span>
                  </p>
                  <p class="mb-1 ">
                    <span class="text-success">Coupon Discount :</span>
                    <span class="text-success float-right "><span *ngIf="+obj1.gift_amount">-</span>{{+obj1.gift_amount ? rupeeIndian.format(+obj1.gift_amount) :'₹00.00'}}</span>
                  </p>
                  <p class="mb-1 font600">
                    <span>Amount Paid :</span>
                    <span class="float-right "> {{rupeeIndian.format(obj1.total_amount)}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  </div>
  <div class="text-center" *ngIf="orderSecDetails.length==0">

    <img class="img-fluid wish-list-width" src="../../../assets/images/noorder.png" alt="">
    <div class="mt-3">
      <h5 class="fs-6">Empty Order</h5>
      <p class=" font8">You have no items in your Order. Start Ordering!</p>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="trackpackage" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header p-3">
        <h6 class="modal-title" id="exampleModalLabel">Track Your Product</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body p-3 minhyt70vh">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <h5 class="mt-2">EYEBOGLER Regular Fit Men's CottonT-Shirt</h5>
              <div class="col-sm-12 pt-3 pb-2">
                <button disabled="" class="btn btn-secondary px-4 py-2 rounded-pill mr-2 mb-2">
                  Track ID : 1210218620916
                </button>
                <button disabled="" class="btn btn-secondary px-4 py-2 rounded-pill mb-2">
                  Courier By : Delhivery Surface
                </button>
              </div>
            </div>

            <section>
              <p class="mt-2">See all updates</p>
              <div class="order-track">
                <div class="order-track-step">
                  <div class="order-track-text-start">
                    <span class="order-track-text-sub">
                      <p>1st October, 2022</p>
                    </span>
                    <div class="collapse" id="collapseExample-1">
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 AM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">03:23 PM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">
                          <b>28 Feb 2022</b>
                        </span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">05:23 PM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">08:23 PM</span>
                      </div>
                    </div>
                  </div>
                  <div class="order-track-status">
                    <span class="order-track-status-dot"></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <p class="order-track-text-stat">
                      <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseExample-1" aria-expanded="false" aria-controls="collapseExample-1"
                        class="text-decoration-none">Accepted</a>
                    </p>
                    <div class="collapse" id="collapseExample-1">
                      <div class="del-status">
                        <strong>Deliver to consignee</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="del-status">
                        <strong>Out for delivery</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="height">

                      </div>
                      <div class="del-status">
                        <strong>Out for delivery</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="del-status">
                        <strong>Out for delivery</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-text-start">
                    <span class="order-track-text-sub">
                      <p>1st November, 2022</p>
                    </span>
                    <div class="collapse" id="collapseExample-2">
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                    </div>
                  </div>
                  <div class="order-track-status">
                    <span class="order-track-status-dot"></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <p class="order-track-text-stat">
                      <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseExample-2" aria-expanded="false" aria-controls="collapseExample-2"
                        class="text-decoration-none">Processed</a>
                    </p>
                    <div class="collapse" id="collapseExample-2">
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-text-start">
                    <span class="order-track-text-sub">
                      <p>1st November, 2019</p>
                    </span>
                    <div class="collapse" id="collapseExample-3">
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                    </div>
                  </div>
                  <div class="order-track-status">
                    <span class="order-track-status-dot"></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <p class="order-track-text-stat">
                      <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseExample-3" aria-expanded="false" aria-controls="collapseExample-3"
                        class="text-decoration-none">Packed</a>
                    </p>
                    <div class="collapse" id="collapseExample-3">
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-text-start">
                    <span class="order-track-text-sub">
                      <p>1st November, 2022</p>
                    </span>
                    <div class="collapse" id="collapseExample-4">
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                    </div>
                  </div>
                  <div class="order-track-status">
                    <span class="order-track-status-dot"></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <p class="order-track-text-stat">
                      <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseExample-4" aria-expanded="false" aria-controls="collapseExample-4"
                        class="text-decoration-none">Shipped</a>
                    </p>
                    <div class="collapse" id="collapseExample-4">
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-track-step">
                  <div class="order-track-text-start mt-1">
                    <span class="order-track-text-sub">
                      <p>1st November, 2022</p>
                    </span>
                    <div class="collapse" id="collapseExample-5">
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                      <div class="mb-5">
                        <span class="order-track-text-sub">01:23 PM</span>
                      </div>
                    </div>
                  </div>
                  <div class="order-track-status">
                    <span class="order-track-status-dot"></span>
                    <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                    <p class="order-track-text-stat">
                      <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseExample-5" aria-expanded="false" aria-controls="collapseExample-5"
                        class="text-decoration-none">Delivered</a>
                    </p>
                    <div class="collapse" id="collapseExample-5">
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                      <div class="del-status">
                        <strong>Lorem ipsum</strong>
                        <p>Gurgaon_Hasanpur_D (Haryana)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
    </div>
  </div>
</div>
<div class="modal" id="cancelOrder">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-2">
        <h5 class="modal-title">Cancel Order</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <p>Are you sure you want to cancel the order?</p>
        <div class="float-right">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-theme2 ml-2">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal fade" id="rating-rv" tabindex="1" aria-labelledby="rating-rv" aria-hidden="true">
  <div class="modal-dialog modal-lg  modal-sm-dialog-centered modal-dialog-scrollable-new">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="addtocartLabel">Write a product review</h6>
       
      </div>
      <div class="modal-body px-3">
          <app-rating-review [productId]="rateProductId" [order_id]="rateOrderId" [order_number]="rateOrderNumber" [revTypeId]="rateRevTypeId" [revtype]="rateRevType"></app-rating-review>
      </div>
     
    </div>
  </div>
</div> -->