<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
        <app-header> </app-header>
        <div class="webdesk">
            <app-left-menu></app-left-menu>
        </div>

        <div class="container-fluid bg-white">
            <div class="row">
                <div class="col-sm-12 p-0">
                    <nav aria-label="breadcrumb p-0" *ngIf="!breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/total-seller">Total Seller</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" class="text-primary" style="text-decoration: none;  cursor: text;" routerLink="/seller-wise-product">Product Listing</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Add Simple Products</li>
                        </ol>
                    </nav>
                    <nav aria-label="breadcrumb p-0" *ngIf="breadcrumb">
                        <ol class="breadcrumb bg-lyt pt-0 pb-0 mb-1 font8">
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" routerLink="/dashboardnew">Dashboard</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a href="javascript:void(0)" routerLink="/product-listing">Pending Products</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Add Simple Products</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="tabs row my-2 mx-0">
                <!-- <ul class="breadcrumb">
                <li><a href="#">Home</a></li>
                <li><a href="#">Pictures</a></li>
              </ul> -->
                <div class="tab-button-outer1 col-sm-9 px-0 mb-sm-0 mb-2">
                    <ul id="tab-button">
                        <li id="tabDraft" class="is-active">
                            <a href="javascript:void(0)" class="font8">Add Simple Products
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- <div class="uploaded-product d-lg-flex">
                <h4 class="mb-3">Simple Product Upload</h4>
                <div class="ms-auto">
                    <button type="button" class="btn btn-sm btn-gray ms-2" (click)="back()">
                        Back
                    </button>
                </div>

            </div> -->
            <div class="card shadow-none p-3">
                <div class="row align-items-center mb-2">
                    <div class="col-md-auto minwidth-responsive">
                        <label for="">Assign Product</label>
                    </div>
                    <div class="col-md-10">
                        <div class="form-check pl-0">
                            <input class="custom1-chkboxradio" type="checkbox" id="market_price" (change)="checkedbox('abc')" value="market_price_value"
                                [(ngModel)]="assign_marketplace" #register type="checkbox">
                            <label class="mx-2" for="market_price">Market Place</label>
                        </div>
                        <div class="form-check pl-0">
                            <input class="custom1-chkboxradio" type="checkbox" id="corporate" value="corporate_value" [(ngModel)]="assign_corp" (change)="CorporateChange()">
                            <label class="mx-2" for="corporate">Corporate</label>
                        </div>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="assign_corp">
                    <div class="col-md-auto minwidth-responsive">
                        <label for="">Select Corporate</label>
                    </div>
                    <div class="col-md">
                        <ng-select multiple="true" name="corpname" id="corpid" class=" mb-2" [(ngModel)]="corp_id" (change)="getcorporate('0')">
                            <ng-option value="undefined" disabled>--Select Corporate--</ng-option>
                            <ng-option *ngFor="let corp of corpList" [value]="corp.add_corporate_id">
                                {{corp.enterprise_name}} ({{corp.corp_name}})
                            </ng-option>
                        </ng-select>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group position-relative brandnamebox">
                            <label for="productTitle">Brand Name
                                <span class="asterisk">*</span>
                            </label>
                            <a class="position-absolute addbrandnew text-decoration-none end-0" href="javascript:void()" data-target="#addbrand" data-toggle="modal">
                                <i class="fa fa-plus"></i> Add New Brand</a>
                            <!-- <ng-select [(ngModel)]="brand_id">
                                <ng-option value="undefined" disabled selected>Select Brand</ng-option>
                                <ng-option *ngFor="let obj of brandNameList" [value]="obj.brand_name_id">{{obj.name}}
                                </ng-option>
                            </ng-select> -->
                            <select class="form-control" [(ngModel)]="brand_id" placeholder="Enter Brand Name">
                                <option value="undefined" selected disabled>--Select Brand--</option>
                                <option *ngFor="let obj of brandNameList" [value]="obj.brand_name_id">
                                    {{obj.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-8 col-12">
                        <div class="form-group ">
                            <label for="productTitle">Product Name
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" class="form-control mb-2" id="productTitle" autocomplete="off" placeholder="Enter Product Name" [disabled]="isView"
                                [(ngModel)]="product_name" maxlength="500">
                        </div>
                    </div>


                    <div class="col-md-3 col-12">

                        <div class="form-group ">
                            <label for="suk">SKU
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" maxlength="50" class="form-control mb-2" id="suk" placeholder="Enter SKU" [(ngModel)]="suk" [disabled]="isView"
                                autocomplete="off" (keypress)="commonFunctionService.alphaNumericValidator($event)" >
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="hsn">HSN No.
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" autocomplete="off" minlength="4" maxlength="8" class="form-control mb-2"
                                id="hsn" placeholder="Enter HSN No." [disabled]="isView" onkeypress="return /[0-9]/i.test(event.key)"
                                [(ngModel)]="hsn" >
                        </div>
                    </div>
                    <!-- <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="purchase_price">Purchase Price (₹)
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" onpaste="return false;" ondrop="return false;" autocomplete="off" min="0" maxlength="10" class="form-control mb-2"
                                id="purchase_price" placeholder="Enter Purchase Price" [disabled]="isView" (keypress)="commonFunctionService.amountValidator($event)"
                                [(ngModel)]="puerchasePrice">
                        </div>
                    </div> -->

                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="price">Selling Price (₹)
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" autocomplete="off" min="0" maxlength="15" class="form-control mb-2"
                                id="productTitle" placeholder="Enter Price" [disabled]="isView" (keypress)="commonFunctionService.amountValidator($event)"
                                [(ngModel)]="price">
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="MRP">MRP (₹)
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" autocomplete="off" min="0" maxlength="15" class="form-control mb-2"
                                id="MRP" placeholder="Enter MRP" [disabled]="isView" (keypress)="commonFunctionService.amountValidator($event)"
                                [(ngModel)]="mrp">
                        </div>
                    </div>
                    
                </div>
                <!-- <div class="row" id="market_price" *ngIf="register.checked">
                        <div class="col-sm-12">
                            <div class="card shadow-none p-0 mt-2 mb-2">
                                <div class="card-header px-2">
                                    <h6 class="mb-0 font600">Bundle Prices</h6>
                                </div>
                                <div class="card-body">
                                    
                                    <div class="row">
                                        <div class="col-lg-2 align-self-center mt-2">
                                            <div class="form-check pl-0">
                                                <input id="showqtyPrice" class="custom1-chkboxradio ml-0 mr-2" type="checkbox" [(ngModel)]="showqtyPrice">
                                                <label for="showqtyPrice" class=""> Add Quantity Wise Price</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 mt-sm-0 mt-2" *ngIf="showqtyPrice">
                                            <div class="row mb-2">
                                               
                                                <div class="col-lg-1 col-md-2 col-3 pr-0 mb-sm-0 mb-2">
                                                    <label for="">Qty</label>
                                                    <input type="text" class="form-control" [(ngModel)]="MinQty" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                        min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                                </div>
                                                <div class="col-auto align-self-end mb-sm-2 mb-3 pr-0">
                                                    <label>to</label>
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                    <label for="">Qty</label>
                                                    <input type="text" class="form-control" [(ngModel)]="MaxQty" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                        min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                    <label for="">Price</label>
                                                    <input type="text" class="form-control" [(ngModel)]="Qtyprice" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                        min="0" maxlength="" (keypress)="commonFunctionService.amountValidator($event)">
                                                </div>
                                                <div class="col-auto d-flex align-self-end">
                                                    <button class="btn btn-theme btn-sm px-3 py-1" (click)="addMarketWisepeise()">
                                                        <i class="fa fa-plus"></i> Add
                                                    </button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="MarketWisepeiseList.length>0">
                                        <div class="col-lg-10 offset-lg-2" *ngIf="showqtyPrice == true">
                                            <div class="table-responsive white-space-pre mt-1">
                                                <table class="table table-bordered vertically-middle thead mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class="text-center w10">Sr. No.</th>
                                                            
                                                            <th scope="col">Min Qty.</th>
                                                            <th scope="col">Max Qty.</th>
                                                            <th scope="col">Price</th>
                                                            <th scope="col" class="text-center w20">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let obj of MarketWisepeiseList;let i=index">
                                                            <td class="text-center">{{i+1}}</td>
                                                           
                                                            <td>{{obj.MinQty}}</td>
                                                            <td>{{obj.MaxQty}}</td>
                                                            <td>{{obj.Qtyprice}}</td>
                                                            <td class="text-center">
                                                                <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editMarketWisepeise(i)">
                                                                    <i class="fa fa-edit mr-lg-1"></i>
                                                                    <span class="d-lg-inline d-none">Edit</span>
                                                                </button>
                                                                <button type="button" class="btn btn-sm btn-danger" (click)="removeMarketWisepeise(i)">
                                                                    <i class="fa fa-trash mr-lg-1"></i>
                                                                    <span class="d-lg-inline d-none">Delete</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="row" *ngIf="register.checked">
                            <div class="col-lg-10 offset-lg-2" *ngIf="showqtyPrice == true">
                                <div class="table-responsive white-space-pre mt-1">
                                    <table class="table table-bordered vertically-middle thead mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-center w10">Sr. No.</th>
                                                 <th scope="col">Min Qty.</th>
                                                <th scope="col">Max Qty.</th>
                                                <th scope="col">Price</th>
                                                <th scope="col" class="text-center w20">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let obj of CorpWisepeiseList;let i=index">
                                                <td class="text-center">{{i+1}}</td>
                                                <td>{{obj.selectedCorpName}}</td>
                                                <td>{{obj.minQty}}</td>
                                                <td>{{obj.maxQty}}</td>
                                                <td>{{obj.qtyprice}}</td>
                                                <td class="text-center">
                                                    <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editCorpWisepeise(i)">
                                                        <i class="fa fa-edit mr-lg-1"></i>
                                                        <span class="d-lg-inline d-none">Edit</span>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-danger" (click)="removeCorpWisepeise(i)">
                                                        <i class="fa fa-trash mr-lg-1"></i>
                                                        <span class="d-lg-inline d-none">Delete</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> -->
                <!-- <div class="row">
                    <div class="col-sm-12">
                        <div class="card shadow-none p-0 mt-2 mb-2" *ngIf="tempcoprlist.length > 0">
                            <div class="card-header px-2">
                                <h6 class="mb-0 font600">Corporate Prices</h6>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center my-1" *ngFor="let corp of tempcoprlist; let i=index">
                                    <div class="col-md-2 pr-lg-0">
                                        <label for="">{{corp.enterprise_name}}</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-3 pr-0">
                                        <input type="text" class="form-control" [(ngModel)]="corp.Corporate_price" onpaste="return false;" ondrop="return false;"
                                            autocomplete="off" min="0" maxlength="15" (keypress)="commonFunctionService.amountValidator($event)">
                                    </div>
                                
                                </div> -->
                                <!-- <div class="row">
                                    <div class="col-lg-2 align-self-center mt-2">
                                        <div class="form-check pl-0">
                                            <input id="showqtyPrice" class="custom1-chkboxradio ml-0 mr-2" type="checkbox" [(ngModel)]="showqtyPrice">
                                            <label for="showqtyPrice" class=""> Add Quantity Wise Price</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-10 mt-sm-0 mt-2" *ngIf="showqtyPrice">
                                        <div class="row mb-2">
                                            <div class="col-lg-2 col-md pr-0 mb-sm-0 mb-2 align-self-end">
                                                <ng-select [(ngModel)]="selectedCorpid">
                                                    <ng-option value="undefined" disabled selected>Select Corporate</ng-option>
                                                    <ng-option *ngFor="let obj of tempcoprlist" [value]="obj.add_corporate_id">{{obj.enterprise_name}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-1 col-md-2 col-3 pr-0 mb-sm-0 mb-2">
                                                <label for="">Qty</label>
                                                <input type="text" class="form-control" [(ngModel)]="minQty" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                    min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                            </div>
                                            <div class="col-auto align-self-end mb-sm-2 mb-3 pr-0">
                                                <label>to</label>
                                            </div>
                                            <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                <label for="">Qty</label>
                                                <input type="text" class="form-control" [(ngModel)]="maxQty" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                    min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)">
                                            </div>
                                            <div class="col-lg-1 col-md-2 col-3 pr-0">
                                                <label for="">Price</label>
                                                <input type="text" class="form-control" [(ngModel)]="qtyprice" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                                    min="0" maxlength="" (keypress)="commonFunctionService.amountValidator($event)">
                                            </div>
                                            <div class="col-auto d-flex align-self-end">
                                                <button class="btn btn-theme btn-sm px-3 py-1" (click)="addCorpWisepeise()">
                                                    <i class="fa fa-plus"></i> Add
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="CorpWisepeiseList.length>0">
                                    <div class="col-lg-10 offset-lg-2" *ngIf="showqtyPrice == true">
                                        <div class="table-responsive white-space-pre mt-1">
                                            <table class="table table-bordered vertically-middle thead mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-center w10">Sr. No.</th>
                                                        <th scope="col">Corporate Name</th>
                                                        <th scope="col">Min Qty.</th>
                                                        <th scope="col">Max Qty.</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col" class="text-center w20">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let obj of CorpWisepeiseList;let i=index">
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td>{{obj.selectedCorpName}}</td>
                                                        <td>{{obj.minQty}}</td>
                                                        <td>{{obj.maxQty}}</td>
                                                        <td>{{obj.qtyprice}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editCorpWisepeise(i)">
                                                                <i class="fa fa-edit mr-lg-1"></i>
                                                                <span class="d-lg-inline d-none">Edit</span>
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-danger" (click)="removeCorpWisepeise(i)">
                                                                <i class="fa fa-trash mr-lg-1"></i>
                                                                <span class="d-lg-inline d-none">Delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row align-items-center mb-2">
                                    <div class="col-md-2 pr-lg-0">
                                        <label for="">Price JCB</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-3 pr-0">
                                        <input type="text" class="form-control">
                                    </div>
                                    <div class="col my-md-0 my-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="inlineCheckbox1" value="option2">
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-2 pr-lg-0">
                                        <label for="">Price Service Now</label>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-3 pr-0">
                                        <input type="text" class="form-control">
                                    </div>
                                    <div class="col my-md-0 my-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input custom1-chkboxradio mx-0" type="checkbox" id="inlineCheckbox1" value="option2">
                                        </div>
                                    </div>
                                </div> -->

                            <!-- </div>
                        </div>
                    </div>
                </div> -->


                <div class="row">
                    <div class="col-sm-12">
                        <div class="card shadow-none p-0 mt-2 mb-2"
                            *ngIf="tempcoprlist.length > 0">
                            <div class="card-header px-2">
                                <h6 class="mb-0 font600">Corporate Prices</h6>
                            </div>
                            <div class="card-body py-2"
                                *ngFor="let corp of tempcoprlist; let i=index">
                                <div class="row">
                                <div class="col-md-2 pr-lg-0">
                                    <label for="">{{corp.enterprise_name}}</label>
                                    <!-- <label for="">{{pro.product_description}}</label> -->
                                </div>
                                <!-- <div class="row align-items-center my-1"
                                    *ngFor="let pro of corp.productCnf; let i = index">
                                    <div class="col-md-2 pr-lg-0">
                                        <label for="">{{pro.product_description.substring(pro.product_description.lastIndexOf("-", pro.product_description.lastIndexOf("-") - 1)+1,pro.product_description.length)}}</label>
                                    </div>

                                    <div class="col-lg-1 col-md-2 col-3 pr-0">
                                        <input type="text" class="form-control"
                                            [name]="corp.enterprise_name +''+(corp.productCnf[i].id || pro.product_configuration_id)"
                                            [(ngModel)]="corp.productCnf[i].corpPrice" 
                                            onpaste="return false;" ondrop="return false;"
                                            autocomplete="off" min="0" maxlength="6"
                                            (keypress)="commonFunctionService.amountValidator($event)">
                                    </div>
                         
                                </div> -->

                                <div class="col-lg-1 col-md-2 col-3 pr-0">
                                    <label for=""> Price(₹)<span class="asterisk">*</span></label>
                                    <input type="text" class="form-control" [name]="corp.enterprise_name" [(ngModel)]="corp.Corporate_price" 
                                        autocomplete="off" min="0" maxlength="15" (keypress)="commonFunctionService.amountValidator($event)">
                                </div>
                            </div>
                                <div class="row">
                                    <div class="col-lg-2 align-self-center mt-2">
                                        <div class="form-check pl-0">
                                            <input id="corp.showqtyPrice"
                                                class="custom1-chkboxradio ml-0 mr-2"
                                                type="checkbox" name="corp.showqtyPrice"
                                                [(ngModel)]="corp.showqtyPrice">
                                            <label for="showqtyPrice" class=""> Add Quantity
                                                Wise Price</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-10 mt-sm-0 mt-2"
                                        *ngIf="corp.showqtyPrice">
                                        <div class="row mb-2">
                                            
                                            <div
                                                class="col-lg-2 col-md-2 col-3 pr-0 mb-sm-0 mb-2">
                                                <label for="">Min Quantity</label>
                                                <input type="text" class="form-control"
                                                    name="minQty" [(ngModel)]="corp.minQty"                                                    
                                                    autocomplete="off" min="0" maxlength="5"
                                                    onkeypress="return /[0-9]/i.test(event.key)">
                                            </div>
                                            <div
                                                class="col-auto align-self-end mb-sm-2 mb-3 pr-0">
                                                <label>to</label>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-3 pr-0">
                                                <label for="">Max Quantity</label>
                                                <input type="text" class="form-control"
                                                    name="maxQty" [(ngModel)]="corp.maxQty"
                                                    autocomplete="off" min="0" maxlength="5"
                                                    onkeypress="return /[0-9]/i.test(event.key)">
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-3 pr-0">
                                                <label for="">Bundle Price(₹)</label>
                                                <input type="text" class="form-control"
                                                    name="qtyprice" [(ngModel)]="corp.qtyprice"
                                                    autocomplete="off" min="0" maxlength=""
                                                    (keypress)="commonFunctionService.amountValidator($event)">
                                            </div>
                                            <div class="col-auto d-flex align-self-center mt-4">
                                                <button
                                                    class="btn btn-theme btn-sm px-3 py-1"
                                                    (click)="addCorpWisepeise(corp)">
                                                    <i class="fa fa-plus"></i> Add
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="corp.CorpWisepeiseList.length > 0">
                                    <div class="col-lg-10 offset-lg-2"
                                        *ngIf="corp.showqtyPrice == true">
                                        <div class="table-responsive white-space-pre mt-1">
                                            <table
                                                class="table table-bordered vertically-middle thead mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"
                                                            class="text-center w10">Sr. No.
                                                        </th>
                                                        <th scope="col">Corporate Name</th>
                                                        <th scope="col">Min Qty.</th>
                                                        <th scope="col">Max Qty.</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col"
                                                            class="text-center w20">Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let obj of corp.CorpWisepeiseList;let i=index">
                                                        <td class="text-center">{{i+1}}</td>
                                                        <td>{{obj.selectedCorpName}}</td>
                                                        <td>{{obj.minQty}}</td>
                                                        <td>{{obj.maxQty}}</td>
                                                        <td>{{obj.qtyprice}}</td>
                                                        <td class="text-center">
                                                            <button type="button"
                                                                class="btn btn-sm btn-theme text-white mr-2"
                                                                (click)="editCorpWisepeise(i, corp)">
                                                                <i
                                                                    class="fa fa-edit mr-lg-1"></i>
                                                                <span
                                                                    class="d-lg-inline d-none">Edit</span>
                                                            </button>
                                                            <button type="button"
                                                                class="btn btn-sm btn-danger"
                                                                (click)="removeCorpWisepeise(i,corp)">
                                                                <i
                                                                    class="fa fa-trash mr-lg-1"></i>
                                                                <span
                                                                    class="d-lg-inline d-none">Delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-2 px-lg-3">
                <div class="row">
                    <div class="col-sm-3 col-8 mb-2" *ngIf="assign_marketplace== true">
                        <div class="form-check pl-0">
                            <input type="checkbox" class="mt-sm-1 custom1-chkboxradio mt-0 "
                                 [(ngModel)]="asPerPrice"
                                (change)="onChange($event)" name="asperprice"
                                id="as_per_bundle">
                            <label class="form-check-label ml-sm-3 mt-1" for="as_per_bundle">Quantity Wise Price<b> (Market Place)</b> </label>
                        </div>
                    </div>
                    <div class="col-sm-10 col-12 mb-2">
                    <div class="row" *ngIf="asPerPrice == true">
                        <div class="col-12">
                            <h6>For Bundle Product</h6>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="min">Min Quantity
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" [(ngModel)]="minQuantity"
                                            onkeypress="return /[0-9]/i.test(event.key)"
                                            class="form-control mb-2" id="min" name="minquant"
                                            autocomplete="off"
                                            placeholder="" maxlength="4">
                                    </div>
        
                                </div>
                                <div class="col-md-2" *ngIf="!noLimit">
                                    <div class="form-group">
                                        <label for="max">Max Quantity
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" [(ngModel)]="maxQuantity"
                                            onkeypress="return /[0-9]/i.test(event.key)"
                                            class="form-control mb-2" id="max" name="maxquant"
                                            autocomplete="off"
                                            placeholder="" maxlength="4">
                                    </div>
        
                                </div>
                                <div class="col-md-3 align-self-center mb-sm-0 my-2">
                                    <div class="input-group">
                                        <input type="checkbox" [(ngModel)]="noLimit"
                                            class="mt-sm-1 mt-0 custom1-chkboxradio" id="check"
                                            name="noLimit" autocomplete="off">
                                        <label for="check" class=" ml-2">Make Bundle
                                            price same</label>
        
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="pr">Bundle Price (₹)
                                            <span class="asterisk">*</span>
                                        </label>
                                        <input type="text" [(ngModel)]="bundlePrice"
                                            onkeypress="return /[0-9]/i.test(event.key)"
                                            class="form-control mb-2" id="pr" name="prquant"
                                            autocomplete="off" placeholder=""
                                            maxlength="15">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-2 col-12 align-self-center mt-sm-3">
                                    <button type="button" class="btn btn-sm btn-theme px-3 py-1"
                                        (click)="addBundleQuantity()">
                                        <i class="fa fa-plus"></i> Add
                                    </button>
                                </div>
                            </div>
                          
                            <div class="row my-2" *ngIf="bundleAtribute.length > 0">
                                <div class="col-12">
                                    <div class="table-responsive white-space-pre">
                                        <table
                                            class="table table-bordered vertically-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="w10 text-center">Sr.
                                                        No.</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Bundle Price (₹)</th>
                                                    <th scope="col" class="text-center w20">
                                                        Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let atr of bundleAtribute; let i = index">
                                                    <td class="text-center">{{i+1}}</td>
                                                    <td>{{atr.min_qty}} - {{atr.max_qty ?
                                                        atr.max_qty
                                                        : ' Above'}}
                                                    </td>
                                                    <td>{{atr.price}}</td>
                                                    <td class="text-center">
                                                        <button type="button"
                                                            class="btn btn-sm btn-theme"
                                                            (click)="editBundleAttribute(i)">
                                                            <i class="fa fa-edit mr-sm-1"></i>
                                                            <span
                                                                class="d-sm-inline d-none">Edit</span>
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-sm btn-danger m-1"
                                                            (click)="removeBundleAttribute(i)">
                                                            <i class="fa fa-trash mr-sm-1"></i>
                                                            <span
                                                                class="d-sm-inline d-none">Delete</span>
                                                        </button>
        
        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
        
                                </div>
                            </div>
        
                       
                    </div>
                </div></div>
                </div>
            </div>

                
                <div class="row">
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="gst_rate">GST Rate(%)
                                <span class="asterisk">*</span>
                            </label>
                            <!-- <input type="text" onpaste="return false;" ondrop="return false;" autocomplete="off"
                                    min="0" maxlength="2" onkeypress="return /[0-9]/i.test(event.key)" class="form-control mb-2" id="gst_rate" placeholder="Enter GST Rate"
                                    [disabled]="isView" 
                                    [(ngModel)]="gstRate"> -->
                            <select class="form-control" [(ngModel)]="gstRate" placeholder="Enter GST Rate">
                                <option value="undefined" selected disabled>Select GST Value</option>
                                <option *ngFor="let obj of gstList" [value]="obj.value">
                                    {{obj.value}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="min_quantity">Minimum Order Quantity
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" autocomplete="off" min="0" maxlength="5" onkeypress="return /[0-9]/i.test(event.key)"
                                class="form-control mb-2" id="min_quantity" placeholder="Enter Minimum Quantity" [disabled]="isView"
                                [(ngModel)]="minOrderQuantity">
                        </div>
                    </div>

                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="quantity">Available Stock 
                                <span class="asterisk">*</span>
                            </label>
                            <input type="text" autocomplete="off" min="0" class="form-control mb-2" id="productTitle"
                                placeholder="Enter Quantity" [disabled]="isView" (keypress)="commonFunctionService.numericCheck($event)"
                                [(ngModel)]="quantity" maxlength="10">
                        </div>
                    </div>
                    <!-- <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="stockStatus">Stock status
                                <span class="asterisk">*</span>
                            </label>
                            <select name="stockStatus" id="stockStatus" class="form-control mb-2" [(ngModel)]="stock_status" [disabled]="isView">
                                <option value="undefined" disabled selected>-:Select Stock status :-</option>
                                <option value="1">In Stock</option>
                                <option value="2">Out Of Stock</option>
                            </select>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="stockStatus">This item has weight</label>
                            <select name="stockStatus" id="stockStatus" class="form-control mb-2" (change)="getWeightValue()" [(ngModel)]="this_item_has_weight"
                                [disabled]="isView">
                                <option value="undefined" disabled selected>-: Select :-</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-12" *ngIf="hasWeight">
                        <div class="form-group ">
                            <label for="weight">Weight(In grm) </label>
                            <input type="text" min="0" maxlength="10" class="form-control mb-2" id="weight" autocomplete="off" placeholder="Enter Weight"
                                [disabled]="isView" [(ngModel)]="weigth" onkeypress="return /[0-9]/i.test(event.key)">
                        </div>
                    </div> -->
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="quantity">Product Tags </label>
                            <input type="text" min="0" class="form-control mb-2" placeholder="Enter Product Tags" autocomplete="off" [disabled]="isView"
                                [(ngModel)]="product_tags" maxlength="50">
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="category">Category
                                <span class="asterisk">*</span>
                            </label>
                            <!-- <input type="number" min="0" class="form-control mb-2" id="Categorie" placeholder="category" [(ngModel)]="category"> -->
                            <!-- <select name="category" id="category" class="form-control mb-2" [(ngModel)]="category" (change)="getSubCategory()" [disabled]="isView"> -->
                                <select name="category" id="category" class="form-control mb-2" [(ngModel)]="category" (change)="categoryChanged()" [disabled]="isView || isEdit">
                                <option value="undefined" disabled>Select Category</option>
                                <option *ngFor="let cat of categoryList" [value]="cat.product_category_id">
                                    {{cat.category_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="category">Sub Category
                                <span class="asterisk">*</span>
                            </label>
                            <select name="sub-category" id="sub-category" class="form-control mb-2" (change)="getSpecificationLabel()" [(ngModel)]="sub_category"
                                [disabled]="isView">
                                <option value="undefined" disabled>Select Sub Category</option>
                                <option *ngFor="let cat of subCategoryList" [value]="cat.product_category_id">
                                    {{cat.category_name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3 col-12" *ngFor="let nexted of nextedsubcategory;let next=index;">
                        <div class="form-group ">
                            <label for="category">Nexted {{next+1}} Sub Category
                                <span class="asterisk">*</span>
                            </label>
                            <select name="sub-category" id="sub-category{{next}}" class="form-control mb-2" (change)="getnextedSubCategory(nexted.selected,next+1)" [(ngModel)]="nexted.selected"
                                [disabled]="isView">
                                <option value="undefined" disabled>Select Sub Category</option>
                                <option *ngFor="let cat1 of nexted.res" [value]="cat1.product_category_id">
                                    {{cat1.category_name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="country_id">Country Of Manufacture
                                <span class="asterisk">*</span>
                            </label>
                            <select name="country_id" id="country_id" class="form-control mb-2" [(ngModel)]="country_id" [disabled]="isView">
                                <option value="undefined" disabled>Select Country</option>
                                <option *ngFor="let con of countryList" value="{{con.country_id}}">
                                    {{con.country_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-12">
                        <div class="form-group ">
                            <label for="quantity">Manufacture Year </label>
                            <span class="asterisk">*</span>
                            <!-- <input type="text" min="0" class="form-control mb-2" placeholder="Enter Manufacture Year" autocomplete="off" [disabled]="isView"
                                (keypress)="commonFunctionService.numericCheck($event)" [(ngModel)]="manufacture_year" maxlength="4"> -->
                            <select class="form-control" [(ngModel)]="manufacture_year" placeholder="Enter Year">
                                <option value="undefined" selected disabled>Select Year</option>
                                <option *ngFor="let obj of yearList" [value]="obj.year_value">
                                    {{obj.year_value}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-md-12">
                        <h6 class="font-weight-bold">Product Specifications
                            <span class="asterisk">*</span> 
                        </h6>
                    </div>
                </div>
                 <!-- ==== P K===> -->
                <!-- <div class="row" *ngIf="!isView">
                    <div class="col-sm-5 col-12">
                        <label for="lable">Label
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" min="0" class="form-control mb-2" maxlength="50" id="lable" autocomplete="off" placeholder="Enter Label"
                            [(ngModel)]="lable">
                    </div>
                    <div class="col-sm-5 col-12">
                        <label for="value">Value
                            <span class="asterisk">*</span>
                        </label>
                        <input type="text" min="0" class="form-control mb-2" maxlength="50" id="value" autocomplete="off" placeholder="Enter Value"
                            [(ngModel)]="value">
                    </div>
                    <div class="col-sm-2 col-4 pl-sm-0">
                        <button type="button" class="btn btn-sm btn-theme p-1 px-3 mt-sm-4" (click)="addSpecifications()">
                            <i class="fa fa-plus"></i> Add </button>
                    </div>
                </div> -->
                <!-- ==== P K===> -->
                <div>
                <!-- ==== P K===> -->
                    <div class="table-responsive white-space-pre mt-1">
                        <table class="table table-bordered vertically-middle thead" *ngIf="lableList.length > 0">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center w10">Sr. No.</th>
                                    <th scope="col">Label</th>
                                    <th scope="col" class="mw-118">Value</th>
                                    <!-- <th scope="col" *ngIf="!isView" class="text-center w20">Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let obj of lableList let i=index">
                                    <td class="text-center">{{i+1}}</td>
                                    <td>{{obj.label_name}}</td>
                                    <td *ngIf="!obj.is_custom">
                                        <select [(ngModel)]="obj.selectedValue" name="selectedValue_{{i+1}}" class="form-control">
                                            <option *ngFor="let values of obj.lableValues" value="{{values.product_specification_label_value_id}}">
                                                {{values.lebal_value}}</option>
                                        </select>
                                    </td>
                                    <td *ngIf="obj.is_custom">{{obj.label_valuelist}}</td>
                                    <td *ngIf="obj.is_custom" class="text-center">
                                        <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editSpecifications(i)">
                                            <i class="fa fa-edit mr-sm-1"></i>
                                            <span class="d-sm-inline d-none">Edit</span>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-danger" (click)="removeSpecifications(i)">
                                            <i class="fa fa-trash mr-sm-1"></i>
                                            <span class="d-sm-inline d-none">Delete</span>
                                        </button>


                                    </td>
                                    <!-- <td *ngIf="!obj.is_custom" class="text-center">

                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                <!-- ==== P K===> -->

                    <div class="row mr-0">
                        <div class="col-10 pr-0">
                            <label for="keyFeature">Key Feature
                            <span class="asterisk">*</span>
                            </label>
                            <input type="text" min="0" class="form-control mb-2" id="keyFeature" placeholder="Enter Key Feature" maxlength="500" [(ngModel)]="key"
                                *ngIf="!isView" autocomplete="off">
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn btn-sm btn-theme p-1 px-3  mt-4 me-2" (click)="addKeyFeatures()" *ngIf="!isView">
                                <i class="fa fa-plus mr-sm-1"></i>
                                <span class="d-sm-inline d-none">Add</span>
                            </button>
                            <!-- <button type="button" class="btn btn-sm  mt-4 btn-danger " (click)="removeKeyFeatures()"
                                    *ngIf="!isView"><i class="fa fa-trash"><-sm/i> Remove</button> -->
                        </div>
                    </div>
                    <div class="table-responsive white-space-pre mt-1">
                        <table class="table table-bordered vertically-middle thead" *ngIf="keyFeatures.length > 0">
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center w10">Sr. No.</th>
                                    <th scope="col">Key Feature</th>
                                    <th scope="col" *ngIf="!isView" class="text-center w20">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let key of keyFeatures; index as i">
                                    <td class="text-center">{{i+1}}</td>
                                    <td>{{key.key}}</td>
                                    <td *ngIf="!isView" class="text-center">
                                        <button type="button" class="btn btn-sm btn-theme text-white mr-2" (click)="editKeyFeatures(i)">
                                            <i class="fa fa-edit mr-sm-1"></i>
                                            <span class="d-sm-inline d-none">Edit</span>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-danger" (click)="removeKeyFeatures(i)">
                                            <i class="fa fa-trash mr-sm-1"></i>
                                            <span class="d-sm-inline d-none">Delete</span>
                                        </button>


                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                 
                    <div class="form-group mb-2">
                        <label for="discription">Product Description
                            <span class="asterisk">*</span>
                        </label>
                        <!-- <textarea class="form-control2 mb-2" id="discription" autocomplete="off" placeholder="Product Description" [disabled]="isView"
                            style="height: 100px;" maxlength="500" (keypress)="commonFunctionService.alphaNumericWithCommaDotHiphenValidator($event)"
                            [(ngModel)]="product_discription"></textarea> -->
                        <!-- <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                                <ngx-editor [placeholder]="'Enter text here...'" [disabled]="false" [(ngModel)]="product_discription" [editor]="editor"></ngx-editor>
                            </div> -->
                        <div class="NgxEditor__Wrapper width100 ngxcstm min-hy-200">
                            <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="product_discription" [config]="editorConfig"></angular-editor>
                        </div>
                    </div>

                    <div class="form-group mb-3">
                        <div class="row">
                            <div class="col-lg-12">
                                <h6 class="fs-6">Images
                                    <span class="asterisk">*</span>
                                </h6>
                            </div>
                            <div class="col-sm-7 col-10 pr-0">
                                <div class="form-group border">
                                    <input type="file" id="Photograph" class="form-control-file form-select pt-1 font8" multiple (change)="onFileChnage($event)" [disabled]="isView"
                                        #inputFile>
                                </div>
                                <label class="font75">
                                    <b class="text-danger">Note :</b> Image should be 1500 px * 1500 px. You can upload maximum 10 images.
                                </label>
                            </div>
                            <!-- <div class="col-sm col-2" *ngIf="!isView">
                                <button type="button" class="btn btn-sm btn-theme p-1 px-3 " (click)="onUpload()">
                                    <i class="fa fa-plus mr-sm-1"></i>
                                    <span class="d-sm-inline d-none">Add Image</span>
                                </button>
                            </div> -->
                            <!-- <div class="col-12 multiimg-box">
                                <div class="row">
                                      <div class="col-md-8 col-lg-6 mx-auto">
                                        <h6 class="fs-6 text-center">Product Images<span class="asterisk">*</span></h6>
                                        <div class="card shadow-none text-center">
                                            <div class="card-body">
                                                <label  for="fileupld" class="justify-content-center cursor-pointer">
                                                    <div  class="drag-drop"><input  type="file" id="fileupld">
                                                        <figure  class="figure text-center mb-0">
                                                            <img  src="../../../assets/images/icon/upload.png" alt="icon" class="figure-img" width="60"></figure>
                                                        <p  class="small mb-1">Drag & drop or browse files to upload</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <label class="font75"><b class="text-danger">Note :</b> Image should be 1500 px * 1500 px. You can upload maximum 10 images.</label>
                                        </div>
                                        <div class="images-live-box mt-2">
                                            <div class="row align-items-center border-bottom py-2">
                                                <div class="col-3 col-md-2">
                                                    <img src="../../../assets/images/product/prduct001.png" width="60" height="60">
                                                </div>
                                                <div class="col-6 col-md-7 pl-0">
                                                    <label class="font8 text-dark">product1-002-img.png</label>
                                                </div>
                                                <div class="col-3 text-right">
                                                    <a href="javascript:void(0)" class="text-decoration-none text-danger"><i class="fa fa-trash"></i></a>
                                                </div>
                                            </div>
                                            <div class="row align-items-center border-bottom py-2">
                                                <div class="col-3 col-md-2">
                                                    <img src="../../../assets/images/product/prduct001.png" width="60" height="60">
                                                </div>
                                                <div class="col-6 col-md-7 pl-0">
                                                    <label class="font8 text-dark">product1-002-img.png</label>
                                                </div>
                                                <div class="col-3 text-right">
                                                    <a href="javascript:void(0)" class="text-decoration-none text-danger"><i class="fa fa-trash"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                </div>
                            </div> -->
                            <div class="col-md-12">
                                <div class="table-responsive white-space-pre mt-1">
                                    <table class="table w-100 thead table-bordered vertically-middle cstmheadtable" *ngIf="uploadedGalaryFiles.length>0">
                                        <thead>
                                            <tr>
                                                <th class="text-center w10">Sr. No.</th>
                                                <th>File Name</th>
                                                <th class="text-center">Preview</th>
                                                <!-- <th>Document Size(In KB)</th> -->
                                                <th *ngIf="!isView" class="text-center w20">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let ob of uploadedGalaryFiles;index as i">
                                                <td class="text-center">{{i+1}}</td>
                                                <td>{{ob.file_name}}</td>
                                                <td *ngIf="!ob.isSavedInDB" class="text-center">
                                                    <img src={{ob.file_path}} width="40" height="40" alt="">
                                                </td>
                                                <td *ngIf="ob.isSavedInDB" class="text-center">
                                                    <img src="../../{{ob.file_path}}" width="40" height="40" alt="">
                                                </td>
                                                <!-- <td>{{ob.file_size}}</td> -->
                                                <td *ngIf="!isView" class="text-center">
                                                    <a href="javascript:void(0)" *ngIf="!ob.isSavedInDB" class="btn btn-sm btn-danger" (click)="deleteFile(ob.id)">
                                                        <i class="fa fa-trash mr-sm-1"></i>
                                                        <span class="d-sm-inline d-none">Delete</span>
                                                    </a>
                                                    <a href="javascript:void(0)" *ngIf="ob.isSavedInDB" class="btn btn-sm btn-danger" (click)="deleteFromDb(ob.id, ob.image_id)">
                                                        <i class="fa fa-trash mr-sm-1"></i>
                                                        <span class="d-sm-inline d-none">Delete</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              

            </div>
           

                <div class="text-center mt-3">
                    <button type="button" class="btn btn-sm btn-success mr-2 px-3" id="save-btn" (click)="addProduct()" *ngIf="!isView && !isEdit">
                        Save </button>
                    <button type="button" class="btn btn-sm btn-success px-3 mr-2" id="save-btn" (click)="addProduct()" *ngIf="isEdit">
                        Update Product
                    </button>
                    <button type="button" class="btn btn-sm btn-warning mr-2 px-3" (click)="reset()" *ngIf="!isView && !isEdit">
                        Reset
                    </button>
                    <button type="button" class="btn btn-sm btn-danger px-3" (click)="back()"> Back </button>

                </div>

           
        </div>
        <div class="modal fade p-0" id="addbrand" tabindex="-1" aria-labelledby="addbrandLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md  modal-dialog-scrollable-new">
                <div class="modal-content">
                    <div class="modal-header py-1">
                        <h6 class="modal-title" id="addtocartLabel">
                            <strong>Add New Brand</strong>
                        </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetBrand()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="">
                            <div class="col-sm-12">
                                <div class="form-group position-relative">
                                    <label for="productTitle">Brand Name
                                        <span class="asterisk">*</span>
                                    </label>
                                    <input type="text" [(ngModel)]="brandName" class="form-control mb-2" id="productTitle" autocomplete="off" placeholder="Enter Brand Name"
                                        maxlength="50">
                                </div>
                                <!-- <div class="form-group">
                                    <label for="productTitle">Brand Code
                                        <span class="asterisk">*</span>
                                    </label>
                                    <input type="text" [(ngModel)]="brandCode" class="form-control mb-2" id="productTitle" autocomplete="off" placeholder="Enter Brand Code"
                                        maxlength="10">
                                </div> -->
                            </div>
                            <div class="col-sm-12 text-center pt-3">
                                <button type="button" class="btn btn-sm px-3 btn-theme" (click)="addBrandName()">
                                    Submit</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          
                </div> -->
                </div>
            </div>
        </div>
    </div>