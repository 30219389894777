        <div class="card-body minwid750">
           <div *ngFor="let obj of criteriaList">
              
            <div *ngIf="obj.type=='object'">
              <div class="row p-1">
                  <div class="col-sm-3 mbb-1">
                          <select class="form-control mr-2" id="operator" name="operator" [(ngModel)]="obj.operator">
                              <option value="and">All</option>
                              <option value="or">Any</option>
                          </select>
                      </div>
             
            <div class="col-sm-9 text-left mbb-1 webdisplay">
                    <label>of the following condition : </label>&nbsp;&nbsp;
                    <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj.list)"><label>Add Condition</label></a>
                    <a href="javascript:void(0)" class="txtlink" (click)="onAddSubCondition(obj.list)"><label>Add Sub-Condition</label></a>
            </div>  </div>
               <div *ngFor="let obj2 of obj.list let i=index">
                 <div *ngIf="obj2.type=='row'">
                    <div class="row p-1">
                        <div class="col-sm-3 mbb-1">
                            <select class="form-control" id="field2_{{i}}" name="field2_{{i}}" [(ngModel)]="obj2.field_id" (change)="oncriteriaFieldChange(obj2)">
                                <option value=null>--Select--</option>
                                <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mbb-1">
                            <select class="form-control" id="condition2_{{i}}" name="condition2_{{i}}" [(ngModel)]="obj2.condition_id" (change)="onCriteriaConditionChange(obj2)">
                                <option value=null>--Select--</option>
                                <option *ngFor="let cond of obj2.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                            </select>
                        </div>
                        <div class="col-sm-4 cstmmultiselect">
                           <div class="mbb-1" *ngIf="obj2.control_type==1 || obj2.control_type==2 || obj2.control_type==3 || obj2.control_type==11 || obj2.control_type==12"><input type="text" class="form-control width100" id="val2_{{i}}" name="val2_{{i}}" [(ngModel)]="obj2.value"></div>
                           <div class="mbb-1" *ngIf="obj2.control_type==4 || obj2.control_type==9 || obj2.control_type==13"><input type="number" class="form-control width100" id="val2_{{i}}" name="val2_{{i}}" [(ngModel)]="obj2.value"></div>
                           <div class="mbb-1" *ngIf="obj2.control_type==14 || obj2.control_type==15 || obj2.control_type==10 || obj2.control_type==19 || obj2.control_type==22">
                            
                          <ng-multiselect-dropdown  
                            [settings]="dropdownSettings" 
                            [placeholder]="'Select'"
                            (onFilterChange)="OnSearch($event,obj2)"
                            [data]="obj2.ddl" 
                            [(ngModel)]="obj2.value"
                            ></ng-multiselect-dropdown>
                            
                        </div>
                          
                            <div *ngIf="obj2.control_type==7 || obj2.control_type==8" class="mbb-1">
                              <input type="date" class="form-control" id="val2_{{i}}" name="val2_{{i}}" [(ngModel)]="obj2.value" />
                             </div>
                          <div *ngIf="obj2.control_type==5" class="font8 my-2">
                              <span *ngFor="let ob of obj2.ddl" class="pdlftrgt15">
                                  <input id="val2_{{i}}" name="val2_{{i}}" type="radio" [(ngModel)]="obj2.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp; 
                              </span>
                          </div>
                          <div *ngIf="obj2.control_type==6" class="mbb-1">
                            <input id="val2_{{i}}" name="val2_{{i}}" type="checkbox" [(ngModel)]="obj2.value" />
                        </div>
                        </div>
                        <div class="col-sm-2 mbb-1">
                            <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj,obj2.id)"><label>Remove</label></a>
                        </div>
                        <div class="col-sm-12 mbb-1 mobiledisplay">
                            <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj.list)"><label>Add Condition</label></a>
                                <a href="javascript:void(0)" class="txtlink" (click)="onAddSubCondition(obj.list)"><label>Add Sub-Condition</label></a>
                       </div>
                    </div>
                 </div>
                 <div *ngIf="obj2.type=='object'" class="pl-4">
                    <div class="row p-1">
                        <div class="col-sm-3 mbb-1">
                            <select class="form-control" id="operator2" name="operator2" [(ngModel)]="obj2.operator">
                                <option value="and">All</option>
                                <option value="or">Any</option>
                            </select>
                            </div>
                   
                  <div class="col-sm-9 text-left mbb-1 webdisplay">
                          <label>of the following condition : </label>&nbsp;&nbsp;
                          <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj2.list)"><label>Add Condition</label></a>
                          <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj2.list)"><label>Add Sub-Condition</label></a>
                          <a href="javascript:void(0)" class="text-danger txtlink" (click)="onRemove(obj,obj2.id)"><label>Remove This Sub-Condition</label></a>
                        </div>  </div>
                  <div *ngFor="let obj3 of obj2.list let j=index">
                      <div *ngIf="obj3.type=='row'">
                          <div class="row p-1">
                              <div class="col-sm-3 mbb-1">
                                  <select class="form-control" id="field3_{{j}}" name="field3_{{j}}" [(ngModel)]="obj3.field_id" (change)="oncriteriaFieldChange(obj3)">
                                      <option value=null>--Select--</option>
                                      <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                                  </select>
                              </div>
                              <div class="col-sm-3 mbb-1">
                                  <select class="form-control" id="condition3_{{j}}" name="condition3_{{j}}" [(ngModel)]="obj3.condition_id" (change)="onCriteriaConditionChange(obj3)">
                                      <option value=null>--Select--</option>
                                      <option *ngFor="let cond of obj3.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                                  </select>
                              </div>
                              <div class="col-sm-3 cstmmultiselect" >
                                  <div class="mbb-1" *ngIf="obj3.control_type==1 || obj3.control_type==2 || obj3.control_type==3 || obj3.control_type==11 || obj3.control_type==12"><input type="text" class="form-control width100" id="val3_{{j}}" name="val3_{{j}}" [(ngModel)]="obj3.value"></div>
                                  <div class="mbb-1" *ngIf="obj3.control_type==4 || obj3.control_type==9 || obj3.control_type==13"><input type="number" class="form-control width100" id="val3_{{j}}" name="val3_{{j}}" [(ngModel)]="obj3.value"></div>
                                  <div class="mbb-1" *ngIf="obj3.control_type==14 || obj3.control_type==15 || obj3.control_type==10 || obj3.control_type==19 || obj3.control_type==22">
                                        <ng-multiselect-dropdown  
                                        [settings]="dropdownSettings" 
                                        [placeholder]="'Select'" 
                                        (onFilterChange)="OnSearch($event,obj3)"
                                        [data]="obj3.ddl" 
                                        [(ngModel)]="obj3.value"
                                        ></ng-multiselect-dropdown>
                                </div>
                                 <div *ngIf="obj3.control_type==7 || obj3.control_type==8" class="mbb-1">
                                  <input type="date" class="form-control" id="val3_{{j}}" name="val3_{{j}}" [(ngModel)]="obj3.value" />
                                 </div>
                                 <div *ngIf="obj3.control_type==5" class="font8 my-2">
                                     <span *ngFor="let ob of obj3.ddl" class="pdlftrgt15">
                                         <input id="val3_{{j}}" name="val3_{{j}}" type="radio" [(ngModel)]="obj3.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp;
                                     </span>
                                 </div>
                                 <div *ngIf="obj3.control_type==6" class="mbb-1">
                                    <input id="val3_{{j}}" name="val3_{{j}}" type="checkbox" [(ngModel)]="obj3.value" />
                                </div>
                              </div>
                              <div class="col-sm-3 mbb-1">
                                  <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj2,obj3.id)"><label>Remove</label></a>
                              </div>
                              <div class="col-sm-12 mbb-1 mobiledisplay">
                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj2.list)"><label>Add Condition</label></a>
                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj2.list)"><label>Add Sub-Condition</label></a>
                                <a href="javascript:void(0)" class="text-danger txtlink" (click)="onRemove(obj,obj2.id)"><label>Remove This Sub-Condition</label></a>
                              </div>
                          </div>
                       </div>
                       <div *ngIf="obj3.type=='object'" class="pl-4">
                          <div class="row p-1">
                              <div class="col-sm-3 mbb-1">
                                  <select class="form-control" id="operator3" name="operator3" [(ngModel)]="obj3.operator">
                                      <option value="and">All</option>
                                      <option value="or">Any</option>
                                  </select>
                                  </div>
                        
                        <div class="col-sm-9 mbb-1 webdisplay">
                                <label>of the following condition : </label>&nbsp;&nbsp;
                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj3.list)"><label>Add Condition</label></a>
                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj3.list)"><label>Add Sub-Condition</label></a>
                                <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj2,obj3.id)"><label>Remove This Sub-Condition</label></a>
                        </div>  </div>
                        <div *ngFor="let obj4 of obj3.list let k=index">
                           <div *ngIf="obj4.type=='row'">
                              <div class="row p-1">
                                  <div class="col-sm-3 mbb-1">
                                      <select class="form-control" id="field4_{{k}}" name="field4_{{k}}" [(ngModel)]="obj4.field_id" (change)="oncriteriaFieldChange(obj4)">
                                          <option value=null>--Select--</option>
                                          <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-3 mbb-1">
                                      <select class="form-control" id="condition4_{{k}}" name="condition4_{{k}}" [(ngModel)]="obj4.condition_id" (change)="onCriteriaConditionChange(obj4)">
                                          <option value=null>--Select--</option>
                                          <option *ngFor="let cond of obj4.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                                      </select>
                                  </div>
                                  <div class="col-sm-3 cstmmultiselect" >
                                      <div class="mbb-1" *ngIf="obj4.control_type==1 || obj4.control_type==2 || obj4.control_type==3 || obj4.control_type==11 || obj4.control_type==12"><input type="text" class="form-control width100" id="val4_{{k}}" name="val4_{{k}}" [(ngModel)]="obj4.value"></div>
                                      <div class="mbb-1" *ngIf="obj4.control_type==4 || obj4.control_type==9 || obj4.control_type==13"><input type="number" class="form-control width100" id="val4_{{k}}" name="val4_{{k}}" [(ngModel)]="obj4.value"></div>
                                      <div class="mbb-1" *ngIf="obj4.control_type==14 || obj4.control_type==15 || obj4.control_type==10 || obj4.control_type==19 || obj4.control_type==22">
                                            <ng-multiselect-dropdown  
                                            [settings]="dropdownSettings" 
                                            [placeholder]="'Select'" 
                                            [data]="obj4.ddl" 
                                            (onFilterChange)="OnSearch($event,obj4)"
                                            [(ngModel)]="obj4.value"
                                            ></ng-multiselect-dropdown>
                                    </div>
                                     <div class="mbb-1" *ngIf="obj4.control_type==7 || obj4.control_type==8">
                                      <input type="date" class="form-control" id="val4_{{k}}" name="val4_{{k}}" [(ngModel)]="obj4.value" />
                                     </div>
                                     <div *ngIf="obj4.control_type==5"  class="font8 my-2">
                                         <span *ngFor="let ob of obj4.ddl" class="pdlftrgt15">
                                             <input id="val4_{{k}}" name="val4_{{k}}" type="radio" [(ngModel)]="obj4.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp;
                                         </span>
                                     </div>
                                     <div *ngIf="obj4.control_type==6" class="mbb-1">
                                        <input id="val4_{{k}}" name="val4_{{k}}" type="checkbox" [(ngModel)]="obj4.value" />
                                    </div>
                                  </div>
                                  <div class="col-sm-3 mbb-1">
                                      <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj3,obj4.id)"><label>Remove</label></a>
                                  </div>
                                  <div class="col-sm-12 mbb-1 mobiledisplay">
                                    <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj3.list)"><label>Add Condition</label></a>
                                    <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj3.list)"><label>Add Sub-Condition</label></a>
                                    <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj2,obj3.id)"><label>Remove This Sub-Condition</label></a>
                            </div> 
                              </div>
                           </div>
                           <div *ngIf="obj4.type=='object'" class="pl-4">
                              <div class="row p-1">
                                  <div class="col-sm-3 mbb-1">
                                      <select class="form-control" id="operator4" name="operator4" [(ngModel)]="obj4.operator">
                                          <option value="and">All</option>
                                          <option value="or">Any</option>
                                      </select>
                                      </div>
                             
                            <div class="col-sm-9 text-left mbb-1 webdisplay">
                                    <label>of the following condition : </label>&nbsp;&nbsp;
                                    <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj4.list)"><label>Add Condition</label></a>
                                    <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj4.list)"><label>Add Sub-Condition</label></a>
                                    <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj3,obj4.id)"><label>Remove This Sub-Condition</label></a>
                                  </div>  </div>
                            <div *ngFor="let obj5 of obj4.list let l=index">
                                <div *ngIf="obj5.type=='row'">
                                    <div class="row p-1">
                                        <div class="col-sm-3 mbb-1">
                                            <select class="form-control" id="field5_{{l}}" name="field5_{{l}}" [(ngModel)]="obj5.field_id" (change)="oncriteriaFieldChange(obj5)">
                                                <option value=null>--Select--</option>
                                                <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mbb-1">
                                            <select class="form-control" id="condition5_{{l}}" name="condition5_{{l}}" [(ngModel)]="obj5.condition_id" (change)="onCriteriaConditionChange(obj5)">
                                                <option value=null>--Select--</option>
                                                <option *ngFor="let cond of obj5.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 cstmmultiselect" >
                                            <div class="mbb-1" *ngIf="obj5.control_type==1 || obj5.control_type==2 || obj5.control_type==3 || obj5.control_type==11 || obj5.control_type==12"><input type="text" class="form-control width100" id="val5_{{l}}" name="val5_{{l}}" [(ngModel)]="obj5.value"></div>
                                            <div class="mbb-1" *ngIf="obj5.control_type==4 || obj5.control_type==9 || obj5.control_type==13"><input type="number" class="form-control width100" id="val5_{{l}}" name="val5_{{l}}" [(ngModel)]="obj5.value"></div>
                                            <div class="mbb-1" *ngIf="obj5.control_type==14 || obj5.control_type==15 || obj5.control_type==10 || obj5.control_type==19 || obj5.control_type==22">
                                                    <ng-multiselect-dropdown  
                                                    [settings]="dropdownSettings" 
                                                    (onFilterChange)="OnSearch($event,obj5)"
                                                    [placeholder]="'Select'" 
                                                    [data]="obj5.ddl" 
                                                    [(ngModel)]="obj5.value"
                                                    ></ng-multiselect-dropdown>
                                        </div>
                                           <div class="mbb-1" *ngIf="obj5.control_type==7 || obj5.control_type==8">
                                            <input type="date" class="form-control" id="val5_{{l}}" name="val5_{{l}}" [(ngModel)]="obj5.value" />
                                           </div>
                                           <div *ngIf="obj5.control_type==5"  class="font8 my-2">
                                               <span *ngFor="let ob of obj5.ddl" class="pdlftrgt15">
                                                   <input id="val5_{{l}}" name="val5_{{l}}" type="radio" [(ngModel)]="obj5.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp;
                                               </span>
                                           </div>
                                           <div *ngIf="obj5.control_type==6" class="mbb-1">
                                            <input id="val5_{{l}}" name="val5_{{l}}" type="checkbox" [(ngModel)]="obj5.value" />
                                        </div>
                                        </div>
                                        <div class="col-sm-3 mbb-1">
                                            <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj4,obj5.id)"><label>Remove</label></a>
                                        </div>
                                        <div class="col-sm-12 mobiledisplay mbb-1">
                                            
                                            <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj4.list)"><label>Add Condition</label></a>
                                            <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj4.list)"><label>Add Sub-Condition</label></a>
                                            <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj3,obj4.id)"><label>Remove This Sub-Condition</label></a>
                                          </div>
                                    </div>
                                 </div>
                                
                                 <div *ngIf="obj5.type=='object'" class="pl-4">
                                    <div class="row p-1">
                                        <div class="col-sm-3 mbb-1">
                                            <select class="form-control" id="operator5" name="operator5" [(ngModel)]="obj5.operator">
                                                <option value="and">All</option>
                                                <option value="or">Any</option>
                                            </select>
                                            </div>
                                   
                                  <div class="col-sm-9 text-left mbb-1 webdisplay">
                                          <label>of the following condition : </label>&nbsp;&nbsp;
                                          <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj5.list)"><label>Add Condition</label></a>
                                          <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj5.list)"><label>Add Sub-Condition</label></a>
                                          <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj4,obj5.id)"><label>Remove This Sub-Condition</label></a>
                                  </div>
                                  <div *ngFor="let obj6 of obj5.list let m =index">
                                      <div *ngIf="obj6.type=='row'">
                                          <div class="row p-1">
                                              <div class="col-sm-3 mbb-1">
                                                  <select class="form-control" id="field6_{{m}}" name="field6_{{m}}" [(ngModel)]="obj6.field_id" (change)="oncriteriaFieldChange(obj6)">
                                                      <option value=null>--Select--</option>
                                                      <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                                                  </select>
                                              </div>
                                              <div class="col-sm-3 mbb-1">
                                                  <select class="form-control" id="condition6_{{m}}" name="condition6_{{m}}" [(ngModel)]="obj6.condition_id" (change)="onCriteriaConditionChange(obj6)">
                                                      <option value=null>--Select--</option>
                                                      <option *ngFor="let cond of obj6.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                                                  </select>
                                              </div>
                                              <div class="col-sm-3 cstmmultiselect" >
                                                  <div class="mbb-1" *ngIf="obj6.control_type==1 || obj6.control_type==2 || obj6.control_type==3 || obj6.control_type==11 || obj6.control_type==12"><input type="text" class="form-control width100" id="val6_{{m}}" name="val6_{{m}}" [(ngModel)]="obj6.value"></div>
                                                  <div class="mbb-1" *ngIf="obj6.control_type==4 || obj6.control_type==9 || obj6.control_type==13"><input type="number" class="form-control width100" id="val6_{{m}}" name="val6_{{m}}" [(ngModel)]="obj6.value"></div>
                                                  <div class="mbb-1" *ngIf="obj6.control_type==14 || obj6.control_type==15 || obj6.control_type==10 || obj6.control_type==19 || obj6.control_type==22">
                                                        <ng-multiselect-dropdown  
                                                        [settings]="dropdownSettings" 
                                                        [placeholder]="'Select'" 
                                                        [data]="obj6.ddl" 
                                                        (onFilterChange)="OnSearch($event,obj6)"
                                                        [(ngModel)]="obj6.value"
                                                        ></ng-multiselect-dropdown>
                                                </div>
                                                 <div class="mbb-1" *ngIf="obj6.control_type==7 || obj6.control_type==8">
                                                  <input type="date" class="form-control" id="val6_{{m}}" name="val6_{{m}}" [(ngModel)]="obj6.value" />
                                                 </div>
                                                 <div *ngIf="obj6.control_type==5"  class="font8 my-2">
                                                     <span *ngFor="let ob of obj6.ddl" class="pdlftrgt15">
                                                         <input id="val6_{{m}}" name="val6_{{m}}" type="radio" [(ngModel)]="obj6.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp;
                                                     </span>
                                                 </div>
                                                 <div class="mbb-1" *ngIf="obj6.control_type==6">
                                                    <input id="val6_{{m}}" name="val6_{{m}}" type="checkbox" [(ngModel)]="obj6.value" />
                                                </div>
                                              </div>
                                              <div class="col-sm-3 mbb-1">
                                                  <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj5,obj6.id)"><label>Remove</label></a>
                                              </div>
                                              <div class="col-sm-12 mbb-1 mobiledisplay">
                                                <!-- <label>of the following condition : </label>&nbsp;&nbsp; -->
                                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj5.list)"><label>Add Condition</label></a>
                                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj5.list)"><label>Add Sub-Condition</label></a>
                                                <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj4,obj5.id)"><label>Remove This Sub-Condition</label></a>
                                        </div>
                                          </div>
                                       </div>
                                       <div *ngIf="obj6.type=='object'" class="pl-4">
                                          <div class="row p-1">
                                              <div class="col-sm-3 mbb-1">
                                                  <select class="form-control" id="operator6" name="operator6" [(ngModel)]="obj6.operator">
                                                      <option value="and">All</option>
                                                      <option value="or">Any</option>
                                                  </select>
                                                  </div>
                                         
                                        <div class="col-sm-9 text-left mbb-1 webdisplay">
                                                <label>of the following condition : </label>&nbsp;&nbsp;
                                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj6.list)"><label>Add Condition</label></a>
                                                <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj6.list)"><label>Add Sub-Condition</label></a>
                                                <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj5,obj6.id)"><label>Remove This Sub-Condition</label></a>
                                              </div></div>
                                              <div *ngFor="let obj7 of obj6.list let n =index">
                                                  <div *ngIf="obj7.type=='row'">
                                                      <div class="row p-1">
                                                          <div class="col-sm-3 mbb-1">
                                                              <select class="form-control" id="field7_{{n}}" name="field7_{{n}}" [(ngModel)]="obj7.field_id" (change)="oncriteriaFieldChange(obj7)">
                                                                  <option value=null>--Select--</option>
                                                                  <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                                                              </select>
                                                          </div>
                                                          <div class="col-sm-3 mbb-1">
                                                              <select class="form-control" id="condition7_{{n}}" name="condition7_{{n}}" [(ngModel)]="obj7.condition_id" (change)="onCriteriaConditionChange(obj7)">
                                                                  <option value=null>--Select--</option>
                                                                  <option *ngFor="let cond of obj7.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                                                              </select>
                                                          </div>
                                                          <div class="col-sm-3 cstmmultiselect" >
                                                              <div class="mbb-1" *ngIf="obj7.control_type==1 || obj7.control_type==2 || obj7.control_type==3 || obj7.control_type==11 || obj7.control_type==12"><input type="text" class="form-control width100" id="val7_{{n}}" name="val7_{{n}}" [(ngModel)]="obj7.value"></div>
                                                              <div class="mbb-1" *ngIf="obj7.control_type==4 || obj7.control_type==9 || obj7.control_type==13"><input type="number" class="form-control width100" id="val7_{{n}}" name="val7_{{n}}" [(ngModel)]="obj7.value"></div>
                                                              <div class="mbb-1" *ngIf="obj7.control_type==14 || obj7.control_type==15 || obj7.control_type==10 || obj7.control_type==19 || obj7.control_type==22">
                                                                    <ng-multiselect-dropdown  
                                                                    [settings]="dropdownSettings" 
                                                                    [placeholder]="'Select'" 
                                                                    [data]="obj7.ddl" 
                                                                    (onFilterChange)="OnSearch($event,obj7)"
                                                                    [(ngModel)]="obj7.value"
                                                                    ></ng-multiselect-dropdown>
                                                            </div>
                                                             <div class="mbb-1" *ngIf="obj7.control_type==7 || obj7.control_type==8">
                                                              <input type="date" class="form-control" id="val7_{{n}}" name="val7_{{n}}" [(ngModel)]="obj7.value" />
                                                             </div>
                                                             <div *ngIf="obj7.control_type==5"  class="font8 my-2">
                                                                 <span *ngFor="let ob of obj7.ddl" class="pdlftrgt15">
                                                                     <input id="val7_{{n}}" name="val7_{{n}}" type="radio" [(ngModel)]="obj7.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp;
                                                                 </span>
                                                             </div>
                                                             <div class="mbb-1" *ngIf="obj7.control_type==6">
                                                                <input id="val7_{{n}}" name="val7_{{n}}" type="checkbox" [(ngModel)]="obj7.value" />
                                                            </div>
                                                          </div>
                                                          <div class="col-sm-3 mbb-1">
                                                              <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj6,obj7.id)"><label>Remove</label></a>
                                                          </div>
                                                          <div class="col-sm-12 mbb-1 mobiledisplay">
                                                            <!-- <label>of the following condition : </label>&nbsp;&nbsp; -->
                                                            <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj6.list)"><label>Add Condition</label></a>
                                                            <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddSubCondition(obj6.list)"><label>Add Sub-Condition</label></a>
                                                            <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj5,obj6.id)"><label>Remove This Sub-Condition</label></a>
                                                          </div>
                                                      </div>
                                                   </div>     
                                                   <div *ngIf="obj7.type=='object'" class="pl-4">
                                                      <div class="row p-1">
                                                          <div class="col-sm-3 mbb-1">
                                                              <select class="form-control" id="operator6" name="operator6" [(ngModel)]="obj7.operator">
                                                                  <option value="and">All</option>
                                                                  <option value="or">Any</option>
                                                              </select>
                                                              </div>
                                                     
                                                    <div class="col-sm-9 text-left mbb-1 webdisplay">
                                                            <label>of the following condition : </label>&nbsp;&nbsp;
                                                            <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj7.list)"><label>Add Condition</label></a>
                                                            <!-- <a href="javascript:void(0)" class="txtlink" (click)="onAddSubCondition(obj7.list)"><label>Add Sub-Condition</label></a> -->
                                                            <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj6,obj7.id)"><label>Remove This Sub-Condition</label></a>
                                                          </div></div>
                                                          <div *ngFor="let obj8 of obj7.list let o =index">
                                                              <div *ngIf="obj8.type=='row'">
                                                                  <div class="row p-1">
                                                                      <div class="col-sm-3 mbb-1">
                                                                          <select class="form-control" id="field8_{{o}}" name="field8_{{o}}" [(ngModel)]="obj8.field_id" (change)="oncriteriaFieldChange(obj8)">
                                                                              <option value=null>--Select--</option>
                                                                              <option *ngFor="let ddl of allFormFields" [value]="ddl.field_name">{{ddl.field_caption}}</option>
                                                                          </select>
                                                                      </div>
                                                                      <div class="col-sm-3 mbb-1">
                                                                          <select class="form-control" id="condition8_{{o}}" name="condition8_{{o}}" [(ngModel)]="obj8.condition_id" (change)="onCriteriaConditionChange(obj8)">
                                                                              <option value=null>--Select--</option>
                                                                              <option *ngFor="let cond of obj8.conditionList" [value]="cond.condition_id">{{cond.condition_text}}</option>
                                                                          </select>
                                                                      </div>
                                                                      <div class="col-sm-3 cstmmultiselect" >
                                                                          <div class="mbb-1" *ngIf="obj8.control_type==1 || obj8.control_type==2 || obj8.control_type==3 || obj8.control_type==11 || obj8.control_type==12"><input type="text" class="form-control width100" id="val8_{{o}}" name="val8_{{o}}" [(ngModel)]="obj8.value"></div>
                                                                          <div class="mbb-1" *ngIf="obj8.control_type==4 || obj8.control_type==9 || obj8.control_type==13"><input type="number" class="form-control width100" id="val8_{{o}}" name="val8_{{o}}" [(ngModel)]="obj8.value"></div>
                                                                          <div class="mbb-1" *ngIf="obj8.control_type==14 || obj8.control_type==15 || obj8.control_type==10 || obj8.control_type==19 || obj8.control_type==22">
                                                                                <ng-multiselect-dropdown  
                                                                                [settings]="dropdownSettings" 
                                                                                [placeholder]="'Select'" 
                                                                                [data]="obj8.ddl" 
                                                                                (onFilterChange)="OnSearch($event,obj8)"
                                                                                [(ngModel)]="obj8.value"
                                                                                ></ng-multiselect-dropdown>
                                                                        </div>
                                                                         <div class="mbb-1" *ngIf="obj8.control_type==7 || obj8.control_type==8">
                                                                          <input type="date" class="form-control" id="val8_{{o}}" name="val8_{{o}}" [(ngModel)]="obj8.value" />
                                                                         </div>
                                                                         <div *ngIf="obj8.control_type==5"  class="font8 my-2">
                                                                             <span *ngFor="let ob of obj8.ddl" class="pdlftrgt15">
                                                                                 <input id="val8_{{o}}" name="val8_{{o}}" type="radio" [(ngModel)]="obj8.value" [value]="ob.id" >&nbsp; {{ob.labl}} &nbsp;
                                                                             </span>
                                                                         </div>
                                                                         <div class="mbb-1" *ngIf="obj8.control_type==6">
                                                                                <input id="val8_{{o}}" name="val8_{{o}}" type="checkbox" [(ngModel)]="obj8.value" />
                                                                        </div>
                                                                      </div>
                                                                      <div class="col-sm-3 mbb-1">
                                                                          <a class="text-warning" href="javascript:void(0)" (click)="onRemove(obj7,obj8.id)"><label>Remove</label></a>
                                                                      </div>
                                                                      <div class="col-sm-12 mbb-1 mobiledisplay">
                                                                        <!-- <label>of the following condition : </label>&nbsp;&nbsp; -->
                                                                        <a href="javascript:void(0)" class="txtlink mr-2" (click)="onAddCondition(obj7.list)"><label>Add Condition</label></a>
                                                                        <!-- <a href="javascript:void(0)" class="txtlink" (click)="onAddSubCondition(obj7.list)"><label>Add Sub-Condition</label></a> -->
                                                                        <a href="javascript:void(0)" class="red txtlink" (click)="onRemove(obj6,obj7.id)"><label>Remove This Sub-Condition</label></a>
                                                                      </div>
                                                                  </div>
                                                               </div>     
                                                          </div>
                                                          
                                                        </div>
                                              </div>
                                       </div>
                                  </div>
                                  
                                 </div>

                            </div>
                           </div>  
                        </div>
                       </div>
                  </div>

                 </div>
               </div>                                     
             </div>

          </div>
      </div>
  </div>    

  <button (click)="apply()" class="btn btn-dark btn-sm rounded-pill pl-3 pr-3 mr-2">Apply</button>

  