
<div class="d-flex minhyt80" id="wrapper">
    <div class="" id="page-content-wrapper">
      <app-header> </app-header>
      <div class="webdesk">
        <app-left-menu></app-left-menu>
      </div>
        <div class="container-fluid bckgry">
            <div class="col-sm-12 p-0">
                <div class="row d-flex ">
                    <div class="col-sm-12 p-0">

                        <div class="funds-success-message-container card mt-4 py-4">
                            <div class="funds-checkmark-text-container">

                                <div class="success-checkmark pt-2">
                                    <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="success-message text-center">
                                <h2>Thanks for Registration</h2>
                                <p>Your request submitted successfully, <br>after approval by NCRB you will get
                                    notified.Thank you</p>
                                <button class="btn btn-primary btn-sm rounded round_btn mr-3 "> Print </button> <button
                                    class="btn btn-danger  btn-sm rounded round_btn " (click)="gotologin()"> Close </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>