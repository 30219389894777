import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormService } from '../../../app/_services/form.service';
import { Page } from '../../_models/page';
import { SectionConfig, SessionUrlPrams, ConfigPrams } from "../../field.interface";
import { Router } from '@angular/router';
import { IgxGridComponent, DisplayDensity, IgxCsvExporterService, IgxExcelExporterService, IgxGridRowComponent, Transaction, IgxStringFilteringOperand, ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, GridPagingMode, IgxExcelExporterOptions, IgxCsvExporterOptions, CsvFileTypes, IgxTreeGridComponent, IColumnExportingEventArgs } from "@infragistics/igniteui-angular";
import { ProductService } from '../../_services/product.service';
declare var $: any;

@Component({
  selector: 'app-manage-order',
  templateUrl: './manage-order.component.html',
  styleUrls: ['./manage-order.component.css']
})
export class ManageOrderComponent implements OnInit {
  sellerId: any;
  cancledDataShow:any
  // deliveredDataShow:any
  adminRemarks: any;
  QC_id: any;
  view_item_id: any;
  isView: boolean;
  allOrderList: any;
  isLoader: boolean = true;
  ShippedDataShow: boolean = false;
  page = new Page();
  configParms: ConfigPrams;
  public totalCount = 0;
  LoadPagination: boolean = false;
  LoadPagination2: boolean = false;
  LoadPagination3: boolean = false;
  LoadPagination4: boolean = false;
  LoadPagination5: boolean = false;
  LoadPagination6: boolean = false;
  deliveredDataShow : boolean = false;
  TempallOrderList: any;
  isLoading: boolean = false;
  // isLoader: boolean = true;
  userId: any;
  pendingDataShow: boolean = false;
  dispatchDataShow: boolean = false;
  allOrderListData: any;
  productId:any;
  action: any;
  qcstatus: any;
  rejectDataShow:boolean = false;
  sellerName:any;
  pendingOrderCount : any;
  rejectOrderCount : any;
  shipedOrderCount : any;
  readyToShipedOrderCount : any;
  acceptedOrderCount : any;
  cancledOrderCount : any;
  deleveredOrderCount : any;
  currentTooltip:any=""
  orderListData:any;
  LoadPaginationfive: boolean = false;
  totalCountPending:any;
  currentOrderStatus: any = '';
  currentOrderStatus2: any = '';
  searchTerm: any;
  isLoadGrid: boolean = false;
  constructor(private productService: ProductService,
    private formService: FormService,
    private toastrService: ToastrService,  private router: Router) { }

  ngOnInit(): void {
    this.sellerId = localStorage.getItem('selected_seller_id');
    this.sellerName = localStorage.getItem('sellerName');
    this.isLoading = true;
    this.pendingDataShow = true;
    this.dispatchDataShow = false;
    this.ShippedDataShow = false;
    this.rejectDataShow = false;
    this.userId = localStorage.getItem("userId");
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.getProductOrderList();
    this.bindNgxDataGrid(this.page, 1, 187)

    $(document).ready(function(){
      $('ul.tbsbtns li').click(function(){
        $('li').removeClass("is-active");
        $(this).addClass("is-active");
    });
    });
  
  }
  setTitle(e){
    this.currentTooltip=e;
  }

  getProductOrderList(){
    let byseller = ''
    this.productService.getAllOrderListSellerWiseForcount(this.sellerId).subscribe(res => {
      this.orderListData = res.rows;
      console.log("listdata===", this.orderListData)
      this.pendingOrderCount = (this.orderListData.filter(x => x.order_status == 187)).length;
      this.rejectOrderCount = (this.orderListData.filter(x => x.order_status == 188)).length;
      this.shipedOrderCount = (this.orderListData.filter(x => x.order_status == 189)).length;
      this.readyToShipedOrderCount = (this.orderListData.filter(x => x.order_status == 192)).length;
      this.acceptedOrderCount = (this.orderListData.filter(x => x.order_status == 194)).length;
      this.cancledOrderCount = (this.orderListData.filter(x => x.order_status == 190)).length;
      byseller = (this.orderListData.filter(x => x.order_status == 191)).length;
      this.cancledOrderCount = byseller + this.cancledOrderCount
      this.deleveredOrderCount = (this.orderListData.filter(x => x.order_status == 193)).length;
    }) 
  }
  LoadGrid(event) {
    this.isLoadGrid = true
    this.configParms = event; 
    this.page.size = event.itemsPerPage;
    this.page.totalElements = event.totalItems;
    this.page.pageNumber = event.currentPage;
    this.bindNgxDataGrid(this.page, 1, undefined)

  }
  bindNgxDataGrid(pageInfo, status, orderStatus, orderStatus2?) {
    this.currentOrderStatus = orderStatus ? orderStatus : this.currentOrderStatus
    this.currentOrderStatus2 = orderStatus2 ? orderStatus2 : this.currentOrderStatus2
    // console.log('check orderstatus', {first: this.currentOrderStatus, second: this.currentOrderStatus2})
    this.isLoader = true;
    let total, pagesize, limit, offset = 0;
    pagesize = pageInfo.size;
    if (pageInfo.pageNumber == 0 || pageInfo.pageNumber == 1 || status == 0) {
      offset = 0;
    } else {
      offset = ((pageInfo.pageNumber - 1) * pagesize);

    }
  //  let orderStatus = 187;
  

if(!this.isLoadGrid)
offset = this.searchTerm ? 0 : offset
    this.productService.getAllOrderListsellerwisecount(this.searchTerm, this.currentOrderStatus,this.sellerId, this.currentOrderStatus2).subscribe(resp => {
      this.page.totalElements = resp.rows[0].count;
      // console.log('countss', this.page.totalElements)
      let pageCount = pageInfo.pageNumber
      offset = this.page.totalElements < offset ? 0 : this.page.totalElements > offset ? offset : 0
      this.productService.getAllOrderListsellerwise(this.searchTerm, pagesize,offset,this.currentOrderStatus,this.sellerId, this.currentOrderStatus2).subscribe(res => {
        // console.log('check response', res.rows)
        this.allOrderList = res.rows;
      //  console.log ("alllist", this.allOrderList)
        // this.sellerName = this.allOrderList[0]?.seller_name;
        this.TempallOrderList = this.allOrderList;
        this.isLoader = false;
      })
      this.totalCount = resp.rows[0].count;
      let currPage = pageInfo.pageNumber;
      if (currPage == 0) { currPage = 1; }
      this.configParms = { currentPage: currPage, totalItems: this.totalCount, itemsPerPage: pagesize }
      if(orderStatus == 187){
        this.LoadPagination = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination5 = false;
      }
      if(orderStatus == 192){
        this.LoadPagination2 = true;
        this.LoadPagination = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination5 = false;
      }
      if(orderStatus == 189){
        this.LoadPagination3 = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination = this.LoadPagination5 = false;
      }
      if(orderStatus == 193){
        this.LoadPagination4 = true;
        this.LoadPagination2 = this.LoadPagination = this.LoadPagination3 = this.LoadPagination5 = false;
      }
      if(orderStatus == 190 && orderStatus2 == 191){
        this.LoadPagination5 = true;
        this.LoadPagination2 = this.LoadPagination4 = this.LoadPagination3 = this.LoadPagination = false;
      }
  
 
      
      this.isLoading = false;
    })

  }


  CommonGridSearchMaster(ev) {
    // console.log('check orderstatus foro searc', {first: this.currentOrderStatus, second: this.currentOrderStatus2})

    this.searchTerm = ev;
    // this.getAllTypeOrder()
    if(this.currentOrderStatus2)
      this.bindNgxDataGrid(this.page, 1, this.currentOrderStatus, this.currentOrderStatus2);
    else
      this.bindNgxDataGrid(this.page, 1, this.currentOrderStatus);
    // this.allOrderList = this.TempallOrderList.filter(item => ((item.order_number ? (item.order_number.toLowerCase().indexOf(ev) !== -1) : false) || (item.product_name ? (item.product_name.toLowerCase().indexOf(ev) !== -1) : false) || (item.order_number ? (item.order_number.toLowerCase().indexOf(ev) !== -1) : false)))
  }

  getAllTypeOrder(code, code2?) {
    this.searchTerm = ''
    this.currentOrderStatus = ''
    this.currentOrderStatus2 = ''
      // console.log('check for status code', {code: code,code2: code2})
    // this.can = false;
    this.configParms = { currentPage: 1, totalItems: this.totalCount, itemsPerPage: 10 }
    this.page.size = 10
    this.page.pageNumber=0
    this.page.totalPages=0;
    if(code2)
    this.bindNgxDataGrid(this.page, 1, code, code2)
    else{
      this.bindNgxDataGrid(this.page, 1, code)
      this.currentOrderStatus2 = ''
    }
  } 

 
  
 
  closeModal() {
    this.isView = false;
    $("#myModal").modal('hide');
  }


  onProductdetailView(data) {
    localStorage.setItem("view-item", data);
    this.view_item_id = data.rowData.product_id;
    $('#scroll').animate({ scrollTop: 0 }, 'slow');
    this.isView = true;
    this.productService.isOpenProduct(this.view_item_id).subscribe(res => {
      $("#exampleModal").modal('show');
      //this.toastrService.success("Product Approved", '', { timeOut: 3000, positionClass: "toast-top-center" 
    });
  }

  showSellerDetails(id) {
    localStorage.setItem("selected_seller_id", id.rowData.seller_id);
    localStorage.setItem('approve_reject', 'false')
    this.router.navigateByUrl('/seller-profile'+ '?' + localStorage.getItem('tempToken'));

  }
  
  ngAfterViewChecked() {
    $(".toast-close-button").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");

    })
    $(".toast-container").click(function () {
      $(".overlay-container").removeClass("addcontainerdisable");
    })

  }

}
